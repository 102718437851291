import * as ReduxUtils from 'shared/utils/reduxUtils';

import { moduleName } from '../constants';

const transform = ReduxUtils.createModuleSelectorTransform(moduleName);

const selectors = ReduxUtils.transformSelectors(transform, {
  getErrorMessage: state => state.errorMessage,
  getLoginResult: state => state.loginResult,
  getPasswordUpdateErrorMessage: state => state.passwordUpdateErrorMessage,
  getCurrentQuestion: state => state.currentQuestion,
  getCurrentQuestionId: state => state.currentQuestionId,
  getSecurityQuestions: state => state.securityQuestions,
  getResetPasswordResult: state => state.resetPasswordResult,
  getSecurityQuestionResult: state => state.securityQuestionResult,
  getZebraTimeoutResult: state => state.zebraTimeoutResult,
  getUsername: state => state.username,
  getUserId: state => state.userId,
  getUser: state => state.user,
  getLogoutMessage: state => state.logoutMessage,
  checkAuthCodeResult: state => state.checkAuthCode,
  isAuthCodeRequired: state => state.isAuthCodeRequired
});

export default selectors;
