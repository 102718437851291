import * as R from 'ramda';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col, FormGroup } from 'reactstrap';

const AppMultiSelect = ({
  id,
  options,
  selectedChanged,
  selected,
  placeholder,
  displayKey,
  valueKey,
}) => {
  const [selectedValue, setSelectedValue] = useState('');

  const selectValue = event => {
    const value = event.target.value;
    if (R.find(item => item === value, selected)) {
      selectedChanged(
        id,
        selected.filter(item => item !== value)
      );
      setSelectedValue('');
      return;
    }

    selectedChanged(id, [...selected, value]);
    setSelectedValue(value);
  };

  const readonlyInputValue = () => {
    return selected
      .map(value => {
        const item = R.find(item => item[valueKey] === value, options);
        if (!item) {
          return '';
        }
        return item[displayKey];
      })
      .join(', ');
  };

  return (
    <Row>
      <Col lg="6">
        <FormGroup>
          <Input
            type="select"
            id={id}
            name={id}
            onChange={selectValue}
            value={selectedValue}
          >
            {placeholder && (
              <option key={-1} value="" disabled>
                {placeholder}
              </option>
            )}
            {options.map((item, index) => (
              <option key={index} value={item[valueKey]}>
                {item[displayKey]}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Input readOnly value={readonlyInputValue()} />
        </FormGroup>
      </Col>
    </Row>
  );
};

AppMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedChanged: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  displayKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
};

export default AppMultiSelect;
