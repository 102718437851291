import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from 'reactstrap';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';

class SearchableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };

    
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  componentDidMount(){
    if(this.props.runSetDataList){
      this.setDataList()
    }
  }

  setDataList = () => {
   
    if (navigator.userAgent.indexOf("Chrome")>-1) { 
      if ('content' in document.createElement('template')) { // if the browser supports templates
          var results=document.querySelector('#' +this.props.id + 'List')
    
          if (results){
            
                var templateResults=document.createElement("template")
                this.props.data.map((object, index) => {
                var el=document.createElement("option")
                el.textContent=object[this.props.displayOption]
                el.value=object[this.props.displayOption]
                el.setAttribute("key", object[this.props.displayOption])
                templateResults.appendChild(el)
                
              });

          
            // Remove all the options due to Chrome's 512 limit
            while(results.options.length){    
                results.removeChild(results.lastChild)
              }

              var clone=templateResults.cloneNode(true).children;
              // Rebuild the options by using the template
              if(clone && clone.length){
                for(let object of clone){
                  results.appendChild(object);
                }
              }            
          }
      }
      else {
        console.log("Templates are not supported by the browser")
      }
    }
  }
   
  onInputBlur = event => {
    let value = event.target.value;

    const match = this.props.data.filter(object => {
      return object[this.props.displayOption].trim() === value;
    });

    if (!match.length) {
      if (!!value.length) {
        this.props.alert(
          this.props.placeholder +
          ' entered in input field must match a record from the list.'
        );
      }
      event.target.value = '';
      this.clearInput();
    } else {
      value = !!this.props.returnValue
        ? match[0][this.props.returnValue]
        : value;
      this.props.stateHandler(this.props.id, value);
    }
  };

  onInputChange = event => {
    //if (this.props.filterType = "startWith") {
     // const datalist = document.querySelector('#' + this.props.id + 'List');
     const datalist = document.querySelector('#' + event.target.id + 'List');

      let prefix = event.target.value;

      let elm = datalist.firstElementChild;
        while( elm )
        { 
          if( elm.value.toLowerCase().startsWith( prefix.toLowerCase() )) { 
            elm.removeAttribute('disabled');
          } else {
            elm.setAttribute('disabled', true );
          }
          elm = elm.nextElementSibling;
        }
    //}
  };

  clearInput = () => {
    const field = this.props.id;
    document.getElementById(field).value = '';
    this.props.stateHandler(field, '');
   
    // Reset entries
    const datalist = document.querySelector('#' + this.props.id + 'List');
    let elm = datalist.firstElementChild;
    while (elm) {
      elm.removeAttribute('disabled');
      elm = elm.nextElementSibling;
    }
  };

  getDefaultDisplayOption() {

    var defaultInputValue = [];

    // if (typeof this.props.data[0][this.props.returnValue] == "number") {
    defaultInputValue = this.props.data.filter(
      object => object[this.props.returnValue] == this.props.defaultValue
    );

    // }




    // if (typeof this.props.data[0][this.props.returnValue] == "string") {
    //   defaultInputValue = this.props.data.filter(
    //     object => object[this.props.returnValue] == this.props.defaultValue
    //   );
    // }

    var returnval = this.props.returnValue;

    var returnObj = this.props.data[0];
    return defaultInputValue.length !== 0
      ? defaultInputValue[0][this.props.displayOption]
      : '';
  }

  render() {
  
    return (
      <>
        <InputGroup>
          <Input
            list={this.props.id + 'List'}
            type="text"
            id={this.props.id}
            placeholder={this.props.placeholder}
            onBlur={this.onInputBlur}
            onInput={this.onInputChange}
            defaultValue={this.props.advancedBillSearch ? this.getDefaultDisplayOption() : this.props.defaultValue}
          />
          <datalist id={this.props.id + 'List'} >
          {this.props.data.map((object, index) => (
              <option value={object[this.props.displayOption]}>{object[this.props.displayOption]}</option>
            ))}
          </datalist>
          {this.props.showToolTip ? (
            <Tooltip
              placement={this.props.tipPlacement}
              isOpen={this.state.tooltipOpen}
              target={this.props.id}
              delay={this.props.tooltipDelay}
              toggle={this.toggle}
            >
              {this.props.placeholder}
            </Tooltip>
          ) : null}
          <InputGroupAddon addonType="append">
            <Button
              onClick={this.clearInput}
              color="danger"
              name={this.props.id}
            >
              X
            </Button>
          </InputGroupAddon>
        </InputGroup>
        
      </>
    );
  }
}

SearchableSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  returnValue: PropTypes.string.isRequired,
  displayOption: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  stateHandler: PropTypes.func.isRequired,
  showToolTip: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipPlacement: PropTypes.string,
  tooltipDelay: PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number,
  }),
  alert: PropTypes.func.isRequired,
  filterType: PropTypes.string, 
  runSetDataList: PropTypes.bool,
};

SearchableSelect.defaultProps = {
  showToolTip: true,
  tooltipDelay: { show: 800, hide: 250 },
  tipPlacement: 'bottom',
  filterType: 'contains',
};

const mapDispatchToProps = dispatch => ({
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(null, mapDispatchToProps)(SearchableSelect);