import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Row } from 'reactstrap';
import DemandButton from './DemandButton';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { authSelectors } from 'modules/auth/store';

import {
  findBillsGridSettingName,
  findBillsGridSettingModuleId,
} from '../utils/constantUtils';

import GridSettingsModal from './GridSettingsModal';
// import NoteLinkAction from './NoteLinkAction';
import BillClaimNotesAction from './BillClaimNotesAction'
import ReferralButton from './ReferralButton';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTH_VIEW = 'monthView';
export const YEAR_VIEW = 'yearView';
class BillSearchHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: 0,
      year: 2000,
      isShowDemandView: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {  
    if (this.props.date && prevProps.date === '' && prevProps.date !== this.props.date) {
      const month = this.props.date.getMonth();
      const year = this.props.date.getFullYear();
      if(month && year && month !== this.state.month || year !== this.state.year) {
        this.setState({
          month: month,
          year: year,
        });
        this.props.onDateChangeHandler({
          month: month,
          year: year,
        });
      }
      }
     
    // if (
    //   this.state.month !== prevState.month ||
    //   this.state.year !== prevState.year
    // ) {
    //   this.props.onDateChangeHandler({
    //     month: this.state.month,
    //     year: this.state.year,
    //   });
    // }
  }

  getClient = (sites, siteId) => {
    const site = sites.filter(site => site.int_sitecode === parseInt(siteId));

    return !!site.length ? site[0].chr_sitename : '';
  };

  toggleURDecision = claim => {
    const { userId } = this.props;

    this.props.onURDecision({ ...claim, userId });
    this.props.urDecisionToggle();
  };

  goToToday = () => {
    const today = new Date();
    const date = {
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.setState(date);
    this.props.onDateChangeHandler(date);
  };

  buildCalendarButtons = () => {
    return this.props.showCalendar ? (
      <Col>
        <Row>
          <Col>
            <Button
              className="mr-2"
              onClick={() => this.props.changeViewHandler(MONTH_VIEW)}
            >
              Month View
            </Button>
            <Button
              className="mr-2"
              onClick={() => this.props.changeViewHandler(YEAR_VIEW)}
            >
              Year View
            </Button>
            <Button onClick={this.goToToday}>Today</Button>
          </Col>
          <Col lg="3">
            <Input
              type="select"
              id="month-selector"
              value={this.state.month}
              onChange={e => this.onSelectDateChange(e, 'month')}
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </Input>
          </Col>
          <Col lg="3">
            <Input
              type="select"
              id="years-selector"
              value={this.state.year}
              onChange={e => this.onSelectDateChange(e, 'year')}
            >
              {Array(new Date().getFullYear() - 2000 + 3)
                .fill('')
                .map((_, index) => (
                  <option value={2000 + index} key={index}>
                    {2000 + index}
                  </option>
                ))}
            </Input>
          </Col>
        </Row>
      </Col>
    ) : (
        <Col>
          <Row>
            <Col>BILLS</Col>
          </Row>
        </Col>
      );
  };

  onSelectDateChange = (e, item) => {
    const value = e.target.value;
    this.setState({
      [item]: value,
    });
  };

  toggleViewDocuments = () => {
    const { profile, loadDemandDocuments } = this.props;
    const claimObject = this.props.claim;
    loadDemandDocuments({
      profile,
      billId: claimObject.int_claimcode,
    });
    this.setState({ isShowDemandView: true })
  }

  closeListOfDoc = () => {
    this.setState({ isShowDemandView: false });
  }

  render() {
    const billsFromClaimSearch = !!this.props.claimControlHandler;
    const claimObject = this.props.claim || {};
    let billsData = [];
    let selectedBillsData = [];

    if (!this.props.hideControls) {
      const { bills, selectedBills } = this.props;
      billsData = bills.map(bill => ({ ...bill, id: bill.int_billid }));
      selectedBillsData = Array.from(selectedBills, bill => bill.int_billid);
    }

    return (
      <div id="billSearchHeaderContainer" style={{ marginBottom: '20px' }}>
        <Row>
          <Col lg="8">
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Patient:</span>{' '}
                {claimObject.chr_firstname || ''}{' '}
                {claimObject.chr_lastname || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Claim #:</span>{' '}
                {claimObject.chr_claimnumber || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>LOB:</span>{' '}
                {claimObject.chr_juris || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Client:</span>{' '}
                {this.getClient(
                  this.props.sites || [],
                  claimObject.int_siteID || ''
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Employer:</span>{' '}
                {claimObject.employer || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Date of Injury:</span>{' '}
                {claimObject.dat_injurydate || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Jurisdiction State:</span>{' '}
                {claimObject.chr_statejuris || ''}
              </Col>
              <Col>
                <span style={{ fontWeight: 'bold' }}>Adjuster:</span>{' '}
                {claimObject.adjname || ''}
              </Col>
            </Row>
          </Col>
          <div style={{ marginLeft: "30px" }}>
            <BillClaimNotesAction
              link=""
              notes={claimObject.notes || ''}
              iconOnly={true}
              isFromClaim="true"
              claimId={claimObject.int_claimcode}
            />
          </div>
          {!this.props.hideControls ? (
            <Col
              lg={
                billsFromClaimSearch
                  ? { size: '3' }
                  : { size: '1', offset: '2' }
              }
            >
              <Row>
                {billsFromClaimSearch && claimObject && claimObject.isAO === "true" ? (
                  <Button
                    color="primary"
                    size="md"
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px"
                    }}
                    onClick={() => this.toggleViewDocuments()}
                  >
                    VIEW DOCUMENTS
                  </Button>) : null} {' '}
                {this.state.isShowDemandView &&
                  <DemandButton
                    name="findViewDemandSetting"
                    isShowDemandButton={false}
                    isShowListOfDoc={true}
                    closeListOfDoc={this.closeListOfDoc}
                    selectedBills={selectedBillsData}
                    bills={billsData}
                    claimObject={claimObject}
                  />}
                {' '}
                <AppTooltip
                  text="Go To UR Decision Screen"
                  id="urDecisionTooltip"
                  placement="bottom"
                >
                  <Button
                    color="primary"
                    onClick={() => this.toggleURDecision(claimObject)}
                    disabled={R.isEmpty(claimObject)}
                  >
                    UR DECISION
                </Button>
                </AppTooltip>{' '}
                {billsFromClaimSearch ? (
                  <div>
                    <span>
                      <Button color="primary" onClick={this.props.calendarToggle}>
                        {this.props.showCalendar ? 'Grid View' : 'Calendar'}
                      </Button>
                    </span>{' '}
                    <span>
                      <Button color="primary" onClick={this.props.exit}>
                        Exit
                    </Button>
                    </span>
                  </div>
                ) : null}
              </Row>
            </Col>
          ) : (
              <Col lg={'3'}>
                <Button
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={this.props.exit}
                >
                  Exit
              </Button>
              </Col>
            )}
        </Row>
        <hr />
        {!this.props.hideControls ? (
          <Row className="flex-row-reverse align-items-center">
            <Col lg="6">
              <Row>
                <Col lg="4"></Col>
                <Col lg="4">
                  <ReferralButton
                    name="findRefSetting"
                    selectedBills={selectedBillsData}
                    bills={billsData}
                    claim={claimObject}
                    injuryId={claimObject.int_injurycode}
                    envRef={this.props.envRef}
                    fromTaskList ={this.props.fromTaskList}
                  />
                </Col>

                <Col lg="4">
                  <GridSettingsModal
                    name="findBillsSetting"
                    gridSettingName={findBillsGridSettingName}
                    gridSettingModuleId={findBillsGridSettingModuleId}
                    settingColumns={this.props.settingColumns}
                    updateCheckbox={this.props.updateGridSettingsCheckbox}
                  ></GridSettingsModal>
                </Col>
              </Row>
            </Col>
            <Col lg="3">
              <Input
                type="select"
                id="combinedDocs"
                value="default"
                onChange={this.props.onChangeHandler}
              >
                <option style={{ display: 'none' }} value="default">
                  Combined Docs
                </option>
                <option value="EORs">EORs</option>
                <option value="medicals">Medicals</option>
                <option value="EORsAndMedicals">EORs + Medicals</option>
                <option value="billOnly">Bill Only</option>
                <option value="notesOnly">Notes Only</option>
              </Input>
            </Col>
            {this.buildCalendarButtons()}
          </Row>
        ) : null}
      </div>
    );
  }
}

BillSearchHeader.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  claimControlHandler: PropTypes.bool,
  sites: PropTypes.array.isRequired,
  hideControls: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  exit: PropTypes.func,
  urDecisionToggle: PropTypes.func,
  settingColumns: PropTypes.array.isRequired,
  updateGridSettingsCheckbox: PropTypes.func.isRequired,
  calendarToggle: PropTypes.func,
  onDateChangeHandler: PropTypes.func,
  changeViewHandler: PropTypes.func,
  selectedBills: PropTypes.array.isRequired,
  userId: PropTypes.string,
  documents: PropTypes.array.isRequired,
  loadDeleteDemandDocuments: PropTypes.func,
  loadDemandDocuments: PropTypes.func,
  profile: PropTypes.string.isRequired,
};

BillSearchHeader.defaultProps = {
  sites: [],
  hideControls: false,
};

const mapStateToProps = state => {
  const gridSettings = billOperationsSelectors.getFindBillsGridSettingsState(
    state
  );
  const findBills = billOperationsSelectors.getFindBills(state);
  const user = authSelectors.getUser(state);
  const demandState = billOperationsSelectors.getDemandState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  return {
    settingColumns: gridSettings,
    selectedBills: findBills.selectedBills,
    userId: user && user.user_id,
    profile: initialData.currentProfile,
    documents: demandState.demandDoc,
  };
};

const mapDispatchToProps = dispatch => ({
  onURDecision: claim =>
    dispatch(billOperationsOperations.getURDecision(claim)),
  updateGridSettingsCheckbox: data =>
    dispatch(billOperationsActions.updateFindBillsGridSettingsCheckbox(data)),
  loadDemandDocuments: data =>
    dispatch(billOperationsOperations.loadDemandDocuments(data)),
  loadDeleteDemandDocuments: data => dispatch(billOperationsOperations.loadDeleteDemandDocuments(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillSearchHeader);
