import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { authActions, authSelectors } from '../store';

export function useAuth() {
  const dispatch = useDispatch();

  const login = (username, password, systemId) =>
    dispatch(authActions.login(username, password, systemId));

  const logout = (username, userId) => dispatch(authActions.logout(username, userId));

  const checkAuthCode = (username, authCode, systemId) => dispatch(authActions.checkAuthCode(username, authCode, systemId));

  const resendAuthCode = (username) => dispatch(authActions.resendAuthCode(username));

  const forgotPassword = (username, user_id) => dispatch(authActions.resetPassword(username, user_id));
  const updatePassword = (username, oldPassword, newPassword) => dispatch(authActions.changePassword(username, oldPassword, newPassword));
  const loadSecurityQuestions = (userId) => dispatch(authActions.loadSecurityQuestions(userId));
  const saveSecurityQuestions = (answer, questionId, userId) => dispatch(authActions.saveSecurityQuestions(answer, questionId, userId));
  const checkSecurityQuestions = (answer, questionId, userId) => dispatch(authActions.checkSecurityQuestions(answer, questionId, userId));
  const zebraTimeoutSeconds = () => dispatch(authActions.zebraTimeoutSeconds());
  const errorMessage = useSelector(authSelectors.getErrorMessage, R.equals);
  const loginResult = useSelector(authSelectors.getLoginResult, R.equals);
  const user = useSelector(authSelectors.getUser, R.equals);
  const username = useSelector(authSelectors.getUsername, R.equals);
  const passwordUpdateErrorMessage = useSelector(authSelectors.getPasswordUpdateErrorMessage, R.equals);
  const userId = useSelector(authSelectors.getUserId, R.equals);
  const currentQuestion = useSelector(authSelectors.getCurrentQuestion, R.equals);
  const currentQuestionId = useSelector(authSelectors.getCurrentQuestionId, R.equals);
  const securityQuestions = useSelector(authSelectors.getSecurityQuestions, R.equals);
  const resetPasswordResult = useSelector(authSelectors.getResetPasswordResult, R.equals);
  const securityQuestionResult = useSelector(authSelectors.getSecurityQuestionResult, R.equals);
  const logoutMessage = useSelector(authSelectors.getLogoutMessage, R.equals);
  const checkAuthCodeResult = useSelector(authSelectors.checkAuthCodeResult, R.equals);
  const isAuthCodeRequired = useSelector(authSelectors.isAuthCodeRequired, R.equals);
  return { login, logout, checkAuthCode, resendAuthCode, forgotPassword, updatePassword, loadSecurityQuestions, saveSecurityQuestions, checkSecurityQuestions, zebraTimeoutSeconds, errorMessage, loginResult, passwordUpdateErrorMessage, resetPasswordResult, user, username, userId, currentQuestionId, currentQuestion, securityQuestions, securityQuestionResult, logoutMessage, checkAuthCodeResult, isAuthCodeRequired };
}
