// NOTE: In order for the polyfills to works, these need to be on the first lines
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'core-js/stable';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import store from 'core/configureStore';
import ErrorPage from 'core/layout/ErrorPage';

import Login from 'modules/auth/Login';

import PrivateRoute from 'shared/components/PrivateRoute';

import ThemeProvider from 'common/ThemeContext';

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import './scss/custom.scss';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={store.getHistory()}>
      <ThemeProvider>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/error" component={ErrorPage} />
          <PrivateRoute path="/">
            <App />
          </PrivateRoute>
        </Switch>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
