import React, { useState } from 'react';
import { Form } from 'reactstrap';
import { connect } from 'react-redux';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import PopupModal from 'shared/components/PopupModal';
import { commonOperations } from 'common/store';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

const HCPCSearchModal = ({
  isOpen,
  onClose,
  formHCPCFields,
  userId,
  loadHCPC,
  lookupHcpcWithLimit,
  resetLoadHCPCWithLimit,
  hcpcListLimit,
  patientInfo,
  alert,
}) => {
  const [searchField, setSearchField] = useState({
    hcpcSearch: 'Description',
    searchText: '',
  });

  const toggle = () => {
    onClose();
    resetLoadHCPCWithLimit();
    setSearchField({ hcpcSearch: 'Description', searchText: '' });
  };

  const changeSearchHandler = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setSearchField({ ...searchField, [name]: value });
  };

  const onChangeSearchHandler = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setSearchField({ ...searchField, [name]: value });
  };

  const onFormSubmit = e => {
    e.preventDefault();
    if (searchField.searchText === '' || searchField.searchText === undefined) {
      alert('Please enter search text for HCPC list');
      return;
    }
    const hcpcSearchParams = {
      functionName: 'lookupHcpcWithLimit',
      userId: userId,
      lookupData: searchField.searchText,
      isDescription: searchField.hcpcSearch === 'Description' ? true : false,
      start: 0,
      limit: 1000,
    };
    lookupHcpcWithLimit(hcpcSearchParams);
  };

  const onRowClickHandler = item => {
    toggle();
    const hcpcParams = {
      functionName: 'loadHCPC',
      userId: userId,
      code: item.code,
      desc: item.hcpc,
      date: patientInfo.audZip,
      zipcode: patientInfo.zipPatient,
      mod1: formHCPCFields.mod1,
      mod2: formHCPCFields.mod2,
    };
    loadHCPC(hcpcParams);
    formHCPCFields.hcpcCode = item.code;
  };

  const getHCPCSearchTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={400}
          columns={[
            {
              header: 'HCPC Code',
              field: 'code',
            },
            {
              header: 'Description',
              field: 'hcpc',
            },
          ]}
          data={hcpcListLimit || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="hcpc-list-table"
        />
      </div>
    );
  };

  const confirmationModalContent = () => {
    return (
      <>
        <Form onSubmit={onFormSubmit}>
          <Row form>
            <Col sm="3">
              <Row>
                <Label>HCPC Quick Lookup</Label>
                <Input
                  type="select"
                  name="hcpcSearch"
                  onChange={changeSearchHandler}
                  value={searchField.hcpcSearch}
                >
                  <option value="Description">Description</option>
                  <option value="Code">Code</option>
                </Input>
              </Row>
            </Col>
            <Col xs="6" style={{ marginLeft: 25 }}>
              <Row>
                <Label>Search</Label>
                <Input
                  name="searchText"
                  type="text"
                  onChange={onChangeSearchHandler}
                  value={searchField.searchText || ''}
                />
              </Row>
            </Col>
            <Col style={{ marginTop: 25, marginLeft: 16 }}>
              <Button type="submit">FIND</Button>
            </Col>
          </Row>
          <div style={{ marginTop: 16 }}>{getHCPCSearchTable()}</div>
        </Form>
      </>
    );
  };

  return (
    <>
      <PopupModal
        content={confirmationModalContent()}
        title="Search on a Partical HCPC Code or Description"
        isOpen={isOpen}
        externalToggle={toggle}
        size="lg"
      />
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  return {
    hcpcList: fast360Info.hcpcList,
    hcpcListLimit: fast360Info.hcpcListLimit,
    patientInfo: fast360Info.patientZip,
    userId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  loadHCPC: data => dispatch(fast360Operations.loadHCPC(data)),
  lookupHcpcWithLimit: data =>
    dispatch(fast360Operations.lookupHcpcWithLimit(data)),
  resetLoadHCPCTable: () => dispatch(fast360Actions.resetLoadHCPCTable()),
  resetLoadHCPCWithLimit: () =>
    dispatch(fast360Actions.resetLoadHCPCWithLimit()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(HCPCSearchModal);
