import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { momentObj } from 'react-moment-proptypes';

import EventDetailTooltip from './EventDetailTooltip';

class Day extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      cursorPosition: {
        clientY: 0,
        clientX: 0,
      },
    };
  }

  onClick = date => {
    this.props.onClickEvent(date);
  };

  onHoverEvent = e => {
    this.setState({
      showTooltip: true,
      cursorPosition: {
        clientY: e.clientY,
        clientX: e.clientX,
      },
    });
  };

  onMouseOut = () => this.setState({ showTooltip: false });

  buildEventDetailTooltip = events =>
    this.state.showTooltip ? (
      <div>
        <EventDetailTooltip cursorPosition={this.state.cursorPosition}>
          {events}
        </EventDetailTooltip>
      </div>
    ) : (
      ''
    );

  render() {
    const { classes, day } = this.props;
    const events = this.props.eventsInDay.map((event, index) => (
      <div key={index}>
        <div>{index + 1 + ': ' + event.title}</div>
      </div>
    ));
    const eventDetailTooltip =
      events.length > 0 ? this.buildEventDetailTooltip(events) : '';
    return (
      <div
        onClick={() => this.onClick(day.toDate())}
        className={classes}
        onMouseOver={this.onHoverEvent}
        style={{
          backgroundColor: events.length > 0 ? '#' + events[0].color : '',
        }}
        onMouseOut={this.onMouseOut}
      >
        <span className="day-number">
          {day === null ? '' : `${day.date()} ${day.format('ddd')}`}
        </span>
        {eventDetailTooltip}
      </div>
    );
  }
}

Day.propTypes = {
  classes: PropTypes.string,
  eventsInDay: PropTypes.array,
  day: momentObj,
};

Day.defaultProps = {
  classes: '',
  eventsInDay: {},
  day: null,
};

export default Day;
