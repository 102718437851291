import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

import AppDateInput from 'shared/components/input/AppDateInput';
import AppTable from 'shared/components/table/AppTable';
import { sortByType } from 'shared/utils/shareUtils';

import { getElementFromId } from '../utils/expertInfoUtils';
import { PROSPECT, APPLICANT, DEACTIVATED } from '../utils/constantUtils';
import '../assets/expert-info.scss';

const RecordModalRecruitmentInfoTab = ({
  data,
  onInputChange,
  onCustomInputChange,
  expertStatuses,
  expertStatusProspect,
  expertStatusApplicant,
  recruitmentMethods,
  referralCodes,
  termReason,
  expertDocumentTypes,
}) => {
  const statusStepProvider = () => {
    switch (getElementFromId(expertStatuses, data.expertStatus)) {
      case PROSPECT:
        return [
          {
            id: -1,
            element: 'Choose...',
          },
          ...expertStatusProspect,
        ];
      case APPLICANT:
        return [
          {
            id: -1,
            element: 'Choose...',
          },
          ...expertStatusApplicant,
        ];
      default:
        return [
          {
            id: -1,
            element: 'N/A',
          },
        ];
    }
  };

  const handleExpertStatusChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    onCustomInputChange(name, value);
    onCustomInputChange('expertStatusStep', '-1');
  };

  const disableExpertStatusStep = (value = data.expertStatus) => {
    const status = getElementFromId(expertStatuses, value);
    return status !== 'Prospect' && status !== 'Applicant';
  };

  return (
    <Row>
      <Col lg="7">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="expertStatus">Recruitment Status</Label>
              <Input
                type="select"
                name="expertStatus"
                id="expertStatus"
                value={data.expertStatus}
                onChange={handleExpertStatusChange}
              >
                <option key={-1} value="" disabled>
                  Choose...
                </option>
                {expertStatuses.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="referredBy">Referred By</Label>
              <Input
                type="text"
                name="referredBy"
                id="referredBy"
                value={data.referredBy}
                onChange={onInputChange}
                maxLength="200"
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Label for="expertStatusStep">Step Progress</Label>
              <Input
                type="select"
                name="expertStatusStep"
                id="expertStatusStep"
                value={data.expertStatusStep}
                onChange={onInputChange}
                disabled={disableExpertStatusStep()}
              >
                {statusStepProvider().map((item, index) => (
                  <option key={index} value={item.id} disabled={item.id === -1}>
                    {item.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <AppDateInput
                id="recommendedOn"
                label="Recruitment"
                selected={data.recommendedOn}
                onChange={value => onCustomInputChange('recommendedOn', value)}
                showYearDropdown
                dropdownMode="select"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <AppDateInput
                id="startDate"
                label="Start Date"
                selected={data.startDate}
                onChange={value => onCustomInputChange('startDate', value)}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                dropdownMode="select"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <AppDateInput
                id="lastCredentialedOn"
                label="Last Credentialed"
                selected={data.lastCredentialedOn}
                onChange={value =>
                  onCustomInputChange('lastCredentialedOn', value)
                }
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                dropdownMode="select"
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="recruitmentMethod">Recruitment Method</Label>
              <Input
                type="select"
                name="recruitmentMethod"
                id="recruitmentMethod"
                value={data.recruitmentMethod}
                onChange={onInputChange}
              >
                <option key={-1} value="" disabled>
                  Choose...
                </option>
                {recruitmentMethods.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="referralCode">Referral Code</Label>
              <Input
                type="select"
                name="referralCode"
                id="referralCode"
                value={data.referralCode}
                onChange={onInputChange}
              >
                <option key={-1} value="-1" disabled>
                  Choose...
                </option>
                {referralCodes.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          {getElementFromId(expertStatuses, data.expertStatus) ===
            DEACTIVATED && (
            <>
              <Col lg="6">
                <FormGroup>
                  <Label for="terminationReason">Termination Reason</Label>
                  <Input
                    type="select"
                    name="terminationReason"
                    id="terminationReason"
                    value={data.terminationReason}
                    onChange={onInputChange}
                  >
                    <option key={-1} value="" disabled>
                      Choose...
                    </option>
                    {termReason.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.element}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <AppDateInput
                    id="terminationDate"
                    label="Termination Date"
                    selected={data.terminationDate}
                    onChange={value =>
                      onCustomInputChange('terminationDate', value)
                    }
                    dateFormat="MM/dd/yyyy"
                    showYearDropdown
                    dropdownMode="select"
                  />
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col lg="5" className="credentialling-document-table">
        <AppTable
          columns={[
            {
              header: 'Credentialling Documents',
              field: 'element',
              sortFunction: (item1, item2, sortOrder) =>
                sortByType(item1.id, item2.id, sortOrder),
            },
          ]}
          data={expertDocumentTypes.filter(item => item.childList === '372')}
          autoPagination={false}
          sortAble={true}
        />
      </Col>
    </Row>
  );
};

RecordModalRecruitmentInfoTab.propTypes = {
  data: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  expertStatusProspect: PropTypes.array.isRequired,
  expertStatusApplicant: PropTypes.array.isRequired,
  recruitmentMethods: PropTypes.array.isRequired,
  referralCodes: PropTypes.array.isRequired,
  termReason: PropTypes.array.isRequired,
  expertDocumentTypes: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCustomInputChange: PropTypes.func.isRequired,
};

export default RecordModalRecruitmentInfoTab;
