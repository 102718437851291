import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppTable from 'shared/components/table/AppTable';

import { expertsOperations, expertsSelectors, expertsActions } from '../store';

import {
  EXPERT_SPECIALTY_BY_STATE_ID,
  reportColumns,
} from '../utils/expertUtils';

import ExpertReportTop from './ExpertReportTop';

class ExpertReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportId: EXPERT_SPECIALTY_BY_STATE_ID,
    };
  }

  onReportTypeChange = id => {
    this.props.updateExpertReportTable([]);
    this.setState({ reportId: id });
  };

  render() {
    return (
      <div>
        <ExpertReportTop
          reportId={this.state.reportId}
          states={this.props.provCredStates}
          networks={this.props.networks}
          specialties={this.props.specialties}
          onReportTypeChange={this.onReportTypeChange}
          runExpertReport={this.props.runExpertReport}
        />
        <AppTable
          data={this.props.reportData}
          columns={reportColumns[this.state.reportId]}
          autoPagination={true}
          sortAble={true}
          key={this.state.reportId}
          resizable={true}
          sortImmediately={false}
        />
        <span>Total Records: {this.props.reportData.length}</span>
      </div>
    );
  }
}

ExpertReportContainer.propTypes = {
  provCredStates: PropTypes.array.isRequired,
  networks: PropTypes.array.isRequired,
  specialties: PropTypes.array.isRequired,
  reportData: PropTypes.array.isRequired,
  runExpertReport: PropTypes.func.isRequired,
  updateExpertReportTable: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialDataState = expertsSelectors.getInitialData(state);
  const report = expertsSelectors.getReport(state);
  return {
    provCredStates: initialDataState.provCredStates,
    networks: initialDataState.expertNetworks,
    specialties: initialDataState.reportsSpecialties,
    reportData: report.data,
  };
};

const mapDispatchToProps = dispatch => ({
  runExpertReport: data => dispatch(expertsOperations.runExpertReport(data)),
  updateExpertReportTable: data =>
    dispatch(expertsActions.updateExpertReportTable(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertReportContainer);
