import React, { useState, useContext, useEffect } from 'react';
import AppDateInput from 'shared/components/input/AppDateInput';
import '../../assets/static/AdvanceSearch.scss'
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { authSelectors } from '../../../auth/store';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';
import { UpdateTypes, selectAll, selectAllSubCategory, selectAllMseCategory } from './utils/helper';
import { fast360Selectors, fast360Operations, fast360Actions } from '../../store/index';
import Accommodation from './Accommodation';
import Interpretation from './Interpretation';
import Services from './Services';
import Transportation from './Transportation';
import HHC from './HHC';
import classnames from 'classnames';
import { element } from 'prop-types';
import actions from '../../store/actions';
import ExcelExport from './ExcelExport';
import PopupModal from 'shared/components/PopupModal';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const dropDown = [
  { label: "All", data: "null" },
  { label: "No", data: 0 },
  { label: "Yes", data: 1 }
]

function updateAdvSearchForm(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_AdvSearch_Form',
    payload: { [key]: value },
  });
}

function updateCheckBox(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_CheckBox',
    payload: { [key]: value },
  });
}

function updateServiceTabs(value, dispatch) {
  dispatch({
    type: 'UPDATE_SERVICE',
    payload: value,
  });
}

function searchContextReset(dispatch, dispatch1, resetAdvancedSearchReport, allStates, serviceStatus, diagnosticType, mseCategory, modificationCategory, accommodationType, transportCategory, specialTransportCategory, translationCategory, hhcCategory) {
  dispatch({
    type: 'SEARCH_CONTEXT_RESET'
  })
  dispatch1({
    type: 'CHECKBOX_CONTEXT_RESET'
  })
  resetAdvancedSearchReport()
  selectAll(true, allStates, dispatch, 'states')
  selectAll(true, serviceStatus, dispatch, 'orderStatusIds')
  selectAll(true, accommodationType, dispatch, 'accommodationTypes')
  selectAll(true, translationCategory, dispatch, 'interpretationTypes')
  selectAll(true, hhcCategory, dispatch, 'hhcTypes')
  selectAllSubCategory(true, diagnosticType, dispatch, 'serviceTypes', 'diagnostics')
  selectAllMseCategory(true, mseCategory, dispatch, 'serviceTypes', 'mse')
  selectAllSubCategory(true, modificationCategory, dispatch, 'serviceTypes', 'modification')
  selectAllSubCategory(true, transportCategory, dispatch, 'transportTypes', 'transport')
  selectAllSubCategory(true, specialTransportCategory, dispatch, 'transportTypes', 'specialTransport')
}


const AdvSearch = ({ userId, getAdvSearchData, loadLookupReport, getLoadHCPCQuick, getClientLookup, allStates, serviceStatus, diagnosticType, mseCategory, modificationCategory, accommodationType, transportCategory, specialTransportCategory, hhcCategory, appointmentType, language, loadHCPCLookUp, loadClientLookUp, translationCategory, searchResponse, loadLookupReportAction, resetAdvancedSearchReport, loadFromWorkList, setFromAdvRef, setIsAdvSearch, loadExistingTasks, groupTasksProgression, mergeFilterDataAction, mergeData, setFast360Data, setAdvValues, loadReferralAlerts, openReferral, updateState, updateState1, storedState, storedState1, updateData, advSearchData, useStoredData, setUseStoredData }) => {
  const { state, dispatch } = useContext(AdvSearchContext);
  const { state1, dispatch1 } = useContext(CheckBoxContext);
  const { states, orderStatusIds } = state;

  const [showAbbrPopup, setShowAbbrPopup] = useState(false);

  const [queryStr, setQueryStr] = useState("");

  useEffect(() => {
    if (userId) {
      getAdvSearchData({ userId });
    }
  }, [userId])

  useEffect(() => {
    if(!useStoredData){
      selectAll(true, allStates, dispatch, 'states');
    }
  }, [allStates])
  useEffect(() => {
    if(!useStoredData){
      selectAll(true, serviceStatus, dispatch, 'orderStatusIds');
    }
  }, [serviceStatus])
  useEffect(() => {
    if (mergeData.length > 0) {
      setData(mergeData)
    } else {
      setData(searchResponse);
    }
  }, [searchResponse, mergeData])

  const [activeTab, setActiveTab] = useState('1');
  const [transportButton, setTransportButton] = useState('');
  const [audiologyButton, setAudiologyButton] = useState('');
  const [mergedCheck, setMergeCheck] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if(advSearchData?.activeTab) {
      setActiveTab(advSearchData.activeTab);
    }
    if(advSearchData?.transportButton) {
      setTransportButton(advSearchData.transportButton);
    }
    if(advSearchData?.audiologyButton) {
      setAudiologyButton(advSearchData.audiologyButton);
    }
    if(advSearchData?.mergedCheck) {
      setMergeCheck(advSearchData.mergedCheck);
    }
    if(advSearchData?.data) {
      setData(advSearchData.data);
    }
  }, [])


  useEffect(() => updateData({...advSearchData, "activeTab": activeTab}), [activeTab]);
  useEffect(() => updateData({...advSearchData, "transportButton": transportButton}), [transportButton])
  useEffect(() => updateData({...advSearchData, "audiologyButton": audiologyButton}), [audiologyButton])
  useEffect(() => updateData({...advSearchData, "mergedCheck": mergedCheck}), [mergedCheck])
  useEffect(() => updateData({...advSearchData, "data": data}), [data])

  useEffect(() => updateState(state), [state])
  useEffect(() => updateState1(state1), [state1])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDblClick = (item) => {
    const { PK_referral_id, chr_reference_table, int_reference_table_id, chr_claim_no, servType } = item;
    let postPayload = {
      "functionName": "loadReferral",
      "zebraUserId": userId,
      "referral_id": PK_referral_id
    };
    let alertPayload = {
      "functionName": "loadReferralAlerts",
      "zebraUserId": userId,
      "referralId": PK_referral_id,
      "tableAbbrv": chr_reference_table,
      "serviceId": int_reference_table_id,
    };

    //loadFromWorkList(postPayload);
    //loadExistingTasks(taskPayload);
    setFromAdvRef(true);
    setIsAdvSearch(false);
    setAdvValues(item.ConfirmNumber);
    openReferral(PK_referral_id,
      chr_reference_table,
      int_reference_table_id,
      0,
      chr_claim_no,
      chr_reference_table,
      false);
    //groupTasksProgression(groupProgressPayload);
    loadReferralAlerts(alertPayload);
    setUseStoredData(true);
  }

  function mergeFilter(arr, index, mergedCheck) {
    if (!mergedCheck) {

      const merged = arr
        .map(e => e[index])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);

      mergeFilterDataAction(merged);
    } else {
      mergeFilterDataAction([]);
    }
    setMergeCheck(!mergedCheck);

  }

  const validateFields = () => {
    
      let reqData = {...state};
      if(reqData.dosStartDate) {
        reqData.dosStartDate = moment(reqData.dosStartDate).format('YYYY-MM-DD');
      }
      if(reqData.dosEndDate) {
        reqData.dosEndDate = moment(reqData.dosEndDate).format('YYYY-MM-DD');
      }
      if(reqData.orderStartDate) {
        reqData.orderStartDate = moment(reqData.orderStartDate).format('YYYY-MM-DD');
      }
      if(reqData.orderEndDate) {
        reqData.orderEndDate = moment(reqData.orderEndDate).format('YYYY-MM-DD');
      }
      if(reqData.completionStartDate) {
        reqData.completionStartDate = moment(reqData.completionStartDate).format('YYYY-MM-DD');
      }
      if(reqData.completionEndDate) {
        reqData.completionEndDate = moment(reqData.completionEndDate).format('YYYY-MM-DD');
      }
      if(reqData.accommodationCheckInDate) {
        reqData.accommodationCheckInDate = moment(reqData.accommodationCheckInDate).format('YYYY-MM-DD');
      }
      if(reqData.accommodationCheckOutDate) {
        reqData.accommodationCheckOutDate = moment(reqData.accommodationCheckOutDate).format('YYYY-MM-DD');
      }
      if(reqData.accommodationDateTimeCreated) {
        reqData.accommodationDateTimeCreated = moment(reqData.accommodationDateTimeCreated).format('YYYY-MM-DD');
      }
      if(reqData.accommodationDateTimeBooked) {
        reqData.accommodationDateTimeBooked = moment(reqData.accommodationDateTimeBooked).format('YYYY-MM-DD');
      }
      if(reqData.serviceRentalStartDate) {
        reqData.serviceRentalStartDate = moment(reqData.serviceRentalStartDate).format('YYYY-MM-DD');
      }
      if(reqData.serviceRentalEndDate) {
        reqData.serviceRentalEndDate = moment(reqData.serviceRentalEndDate).format('YYYY-MM-DD');
      }
      if(reqData.serviceRentalStartDate) {
        reqData.serviceRentalStartDate = moment(reqData.serviceRentalStartDate).format('YYYY-MM-DD');
      }
      if(reqData.serviceRentalEndDate) {
        reqData.serviceRentalEndDate = moment(reqData.serviceRentalEndDate).format('YYYY-MM-DD');
      }

      loadLookupReport(reqData, userId, mergedCheck, queryStr);
   
  }


  return (
    <FormGroup>
      <div style={{ width: '60%', float: 'left', padding: '20px' }}>
        <Row xs="1" md="4">
          <Col>
            <Label>Claimant Name:</Label>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                value={state.claimantFirst}
                style={{ width: '45%', marginRight: '5px' }}
                id="claimantFirstName"
                name="claimantFirstname"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'claimantFirst', dispatch)}
              />
              <Input
                value={state.claimantLast}
                style={{ width: '45%' }}
                id="claimantLastname"
                name="claimantLastName"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'claimantLast', dispatch)}
              />
            </span>
          </Col>
          <Col>
            <Label>Confirmation #:</Label>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                value={state.referralId}
                style={{ width: '45%' }}
                id="referralId"
                name="referralId"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'referralId', dispatch)}
              />-
              <Input
                value={state.serviceType}
                style={{ width: '45%' }}
                id="serviceType"
                name="serviceType"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'serviceType', dispatch)}
              />-
              <Input
                value={state.serviceId}
                style={{ width: '45%' }}
                id="serviceId"
                name="serviceId"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'serviceId', dispatch)}
              />
            </span>
          </Col>
          <Col>
            <Label>Referral Co:</Label>
            <Input
              value={state.referralCompany}
              id="referralCo"
              name="referralCo"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'referralCompany', dispatch)}
            />
          </Col>
          <Col>
            <Label>York Client:</Label>
            <Input
              value={state.yorkClientName}
              id="yorkClient"
              name="yorkClient"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'yorkClientName', dispatch)}
            />
          </Col>
        </Row>
        <Row xs="1" md="4">
          <Col>
            <Label>Vendor Name:</Label>
            <Input
              value={state.vendorName}
              id="vendorName"
              name="vendorName"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'vendorName', dispatch)}
            />
          </Col>
          <Col>
            <Label>Claim #:</Label>
            <Input
              value={state.claimNo}
              id="claimNo"
              name="claimNo"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'claimNo', dispatch)}
            />
          </Col>
          <Col>
            <Label>Case Manager Name:</Label>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                style={{ width: '45%', marginRight: '5px' }}
                value={state.ncmName}
                id="caseManagerFirstName"
                name="caseManagerFirstName"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'ncmName', dispatch)}
              />
              <Input
                style={{ width: '45%' }}
                value={state.ncmLastName}
                id="caseManagerLastName"
                name="caseManagerLastName"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'ncmLastName', dispatch)}
              />
            </span>
          </Col>
          <Col>
            <Label>York Employer:</Label>
            <Input
              value={state.yorkEmployerName}
              id="yorkEmployer"
              name="yorkEmployer"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'yorkEmployerName', dispatch)}
            />
          </Col>
        </Row>
        <Row xs="1" md="4">
          <Col>
            <Label>Client Name:</Label>
            <AsyncTypeahead
              filterBy={["PK_client_id"]}
              placeholder="Search"
              id="clientName"
              isLoading={false}
              labelKey={option => `${option.chr_companyname}`}
              minLength={3}
              name="clientName"
              defaultInputValue={state?.clientName}
              onSearch={(query) => getClientLookup(query, userId)}
              options={loadClientLookUp}
              renderMenuItemChildren={(option) => (
                <div>
                  <div><h6>{option.chr_companyname}</h6></div>
                  <div>{option.chr_street1}</div>
                  <div>{option.chr_city} {option.chr_state}, {option.chr_zipcode}</div>
                </div>
              )}
              onChange={
                (e) => {
                  updateAdvSearchForm(e.length > 0 ? e[0].chr_companyname : "", "clientName", dispatch);
                  updateAdvSearchForm(e.length > 0 ? e[0].PK_client_id : "", "clientId", dispatch);
                }
              }
            />
          </Col>
          <Col>
            <Label>PO #:</Label>
            <Input
              value={state.poNumber}
              id="PONumber"
              name="PONumber"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'poNumber', dispatch)}
            />
          </Col>
          <Col>
            <Label>Vendor Paid By:</Label>
            <br />
            <Input
              value={state.vendorPaidBy}
              id="vendorPaidBy"
              name="vendorPaidBy"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'vendorPaidBy', dispatch)}
            />
          </Col>
          <Col>
            <Label>Program Name:</Label>
            <Input
              value={state.yorkProgramName}
              id="yorkProgramName"
              name="yorkProgramName"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'yorkProgramName', dispatch)}
            />
          </Col>
        </Row>
        <Row xs="1" md="4">
          <Col>
            <Label>Adjuster Name:</Label>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                style={{ width: '45%', marginRight: '5px' }}
                value={state.adjusterName}
                id="adjusterName"
                name="adjusterName"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'adjusterName', dispatch)}
              />
              <Input
                style={{ width: '45%' }}
                value={state.adjusterLastName}
                id="adjusterLastName"
                name="adjusterLastName"
                onChange={(e) => updateAdvSearchForm(e.target.value, 'adjusterLastName', dispatch)}
              />
            </span>
          </Col>
          <Col>
            <Label>Event ID:</Label>
            <Input
              value={state.eventId}
              id="eventID"
              name="eventID"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'eventId', dispatch)}
            />
          </Col>
          <Col>
            <Label>Claim ID:</Label>
            <Input
              value={state.claimId}
              id="claimID"
              name="claimID"
              onChange={(e) => updateAdvSearchForm(e.target.value, 'claimId', dispatch)}
            />
          </Col>
        </Row>
      </div>
      <Row xs="1" md="2" style={{ paddingLeft: '20px' }}>
        <FormGroup>
          <Label>States:</Label>
          <FormGroup check>
            <Label check>
              <Input
                checked={allStates && (allStates.length === states.length)}
                type="checkbox"
                onClick={(e) => selectAll(e.target.checked, allStates, dispatch, 'states')}
              /> Select All
            </Label>
          </FormGroup>
          <FormGroup check></FormGroup>
          <Col
            style={{
              border: '1px solid black',
              height: '250px',
              width: '200px',
              padding: '10px',
              overflowY: 'scroll',
            }}
          >
            {allStates && allStates.map(element => {
              return (
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={states.includes(element.value)}
                      type="checkbox"
                      onClick={(e) => UpdateTypes(e.target.checked, element.value, states, dispatch, 'states')}
                    />
                    {element.value}
                  </Label>
                </FormGroup>
              );
            })}
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>Order Status:</Label>
          <FormGroup check>
            <Label check>
              <Input
                checked={serviceStatus && (serviceStatus.length === orderStatusIds.length)}
                type="checkbox"
                onClick={(e) => selectAll(e.target.checked, serviceStatus, dispatch, 'orderStatusIds')}
              /> Select All
            </Label>
          </FormGroup>
          <FormGroup check></FormGroup>
          <Col
            style={{
              border: '1px solid black',
              height: '250px',
              width: '200px',
              padding: '10px',
              overflowY: 'scroll',
            }}
          >
            {serviceStatus && serviceStatus.map(element => {
              return (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={orderStatusIds.includes(element.status_id)}
                      onClick={(e) => UpdateTypes(e.target.checked, element.status_id, orderStatusIds, dispatch, 'orderStatusIds')}
                    />
                    {element.value}
                  </Label>
                </FormGroup>
              );
            })}

          </Col>
        </FormGroup>
      </Row>

      <Row style={{ clear: 'both', padding: '20px' }}>
        <Label for="pgo">PGO: </Label>
        <Col md="2">
          <Input
            type="select"
            name="pgo"
            id="pgo"
            value={state.pgo}
            onChange={(e) => {
              updateAdvSearchForm(e.target.value !== "null" ? e.target.value : Number(e.target.value), 'pgo', dispatch)
            }
            }
          >
            {dropDown.map((value, idx) => {
              return <option key={idx} value={value.data}>{value.label}</option>
            })}
          </Input>
        </Col>
        <Label for="conversion">Conversion: </Label>
        <Col md="2">
          <Input
            type="select"
            name="conversion"
            id="conversion"
            value={state.conv}
            onChange={(e) => updateAdvSearchForm(e.target.value !== "null" ? e.target.value : Number(e.target.value), 'conv', dispatch)}
          >
            {dropDown.map((value, idx) => {
              return <option key={idx} value={value.data}>{value.label}</option>
            })}
          </Input>
        </Col>
        <Label for="catastrophe">Catastrophe: </Label>
        <Col md="2">
          <Input
            type="select"
            name="catastrophe"
            id="catastrophe"
            value={state.york}
            onChange={(e) => updateAdvSearchForm(e.target.value !== "null" ? e.target.value : Number(e.target.value), 'york', dispatch)}
          >
            {dropDown.map((value, idx) => {
              return <option key={idx} value={value.data}>{value.label}</option>
            })}
          </Input>
        </Col>
        <Label for="billed" style={{ paddingRight: '20px' }}>
          {' '}
          Billed{' '}
        </Label>
        <Col md="2">
          <Input
            type="checkbox"
            name="billed"
            id="billed"
            checked={state.billed}
            onClick={(e) => updateAdvSearchForm(e.target.checked, 'billed', dispatch)}
          />
        </Col>
      </Row>
      <Row style={{ padding: '20px' }}>
        <Label for="readyForBilling" style={{ paddingRight: '20px' }}>
          {' '}
          Ready For Billing{' '}
        </Label>
        <Col md="2">
          <Input
            type="checkbox"
            name="readyForBilling"
            id="readyForBilling"
            checked={state.readyForGP}
            onClick={(e) => updateAdvSearchForm(e.target.checked, 'readyForGP', dispatch)}
          />
        </Col>
      </Row>
      <Row xs="1" md="6" style={{ padding: '20px' }}>
        <Col>
          <Label for="exampleDate">DOS Begin:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'dosStartDate', dispatch)}
            selected={state.dosStartDate && new Date(state.dosStartDate)}
            value={state.dosStartDate ? new Date(state.dosStartDate) : ''}
            dateFormat="MM/dd/yyyy"         />
        </Col>
        <Col>
          <Label for="exampleDate">DOS End:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'dosEndDate', dispatch)}
            selected={state.dosEndDate}
            value={state?.dosEndDate || ''}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col>
          <Label for="exampleDate">Order Date Start:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'orderStartDate', dispatch)}
            selected={state.orderStartDate}
            value={state?.orderStartDate || ''}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col>
          <Label for="exampleDate">Order Date End:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'orderEndDate', dispatch)}
            selected={state.orderEndDate}
            value={state?.orderEndDate || ''}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col>
          <Label for="exampleDate">Completion Date Start:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'completionStartDate', dispatch)}
            selected={state.completionStartDate}
            value={state?.completionStartDate || ''}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
        <Col>
          <Label for="exampleDate">Completion Date End:</Label>
          <AppDateInput
            onChange={value => updateAdvSearchForm(value, 'completionEndDate', dispatch)}
            selected={state.completionEndDate}
            value={state?.completionEndDate || ''}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
      </Row>
      <Row style={{ padding: '20px' }}>
        <Col>Select the fields to display on the report</Col>
      </Row>
      <Row xs="2" md="6" style={{ paddingLeft: '40px' }}>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayAdjuster}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayAdjuster', dispatch1)}
            /> Adjuster Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayAttorneyName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayAttorneyName', dispatch1)}
            /> Attorney Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayBilledStatus}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayBilledStatus', dispatch1)}
            /> Billed Status
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayBilledDate}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayBilledDate', dispatch1)}
            /> Billed Date
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayCaseManager}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayCaseManager', dispatch1)}
            /> Case Manager Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayCity}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayCity', dispatch1)}
            /> City
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayClaimant}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayClaimant', dispatch1)}
            /> Claimant Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayCompletedDate}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayCompletedDate', dispatch1)}
            /> Completed Date
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayDayBeforePTConf}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayDayBeforePTConf', dispatch1)}
            /> Day Before PT Conf
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayDayBeforeFacilityConf}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayDayBeforeFacilityConf', dispatch1)}
            /> Day Before Facility Conf
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayDayBeforeProviderConf}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayDayBeforeProviderConf', dispatch1)}
            /> Day Before Provider Conf
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayDOS}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayDOS', dispatch1)}
            /> DOS
          </Label>
        </Col>
      </Row>
      <Row xs="2" md="6" style={{ paddingLeft: '40px' }}>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayEnteredBy}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayEnteredBy', dispatch1)}
            /> Entered By
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayEventId}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayEventId', dispatch1)}
            /> Event ID
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayFaxConfirmation}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayFaxConfirmation', dispatch1)}
            /> Fax Confirmation
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayLastModifiedBy}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayLastModifiedBy', dispatch1)}
            /> Last Modified By
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayLastModifiedDate}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayLastModifiedDate', dispatch1)}
            /> Last Modified Date
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayOrderDate}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayOrderDate', dispatch1)}
            /> Order Date
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayPayer}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayPayer', dispatch1)}
            /> Payer Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayPhysicianName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayPhysicianName', dispatch1)}
            /> Physician Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayPricing}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayPricing', dispatch1)}
            /> Pricing
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayProductType}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayProductType', dispatch1)}
            /> Product type
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayReferrerName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayReferrerName', dispatch1)}
            /> Referrer Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayUSState}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayUSState', dispatch1)}
            /> State
          </Label>
        </Col>
      </Row>
      <Row xs="2" md="6" style={{ paddingLeft: '40px' }}>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayType}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayType', dispatch1)}
            /> Type
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayVendorId}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayVendorId', dispatch1)}
            /> Vendor ID
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayVendorName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayVendorName', dispatch1)}
            /> Vendor Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayVendorCityState}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayVendorCityState', dispatch1)}
            /> Vendor City/State
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayVendorExtra}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayVendorExtra', dispatch1)}
            /> Extra vendor Fields
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayPONumber}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayPONumber', dispatch1)}
            /> PO Number
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayClientId}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayClientId', dispatch1)}
            /> Client ID
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayClientName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayClientName', dispatch1)}
            /> Client Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayOutcome}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayOutcome', dispatch1)}
            /> Outcome
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayReadyForBilling}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayReadyForBilling', dispatch1)}
            /> Ready For Billing
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayCurrentlyAssignedTo}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayCurrentlyAssignedTo', dispatch1)}
            /> Currently Assigned To
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayYorkClientName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayYorkClientName', dispatch1)}
            /> York Client Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayYorkEmployerName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayYorkEmployerName', dispatch1)}
            /> York Employer Name
          </Label>
        </Col>
        <Col>
          <Label check>
            <Input
              type="checkbox"
              checked={state1.displayProgramName}
              onClick={(e) => updateCheckBox(e.target.checked, 'displayProgramName', dispatch1)}
            /> Program Name
          </Label>
        </Col>
      </Row>
      <div style={{ padding: '20px' }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1'); updateServiceTabs('ALL', dispatch)
              }}
            >
              Services
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2'); updateServiceTabs('ACC', dispatch);
              }}
            >
              Accommodation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3'); updateServiceTabs('ALL', dispatch);
              }}
            >
              Transportation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4'); updateServiceTabs('INT', dispatch);
              }}
            >
              Interpretation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggle('5'); updateServiceTabs('HHC', dispatch);
              }}
            >
              HHC
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ padding: '20px' }}>
          <Services diagnosticType={diagnosticType} mseCategory={mseCategory} modificationCategory={modificationCategory} getLoadHCPCQuick={getLoadHCPCQuick} userId={userId} loadHCPCLookUp={loadHCPCLookUp} loadLookupReportAction={loadLookupReportAction} audiologyButton={audiologyButton} setAudiologyButton={setAudiologyButton} setQueryStr={setQueryStr} useStoredData={useStoredData} />
          <Accommodation accommodationType={accommodationType} appointmentType={appointmentType} useStoredData={useStoredData} />
          <Transportation transportCategory={transportCategory} specialTransportCategory={specialTransportCategory} appointmentType={appointmentType} loadLookupReportAction={loadLookupReportAction} transportButton={transportButton} setTransportButton={setTransportButton} useStoredData={useStoredData} />
          <Interpretation language={language} translationCategory={translationCategory} appointmentType={appointmentType} useStoredData={useStoredData} />
          <HHC hhcCategory={hhcCategory} getLoadHCPCQuick={getLoadHCPCQuick} setQueryStr={setQueryStr} userId={userId} loadHCPCLookUp={loadHCPCLookUp} useStoredData={useStoredData} />
        </TabContent>
      </div>
      <div className={searchResponse && searchResponse.length > 0 ? 'recordTable' : 'recordEmptyTable'}>
        <table className="table">
          <thead>
            <tr>
              <th>Conf No</th>
              {state1.displayAdjuster && <th>Adjuster Name</th>}
              {state1.displayAttorneyName && <th>Attorney Name</th>}
              {state1.displayBilledStatus && <th>Billed Status</th>}
              {state1.displayBilledDate && <th>Billed Date</th>}
              {state1.displayCaseManager && <th>Case Manager Name</th>}
              {state1.displayCity && <th>City</th>}
              {state1.displayClaimant && <th>Claimant Name</th>}
              {state1.displayCompletedDate && <th>Completed Date</th>}
              {state1.displayDayBeforePTConf && <th>Day Before PT Conf</th>}
              {state1.displayDayBeforeFacilityConf && <th>Day Before Facility Conf</th>}
              {state1.displayDayBeforeProviderConf && <th>Day Before Provider Conf</th>}
              {state1.displayDOS && <th>DOS</th>}
              {state1.displayEnteredBy && <th>Entered By</th>}
              {state1.displayEventId && <th>Event ID</th>}
              {state1.displayFaxConfirmation && <th>Fax Confirmation </th>}
              {state1.displayLastModifiedBy && <th>Last Modified By</th>}
              {state1.displayLastModifiedDate && <th>Last Modified Date</th>}
              {state1.displayOrderDate && <th>Order Date</th>}
              {state1.displayPayer && <th>Payer Name</th>}
              {state1.displayPhysicianName && <th>Physician Name</th>}
              {state1.displayPricing && <><th>Total Cost</th><th>Total Billed Charge</th><th>Order Margin</th></>}
              {state1.displayProductType && <th>Product Type</th>}
              {state1.displayReferrerName && <th>Referrer Name</th>}
              {state1.displayUSState && <th>State</th>}
              {state1.displayType && <th>Type</th>}
              {state1.displayVendorId && <th>Vendor ID</th>}
              {state1.displayVendorName && <th>Vendor Name</th>}
              {state1.displayVendorCityState && <th>Vendor City/State</th>}
              {state1.displayVendorExtra && <><th>Do Business As</th><th>Vendor GP Code</th><th>Vendor TIN</th><th>Vendor Type</th><th>Vendor Active</th><th>Vendor Contracted</th></>}
              {state1.displayPONumber && <th>PO Number</th>}
              {state1.displayClientId && <th>Client ID</th>}
              {state1.displayClientName && <th>Client Name</th>}
              {state1.displayOutcome && <th>Outcome</th>}
              {state1.displayReadyForBilling && <th>Ready For GP</th>}
              {state1.displayCurrentlyAssignedTo && <th>Currently Assigned To</th>}
              {state1.displayYorkClientName && <th>York Client Name</th>}
              {state1.displayYorkEmployerName && <th>York Employer Name</th>}
              {state1.displayProgramName && <th>Program Name</th>}
              {state1.displayServiceHCPC && <th>HCPC</th>}
              {state1.displayServiceLocation && <th>Service Location</th>}
              {state1.displayServiceRentals && <th>Rentals</th>}
              {state1.displayServiceRentalStartDate && <th>Rental Start Date</th>}
              {state1.displayServiceRentalEndDate && <th>Rental End Date</th>}
              {state1.displayServiceRentalFreq && <th>Rental Frequency</th>}
              {state1.displayServiceRenewal && <th>Renewal</th>}
              {state1.displayAccommodationCheckInDateTime && <th>Check In Date/Time</th>}
              {state1.displayAccommodationCheckOutDateTime && <th>Check Out Date/Time</th>}
              {state1.displayAccommodationNumberOfNights && <th>Number Of Nights</th>}
              {state1.displayAccommodationHotelAddress && <th>Hotel Address</th>}
              {state1.displayAccommodationNumberOfRooms && <th>Number of Rooms</th>}
              {state1.displayAccommodationDateTimeCreated && <th>Date/Time Created</th>}
              {state1.displayAccommodationDateTimeBooked && <th>Date/Time Booked</th>}
              {state1.displayAccommodationBookingSite && <th>Booking Site</th>}
              {state1.displayAccommodationHotelContact && <th>Hotel Contact</th>}
              {state1.displayTransportPickUpAddress && <th>Pick Up Address</th>}
              {state1.displayTransportDropOffAddress && <th>Drop Off Address </th>}
              {state1.displayTransportPickUpTime && <th>Pick Up Time</th>}
              {state1.displayTransportDropOffTime && <th>Drop Off Time</th>}
              {state1.displayTransportAppointmentType && <th>Appointment Type</th>}
              {state1.displayTransportMileage && <th>Mileage</th>}
              {state1.displayTransportStaffedBy && <th>Staffed By</th>}
              {state1.displayTransportTimeStaffed && <th>Time Staffed</th>}
              {state1.displayTransportNumberOfLegs && <th>Number Of Legs</th>}
              {state1.displayTransportReturnType && <th>Return Type</th>}
              {state1.displayTransportWaitingOnVendorConf && <th>Waiting On Vendor Conf</th>}
              {state1.displayTransportationPickUpAddress && <th>Pick Up Address</th>}
              {state1.displayTransportationDropOffAddress && <th>Drop Off Address</th>}
              {state1.displayTransportationPickUpTime && <th>Pick Up Time</th>}
              {state1.displayTransportationDropOffTime && <th>Drop Off Time</th>}
              {state1.displayTransportationMileage && <th>Mileage</th>}
              {state1.displayTransportationStaffedBy && <th>Staffed By</th>}
              {state1.displayTransportationTimeStaffed && <th>Time Staffed</th>}
              {state1.displayTransportationNumberOfLegs && <th>Number Of Legs</th>}
              {state1.displayTransportationReturnType && <th>Return Type</th>}
              {state1.displayTransportationWaitingOnVendorConf && <th>Waiting on Vendor Conf</th>}
              {state1.displayTransportationDateTimeBooked && <th>Date/Time Booked</th>}
              {state1.displaySplTransportationDropOffAddress && <th>Drop Off Address</th>}
              {state1.displaySplTransportationAppointmentType && <th>Appointment Type</th>}
              {state1.displaySplTransportationDepartingDateTime && <th>Departing Date/Time</th>}
              {state1.displaySplTransportationArrivingDateTime && <th>Arriving Date Time</th>}
              {state1.displaySplTransportationDateTimeCreated && <th>Date Time Created</th>}
              {state1.displaySplTransportationDateTimeBooked && <th>Date Time Booked</th>}
              {state1.displaySplTransportationAirline && <th>Airline</th>}
              {state1.displaySplTransportationDepartingAirport && <th>Departing Airport</th>}
              {state1.displaySplTransportationArrivingAirport && <th>Arriving Airport</th>}
              {state1.displaySplTransportationFlightClass && <th>Flight Class</th>}
              {state1.displaySplTransportationBookingSite && <th>Booking Site</th>}
              {state1.displaySplTransportationLayOverTime && <th>LayOver Time</th>}
              {state1.displayInterpretationAddress && <th>Interpretation Address</th>}
              {state1.displayInterpretationTime && <th>Duration Time</th>}
              {state1.displayInterpretationStartTime && <th>Start Time</th>}
              {state1.displayInterpretationLanguage && <th>Language</th>}
              {state1.displayInterpretationMileage && <th>Mileage</th>}
              {state1.displayInterpretationTimeStaffed && <th>Time Staffed</th>}
              {state1.displayInterpretationStaffedBy && <th>Staffed By</th>}
              {state1.displayInterpretationArrivalTime && <th>Arrival Time</th>}
              {state1.displayInterpretationAppointmentType && <th>AppointmentType</th>}
              {state1.displayHHCHCPC && <th>HCPC</th>}
              {state1.displayHHCServiceLocation && <th>Service Location</th>}
            </tr>
          </thead>
          <tbody>
            {data && data.map((ele) => {
              return (
                <tr onDoubleClick={() => handleDblClick(ele)}>
                  <td>{ele.ConfirmNumber && ele.ConfirmNumber}</td>
                  {state1.displayAdjuster && <td>{(ele.adjusterLastName && ele.adjusterLastName) + "," + (ele.adjusterFirstName && ele.adjusterFirstName)}</td>}
                  {state1.displayAttorneyName && <td>{element.ATTORNEY_NAME && element.ATTORNEY_NAME}</td>} {/* Check this */}
                  {state1.displayBilledStatus && <td>{ele.billed}</td>}
                  {state1.displayBilledDate && <td>{ele.billedDate && ele.billedDate}</td>}
                  {state1.displayCaseManager && <td>{(ele.caseManagerLastName && ele.caseManagerLastName) + "," + (ele.caseManagerFirstName && ele.caseManagerFirstName)}</td>}
                  {state1.displayCity && <td>{ele.claimantCity && ele.claimantCity}</td>}
                  {state1.displayClaimant && <td>{(ele.claimantLastName && ele.claimantLastName) + "," + (ele.claimantFirstName && ele.claimantFirstName)}</td>}
                  {state1.displayCompletedDate && <td>{ele.completedDate && ele.completedDate}</td>}
                  {state1.displayDayBeforePTConf && <td>{ele.dayBeforePTConf && ele.dayBeforePTConf}</td>}
                  {state1.displayDayBeforeFacilityConf && <td>{ele.dayBeforeFacilityConf && ele.dayBeforeFacilityConf}</td>}
                  {state1.displayDayBeforeProviderConf && <td>{ele.dayBeforeProviderConf && ele.dayBeforeProviderConf}</td>}
                  {state1.displayDOS && <td>{ele.dos && ele.dos}</td>}
                  {state1.displayEnteredBy && <td>{ele.origEnteredBy && ele.origEnteredBy}</td>}
                  {state1.displayEventId && <td>{ele.int_event_id && ele.int_event_id}</td>}
                  {state1.displayFaxConfirmation && <td>{ele.vendorFax && ele.vendorFax}</td>}
                  {state1.displayLastModifiedBy && <td>{ele.lastModifiedBy && ele.lastModifiedBy}</td>}
                  {state1.displayLastModifiedDate && <td>{ele.lastModifiedDate && ele.lastModifiedDate}</td>}
                  {state1.displayOrderDate && <td>{ele.orderCreateDate && ele.orderCreateDate}</td>}
                  {state1.displayPayer && <td>{ele.payorName && ele.payorName}</td>}
                  {state1.displayPhysicianName && <td>{(ele.physicianLastName && ele.physicianLastName) + "," + (ele.physicianFirstName && ele.physicianFirstName)}</td>}
                  {state1.displayPricing && <><td>${ele.totalCost && ele.totalCost}</td><td>${ele.totalBilledCharges && ele.totalBilledCharges}</td><td>${ele.margin && ele.margin}</td></>}
                  {state1.displayProductType && <td>{ele.productType && ele.productType}</td>}
                  {state1.displayReferrerName && <td>{ele.referrerName && ele.referrerName}</td>}
                  {state1.displayUSState && <td>{ele.claimantState && ele.claimantState}</td>}
                  {state1.displayType && <td>{ele.servType && ele.servType}</td>}
                  {state1.displayVendorId && <td>{ele.vendorId && ele.vendorId}</td>}
                  {state1.displayVendorName && <td>{ele.vendorName && ele.vendorName}</td>}
                  {state1.displayVendorCityState && <td>{ele.vendorCityState && ele.vendorCityState}</td>}
                  {state1.displayVendorExtra && <><td>{ele.vendorDBA && ele.vendorDBA}</td><td>{ele.vendorGPCode && ele.vendorGPCode}</td><td>{ele.vendorTIN && ele.vendorTIN}</td><td>{ele.vendorType && ele.vendorType}</td><td>{ele.vendorActive && ele.vendorActive}</td><td>{ele.vendorContracted && ele.vendorContracted}</td></>}
                  {state1.displayPONumber && <td>{ele.chr_po_number && ele.chr_po_number}</td>}
                  {state1.displayClientId && <td>{ele.clientID && ele.clientID}</td>}
                  {state1.displayClientName && <td>{ele.clientName && ele.clientName}</td>}
                  {state1.displayOutcome && <td>{ele.orderStatus && ele.orderStatus}</td>}
                  {state1.displayReadyForBilling && <td>{ele.vendorContracted && ele.vendorContracted}</td>}
                  {state1.displayCurrentlyAssignedTo && <td>{(ele.assignedUserLastName && ele.assignedUserLastName) + " " + (ele.assignedUserFirstName && ele.assignedUserFirstName)}</td>}
                  {state1.displayYorkClientName && <td>{ele.yorkClientName && ele.yorkClientName}</td>}
                  {state1.displayYorkEmployerName && <td>{ele.displayYorkEmployerName && ele.displayYorkEmployerName}</td>}
                  {state1.displayProgramName && <td>{ele.displayProgramName && ele.displayProgramName}</td>}
                  {state1.displayServiceHCPC && <td>{ele.hcpc && ele.hcpc}</td>}
                  {state1.displayServiceLocation && <td>{ele.serviceLocation && ele.serviceLocation}</td>}
                  {state1.displayServiceRentals && <td>{ele.rental && ele.rental}</td>}
                  {state1.displayServiceRentalStartDate && <td>{ele.rentalStartDate && ele.rentalStartDate}</td>}
                  {state1.displayServiceRentalEndDate && <td>{ele.rentalEndDate && ele.rentalEndDate}</td>}
                  {state1.displayServiceRentalFreq && <td>{ele.rentalFreq && ele.rentalFreq}</td>}
                  {state1.displayServiceRenewal && <td>{ele.renewal && ele.renewal}</td>}
                  {state1.displayAccommodationCheckInDateTime && <td>{ele.hotelCheckin && ele.hotelCheckin}</td>}
                  {state1.displayAccommodationCheckOutDateTime && <td>{ele.hotelCheckout && ele.hotelCheckout}</td>}
                  {state1.displayAccommodationNumberOfNights && <td>{ele.numberOfNights && ele.numberOfNights}</td>}
                  {state1.displayAccommodationHotelAddress && <td>{ele.hotelAddress && ele.hotelAddress}</td>}
                  {state1.displayAccommodationNumberOfRooms && <td>{ele.numberOfRooms && ele.numberOfRooms.toString()}</td>}
                  {state1.displayAccommodationDateTimeCreated && <td>{ele.billedDate && ele.billedDate}</td>}
                  {state1.displayAccommodationDateTimeBooked && <td>{ele.bookedDate && ele.bookedDate}</td>}
                  {state1.displayAccommodationBookingSite && <td>{ele.bookingSite && ele.bookingSite}</td>}
                  {state1.displayAccommodationHotelContact && <td>{ele.hotelContact && ele.hotelContact}</td>}
                  {state1.displayTransportPickUpAddress && <td>{ele.pickUpAddress && ele.pickUpAddress}</td>}
                  {state1.displayTransportDropOffAddress && <td>{ele.dropOffAddress && ele.dropOffAddress}</td>}
                  {state1.displayTransportPickUpTime && <td>{ele.transportationPickupDate && ele.transportationPickupDate}</td>}
                  {state1.displayTransportDropOffTime && <td>{ele.transportationDropoffDate && ele.transportationDropoffDate}</td>}
                  {state1.displayTransportAppointmentType && <td>{ele.appointmentType_special_transp && ele.appointmentType_special_transp}</td>}
                  {state1.displayTransportMileage && <td>{ele.mileage && ele.mileage}</td>}
                  {state1.displayTransportStaffedBy && <td>{ele.TransportStaffedBy && ele.displayTransportStaffedBy}</td>}
                  {state1.displayTransportTimeStaffed && <td>{ele.TransportTimeStaffed && ele.TransportTimeStaffed}</td>}
                  {state1.displayTransportNumberOfLegs && <td>{ele.int_leg_no && ele.int_leg_no}</td>}
                  {state1.displayTransportReturnType && <td>{ele.transportationReturnType && ele.transportationReturnType}</td>}
                  {state1.displayTransportWaitingOnVendorConf && <td>{ele.waitingOnVendorConf && ele.waitingOnVendorConf}</td>}
                  {state1.displayTransportDateTimeBooked && <td>{ele.bookedDate && ele.bookedDate}</td>}
                  {state1.displayTransportationPickUpAddress && <td>{ele.pickUpAddress && ele.pickUpAddress}</td>}
                  {state1.displayTransportationDropOffAddress && <td>{ele.dropOffAddress && ele.dropOffAddress}</td>}
                  {state1.displayTransportationPickUpTime && <td>{ele.transportationPickupDate && ele.transportationPickupDate}</td>}
                  {state1.displayTransportationDropOffTime && <td>{ele.transportationDropoffDate && ele.transportationDropoffDate}</td>}
                  {state1.displayTransportationMileage && <td>{ele.mileage && ele.mileage}</td>}
                  {state1.displayTransportationStaffedBy && <td>{ele.TransportStaffedBy && ele.TransportStaffedBy}</td>}
                  {state1.displayTransportationTimeStaffed && <td>{ele.TransportTimeStaffed && ele.TransportTimeStaffed}</td>}
                  {state1.displayTransportationNumberOfLegs && <td>{ele.numberOfLegs && ele.numberOfLegs}</td>}
                  {state1.displayTransportationReturnType && <td>{ele.transportationReturnType && ele.transportationReturnType}</td>}
                  {state1.displayTransportationWaitingOnVendorConf && <td>{ele.waitingOnVendorConf && ele.waitingOnVendorConf}</td>}
                  {state1.displayTransportationDateTimeBooked && <td>{ele.bookedDate && ele.bookedDate}</td>}
                  {state1.displaySplTransportationDropOffAddress && <td>{ele.sptDropOffAddress && ele.sptDropOffAddress}</td>}
                  {state1.displaySplTransportationAppointmentType && <td>{ele.appointmentType_special_transp && ele.appointmentType_special_transp}</td>}
                  {state1.displaySplTransportationDepartingDateTime && <td>{ele.departingDateTime && ele.departingDateTime}</td>}
                  {state1.displaySplTransportationArrivingDateTime && <td>{ele.arrivingDateTime && ele.arrivingDateTime}</td>}
                  {state1.displaySplTransportationDateTimeCreated && <td>{ele.completedDate && ele.completedDate}</td>}
                  {state1.displaySplTransportationDateTimeBooked && <td>{ele.bookedDate && ele.bookedDate}</td>}
                  {state1.displaySplTransportationAirline && <td>{ele.airline && ele.airline}</td>}
                  {state1.displaySplTransportationDepartingAirport && <td>{ele.departingAirport && ele.departingAirport}</td>}
                  {state1.displaySplTransportationArrivingAirport && <td>{ele.arrivingAirport && ele.arrivingAirport}</td>}
                  {state1.displaySplTransportationFlightClass && <td>{ele.flightClass && ele.flightClass}</td>}
                  {state1.displaySplTransportationBookingSite && <td>{ele.bookingSite && ele.bookingSite}</td>}
                  {state1.displaySplTransportationLayOverTime && <td>{ele.layOverTime && ele.layOverTime}</td>}
                  {state1.displayInterpretationAddress && <td>{ele.interpretationAddress && ele.interpretationAddress}</td>}
                  {state1.displayInterpretationTime && <td>{ele.timeDuration && ele.timeDuration}</td>}
                  {state1.displayInterpretationStartTime && <td>{ele.interpretationStartTime && ele.interpretationStartTime}</td>}
                  {state1.displayInterpretationLanguage && <td>{ele.languageName && ele.languageName}</td>}
                  {state1.displayInterpretationMileage && <td>{ele.mileage && ele.mileage}</td>}
                  {state1.displayInterpretationTimeStaffed && <td>{ele.TimeStaffed && ele.TimeStaffed}</td>}
                  {state1.displayInterpretationStaffedBy && <td>{ele.StaffedBy && ele.StaffedBy}</td>}
                  {state1.displayInterpretationArrivalTime && <td>{ele.interpretationArrivalTime && ele.interpretationArrivalTime}</td>}
                  {state1.displayInterpretationAppointmentType && <td>{ele.appointmentType_interpretation && ele.appointmentType_interpretation}</td>}
                  {state1.displayHHCHCPC && <td>{ele.hcpc && ele.hcpc}</td>}
                  {state1.displayHHCServiceLocation && <td>{ele.serviceLocation && ele.serviceLocation}</td>}
                </tr>)
            })}

          </tbody>
        </table>
      </div>
      <div>
        <Row>
          <Col> Result Count:{data && data.length}</Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={mergedCheck} onClick={() => mergeFilter(searchResponse, "ConfirmNumber", mergedCheck)} /> Merged
              </Label>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button" style={{ width: '100%' }}
                onClick={() => { searchContextReset(dispatch, dispatch1, resetAdvancedSearchReport, allStates, serviceStatus, diagnosticType, mseCategory, modificationCategory, accommodationType, transportCategory, specialTransportCategory, translationCategory, hhcCategory); toggle('1'); setTransportButton(''); setAudiologyButton(''); setMergeCheck(false) }}
              >
                Reset
              </Button>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                checked={mergedCheck}
                onClick={validateFields}
                style={{ width: '100%' }}>
                Search
              </Button>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <ExcelExport data={data} />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <PopupModal
        content={<div>For efficiency purposes please add the 3 letter abbreviation for the Conf Number.</div>}
        title="Criteria Needed"
        externalToggle={() => setShowAbbrPopup(false)}
        size="sm"
        isOpen={showAbbrPopup}
        footerContent={<Button
          type="button"
          onClick={() => setShowAbbrPopup(false)}>
          Ok
        </Button>}
      />
    </FormGroup>
  );
};

const mapStateToProps = state => {
  const fast360InitialData = fast360Selectors.getFast360InitialData(state);
  const fast360SearchData = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);


  return {
    userId: user.id,
    allStates: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.States,
    serviceStatus: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.ServiceStatus,
    diagnosticType: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.DiagnosticType,
    mseCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.MSECategory,
    modificationCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.ModificationCategory,
    accommodationType: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.AccommodationType,
    transportCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.TransportCategory,
    translationCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.TranslationCategory,
    specialTransportCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.SpecialTransportCategory,
    hhcCategory: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.HHCCategory,
    appointmentType: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.AppointmentType,
    language: fast360InitialData.loadStateTypes && fast360InitialData.loadStateTypes.Language,
    loadHCPCLookUp: fast360InitialData.loadHCPCLookUp,
    loadClientLookUp: fast360InitialData.loadClientLookUp,
    searchResponse: fast360SearchData.advSearchReportData && fast360SearchData.advSearchReportData,
    mergeData: fast360SearchData.mergeFilterData && fast360SearchData.mergeFilterData,
  };
};

const mapDispatchToProps = dispatch => ({
  getAdvSearchData: data =>
    dispatch(fast360Operations.getAdvSearchData(data)),
  getLoadHCPCQuick: (query, userId) =>
    dispatch(fast360Operations.getLoadHCPCQuick(query, userId)),
  getClientLookup: (query, userId) =>
    dispatch(fast360Operations.getClientLookup(query, userId)),
  loadLookupReport: (data, userId, flag, queryStr) =>
    dispatch(fast360Operations.loadLookupReport(data, userId, flag, queryStr)),
  loadLookupReportAction: data =>
    dispatch(actions.loadLookupReport(data)),
  mergeFilterDataAction: data =>
    dispatch(actions.mergeFilterData(data)),
  resetAdvancedSearchReport: () =>
    dispatch(actions.resetAdvancedSearchReport()),
  loadFromWorkList: (data) => dispatch(fast360Operations.loadFromWorkList(data)),
  loadExistingTasks: (data) => dispatch(fast360Operations.loadExistingTasks(data)),
  groupTasksProgression: (data) => dispatch(fast360Operations.groupTasksProgression(data)),
  setAdvValues: (data) => dispatch(fast360Actions.setAdvValues(data)),
  loadReferralAlerts: data =>
    dispatch(fast360Operations.loadReferralAlerts(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvSearch);

