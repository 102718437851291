import * as R from 'ramda';
import React, { useEffect, useState, useContext } from 'react';
import { connect} from 'react-redux';
import { Fast360VendorSearchContext } from '../components/AdvanceSearch/contexts/Fast360VendorSearchContext';
import {
  fast360Selectors,
  fast360Operations,
  fast360Actions,
} from '../store/index';
import moment from 'moment';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';

import { useAuth } from 'modules/auth/hooks/useAuth';

import VendorLookupResults from './VendorLookupResults';

const VendorLookupModal = ({
  isOpen,
  onClose,
  refreshCall,
  selectedRowIndex,
  rowIndex,
  lmsUserId,
  inputFields,
  dispatchFields,
  dataObject,
  vendorLookUpStoreProcedure,
  currentWorkList,
  loadReferralList,
  vendorLookupTop5ByLocation,
  setVendorSearchCall,
  vendorSearchCall,
  vendorLookupTop5ByCount,
  vendorLookupSuggestions,
  vendorLookupByTop5ByLocation,
  vendorLookupByTop5ByCount,
  vendorLookUpAssign,
  logBreadCrumbs,
  setVendorLookupData,
  selectedSubService,
  setVendorData,
  loadReferralandVender,
  updateLoadFromWorkList,
  fromLanding,
  clearVendorHistorySuggestedVendors,
  loadVendorLookupSubServices,
  loadVendorLookupInitialData,
  miles, services, subServices
}) => {
  const { userId } = useAuth();
  // const ;
  let selectedSubServiceType;
  const rowServiceId =
    selectedSubService?.serviceId ||
    currentWorkList.int_reference_table_id ||
    dataObject.int_reference_table_id;

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [serviceType, setServiceType] = useState([]);
  const [milesOptions, setMilesOptions] = useState([]);
  const [subServiceOptions, setSubServiceOptions] = useState([]);

  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const { abbreviation: serviceAbbr, parentId: serviceId } =
    R.find(R.propEq('abbreviation', inputFields.tableAbbrv))(services) || {};

  useEffect(() => {
    loadVendorLookupInitialData();
  }, []);

  useEffect(() => {
    const newMilesOptions = R.map(
      item => ({ value: item, label: `${item}` }),
      miles
    );
    const newServiceType = R.map(
      item => ({ value: item.abbreviation, label: item.displayName }),
      services
    );
    const newSubServiceOptions = R.map(
      item => ({ value: item.status_id, label: item.value }),
      subServices
    );

    setMilesOptions(newMilesOptions);
    setServiceType(newServiceType);
    setSubServiceOptions(newSubServiceOptions);
    if(selectedSubServiceType !== inputFields.subService){
      let subServiceArr = newSubServiceOptions.filter(
        optionObj =>
          optionObj.label === inputFields.subService ||
          optionObj.value === inputFields.subService
      );
      if (subServiceArr.length > 0) {
        handleSubServiceDropdown(subServiceArr[0], dispatchFields);
      }
      selectedSubServiceType = inputFields.subService;
    }
  }, [miles, services, subServices]);

  useEffect(() => {
    if (
      !fromLanding &&
      inputFields.serviceType &&
      selectedRowIndex == rowIndex
    ) {
      loadVendorLookupSubServices({
        serviceId,
        userId,
      });
    }
    if (fromLanding && selectedRowIndex == rowIndex && services.length) {
      const parentData = services.filter(
        i => i.abbreviation == dataObject?.chr_reference_table
      )[0];
      let data = { serviceId: parentData?.parentId, userId };
      loadVendorLookupSubServices(data);
    }
  }, [fromLanding, inputFields.serviceType, serviceId]);

  const handleChange = (value, key, dispatchFields) => {
    dispatchFields({
      type: 'UPDATE_INPUT_FIELDS',
      payload: { [key]: value },
    });
  };

  const onSelectRow = (dataObject, rowIndex) => {
    setIsRowSelected(true);
    setSelectedRow(dataObject);
  };

  const handleDropDownChange = (value, dispatchFields) => {
    dispatchFields({
      type: 'UPDATE_INPUT_FIELDS',
      payload: {
        tableAbbrv: value && value.value ? value.value : '',
        table: value && value.value ? value.label : '',
        serviceType: value && value.value ? value : {},
      },
    });
  };

  const handleMilesChange = (value, dispatchFields) => {
    dispatchFields({
      type: 'UPDATE_INPUT_FIELDS',
      payload: {
        radius: value && value.value ? value.value : 0,
      },
    });
  };

  const handleSubServiceDropdown = (value, dispatchFields) => {
    dispatchFields({
      type: 'UPDATE_INPUT_FIELDS',
      payload: { subService: value && value.value ? value.value : '' },
    });
  };

  const toggleModal = () => {
    onClose();
  };

  const clearSuggestions = () => {
    clearVendorHistorySuggestedVendors(activeTab);
  };

  const vendorLookUp = (data, userId, lmsUserId, currentWorkList) => {
    const request = {
      lmsUserId: lmsUserId && parseInt(lmsUserId),
      zebraUserId: userId,
      subService: data.subService,
      serviceType: fromLanding
        ? dataObject.chr_reference_table
        : data.tableAbbrv,
      referralId:
        selectedSubService?.referralId ||
        currentWorkList.FK_referral_id ||
        dataObject.FK_referral_id,
      dos: moment(currentWorkList.serviceDate).format('YYYY-MM-DD'),
      dbaName: data.dbaName,
      vendorName: data.vendorName,
      tableAbbrv: fromLanding
        ? dataObject.chr_reference_table
        : data.tableAbbrv,
      limit: data.limit && parseInt(data.limit, 10),
      nationwide: '' + data.nationwide,
      vendorZip: data.vendorZip,
      serviceId:
        selectedSubService?.serviceId ||
        currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      radius: data.nationwide ? 9999 : data.radius,
      table: fromLanding
        ? dataObject.ServiceType.toLowerCase()
        : data.table.toLowerCase(),
      allowZero: data.allowZero,
    };
    setVendorSearchCall(false);
    vendorLookUpStoreProcedure(request, setVendorSearchCall);
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'VENDSEARCHREC',
      refTableId:
        currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      refId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      refTable: data.tableAbbrv,
    };
    logBreadCrumbs(breadCrumb);
  };
  const assignVendor = async () => {
    let tableName = inputFields.table.toLowerCase();
    if (tableName === 'special transport') {
      tableName = 'special_transp';
    } else if (tableName === 'diagnostic') {
      tableName = 'diagnostics';
    } else if (tableName === 'modification') {
      tableName = 'modifications';
    }

    let serviceTypeMap = {
      accommodation: 'accommodation',
      audiology: 'audiology',
      dental: 'dental',
      diagnostic: 'diagnostics',
      interpretation: 'interpretation',
      transportation: 'transportation',
      'special transport': 'special_transp',
      mse: 'mse',
      hhc: 'hhc',
      modification: 'modifications',
    };

    const request = {
      functionName: 'updateServiceVendor',
      vendorId: selectedRow.PK_vendor_id,
      serviceId: selectedSubService?.serviceId
        ? selectedSubService.serviceId
        : dataObject?.int_reference_table_id,
      referralId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      table: tableName,
      serviceTypeId: inputFields.subService,
      state: selectedRow.chr_state,
      zipCodeId: selectedRow.PK_zipcode_id,
      feeType: null,
      serviceType: fromLanding
        ? dataObject.chr_reference_table
        : inputFields.tableAbbrv,
      zebraUserId: userId,
      fromLangId:
        (loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].fromLanguageId) ||
        '',
      langId:
        (loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].LanguageId) ||
        '',
      dos: moment(currentWorkList.serviceDate).format('YYYY-MM-DD'),
    };
    vendorLookUpAssign(request, updateLoadFromWorkList, selectedSubService);
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'VENDASSIGN',
      refTableId:
        selectedSubService.serviceId ||
        dataObject.int_reference_table_id,
      refId: currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      refTable: inputFields.tableAbbrv,
    };
    logBreadCrumbs(breadCrumb);
    setVendorData({ selectedSubService: selectedSubService, flag: true });
    onClose();
  };
  const topByLocation = (
    data,
    userId,
    lmsUserId,
    currentWorkList,
    loadReferralList
  ) => {
    const request = {
      lmsUserId: lmsUserId,
      zebraUserId: userId,
      subService: data.subService,
      fromLanguage:
        (loadReferralList &&
          loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].fromLanguageId) ||
        '',
      referralId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      dos: moment(currentWorkList.serviceDate).format('YYYY-MM-DD'),
      dbaName: data.dbaName,
      vendorName: data.vendorName,
      toLanguage:
        (loadReferralList &&
          loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].LanguageId) ||
        '',
      tableAbbrv: data.tableAbbrv || dataObject.chr_reference_table,
      limit: data.limit,
      nationwide: data.nationwide,
      vendorZip: data.vendorZip,
      serviceId:
        selectedSubService?.serviceId || currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      radius: data.radius,
      table: data.table.toLowerCase() || dataObject.ServiceType.toLowerCase(),
    };

    vendorLookupTop5ByLocation(request);
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'VENDSEARCHLOC',
      refTableId:
        currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      refId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      refTable: data.tableAbbrv,
    };
    logBreadCrumbs(breadCrumb);
  };

  const topByCount = (
    data,
    userId,
    lmsUserId,
    currentWorkList,
    loadReferralList
  ) => {
    const request = {
      lmsUserId: lmsUserId,
      zebraUserId: userId,
      subService: data.subService,
      fromLanguage:
        (loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].fromLanguageId) ||
        '',
      referralId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      dos: moment(currentWorkList.serviceDate).format('YYYY-MM-DD'),
      dbaName: data.dbaName,
      vendorName: data.vendorName,
      toLanguage:
        (loadReferralList.translationList[0] &&
          loadReferralList.translationList[0].LanguageId) ||
        '',
      tableAbbrv: data.tableAbbrv || dataObject.chr_reference_table,
      limit: data.limit,
      nationwide: data.nationwide,
      vendorZip: data.vendorZip,
      serviceId:
        selectedSubService?.serviceId || currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      radius: data.radius,
      table: data.table.toLowerCase() || dataObject.ServiceType.toLowerCase(),
    };

    vendorLookupTop5ByCount(request);
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'VENDSEARCHTOP',
      refTableId:
        currentWorkList.int_reference_table_id ||
        dataObject.int_reference_table_id,
      refId: selectedSubService?.referralId || currentWorkList.FK_referral_id || dataObject.FK_referral_id,
      refTable: data.tableAbbrv,
    };
    logBreadCrumbs(breadCrumb);
  };

  const serviceCall = activeTab => {
    let inputObj = { ...inputFields };
    if (inputObj.nationwide) {
      inputObj.radius = 9999;
    }
    inputObj.nationwide = '' + inputObj.nationwide;

    if (activeTab === '2') {
      topByLocation(
        inputObj,
        userId,
        lmsUserId,
        currentWorkList,
        loadReferralList
      );
    } else {
      topByCount(
        inputObj,
        userId,
        lmsUserId,
        currentWorkList,
        loadReferralList
      );
    }
  };
  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggleModal}>Vendor Lookup</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label lg="1" for="vendorName">
              Name:
            </Label>
            <Col lg="3">
              <InputGroup>
                <Input
                  id="vendorName"
                  type="text"
                  value={inputFields.vendorName}
                  onChange={e =>
                    handleChange(e.target.value, 'vendorName', dispatchFields)
                  }
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() =>
                      handleChange('', 'vendorName', dispatchFields)
                    }
                  >
                    x
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Label lg="1" for="service">
              Service:
            </Label>
            <Col lg="3">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                placeholder="Choose..."
                name="service"
                value={serviceType.filter(service => {
                  if (fromLanding)
                    return (
                      service.label.toLowerCase() ==
                      dataObject.ServiceType.toLowerCase()
                    );
                  else
                    return (
                      service.value.toLowerCase() ==
                      inputFields?.serviceType?.value?.toLowerCase()
                    );
                })}
                onChange={value => {
                  handleDropDownChange(value, dispatchFields);
                }}
                options={serviceType}
              />
            </Col>
            <Col lg={{ size: 3, offset: 1 }} align="right">
              <Button
                type="button"
                disabled={activeTab === '1'}
                onClick={() => serviceCall(activeTab)}
              >
                Vendor Search
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="1" for="dbaName">
              DBA Name:
            </Label>
            <Col lg="3">
              <InputGroup>
                <Input
                  id="dbaName"
                  type="text"
                  value={inputFields.dbaName}
                  onChange={e =>
                    handleChange(e.target.value, 'dbaName', dispatchFields)
                  }
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => handleChange('', 'dbaName', dispatchFields)}
                  >
                    x
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Label lg="1" for="subService">
              Sub Service:
            </Label>
            <Col lg="3">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                placeholder="Choose..."
                name="subService"
                value={subServiceOptions.filter(
                  subServiceOption =>
                    subServiceOption.value == inputFields.subService
                )}
                onChange={newOption =>
                  handleSubServiceDropdown(newOption, dispatchFields)
                }
                options={subServiceOptions}
              />
            </Col>
            <Col lg={{ size: 3, offset: 1 }} align="right">
              <Button
                type="button"
                disabled={activeTab !== '1'}
                onClick={() => {
                  vendorLookUp(inputFields, userId, lmsUserId, currentWorkList);
                  onClose(true);
                }}
              >
                Re-Evaluate Current Selection
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg="2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="isNationwide"
                    checked={inputFields.nationwide}
                    onChange={e =>
                      handleChange(
                        e.target.checked,
                        'nationwide',
                        dispatchFields
                      )
                    }
                  />
                  {' Nationwide'}
                </Label>
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="viewZeroVendor"
                    checked={inputFields.viewZeroVendor}
                    onChange={e =>
                      handleChange(
                        e.target.checked,
                        'allowZero',
                        dispatchFields
                      )
                    }
                  />
                  {' View $0 Vendor'}
                </Label>
              </FormGroup>
            </Col>
            <Label lg="1" for="miles">
              Miles:
            </Label>
            <Col lg="2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                disabled={inputFields.nationwide}
                isClearable
                placeholder=""
                name="miles"
                value={milesOptions.filter(
                  option => inputFields.radius == option.value
                )}
                onChange={value => handleMilesChange(value, dispatchFields)}
                options={milesOptions}
              />
            </Col>
            <Label lg={{ size: 1, offset: 3 }} for="limit">
              Result Limit:
            </Label>
            <Col lg="1">
              <Input
                id="limit"
                type="number"
                min={0}
                max={50}
                value={inputFields.limit}
                onChange={e =>
                  handleChange(e.target.value, 'limit', dispatchFields)
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="1" for="zip">
              City/St/Zip:
            </Label>
            <Col lg="2">
              <InputGroup>
                <Input
                  id="zip"
                  type="text"
                  disabled={inputFields.nationwide}
                  value={inputFields.vendorZip}
                  onChange={e =>
                    handleChange(e.target.value, 'vendorZip', dispatchFields)
                  }
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() =>
                      handleChange('', 'vendorZip', dispatchFields)
                    }
                  >
                    x
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={{ size: 3, offset: 6 }} align="right">
              <Button type="button" onClick={clearSuggestions}>
                Clear Suggestion
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <Row>
          <VendorLookupResults
            onToggle={toggle}
            activeTab={activeTab}
            vendorLookupSuggestions={
              vendorLookupSuggestions[rowServiceId] || []
            }
            vendorLookupByTop5ByLocation={
              vendorLookupByTop5ByLocation[rowServiceId] || []
            }
            vendorLookupByTop5ByCount={
              vendorLookupByTop5ByCount[rowServiceId] || []
            }
            rowClickHandler={onSelectRow}
            closeLookupModal={onClose}
            payloadObj={{
              serviceId: rowServiceId,
              serviceType: inputFields.tableAbbrv,
              zebraUserId: userId,
              lmsUserId: lmsUserId,
              subService: inputFields.subService,
            }}
          />
        </Row>
        <Row>
          <Col>
            <Button type="button" onClick={toggleModal}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={assignVendor}
              disabled={!isRowSelected}
            >
              Assign
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  const fast360InitialData = fast360Selectors.getFast360InitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  const fast360VendorLookupData = fast360Selectors.getFast360VendorLookup(state)
  return {
    initialworklist:
      fast360InitialData.initialworklist && fast360InitialData.initialworklist,
    currentWorkList: fast360Info.currentWorkListItem,
    updateLoadFromWorkList: fast360Info.updateLoadFromWorkList,
    loadReferralList:
      fast360Info.updateLoadFromWorkList.translationList &&
      fast360Info.updateLoadFromWorkList,
    vendorLookupSuggestions: fast360Info.vendorLookupSuggestions,
    vendorLookupByTop5ByLocation: fast360Info.vendorLookupByTop5ByLocation,
    vendorLookupByTop5ByCount: fast360Info.vendorLookupByTop5ByCount,
    miles: fast360VendorLookupData.miles, 
    services : fast360VendorLookupData.services || [], 
    subServices : fast360VendorLookupData.subServices || []
  };
};

const mapDispatchToProps = dispatch => ({
  vendorLookUpStoreProcedure: (data, setVendorSearchCall) =>
    dispatch(
      fast360Operations.vendorLookUpStoreProcedure(data, setVendorSearchCall)
    ),
  vendorLookupTop5ByLocation: data =>
    dispatch(fast360Operations.vendorLookupTop5ByLocation(data)),
  vendorLookupTop5ByCount: data =>
    dispatch(fast360Operations.vendorLookupTop5ByCount(data)),
  vendorLookUpAssign: (data, updatedWorkList, selectedSubService) =>
    dispatch(
      fast360Operations.vendorLookUpAssign(
        data,
        updatedWorkList,
        selectedSubService
      )
    ),
  logBreadCrumbs: data => dispatch(fast360Operations.logBreadCrumbs(data)),
  setVendorData: data => dispatch(fast360Actions.setVendorData(data)),
  clearVendorHistorySuggestedVendors: data => dispatch(fast360Operations.clearVendorHistorySuggestedVendors(data)),
  loadVendorLookupSubServices: data => dispatch(fast360Operations.loadVendorLookupSubServices(data)),
  loadVendorLookupInitialData: data => dispatch(fast360Operations.loadVendorLookupInitialData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorLookupModal);
