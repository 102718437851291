import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import { authSelectors } from 'modules/auth/store';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

import AddManageBillsModal from './AddManageBillsModal';

const ManageBillsModal = ({
  isOpen,
  onClose,
  billListData,
  deleteBillLines,
  setBillLinesData,
  updatedBillListData,
  userId,
}) => {
  const [plusClicked, setPlusClicked] = useState(false);
  const [minusClicked, setMinusClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentBillList, setCurrentBillList] = useState(updatedBillListData);

  useEffect(() => {
    setBillLinesData(currentBillList);
  }, [plusClicked, editClicked]);
  const toggle = () => {
    //   resetFormData();
    onClose();
  };

  const handleModalClick = (name, selectedRecord) => {
    if (name === 'Add') {
      setPlusClicked(true);
      setEditClicked(false);
    } else if (name === 'Delete') {
      setMinusClicked(true);
      let index = R.findIndex(
        item => item.id === selectedRecord.id,
        currentBillList
      );
      currentBillList.splice(index, 1);
      setCurrentBillList(currentBillList);
      setSelectedRecord(null);
      deleteBillLines(selectedRecord.id);
    } else if (name === 'Edit') {
      setEditClicked(true);
    }
  };

  const addManageBill = () => {
    setPlusClicked(false) || setEditClicked(false);
  };

  const handleClick = data => {
    setSelectedRecord(data);
  };

  const renderBillsList = currentBillList => (
    <FormGroup>
      <Input type="select" name="selectMulti" id="exampleSelectMulti" multiple>
        {currentBillList.map(item => (
          <option onClick={() => handleClick(item)}>{item.search_name}</option>
        ))}
      </Input>
    </FormGroup>
  );

  return (
    <>
      {(plusClicked || editClicked) && (
        <AddManageBillsModal
          isOpen={plusClicked || editClicked}
          onClose={addManageBill}
          selectedRecord={selectedRecord}
          addNew={plusClicked}
        />
      )}

      <Modal size="md" isOpen={isOpen} style={{ height: '200px' }}>
        <ModalHeader toggle={toggle}>Manage Bill Searches..</ModalHeader>
        <ModalBody>
          {renderBillsList(updatedBillListData)}
          <Form style={{ float: 'right' }}>
            <Button
              id="Delete"
              onClick={() => handleModalClick('Delete', selectedRecord)}
              style={{ margin: '10px' }}
              disabled={selectedRecord === null}
            >
              X{' '}
            </Button>
            <Button
              id="Add"
              onClick={() => handleModalClick('Add')}
              style={{ margin: '10px' }}
            >
              +{' '}
            </Button>
            <Button
              id="Edit"
              onClick={() => handleModalClick('Edit', selectedRecord)}
              style={{ margin: '10px' }}
              disabled={selectedRecord === null}
            >
              EDIT{' '}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ManageBillsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteBillLines: PropTypes.func,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  setBillLinesData: PropTypes.func,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);

  return {
    billListData: billReviewInitialData.billsearches
      ? billReviewInitialData.billsearches
      : [],
    updatedBillListData: billReviewState.updatedBillSearches
      ? billReviewState.updatedBillSearches
      : billReviewInitialData.billsearches,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteBillLines: data =>
    dispatch(billOperationsOperations.deleteBillLines(data)),
  setBillLinesData: data =>
    dispatch(billOperationsActions.setBillLinesData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBillsModal);
