import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Row, Col } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import TransportDetailModal from './TransportDetailModal';
import moment from '../../../../node_modules/moment/moment';
import PopupModal from 'shared/components/PopupModal';
import { connect } from 'react-redux';
import { fast360Selectors, fast360Operations, fast360Actions } from '../store';
import { isEmpty } from 'lodash';
const Fast360TransportDetails = ({
  disableSave,
  getLegStatus,
  userId,
  timeZone,
  zipList,
  loadLegStatus,
  getZipList,
  alert,
  updatedClaimInfo,
  workListData,
  detailsForOrder,
  isFromLanding,
  setTrnTableDetails,
  saveObj,
  setTrnDetails,
  isNew,
  newTrnData,
  trnDetailsList,
  updateTrnDetailList,
  updateDeletedTRNList,
  trnDeletedList,
  logBreadCrumbs
}) => {
  const [
    openTransportServiceDetailModal,
    setopenTransportServiceDetailModal,
  ] = useState(false);
  const [transportDetails, setTransportDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(
    false
  );

  const openTransportService = (onSave) => {
    setSelectedIndex('');
    setopenTransportServiceDetailModal(!openTransportServiceDetailModal);
    getLegStatus(userId);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": onSave === true ? 'SAVEDETAILLINE' : 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    (!openTransportServiceDetailModal || onSave === true) && logBreadCrumbs(breadCrumb);
  };

  useEffect(() => {
    setTrnTableDetails(transportDetails);
  }, [transportDetails]);

  useEffect(() => {
    if (
      detailsForOrder.detailList &&
      (detailsForOrder.detailList.length > 0 || trnDetailsList.length > 0)
    ) {
      let detailsArr = detailsForOrder.detailList.filter(
        detailObj => detailObj.leg
      );
      let newLegsArr = [];
      trnDetailsList.map(
        detailObj => {
          if(detailObj.serviceId == detailsForOrder.serviceId && detailObj.legStatus ) {
            newLegsArr.push(detailObj);
          }
          if(detailObj.tempId){
            detailsArr = detailsArr.filter(detailItem => !detailItem.tempId || detailItem.tempId !== detailObj.tempId);
            if(detailObj.id){
              detailsArr = detailsArr.filter(detailItem => detailItem.id !== detailObj.id);
            }
          }
          return detailObj;
        }
      );
      if (trnDeletedList.length > 0) {
        trnDeletedList.map(delItem => {
          detailsArr = detailsArr.filter(detailObj => detailObj.id !== delItem);
        });
      }
      let mergedData = [...detailsArr, ...newLegsArr];
      let sortedData = mergedData.sort((a,b) => a.leg<b.leg ? -1 : 1);
      setTransportDetails([...sortedData]);
      setTrnTableDetails([...sortedData]);
    } else setTransportDetails([]);
  }, [detailsForOrder, trnDetailsList]);

  useEffect(() => {
    if (isNew && detailsForOrder) {
      let index = newTrnData.findIndex(
        i => i.serviceId == detailsForOrder.serviceId
      );
      if (index !== -1) {
        setTransportDetails(newTrnData[index].data);
      }
    }
  }, [detailsForOrder, isNew]);

  const deleteTransportServiceRow = () => {
    if (selectedIndex === undefined || selectedIndex === '') {
      alert('Please select atleast one row to remove');
      return false;
    }

    let transportDetailsArr = [...transportDetails];
    if (selectedIndex !== '') {
      let tripLeg = {
        functionName: 'delTRNLeg',
        zebraUserId: userId,
        id: transportDetailsArr[selectedIndex].id,
      };
      if (transportDetailsArr[selectedIndex].id > 0) {
        saveObj(tripLeg);
        updateDeletedTRNList(transportDetailsArr[selectedIndex].id);
        let trnList = trnDetailsList.filter(
          trnItem => trnItem.id !== transportDetailsArr[selectedIndex].id
        );
        if (trnDetailsList.length !== trnList.length) {
          updateTrnDetailList(trnList);
        }
      } else {
        let trnList = trnDetailsList.filter(
          trnItem =>
            !trnItem.tempId ||
            trnItem.tempId !== transportDetailsArr[selectedIndex].tempId
        );
        if (trnDetailsList.length !== trnList.length) {
          updateTrnDetailList(trnList);
        }
      }
      transportDetailsArr.splice(selectedIndex, 1);
    }
    if (transportDetailsArr.length === 0) {
      setTransportDetails([]);
    } else {
      setTransportDetails(transportDetailsArr);
    }
    setSelectedIndex('');
  };

  const onRowClick = (item, index) => {
    setSelectedIndex(index);
  };

  const onRowDoubleClick = (item, index) => {
    setSelectedIndex(index);
    getLegStatus(userId);
    setopenTransportServiceDetailModal(true);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    logBreadCrumbs(breadCrumb);
  };

  const updateTransportDetails = inputObj => {
    let transportDetailsArr = [...transportDetails];
    if (selectedIndex !== '') {
      transportDetailsArr[selectedIndex] = inputObj;
    } else {
      transportDetailsArr.push(inputObj);
    }
    setTransportDetails(transportDetailsArr);
    if(!inputObj.tempId){
      let obj = {
        serviceId: detailsForOrder.serviceId,
        data: transportDetailsArr,
      };
      let arrBefore = [...newTrnData];
      let index = arrBefore.findIndex(
        i => i.serviceId == detailsForOrder.serviceId
      );
      if (index === -1) arrBefore.push(obj);
      else arrBefore[index] = obj;
      setTrnDetails(arrBefore);
    }
    setopenTransportServiceDetailModal(false);
  };

  const renderPickUpAddressColumn = item => {
    let print = '';
    let locationName = '';
    let pickUpAddress = '';
    let pickUpAddress2 = '';
    if (item.pickupAddress?.zipCode?.city) {
      print = (
        <>
          <br />
          <span>{`${item.pickupAddress.zipCode.city}, ${item.pickupAddress.zipCode.state}  ${item.pickupAddress.zipCode.zip}`}</span>
        </>
      );
    }
    if (item.locationName) {
      locationName = (
        <>
          <span>{`${item.locationName}`}</span>
        </>
      );
    }
    if (item.pickupAddress?.street1) {
      pickUpAddress = (
        <>
          <br />
          <span>{`${item.pickupAddress.street1}`}</span>
        </>
      );
    }
    if (item.pickupAddress?.street2) {
      pickUpAddress2 = (
        <>
          <br />
          <span>{`${item.pickupAddress.street2}`}</span>
        </>
      );
    }
    return (
      <>
        {locationName}
        {pickUpAddress}
        {pickUpAddress2}
        {print}
        <br />
        {item.pickupAddress?.phone}
      </>
    );
  };

  const renderDropOffAddressColumn = item => {
    let print = '';
    let droplocationName = '';
    let dropOffAddress = '';
    let dropOffAddress2 = '';
    if (item.dropOffAddress?.zipCode?.city) {
      print = (
        <>
          <br />
          <span>{`${item.dropOffAddress.zipCode.city}, ${item.dropOffAddress.zipCode.state}  ${item.dropOffAddress.zipCode.zip}`}</span>
        </>
      );
    }
    if (item.dropLocationName) {
      droplocationName = (
        <>
          <span>{`${item.dropLocationName}`}</span>
        </>
      );
    }
    if (item.dropOffAddress?.street1) {
      dropOffAddress = (
        <>
          <br />
          <span>{`${item.dropOffAddress.street1}`}</span>
        </>
      );
    }
    if (item.dropOffAddress?.street2) {
      dropOffAddress2 = (
        <>
          <br />
          <span>{`${item.dropOffAddress.street2}`}</span>
        </>
      );
    }
    return (
      <>
        {droplocationName}
        {dropOffAddress}
        {dropOffAddress2}
        {print}
        <br />
        {item.dropOffAddress?.phone}
      </>
    );
  };

  const actualWaitTime = item => {
    let print = (
      <>
        <span>{`${item.waitHour || '0'}h ${item.waitMinute || '0'}m`}</span>
      </>
    );
    return <>{print}</>;
  };

  const projWaitTime = item => {
    let print = (
      <>
        <span>{`${item.proWaitHour || '0'}h ${item.proWaitMinute ||
          '0'}m`}</span>
      </>
    );
    return <>{print}</>;
  };

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const getDeleteBody = () => {
    return (
      <div className="text-center">
        Are you sure you wish to delete Service Detail?
      </div>
    );
  };

  const getDeleteFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteTransportServiceRow();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <div>
      <Row>
        <Col lg="2">
          <Button
            type="button"
            disabled={isEmpty(detailsForOrder) ||  disableSave}
            onClick={openTransportService}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>

          <Button
            color="danger"
            type="button"
            disabled={disableSave}
            onClick={confirmationDeleteToggle}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <AppTable
            resizable
            columns={[
              {
                header: '',
              },
              {
                header: 'Leg #',
                field: 'leg',
              },
              {
                header: 'Leg Status',
                field: 'legStatus',
              },
              {
                header: 'Pickup Date',
                field: 'pickupDate',
                render: item =>
                  item.pickup && moment(item.pickup).format('MM/DD/YYYY'),
              },
              {
                header: 'Pickup Time',
                field: 'pickUpTime',
                render: item =>
                  `${moment(item.pickup).format('hh:mm A')} ${item.pickupTz}`,
              },
              {
                header: 'Pickup Information',
                field: 'pickUpInformation',
                render: item => renderPickUpAddressColumn(item),
              },
              {
                header: 'Drop Off Date',
                render: item =>
                  item.dropOff && moment(item.dropOff).format('MM/DD/YYYY'),
              },
              {
                header: 'Drop Off Time',
                field: 'dropOffTime',
                render: item =>
                  `${moment(item.dropOff).format('hh:mm A')} ${item.dropOffTz}`,
              },
              {
                header: 'Drop Off Information',
                field: 'dropOffInformation',
                render: item => renderDropOffAddressColumn(item),
              },
              {
                header: 'Proj. Wait Time',
                field: 'projWaitTime',
                render: item => projWaitTime(item),
              },
              {
                header: 'Actual Wait Time',
                field: 'actualWaitTime',
                render: item => actualWaitTime(item),
              },
              {
                header: 'Return Type',
                field: 'returnTypeDesc',
              },
              {
                header: 'Est.Mileage',
                field: 'estimatedMillage',
              },
            ]}
            data={transportDetails}
            rowClickHandler={onRowClick}
            rowDoubleClickHandler={onRowDoubleClick}
          />
        </Col>
      </Row>
      <PopupModal
        content={getDeleteBody()}
        title="Confirmation"
        externalToggle={confirmationDeleteToggle}
        isOpen={isConfirmDeleteModalOpen}
        footerContent={getDeleteFooter()}
        size="md"
      />
      {openTransportServiceDetailModal && (
        <TransportDetailModal
          isOpen={openTransportServiceDetailModal}
          disableSave={disableSave}
          transportDetailObj={
            selectedIndex !== '' ? transportDetails[selectedIndex] : {}
          }
          pickupAddr={
            transportDetails.length > 0
              ? JSON.parse(JSON.stringify(transportDetails[transportDetails.length - 1].dropOffAddress))
              : {}
          }
          pickupLocName={
            transportDetails.length > 0
              ? transportDetails[transportDetails.length - 1].dropLocationName
              : ''
          }
          legLength={transportDetails.length}
          onClose={openTransportService}
          onSave={updateTransportDetails}
          timeZone={timeZone}
          loadLegStatus={loadLegStatus}
          getLegStatus={getLegStatus}
          getZipList={getZipList}
          userId={userId}
          referralId={detailsForOrder.referralId}
          serviceId={detailsForOrder.serviceId}
          zipList={zipList}
          alert={alert}
          updatedClaimInfo={updatedClaimInfo}
          workListData={workListData}
          isFromLanding={isFromLanding}
          detailsForOrder={detailsForOrder}
          setTransportDetails={setTransportDetails}
          transportDetails={transportDetails}
        />
      )}
    </div>
  );
};

/*GroupBySelection.propTypes = {
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
};*/

const mapStateToProps = state => {
  return {
    newTrnData: state.FAST360.fast360Reducer.trnData,
    trnDetailsList: state.FAST360.fast360Reducer.trnDetailsList
      ? state.FAST360.fast360Reducer.trnDetailsList
      : [],
    trnDeletedList: state.FAST360.fast360Reducer.trnDeletedList
      ? state.FAST360.fast360Reducer.trnDeletedList
      : [],
  };
};

const mapDispatchToProps = dispatch => ({
  setTrnTableDetails: data => dispatch(fast360Actions.trnTableDetails(data)),
  saveObj: data => dispatch(fast360Operations.saveObj(data)),
  updateTrnDetailList: data =>
    dispatch(fast360Actions.updateTrnDetailList(data)),
  setTrnDetails: data => dispatch(fast360Actions.setTrnDetails(data)),
  updateDeletedTRNList: data =>
    dispatch(fast360Actions.updateDeletedTRNList(data)),
  logBreadCrumbs: data => dispatch(fast360Operations.logBreadCrumbs(data)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fast360TransportDetails);
