import React, { Component } from 'react';
import { Input, Button, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import PropTypes from 'prop-types';

class CustomDatePickerInput extends Component {
  render() {
    const { onClick, value, onChange, icon, label, disabled, error } = this.props;
    let borderStyle = ''
    if (error)
      borderStyle = '1px solid red'

    return (
      <>
        {label && <Label for="datePicker">{label}</Label>}
        <InputGroup>
          <Input
            type="text"
            onClick={onClick}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={{ border: borderStyle }}

          />
          <InputGroupAddon addonType="append">
            <Button onClick={onClick}>
              <FontAwesomeIcon icon={icon} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </>
    );
  }
}

class AppDateInput extends Component {
  
  getDate() {
    if(!this.props.value || this.props.value === '') {
      if (this.props.blankDefault == "1") {
        return null;
      } else {
        return new Date();
      }      
    } else{
      return new Date(this.props.value)
    }
  }

  render() {
    const props = {...this.props};
    props.selected = this.props.selected ? this.props.selected : this.getDate();
    registerLocale('en', en)
    return (
      <div style={{ border: this.props.error ? '1px solid red' : '' }}>
        <DatePicker
          {...props}
          locale="en"
          showYearDropdown
          dateFormatCalendar="MMMM"
          openToDate={this.props.openToDate}
          customInput={
            <CustomDatePickerInput
              icon={this.props.icon}
              label={this.props.label}
            />
          }
        />
      </div>
    );
  }
}

AppDateInput.defaultProps = {
  icon: faCalendarAlt,
};

AppDateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
  label: PropTypes.string,
  openToDate: PropTypes.any,
};

export default AppDateInput;
