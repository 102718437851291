import React, { useState } from 'react';
import {
  Row,
  Collapse,
  Navbar,
  NavbarToggler,

  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import CarrierTrackingModal from '../../../modules/fast-360/components/carrierTrackingModal';
import InfoModal from './InfoModal';
import ExternalReferral from '../../../modules/fast-360/components/ExternalReferral';
import Fast360FeeLookupModal from '../../../modules/fast-360/components/Fast360FeeLookupModal';
import Fast360APMaint from '../../../modules/fast-360/components/Fast360APMaint';
import Fast360APApproval from '../../../modules/fast-360/components/Fast360APApproval';
import Fast360WCEDI from '../../../modules/fast-360/components/Fast360WCEDI';
import Fast360HHCLookupModal from '../../../modules/fast-360/components/Fast360HHCLookupModal';
import VendorMaintenanceNewEntry from '../../../modules/fast-360/components/VendorMaintenanceNewEntry';
import VendorMaintenanceSearch from '../../../modules/fast-360/components/VendorMaintenanceSearch';

const Fast360TopMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);


  const openphysicianLookup = () => {
    let npiUrl = `https://npiregistry.cms.hhs.gov/`;
    window.open(npiUrl, '_blank')
  };
  const [isOpenCarrierTracking, setIsOpenCarrierTracking] = useState(false);
  const [isOpenHHCLookup, setIsOpenHHCLookup] = useState(false);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const handleInfoClick = () => { setIsInfoModal(!isInfoModal); };
  const openCarrierTracking = () => { setIsOpenCarrierTracking(!isOpenCarrierTracking); };
  const [isOpenFeeLookup, setIsOpenFeelookUp] = useState(false);
  const openFast360Feelookup = () => { setIsOpenFeelookUp(!isOpenFeeLookup); };
  const [isOpenAPMaint, setIsOpen360APMaint] = useState(false);
  const openFast360APMaint = () => { setIsOpen360APMaint(!isOpenAPMaint); };
  const [isOpenAPApproval, setIsOpen360ApApproval] = useState(false);
  const openFast360APApproval = () => { setIsOpen360ApApproval(!isOpenAPApproval); };
  const [isOpenExternal, setIsOpenExternal] = useState(false);
  const [isOpenWCEDI, setIsOpen360WCEDI] = useState(false);
  const openFast360WCEDI = () => { setIsOpen360WCEDI(!isOpenWCEDI); };
  const [isOpenVendorMaintNewEntry, setIsOpenVendorMaintNewEntry] = useState(false);
  const [providerData, setProviderData] = useState(null);
  const openVendorMaintNewEntry = () => { setIsOpenVendorMaintNewEntry(!isOpenVendorMaintNewEntry); };
  const [isOpenVendorMaintSearch, setIsOpenVendorMaintSearch] = useState(false);
  const openVendorMaintSearch = () => { setIsOpenVendorMaintSearch(!isOpenVendorMaintSearch); };
  const [isOpenCareworksTraining, setIsOpenCareworksTraining] = useState(false);
  const openCareworksTraining = () => { setIsOpenCareworksTraining(!isOpenCareworksTraining); };
  
  const openFast360HHCLookup = () => { 
    
    setIsOpenHHCLookup(!isOpenHHCLookup); 
    
  };
  
  const openExternal = (data) => { 
    setIsOpenExternal(!isOpenExternal); 
    if(props.onOpenReferral) {
      props.onOpenReferral(data);
    }
  };
  const openExt = (flag) => { setIsOpenExternal(flag); };

  return (
    <Row>
      {isOpenCarrierTracking && <CarrierTrackingModal clicked={openCarrierTracking} />}
      {isInfoModal && <InfoModal clicked={handleInfoClick} />}
      <Fast360FeeLookupModal isOpen={isOpenFeeLookup} onClose={openFast360Feelookup} />
      <Fast360APMaint isOpen={isOpenAPMaint} onClose={openFast360APMaint} />
      {isOpenAPApproval &&<Fast360APApproval isOpen={isOpenAPApproval} onClose={openFast360APApproval}  openFast360APApproval={openFast360APApproval}/>}
      {isOpenWCEDI &&<Fast360WCEDI isOpen={isOpenWCEDI} onClose={openFast360WCEDI}  openFast360WCEDI={openFast360WCEDI}/>}
      {isOpenHHCLookup && <Fast360HHCLookupModal isOpen={isOpenHHCLookup} onClose={openFast360HHCLookup}  openFast360HHCLookup={openFast360HHCLookup} />}
      {/* {isOpenFeeLookup && <Fast360FeeLookupModal clicked={openFast360Feelookup} />} */}
      {isOpenExternal && <ExternalReferral openExternal={openExternal} openExt={openExt} onNewReferral={props} />}
      <VendorMaintenanceNewEntry isOpen={isOpenVendorMaintNewEntry} onClose={openVendorMaintNewEntry} providerData={providerData}/>
      <VendorMaintenanceSearch isOpen={isOpenVendorMaintSearch} onClose={openVendorMaintSearch} switchToEntry={openVendorMaintNewEntry} setProviderData={setProviderData}/>
      <Navbar color="light" light expand="lg">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                About
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={handleInfoClick}>Info</DropdownItem>
                <DropdownItem>Quick Reference</DropdownItem>
                <DropdownItem>Release Notes</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Reporting
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Attached Invoice Unbilled</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem value="HHClookup" onClick={openFast360HHCLookup}>HHC Lookup Fees</DropdownItem>
                <DropdownItem value="Feelookup" onClick={openFast360Feelookup}>Fee Lookup</DropdownItem>
                <DropdownItem>Edit Teams</DropdownItem>
                <DropdownItem value="APMaint" onClick={openFast360APMaint}>AP Data Maintenance</DropdownItem>
                <DropdownItem value="APApproval" onClick={openFast360APApproval}>AP Approval</DropdownItem>
                {/* <DropdownItem>Print Reports</DropdownItem> */}
                {/* <DropdownItem>Maintenance</DropdownItem> */}
                <DropdownItem value="Physican" onClick={openphysicianLookup}>Physician NPI/License# Lookup</DropdownItem>
                {/* <DropdownItem>Unbilled Vendor Email</DropdownItem> */}
                <DropdownItem value="WCEDI" onClick={openFast360WCEDI}>WCEDI</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Tracking
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem value="carrier" onClick={openCarrierTracking}>Carrier Tracking</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                External Referral Queue
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={openExternal}>External Referral Queue</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
              Contracted Provider Maintenance
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem value="VendorNewEntry" onClick={openVendorMaintNewEntry}>New Entry</DropdownItem>
                <DropdownItem value="VendorSearch" onClick={openVendorMaintSearch}>Search</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </Row>
  );
};

export default Fast360TopMenu;
