import * as R from 'ramda';

import * as ReduxUtils from 'shared/utils/reduxUtils';
import { createSelector } from 'reselect';

import { MODULE_NAME } from './constants';

const getModule = state => state[MODULE_NAME];
const transform = ReduxUtils.createModuleSelectorTransform(MODULE_NAME);

const selectors = ReduxUtils.transformSelectors(transform, {
  getDocTypes: state => R.path(['initialData', 'docTypes'], state),
  getMilestones: state => R.path(['initialData', 'milestones'], state),
  getWorkTasks: state => R.path(['initialData', 'workTasks'], state),
  getWorkStatuses: state => R.path(['initialData', 'workStatuses'], state),
});

const getFast360Info = createSelector(getModule, state => state.fast360Reducer);

const getFast360InitialData = createSelector(
  getModule,
  state => state.initialData
);

const getFast360VendorLookup = createSelector(
  getModule,
  state => state.vendorLookup
);

const getVendorMaintenance = createSelector(
  getModule,
  state => state.vendorMaintenance
);

export default {
  selectors,
  getFast360Info,
  getFast360InitialData,
  getFast360VendorLookup,
  getVendorMaintenance,
};
