import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import Select from 'react-select';
import { components } from "react-select";
import { omit } from 'lodash';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import BillDetailDenyMisc from '../../../modules/bill-operations/components/BillDetailDenyMisc';
const BillDetailDeny = ({
  selected,
  index,
  onClose,
  billDetailDropdownData,
  getBillDetailDropdownData,
  userId,
  profile,
  setDenyReason,
  denyReasonFromState,
  taskListData,
  billId,
  claimant,
  siteId,
  fromBillReview,
  getDenyReason,
  reasonOtherNote,
  updateMap,
  getUpdatedMap,
  setCode1,
  setCode2,
  reasonMTGNote,
}) => {
  var selectedBill = taskListData && taskListData.filter(bill=>bill.id===billId);
  const [searchFields, setSearchFields] = useState({});
  const [denyReason] = useState('');
  const [inputs, setInputs] = useState([]);
  const [selectedDenyItems, setSelectedDenyItems] = useState([]);
  var DenyArray = [];
  const [previousIndex, setPreviousIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [reasonOther,setDenyText] = useState('');
  const [reasonCode1, setReasonCode1] = useState('');
  const [reasonCode2, setReasonCode2] = useState('');
  useEffect(() => {
    const params = {
      userId,
      profile,
      claimant,
      siteId,
      billId,
    };

    getBillDetailDropdownData(params);
  }, [index === selected]);

  const toggle = () => {
    onClose();
  };

  const onSelectDenyItemsHandler = (value, {action}) => {
    if(action === 'select-option') {
      selectedDenyItems.length < 2 &&
      setSelectedDenyItems(value);
    } else {
      setSelectedDenyItems(value ? value : []);
    }
    if(action === 'remove-value') {
      // If we remove a value clear the boxes to make it easier
      setCode1('');
      setCode2('');
      reasonOtherNote('');
      reasonMTGNote('');
    }
    if((value && value.length <= 2) || !value) {
      let denyObj;
      value = value ? value : [];
        if (fromBillReview) {
          denyObj = { detailId: index + 1, note: value};
        } else {
          denyObj = { detailId: index + 1, note: value};
        }
        denyReasonFromState.push(denyObj);
        updateMap(denyObj.detailId,denyObj.note)
        setDenyReason(denyReasonFromState);
        if (fromBillReview) getDenyReason(denyReasonFromState); 
    }
    //console.log(getUpdatedMap().get(index+1));
    if (getUpdatedMap().get(index+1).length == 0) {
      setReasonCode1('');
      setReasonCode2('');
    } else if (getUpdatedMap().get(index+1).length == 1){
      setReasonCode1(getUpdatedMap().get(index+1)[0].value.toLowerCase().substring(0,getUpdatedMap().get(index+1)[0].value.indexOf("-")));
      setReasonCode2('');
    } else {
      setReasonCode1(getUpdatedMap().get(index+1)[0].value.toLowerCase().substring(0,getUpdatedMap().get(index+1)[0].value.indexOf("-")));
      setReasonCode2(getUpdatedMap().get(index+1)[1].value.toLowerCase().substring(0,getUpdatedMap().get(index+1)[1].value.indexOf("-")));
    }
  }

  const onSingleSelectDenyItemsHandler = (value, {action}) => {
    var arrayValue = [];
    arrayValue.push(value);
    if(action === 'select-option') {
      setSelectedDenyItems(arrayValue);
    } else {
      setSelectedDenyItems(arrayValue ? arrayValue : []);
    }

    let denyObj;
    arrayValue = arrayValue ? arrayValue : [];
        if (fromBillReview) {
          denyObj = { detailId: index + 1, note: arrayValue};
        } else {
          denyObj = { detailId: index + 1, note: arrayValue};
        }
        if (denyReasonFromState.length > 0) {
          denyReasonFromState.pop();
        }
        denyReasonFromState.push(denyObj);
        updateMap(denyObj.detailId,denyObj.note)
        setDenyReason(denyReasonFromState);
        if (fromBillReview) getDenyReason(denyReasonFromState); 
  }

  const onChangeHandler = (id, value) => {
      setCurrentIndex(index + 1);

      setSearchFields({ ...searchFields, [id]: value });

      DenyArray.push(value);
      let denyObj;
      if (fromBillReview) {
        denyObj = { detailId: index + 1, note: DenyArray[0] };
      } else {
        denyObj = { detailId: index + 1, note: 'Deny-' + DenyArray[0] };
      }

      if (previousIndex == currentIndex) {
        denyReasonFromState.pop();
      }

      denyReasonFromState.push(denyObj);
      setPreviousIndex(index + 1);

      setDenyReason(denyReasonFromState);
      if (fromBillReview) getDenyReason(denyReasonFromState);
  };

  const addValueFromState = (state, value) => {
    return updateInputs(state, inputs.concat(value));
  };

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 2 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };
  const onChangeDenyReasonsText = (value) =>
  {
      setDenyText(value)
      reasonOtherNote(value)
  }
  const onChangeMTGReasons = (value) =>
  {
      setDenyText(value);
      reasonMTGNote(value);
  }
  const onChangeReasonMisc1 = (value) =>
  {
      setDenyText(value);
      setCode1(value);
  }
  const onChangeReasonMisc2 = (value) =>
  {
      setDenyText(value);
      setCode2(value);
  }
  const updateInputs = (state, inputs) => {
    return {
      ...state,
      inputs,
    };
  };
  if (index === selected) {
    return (
      <>
          <div style={{width: '200px'}}>
        <Select
          isMulti
          closeMenuOnSelect={true}
          isClearable={true}
          components={{Menu}}
          menuPortalTarget={document.body}
          placeholder="choose reason..."
          value={selectedDenyItems}
          options={billDetailDropdownData}
          menuShouldScrollIntoView={false}
          onChange={onSelectDenyItemsHandler}
          styles={{
            singleValue: styles =>
            omit(styles, [
                'position',
                'transform',
              ]),
              menu: styles => ({
                ...styles,
                whiteSpace: 'normal',
                width: '1050px',
                right: 0,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
        />
          {selectedBill && selectedBill[0].billState === "NY"
          && selectedBill[0].juris==="WC" && getUpdatedMap().size > 0 && getUpdatedMap().get(index+1)!==undefined
          && (getUpdatedMap().get(index+1)).length>0?
        <BillDetailDenyMisc
          reasonOtherNote={onChangeDenyReasonsText}
          placeholder="Reason"
          id="reasonOther1"
          reasonCode={reasonCode1}
          miscCode={onChangeReasonMisc1}
          reasonMTGNote={onChangeMTGReasons}
          /> : null}
          {selectedBill && selectedBill[0].billState === "NY"
          && selectedBill[0].juris==="WC" && getUpdatedMap().size > 0 && getUpdatedMap().get(index+1)!==undefined
          && (getUpdatedMap().get(index+1)).length>1?
        <BillDetailDenyMisc
        reasonOtherNote={onChangeDenyReasonsText}
          placeholder="Reason"
          id="reasonOther2"
          reasonCode={reasonCode2}
          miscCode={onChangeReasonMisc2}
          reasonMTGNote={onChangeMTGReasons}
          /> : null}
        </div>
      </>
    );
  } else {
    return null;
  }
};

BillDetailDeny.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  billDetailDropdownData: PropTypes.array,
  getBillDetailDropdownData: PropTypes.func,
  resetBillDetailDropdownData: PropTypes.func,
  alert: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  //DenyArray: PropTypes.array,
  billId: PropTypes.number,
  claimant: PropTypes.string,
  getDenyReason: PropTypes.func,
  fromBillReview: PropTypes.bool,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);

  return {
    billDetailDropdownData: billReviewState.billDetailDropdownData,
    denyReasonFromState: billReviewState.denyReasons
      ? billReviewState.denyReasons
      : [],
    userId: user.user_id,
    profile: billReviewInitialData.currentProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  getBillDetailDropdownData: data =>
    dispatch(billOperationsOperations.getBillDetailDropdownData(data)),
  resetBillDetailDropdownData: () =>
    dispatch(billOperationsActions.resetBillDetailDropdownData()),

  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  setDenyReason: reason =>
    dispatch(billOperationsActions.setDenyReason(reason)),
  // setDenyReason2: reason2 =>
  //   dispatch(billOperationsActions.setDenyReason2(reason2)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillDetailDeny);
