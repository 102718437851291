import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppInput from 'shared/components/input/AppInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import DashboardButton from './DashboardButton';

const Fast360SPT = ({
  specialTransportCategory,
  appointmentType,
  relationShipType,
  selectedService,
  userId,
  requiredServiceData
}) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);

  const onTabRadioChangeHandler = event => {
    selectedService.locationSame = event.target.value;
    setSelectedItem({ selectedService });
  };

  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);

  const onChangeHandlerSptType = (e) => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.specialTransportationCategoryId = target.value;
    setSelectedItem({ selectedService });
  };

  const onChangeHandlerRelationshipType = (id, value) => {
    selectedService.addPassRelation = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerAppType = (e) => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.apptType = name;
    selectedService.apptTypeId = target.value;
    if (target.value === '22' || target.value === '23' || target.value === '24' || target.value === '25' || target.value === '26') {
      selectedService.highPriority = true;
    }
    setSelectedItem({ selectedService });
  };
  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }

    setSelectedItem({ selectedService });
  };
  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
    }
    if (name === 'passengerNameUI') {
      selectedService.addPassName = value;
    }
    if (name === 'weightUI') {
      selectedService.addPassWeight = value;
    }
    if (name === 'feetUI') {
      selectedService.addPassHeightFeet = value;
    }
    if (name === 'inchesUI') {
      selectedService.addPassHeightInch = value;
    }

    if (name === 'passengerPhone') {
      selectedService.addPassPhone = formatPhoneNum(value);
    }

    if (name === 'recurring') {
      selectedService.recurring =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subService'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerSptType(e)}
                placeholder="Choose..."
                value={selectedService?.specialTransportationCategoryId || ''}
                className={selectedService?.specialTransportationCategoryId ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {specialTransportCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                type="checkbox"
                name="recurring"
                onChange={handleInputChange}
                checked={selectedService?.recurring}
              />{' '}
              Recurring Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date oF Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'dos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
              />
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>Appt Type: </Label>
            <Input
              type="select"
              className="dropdown"
              onChange={e => onChangeHandlerAppType(e)}
              placeholder="Choose..."
              value={selectedService?.apptTypeId || ''}
              id="subService"
            >
              <option value="default">Choose...</option>
              {appointmentType.map((id, index) => (
                <option key={index} value={id.id}>
                  {id.value}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="1"></Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Additional Passenger: </Label>
            <Input
              id="passengerNameUI"
              name="passengerNameUI"
              onChange={handleInputChange}
              value={selectedService?.addPassName || ''}
            />
          </Col>
          <Col sm="2">
            <Label>Weight: </Label>
            <Input
              id="weightUI"
              name="weightUI"
              onChange={handleInputChange}
              value={selectedService?.addPassWeight || ''}
            />
          </Col>
          <Col sm="6">
            <Label>Height: </Label>
            <Row>
              <Col sm="3">
                <Input
                  id="feetUI"
                  name="feetUI"
                  onChange={handleInputChange}
                  value={selectedService?.addPassHeightFeet || ''}
                />
              </Col>
              <Col sm="3">
                <Input
                  id="inchesUI"
                  name="inchesUI"
                  onChange={handleInputChange}
                  value={selectedService?.addPassHeightInch || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Phone: </Label>
            <Input
              name="passengerPhone"
              onChange={handleInputChange}
              value={selectedService?.addPassPhone || ''}
              placeholder="(xxx)xxx-xxxx"
            />
          </Col>
          <Col sm="3">
            <Label>Relationship: </Label>
            <AppInput
              id="relationship"
              type="select"
              changeHandler={onChangeHandlerRelationshipType}
              option={{
                value: 'id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={selectedService?.addPassRelation || ''}
              data={relationShipType}
            />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};
Fast360SPT.propTypes = {
  specialTransportCategory: PropTypes.array,
  relationShipType: PropTypes.array,
  appointmentType: PropTypes.array,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    specialTransportCategory: initialData.specialTransportCategory,
    appointmentType: initialData.appointmentType,
    relationShipType: initialData.relationShipType,
    userId: user.id,
  };
};
const mapDispatchToProps = dispatch => ({
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360SPT);
