import React from 'react';

const ListErrors = (props) => {
    const errors = props.errors;
    if (errors) {
        return (
            <ul className="error-messages">
                {
                    errors.map((error, index) => {
                        return (
                            <li key={index}>
                                {error}
                            </li>
                        );
                    })
                }
            </ul>
        )
    } else {
        return null;
    }
}

export default ListErrors;