import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, FormGroup, Col, Input } from 'reactstrap';

import { commonOperations } from 'common/store';

import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';

import { billOperationsOperations, billOperationsSelectors } from '../store';

import { nurseAuditsType, codeReviewsType } from '../utils/constantUtils';

class ModifyBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      note: '',
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  onInputChangeHandler = event => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  getBody() {
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <Input
              name="note"
              id="note"
              value={this.state.note}
              onChange={this.onInputChangeHandler}
              type="textarea"
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  getFooter() {
    return (
      <Button type="button" color="primary" onClick={this.handleSave}>
        SAVE
      </Button>
    );
  }

  handleClick = () => {
    const { reviewType, selectedAdjusterName } = this.props;

    if (reviewType !== nurseAuditsType && reviewType !== codeReviewsType) {
      return;
    }

    if (selectedAdjusterName === 'Generic List') {
      this.props.alert('You cannot modify bills from the Generic List');
      return;
    }

    this.toggle();
  };

  handleSave = () => {
    this.toggle();
    this.modifyBills(this.props.selectedBills,this.state.note);
  };

  modifyBills = (bills,notes) => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
    } = this.props;
    this.props.modifyBills({
      currentUserId,
      profile,
      selectedAdjusterId,
      name: selectedAdjusterName,
      note:notes,
      reviewType,
      selectedBills: bills,
    });
  };

  resetModal = () => {
    this.setState({
      note: '',
    });
  };

  render() {
    return (
      <>
        <PopupModal
          content={this.getBody()}
          title="NOTES"
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          footerContent={this.getFooter()}
          onClosed={this.resetModal}
          size="lg"
        />
        <Button
          type="button"
          onClick={this.handleClick}
          disabled={this.props.disabled}
          style={{ width: '100%' }}
          color="primary"
        >
          MODIFY
        </Button>
      </>
    );
  }
}

ModifyBill.propTypes = {
  alert: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  bills: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  selectedAdjusterId: PropTypes.number.isRequired,
  selectedBills: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  return {
    bills: approveBillState.bills,
    profile: initialData.currentProfile,
    reviewType: approveBillState.reviewType,
    selectedAdjusterId: approveBillState.selectedAdjusterId,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
    selectedBills: approveBillState.selectedBills,
    currentUserId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  modifyBills: data => dispatch(billOperationsOperations.modifyBills(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyBill);
