import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { assign, isEmpty } from 'lodash';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import AppInput from 'shared/components/input/AppInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';
import DashboardButton from './DashboardButton';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  TabPane,
  TabContent,
  Input,
  Label,
  CustomInput,
  Nav,
  NavItem,
  NavLink, Card
} from 'reactstrap';
import classnames from 'classnames';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
const hitchtrue = '1';
const hitchfalse = '0';
const owned = '1';
const rental = '0';
const locSame = '1';
const locDif = '0';
const okMod = '1';
const noMod = '0';
const hoaY = '1';
const hoaN = '0';

const Fast360MOD = ({ modificationCategory,
  homeType,
  homeStructure,
  bodyType,
  selectedService,
  claimantAddress,
  userId,
  zipList,
  getZipList,
  resetZipList,
  fromExt,
  modPO,
  selectedMod,
  isFromLanding,
  dataFromWorklist,
  updatePatientZip,
  patientInfo,
  requiredServiceData }) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const [activeTab, setActiveTab] = useState('1');
  const [poNum, setPoNum] = useState('');
  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);
  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.modCategoryId = target.value;
    setSelectedItem({ selectedService });
  };
  useEffect(() => {
    if (fromExt && selectedMod === 'MOD') {
      setPoNum(modPO.UniquePortalAuthCode)
      selectedService.poNumber = modPO.UniquePortalAuthCode;
    }
  }, [modPO.UniquePortalAuthCode])

  useEffect(() => {
    if (selectedService || isFromLanding || !isEmpty(dataFromWorklist)) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.modCategoryId,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          modService: selectedService?.subService,
          serviceTypeId: selectedService?.modCategoryId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService, isFromLanding, dataFromWorklist]);

  useEffect(() => {
    if (
      selectedService?.dos ||
      selectedService?.subService ||
      isFromLanding ||
      !isEmpty(dataFromWorklist)
    ) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          modService: selectedService?.subService,
          serviceTypeId: selectedService?.modCategoryId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.modCategoryId,
          module: selectedService?.module,
        });
      }
    }
  },
    [selectedService?.dos,
    selectedService?.subService,
      isFromLanding,
      dataFromWorklist,
    ]);
  const onTabRadioSameDiffChangeHandler = event => {
    selectedService.locSameDif = event.target.value;

    if (selectedService?.locSameDif === '1') {
      if (claimantAddress != undefined) {
        selectedService.locationAddress.street1 = claimantAddress.street1;
        selectedService.locationAddress.street2 = claimantAddress.street2;
        selectedService.modlocAddressId = claimantAddress.id;
        selectedService.locationAddress.zipCode.id = claimantAddress.zipCode.id;
        selectedService.locationAddress.zipCode.city = claimantAddress.zipCode.city;
        selectedService.locationAddress.zipCode.state = claimantAddress.zipCode.state;
        selectedService.locationAddress.zipCode.county = claimantAddress.zipCode.county;
        selectedService.locationAddress.zipCode.zip = claimantAddress.zipCode.zip;
        selectedService.locationAddress.zipCodeId = claimantAddress.zipCodeId;
        selectedService.locationAddress.id = claimantAddress.id;
        selectedService.locationAddress.zipCode.timeZone = claimantAddress.timezone;
        selectedService.modlocClaimant = selectedService?.locSameDif === '1' ? true : false;
      }
      else {
        if (isFromLanding) {
          selectedService.locationAddress.street1 = dataFromWorklist.claim?.claimant?.address?.street1;
          selectedService.locationAddress.street2 = dataFromWorklist.claim?.claimant?.address?.street2;
          selectedService.locationAddress.zipCode.city = dataFromWorklist.claim?.claimant?.address?.zipCode?.city;
          selectedService.locationAddress.zipCode.state = dataFromWorklist.claim?.claimant?.address?.zipCode?.state;
          selectedService.locationAddress.zipCode.zip = dataFromWorklist.claim?.claimant?.address?.zipCode?.zip;
          selectedService.modlocAddressId = dataFromWorklist.claim?.claimant?.address?.id;
          selectedService.locationAddress.id = dataFromWorklist.claim?.claimant?.address?.id;
          selectedService.locationAddress.zipCodeId = dataFromWorklist.claim?.claimant?.address?.zipCodeId;
          selectedService.modlocClaimant = selectedService?.locSameDif === '1' ? true : false;
        }
      }
    } else {
      selectedService.modlocAddressId = 0;
      selectedService.locationAddress.street1 = '';
      selectedService.locationAddress.street2 = '';
      selectedService.locationAddress.zipCode.id = '';
      selectedService.locationAddress.zipCode.city = '';
      selectedService.locationAddress.zipCode.state = '';
      selectedService.locationAddress.zipCode.county = '';
      selectedService.locationAddress.zipCode.zip = '';
      selectedService.locationAddress.zipCodeId = '';
      //selectedService.locationAddress.zipId = '';
      selectedService.locationAddress.zipCode.timeZone = '';
      selectedService.modlocClaimant = selectedService?.locSameDif === '1' ? true : false;

    }

    setSelectedItem({ selectedService });
  };
  const setMap = {
    dos: setValidDos,
  }

  const onTabRadioHoaChangeHandler = event => {
    selectedService.hoaYN = event.target.value;
    selectedService.hoaApproval = selectedService?.hoaYN === '1' ? true : false;
    setSelectedItem({ onTabRadioHoaChangeHandler });
  };

  const onTabRadioModYNChangeHandler = event => {
    selectedService.modYN = event.target.value;
    selectedService.modifyOk = selectedService?.modYN === '1' ? true : false;
    setSelectedItem({ selectedService });
  };

  const onTabRadioHitchChangeHandler = event => {
    selectedService.hitchYesNo = event.target.value;
    selectedService.vhHitch = selectedService?.hitchYesNo === '1' ? true : false;
    setSelectedItem({ selectedService });
  };
  const onTabRadioOwnRentChangeHandler = event => {
    selectedService.ownVRent = event.target.value;
    selectedService.ownOrRent = event.target.id;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerHomeType = (e) => {
    const target = e.target;
    selectedService.listelementHometypeId = target.value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerBodyType = (id, value) => {
    selectedService.vhTypeId = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerHomeStructure = (e) => {
    const target = e.target;
    selectedService.listelementHomestructId = target.value;
    setSelectedItem({ selectedService });
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip != null) {
      if (id === 'locZip') {
        selectedService.locationAddress.zipCode.id = selectedZip.code_id;
        selectedService.locationAddress.zipCode.city = selectedZip.chr_city;
        selectedService.locationAddress.zipCode.state = selectedZip.chr_state;
        selectedService.locationAddress.zipCode.county = selectedZip.chr_county;
        selectedService.locationAddress.zipCode.zip = selectedZip.chr_zipcode;
        selectedService.locationAddress.zipCodeId = selectedZip.code_id;
        //selectedService.locationAddress.zipId = selectedZip.code_id;
        selectedService.locationAddress.zipCode.timeZone = selectedZip.chr_timezone;
      } else if (id === 'locZiphm') {
        selectedService.landlordAddress.zipCode.id = selectedZip.code_id;
        selectedService.landlordAddress.zipCode.city = selectedZip.chr_city;
        selectedService.landlordAddress.zipCode.state = selectedZip.chr_state;
        selectedService.landlordAddress.zipCode.county = selectedZip.chr_county;
        selectedService.landlordAddress.zipCode.zip = selectedZip.chr_zipcode;
        selectedService.landlordAddress.zipCodeId = selectedZip.code_id;
        //selectedService.landlordAddress.zipId = selectedZip.code_id;
        selectedService.landlordAddress.zipCode.timeZone = selectedZip.chr_timezone;
      } else if (id === 'locZipHoa') {
        selectedService.hoaAddress.zipCode.id = selectedZip.code_id;
        selectedService.hoaAddress.zipCode.city = selectedZip.chr_city;
        selectedService.hoaAddress.zipCode.state = selectedZip.chr_state;
        selectedService.hoaAddress.zipCode.county = selectedZip.chr_county;
        selectedService.hoaAddress.zipCode.zip = selectedZip.chr_zipcode;
        selectedService.hoaAddress.zipCodeId = selectedZip.code_id;
        //selectedService.hoaAddress.zipId = selectedZip.code_id;
        selectedService.hoaAddress.zipCode.timeZone = selectedZip.chr_timezone;
      }
      setSelectedItem({ selectedService });
    }
    resetZipList();
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'poNumber') {
      selectedService.poNumber = value;
      // setPoNum(value);
    }

    if (name === 'recurringOrder') {
      selectedService.recurringOrder =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'locationName') {
      selectedService.locationAddress.street1 = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'vhYearbuilt') {
      selectedService.vhYearbuilt = value;
    }
    if (name === 'vhMake') {
      selectedService.vhMake = value;
    }
    if (name === 'vhModel') {
      selectedService.vhModel = value;
    }
    if (name === 'vhNnoofmiles') {
      selectedService.vhNnoofmiles = value;
    }
    if (name === 'vhNoofdoors') {
      selectedService.vhNoofdoors = value;
    }

    if (name === 'hmAddress') {
      selectedService.locationAddress.street1 = value;
    }
    if (name === "hmAddress2") {
      selectedService.locationAddress.street2 = value;
    }
    if (name === 'hmYear') {
      selectedService.yearBuilt = value;
    }
    if (name === 'hmSqr') {
      selectedService.sqFoot = value;
    }
    if (name === 'hmStories') {
      selectedService.numStories = value;
    }
    if (name === 'hmBedrooms') {
      selectedService.numBedrooms = value;
    }
    if (name === 'hmBaths') {
      selectedService.numBaths = value;
    }
    if (name === 'hmPeople') {
      selectedService.numPeople = value;
    }
    if (name === 'historic') {
      selectedService.historicDistrict = value;
    }
    if (name === 'basement') {
      selectedService.basement = value;
    }

    if (name === 'hmOwner') {
      selectedService.lanlordName = value;
    }
    if (name === 'hmOwnerPhone') {
      selectedService.landlordPhone = formatPhoneNum(value);
    }

    if (name === 'hmOwnerFax') {
      if (value.length !== 10) {
        selectedService.landlordFax = value;
      } else {
        selectedService.landlordFax = formatPhoneNumber(value);
      }
    }
    if (name === 'hmOwnerEmail') {
      selectedService.landlordEmail = value;
    }
    if (name === 'landlordAddress') {
      selectedService.landlordAddress.street1 = value;
    }
    if (name === 'landlordAddress2') {
      selectedService.landlordAddress.street2 = value;
    }

    if (name === 'hoaName') {
      selectedService.hoaName = value;
    }
    if (name === 'hoaPhone') {
      selectedService.hoaPhone = formatPhoneNum(value);
    }
    if (name === 'hoaFax') {
      if (value.length !== 10) {
        selectedService.hoaFax = value;
      } else {
        selectedService.hoaFax = formatPhoneNumber(value);
      }
    }
    if (name === 'hoaEmail') {
      selectedService.hoaEmail = value;
    }
    if (name === 'hoaAddress1') {
      selectedService.hoaAddress.street1 = value;
    }
    if (name === 'hoaAddress2') {
      selectedService.hoaAddress.street2 = value;
    }

    setSelectedItem({ selectedService });
  };


  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
      setValidDos(true);
    }

    setSelectedItem({ selectedService });
  };

  const subServType = () => {
    if (selectedService?.modCategoryId !== undefined) {
      if (
        (selectedService.modCategoryId === '1090')
      ) {
        return (
          <>
            {' '}
            <Row style={{ marginTop: 5, marginLeft: 1 }}>
              <Col xs="2">
                <Label>Year Built: </Label>
                <Input
                  id="yearBuilt"
                  name="vhYearbuilt"
                  onChange={handleInputChange}
                  value={selectedService?.vhYearbuilt || ''}

                />{' '}
              </Col>
              <Col sm="3">
                <Label>Make:</Label>
                <Input
                  id="vhMake"
                  name="vhMake"
                  onChange={handleInputChange}
                  value={selectedService?.vhMake || ''}

                />{' '}
              </Col>
              <Col sm="3">
                <Label>Model:</Label>
                <Input
                  id="vhModel"
                  name="vhModel"
                  onChange={handleInputChange}
                  value={selectedService?.vhModel || ''}
                />{' '}
              </Col>
              <Col sm="2">
                <Label>No. of Miles:</Label>
                <Input
                  id="vhNnoofmiles"
                  name="vhNnoofmiles"
                  onChange={handleInputChange}
                  value={selectedService?.vhNnoofmiles || ''}
                />{' '}
              </Col>
            </Row>
            <Row style={{ marginTop: 5, marginLeft: 1 }}>
              <Col sm="4">
                <Label>Body Type:</Label>
                <AppInput
                  id="bodyTypeUI"
                  type="select"
                  changeHandler={onChangeHandlerBodyType}
                  option={{
                    value: 'id',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={selectedService?.vhTypeId || ''}
                  data={bodyType}
                />
              </Col>
              <Col sm="2" style={{ marginTop: 29 }}>
                <Label>No. of Doors:</Label>
              </Col>
              <Col sm="1" style={{ marginTop: 27 }}>
                <Input id="vhNoofdoors"
                  name="vhNoofdoors" onChange={handleInputChange}
                  value={selectedService?.vhNoofdoors || ''} />
              </Col>
              <Col sm="2">
                <Label style={{ marginTop: 29 }}>Hitch:</Label>
              </Col>
              <Col sm="3" style={{ marginLeft: 5 }, { marginTop: 30 }}>
                <FormGroup className="form-check-inline">
                  <CustomInput
                    type="radio"
                    label="Yes"
                    id="hitchYes"
                    name="hitchYesNo"
                    value={hitchtrue}
                    checked={selectedService?.hitchYesNo === hitchtrue}
                    onChange={onTabRadioHitchChangeHandler}
                  />
                </FormGroup>
                <FormGroup className="form-check-inline">
                  <CustomInput
                    type="radio"
                    id="hitchNo"
                    name="hitchYesNo"
                    label="No"
                    value={hitchfalse}
                    checked={selectedService?.hitchYesNo === hitchfalse}
                    onChange={onTabRadioHitchChangeHandler}
                  />
                </FormGroup>
              </Col>

            </Row>
          </>
        );
      } else if (selectedService?.modCategoryId === '1091') {
        return (
          <>
            {' '}
            <Nav tabs style={{ marginLeft: 1, marginBottom: 5 }}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Location Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Landlord Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  HOA Details
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" style={{ marginLeft: 10 }}>
                <Row>
                  <Col>
                    <Row>
                      <FormGroup className="form-check-inline">
                        <CustomInput style={{ marginLeft: 10 }}
                          type="radio"
                          label="Owned"
                          id="own"
                          name="ownvRental"
                          value={owned}
                          checked={selectedService?.ownVRent === owned}
                          onChange={onTabRadioOwnRentChangeHandler}
                        />
                      </FormGroup>
                      <FormGroup className="form-check-inline">
                        <CustomInput
                          type="radio"
                          id="Rent"
                          name="ownvRental"
                          label="Rental"
                          value={rental}
                          checked={selectedService?.ownVRent === rental}
                          onChange={onTabRadioOwnRentChangeHandler}
                        />
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <FormGroup className="form-check-inline">
                      <CustomInput
                        type="radio"
                        label="Yes"
                        id="locSame"
                        name="locSame"
                        value={locSame}
                        checked={selectedService?.locSameDif === locSame}
                        onChange={onTabRadioSameDiffChangeHandler}
                      />
                    </FormGroup>
                    <FormGroup className="form-check-inline">
                      <CustomInput
                        type="radio"
                        id="locDiff"
                        name="locDiff"
                        label="No "
                        value={locDif}
                        checked={selectedService?.locSameDif === locDif}
                        onChange={onTabRadioSameDiffChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="5" className="text-right">
                    <Label>Mod Location Same as Claimant Address</Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2" className="text-right"><Label> Location:</Label></Col>
                  <Col xs="3"><Input
                    id="hmAddress"
                    name="hmAddress"
                    onChange={handleInputChange}
                    value={selectedService?.locationAddress.street1 || ''}
                    readOnly={
                      (selectedService?.locSameDif === '1')
                    }
                  />
                  </Col>
                  <Col sm="4"><Input id="hmAddress2"
                    name="hmAddress2"
                    onChange={handleInputChange}
                    value={selectedService?.locationAddress.street2 || ''}
                    readOnly={
                      (selectedService?.locSameDif === '1')}></Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right" ><Label>City/State/Zip:</Label></Col>
                  <Col xs="2" className="text-right">
                    <Label>
                      {selectedService?.locationAddress?.zipCode?.city}{' '}
                      {selectedService?.locationAddress?.zipCode?.state},{' '}
                      {selectedService?.locationAddress?.zipCode?.zip}
                    </Label>
                  </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right">
                    <Label for="locZip">Lookup Zip</Label>
                  </Col>
                  <Col xs="5" className="text-right">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) =>
                        handleFormFields(e, 'locZip', actionType)
                      }
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={e => handleTypeAheadSearch(e)}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      menuIsOpen={zipList.length}
                      value={selectedService?.locationAddress?.zipCode?.zip}
                      closeMenuOnSelect={true}
                      options={
                        zipList.length
                          ? zipList.map(obj =>
                            assign(obj, {
                              label: obj.zip_type_with_county,
                              value: obj.code_id,
                            })
                          )
                          : []
                      }
                      name="locZip"
                      id="locZip"
                      isDisabled={
                        (selectedService?.locSameDif === '1')
                      }
                    /> <span>type in 3+ digits and hit tab to search</span>
                  </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label> Year Built:</Label></Col>
                  <Col xs="3"><Input id="hmYear"
                    name="hmYear"
                    onChange={handleInputChange}
                    value={selectedService?.yearBuilt || ''}> </Input></Col>
                  <Col sm="2" className="text-right"><Label> Home Structure:</Label></Col>
                  <Col sm="3">
                    <Input
                      type="select"
                      className="dropdown"
                      onChange={e => onChangeHandlerHomeStructure(e)}
                      placeholder="Choose..."
                      value={selectedService?.listelementHomestructId || ''}
                      id="homeStructureUI"
                    >
                      <option value="default">Choose...</option>
                      {homeStructure.map((id, index) => (
                        <option key={index} value={id.id}>
                          {id.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label> Home Type:</Label></Col>
                  <Col sm="3">
                    <Input
                      type="select"
                      className="dropdown"
                      onChange={e => onChangeHandlerHomeType(e)}
                      placeholder="Choose..."
                      value={selectedService?.listelementHometypeId || ''}
                      id="homeTypeUI"
                    >
                      <option value="default">Choose...</option>
                      {homeType.map((id, index) => (
                        <option key={index} value={id.id}>
                          {id.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="2" className="text-right"><Label>Square Footage:</Label></Col>
                  <Col sm="2"><Input id="hmSqr"
                    name="hmSqr"
                    onChange={handleInputChange}
                    value={selectedService?.sqFoot || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label> No. Stories:</Label></Col>
                  <Col sm="2"><Input id="hmStories"
                    name="hmStories"
                    onChange={handleInputChange}
                    value={selectedService?.numStories || ''}> </Input></Col>
                  <Col sm="2" className="text-right"><Label> No. Bedrooms:</Label></Col>
                  <Col sm="2"><Input id="hmBedrooms"
                    name="hmBedrooms"
                    onChange={handleInputChange}
                    value={selectedService?.numBedrooms || ''}> </Input></Col>
                  <Col sm="2" className="text-right"><Label> No. Baths:</Label></Col>
                  <Col sm="2"><Input id="hmBaths"
                    name="hmBaths"
                    onChange={handleInputChange}
                    value={selectedService?.numBaths || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label> No. of People:</Label></Col>
                  <Col sm="2"><Input id="hmPeople"
                    name="hmPeople"
                    onChange={handleInputChange}
                    value={selectedService?.numPeople || ''}> </Input></Col>
                  <Col sm="2" className="text-right"><Label> Basement:</Label></Col>
                  <Col sm="1" style={{ marginLeft: 20 }} ><Input name="basement"
                    type="checkbox"
                    onChange={handleInputChange}
                    checked={selectedService?.basement}> </Input></Col>
                  <Col sm="2" className="text-right" style={{ marginLeft: 32 }}><Label> Historic Dist:</Label></Col>
                  <Col sm="1" style={{ marginLeft: 20 }}><Input name="historic"
                    type="checkbox"
                    onChange={handleInputChange}
                    checked={selectedService?.historicDistrict}> </Input></Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="2" className="text-right"><Label>Owner:</Label></Col>
                  <Col xs="4"><Input id="hmOwner"
                    name="hmOwner"
                    onChange={handleInputChange}
                    value={selectedService?.lanlordName || ''}> </Input></Col>
                  <Col sm="3" className="text-right"><Label> OK to Modify:</Label></Col>
                  <FormGroup className="form-check-inline">
                    <CustomInput
                      type="radio"
                      label="Yes"
                      id="okMod"
                      name="modYN"
                      style={{ marginLeft: 10 }}
                      value={okMod}
                      checked={selectedService?.modYN === okMod}
                      onChange={onTabRadioModYNChangeHandler}
                    />
                  </FormGroup>
                  <FormGroup className="form-check-inline">
                    <CustomInput
                      type="radio"
                      id="noMod"
                      name="modYN"
                      label="No"
                      value={noMod}
                      checked={selectedService?.modYN === noMod}
                      onChange={onTabRadioModYNChangeHandler}
                    />
                  </FormGroup>

                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right"><Label>Phone:</Label></Col>
                  <Col xs="4"><Input id="hmOwnerPhone"
                    name="hmOwnerPhone"
                    onChange={handleInputChange}
                    placeholder="(xxx)xxx-xxxx"
                    value={selectedService?.landlordPhone || ''}> </Input></Col>
                  <Col xs="1" className="text-right"><Label>Fax:</Label></Col>
                  <Col xs="4"><Input id="hmOwnerFax"
                    name="hmOwnerFax"
                    onChange={handleInputChange}
                    value={selectedService?.landlordFax || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right"><Label>Email:</Label></Col>
                  <Col sm="4"><Input id="hmOwnerEmail"
                    name="hmOwnerEmail"
                    onChange={handleInputChange}
                    value={selectedService?.landlordEmail || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label> Address:</Label></Col>
                  <Col xs="4"><Input id="landlordAddress"
                    name="landlordAddress"
                    onChange={handleInputChange}
                    value={selectedService?.landlordAddress?.street1 || ''}></Input></Col>
                  <Col sm="4"><Input id="landlordAddress2"
                    name="landlordAddress2"
                    onChange={handleInputChange}
                    value={selectedService?.landlordAddress?.street2 || ''}></Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right" style={{ marginRight: "8px" }}><Label>City/State/Zip:</Label></Col>
                  <Col sm="4">
                    <Label>
                      {selectedService?.landlordAddress?.zipCode?.city}{' '}
                      {selectedService?.landlordAddress?.zipCode?.state}{' '}
                      {selectedService?.landlordAddress?.zipCode?.zip}
                    </Label>
                  </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2">
                    <Label for="locZiphm" className="text-right" >Lookup Zip</Label>
                  </Col>
                  <Col xs="6" className="text-right">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) =>
                        handleFormFields(e, 'locZiphm', actionType)
                      }
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={e => handleTypeAheadSearch(e)}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      menuIsOpen={zipList.length}
                      value={selectedService?.landlordAddress?.zipCode?.zip}
                      closeMenuOnSelect={true}
                      options={
                        zipList.length
                          ? zipList.map(obj =>
                            assign(obj, {
                              label: obj.zip_type_with_county,
                              value: obj.code_id,
                            })
                          )
                          : []
                      }
                      name="locZiphm"
                      id="locZiphm"
                    />
                    <span>type in 3+ digits and hit tab to search</span>
                  </Col>
                </Row>

              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="2" className="text-right"><Label>HOA Name:</Label></Col>
                  <Col xs="4"><Input id="hoaName"
                    name="hoaName"
                    onChange={handleInputChange}
                    value={selectedService?.hoaName || ''}></Input></Col>
                  <Col sm="3" style={{ marginTop: 5 }} className="text-right"><Label>HOA Approval:</Label></Col>
                  <FormGroup className="form-check-inline">
                    <CustomInput
                      type="radio"
                      label="Yes"
                      id="hoaYes"
                      name="hoaYes"
                      style={{ marginLeft: 10 }}
                      value={hoaY}
                      checked={selectedService?.hoaYN === hoaY}
                      onChange={onTabRadioHoaChangeHandler}
                    />
                  </FormGroup>
                  <FormGroup className="form-check-inline">
                    <CustomInput
                      type="radio"
                      id="hoaNo"
                      name="hoaNo"
                      label="No"
                      value={hoaN}
                      checked={selectedService?.hoaYN === hoaN}
                      onChange={onTabRadioHoaChangeHandler}
                    />
                  </FormGroup>

                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right"><Label>Phone:</Label></Col>
                  <Col xs="4"><Input id="hoaPhone"
                    name="hoaPhone"
                    onChange={handleInputChange}
                    placeholder="(xxx)xxx-xxxx"
                    value={selectedService?.hoaPhone || ''}> </Input></Col>
                  <Col xs="1" className="text-right"><Label>Fax:</Label></Col>
                  <Col sm="4"><Input id="hoaFax"
                    name="hoaFax"
                    onChange={handleInputChange}
                    value={selectedService?.hoaFax || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col xs="2" className="text-right"><Label>Email:</Label></Col>
                  <Col sm="4"><Input id="hoaEmail"
                    name="hoaEmail"
                    onChange={handleInputChange}
                    value={selectedService?.hoaEmail || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right"><Label>HOA Address:</Label></Col>
                  <Col xs="4"><Input id="hoaAddress1"
                    name="hoaAddress1"
                    onChange={handleInputChange}
                    value={selectedService?.hoaAddress?.street1 || ''}> </Input></Col>
                  <Col sm="4"><Input id="hoaAddress2"
                    name="hoaAddress2"
                    onChange={handleInputChange}
                    value={selectedService?.hoaAddress?.street2 || ''}> </Input></Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right" style={{ marginRight: "8px" }}><Label>City/State/Zip:</Label></Col>
                  <Col sm="4">
                    <Label>
                      {selectedService?.hoaAddress?.zipCode?.city}{' '}
                      {selectedService?.hoaAddress?.zipCode?.state},{' '}
                      {selectedService?.hoaAddress?.zipCode?.zip}
                    </Label>
                  </Col>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <Col sm="2" className="text-right">
                    <Label for="locZipHoa">Lookup Zip</Label>
                  </Col>
                  <Col xs="6" className="text-right">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) =>
                        handleFormFields(e, 'locZipHoa', actionType)
                      }
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={e => handleTypeAheadSearch(e)}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      menuIsOpen={zipList.length}
                      value={selectedService?.hoaAddress?.zipCode?.zip}
                      closeMenuOnSelect={true}
                      options={
                        zipList.length
                          ? zipList.map(obj =>
                            assign(obj, {
                              label: obj.zip_type_with_county,
                              value: obj.code_id,
                            })
                          )
                          : []
                      }
                      name="locZipHoa"
                      id="locZipHoa"
                    />
                    <span>type in 3+ digits and hit tab to search</span>
                  </Col>

                </Row>

              </TabPane>
            </TabContent>
          </>
        );
      }
    } else {
      return '';
    }
  };


  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subService'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                placeholder="Choose..."
                value={selectedService?.modCategoryId || ''}
                className={selectedService?.modCategoryId ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {modificationCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              // value={poNum || ''}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                type="checkbox"
                name="recurringOrder"
                onChange={handleInputChange}
                checked={selectedService?.recurringOrder}
              />{' '}
              Recurring Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'dos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
        <Row>{subServType()}</Row>
      </FormGroup>
    </>
  );
};

Fast360MOD.propTypes = {
  modificationCategory: PropTypes.array,
  homeType: PropTypes.array,
  homeStructure: PropTypes.array,
  bodyType: PropTypes.array,
  alert: PropTypes.func,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  var claimantAddress;
  if (fast360Info.updatedClaimInfo.claim != undefined) {
    if (fast360Info.updatedClaimInfo.claim.claimant != undefined) {
      if (fast360Info.updatedClaimInfo.claim.claimant.address != undefined) {
        claimantAddress = fast360Info.updatedClaimInfo.claim.claimant.address
      } else {
        claimantAddress = undefined;
      }
    } else {
      claimantAddress = undefined;
    }
  } else {
    claimantAddress = undefined;
  }
  return {
    claimantAddress: claimantAddress,
    modificationCategory: initialData.modificationCategory,
    homeType: initialData.homeType,
    homeStructure: initialData.homeStructure,
    bodyType: initialData.bodyType,
    userId: user.id,
    zipList: fast360Info.zipList,
    patientInfo: fast360Info.patientZip,
  };
};

const mapDispatchToProps = dispatch => ({
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360MOD);
