import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

export default class ExpertFaxTable extends Component {
  getRow(item) {
    return {
      dateCreated: item.upload_date,
      from: '',
      pages: '',
      preview: item.description,
      attach: item.path,
    };
  }

  render() {
    const data = this.props.data.map(item => this.getRow(item));
    return (
      <AppTable
        resizable
        columns={[
          {
            header: 'Date Received',
            field: 'dateCreated',
          },
          {
            header: 'From',
            field: 'from', // TODO
          },
          {
            header: 'Pages',
            field: 'pages', // TODO
          },
          {
            header: 'Preview',
            field: 'preview', // TODO
          },
          {
            header: 'Attach',
            field: 'attach',
          },
        ]}
        data={data}
        autoPagination={true}
      />
    );
  }
}

ExpertFaxTable.propTypes = {
  data: PropTypes.array.isRequired,
};
