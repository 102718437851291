import * as R from 'ramda';

import helper from 'helper/helper';

import {
  findClaimsTransform,
  findBillsTransform,
  initialDataTransform,
  urDecisionTransform,
} from './transform';

import {
  adjustersType,
  mcnsUsersType,
  nurseAuditsType,
  codeReviewsType,
  //paycodeList51,
  // paycodeList50,
} from '../utils/constantUtils';

const findClaims = data => {
  return helper.request
    .post(
      '/SLPWebHistoryHandlerGetClaim',
      findClaimsTransform.toRequestBody(data)
    )
    .then(response => {
      return findClaimsTransform.fromResponseBody(response.data);
    });
};

const findBills = data => {
  return helper.request
    .post(
      '/SLPWebHistoryHandlerGetClaimDetails',
      findBillsTransform.toRequestBody(data)
    )
    .then(response => {
      return findBillsTransform.fromResponseBody(response.data);
    });
};

const getStateBillReviewDocuments = () => {
  return helper.request.get('/loadStateBillDocuments/-1');
};

const getAccessData = async id => {
  const response = await helper.request.get(`/loadView/${id}`);
  return response.data;
};

const getBillReviewData = async userId => {
  const [baseDataResponse, stateDocumentsResponse] = await Promise.all([
    getBaseStateData(userId),
    getStateBillReviewDocuments(),
  ]);

  const profile = getValueByKey(
    baseDataResponse.data.currentProfile,
    'profile'
  );

  const userSessionResponse = await findUserSession({
    profile,
    zebraUserId: userId,
  });

  const sLPUser = await getSLPUser({
    profile,
    zebraUserId: userId,
  });

  const manager = userSessionResponse.data.manager || false;
  const slpUserId = userSessionResponse.data.id || '';

  const baseData = {
    ...baseDataResponse.data,
    currentProfile: profile,
    manager,
    slpUserId,
    sLPUser,
  };

  return initialDataTransform.fromResponseBody(
    baseData,
    stateDocumentsResponse.data
  );
};

const getURDecision = async claim => {
  const response = await helper.request.post(
    '/loadGuiDaoloadArrayData',
    urDecisionTransform.toRequestBody(claim)
  );
  return response.data.length > 0 ? response.data[0] : [];
};

const findApproveBills = async (
  userId,
  name,
  profile,
  adjuster,
  reviewType
) => {
  switch (reviewType) {
    case adjustersType: {
      let currentProfile = profile;
      if (profile === 'Forte') {
        currentProfile = 'Attenta';
      }
      const data = {
        adjuster: adjuster > 0 ? adjuster : null,
        name,
        profile: currentProfile,
        userId,
      };
      const response = await helper.request.post('/BillApprovalData', data);
      return response.data;
    }

    case mcnsUsersType: {
      const data = {
        functionName: 'loadMcnsWorklist',
        zebraUserId: userId,
        userID: adjuster > 0 ? adjuster : null,
        name,
        profile,
      };
      const response = await helper.request.post(
        '/loadGuiDaoloadArrayData',
        data
      );
      return response.data;
    }

    case nurseAuditsType: {
      const data = {
        functionName: 'loadNurseAuditWorklist',
        zebraUserId: userId,
        userID: adjuster > 0 ? adjuster : null,
        name,
        profile,
      };
      const response = await helper.request.post(
        '/loadGuiDaoloadArrayData',
        data
      );
      return response.data;
    }

    case codeReviewsType: {
      const data = {
        functionName: 'loadCodeReviewWorklist',
        zebraUserId: userId,
        userID: adjuster > 0 ? adjuster : null,
        name,
        profile,
      };
      const response = await helper.request.post(
        '/loadGuiDaoloadArrayData',
        data
      );
      return response.data;
    }
    default:
      return [];
  }
};

const getBaseStateData = userId => {
  return helper.request.post('/BaseStateData', { userId });
};

const findUserSession = data => {
  return helper.request.post('/findSession', data);
};

const getAdjusters = data => {
  return helper.request.post('/AdjusterListData', data);
};

const getMcnsUsers = data => {
  return helper.request.post('/GetMcnsUserListData', data);
};
const getNurseAudits = data => {
  return helper.request.post('/GetNuseAuditListData', data);
};
const getCodeReviews = data => {
  return helper.request.post('/GetCodeReviewListData', data);
};

const reverseAdjusterAction = data => {
  const body = {
    billId: data.billId,
    profile: data.profile,
    userId: data.zebraUserId,
  };
  return helper.request.post('/reverseAdjusterAction', body);
};

const getTaskListInitialData = async ({ userId, slpUserId, profile }) => {
  const [
    adjusterResponse,
    mcnsUsersResponse,
    nurseAuditsResponse,
    codeReviewsResponse,
    loadList51Response,
    loadlist50Response,
  ] = await Promise.all([
    getAdjusters({
      userId,
      profile,
    }),
    getMcnsUsers({
      profile,
    }),
    getNurseAudits({
      profile,
    }),
    getCodeReviews({
      profile,
    }),
    // loadPaycodeList51(userId, profile),
    // loadPaycodeList50(userId, profile),
  ]);

  const approveBillAdjuster = R.find(
    item => item.id === slpUserId,
    adjusterResponse.data
  );
  const selectedApproveBillAdjusterId =
    adjusterResponse.data.length > 0 && approveBillAdjuster
      ? adjusterResponse.data[0].id
      : slpUserId;
  const selectedApproveBillAdjusterName = approveBillAdjuster
    ? approveBillAdjuster.name
    : '';
  const selectedRecentActivityAdjuster =
    adjusterResponse.data.length > 0
      ? adjusterResponse.data[0]
      : { id: 0, name: '' };

  return {
    selectedApproveBillAdjusterId,
    selectedApproveBillAdjusterName,
    selectedRecentActivityAdjusterId: selectedRecentActivityAdjuster.id,
    selectedRecentActivityAdjusterName: selectedRecentActivityAdjuster.name,
    approveAdjusters: {
      [adjustersType]: adjusterResponse.data,
      [mcnsUsersType]: transformUsersRawData(mcnsUsersResponse.data),
      [nurseAuditsType]: transformUsersRawData(nurseAuditsResponse.data),
      [codeReviewsType]: transformUsersRawData(codeReviewsResponse.data),
      //[paycodeList51]: loadList51Response,
      //[paycodeList50]: loadlist50Response,
    },
    recentActivityAdjusters: adjusterResponse.data,
  };
};

const loadAORemaining = async data => {
  const body = {
    profile: data.profile,
    zebraUserId: data.currentUserId,
    userId: data.currentUserId,
    selectedBills: data.selectedBills,
    functionName: 'loadAORemaining',
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data : [];
};
const approveBills = async data => {
  switch (data.reviewType) {
    case adjustersType:
    case mcnsUsersType:
    default: {
      const requests = data.selectedBills.map(async billId => {
        const body = {
          billIds: billId,
          profile: data.profile,
          zebId: data.currentUserId,
        };
        const response = await helper.request.post('/ApproveBill', body);
        return response.data;
      });

      return await Promise.all(requests);
    }

    case nurseAuditsType: {
      const body = {
        profile: data.profile,
        userId: data.currentUserId,
        billList: data.selectedBills,
      };
      const response = await helper.request.post(
        '/SaveApproveNurseAuditBills',
        body
      );
      return response.data;
    }

    case codeReviewsType: {
      const body = {
        profile: data.profile,
        userId: data.currentUserId,
        billList: data.selectedBills,
      };
      const response = await helper.request.post(
        '/ApproveCodeReviewBills',
        body
      );
      return response.data;
    }
  }
};

const denyBills = async data => {
  switch (data.reviewType) {
    case adjustersType:
    case mcnsUsersType:
    case nurseAuditsType:
    case codeReviewsType: {
      const body = {
        billIds: data.selectedBills.join(','),
        reason: data.reason,
        profile: data.profile,
        zebId: data.currentUserId,
        denialCode: data.denialCode,
      };
      const response = await helper.request.post('/DenyBills', body);
      return response.data;
    }
    default:
      return [];
  }
};

const pendBills = async data => {
  const body = {
    billIds: data.selectedBills.join(','),
    profile: data.profile,
    zebraUserId: data.currentUserId,
  };
  const response = await helper.request.post('/addPendCodeToBill', body);
  return response.data;
};

const pendBillsWorklist = async data => {
  const body = {
    billIds: data.selectedBills.join(','),
    profile: data.profile,
    zebraUserId: data.currentUserId,
  };
  const response = await helper.request.post(
    '/addPendCodeToBillWorklist',
    body
  );
  return response.data;
};

const unpendCodeOnBill = async data => {
  const body = {
    billId: data.billId,
    profile: data.profile,
    zebraUserId: data.currentUserId,
  };
  const response = await helper.request.post('/unpendCodeOnBill', body);
  return response.data;
};

const unpendCodeOnBills = async data => {
  const body = {
    billIds: data.billIds.join(','),
    profile: data.profile,
    zebraUserId: data.currentUserId,
  };
  const response = await helper.request.post('/unpendCodeOnBills', body);
  return response.data;
};

const correctBills = async data => {
  switch (data.reviewType) {
    case adjustersType:
    case mcnsUsersType: {
      const requests = data.selectedBills.map(async billId => {
        const body = {
          billIds: billId,
          reason: data.reason,
          profile: data.profile,
          zebId: data.currentUserId,
        };
        const response = await helper.request.post('/correctBill', body);
        return response.data;
      });

      return await Promise.all(requests);
    }

    case nurseAuditsType: {
      const body = {
        functionName: 'correctNurseAuditBills',
        billList: data.selectedBills,
        reason: data.reason,
        profile: data.profile,
        zebraUserId: data.currentUserId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      return response.data;
    }

    case codeReviewsType: {
      const body = {
        functionName: 'correctCodeReviewBills',
        billList: data.selectedBills,
        reason: data.reason,
        profile: data.profile,
        zebraUserId: data.currentUserId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      return response.data;
    }
    default:
      return [];
  }
};

const modifyBills = async data => {
  const body = {
    billList: data.selectedBills,
    billNotes: data.note,
    profile: data.profile,
    userId: data.currentUserId,
  };
  switch (data.reviewType) {
    case nurseAuditsType: {
      const response = await helper.request.post(
        '/ModifyNurseAuditBills',
        body
      );
      return response.data;
    }

    case codeReviewsType: {
      const response = await helper.request.post(
        '/ModifyCodeReviewBills',
        body
      );
      return response.data;
    }
    case mcnsUsersType:
    default: {
      const response = await helper.request.post(
        '/ModifyCodeReviewBills',
        body
      );
      return response.data;
    }
  }
};

const getQABills = async object => {
  const qaBillsResponse = await helper.request.post('/LoadQABills', object);
  return qaBillsResponse.data;
};

const findRecentActivityBills = async data => {
  const response = await helper.request.post('/loadAdjusterHistory', data);
  //return response.data;
  return response.data.length > 0 ? response.data : [];
};

const getValueByKey = (data, key) => {
  const resultItem = R.find(item => item.hasOwnProperty(key), data);
  return resultItem ? resultItem[key] : '';
};

const transformUsersRawData = data => {
  return data.map(item => {
    return {
      id: item.fields.key ? parseInt(item.fields.key) : 0,
      name: item.fields.value,
    };
  });
};

const confirmQABills = async data => {
  const response = await helper.request.post('/agreeQaBills', data);
  if (response && response.data === -1) {
    throw new Error('');
  }
  return response.data.length > 0 ? response.data : [];
};

const transferBills = async data => {
  switch (data.reviewType) {
    case adjustersType: {
      const body = {
        billList: data.selectedBills.join(','),
        adjusterId: data.assignedUser,
        profile: data.profile,
        zebraUserId: data.currentUserId,
        groupByType: data.groupByType,
      };
      const response = await helper.request.post('/assignBills', body);
      if (response.data !== 'true') {
        throw new Error(response.data);
      }
      return response.data;
    }

    case mcnsUsersType: {
      const body = {
        functionName: 'transferMcnsBills',
        billList: data.selectedBills,
        profile: data.profile,
        userId: data.assignedUser,
        zebraUserId: data.currentUserId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      if (response.data !== 1) {
        throw new Error(response.data);
      }
      return response.data;
    }

    case nurseAuditsType: {
      const body = {
        functionName: 'transferNurseAuditBills',
        billList: data.selectedBills,
        profile: data.profile,
        userId: data.assignedUser,
        zebraUserId: data.currentUserId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      if (response.data !== 1) {
        throw new Error(response.data);
      }
      return response.data;
    }
    case codeReviewsType:
    default:
      const body = {
        functionName: 'transferCodeReviewBills',
        billList: data.selectedBills,
        profile: data.profile,
        userId: data.assignedUser,
        zebraUserId: data.currentUserId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      if (response.data !== 1) {
        throw new Error(response.data);
      }
      return response.data;
  }
};

const getEDISetupData = async () => {
  const response = await helper.request.get('/SLPWebHistoryHandlerGetEDISetup');
  return response.data.length > 0 ? response.data : [];
};

const getOdgLookupData = async data => {
  const body = {
    functionName: 'loadODGScheduleLookup',
    code: data.cpt,
    icd: data.icd,
    profile: data.profile,
    zebraUserId: data.userId,
  };

  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : [];
};

const getOdgLookupDataByBill = async data => {
  const body = {
    functionName: 'loadODGScheduleLookupByBill',
    billId: data.billId,
    profile: data.profile,
    zebraUserId: data.userId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : [];
};

const getBillLinesData = async data => {
  const body = {
    billId: data.billId,
    sitecode: data.claimant,
    userId: data.userId,
    siteId: data.siteId,
    profile: data.profile,
  };
  const response = await helper.request.post('/loadBillDetail', body);
  return response.data.billDetail && response.data.billDetail.length > 0
    ? response.data
    : [];
};

const getBillDetailDropdownData = async data => {
  const body = {
    billId: data.billId,
    sitecode: data.claimant,
    userId: data.userId,
    siteId: data.siteId,
    profile: data.profile,
  };
  const response = await helper.request.post('/loadBillDetail', body);
  return response.data.denyReasons && response.data.denyReasons.length > 0
    ? response.data.denyReasons
    : [];
};

const postBillLinesData = async data => {
  var notes = data.notes;

  var deny = 'Deny-';

  switch (data.reviewType) {
    case nurseAuditsType: {
      const body = {
        functionName: 'modifyNurseAuditBillDetail',
        note: data.notes,
        profile: data.profile,
        zebraUserId: data.userId,
        detailLines: data.denyReasons,
        billId: data.billId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      return response.data == 1 ? response.data : 0;
    }
    case codeReviewsType: {
      const body = {
        functionName: 'modifyCodeReviewBillDetail',
        note: data.notes,
        profile: data.profile,
        zebraUserId: data.userId,
        detailLines: data.denyReasons,
        billId: data.billId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );

      return response.data == 1 ? response.data : 0;
    }
    default:
      const body = {
        functionName: 'modifyNurseAuditBillDetail',
        note: data.notes,
        profile: data.profile,
        zebraUserId: data.userId,
        detailLines: data.denyReasons,
        billId: data.billId,
      };
      const response = await helper.request.post(
        '/loadGuiDaosaveObjectData',
        body
      );
      return response.data == 1 ? response.data : 0;
  }
};

const postMcnsBills = async data => {
  const body = {
    zebraUserId: data.userId,
    userId: data.userId,
    profile: data.profile,
    billNotes: data.notes,
    billId: data.billId,
    reductionMap: data.mcnsEditvalues,
  };
  const response = await helper.request.post(
    '/SaveObjectapproveMcnsBill',
    body
  );

  return response.data.length > 0 ? response.data : [];
};

const postCodeReviewBills = async data => {
  const body = {
    zebraUserId: data.userId,
    userId: data.userId,
    profile: data.profile,
    billNotes: data.notes,
    billId: data.billId,
    reductionMap: data.mcnsEditvalues,
  };
  const response = await helper.request.post(
    '/SaveObjectapproveCodeReviewBill',
    body
  );

  return response.data.length > 0 ? response.data : [];
};

const savePaycode = async data => {
  const body = {
    functionName: 'savePaycode',
    zebraUserId: data.userId,
    userId: data.userId,
    listId: data.listId,
    oldPaycode: data.oldPaycode,
    newPaycode: data.listId,
    billId: data.billId,
    profile: data.profile,
  };
  const response = await helper.request.post('/loadGuiDaosaveObjectData', body);
  return response.data.length > 0 ? response.data : [];
};

const postBillReviewData = async data => {
  const body = {
    billId: data.billId,
    zebraUserId: data.userId,
    billLines: data.billRevEditValues,
    note: data.notes,
    userId: data.userId,
    profile: data.profile,
  };
  const response = await helper.request.post('/saveBillDetail', body);
  return response.data.length > 0 ? response.data : [];
};
const postBillReviewAoData = async data => {
  const body = {
    billId: data.billId,
    zebraUserId: data.userId,
    billLines: data.billRevEditValues,
    //note: data.notes,
    userId: data.userId,
    profile: data.profile,
    slpUserId: data.slpUserId,
  };
  const response = await helper.request.post('/saveAOFields', body);
  return response.data.length > 0 ? response.data : [];
};

const getSearchPPOData = async data => {
  const body = {
    tin: data.providerTIN,
    billId: data.billID,
    profile: data.profile,
    userId: data.userId,
  };
  const response = await helper.request.post('/loadPPOHistory', body);
  return response.data.length > 0 ? response.data : [];
};
const postAddManageBillData = async data => {
  const body = {
    searchName: data.searchName,
    hidePrinted: data.checkBoxVal,
    statusId: data.billStatus,
    clientId: data.site,
    searchUserId: data.userId,
    stateId: data.state,
    searchId: data.id ? data.id : -1,
    ZebUserid: parseInt(data.user) ? data.user : 0,
  };
  const response = await helper.request.post('/SaveBillSearchObjectData', body);
  return response.data;
};

const deleteBillLines = async selectedId => {
  const body = {
    id: selectedId,
  };
  const response = await helper.request.post(
    '/deleteBillSearchObjectData',
    body
  );
  return true;
};

const getClientsPPOData = async data => {
  const body = {
    billId: data.billID,
    profile: data.profile,
    userId: data.userId,
  };
  const response = await helper.request.post('/loadPPOHistorySetting', body);
  return response.data.length > 0 ? response.data : [];
};

const sendProfileDrodownData = async data => {
  const body = {
    userId: data.userId,
    slpprofile: data.currentProfile,
  };
  const response = await helper.request.post('/saveProfile', body);
  return response.data.length > 0 ? response.data : [];
};

const getStartDateData = async data => {
  const body = {
    site_id: data.siteType,
    userId: data.userId,
  };
  const response = await helper.request.post('/getInvoiceStart', body);
  return response.data;
};

const getSiteOptionsData = async data => {
  const body = {
    siteid: data.siteType,
    profile: data.profile,
  };
  const response = await helper.request.post('/loadOptions', body);
  return response.data.length > 0 ? response.data : [];
};

const getCashPopulateData = async data => {
  const body = {
    action: 'generateInvoice',
    siteid: data.billStatus,
    startdate: data.entryDate,
    date: data.endDate,
    email: data.email,
    amount: data.amount,
    options: data.checkedItemId,
    userId: data.userId,
  };
  const response = await helper.request.post('/generateInvoice', body);
  return response.data.length > 0 ? response.data : [];
};

const getFeeLookupData = async data => {
  var fullDate = data.startDate;
  var day = fullDate.getDate();
  var month = fullDate.getMonth() + 1;

  var year = fullDate.getFullYear();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var modifiedDate = month + '/' + day + '/' + year;

  const body = {
    action: 'lookupfeeschedule',
    proccode: data.searchFieldTwo,
    date: modifiedDate,
    zipcode: data.searchFieldOne,
    profile: data.profile,
    userId: data.userId,
  };
  const response = await helper.request.post('/lookupfeeschedule', body);
  return response.data != null ? response.data : {};
};

const getLookupfeescheduleNPI = async data => {
  // var fullDate = data.startDate;
  // var day = fullDate.getDate();
  // var month = fullDate.getMonth() + 1;

  // var year = fullDate.getFullYear();
  // if (day < 10) {
  //   day = '0' + day;
  // }
  // if (month < 10) {
  //   month = '0' + month;
  // }
  // var modifiedDate = month + '/' + day + '/' + year;

  const body = {
    // action: 'lookupfeescheduleNPI',
    svcCode: data.searchFieldTwo,
    svcDate: data.startDate,
    //zipcode: data.searchFieldOne,
    profile: data.profile,
    //userId: data.userId,
    tin: data.searchFieldThree
  };
  const response = await helper.request.post('/lookupfeescheduleNPI', body);
  return response.data != null ? response.data : {};
};

// const getFeeLookupDataUC = async data => {
//   var fullDate = data.startDate;
//   var day = fullDate.getDate();
//   var month = fullDate.getMonth() + 1;

//   var year = fullDate.getFullYear();
//   if (day < 10) {
//     day = '0' + day;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }
//   var modifiedDate = month + '/' + day + '/' + year;

//   const body = {
//     action: 'lookupfeeschedule',
//     proccode: data.searchFieldTwo,
//     date: modifiedDate,
//     zipcode: data.searchFieldOne,
//     profile: data.profile,
//     userId: data.userId,
//   };

//   const response = await helper.request.post('/lookupfeeschedule', body);

//   return response.data.uc.length > 0 ? response.data.uc : [];
// };

// const getFeeLookupDataUHUC = async data => {
//   var fullDate = data.startDate;
//   var day = fullDate.getDate();
//   var month = fullDate.getMonth() + 1;

//   var year = fullDate.getFullYear();
//   if (day < 10) {
//     day = '0' + day;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }
//   var modifiedDate = month + '/' + day + '/' + year;

//   const body = {
//     action: 'lookupfeeschedule',
//     proccode: data.searchFieldTwo,
//     date: modifiedDate,
//     zipcode: data.searchFieldOne,
//     profile: data.profile,
//     userId: data.userId,
//   };

//   const response = await helper.request.post('/lookupfeeschedule', body);

//   return response.data.uhuc.length > 0 ? response.data.uhuc : [];
// };

// const getFeeLookupDataMed = async data => {
//   var fullDate = data.startDate;
//   var day = fullDate.getDate();
//   var month = fullDate.getMonth() + 1;

//   var year = fullDate.getFullYear();
//   if (day < 10) {
//     day = '0' + day;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }
//   var modifiedDate = month + '/' + day + '/' + year;

//   const body = {
//     action: 'lookupfeeschedule',
//     proccode: data.searchFieldTwo,
//     date: modifiedDate,
//     zipcode: data.searchFieldOne,
//     profile: data.profile,
//     userId: data.userId,
//   };

//   const response = await helper.request.post('/lookupfeeschedule', body);

//   return response.data.med.length > 0 ? response.data.med[0].state : [];
// };

// const getFeeLookupDataSsDesc = async data => {
//   var fullDate = data.startDate;
//   var day = fullDate.getDate();
//   var month = fullDate.getMonth() + 1;

//   var year = fullDate.getFullYear();
//   if (day < 10) {
//     day = '0' + day;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }
//   var modifiedDate = month + '/' + day + '/' + year;

//   const body = {
//     action: 'lookupfeeschedule',
//     proccode: data.searchFieldTwo,
//     date: modifiedDate,
//     zipcode: data.searchFieldOne,
//     profile: data.profile,
//     userId: data.userId,
//   };

//   const response = await helper.request.post('/lookupfeeschedule', body);

//   return response.data.state.length > 0
//     ? response.data.state[0].ssDescription
//     : [];
// };

const getFeeLookupDataByZip = async data => {
  const body = {
    functionName: 'slpZipLookup',
    zipcode: data.searchFieldOne,
    profile: data.profile,
    zebraUserId: data.zebraUserId,
  };

  const response = await helper.request.post(
    '/loadGuiZebraInterfaceloadArrayData',
    body
  );

  return response.data.obj.length > 0 ? response.data.obj : [];
};

const getPrintEORDropdownUserData = async data => {
  const body = {
    currentProfile: data.profile,
  };

  const response = await helper.request.post('/getEorUserData', body);

  return response.data.length > 0 ? response.data : [];
};

const getPrintEORData = async data => {
  const body = {
    billIDStart: data.billIDStart,
    billIDEnd: data.billIDEnd,
    // zebraUserId: data.zebraUserId,
    user: data.user,
  };

  var pdf_srange = 'pdf_srange=' + data.billIDStart;
  var pdf_erange = 'pdf_erange=' + data.billIDEnd;

  var username = 'username';

  var Url = 'https://slp.yorkrisk.com/eor.php?' + pdf_srange + '&' + pdf_erange;

  // pdf_srange=55555
  // &pdf_erange=555556'
  // &username=medusind-att-0
  // &site=&rangetype=Range
  // &printtype=Draft
  // &orderby=B
  // &orderbydetail=L
  // &script=Y
  // &profile=Forte
  // &userid=10229
  // &skipdrafts=0
  // &skipreprints=0
  // &printflags=0
  // &printeop=0
  // &batch=

  const response = await helper.request.get(Url);

  return response.data.obj.length > 0 ? response.data.obj : [];
};

const getPrintReportsData = async ({ profile, userId }) => {
  const response = await helper.request.post('/getMonthlyReportSetup', {
    profile,
    userId,
  });
  const { data } = response || {};

  return data || {};
};

const getEmployerList = async data => {
  const body = {
    actionName: 'lookupEmployer',
    profile: data.profile,
    userId: data.userId,
    employer: data.employer,
  };

  const response = await helper.request.post('/getMonthlyReportLookups', body);
  return response.data;
};

const getClaimList = async data => {
  const body = {
    actionName: 'lookupClaim',
    profile: data.profile,
    userId: data.userId,
    claim: data.claim,
  };

  const response = await helper.request.post('/getMonthlyReportLookups', body);
  return response.data;
};

const getProviderList = async data => {
  const body = {
    actionName: 'lookupProvider',
    profile: data.profile,
    userId: data.userId,
    provider: data.provider,
  };

  const response = await helper.request.post('/getMonthlyReportLookups', body);
  return response.data;
};

const getClaimantList = async data => {
  const body = {
    actionName: 'lookupClaimant',
    profile: data.profile,
    userId: data.userId,
    claimant: data.claimant,
  };

  const response = await helper.request.post('/getMonthlyReportLookups', body);
  return response.data;
};

const getGridSettings = async data => {
  const body = {
    functionName: 'loadGridSetting',
    gridName: data.gridName,
    userId: data.userId,
    moduleId: data.moduleId,
    profile: data.profile,
    zebraUserId: data.zebraUserId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 && response.data[0] !== null
    ? response.data[0]
    : {};
};

const changeGridSettings = async data => {
  const body = {
    ...data,
    functionName: 'saveGridSetting',
  };
  const response = await helper.request.post('/loadGuiDaosaveObjectData', body);
  return response.data.length > 0 ? response.data[0] : {};
};

// FIXME: Doesn't look like API is even checking userId and profile currently
const getPpoLink = async data => {
  const body = {
    functionName: 'getLink',
    zebraLink: 'FIND PPOs',
    profile: data.profile,
    zebraUserId: data.userId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : [];
};

const loadMoreInfo = async data => {
  const body = {
    functionName: 'loadAOMoreInfoz3',
    zebraUserId: data.zebraUserId,
    userId: data.userId,
    claimId: data.claimId,
    profile: data.profile,
    billId: data.billId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : [];
};

const saveClientRecords = async body => {
  const response = await helper.request.post(
    '/SLPWebHistoryHandlerSaveNote',
    body
  );
  return response.data;
};

const deleteReferralDocuments = async data => {
  const body = {
    documentId: data.documentId,
  };

  const response = await helper.request.post('/updateDocumentFiles', body);

  return response.data;
};

const loadReferralDocuments = async data => {
  const body = {
    profile: data.profile,
    referralserviceid: data.billId,
    referralid: 0,
    tablename: data.tableName,
  };

  const response = await helper.request.post('/loadDocumentsBRReferral', body);
  return response.data;
};

const retrieveReferralDocument = async data => {
  const body = {
    fileName: data.fileName,
    description: data.description,
  };

  const response = await helper.request.post('/retrieveFile', body);
  return response.data;
};

const confirmDemandBills = async data => {
  const response = await helper.request.post('/loadGuiDaosaveObjectData', data);
  if (response && response.data === -1) {
    throw new Error('');
  }
  return response.data.length > 0 ? response.data : [];
};

const loadDemandDocuments = async data => {
  const body = {
    action: 'loadDocumentsBRDemand',
    profile: data.profile,
    referralserviceid: data.billId,
    referralid: 0,
    tablename: `${data.profile}_demand_documentation`,
  };

  const response = await helper.request.post('/loadDocumentsBRReferral2', body);
  return response.data;
};

const demandFileUpload = async data => {
  const body = {
    filedata: data.fileData,
    filedescription: data.fileName,
    Filename: data.fileName,
    fileprivate: 'false',
    filetype: 0,
    record_id: data.billId,
    sessionId: data.sessionId,
    table_name: `ancillary.${data.profile}_demand_documentation`,
    docNote: data.docNote,
    upload: 'submit Query',
  };

  const response = await helper.request.post('/uploadFiles', body);
  return response;
};

const loadDeleteDemandDocuments = async data => {
  const body = {
    documentId: data.docId,
  };

  const response = await helper.request.post('/updateDocumentFiles', body);
  return response.data;
};

const retriveDemandDocument = async data => {
  const body = {
    fileName: data.fileName,
    description: data.description,
  };

  const response = await helper.request.post('/retrieveFile', body);
  return response.data;
};

const loadReferralEmailTemplates = async data => {
  const body = {
    functionName: 'loadReferralEmailTemplates',
    profile: data.profile,
    zebraUserId: data.currentUserId,
    intBillId: data.billId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data;
};

const loadReferralEmailTemplatesInjury = async data => {
  const body = {
    functionName: 'loadReferralEmailTemplatesInjury',
    profile: data.profile,
    zebraUserId: data.currentUserId,
    intInjuryId: data.injuryId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data;
}

const loadSpecialties = async data => {
  const body = {
    functionName: 'loadSpecialties',
    profile: data.profile,
    zebraUserId: data.currentUserId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data;
};

const sendEmail = async data => {
  //TODO replace with real API
  const response = await helper.request.post('/sendReferralEmail', data);
  if (response.data != 1) {
    throw new Error(response.data);
  }
  return response.data;
};

const getSLPUser = async data => {
  const body = {
    functionName: 'loadSLPUserAndAccess',
    profile: data.profile,
    zebraUserId: data.zebraUserId,    
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : {};
};

const addPendCodeToBill = async data => {
  const body = {
    billIds: data.billIds,
    profile: data.profile,
    zebraUserId: data.currentUserId + '',
  };
  const response = await helper.request.post('/addPendCodeToBill', body);
  if (response.data !== '1') {
    throw new Error(response.data);
  }
  return response.data;
};

const sendRereviewNote = async data => {
  let currentProfile = data.profile;
  if (data.profile === 'Forte') {
    currentProfile = 'Attenta';
  }
  const body = {
    functionName: 'insertBillStatus',
    profile: currentProfile,
    zebraUserId: data.currentUserId,
    billList: data.billList,
  };

  const response = await helper.request.post('/loadGuiDaosaveObjectData', body);
  return response.data;
};

const getStatusHistoryData = async data => {
  const body = {
    billId: data.billId,
    profile: data.profile,
    userId: data.userId,
  };
  const response = await helper.request.post('/buildStatusGrid', body);
  return response.data.length > 0 ? response.data : [];
};

const sendStatusListDropdownData = async data => {
  const body = {
    billId: data.billId,
    StatusId: data.statusId,
    profile: data.profile,
    userId: data.userId,
  };
  const response = await helper.request.post('/SaveBillStatus', body);
  return response.data.length > 0 ? response.data : [];
};

const sendUnPendData = async data => {
  const body = {
    functionName: 'saveUserFlagUnpendList',
    flagList: data.checkedItems,
    billId: data.billId,
    profile: data.profile,
    zebraUserId: data.userId,
  };
  const response = await helper.request.post('/loadGuiDaosaveObjectData', body);
  return response.data.length > 0 ? response.data : [];
};

const fileUpload = async data => {
  const body = {
    filedata: data.fileData,
    filedescription: data.fileName,
    Filename: data.fileName,
    fileprivate: 'false',
    filetype: 0,
    record_id: data.recordId,
    sessionId: data.sessionId,
    table_name: `ancillary.${data.tableName}`,
    upload: 'submit Query',
  };

  const response = await helper.request.post('/uploadFiles', body);

  return response;
};
const loadBillClaimNotes = async data => {
  const response = await helper.request.post('/LoadNotes', data);
  return response;
};

const loadCorrectionReasons = async data => {
  const body = {
    functionName: 'loadCorrectionReasons',
    profile: data.profile,
    zebraUserId: data.currentUserId
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  debugger;
  return response.data;
};

export default {
  getAccessData,
  findClaims,
  findBills,
  getBillReviewData,
  getURDecision,
  findApproveBills,
  getTaskListInitialData,
  transferBills,
  getEDISetupData,
  getOdgLookupData,
  getOdgLookupDataByBill,
  getBillLinesData,
  getBillDetailDropdownData,
  postBillLinesData,
  getSearchPPOData,
  postAddManageBillData,
  deleteBillLines,
  getClientsPPOData,
  getStartDateData,
  getSiteOptionsData,
  getCashPopulateData,
  getFeeLookupData,
  getLookupfeescheduleNPI,
  getFeeLookupDataByZip,
  // getFeeLookupDataUC,
  // getFeeLookupDataUHUC,
  // getFeeLookupDataMed,
  // getFeeLookupDataSsDesc,
  getPrintEORData,
  getPrintEORDropdownUserData,
  getPrintReportsData,
  getEmployerList,
  getClaimList,
  getProviderList,
  getClaimantList,
  approveBills,
  denyBills,
  pendBills,
  pendBillsWorklist,
  unpendCodeOnBill,
  unpendCodeOnBills,
  correctBills,
  modifyBills,
  confirmQABills,
  getQABills,
  getGridSettings,
  changeGridSettings,
  findRecentActivityBills,
  reverseAdjusterAction,
  getPpoLink,
  loadMoreInfo,
  saveClientRecords,
  deleteReferralDocuments,
  loadReferralDocuments,
  retrieveReferralDocument,
  confirmDemandBills,
  loadDemandDocuments,
  demandFileUpload,
  loadDeleteDemandDocuments,
  retriveDemandDocument,
  loadReferralEmailTemplates,
  loadReferralEmailTemplatesInjury,
  loadSpecialties,
  sendEmail,
  getSLPUser,
  addPendCodeToBill,
  sendRereviewNote,
  getStatusHistoryData,
  sendStatusListDropdownData,
  sendUnPendData,
  sendProfileDrodownData,
  postMcnsBills,
  postCodeReviewBills,
  postBillReviewData,
  fileUpload,
  savePaycode,
  loadAORemaining,
  postBillReviewAoData,
  loadBillClaimNotes,
  loadCorrectionReasons,
};
