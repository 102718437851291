import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Row, Col, FormGroup, Input } from 'reactstrap';

import {
  administrationActions,
  administrationOperations,
  administrationSelectors,
} from '../store';
import {
  ADJUSTER_MAPPING_MODULE,
  ADJUSTER_LIST,
  CLAIM_NUMBER_LIST,
  BILL_LIST,
} from '../store/constants';

import UserAdjusterMappingChild from './UserAdjusterMappingChild';
import UserAdjusterMappingSearchModal from './UserAdjusterMappingSearchModal';

import PopupModal from 'shared/components/PopupModal';

const adjusterColumns = [
  {
    header: 'Adjuster Name',
    field: 'value',
  },
  {
    header: 'selected',
    field: 'checked',
  },
];

const claimColumns = [
  {
    header: 'Claim Number',
    field: 'value',
  },
  {
    header: 'selected',
    field: 'checked',
  },
];

const billColumns = [
  {
    header: 'Bill ID',
    field: 'value',
  },
  {
    header: 'selected',
    field: 'checked',
  },
];

const Profiles = props => (
  <FormGroup>
    <Input
      type="select"
      name="profileSelect"
      value={props.adjusterValue}
      defaultValue={props.profileId}
      onChange={event => props.changeProfile(event)}
      disabled={props.disableHandler}
      className="dropdown"
    >
      <option value="default" disabled>
        Choose a profile
      </option>
      {props.profiles.map((profile, index) => (
        <option key={index} value={profile.name}>
          {profile.name}
        </option>
      ))}
    </Input>
  </FormGroup>
);

export class UserAdjusterMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.changeProfile = event => {
      const selectedProfile = event.currentTarget.value;
      this.props.onChangeProfile(selectedProfile);
      this.props.onGetAdjusterMappingLists(this.props.userId, selectedProfile);
    };

    this.openAdjusters = () => {
      if (
        this.props.adjuster.profileId.trim().length !== 0 &&
        this.props.adjuster.profileId !== 'default'
      ) {
        if (!this.props.isModalOpen) {
          this.props.onGetAdjusterList(this.props.profileId);
        }
        this.props.onOpenAdjusters();
      }
    };
  }

  isNewUser = () => {
    return this.props.userId === -1;
  };

  getCurrentProfile = () => {
    return {
      id: this.props.userId,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
    };
  };

  onDeleteMap = () => {
    this.props.onDeleteMap(
      this.props.adjusterList,
      this.props.billList,
      this.props.claimnumberList,
      this.props.adjusterDelete,
      this.props.billDelete,
      this.props.claimDelete,
    );
  };

  toggleDeleteAlert = () => {
    this.setState({
      isOpen: true,
    });
  };

  toggleOkay = () => {
    if (this.state.isOpen) {
      this.onDeleteMap();
    }
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  onFooterClickOK = () => {
    this.setState({
      isOpen: false,
    });
    this.onDeleteMap();
  };

  render() {
    return (
      <>
        <Card body>
          <Row>
            <Col lg="4">
              <span>Adjuster List</span>
              <Button
                color="primary"
                size="sm"
                style={{ marginLeft: '10px' }}
                onClick={this.openAdjusters}
              >
                +
              </Button>
            </Col>
            <Col lg="2">
              <span className="float-right">Profile</span>
            </Col>
            <Col lg="6">
              <Profiles
                profiles={this.props.profiles}
                changeProfile={this.changeProfile}
                profileId={this.props.adjuster.profileId}
                adjusterValue={this.props.adjusterSelect}
                disableHandler={false}
              />
            </Col>
          </Row>
          <Row className="fixed-panel">
            <Col lg="12">
              <UserAdjusterMappingChild
                columns={adjusterColumns}
                data={this.props.adjusterList}
                handleInputChange={this.props.onUpdateAdjusterList}
                handleCheckedAll={this.props.onUpdateAllAdjusterList}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <span>Claim Number List</span>
                </Col>
                <Col lg="12" className="fixed-panel">
                  <UserAdjusterMappingChild
                    columns={claimColumns}
                    data={this.props.claimnumberList}
                    handleInputChange={this.props.onUpdateClaimNumberList}
                    handleCheckedAll={this.props.onUpdateAllClaimNumberList}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <span>Bill List</span>
                </Col>
                <Col lg="12" className="fixed-panel">
                  <UserAdjusterMappingChild
                    columns={billColumns}
                    data={this.props.billList}
                    handleInputChange={this.props.onUpdateBillList}
                    handleCheckedAll={this.props.onUpdateAllBillList}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <UserAdjusterMappingSearchModal
            toggle={this.openAdjusters}
            adjusters={this.props.profileAdjusterList}
            currentProfile={this.getCurrentProfile()}
            userId={this.props.userId}
            profileId={this.props.profileId}
          />
          <Row style={{ marginTop: '20px' }}>
            <Col lg={{ size: 1, offset: 11 }}>
              <Button onClick={this.toggleDeleteAlert}>DELETE MAP</Button>
            </Col>
          </Row>
        </Card>
        <PopupModal
          content={
              <>
                <span>
                  The selected items will not be deleted until you click the
                  save button below.
                </span>
                <br /><br />
                <Button type="button" style={{ marginLeft: '200px', marginRight: '200px' }} onClick={this.onFooterClickOK}>
                OK
                </Button>
              </>
            }
          title={'Notice'}
          externalToggle={this.toggleOkay}
          isOpen={this.state.isOpen}
          size="md"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const user = administrationSelectors.getUser(state);
  const adjuster = administrationSelectors.getAdjuster(state);

  return {
    userId: user.userId,
    firstName: user.userBasics.firstName,
    lastName: user.userBasics.lastName,
    adjuster: adjuster,
    adjusterSelect: state.adjusterSelect,
    claimnumberList: user.userAdjusterMapping.claimnumberList,
    billList: user.userAdjusterMapping.billList,
    adjusterData: user.userAdjusterMapping.adjusterData,
    adjusterList: user.userAdjusterMapping.adjusterList,
    profiles: user.userAdjusterMapping.profiles,
    profileId: adjuster.profileId,
    profileAdjusterList: user.userAdjusterMapping.profileAdjusterList,
    isModalOpen: adjuster.modal,
    billDelete: user.userAdjusterMapping.billDelete,
    adjusterDelete: user.userAdjusterMapping.adjusterDelete,
    claimDelete: user.userAdjusterMapping.claimDelete,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeProfile: profileId =>
    dispatch(administrationActions.changeProfile(profileId)),
  onGetAdjusterMappingLists: (userId, profileId) =>
    dispatch(
      administrationOperations.getAdjusterMappingLists(userId, profileId)
    ),
  onOpenAdjusters: () => dispatch(administrationActions.openAdjusters()),
  onUpdateAdjusterList: event =>
    dispatch(
      administrationActions.updateObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        ADJUSTER_LIST
      )
    ),
  onUpdateClaimNumberList: event =>
    dispatch(
      administrationActions.updateObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        CLAIM_NUMBER_LIST
      )
    ),
  onUpdateBillList: event =>
    dispatch(
      administrationActions.updateObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        BILL_LIST
      )
    ),
  onGetAdjusterList: profileId =>
    dispatch(administrationOperations.getAdjusterList(profileId)),
  onDeleteMap: (adjusterList, billList, claimnumberList, adjusterDelete, billDelete, claimDelete) =>
    dispatch(
      administrationActions.removeItemsFromAdjusterLists(
        adjusterList,
        billList,
        claimnumberList,
        adjusterDelete,
        billDelete,
        claimDelete,
      )
    ),
  onUpdateAllAdjusterList: event =>
    dispatch(
      administrationActions.updateEntireObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        ADJUSTER_LIST
      )
    ),
  onUpdateAllClaimNumberList: event =>
    dispatch(
      administrationActions.updateEntireObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        CLAIM_NUMBER_LIST
      )
    ),
  onUpdateAllBillList: event =>
    dispatch(
      administrationActions.updateEntireObjectArray(
        event,
        ADJUSTER_MAPPING_MODULE,
        BILL_LIST
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAdjusterMapping);
