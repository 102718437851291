import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, FormGroup, Label, Input } from 'reactstrap';

import actions from '../store/actions';
import { BASICS_MODULE } from '../store/constants';

class UserBasics extends Component {
  render() {
    return (
      <Card body>
        <Row>
          <Col lg="2">
            <FormGroup className="group full">
              <Label for="salutation">Salutation</Label>
              <Input
                type="select"
                name="salutation"
                id="salutation"
                value={this.props.salutation}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Choose a salutation</option>
                {this.props.salutationSelect.map((salutation, index) => (
                  <option key={index} value={salutation.id}>
                    {salutation.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="group full">
              <Label for="firstName">
                First Name <span style={{ color: 'red' }}>(*)</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={this.props.firstName}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="group full">
              <Label for="lastName">
                Last Name <span style={{ color: 'red' }}>(*)</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={this.props.lastName}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="group full">
              <Label for="gender">Gender</Label>
              <Input
                type="select"
                name="gender"
                id="gender"
                value={this.props.gender}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value={0}>Female</option>
                <option value={1}>Male</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="group full">
              <Label for="primaryEmailAddress">
                Primary Email Address <span style={{ color: 'red' }}>(*)</span>
              </Label>
              <Input
                type="email"
                name="primaryEmailAddress"
                id="primaryEmailAddress"
                value={this.props.primaryEmailAddress}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="group full">
              <Label for="secondaryEmailAddress">Secondary Email Address</Label>
              <Input
                type="email"
                name="secondaryEmailAddress"
                id="secondaryEmailAddress"
                value={this.props.secondaryEmailAddress}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="group full">
              <Input
                type="select"
                name="honorific"
                value={this.props.honorific}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Honorific...</option>
                {this.props.honorificSelect.map((honorific, index) => (
                  <option key={index} value={honorific.element}>
                    {honorific.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="group full">
              <Input
                type="select"
                name="language"
                value={this.props.language}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Select a language</option>
                {this.props.languageSelect.map((language, index) => (
                  <option key={index} value={language.element}>
                    {language.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup className="group full">
              <Label for="workPhone">Work Phone</Label>
              <Input
                type="tel"
                name="workPhone"
                id="workPhone"
                placeholder="(111) 111-1111"
                pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                value={this.props.workPhone}
                onChange={this.props.onUpdatePhoneNumber}
              />
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup className="group full">
              <Label for="ext">Ext</Label>
              <Input
                maxLength={10}
                type="text"
                name="ext"
                id="ext"
                value={this.props.ext}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="group full">
              <Label for="cellPhone">Cell Phone</Label>
              <Input
                type="tel"
                name="cellPhone"
                id="cellPhone"
                placeholder="(111) 111-1111"
                value={this.props.cellPhone}
                onChange={this.props.onUpdatePhoneNumber}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="group full">
              <Label for="fax">Fax Number</Label>
              <Input
                type="tel"
                name="fax"
                id="fax"
                placeholder="(111) 111-1111"
                value={this.props.fax}
                onChange={this.props.onUpdatePhoneNumber}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="group full">
              <Label for="pager">Pager</Label>
              <Input
                type="tel"
                name="pager"
                id="pager"
                placeholder="(111) 111-1111"
                value={this.props.pager}
                onChange={this.props.onUpdatePhoneNumber}
              />
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup className="group full">
              <Label for="pin">Pin</Label>
              <Input
                maxLength={10}
                type="text"
                name="pin"
                id="pin"
                value={this.props.pin}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <FormGroup className="group full">
              <Label for="carrier">Carrier</Label>
              <Input
                type="select"
                name="carrier"
                id="carrier"
                value={this.props.carrier}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Choose a carrier</option>
                {this.props.carrierList.map((carrier, index) => (
                  <option key={index} value={carrier.id}>
                    {carrier.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup className="group full">
              <Label for="location">
                Location <span style={{ color: 'red' }}>(*)</span>
              </Label>
              <Input
                type="select"
                name="location"
                id="location"
                value={this.props.location}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Choose a location</option>
                {this.props.locationList.map((location, index) => (
                  <option key={index} value={location.id}>
                    {location.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="group full">
              <Label for="applicationTimeout">Application Timeout</Label>
              <Input
                type="number"
                name="applicationTimeout"
                id="applicationTimeout"
                value={this.props.applicationTimeout}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Label for="taskNotificationMethod">Task Notification Method</Label>
            <Input
              type="select"
              name="taskNotificationMethod"
              id="taskNotificationMethod"
              value={this.props.taskNotificationMethod}
              onChange={this.props.onUpdateInput}
              className="dropdown"
            >
              <option value="0">Display in on-screen Task List</option>
              <option value="1">Send to Primary Email Address</option>
              <option value="2">Send to Secondary Email Address</option>
            </Input>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    salutation: state.ADMIN.user.userBasics.salutation,
    salutationSelect: state.ADMIN.user.userBasics.salutationSelect,
    firstName: state.ADMIN.user.userBasics.firstName,
    lastName: state.ADMIN.user.userBasics.lastName,
    gender: state.ADMIN.user.userBasics.gender,
    primaryEmailAddress: state.ADMIN.user.userBasics.primaryEmailAddress,
    secondaryEmailAddress: state.ADMIN.user.userBasics.secondaryEmailAddress,
    honorific: state.ADMIN.user.userBasics.honorific,
    honorificSelect: state.ADMIN.user.userBasics.honorificSelect,
    language: state.ADMIN.user.userBasics.language,
    languageSelect: state.ADMIN.user.userBasics.languageSelect,
    workPhone: state.ADMIN.user.userBasics.workPhone,
    ext: state.ADMIN.user.userBasics.ext,
    cellPhone: state.ADMIN.user.userBasics.cellPhone,
    fax: state.ADMIN.user.userBasics.fax,
    pager: state.ADMIN.user.userBasics.pager,
    pin: state.ADMIN.user.userBasics.pin,
    location: state.ADMIN.user.userBasics.location,
    applicationTimeout: state.ADMIN.user.userBasics.applicationTimeout,
    taskNotificationMethod: state.ADMIN.user.userBasics.taskNotificationMethod,
    locationList: state.ADMIN.user.userBasics.locationList,
    carrier: state.ADMIN.user.userBasics.carrier,
    textUser: state.ADMIN.user.userBasics.textUser,
    carrierList: state.ADMIN.user.userBasics.carrierList,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateInput: event => dispatch(actions.updateInput(event, BASICS_MODULE)),
  onUpdatePhoneNumber: event => dispatch(actions.updatePhoneInput(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBasics);
