import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Popover, PopoverBody } from 'reactstrap';
import { expertsSelectors, expertsOperations } from '../store';
import FindExpertResultModal from './FindExpertResultModal';

class FindExpertSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      popoverOpen: false,
      resultModalOpen: false,
    };
  }

  stateResultModalToggle = () => {
    this.setState(prevState => ({
      resultModalOpen: !prevState.resultModalOpen,
    }));
  };

  popoverToggle = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };

  onInputChangeHandler = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onKeyPressHandler = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.props.findExpertSimple(this.state.searchInput);
    this.stateResultModalToggle();
  };

  onFocusInputHandler = () => {
    this.setState({
      popoverOpen: true,
    });
  };

  render() {
    return (
      <>
        <Input
          name="searchInput"
          id="searchInput"
          value={this.state.searchInput}
          onChange={this.onInputChangeHandler}
          onKeyPress={this.onKeyPressHandler}
          onFocus={this.onFocusInputHandler}
          placeholder="Name or ID #"
          type="text"
        />
        <FindExpertResultModal
          externalToggle={this.stateResultModalToggle}
          isOpen={this.state.resultModalOpen}
          data={this.props.experts}
        />
        <Popover
          trigger="focus"
          className="find-expert-popover"
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target="searchInput"
        >
          <PopoverBody>
            <span onClick={this.stateResultModalToggle}>
              Show {this.props.experts.length} results
            </span>
            <span className="close" onClick={this.popoverToggle}>
              <FontAwesomeIcon icon={faWindowClose} />
            </span>
          </PopoverBody>
        </Popover>
      </>
    );
  }
}

FindExpertSimple.propTypes = {
  experts: PropTypes.array.isRequired,
  findExpertSimple: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const previewState = expertsSelectors.getPreview(state);
  return {
    experts: previewState.experts,
  };
};

const mapDispatchToProps = dispatch => ({
  findExpertSimple: searchInput =>
    dispatch(expertsOperations.findExpertSimple(searchInput)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindExpertSimple);
