import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Fast360NppesPhysiciansModal from './Fast360NppesPhysiciansModal';

const NewPhysicianModal = ({
  isOpen,
  onClose,
  userId,
  userRole,
  getPhysicianList,
  physicianInfo,
  updatedPhysicianInfo,
  resetSearchPhysicianTable,
  getLoadPhysician,
  loadPhysicianInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadPhysician,
  resetPhysicianInfo,
  savePhysician,
  alert,
  isNew,
  setPhyEdited,
  setPhyData,
  isFromLanding,
  loadInfoFromExistingPatient,
  fromWorklist,
  worklistData,
  referralId,
  logBreadCrumbs
}) => {
  const [isOpenLoadPhysicianModal, setIsOpenLoadPhysicianModal] = useState(
    false
  );
  const [isOpenSeachPhysician, setIsOpenSeachPhysician] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [phyValidLastName, setPhyValidLastName] = useState(false);
  const [phyValidGroupName, setPhyValidGroupName] = useState(false);
  const [phyValidLicNum, setPhyValidLicNum] = useState(false);
  const [phyValidNpi, setPhyValidNpi] = useState(false);
  const [isOpenNppesModal, setIsOpenNppesModal] = useState(false);
  const [searchFields, setSearchFields] = useState({
    physicianLastName: '',
    physicianFirstName: '',
    physicianGroupName: '',
  });
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [formPhysicianFields, setFormPhysicianFields] = useState({
    phyFirstName: (!isFromLanding && loadInfoFromExistingPatient?.people?.first) || '',
    phyLastName: (!isFromLanding && loadInfoFromExistingPatient?.people?.last) || '',
    phyGroupName: (!isFromLanding && loadInfoFromExistingPatient?.groupName) || '',
    phyEmail: '',
    phyPhone: '',
    phyPhoneExt: '',
    phyFax: '',
    phyAddress: '',
    phyAddress2: '',
    phyCity: '',
    phyCounty: '',
    phyState: '',
    phyZip: '',
    phyLicenseNo: '',
    phyNpi: '',
    phyNotes: '',
    phyZipCodeId: '',
    phyZipId: '',
    phyTimeZone: '',
  });

  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSeachPhysician(true);
      } else {
        setIsOpenLoadPhysicianModal(true);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!formPhysicianFields.phyLastName)
      setPhyValidLastName(false);
    else setPhyValidLastName(true);
    if (!formPhysicianFields.phyGroupName)
      setPhyValidGroupName(false);
    else setPhyValidGroupName(true);
    if (!formPhysicianFields.phyLicenseNo)
      setPhyValidLicNum(false);
    else setPhyValidLicNum(true);
    if (!formPhysicianFields.phyNpi)
      setPhyValidNpi(false);
    else setPhyValidNpi(true);
    setPhyData(formPhysicianFields);
  }, [formPhysicianFields])

  useEffect(() => {
    setPhyData(formPhysicianFields)
  }, [formPhysicianFields])

  useEffect(() => {
    if (isOpenLoadPhysicianModal) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENPHYS',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb)
      if (selectedItem) {
        const params = {
          userId,
          functionName: 'loadPhysician',
          id: selectedItem.PK_physician_id,
        };
        getLoadPhysician(params);
      } else if (!isNew && !physicianInfo) {
        if (!isEmpty(updatedPhysicianInfo) && updatedPhysicianInfo.id !== 0) {
          const params = {
            userId,
            functionName: 'loadPhysician',
            id: updatedPhysicianInfo.id,
          };
          getLoadPhysician(params);
          const pplCheck =
            (updatedPhysicianInfo &&
              updatedPhysicianInfo.people) ||
            {};
          const adrCheck =
            (updatedPhysicianInfo &&
              updatedPhysicianInfo.address) ||
            {};
          setFormPhysicianFields({
            phyfirstName:
              updatedPhysicianInfo &&
              updatedPhysicianInfo.first,
            phyLastName:
              updatedPhysicianInfo &&
              updatedPhysicianInfo.last,
            phyGroupName:
              updatedPhysicianInfo &&
              updatedPhysicianInfo.groupName,
            phyEmail: pplCheck.email1,
            phyNpi: updatedPhysicianInfo.npi,
            phyLicenseNo: updatedPhysicianInfo.licenseNumber,
            phyNotes: updatedPhysicianInfo.comments,
            phyPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            phyPhoneExt: pplCheck && pplCheck.phoneExt,
            phyFax: pplCheck && pplCheck.fax && formatPhoneNumber(pplCheck.fax),
            phyAddress: adrCheck.street1,
            phyZipCodeId: adrCheck.zipCodeId,
            phyZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            phyTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            phyAddress2: adrCheck.street2,
            phyCity: adrCheck.zipCode && adrCheck.zipCode.city,
            phyCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            phyState: adrCheck.zipCode && adrCheck.zipCode.state,
            phyZip: adrCheck.zipCode && adrCheck.zipCode.zip,
          });
        }
      }
    }
  }, [isOpenLoadPhysicianModal]);

  useEffect(() => {
    if (!isEmpty(loadPhysicianInfo)) {
      const pplCheck = loadPhysicianInfo.people || {};
      const adrCheck = loadPhysicianInfo.address || {};
      setFormPhysicianFields({
        phyFirstName: pplCheck.first,
        phyLastName: pplCheck.last,
        phyGroupName: loadPhysicianInfo.groupName,
        phyNotes: loadPhysicianInfo.comments,
        phyFax: pplCheck && pplCheck.fax && formatPhoneNumber(pplCheck.fax),
        phyNpi: loadPhysicianInfo.npi,
        phyLicenseNo: loadPhysicianInfo.licenseNumber,
        phyEmail: pplCheck.email1,
        phyPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        phyPhoneExt: pplCheck && pplCheck.phoneExt,
        phyAddress: adrCheck.street1,
        phyZipCodeId: adrCheck.zipCodeId,
        phyZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        phyTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        phyAddress2: adrCheck.street2,
        phyCity: adrCheck.zipCode && adrCheck.zipCode.city,
        phyCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        phyState: adrCheck.zipCode && adrCheck.zipCode.state,
        phyZip: adrCheck.zipCode && adrCheck.zipCode.zip,
      });
    }
  }, [loadPhysicianInfo]);

  useEffect(() => {
    let fname = '', lname = '', grpName = '';
    if (!isFromLanding && isEmpty(updatedPhysicianInfo) && !isEmpty(loadInfoFromExistingPatient)) {
      fname = loadInfoFromExistingPatient?.people?.first || ''
      lname = loadInfoFromExistingPatient?.people?.last || ''
      grpName = loadInfoFromExistingPatient?.groupName || ''
    } else {
      if (fromWorklist && worklistData) {
        if (worklistData?.physician?.people?.first)
          fname = worklistData?.physician?.people?.first
        if (worklistData?.physician?.people?.last)
          lname = worklistData?.physician?.people?.last
        if (worklistData?.physician?.groupName)
          grpName = worklistData?.physician?.groupName
      }
    }
    if (isOpen) {
      setSearchFields({
        physicianFirstName: fname,
        physicianLastName: lname,
        physicianGroupName: grpName,
      });
      const payload = {
        physicianFirstName: fname,
        physicianLastName: lname,
        physicianGroupName: grpName,
        userId,
      };
      (fname || lname) && getPhysicianList(payload);
    }

  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.physician?.id) {
        const item = physicianInfo && physicianInfo.filter(o => o.PK_physician_id === worklistData?.physician?.id);
        let index = physicianInfo && physicianInfo.findIndex(i => i.PK_physician_id === worklistData?.physician?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [physicianInfo])

  const loadPhysicianInfoToggle = () => {
    setIsOpenLoadPhysicianModal(!isOpenLoadPhysicianModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormPhysicianFields({ ...formPhysicianFields, phyLastName: '', phyGroupName: '', phyLicenseNo: '', phyNpi: '' })
    }
  };

  const searchPhysicianToggle = () => {
    setIsOpenSeachPhysician(!isOpenSeachPhysician);
    onClose();
    resetFormData();
  };
  const resetFormData = () => {
    setSearchFields({
      physicianLastName: '',
      physicianFirstName: '',
      physicianGroupName: '',
    });
    resetSearchPhysicianTable();
    resetLoadPhysician();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const setMap = {
    phyLastName: setPhyValidLastName,
    phyGroupName: setPhyValidGroupName,
    phyLicenseNo: setPhyValidGroupName,
    phyNpi: setPhyValidNpi
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "phyPhone") {
      value = formatPhoneNum(value);
    }
    setFormPhysicianFields({ ...formPhysicianFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };


  const isDisabledInput = () => {
    return userRole === 'Administrator' ? false : true;
  };

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchPhysicianTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'Group Name',
              field: 'chr_groupname',
            },
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'License',
              field: 'chr_licensenumber',
            },
            {
              header: 'NPI',
              field: 'int_npi',
            },
            {
              header: 'TIN',
              field: 'chr_tin',
            },
          ]}
          data={physicianInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-physician-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadPhysicianModal = () => {
    setIsOpenSeachPhysician(false);
    setIsOpenLoadPhysicianModal(true);
  };

  const getPhysicians = () => {
    const params = {
      ...searchFields,
      userId,
    };
    getPhysicianList(params);
  }

  const onFormSubmit = e => {
    e.preventDefault();
    getPhysicians();
  };

  const onChangeCurrentPhysician = () => {
    setSearchFields({
      physicianLastName: '',
      physicianFirstName: '',
      physicianGroupName: '',
    });
    setIsOpenLoadPhysicianModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachPhysician(true);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormPhysicianFields({
        ...formPhysicianFields,
        phyCity: selectedZip.chr_city,
        phyState: selectedZip.chr_state,
        phyCounty: selectedZip.chr_county,
        phyZip: selectedZip.chr_zipcode,
        phyZipCodeId: selectedZip.code_id,
        phyZipId: selectedZip.code_id,
        phyTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVEPHYS',
      refTableId: loadPhysicianInfo,
      refId: 0,
      refTable: 'PHY',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem) {
      const response = loadPhysicianInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formPhysicianFields.phyFirstName;
      response.people.last = formPhysicianFields.phyLastName;
      response.people.email1 = formPhysicianFields.phyEmail;
      response.people.fax = formPhysicianFields.phyFax &&
        formPhysicianFields.phyFax.replace(/[\s()-]/g, '');
      response.people.phone =
        formPhysicianFields.phyPhone &&
        formPhysicianFields.phyPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formPhysicianFields.phyPhoneExt;
      response.address.street1 = formPhysicianFields.phyAddress;
      response.address.street2 = formPhysicianFields.phyAddress2;
      response.address.zipCode.city = formPhysicianFields.phyCity;
      response.address.zipCode.county = formPhysicianFields.phyCounty;
      response.address.zipCode.state = formPhysicianFields.phyState;
      response.address.zipCode.zip = formPhysicianFields.phyZip;
      response.address.zipCode.id = formPhysicianFields.phyZipId;
      response.address.zipCodeId = formPhysicianFields.phyZipCodeId;
      response.licenseNumber = formPhysicianFields.phyLicenseNo;
      response.groupName = formPhysicianFields.phyGroupName;
      response.npi = formPhysicianFields.phyNpi;
      response.comments = formPhysicianFields.phyNotes;
      const physicianId = response.id;
      const refId = referralId?.referralId || 0;
      const tableName = referralId?.tableName === "ACC" ? 'accommodation' :
        referralId?.tableName === "AUD" ? 'audiology' :
          referralId?.tableName === "DTL" ? 'dental' :
            referralId?.tableName === "DGN" ? 'diagnostics' :
              referralId?.tableName === "INT" ? 'interpretation' :
                referralId?.tableName === "TRN" ? 'transportation' :
                  referralId?.tableName === "SPT" ? 'special_transp' :
                    referralId?.tableName === "MOD" ? 'modifications' :
                      referralId?.tableName === "MSE" ? 'mse' :
                        referralId?.tableName === "HHC" ? 'hhc' : ''
      const serviceId = referralId?.serviceId || 0;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = 0;
      }
      const params = {
        response,
        userId,
        physicianId,
        claimId,
        refId,
        tableName,
        serviceId
      };
      if ((response.groupName === '' || response.groupName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Group name or Physician name in order to save.');
        return;
      }
      if (response.licenseNumber === '' || response.licenseNumber === undefined) {
        alert('You must provide an License Number in order to save.');
        return;
      }
      if (response.npi === '' || response.npi === undefined) {
        alert('You must provide a npi in order to save.');
        return;
      }
      savePhysician(params);
      loadPhysicianInfoToggle();
    } else if (!isNew && !physicianInfo) {
      const response = updatedPhysicianInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formPhysicianFields.phyFirstName;
      response.people.last = formPhysicianFields.phyLastName;
      response.people.fax = formPhysicianFields.phyFax &&
        formPhysicianFields.phyFax.replace(/[\s()-]/g, '');
      response.people.email1 = formPhysicianFields.phyEmail;
      response.people.phone =
        formPhysicianFields.phyPhone &&
        formPhysicianFields.phyPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formPhysicianFields.phyPhoneExt;
      response.address.street1 = formPhysicianFields.phyAddress;
      response.address.street2 = formPhysicianFields.phyAddress2;
      response.address.zipCode.city = formPhysicianFields.phyCity;
      response.address.zipCode.county = formPhysicianFields.phyCounty;
      response.address.zipCode.state = formPhysicianFields.phyState;
      response.address.zipCode.zip = formPhysicianFields.phyZip;
      response.address.zipCode.id = formPhysicianFields.phyZipId;
      response.address.zipCodeId = formPhysicianFields.phyZipCodeId;
      response.licenseNumber = formPhysicianFields.phyLicenseNo;
      response.groupName = formPhysicianFields.phyGroupName;
      response.comments = formPhysicianFields.phyNotes;
      response.npi = formPhysicianFields.phyNpi;
      const physicianId = response.id;
      const refId = referralId?.referralId || 0;
      const tableName = referralId?.tableName === "ACC" ? 'accommodation' :
        referralId?.tableName === "AUD" ? 'audiology' :
          referralId?.tableName === "DTL" ? 'dental' :
            referralId?.tableName === "DGN" ? 'diagnostics' :
              referralId?.tableName === "INT" ? 'interpretation' :
                referralId?.tableName === "TRN" ? 'transportation' :
                  referralId?.tableName === "SPT" ? 'special_transp' :
                    referralId?.tableName === "MOD" ? 'modifications' :
                      referralId?.tableName === "MSE" ? 'mse' :
                        referralId?.tableName === "HHC" ? 'hhc' : ''
      const serviceId = referralId?.serviceId || 0;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = 0;
      }
      const params = {
        response,
        userId,
        physicianId,
        claimId,
        refId,
        tableName,
        serviceId
      };
      if ((response.groupName === '' || response.groupName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Group name or Physician name in order to save.');
        return;
      }
      if (response.licenseNumber === '' || response.licenseNumber === undefined) {
        alert('You must provide an License Number in order to save.');
        return;
      }
      if (response.npi === '' || response.npi === undefined) {
        alert('You must provide a npi in order to save.');
        return;
      }
      savePhysician(params);
      loadPhysicianInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.licenseNumber = formPhysicianFields.phyLicenseNo;
      response.comments = formPhysicianFields.phyNotes;
      response.npi = formPhysicianFields.phyNpi;
      response.groupName = formPhysicianFields.phyGroupName;
      response.people = {
        id: 0,
        first: formPhysicianFields.phyFirstName,
        last: formPhysicianFields.phyLastName,
        fax: formPhysicianFields.phyFax &&
          formPhysicianFields.phyFax.replace(/[\s()-]/g, ''),
        email1: formPhysicianFields.phyEmail,
        phone:
          formPhysicianFields.phyPhone &&
          formPhysicianFields.phyPhone.replace(/[\s()-]/g, ''),
        phoneExt: formPhysicianFields.phyPhoneExt,
      };
      response.address = {
        id: 0,
        street1: formPhysicianFields.phyAddress,
        street2: formPhysicianFields.phyAddress2,
        zipCodeId: formPhysicianFields.phyZipCodeId,
        zipCode: {
          city: formPhysicianFields.phyCity,
          county: formPhysicianFields.phyCounty,
          state: formPhysicianFields.phyState,
          zip: formPhysicianFields.phyZip,
          id: formPhysicianFields.phyZipId,
          timeZone: formPhysicianFields.phyTimeZone,
        },
      };
      if ((response.groupName === '' || response.groupName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Group name or Physician name in order to save.');
        return;
      }
      if (response.licenseNumber === '' || response.licenseNumber === undefined) {
        alert('You must provide an License Number in order to save.');
        return;
      }
      if (response.npi === '' || response.npi === undefined) {
        alert('You must provide a npi in order to save.');
        return;
      }
      const physicianId = response.id;
      const refId = referralId?.referralId || 0;
      const tableName = referralId?.tableName === "ACC" ? 'accommodation' :
        referralId?.tableName === "AUD" ? 'audiology' :
          referralId?.tableName === "DTL" ? 'dental' :
            referralId?.tableName === "DGN" ? 'diagnostics' :
              referralId?.tableName === "INT" ? 'interpretation' :
                referralId?.tableName === "TRN" ? 'transportation' :
                  referralId?.tableName === "SPT" ? 'special_transp' :
                    referralId?.tableName === "MSE" ? 'mse' :
                      referralId?.tableName === "HHC" ? 'hhc' : ''
      const serviceId = referralId?.serviceId || 0;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = 0;
      }
      const params = {
        response,
        userId,
        physicianId,
        claimId,
        refId,
        tableName,
        serviceId
      };
      savePhysician(params);
      loadPhysicianInfoToggle();
    }
    // setIsExternal();
    setPhyEdited(true)
  };

  const toggleNppes = () => {
    setIsOpenNppesModal(true);
  }

  const getBody = () => {
    if (physicianInfo && physicianInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new physician as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Physician?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setFormPhysicianFields({
      phyFirstName: (!isFromLanding && loadInfoFromExistingPatient?.people?.first) || '',
      phyLastName: (!isFromLanding && loadInfoFromExistingPatient?.people?.last) || '',
      phyGroupName: (!isFromLanding && loadInfoFromExistingPatient?.groupName) || '',
      phyEmail: '',
      phyPhone: '',
      phyPhoneExt: '',
      phyFax: '',
      phyAddress: '',
      phyAddress2: '',
      phyCity: '',
      phyCounty: '',
      phyState: '',
      phyZip: '',
      phyLicenseNo: '',
      phyNpi: '',
      phyNotes: '',
      phyZipCodeId: '',
      phyZipId: '',
      phyTimeZone: '',
    });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadPhysicianModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadPhysicianModal}>
        <ModalHeader toggle={loadPhysicianInfoToggle}>
          ADD A NEW PHYSICIAN
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={4}>
              <Label for="phyFirstName">First Name</Label>
              <Input
                type="text"
                id="phyFirstName"
                value={formPhysicianFields.phyFirstName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !physicianInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={4}>
              <Label for="phyLastName">Last Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="phyLastName"
                value={formPhysicianFields.phyLastName || ''}
                className={phyValidLastName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !physicianInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={4}>
              <Label for="phyGroupName">Group Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="phyGroupName"
                value={formPhysicianFields.phyGroupName || ''}
                className={phyValidGroupName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !physicianInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
          </FormGroup>
          <Col style={{ top: '-8px', color: 'red', paddingLeft: 0 }}>
            {' '}
            {(selectedItem || (!isNew && !physicianInfo)) && (
              <span>
                {isDisabledInput()
                  ? 'Please contact Administrator if you want to change a Physician Name'
                  : 'Warning: Changing the name on this record will also change it on any referral that this record is linked to!'}
              </span>
            )}
          </Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="phyEmail">Physician Email</Label>
              <Input
                type="text"
                id="phyEmail"
                value={formPhysicianFields.phyEmail || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="phyPhone">Physician Phone#</Label>
              <Input
                type="tel"
                id="phyPhone"
                value={formPhysicianFields.phyPhone || ''}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="phyPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="phyPhoneExt"
                value={formPhysicianFields.phyPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="phyFax">Fax#</Label>
              <Input
                type="text"
                id="phyFax"
                value={formPhysicianFields.phyFax || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="phyAddress">Physician Address</Label>
              <Input
                type="text"
                id="phyAddress"
                value={formPhysicianFields.phyAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="phyAddress2">Physician Address 2</Label>
              <Input
                type="text"
                id="phyAddress2"
                value={formPhysicianFields.phyAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="phyCity">Physician City</Label>
              <Input
                type="text"
                id="phyCity"
                value={formPhysicianFields.phyCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="phyState">State</Label>
              <Input
                type="text"
                id="phyState"
                value={formPhysicianFields.phyState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="phyCounty">County</Label>
              <Input
                type="text"
                id="phyCounty"
                value={formPhysicianFields.phyCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="phyZip">Physician Zip</Label>
              <Input
                type="text"
                id="phyZip"
                value={formPhysicianFields.phyZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="phyZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'phyZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="phyZip"
                id="phyZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="phyLicenseNo">License No:</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="tel"
                id="phyLicenseNo"
                className={phyValidLicNum ? '' : "error"}
                value={formPhysicianFields.phyLicenseNo || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="phyNpi">NPI#</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="phyNpi"
                value={formPhysicianFields.phyNpi || ''}
                className={phyValidNpi ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label for="phyNotes">Comments</Label>
              <Input
                type="textarea"
                id="phyNotes"
                value={formPhysicianFields.phyNotes || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentPhysician}>
              CHANGE CURRENT PHYSICIAN
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={isOpenSeachPhysician}>
        <ModalHeader toggle={searchPhysicianToggle}>
          SEARCH PHYSICIAN
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="physicianFirstName">First Name</Label>
                  <Input
                    type="text"
                    id="physicianFirstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.physicianFirstName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="physicianLastName">Last Name</Label>
                  <Input
                    type="text"
                    id="physicianLastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.physicianLastName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="physicianGroupName">Group Name</Label>
                  <Input
                    type="text"
                    id="physicianGroupName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.physicianGroupName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
                <Button style={{ marginTop: '7px', marginLeft: "10px" }} onClick={toggleNppes}>
                  SEARCH NPPES
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchPhysicianTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!physicianInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadPhysicianModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {isOpenNppesModal && <Fast360NppesPhysiciansModal 
        searchFields={searchFields} 
        isOpen={isOpenNppesModal} 
        onClose={() => {
          setIsOpenNppesModal(false);
          setTimeout(() => {
            getPhysicians();
          }, 3000);
        }}/>}
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
    </>
  );
};

NewPhysicianModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  physicianInfo: PropTypes.array,
  getPhysicianList: PropTypes.func,
  resetSearchPhysicianTable: PropTypes.func,
  getLoadPhysician: PropTypes.func,
  loadPhysicianInfo: PropTypes.object,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadPhysician: PropTypes.func,
  savePhysician: PropTypes.func,
  alert: PropTypes.func,
  resetPhysicianInfo: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);

  return {
    physicianInfo: fast360Info.physicianInfo,
    loadPhysicianInfo: fast360Info.loadPhysicianInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    updatedPhysicianInfo: fast360Info.updatedPhysicianInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getPhysicianList: data => dispatch(fast360Operations.getPhysicianList(data)),
  getLoadPhysician: data => dispatch(fast360Operations.getLoadPhysician(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchPhysicianTable: () =>
    dispatch(fast360Actions.resetSearchPhysicianTable()),
  resetLoadPhysician: () => dispatch(fast360Actions.resetLoadPhysician()),
  resetPhysicianInfo: () => dispatch(fast360Actions.resetPhysicianInfo()),
  savePhysician: data => dispatch(fast360Operations.savePhysician(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPhysicianModal);
