import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'reactstrap';

let link;

class LinkAction extends Component {
  constructor(props) {
    super(props);
    link =
      this.props.link !== '' && this.props.link !== undefined
        ? this.props.link
        : '#';
  }

  render() {
    if (this.props.type === 'UR') {
      return (
        <li>
          <Button
            color="link"
            onClick={() => {
              this.props.urAction(this.props.urData);
            }}
          >
            <span aria-hidden>
              <FontAwesomeIcon
                icon="folder-open"
                style={{ display: 'block' }}
              />
              {this.props.iconOnly ? '' : this.props.type}
            </span>
          </Button>
        </li>
      );
    } else if (this.props.type === 'Detail') {
      return (
        <li>
          <Button color="link" href={this.props.link} target="_blank">
            <FontAwesomeIcon icon={link !== '#' ? 'clipboard-list' : 'ban'} />
            {this.props.iconOnly ? '' : <span>{this.props.type}</span>}
          </Button>
        </li>
      );
    }
    return '';
  }
}

LinkAction.propTypes = {
  type: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
  notes: PropTypes.string,
};

export default LinkAction;
