import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  ListGroup,
  ListGroupItem,
  Label,
  FormGroup,
  Table,
} from 'reactstrap';

import '../assets/static/adminmodule-user.scss';

import actions from '../store/actions';
import { BILL_APPROVAL_MODULE } from '../store/constants';

const columns = [
  {
    header: 'Link Clients',
    field: 'linkClients',
  },
  {
    header: 'Threshold Amt',
    field: 'thresholdAmt',
  },
  {
    header: 'Selected',
    field: 'selected',
  },
];

const TOP = 'TOP';
const BOTTOM = 'BOTTOM';

const TopPane = props => (
  <FormGroup row>
    <Label for="availableClients" className="adjust-label">
      Available Clients
    </Label>
    <Col
      lg={12}
      id="availableClients"
      className="fixed-panel"
      onDragOver={props.allowDrop}
      onDrop={props.onDrop}
    >
      <ListGroup>
      {' '}
        {props.data.map((dataObj, index) => (
          <ListGroupItem
            onDragStart={event => props.dragStart(event, dataObj, props.origin)}
            draggable={true}
            key={index}
          >
            {dataObj.id}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Col>
  </FormGroup>
);

const BottomPane = props => (
  <FormGroup row>
    <Label for="linkedClients" className="adjust-label">
      Linked Clients
    </Label>
    <Col
      id="linkedClients"
      lg="12"
      className="fixed-panel"
      onDragOver={props.allowDrop}
      onDrop={props.onDrop}
    >
      <Table size="sm" striped={true}>
        <thead className={'thead-dark'}>
          <tr>
            {props.columns.map((column, index) => {
              if (column.field === 'selected') {
                return (
                  <th key={index}>
                     <span>
                      <Input type="checkbox" onChange={props.handleCheckedAll} style={{marginLeft: 0} }/>
                    </span>
                    <span>
                    {column.header}
                    </span>
                  </th>
                );
              } else {
                return <th key={index}>{column.header}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          {props.linkClients.map((dataObj, index) => (
            <tr
              key={index}
              draggable={true}
              onDragStart={event =>
                props.dragStart(event, dataObj, props.origin)
              }
            >
              <td>{dataObj.id}</td>
              <td>$
                <Input
                  value={dataObj.thresholdAmt}
                  type="number"
                  id={"threshold_amount_" + dataObj.id}
                  maxLength={7}
                  name={dataObj.id + "_thresholdAmt"}
                  className="thresholdAmount"
                  onChange={props.onChange}
                />00
              </td>
              <td>
                <Input
                  type="checkbox"
                  checked={dataObj.checked}
                  id={dataObj.id}
                  name={dataObj.id}
                  onChange={props.onChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  </FormGroup>
);

class UserBillApproval extends Component {
  state = {
    selectedItems : [],
    data: [],
    checkedAll:false,
  }
  allowDrop = event => {
    event.preventDefault();
  };

  drag = (event, data, origin) => {
    this.setState({
      draggedItem: data,
      dragOrigin: origin,
    });
  };

  dropBottomUp = () => {
    if (this.state.dragOrigin === TOP) {
      return;
    }

    const { availableClients, linkClients } = this.props.userBillApproval;
    const draggedItem = this.state.draggedItem;

    draggedItem.thresholdAmt = 0;
    draggedItem.checked = false;

    this.props.onUpdateAvailableFromLinked(
      linkClients,
      availableClients,
      draggedItem
    );

    this.setState({
      draggedItem: {},
    });
  };

  dropTopDown = () => {
    if (this.state.dragOrigin === BOTTOM) {
      return;
    }

    const { availableClients, linkClients } = this.props.userBillApproval;
    const draggedItem = this.state.draggedItem;
    this.setState({
      draggedItem: {},
    });

    this.props.onUpdateLinkedFromAvailable(
      linkClients,
      availableClients,
      draggedItem
    );
  };

  onApplyToSelected = () => {
    this.props.onApplyToSelected(
      this.props.userBillApproval.thresholdAmt,
      this.props.userBillApproval.linkClients
    );
  };


  handleCheckedAll = (event) => {
    const { linkClients } = this.props.userBillApproval;
    const { checkedAll } = this.state;
    for (let i = 0; i < linkClients.length; i++) {
      linkClients[i].checked = !checkedAll;
      this.props.onUpdateSelected(event);
    }
    this.setState({
      data: linkClients,
      checkedAll: !checkedAll,
    });
  };

  render() {
    return (
      <Card body>
        <FormGroup className="bill-approval-alert-row">
          <Col lg="12">
            <span className="bill-approval-alert">
              Drag a client from either list to link or unlink them to this user
            </span>
          </Col>
        </FormGroup>

        <TopPane
          allowDrop={this.allowDrop}
          onDrop={this.dropBottomUp}
          data={this.props.userBillApproval.availableClients}
          dragStart={this.drag}
          origin={TOP}
        />

        <FormGroup row>
          <Col sm={{ size: 4, offset: 8 }}>
            <Label for="amount">Threshold Amount</Label>
            <InputGroup>
              <Input
                value={this.props.userBillApproval.thresholdAmt}
                type="number"
                id="amount"
                maxLength={7}
                name="thresholdAmt"
                onChange={this.props.onUpdateInput}
              />
              <InputGroupAddon addonType="append">.00</InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button onClick={this.onApplyToSelected}>
                  APPLY TO SELECTED
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>

        <BottomPane
          onDrop={this.dropTopDown}
          columns={columns}
          dragStart={this.drag}
          allowDrop={this.allowDrop}
          linkClients={this.props.userBillApproval.linkClients}
          onChange={this.props.onUpdateSelected}
          handleCheckedAll={this.handleCheckedAll}
          origin={BOTTOM}
        />
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    userBillApproval: {
      availableClients: state.ADMIN.user.userBillApproval.availableClients,
      linkClients: state.ADMIN.user.userBillApproval.linkClients,
      thresholdAmt: state.ADMIN.user.userBillApproval.thresholdAmt,
      draggedItem: state.ADMIN.user.userBillApproval.draggedItem,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  onApplyToSelected: (thresholdAmt, linkClients) =>
    dispatch(actions.billApprovalApplyToSelected(thresholdAmt, linkClients)),
  onUpdateSelected: event =>
    dispatch(
      actions.updateObjectArray(event, BILL_APPROVAL_MODULE, 'linkClients')
    ),
  onUpdateAvailableFromLinked: (linkClients, availableClients, draggedItem) =>
    dispatch(
      actions.updateAvailableFromLinked(
        linkClients,
        availableClients,
        draggedItem
      )
    ),
  onUpdateLinkedFromAvailable: (linkClients, availableClients, draggedItem) =>
    dispatch(
      actions.updateLinkedFromAvailable(
        linkClients,
        availableClients,
        draggedItem
      )
    ),
  onUpdateInput: event =>
    dispatch(actions.updateInput(event, BILL_APPROVAL_MODULE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBillApproval);
