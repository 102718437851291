import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import SearchableSelect from 'shared/components/input/SearchableSelect';
import SearchableSelectForStatus from 'shared/components/input/SearchableSelectForStatus';
import AppInput from 'shared/components/input/AppInput';
import DropdownMultiSelect from 'shared/components/dropdown-multi-select/dropdown-multi-select';

import { billOperationsSelectors } from '../store';

class AdvancedBillSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidUpdate(prevProps) {
    const {isClear} = this.props;
    if((prevProps.isClear !== isClear) && isClear) {
      document.getElementById("externalId").value = '';
      document.getElementById("billStatus").value = '';
      document.getElementById("processor").value = '';
      document.getElementById("siteId").value = '';
      document.getElementById("state").value = '';
      document.getElementById("date").value = '';
    }
  }

  render() {
    return (
      <div id="advancedBillSearchContainer">
        <Row form>
        <Col>
            <FormGroup>
              <AppInput
                changeHandler={this.props.onChangeHandler}
                placeholder="External ID"
                id="externalId"
                type="text"
                value={this.props.externalIdAdv}
                advancedBillSearch={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <SearchableSelectForStatus
                data={this.props.status}
                id="billStatus"
                returnValue="id"
                displayOption="description"
                placeholder="Status"
                stateHandler={this.props.onChangeHandler}
                defaultValue={this.props.statusAdv}
                advancedBillSearch={true}
                runSetDataList={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <SearchableSelect
                data={this.props.users}
                id="processor"
                option={{
                  value: 'id',
                  displayValue: 'description',
                }}
                returnValue="key"
                displayOption="description"
                placeholder="User"
                stateHandler={this.props.onChangeHandler}
                defaultValue={this.props.userAdv}
                advancedBillSearch={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <SearchableSelect
                data={this.props.sites}
                id="siteId"
                returnValue="int_sitecode"
                displayOption="chr_sitename"
                placeholder="Site"
                stateHandler={this.props.onChangeHandler}
                defaultValue={this.props.siteAdv}
                advancedBillSearch={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <SearchableSelect
                data={this.props.states}
                id="state"
                returnValue="abbreviation"
                displayOption="name"
                placeholder="State"
                stateHandler={this.props.onChangeHandler}
                defaultValue={this.props.stateAdv}
                advancedBillSearch={true}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <DropdownMultiSelect
                id="billTypes"
                accessor={{
                  key: 'key',
                  value: 'description',
                }}
                options={this.props.billTypes}
                placeHolder="Bill Type"
                optionColumnHeader="Bill Type"
                changeHandler={this.props.onChangeHandler}
                selected={this.props.searchFields.billTypes === "" ? [] : this.props.searchFields.billTypes}
              />
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <div
                id="hidePrintedBillsContainer"
                style={{ marginTop: '5px' }}
              >
                <AppInput
                  changeHandler={this.props.onChangeHandler}
                  placeholder="Hide Printed Bills"
                  type="checkbox"
                  id="hidePrintedBills"
                  checked={this.props.hidePrintedBills}
                  showToolTip={false}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Input
                type="date"
                name="date"
                id="date"
                placeholder="mm/dd/yyyy"
                onChange={e =>
                  this.props.onChangeHandler('date', e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col style={{marginRight: "-29px"}}>
            <FormGroup>
              <Button color="warning" id="saveMySearch">
                SAVE
              </Button>
              <UncontrolledTooltip placement="bottom" target="saveMySearch">
                Save to my Searches
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

AdvancedBillSearch.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  hidePrintedBills: PropTypes.bool.isRequired,
  billTypes: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  status: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  stateAdv: PropTypes.string,
  siteAdv: PropTypes.string,
  isClear: PropTypes.bool.isRequired,
  searchFields: PropTypes.object
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);

  return {
    billTypes: initialData.billTypes,
    sites: initialData.slpsites,
    status: initialData.statuses,
    states: initialData.states,
    users: initialData.processors,
  };
};

export default connect(mapStateToProps)(AdvancedBillSearch);
