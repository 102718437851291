import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppInput from 'shared/components/input/AppInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import DashboardButton from './DashboardButton';

const locationSame = '1';
const locationDiff = '0';
const am = '1';
const pm = '0';

const Fast360INT = ({
  translationCategory,
  translationCertification,
  language,
  claimantAddress,
  appointmentType,
  selectedService,
  zipList,
  getZipList,
  resetZipList,
  userId,
  timeZone,
  fromExt,
  intPo,
  selectedMod,
  updatePostExternal,
  requiredServiceData
}) => {
  const [tab, setTab] = useState(locationDiff);
  const [selectedItem, setSelectedItem] = useState(selectedService);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const [poNum, setPoNum] = useState('');
  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);
  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.translationCategoryId = target.value;
    setSelectedItem({ selectedService });
  };
  useEffect(() => {
    if (fromExt && selectedMod === 'INT') {
      // selectedService.poNumber = intPo.UniquePortalAuthCode;
      if (updatePostExternal?.length > 0) {
        selectedService.dos = moment(updatePostExternal[0].RequestedToDate).format('MM/DD/YYYY');
        const fromLanguage = language.filter(item => item.value == updatePostExternal[0].LanguageFrom);
        const toLanguage = language.filter(item => item.value == updatePostExternal[0].LanguageTo);
        if (fromLanguage.length > 0) selectedService.fromLanguageId = `${fromLanguage[0].lang_id}`;
        if (toLanguage.length > 0) selectedService.languageId = `${toLanguage[0].lang_id}`;
        else
          selectedService.languageId = '670';
        if (updatePostExternal[0].CertifiedTranslator == 1) { selectedService.translationCategoryId = '1391' }
        if (updatePostExternal[0].RequestedAppointmentTime) {
          if (updatePostExternal[0].RequestedAppointmentTime.includes(':')) {
            const time = updatePostExternal[0].RequestedAppointmentTime;
            selectedService.aptHour = Number(time.split(':')[0])
            selectedService.aptMinutes = Number(time.split(':')[1].split(' ')[0])
            if (time.includes('AM')) selectedService.aptAMPM = am;
            else selectedService.aptAMPM = pm;
          }
          else {
            const time = updatePostExternal[0].RequestedAppointmentTime;
            const hrs = Number(time.substring(0, 2));
            const min = Number(time.substring(2, 4));
            if (hrs <= 11) {
              selectedService.aptAMPM = am;
              selectedService.aptHour = (hrs === 0) ? 12 : hrs;
            }
            else {
              selectedService.aptAMPM = pm;
              selectedService.aptHour = hrs;
            }
            selectedService.aptMinutes = min;
          }
        }
      }
      if (intPo.ServiceType === 'On site') { selectedService.translationCategoryId = '898' }
    }
  }, [updatePostExternal.length]);

  useEffect(() => {
    if (fromExt && selectedMod === 'INT') {
      selectedService.poNumber = intPo.UniquePortalAuthCode;
      setPoNum(intPo.UniquePortalAuthCode);
    }
  }, [intPo.UniquePortalAuthCode])
  const onTabRadioChangeHandler = event => {
    selectedService.locationPhone = '';
    selectedService.serviceAddress.locationStreet = '';
    selectedService.serviceAddress.zipCode.id = '';
    selectedService.serviceAddress.city = '';
    selectedService.serviceAddress.state = '';
    selectedService.serviceAddress.county = '';
    selectedService.serviceAddress.zip = '';
    selectedService.serviceAddress.zipCodeId = '';
    selectedService.serviceAddress.zipId = '';
    selectedService.serviceAddress.tz = '';
    setSelectedItem({ selectedService });
  };

  const onTabRadioStartChangeHandler = event => {
    selectedService.startAMPM = event.target.value;
    setSelectedItem({ selectedService });
  };

  const onTabRadioAptChangeHandler = event => {
    selectedService.aptAMPM = event.target.value;
    setSelectedItem({ selectedService });
  };
  const onTabRadioStartAMPMChangeHandler = event => {
    selectedService.startAMPM = event.target.value;
    setSelectedItem({ selectedService });
    handleInputChange(event);
  };

  const onTabRadioAuthChangeHandler = event => {
    selectedService.authTravel = event.target.value;
    setSelectedItem({ selectedService });
  };

  const onTabRadioArriveAMPMChangeHandler = event => {
    selectedService.arriveAMPM = event.target.value;
    setSelectedItem({ selectedService });

  };
  const onTabRadioEndAMPMChangeHandler = event => {
    selectedService.endAMPM = event.target.value;
    setSelectedItem({ selectedService });
    handleInputChange(event);
  };

  const onChangeHandlerCertType = (id, value) => {
    selectedService.certificationId = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerTimeZoneType = (id, value) => {
    selectedService.timeZoneId = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerArriveTimeZoneType = (id, value) => {
    selectedService.arriveTZ = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerStartTimeZoneType = (id, value) => {
    selectedService.startTZ = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerEndTimeZoneType = (id, value) => {
    selectedService.endTZ = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerAppType = (id, value) => {
    selectedService.appointmentTypeId = value;
    if (value === '22' || value === '23' || value === '24' || value === '25' || value === '26') {
      selectedService.highPriority = true;
    }
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerFromLanguage = (id, value) => {
    selectedService.fromLanguageId = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerToLanguage = (id, value) => {
    selectedService.languageId = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
      selectedService.arrivalDate =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
      setValidDos(true);
    }

    setSelectedItem({ selectedService });
  };

  const handleClearButton = event => {
    selectedService.facility.phone = '';
    selectedService.facility.name = '';
    selectedService.facility.address.street1 = '';
    selectedService.facility.address.street2 = '';
    selectedService.facility.address.zipCode.id = '';
    selectedService.facility.address.zipCode.city = '';
    selectedService.facility.address.zipCode.state = '';
    selectedService.facility.address.zipCode.county = '';
    selectedService.facility.address.zipCode.zip = '';
    selectedService.facility.address.zipCodeId = '';
    selectedService.facility.address.zipCode.timeZone = '';

    setSelectedItem({ selectedService });
  };


  const setMap = {
    dos: setValidDos,
  }
  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
      // setPoNum(value)
    }

    if (name === 'arrivalHour') {
      selectedService.arrivalHour = value;
    }
    if (name === 'arrivalMinute') {
      selectedService.arrivalMin = value;
    }
    if (name === 'linesPerPage') {
      selectedService.linesPerPage = value;
    }
    if (name === 'ratePerLine') {
      selectedService.rate = value;
    }
    if (name === 'startHours') {
      selectedService.startHours = value;
    }
    if (name === 'startMinutes') {
      selectedService.startMinutes = value;
    }

    if (name === 'aptHour') {
      selectedService.aptHour = value;
    }
    if (name === 'aptMinutes') {
      selectedService.aptMinutes = value;
    }
    if (name === 'aptTZ') {
      selectedService.aptTZ = value;
    }
    if (name === 'arriveTZ') {
      selectedService.arriveTZ = value;
    }


    if (name === 'startTZ') {
      selectedService.startTZ = value;
    }
    if (name === 'endHour') {
      selectedService.endHour = value;
    }
    if (name === 'endMinute') {
      selectedService.endMinute = value;
    }
    if (name === 'endTZ') {
      selectedService.endTZ = value;
    }

    if (name === 'locationPhone') {
      selectedService.facility.phone = formatPhoneNum(value);
    }

    if (name === 'recurring') {
      selectedService.recurring =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'locationName') {
      selectedService.facility.address.street1 = value;
    }
    if (name === 'locationName2') {
      selectedService.facility.address.street2 = value;
    }
    if (name === 'facilityName') {
      selectedService.facility.name = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    var hours;
    var minutes;
    var stHr;
    var endHr;
    var stMin;
    var endMin;
    var arrHr;
    var arrMin;

    if (selectedService.startHours !== undefined) {
      if (selectedService.startAMPM !== undefined) {
        if (selectedService.startAMPM === "0") {
          if (selectedService.startHours === "12") {
            stHr = selectedService.startHours;
          } else {
            stHr = parseInt(selectedService.startHours) + 12;
          }
        } else {
          if (selectedService.startHours === "12") {
            stHr = 0;
          } else {
            stHr = selectedService.startHours;
          }
        }
      } else {
        stHr = selectedService.startHours
      }
    } else {
      stHr = 0
    }

    if (selectedService.endHour !== undefined) {
      if (selectedService.startAMPM !== undefined) {
        if (selectedService.endAMPM === "0") {
          if (selectedService.endHour == 12) {
            endHr = selectedService.endHour;
          } else {
            endHr = parseInt(selectedService.endHour) + 12;
          }
        } else {
          if (selectedService.endHour == 12) {
            endHr = 0;
          } else {
            endHr = selectedService.endHour;
          }
        }
      } else {
        endHr = selectedService.endHour
      }
    } else {
      stHr = 0
    }
    if (selectedService.endMinute !== undefined) {
      endMin = selectedService.endMinute
    } else {
      endMin = 0
    }
    if (selectedService.startMinutes) {
      stMin = selectedService.startMinutes
    } else {
      stMin = 0
    }

    if (selectedService.arrivalHour !== undefined) {
      if (selectedService.arriveAMPM !== undefined) {
        if (selectedService.arriveAMPM === "0") {
          if (selectedService.arrivalHour === "12") {
            arrHr = selectedService.arrivalHour;
          } else {
            arrHr = parseInt(selectedService.arrivalHour) + 12;
          }
        } else {
          if (selectedService.arrivalHour === "12") {
            arrHr = 0;
          } else {
            arrHr = selectedService.arrivalHour;
          }
        }
      } else {
        arrHr = selectedService.arrivalHour
      }
    } else {
      arrHr = 0
    }

    if (selectedService.arrivalMin) {
      arrMin = selectedService.arrivalMin
    } else {
      arrMin = 0
    }

    if (selectedService.startHours) {
      selectedService.startTime = stHr + ':' + stMin + ':' + '00';
    }

    if(selectedService.translationCategoryId === '1689' && (selectedService.aptHour || selectedService.startHours)) {
      let stHrr = selectedService.aptHour ? selectedService.aptHour : selectedService.startHours;
      let stMinn = selectedService.aptMinutes ? selectedService.aptMinutes : selectedService.startMinutes; 
      selectedService.startTime = stHrr + ':' + stMinn + ':' + '00';
    }

    if (selectedService.endHour) {
      selectedService.endTime = endHr + ':' + endMin + ':' + '00';
    }

    if (selectedService.arrivalHour) {
      selectedService.arrivalTime = arrHr + ':' + arrMin + ':' + '00';
    }

    if (parseInt(stMin) > parseInt(endMin)) {
      minutes = (parseInt(endMin) + 60) - stMin
      endHr = endHr - 1
    } else {
      minutes = parseInt(endMin) - parseInt(stMin)
    }
    hours = parseInt(endHr) - parseInt(stHr);
    selectedService.durationHour = hours;
    selectedService.durationMinutes = minutes;


    setSelectedItem({ selectedService });
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip != null) {
      selectedService.facility.address.zipCode.id = selectedZip.code_id;
      selectedService.facility.address.zipCode.city = selectedZip.chr_city;
      selectedService.facility.address.zipCode.state = selectedZip.chr_state;
      selectedService.facility.address.zipCode.county = selectedZip.chr_county;
      selectedService.facility.address.zipCode.zip = selectedZip.chr_zipcode;
      selectedService.facility.address.zipCodeId = selectedZip.code_id;
      selectedService.facility.address.zipCode.timeZone = selectedZip.chr_timezone;
      setSelectedItem({ selectedService });
    }
    resetZipList();
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };
  const apptTimeField = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (
        (selectedService.translationCategoryId === '898' || selectedService.translationCategoryId === '1391' || 
        selectedService.translationCategoryId === '1392' || selectedService.translationCategoryId === '900' || 
        selectedService.translationCategoryId === '1689')
      ) {
        return (
          <>
            {' '}
            <Col xs="2">
              <Label>Appt Hour: </Label>
              <Input
                id="aptHour"
                name="aptHour"
                type="number"
                onChange={handleInputChange}
                value={selectedService?.aptHour || ''}
                max={12}
                min={1}
              />{' '}
            </Col>
            <Col sm="2">
              <Label>Minute:</Label>
              <Input
                id="aptMinutes"
                name="aptMinutes"
                type="number"
                onChange={handleInputChange}
                value={selectedService?.aptMinutes === 0 ? 0 : selectedService?.aptMinutes}
                max={59}
                min={0}
              />{' '}
            </Col>
            <Col sm="1" style={{ border: selectedService?.aptAMPM == undefined ? '1px solid red' : '' }}>
              <FormGroup className="form-check-inline">

                <CustomInput
                  type="radio"
                  label="AM"
                  id="aptAM"
                  name="aptAMPM"
                  value={am}
                  checked={selectedService?.aptAMPM === am}
                  onChange={onTabRadioAptChangeHandler}
                />
              </FormGroup>
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  id="aptPM"
                  name="aptAMPM"
                  label="PM"
                  value={pm}
                  checked={selectedService?.aptAMPM === pm}
                  onChange={onTabRadioAptChangeHandler}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <Label>Timezone:</Label>
              <AppInput
                id="timezone"
                type="select"
                changeHandler={onChangeHandlerTimeZoneType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.timeZoneId || ''}
                data={timeZone}
              />
            </Col>
          </>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const arrivalTimeField = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (
        (selectedService.translationCategoryId === '898' || selectedService.translationCategoryId === '1391' || selectedService.translationCategoryId === '1392')
      ) {
        return (
          <>
            <Col xs="2">
              <Label> Arrival Time: </Label>
              <Input id="arriveHour" name="arrivalHour"
                type="number"
                onChange={handleInputChange}
                value={selectedService?.arrivalHour || ''}
                max={12} min={1} />{' '}
            </Col>
            <Col sm="2">
              <Label>Minute:</Label>
              <Input id="arriveMinute" name="arrivalMinute"
                onChange={handleInputChange}
                value={selectedService?.arrivalMin ? selectedService?.arrivalMin : 0} type="number" max={59} min={0} />{' '}
            </Col>
            <Col sm="1">
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  label="AM"
                  id="arriveAM"
                  name="arriveAMPM"
                  value={am}
                  checked={selectedService?.arriveAMPM === am}
                  onChange={onTabRadioArriveAMPMChangeHandler}
                />
              </FormGroup>
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  id="arrivePM"
                  name="arriveAMPM"
                  label="PM"
                  value={pm}
                  checked={selectedService?.arriveAMPM === pm}
                  onChange={onTabRadioArriveAMPMChangeHandler}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <Label>Timezone:</Label>
              <AppInput
                id="timezone"
                type="select"
                changeHandler={onChangeHandlerArriveTimeZoneType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.arriveTZ || ''}
                data={timeZone}
              />
            </Col>
          </>
        );
      } else if (
        selectedService.translationCategoryId === '1088' ||
        selectedService.translationCategoryId === '899'
      ) {
        return (
          <>
            <Col xs="3">
              <Label># of lines per pages </Label>
              <Input id="linesPer" name="linesPerPage" type="number" value={selectedService?.linesPerPage || ''} onChange={handleInputChange} />
            </Col>
            <Col xs="3">
              <Label> Rate per line </Label>
              <Input id="rate" name="ratePerLine" value={selectedService?.rate || ''} onChange={handleInputChange} />
            </Col>
          </>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  const startTimeField = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (
        (selectedService.translationCategoryId === '898' || selectedService.translationCategoryId === '1391' || selectedService.translationCategoryId === '1392' || selectedService.translationCategoryId === '900')
      ) {
        return (
          <>
            <Col xs="2">
              <Label> Start Time: </Label>
              <Input id="startlHours" name="startHours" onChange={handleInputChange}
                value={selectedService?.startHours || ''} type="number" max={12} min={1} />{' '}
            </Col>
            <Col sm="2">
              <Label>Minute:</Label>
              <Input id="startlMinutes" name="startMinutes" onChange={handleInputChange}
                value={selectedService?.startMinutes ? selectedService?.startMinutes : 0} type="number" max={59} min={0} />{' '}
            </Col>
            <Col sm="1">
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  label="AM"
                  id="startAM"
                  name="startAMPM"
                  value={am}
                  checked={selectedService?.startAMPM === am}
                  onChange={onTabRadioStartAMPMChangeHandler}
                />
              </FormGroup>
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  id="startPM"
                  name="startAMPM"
                  label="PM"
                  value={pm}
                  checked={selectedService?.startAMPM === pm}
                  onChange={onTabRadioStartAMPMChangeHandler}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <Label>Timezone:</Label>
              <AppInput
                id="timezone"
                type="select"
                changeHandler={onChangeHandlerStartTimeZoneType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.startTZ || ''}
                data={timeZone}
              />
            </Col>
          </>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getDuration = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (selectedService?.translationCategoryId !== '1088') {
        if (selectedService?.translationCategoryId === '1689') {
          return '';
        } else {
          return (
            <>
              <Col xs="10">
                <Label> Duration {selectedService?.durationHour} hour {selectedService?.durationMinutes} minutes </Label>
              </Col>
            </>
          );
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  const endTimeField = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (selectedService.translationCategoryId === '898' || selectedService.translationCategoryId === '1391' || selectedService.translationCategoryId === '900' || selectedService.translationCategoryId === '1392') {
        return (
          <>
            <Col xs="2">
              <Label> End Time: </Label>
              <Input id="endHour" name="endHour" type="number" onChange={handleInputChange}
                value={selectedService?.endHour || ''} max={12} min={1} />{' '}
            </Col>
            <Col sm="2">
              <Label>Minute:</Label>
              <Input id="endMinute" type="number" name="endMinute" onChange={handleInputChange}
                value={selectedService?.endMinute ? selectedService?.endMinute : 0} max={59} min={0} />{' '}
            </Col>
            <Col sm="1">
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  label="AM"
                  id="endAM"
                  name="endAMPM"
                  value={am}
                  checked={selectedService?.endAMPM === am}
                  onChange={onTabRadioEndAMPMChangeHandler}
                />
              </FormGroup>
              <FormGroup className="form-check-inline">
                <CustomInput
                  type="radio"
                  id="endPM"
                  name="endAMPM"
                  label="PM"
                  value={pm}
                  checked={selectedService?.endAMPM === pm}
                  onChange={onTabRadioEndAMPMChangeHandler}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <Label>Timezone:</Label>
              <AppInput
                id="timezone"
                type="select"
                changeHandler={onChangeHandlerEndTimeZoneType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.endTZ || ''}
                data={timeZone}
              />
            </Col>
          </>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const locationField = () => {
    if (selectedService?.translationCategoryId !== undefined) {
      if (
        (selectedService.translationCategoryId === '899' || selectedService.translationCategoryId === '900' || selectedService.translationCategoryId === '1088')
      ) {
        return '';
      } else {
        return (
          <>
            <Row>
              <Col sm="6">
                <Label>Facility Name:</Label>
                <Input
                  id="facilityNameUI"
                  name="facilityName"
                  onChange={handleInputChange}
                  className={(selectedService?.facility?.name == "" || selectedService?.facility?.name === undefined) && (selectedService?.subService == "Certified Legal - On-Site Interpretation" ||
                    selectedService?.subService == "Certified Medical – On-Site Interpretation" || selectedService?.subService == "On-Site Interpretation") ? "error" : ''}
                  value={selectedService?.facility?.name || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <Label>Address: </Label>
                <Input
                  id="LocationNameUI"
                  name="locationName"
                  onChange={handleInputChange}
                  className={(selectedService?.facility?.address?.street1 == "" || selectedService?.facility?.address?.street1 === undefined) && (selectedService?.subService == "Certified Legal - On-Site Interpretation" ||
                    selectedService?.subService == "Certified Medical – On-Site Interpretation" || selectedService?.subService == "On-Site Interpretation") ? "error" : ''}
                  value={selectedService?.facility?.address?.street1 || ''}
                />
              </Col>
              <Col sm="4">
                <Label>Address2: </Label>
                <Input
                  id="LocationNameUI"
                  name="locationName2"
                  onChange={handleInputChange}
                  value={selectedService?.facility?.address?.street2 || ''}
                />
              </Col>
              <Col sm="4">
                <Row>
                  <Label>City/State/Zip: </Label>
                </Row>
                <Row>
                  <Label>
                    {selectedService?.facility?.address?.zipCode?.city}{' '}
                    {selectedService?.facility?.address?.zipCode?.state},{' '}
                    {selectedService?.facility?.address?.zipCode?.zip}
                  </Label>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <Label>County: </Label>
                <Input
                  id="LocationNameUI"
                  name="locationName2"
                  onChange={handleInputChange}
                  value={selectedService?.facility?.address?.zipCode?.county || ''}
                />
              </Col>
              <Col sm="5">
                <Label for="locZip">Lookup Zip</Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  onChange={(e, actionType) =>
                    handleFormFields(e, 'locZip', actionType)
                  }
                  placeholder=""
                  menuPlacement="bottom"
                  onKeyDown={e => handleTypeAheadSearch(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  menuIsOpen={zipList.length}
                  value={selectedService?.facility?.address?.zipCode?.zip}
                  closeMenuOnSelect={true}
                  options={
                    zipList.length
                      ? zipList.map(obj =>
                        assign(obj, {
                          label: obj.zip_type_with_county,
                          value: obj.code_id,
                        })
                      )
                      : []
                  }
                  name="locZip"
                  id="locZip"
                />
                <span>type in 3+ digits and hit tab to search</span>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <Label>Phone: </Label>
                <Input
                  name="locationPhone"
                  onChange={handleInputChange}
                  value={selectedService?.facility.phone || ''}
                  placeholder="(xxx)xxx-xxxx"
                  className={selectedService?.facility?.phone == "" && (selectedService?.subService == "Certified Legal - On-Site Interpretation" ||
                    selectedService?.subService == "Certified Medical – On-Site Interpretation" || selectedService?.subService == "On-Site Interpretation") ? "error" : ''}
                />
              </Col>
            </Row>
            <Button style={{ marginTop: "7px" }} color="primary" onClick={handleClearButton}>
              Clear Location
            </Button>
          </>
        )
      }
    }
  }

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subServType'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                placeholder="Choose..."
                value={selectedService?.translationCategoryId || ''}
                className={selectedService?.translationCategoryId ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {translationCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              // value={poNum || ''}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Certification: </Label>
            <AppInput
              id="certUI"
              type="select"
              changeHandler={onChangeHandlerCertType}
              option={{
                value: 'id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={selectedService?.certificationId || ''}
              data={translationCertification}
            />
          </Col>
          <Col sm="3">
            <Label>Appt Type: </Label>
            <AppTooltip text={'Appt Type is a required field'} id={'intdos'}>
              <AppInput
                id="appt"
                type="select"
                changeHandler={onChangeHandlerAppType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.appointmentTypeId || ''}
                data={appointmentType}
              />
            </AppTooltip>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>From Language: </Label>
            <AppTooltip text={'From language is a required field'} id={'fromLangUI'}>
              <AppInput
                id="fromLangUI"
                type="select"
                changeHandler={onChangeHandlerFromLanguage}
                option={{
                  value: 'lang_id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.fromLanguageId || ''}
                data={language}
                className={selectedService?.fromLanguageId ? '' : "error"}
                error={selectedService?.fromLanguageId ? false : true}
              />
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>Target Language: </Label>
            <AppInput
              id="toLangUI"
              type="select"
              changeHandler={onChangeHandlerToLanguage}
              option={{
                value: 'lang_id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={selectedService?.languageId || ''}
              className={selectedService?.languageId ? '' : "error"}
              data={language}
              error={selectedService?.languageId ? false : true}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col xs="2"></Col>
              <Label check>
                <Input
                  name="rush"
                  type="checkbox"
                  onChange={handleInputChange}
                  checked={selectedService?.rush}
                />{' '}
                Rush Order
              </Label>
              <Col xs="2"></Col>
              <Label check>
                <Input
                  type="checkbox"
                  name="recurring"
                  onChange={handleInputChange}
                  checked={selectedService?.recurring}
                />{' '}
                Recurring Order
              </Label>
              <Col xs="2"></Col>
              <Label check>
                <Input
                  name="highPriority"
                  type="checkbox"
                  onChange={handleInputChange}
                  checked={selectedService?.highPriority}
                />{' '}
                High Priority
              </Label>
            </Row>
            <Row> </Row>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'intdos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>
          {apptTimeField()}
          <Col xs="1"></Col>
        </Row>
        <Row> {arrivalTimeField()}</Row>
        <Row> {startTimeField()}</Row>
        <Row> {endTimeField()}</Row>
        <Row> {getDuration()}</Row>
        <Row>
          <Col sm="8">
            <Label style={{ marginRight: "5px" }}>Authorize Travel </Label>
            <FormGroup className="form-check-inline">
              <CustomInput
                type="radio"
                label="yes"
                id="authTravelYes"
                name="authTravelRadio"
                value={am}
                checked={selectedService?.authTravel === am}
                onChange={onTabRadioAuthChangeHandler}
              />
            </FormGroup>
            <FormGroup className="form-check-inline">
              <CustomInput
                type="radio"
                id="authTravelNo"
                name="authTravelRadio"
                label="no"
                value={pm}
                checked={selectedService?.authTravel === pm}
                onChange={onTabRadioAuthChangeHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        {locationField()}

      </FormGroup>
    </>
  );
};

Fast360INT.propTypes = {
  translationCategory: PropTypes.array,
  translationCertification: PropTypes.array,
  claimantAddress: PropTypes.any,
  language: PropTypes.array,
  appointmentType: PropTypes.array,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  var claimantAddress;

  if (fast360Info.updatedClaimInfo.claim != undefined) {
    if (fast360Info.updatedClaimInfo.claim.claimant != undefined) {
      if (fast360Info.updatedClaimInfo.claim.claimant.address != undefined) {
        claimantAddress = fast360Info.updatedClaimInfo.claim.claimant.address
      } else {
        claimantAddress = undefined;
      }
    } else {
      claimantAddress = undefined;
    }
  } else {
    claimantAddress = undefined;
  }
  return {
    translationCategory: initialData.translationCategory,
    translationCertification: initialData.translationCertification,
    claimantAddress: claimantAddress,
    language: initialData.language,
    timeZone: initialData.timeZone,
    appointmentType: initialData.appointmentType,
    zipList: fast360Info.zipList,
    userId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360INT);
