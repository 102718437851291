import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store';


import { useAuth } from 'modules/auth/hooks/useAuth';
import AppTable from 'shared/components/table/AppTable';

import * as NumberUtils from 'shared/utils/numberUtils';
import * as StringUtils from 'shared/utils/stringUtils';

import operations from '../store/operations';
import selectors from '../store/selectors';
import PopupModal from 'shared/components/PopupModal';

function GpHeaderTable({ data }) {
  return (

    <AppTable
      columns={[
        { header: 'Invoice Number', field: 'InvoiceNumber', minWidth: '150' },
        { header: 'Client ID', field: 'clientID', minWidth: '125' },
        { header: 'Date', field: 'Today', minWidth: '75' },
        { header: 'PO Number', field: 'PONumber', minWidth: '100' },
        { header: 'Claimant', field: 'claimant', minWidth: '150' },
        { header: 'Claimant ID #', field: 'claimantID', minWidth: '100' },
        { header: 'DOB', field: 'DOB', minWidth: '75' },
        {
          header: 'SSN',
          minWidth: '125',
          field: 'SSN',
          render: (item, index) => (
            <>
              <Button
                color="link"
                id={`ssnLink${index}`}
                style={{ padding: 0 }}
              >
                {NumberUtils.formatMaskedSsn(item.SSN)}
              </Button>
              <UncontrolledPopover
                trigger="focus"
                placement="top"
                target={`ssnLink${index}`}
              >
                <PopoverBody>{NumberUtils.formatSsn(item.SSN)}</PopoverBody>
              </UncontrolledPopover>
            </>
          ),
        },
        { header: 'DOI', field: 'DOI', minWidth: '75' },
        { header: 'DOS', field: 'DOS', minWidth: '75' },
        { header: 'Case Manager', field: 'CaseManager', minWidth: '150' },
        { header: 'Adjuster', field: 'Adjuster', minWidth: '150' },
        { header: 'Employer', field: 'Employer', minWidth: '150' },
        {
          header: 'DX Codes',
          minWidth: '200',
          field: 'DXCodes',
          percent: '200',
          render: item => (
            <div style={{ wordBreak: 'break-all' }}>
              {StringUtils.truncate(item.DXCodes || [], 25)}
            </div>
          ),
        },
        { header: 'Type', field: 'Type', minWidth: '75' },
        { header: 'Status', field: 'Status', minWidth: '90' },
        { header: 'Reason', field: 'Reason', minWidth: '100' },
        { header: 'GPO', field: 'GPO', minWidth: '75' },
        { header: 'Client ID #', field: 'YorkEmployerClientID', minWidth: '75' },
        { header: 'Insured ID #', field: 'YorkEmployerInsuredId', minWidth: '75' },
        { header: 'Adjuster Email', field: 'AdjusterEmail', minWidth: '150' },
      ]}
      data={data}
      sortAble
      modalHeight={600}

    />
  );
}

function GpDetailTable({ data }) {
  return (
    <AppTable
      columns={[
        { header: 'Invoice Number', field: 'ConfirmNumber' },
        { header: 'Item', field: 'Item' },
        { header: 'Quantity', field: 'Quantity' },
        { header: 'Rate', field: 'Rate' }, // TODO: Format to two decimal
        { header: 'Territory', field: 'TerritoryID' },
        { header: 'Description', field: 'CommentID' },
        { header: 'Rental Dates', field: '' },
        { header: 'Physician', field: 'Physician' },
        {
          header: 'Physician Phone',
          field: 'physicianPhone',
          render: item =>
            NumberUtils.formatPhoneNumber(item.physicianPhone || ''),
        },
        { header: 'Physician NPI', field: 'physicianNPI' },
        { header: 'Physician License', field: 'physicianLicense' },
        { header: 'Vendor', field: 'Vendor' },
        {
          header: 'Vendor Phone',
          field: 'vendorPhone',
          render: item => NumberUtils.formatPhoneNumber(item.vendorPhone || ''),
        },
        { header: 'Vendor NPI', field: 'vendorNPI' },
        { header: 'Vendor License', field: 'vendorLicense' },
      ]}
      data={data}
      resizable
      sortAble
      modalHeight={200}
    />
  );
}

function GpDisplayTable({ data, gpType }) {
  let columns = [
    { header: 'Detail Lines', field: 'Counter' },
    { header: 'Confirm Number', field: 'InvoiceNumber' },
    { header: 'Claimant Name', field: 'claimant' },
    { header: 'Company Name', field: 'Company' },
    { header: 'Status', field: 'Status' },
    { header: 'Client ID #', field: 'YorkEmployerClientID' },
    { header: 'Insured ID #', field: 'YorkEmployerInsuredId' },
  ];

  if (gpType === 'Interpretation') {
    columns = [
      { header: 'Detail Lines', field: 'Counter' },
      { header: 'Confirm Number', field: 'InvoiceNumber' },
      { header: 'Claimant Name', field: 'claimant' },
      { header: 'Company Name', field: 'Company' },
      { header: 'Status', field: 'Status' },
      { header: 'Int Type', field: 'product' },
      { header: 'From Language', field: 'langFrom' },
      { header: 'To Language', field: 'lang' },
      { header: 'Client ID #', field: 'YorkEmployerClientID' },
      { header: 'Insured ID #', field: 'YorkEmployerInsuredId' },
    ];
  }

  if (gpType === 'Special Transportation' || gpType === 'Transportation') {
    columns = [
      { header: 'Detail Lines', field: 'detailCount' },
      { header: 'Confirm Number', field: 'InvoiceNumber' },
      { header: 'Claimant Name', field: 'claimant' },
      { header: 'Pickup Date', field: 'DOS' },
      { header: 'Company Name', field: 'Company' },
      { header: 'Status', field: 'Status' },
      { header: 'Transport Type', field: 'product' },
      { header: 'Client ID #', field: 'YorkEmployerClientID' },
      { header: 'Insured ID #', field: 'YorkEmployerInsuredId' },
    ];
  }

  return (
    <AppTable
      columns={columns}
      data={data}
      resizable
      sortAble
      modalHeight={200}
    />
  );
}

function GpIntegrationsModal({ gpIntegration, isOpen, onClose, currentUserId, getGpInfo, gpInfoData, logBreadCrumbs, saveGreatPlains, greatPlainsSaved, clearGreatPlains }) {
  const dispatch = useDispatch();
  const { userId } = useAuth();
  const [selectedView, setSelectedView] = useState('Display');
  const [archiveModelOpen, setArchiveModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [showGpSuccessModal, setShowGpSucessModal] = useState(false);
  
  const { gpData } = useSelector(selectors.getFast360Info);
  const { archiveList, noDetailList, detailList, headerList } = gpData;

  const noDetailsColumns = [
    { header: 'Detail Lines', render: () => 0 },
    { header: 'Confirm Number', field: 'InvoiceNumber' },
    { header: 'Claimant Name', field: 'claimant' },
    { header: 'Status', field: 'Status' },
  ];

  const integrationColumns = [
    { header: 'Type', field: 'type' },
    { header: 'Date', field: 'date' },
    { header: 'File Name', field: 'fileName' },
  ];

  useEffect(() => {
    if (gpIntegration === '') {
      dispatch(operations.clearGpData());
    } else {
      const data = { userId };

      switch (gpIntegration) {
        case 'Accommodation':
          data.gpId = '1';
          data.service = 'ACC';
          break;
        case 'Audiology':
          data.gpId = '2';
          data.service = 'AUD';
          break;
        case 'Dental':
          data.gpId = '3';
          data.service = 'DTL';
          break;
        case 'Diagnostic':
          data.gpId = '4';
          data.service = 'DGN';
          break;
        case 'HHC':
          data.gpId = '5';
          data.service = 'HHC';
          break;
        case 'Interpretation':
          data.gpId = '10';
          data.service = 'INT';
          break;
        case 'Modification':
          data.gpId = '6';
          data.service = 'MOD';
          break;
        case 'MSE':
          data.gpId = '9';
          data.service = 'MSE';
          break;
        case 'Transportation':
          data.gpId = '7';
          data.service = 'TRN';
          break;
        case 'Special Transportation':
          data.gpId = '8';
          data.service = 'SPT';
          break;
        default:
          break;
      }

      dispatch(operations.getGpData(data));
    }
  }, [dispatch, userId, gpIntegration, showGpSuccessModal]);

  const SelectedTable = props => {
    if (selectedView === 'Header') {
      return <GpHeaderTable {...props} />;
    }

    if (selectedView === 'Detail') {
      return <GpDetailTable {...props} />;
    }

    return <GpDisplayTable {...props} />;
  };

  useEffect(() => {
    if (archiveModelOpen && selectedFilename !== '') {
      const payload = {
        functionName: 'loadGPInfo',
        zebraUserId: currentUserId,
        fileName: `${selectedFilename}.txt`
      };
      getGpInfo(payload);
    }
  }, [archiveModelOpen])

  useEffect(() => {
    if(greatPlainsSaved) {
      setShowGpSucessModal(true);
      clearGreatPlains();
    }
  }, [greatPlainsSaved]);



  const toggleModal = () => {
    setSelectedView('Display');
    onClose();
  };

  const runIntegration = () => {
    let module = '';
    switch (gpIntegration) {
      case 'Accommodation':
        module = 'ACC';
        break;
      case 'Audiology':
        module= 'AUD';
        break;
      case 'Dental':
        module = 'DTL';
        break;
      case 'Diagnostic':
        module = 'DGN';
        break;
      case 'HHC':
        module = 'HHC';
        break;
      case 'Interpretation':
        module = 'INT';
        break;
      case 'Modification':
        module = 'MOD';
        break;
      case 'MSE':
        module = 'MSE';
        break;
      case 'Transportation':
        module = 'TRN';
        break;
      case 'Special Transportation':
        module = 'SPT';
        break;
      default:
        break;
    }
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": 'RUNGPINTEGRATION',
      "refTableId": 0,
      "refId":  0,
      "refTable": module,
    };
  
    logBreadCrumbs(breadCrumb);

    
    const serverType = process.env.NODE_ENV;
    //console.log(serverType);
    let serverName = 'QA'

    if(serverType === 'docker' || serverType === 'dockerQA' ) {
      serverName = 'QA'
    }

    if(serverType === 'dockerPROD') {
      serverName = 'PROD'
    }


    if(serverType === 'dockerUAT') {
      serverName = 'UAT'
    }



    let req = {
        "functionName": "saveGreatPlains",
        "zebraUserId": userId,
        "serviceId": module,
        "userId": userId,
        "serverType": process.env.REACT_APP_SERVER_TYPE
    }
    

    saveGreatPlains(req);
   
  };

  const onRowDoubleClick = (data) => {
    setSelectedFilename(data.fileName);
    setSelectedType(data.type)
    setArchiveModalOpen(true);
  }
  const toggleArchiveModal = () => {
    setArchiveModalOpen(false);
  }

  const getColumns = () => {
    let columnData = [];
    const pushData = (header, field, minWidth) => {
      columnData.push({ header, field, minWidth })
    }
    if (selectedType == 'details') {
      pushData('Invoice Number', 'Invoice Number ', '150');
      pushData('Item', 'Item ', '125');
      pushData('Quantity', 'Quantity ', '75');
      pushData('Rate', 'Price ', '75');
      pushData('Territory', 'Territory ID ', '70');
      pushData('Description', 'Description ', '150');
      pushData('RentalDates', 'RentalDates ', '100');
      pushData('Physician', 'Physician ', '200');
      pushData('Physician Phone', 'Physician Phone ', '130');
      pushData('Physician NPI', 'Physician NPI ', '110');
      pushData('Physician License', 'Physician License ', '140');
      pushData('Vendor', 'Vendor ', '190');
      pushData('Vendor Phone', 'Vendor Phone ', '115');
      pushData('Vendor NPI', 'Vendor NPI ', '95');
      pushData('Vendor License', 'Vendor License ', '125');
    }
    else if (selectedType == 'header') {
      pushData('Invoice Number', 'Invoice Number ', '150');
      pushData('Client ID', 'Client ID ', '125');
      pushData('Date', 'Date ', '85');
      pushData('PO Number', 'PO Number ', '100');
      pushData('Claimant', 'Claimant ', '150');
      pushData('Claimant ID#', 'Claimant ID# ', '100');
      pushData('DOB', 'DOB ', '85');
      columnData.push({
        header: 'SSN',
        minWidth: '125',
        render: (item, index) => (
          <>
            <Button
              color="link"
              id={`ssnLink${index}`}
              style={{ padding: 0 }}
            >
              {NumberUtils.formatMaskedSsn(item['SSN '])}
            </Button>
            <UncontrolledPopover
              trigger="focus"
              placement="top"
              target={`ssnLink${index}`}
            >
              <PopoverBody>{NumberUtils.formatSsn(item['SSN '])}</PopoverBody>
            </UncontrolledPopover>
          </>
        )
      })
      pushData('DOI', 'DOI ', '85');
      pushData('DOS', 'DOS ', '85');
      pushData('Case Manager', 'Case Manager ', '150');
      pushData('Adjuster', 'Adjuster ', '150');
      pushData('Employer', 'Employer ', '150');
      pushData('DX Codes', 'DXCodes ', '150');
      pushData('Type', 'Type ', '75');
      pushData('Status', 'Status ', '90');
      pushData('Reason', 'Reason ', '100');
      pushData('GPO', 'GPO ', '85');
    }

    return columnData;
  }

  const ArchiveModal = ({ gpInfoData }) => {
    return (
      <Modal size="xl" isOpen={archiveModelOpen}>
        <ModalHeader toggle={toggleArchiveModal}>
          {gpIntegration} Archive File: {selectedFilename}
        </ModalHeader>
        <ModalBody>
          <AppTable
            columns={getColumns()}
            data={gpInfoData}
            autoPagination={true}
            sortAble={true}
            resizable={true}
          />

          <Row>
            <Col align="right">
              <Button className="Archile File" onClick={() => toggleArchiveModal()}>Cancel</Button>
            </Col>
          </Row>

        </ModalBody>
      </Modal>
    )
  }

  const getSuccessModalBody = () => {
    let mod = ''
    switch (gpIntegration) {
      case 'Accommodation':
        mod = 'ACC';
        break;
      case 'Audiology':
        mod= 'AUD';
        break;
      case 'Dental':
        mod = 'DTL';
        break;
      case 'Diagnostic':
        mod = 'DGN';
        break;
      case 'HHC':
        mod = 'HHC';
        break;
      case 'Interpretation':
        mod = 'INT';
        break;
      case 'Modification':
        mod = 'MOD';
        break;
      case 'MSE':
        mod = 'MSE';
        break;
      case 'Transportation':
        mod = 'TRN';
        break;
      case 'Special Transportation':
        mod = 'SPT';
        break;
      default:
        break;
    }
    return (
      <>
      <div className="text-center">
        <div className="text-center">Your {mod} files have been processed</div>
        <div className="text-center">
          Exit the integration form and retrieve the zip file from the sftp site
        </div>
      </div>
      </>
    )
  }

  const getSuccessModalFooter = () => {
    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center'}}>
        <Button
          color="primary"
          onClick={() => {
            setShowGpSucessModal(false);
          }}
        >
          OK
        </Button>
      </div>
    );
  };
 

  return (
    <>
      <ArchiveModal gpInfoData={gpInfoData} />
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={toggleModal}>
          {`${gpIntegration} File Generation For Great Plains`}
        </ModalHeader>
        <ModalBody>
          <Row className="pb-1">
            <Col
              lg={6}
            >{`Generated files for ${gpIntegration} invoice import`}</Col>
            <Col lg={6} align="right">
              <Button
                type="button"
                className="mr-1"
                disabled={selectedView === 'Header'}
                onClick={() => setSelectedView('Header')}
              >
                View Header File
              </Button>
              <Button
                type="button"
                className="mr-1"
                disabled={selectedView === 'Detail'}
                onClick={() => setSelectedView('Detail')}
              >
                View Detail File
              </Button>
              <Button
                type="button"
                disabled={selectedView === 'Display'}
                onClick={() => setSelectedView('Display')}
              >
                Display View
              </Button>
            </Col>
          </Row>
          <Row className="pb-1">
            <Col lg={12}>
              <div style={{ width: '100%', height: '100%' }}>
                <SelectedTable style={{ width: '100%', height: '100%' }}
                  data={
                    (selectedView === 'Detail' ? detailList : headerList) || []
                  }
                  gpType={gpIntegration}
                />
              </div>
            </Col>
          </Row>
          <Row className="pb-1">
            <Col lg={12} align="right">
              {`${(headerList || []).length} invoice header records / ${(detailList || []).length
                } invoice detail records`}
            </Col>
          </Row>
          <Row className="pb-1">
            <Col lg={5}>
              <Row className="pb-1">
                <Col>Referrals Without Detail Lines</Col>
              </Row>
              <Row>
                <Col>
                  <AppTable
                    columns={noDetailsColumns}
                    data={noDetailList || []}
                    modalHeight={200}
                    resizable
                    sortAble
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <Row className="pb-1">
                <Col>Integration File Archive</Col>
              </Row>
              <Row>
                <Col>
                  <AppTable
                    columns={integrationColumns}
                    data={archiveList || []}
                    modalHeight={200}
                    resizable
                    sortAble
                    rowDoubleClickHandler={onRowDoubleClick}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={2}>
              <Row className="pb-1">
                <Col align="right">
                  <Button 
                  disabled={detailList && headerList && detailList.length === 0 && headerList.length === 0} 
                  onClick={runIntegration}>Run DB Integration</Button>
                </Col>
              </Row>
              <Row>
                <Col align="right">
                  <Button onClick={toggleModal}>Cancel</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {
        showGpSuccessModal && <PopupModal
        content={ getSuccessModalBody()}
        title=""
        externalToggle={() => setShowGpSucessModal(false)}
        isOpen={showGpSuccessModal}
        footerContent={getSuccessModalFooter()}
        size="md"
      />
      }
    </>
  );
}

GpIntegrationsModal.propTypes = {
  gpIntegration: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentUserId: PropTypes.any,
  getGpInfo: PropTypes.any,
  gpInfoData: PropTypes.any,
  logBreadCrumbs: PropTypes.func.isRequired,
};


const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  return {
    currentUserId: userId,
    gpInfoData: state.FAST360.fast360Reducer.gpInfo,
    greatPlainsSaved: state.FAST360.fast360Reducer.greatPlainsSaved

  };
};

const mapDispatchToProps = dispatch => ({
  getGpInfo: (data) => dispatch(fast360Operations.getGpInfo(data)),
  logBreadCrumbs : (data) =>
  dispatch(fast360Operations.logBreadCrumbs(data)),
  saveGreatPlains: (data) => dispatch(fast360Operations.saveGreatPlains(data)),
  clearGreatPlains: () => dispatch(fast360Operations.clearGreatPlains())
});

export default connect(mapStateToProps, mapDispatchToProps)(GpIntegrationsModal);
