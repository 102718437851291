import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

const LAST_NAME = 'Last Name';
const FIRST_NAME = 'First Name';
const DATE_OF_BIRTH = 'Date of Birth';
const SPECIALTIES = 'Specialties';
export default class NewExpertTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: LAST_NAME,
      sortOrder: true,
    };
  }

  getRow(item) {
    return {
      id: item.expert_id,
      firstName: item.firstname || '',
      lastName: item.lastname || '',
      dateOfBirth: item.dob || '',
      specialties: item.specialties || '',
    };
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case FIRST_NAME:
        const firstName1 = item1.firstname || '';
        const firstName2 = item2.firstname || '';
        return sortOrder
          ? firstName1.localeCompare(firstName2)
          : firstName2.localeCompare(firstName1);
      case LAST_NAME:
        const lastName1 = item1.lastname || '';
        const lastName2 = item2.lastname || '';
        return sortOrder
          ? lastName1.localeCompare(lastName2)
          : lastName2.localeCompare(lastName1);
      case DATE_OF_BIRTH:
        const dateOfBirth1 = item1.dob ? new Date(item1.dob) : new Date();
        const dateOfBirth2 = item2.dob ? new Date(item2.dob) : new Date();
        return sortOrder
          ? dateOfBirth1 - dateOfBirth2
          : dateOfBirth2 - dateOfBirth1;
      case SPECIALTIES:
      default:
        const specialties1 = item1.specialties || '';
        const specialties2 = item2.specialties || '';
        return sortOrder
          ? specialties1.localeCompare(specialties2)
          : specialties2.localeCompare(specialties1);
    }
  }

  render() {
    const data = this.props.data
      .sort((item1, item2) => this.sortData(item1, item2))
      .map((item, index) => this.getRow(item, index));
    return (
      <div className="row-clickable">
        <AppTable
          resizable
          columns={[
            {
              header: this.getHeader(FIRST_NAME),
              field: 'firstName',
            },
            {
              header: this.getHeader(LAST_NAME),
              field: 'lastName',
            },
            {
              header: this.getHeader(DATE_OF_BIRTH),
              field: 'dateOfBirth',
            },
            {
              header: this.getHeader(SPECIALTIES),
              field: 'specialties',
            },
          ]}
          data={data}
          rowClickHandler={this.props.onExpertClickHandler}
          autoPagination={true}
        />
      </div>
    );
  }
}

NewExpertTable.propTypes = {
  data: PropTypes.array.isRequired,
  onExpertClickHandler: PropTypes.func.isRequired,
};
