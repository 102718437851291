export const BILL_MAINTENANCE_LOAD_INITIAL_DATA =
  'BILL_MAINTENANCE_LOAD_INITIAL_DATA';
export const UPDATE_CURRENT_VIEW = 'UPDATE_CURRENT_VIEW';
export const UPDATE_CLIENT_RECORDS_TABLE = 'UPDATE_CLIENT_RECORDS_TABLE';
export const UPDATE_GROUP_CLIENTS = 'UPDATE_GROUP_CLIENTS';
export const UPDATE_SLP_PROFILES_INITIAL_DATA =
  'UPDATE_SLP_PROFILES_INITIAL_DATA';
export const LOOK_UP_GROUPS_BY_GROUP_NAME = 'LOOK_UP_GROUPS_BY_GROUP_NAME';
export const LOAD_GROUP_DETAILS = 'LOAD_GROUP_DETAILS';
export const ADD_NEW_BLANK_GROUP = 'ADD_NEW_BLANK_GROUP';
export const SAVE_GROUP = 'SAVE_GROUP';
export const EMPTY_GROUP_DATA = {
  fields: {
    sitetop: 0,
    profile: '',
    clientname: '',
    description: '',
    id: 0,
  },
};

export const UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER =
  'UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER';
export const UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE =
  'UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE';
export const ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES =
  'ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES';

export const UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER =
  'UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER';
export const UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE =
  'UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE';
export const ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP =
  'ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP';

export const UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER =
  'UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER';
export const UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE =
  'UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE';
export const ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP =
  'ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP';

export const UPDATE_MASTER_CLIENT_DATA_PROVIDER =
  'UPDATE_MASTER_CLIENT_DATA_PROVIDER';
export const UPDATE_SITE_DETAILS = 'UPDATE_SITE_DETAILS';
export const UPDATE_MASTER_SITE_DETAILS = 'UPDATE_MASTER_SITE_DETAILS';
export const UPDATE_CLIENT_COMBO_BOXES = 'UPDATE_CLIENT_COMBO_BOXES';
export const UPDATE_STATE_CITY_BY_ZIP_CODE = 'UPDATE_STATE_CITY_BY_ZIP_CODE';
export const UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE =
  'UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE';
export const UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS =
  'UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS';
export const SELECT_SITE_COPY_SETTINGS_MODAL =
  'SELECT_SITE_COPY_SETTINGS_MODAL';
export const RESET_SITE_COPY_SETTINGS_MODAL = 'RESET_SITE_COPY_SETTINGS_MODAL';
export const UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE =
  'UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE';
export const UPDATE_SAVE_SITE_DETAILS = 'UPDATE_SAVE_SITE_DETAILS';
export const UPDATE_SITE_TREE = 'UPDATE_SITE_TREE';
export const RESET_SITE_TREE = 'RESET_SITE_TREE';
export const RESET_GROUP_CLIENTS = "RESET_GROUP_CLIENTS";
export const RESET_GROUP_DETAILS = "RESET_GROUP_DETAILS";

export default {
  BILL_MAINTENANCE_LOAD_INITIAL_DATA,
  UPDATE_CURRENT_VIEW,
  UPDATE_CLIENT_RECORDS_TABLE,
  UPDATE_GROUP_CLIENTS,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP,
  UPDATE_MASTER_CLIENT_DATA_PROVIDER,
  UPDATE_SITE_DETAILS,
  UPDATE_MASTER_SITE_DETAILS,
  UPDATE_CLIENT_COMBO_BOXES,
  UPDATE_STATE_CITY_BY_ZIP_CODE,
  UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE,
  UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS,
  SELECT_SITE_COPY_SETTINGS_MODAL,
  RESET_SITE_COPY_SETTINGS_MODAL,
  UPDATE_SAVE_SITE_DETAILS,
  UPDATE_SITE_TREE,
  RESET_SITE_TREE,
  RESET_GROUP_CLIENTS,
  RESET_GROUP_DETAILS
};
