import { Modal, ModalHeader, ModalBody, Input, } from 'reactstrap';
import { Col, Row, Form, Button, Label, Card, CardHeader, CardBody } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import ExcelExport from './VendorMaintenanceExcelExport';
import {
  fast360Actions,
  fast360Operations,
  fast360Selectors,
} from '../store/index';
import PropTypes from 'prop-types';
import AppTable from 'shared/components/table/AppTable';
import AppDateInput from 'shared/components/input/AppDateInput';
import { connect } from 'react-redux';
import { commonOperations } from 'common/store';
import moment from '../../../../node_modules/moment/moment';
import "react-datepicker/dist/react-datepicker.css";
import { authSelectors } from 'modules/auth/store';

const productDefault = [
  { id: "0", productName: "All" }
]

const groupDefault = [
  { id: "0", groupName: "All" }
]
const VendorMaintenanceSearch = ({
  isOpen,
  onClose,
  switchToEntry,
  setProviderData,
  providerTypes,
  loadVMProviderTypes,
  loadVMGroups,
  groupList,
  providerResults,
  userId,
  searchProvider,
  resetProviderResults
}) => {

  const [columns, setColumns] = useState([]);

  const [formFields, setFormFields] = useState({
    id: null,
    tin: null,
    groupName: null,
    lastName: null,
    firstName: null,
    productLineId: "0",
    groupId: "0",
  });
  const [displayFields, setDisplayFields] = useState({
    displayTin: true,
    displayGroupName: true,
    displayFLName: true,
    displayAddress: true,
    displayState: false,
    displayZip: true,
    displayPhone: false,
    displayPctBelow: true,
    displayLastModifiedBy: false,
    displayEffTermDates: true,
    displayFax: false,
    displayContact: false,
    displayIsANetwork: false,
    displayProviderType: false,
    displayGroupMembership: false,
  });

  const toggle = () => {
    onClose();
    resetFormData();
    resetProviderResults();
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === 'tin' || name === 'parentTIN') {
      if(value.length <= 9){
        setFormFields({ ...formFields, [name]: formatNumeric(value) });
      }
    } else if (name === 'phoneNumber' || name === 'faxNumber') {
      setFormFields({ ...formFields, [name]: formatPhoneNum(value) });
    } else if (name === 'effDate' || name === 'termDate') {
      let mon = value.getMonth() + 1;
      value =
        mon + '/' + value.getDate() + '/' + value.getFullYear();
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const onChangeDateHandler = object => {
    let mon = object.value.getMonth() + 1;
    const dateText =
      mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    setFormFields({ ...formFields, [object.key]: dateText });
  };

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleSelectChange = (id, value) => {
    setFormFields({ ...formFields, [id]: value });
  };

  const handleCheckboxChange = (id, checked) => {
    setDisplayFields({ ...displayFields, [id]: checked });
  }

  const formatNumeric = (value) => {
    if (!value) return value;
    return value.replace(/[^\d]/g, "");
  }

  useEffect(() => {
    loadVMProviderTypes();
    loadVMGroups();
    resetFormData();
    resetProviderResults();
    updateColumns();
  }, []);

  useEffect(() => {
    updateColumns();
  }, [displayFields]);

  const resetFormData = () => {
    setFormFields({
      id: null,
      tin: null,
      groupName: null,
      lastName: null,
      firstName: null,
      productLineId: "0",
      groupId: "0"});
    setDisplayFields({
      displayTin: true,
      displayGroupName: true,
      displayFLName: true,
      displayAddress: true,
      displayState: false,
      displayZip: true,
      displayPhone: false,
      displayPctBelow: true,
      displayLastModifiedBy: false,
      displayEffTermDates: true,
      displayFax: false,
      displayContact: false,
      displayIsANetwork: false,
      displayProviderType: false,
      displayGroupMembership: false,
    });
    setColumns([]);
  }

  const updateColumns = () => {
    let tableColumns = [];
    if(displayFields.displayTin){
      tableColumns.push( {
        header: 'TIN',
        field: 'tin',
        hide: !displayFields.displayTin,
        percent: '6.6',
      });
    }
    if(displayFields.displayGroupName){
      tableColumns.push({
        header: 'Group Name',
        field: 'groupName',
        hide: !displayFields.displayGroupName,
        percent: '6.6',
      });
    }
    if(displayFields.displayFLName){
      tableColumns.push({
        header: 'First/Last Name',
        field: 'flName',
        hide: !displayFields.displayFLName,
        percent: '6.6',
        render: item => renderFLNameColumn(item),
      });
    }
    if(displayFields.displayAddress){
      tableColumns.push({
        header: 'Address',
        field: 'address1',
        hide: !displayFields.displayAddress,
        percent: '6.6',
      });
    }
    if(displayFields.displayState){
      tableColumns.push({
        header: 'State',
        field: 'state',
        hide: !displayFields.displayState,
        percent: '6.6',
      })
    }
    if(displayFields.displayZip){
      tableColumns.push({
        header: 'Zip Code',
        field: 'zipCode',
        hide: !displayFields.displayZip,
        percent: '6.6'
      })
    }    
    if(displayFields.displayPhone){
      tableColumns.push({
        header: 'Phone',
        field: 'phoneNumber',
        hide: !displayFields.displayPhone,
        percent: '6.6',
        render: item => renderPhoneColumn(item.phoneNumber)
      })
    }  
    if(displayFields.displayPctBelow){
      tableColumns.push({
        header: 'UCR',
        field: 'ucrPercent',
        hide: !displayFields.displayPctBelow,
        percent: '6.6',
        render: item => parseFloat(item.ucrPercent).toFixed(2),
      })
    }
    if(displayFields.displayPctBelow){
      tableColumns.push({
        header: 'FS',
        field: 'fsPercent',
        hide: !displayFields.displayPctBelow,
        percent: '6.6',
        render: item => parseFloat(item.fsPercent).toFixed(2),
      })
    }
    if(displayFields.displayPctBelow){
      tableColumns.push({
        header: 'BC',
        field: 'billedCharges',
        hide: !displayFields.displayPctBelow,
        percent: '6.6',
        render: item => parseFloat(item.billedCharges).toFixed(2),
      })
    }
    if(displayFields.displayPctBelow){
      tableColumns.push({
        header: 'MCA',
        field: 'medicarePercent',
        hide: !displayFields.displayPctBelow,
        percent: '6.6',
        render: item => parseFloat(item.medicarePercent).toFixed(2),
      })
    }
    if(displayFields.displayLastModifiedBy){
      tableColumns.push({
        header: 'Last Modified By',
        field: 'modifiedDate',
        hide: !displayFields.displayLastModifiedBy,
        percent: '6.6',
        render: item => (item.modifiedBy ? item.modifiedBy + " " : "") + moment(item.modifiedDate).format('MM/DD/YYYY HH:mm:ss'),
      })
    }
    if(displayFields.displayEffTermDates){
      tableColumns.push({
        header: 'Effective Date',
        field: 'effDate',
        hide: !displayFields.displayEffTermDates,
        percent: '6.6',
        render: item => moment(item.effDate).format('MM/DD/YYYY'),
      })
    }
    if(displayFields.displayEffTermDates){
      tableColumns.push({
        header: 'Term Date',
        field: 'termDate',
        hide: !displayFields.displayEffTermDates,
        percent: '6.6',
        render: item => moment(item.termDate).format('MM/DD/YYYY'),
      })
    }
    if(displayFields.displayFax){
      tableColumns.push({
        header: 'Fax',
        field: 'faxNumber',
        hide: !displayFields.displayFax,
        percent: '6.6',
        render: item => renderPhoneColumn(item.faxNumber),
      })
    }
    if(displayFields.displayContact){
      tableColumns.push({
        header: 'Contact',
        field: 'contact',
        hide: !displayFields.displayContact,
        percent: '6.6',
      })
    }
    if(displayFields.displayIsANetwork){
      tableColumns.push( {
        header: 'Is A Network',
        field: 'network',
        hide: !displayFields.displayIsANetwork,
        percent: '6.6',
        render: item => item.network ? "yes" : "no",
      })
    }
    if(displayFields.displayProviderType){
      tableColumns.push({
        header: 'Provider Type',
        field: 'productLineId',
        hide: !displayFields.displayProviderType,
        percent: '6.6',
        render: item => renderProviderTypeColumn(item),
      })
    }
    if(displayFields.displayGroupMembership){
      tableColumns.push({
        header: 'Group Membership',
        field: 'groupId',
        hide: !displayFields.displayGroupMembership,
        percent: '6.6',
        render: item => renderGroupMembershipColumn(item),
      })
    }
    setColumns(tableColumns);
  }

  const renderFLNameColumn = (item) => {
    var flName = "";
    if (item.firstName && item.lastName) {
      flName = item.firstName + " " + item.lastName;
    }
    return flName;
  }

  const renderPhoneColumn = (item) => {
    if (item) {
      return `(${item.slice(0, 3)}) ${item.slice(3, 6)}-${item.slice(6, 10)}`;
    }
    return;
  }

  const renderProviderTypeColumn = (item) => {
    let filteredItem = {};
    if (item?.productLineId) {
      filteredItem = providerTypes.filter((providerItem) => providerItem.id === item.productLineId)[0] || {};
    }
    return filteredItem?.productName ?filteredItem?.productName : '';
  }

  const renderGroupMembershipColumn = (item) => {
    let filteredItem = {};
    if (item?.groupId) {
      filteredItem = groupList.filter((groupItem) => groupItem.id === item.groupId)[0] || {};
    }
    return filteredItem?.groupName ? filteredItem.groupName : '';
  }

  const searchHandler = () => {
    let params = {
      uid: userId,
    };

    params.tin = null;
    params.groupName = null;
    params.firstName = null;
    params.lastName = null;
    params.providerTypeId = null;
    params.groupMemberId = null;

    if (formFields.tin !== "" && formFields.tin !== null) {
      params.tin = formFields.tin;
    }
    if (formFields.groupName !== "" && formFields.groupName !== null) {
      params.groupName = formFields.groupName;
    }
    if (formFields.firstName !== "" && formFields.firstName !== null) {
      params.firstName = formFields.firstName;
    }
    if (formFields.lastName !== "" && formFields.groupNlastNameame !== null) {
      params.lastName = formFields.lastName;
    }
    if (formFields.productLineId !== "" && formFields.productLineId !== null && formFields.productLineId !== "0" && formFields.productLineId !== "All") {
      params.providerType = formFields.productLineId;
    }
    if (formFields.groupId !== "" && formFields.groupId !== null && formFields.groupId !== "0" && formFields.groupId !== "All") {
      params.groupMember = formFields.groupId;
    }

    searchProvider(params);
  }

  const switchWindow = () => {
    switchToEntry();
    toggle();
  }

  const handleDblClick = (item) => {
    let providerData = {
      id: item.id ? item.id : null,
      tin: item.tin ? item.tin : null,
      groupName: item.groupName ? item.groupName : null,
      firstName: item.firstName ? item.firstName : null,
      lastName: item.lastName ? item.lastName : null,
      address1: item.address1 ? item.address1 : null,
      address2:  item.address2 ? item.address2 : null,
      zipCode:  item.zipCode ? item.zipCode : null,
      phoneNumber:  item.phoneNumber ? item.phoneNumber : null,
      ucrPercent:  item.ucrPercent === 0 ? 0 : item.ucrPercent,
      billedCharges:  item.billedCharges === 0 ? 0 : item.billedCharges,
      fsPercent:  item.fsPercent === 0 ? 0 : item.fsPercent,
      medicarePercent:  item.medicarePercent === 0 ? 0 : item.medicarePercent,
      effDate:  item.effDate ? item.effDate : null,
      termDate:  item.termDate ? item.termDate : null,
      notes:  item.notes ? item.notes : null,
      faxNumber:  item.faxNumber ? item.faxNumber : null,
      contact:  item.contact ? item.contact : null,
      services:  item.services ? item.services : null,
      parentTIN:  item.parentTIN ? item.parentTIN : null,
      network:  item.network === true ? 1 : 0,
      productLineId:  item.productLineId ? item.productLineId : null,
      groupId:  item.groupId ? item.groupId : null
    };

    setProviderData(providerData);
    switchToEntry();
  }

  return (
    <>
      <Modal size="xl" isOpen={isOpen} modalClassName="vendor-search-modal-body">
        <ModalHeader toggle={toggle} >Contracted Provider Search</ModalHeader>
        <ModalBody>
          <div>
            <Row>
              <Col lg="6" md={{ size: 2, offset: 5 }} style={{ paddingTop: '8px', paddingBottom: '20px' }}>
                <Button style={{ paddingTop: '5px' }} onClick={switchWindow}>Enter New Contract</Button>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}>  TIN: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '25%' }}
                    placeholder="#s only"
                    id="tin"
                    name="tin"
                    onChange={handleInputChange}
                    value={formFields.tin}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}>  Group Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '25%' }}
                    id="groupName"
                    name="groupName"
                    onChange={handleInputChange}
                    value={formFields.groupName}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> First Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="firstName"
                    name="firstName"
                    //value={}
                    onChange={handleInputChange}
                    value={formFields.firstName}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Last Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="lastName"
                    name="lastName"
                    //value={}
                    onChange={handleInputChange}
                    value={formFields.lastName}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Provider Type: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppInput
                    id="productLineId"
                    name="productLineId"
                    type="select"
                    changeHandler={handleSelectChange}
                    value={formFields.productLineId || ''}
                    option={{
                      value: 'productName',
                      displayValue: 'productName',
                    }}
                    placeholder="Choose..."
                    data={providerTypes}
                  />
                </span>
              </Col>

              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Member of Group: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppInput
                    id="groupId"
                    name="groupId"
                    type="select"
                    changeHandler={handleSelectChange}
                    value={formFields.groupId || ''}
                    option={{
                      value: 'groupName',
                      displayValue: 'groupName',
                    }}
                    placeholder="Choose..."
                    data={groupList}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg="6" className="text-left" style={{ paddingTop: '30px', paddingBottom: '20px' }}> Select the information to display on the report: </Col>
            </Row>
            <Row>
              <Col sm="1" style={{ marginLeft: '50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayTin}
                    width="100px"
                    onClick={(e) => handleCheckboxChange("displayTin", e.target.checked)}
                  /> TIN
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayGroupName}
                    onClick={(e) => handleCheckboxChange("displayGroupName", e.target.checked)}
                  /> Group Name
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '-50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayFLName}
                    onClick={(e) => handleCheckboxChange("displayFLName", e.target.checked)}
                  /> First/Last Name
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '-65px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayAddress}
                    onClick={(e) => handleCheckboxChange("displayAddress", e.target.checked)}
                  /> Address
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '5px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayState}
                    onClick={(e) => handleCheckboxChange("displayState", e.target.checked)}
                  /> State
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '30px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayZip}
                    onClick={(e) => handleCheckboxChange("displayZip", e.target.checked)}
                  /> Zip
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '20px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayPhone}
                    onClick={(e) => handleCheckboxChange("displayPhone", e.target.checked)}
                  /> Phone
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayPctBelow}
                    onClick={(e) => handleCheckboxChange("displayPctBelow", e.target.checked)}
                  /> % Below
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm="2" style={{ marginLeft: '50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayLastModifiedBy}
                    onClick={(e) => handleCheckboxChange("displayLastModifiedBy", e.target.checked)}
                  /> Last Modified By
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '-45px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayEffTermDates}
                    onClick={(e) => handleCheckboxChange("displayEffTermDates", e.target.checked)}
                  /> Eff/Term Dates
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '-50px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayFax}
                    onClick={(e) => handleCheckboxChange("displayFax", e.target.checked)}
                  /> Fax
                </Label>
              </Col>
              <Col sm="1" style={{ marginLeft: '30px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayContact}
                    onClick={(e) => handleCheckboxChange("displayContact", e.target.checked)}
                  /> Contact
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '5px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayIsANetwork}
                    onClick={(e) => handleCheckboxChange("displayIsANetwork", e.target.checked)}
                  /> Is a Network
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '-65px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayProviderType}
                    onClick={(e) => handleCheckboxChange("displayProviderType", e.target.checked)}
                  /> Provider Type
                </Label>
              </Col>
              <Col sm="2" style={{ marginLeft: '-72px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={displayFields.displayGroupMembership}
                    onClick={(e) => handleCheckboxChange("displayGroupMembership", e.target.checked)}
                  /> Group Membership
                </Label>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop: '20px' }}>
                <Card>
                  <CardBody>
                    <AppTable
                      modalHeight={250}
                      columns={columns}
                      data={providerResults || []}
                      autoPagination={true}
                      sortAble={true}
                      resizable={false}
                      sortImmediately={false}
                      striped={true} 
                      rowDoubleClickHandler={(e) => handleDblClick(e)}
                      fromVendorMaintenance= {true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="9" className="text-right" style={{ paddingTop: '8px' }}>  </Col>
              <Col xs="1" style={{ paddingTop: '8px' }}>
                <Button style={{ paddingTop: '8px' }} onClick={searchHandler}>Search</Button>
              </Col>
              <Col xs="1" style={{ paddingTop: '8px' }}>
                <ExcelExport data={providerResults} check={displayFields} providerTypes={providerTypes} groupList={groupList}/>
              </Col>
              <Col xs="1" style={{ paddingTop: '8px' }}>
                <Button style={{ paddingTop: '8px' }} onClick={toggle} >Close</Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

VendorMaintenanceSearch.propTypes = {
  onClose: PropTypes.func,
  switchToEntry: PropTypes.func.isRequired,
  setProviderData: PropTypes.func.isRequired,
  providerTypes: PropTypes.array,
  groupList: PropTypes.array,
};

const mapStateToProps = state => {
  const vmData = fast360Selectors.getVendorMaintenance(state);
  const user = authSelectors.getUser(state);
  return {
    providerTypes: productDefault.concat(vmData.vmProviderTypes),
    groupList: groupDefault.concat(vmData.vmGroups),
    userId: user.id,
    providerResults: vmData.vmResults,
  };

};



const mapDispatchToProps = dispatch => ({
  loadVMProviderTypes: () => dispatch(fast360Operations.loadVMProviderTypes()),
  loadVMGroups: () => dispatch(fast360Operations.loadVMGroups()),
  searchProvider: (data, displayResults) => dispatch(fast360Operations.searchProvider(data)),
  resetProviderResults: () => dispatch(fast360Actions.resetProviderResults()),
})

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaintenanceSearch);