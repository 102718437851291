import classnames from 'classnames';
import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

import '../assets/notesTabs.css';

class NotesTabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      if (tab < 3) {
        this.props.setActiveTab(tab)
      }
    }

  };

  formatNotes = notes => {
    if (notes !== undefined) {
      return (
        <ul className="notes">
          {notes.split('\n').map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      );
    }
  };

  onChangeNoteText = (event, tab) => {
    this.props.onChangeNoteText(event, tab);
  };

  render() {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Internal Note
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => {
                this.toggle('2');
              }}
            >
              EOR Note
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => {
                this.toggle('3');
              }}
            >
              Accounting
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row style={{ padding: '10px' }}>
              <Col sm="12">
                <div
                  style={{
                    overflowY: 'scroll',
                    height: '300px',
                    width: '100%',
                    resize: 'none',
                  }}
                >
                  {this.formatNotes(this.props.notes)}
                </div>

                <Form style={{ paddingTop: '20px' }}>
                  <FormGroup>
                    <Label for="internalNote">Add / Amend Internal Note</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="internalNote"
                      value={this.props.internalNote}
                      onChange={e => this.onChangeNoteText(e, this.state.activeTab)}
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row style={{ padding: '10px' }}>
              <Col sm="12">
                <div
                  style={{
                    overflowY: 'scroll',
                    height: '300px',
                    width: '100%',
                    resize: 'none',
                  }}
                >
                  {this.formatNotes(this.props.eorNotes)}
                </div>
                <Form style={{ paddingTop: '20px' }}>
                  <FormGroup>
                    <Label for="eorNote">Add / Amend EOR Note</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="eorNote"
                      value={this.props.eorNote}
                      onChange={e => this.onChangeNoteText(e, this.state.activeTab)}
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <div
              style={{
                overflowY: 'scroll',
                height: '300px',
                width: '100%',
                resize: 'none',
              }}
            >
              {this.formatNotes(this.props.accountingNotes)}
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default NotesTabs;
