import React, { createContext, useReducer } from 'react';
import { isNil } from 'lodash';

export const Fast360SPTDetailModalContext = createContext();

const initailState = {
  id: 0,
  leg: 1,
  carrier: '',
  legStatusId: '',
  tripType: '',
  pickup: '',
  pickupAddress: {},
  departureDate: '',
  departureHours: '0',
  departureMin: '00',
  departureAm: '',
  departureTz: '',
  ArrivalDate: '',
  ArrivalHours: '0',
  ArrivalMin: '00',
  ArrivalAm: '',
  ArrivalTz: '',
  dropOffAddress: {},
  wheelChair: '',
  tripClass: '',
  tripDurationHrs: '',
  tripDurationMins: '',
  tripLayoverHrs: '',
  tripLayoverMins: '',
  transpCostAmt: '',
  transpRebookFee: '',
  transpServiceCredit: '',
  departureGate: '',
};

const reducer = (sptData, action) => {
  const detailsValue = action.payload;
  switch (action.type) {
    case 'RESET_MODAL_DATA':
      return {
        ...initailState,
        pickupAddress: {},
        dropOffAddress: {},
      };
    case 'UPDATE_MODAL_DATA':
      return {
        ...sptData,
        id: isNil(detailsValue.id) ? sptData.id : detailsValue.id,
        leg: isNil(detailsValue.leg) ? sptData.leg : detailsValue.leg,
        carrier: isNil(detailsValue.carrier)
          ? sptData.carrier
          : detailsValue.carrier,
        legStatusId: isNil(detailsValue.legStatusId)
          ? sptData.legStatusId
          : detailsValue.legStatusId,
        tripType: isNil(detailsValue.tripType)
          ? sptData.tripType
          : detailsValue.tripType,
        pickup: isNil(detailsValue.pickup)
          ? sptData.pickup
          : detailsValue.pickup,
        pickupAddress: isNil(detailsValue.pickupAddress)
          ? sptData.pickupAddress
          : detailsValue.pickupAddress,
        departureHours: isNil(detailsValue.departureHours)
          ? sptData.departureHours
          : detailsValue.departureHours,
        departureDate: detailsValue.departureDate === undefined
          ? sptData.departureDate
          : detailsValue.departureDate,
        departureMin: isNil(detailsValue.departureMin)
          ? sptData.departureMin
          : detailsValue.departureMin,
        departureAm: isNil(detailsValue.departureAm)
          ? sptData.departureAm
          : detailsValue.departureAm,
          departureGate: isNil(detailsValue.departureGate)
          ? sptData.departureGate
          : detailsValue.departureGate,
        departureTz: isNil(detailsValue.departureTz)
          ? sptData.departureTz
          : detailsValue.departureTz,
        ArrivalDate: detailsValue.ArrivalDate === undefined
          ? sptData.ArrivalDate
          : detailsValue.ArrivalDate,
        ArrivalHours: isNil(detailsValue.ArrivalHours)
          ? sptData.ArrivalHours
          : detailsValue.ArrivalHours,
        ArrivalMin: isNil(detailsValue.ArrivalMin)
          ? sptData.ArrivalMin
          : detailsValue.ArrivalMin,
        ArrivalAm: isNil(detailsValue.ArrivalAm)
          ? sptData.ArrivalAm
          : detailsValue.ArrivalAm,
        ArrivalTz: isNil(detailsValue.ArrivalTz)
          ? sptData.ArrivalTz
          : detailsValue.ArrivalTz,
        dropOffAddress: isNil(detailsValue.dropOffAddress)
          ? sptData.dropOffAddress
          : detailsValue.dropOffAddress,
        wheelChair: isNil(detailsValue.wheelChair)
          ? sptData.wheelChair
          : detailsValue.wheelChair,
        tripClass: isNil(detailsValue.tripClass)
          ? sptData.tripClass
          : detailsValue.tripClass,
        tripDurationHrs: isNil(detailsValue.tripDurationHrs)
          ? sptData.tripDurationHrs
          : detailsValue.tripDurationHrs,
        tripDurationMins: isNil(detailsValue.tripDurationMins)
          ? sptData.tripDurationMins
          : detailsValue.tripDurationMins,
        tripLayoverHrs: isNil(detailsValue.tripLayoverHrs)
          ? sptData.tripLayoverHrs
          : detailsValue.tripLayoverHrs,
        tripLayoverMins: isNil(detailsValue.tripLayoverMins)
          ? sptData.tripLayoverMins
          : detailsValue.tripLayoverMins,
        transpCostAmt: isNil(detailsValue.transpCostAmt)
          ? sptData.transpCostAmt
          : detailsValue.transpCostAmt,
        transpRebookFee: isNil(detailsValue.transpRebookFee)
          ? sptData.transpRebookFee
          : detailsValue.transpRebookFee,
        transpServiceCredit: isNil(detailsValue.transpServiceCredit)
          ? sptData.transpServiceCredit
          : detailsValue.transpServiceCredit,
      };
      break;
    case 'DELETE_MODAL_DATA':
      return {};
  }
};

export const Fast360SPTDetailModalContextProvider = ({ children }) => {
  const [SPTData, dispatch] = useReducer(reducer, {
    ...initailState,
    pickupAddress: { ...initailState.pickupAddress },
    dropOffAddress: { ...initailState.dropOffAddress },
  });

  return (
    <Fast360SPTDetailModalContext.Provider value={{ SPTData, dispatch }}>
      {children}
    </Fast360SPTDetailModalContext.Provider>
  );
};
