import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Row,
  FormGroup,
  Input,
  Col,
  Label,
  Button,
} from 'reactstrap';

import selectors from '../store/selectors';
import operations from '../store/operations';

const columns = [
  {
    header: 'Adjuster Name',
    field: 'adjusterName',
  },
];

const AdjusterTable = props => (
  <Table size="sm" hover={true}>
    <thead className={'thead-dark'}>
      <tr>
        {props.columns.map((column, index) => (
          <th key={index}>{column.header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {props.adjusters.map((adjuster, index) => (
        <tr
          key={index}
          className="pointer-on-hover"
          onClick={() => props.selectAdjuster(adjuster)}
        >
          {props.columns.map((column, colIndex) => (
            <td key={colIndex}>{adjuster[column.field]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);

class UserAdjusterMappingSearchModal extends Component {
  constructor() {
    super();
    this.selectAdjuster = this.selectAdjuster.bind(this);
    this.state = {
      currentAdjuster: {},
      billId: '',
    };
  }

  addAdjuster = () => {
    const { currentAdjuster } = this.state;

    if (Object.keys(currentAdjuster).length !== 0 && this.state.billId !== '') {
      this.props.onAddAdjuster(
        this.state.billId,
        this.props.userId,
        this.props.profileId,
        currentAdjuster
      );
      this.props.toggle();
    } else {
      //TODO: Set an alert or highlight the required fields that are missing
    }
  };

  selectAdjuster = adjuster => {
    this.setState({
      currentAdjuster: adjuster,
    });
  };

  updateSelectedAdjuster = (event, field) => {
    const { currentAdjuster } = this.state;
    currentAdjuster[field] = event.target.value;

    this.setState({
      currentAdjuster: currentAdjuster,
    });
  };

  updateBillId = event => {
    this.setState({
      billId: event.target.value,
    });
  };

  render() {
    return (
      <Modal
        className="modal-lg"
        isOpen={this.props.adjuster.modal}
        toggle={this.props.toggle}
      >
        <ModalHeader toggle={this.props.toggle}>Add Adjuster</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12" className="fixed-panel">
              <AdjusterTable
                columns={columns}
                adjusters={this.props.adjusters}
                selectAdjuster={this.selectAdjuster}
              />
            </Col>
            <Col lg="12">
              <Row style={{ marginTop: '20px' }}>
                <Col lg="3">
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={event =>
                        this.updateSelectedAdjuster(event, 'chr_lastname')
                      }
                      value={this.state.currentAdjuster.chr_lastname || ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={event =>
                        this.updateSelectedAdjuster(event, 'chr_firstname')
                      }
                      value={this.state.currentAdjuster.chr_firstname || ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="billExampleId">Bill Example ID</Label>
                    <Input
                      type="text"
                      name="billExampleId"
                      id="billExampleId"
                      onChange={event => this.updateBillId(event)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="transferTo">Transfer To</Label>
                    <Input
                      type="text"
                      name="transferTo"
                      id="transferTo"
                      value={
                        this.props.currentProfile.lastName +
                        ', ' +
                        this.props.currentProfile.firstName
                      }
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg="12">
              <Button onClick={this.addAdjuster}>ADD</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  adjuster: selectors.getAdjuster(state),
});

const mapDispatchToProps = dispatch => ({
  onAddAdjuster: (billId, userId, profile, adjuster) =>
    dispatch(operations.addAdjuster(billId, userId, profile, adjuster)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAdjusterMappingSearchModal);
