import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, Row, CustomInput } from 'reactstrap';

import { authSelectors } from 'modules/auth/store';

import * as FileUtils from 'shared/utils/fileUtils';
import * as NumberUtils from 'shared/utils/numberUtils';

import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

import { billOperationsOperations, billOperationsSelectors } from '../store';

let uploadCheckedItems = [];
const UploadFilesModal = ({
  recordId,
  fileUpload,
  isOpen,
  onToggle,
  onUpload,
  profile,
  user,
  tableName,
}) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [removeVal, setRemoveVal] = useState(false);
  const attachFileRef = useRef();

  const clearFilesToUpload = () => {
    setFilesToUpload([]);
    uploadCheckedItems = [];
    setRemoveVal(false);
  };

  const onExternalToggle = () => {
    onToggle();
    uploadCheckedItems = [];
    setRemoveVal(false);
  }

  const handleSelectUploadFile = e => {
    e.preventDefault();

    const keyOffset = filesToUpload.length;
    const filesToAdd = Object.values(
      R.mapObjIndexed(
        (value, key) => ({
          id: parseInt(key, 10) + keyOffset,
          file: value,
          selected: false,
        }),
        attachFileRef.current.files
      )
    );
    attachFileRef.current.value = null;

    const newFilesToUpload = R.concat(filesToUpload, filesToAdd);
    setFilesToUpload(newFilesToUpload);
  };

  const resetModal = () => {
    clearFilesToUpload();
  };

  const getTotalUploadSize = () => {
    let totalBytes = 0;
    R.forEach(fileToUpload => {
      totalBytes += fileToUpload.file.size;
    }, filesToUpload);

    return NumberUtils.formatFileSize(totalBytes);
  };

  const removeFilesToUpload = () => {
    const removeId = uploadCheckedItems.map(file => file.id);
    const selectedFiles = filesToUpload.filter(file => !removeId.includes(file.id));
    setFilesToUpload(selectedFiles);
    uploadCheckedItems = [];
    setRemoveVal(false);
  }

  const selectRemoveHandler = (item, event) => {
    uploadCheckedItems = event.target.checked
      ? uploadCheckedItems.concat(item)
      : uploadCheckedItems.filter(
        row => row.id !== item.id
      );
    if (uploadCheckedItems.length > 0) {
      setRemoveVal(true);
    } else {
      setRemoveVal(false);
    }
  }

  const renderColumn = (item) => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.file.name}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectRemoveHandler(item, event);
          }}
        />
      </div>
    );
  }

  const toggleUploadSelected = useCallback(
    item => {
      const newFilesToUpload = R.adjust(
        item.id,
        file => ({
          ...file,
          selected: !file.selected,
        }),
        filesToUpload
      );
      setFilesToUpload(newFilesToUpload);
    },
    [filesToUpload]
  );

  const handleUploadFiles = e => {
    e.preventDefault();

    R.forEach(async fileObj => {
      const fileData = await FileUtils.fileToBase64(fileObj.file);
      const fileExt = FileUtils.getFilenameExtension(fileObj.file.name);

      fileUpload({
        fileExt,
        fileName: fileObj.file.name,
        fileData,
        recordId,
        profile,
        sessionId: user.sessionId,
        tableName,
      });
    }, filesToUpload);

    onUpload();
  };

  const getBody = () => {
    const filesToUploadCount = filesToUpload.length;
    const filesToUploadLabel =
      filesToUploadCount < 1
        ? ''
        : `Total Files: ${filesToUploadCount}, Total Size: ${getTotalUploadSize()}`;

    return (
      <Form>
        <Row form>
          <Col sm={12}>
            <AppTable
              modalHeight={370}
              columns={[
                {
                  header: 'File Name',
                  render: item => item.file.name,
                },
                {
                  header: 'File Size',
                  render: item => NumberUtils.formatFileSize(item.file.size),
                },
                {
                  header: "Remove",
                  field: '',
                  render: item => renderColumn(item)
                },
              ]}
              data={filesToUpload}
            />
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="attachFile"
                multiple
                accept=".pdf,.jpg,.png,.tif,.gif,.doc,.docx,.xls,.xlsx"
                ref={attachFileRef}
                onChange={handleSelectUploadFile}
              />
              <label className="custom-file-label" htmlFor="attachFile">
                {filesToUploadLabel}
              </label>
            </div>
          </Col>
          <Col sm={6}>
            <Button
              className="file-attachment-remove-button"
              disabled={!removeVal}
              onClick={() => removeFilesToUpload()}
            >
              REMOVE
            </Button>
            <Button
              className="file-attachment-button"
              disabled={filesToUpload.length < 1}
              onClick={() => clearFilesToUpload()}
            >
              CLEAR ALL
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const getFooter = () => {
    return (
      <Button
        className="file-attachment-button"
        disabled={filesToUpload.length < 1}
        onClick={handleUploadFiles}
      >
        UPLOAD
      </Button>
    );
  };

  return (
    <PopupModal
      content={getBody()}
      title="ADD A FILE"
      externalToggle={() => {
        onExternalToggle()
      }}
      isOpen={isOpen}
      footerContent={getFooter()}
      onClosed={resetModal}
      size="lg"
    />
  );
};

UploadFilesModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  fileUpload: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  tableName: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);

  return {
    profile: initialData.currentProfile,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  fileUpload: data => dispatch(billOperationsOperations.fileUpload(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFilesModal);
