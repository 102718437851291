import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fast360Operations
} from '../store';
import { Modal, ModalHeader, ModalBody, CustomInput } from 'reactstrap';
import moment from 'moment';
import AppTable from 'shared/components/table/AppTable';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';



const Fast360SaveTask = ({
  isOpen,
  currentTaskData,
  currentUserId,
  progressTasks,
  loadExistingTasks,
  groupTasksProgression,
  worklistData,
  currentTaskId,
  groupProgressData,
  onClose,
 
}) => {
  const [rowInfo, setRowInfo] = useState({});
  const [saveTaskNote, setTaskNote] = useState('')
  const [openSaveTaskModal, setopenSaveTaskModal ] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  

  const progressTask = () =>{
    let progressTaskArgs = {
      "functionName": "progressTasks",
      "zebraUserId": currentUserId,
      "selectedServices": selectedServices,
      "lmsUserId": currentUserId,
      "taskNote": saveTaskNote,
    }
    
   progressTasks(progressTaskArgs);
   
   onClose()
  };

  const openSaveTaskProgress = () =>{
    setopenSaveTaskModal(!openSaveTaskModal);
  };
  

  useEffect(() => {
    if (isOpen) {
      var newDate
      newDate = Date();
      setTaskNote(currentTaskData[currentTaskId - 1].description +  ' was closed on ' +  moment(newDate).format('MM/DD/YYYY h:mm a'))
      setServicesDefault();
      setopenSaveTaskModal(true);
   } else {
    setopenSaveTaskModal(false);
   }
  }, [isOpen]);

  
  const setServicesDefault = () =>{
    let services = [];
    if (groupProgressData.length > 0){
      groupProgressData.forEach(function (item, index) {
        services[index] = item;
      });
    }else{
      services[0] = currentTaskData[currentTaskId -1]
    }
    setSelectedServices(services);
  }


  const selectItem = (item, event) => {
    if (currentTaskData[currentTaskId - 1].serviceId != item.serviceId){
      const result = event.target.checked
        ? selectedServices.concat(item)
        : selectedServices.filter(
          row => row.serviceId !== item.serviceId
        );
        setSelectedServices(result)
    }
    //this.props.selectBills(result);
  };

  const isChecked = () => {
    if (selectedServices.length === groupProgressData.length) {
       return true;
    }    
    return false;
  };

  const getSelectActionColumn = (item) => {
    if (!!!item) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          id={`billSearchResult-select-${item.serviceId}`}
          name={`billSearchResult-select-${item.serviceId}`}
          type="checkbox"
          checked={selectedServices.includes(item)}
          onChange={event => {
            selectItem(item, event);
          }}
        />
      </div>
    );
  }

  

  const selectAll = event => {
    let services = [];
    if (selectedServices.length === groupProgressData.length) {
      setSelectedServices([])
      groupProgressData.forEach(function (item, index) {
        if (currentTaskData[currentTaskId - 1].serviceId === Number(item.serviceId)){
          services[0] = item;
          setSelectedServices(services);
        }
      });
    }else{
      setSelectedServices(groupProgressData);
    }
    
       
  };

  
  const renderMultServices = () => {
     if (groupProgressData.length > 1){
        return (<>
        {' '}
         <Col lg="12">
            <AppTable modalHeight={250}
            size="sm"
              resizable
              columns={[
                {
                  header: 'Conf #',
                  field: 'confNo',
                  width: '100'
                 
                },
                {
                  header: 'Date of Service',
                  field: 'dos',
                  width: '35'
                },
               
                {
                  header: (
                    <div className="select-action">
                      <CustomInput
                        id={`billSearchResult-selectAll`}
                        name={`billSearchResult-selectAll`}
                        type="checkbox"
                        checked={isChecked() == true ? true : false}
                        onChange={selectAll}
                      />
                    </div>
                  ),
                  field: 'checkBox',
                  render: item => getSelectActionColumn(item),
                  percent: '3',
                  sortAble: false,
                },
                
               
              ]}
              data={groupProgressData ? groupProgressData :[]}
              sortAble={true}
              isSelectable={true}
              //sortImmediately={true}
              //rowClickHandler={onRowClick}
              />
          </Col >
        </>);
      }else{
        return "";
      }
      

  }

  return (
    <>
      <Modal size="md" isOpen={openSaveTaskModal}>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Label>Are you sure you wish to mark as complete, <br/>you will not be able to reopen the task</Label>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
            <Input
                type="textarea"
                name="text"
                id="saveTaskNote"
                value={saveTaskNote}
                onChange={e => setTaskNote(e.target.value)}
            />
            </Col>
          </Row>
          <Row>
            {renderMultServices()}
          </Row>
          <Form>
             
              <div style={{ float: 'right' }}>
                <Button style={{marginLeft:5}}
                  type="button"
                  onClick={progressTask}
                >
                  Save
                </Button>
                <Button style={{marginLeft:5}}
                  type="button"
                  onClick={openSaveTaskProgress}
                >
                  Cancel
                </Button>
              </div>
              
             </Form>
        </ModalBody>
      </Modal>
     
    </>
  );
};

Fast360SaveTask.propTypes = {
  progressTasks: PropTypes.func,
  loadExistingTasks: PropTypes.func,
  groupTasksProgression: PropTypes.func,
  loadFromWorkList: PropTypes.func,
};


const mapStateToProps = state => {
  // const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  return {
      lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
      currentTaskData: state.FAST360.fast360Reducer.updateloadExistingTasks ? state.FAST360.fast360Reducer.updateloadExistingTasks : [],
      groupTasksProgression: state.FAST360.fast360Reducer.updateloadGroupProgression ? state.FAST360.fast360Reducer.updateloadGroupProgression : [],
  };
};

const mapDispatchToProps = dispatch => ({
  progressTasks: (data) => dispatch(fast360Operations.progressTasks(data)),
  loadFromWorkList: (data) => dispatch(fast360Operations.progressTasks(data)),
  loadExistingTasks: (data) => dispatch(fast360Operations.loadExistingTasks(data)),
  groupTasksProgression: (data) => dispatch(fast360Operations.groupTasksProgression(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360SaveTask);
