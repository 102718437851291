import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { commonActions } from 'common/store';
import appModules, { ADMIN_MODULE } from 'common/appModules';

import EditUser from './pages/EditUser';
import UserSearch from './pages/UserSearch';
import { administrationOperations } from './store';

const Administration = ({ switchModule }) => {
  useState(() => {
    switchModule(appModules.getModule(ADMIN_MODULE));
  });

  return (
    <div>
      <Switch>
        <Route exact path="/administration/user" component={EditUser} />
        <Route exact path="/administration/search" component={UserSearch} />
      </Switch>
    </div>
  );
};

Administration.propTypes = {
  switchModule: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  getUserInformation: userId =>
    dispatch(administrationOperations.getUserInformation(userId)),
  switchModule: module => dispatch(commonActions.switchModule(module)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
