import React,{ useContext, useEffect, useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Countdown from 'react-countdown-now';

import { useAuth } from 'modules/auth/hooks/useAuth';

const TimeOutModal =(props)=>{

    const { logout, user, userId } = useAuth();
    const userName = user ? `${user.firstname} ${user.lastname}` : 'Unknown User';
   
    useEffect(()=>{
        const timer = setTimeout(() => {
        props.resetFilterData();
        props.updateIsLogout(true);
        logout(userName, userId);
        localStorage.setItem('auth_token', "");
        window.location.href = '/login';
        window.location.reload();
    }, 60000);

    return()=>{
    clearTimeout(timer);

    }

    },[]);
    

    return (
        
            <Modal isOpen="true" size="md" backdrop="static">
                <ModalBody>
                    <div>Your Session will time out in <Countdown date={Date.now() + 60000} renderer={props => <>{props.total / 1000}</>} /> second(s) </div>
                    <div>Please click on Agree to confirm you are still active.</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.onClose}>Agree</Button>
                </ModalFooter>
            </Modal>
        );
       
}

  
  export default  TimeOutModal;

