import React, { Component, useState } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { SelectableCell } from './SelectableCell';
import { ClickableCell } from './ClickableCell';
import BillDetailDeny from '../../../modules/bill-operations/components/BillDetailDeny';
import FeeLookupModal from '../../../modules/bill-operations/components/FeeLookupModal';
import { Form, FormGroup, Label } from 'reactstrap';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap';
import { modifyBillLines } from '../../../modules/bill-operations/utils/constantUtils';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsSelectors,
} from '../../../modules/bill-operations/store';
import { connect } from 'react-redux';
import { isThisQuarter } from 'date-fns';
import { formatCurrency } from '../../../modules/bill-operations/utils/billUtils';

class AppTableBodyNurseAuditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      highlightedRow: -1,
      isOpen: false,
      selectedReason: -1,
      dropdownState: 'Undecided',
      selectedDec: '',
      rowIndex: null,
      flag: 0,
      netSavInput:
        this.props.dataObject.negReduct !== ''
          ? this.props.dataObject.negReduct
          : '$0.00',
      provReimb:
        this.props.dataObject.provReimb !== ''
          ? this.props.dataObject.provReimb
          : '$0.00',
      audit:
          this.props.dataObject.audit !== ''
            ? this.props.dataObject.audit
            : '$0.00',
      cptClicked: false,
      fromMcns: true,
      mcnsRowIndex: 0,
    };
  }

  componentDidMount() {
    //this.getEditValuesData(this.props.rowIndex);

    const found = this.props.columns.some((column, columnIndex) => column.header == 'Audit')
    if (found) this.setState({fromMcns: false});

    this.getEditValuesData(this.props.rowIndex);
  }

  componentWillUnmount() {
    this.props.setMcnsEditVal({});
  }

  toggleSelectedRow = (id, checked) => {
    this.setState(prevState => {
      return checked
        ? this.addRowFromState(prevState, id)
        : this.removeRowFromState(prevState, id);
    }, this.executeSelectHandler);
  };

  removeRowFromState = (state, id) => {
    return this.updateSelectedRows(
      state,
      state.selectedRows.filter(row => row !== id)
    );
  };

  addRowFromState = (state, id) => {
    return this.updateSelectedRows(state, state.selectedRows.concat(id));
  };

  updateSelectedRows = (state, selectedRows) => {
    return {
      ...state,
      selectedRows,
    };
  };

  executeSelectHandler = () => {
    const selectedRows = this.state.selectedRows.map(id => {
      return this.props.data[id];
    });
    this.props.selectHandler(selectedRows);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedRows: [] });
  }

  onRowClicked = (dataObject, rowIndex, event) => {
    if (event.target.tagName === 'TD') {
      this.props.rowClickHandler(dataObject);
      this.setState({
        highlightedRow: rowIndex,
      });
    }
  };

  dropdownData = rowIndex => {
    return (
      <>
        <Input
          type="select"
          name="selectedDecision"
          onChange={event => this.handleInputChange(event, rowIndex)}
        >
          {modifyBillLines.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </Input>
        <BillDetailDeny
          isOpen={this.state.isOpen}
          index={rowIndex}
          selected={this.state.selectedReason}
          claimant={this.props.claimant}
          billId={this.props.billId}
          taskListData={this.props.taskListData}
        />
      </>
    );
  };

  handleAuditChange = (event, rowIndex) => {
    let totPaid = Number(
      this.props.dataObject.reimb.split('$')[1].replace(',', '')
    );
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(2);
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      //newValue = changedVal;
    }
    // provider reimbursement = billed – reductions – audit
    let billed = Number(this.props.dataObject.billed.split('$')[1].replace(',', ''));
    let reductions = Number(this.props.dataObject.reductions.split('$')[1].replace(',', ''));
    let proReim = billed - reductions - changedVal;
        
    if (totPaid >= Number(changedVal)) {
      this.setState({ provReimb: formatCurrency(proReim) });
      this.setState({ audit: newValue });
      //this.props.dataObject.provReimb = formatCurrency(proReim);
    } else {
      this.props.alert(
        'The Audit entered is greater than the Total Paid. Please enter an amount lower than the Total Paid amount.'
      );
    }
  };

  handleChange = (event, rowIndex, isNetworkSavings) => {
    let totPaid = Number(
      this.props.dataObject.reimb.split('$')[1].replace(',', '')
    );
    let changedVal = 0.0;
    let newValue = event.target.value;
 
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      //newValue = changedVal;
    }

    // audit = billed – reductions – provider reimbursement
    let billed = Number(this.props.dataObject.billed.split('$')[1].replace(',', ''));
    let reductions = Number(this.props.dataObject.reductions.split('$')[1].replace(',', ''));
    let audit = billed - reductions - changedVal;
        
    if (totPaid >= Number(changedVal)) {
      if (isNetworkSavings) this.setState({ netSavInput: newValue });
      else {
        this.setState({ provReimb: newValue });
        this.setState({ audit: formatCurrency(audit) });
        //this.props.dataObject.audit = formatCurrency(audit);
      }       
    } else if (isNetworkSavings)
      this.props.alert(
        'The Negotiated Reduction entered is greater than the Total Paid, which would result in a negative reimbursement. Please enter an amount lower than the Total Paid amount.'
      );
    else
      this.props.alert(
        'The Provider Reimbursement entered is greater than the Total Paid. Please enter an amount lower than the Total Paid amount.'
      );
  };

  handleBlur = (event, rowIndex, isNetworkSavings) => {
    let newValue = event.target.value;
    let val = 0.0;

    if (newValue.indexOf('$') !== -1) {
      val = parseFloat(Number(event.target.value.split('$')[1])).toFixed(2);
    } else {
      val = parseFloat(Number(event.target.value)).toFixed(2);
      //newValue = changedVal;
    }

    // let val = parseFloat(Number(event.target.value.split('$')[1])).toFixed(2);
    if (isNetworkSavings)
      this.setState({ netSavInput: '$' + val }, () => {
        this.getEditValuesData(rowIndex);
      });
    else
      this.setState({ provReimb: '$' + val }, () => {
        this.getEditValuesData(rowIndex);
      });
  };

  handleAuditBlur = (event, rowIndex) => {
    let newValue = event.target.value;
    let val = 0.0;
    if (newValue.indexOf('$') !== -1) {
      val = parseFloat(Number(event.target.value.split('$')[1])).toFixed(2);
    } else {
      val = parseFloat(Number(event.target.value)).toFixed(2);
    }

    this.setState({ audit: '$' + val }, () => {
      this.getEditValuesData(rowIndex);
    });
  };

  getEditValuesData = rowIndex => {
    let index = rowIndex + 1;
    let billLine = '' + index;
    let modifyArray = [0, 0];

    if (this.state.fromMcns){
      modifyArray = [
        Number(this.state.netSavInput.split('$')[1]),
        Number(this.state.provReimb.split('$')[1]),
      ];
    } else {
      modifyArray = [
        Number(this.state.audit.split('$')[1]),
        Number(this.state.provReimb.split('$')[1]),
      ];
    }    
    
    let prevObj = this.props.mcnsFromState;
    prevObj[billLine] = modifyArray;
    let modiObj = prevObj;
    this.props.setMcnsEditVal(modiObj);
  };

  handleCptClick = rowIndex => {
    this.setState(prevState => ({
      cptClicked: !prevState.cptClicked,
      mcnsRowIndex: rowIndex,
    }));
  };

  handleCloseCptClick = () => {
    this.setState({ cptClicked: false });
  };

  handleInputChange = (event, rowIndex) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({ rowIndex: rowIndex });

    if (name === 'selectedDecision') {
      this.setState({
        selectedDec: value,
      });
    }

    if (value == 'Deny' && this.state.selectedReason == -1) {
      this.setState(prevState => ({
        selectedReason: rowIndex,
      }));
    }

    if (
      this.state.selectedReason == rowIndex &&
      (value == 'Approve' || value == 'Undecided')
    ) {
      this.setState({
        selectedReason: -1,
        isOpen: false,
      });
    }

    if (value == 'Approve') {
      var billed = this.props.dataObject.billed;
      billed = billed.substring(1);
      billed = billed.replace(/,/g, '');
      var intBilled = billed * 1;
      this.total = this.total + intBilled;

      this.setState({
        total: 1,
      });

      this.props.setRembTotal(intBilled);
      this.setState({
        flag: 1,
      });
    }

    if (value == 'Undecided' || value == 'Deny') {
      var billed = this.props.dataObject.billed;
      billed = billed.substring(1);
      billed = billed.replace(/,/g, '');
      var intBilled = billed * 1;
      this.total = this.total + intBilled;

      this.setState({
        total: 1,
      });

      if (this.state.flag == 1) {
        this.props.removeRembTotal(intBilled);
        this.setState({
          flag: 0,
        });
      }
    }
  };

  render() {
    const rowIndex = this.props.rowIndex;
    const dataObject = this.props.dataObject;

    return (
      <>
        {this.state.cptClicked && (
          <FeeLookupModal
            isFromMcns={this.state.fromMcns}
            mcnsData={this.props.data}
            mcnsRowIndex={this.state.mcnsRowIndex}
            isOpen={this.state.cptClicked}
            taskListData={
              this.props.taskListData ? this.props.taskListData : []
            }
            onClose={this.handleCloseCptClick}
            start_date={dataObject.dos}
            mcnsZipCode={this.props.mcnsZipCodeM}
          />
        )}

        <tbody>
          <tr
            key={rowIndex}
            style={
              this.props.highlightedRow === rowIndex
                ? { backgroundColor: '#cdf0f9' }
                : null
            }
            onDoubleClick={
              this.props.isRowDoubleClickable
                ? () => this.props.rowDoubleClickHandler(this.props.returnData)
                : null
            }
            onClick={
              this.props.isRowClickable
                ? event => this.onRowClicked(dataObject, rowIndex, event)
                : null
            }
          >
            {this.props.isSelectable ? (
              <SelectableCell
                rowIndex={rowIndex}
                selectHandler={this.toggleSelectedRow}
                selected={this.state.selectedRows.includes(rowIndex)}
              />
            ) : null}
            {this.props.columns.map((column, columnIndex) => {
              if (column.render) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {column.render(dataObject, rowIndex)}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>
                    {column.render(dataObject, rowIndex)}
                  </td>
                );
              }

              if (column.header == 'Decision') {
                return (
                  <>
                    <th
                      key={columnIndex}
                      scope="row"
                      style={{ width: '180px' }}
                    >
                      {this.dropdownData(rowIndex)}
                    </th>
                  </>
                );
              }

              if (this.props.returnData === dataObject) {
                if (column.header == 'Network Savings') {
                  return (
                    <td key={columnIndex} style={{ width: '100px' }}>
                      <FormGroup>
                        <Input
                          type="text"
                          id="NetworkSavingsInput"
                          value={this.state.netSavInput}
                          onChange={event =>
                            this.handleChange(event, rowIndex, true)
                          }
                          onBlur={event =>
                            this.handleBlur(event, rowIndex, true)
                          }
                        />
                      </FormGroup>
                    </td>
                  );
                }
              }

              if (this.props.returnData === dataObject) {
                if (column.header == 'Prov Reimb') {
                  return (
                    <td key={columnIndex} style={{ width: '100px' }}>
                      <FormGroup>
                        <Input
                          type="text"
                          id="ProvReimbInput"
                          value={this.state.provReimb}
                          onChange={event =>
                            this.handleChange(event, rowIndex, false)
                          }
                          onBlur={event =>
                            this.handleBlur(event, rowIndex, false)
                          }
                        />
                      </FormGroup>
                    </td>
                  );
                }
              }

              if (this.props.returnData === dataObject) {
                if (column.header == 'Audit') {
                  return (
                    <td key={columnIndex} style={{ width: '100px' }}>
                      <FormGroup>
                        <Input
                          type="text"
                          id="AuditInput"
                          value={this.state.audit}
                          onChange={event =>
                            this.handleAuditChange(event, rowIndex)
                          }
                          onBlur={event =>
                            this.handleAuditBlur(event, rowIndex)
                          }
                        />
                      </FormGroup>
                    </td>
                  );
                }
              }

              if (this.props.returnData === dataObject) {
                if (column.header == 'CPT') {
                  return (
                    <td
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => this.handleCptClick(rowIndex)}
                    >
                      {dataObject.cpt}
                    </td>
                  );
                }
              }

              if (this.props.returnData === dataObject) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {dataObject[column.field] || ''}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>{dataObject[column.field] || ''}</td>
                );
              }

              if (this.props.returnData === dataObject.fields) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {dataObject[column.field] || ''}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>
                    {dataObject.fields[column.field] ||
                      dataObject.fields.dateRange[column.field] ||
                      ''}
                  </td>
                );
              }
            })}
            {this.props.isClickable ? (
              <ClickableCell
                clickHandler={() => this.props.clickHandler(dataObject)}
                location="/administration/user"
              />
            ) : null}
          </tr>
        </tbody>
      </>
    );
  }
}

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);

  return {
    totalFromState: billReviewState.rembTotal,
    //denyReasonFromState: billReviewState.denyReasons ? billReviewState.denyReasons : [],
    mcnsFromState: billReviewState.mcnsEditvalues
      ? billReviewState.mcnsEditvalues
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  setRembTotal: total => dispatch(billOperationsActions.setRembTotal(total)),
  removeRembTotal: total =>
    dispatch(billOperationsActions.removeRembTotal(total)),
  setMcnsEditVal: value =>
    dispatch(billOperationsActions.setMcnsEditVal(value)),
  setProvReimb: value => dispatch(billOperationsActions.setProvReimb(value)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

AppTableBodyNurseAuditRow.propTypes = AppTableBodyPropType;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppTableBodyNurseAuditRow);
