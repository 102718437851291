import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';

const CareworksTrainingModal = ({
    isOpen,
    onClose,
}) => {

    useEffect(() => {
        if (isOpen) {
            // Do any resets if necessary?
        }
    }, [isOpen]);
    
    const toggle = () => {
        onClose();
    };
    const trainingVideoData = [
        {
            name: 'Module 1',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/609fb468af1f103caebf00505681f03c/playback',
            altName: 'Careworks Z3 Portal Tour'
          },
          {
            name: 'Module 2',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/053b6f37af36103c9dfd005056814b80/playback',
            altName: 'Task List - Agree, Disagree,and Correction'
          },
          {
            name: 'Module 3',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/146500a0af37103cbee3005056815359/playback',
            altName: 'Line Level Approval'
          },
          {
            name: 'Module 4',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/b238715caf37103cafbd005056811c4a/playback',
            altName: 'Pend, Corrections, and Re Review'
          },
          {
            name: 'Module 5',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/0a0ec288af39103cb1de00505681dbe0/playback',
            altName: 'Transfer or Move Bills'
          },
          {
            name: 'Module 6',
            link: 'https://sedgwick.webex.com/recordingservice/sites/sedgwick/recording/5d2a2037af3a103cbe6f0050568188a3/playback',
            altName: 'Approving Bills'
          },
    ]

    const getTrainingVideoTable = () => {
        return (
            <div className="training-video-table">
                <AppTable
                    modalHeight={300}
                    columns={[
                        {
                            header: 'Module',
                            field: 'name'
                        },
                        {
                            header: 'Recording Link',
                            field: 'link',
                            render: item => (
                                <a href={item.link} target="_blank" style={{color: "DodgerBlue", textDecoration: "underline"}}>{item.altName}</a>
                              ),
                        },
                        
                    ]}
                    data={trainingVideoData}
                    autoPagination={true}
                    sortAble={false}
                    resizable={true}
                    sortImmediately={false}
                    key="traing-video-table"
                />
            </div>
        )
    }

    return (
        <>
            <Modal size="xl" isOpen={isOpen} className="careworksTrainingModal" dialogClassName="careworksTrainingModal">
                <ModalHeader toggle={toggle}>Careworks Training</ModalHeader>
                <ModalBody>
                {/* <iframe className='video'
                        type="text/html"
                        title='Youtube player'
                        height='620px'
                        width='100%'                        
                        src={`https://www.youtube.com/embed/playlist?list=PL6jKkdAZt9_XRUUD_1Yv5Zo7KC5ov0pbQ`}
                        frameborder="0"
                        sandbox="allow-same-origin allow-forms allow-scripts allow-presentation allow-popups allow-popups-to-escape-sandbox"
                        allowFullScreen>
                </iframe> */}
                {getTrainingVideoTable()}
                </ModalBody>
            </Modal>
        </>
    );
};

CareworksTrainingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CareworksTrainingModal);