import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { expertsSelectors } from '../store';

import ExpertRadiusSearchTop from './ExpertRadiusSearchTop';
import ExpertRadiusTable from './ExpertRadiusTable';

class ExpertRadiusSearchContainer extends Component {
  render() {
    return (
      <>
        <ExpertRadiusSearchTop />
        <ExpertRadiusTable data={this.props.data} />
      </>
    );
  }
}

ExpertRadiusSearchContainer.propTypes = {
  data: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const radiusSearchState = expertsSelectors.getRadiusSearch(state);
  return {
    data: radiusSearchState.experts,
  };
};

export default connect(mapStateToProps)(ExpertRadiusSearchContainer);
