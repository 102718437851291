import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import { Col, Row, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { fast360Operations, fast360Actions } from '../store/index';
import { authSelectors } from 'modules/auth/store';
import AppTable from 'shared/components/table/AppTable';


const Fast360NppesPhysiciansModal = ({ 
    isOpen, 
    onClose, 
    searchFields, 
    userId, 
    nppesPhysicianList, 
    loadNppesPhysicianList, 
    resetNppesPhysicianList,
    saveNppesPhysician, 
}) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [nppesPhysicians, setNppesPhysicians] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const req = {
            functionName: "physicianNPPESLookup",
            zebraUserId: userId,
            firstName: searchFields.physicianFirstName,
            lastName: searchFields.physicianLastName,
            groupName: searchFields.physicianGroupName !== ""  ? searchFields.physicianGroupName : null
        }

        loadNppesPhysicianList(req);
        setLoading(true);

        return () => {
            resetNppesPhysicianList();
        }
    }, []);

    useEffect(() => {
        setLoading(false);
        if(nppesPhysicianList.length > 0) {
            setNppesPhysicians(nppesPhysicianList.map(item => ({...item, checked: false})));
        }
    }, [nppesPhysicianList]);

    useEffect(() => {
        if(selectedItemIndex !== -1) {
            let updatedNppesPhysicianList = [...nppesPhysicianList];
            updatedNppesPhysicianList = updatedNppesPhysicianList.map((item, idx) => {
                return {
                    ...item, 
                    checked: selectedItemIndex === idx
                }
            });
            setSelectedItem(updatedNppesPhysicianList[selectedItemIndex]);
            setNppesPhysicians(updatedNppesPhysicianList);
        }
    }, [selectedItemIndex])

    const onInputChange = (index ) =>  {
       setSelectedItemIndex(index);
    }

    const onSelectHandler = () => {
        const req = {
            functionName: "saveNPPESPhysician",
            zebraUserId: userId,
            issued_by: selectedItem.issued_by,
            license: selectedItem.license,
            address: selectedItem.address,
            address_1: selectedItem.address_1,
            address_2: selectedItem.address_2,
            city: selectedItem.city,
            state: selectedItem.state,
            country: selectedItem.country,
            zipcode: selectedItem.zipcode,
            credential: selectedItem.credential,
            npi: selectedItem.npi,
            last_name: selectedItem.last_name,
            active: selectedItem.active,
            description: selectedItem.description,
            taxonomy: selectedItem.taxonomy,
            middle_name: selectedItem.middle_name,
            first_name: selectedItem.first_name,
            name_prefix: selectedItem.name_prefix,
            group_name: selectedItem.group_name,
            authorized_official_middle_name: selectedItem.authorized_official_middle_name,
            authorized_official_first_name: selectedItem.authorized_official_first_name,
            authorized_official_last_name: selectedItem.authorized_official_last_name
        
        }

        saveNppesPhysician(req);
        onClose();
    }

 

    return (<Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={onClose}>State License Select</ModalHeader>
        <ModalBody>
            <Row>
                <Col lg="12">
                    {nppesPhysicians && nppesPhysicians.length > 0 && <AppTable
                        resizable
                        columns={[
                            {
                                header: 'Select',
                                field: '',
                                render: (item, index) => <div style={{marginLeft: '2rem'}}>
                                    <Input 
                                        style={{
                                            position: 'relative',
                                            display: 'block',
                                            minHeight: '1.2rem',
                                            paddingLeft: '1.5rem'}} 
                                        checked={item.checked}
                                        onChange={() => onInputChange(index)}
                                        type="radio" />
                                </div>
                            },
                            {
                                header: 'Prefix',
                                field: 'name_prefix',
                                // render: item => getDollarAmount(item, 'dos')
                            },
                            {
                                header: 'Name',
                                field: 'name',
                            },
                            {
                                header: 'NPI',
                                field: 'npi',
                            },
                            {
                                header: 'Address',
                                field: 'address',
                               
                            },
                            {
                                header: 'Issued By',
                                field: 'issued_by',
                            },
                            {
                                header: 'License#',
                                field: 'license',
                            },
                            {
                                header: 'Descriptionn',
                                field: 'description',
                            },
                            {
                                header: 'Taxonomy',
                                field: 'taxonomy',
                            },
                            {
                                header: 'Credential',
                                field: 'credential',
                            },
                        ]}
                        data={nppesPhysicians}
                        autoPagination={true}
                    // sortAble={true}
                    /> }
                    {!loading && nppesPhysicians.length === 0 && <div style={{display: 'flex', height: '200px', alignItems: 'center', justifyContent: 'center'}}>
                        <h3 style={{textTransform: 'none'}}>No Physicians Found</h3>
                        </div>}
                </Col >
            </Row>
            <Row>
                <Col lg="12">
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                        <Button onClick={onClose} style={{marginRight: '10px'}}>Cancel</Button>
                        {selectedItem && <Button onClick={() => onSelectHandler(selectedItem)}>Save</Button> }
                    </div>
                </Col>
            </Row>
        </ModalBody>
    </Modal>)
}

const mapStateToProps = state => {
    const user = authSelectors.getUser(state);
    return {
        userId: user.id,
        nppesPhysicianList: state.FAST360.fast360Reducer.nppesPhysicianList,
    }
}

const mapDispatchToProps = dispatch => ({
    resetNppesPhysicianList: () => dispatch(fast360Actions.resetNppesPhysicianList()),
    loadNppesPhysicianList: (data) => dispatch(fast360Operations.loadNppesPhysicianList(data)),
    saveNppesPhysician: (data) => dispatch(fast360Operations.saveNppesPhysician(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360NppesPhysiciansModal);