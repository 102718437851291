import React, { useState } from 'react';
import {
  Button,
  CustomInput,
  FormGroup,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';

import DashboardUser from './DashboardUser';
import DashboardWIP from './DashboardWIP';
import DashboardBilling from './DashboardBilling';
import services from '../services/service';

const USER = 'USER';
const WIP = 'WIP';
const BILLING = 'BILLING';
const USERDATA = {};
const WORKLOADDATA = [];
const WIPDASHBOARDDATA = [];
const USERWIPTASKDATA = [];
const BILLINGDASHBOARDDATA = [];

const DashboardButton = (props) => {
  const userId = props.userId;
  const zebraUserId = props.zebraUserId;
  const zebraId = props.zebraId;
  const setSelectedUser = props.setSelectedUser
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState(USER);
  const [userData, setData] = useState(USERDATA);
  const [workloadData, setWorkloadData] = useState(WORKLOADDATA)
  const [wipDashboardData, setWIPDashboardData] = useState(WIPDASHBOARDDATA);
  const [billingDashboardData, setBillingDashboardData] = useState(BILLINGDASHBOARDDATA);
  const [userWIPTaskData, setUserWIPTaskData] = useState(USERWIPTASKDATA);

  const toggle = () => {
    setModal(!modal);
  };

  //const closeModal = useCallback(() => setModal(false));

  const openModal = () => {
    services.loadUserDashboardData(userId, zebraId).then(dashboardData => {
      setData({...dashboardData, userId, zebraId});
      toggle();
    });
  };

  const onTabRadioChangeHandler = event => {
    const tabId = event.target.value;
    
    const zebraUserId = zebraId;
    if(tabId === WIP && workloadData.length === 0) {
      const promise1 = services.loadWorkloadData(userId, zebraUserId);
      const promise2 = services.loadWIPDashboardData(userId, zebraUserId);
      Promise.all([promise1, promise2]).then(([workload, wip]) => {
        setWorkloadData(workload);
        setWIPDashboardData(wip);
      })
    }else if(tabId === BILLING) {
      const promise1 = services.loadBillingDashboardData(userId, zebraUserId);
      Promise.all([promise1]).then(([workload]) => {
        setBillingDashboardData(workload);
        
      })
    }
    setTab(tabId)
  };

  
  const getBody = () => {
    return (
      <>
        <Row className="justify-content-md-center">
          <FormGroup className="form-check-inline">
            <CustomInput
              type="radio"
              id="userRadio"
              name="tabRadio"
              label="User"
              value={USER}
              checked={tab === USER}
              onChange={onTabRadioChangeHandler}
            />
          </FormGroup>
          <FormGroup className="form-check-inline">
            <CustomInput
              type="radio"
              id="wipRadio"
              name="tabRadio"
              label="WIP"
              value={WIP}
              checked={tab === WIP}
              onChange={onTabRadioChangeHandler}
            />
          </FormGroup>
          <FormGroup className="form-check-inline">
            <CustomInput
              type="radio"
              id="billingRadio"
              name="tabRadio"
              label="Billing"
              value={BILLING}
              checked={tab === BILLING}
              onChange={onTabRadioChangeHandler}
            />
          </FormGroup>
        </Row>
        <hr />
        <TabContent activeTab={tab} style={{ paddingTop: '10px' }}>
          <TabPane tabId={USER}>
            <DashboardUser userData={userData} />
          </TabPane>
          <TabPane tabId={WIP}>
            {tab === WIP && workloadData.length > 0 && wipDashboardData.length > 0 && <DashboardWIP workloadData={workloadData} wipDashboardData={wipDashboardData} userWIPTaskData={userWIPTaskData} setSelectedUser={setSelectedUser} setUserWIPTaskData={setUserWIPTaskData} setFilterData={props.setFilterData}/>}
          </TabPane>
          <TabPane tabId={BILLING}>
            {tab === BILLING && billingDashboardData.length > 0 && <DashboardBilling zebraUserId={zebraId} billingDashboardData={billingDashboardData} setSelectedUserId={setSelectedUser} setUserWIPTaskData={setUserWIPTaskData} />}
          </TabPane>
        </TabContent>
      </>
    );
  };

  return (
    <>
      <Button type="button" onClick={openModal}>
        &gt;
      </Button>
      <PopupModal
        content={getBody()}
        title="Dashboards"
        externalToggle={toggle}
        isOpen={modal}
        includeFooter={false}
        size="lg"
      />
    </>
  );
};

export default DashboardButton;
