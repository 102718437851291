import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store';

class MilestoneHover360 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChecked: true,
            list: []
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {

        let data = [], checkVal = [];
        if (!this.props.milestoneChanged || (this.props.prevUser != '' && this.props.userSelected != this.props.prevUser))
            this.props.dataFromReducer.milestone.map(item => data.push({ 'name': item, 'isChecked': true }))
        else
            data = this.props.milestoneData;


        data.map(i => checkVal.push(i.isChecked));
        if (checkVal.includes(false))
            this.setState({ allChecked: false })
        this.setState({ list: data })
        if(this.props.mileStoneBackUp && this.props.mileStoneBackUp.length === 0) {
            this.props.setMilestoneBackUp(this.props.dataFromReducer.initialWorkList);
        }
    }
    toggle = () => {
        this.props.clicked()
    };
    handleChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { list, allChecked } = prevState;
            if (itemName === "checkAll") {
                allChecked = checked;
                list = list.map(item => ({ ...item, isChecked: checked }));
            } else {
                list = list.map(item =>
                    item.name === itemName ? { ...item, isChecked: checked } : item
                );
                allChecked = list.every(item => item.isChecked);
            }
            return { list, allChecked };
        }, () => {
            this.filterSelectedItems();
            this.props.updateMilestoneClicked(true);
            this.props.milestoneAgainClicked(true);
            this.props.updateMilestoneData(this.state.list);
            this.props.updatePreviousUser(this.props.userSelected);
            this.props.setSelectedType('mileStone');
            this.props.setSelectedFilterType('mileStone');
        });
    };
    filterSelectedItems = () => {
        let selected = [], selectedWorkList = [];
        let selectedItems = this.state.list.filter(i => i.isChecked == true);
        selectedItems.map(i => selected.push(i.name.toLowerCase().replace(/ /g, '')));
        // if (this.props.statusChanged)
        //     this.props.statusWorkList.map(i => i.currentMilestone && selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
        // else
        this.props.mileStoneBackUp.map(i => selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
        this.props.updateNewUsersWorkList(selectedWorkList);
        this.props.milestoneWorkListBackup(selectedWorkList);
    }

    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '234px' }} scrollable={true}>
                <ModalHeader toggle={this.toggle}>
                    Milestones:
                </ModalHeader>
                <FormGroup check style={{ margin: '16px' }}>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChange}
                        />{' '}
                        {'Select All'}
                    </Label>
                </FormGroup>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            {this.state.list.map((item, idx) => (
                                <FormGroup key={idx} check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name={item.name} value={item.name} checked={item.isChecked} onChange={this.handleChange}
                                        />
                                        {item.name}
                                    </Label>
                                </FormGroup>

                            ))}
                        </FormGroup>
                    </Form>

                </ModalBody>
            </Modal>
        )
    }
}


const mapStateToProps = state => {
    return {
        statusChanged: state.FAST360.fast360Reducer.statusClicked,
        dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
        milestoneChanged: state.FAST360.fast360Reducer.milestoneClicked,
        milestoneData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateMilestoneData : [],
        prevUser: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatePreviousUser : '',
        statusWorkList: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.statusWorkListBackup : [],
        mileStoneBackUp: state.FAST360.fast360Reducer.mileStoneBackUp

    };
};
const mapDispatchToProps = dispatch => ({
    updateNewUsersWorkList: (data) => dispatch(fast360Actions.updateNewUsersWorkList(data)),
    milestoneWorkListBackup: (data) => dispatch(fast360Actions.milestoneWorkListBackup(data)),
    updateMilestoneClicked: (data) => dispatch(fast360Actions.updateMilestoneClicked(data)),
    milestoneAgainClicked: (data) => dispatch(fast360Actions.milestoneAgainClicked(data)),
    updateMilestoneData: (data) => dispatch(fast360Actions.updateMilestoneData(data)),
    updatePreviousUser: (data) => dispatch(fast360Actions.updatePreviousUser(data)),
    setSelectedType: (data) => dispatch(fast360Actions.setSelectedType(data)),
    setMilestoneBackUp: (data) => dispatch(fast360Actions.mileStoneBackUp(data)),
    setSelectedFilterType:(data) => dispatch(fast360Actions.setSelectedFilterType(data)),

});


export default connect(mapStateToProps, mapDispatchToProps)(MilestoneHover360);