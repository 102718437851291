import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

class NewUserButton extends Component {
  render() {
    return (
      <NavLink to={{ pathname: '/administration/user', state: { userId: '' } }}>
        <Button>NEW USER</Button>
      </NavLink>
    );
  }
}

export default NewUserButton;
