import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { CustomInput, Input, Button } from 'reactstrap';

import { commonOperations } from 'common/store';
import AppTable from '../table/AppTable';
import './dropdown-multi-select.scss';

class DropdownMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selected: [],
      value: '',
      showOptions: false,
    };
  }

  initOptions = () => {
    if (
      this.props.options[0] &&
      !this.props.options[0].hasOwnProperty(this.props.accessor.key)
    ) {
      commonOperations.addAlert(
        'Data does not have property: ' + this.props.accessor.key,
        'danger'
      );
      return;
    }
    let options = [];
    let item = {};
    for (let i = 0; i < this.props.options.length; i++) {
      item = this.props.options[i];
      options.push({
        key: item[this.props.accessor.key],
        value: item[this.props.accessor.value],
      });
    }
    let selected = [];
    if (this.props.selected) {
      for (let i = 0; i < this.props.selected.length; i++) {
        item = this.props.selected[i];
        if (this.props.options.includes(item)) {
          selected.push({
            key: item[this.props.accessor.key],
            value: item[this.props.accessor.value],
          });
        }
      }
    }
    this.setState({ options: options, selected: selected });
  };

  componentDidMount() {
    if (this.props.options.length > 0) {
      this.initOptions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.options.length === 0 &&
      prevProps.options.length !== this.props.options.length
    ) {
      this.initOptions();
    }
    if(prevProps.selected.length && !this.props.selected.length) {
      this.setState({ value: '', selected: [] });
    }
    if (prevState.selected.length !== this.state.selected.length) {
      if (this.state.selected.length === 0) {
        this.setState({ value: '' });
        this.props.changeHandler(this.props.id, '');
        return;
      }
      let keyString = '';
      const valueString = this.state.selected
        .reduce((accumulator, currentValue) => {
          keyString += currentValue.key + ',';
          return accumulator + currentValue.value + ',';
        }, '')
        .replace(/,\s*$/, '');
      this.setState({ value: valueString });
      this.props.changeHandler(
        this.props.id,
        `(${keyString.replace(/,\s*$/, '')})`
      );
    }
  }

  select = (event, item) =>
    this.setState({
      selected: event.target.checked
        ? this.state.selected.concat(item)
        : this.state.selected.filter(select => select.key !== item.key),
    });

  selectAll = e =>
    this.setState({ selected: e.target.checked ? this.state.options : [] });

  calculateChecked = item =>
    typeof R.find(select => select.key === item.key, this.state.selected) !==
    'undefined';

  calculateCheckedAll = () =>
    this.state.options.length !== 0 &&
    this.state.selected.length === this.state.options.length;

  buildSelectAllHeader = () => (
    <CustomInput
      id={this.props.id + '-id-select-all-checkbox'}
      type="checkbox"
      checked={this.calculateCheckedAll()}
      onChange={this.selectAll}
    />
  );

  buildSelectColumn = item => (
    <CustomInput
      id={`dropdown-multi-select-id-${item.key}`}
      type="checkbox"
      checked={this.calculateChecked(item)}
      onChange={event => this.select(event, item)}
    />
  );

  buildOptions = item => item.value;

  buildOptionsList = columns =>
    this.state.showOptions ? (
      <div className="options-list-wrapper">
        <AppTable
          resizable
          columns={columns}
          data={this.state.options}
          sortAble={true}
          sortBy="value"
        />
      </div>
    ) : (
      ''
    );

  toggleOptionsList = () =>
    this.setState({ showOptions: !this.state.showOptions });

  render() {
    const columns = [
      {
        header: this.buildSelectAllHeader(),
        field: 'select',
        percent: '20',
        render: item => this.buildSelectColumn(item),
        sortAble: false,
      },
      {
        header: this.props.optionColumnHeader,
        field: 'value',
        render: this.buildOptions,
      },
    ];
    return (
      <div className="dropdown-multi-select-wrapper">
        <Input
          type="text"
          id="dropdown-multi-select-input"
          onChange={this.onSelectedChanged}
          value={this.state.value}
          readOnly
          placeholder={this.props.placeHolder}
        />
        <Button
          className="show-dropdown-button"
          onClick={this.toggleOptionsList}
        >
          {this.state.showOptions ? '-' : '+'}
        </Button>
        {this.buildOptionsList(columns)}
      </div>
    );
  }
}

DropdownMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  accessor: PropTypes.object,
  placeHolder: PropTypes.string,
  optionColumnHeader: PropTypes.string,
};
DropdownMultiSelect.defaultProps = {
  accessor: {
    key: 'key',
    value: 'value',
  },
  selected: [],
  placeHolder: '',
  optionColumnHeader: 'Column header',
};
export default DropdownMultiSelect;
