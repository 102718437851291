import {
  Modal,
  ModalHeader, ModalBody, Input
} from 'reactstrap';
import { Col, Row, Form, FormGroup, Button, Card, CardHeader, CardBody } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from "../store";
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

import { Tooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FeeLookupModal = ({
  isOpen,
  onClose,
  npiFeeLookupData,
  feeLookupData,
  feeLookupDataMed,
  feeLookupDataSsDesc,
  feeLookupDataUC,
  feeLookupDataUHUC,
  feeLookupDataByZip,
  getFeeLookupData,
  getLookupfeescheduleNPI,
  resetLookupfeescheduleNPIData,
  getFeeLookupDataMed,
  getFeeLookupDataSsDesc,
  getFeeLookupDataUC,
  getFeeLookupDataUHUC,
  getFeeLookupDataByZip,
  resetFeeLookupData,
  resetFeeLookupDataUC,
  resetFeeLookupDataUHUC,
  resetFeeLookupDataByZip,
  resetFeeLookupDataMed,
  resetFeeLookupDataSsDesc,
  resetFeeLookupDataByMed,
  userId,
  zebraUserId,
  profile,
  isFromMcns,
  mcnsData,
  mcnsRowIndex,
  taskListData,
  mcnsZipCode,

  alert,
  start_date
}) => {

  const [searchFieldOne, setSearchFieldOne] = useState(taskListData && taskListData.length ? mcnsZipCode : '');
  const [searchFieldTwo, setSearchFieldTwo] = useState(mcnsData ? mcnsData[mcnsRowIndex].cpt : '');
  const [searchFieldThree, setSearchFieldThree] = useState('');
  const [searchFields, setSearchFields] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTool = () => setTooltipOpen(!tooltipOpen);
  const [startDate, setStartDate] = useState(start_date ? new Date(start_date) : new Date());
  const toggle = () => {
    resetFormData();
    onClose();
  };
  const onChangeZipCodeHandler = (id, value) => {
    setSearchFieldOne(value);
  }

  useEffect(() => {
    if (isFromMcns) {
      const params = {
        searchFieldOne,
        searchFieldTwo,
        searchFieldThree,
        startDate,
        userId,
        zebraUserId,
        profile
      }
      if (params.searchFieldOne !== "" && params.searchFieldOne.length == 5) {
        // getFeeLookupDataByZip(params);
        getFeeLookupData(params);
        // getFeeLookupDataUC(params);
        // getFeeLookupDataUHUC(params);
        // getFeeLookupDataMed(params);
        // getFeeLookupDataSsDesc(params);
      }
    }
    const params = {
      searchFieldOne,
      userId,
      zebraUserId,
      profile
    }
    if (searchFieldOne !== "" && (searchFieldOne).length == 5 && !isFromMcns) {
      getFeeLookupDataByZip(params);
    }
  }, [searchFieldOne]);

  const onChangeProcCodeHandler = (id, value) => {
    setSearchFieldTwo(value);
  }

  const onChangeNPICodeHandler = (id, value) => {
    setSearchFieldThree(value);
  }

  const resetFormData = () => {
    setSearchFields({});
    setSearchFieldOne("");
    setSearchFieldTwo("");
    setSearchFieldThree("");
    setStartDate(new Date());
    resetFeeLookupData();
    resetLookupfeescheduleNPIData();
    resetFeeLookupDataUC();
    resetFeeLookupDataUHUC();
    resetFeeLookupDataByZip();
    resetFeeLookupDataMed();
    resetFeeLookupDataSsDesc();
    // resetFeeLookupDataByMed();

  }
  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      searchFieldOne,
      searchFieldTwo,
      searchFieldThree,
      startDate,
      userId,
      zebraUserId,
      profile
    }
    resetFeeLookupData();
    resetFeeLookupDataUC();
    resetFeeLookupDataUHUC();
    resetFeeLookupDataMed();
    resetFeeLookupDataSsDesc();
    // resetFeeLookupDataByZip();
    //resetFeeLookupDataByMed();
    if (searchFieldTwo !== "" && startDate !== null && searchFieldThree !== "") {
      getLookupfeescheduleNPI(params);
    }
    if (searchFieldOne == "" || searchFieldTwo == "" || startDate == null) {
      alert('You must provide a Zipcode, Procedure Code and Date in order to search.');
      return;
    }
    if (searchFieldOne !== "" && searchFieldTwo !== "" && startDate !== null) {
      getFeeLookupData(params);
    }
    // if (searchFieldOne !== "" && searchFieldTwo !== "" && startDate !== null) {
    //   getFeeLookupDataUC(params);
    // }
    // if (searchFieldOne !== "" && searchFieldTwo !== "" && startDate !== null) {
    //   getFeeLookupDataUHUC(params);
    // }
    // if (searchFieldOne !== "" && searchFieldTwo !== "" && startDate !== null) {
    //   getFeeLookupDataMed(params);
    // }
    // if (searchFieldOne !== "" && searchFieldTwo !== "" && startDate !== null) {
    //   getFeeLookupDataSsDesc(params);
    // }
    if (searchFields.zipcode && searchFields.zipcode !== "") {
      getFeeLookupDataByZip(params);
    }
  }

  const sortColumn = (item1, item2, order, field) => {
    const field1 = item1[field] || {};
    const field2 = item2[field] || {};
    return sortByType(field1[field], field2[field], order);
  }

  const renderAddressColumn = item => {
    let print = '';
    if (item.address1) {
      print = (
        <>
          <br />
          <span>
            {`${item.city}, ${item.state}  ${item.zip}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.address1}{' '}{item.address2}
        {print}
      </>
    );
  };

  const getNpiFeeLookupAppTable = () => {
    return (
      <div className="npi-fee-lookup-table">
       <Card>
          <CardHeader>CM Fee Schedule</CardHeader>
          <CardBody>
            <AppTable
              modalHeight={130}
              columns={[
                {
                  header: 'TIN',
                  field: 'tin'
                },
                {
                  header: 'NPI',
                  field: 'npi'
                },
                {
                  header: 'Hcpcs',
                  field: 'hcpcs',

                },
                {
                  header: 'Description',
                  field: 'description',

                },
                {
                  header: 'Name',
                  field: 'name',
                },
                {
                  header: 'Address',
                  field: 'address1',
                  render: item => renderAddressColumn(item),
                },
                {
                  header: 'Mod1',
                  field: 'mod1',
                },
                {
                  header: 'Mod2',
                  field: 'mod2',
                },
                {
                  header: 'In Patient',
                  field: 'inPatient',
                },
                {
                  header: 'Out Patient',
                  field: 'outPatient',

                },
                {
                  header: 'Eff Date',
                  field: 'effDate',
                  render: item => (item.dateSpan || {}).effDate || '',

                },
                {
                  header: 'Term Date',
                  field: 'termDate',
                  render: item => (item.dateSpan || {}).termDate || '',
                },
                {
                  header: 'Reason',
                  field: 'reason',
                },
              ]}

              data={npiFeeLookupData || []}
              //autoPagination={true}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
              key="npi-fee-lookup-table"
              striped={true}
            />
          </CardBody>
        </Card>



      </div>


    )
  }

  const getFeeLookupAppTable = () => {
    return (
      <div className="fee-lookup-table">
        <Row>
          <Col lg="12 mb-5">
            <AppTable
              modalHeight={130}
              columns={[
                {
                  header: 'LOB',
                  field: 'ssLob',

                  sortFunction: (item1, item2, order) =>
                    sortColumn(item1, item2, order, 'ssLob'),

                },
                {
                  header: 'Sub Code',
                  field: 'ssSubcode',

                },
                {
                  header: 'Specialty',
                  //field: 'ssDescription',

                },
                {
                  header: 'Region',
                  field: 'ssRegion',

                },
                {
                  header: 'Modifier',
                  field: 'ssMod',

                },
                {
                  header: 'Type of Service',
                  field: 'ssTos',

                },
                {
                  header: 'Place of Service',
                  field: 'ssPos',

                },
                {
                  header: 'Schedule',
                  field: 'ssSchedule',

                },

              ]}

              data={feeLookupData}
              //autoPagination={true}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
              key="fee-lookup-table"
              striped={true}
            />
          </Col>
        </Row>



      </div>


    )
  }

  const getFeeLookupUCAppTable = () => {
    return (
      <div className="fee-lookup-table">
        <Card>
          <CardHeader>U & C Fee Schedule</CardHeader>
          <CardBody>
            <AppTable
              modalHeight={130}
              columns={[
                {
                  header: 'Region',
                  field: 'ucRegion',

                },
                {
                  header: 'Modifier',
                  field: 'ucMod',

                },
                {
                  header: 'Type of Service',
                  field: 'ucTos',

                },
                {
                  header: 'Place Of Service',
                  field: 'ucPos',

                },
                {
                  header: '70TH',
                  field: 'uc70',

                },
                {
                  header: '75TH',
                  field: 'uc75',

                },
                {
                  header: '80TH',
                  field: 'uc80',

                },
                {
                  header: '85TH',
                  field: 'uc85',

                },
                {
                  header: '90TH',
                  field: 'uc90',

                },
                {
                  header: 'RVU',
                  field: 'ucRvu',

                },

              ]}
              data={feeLookupDataUC}
              autoPagination={true}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
              key="fee-lookup-table-uc"
            />
          </CardBody>
        </Card>


      </div>


    )
  }

  const getFeeLookupUHUCAppTable = () => {
    return (
      <div className="fee-lookup-table">
        <Card>
          <CardHeader>C4H Fee Schedule</CardHeader>
          <CardBody>
            <AppTable
              modalHeight={130}
              columns={[
                {
                  header: 'Region',
                  field: 'c4Region',

                },
                {
                  header: 'Modifier',
                  field: 'c4Mod',

                },
                {
                  header: 'Type of Service',
                  field: 'c4Tos',

                },
                {
                  header: 'Place Of Service',
                  field: 'c4Pos',

                },
                {
                  header: '70TH',
                  field: 'c470',

                },
                {
                  header: '75TH',
                  field: 'c475',

                },
                {
                  header: '80TH',
                  field: 'c480',

                },
                {
                  header: '85TH',
                  field: 'c485',

                },
                {
                  header: '90TH',
                  field: 'c490',

                },
                {
                  header: 'RVU',
                  field: 'c4Rvu',

                },

              ]}
              data={feeLookupDataUHUC}
              autoPagination={true}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
              key="fee-lookup-table-uc"
            />
          </CardBody>
        </Card>


      </div>


    )
  }

  const getFeeLookupByZipAppTable = () => {
    return (
      <div className="fee-lookup-table">
        <Card>
          <CardHeader>Fees by Zip</CardHeader>
          <CardBody>
            <AppTable
              modalHeight={130}
              columns={[
                {
                  header: 'Eff Date',
                  field: 'effDate',
                  sortAble: false
                },
                {
                  header: 'Term Date',
                  field: 'termDate',
                  sortAble: false

                },
                {
                  header: 'WC REG',
                  field: 'wcRegion',

                },
                {
                  header: 'POP REG',
                  field: 'pipRegion',

                },
                {
                  header: 'UC REG',
                  field: 'ucRegion',

                },
                {
                  header: 'OP REG',
                  field: 'opRegion',

                },
                {
                  header: 'OWCP REG',
                  field: 'owcRegion',

                },
                {
                  header: 'WC CFACT',
                  field: 'wcCFactor',
                  sortFunction: (item1, item2, order) =>
                    sortColumn(item1, item2, order, 'wcCFactor'),


                },
                {
                  header: 'PIP CFACT',
                  field: 'pipCFactor',
                  sortAble: false

                },
                {
                  header: 'OP CFACT',
                  field: 'opcfact',

                },
                {
                  header: 'OWCP CFACT',
                  field: 'owcpCFactor',

                },

              ]}
              data={feeLookupDataByZip}
              //autoPagination={true}
              sortAble={true}
              resizable={true}
              sortImmediately={false}
              key="fee-lookup-table-by-zip"
            />
          </CardBody>
        </Card>


      </div>


    )
  }


  return (
    <>
      <Modal size="xl" isOpen={isOpen} modalClassName="fee-lookup-modal-body">
        <ModalHeader toggle={toggle}>FEE SCHEDULE LOOKUP</ModalHeader>
        <ModalBody>
          <Row xs="5">
            <Col>
              <div>Zip Code</div>
            </Col>

            <Col>
              <div>Procedure Code</div>
            </Col>

            <Col>
              <div>TIN/NPI</div>
            </Col>
            
            <Col>
              <div>Date of Service</div>
            </Col>

            <Col>

            </Col>

            <Col className="speciality">
              <div >{feeLookupDataSsDesc}</div>
            </Col>


          </Row>
          <Form onSubmit={onFormSubmit}>
            <Row form xs="5">
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeZipCodeHandler}
                    placeholder="Zip Code"
                    value={searchFieldOne}
                    id="zipcode"
                    type="text"
                  />

                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeProcCodeHandler}
                    placeholder="Procedure Code"
                    value={searchFieldTwo}
                    id="proccode"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeNPICodeHandler}
                    placeholder="TIN/NPI"
                    value={searchFieldThree}
                    id="npiCode"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <DatePicker
                    locale="en"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                  />
                </FormGroup>
              </Col>

              <Col>
                <Button type="submit" id="TooltipExample">FIND</Button>

                <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleTool}>
                  All three Fields are required for search
      </Tooltip>

              </Col>

              <Col className="speciality">
                <div >{feeLookupDataMed}</div>
              </Col>

            </Row>
            <Row>
              <Col lg="12">
                {getFeeLookupAppTable()}
              </Col>

            </Row>

            <Row>
              <Col lg="12">
                {getFeeLookupUCAppTable()}
              </Col>

            </Row>
            <Row>
              <Col lg="12">
                {getFeeLookupUHUCAppTable()}
              </Col>

            </Row>
            <Row>
              <Col lg="12">
                {getFeeLookupByZipAppTable()}
              </Col>

            </Row>
            <Row>
              <Col lg="12">
                {getNpiFeeLookupAppTable()}
              </Col>

            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

FeeLookupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mcnsRowIndex: PropTypes.number,
  isFromMcns: PropTypes.bool,
  mcnsData: PropTypes.array,
  taskListData: PropTypes.array,
  mcnsZipCode: PropTypes.string,
  start_date: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  feeLookupData: PropTypes.array,
  feeLookupDataMed: PropTypes.array,
  feeLookupDataSsDesc: PropTypes.array,
  feeLookupDataMedState: PropTypes.string,
  feeLookupDataUC: PropTypes.array,
  feeLookupDataUHUC: PropTypes.array,
  feeLookupDataByZip: PropTypes.array,
  getFeeLookupData: PropTypes.func,
  getFeeLookupDataMed: PropTypes.func,
  getFeeLookupDataSsDesc: PropTypes.func,
  getFeeLookupDataUC: PropTypes.func,
  getFeeLookupDataUHUC: PropTypes.func,
  getFeeLookupDataByZip: PropTypes.func,
  resetFeeLookupData: PropTypes.func,
  resetFeeLookupDataUC: PropTypes.func,
  resetFeeLookupDataUHUC: PropTypes.func,
  resetFeeLookupDataByZip: PropTypes.func,
  resetFeeLookupDataByMed: PropTypes.func,
  alert: PropTypes.func,
  userId: PropTypes.string,
  zebraUserId: PropTypes.string,
  profile: PropTypes.string,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  return {
    feeLookupData: billReviewState.feeLookupData,
    npiFeeLookupData: billReviewState.npiFeeLookupData,
    feeLookupDataMed: billReviewState.feeLookupDataMed,
    feeLookupDataSsDesc: billReviewState.feeLookupDataSsDesc,
    feeLookupDataUC: billReviewState.feeLookupDataUC,
    feeLookupDataUHUC: billReviewState.feeLookupDataUHUC,
    feeLookupDataByZip: billReviewState.feeLookupDataByZip,
    zebraUserId: user && user.user_id,
    profile: billReviewInitialData.currentProfile
  };

};



const mapDispatchToProps = dispatch => ({
  getFeeLookupData: data => dispatch(billOperationsOperations.getFeeLookupData(data)),
  getLookupfeescheduleNPI: data => dispatch(billOperationsOperations.getLookupfeescheduleNPI(data)),
  // getFeeLookupDataMed: data => dispatch(billOperationsOperations.getFeeLookupDataMed(data)),
  // getFeeLookupDataSsDesc: data => dispatch(billOperationsOperations.getFeeLookupDataSsDesc(data)),


  // getFeeLookupDataUC: data => dispatch(billOperationsOperations.getFeeLookupDataUC(data)),
  //getFeeLookupDataUHUC: data => dispatch(billOperationsOperations.getFeeLookupDataUHUC(data)),
  getFeeLookupDataByZip: data => dispatch(billOperationsOperations.getFeeLookupDataByZip(data)),
  resetFeeLookupData: () => dispatch(billOperationsActions.resetFeeLookupData()),
  resetLookupfeescheduleNPIData: () => dispatch(billOperationsActions.resetLookupfeescheduleNPIData()),
  resetFeeLookupDataUC: () => dispatch(billOperationsActions.resetFeeLookupDataUC()),
  resetFeeLookupDataUHUC: () => dispatch(billOperationsActions.resetFeeLookupDataUHUC()),
  resetFeeLookupDataByZip: () => dispatch(billOperationsActions.resetFeeLookupDataByZip()),
  resetFeeLookupDataMed: () => dispatch(billOperationsActions.resetFeeLookupDataMed()),
  resetFeeLookupDataSsDesc: () => dispatch(billOperationsActions.resetFeeLookupDataSsDesc()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeLookupModal);
