import React, {useEffect, useState} from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  fast360Selectors
} from '../store/index';
import AppTable from 'shared/components/table/AppTable';

let details;
let costSavings;
let detailsFromOrder;
const Fast360Matrix = ({
  detailsForOrder,
  isExternalReferral,
  updateAudHcpcList,
  updateCostSavingList,
  audInfo
}) => {

  useEffect(() => {
    if(!isEmpty(detailsForOrder) && !isExternalReferral) {
      if(detailsForOrder?.module === 'MSE' || detailsForOrder?.module === 'MOD') {
        details = updateAudHcpcList || [];
        costSavings = updateCostSavingList;
        detailsFromOrder = detailsForOrder;
      } else if(detailsForOrder?.module === 'AUD' || detailsForOrder?.module === 'HHC' || detailsForOrder?.module === 'DTL' || detailsForOrder?.module === 'DGN') {
        details = updateAudHcpcList || [];
        costSavings = updateCostSavingList;
        detailsFromOrder = detailsForOrder;
      }
    } 
    else {
      details = [];
      costSavings = [];
      detailsFromOrder = {};
    }
  }, [updateCostSavingList, updateAudHcpcList, detailsForOrder, audInfo])

  const matrixColumns = (item, value) => {
    let val;
    if (value === "vendCost") {
      let filteredDetails = details && details.length && details.filter(detailObj => (detailObj.vendCost || detailObj.vendorCost));
      let venCost = filteredDetails && filteredDetails.length && filteredDetails.map(i => Number(i.vendCost || i.vendorCost));
      val = venCost && venCost.length > 0 ? venCost.reduce((a, b) => { return +a + +b; }) : "";
      if(val){
        val = val.toFixed(2);
      }
      return `$${val || 0.00}`;
    } else if(value === "custCost") {
      let filteredDetails = details && details.length && details.filter(detailObj => detailObj.custCost);
      let cusCost = filteredDetails && filteredDetails.length && filteredDetails.map(i => Number(i.custCost));
      val = cusCost && cusCost.length > 0 ? cusCost.reduce((a, b) => { return +a + +b; }) : "";
      if(val){
        val = val.toFixed(2);
      }
      return `$${val || 0.00}`;
    } else if(value === 'costMar') {
      let venCost = details && details.length && details.filter(i => Number(i.vendCost || i.vendorCost)).map(i => Number(i.vendCost || i.vendorCost));
      let venVal = venCost && venCost.length > 0 ? venCost.reduce((a, b) => { return +a + +b; }) : "";
      let cusCost = details && details.length && details.filter(detailObj => detailObj.custCost).map(i => Number(i.custCost));
      let cusVal = cusCost && cusCost.length > 0 ? cusCost.reduce((a, b) => { return +a + +b; }) : "";
      val = Number(((Number(cusVal) - Number(venVal)) / (Number(cusVal))) * 100).toFixed(2);
      return `${isNaN(val) ? 0 : val}%`;
    } else if(value === 'venTot'){
      let venCost = details && details.length && details.filter(i => (i.vendCost || i.vendorCost) && i.quantity).map(i => {
        if(i.vendPercentDiscount && i.vendPercentDiscount > 0 && (detailsFromOrder?.module !== 'AUD' && detailsFromOrder?.module !== 'MSE')) {
          return Number(Number(i.vendCost || i.vendorCost) * (1 - Number(i.vendPercentDiscount)) * Number(i.quantity)).toFixed(2);
        }else {
        return Number(Number(i.vendCost || i.vendorCost) * Number(i.quantity)).toFixed(2);
        }
        });
       val = venCost && venCost.length > 0?venCost.reduce((a, b) => { return +a + +b; }) : "";
      return `$${isNaN(val) ? 0.00 : Number(val).toFixed(2)}`;
    } else if(value === 'cusTot'){
      let filteredDetails = details && details.length && details.filter(detailObj => detailObj.custCost && detailObj.quantity);
      let cusCost = filteredDetails && filteredDetails.length && filteredDetails.map(i =>  {
        if(i.custPercentDiscount && i.custPercentDiscount > 0 && (detailsFromOrder?.module !== 'AUD' && detailsFromOrder?.module !== 'MSE')) {
          return Number(Number(i.custCost) * (1 - Number(i.custPercentDiscount)) * Number(i.quantity)).toFixed(2);
        }else {
        return Number(Number(i.custCost) * Number(i.quantity)).toFixed(2);
        }
        });
      val = cusCost && cusCost.length> 0?cusCost.reduce((a, b) => { return +a + +b; }) : "";
      return `$${isNaN(val) ? 0.00 : Number(val).toFixed(2)}`;
    } else if(value === 'totMargin'){
      let venCost = details && details.length && details.filter(i => (i.vendCost || i.vendorCost) && i.quantity).map(i => {
        if(i.vendPercentDiscount && i.vendPercentDiscount > 0 && (detailsFromOrder?.module !== 'AUD' && detailsFromOrder?.module !== 'MSE')) {
          return Number(Number(i.vendCost || i.vendorCost) * (1 - Number(i.vendPercentDiscount)) * Number(i.quantity)).toFixed(2);
        }else {
        return Number(Number(i.vendCost || i.vendorCost) * Number(i.quantity)).toFixed(2);
        }
        });
      let venVal = venCost && venCost.length > 0 ?venCost.reduce((a, b) => { return +a + +b; }) : "";
      let cusCost = details && details.length && details.filter(detailObj => detailObj.custCost && detailObj.quantity).map(i => {
        if(i.custPercentDiscount && i.custPercentDiscount > 0 && (detailsFromOrder?.module !== 'AUD' && detailsFromOrder?.module !== 'MSE')) {
          return Number(Number(i.custCost) * (1 - Number(i.custPercentDiscount)) * Number(i.quantity)).toFixed(2);
        } else {
        return Number(Number(i.custCost) * Number(i.quantity)).toFixed(2);
        }
        });
      let cusVal = cusCost && cusCost.length > 0?cusCost.reduce((a, b) => { return +a + +b; }) : "";
      val = Number(((Number(cusVal) - Number(venVal)) / (Number(cusVal))) * 100).toFixed(2);
      return `${isNaN(val) ? 0 : val}%`;
    } else if(value === 'custBuyOut') {
      let cusCost = details && details.length && details.filter(i => i.rental?.custBuyOut && i.quantity).map(i => Number(Number(i.rental?.custBuyOut)* Number(i.quantity)).toFixed(2));
      val = cusCost && cusCost.length > 0?cusCost.reduce((a, b) => { return +a + +b; }) : "";
      return `$${val || 0.00}`;
    } else if(value === 'vendBuyOut') {
      let cusCost = details && details.length && details.filter(i => i.rental?.vendBuyOut && i.quantity).map(i => Number(Number(i.rental?.vendBuyOut)* Number(i.quantity)).toFixed(2));
      val = cusCost && cusCost.length > 0? cusCost.reduce((a, b) => { return +a + +b; }) : "";
      return `$${val || 0.00}`;
    } else if(value === 'buyOutMargin') {
      let cusCost = details && details.length && details.filter(i => i.rental?.custBuyOut && i.quantity).map(i => Number(Number(i.rental?.custBuyOut)* Number(i.quantity)).toFixed(2));
      let cusVal = cusCost && cusCost.length > 0?cusCost.reduce((a, b) => { return +a + +b; }) : "";
      let venCost = details && details.length && details.filter(i => i.rental?.vendBuyOut && i.quantity).map(i => Number(Number(i.rental?.vendBuyOut)* Number(i.quantity)).toFixed(2));
      let venVal = venCost && venCost.length > 0?venCost.reduce((a, b) => { return +a + +b; }) : "";
      val = Number(((Number(cusVal) - Number(venVal)) / (Number(cusVal))) * 100).toFixed(2);
      return `${val || 0}%`;
    } else if(value === 'billedCharges') {
      let billedCharges = details && details.length &&  details.filter(i => i.billCharges).map(i => Number(i.billCharges).toFixed(2));
      val = billedCharges && billedCharges.length > 0? billedCharges.reduce((a, b) => { return +a + +b; }) : "";
      return `$${isNaN(val) ? 0.00 : Number(val).toFixed(2)}`;
    }
  }

  return (
    <>
    <AppTable
      resizable
      columns={[
        {
          header: 'Vend Cost',
          field: 'vendCost',
          render: item => matrixColumns(item, 'vendCost'),
        },
        {
          header: 'Cust Cost',
          field: 'custCost',
          render:  item => matrixColumns(item, 'custCost'),
        },
        {
          header: 'Cost Margin',
          field: 'costMar',
          render:  item => matrixColumns(item, 'costMar'),
        },
        {
          header: 'Vend Buyout Amt',
          field: 'vendBuyOut',
          render: item => matrixColumns(item, 'vendBuyOut')
        },
        {
          header: 'Cust Buyout Amt',
          field: 'custBuyOut',
          render: item => matrixColumns(item, 'custBuyOut')
        },
        {
          header: 'Buyout Margin',
          field: 'buyOutMargin',
          render: item => matrixColumns(item, 'buyOutMargin')
        },
        {
          header: 'Billed Charges',
          field: 'billedCharges',
          render: item => matrixColumns(item, 'billedCharges')
        },
        {
          header: 'Vend. Total Cost',
          field: 'venTot',
          render: item => matrixColumns(item, 'venTot')
        },
        {
          header: 'Cust. Total Cost',
          field: 'cusTot',
          render: item => matrixColumns(item, 'cusTot')
        },
        {
          header: 'Total Margin',
          field: 'totMargin',
          render: item => matrixColumns(item, 'totMargin')
        },
      ]}
      data={ costSavings && costSavings.length > 0 && [costSavings[0]] || details && details.length > 0 && [details[0]] || []}
      autoPagination={true}
    />
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    updateAudHcpcList: fast360Info.updateAudHcpcList,
    updateCostSavingList: fast360Info.updateCostSavingList,
    audInfo: fast360Info.audInfo
  }
}


export default connect(mapStateToProps, null) (Fast360Matrix);
