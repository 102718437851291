import {
    Modal,
    ModalHeader, ModalBody
} from 'reactstrap';
import { Col, Row, Form, FormGroup, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
    billOperationsActions,
    billOperationsOperations,
    billOperationsSelectors,
} from "../store";
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

const OdgLookupModal = ({
    isOpen,
    onClose,
    billId,
    odgLookupData,
    resetOdgLookupData,
    getOdgLookupData,
    getOdgLookupDataByBill,
    userId,
    profile,
    alert,
    fromOptions
}) => {
    const [searchFields, setSearchFields] = useState({})

    const [icd, setICD] = useState("")
    const [cpt, setCPT] = useState("")

    useEffect(() => {
        if (isOpen && !fromOptions) {
            // Modal got opened so run initial load
            const params = {
                ...searchFields,
                userId,
                profile,
                billId,
            }
            getOdgLookupDataByBill(params);
        }
    }, [isOpen, getOdgLookupData, getOdgLookupDataByBill]);

    const toggle = () => {
        resetFormData();
        onClose();
    };
    const onChangeHandler = (id, value) => {
        setSearchFields({ ...searchFields, [id]: value })
        if (id == "icd") {
            setICD(value);
        } else {
            setCPT(value);
        }
    }
    const resetFormData = () => {
        setSearchFields({});
        setICD("");
        setCPT("");
        resetOdgLookupData();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const params = {
            ...searchFields,
            userId,
            profile
        }


        const result = Object.values(searchFields).every(value => value === "")
        if (Object.keys(searchFields).length <= 1 || result) {
            alert('You must provide a ICD and CPT in order to search.');
            return;
        }
        resetOdgLookupData();

        if (searchFields.icd !== "" && searchFields.cpt !== "") {
            getOdgLookupData(params);
        }
    }

    const sortColumn = (item1, item2, order, field) => {
        const field1 = item1[field] || {};
        const field2 = item2[field] || {};
        return sortByType(field1[field], field2[field], order);
    }

    const renderFunction = (item) => {
        switch (item.fields.paymentFlag) {
            case 0:
                return (<AppTooltip text={"OK to auto-pay up to ODG CAA max number of visits"} id={`status-toolTip-${item.fields.id}`} placement="bottom"> <span style={{ color: 'green' }}>Green</span></AppTooltip>)
            case 1:
                return (<AppTooltip
                    text={"OK to auto-pay up to 25th percentile number of visits (or 50th percentile if a more liberal policy is desired by payor)"}
                    id={`status-toolTip-${item.fields.id}`} placement="right"> <span style={{ color: 'yellow' }}>Yellow</span></AppTooltip>)
            case 2:
                return (<AppTooltip text={"Need to review"} id={`status-toolTip-${item.fields.id}`} placement="bottom"> <span style={{ color: 'red' }}>Red</span></AppTooltip>)
            case 3:
                return (<AppTooltip text={"Need to review and considered unlikely"} id={`status-toolTip-${item.fields.id}`} placement="bottom"> <span style={{ color: 'black' }}>Black</span></AppTooltip>)
            default:
                return (<AppTooltip text={"OK to auto-pay up to ODG CAA max number of visits"} id={`status-toolTip-${item.fields.id}`} placement="bottom"> <span style={{ color: 'green' }}>Green</span></AppTooltip>);
        }

    }

    const onRowClick = data => {
        setICD(data.fields.icd);
        setCPT(data.fields.code);
    }

    const getOdgLookupAppTable = () => {
        return (
            <div className="odg-lookup-table">
                <AppTable
                    modalHeight={300}
                    columns={[
                        {
                            header: 'Diagnosis Code',
                            field: 'icd',

                        },
                        {
                            header: 'ICD Name',
                            field: 'icdDesc',

                            sortFunction: (item1, item2, order) =>
                                sortColumn(item1, item2, order, 'icdDesc'),

                        },
                        {
                            header: 'CPT Code',
                            field: 'code',

                        },
                        {
                            header: 'CPT Name',
                            field: 'codeDesc',

                        },
                        {
                            header: 'Incident Rate per 100k Workers',
                            field: 'incRate',

                        },
                        {
                            header: '% CPT Frequency per ICD',
                            field: 'frequency',

                        },
                        {
                            header: 'Visits 25%',
                            field: 'visits25',

                        },
                        {
                            header: 'Visits 50%',
                            field: 'visits50',

                        },
                        {
                            header: 'Visits 75%',
                            field: 'visits75',

                        },
                        {
                            header: 'Visits Mean',
                            field: 'visitsAvg',

                        },
                        {
                            header: 'Costs Mean',
                            field: 'costAvg',

                        },
                        {
                            header: 'ODG CAA',
                            field: 'caaMax',

                        },
                        {
                            header: 'Bill Review Payment Flag',
                            field: 'paymentFlag',
                            render: item => (renderFunction(item)),

                        },
                    ]}
                    data={odgLookupData}
                    autoPagination={true}
                    sortAble={true}
                    resizable={true}
                    sortImmediately={false}
                    key="odg-lookup-table"
                    rowClickHandler={onRowClick}
                />
            </div>
        )
    }

    return (
        <>
            <Modal size="xl" isOpen={isOpen}>
                <ModalHeader toggle={toggle}>ODG Lookup</ModalHeader>
                <ModalBody>
                    <Form onSubmit={onFormSubmit}>
                        <Row form>
                            <div className="mt-2" > ICD: </div>
                            <Col xs="2">
                                <FormGroup>
                                    <AppInput
                                        changeHandler={onChangeHandler}
                                        placeholder="ICD"
                                        id="icd"
                                        type="text"
                                        value={icd}
                                    />
                                </FormGroup>

                            </Col>
                            <div className="mt-2">CPT: </div>
                            <Col xs="2">
                                <FormGroup>
                                    <AppInput
                                        changeHandler={onChangeHandler}
                                        placeholder="CPT"
                                        id="cpt"
                                        type="text"
                                        value={cpt}
                                    />
                                </FormGroup>
                            </Col>


                        </Row>
                        <Row>
                            <Col lg="12">
                                {getOdgLookupAppTable()}
                            </Col>

                        </Row>
                        {fromOptions &&
                            <Button type="submit" id="odgSubmit">SUBMIT </Button>
                        }
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

OdgLookupModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    billId: PropTypes.number,
    odgLookupData: PropTypes.array,
    getOdgLookupData: PropTypes.func,
    resetOdgLookupData: PropTypes.func,

    alert: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    fromOptions: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    const billReviewState = billOperationsSelectors.getBillReview(state);
    const user = authSelectors.getUser(state);
    const billReviewInitialData = billOperationsSelectors.getInitialData(state);

    return {
        odgLookupData: billReviewState.odgLookupData,

        userId: user && user.user_id,
        profile: billReviewInitialData.currentProfile
    };
};

const mapDispatchToProps = dispatch => ({
    getOdgLookupData: data => dispatch(billOperationsOperations.getOdgLookupData(data)),
    getOdgLookupDataByBill: data => dispatch(billOperationsOperations.getOdgLookupDataByBill(data)),
    resetOdgLookupData: () => dispatch(billOperationsActions.resetOdgLookupData()),

    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(OdgLookupModal);
