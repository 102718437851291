import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Button, FormGroup } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';

import {
  taskListGridSettingName,
  taskListGridSettingModuleId,
} from '../utils/constantUtils';

import GridSettingsModal from './GridSettingsModal';

const RecentActivityTopNav = ({
  adjusters,
  selectedAdjusterName,
  handleUserDisplayChange,
  handleUserChange,
  settingColumns,
  updateGridSettingsCheckbox,
}) => {
  return (
    <Row>
      <Col lg={{ size: 2, offset: 2 }}>
        <FormGroup>
          {
            <AppAutocomplete
              id="adjuster"
              data={adjusters}
              value={selectedAdjusterName}
              displayOption="name"
              returnValue="id"
              onChange={handleUserDisplayChange}
              onSelect={handleUserChange}
              showToolTip={true}
              placeholder="Adjuster"
              isSuggested={true}
            />
          }
        </FormGroup>
      </Col>
      <Col lg="8">
        <Row>
          <Col lg={{ size: 3, offset: 6 }}>
            <FormGroup>
              <Button style={{ width: '100%' }} type="button" size="sm" disabled>
                CREATE REFERRAL
              </Button>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <GridSettingsModal
                name="recentActivitySetting"
                settingColumns={settingColumns}
                updateCheckbox={updateGridSettingsCheckbox}
                gridSettingName={taskListGridSettingName}
                gridSettingModuleId={taskListGridSettingModuleId}
              ></GridSettingsModal>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

RecentActivityTopNav.propTypes = {
  handleUserDisplayChange: PropTypes.func.isRequired,
  handleUserChange: PropTypes.func.isRequired,
  adjusters: PropTypes.array.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  settingColumns: PropTypes.array.isRequired,
  updateGridSettingsCheckbox: PropTypes.func.isRequired,
};

export default RecentActivityTopNav;
