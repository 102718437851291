export const MODULE_NAME = 'EXPERTS';
export const EXPERT_LOAD_INITIAL_DATA = 'EXPERT_LOAD_INITIAL_DATA';
export const LOAD_TASKS = 'LOAD_TASKS';
export const LOAD_FAX = 'LOAD_FAX';
export const GET_USER_TASKS = 'GET_USER_TASKS'; // return tasks and fax
export const UPDATE_RADIUS_SEARCH_TABLE = 'UPDATE_RADIUS_SEARCH_TABLE';
export const UPDATE_TASK_TABLE = 'UPDATE_TASK_TABLE';
export const UPDATE_EXPERTS_SEARCH_RESULT = 'UPDATE_EXPERTS_SEARCH_RESULT';
export const UPDATE_SELECTED_EXPERT = 'UPDATE_SELECTED_EXPERT';
export const UPDATE_PREVIEW_USER = 'UPDATE_PREVIEW_USER';
export const UPDATE_EXPERT_ACTIVE_TAB = 'UPDATE_EXPERT_ACTIVE_TAB';
export const UPDATE_EXPERT_REPORT_TABLE = 'UPDATE_EXPERT_REPORT_TABLE';
export const UPDATE_CHECK_DUPLICATE_EXPERT_GROUP =
  'UPDATE_CHECK_DUPLICATE_EXPERT_GROUP';
export const UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES =
  'UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES';
export const RESET_NEW_EXPERT_GROUP_VIEW = 'RESET_NEW_EXPERT_GROUP_VIEW';
export const EXPERT_INFO_MODULE = 'EXPERT_INFO_MODULE';
export const UPDATE_CHECK_DUPLICATE_EXPERT = 'UPDATE_CHECK_DUPLICATE_EXPERT';
export const UPDATE_SELECTED_EXPERT_ADDRESSES =
  'UPDATE_SELECTED_EXPERT_ADDRESSES';
export const RESET_NEW_EXPERT_VIEW = 'RESET_NEW_EXPERT_VIEW';
export default {
  MODULE_NAME,
  EXPERT_LOAD_INITIAL_DATA,
  LOAD_TASKS,
  LOAD_FAX,
  GET_USER_TASKS,
  UPDATE_RADIUS_SEARCH_TABLE,
  UPDATE_TASK_TABLE,
  UPDATE_EXPERTS_SEARCH_RESULT,
  UPDATE_SELECTED_EXPERT,
  UPDATE_PREVIEW_USER,
  UPDATE_EXPERT_ACTIVE_TAB,
  UPDATE_EXPERT_REPORT_TABLE,
  UPDATE_CHECK_DUPLICATE_EXPERT_GROUP,
  UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES,
  RESET_NEW_EXPERT_GROUP_VIEW,
  EXPERT_INFO_MODULE,
  UPDATE_CHECK_DUPLICATE_EXPERT,
  UPDATE_SELECTED_EXPERT_ADDRESSES,
  RESET_NEW_EXPERT_VIEW,
};
