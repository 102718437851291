import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTooltip from 'shared/components/tooltip/AppTooltip';

class IconBasedContent extends Component {
  onClickHandler = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item, text, columnId, icon } = this.props;
    return text ? (
      <AppTooltip text={text} id={`${columnId}-${item.id}`}>
        <FontAwesomeIcon
          className="cursor-hover"
          onClick={this.onClickHandler}
          icon={icon}
        />
      </AppTooltip>
    ) : (
      <FontAwesomeIcon
        className="cursor-hover"
        onClick={this.onClickHandler}
        icon={icon}
      />
    );
  }
}

IconBasedContent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  text: PropTypes.string,
  columnId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.any.isRequired,
};

export default IconBasedContent;
