import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  CustomInput,
  InputGroup,
} from 'reactstrap';

const GroupModalBasicInfoTab = ({
  data,
  errors,
  onInputChange,
  onInputMaskChange,
  expertGroupTypes,
}) => (
  <Row>
    <Col lg="12">
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="groupName">Group Name</Label>
                <Input
                  type="text"
                  name="groupName"
                  id="groupName"
                  value={data.groupName}
                  onChange={onInputChange}
                  maxLength="200"
                  invalid={errors.groupName.length > 0}
                />
                {errors.groupName.length > 0 && (
                  <FormFeedback tooltip>{errors.groupName}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <div className="tin-label-group">
                  <Label for="tinNumber">Tin #</Label>
                  <Label for="payableTo">Make Payable to</Label>
                </div>
                <div className="tin-input-group">
                  <InputGroup>
                    <InputMask
                      mask="99-9999999"
                      alwaysShowMask={true}
                      name="tinNumber"
                      id="tinNumber"
                      value={data.tinNumber}
                      onChange={onInputMaskChange}
                      invalid={errors.tinNumber.length > 0}
                    >
                      <Input />
                    </InputMask>
                    {errors.tinNumber.length > 0 && (
                      <FormFeedback tooltip>{errors.tinNumber}</FormFeedback>
                    )}
                    <Input
                      type="text"
                      name="payableTo"
                      id="payableTo"
                      value={data.payableTo}
                      onChange={onInputChange}
                      maxLength="40"
                    />
                  </InputGroup>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="groupType">Group Type</Label>
                <Input
                  type="select"
                  name="groupType"
                  id="groupType"
                  value={data.groupType}
                  onChange={onInputChange}
                >
                  <option key={-1} value="-1" disabled>
                    Choose...
                  </option>
                  {expertGroupTypes.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.element}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label for="npi">Nat. Prov. ID</Label>
                <Input
                  type="text"
                  name="npi"
                  id="npi"
                  value={data.npi}
                  onChange={onInputChange}
                  maxLength="10"
                />
              </FormGroup>
            </Col>
            <Col lg="8">
              <FormGroup>
                <Label for="previousJericho">Previous Jericho ID</Label>
                <Input
                  type="text"
                  name="previousJericho"
                  id="previousJericho"
                  value={data.previousJericho || ''}
                  onChange={onInputChange}
                  maxLength="20"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Label for="website">Website</Label>
            <Input
              type="text"
              name="website"
              id="website"
              value={data.website}
              onChange={onInputChange}
              maxLength="200"
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="underInvestigation"
              id="underInvestigation"
              label="Under Investigation"
              checked={data.underInvestigation}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Input
              type="textarea"
              name="reason"
              id="reason"
              value={data.reason}
              onChange={onInputChange}
              spellCheck={true}
              rows={5}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

GroupModalBasicInfoTab.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  expertGroupTypes: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputMaskChange: PropTypes.func.isRequired,
};

export default GroupModalBasicInfoTab;
