import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isValidDate } from 'shared/utils/shareUtils';

import { billOperationsSelectors } from '../../bill-operations/store';

import { billMaintenanceOperations, billMaintenanceSelectors } from '../store';

import SiteMaintenanceFormMasterClient from './SiteMaintenanceFormMasterClient';
import SiteMaintenanceFormClient from './SiteMaintenanceFormClient';

const USER_ID = '2591'; //TODO: hardcode

class SiteMaintenanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: this.props.siteDetails.parentId || 0,
      inputValue: {
        masterClient: '',
        name: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        clientState: '',
        payerName: '',
        payerAddress1: '',
        payerAddress2: '',
        payerZip: '',
        payerCity: '',
        payerState: '',
        clientTin: '',
        carrierType: '',
        effDate: '',
        termDate: '',
        contact: '',
        phone: '',
        fax: '',
        logo: '',
        naic: '',
        svcCo: 0,
        payerTin: '',
        payerTinExt: 0,
        eorText: '',
        notes: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.activeVale && this.props.activeVal) {
      this.props.siteSaveComp(this.state.inputValue)
    }
    if (this.props.masterSiteDetails !== prevProps.masterSiteDetails) {
      this.setState({
        inputValue: {
          ...this.state.inputValue,
          masterClient: this.props.masterSiteDetails.name || '',
        },
        parentId: this.props.masterSiteDetails.id,
      });
    }

    if (this.props.siteDetails !== prevProps.siteDetails) {
      const name = this.props.siteDetails.name || '';
      const address = (this.props.siteDetails.address || {}).fields || {};
      const address1 = address.address1 || '';
      const address2 = address.address2 || '';
      const zipCode = (address.zipCode || {}).fields || {};
      const zip = zipCode.zip || '';
      const city = zipCode.city || '';
      const stateFields = (zipCode.state || {}).fields || {};
      const clientState = stateFields.abbreviation || '';

      const payerPerson =
        (this.props.siteDetails.payerPerson || {}).fields || {};
      const payerName = payerPerson.last || '';
      const payerAddress =
        (this.props.siteDetails.payerAddress || {}).fields || {};
      const payerAddress1 = payerAddress.address1 || '';
      const payerAddress2 = payerAddress.address2 || '';
      const payerZipCode = (payerAddress.zipCode || {}).fields || {};
      const payerZip = payerZipCode.zip || '';
      const payerCity = payerZipCode.city || '';
      const payerStateFields = (payerZipCode.state || {}).fields || {};
      const payerState = payerStateFields.abbreviation || '';

      const clientTin = this.props.siteDetails.taxId || '';
      const carrierType = this.props.siteDetails.carrierType || '';
      const dateRange = this.props.siteDetails.dateRange || {} || {};
      const effDateObject = new Date(dateRange.effDate || '');
      const effDate = isValidDate(effDateObject) ? effDateObject : '';
      const termDateObject = new Date(dateRange.termDate || '');
      const termDate = isValidDate(termDateObject) ? termDateObject : '';
      const contactFields = (this.props.siteDetails.contact || {}).fields || {};
      const contact = contactFields.contact || '';
      const phone = contactFields.phone1 || '';
      const fax = contactFields.fax || '';
      const logo = this.props.siteDetails.eorProfile || '';
      const naic = this.props.siteDetails.naic || '';
      const svcCo = this.props.siteDetails.serviceCompanyCode || 0;

      const payerTin = this.props.siteDetails.payerTaxId || '';
      const payerTinExt = this.props.siteDetails.payorCode || 0;

      const eorText = this.props.siteDetails.eorText1;
      const notes = this.props.siteDetails.notes;

      this.setState({
        inputValue: {
          ...this.state.inputValue,
          name,
          address1,
          address2,
          zip,
          city,
          clientState,
          payerName,
          payerAddress1,
          payerAddress2,
          payerZip,
          payerCity,
          payerState,
          clientTin,
          carrierType,
          effDate,
          termDate,
          contact,
          phone,
          fax,
          logo,
          naic,
          svcCo,
          payerTin,
          payerTinExt,
          eorText,
          notes,
        },
      });
    }

    if (this.props.stateCity !== prevProps.stateCity) {
      this.setState({
        inputValue: {
          ...this.state.inputValue,
          city: this.props.stateCity.city || '',
          clientState: this.props.stateCity.state || '',
        },
      });
    }

    if (this.props.payerStateCity !== prevProps.payerStateCity) {
      this.setState({
        inputValue: {
          ...this.state.inputValue,
          payerCity: this.props.payerStateCity.city || '',
          payerState: this.props.payerStateCity.state || '',
        },
      });
    }
  }

  onInputChangeHandler = (id, value) => {
    this.setState({
      inputValue: {
        ...this.state.inputValue,
        [id]: value,
      },
    });
  };

  onMasterClientSelect = (_id, item) => {
    this.setState({
      parentId: item.int_sitecode,
      inputValue: {
        ...this.state.inputValue,
        masterClient: item.chr_sitename,
      },
    });
    const { profile } = this.props;
    this.props.loadMasterSiteDetails({
      profile,
      siteId: item.int_sitecode,
      zebraUserId: USER_ID,
    });
  };

  loadMasterClientDataProvider = value => {
    const { profile } = this.props;
    this.props.loadMasterClientDataProvider({
      profile,
      siteName: value,
      excludeEmployers: true,
      zebraUserId: USER_ID,
    });
  };

  loadStateCityByZipCode = event => {
    const { value, name } = event.target;
    if (value.length !== 5) {
      return;
    }

    const { profile } = this.props;
    const data = {
      profile,
      userId: USER_ID,
      zipCode: value,
    };

    if (name === 'zip') {
      this.props.loadStateCityByZipCode(data);
    } else {
      this.props.loadPayerStateCityByZipCode(data);
    }
  };

  render() {
    return (
      <>
        <SiteMaintenanceFormMasterClient
          masterClient={this.state.inputValue.masterClient}
          masterClientDataProvider={this.props.masterClientDataProvider}
          siteDetails={this.props.siteDetails}
          masterSiteDetails={this.props.masterSiteDetails}
          loadMasterClientDataProvider={this.loadMasterClientDataProvider}
          onInputChangeHandler={this.onInputChangeHandler}
          onMasterClientSelect={this.onMasterClientSelect}
          parentId={this.state.parentId}
        />
        <hr />
        <SiteMaintenanceFormClient
          logos={this.props.logos}
          inputValue={this.state.inputValue}
          onInputChangeHandler={this.onInputChangeHandler}
          onZipCodeBlur={this.loadStateCityByZipCode}
        />
      </>
    );
  }
}

SiteMaintenanceForm.propTypes = {
  masterClientDataProvider: PropTypes.array.isRequired,
  siteDetails: PropTypes.object.isRequired,
  masterSiteDetails: PropTypes.object.isRequired,
  activeVal: PropTypes.bool,
  siteSaveComp: PropTypes.func,
  profile: PropTypes.string.isRequired,
  loadMasterClientDataProvider: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const clientMaintenanceSite = billMaintenanceSelectors.getClientMaintenanceSite(
    state
  );
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientComboBoxes = clientMaintenanceState.clientComboBoxes;
  return {
    masterClientDataProvider: clientMaintenanceSite.masterClientDataProvider,
    profile: billReviewInitialData.currentProfile,
    siteDetails: clientMaintenanceState.siteDetails,
    masterSiteDetails: clientMaintenanceSite.masterSiteDetails,
    logos: clientComboBoxes.logoList || [],
    stateCity: clientMaintenanceSite.stateCity,
    payerStateCity: clientMaintenanceSite.payerStateCity,
  };
};

const mapDispatchToProps = dispatch => ({
  loadMasterClientDataProvider: data =>
    dispatch(billMaintenanceOperations.loadMasterClientDataProvider(data)),
  loadMasterSiteDetails: data =>
    dispatch(billMaintenanceOperations.loadMasterSiteDetails(data)),
  loadStateCityByZipCode: data =>
    dispatch(billMaintenanceOperations.loadStateCityByZipCode(data)),
  loadPayerStateCityByZipCode: data =>
    dispatch(billMaintenanceOperations.loadPayerStateCityByZipCode(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteMaintenanceForm);
