import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { fast360Operations } from '../../../modules/fast-360/store';
import { authSelectors } from 'modules/auth/store';
import { fast360Actions } from 'modules/fast-360/store/index';



class ExternalReferralTableBodyRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
            rowIndex: null,
        };
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRows: [] });
    }

    handleDblClick = () => {
        const { TempField, YorkID } = this.props.dataObject;
        this.props.selectedExternalData(this.props.dataObject)
        let postPayload = {
            "functionName": "loadYorkImport",
            "zebraUserId": this.props.currentUserId,
            "referral_id": YorkID,
            "tblName": TempField
        }
        this.props.postExternal(postPayload, this.props.dataObject.svc);

        setTimeout(() => {
            let postPayload = {
                "functionName": "clientLookupByJuris",
                "zebraUserId": this.props.currentUserId,
                "jurisClientId": this.props.externalReferralData.length > 0 && this.props.externalReferralData[0].EmployerClientID
            };
            this.props.postExternalClient(postPayload);
            this.props.openExternal(this.props.dataObject);
            // this.props.toggle();
            this.props.onNewReferral.onNewReferral();
            this.props.openExt(false);
        }, 1000);

    }

    render() {
        const rowIndex = this.props.rowIndex;
        const dataObject = this.props.dataObject;

        return (
            <>
                <tbody>
                    <tr
                        key={rowIndex}
                        style={
                            this.props.highlightedRow === rowIndex
                                ? { backgroundColor: '#cdf0f9' }
                                : null
                        }
                        onDoubleClick={this.handleDblClick}
                    >
                        {this.props.columns.map((column, columnIndex) => {
                            if (column.header == 'Service') {
                                return (<td >
                                    {dataObject.TempField || ''}
                                </td>)
                            }
                            if (column.header == 'Client') {
                                return (<td>
                                    {dataObject.sedgwickReferralId > 0 ? 'Sedgwick' : 'York'}
                                </td>)
                            }
                            if (column.header == 'Date Received') {
                                return (<td>
                                    {dataObject.DateReceived || ''}
                                </td>)
                            }
                            if (column.header == 'Date of Service') {
                                return (<td>
                                    {dataObject.DateOfService || ''}
                                </td>)
                            }
                            if (column.header == 'Service Type') {
                                return (<td>
                                    {dataObject.ServiceType || ''}
                                </td>)
                            }
                            if (column.header == 'Patient Name') {
                                return (<td>
                                    {`${dataObject.PatientLastName},${dataObject.PatientFirstName}` || ''}
                                </td>)
                            }
                            if (column.header == 'Patient City/State') {
                                return (<td>
                                    {`${dataObject.PatientCity},${dataObject.PatientState}` || ''}
                                </td>)
                            }
                            if (column.header == 'Adjuster Name') {
                                return (<td>
                                    {dataObject.AdjusterName || ''}
                                </td>)
                            }
                            if (column.header == 'Auth Code') {
                                return (<td>
                                    {dataObject.UniquePortalAuthCode || ''}
                                </td>)
                            }
                            if (column.header == 'File Name') {
                                return (<td>
                                    {dataObject.FileName || ''}
                                </td>)
                            }




                        })}
                    </tr>
                </tbody >
            </>
        );
    }
}
const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        externalReferralData: state.FAST360.fast360Reducer.updatePostExternal ? state.FAST360.fast360Reducer.updatePostExternal : [],
    };
};
const mapDispatchToProps = dispatch => ({
    postExternal: (data, mod) => dispatch(fast360Operations.postExternal(data, mod)),
    postExternalClient: (data) => dispatch(fast360Operations.postExternalClient(data)),
    selectedExternalData: (data) => dispatch(fast360Actions.selectedExternalData(data))
});


ExternalReferralTableBodyRow.propTypes = AppTableBodyPropType;

export default connect(mapStateToProps, mapDispatchToProps)(ExternalReferralTableBodyRow);