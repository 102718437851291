const setNullValues = (object, value = undefined) => {
    if (Array.isArray(object)) {
        return object.map(arrayValue => setNullValues(arrayValue, value))
    } else if (object === null) {
        return value;
    } else if (typeof object === 'object') {
        const newObject = {...object};

        Object.keys(newObject).forEach(key => {
            newObject[key] = setNullValues(newObject[key], value)
        });

        return newObject
    } else {
        return object;
    }
}

export default {setNullValues}
