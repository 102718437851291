import React, { useContext, useEffect } from 'react';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';
import { UpdateTypes, selectAll} from './utils/helper';
import AppDateInput from 'shared/components/input/AppDateInput';
import { Row, Col, FormGroup, Label, TabPane, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

function updateAdvSearchForm(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_AdvSearch_Form',
        payload: { [key]: value },
    });
}

function updateCheckBox(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_CheckBox',
        payload: { [key]: value },
    });
}

const Accommodation = ({ accommodationType, appointmentType, useStoredData }) => {

    const { state, dispatch } = useContext(AdvSearchContext);
    const { state1, dispatch1 } = useContext(CheckBoxContext);
    const { accommodationTypes } = state;

    useEffect(()=> {
        if(!useStoredData){
            selectAll(true, accommodationType, dispatch, 'accommodationTypes')
        }
    },[accommodationType])

    return (
        <TabPane tabId="2" style={{ border: "1px solid #dee2e6", padding: "15px", overflow: "auto" }}>
            <FormGroup>
                <div style={{ float: "left", width: "60%" }}>
                    <Row xs={2} md={5}>
                        <Col>
                            <FormGroup>
                                <Label for="exampleDate">Check in Date/Time: </Label>
                                <AppDateInput
                                onChange={value => updateAdvSearchForm(value, 'accommodationCheckInDate', dispatch)}
                                selected={state.accommodationCheckInDate}
                                value={state?.accommodationCheckInDate || ''}
                                dateFormat="MM/dd/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Hotel Contact:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationHotelContact}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationHotelContact', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Total Cost From:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationTotalCostFrom}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationTotalCostFrom', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Billed Charges from:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationCheckInDate}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationCheckInDate', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Margin From:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationOrderMarginFrom}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationOrderMarginFrom', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs={2} md={5}>
                        <Col>
                            <FormGroup>
                                <Label for="exampleDate">Check out Date/Time: </Label>
                                <AppDateInput
                                onChange={value => updateAdvSearchForm(value, 'accommodationCheckOutDate', dispatch)}
                                selected={state.accommodationCheckOutDate}
                                value={state?.accommodationCheckOutDate || ''}
                                dateFormat="MM/dd/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label> # of Rooms:</Label>
                                <Input
                                    type="number"
                                    min="0"
                                    value={state.accommodationNumberOfRooms}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationNumberOfRooms', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Total Cost to:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationTotalCostTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationTotalCostTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Billed Charges To:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationTotalBilledChargesTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationTotalBilledChargesTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Margin To:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationOrderMarginTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationOrderMarginTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs={2} md={5}>
                        <Col>
                            <FormGroup>
                                <Label for="exampleDate">Date/Time Created: </Label>
                                <AppDateInput
                                onChange={value => updateAdvSearchForm(value, 'accommodationDateTimeCreated', dispatch)}
                                selected={state.accommodationDateTimeCreated}
                                value={state?.accommodationDateTimeCreated || ''}
                                dateFormat="MM/dd/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label> Booking Site:</Label>
                                <Input
                                    type="number"
                                    value={state.accommodationBookingSite}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'accommodationBookingSite', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs={2} md={5}>
                        <Col>
                            <FormGroup>
                                <Label for="exampleDate">Date/Time Booked: </Label>
                                <AppDateInput
                                onChange={value => updateAdvSearchForm(value, 'accommodationDateTimeBooked', dispatch)}
                                selected={state.accommodationDateTimeBooked}
                                value={state?.accommodationDateTimeBooked || ''}
                                dateFormat="MM/dd/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Label>Appt Type: </Label>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                {state.accommodationApptTypeUI?state.accommodationApptTypeUI:'Choose'}
                                </DropdownToggle>
                                <DropdownMenu style={{overflowY:"scroll", height: "150px"}}  >
                                    {appointmentType && appointmentType.map((appointmentType, idx) => {
                                        return (
                                            <DropdownItem key={idx} onClick={(e)=>updateAdvSearchForm(e.currentTarget.textContent,'accommodationApptTypeUI', dispatch)}>
                                                {appointmentType.value}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Label>Select the fields to display on the report</Label>
                    </Row>
                    <Row xs={2} md={5}>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationCheckInDateTime}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationCheckInDateTime', dispatch1)}
                                    />
                                     Check in Date/Time
                                    </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationCheckOutDateTime}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationCheckOutDateTime', dispatch1)}
                                    />
                                    Check out Date/Time
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationNumberOfNights}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationNumberOfNights', dispatch1)}
                                    />
                                     Number of Nights
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationHotelAddress}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationHotelAddress', dispatch1)}
                                    />
                                     Hotel Address
                                    </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationNumberOfRooms}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationNumberOfRooms', dispatch1)}
                                    />
                                     Number of Rooms
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationDateTimeCreated}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationDateTimeCreated', dispatch1)}
                                    />
                                     Date/Time Created
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationDateTimeBooked}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationDateTimeBooked', dispatch1)}
                                    />
                                    Date/Time Booked
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationBookingSite}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationBookingSite', dispatch1)}
                                    />
                                     Booking Site
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayAccommodationHotelContact}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayAccommodationHotelContact', dispatch1)}
                                    />
                                     Hotel Contact
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Types:</Label>
                            <FormGroup check>
                                <Label check>
                                    <Input 
                                     checked={accommodationType &&  (accommodationType.length === accommodationTypes.length)}
                                    type="checkbox" 
                                    onClick={(e)=> selectAll(e.target.checked, accommodationType, dispatch, 'accommodationTypes')}
                                    /> Select All:
                            </Label>
                            </FormGroup>
                            <div style={{ border: "1px solid black", height: "150px", width: "210px", padding: "10px", overflowY: "scroll" }}>
                                {accommodationType && accommodationType.map((element, idx) => {
                                    return (
                                        <FormGroup key={idx} check>
                                            <Label check>
                                                <Input 
                                                type="checkbox" 
                                                checked={accommodationTypes.includes(element.id)}
                                                onClick={(e) => UpdateTypes(e.target.checked, element.id, accommodationTypes, dispatch, 'accommodationTypes')}
                                                />
                                                {element.value}
                                            </Label>
                                        </FormGroup>
                                    );
                                })}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </FormGroup>
        </TabPane>
    )
}

export default Accommodation