import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import { moduleName } from './constants';
import reducer from './reducers';

reducerRegistry.register(moduleName, reducer);

export { default as commonConstants } from './constants';
export { default as commonSelectors } from './selectors';
export { default as commonActions } from './actions';
export { default as commonOperations } from './operations';

export default reducer;
