import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import PopupModal from 'shared/components/PopupModal';

const PasswordResetModal = ({ isOpen, onToggle, newUsername, setLoginState, setPassword }) => {
  const { resetPasswordResult } = useAuth();
  const onSaveHandler = () => {
    onToggle();
    if( resetPasswordResult == 1){
      setLoginState('login')
      setPassword('');
    }   
  }

  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
          {newUsername != '' && resetPasswordResult == 1 &&
              <span>
                A temporary password has been emailed to you. You should receive
                it shortly.
                <br />
                <br />
                <Button
                  outline
                  color="primary"
                  style={{ marginLeft: '200px', marginRight: '200px' }}
                  onClick={()=>onSaveHandler()}
                >
                  Ok{' '}
                </Button>
              </span>
            }
            {newUsername != '' && resetPasswordResult === 2 &&
              <span> No email found
                <br />
                <br />
                <Button
                  outline
                  color="primary"
                  style={{ marginLeft: '200px', marginRight: '200px' }}
                  onClick={() => onSaveHandler()}
                >
                  Ok{' '}
                </Button>
              </span>
            }
            {newUsername != '' && resetPasswordResult == 3 &&
              <span> Failed to send email
                <br />
                <br />
                <Button
                  outline
                  color="primary"
                  style={{ marginLeft: '200px', marginRight: '200px' }}
                  onClick={() => onSaveHandler()}
                >
                  Ok{' '}
                </Button>
              </span>
            }
            
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <PopupModal
      content={getContent()}
      externalToggle={onToggle}
      title={resetPasswordResult === 2 ? "Password Reset" : "Login"}
      isOpen={isOpen}
      size="md"
    />
  );
};

export default PasswordResetModal;
