import actionCreatorUtils from 'core/utils/actionCreatorUtils';
import constants from './constants';

import {
  SWITCH_VIEW,
  UPDATE_APPROVE_BILL_TABLE,
  UPDATE_APPROVE_BILL_INITIAL_DATA,
  UPDATE_SELECTED_APPROVE_BILLS,
  UPDATE_APPROVE_BILL_REVIEW_TYPE,
  UPDATE_APPROVE_BILL_SELECTED_ADJUSTER,
  UPDATE_APPROVE_BILL_GROUP_BY,
  UPDATE_QA_BILL_TABLE,
  UPDATE_SELECTED_QA_BILLS,
  UPDATE_QA_BILL_GROUP_BY,
  UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER,
  UPDATE_FIND_BILLS_TABLE,
  UPDATE_SELECTED_BILLS_FROM_CLAIM,
  LOAD_FIND_BILLS_GRID_SETTINGS,
  UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX,
  LOAD_BILL_REVIEW_DATA,
  UPDATE_SEND_EDI_SETUP_DATA,
  RESET_SEND_EDI_SETUP_DATA,
  UPDATE_ODG_LOOKUP_DATA,
  UPDATE_BILL_LINES_DATA,
  UPDATE_POST_BILL_LINES_DATA,
  UPDATE_BILL_TOTAL_DATA,
  RESET_BILL_TOTAL_DATA,
  RESET_BILL_LINES_DATA,
  UPDATE_BILL_LINES_DROPDOWN_DATA,
  UPDATE_BILL_DETAIL_DROPDOWN_DATA,
  RESET_BILL_DETAIL_DROPDOWN_DATA,
  UPDATE_PRINT_EOR_DATA,
  UPDATE_PRINT_REPORTS_DATA,
  UPDATE_CLAIM_LIST_DATA,
  RESET_CLAIM_LIST_DATA,
  UPDATE_EMPLOYER_LIST_DATA,
  RESET_EMPLOYER_LIST_DATA,
  UPDATE_PROVIDER_LIST_DATA,
  RESET_PROVIDER_LIST_DATA,
  UPDATE_CLAIMANT_LIST_DATA,
  RESET_CLAIMANT_LIST_DATA,
  UPDATE_FEE_LOOKUP_NPI_DATA,
  RESET_FEE_LOOKUP_NPI_DATA,
  UPDATE_FEE_LOOKUP_DATA,
  UPDATE_FEE_LOOKUP_DATA_UC,
  UPDATE_FEE_LOOKUP_DATA_SSD,
  UPDATE_FEE_LOOKUP_DATA_UHUC,
  UPDATE_FEE_LOOKUP_DATA_BY_ZIP,
  UPDATE_FEE_LOOKUP_DATA_MED,
  RESET_FEE_LOOKUP_DATA,
  UPDATE_PRINT_EOR_DROPDOWN_USER_DATA,
  RESET_PRINT_EOR_DROPDOWN_USER_DATA,
  RESET_FEE_LOOKUP_DATA_UC,
  RESET_FEE_LOOKUP_DATA_SSD,
  RESET_FEE_LOOKUP_DATA_UHUC,
  RESET_FEE_LOOKUP_DATA_BY_ZIP,
  RESET_FEE_LOOKUP_DATA_MED,
  RESET_PRINT_EOR_DATA,
  RESET_PRINT_REPORTS_DATA,
  LOAD_TASK_LIST_GRID_SETTINGS,
  UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX,
  RESET_ODG_LOOKUP_DATA,
  UPDATE_SEARCH_PPO_DATA,
  UPDATE_DELETE_BILL_LINES_DATA,
  RESET_SEARCH_PPO_DATA,
  UPDATE_CLIENTS_PPO_DATA,
  RESET_CLIENTS_PPO_DATA,
  UPDATE_PROFILE_DRODOWN_DATA,
  RESET_PROFILE_DRODOWN_DATA,
  UPDATE_START_DATE_DATA,
  RESET_START_DATE_DATA,
  UPDATE_SITE_OPTIONS_DATA,
  RESET_SITE_OPTIONS_DATA,
  UPDATE_CASH_APP_DATA,
  RESET_CASH_APP_DATA,
  UPDATE_MANAGE_BILL_DATA,
  RESET_MANAGE_BILL_DATA,
  UPDATE_BILLS_FROM_CLAIM,
  UPDATE_CLAIM_FROM_TASK_LIST,
  UPDATE_CLAIM_DATA,
  UPDATE_UR_DECISION_TABLE,
  UPDATE_RECENT_ACTIVITY_INITIAL_DATA,
  UPDATE_RECENT_ACTIVITY_TABLE,
  UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER,
  UPDATE_SEARCH_FIELDS,
  UPDATE_REFERRAL_DOCUMENTS,
  RESET_REFERRAL_DOCUMENTS,
  UPDATE_RETRIEVE_REFERRAL_DOCUMENT,
  RESET_RETRIEVE_REFERRAL_DOCUMENT,
  UPDATE_SELECTED_DEMAND_BILLS,
  UPDATE_DEMAND_DOCUMENTS,
  UPDATE_RETRIVE_DEMAND_DOCUMENT,
  RESET_RETRIVE_DEMAND_DOCUMENT,
  UPDATE_REFERRAL_EMAIL_TEMPLATES,
  RESET_REFERRAL,
  UPDATE_REFERRAL_SPECIALTIES,
  SET_DENY_REASON,
  // SET_DENY_REASON2,
  RESET_DENY_REASON,
  SET_MCNS_EDIT_VAL,
  RESET_MCNS_EDIT_VAL,
  SET_REMB_TOTAL,
  RESET_REMB_TOTAL,
  REMOVE_REMB_TOTAL,
  SET_BILL_LINES_DATA,
  UPDATE_STATUS_HISTORY_DATA,
  RESET_STATUS_HISTORY_DATA,
  UPDATE_STATUS_LIST_DATA,
  RESET_STATUS_LIST_DATA,
  UPDATE_UNPEND_DATA,
  RESET_UNPEND_DATA,
  RESET_STORE_STATE,
  UPDATE_CURRENT_PROFILE,
  UPDATE_DEFAULT_PROFILE,
  UPDATE_POST_MCNS_BILLS_DATA,
  RESET_POST_MCNS_BILLS_DATA,
  SET_BILL_REVIEW_REV_EDITVAL,
  RESET_BILL_REVIEW_REV_EDITVAL,
  UPDATE_POST_BILL_REVIEW_DATA,
  RESET_POST_BILL_REVIEW_DATA,
  UPDATE_FINDBILLS_FROM_TABS,
  BILLS_FROM_TASK_LIST_CLAIM,
  UPDATE_FIND_BILLS_FROM_RECENT,
  UPDATE_SAVE_PAY_CODE_DATA,
  UPDATE_AOREMAINING_INFO_DATA,
  UPDATE_POST_BILL_REVIEW_AO_DATA,
  RESET_POST_BILL_REVIEW_AO_DATA,
  LOAD_MORE_INFO,
  UNPEND_BILL,
  UPDATE_BILLCLAIM_NOTES,
  UPDATE_NEW_MED,
  UPDATE_CORRECTION_REASONS,
  RESET_TASK_LIST,
} from './constants';

const switchView = view => {
  return {
    type: SWITCH_VIEW,
    payload: { currentView: view },
  };
};

const updateFindBillsTable = payload => {
  return actionCreatorUtils.create(UPDATE_FIND_BILLS_TABLE, payload);
};

const getFindBillsGridSettings = payload => {
  return actionCreatorUtils.create(LOAD_FIND_BILLS_GRID_SETTINGS, payload);
};

const updateFindBillsGridSettingsCheckbox = payload => {
  return actionCreatorUtils.create(
    UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX,
    payload
  );
};

const updateSelectedBillsFromClaim = data => {
  return {
    type: UPDATE_SELECTED_BILLS_FROM_CLAIM,
    payload: data,
  };
};

const updateURDecisionData = payload => {
  return actionCreatorUtils.create(UPDATE_UR_DECISION_TABLE, payload);
};

const updateBillsFromClaim = payload => {
  return actionCreatorUtils.create(UPDATE_BILLS_FROM_CLAIM, payload);
};

const updateClaimFromTaskList = payload => {
  return actionCreatorUtils.create(UPDATE_CLAIM_FROM_TASK_LIST, payload);
};

const updateBillsFromTaskListClaim = payload => {
  return actionCreatorUtils.create(BILLS_FROM_TASK_LIST_CLAIM, payload);
};

const loadBillReviewData = payload => {
  return actionCreatorUtils.create(LOAD_BILL_REVIEW_DATA, payload);
};

const loadMoreInfo = data => {
  return {
    type: LOAD_MORE_INFO,
    payload: data,
  };
};

const unpendCodeOnBill = data => {
  return {
    type: UNPEND_BILL,
    payload: data,
  };
};

const updateClaimData = payload => {
  return actionCreatorUtils.create(UPDATE_CLAIM_DATA, payload);
};
const updateFindBillsFromTabs = data => {
  return {
    type: UPDATE_FINDBILLS_FROM_TABS,
    payload: data,
  };
};
const updateFindBillsFromRecentActivity = data => {
  return {
    type: UPDATE_FIND_BILLS_FROM_RECENT,
    payload: data,
  };
};

const updateApproveBillInitialData = data => {
  return {
    type: UPDATE_APPROVE_BILL_INITIAL_DATA,
    payload: data,
  };
};

const updateApproveBillTable = data => {
  return {
    type: UPDATE_APPROVE_BILL_TABLE,
    payload: data,
  };
};

const updateAORemainingTable = data => {
  return {
    type: UPDATE_AOREMAINING_INFO_DATA,
    payload: data,
  };
};

const updateSelectedApproveBills = data => {
  return {
    type: UPDATE_SELECTED_APPROVE_BILLS,
    payload: data,
  };
};

const updateApproveBillReviewType = data => {
  return {
    type: UPDATE_APPROVE_BILL_REVIEW_TYPE,
    payload: data,
  };
};

const updateApproveBillSelectedAdjuster = data => {
  return {
    type: UPDATE_APPROVE_BILL_SELECTED_ADJUSTER,
    payload: data,
  };
};

const updateApproveBillGroupBy = data => {
  return {
    type: UPDATE_APPROVE_BILL_GROUP_BY,
    payload: data,
  };
};

const updateSendEDIData = data => {
  return {
    type: UPDATE_SEND_EDI_SETUP_DATA,
    payload: data,
  };
};

const resetSendEDIData = data => {
  return {
    type: RESET_SEND_EDI_SETUP_DATA,
  };
};

const updateOdgLookupData = data => {
  return {
    type: UPDATE_ODG_LOOKUP_DATA,
    payload: data,
  };
};
const resetOdgLookupData = () => {
  return {
    type: RESET_ODG_LOOKUP_DATA,
  };
};
const updateBillLinesData = data => {
  return {
    type: UPDATE_BILL_LINES_DATA,
    payload: data,
  };
};
const updatepostMcnsBills = data => {
  return {
    type: UPDATE_POST_MCNS_BILLS_DATA,
    payload: data,
  };
};
const updatePostBillReviewData = data => {
  return {
    type: UPDATE_POST_BILL_REVIEW_DATA,
    payload: data,
  };
};

const resetBillReviewData = () => {
  return {
    type: RESET_POST_BILL_REVIEW_DATA,
  };
};
const updatePostBillReviewAoData = data => {
  return {
    type: UPDATE_POST_BILL_REVIEW_AO_DATA,
    payload: data,
  };
};

const resetPostBillReviewAoData = () => {
  return {
    type: RESET_POST_BILL_REVIEW_AO_DATA,
  };
};

const resetpostMcnsBills = () => {
  return {
    type: RESET_POST_MCNS_BILLS_DATA,
  };
};

const resetBillLinesData = () => {
  return {
    type: RESET_BILL_LINES_DATA,
  };
};

const updatePostBillLinesData = data => {
  return {
    type: UPDATE_POST_BILL_LINES_DATA,
    payload: data,
  };
};

const updateBillTotalData = data => {
  return {
    type: UPDATE_BILL_TOTAL_DATA,
    payload: data,
  };
};

const resetBillTotalData = () => {
  return {
    type: UPDATE_BILL_TOTAL_DATA,
  };
};

const updateBillDetailDropdownData = data => {
  return {
    type: UPDATE_BILL_DETAIL_DROPDOWN_DATA,
    payload: data,
  };
};

const resetBillDetailDropdownData = () => {
  return {
    type: RESET_BILL_DETAIL_DROPDOWN_DATA,
  };
};

const setDenyReason = data => {
  return {
    type: SET_DENY_REASON,
    data: data,
  };
};

// const setDenyReason2 = data => {
//   return {
//     type: SET_DENY_REASON2,
//     data: data,
//   };
// };

const setMcnsEditVal = data => {
  return {
    type: SET_MCNS_EDIT_VAL,
    data: data,
  };
};

const setBillRevEditVal = data => {
  return {
    type: SET_BILL_REVIEW_REV_EDITVAL,
    data: data,
  };
};

const resetBillRevEditVal = () => {
  return {
    type: RESET_BILL_REVIEW_REV_EDITVAL,
  };
};

const resetMcnsEditVal = () => {
  return {
    type: RESET_MCNS_EDIT_VAL,
  };
};

const resetDenyReason = () => {
  return {
    type: RESET_DENY_REASON,
  };
};

const setRembTotal = data => {
  return {
    type: SET_REMB_TOTAL,
    data: data,
  };
};

const resetRembTotal = () => {
  return {
    type: RESET_REMB_TOTAL,
  };
};

const removeRembTotal = data => {
  return {
    type: REMOVE_REMB_TOTAL,
    data: data,
  };
};

const updatePrintEORData = data => {
  return {
    type: UPDATE_PRINT_EOR_DATA,
    payload: data,
  };
};

const resetPrintEORData = () => {
  return {
    type: RESET_PRINT_EOR_DATA,
  };
};

const updatePrintReportsData = data => {
  return {
    type: UPDATE_PRINT_REPORTS_DATA,
    payload: data,
  };
};

const resetPrintReportsData = () => {
  return {
    type: RESET_PRINT_REPORTS_DATA,
  };
};

const updateClaimList = data => {
  return {
    type: UPDATE_CLAIM_LIST_DATA,
    payload: data,
  };
};

const resetClaimList = () => {
  return {
    type: RESET_CLAIM_LIST_DATA,
  };
};

const updateEmployerList = data => {
  return {
    type: UPDATE_EMPLOYER_LIST_DATA,
    payload: data,
  };
};

const resetEmployerList = () => {
  return {
    type: RESET_EMPLOYER_LIST_DATA,
  };
};

const resetStoreState = () => {
  return {
    type: RESET_STORE_STATE,
  };
};

const updateProviderList = data => {
  return {
    type: UPDATE_PROVIDER_LIST_DATA,
    payload: data,
  };
};

const resetProviderList = () => {
  return {
    type: RESET_PROVIDER_LIST_DATA,
  };
};

const updateClaimantList = data => {
  return {
    type: UPDATE_CLAIMANT_LIST_DATA,
    payload: data,
  };
};

const resetClaimantList = () => {
  return {
    type: RESET_CLAIMANT_LIST_DATA,
  };
};

const updateProfileDrodownData = data => {
  return {
    type: UPDATE_PROFILE_DRODOWN_DATA,
    payload: data,
  };
};

const resetProfileDrodownData = () => {
  return {
    type: RESET_PROFILE_DRODOWN_DATA,
  };
};

const updateSearchPPOData = data => {
  return {
    type: UPDATE_SEARCH_PPO_DATA,
    payload: data,
  };
};
const updateDeleteBillLines = data => {
  return {
    type: UPDATE_DELETE_BILL_LINES_DATA,
    payload: data,
  };
};

const setBillLinesData = data => {
  return {
    type: SET_BILL_LINES_DATA,
    payload: data,
  };
};
const updateBillLinesDropDownData = data => {
  return {
    type: UPDATE_BILL_LINES_DROPDOWN_DATA,
    payload: data,
  };
};

const updateStartDateData = data => {
  return {
    type: UPDATE_START_DATE_DATA,
    payload: data,
  };
};

const resetStartDateData = () => {
  return {
    type: RESET_START_DATE_DATA,
  };
};

const updateSiteOptionsData = data => {
  return {
    type: UPDATE_SITE_OPTIONS_DATA,
    payload: data,
  };
};

const resetSiteOptionsData = () => {
  return {
    type: RESET_SITE_OPTIONS_DATA,
  };
};

const updateCashAppData = data => {
  return {
    type: UPDATE_CASH_APP_DATA,
    payload: data,
  };
};

const resetCashAppData = () => {
  return {
    type: RESET_CASH_APP_DATA,
  };
};

const updateCurrentProfile = data => {
  return {
    type: UPDATE_CURRENT_PROFILE,
    payload: data,
  };
};

const updateLookupfeescheduleNPIData = data => {
  return {
    type: UPDATE_FEE_LOOKUP_NPI_DATA,
    payload: data,
  };
};

const resetLookupfeescheduleNPIData = () => {
  return {
    type: RESET_FEE_LOOKUP_NPI_DATA,
  };
}

const updateFeeLookupData = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA,
    payload: data,
  };
};

const resetFeeLookupData = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA,
  };
};

const updateFeeLookupDataUC = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA_UC,
    payload: data,
  };
};

const resetFeeLookupDataUC = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA_UC,
  };
};

const updateFeeLookupDataSsDesc = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA_SSD,
    payload: data,
  };
};

const resetSearchPPOData = () => {
  return {
    type: RESET_SEARCH_PPO_DATA,
  };
};

const updateClientsPPOData = data => {
  return {
    type: UPDATE_CLIENTS_PPO_DATA,
    payload: data,
  };
};

const updateAddManageBillData = data => {
  return {
    type: UPDATE_MANAGE_BILL_DATA,
    payload: data,
  };
};

const resetAddManageBillData = () => {
  return {
    type: RESET_MANAGE_BILL_DATA,
  };
};

const resetFeeLookupDataSsDesc = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA_SSD,
  };
};

const updateFeeLookupDataUHUC = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA_UHUC,
    payload: data,
  };
};

const resetClientsPPOData = () => {
  return {
    type: RESET_CLIENTS_PPO_DATA,
  };
};
const resetFeeLookupDataUHUC = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA_UHUC,
  };
};
const updateFeeLookupDataByZip = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA_BY_ZIP,
    payload: data,
  };
};

const resetFeeLookupDataByZip = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA_BY_ZIP,
  };
};
const updateFeeLookupDataMed = data => {
  return {
    type: UPDATE_FEE_LOOKUP_DATA_MED,
    payload: data,
  };
};
const updateNewMed = data => {
  return {
    type: UPDATE_NEW_MED,
    payload: data,
  };
};


const resetFeeLookupDataMed = () => {
  return {
    type: RESET_FEE_LOOKUP_DATA_MED,
  };
};

const updatePrintEORDropdownUserData = data => {
  return {
    type: UPDATE_PRINT_EOR_DROPDOWN_USER_DATA,
    payload: data,
  };
};

const resetPrintEORDropdownUserData = () => {
  return {
    type: RESET_PRINT_EOR_DROPDOWN_USER_DATA,
  };
};

const updateQABillTable = data => {
  return {
    type: UPDATE_QA_BILL_TABLE,
    payload: data,
  };
};

const updateQABillMasterClientDataProvider = data => {
  return {
    type: UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER,
    payload: data,
  };
};

const updateSelectedQABills = data => {
  return {
    type: UPDATE_SELECTED_QA_BILLS,
    payload: data,
  };
};

const updateQABillGroupBy = groupBy => {
  return {
    type: UPDATE_QA_BILL_GROUP_BY,
    payload: groupBy,
  };
};

const getTaskListGridSettings = data => {
  return {
    type: LOAD_TASK_LIST_GRID_SETTINGS,
    payload: data,
  };
};

const updateTaskListGridSettingsCheckbox = data => {
  return {
    type: UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX,
    payload: data,
  };
};

const updateRecentActivityInitialData = data => {
  return {
    type: UPDATE_RECENT_ACTIVITY_INITIAL_DATA,
    payload: data,
  };
};

const updateRecentActivityTable = data => {
  return {
    type: UPDATE_RECENT_ACTIVITY_TABLE,
    payload: data,
  };
};

const updateRecentActivitySelectedAdjuster = data => {
  return {
    type: UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER,
    payload: data,
  };
};

const updateReferralDocuments = data => {
  return {
    type: UPDATE_REFERRAL_DOCUMENTS,
    payload: data,
  };
};

const resetReferralDocuments = () => {
  return {
    type: RESET_REFERRAL_DOCUMENTS
  };
};

const updateRetrieveReferralDocument = data => {
  return {
    type: UPDATE_RETRIEVE_REFERRAL_DOCUMENT,
    payload: data,
  };
};

const resetRetrieveReferralDocument = () => {
  return {
    type: RESET_RETRIEVE_REFERRAL_DOCUMENT,
  };
};

const updateSelectedDemandBills = data => {
  return {
    type: UPDATE_SELECTED_DEMAND_BILLS,
    payload: data,
  };
};

const updateDemandDocuments = data => {
  return {
    type: UPDATE_DEMAND_DOCUMENTS,
    payload: data,
  };
};

const updateRetriveDemandDocument = data => {
  return {
    type: UPDATE_RETRIVE_DEMAND_DOCUMENT,
    payload: data,
  };
};

const resetRetriveDemandDocument = data => {
  return {
    type: RESET_RETRIVE_DEMAND_DOCUMENT,
  };
};

const updateReferralEmailTemplates = data => {
  return {
    type: UPDATE_REFERRAL_EMAIL_TEMPLATES,
    payload: data,
  };
};

const updateReferralSpecialties = data => {
  return {
    type: UPDATE_REFERRAL_SPECIALTIES,
    payload: data,
  };
};

const UpdateBillClaimNotes = data => {
  return {
    type: UPDATE_BILLCLAIM_NOTES,
    payload: data.data,
  };
};

const resetReferral = () => {
  return {
    type: RESET_REFERRAL,
  };
};

const updateSearchFields = data => {
  return {
    type: UPDATE_SEARCH_FIELDS,
    payload: data,
  };
};

const updateStatusHistoryData = data => {
  return {
    type: UPDATE_STATUS_HISTORY_DATA,
    payload: data,
  };
};

const updateSavePayCodeData = data => {
  return {
    type: UPDATE_SAVE_PAY_CODE_DATA,
    //payload: data,
  };
};

const resetStatusHistoryData = () => {
  return {
    type: RESET_STATUS_HISTORY_DATA,
  };
};

const moreInfoData = () => {
  return {
    type: RESET_STATUS_HISTORY_DATA,
  };
};

const updateStatusListData = data => {
  return {
    type: UPDATE_STATUS_LIST_DATA,
    payload: data,
  };
};

const resetStatusListData = () => {
  return {
    type: RESET_STATUS_LIST_DATA,
  };
};

const updateUnPendData = data => {
  return {
    type: UPDATE_UNPEND_DATA,
    payload: data,
  };
};

const resetUnPendData = () => {
  return {
    type: RESET_UNPEND_DATA,
  };
};

const addAlert = (id, message, level = constants.DEFAULT_ALERT_LEVEL) => {
  return actionCreatorUtils.create(constants.ADD_APP_ALERT, {
    id,
    message,
    level,
  });
};

const updateCorrectionReasons = data => {
  return {
    type: UPDATE_CORRECTION_REASONS,
    payload: data,
  };
};

const resetTaskList = data => {
  return {
    type: RESET_TASK_LIST,
    payload: data,
  }
}

export default {
  resetStoreState,
  switchView,
  updateApproveBillTable,
  updateApproveBillInitialData,
  updateSelectedApproveBills,
  updateApproveBillReviewType,
  updateApproveBillSelectedAdjuster,
  updateApproveBillGroupBy,
  updateQABillTable,
  updateSelectedQABills,
  updateQABillGroupBy,
  updateQABillMasterClientDataProvider,
  updateFindBillsTable,
  updateClaimFromTaskList,
  getFindBillsGridSettings,
  updateFindBillsGridSettingsCheckbox,
  updateSelectedBillsFromClaim,
  updateURDecisionData,
  updateBillsFromClaim,
  updateBillsFromTaskListClaim,
  updateClaimData,
  loadBillReviewData,
  loadMoreInfo,
  updateSendEDIData,
  resetSendEDIData,
  updateOdgLookupData,
  updatePostBillLinesData,
  updateBillTotalData,
  resetBillTotalData,
  resetBillLinesData,
  updateBillLinesDropDownData,
  updateBillDetailDropdownData,
  resetBillDetailDropdownData,
  updateLookupfeescheduleNPIData,
  resetLookupfeescheduleNPIData,
  updateFeeLookupData,
  updateFeeLookupDataUC,
  updateFeeLookupDataSsDesc,
  updateFeeLookupDataUHUC,
  updateFeeLookupDataByZip,
  updateFeeLookupDataMed,
  updatePrintEORDropdownUserData,
  resetPrintEORDropdownUserData,
  resetOdgLookupData,
  updateSearchPPOData,
  updateStartDateData,
  resetStartDateData,
  updateSiteOptionsData,
  resetSiteOptionsData,
  updateCashAppData,
  resetCashAppData,
  updateCurrentProfile,
  updateDeleteBillLines,
  setBillLinesData,
  updatePrintEORData,
  resetPrintEORData,
  updatePrintReportsData,
  resetPrintReportsData,
  updateClaimList,
  resetClaimList,
  updateEmployerList,
  resetEmployerList,
  updateProviderList,
  resetProviderList,
  updateClaimantList,
  resetClaimantList,
  updateProfileDrodownData,
  resetProfileDrodownData,
  resetSearchPPOData,
  updateClientsPPOData,
  resetClientsPPOData,
  updateAddManageBillData,
  resetAddManageBillData,
  resetFeeLookupData,
  resetFeeLookupDataUC,
  resetFeeLookupDataSsDesc,
  resetFeeLookupDataUHUC,
  resetFeeLookupDataByZip,
  resetFeeLookupDataMed,
  getTaskListGridSettings,
  updateTaskListGridSettingsCheckbox,
  updateRecentActivityInitialData,
  updateRecentActivityTable,
  updateRecentActivitySelectedAdjuster,
  updateSearchFields,
  updateReferralDocuments,
  resetReferralDocuments,
  updateRetrieveReferralDocument,
  resetRetrieveReferralDocument,
  updateDemandDocuments,
  updateRetriveDemandDocument,
  resetRetriveDemandDocument,
  updateSelectedDemandBills,
  updateReferralEmailTemplates,
  updateReferralSpecialties,
  resetReferral,
  setDenyReason,
  // setDenyReason2,
  resetDenyReason,
  setRembTotal,
  resetRembTotal,
  removeRembTotal,
  updateStatusHistoryData,
  resetStatusHistoryData,
  updateStatusListData,
  resetStatusListData,
  updateUnPendData,
  resetUnPendData,
  updateBillLinesData,
  updatepostMcnsBills,
  resetpostMcnsBills,
  setMcnsEditVal,
  resetMcnsEditVal,
  setBillRevEditVal,
  resetBillRevEditVal,
  updatePostBillReviewData,
  resetBillReviewData,
  addAlert,
  updateFindBillsFromTabs,
  updateFindBillsFromRecentActivity,
  updateSavePayCodeData,
  updateAORemainingTable,
  updatePostBillReviewAoData,
  resetPostBillReviewAoData,
  UpdateBillClaimNotes,
  updateNewMed,
  updateCorrectionReasons,
  resetTaskList,
};
