import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import React, { Component } from "react";
import { AppTablePaginationPropType } from "./AppTablePropTypes";
import paginationUtils from "./paginationUtils";
class AppTablePagination extends Component {
    render() {
        return (
            <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={this.props.currentPage === 0}>
                    <PaginationLink previous onClick={this.selectPreviousPage} />
                </PaginationItem>
                <PaginationItem disabled={this.props.currentPage === 0}>
                    <PaginationLink previous onClick={this.selectFirstPage}>
                        First
                    </PaginationLink>
                </PaginationItem>
                {this.pageNumberArray().map((e) => {
                    return (
                        <PaginationItem key={e} active={this.props.currentPage === e}>
                            <PaginationLink onClick={this.createSelectPageHandler(e)}>
                                {e + 1}
                            </PaginationLink>
                        </PaginationItem>
                    )
                })}
                <PaginationItem disabled={this.isLastPage()}>
                    <PaginationLink previous onClick={this.selectLastPage}>
                        Last
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={this.isLastPage()}>
                    <PaginationLink next onClick={this.selectNextPage} />
                </PaginationItem>
            </Pagination>
        )
    }

    pageNumberArray = () => {
        return paginationUtils.pageNumberArray(this.pageCount(), this.props.pageLinkSize, this.props.currentPage)
    }

    pageCount = () => {
        return Math.ceil(this.props.totalItems / this.props.pageSize)
    }

    isLastPage = () => {
        return this.props.currentPage === this.lastPage()
    }

    lastPage = () => {
        return this.pageCount() - 1
    }

    selectPreviousPage = () => {
        this.props.selectPage(this.props.currentPage - 1)
    }

    selectNextPage = () => {
        this.props.selectPage(this.props.currentPage + 1)
    }

    selectFirstPage = () => {
        this.props.selectPage(0)
    }

    selectLastPage = () => {
        this.props.selectPage(this.lastPage())
    }

    createSelectPageHandler = (pageNumber) => {
        return () => {
            this.selectPage(pageNumber)
        }
    }

    selectPage = (pageNumber) => {
        this.props.selectPage(pageNumber);
    }
}

AppTablePagination.propTypes = AppTablePaginationPropType;

AppTablePagination.defaultProps = {
    currentPage: 0,
    pageSize: 20,
    pageLinkSize: 10
};



export default AppTablePagination;


