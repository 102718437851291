import React, { Component } from 'react';

import BillReviewTopNav from './TopNav';
import TaskListNav from './TaskListNav';

class TaskList extends Component {
  render() {
    return (
      <div id="taskListContainer">
        <BillReviewTopNav />
        <TaskListNav />
      </div>
    );
  }
}

export default TaskList;
