import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import DashboardButton from './DashboardButton';
const locationSame = '1';
const locationDiff = '0';
const Fast360HHC = ({
  hhcCategory,
  selectedService,
  claimantAddress,
  zipList,
  getZipList,
  resetZipList,
  userId,
  updatedClaimInfo,
  fromExt,
  hhcPO,
  selectedMod,
  updatePatientZip,
  patientInfo,
  isFromLanding,
  dataFromWorklist,
  requiredServiceData
}) => {
  const [tab, setTab] = useState(locationDiff);
  const [selectedItem, setSelectedItem] = useState(selectedService);
  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.categoryId = target.value;
    setSelectedItem({ selectedService });
  };

  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);

  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (fromExt && selectedMod === 'HHC') {
      selectedService.poNumber = hhcPO.UniquePortalAuthCode;
    }
  }, [])

  useEffect(() => {
    if (selectedService || isFromLanding || (!isEmpty(dataFromWorklist))) {
      if (isFromLanding || (!isEmpty(dataFromWorklist))) {
        updatePatientZip({
          ...patientInfo,
          hhcService: selectedService?.subService,
          serviceId: selectedService?.serviceId,
          serviceTypeId: selectedService?.categoryId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          hhcService: selectedService?.subService,
          serviceTypeId: selectedService?.categoryId,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService, isFromLanding, dataFromWorklist]);

  useEffect(() => {
    if (selectedService?.dos || selectedService?.subService || isFromLanding || (!isEmpty(dataFromWorklist))) {
      if (isFromLanding || (!isEmpty(dataFromWorklist))) {
        updatePatientZip({
          ...patientInfo,
          hhcService: selectedService?.subService,
          serviceTypeId: selectedService?.categoryId,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          hhcService: selectedService?.subService,
          serviceId: selectedService?.serviceId,
          serviceTypeId: selectedService?.categoryId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService?.dos, selectedService?.subService, isFromLanding, dataFromWorklist]);

  const onTabRadioChangeHandler = event => {
    selectedService.locationSame = event.target.value;
    setSelectedItem({ selectedService });
    if (selectedService?.locationSame === '1') {
      if (claimantAddress != undefined) {
        selectedService.locationPhone = updatedClaimInfo && updatedClaimInfo.claim && updatedClaimInfo.claim.claimant &&
          updatedClaimInfo.claim.claimant.people && updatedClaimInfo.claim.claimant.people.phone;
        selectedService.serviceAddress.street1 = claimantAddress.street1;
        selectedService.serviceAddressId = claimantAddress.id;
        selectedService.serviceAddress.phone = updatedClaimInfo && updatedClaimInfo.claim && updatedClaimInfo.claim.claimant &&
          updatedClaimInfo.claim.claimant.people && updatedClaimInfo.claim.claimant.people.phone;
        selectedService.serviceAddress.zipCode.id = claimantAddress.zipCode.id;
        selectedService.serviceAddress.zipCode.city = claimantAddress.zipCode.city;
        selectedService.serviceAddress.zipCode.state = claimantAddress.zipCode.state;
        selectedService.serviceAddress.zipCode.county = claimantAddress.zipCode.county;
        selectedService.serviceAddress.zipCode.zip = claimantAddress.zipCode.zip;
        selectedService.serviceAddress.zipCodeId = claimantAddress.zipCodeId;
        // selectedService.serviceAddress.zipId = claimantAddress.zipCodeId;
        selectedService.serviceAddress.zipCode.timeZone = claimantAddress.timezone;
        selectedService.serviceAddSameAsClmnt = selectedService?.locationSame === '1' ? true : false;
      }
      else {
        if (isFromLanding) {
          selectedService.serviceAddress.street1 = dataFromWorklist.claim?.claimant?.address?.street1;
          selectedService.serviceAddress.zipCode.city = dataFromWorklist.claim?.claimant?.address?.zipCode?.city;
          selectedService.serviceAddress.zipCode.state = dataFromWorklist.claim?.claimant?.address?.zipCode?.state;
          selectedService.serviceAddress.zipCode.zip = dataFromWorklist.claim?.claimant?.address?.zipCode?.zip;
          selectedService.locationPhone = dataFromWorklist?.claim?.claimant?.people?.phone
            ? formatPhoneNumber(dataFromWorklist.claim.claimant.people.phone) : ' ';
          selectedService.serviceAddressId = 0;
          selectedService.serviceAddress.zipCodeId = dataFromWorklist.claim?.claimant?.address?.zipCodeId;
          selectedService.serviceAddSameAsClmnt = selectedService?.locationSame === '1' ? true : false;
        }
      }
    } else {
      selectedService.locationPhone = '';
      selectedService.serviceAddressId = 0;
      selectedService.serviceAddress.street1 = '';
      selectedService.serviceAddress.zipCode.id = '';
      selectedService.serviceAddress.zipCode.city = '';
      selectedService.serviceAddress.zipCode.state = '';
      selectedService.serviceAddress.zipCode.county = '';
      selectedService.serviceAddress.zipCode.zip = '';
      selectedService.serviceAddress.zipCodeId = '';
      //selectedService.serviceAddress.zipId = '';
      selectedService.serviceAddress.zipCode.timeZone = '';
      selectedService.serviceAddress.phone = '';
      selectedService.serviceAddSameAsClmnt = selectedService?.locationSame === '1' ? true : false;
    }
    setSelectedItem({ selectedService });
  };

  const setMap = {
    dos: setValidDos,
  }

  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }
    setSelectedItem({ selectedService });
    setValidDos(true);

  };

  const handleClearButton = event => {
    selectedService.locationPhone = '';
    selectedService.serviceAddressId = 0;
    selectedService.serviceAddress.street1 = '';
    selectedService.serviceAddress.zipCode.id = '';
    selectedService.serviceAddress.zipCode.city = '';
    selectedService.serviceAddress.zipCode.state = '';
    selectedService.serviceAddress.zipCode.county = '';
    selectedService.serviceAddress.zipCode.zip = '';
    selectedService.serviceAddress.zipCodeId = '';
    // selectedService.serviceAddress.zipId = '';
    selectedService.serviceAddress.zipCode.timeZone = '';
    selectedService.serviceAddress.phone = '';

    setSelectedItem({ selectedService });
  };

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
    }

    if (name === 'locationPhone') {
      selectedService.locationPhone = formatPhoneNum(value);
      selectedService.serviceAddress.phone = formatPhoneNum(value);

    }

    if (name === 'recurringOrder') {
      selectedService.recurringOrder =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'locationAddress') {
      selectedService.serviceAddress.street1 = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip != null) {
      selectedService.serviceAddress.zipCode.id = selectedZip.code_id;
      selectedService.serviceAddress.zipCode.city = selectedZip.chr_city;
      selectedService.serviceAddress.zipCode.state = selectedZip.chr_state;
      selectedService.serviceAddress.zipCode.county = selectedZip.chr_county;
      selectedService.serviceAddress.zipCode.zip = selectedZip.chr_zipcode;
      selectedService.serviceAddress.zipCodeId = selectedZip.code_id;
      //selectedService.serviceAddress.zipId = selectedZip.code_id;
      selectedService.serviceAddress.zipCode.timeZone = selectedZip.chr_timezone;
      setSelectedItem({ selectedService });
    }
    resetZipList();
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subServ'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                placeholder="Choose..."
                value={selectedService?.categoryId || ''}
                className={selectedService?.categoryId ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {hhcCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="recurringOrder"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.recurringOrder}
              />{' '}
              Recurring Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'hhcdos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
        <Row>
          <Col sm="8">
            <FormGroup className="form-check-inline">
              <CustomInput
                type="radio"
                label="yes"
                id="locationSameRadio"
                name="tabRadio"
                value={locationSame}
                checked={selectedService?.locationSame === locationSame}
                onChange={onTabRadioChangeHandler}
              />
            </FormGroup>
            <FormGroup className="form-check-inline">
              <CustomInput
                type="radio"
                id="locationDiffRadio"
                name="tabRadio"
                label="no"
                value={locationDiff}
                checked={selectedService?.locationSame === locationDiff}
                onChange={onTabRadioChangeHandler}
              />
            </FormGroup>

            <Label>Service Location Same as Patient Address</Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Location: </Label>
            <Input
              name="locationAddress"
              onChange={handleInputChange}
              value={selectedService?.serviceAddress?.street1 || ''}
              className={selectedService?.serviceAddress?.street1 ? '' : "error"}
              readOnly={
                (selectedService?.locationSame === '1')
              }
            />
          </Col>
          <Col sm="5">
            <Row>
              <Label>City/State/Zip: </Label>
            </Row>
            <Row>
              <Label>
                {selectedService?.serviceAddress?.zipCode?.city}{' '}
                {selectedService?.serviceAddress?.zipCode?.state},{' '}
                {selectedService?.serviceAddress?.zipCode?.zip}
              </Label>
            </Row>
          </Col>
          <Col sm="5">
            <Label for="locZip">Lookup Zip</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              onChange={(e, actionType) =>
                handleFormFields(e, 'locZip', actionType)
              }
              className={selectedService?.serviceAddress?.zipCode?.zip ? '' : "error"}
              placeholder=""
              menuPlacement="bottom"
              onKeyDown={e => handleTypeAheadSearch(e)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              menuIsOpen={zipList.length}
              value={selectedService?.serviceAddress?.zipCode?.zip}
              closeMenuOnSelect={true}
              options={
                zipList.length
                  ? zipList.map(obj =>
                    assign(obj, {
                      label: obj.zip_type_with_county,
                      value: obj.code_id,
                    })
                  )
                  : []
              }
              name="locZip"
              id="locZip"
              isDisabled={
                (selectedService?.locationSame === '1')
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Phone: </Label>
            <Input
              name="locationPhone"
              onChange={handleInputChange}
              value={selectedService?.locationPhone || ''}
              placeholder="(xxx)xxx-xxxx"
              className={(selectedService?.locationPhone?.trim() == "" || selectedService?.locationPhone == undefined) ? (selectedService?.locationSame === '1' ? "" : "error") : ''}
              readOnly={
                (selectedService?.locationSame === '1')
              }
            />
          </Col>
        </Row>
        <Row>
          {' '}
          <Button color="primary" onClick={handleClearButton}>
            Clear Location
          </Button>
        </Row>
      </FormGroup>
    </>
  );
};

Fast360HHC.propTypes = {
  hhcCategory: PropTypes.array,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  var claimantAddress;
  if (fast360Info.updatedClaimInfo.claim != undefined) {
    if (fast360Info.updatedClaimInfo.claim.claimant != undefined) {
      if (fast360Info.updatedClaimInfo.claim.claimant.address != undefined) {
        claimantAddress = fast360Info.updatedClaimInfo.claim.claimant.address
      } else {
        claimantAddress = undefined;
      }
    } else {
      claimantAddress = undefined;
    }
  } else {
    claimantAddress = undefined;
  }
  return {
    claimantAddress: claimantAddress,
    hhcCategory: initialData.hhcCategory,
    zipList: fast360Info.zipList,
    updatedClaimInfo: fast360Info.updatedClaimInfo,
    userId: user.id,
    patientInfo: fast360Info.patientZip,
  };
};

const mapDispatchToProps = dispatch => ({
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360HHC);
