import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';

import { authSelectors } from 'modules/auth/store';

import { expertsOperations, expertsSelectors, expertsActions } from '../store';

import {
  TASK_LIST_TAB,
  FAX_LIST_TAB,
  REPORT_TAB,
  RADIUS_SEARCH_TAB,
  PREVIEW_TAB,
} from '../utils/constantUtils';

import ExpertTaskListContainer from './ExpertTaskListContainer';
import ExpertFaxTabContainer from './ExpertFaxTabContainer';
import ExpertReportContainer from './ExpertReportContainer';
import ExpertRadiusSearchContainer from './ExpertRadiusSearchContainer';
import ExpertPreviewContainer from './ExpertPreviewContainer';
import FindExpertSimple from './FindExpertSimple';
import NewGroupContainer from './NewGroupContainer';
import NewExpertContainer from './NewExpertContainer';

const ExpertPage = ({
  tasks,
  faxes,
  activeTab,
  currentUserId,
  getInitialData,
  updateActiveTab,
}) => {
  useEffect(() => {
    if (currentUserId && currentUserId !== 0) {
      getInitialData(currentUserId);
    }
  }, [getInitialData, currentUserId]);

  const toggle = tab => {
    if (activeTab !== tab) {
      updateActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs>
        <Col lg="8" style={{ paddingLeft: 0 }}>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TASK_LIST_TAB,
              })}
              onClick={() => {
                toggle(TASK_LIST_TAB);
              }}
            >
              Task List ({tasks.length})
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === FAX_LIST_TAB,
              })}
              onClick={() => {
                toggle(FAX_LIST_TAB);
              }}
            >
              Fax List ({faxes.length})
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === REPORT_TAB,
              })}
              onClick={() => {
                toggle(REPORT_TAB);
              }}
            >
              Report
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === RADIUS_SEARCH_TAB,
              })}
              onClick={() => {
                toggle(RADIUS_SEARCH_TAB);
              }}
            >
              Radius Search
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === PREVIEW_TAB,
              })}
              onClick={() => {
                toggle(PREVIEW_TAB);
              }}
            >
              Preview
            </NavLink>
          </NavItem>
        </Col>
        <Col lg="2">
          <Row>
            <Col lg="12">
              <FindExpertSimple />
            </Col>
          </Row>
        </Col>
        <Col lg="2" style={{ paddingRight: 0 }}>
          <Row>
            <Col lg="6">
              <NewGroupContainer currentUserId={currentUserId} />
            </Col>
            <Col lg="6">
              <NewExpertContainer currentUserId={currentUserId} />
            </Col>
          </Row>
        </Col>
      </Nav>
      <TabContent activeTab={activeTab} style={{ paddingTop: '10px' }}>
        <TabPane tabId={TASK_LIST_TAB}>
          <ExpertTaskListContainer currentUserId={currentUserId} />
        </TabPane>
        <TabPane tabId={FAX_LIST_TAB}>
          <ExpertFaxTabContainer />
        </TabPane>
        <TabPane tabId={REPORT_TAB}>
          <ExpertReportContainer />
        </TabPane>
        <TabPane tabId={RADIUS_SEARCH_TAB}>
          <ExpertRadiusSearchContainer />
        </TabPane>
        <TabPane tabId={PREVIEW_TAB}>
          <ExpertPreviewContainer />
        </TabPane>
      </TabContent>
    </>
  );
};

ExpertPage.propTypes = {
  tasks: PropTypes.array.isRequired,
  faxes: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  getInitialData: PropTypes.func.isRequired,
  updateActiveTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const taskState = expertsSelectors.getTasks(state);
  const faxesState = expertsSelectors.getFaxes(state);
  const initialState = expertsSelectors.getInitialData(state);
  const userId = authSelectors.getUserId(state);
  return {
    tasks: taskState.tasks,
    faxes: faxesState.faxes,
    activeTab: initialState.activeTab,
    currentUserId: userId || 0,
  };
};

const mapDispatchToProps = dispatch => ({
  getInitialData: userId => dispatch(expertsOperations.getInitialData(userId)),
  updateActiveTab: activeTab =>
    dispatch(expertsActions.updateExpertActiveTab(activeTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertPage);
