import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

import TopNavActions from '../TopNavActions';

import AddClientRatesButton from './AddClientRatesButton';
import ClientRatesTable from './ClientRatesTable';

class ClientRatesContainer extends Component {
  addNewClientRates = () => {
    return <AddClientRatesButton data={this.props.data} />;
  };

  render() {
    return (
      <>
        <TopNavActions
          name={`${this.props.name}_topClientRates`}
          stateList={this.props.stateList}
          lobList={this.props.lobList}
          updateFilter={this.props.updateClientRatesFilter}
          addNewButton={this.addNewClientRates}
          haveAddNewButton={this.props.haveAddNewButton}
        />

        <ClientRatesTable
          name={`${this.props.name}_tableClientRates`}
          isSelectable={this.props.isSelectable}
          onSelectRows={this.props.onSelectRows}
          data={this.props.data}
          updateTableData={this.props.updateTableData}
          lobList={this.props.lobList}
          rateTypeList={this.props.rateTypeList}
          clientNetworkList={this.props.clientNetworkList}
          billTypeList={this.props.billTypeList}
          stateList={this.props.stateList}
          editColumns={this.props.editColumns}
          filters={this.props.filters}
        />
      </>
    );
  }
}

ClientRatesContainer.propTypes = {
  isSelectable: PropTypes.bool,
  haveAddNewButton: PropTypes.bool,
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  rateTypeList: PropTypes.array.isRequired,
  clientNetworkList: PropTypes.array.isRequired,
  billTypeList: PropTypes.array.isRequired,
  updateClientRatesFilter: PropTypes.func.isRequired,
  updateTableData: PropTypes.func.isRequired,
};

ClientRatesContainer.defaultProps = {
  isSelectable: false,
  haveAddNewButton: true,
};

const mapStateToProps = state => {
  const initialDataState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientRateFilters = billMaintenanceSelectors.getClientMaintenanceClientRatesFilter(
    state
  );

  return {
    filters: clientRateFilters,
    stateList: initialDataState.clientComboBoxes.stateList,
    lobList: initialDataState.clientComboBoxes.lobList,
    rateTypeList: initialDataState.clientComboBoxes.rateTypeList,
    clientNetworkList: initialDataState.clientComboBoxes.clientNetworkList,
    billTypeList: initialDataState.clientComboBoxes.billTypeList,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateClientRatesFilter: data =>
      dispatch(billMaintenanceActions.updateClientRatesFilter(data)),
    updateTableData: data =>
      dispatch(billMaintenanceActions.updateClientRatesTable(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientRatesContainer);
