import * as R from 'ramda';

const toWordsRegEx = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

function toWords(str) {
  return R.match(toWordsRegEx, str);
}

function capitalize(word) {
  return R.replace(/^\w/, chr => R.toUpper(chr), R.toLower(word));
}

const mapIndexed = R.addIndex(R.map);

export function toCamelCase(str) {
  return R.join(
    '',
    mapIndexed(
      (word, i) => (i === 0 ? R.toLower(word) : capitalize(word)),
      toWords(str)
    )
  );
}

export function truncate(str, length, append = '...') {
  if (str.length <= length) {
    return str;
  }

  return `${str.slice(0, length)}${append}`;
}
