import React, { Component } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { SelectableCell } from './SelectableCell';
import { ClickableCell } from './ClickableCell';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { isThisQuarter } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authSelectors } from 'modules/auth/store';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
    faAsterisk,
    faCircle,
    faEdit,
    faEnvelope,
    faExclamation,
    faFileUpload,
    faCheck,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { Input, CustomInput, Form } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import {
    fast360Operations,
    fast360Selectors,
    fast360Actions,
} from '../../../modules/fast-360/store';



class Fast360AuthTableBodyRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
            rowIndex: null,
            checked: false,
            selectedReason: 0,
            isChecked: false,
            isStatusDisabled: false,
        };
        this.getStatus = this.getStatus.bind(this)
    }



    getStatus = (item) => {
        let status = '';
        const today = new Date().getTime();
        const endDt = new Date(item.endDate).getTime();
        if (item.closeReasonId) {
            status = 'Closed';
        }
        else if ((endDt - today) >= 0) {
            status = 'Open';
        }
        else
            status = 'Expired';

        return status;
    }


    componentDidMount() {
        const data = this.getStatus(this.props.data[this.props.rowIndex]);
        if (this.props.authData.id == this.props.data[this.props.rowIndex].id) {
         
            this.setState({ isChecked: true });
            if (data == 'Expired') {
                this.props.alert('Selected Auth is invalid as it is currently expired.')
            }
            if( data  == 'Closed') {
                this.props.alert('Selected Auth is invalid as it is currently closed.')
            }
        }
        if (this.props.userRole != 'Administrator') {
            if (data == 'Closed' || data == 'Expired')
                this.setState({ isStatusDisabled: true })
        }
 
    }

    componentWillUnmount() {
        this.setState({isChecked: false});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data[prevProps.rowIndex] !== this.props.data[this.props.rowIndex] ) {
            
            const data = this.getStatus(this.props.data[this.props.rowIndex]);  
            const prevData = this.getStatus(prevProps.data[prevProps.rowIndex]);
            
            if( data  == 'Closed' && data !== prevData &&  this.state.isChecked) {
                this.props.alert('Selected Auth is invalid as it is currently closed.')
              
            }
        }
    }

    toggleSelectedRow = (id, checked) => {
        this.setState(prevState => {
            return checked
                ? this.addRowFromState(prevState, id)
                : this.removeRowFromState(prevState, id);
        }, this.executeSelectHandler);
    };

    removeRowFromState = (state, id) => {
        return this.updateSelectedRows(
            state,
            state.selectedRows.filter(row => row !== id)
        );
    };

    addRowFromState = (state, id) => {
        return this.updateSelectedRows(state, state.selectedRows.concat(id));
    };

    updateSelectedRows = (state, selectedRows) => {
        // this.props.setSelectedBills(selectedRows)
        return {
            ...state,
            selectedRows,
        };
    };

    executeSelectHandler = () => {
        const selectedRows = this.state.selectedRows.map(id => {
            return this.props.data[id];
        });
        // this.props.selectHandler(selectedRows);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ selectedRows: [] });
            nextProps.data.map(item => {
                if (item.confNo == nextProps.dataObject.confNo)
                    this.setState({ checked: item.selected });
            });
        }
    };

    selectCheckBoxHandler = (event, dataObject) => {
        let status = '';
        const today = new Date().getTime();
        const endDt = new Date(dataObject.endDate).getTime();
        if (dataObject.closeReasonId) { status = 'Closed'; }
        else if ((endDt - today) >= 0) { status = 'Open'; }
        else status = 'Expired';
        const { updateSelectedAuthRecord, rowIndex, selectedAuthRecord } = this.props;
        if (event.target.checked) {
            if (selectedAuthRecord.index == -1) {
                if (dataObject.authServiceTypes[0]?.serviceType == this?.props?.serviceType && status !== 'Closed' && status !== 'Expired') {
                    const payload = {
                        "functionName": "saveAuthToService",
                        "zebraUserId": this.props.currentUserId,
                        "serviceType": dataObject.authServiceTypes[0]?.serviceType,
                        "serviceId": this.props.serviceID,
                        // "id": dataObject.id,
                        "authId": dataObject.authServiceTypes[0]?.authId
                    }
                    this.props.saveAuthService(payload, dataObject);
                    this.setState({ isChecked: true });
                    const breadCrumb = {
                        functionName: 'logBreadCrumbs',
                        zebraUserId: this.props.currentUserId ,
                        userid: this.props.currentUserId,
                        breadcrumbkey: 'SAVEAUTH',
                        refTableId: this.props.serviceID,
                        refId: 0,
                        refTable: dataObject.authServiceTypes[0]?.serviceType,
                      };
                      //helper.request.post('/SaveObject', breadCrumb);
                    
                    this.props.logBreadCrumbs(breadCrumb);
                    updateSelectedAuthRecord({ index: rowIndex, state: true });
                }
                else if (status === 'Closed')
                    this.props.alert('Selected Auth is invalid as it is currently closed.')
                else if (status === 'Expired')
                    this.props.alert('Selected auth is invalid as it is currently expired.')
                else
                    this.props.alert('Selected authorization does not match service type.')
            }
            else
                this.props.alert('Please uncheck a selected authorization first.')
        }
        else {
            const payload = {
                "functionName": "clearAuthFromService",
                "zebraUserId": this.props.currentUserId,
                "serviceType": dataObject.authServiceTypes[0]?.serviceType,
                "serviceId": this.props.serviceID,
            }
            this.props.clearAuthService(payload);
            this.setState({ isChecked: false });
            const breadCrumb = {
                functionName: 'logBreadCrumbs',
                zebraUserId: this.props.currentUserId ,
                userid: this.props.currentUserId,
                breadcrumbkey: 'CLEARAUTH',
                refTableId: this.props.serviceID,
                refId: 0,
                refTable: dataObject.authServiceTypes[0]?.serviceType,
              };
              //helper.request.post('/SaveObject', breadCrumb);
            
            this.props.logBreadCrumbs(breadCrumb);
            updateSelectedAuthRecord({ index: -1, state: false });
        }

    }


    onRowClicked = (dataObject, rowIndex, event) => {
        if (event.target.tagName === 'TD') {
            this.props.rowClickHandler(dataObject);
            this.setState({
                highlightedRow: rowIndex,
            });
        }
    };


    handleReasonSave = (dataObject) => {
        if (!this.state.isStatusDisabled) {
            const { frequency, authNumber, notes, startDate, endDate, authServiceTypes, id, claimId } = dataObject;
            const dateNow = new Date().toISOString();
            const user = this.props.roleUsers.filter(i => i.zebraId == this.props.currentUserId)[0];
            const closeDate = `${dateNow.split('T')[0]} ${dateNow.split('T')[1].split('Z')[0]}`;
            if (this.state.selectedReason == 0 || this.state.selectedReason == '') {
                this.props.alert('Please select a close reason')
            }
            else {
                const payload = {
                    functionName: "saveAuth",
                    zebraUserId: this.props.currentUserId,
                    claimId,
                    frequency,
                    authNum: authNumber,
                    notes,
                    startDate,
                    endDate,
                    authServiceTypes,
                    closeReasonId: this.state.selectedReason,
                    closeDate,
                    closeUser: `${user.username.split(',')[1]} ${user.username.split(',')[0]}`,
                    id
                };
                this.props.saveAuthHistory(payload);
                const breadCrumb = {
                    functionName: 'logBreadCrumbs',
                    zebraUserId: this.props.currentUserId ,
                    userid: this.props.currentUserId,
                    breadcrumbkey: 'SAVEAUTH',
                    refTableId: this.props.serviceID,
                    refId: 0,
                    refTable: dataObject.authServiceTypes[0]?.serviceType,
                  };
                  //helper.request.post('/SaveObject', breadCrumb);
                
                this.props.logBreadCrumbs(breadCrumb);
                setTimeout(() => {
                    this.props.getUpdatedHistory();
                }, 2000)
            }
        }
    }

    handleAuthCloseReason = (event) => {
        this.setState({ selectedReason: event.target.value })
    }

    render() {
        const rowIndex = this.props.rowIndex;
        const dataObject = this.props.dataObject;
        return (
            <>
                <tbody>
                    <tr
                        key={rowIndex}
                        style={{
                            marginTop: '42px',
                            backgroundColor: this.props.highlightedRow === rowIndex ? '#cdf0f9' : null
                        }}
                        onDoubleClick={this.handleDblClick}
                        onClick={
                            this.props.isRowClickable
                                ? event => this.onRowClicked(dataObject, rowIndex, event)
                                : null
                        }
                    >
                        {this.props.isSelectable ? (
                            <SelectableCell
                                rowIndex={rowIndex}
                                selectHandler={this.toggleSelectedRow}
                                selected={this.state.selectedRows.includes(rowIndex)}
                            />
                        ) : null}
                        {this.props.columns.map((column, columnIndex) => {
                            if (column.render) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {column.render(dataObject, rowIndex)}
                                        </th>
                                    );
                                }

                                return (
                                    <td key={columnIndex}>
                                        {column.render(dataObject, rowIndex)}
                                    </td>
                                );
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Show on Details') {

                                    return (
                                        <td>
                                            <div
                                                style={{
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Form>
                                                    <CustomInput
                                                        id={dataObject.id}
                                                        name={dataObject.id}
                                                        key={dataObject.id}
                                                        type="checkbox"
                                                        checked={this.state.isChecked}
                                                        onChange={event => {
                                                            this.selectCheckBoxHandler(event, dataObject);
                                                        }}
                                                    />
                                                </Form>
                                            </div>
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Close') {
                                    return (
                                        <td>
                                            <div
                                                style={{
                                                    marginLeft: '12px',
                                                }}
                                            >


                                                <FontAwesomeIcon
                                                    onClick={() => this.handleReasonSave(dataObject)}
                                                    icon={faSave}
                                                    style={{ marginTop: '10px', marginLeft: '15px' }}
                                                    type="button"
                                                // disabled={this.state.isStatusDisabled}
                                                />


                                            </div>
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Close Reason') {
                                    return (
                                        <td>
                                            <div
                                                style={{
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <Input
                                                    type="select"
                                                    onChange={(e) => this.handleAuthCloseReason(e)}
                                                    defaultValue={dataObject.closeReasonId ? dataObject.closeReasonId : ''}
                                                    // value={this.state.selectedReason}
                                                    disabled={this.state.isStatusDisabled}
                                                >
                                                    <option value="" disabled={true}>
                                                        Close Reasons
                                                    </option>
                                                    {
                                                        this.props.authCloseReasons.length ? this.props.authCloseReasons.map(
                                                            item => <option value={item.id}>{item.value}</option>
                                                        ) : []
                                                    }
                                                </Input >
                                            </div>
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                const status = this.getStatus(dataObject);
                                if (column.header == 'Status') {
                                    return (
                                        <td>
                                            <div
                                                style={{
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                {status == 'Closed' ?
                                                    <AppTooltip
                                                        text={`${dataObject?.closeDate?.split('.')[0]}-${dataObject?.closeUser}`}
                                                        id={`Close_${this.props.rowIndex}`}
                                                    >
                                                        {status}
                                                    </AppTooltip> : status}
                                            </div>
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {dataObject[column.field] || ''}
                                        </th>
                                    );
                                }
                                return (
                                    <td key={columnIndex}>{dataObject[column.field] || ''}</td>
                                );
                            }

                            if (this.props.returnData === dataObject.fields) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {dataObject[column.field] || ''}
                                        </th>
                                    );
                                }

                                return (
                                    <td key={columnIndex}>
                                        {dataObject.fields[column.field] ||
                                            dataObject.fields.dateRange[column.field] ||
                                            ''}
                                    </td>
                                );
                            }
                        })}
                        {this.props.isClickable ? (
                            <ClickableCell
                                clickHandler={() => this.props.clickHandler(dataObject)}
                                location="/administration/user"
                            />
                        ) : null}
                    </tr>
                </tbody>
            </>
        );
    }
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    const initialData = fast360Selectors.getFast360InitialData(state);
    const user = authSelectors.getUser(state);
    return {
        currentUserId: userId,
        authCloseReasons: initialData.authCloseReasons,
        roleUsers: initialData.roleUsers.users,
        selectedAuthRecord: state.FAST360.fast360Reducer.selectedAuthRecord ? state.FAST360.fast360Reducer.selectedAuthRecord : { index: -1, state: false },
        userRole: user.role_name,
    };
};

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    setCurrentWorkList: data => dispatch(fast360Actions.setCurrentWorkList(data)),
    saveAuthHistory: data => dispatch(fast360Operations.saveAuthHistory(data)),
    getClaimAuth: data => dispatch(fast360Operations.getClaimAuth(data)),
    saveAuthService: (data, obj) => dispatch(fast360Operations.saveAuthService(data, obj)),
    clearAuthService: data => dispatch(fast360Operations.clearAuthService(data)),
    updateSelectedAuthRecord: data => dispatch(fast360Actions.updateSelectedAuthRecord(data)),
    logBreadCrumbs : data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

Fast360AuthTableBodyRow.propTypes = AppTableBodyPropType;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fast360AuthTableBodyRow);
