import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import { commonOperations } from 'common/store';
import { authSelectors } from 'modules/auth/store';
import Select from 'react-select';
import { createFilter } from 'react-select';
import { assign } from 'lodash';
import AppDateInput from 'shared/components/input/AppDateInput';
import * as FetchUtils from 'shared/utils/fetchUtils';
import { usePrevious } from 'shared/hooks/usePrevious';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

import styles from './PrintReportsModal.module.scss';

const defaultFormData = {
  adjuster: '',
  batchName: '',
  billIdEnd: '',
  billIdStart: '',
  billType: '',
  billingType: 'WholeSale',
  claim: '',
  claimant: '',
  email: '',
  employer: '',
  endDate: moment().toDate(),
  ppo: '',
  providerTin: '',
  report: '',
  reportType: 'ALL',
  site: '',
  startDate: moment().toDate(),
  state: 'All',
  user: '',
};

const reportOptions = [
  { default: true, label: 'Span Sub Sites', id: 'sitespan' },
  { default: false, label: 'Total Page Only', id: 'onlytotal' },
  { default: false, label: 'Only Active Clients', id: 'onlyactive' },
  { default: false, label: 'Print Carrier Locations', id: 'prntlocations' },
  { default: false, label: 'Print Adjusters', id: 'prntadjusters' },
  { default: false, label: 'Separate Employers', id: 'sepemployers' },
  { default: false, label: 'Separate PPO', id: 'sepPpo' },
  { default: false, label: 'Separate Month', id: 'sepMonth' },
  { default: false, label: 'Run for Adjustments Only', id: 'adjonly' },
  { default: false, label: 'One Bill per ISS', id: 'sepiss' },
  { default: false, label: 'Run Using Entry Date', id: 'useEntryDate' },
  { default: true, label: 'Use Site Logo', id: 'slogo' },
  { default: false, label: 'Print ISS by Bill ID', id: 'issbyid' },
  { default: false, label: 'Use Site Address', id: 'siteAddress' },
  { default: false, label: 'Use Claim State', id: 'claimstate' },
  { default: false, label: 'Include RFIs', id: 'includeRFI' },
  { default: false, label: 'Include Duplicates', id: 'includeDups' },
];

const defaultReportOptionsData = R.reduce(
  (acc, item) => ({ ...acc, [item.id]: item.default }),
  {},
  reportOptions
);

const getUrlSearchParams = (formData, reportOptionsData, profile) => {
  const params = new URLSearchParams();

  params.append('site', formData.site);
  R.forEachObjIndexed((value, key) => {
    if (value) {
      params.append(key, 'on');
    } else params.append(key, '');
  }, reportOptionsData);
  params.append('pdf_srange', formData.billIdStart);
  params.append('pdf_erange', formData.billIdEnd);
  params.append('emailaddress', formData.email);

  if (profile !== '') {
    params.append('profile', profile.profileAlias);
    params.append('logo', profile.logo);
    params.append('logow', profile.width);
    params.append('logoh', profile.height);
  }

  const startDate = formData.startDate.toString();
  if (startDate !== '') {
    params.append('bdatemm', moment(startDate).format('MM'));
    params.append('bdatedd', moment(startDate).format('DD'));
    params.append('bdateyy', moment(startDate).format('YYYY'));
  }

  const endDate = formData.endDate.toString();
  if (endDate !== '') {
    params.append('edatemm', moment(endDate).format('MM'));
    params.append('edatedd', moment(endDate).format('DD'));
    params.append('edateyy', moment(endDate).format('YYYY'));
  }

  params.append('report', formData.report);
  params.append('user', formData.user);
  params.append('state', formData.state);
  params.append('juris', formData.reportType);
  params.append('employer', formData.employer);
  params.append('ppo', formData.ppo);
  params.append('billtype', formData.billType);
  params.append('billingtype', formData.billingType);
  params.append('Script', 'Y');
  params.append('batch', formData.batchName);
  params.append('adjuster', formData.adjuster);
  params.append('claim', formData.claim);
  params.append('claimant', formData.claimant);
  params.append('tin', formData.providerTin);
  params.append('skipZero', '');

  return params;
};
const getISSParams = (formData, reportOptionsData, profile) => {
  const params = new URLSearchParams();

  params.append('eor', formData.billIdStart);
  
  if (profile !== '') {
    params.append('profile', profile.profileAlias);    
  }

  params.append('billingtype', formData.billingType);
  params.append('includeRFI', '');
  params.append('includeDups', '');
  
  return params;
};

const PrintReportsModal = ({
  isOpen,
  onClose,
  getEmployerList,
  employerListData,
  resetEmployerList,
  getClaimantList,
  claimantListData,
  resetClaimantList,
  getClaimList,
  getProviderList,
  resetProviderList,
  resetClaimList,
  claimListData,
  providerListData,
  printProfile
}) => {
  const dispatch = useDispatch();

  const billReviewState = useSelector(state =>
    billOperationsSelectors.getBillReview(state)
  );
  const user = useSelector(state => authSelectors.getUser(state));
  const billReviewInitialData = useSelector(state =>
    billOperationsSelectors.getInitialData(state)
  );

  const alert = msg => dispatch(commonOperations.addAlert(msg, 'danger'));

  const email = R.pathOr(
    '',
    ['printReportsData', 'email', 'id'],
    billReviewState
  );
  const prevEmail = usePrevious(email);

  const billTypeList = R.pathOr(
    [],
    ['printReportsData', 'billtypes'],
    billReviewState
  );
  const billingTypeList = R.pathOr(
    [],
    ['printReportsData', 'billingtypes'],
    billReviewState
  );
  const ppoList = R.pathOr([], ['printReportsData', 'ppos'], billReviewState);
  const reportList = R.pathOr(
    [],
    ['printReportsData', 'reports'],
    billReviewState
  );
  const reportTypeList = R.pathOr(
    [],
    ['printReportsData', 'reporttypes'],
    billReviewState
  );
  const siteList = R.pathOr([], ['printReportsData', 'sites'], billReviewState);
  const stateList = R.pathOr(
    [],
    ['printReportsData', 'states'],
    billReviewState
  );
  const userList = R.pathOr([], ['printReportsData', 'users'], billReviewState);

  const userId = user && user.user_id;
  // const profile = billReviewInitialData.currentProfile;
  const { currentProfile, profiles } = billReviewInitialData;
  const profile =
    R.find(p => p.profileAlias === currentProfile, profiles || []) || {};
  const profileName = profile.database || '';

  const [formData, setFormData] = useState(defaultFormData);
  const [reportOptionsData, setReportOptionsData] = useState(
    defaultReportOptionsData
  );
  const [report, setReport] = useState('');
  const [ignoreCase, setIgnoreCase] = useState(true);
  const [ignoreAccents, setIgnoreAccents] = useState(true);
  const [trim, setTrim] = useState(true);
  const [matchFromStart, setMatchFromStart] = useState(true);

  const setFormFieldSelect = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );
  const setFormFieldSite = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );
  const setFormFieldUser = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );
  const setFormFieldState = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );

  const setFormFieldPpo = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );
  const setFormFieldBillType = useCallback(
    (value, name) => {
      if (value != null) {
        setFormData(R.assoc(name.name, value.id, formData))
      } else {
        setFormData(R.assoc(name.name, '', formData))
      }
    },
    [formData]
  );

  const setFormField = useCallback(
    (name, value) => {
      setFormData(R.assoc(name, value, formData))
    },

    [formData]
  );
  const setDropdownDataReport = (value) => {
    setReport(value)

  }

  const toggleReportOption = id =>
    setReportOptionsData(
      R.assoc(id, !reportOptionsData[id], reportOptionsData)
    );

  const resetFormData = () => {
    setFormData(defaultFormData);
    setReportOptionsData(defaultReportOptionsData);
    resetClaimList();
    resetEmployerList();
    resetClaimantList();
    resetProviderList();
  };

  useEffect(() => {
    const getPrintReportsData = data =>
      dispatch(billOperationsOperations.getPrintReportsData(data));
    const resetPrintReportsData = () =>
      dispatch(billOperationsActions.resetPrintReportsData());

    const params = {
      userId,
      profile: printProfile,
    };
    if (isOpen) {
      resetPrintReportsData();
      getPrintReportsData(params);
    }
  }, [dispatch, isOpen, userId, printProfile]);

  useEffect(() => {
    if (!R.isEmpty(email) && !R.isNil(email) && email !== prevEmail) {
      setFormField('email', email);
    }
  }, [email, prevEmail, setFormField]);

  const toggleModal = () => {
    resetFormData();
    onClose();
  };

  const handleTypeAheadSearch = (event, type) => {
    event.persist();
  
    switch (type) {
      case 'employer':
       if(event.target.value.length > 2 && (event.keyCode === 13 || event.keyCode === 9)) {
      const params = {
        profile: printProfile,
        userId: userId,
        employer: event.target.value 
      }
      getEmployerList(params);
    } else if(event.target.value.length > 0 && employerListData.length) {
      resetEmployerList();
    }
    break;
    case 'claimant':
       if(event.target.value.length > 2 && (event.keyCode === 13 || event.keyCode === 9)) {
      const params = {
        profile: printProfile,
        userId: userId,
        claimant: event.target.value 
      }
      getClaimantList(params);
    } else if(event.target.value.length > 0 && claimantListData.length) {
      resetClaimantList();
    }
    break;
    case 'providerTin':
       if(event.target.value.length > 2 && (event.keyCode === 13 || event.keyCode === 9)) {
      const params = {
        profile: printProfile,
        userId: userId,
        provider: event.target.value 
      }
      getProviderList(params);
    } else if(event.target.value.length > 0 && providerListData.length) {
      resetProviderList();
    }
    break; 
    case 'claim':
       if(event.target.value.length > 2 && (event.keyCode === 13 || event.keyCode === 9)) {
      const params = {
        profile: printProfile,
        userId: userId,
        claim: event.target.value 
      }
      getClaimList(params);
    } else if(event.target.value.length > 0 && claimListData.length) {
      resetClaimList();
    }
    break;
  }
}
  
  const handleFormFields = (event, id, actionType) => {
    if(actionType && actionType.action === "clear" ) {
      switch(id) {
        case 'employer':
        resetEmployerList();
        break;
        case 'claimant':
        resetClaimantList();
        break;
        case 'claim':
        resetClaimList();
        break;
        case 'providerTin':
        resetProviderList();
        break;
      }
     }
    setFormData({...formData, [id]: event ? event.value : ""})
  }  

  const generateReport = (mode)=> {

    let isValid = true;
    let isISS = false; 

    // ZEBRA-419 making reports work like they do in legacy
    if (mode === "print" && formData.report === "ISS" && formData.billIdStart != "" && formData.billIdEnd === "") {
      // Allow for ISS exception
      isISS = true;
      isValid = true;
    }

    if (!isISS) {
      if (formData.report === '') {
        alert('You must select a report');
        isValid = false;
      }
  
      if (formData.site === '') {
        alert('You must select a full site name');
        isValid = false;
      }
  
      const startDate = formData.startDate.toString();
      const endDate = formData.endDate.toString();
      if (startDate === '' || endDate === '') {
        alert('You must select a starting and ending date range');
        isValid = false;
      }
  
      // FIXME: There appears to be a bug in old Zebra relating to this check. Re-add it to Z3 once we know what the intended behavior is.
      // if (formData.user === '') {
      //   alert('You must select a full user name');
      //   isValid = false;
      // }
   }

    if (!isValid) {
      return;
    }

    let actionUrl = null;
    let params = null;
    if (isISS) {
      actionUrl = new URL('/reports/iss.php',process.env.REACT_APP_SLP_BASE_URL);
      params = getISSParams(formData, reportOptionsData, profile);
    } else {
      actionUrl = new URL(
        mode === 'email' ? '/report_script.php' : '/report_try.php',
        process.env.REACT_APP_SLP_BASE_URL
      );
      params = getUrlSearchParams(formData, reportOptionsData, profile);
    }
    
    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `Print-Reports${new Date().getTime()}`
    );
  };

  const submitClassName = classnames('mt-sm-2', styles.submitButtons);
  const filterConfig = {
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom: matchFromStart ? 'start' : 'any',
  };
  return (
    <>
      <Modal size="lg" isOpen={isOpen} className={styles.modal}>
        <ModalHeader toggle={toggleModal}>Monthly Billing Reports</ModalHeader>
        <ModalBody>
          <Form
           
          >
            <Row form>
              <Col xs={8}>
                <FormGroup row>
                  <Col sm={8}>
                    <Label for="report">Report</Label> 
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldSelect}
                      filterOption={createFilter(filterConfig)}
                      //defaultValue={reportList.filter(option => option.id == formData.report)}
                      name="report"
                      id="report"
                      options={
                        reportList.length
                          ? reportList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="billingType">Billing Type</Label>
                    <Input
                      type="select"
                      id="billingType"
                      onChange={e =>
                        setFormField('billingType', e.target.value)
                      }
                      value={formData.billingType}
                    >
                      {R.map(
                        item => (
                          <option value={item.id}>{item.desc}</option>
                        ),
                        billingTypeList
                      )}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Label for="email">
                      E-Mail Address(s) to send report to
                    </Label>
                    <Input
                      type="email"
                      id="email"
                      onChange={e => setFormField('email', e.target.value)}
                      value={formData.email}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>
                    <Label for="billIdStart">Bill ID Start</Label>
                    <Input
                      type="text"
                      id="billIdStart"
                      onChange={e =>
                        setFormField('billIdStart', e.target.value)
                      }
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for="billIdEnd">Bill ID End</Label>
                    <Input
                      type="text"
                      id="billIdEnd"
                      onChange={e => setFormField('billIdEnd', e.target.value)}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for="startDate">Start Date</Label>
                    <AppDateInput
                      id="startDate"
                      onChange={value => setFormField('startDate', value)}
                      selected={formData.startDate ? formData.startDate : ''}
                      maxDate={moment().toDate()}
                      value={formData?.startDate || ''}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for="endDate">End Date</Label>
                    <AppDateInput
                      id="endDate"
                      onChange={value => setFormField('endDate', value)}
                      selected={formData.endDate ? formData.endDate : ''}
                      maxDate={moment().toDate()}
                      value={formData?.endDate || ''}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Label for="site">Site</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldSite}
                      placeholder="Choose..."
                      filterOption={createFilter(filterConfig)}
                      //defaultValue={reportList.filter(option => option.id == formData.report)}
                      name="site"
                      id="site"
                      options={
                        siteList.length
                          ? siteList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={9}>
                    <Label for="batchName">Batch Name</Label>
                    <Input
                      type="text"
                      id="batchName"
                      onChange={e => setFormField('batchName', e.target.value)}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for="providerTin">Provider Tin</Label>
                     <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) => handleFormFields(e, 'providerTin', actionType)}
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={ e => handleTypeAheadSearch(e, 'providerTin')}
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      menuIsOpen={providerListData.length && formData.providerTin === ''}
                      closeMenuOnSelect={true}
                      options={providerListData.length
                        ? providerListData.map(
                          obj => assign(obj, { label: obj.desc, value: obj.id })
                        )
                        : []}
                      name="providerTin"
                      id="providerTin"
                      />
                      <span>type in 3+letters<br/>and hit tab to<br/>search</span>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={4}>
                    <Label for="claim">Claim</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) => handleFormFields(e, 'claim', actionType)}
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={ e => handleTypeAheadSearch(e, 'claim')}
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      menuIsOpen={claimListData.length && formData.claim === ''}
                      closeMenuOnSelect={true}
                      options={claimListData.length
                        ? claimListData.map(
                          obj => assign(obj, { label: obj.desc, value: obj.id })
                        )
                        : []}
                      name="claim"
                      id="claim"
                      />
                      <span>type in 3+letters and hit tab to search</span>
                  </Col>
                  <Col sm={4}>
                    <Label for="adjuster">Adjuster</Label>
                    <Input
                      type="text"
                      id="adjuster"
                      onChange={e => setFormField('adjuster', e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="claimant">Claimant</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) => handleFormFields(e, 'claimant', actionType)}
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={ e => handleTypeAheadSearch(e, 'claimant')}
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      menuIsOpen={claimantListData.length && formData.claimant === ''}
                      closeMenuOnSelect={true}
                      options={claimantListData.length
                        ? claimantListData.map(
                          obj => assign(obj, { label: obj.desc, value: obj.id })
                        )
                        : []}
                      name="claimant"
                      id="claimant"
                      />
                      <span>type in 3+letters and hit tab to search</span>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={5}>
                    <Label for="user">User</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldUser}
                      filterOption={createFilter(filterConfig)}
                      //defaultValue={reportList.filter(option => option.id == formData.report)}
                      placeholder="Choose..."
                      name="user"
                      id="user"
                      options={
                        userList.length
                          ? userList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for="state">State</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldState}
                      filterOption={createFilter(filterConfig)}
                     defaultValue={[{ id: "All", desc: "All", label: "All", value: "All" }]}
                      name="state"
                      id="state"
                      options={
                        stateList.length
                          ? stateList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="reportType">Line of Business</Label>
                    <Input
                      type="select"
                      id="reportType"
                      onChange={e => setFormField('reportType', e.target.value)}
                      value={formData.reportType}
                    >
                      {R.map(
                        item => (
                          <option value={item.id}>{item.desc}</option>
                        ),
                        reportTypeList
                      )}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="employer">Employer</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={(e, actionType) => handleFormFields(e, 'employer', actionType)}
                      placeholder=""
                      menuPlacement="bottom"
                      onKeyDown={e => handleTypeAheadSearch(e, 'employer')}
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      menuIsOpen={employerListData.length && formData.employer === ''}
                      closeMenuOnSelect={true}
                      options={employerListData.length
                        ? employerListData.map(
                          obj => assign(obj, { label: obj.desc, value: obj.id })
                        )
                        : []}
                      name="employer"
                      id="employer"
                    />
                     <span>type in 3+letters and hit tab to search</span>
                  </Col>
                  <Col sm={6}>
                    <Label for="ppo">PPO Network</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldPpo}
                      placeholder="Choose..."
                      menuPlacement="top"
                      filterOption={createFilter(filterConfig)}
                      //defaultValue={reportList.filter(option => option.id == formData.report)}
                      name="ppo"
                      id="ppo"
                      options={
                        ppoList.length
                          ? ppoList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Label for="billType">Bill Type</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onChange={setFormFieldBillType}
                      menuPlacement="top"
                      placeholder="Choose..."
                      filterOption={createFilter(filterConfig)}
                      //defaultValue={reportList.filter(option => option.id == formData.report)}
                      name="billType"
                      id="billType"
                      options={
                        billTypeList.length
                          ? billTypeList.map(
                            obj => assign(obj, { label: obj.desc, value: obj.id })
                          )
                          : []
                      }
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={4} className={styles.reportOptions}>
                <FormGroup tag="fieldset">
                  <legend className="col-form-label">Report Options</legend>
                  {R.map(reportOption => {
                    const disabled =
                      reportOption.id === 'useEntryDate' &&
                      printProfile !== 'slp_prod_loc_reny';

                    return (
                      <FormGroup check className={styles.reportOptionsItem}>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={reportOption.id}
                            disabled={disabled}
                            checked={reportOptionsData[reportOption.id]}
                            onChange={() => toggleReportOption(reportOption.id)}
                          />
                          <span className={disabled ? 'text-muted' : ''}>
                            {reportOption.label}
                          </span>
                        </Label>
                      </FormGroup>
                    );
                  }, reportOptions)}
                </FormGroup>
              </Col>
            </Row>
            <div className={submitClassName}>
              <Button
                type="button"
                id="emailSubmit"
                onClick={e => {
                  e.preventDefault();
                  generateReport('email');
                }}
              >
                EMAIL REPORT
              </Button>
              <Button type="button" id="printSubmit" className="ml-sm-1"
              onClick={e => {
                    e.preventDefault();
                    generateReport('print');
                }}>
                PRINT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

PrintReportsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getEmployerList: PropTypes.func,
  getClaimantList: PropTypes.func,
  employerListData: PropTypes.array,
  resetEmployerList: PropTypes.func,
  resetClaimantList: PropTypes.func,
  claimantListData: PropTypes.func,
  getClaimList: PropTypes.func,
  getProviderList: PropTypes.func,
  resetProviderList: PropTypes.func,
  resetClaimList: PropTypes.func,
  claimListData: PropTypes.array,
  providerListData: PropTypes.array,
  printProfile: PropTypes.string
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const billReviewState = billOperationsSelectors.getBillReview(state);
  return {
    userId: user && user.user_id,
    printProfile: billReviewInitialData.currentProfile,
    employerListData: billReviewState.employerListData,
    claimantListData: billReviewState.claimantListData,
    providerListData: billReviewState.providerListData,
    claimListData: billReviewState.claimListData
  };
};

const mapDispatchToProps = dispatch => ({
  getEmployerList: data =>
    dispatch(billOperationsOperations.getEmployerList(data)),
  resetEmployerList: () =>
    dispatch(billOperationsActions.resetEmployerList()),
  getProviderList: data =>
    dispatch(billOperationsOperations.getProviderList(data)),
  resetProviderList: () =>
    dispatch(billOperationsActions.resetProviderList()),
  getClaimantList: data =>
    dispatch(billOperationsOperations.getClaimantList(data)),
  resetClaimantList: () =>
    dispatch(billOperationsActions.resetClaimantList()),
  getClaimList: data =>
    dispatch(billOperationsOperations.getClaimList(data)),
  resetClaimList: () =>
    dispatch(billOperationsActions.resetClaimList()),
 alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintReportsModal);
