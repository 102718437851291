export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const getFilenameExtension = fileName =>
  fileName ? fileName.match(/\.[0-9a-z]+$/i)[0] : ''

const base64ToBlob = (base64Str, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64Str);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const parseBase64String = (encoded, fileName) => {
  // const matches = encoded.match(
  // /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,(.*)/
  // );
  // const contentType = matches[1];
  // const blob = base64ToBlob(matches[2], contentType);
  // TODO: Filename is not reliable. Get MIME Type from API.

  let contentType = '';
  const fileExt = getFilenameExtension(fileName);
  switch (fileExt) {
    case '.pdf':
      contentType = 'application/pdf';
      break;
    case '.jpg':
    case '.jpeg':
      contentType = 'image/jpeg';
      break;
    case '.png':
      contentType = 'image/png';
      break;
    case '.PNG':
      contentType = 'image/png';
      break;
    case '.tif':
    case '.tiff':
      contentType = 'image/tiff';
      break;
    case '.gif':
      contentType = 'image/gif';
      break;
    case '.doc':
      contentType = 'application/msword';
      break;
    case '.docx':
      contentType =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case '.xls':
      contentType = 'application/vnd.ms-excel';
      break;
    case '.xlsx':
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    default:
      contentType = '';
  }

  const blob = base64ToBlob(encoded, contentType);
  return { blob, contentType };
};
export const saveBase64File = (fileName, encoded) => {
  const { blob, contentType } = parseBase64String(encoded, fileName);
  const typedBlob = new Blob([blob], { type: contentType });
  // Required for IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(typedBlob, fileName);
    return;
  }
  const downloadLink = document.createElement('a');
  // Remove the "if", and PDF goes straight to download instead of viewing in browser
  if (contentType !== 'application/pdf') {
    downloadLink.download = fileName;
  }
  // downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(typedBlob);
  downloadLink.target = '_blank';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(typedBlob);
  }, 100);
  document.body.removeChild(downloadLink);
};
