import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';
import { AppTableBodyPropType } from './AppTablePropTypes';

class UserAccessTableBody extends Component {
  render() {
    return (
      <tbody>
        {this.props.data.map((dataObj, index) => (
          <tr
            key={index}
            onClick={() =>
              this.props.showAccessCheckboxes &&
              this.props.showAccessCheckboxes(dataObj.id)
            }
            style={
              dataObj.id === this.props.activeAccessPanel
                ? { backgroundColor: '#fff3cd' }
                : {}
            }
          >
            {this.props.columns.map((column, colIndex) => {
              let columnValue = dataObj[column.field];
              let id = dataObj[column.id];
              if (columnValue === true || columnValue === false) {
                return (
                  <td key={colIndex}>
                    <CustomInput
                      type="checkbox"
                      checked={columnValue}
                      id={id + '_' + column.field}
                      name={id + '_' + column.field}
                      onChange={this.props.updateDataTable}
                    />
                  </td>
                );
              } else {
                return <td key={colIndex}>{columnValue}</td>;
              }
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}

UserAccessTableBody.propTypes = AppTableBodyPropType;

export default UserAccessTableBody;
