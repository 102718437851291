import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import PopupModal from 'shared/components/PopupModal';

const ValidationErrorsModal = ({ isOpen, onToggle, errors }) => {
    const onSaveHandler = () => {
        onToggle();
    }
    const inputErrors = Object.keys(errors).filter(
        key => errors[key].length > 0
    );
    const getContent = () => {
        return (
            <Form>
                <Row className="box">
                    <div style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
                        {(inputErrors.length === 1 ? ((inputErrors.findIndex(x => x === 'Old password1') < 0)&& <b>Some problems were found:</b>) : (<b>Some problems were found:</b>))}
                        <br />
                        <span>
                            {inputErrors.map((key, index) => (
                                <span key={index}>
                                    <br />
                                    {errors[key]}
                                </span>
                            ))}
                        </span>
                        <br />
                        <br />
                    </div>
                </Row>
                <Button
                    outline
                    color="primary"
                    style={{ marginLeft: '200px', marginRight: '200px' }}
                    onClick={() => onSaveHandler()}>
                    Ok{' '}
                </Button>
            </Form>
        );
    };

    return (
        <PopupModal
            content={getContent()}
            externalToggle={onToggle}
            title={"Password Change"}
            isOpen={isOpen}
            size="md"
        />
    );
}
export default ValidationErrorsModal;