import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  FormFeedback,
} from 'reactstrap';

const IndividualExpertRecordDeliveryNotificationTab = ({
  data,
  errors,
  onInputChange,
  onInputMaskChange,
  notificationTypes,
}) => (
  <Row>
    <Col lg="8">
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label for="primaryEmailAddress">Primary Email Address</Label>
            <Input
              type="text"
              name="email1"
              id="email1"
              value={data.email1}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Label for="email2">Secondary Email Address</Label>
            <Input
              type="text"
              name="email2"
              id="email2"
              value={data.email2}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Label for="notificationMethod">
              Preferred Method of Notification
            </Label>
            <Input
              type="select"
              name="notificationMethod"
              id="notificationMethod"
              value={data.notificationMethod}
              onChange={onInputChange}
            >
              <option key={-1} value="-1" disabled>
                Choose...
              </option>
              {notificationTypes.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.element}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label for="cellphone">Cell Phone</Label>
            <InputMask
              mask="999-999-9999"
              alwaysShowMask={true}
              name="cellphone"
              id="cellphone"
              value={data.cellphone}
              onChange={onInputMaskChange}
              invalid={errors.cellphone.length > 0}
            >
              <Input />
            </InputMask>
            {errors.cellphone.length > 0 && (
              <FormFeedback tooltip>{errors.cellphone}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label for="pager">Pager</Label>
            <InputMask
              mask="999-999-9999"
              alwaysShowMask={true}
              name="pager"
              id="pager"
              value={data.pager}
              onChange={onInputMaskChange}
              invalid={errors.pager.length > 0}
            >
              <Input />
            </InputMask>
            {errors.pager.length > 0 && (
              <FormFeedback tooltip>{errors.pager}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label for="pagerPin">Pin</Label>
            <Input
              type="text"
              name="pagerPin"
              id="pagerPin"
              value={data.pagerPin}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="callForAssignment"
              id="callForAssignment"
              label="Call Before Assigning"
              checked={data.callForAssignment}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="onlineTaskList"
              id="onlineTaskList"
              label="Manage Referrals online via TaskList"
              checked={data.onlineTaskList}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          {/* TODO: handle setup expert button when having enough API*/}
          <Button disabled>Click to setup expert as Zebra user</Button>
        </Col>
      </Row>
    </Col>
    <Col lg="4">
      <Row>
        <Col lg="12">
          <Label for="maxFaxedPages">Maximum Faxed Pages</Label>
          <FormGroup row>
            <Col lg="6">
              <Input
                type="number"
                name="maxFaxedPages"
                id="maxFaxedPages"
                value={data.maxFaxedPages}
                onChange={onInputChange}
                min="0"
              />
            </Col>
            <Label lg="6" for="maxFaxedPages">
              Pages
            </Label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <Label for="maxPageCount">Maximum Page Count</Label>
          <FormGroup row>
            <Col lg="6">
              <Input
                type="number"
                name="maxPageCount"
                id="maxPageCount"
                value={data.maxPageCount}
                onChange={onInputChange}
                min="0"
              />
            </Col>
            <Label lg="6" for="maxFaxedPages">
              Pages
            </Label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <Label for="maxFileSizeMB">Maximum File Size</Label>
          <FormGroup row>
            <Col lg="6">
              <Input
                type="number"
                name="maxFileSizeMB"
                id="maxFileSizeMB"
                value={data.maxFileSizeMB}
                onChange={onInputChange}
                min="0"
              />
            </Col>
            <Label lg="6" for="maxFileSizeMB">
              MB
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

IndividualExpertRecordDeliveryNotificationTab.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  notificationTypes: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputMaskChange: PropTypes.func.isRequired,
};

export default IndividualExpertRecordDeliveryNotificationTab;
