import React, { Component } from 'react';

class EventDetailTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toolTip = React.createRef();
  }

  componentDidMount() {
    this.toolTip.current.style.top =
      this.props.cursorPosition.clientY + 25 + 'px';
    const rightWidth =
      window.innerWidth - this.props.cursorPosition.clientX - 10;
    if (rightWidth < this.toolTip.current.offsetWidth) {
      this.toolTip.current.style.right = rightWidth + 'px';
      this.toolTip.current.style.left = 'initial';
    } else {
      this.toolTip.current.style.left =
        this.props.cursorPosition.clientX + 10 + 'px';
      this.toolTip.current.style.right = 'initial';
    }
  }

  render() {
    return (
      <div
        className="event-detail-tooltip-wrapper position-fixed p-1
            shadow rounded"
        ref={this.toolTip}
      >
        {this.props.children}
      </div>
    );
  }
}

export default EventDetailTooltip;
