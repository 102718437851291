import helper from 'helper/helper';

const findClients = async data => {
  const body = {
    profile: data.profile,
    siteName: data.siteName,
    zebraUserId: data.zebraUserId,
  };
  if (data.excludeEmployers) {
    body.functionName = 'siteLookUpNoEmp';
  } else {
    body.functionName = 'siteLookUp';
  }

  const response = await helper.request.post(`/loadGuiDaoloadArrayData`, body);
  return response.data.length > 0 ? response.data[0] : [];
};

export default {
  findClients,
};
