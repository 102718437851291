export const transformItems = (items, fieldMap) => {
    return items.filter(item => item[fieldMap.key] !== undefined && item[fieldMap.key] !== '')
        .map(item => transformItem(item, fieldMap));
}

export const transformItem = (item, fieldMap) => {
    return Object.keys(fieldMap.fields).reduce((item, field) => {
        return item[field] !== undefined ? item : { [field]: fieldMap.fields[field], ...item};
    }, item);
}

export const expertUsersCoreDataFieldMap = {
    key: 'id',
    fields: {
        fullname: ''
    }
}

export const taskFieldMap = {
    key: 'id',
    fields: {
        category_name: '',
        expert_name: '',
        description: '',
        due_date: ''
    }
}