import React, { useState } from 'react';
import { connect, useSelector  } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import services from '../services/service';
import { fast360Selectors, fast360Operations, fast360Actions } from '../store/index';

const DashboardBilling = ({billingDashboardData,  
  userId, 
  zebraUserId, 
  setSelectedUserId, 
  getUsersWorkList, 
  updateClickRecord,
  updateStatusClicked,
  updateMilestoneClicked,
  updateTaskClicked,
  updateMilestoneData,
   }) => {
  const users = useSelector((state) => state.FAST360.initialData.roleUsers.users);
  const [billingMilestoneData, setBillingMilestoneData] = useState([]);
  const [billingUserData, setBillingUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const getDollars = billdata => {
    return '$' + billdata.dollars ;
  };

  
  const CAcolumns = [
    {
      header: 'Milestones',
      field: 'serviceStateTranslation',   
      width: '200px',          
    },
    {
      header: 'On Time',
      field: 'onTime',
    },
    {
      header: 'OOC',
      field: 'late',
    },
    {
      header: 'Dollars',
      field: 'dollars',
      render: item => getDollars(item),
    },
  ];
  const CAcolumns2 = [
    {
      header: 'Status',
      field: 'serviceState',   
      width: '200px',          
    },
    {
      header: 'On Time',
      field: 'onTime',
    },
    {
      header: 'OOC',
      field: 'late',
    },
    {
      header: 'Dollars',
      field: 'dollars',
      render: item => getDollars(item),
    },
  ];
  const CAcolumns3 = [
    {
      header: 'User',
      field: 'assignedUser',   
      width: '200px',          
    },
    {
      header: 'On Time',
      field: 'onTime',
    },
    {
      header: 'OOC',
      field: 'late',
    },
    {
      header: 'Dollars',
      field: 'dollars',
      render: item => getDollars(item),
    },
  ];



  const handleMileStoneChange = e => {
      const milestones = e.FK_listelement__state_id;
      const billStatusData= services.loadBillingByMileStone(userId, zebraUserId, milestones);
          Promise.all([billStatusData]).then(([workload]) => {
            setBillingMilestoneData(workload); })
            
  };
  const handleStatusChange = e => {
    const milestones = e.FK_listelement__state_id;
    const statusName = e.serviceState;
    const users = e.assignedUsers;
      const billingUserData= services.loadBillingByStatus(userId, zebraUserId, milestones, statusName, users);
          Promise.all([billingUserData]).then(([workload]) => {
            setBillingUserData(workload); })
  };
  const handleUserChange = e => {
    const user =
          users &&
          users.find(u => u.id == e.FK_assigned_user_id);
      // this.props.updatePreviousUser(this.state.userSelected);
        setSelectedUser(user)
        setSelectedUserId(user.zebraId)
        getUsersWorkList(user, user.zebraId);
        updateClickRecord();
        updateStatusClicked(false);
       updateMilestoneClicked(false);
        updateTaskClicked(false);
        updateMilestoneData([]);
  };

  return (
    <>
      <span className="bold-text">Breakdown of Milestones</span>
      <AppTable data={billingDashboardData} columns={CAcolumns} 
               modalHeight={150}
              rowClickHandler={handleMileStoneChange}
              sortAble={true}
              resizable={true}
             ></AppTable>
      <span className="bold-text">Breakdown of Status By Milestone</span>
      <AppTable data={billingMilestoneData} columns={CAcolumns2}  
               modalHeight={250}
              rowClickHandler={handleStatusChange}
              sortAble={true}
              resizable={true}
             ></AppTable>
      <span className="bold-text">Breakdown of Users by Status</span>
      <AppTable data={billingUserData} columns={CAcolumns3}  
              modalHeight={250}
              rowClickHandler={handleUserChange}
              sortAble={true}
              resizable={true}
             ></AppTable>
     </>
  );
};

const mapStateToProps = state => {
  const fast360Info = state.FAST360.initialData.roleUsers.users;
  return ({
    fast360Users: state.FAST360.initialData.roleUsers.users,
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
    userId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    quickSearchCheck: state.FAST360.fast360Reducer.quickSearchCheck ? state.FAST360.fast360Reducer.quickSearchCheck : {},
  });
}

const mapDispatchToProps = dispatch => ({
  getUsersWorkList: (data, zebraId) => fast360Operations.getUsersWorkList(data, zebraId),
  updateNewUsersWorkList: data =>
    dispatch(fast360Actions.updateNewUsersWorkList(data)),
  getUsersWorkList: (data, currentUserId) =>
    dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
  updateClickRecord: () => dispatch(fast360Actions.updateClickRecord([])),
  updatePreviousUser: data => dispatch(fast360Actions.updatePreviousUser(data)),
  updateStatusClicked: data =>
    dispatch(fast360Actions.updateStatusClicked(data)),
  updateMilestoneClicked: data =>
    dispatch(fast360Actions.updateMilestoneClicked(data)),
  updateTaskClicked: data => dispatch(fast360Actions.updateTaskClicked(data)),
  updateMilestoneData: data =>
    dispatch(fast360Actions.updateMilestoneData(data)),
  runQuickSearchCheck: (data) => dispatch(fast360Operations.quickSearchCheck(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBilling);

