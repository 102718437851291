import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { CustomInput, Input } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import {
  sortByType,
  formatMMDDYYYY,
  isValidDate,
} from 'shared/utils/shareUtils';

import { getValueByKey } from '../../../utils/billMaintenanceUtil';

class PPOSetupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      editColumns: {},
    };
  }

  updateEditColumnsState = (id, value) => {
    this.setState({
      editColumns: {
        ...this.state.editColumns,
        [id]: value,
      },
    });
  };

  onChangeHandler = (targetId, value) => {
    this.updateEditColumnsState(targetId, value);
    this.updateTableData(targetId, value);
  };

  onChangeEffectiveDateHandler = (targetId, value) => {
    if (this.props.editColumns['effDate']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeTerminatedDateHandler = (targetId, value) => {
    if (this.props.editColumns['termDate']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeDescriptionHandler = (targetId, event) => {
    if (this.props.editColumns['name']) {
      const value = event.target.value;
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onSelectLineOfBusinessHandler = (id, item) => {
    if (this.props.editColumns['lob']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.lobList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectStateHandler = (id, item) => {
    if (this.props.editColumns['state']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.stateList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectNetworkHandler = (id, item) => {
    if (this.props.editColumns['network']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.ppoList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  getDescriptionColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_name_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      value = item.name || '';
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <Input
        id={id}
        type="text"
        name={id}
        value={value}
        onChange={event => this.onChangeDescriptionHandler(id, event)}
        disabled={!this.props.editColumns['name']}
      />
    );
  }

  getLineOfBusinessColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_lob_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      const key = ((item.lob || {}).fields || {}).lob || '';
      value = getValueByKey(this.props.lobList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.lobList}
        value={value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectLineOfBusinessHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['lob'],
        }}
      />
    );
  }

  getNetworkColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_network_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      //Set default value
      const key = item.hasOwnProperty('ppoId') ? item.ppoId + '' : '';
      value = getValueByKey(this.props.ppoList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.ppoList}
        value={value === '' ? 'No PPO' : value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectNetworkHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['network'],
        }}
      />
    );
  }

  getStateColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_state_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = item.hasOwnProperty('state') ? item.state + '' : '';
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.stateList}
        value={value}
        displayOption="key"
        onChange={this.onChangeHandler}
        onSelect={this.onSelectStateHandler}
        showToolTip={false}
        updateOnLeave={false}
        placeholder=""
        inputProps={{
          disabled: !this.props.editColumns['state'],
        }}
      />
    );
  }

  getRankColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_ppoRank_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = item.ppoRank || 0;
    }

    return (
      <Input
        type="text"
        id={id}
        onChange={event =>
          this.onChangeHandler(event.target.id, event.target.value)
        }
        value={value}
        disabled={!this.props.editColumns['ppoRank']}
      />
    );
  }

  getEffectiveColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_effDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).effDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }

    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeEffectiveDateHandler(id, value)}
        selected={value}
        disabled={!this.props.editColumns['effDate']}
      />
    );
  }

  getTerminatedColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_dateRange_termDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).termDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }
    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeTerminatedDateHandler(id, value)}
        selected={value}
      />
    );
  }

  getSelectActionColumn(item) {
    if (!!!item.fields) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          id={`${this.props.name}-select-${item.fields.id}`}
          name={`${this.props.name}-select-${item.fields.id}`}
          type="checkbox"
          checked={this.state.selectedRows.includes(item)}
          onChange={event => {
            this.selectItem(item, event);
          }}
        />
      </div>
    );
  }

  sortColumn(item1, item2, order, field) {
    const value1 = (item1.fields || {})[field] || '';
    const value2 = (item2.fields || {})[field] || '';
    return sortByType(value1, value2, order);
  }

  sortLineOfBusinessColumn(item1, item2, order) {
    const value1 = (((item1.fields || {}).lob || {}).fields || {}).lob || '';
    const value2 = (((item2.fields || {}).lob || {}).fields || {}).lob || '';
    return sortByType(value1, value2, order);
  }

  sortEffectiveColumn(item1, item2, order) {
    const value1 = ((item1.fields || {}).dateRange || {}).effDate || '';
    const value2 = ((item2.fields || {}).dateRange || {}).effDate || '';
    return sortByType(value1, value2, order);
  }

  sortTerminatedColumn(item1, item2, order) {
    const value1 = ((item1.fields || {}).dateRange || {}).termDate || '';
    const value2 = ((item2.fields || {}).dateRange || {}).termDate || '';
    return sortByType(value1, value2, order);
  }

  selectAll = event => {
    const result = event.target.checked ? this.props.data.slice() : [];
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  selectItem = (item, event) => {
    const result = event.target.checked
      ? this.state.selectedRows.concat(item)
      : this.state.selectedRows.filter(row => row.fields.id !== item.fields.id);
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  updateTableData = (targetId, value) => {
    const [, columnId, itemId, columnId2, itemId2] = targetId.split('_');
    if (columnId && itemId) {
      if(columnId2 && columnId2 === "termDate" && itemId2){
        let item = R.find(
          item => item.fields.id + "" === itemId2,
          this.props.data
        );
        if (item) {
          item.fields.dateRange[columnId2] = formatMMDDYYYY(value);
        }
      }else{
        let item = R.find(
          item => item.fields.id + '' === itemId,
          this.props.data
        );
        if (item) {
          if (columnId === 'termDate' || columnId === 'effDate') {
            item.fields.dateRange[columnId] = formatMMDDYYYY(value);
          } else if (columnId === 'lob') {
            item.fields.lob.fields.lob = value;
          } else {
            item.fields[columnId] = value;
          }
        }
      }
    }
    if (!this.props.isSelectable) {
      this.props.updateTableData(this.props.data);
    }
  };

  render() {
    let { data } = this.props;
    const { filters } = this.props;
    if (filters) {
      if (filters.state) {
        data = data.filter(item => {
          return item.fields.state === filters.state;
        });
      }

      if (filters.lineOfBusiness) {
        data = data.filter(item => {
          const lob = ((item.fields.lob || {}).fields || {}).lob || '';
          return lob === filters.lineOfBusiness;
        });
      }

      if (filters.hasOwnProperty('effective')) {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        data = data.filter(item => {
          if (item.fields.hasOwnProperty('dateRange') && filters.effective) {
            const effDate = new Date(item.fields.dateRange.effDate);
            const termDate = new Date(item.fields.dateRange.termDate);
            if (filters.effective) {
              if (effDate <= today && termDate >= today) {
                return true;
              } else {
                return false;
              }
            } else {
              if (effDate >= today || termDate <= today) {
                return true;
              } else {
                return false;
              }
            }
          }
          return true;
        });
      }
    }

    let columns = [
      {
        header: 'Description',
        field: 'name',
        render: item => this.getDescriptionColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'name'),
      },
      {
        header: 'LOB',
        field: 'lob',
        render: item => this.getLineOfBusinessColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortLineOfBusinessColumn(item1, item2, order, 'lob'),
      },
      {
        header: 'Network',
        field: 'ppoId',
        render: item => this.getNetworkColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'ppoId'),
      },
      {
        header: 'Rank',
        field: 'ppoRank',
        render: item => this.getRankColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'ppoRank'),
      },
      {
        header: 'State',
        field: 'state',
        render: item => this.getStateColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'state'),
      },
      {
        header: 'Effective',
        field: 'effDate',
        render: item => this.getEffectiveColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortEffectiveColumn(item1, item2, order),
      },
      {
        header: 'Terminated',
        field: 'termDate',
        render: item => this.getTerminatedColumn(item.fields || {}),
        sortFunction: (item1, item2, order) =>
          this.sortTerminatedColumn(item1, item2, order),
      },
    ];
    if (this.props.isSelectable) {
      columns.push({
        header: (
          <div className="select-action">
            <CustomInput
              id={`${this.props.name}-selectAll`}
              name={`${this.props.name}-selectAll`}
              type="checkbox"
              checked={
                this.state.selectedRows.length === data.length &&
                data.length !== 0
              }
              onChange={this.selectAll}
            />
          </div>
        ),
        field: 'selectAction',
        sortAble: false,
        render: item => this.getSelectActionColumn(item),
      });
    }
    return (
      <AppTable
        columns={columns}
        data={data}
        autoPagination={true}
        sortAble={true}
        resizable={true}
      />
    );
  }
}

PPOSetupTable.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  editColumns: PropTypes.object.isRequired,
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  clientNetworkList: PropTypes.array.isRequired,
  updateTableData: PropTypes.func.isRequired,
  ppoList: PropTypes.array.isRequired,
};

export default PPOSetupTable;
