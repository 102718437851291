import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Row } from 'reactstrap';

import { authSelectors } from 'modules/auth/store';

import AppInput from 'shared/components/input/AppInput';
import PopupModal from 'shared/components/PopupModal';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { billOperationsOperations, billOperationsSelectors } from '../store';

class GridSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loadcount:0
    };
    this.toggle = this.toggle.bind(this);
  }
 
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  onChangeHandler = (targetId, value) => {
    const newStates = this.props.settingColumns.map(checkbox => {
      if (`chk${this.getUseableString(checkbox.columnName)}` === targetId) {
        return {
          ...checkbox,
          state: value,
        };
      }
      return checkbox;
    });

    this.props.updateCheckbox(newStates);
  };

  saveSettings = () => {
    const currentUserId = this.props.user.id.toString();
    const checkboxModal = this.props.settingColumns;
    let body = {
      gridName: this.props.gridSettingName,
      userId: currentUserId,
      moduleId: this.props.gridSettingModuleId,
      profile: this.props.profile,
      zebraUserId: currentUserId,
    };
    let count = 0;
    checkboxModal.forEach((col, index) => {
      
      if (col.state) {
        body = {
          ...body,
          [`column${count}`]: [
            {
              columnOrder: col.columnOrder,
              columnName: col.columnName,
            },
          ],
        };
        count++;
      }
    });

    this.props.saveGridSettings(body);

    this.toggle();
  };

  createModalBody = () => {
    const checkboxModal = this.props.settingColumns;
    return (
      <Row>
        {checkboxModal.map((item, index) => {
          if (item.columnName) {
            return (
              <Col lg="6" key={index}>
                <FormGroup>
                  <AppInput
                    changeHandler={this.onChangeHandler}
                    placeholder={item.columnName}
                    type="checkbox"
                    id={`chk${this.getUseableString(item.columnName)}`}
                    checked={item.state}
                    showToolTip={false}
                  />
                </FormGroup>
              </Col>
            );
          }
        })}
      </Row>
    );
  };
  createModalFooter = () => {
    return (
      <Button color="danger" onClick={this.saveSettings}>
        SAVE SETTINGS
      </Button>
    );
  };

  getUseableString = name => {
    let cbIdBase = name.replace(' ', '');
    return cbIdBase;
  };

  render() {
    return (
      <>
        <AppTooltip
          text="Show/Hide Bill Grid Columns"
          id={`btn${this.props.name}`}
        >
          <Button onClick={this.toggle} style={{ width: '100%'}} size="md" type="button">
            GRID SETTINGS
          </Button>
        </AppTooltip>
        <PopupModal
          content={this.createModalBody()}
          title="SHOW/HIDE TASK GRID COLUMNS"
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          footerContent={this.createModalFooter()}
          className={this.props.className}
        />
      </>
    );
  }
}

GridSettingsModal.propTypes = {
  profile: PropTypes.string.isRequired,
  settingColumns: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  updateCheckbox: PropTypes.func.isRequired,
  saveGridSettings: PropTypes.func.isRequired,
};

GridSettingsModal.defaultProps = {
  settingColumns: [],
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);

  return {
    profile: initialData.currentProfile,
    user: authSelectors.getUser(state),
  };
};
const mapDispatchToProps = dispatch => ({
  saveGridSettings: data =>
    dispatch(billOperationsOperations.changeGridSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridSettingsModal);
