import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { isEmpty } from 'lodash';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const PayorLookup = ({
  isOpen,
  onClose,
  userId,
  getClientInfo,
  clientInfo,
  resetSearchClientTable,
  loadClientWithFees,
  saveObj, 
  logBreadCrumbs,
  claimNumber,
  saveClaim,
  updatedClaimInfo,
  isNew,
  resetLoadClient,
  alert,
  NewPayorName,
  setIsExternal,
  setPayorEdited,
  fromExternal,
  fromWorklist,
  worklistData,
  updatePatientZip,
  patientInfo,
  isFromLanding,
  loadInfoFromExistingPatient,
  updatedPayorInfo
}) => {
  const [isOpenLoadPayorModal, setIsOpenLoadPayorModal] = useState(false);
  const [isOpenSearchClient, setIsOpenSearchClient] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [searchFields, setSearchFields] = useState({
    clientName: (fromExternal ? NewPayorName : '') || (!isFromLanding && loadInfoFromExistingPatient?.name),
  });
  const [formPayorFields, setFormPayorFields] = useState({
    payClaimNumber: '',
  });
  const [selectedItem, setSelectedItem] = useState(undefined);
  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSearchClient(true);
      } else {
        setIsOpenLoadPayorModal(true);
      }
    }
  }, [isOpen]);

  const getClientName = () => {
    let value = '';
    if (fromExternal)
      value = NewPayorName
    else if (fromWorklist && worklistData && worklistData.client && worklistData.client.name)
      value = worklistData?.client?.name
    return value;
  }
  useEffect(() => {
    if (isOpen) {
    setSearchFields({
      clientName: getClientName()
    });
    const payload = {
      clientName: getClientName() || '',
      userId,
    };
    if(!isFromLanding && isEmpty(updatedPayorInfo) && loadInfoFromExistingPatient?.name) {
      let param = {
        clientName: loadInfoFromExistingPatient?.name || '',
        userId,
      }
      getClientInfo(param);
    } else {
      (NewPayorName || searchFields.clientName != "" || getClientName()) && getClientInfo(payload);
    }
  }
  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.client?.id) {
        const item = clientInfo && clientInfo.filter(o => o.PK_client_id === worklistData?.client?.id);
        let index = clientInfo && clientInfo.findIndex(i => i.PK_client_id === worklistData?.client?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [clientInfo])

  useEffect(() => {
    if (selectedItem) {
      const params = {
        userId,
        id: selectedItem.PK_client_id,
      };
      loadClientWithFees(params);
      updatePatientZip({...patientInfo, clientPatientId: selectedItem.PK_client_id})
    }
  }, []);

  useEffect(() => {
    if (isOpenLoadPayorModal) {
      if (!isNew && claimNumber) {
        setFormPayorFields({
          payClaimNumber: claimNumber,
        });
      }
    }
  }, [isOpenLoadPayorModal]);

  const loadPayorInfoToggle = () => {
    setIsOpenLoadPayorModal(!isOpenLoadPayorModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
  };

  const searchClientToggle = () => {
    setIsOpenSearchClient(false);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      clientName: '',
    });
    resetSearchClientTable();
    resetLoadClient();
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchClientTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'Company Name',
              field: 'chr_companyname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'Client ID',
              field: 'PK_client_id',
            },
          ]}
          data={clientInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-referrer-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadClientModal = () => {
    const params = {
      userId,
      functionName: 'loadClientData',
      id: selectedItem.PK_client_id,
    };
    loadClientWithFees(params);

    if (worklistData != undefined){
      if (worklistData.id != undefined){
        const upcateClientParms = {
          zebraUserId: userId,
          functionName: 'updateClient',
          clientId: selectedItem.PK_client_id,
          referralId: worklistData.id,
        };
        saveObj(upcateClientParms);
        const breadCrumb = {
          functionName: 'logBreadCrumbs',
          zebraUserId: userId,
          userid: userId,
          breadcrumbkey: 'SAVECLIENT',
          refTableId: selectedItem.PK_client_id,
          refId: worklistData.id,
          refTable: 'REF',
        };
        logBreadCrumbs(breadCrumb);
      }
    }
    setIsOpenSearchClient(false);
    setPayorEdited(true)
    onClose();
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getClientInfo(params);
  };

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setFormPayorFields({ ...formPayorFields, [name]: value });
  };

  const onChangeCurrentPayor = () => {
    setSearchFields({
      clientName: '',
    });
    setIsOpenLoadPayorModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSearchClient(true);
  };

  const onSaveHandler = () => {
    if (claimNumber) {
      const response = updatedClaimInfo;
      response.claimNo = formPayorFields.payClaimNumber;
      const claimId = response.id;
      const params = {
        response,
        userId,
        claimId,
      };
      if (response.claimNo === '' || response.claimNo === undefined) {
        alert('You must provide a Claim Number in order to save.');
        return;
      }
      saveClaim(params);
      loadPayorInfoToggle();
    }
    // setIsExternal();
    setPayorEdited(true)
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadPayorModal}>
        <ModalHeader toggle={loadPayorInfoToggle}>ADD A NEW PAYOR</ModalHeader>
        <ModalBody>
          {(claimNumber ? (<FormGroup row>
            <Col sm={4}>
              <Label for="payClaimNumber">Claim#</Label>
              <Input
                type="text"
                id="payClaimNumber"
                value={formPayorFields.payClaimNumber || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>) :
            (
              <>
                <span>Please select the Claim Number from Patient section to edit</span>
                <br></br>
                <br></br>
                <FormGroup row>
                  <Col sm={4}>
                    <Label for="payClaimNumber">Claim#</Label>
                    <Input
                      type="text"
                      id="payClaimNumber"
                      value={formPayorFields.payClaimNumber || ''}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </>
            ))}
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentPayor}>
              CHANGE CURRENT PAYOR
            </Button>
            {'  '}
            <Button type="button" disabled={!claimNumber} onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSearchClient}>
        <ModalHeader toggle={searchClientToggle}>SEARCH CLIENT</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="clientName">Client Name</Label>
                  <Input
                    type="text"
                    id="clientName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.clientName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchClientTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadClientModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

PayorLookup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientInfo: PropTypes.array,
  getClientInfo: PropTypes.func,
  resetSearchClientTable: PropTypes.func,
  loadClientWithFees: PropTypes.func,
  saveObj: PropTypes.func,
  loadClientInfo: PropTypes.object,
  resetLoadClient: PropTypes.func,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    clientInfo: fast360Info.clientInfo,
    loadClientInfo: fast360Info.loadClientInfo,
    userId: user.id,
    ReferralSourceList: initialData.ReferralSourceList,
    patientInfo: fast360Info.patientZip,
    updatedPayorInfo: fast360Info.updatedPayorInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  getClientInfo: data => dispatch(fast360Operations.getClientInfo(data)),
  loadClientWithFees: data =>
    dispatch(fast360Operations.loadClientWithFees(data)),
  saveObj: data =>
    dispatch(fast360Operations.saveObj(data)),
  resetSearchClientTable: () =>
    dispatch(fast360Actions.resetSearchClientTable()),
  resetLoadClient: () => dispatch(fast360Actions.resetLoadClient()),
  saveClaim: data => dispatch(fast360Operations.saveClaim(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs : data =>
  dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayorLookup);
