export const siteDetailsDefault = {
  fields: {
    id: 0,
    name: '',
    parentId: 0,
    dateRange: {
      effDate: '',
      termDate: '',
    },
    contact: {
      fields: {
        contact: '',
        phone1: '',
        fax: '',
      },
    },
    ppoFsSettingHelper: {
      fields: {
        fsSettingList: [],
        ppoSettingList: [],
      },
    },
    siteSettingMap: {},
    eorProfile: '',
    naic: '',
    serviceCompanyCode: '',
    rateList: [],
  },
};

export const clientComboBoxesDefault = {
  settingsList: [],
  ppoList: [],
  stateList: [],
  settingTypeList: [],
  rateTypeList: [],
  lobList: [],
  clientNetworkList: [],
  logoList: [],
  billTypeList: [],
};
