import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations } from '../store';
import { billOperationsSelectors } from '../../bill-operations/store';
import moment from 'moment';

class StatusHover360 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            userSelected: '',
            users: [],
            selectedService: {},
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle = () => {
        this.props.clicked()
    };
    componentDidMount() {
        let users = [];
        if (this.props.users.length > 0) {
            users = this.props.users
            users.splice(0, 2)
        }
        this.setState({ users: users })
     //   this.setState({selectedService: this.props.selectedService })
    }
    handleUserChange = (e) => {
        this.setState({ userSelected: e.target.value });
    }
    handleTransfer = () => {
        let transferRecords = [];
        if (this.props.selectedService != undefined && this.props.selectedService !== null){
            transferRecords.push({
                "taskServiceId": this.props.selectedService.serviceTaskId,
                    "transferToId": this.state.userSelected,
                    "refIds": "",
                    "service": this.props.selectedService.module,
                    "serviceId": this.props.selectedService.serviceId,
                    "referralId": this.props.selectedService.referralId,
                    "currentTaskId": this.props.selectedService.currentTaskId,
                    "lmsUserId": this.props.lmsUserId,
                    "completeDate": moment().format("YYYY-MM-DD HH:mm:ss")
            })
            let breadCrumb = {
                "functionName": 'logBreadCrumbs',
                "zebraUserId": this.props.currentUserId,
                "userid": this.props.currentUserId,
                "breadcrumbkey": 'TRANSFERTASK',
                "refTableId": this.props.selectedService.serviceId,
                "refId": this.props.selectedService.referralId,
                "refTable": this.props.selectedService.module,
              };
              this.props.logBreadCrumbs(breadCrumb)
        }else if(this.props.updatedRec && this.props.updatedRec.length > 0){
            this.props.updatedRec.map(item => {
                transferRecords.push({
                    "taskServiceId": item.PK_service_id,
                    "transferToId": this.state.userSelected,
                    "refIds": "",
                    "service": item.chr_reference_table,
                    "serviceId": item.int_reference_table_id,
                    "referralId": item.FK_referral_id,
                    "currentTaskId": item.currentTaskID,
                    "lmsUserId": this.props.lmsUserId,
                    "completeDate": moment().format("YYYY-MM-DD HH:mm:ss")
                })
                let breadCrumb = {
                    "functionName": 'logBreadCrumbs',
                    "zebraUserId": this.props.currentUserId,
                    "userid": this.props.currentUserId,
                    "breadcrumbkey": 'TRANSFERTASK',
                    "refTableId": item.int_reference_table_id,
                    "refId": item.FK_referral_id,
                    "refTable": item.chr_reference_table,
                  };
                  this.props.logBreadCrumbs(breadCrumb)
            })
            
        }
        let payload = {
            "zebraUserId": this.props.currentUserId,
            "functionName": "updateWorklist",
            transferRecords
        }

        let records = [];

        if(this.props.selectedService != undefined && this.props.selectedService !== null) {
            const cnfNumber = `${this.props.selectedService.referralId}-${this.props.selectedService.module}-${this.props.selectedService.id}`;
            records.push({
                confNo: cnfNumber,
                assignedToUserName: this.props.selectedService.assignedUser,
                chr_reference_table: this.props.selectedService.module,
                FK_referral_id: this.props.selectedService.referralId,
                int_reference_table_id: this.props.selectedService.serviceId
            })
        } else {
            records = [...this.props.updatedRec];
        }

        
    
        records.forEach(item => {
            const itemNotes = [];
            const user = this.props.roleUsers.find(u => u.zebraId == this.props.currentUserId);
            const cnfNo = item.confNo.trim().split(' ')[0];
            let transferFrom = ''; 
            if(this.props.selectedUser && this.props.selectedUser === '1111' || this.props.selectedUser === '2222') {
                transferFrom = this.props.selectedUser == '2222' ? 'Unassigned' : 'Unbilled/Unassigned';
            } else if(!item.assignedToUserName) {
                transferFrom = this.props.currentWorkList.new_id == 2222 ? 'Unassigned' : 'Unbilled/Unassigned';
            } else {
                transferFrom = item.assignedToUserName ;
            }
            let transferTo = '';
            if(this.props.users) {
                const index = this.props.users.findIndex(i => i.id == this.state.userSelected);
                if(index !== -1) {
                    transferTo = this.props.users[index].username;
                }
            }
            
            const newNote = {
                'userName': `${this.props.userName.firstname} ${this.props.userName.lastname}`,
                'enteredDate': new Date(),
                'note': `Conf# ${cnfNo} transferred from ${transferFrom} to ${transferTo}`,
                'noteType': 'General',
                'canClientSee': false,
                "id": null,
                "referenceTable": item.chr_reference_table,
                "referenceId": item.FK_referral_id,
                "internalUse": true,
                "userId":user.id
            };
            itemNotes.push(newNote);
            let saveNotesPayload = {
                "functionName": "saveNotes",
                "zebraUserId": this.props.currentUserId,
                "serviceId": item.int_reference_table_id,
                notes: itemNotes
            }

            this.props.saveNotesData(saveNotesPayload);
        })
        this.props.postTransfer(payload);

        this.props.handleTransferFromModal();
        this.props.onWorklistExit && this.props.onWorklistExit();

    }

    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '300px' }} scrollable={'true'}>
                <ModalHeader toggle={this.toggle}> TRANSFER TO </ModalHeader>
                <ModalBody>
                    <div style={{ paddingBottom: '10px' }}>Worklist</div>
                    <Form>
                        <FormGroup>
                            <Input type="select" onChange={this.handleUserChange} defaultValue="">
                                <option value="" disabled={true}>Users</option>
                                {this.props.users && this.props.users.map(item => (
                                    <option value={item.id}>{item.username}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Form>
                    <div style={{ color: 'red' }}>
                        Orders can only be transferred to another user once it has been assigned and cannot be transfered back to the Unassigned or the Unbilled/Unassigned Worklist.
                    </div>
                </ModalBody>
                <ModalFooter> <Button color="primary" onClick={this.handleTransfer} disabled={this.state.userSelected !== "" ? false : true}>TRANSFER</Button></ModalFooter>
            </Modal >
        )
    }
}


const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    const roleUsers = state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [];
    //const billReviewInitialData = billOperationsSelectors.getInitialData(state);
    return {
        currentUserId: userId,
        users: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        //lmsUserId: billReviewInitialData.LmsuserId,
        lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
        userName: state.AUTH.user,
        currentWorkList: state.FAST360.fast360Reducer.currentWorkListItem,
        roleUsers
    };
};



const mapDispatchToProps = dispatch => ({
    postTransfer: (data) => dispatch(fast360Operations.postTransfer(data)),
    logBreadCrumbs : data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
    // getUsersWorkList: (data, currentUserId) => dispatch(fast360Operations.getUsersWorkList(data, currentUserId))
    saveNotesData: (data) => dispatch(fast360Operations.saveNotesData(data)),
});




export default connect(mapStateToProps, mapDispatchToProps)(StatusHover360);