import { CHANGE_BILL_MODULE } from './constants';

const changeModule = module => ({
  type: CHANGE_BILL_MODULE,
  payload: {
    module: module,
  },
});

export default {
  changeModule,
};
