import * as R from 'ramda';

import objectUtils from 'core/utils/objectUtils';
import * as TypeUtils from 'shared/utils/typeUtils';

import adminObjectUtils from '../utils/adminObjectUtils';

export const findUserTransform = {
  toRequestBody: (name, location) => {
    return {
      action: 'search',
      lastname: name + '%',
      location: location !== undefined && location !== '' ? location : null,
    };
  },
};

export const loadUserDataTransform = {
  toRequestBody: id => {
    return {
      'query-vw_user_edit.vw_user_edit': {
        user_id: id,
      },
      'picklist.salutation': {
        name: 'salutation',
      },
      'picklist.gender': {
        name: 'gender',
      },
      'picklist.language': {
        name: 'language',
      },
      'picklist.honorific': {
        name: 'honorific',
      },
      'picklist.locations': {
        name: 'userlocations',
      },
      'query-vw_module_search.modules': {
        id: '%',
      },
      'query-vw_users_modules_groups.users_modules': {
        user_id: id,
      },
      'query-userroles.userroles': {
        id: '%',
      },
      'queryattr-vw_user_search.users': {
        user_id: id,
      },
      'queryattr-vw_client_search.clients': {
        id: '%',
        asc: 'name',
      },
      'queryattr-client_user.user_clients': {
        user_id: id,
      },
      'queryattr-workflows.teams': {
        id: '%',
      },
      
    };
  },
  fromResponseBody: (
    zebraUserData,
    loadView,
    loadAncilUserRoles,
    loadUsersAncilRoles,
    carrierList
  ) => {
    const zebraUserDataResult = zebraUserData.data.result;
    const loadViewData = loadView.data.result;
    const loadAncilUserRolesData = loadAncilUserRoles.data.result;
    const loadUsersAncilRolesData = loadUsersAncilRoles.data.result;
    const carrierListData = carrierList.data.result;
    if (carrierListData == null) {
      carrierListData = [];
    }

    let userId = -1;
    if (zebraUserDataResult.vwUserEdit.id !== undefined) {
      userId = zebraUserDataResult.vwUserEdit.id;
    }

    const payload = {
      userId: userId,
      userBasics: adminObjectUtils.parseBasicsData(
        zebraUserDataResult,
        carrierListData
      ),
      userProfileList: loadViewData.userProfileList,
      userAccess: adminObjectUtils.parseAccessData(zebraUserDataResult),
      userAncilAccess: adminObjectUtils.parseAncilAccessData(
        loadAncilUserRolesData,
        loadUsersAncilRolesData
      ),
      userBillReview: adminObjectUtils.parseBillReviewData(loadViewData),
      userBillApproval: adminObjectUtils.parseBillApprovalData(loadViewData),
      userAdjusterMapping: adminObjectUtils.parseAdjusterMappingData(
        loadViewData
      ),
      //userClients.availableClients:zebraUserData.data.result.clients;
      
      userComments: {
        comments: zebraUserDataResult.vwUserEdit.person.comments,
      },
    };

    return objectUtils.setNullValues(payload, '');
  },
};

export const resetPasswordTransform = {
  toRequestBody: id => {
    return {
      user_id: id,
    };
  },
};

export const resendPasswordTransform = {
  toRequestBody: id => {
    return {
      user_id: id,
    };
  },
};


export const loadLocationsTransform = {
  toRequestBody: id => {
    return {
      user_id: id,
    };
  },
};

export const canTransferBillDataTransform = {
  toRequestBody: (billId, userId, profile) => {
    return {
      billId: billId,
      adjusterId: userId,
      profile: profile,
    };
  },
};

export const updateAdjusterMapDataTransform = {
  toRequestBody: (userId, linkedUserId, profile, firstName, lastName) => {
    return {
      userId: userId,
      linkedUserId: linkedUserId,
      profile: profile,
      firstname: firstName,
      lastname: lastName,
    };
  },
};

export const getCarrierListTransform = {
  toRequestBody: () => {
    return {
      userId: '-1',
      matchCriteria: 'cellProvider',
    };
  },
};

export const loadUserWorklistLinksTransform = {
  toRequestBody: (id, profile) => {
    return {
      userId: id,
      profile: profile,
    };
  },
  fromResponseBody: (response, profile) => {
    const lists = response.data;
    const adjusterList = lists.adjusterList.map(adjuster => {
      return {
        id: adjuster.id,
        value: adjuster.value,
        checked: false,
        profile: profile,
      };
    });
    const billList = lists.billList.map(bill => {
      return {
        id: bill.id,
        value: bill.value,
        checked: false,
        profile: profile,
      };
    });
    const claimnumberList = lists.claimnumberList.map(claimnumber => {
      return {
        id: claimnumber.id,
        value: claimnumber.value,
        checked: false,
        profile: profile,
      };
    });

    return {
      adjusterList: adjusterList,
      billList: billList,
      claimnumberList: claimnumberList,
    };
  },
};

export const saveUserInformationTransform = {
  toRequestBody: user => {
    const {
      userAccess,
      userBasics,
      userBillApproval,
      userBillReview,
      userClients,
      userComments,
      userId,
      userProfileList,
    } = user || {};

    const { dataAccessLevel, dataTable: moduleAccess } = userAccess || {};

    const accessOverridePpo = R.find(
      item => item.id === 'accessOverridePPO',
      dataAccessLevel
    );
    const sendReferrals = R.find(
      item => item.id === 'accessCanSendReferrals',
      dataAccessLevel
    );
    const defaultToTasklist = R.find(
      item => item.id === 'accessDefaultToTasklist',
      dataAccessLevel
    );
    const editMbrData = R.find(
      item => item.id === 'accessEditMBRData',
      dataAccessLevel
    );
    const hideQaButton = R.find(
      item => item.id === 'accessHideQAButton',
      dataAccessLevel
    );
    const showPrintDialog = R.find(
      item => item.id === 'accessShowPrintDialog',
      dataAccessLevel
    );
    const supportUser = R.find(
      item => item.id === 'accessZebraSupportUser',
      dataAccessLevel
    );

    const { linkClients: approvalLinkedClients } = userBillApproval;

    const approvalClients = R.map(
      item => ({
        mx_internal_uid: '',
        profileName: item.profileName,
        description: item.id,
        profId: item.profId,
        threshold: `${item.thresholdAmt}`.substr(0, 7),
        linkeduser: TypeUtils.isNilOrEmpty(userId) ? '0' : `${userId}`,
      }),
      approvalLinkedClients
    );

    const clientIds = R.map(
      item => (TypeUtils.isNilOrEmpty(item.profId) ? '0' : `${item.profId}`),
      approvalLinkedClients
    );

    const clientThresholds = R.map(
      item =>
        TypeUtils.isNilOrEmpty(item.threshold)
          ? 0
          : Number.parseInt(item.threshold, 10),
      approvalClients
    );

    const allowedModules = R.filter(item => item.allowAccess, moduleAccess);
    const allowedModuleIds = R.map(item => item.id, allowedModules);
    const allowedModuleIdStr = R.join(',', allowedModuleIds);

    const defaultModule = R.find(item => item.default, moduleAccess) || {};
    const defaultModuleId = defaultModule.id;

    const {
      accessLevel,
      centerList,
      processingCenter: processingCenterId,
      profiles,
    } = userBillReview;

    const defaultProcessingCenter = {
      id: 0,
      mx_internal_uid: '', // Not being passed from the Get API, so can't return it to save
      name: '',
    };
    const processingCenter = R.find(
      item => item.id === processingCenterId,
      centerList
    );

    const { linkedClients } = userClients || {};

    const linkedClientIds = R.map(
      item => (TypeUtils.isNilOrEmpty(item.id) ? '0' : `${item.id}`),
      linkedClients
    );

    const selectedProfiles = R.filter(item => item.selected, profiles);
    const profileList = R.map(
      ({ default: isDefault, id, profileId, siteTops }) => {
        const strSites = R.map(
          item => (TypeUtils.isNilOrEmpty(item) ? '0' : `${item}`),
          siteTops.includes(',') ? siteTops.split(',') : (TypeUtils.isArray(siteTops) ? siteTops : [siteTops])
        );

        return {
          database: id,
          id: profileId,
          isDefault,
          profile: '',
          sites: strSites,
        };
      },
      selectedProfiles
    );

    return {
      user_id: TypeUtils.isNilOrEmpty(userId) ? '0' : `${userId}`,
      salutation: userBasics.salutation,
      firstname: userBasics.firstName,
      lastname: userBasics.lastName,
      honor: userBasics.honorific,
      initials: userBillReview.initials,
      gender: userBasics.gender,
      email1: userBasics.primaryEmailAddress,
      email2: userBasics.secondaryEmailAddress,
      phone: (userBasics.workPhone || '').replace(/\D/g, ''),
      phoneExt: (userBasics.ext || '').replace(/\D/g, ''),
      cell1: (userBasics.cellPhone || '').replace(/\D/g, ''),
      cellProviderId: TypeUtils.isNilOrEmpty(userBasics.carrier)
        ? 0
        : Number.parseInt(userBasics.carrier, 10),
      fax: (userBasics.fax || '').replace(/\D/g, ''),
      pager1: (userBasics.pager || '').replace(/\D/g, ''),
      pagerpinnumber: TypeUtils.isNilOrEmpty(userBasics.pin)
        ? '0'
        : userBasics.pin,
      location: TypeUtils.isNilOrEmpty(userBasics.location)
        ? '0'
        : `${userBasics.location}`,
      languages: userBasics.language,
      timeout: userBasics.applicationTimeout,
      tasknotifymethod: userBasics.taskNotificationMethod,
      username: userAccess.userName,
      userdisabled: userAccess.userDisabled,
      userrole: TypeUtils.isNilOrEmpty(userAccess.roleSelect)
        ? '0'
        : `${userAccess.roleSelect}`,
      addReferral: sendReferrals.checked,
      defaulttobbtaskList: defaultToTasklist.checked,
      editMBRDocs: editMbrData.checked,
      hideqa: hideQaButton.checked,
      ignoresitetop: accessOverridePpo.checked,
      referralprintoption: showPrintDialog.checked,
      supportuser: supportUser.checked,
      users_modules: allowedModuleIdStr,
      defaultAppId: TypeUtils.isNilOrEmpty(defaultModuleId)
        ? 0
        : Number.parseInt(defaultModuleId, 10),
      access: TypeUtils.isNilOrEmpty(accessLevel) ? '0' : `${accessLevel}`,
      comments: userComments.comments,
      profiles: profileList,
      linkedclientlist: linkedClientIds,
      adjusterclients: approvalClients,
      clientlist: clientIds,
      thresholds: clientThresholds,
      processingcenter: { ...defaultProcessingCenter, ...processingCenter },
      // TODO: Verify that these two values came from the User Groups tab that is not being carried into Z3
      int_defaultUserView: 0,
      default_team: '0',
    };
  },
};

export const saveAncilUserTransform = {
  toRequestBody: (user, lmsUserId) => {
    const {
      userAccess,
      userAncilAccess,
      userId,
    } = user || {};

    const roleList = { };

    const defaultAncil = R.find(item => item.default, userAncilAccess.dataTable);

    return {
      userId: TypeUtils.isNilOrEmpty(userId) ? '0' : `${userId}`,
      username: userAccess.userName,
      lmsUserId: lmsUserId,
      roleList: userAncilAccess.dataTable,
      ancilDefaultRole: TypeUtils.isNilOrEmpty(defaultAncil) ? '0' : defaultAncil.roleId,
    };  
  },
};

export const deleteWorklistLinksTransform = {
  toRequestBody: (user, lmsUserId) => {
    return {
      billList: user.userAdjusterMapping.billDelete,
      claimnumberList: user.userAdjusterMapping.claimDelete,
      adjusterList: user.userAdjusterMapping.adjusterDelete,
    }
  }
};