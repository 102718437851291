import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from 'reactstrap';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import './AppAutocomplete.scss';
import classnames from 'classnames';

class AppAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      dropdownOpen: false,
      cursor: -1,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.resetCursor();
    }

    if (this.props.value !== prevProps.value) {
      this.resetCursor();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.dropdownOpen
    ) {
      this.resetCursor();

      this.setState({
        dropdownOpen: false,
      });

      if (!this.props.updateOnLeave) {
        return;
      }

      const value = this.props.value;

      const match = this.props.data.filter(item => {
        return item[this.props.displayOption] === value;
      });

      if (!match.length) {
        if (!!value.length) {
          this.props.alert(
            this.props.placeholder +
              ' entered in input field must match a record from the list.'
          );
        }
        this.clearInput();
      } else {
        this.onSelect(match[0]);
      }
    }
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  expandDropdown() {
    this.setState({
      dropdownOpen: true,
    });
  }

  resetCursor = () => {
    this.setState({
      cursor: -1,
    });
  };

  collapseDropdown() {
    this.setState({
      dropdownOpen: false,
    });
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onFocus = () => {
    this.resetCursor();
    this.expandDropdown();
  };

  onCaretClick = () => {
    this.resetCursor();
    this.toggleDropDown();
  };

  onChange = event => {
    this.resetCursor();
    this.props.onChange(this.props.id, event.target.value);
  };

  onSelect = item => {
    this.resetCursor();
    this.collapseDropdown();
    if (this.props.returnValue) {
      this.props.onSelect(
        this.props.id,
        item[this.props.displayOption],
        item[this.props.returnValue]
      );
    } else {
      this.props.onSelect(this.props.id, item);
    }
  };

  clearInput = () => {
    this.props.onChange(this.props.id, '');
    if (this.props.clearInput) {
      this.props.clearInput(this.props.id);
    }
  };

  handleInputKeyDown = (event, data) => {
    if (event.keyCode === 38) {
      event.preventDefault();
      if (this.state.cursor < 0) {
        return;
      }
      this.setState(prevState => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (event.keyCode === 40) {
      event.preventDefault();
      if (this.state.cursor > data.length - 1) {
        return;
      }
      this.setState(prevState => ({
        cursor: prevState.cursor + 1,
      }));
    }

    if (this.props.inputProps && this.props.inputProps.onKeyDown) {
      this.props.inputProps.onKeyDown(event);
    }
  };

  handleInputKeyPress = (event, data) => {
    if (event.charCode === 13 && this.state.cursor > -1) {
      event.preventDefault();
      this.onSelect(data[this.state.cursor]);
    }

    if (
      this.props.inputProps &&
      this.props.inputProps.onKeyPress &&
      this.state.cursor < 0
    ) {
      this.props.inputProps.onKeyPress(event);
    }
  };

  handleHoverItem = index => {
    this.setState({
      cursor: index,
    });
  };

  processData(data) {
    let processedData = [...data];

    if (this.props.highlightSelected) {
      processedData = processedData.map(item => {
        return this.isHighlighted(item)
          ? {
              ...item,
              highlighted: true,
            }
          : item;
      });
    }

    const matchedData = processedData.filter(item =>
      this.shouldItemRender(item)
    );

    if (this.props.isSuggested) {
      const suggestedData = matchedData.map((item, index) => {
        return index === matchedData.length - 1
          ? {
              ...item,
              suggested: true,
            }
          : item;
      });
      return [
        ...suggestedData,
        ...processedData.filter(item => !this.shouldItemRender(item)),
      ];
    }

    return matchedData;
  }

  renderItem(item) {
    return this.props.renderItem
      ? this.props.renderItem(item)
      : item[this.props.displayOption];
  }

  getItemClassNames(item, index) {
    return {
      suggested: item.suggested === true,
      highlighted: item.highlighted === true || this.state.cursor === index,
    };
  }

  shouldItemRender(item) {
    const value = this.props.value;
    if (this.props.shouldItemRender) {
      return this.props.shouldItemRender(item, value);
    }

    return (
      item[this.props.displayOption]
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 || value === ''
    );
  }

  isHighlighted(item) {
    if (this.props.isHighlighted) {
      return this.props.isHighlighted(item);
    }

    return item[this.props.displayOption] === this.props.value;
  }

  render() {
    const data = this.processData(this.props.data);

    return (
      <div
        ref={this.setWrapperRef}
        className={this.props.autoCompleteWrapperClass}
      >
        <InputGroup>
          <Input
            type="text"
            {...this.props.inputProps}
            id={this.props.id}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.onChange}
            onKeyDown={event => this.handleInputKeyDown(event, data)}
            onKeyPress={event => this.handleInputKeyPress(event, data)}
            autoComplete="off"
            onFocus={this.onFocus}
          />
          <span className="input-caret" onClick={this.onCaretClick} />
          <InputGroupAddon addonType="append">
            <Button
              onClick={this.clearInput}
              color="danger"
              name={this.props.id}
              disabled={this.props.inputProps.disabled}
            >
              X
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {this.state.dropdownOpen && (
          <ul
            className="datalist-ul"
            style={{ maxHeight: this.props.maxHeight, width: this.props.width }}
          >
            {data.map((item, index) => (
              <li
                className={classnames(this.getItemClassNames(item, index))}
                key={index}
                onClick={() => this.onSelect(item)}
                onMouseEnter={() => this.handleHoverItem(index)}
              >
                {this.renderItem(item)}
              </li>
            ))}
          </ul>
        )}
        {this.props.showToolTip && (
          <Tooltip
            placement={this.props.tipPlacement}
            isOpen={this.state.tooltipOpen}
            target={this.props.id}
            delay={this.props.tooltipDelay}
            toggle={this.toggleTooltip}
          >
            {this.props.tooltipText
              ? this.props.tooltipText
              : this.props.placeholder}
          </Tooltip>
        )}
      </div>
    );
  }
}

AppAutocomplete.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  displayOption: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  showToolTip: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string.isRequired,
  isHighlighted: PropTypes.func,
  highlightSelected: PropTypes.bool,
  returnValue: PropTypes.string,
  tipPlacement: PropTypes.string,
  tooltipDelay: PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number,
  }),
  alert: PropTypes.func.isRequired,
  renderItem: PropTypes.func,
  shouldItemRender: PropTypes.func,
  clearInput: PropTypes.func,
  updateOnLeave: PropTypes.bool,
  inputProps: PropTypes.object,
  autoCompleteWrapperClass: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSuggested: PropTypes.bool,
};

AppAutocomplete.defaultProps = {
  autoCompleteWrapperClass: 'autocomplete-wrapper',
  showToolTip: true,
  tooltipDelay: { show: 800, hide: 250 },
  tipPlacement: 'bottom',
  updateOnLeave: true,
  maxHeight: 600,
  width: '100%',
  placeholder: '',
  isSuggested: false,
  highlightSelected: false,
  inputProps: {},
};

const mapDispatchToProps = dispatch => ({
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(null, mapDispatchToProps)(AppAutocomplete);
