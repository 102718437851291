import * as R from 'ramda';

const formatPhoneNumber = value => {
  let phoneNumber = value;
  let formattedNumber;
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
  const areaCode = phoneNumber.substr(0, 3);
  const firstPart = phoneNumber.substr(3, 3);
  const secondPart = phoneNumber.substr(6, 4);
  if (areaCode.length === 0) {
    formattedNumber = '';
  } else if (areaCode.length < 3) {
    formattedNumber = '(' + areaCode;
  } else if (areaCode.length === 3 && firstPart.length < 3) {
    formattedNumber = '(' + areaCode + ') ' + firstPart;
  } else if (areaCode.length === 3 && firstPart.length === 3) {
    formattedNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
  }

  return formattedNumber;
};

const parseBasicsData = (object, carrierListData) => {
  const user = object.vwUserEdit;
  const person = user.person;
  const contact = user.contact;
  const pickList = object.pickList;

  const carrierList = carrierListData.map(carrier => {
    return carrier.fields;
  });

  return {
    salutation: person.salutation,
    salutationSelect: pickList.salutation,
    firstName: person.firstName,
    lastName: person.lastName,
    gender: person.genderId,
    primaryEmailAddress: contact.email,
    secondaryEmailAddress: contact.email2,
    honorific: person.honor,
    honorificSelect: pickList.honorific,
    language: person.language,
    languageSelect: pickList.language,
    workPhone: formatPhoneNumber(contact.workPhone || ''),
    ext: contact.workPhoneExt,
    cellPhone: formatPhoneNumber(contact.cellPhone || ''),
    fax: formatPhoneNumber(contact.fax || ''),
    pager: formatPhoneNumber(contact.pager || ''),
    pin: contact.pagerPin,
    location: user.location,
    applicationTimeout: user.timeout,
    taskNotificationMethod: user.taskNotifyMethod,
    locationList: pickList.userlocations,
    carrierList: carrierList,
  };
};

const parseAccessData = object => {
  const user = object.vwUserEdit;
  const modules = object.modules;
  const userModules = object.userModules;
  const defaultModule = user.defaultAppId;
  const userProfile = object.vwUsers[0];

  let userName = '';
  if (typeof userProfile !== 'undefined') {
    userName = userProfile.username;
  }

  return {
    userName: userName,
    userDisabled: user.disabled,
    roleSelect: user.userRole,
    roles: parseAccessRoles(object),
    dataTable: parseAccessDataTable(modules, userModules, defaultModule),
    dataAccessLevel: [
      {
        id: 'accessZebraSupportUser',
        value: 'Zebra Support User',
        checked: user.supportUser,
      },
      {
        id: 'accessShowPrintDialog',
        value: 'Show Print Dialog (EVALS)',
        checked: user.referralPrintOption,
      },
      {
        id: 'accessOverridePPO',
        value: 'Override PPO Site Rules (BB)',
        checked: user.ignoreSiteTop,
      },
      {
        id: 'accessDefaultToTasklist',
        value: 'Defaults to Tasklist (BB)',
        checked: user.defaultToBbTaskList,
      },
      {
        id: 'accessHideQAButton',
        value: 'Hide QA Button (BB)',
        checked: user.hideQa,
      },
      {
        id: 'accessEditMBRData',
        value: 'Edit MBR Data',
        checked: user.editMBRDocs,
      },
      {
        id: 'accessCanSendReferrals',
        value: 'Can Send Referrals (EVALS)',
        checked: user.addReferral,
      },
    ],
  };
};

const parseAccessRoles = object => {
  return object.userRoles.map(value => {
    return {
      id: value.id.toString(),
      roleName: value.role_name,
    };
  });
};

const parseAccessDataTable = (modules, userModules, defaultModule) => {
  const userModuleIds = userModules.map(value => {
    return value.module_id;
  });

  return modules.map(value => {
    const allowAccess = userModuleIds.includes(value.id);
    const defaultAccess = defaultModule === value.id;

    return {
      id: value.id.toString(),
      moduleName: value.display_name,
      allowAccess: allowAccess,
      default: defaultAccess,
    };
  });
};

const parseAncilAccessData = (ancilUserRoles, usersAncilRoles) => {
  return {
    dataTable: parseAncilAccessDataTable(ancilUserRoles, usersAncilRoles),
  };
};

const parseAncilAccessDataTable = (ancilUserRoles, usersAncilRoles) => {
  const userRoles = {
    acc: 'Accommocation',
    adj: 'Adjuster',
    aud: 'Audiology',
    clms: 'Claims',
    clnts: 'Clients',
    dtl: 'Dental',
    dgn: 'Diagnostic',
    hhc: 'HHC',
    intr: 'Interpretation',
    modi: 'Modification',
    mse: 'MSE',
    spt: 'Special Transport',
    trn: 'Transportation',
    ven: 'Vendors',
  };

  const defaultAccessObject = [
    {
      id: 'acc',
      value: 'Accommocation',
      checked: false,
    },
    {
      id: 'adj',
      value: 'Adjuster',
      checked: false,
    },
    {
      id: 'aud',
      value: 'Audiology',
      checked: false,
    },
    {
      id: 'clms',
      value: 'Claims',
      checked: false,
    },
    {
      id: 'clnts',
      value: 'Clients',
      checked: false,
    },
    {
      id: 'dtl',
      value: 'Dental',
      checked: false,
    },
    {
      id: 'dgn',
      value: 'Diagnostic',
      checked: false,
    },
    {
      id: 'hhc',
      value: 'HHC',
      checked: false,
    },
    {
      id: 'intr',
      value: 'Interpretation',
      checked: false,
    },
    {
      id: 'modi',
      value: 'Modification',
      checked: false,
    },
    {
      id: 'mse',
      value: 'MSE',
      checked: false,
    },
    {
      id: 'spt',
      value: 'Special Transport',
      checked: false,
    },
    {
      id: 'trn',
      value: 'Transportation',
      checked: false,
    },
    {
      id: 'ven',
      value: 'Vendors',
      checked: false,
    },
  ];

  const rolesCopy = JSON.parse(JSON.stringify(usersAncilRoles));

  return ancilUserRoles.map(value => {
    let userRoleAccess = rolesCopy.filter(
      userRole => userRole.roleId === value.id
    )[0];

    const allowAccess = userRoleAccess !== undefined;

    const defaultAccess =
      rolesCopy.filter(userRole => userRole.defaultrole === value.id).length >
      0;

    let userRolesAccessObject = [];

    if (allowAccess) {
      delete userRoleAccess.defaultrole;
      delete userRoleAccess.roleId;
      delete userRoleAccess.rolename;
      delete userRoleAccess.worklistId;

      Object.keys(userRoleAccess).forEach(key => {
        userRolesAccessObject.push({
          id: key,
          value: userRoles[key],
          checked: userRoleAccess[key],
        });
      });
    } else {
      userRolesAccessObject = defaultAccessObject;
    }

    return {
      id: value.value.split(' ').join(''),
      roleId: value.id,
      userRole: value.value,
      allowAccess: allowAccess,
      default: defaultAccess,
      access: userRolesAccessObject,
    };
  });
};

const parseBillReviewData = object => {
  return {
    initials: object.initials,
    accessLevel: object.currentAccess,
    processingCenter: object.currentCenter,
    profiles: parseBillReviewProfiles(object),
    accessList: parseBillReviewAccessList(object),
    centerList: parseBillReviewCenterList(object),
  };
};

const parseBillReviewAccessList = object => {
  return object.accessList.map(value => {
    return {
      id: value.id.toString(),
      name: value.description,
    };
  });
};

const parseBillReviewCenterList = object => {
  return object.centerList.map(value => {
    return {
      id: value.id.toString(),
      name: value.name,
    };
  });
};

const parseBillReviewProfiles = object => {
  let defaultProfile = '';
  let siteTopsList = {};
  const userProfileList = object.userProfileList;

  Object.keys(userProfileList).forEach(key => {
    siteTopsList[userProfileList[key].id] = userProfileList[key].sites;

    if (userProfileList[key].isDefault) {
      defaultProfile = userProfileList[key].id;
    }
  });

  return object.profileList.map(value => {
    const siteTops = siteTopsList[value.id] ? siteTopsList[value.id] : '';
    const selected = value.id in siteTopsList;
    const isDefault = value.id === defaultProfile;

    return {
      id: value.database,
      profileId: value.id,
      selected: selected,
      default: isDefault,
      siteTops: siteTops,
    };
  });
};

const parseBillApprovalData = object => {
  const linkClientList = object.masterClientList
    .filter(obj => {
      return R.find(
        element => element.profId === obj.profId,
        object.userClientList
      );
    })
    .map(obj => {
      let match = R.find(
        element => element.profId === obj.profId,
        object.userClientList
      );
      if (match) {
        return {
          id: obj.description,
          profId: obj.profId,
          thresholdAmt: match.threshold,
          profileName: obj.profileName,
          siteId: obj.siteId,
          checked: false,
        };
      } else {
        return {};
      }
    });

  const masterClientList = object.masterClientList
    .filter(obj => {
      const filteredRecord = R.find(
        element => element.profId === obj.profId,
        linkClientList
      );
      if (!filteredRecord) {
        return obj;
      }
    })
    .map(obj => {
      return {
        id: obj.description,
        profId: obj.profId,
        thresholdAmt: 0,
        profileName: obj.profileName,
        siteId: obj.siteId,
        checked: false,
      };
    });

  return {
    availableClients: masterClientList,
    thresholdAmt: 0,
    draggedItem: {},
    linkClients: linkClientList,
  };
};

const parseAdjusterMappingData = object => {
  const profiles = object.profileList.map(profile => {
    return {
      id: profile.id,
      name: profile.database,
    };
  });

  return {
    claimnumberList: [],
    billList: [],
    adjusterData: [],
    adjusterList: [],
    profileAdjusterList: [],
    profiles: profiles,
  };
};

const parseClients = object => {
  const linkedClients = object.clients
    .filter(obj => {
      return R.find(
        element => element.client_id === obj.id,
        object.userClients
      );
    })
    .map(obj => {
      let match = R.find(
        element => element.client_id === obj.id,
        object.userClients
      );
      if (match) {
        return {
          code: obj.code,
          address: obj.address,
          level: obj.level,
          parent_id: obj.parent_id,
          name: obj.name,
          id: obj.id,
          haschildren: obj.haschildren,
        };
      } else {
        return {};
      }
    });
  
  const availableClients = object.clients
    .filter(obj => {
      const filteredRecord = R.find(
        element => element.id === obj.id,
        linkedClients
      );
      if (!filteredRecord) {
        return obj;
      }
    })
    .map(obj => {
      return {
        code: obj.code,
        address: obj.address,
        level: obj.level,
        parent_id: obj.parent_id,
        name: obj.name,
        id: obj.id,
        haschildren: obj.haschildren,
      }
    });

  return {
    linkedClients: linkedClients,
    availableClients: availableClients,
  }
}

export default {
  parseBasicsData,
  parseAccessData,
  parseAncilAccessData,
  parseBillReviewData,
  parseBillApprovalData,
  parseAdjusterMappingData,
  parseClients,
};
