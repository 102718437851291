import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import PopupModal from 'shared/components/PopupModal';

const AnswerEmptyModal = ({ isOpen, onToggle }) => {
  const onSaveHandler = () => {
      onToggle();
  }

  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              Invalid answer, please check your answer and try again.
              <br />
              <br />
              <Button
              outline
              color="primary"
              style={{ marginLeft: '200px', marginRight: '200px' }}
              onClick={() => onSaveHandler()}>
              Ok{' '}
            </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <PopupModal
      content={getContent()}
      externalToggle={onToggle}
      title={''}
      isOpen={isOpen}
      size="md"
    />
  );
}

export default AnswerEmptyModal;
