import React, { useState, useEffect, useContext } from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import moment from 'moment';

import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import { TransportDetailModalContext } from './TransportDetailModalContext';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { isNil } from 'shared/utils/typeUtils';

const TransportDetailModal = ({
  disableSave,
  isOpen,
  onClose,
  onSave,
  timeZone,
  loadLegStatus,
  getLegStatus,
  getZipList,
  userId,
  referralId,
  serviceId,
  zipList,
  alert,
  saveTransportLeg,
  transportDetailObj,
  pickupAddr,
  pickupLocName,
  workListData,
  updateTRNInfoTable,
  legLength,
  setTrnDetailModaldata,
  setTransportDetails,
  transportDetails,
  trnSaveData,
  detailsForOrder,
  updateTrnDetailList,
  trnDetailsList,
}) => {
  const { inputData, dispatch } = useContext(TransportDetailModalContext);

  const [legStatus, setLegStatus] = useState({ id: '', value: '' });

  const [selectedPickupLocation, setSelectedPickupLocation] = useState(
    'default'
  );

  const [selectedDropLocation, setSelectedDropLocation] = useState('default');

  const [addressInfo, setAddressInfo] = useState([]);

  function updateTransportModalForm(value, key) {
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { [key]: value },
    });
  }
  function preselectPickupAddress(addrObj) {
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { pickupAddress: addrObj },
    });
  }
  useEffect(() => {
    setTrnDetailModaldata(inputData);
  }, [inputData]);

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  function updatePickupAddressInfo(value, key) {
    let pickUpAddress = inputData.pickupAddress;
    if (key == "phone") {
      value = formatPhoneNum(value);
    }
    pickUpAddress[key] = value;
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { pickupAddress: pickUpAddress },
    });
  }
  function updateDropAddressInfo(value, key) {
    let dropAddress = inputData.dropOffAddress;
    console.log(key);
    if (key == "phone") {
      value = formatPhoneNum(value);
    }
    dropAddress[key] = value;
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { dropOffAddress: dropAddress },
    });
  }
  const updateBulkData = inputObj => {
    if (inputObj && inputObj.leg) {
      let pickupdateStr = moment(inputObj.pickup).format('YYYY-MM-DD hh:mm a'); //: "2020-12-28 06:00:00.0";
      let pickupDateArr = pickupdateStr.split(' ');
      inputObj.pickupDate = new Date(inputObj.pickup);
      inputObj.pickupHours = pickupDateArr[1].split(':')[0];
      inputObj.pickupMin = pickupDateArr[1].split(':')[1];
      inputObj.pickupAM = pickupDateArr[2];
      let dropOffdateStr = moment(inputObj.dropOff).format(
        'YYYY-MM-DD hh:mm a'
      ); //: "2020-12-28 06:00:00.0";
      let dropOffDateArr = dropOffdateStr.split(' ');
      inputObj.dropOffDate = new Date(inputObj.dropOff);
      inputObj.dropOffHours = dropOffDateArr[1].split(':')[0];
      inputObj.dropOffMin = dropOffDateArr[1].split(':')[1];
      inputObj.dropOffAM = dropOffDateArr[2];
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: inputObj,
      });
    }
  };
  const changeZipList = zipVal => {
    let selectedZip = zipList.filter(
      zipObj =>
        zipObj.chr_zipcode === zipVal &&
        assign(zipObj, {
          label: `${zipObj.chr_city}, ${zipObj.chr_state} ${zipObj.chr_zipcode}`,
          value: zipObj.chr_zipcode,
        })
    );
    if (selectedZip.length === 0) {
      handleTypeAheadSearch(zipVal);
    }
  };
  useEffect(() => {
    if (transportDetailObj && transportDetailObj.leg) {
      updateBulkData(transportDetailObj);
    } else {
      if (pickupAddr && pickupAddr.zipCode) {
        preselectPickupAddress(pickupAddr);
      }
      updateTransportModalForm(pickupLocName, 'locationName');
      updateTransportModalForm(legLength + 1, 'leg');
      updateTransportModalForm(new Date(), 'dropOffDate');
      updateTransportModalForm(new Date(), 'pickupDate');
    }
  }, []);

  const handleTypeAheadSearch = event => {
    // event.persist();
    if (event.length > 2) {
      const params = {
        userId: userId,
        zipCode: event,
      };
      getZipList(params);
    }
  };

  useEffect(() => {
    let info = [];
    if (
      workListData &&
      workListData.claim &&
      workListData.claim.claimant &&
      workListData.claim.claimant.claimantLocations &&
      workListData.claim.claimant.claimantLocations.length > 0
    ) {
      info = workListData.claim.claimant.claimantLocations.map(locationObj => {
        locationObj.address.locationName = locationObj.locationName || '';
        return {
          data: JSON.stringify(locationObj.address),
          address: `${locationObj.locationName || 'Null'},${locationObj.address.street1
            },${locationObj.address.zipCode.county},${locationObj.address.zipCode.city
            },${locationObj.address.zipCode.state},${locationObj.address.zipCode.zip
            }`,
        };
      });
    } else if (
      workListData &&
      workListData.claim &&
      workListData.claim.claimant &&
      workListData.claim.claimant.address &&
      workListData.claim.claimant.address.zipCode
    ) {
      let addressObj = workListData.claim.claimant.address;
      addressObj.locationName = 'Home';
      info = [
        {
          data: JSON.stringify(addressObj),
          address: `${addressObj.locationName},${addressObj.street1},${addressObj.zipCode.county},${addressObj.zipCode.city},${addressObj.zipCode.state},${addressObj.zipCode.zip}`,
        },
      ];
    }

    setAddressInfo(info);
  }, [workListData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'RESET_MODAL_DATA',
        payload: {},
      });
    };
  }, []);

  const validateFields = () => {
    if (inputData.leg === '' || inputData.leg <= 0) {
      alert('Quantity must be greater than 0.');
    } else if (inputData.legStatus === '' || inputData.legStatus <= 0) {
      alert('Leg status is required to add/change a leg');
    } else if (
      inputData.returnTypeDesc === '' ||
      inputData.returnTypeDesc <= 0
    ) {
      alert('Return Type is required to add/change a leg');
    } else if (inputData.pickupDate === '' || inputData.pickupDate <= 0) {
      alert(
        'Departure Date is required to add/change Scheduled Return trip leg'
      );
    } else if (inputData.pickupAM === '' || inputData.pickupAM <= 0) {
      alert('pickup Am or Pm is required');
    } else if (
      !inputData.pickupHours ||
      (inputData.pickupHours && inputData.pickupHours === '0')
    ) {
      alert('Pickup Time is required to add/change a leg');
    } else if (
      inputData.pickupAddress.street1 === '' ||
      inputData.pickupAddress.street1 <= 0
    ) {
      alert('Please Enter Pickup Address to add a leg');
    }
    else if (
      inputData.pickupAddress.zipCode.city === '' ||
      inputData.pickupAddress.zipCode.city <= 0
    ) {
      alert('Please enter Pickup ZipCode to add a leg');
    }
    else if (inputData.dropOffDate === '' || inputData.dropOffDate <= 0) {
      alert('Arrival Date is required to add/change Scheduled Return trip leg');
    } else if (
      !inputData.dropOffHours ||
      (inputData.dropOffHours && inputData.dropOffHours === '0')
    ) {
      alert('Arrival Time is required to add/change Scheduled Return trip leg');
    } else if (inputData.dropOffAM === '' || inputData.dropOffAM <= 0) {
      alert('dropOff Am or Pm is required');
    } else if (
      inputData.pickupAddress.street1 === '' ||
      inputData.pickupAddress.street1 <= 0
    ) {
      alert('Please Enter Pickup Address to add a leg');
    } else if (
      inputData.dropOffAddress.street1 === '' ||
      inputData.dropOffAddress.street1 <= 0
    ) {
      alert('Please Enter DropOff Address to add a leg');
    } else if (
      inputData.pickupAddress.zipCode.city === '' ||
      inputData.pickupAddress.zipCode.city <= 0
    ) {
      alert('Please enter Pickup ZipCode to add a leg');
    } else if (
      inputData.dropOffAddress.zipCode.city === '' ||
      inputData.dropOffAddress.zipCode.city <= 0
    ) {
      alert('Please Enter DropOff Zipcode to add a leg');
    } else {
      let inputObj = { ...inputData };
      let pickupHour = `${('0' + inputObj.pickupHours).slice(-2)}:${(
        '0' + inputObj.pickupMin
      ).slice(-2)} ${inputObj.pickupAM}`;
      let pickupDate = `${moment(inputObj.pickupDate).format(
        'MM/DD/YYYY'
      )} ${pickupHour}`;
      inputObj.pickup = moment(pickupDate).format('YYYY-MM-DD HH:mm:ss.S');

      let dropOffHour = `${('0' + inputObj.dropOffHours).slice(-2)}:${(
        '0' + inputObj.dropOffMin
      ).slice(-2)} ${inputObj.dropOffAM}`;
      let dropOffDate = `${moment(inputObj.dropOffDate).format(
        'MM/DD/YYYY'
      )} ${dropOffHour}`;
      inputObj.dropOff = moment(dropOffDate).format('YYYY-MM-DD HH:mm:ss.S');

      delete inputObj.dropOffAM;
      delete inputObj.dropOffDate;
      delete inputObj.dropOffHours;
      delete inputObj.dropOffMin;
      delete inputObj.pickupAM;
      delete inputObj.pickupDate;
      delete inputObj.pickupHours;
      delete inputObj.pickupMin;
      delete inputObj.deadMileage;
      inputObj.pickupAddress.zipCodeId = inputObj.pickupAddress.zipCode.id;
      inputObj.dropOffAddress.zipCodeId = inputObj.dropOffAddress.zipCode.id;
      let selectedReturnType = loadLegStatus.ReturnType.filter(
        returnObj => returnObj.value === inputObj.returnTypeDesc
      );
      if (selectedReturnType.length > 0) {
        inputObj.returnType = selectedReturnType[0].id;
      }
      let legStatusObj = loadLegStatus.LegStatus.filter(
        legObj => legObj.value === inputObj.legStatus
      )[0];
      inputObj.legStatusId = legStatusObj.id;
      inputObj.physician = {
        active: false,
        address: {
          active: false,
          permAddress: false,
          zipCode: {
            dts: false,
            latitude: 0,
            longitude: 0,
          },
        },
        medicalProvider: false,
        people: {},
      };

      let reqData = {
        inputData: {
          transportLeg: [inputObj],
          serviceId: serviceId,
          zebraUserId: userId,
        },
        loadWorkData: {
          zebraUserId: userId,
          service_id: serviceId,
          transport_type: detailsForOrder.transportationCategoryId,
          module: detailsForOrder.module,
        },
        workListData: workListData,
      };
      if (referralId && serviceId > 100) {
        let tempId = `trn-${Math.floor(Math.random() * 1000)}`;
        if (!inputObj.id) {
          reqData.tempId = tempId;
        }
        saveTransportLeg(reqData);
        trnSaveData(true);
        if (inputObj.id && !transportDetailObj.tempId) {
          updateTRNInfoTable([{ ...inputObj, serviceId, tempId: tempId }]);
        }
        if (transportDetailObj.tempId && trnDetailsList.length > 0) {
          let trnList = trnDetailsList.map(trnItem => {
            if (trnItem.tempId === transportDetailObj.tempId) {
              trnItem = {
                ...inputObj,
                serviceId,
                tempId: transportDetailObj.tempId,
              };
            }
            return trnItem;
          });
          updateTrnDetailList(trnList);
        }
        closeModal(true);
      } else {
        if (transportDetailObj.tempId) {
          if (trnDetailsList.length > 0) {
            let trnList = trnDetailsList.map(trnItem => {
              if (trnItem.tempId === transportDetailObj.tempId) {
                trnItem = {
                  ...inputObj,
                  serviceId,
                  tempId: transportDetailObj.tempId,
                };
              }
              return trnItem;
            });
            updateTrnDetailList(trnList);
          }
          onSave({ ...inputObj, tempId: transportDetailObj.tempId });
          if (transportDetailObj.id === 0) {
            let isExisting =
              trnDetailsList.filter(
                detailItem => detailItem.tempId === transportDetailObj.tempId
              ).length > 0;
            if (!isExisting) {
              updateTRNInfoTable([
                { ...inputObj, serviceId, tempId: transportDetailObj.tempId },
              ]);
            }
          }
        } else {
          let tempId = `trn-${Math.floor(Math.random() * 1000)}`;
          console.log(inputObj);
          updateTRNInfoTable([{ ...inputObj, serviceId, tempId: tempId }]);
          onSave({ ...inputObj, tempId: tempId });
        }
        trnSaveData(true);
      }
    }
  };

  const clearDropOffData = () => {
    setSelectedDropLocation('default');
    dispatch({
      type: 'CLEAR_DROPOFF_ADDRESS',
      payload: {},
    });
  };

  const clearPickUpAddress = () => {
    setSelectedPickupLocation('default');
    dispatch({
      type: 'CLEAR_PICKUP_ADDRESS',
      payload: {},
    });
  };

  const closeModal = onSave => {
    dispatch({
      type: 'RESET_MODAL_DATA',
      payload: {},
    });
    onClose(onSave);
  };

  const openGoogleMaps = inputData => {
    const urlString =
      'https://www.google.com/maps/dir/' +
      inputData.pickupAddress.street1 +
      ',' +
      inputData.pickupAddress.zipCode.city +
      ',' +
      inputData.pickupAddress.zipCode.state +
      ',' +
      inputData.pickupAddress.zipCode.zip +
      '/' +
      inputData.dropOffAddress.street1 +
      ',' +
      inputData.dropOffAddress.zipCode.city +
      ',' +
      inputData.dropOffAddress.zipCode.state +
      ',' +
      inputData.dropOffAddress.zipCode.zip;

    window.open(urlString, '_blank');
  };

  const pickUpRoundOff = pickUpMin => {
    let minutes;
    if (pickUpMin === '') {
      minutes = '00';
    } else if (pickUpMin < 10) {
      minutes = '0' + pickUpMin.toString();
    } else if (pickUpMin > 59) {
      minutes = '59';
    } else {
      minutes = pickUpMin.toString();
    }
    updateTransportModalForm(minutes, 'pickupMin');
  };

  const dropOffRoundOff = dropOffMin => {
    let minutes;
    if (dropOffMin === '') {
      minutes = '00';
    } else if (dropOffMin < 10) {
      minutes = '0' + dropOffMin.toString();
    } else if (dropOffMin > 59) {
      minutes = '59';
    } else {
      minutes = dropOffMin.toString();
    }
    updateTransportModalForm(minutes, 'dropOffMin');
  };

  const projWaitTimeM = projWaitTime => {
    let minutes;
    if (projWaitTime == '') {
      minutes = '0';
    } else if (projWaitTime >= 0) {
      minutes = '0';
    }
    updateTransportModalForm(minutes, 'ProjWaitTimeM');
  };

  const projWaitTimeH = projWaitTime => {
    let minutes;
    if (projWaitTime == '') {
      minutes = '0';
    } else if (projWaitTime >= 0) {
      minutes = '0';
    }
    updateTransportModalForm(minutes, 'ProjWaitTimeH');
  };

  const ActualwaitTimeH = ActualwaitTime => {
    let minutes;
    if (ActualwaitTime == '') {
      minutes = '0';
    } else if (ActualwaitTime >= 0) {
      minutes = '0';
    }
    updateTransportModalForm(minutes, 'ActualwaitTimeM');
  };

  const ActualwaitTimeM = ActualwaitTime => {
    let minutes;
    if (ActualwaitTime == '') {
      minutes = '0';
    } else if (ActualwaitTime >= 0) {
      minutes = '0';
    }
    updateTransportModalForm(minutes, 'ActualwaitTimeH');
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader>Ambulatory</ModalHeader>
        <ModalBody>
          <Form>
            <Row style={{ padding: '3px' }}>
              <Label for="leg" md="2">
                Leg:
              </Label>
              <Col md="2">
                <Input
                  name="leg"
                  min="0"
                  max="12"
                  value={inputData.leg}
                  type="number"
                  id="leg"
                  name="leg"
                  onChange={e =>
                    updateTransportModalForm(e.target.value, 'leg')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label for="legStatus" md="2">
                Leg Status:
              </Label>
              <Col md="4">
                <AppInput
                  id="legstatus"
                  type="select"
                  changeHandler={(id, value) => {
                    updateTransportModalForm(value, 'legStatus');
                  }}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={inputData.legStatus}
                  data={loadLegStatus.LegStatus || []}
                />
              </Col>
              <Label for="returnType" md="1.5">
                Return Type:
              </Label>
              <Col md="4">
                <AppInput
                  id="returnType"
                  type="select"
                  changeHandler={(id, value) =>
                    updateTransportModalForm(value, 'returnTypeDesc')
                  }
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={inputData.returnTypeDesc}
                  data={loadLegStatus.ReturnType}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="10" md={{ size: 3, offset: 4 }}>
                <Label>
                  <b>PickUp Information</b>
                </Label>
              </Col>
            </Row>
            <Row Style={{ padding: '3px' }}>
              <Label md="2">Pickup Date:</Label>
              <Col md="4">
                <AppDateInput
                  onChange={value => {
                    updateTransportModalForm(value, 'pickupDate');
                  }}
                  selected={inputData.pickupDate ? inputData.pickupDate : ''}
                  value={inputData?.pickupDate || ''}
                />
              </Col>
              <Col md="4">
                <AppInput
                  id="locationHistory"
                  type="select"
                  placeholder="LocationHistory"
                  changeHandler={(e, val) => {
                    setSelectedPickupLocation(val);
                    let selectedObj = JSON.parse(val);
                    changeZipList(selectedObj.zipCode.zip);
                    updatePickupAddressInfo(selectedObj.street1, 'street1');
                    updatePickupAddressInfo(selectedObj.street2, 'street2');
                    updatePickupAddressInfo(selectedObj.zipCode, 'zipCode');
                    updateTransportModalForm(
                      selectedObj.locationName,
                      'locationName'
                    );
                    updatePickupAddressInfo(selectedObj.phone, 'phone');
                  }}
                  option={{
                    value: 'data',
                    displayValue: 'address',
                  }}
                  value={selectedPickupLocation}
                  data={addressInfo}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Pickup Time:</Label>
              <Col md="2">
                <Input
                  type="number"
                  min="0"
                  max="12"
                  value={inputData.pickupHours}
                  id=""
                  name="pickupHours"
                  onChange={e =>
                    updateTransportModalForm(
                      Number(e.target.value) > 12 ? '12' : e.target.value,
                      'pickupHours'
                    )
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  type="number"
                  min="0"
                  value={inputData.pickupMin}
                  id=""
                  name="pickupMin"
                  onBlur={e => pickUpRoundOff(e.target.value)}
                  onChange={e =>
                    updateTransportModalForm(e.target.value, 'pickupMin')
                  }
                />
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateTransportModalForm('am', 'pickupAM')}
                >
                  AM
                </Button>
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateTransportModalForm('pm', 'pickupAM')}
                >
                  PM
                </Button>
              </Col>
              <Col md="2">
                <AppInput
                  id="pickupTz"
                  type="select"
                  changeHandler={(id, value) =>
                    updateTransportModalForm(value, 'pickupTz')
                  }
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={inputData.pickupTz}
                  data={timeZone}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Location:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.locationName}
                  id="locationName"
                  name="locationName"
                  onChange={e =>
                    updateTransportModalForm(e.target.value, 'locationName')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Address:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.pickupAddress.street1}
                  id="pickUpAddress"
                  name="pickUpStreet1"
                  onChange={e => {
                    updatePickupAddressInfo(e.target.value, 'street1');
                  }}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Address 2:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.pickupAddress.street2}
                  id="pickUpAddress2"
                  name="pickUpStreet2"
                  onChange={e =>
                    updatePickupAddressInfo(e.target.value, 'street2')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">City/St/Zip:</Label>
              <Col md="3">
                <Input
                  type="text"
                  value={inputData.pickupAddress.zipCode.city}
                />
              </Col>
              <Col md="1">
                <Input
                  type="text"
                  value={inputData.pickupAddress.zipCode.state}
                />
              </Col>
              <Col md="2">
                <Input
                  type="text"
                  value={inputData.pickupAddress.zipCode.zip}
                />
              </Col>
              <Label md="2" className="text-right">
                Lookup zip:
              </Label>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  onChange={e => {
                    if (e) {
                      // updatePickupAddressInfo(e.chr_city, 'city');
                      // updatePickupAddressInfo(e.chr_state, 'state');
                      // updatePickupAddressInfo(e.chr_zipcode, 'zip');
                      // updatePickupAddressInfo(e.chr_county, 'county');
                      let addressObj = {
                        city: e.chr_city,
                        county: e.chr_county,
                        dts: true,
                        id: e.code_id,
                        state: e.chr_state,
                        timeZone: e.chr_timezone,
                        zip: e.chr_zipcode,
                      };
                      updatePickupAddressInfo(addressObj, 'zipCode');
                    } else {
                      updatePickupAddressInfo('', 'zipCode');
                    }
                  }}
                  placeholder="zipcode lookup"
                  menuPlacement="bottom"
                  value={''}
                  onInputChange={e => handleTypeAheadSearch(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  closeMenuOnSelect={true}
                  options={
                    zipList.length
                      ? zipList.map(obj =>
                        assign(obj, {
                          label: obj.zip_type_with_county,
                          value: obj.chr_zipcode,
                        })
                      )
                      : []
                  }
                  name="locZip"
                  id="locZip"
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Phone:</Label>
              <Col md="3">
                <Input
                  type="tel"
                  value={inputData.pickupAddress.phone}
                  id="phone1"
                  name="phone1"
                  minLength="0"
                  maxLength="14"
                  placeholder="(xxx)xxx-xxxx"
                  onChange={e =>
                    updatePickupAddressInfo(e.target.value, 'phone')
                  }
                />
              </Col>
              <Col md="5"></Col>
              <Col md="2">
                <Button onClick={clearPickUpAddress}>Clear</Button>
              </Col>
            </Row>
            <Row>
              <Col sm="10" md={{ size: 3, offset: 4 }}>
                <Label>
                  <b>Drop Off Information</b>
                </Label>
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Drop Off Date:</Label>
              <Col md="4">
                <AppDateInput
                  onChange={value =>
                    updateTransportModalForm(value, 'dropOffDate')
                  }
                  selected={inputData.dropOffDate ? inputData.dropOffDate : ''}
                  value={inputData?.dropOffDate || ''}
                />
              </Col>
              <Col md="4">
                <AppInput
                  id="locationHistory"
                  type="select"
                  placeholder="LocationHistory"
                  changeHandler={(id, val) => {
                    setSelectedDropLocation(val);
                    let selectedObj = JSON.parse(val);
                    changeZipList(selectedObj.zipCode.zip);
                    updateDropAddressInfo(selectedObj.street1, 'street1');
                    updateDropAddressInfo(selectedObj.street2, 'street2');
                    updateDropAddressInfo(selectedObj.zipCode, 'zipCode');
                    updateTransportModalForm(
                      selectedObj.locationName,
                      'dropLocationName'
                    );
                    updateDropAddressInfo(selectedObj.phone, 'phone');
                  }}
                  option={{
                    value: 'data',
                    displayValue: 'address',
                  }}
                  value={selectedDropLocation}
                  data={addressInfo}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Drop Off Time:</Label>
              <Col md="2">
                <Input
                  type="text"
                  min="0"
                  value={inputData.dropOffHours}
                  id="dropOffHours"
                  name="dropOffHours"
                  onChange={e =>
                    updateTransportModalForm(
                      Number(e.target.value) > 12 ? '12' : e.target.value,
                      'dropOffHours'
                    )
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  type="text"
                  min="0"
                  value={inputData.dropOffMin}
                  id="dropOffMin"
                  name="dropOffMin"
                  onBlur={e => dropOffRoundOff(e.target.value)}
                  onChange={e =>
                    updateTransportModalForm(e.target.value, 'dropOffMin')
                  }
                />
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateTransportModalForm('am', 'dropOffAM')}
                >
                  AM
                </Button>
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateTransportModalForm('pm', 'dropOffAM')}
                >
                  PM
                </Button>
              </Col>
              <Col md="2">
                <AppInput
                  id="dropOffTz"
                  type="select"
                  changeHandler={(id, value) =>
                    updateTransportModalForm(value, 'dropOffTz')
                  }
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={inputData.dropOffTz}
                  data={timeZone}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Location:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.dropLocationName}
                  id="dropLocationName"
                  name="dropLocationName"
                  onChange={e =>
                    updateTransportModalForm(e.target.value, 'dropLocationName')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Address:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.dropOffAddress.street1}
                  id="dropOffAddress"
                  name="dropOffAddress"
                  onChange={e =>
                    updateDropAddressInfo(e.target.value, 'street1')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Address 2:</Label>
              <Col md="8">
                <Input
                  type="text"
                  value={inputData.dropOffAddress.street2}
                  id="dropOffAddress2"
                  name="dropOffAddress2"
                  onChange={e =>
                    updateDropAddressInfo(e.target.value, 'street2')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">City/St/Zip:</Label>
              <Col md="3">
                <Input
                  type="text"
                  value={inputData.dropOffAddress.zipCode.city}
                />
              </Col>
              <Col md="1">
                <Input
                  type="text"
                  value={inputData.dropOffAddress.zipCode.state}
                />
              </Col>
              <Col md="2">
                <Input
                  type="text"
                  value={inputData.dropOffAddress.zipCode.zip}
                />
              </Col>
              <Label md="2" className="text-right">
                Lookup zip:
              </Label>
              <Col md="2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  onChange={e => {
                    if (e) {
                      // updateDropAddressInfo(e.chr_city, 'city');
                      // updateDropAddressInfo(e.chr_state, 'state');
                      // updateDropAddressInfo(e.chr_zipcode, 'zip');
                      let addressObj = {
                        city: e.chr_city,
                        county: e.chr_county,
                        dts: true,
                        id: e.code_id,
                        state: e.chr_state,
                        timeZone: e.chr_timezone,
                        zip: e.chr_zipcode,
                      };
                      updateDropAddressInfo(addressObj, 'zipCode');
                    } else {
                      updateDropAddressInfo('', 'zipCode');
                    }
                  }}
                  placeholder="zipcode lookup"
                  menuPlacement="bottom"
                  onInputChange={e => handleTypeAheadSearch(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={''}
                  closeMenuOnSelect={true}
                  options={
                    zipList.length
                      ? zipList.map(obj =>
                        assign(obj, {
                          label: obj.zip_type_with_county,
                          value: obj.chr_zipcode,
                        })
                      )
                      : []
                  }
                  name="locZip"
                  id="locZip"
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Phone:</Label>
              <Col md="3">
                <Input
                  type="tel"
                  value={inputData.dropOffAddress.phone}
                  id="phone2"
                  name="phone2"
                  minLength="0"
                  maxLength="14"
                  onChange={e => updateDropAddressInfo(e.target.value, 'phone')}
                  placeholder="(xxx)xxx-xxxx"
                />
              </Col>
              <Col md="5">
                <Button onClick={() => openGoogleMaps(inputData)}>Map</Button>
              </Col>

              <Col md="2">
                <Button onClick={clearDropOffData}>Clear</Button>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Row style={{ padding: '3px' }}>
                  <Label md="7">Est.Mileage</Label>
                  <Col md="5">
                    <Input
                      type="text"
                      value={inputData.estimatedMillage}
                      id="estimateMillage"
                      name="estimateMillage"
                      onChange={e =>
                        updateTransportModalForm(
                          e.target.value,
                          'estimatedMillage'
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ padding: '3px' }}>
                  <Label md="8">Ad'l Passenger:</Label>
                  <Col md="4" style={{ marginTop: '8px' }}>
                    <Input
                      type="checkbox"
                      checked={inputData.extraPassenger}
                      id="extraPassenger"
                      name="extraPassenger"
                      onClick={e =>
                        updateTransportModalForm(
                          e.target.checked,
                          'extraPassenger'
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row style={{ padding: '3px' }}>
                  <Label md="7">Dead Mileage</Label>
                  <Col md="5">
                    <Input
                      type="text"
                      value={inputData.deadMileage}
                      id="deadMileage"
                      name="deadMileage"
                      onChange={e =>
                        updateTransportModalForm(e.target.value, 'deadMileage')
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row style={{ padding: '3px' }}>
                  <Label md="7">Proj Wait Time</Label>
                  <Col md="5">
                    <Input
                      type="number"
                      min="0"
                      value={inputData.proWaitHour}
                      id="ProjWaitTimeH"
                      name="ProjWaitTimeH"
                      onBlur={e => projWaitTimeM(e.target.value)}
                      onChange={e =>
                        updateTransportModalForm(e.target.value, 'proWaitHour')
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ padding: '2px' }}>
                  <Label md="7">Actual Wait Time</Label>
                  <Col md="5">
                    <Input
                      type="number"
                      min="0"
                      value={inputData.waitHour}
                      id="ActualwaitTimeH"
                      name="ActualwaitTimeH"
                      onBlur={e => ActualwaitTimeH(e.target.value)}
                      onChange={e =>
                        updateTransportModalForm(e.target.value, 'waitHour')
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ padding: '2px' }}>
                  <Label md="8">WheelChair Req:</Label>
                  <Col md="4" style={{ marginTop: '7px' }}>
                    <Input
                      type="checkbox"
                      checked={inputData.wheelChairRental}
                      id="wheelChairRental"
                      name="wheelChairRental"
                      onClick={e =>
                        updateTransportModalForm(
                          e.target.checked,
                          'wheelChairRental'
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row style={{ padding: '2px' }}>
                  <Label md="1">H</Label>
                  <Col md="5">
                    <Input
                      type="number"
                      min="0"
                      value={inputData.proWaitMinute}
                      id="ProjWaitTimeM"
                      name="ProjWaitTimeM"
                      onBlur={e => projWaitTimeH(e.target.value)}
                      onChange={e =>
                        updateTransportModalForm(
                          e.target.value,
                          'proWaitMinute'
                        )
                      }
                    />
                  </Col>
                  <Label md="1">M</Label>
                </Row>
                <Row style={{ padding: '2px' }}>
                  <Label md="1">H</Label>
                  <Col md="5">
                    <Input
                      type="number"
                      min="0"
                      value={inputData.waitMinute}
                      id="ActualwaitTimeM"
                      name="ActualwaitTimeM"
                      onBlur={e => ActualwaitTimeM(e.target.value)}
                      onChange={e =>
                        updateTransportModalForm(e.target.value, 'waitMinute')
                      }
                    />
                  </Col>
                  <Label md="1">M</Label>
                </Row>
              </Col>
            </Row>

            <div style={{ float: 'right' }}>
              <Button type="button" disabled={disableSave} onClick={validateFields}>
                Save
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  // const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    // patientInfo: fast360Info.patientZip,
    trnDetailsList: state.FAST360.fast360Reducer.trnDetailsList
      ? state.FAST360.fast360Reducer.trnDetailsList
      : [],
  };
};

const mapDispatchToProps = dispatch => ({
  saveTransportLeg: data => dispatch(fast360Operations.saveTransportLeg(data)),
  updateTRNInfoTable: data => dispatch(fast360Actions.updateTRNInfoTable(data)),
  setTrnDetailModaldata: data =>
    dispatch(fast360Actions.trnDetailModaldata(data)),
  trnSaveData: data => dispatch(fast360Actions.trnSaveData(data)),
  updateTrnDetailList: data =>
    dispatch(fast360Actions.updateTrnDetailList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportDetailModal);
