import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class PopupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    getToggle = () => {
        const hasExternalToggle = !!this.props.externalToggle;
        if (!hasExternalToggle) {
            return <>
                <Button color={this.props.isButton ? "primary" : "link"}
                    onClick={!!this.props.externalToggle ? this.props.externalToggle
                        :
                        this.toggle}>
                    {this.props.text}
                </Button>
            </>
        }
    };

    render() {
        return (
            <>
                {this.getToggle()}

                <Modal isOpen={!!this.props.externalToggle ? this.props.isOpen : this.state.modal}
                    toggle={!!this.props.externalToggle ? this.props.externalToggle : this.toggle}
                    className={!!this.props.className ? this.props.className : null}
                    size={!!this.props.size ? this.props.size : null}
                    onOpened={this.props.onOpened}
                    onClosed={this.props.onClosed}
                    backdrop="static"
                >
                    <ModalHeader toggle={!!this.props.externalToggle ? this.props.externalToggle : this.toggle}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.content}
                    </ModalBody>
                    {this.props.includeFooter ?
                        <ModalFooter>
                            {this.props.footerContent}
                        </ModalFooter>
                        :
                        null
                    }
                </Modal>
            </>
        );
    }
}

PopupModal.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    footerContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    isButton: PropTypes.bool,
    includeFooter: PropTypes.bool,
    size: PropTypes.string,
    externalToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func
};

PopupModal.defaultProps = {
    isButton: false,
    includeFooter: true,
    //text: "Popup Modal"
};

export default PopupModal;