import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label, Row, Button, Col, Table } from 'reactstrap';
import AppDateInput from 'shared/components/input/AppDateInput';
import { shortToDatabaseTable } from '../utils/dataUtils'
import {
    fast360Operations, fast360Selectors,
    fast360Actions,
} from '../store';
import { authSelectors } from 'modules/auth/store';
import moment from 'moment';
import Fast360NotesTable from 'shared/components/table/Fast360NotesTable';
import { billOperationsSelectors } from '../../bill-operations/store';


class Fast360Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fDate: "",
            fHrs: "",
            fMin: "",
            timeZ: "",
            notesInput: "",
            notesData: [],
            noteType: '',
            notesDataBackup: [],
            followupType: this.props.fast360Reducer?.followupData?.followupType ? this.props.fast360Reducer.followupData.followupType : '',
            followupDate: null,
            userNotes: [],
            showCancel: false,
            min: 0,
            max: 12
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({ showCancel: true })
    };

    componentDidMount() {
        const { table, tableName, refId, serviceId, currentUserId } = this.props
        let payload1 = {
            "functionName": "loadReferral",
            "zebraUserId": currentUserId,
            "referral_id": refId
        }
        let payload2 = {
            "functionName": "loadUserNotes",
            "zebraUserId": currentUserId,
            "refId": refId,
            "tableName": tableName,
            "serviceId": serviceId,
            "claimantId": ""
        };
        let payload3 = {
            "functionName": "loadFollowupInfo",
            "zebraUserId": currentUserId,
            "serviceId": serviceId,
            "table": table.toLowerCase() == 'modification' ? 'modifications' : table.toLowerCase(),
            "tableName": tableName
        }
        let breadCrumb = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": currentUserId,
            "userid": currentUserId,
            "breadcrumbkey": 'OPENNOTES',
            "refTableId": serviceId,
            "refId": refId,
            "refTable": tableName,
        };
        this.props.logBreadCrumbs(breadCrumb)
        this.props.getNotesData(payload1);
        this.props.getLoadNotes(payload2);
        this.props.getFollowupData(payload3);
    }

    getServiceFromMod = (serviceAbrv) => {
        if (serviceAbrv === "HHC") {
            return "HHC";
        } else if (serviceAbrv === "AUD") {
            return "Audiology";
        } else if (serviceAbrv === "DTL") {
            return "Dental";
        } else if (serviceAbrv === "DGN") {
            return "Diagnostic";
        } else if (serviceAbrv === "MOD") {
            return "Modification";
        } else if (serviceAbrv === "MSE") {
            return "MSE";
        } else if (serviceAbrv === "TRN") {
            return "Transportation";
        } else if (serviceAbrv === "INT") {
            return "Interpretation";
        } else if (serviceAbrv === "SPT") {
            return "Special Transport";
        }
        return "";
    };
    //   getConfId = (dataObject) =>{
    //     return {dataObject.referralId + '-' + dataObject.module + '-' + dataObject.serviceId}
    //   }
    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if (nextProps.fast360Reducer.followupData && nextProps.fast360Reducer.followupData.followupDate && nextProps.fast360Reducer.followupData.followupDate != "") {
                let fTime = nextProps.fast360Reducer.followupData.followupDate.split(" ")[1];
                let Hrs = fTime.split(":")[0];
                let fMin = fTime.split(":")[1];
                let timeZ = Number(Hrs) < 12 ? 'AM' : 'PM';
                let fHrs = Number(Hrs) > 12 ? Number(Hrs) - 12 : Number(Hrs);
                if (Number(Hrs) === 0) {
                    fHrs = 12;
                    timeZ = 'AM'
                }
                this.setState({
                    fHrs: fHrs, fMin: Number(fMin), timeZ: timeZ,
                    followupDate: new Date(nextProps.fast360Reducer.followupData.followupDate)
                }, () => {
                })
            }
            if (nextProps.fast360Reducer.loadNotesData.length) {
                this.setState({ notesData: nextProps.fast360Reducer.loadNotesData, notesDataBackup: nextProps.fast360Reducer.loadNotesData })
            }
            if (
                nextProps.fast360Reducer.followupData && this.props.fast360Reducer.followupData
                && this.props.fast360Reducer.followupData.followupType != nextProps.fast360Reducer.followupData.followupType) {
                this.setState({ followupType: nextProps.fast360Reducer.followupData.followupType })
            }
        }
    }

    handleHrChange = (e) => {
        this.setState({ fHrs: e.target.value })
    }

    handleMinChange = (e) => {
        this.setState({ fMin: e.target.value })
    }

    handleRadioChange = (data) => {
        this.setState({ timeZ: data })
    }

    onChangeNoteText = (e) => {
        this.setState({ notesInput: e })
    }

    noteTypeChange = (e) => {
        this.setState({ noteType: e })
        if (e == "All") {
            this.setState({ notesData: this.state.notesDataBackup })
        }
        else {
            let filteredData = this.state.notesDataBackup.filter(i => i.noteType == e)
            this.setState({ notesData: filteredData })
        }
    }

    add = () => {
        const { tableName, refId, roleUsers, currentUserId } = this.props;
        const user = roleUsers.find(u => u.zebraId == currentUserId);
        let userNotes = this.state.userNotes;
        let obj = {
            'userName': `${this.props.userName.firstname} ${this.props.userName.lastname}`,
            'enteredDate': new Date(),
            'note': this.state.notesInput,
            'noteType': (this.state.noteType == '' || this.state.noteType == 'All') ? 'General' : this.state.noteType,
            'canClientSee': false,
            "id": null,
            "referenceTable": tableName,
            "referenceId": refId,
            "internalUse": true,
            "userId": user.id
        }
        let dataStr = JSON.stringify(this.state.notesData);
        let backupDataStr = JSON.stringify(this.state.notesDataBackup);
        let data = JSON.parse(dataStr);
        let backupData = JSON.parse(backupDataStr);
        data.unshift(obj);
        backupData.unshift(obj)
        userNotes.push(obj);
        this.setState({ notesInput: '', notesData: data, notesDataBackup: backupData, userNotes: userNotes })
    }

    handleDateChange = (date) => {
        this.setState({ followupDate: date })
    }

    followupReasonChange = (e) => {
        this.setState({ followupType: e })
    }

    handleSave = () => {
        const { table, tableName, serviceId, currentUserId } = this.props;
        let { fHrs, fMin, timeZ } = this.state;
        fHrs = (timeZ == 'PM' && Number(fHrs) !== 12) ? Number(fHrs) + 12 : fHrs;
        if (timeZ == 'AM' && Number(fHrs) === 12) {
            fHrs = 0;
        }
        let hrs = Number(fHrs) > 9 ? fHrs : `0${fHrs}`;
        let min = Number(fMin) > 9 ? fMin : `0${fMin}`;
        let timeStamp = `${hrs}:${min}:00.0`;
        let saveNotesPayload = {
            "functionName": "saveNotes",
            "zebraUserId": currentUserId,
            "serviceId": serviceId,
            notes: this.state.userNotes
        }
        let saveFollowupPayload = {
            "functionName": "saveFollowup",
            "zebraUserId": currentUserId,
            "serviceId": serviceId,
            "table": table.toLowerCase(),
            "followupDate": `${moment(this.state.followupDate).format('YYYY-MM-DD')} ${timeStamp}`,
            "followupType": this.state.followupType,
            "servAbbrv": tableName,
            "lmsUserId": this.props.lmsUserId
        }
        let breadCrumb = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": currentUserId,
            "userid": currentUserId,
            "breadcrumbkey": 'FOLLOWUPDATECHANGE',
            "refTableId": serviceId,
            "refId": 0,
            "refTable": tableName,
        };
        this.props.logBreadCrumbs(breadCrumb);
        let breadCrumb2 = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": currentUserId,
            "userid": currentUserId,
            "breadcrumbkey": 'FOLLOWUPCHANGE',
            "refTableId": serviceId,
            "refId": 0,
            "refTable": tableName,
        };
        this.props.logBreadCrumbs(breadCrumb2);
        let breadCrumb3 = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": currentUserId,
            "userid": currentUserId,
            "breadcrumbkey": 'SAVENOTES',
            "refTableId": serviceId,
            "refId": 0,
            "refTable": tableName,
        };
        this.props.logBreadCrumbs(breadCrumb3)
        this.props.saveNotesData(saveNotesPayload);
        this.props.saveFollowUp(saveFollowupPayload);
        this.props.clicked();
        this.props.resetNotes([]);
        !this.props.fromlanding && this.props.updateFollowUpDates({ follow: `${moment(this.state.followupDate).format('YYYY-MM-DD')} ${timeStamp}`, type: this.state.followupType, stateData: this.state });
        const id = this.props.zebraId || this.props.currentUserId;
        const payload = {
            zebraId: id,
            functionName: "loadUserWorkList",
            id: this.props.roleUsers.filter(i => i.zebraId == id)[0].id
        }
        setTimeout(() => {
            this.props.getUsersWorkList(payload, this.props.currentUserId);
            this.props.updateToggleValues(!this.props.updateTogVal);
        }, 2000);

    }


    getPhoneFormat = (phone) => {
        let phNo = '', firPart = '', secondPart = '', thirdPart = '', formatted = '';
        if (phone) {
            phNo = phone.toString();
            firPart = phNo.substr(0, 3);
            secondPart = phNo.substr(3, 3);
            thirdPart = phNo.substr(6, 4);
            formatted = `(${firPart}) ${secondPart}-${thirdPart}`
        }
        return formatted;
    }

    handleCancel = (bool) => {
        if (!bool)
            this.setState({ showCancel: false })
        else {
            this.props.clicked();
            this.props.resetNotes([]);
        }
    }

    getCancelModal = () => (
        <Modal isOpen={true} size="md" style={{ height: '250px' }} scrollable={'true'}>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
                Are you sure you wish to exit without saving changes?<br></br>
                <Row>
                    <Col style={{ marginLeft: '200px', marginTop: '54px' }}> <Button onClick={() => this.handleCancel(false)} style={{ width: '120px' }}>No</Button></Col>
                    <Col style={{ marginTop: '54px' }}> <Button onClick={() => this.handleCancel(true)} style={{ width: '120px' }}>Yes</Button></Col>

                </Row>
            </ModalBody>
        </Modal>
    )

    getList = (data, type, id) => {
        const obj = {
            'MSE': 'mseList',
            'mse': 'mseList',
            'HHC': 'hhcList',
            'hhc': 'hhcList',
            'Dental': 'dentalList',
            'dental': 'dentalList',
            'Interpretation': 'translationList',
            'interpretation': 'translationList',
            'Transportation': 'transportationList',
            'transportation': 'transportationList',
            'Accommodation': 'accommodationList',
            'accommodation': 'accommodationList',
            'Audiology': 'audiologyList',
            'audiology': 'audiologyList',
            'Modification': 'modificationList',
            'modifications': 'modificationList',
            'Special Transport': 'specialTransportationList',
            'special_transp': 'specialTransportationList',
            'diagnostic': 'diagnosticList',
            'Diagnostic': 'diagnosticList'
        }
        let list = data[obj[type]]
        let rec = list?.filter(i => i.id == id)[0];
        return (rec ? rec.vendor : undefined)
    }

    getModalBody = (payloadData) => {
        const { dataObject } = this.props;
        if (payloadData && payloadData.claim) {
            const { claimant } = payloadData.claim;
            const { payor, adjuster, caseManager } = payloadData;
            const dxCodes = this.props.dxCodes;
            const vendor = this.getList(payloadData, (dataObject.ServiceType ? dataObject.ServiceType : shortToDatabaseTable(dataObject.module)), (dataObject.int_reference_table_id ? dataObject.int_reference_table_id : dataObject.serviceId));
            return (<ModalBody>
                <Row style={{ border: '1px solid black' }}>
                    <Col style={{ borderRight: '1px solid black' }} >
                        <Row style={{ marginLeft: '15px' }}>
                            Claimant info: {claimant.people.first} {claimant.people.last}
                            <br></br>{claimant.address.street1} {claimant.address.zipCode.city}
                            {claimant.address.zipCode.state}, {claimant.address.zipCode.zip}
                        </Row>
                        <Row style={{ marginLeft: '15px' }}>
                            Phone: {this.getPhoneFormat(claimant.people.phone)}
                        </Row>
                        <Row >
                            <Col style={{ marginLeft: '15px' }}> DOB: {moment(claimant.people.dob).format('MM/DD/YYYY')}
                            </Col>
                            <Col>
                                DOI: {moment(payloadData.claim.doi).format('MM/DD/YYYY')}</Col>
                        </Row>
                        <Row>
                            <Col style={{ marginLeft: '15px' }}>Height: {claimant.heightFeet}ft {claimant.heightInch}in </Col>
                            <Col>Weight: {claimant.weight}lbs</Col>
                        </Row>
                        <Row style={{ marginLeft: '15px' }}>
                            DX Codes: {dxCodes}
                        </Row>

                    </Col>
                    <Col>
                        <Row style={{ marginLeft: '15px' }}>Payor Info: {payor.name} <br></br>
                            {payor.address.street1} {payor.address.zipCode.city} {payor.address.zipCode.state}, {payor.address.zipCode.zip}</Row>
                        <Row style={{ marginLeft: '15px' }}> Phone: {this.getPhoneFormat(payor.phone)}
                        </Row>
                        <Row style={{ marginLeft: '15px' }}>Claim #: {dataObject.chr_claim_no ? dataObject.chr_claim_no.split(' ').join('\u00A0') : this.props.worklistData?.claim?.claimNo ? this.props.worklistData.claim.claimNo?.split(' ').join('\u00A0') : this.props.worklistData.data.claimNo?.split(' ').join('\u00A0')}</Row>


                    </Col>
                </Row>
                <Row style={{ border: '1px solid black', background: '#FFFDE0' }}>
                    <Col style={{ borderRight: '1px solid black' }} >
                        <Row style={{ marginLeft: '15px' }}>Service: {(dataObject.ServiceType ? dataObject.ServiceType : this.getServiceFromMod(dataObject.module))}</Row>
                        <Row style={{ marginLeft: '15px' }}>Order Outcome: {dataObject.statusReminder ? dataObject.statusReminder : this.props.currentOutcomeDesc ? this.props.currentOutcomeDesc : 'Referral'}</Row>
                        <Row>
                            <Col style={{ marginLeft: '15px' }}>Sub Service: {dataObject.product ? dataObject.product : dataObject.subService}</Col>
                            <Col style={{ marginLeft: '15px' }}>Conf #: {dataObject.confNo ? dataObject.confNo : dataObject.referralId + '-' + dataObject.module + '-' + dataObject.serviceId}</Col>
                        </Row>
                        <Row style={{ marginLeft: '15px' }}>Date Of Service: {dataObject.serviceDate ? moment(dataObject.serviceDate).format('MM/DD/YYYY') : moment(dataObject.dos).format('MM/DD/YYYY')}</Row>
                    </Col>
                    <Col>
                        <Row style={{ marginLeft: '15px' }}>
                            Vendor Info: {vendor && vendor.name}
                        </Row>
                        <Row style={{ marginLeft: '15px' }}>
                            {vendor &&
                                <>
                                    <span style={{ marginRight: '6px' }}>{vendor.address.street1 == undefined ? '' : ` ${vendor.address.street1} `}</span>
                                    <span style={{ marginRight: '6px' }}>{vendor.address.zipCode.city == undefined ? '' : ` ${vendor.address.zipCode.city} `} </span>
                                    <span style={{ marginRight: '6px' }}>{vendor.address.zipCode.state == undefined ? '' : ` ${vendor.address.zipCode.state},`}</span>
                                    <span>{vendor.address.zipCode.zip == undefined ? '' : ` ${vendor.address.zipCode.zip}`}</span>
                                </>}


                        </Row>
                        <Row style={{ marginLeft: '15px' }}>Contact:</Row>
                        <Row style={{ marginLeft: '15px' }}>Phone: {vendor && this.getPhoneFormat(vendor.phone)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Fax: {vendor && this.getPhoneFormat(vendor.fax)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Email: {vendor && vendor.email1}</Row>
                    </Col>
                </Row>
                <Row style={{ border: '1px solid black' }}>
                    <Col style={{ borderRight: '1px solid black' }} >
                        <Row style={{ marginLeft: '15px' }}>Adjuster: {adjuster.people.first} {adjuster.people.last}</Row>
                        <Row style={{ marginLeft: '15px' }}>Phone: {this.getPhoneFormat(adjuster.people.phone)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Fax: {this.getPhoneFormat(adjuster.people.fax)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Email: {adjuster.people.email1}</Row>
                    </Col>
                    <Col>
                        <Row style={{ marginLeft: '15px' }}>Case Mgr: {caseManager.people.first} {caseManager.people.last}</Row>
                        <Row style={{ marginLeft: '15px' }}>Phone: {this.getPhoneFormat(caseManager.people.phone)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Fax: {this.getPhoneFormat(caseManager.people.fax)}</Row>
                        <Row style={{ marginLeft: '15px' }}>Email:{caseManager.people.email1} </Row>
                    </Col>
                </Row>
                Add Notes<br />
                <Row>
                    <Col xl={6} lg={6}>
                        <Input
                            type="textarea"
                            name="text"
                            id="fast360Notes"
                            value={this.state.notesInput}
                            onChange={e => this.onChangeNoteText(e.target.value)}
                        />
                    </Col>
                    <Col xl={1} lg={1} style={{ marginTop: '25px' }}> Note Type:</Col>
                    <Col xl={3} lg={3} style={{ marginTop: '20px', marginLeft: '-22px' }}>
                        <FormGroup>
                            <Input
                                type="select"
                                onChange={e => this.noteTypeChange(e.target.value)}
                                defaultValue="All"
                            >
                                <option value="All">All</option>
                                <option value="General">General</option>
                                <option value="Special Instructions_Vendor">Special Instructions_Vendor</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xl={2} lg={2} style={{ marginTop: '20px', marginLeft: '15px' }}> <Button onClick={this.add}>+</Button> </Col>
                </Row>
                <Row>
                    <Fast360NotesTable
                        modalHeight={250}
                        columns={[
                            {
                                header: 'Name',
                                field: 'userName',
                                percent: '12',
                            },
                            {
                                header: 'Date',
                                field: 'enteredDate',
                                percent: '14',
                            },
                            {
                                header: 'Note',
                                field: 'note',
                                percent: '52',
                            },
                            {
                                header: 'Type',
                                field: 'noteType',
                                percent: '16',
                            },
                            {
                                header: 'Send',
                                field: 'canClientSee',
                                percent: '6',
                            },

                        ]}
                        data={this.state.notesData || []}
                        //autoPagination={true}
                        sortAble={true}
                        resizable={true}
                        sortImmediately={false}
                        key="notes-table"
                    />
                </Row >
                <Row>
                    <Col xl={1} lg={1} style={{ marginTop: '7px' }}>
                        Follow up:
                    </Col>
                    <Col xl={2} lg={2} style={{ marginLeft: '-25px' }}>
                        <AppDateInput
                            id="FollowupDate"
                            onChange={date => this.handleDateChange(date)}
                            selected={this.state.followupDate
                                ? this.state.followupDate
                                : new Date()}
                            minDate={moment().toDate()}
                        />
                    </Col>
                    <Col xl={1} lg={1}>
                        <Input
                            type="number"
                            // min={this.state.min}
                            // max={this.state.max}
                            min={1}
                            max={12}
                            value={Number(this.state.fHrs)}
                            onChange={(e) => this.handleHrChange(e)}
                        >
                        </Input>
                    </Col>
                    <Col xl={1} lg={1}>
                        <Input
                            type="number"
                            min={0}
                            max={59}
                            value={Number(this.state.fMin)}
                            onChange={(e) => this.handleMinChange(e)}
                        >
                        </Input>
                    </Col>
                    <Col xl={1} lg={1} style={{ marginTop: '7px' }}> <FormGroup check>
                        <Label check>
                            <Input type="radio" name="radio2" checked={this.state.timeZ === 'AM' ? true : false}
                                onChange={() => this.handleRadioChange('AM')} />  AM </Label>
                    </FormGroup></Col>
                    <Col xl={1} lg={1} style={{ marginTop: '7px', marginLeft: '-40px' }}>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio2" checked={this.state.timeZ === 'PM' ? true : false}
                                    onChange={() => this.handleRadioChange('PM')} /> PM
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xl={1} lg={1}>
                        <Button onClick={this.handleSave} style={{ width: '140px' }} >SAVE</Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '20px' }}>Follow up Reason:</Col>
                    <Col xl={9} lg={9} style={{ marginRight: '157px', marginTop: '15px' }}><FormGroup style={{ width: '300px' }}>
                        <Input
                            type="select"
                            defaultValue=""
                            placeholder="Select a Followup reason"
                            value={this.state.followupType || ''}
                            onChange={e => this.followupReasonChange(e.target.value)}
                        >
                            <option value="" disabled={true}>Select a Followup reason</option>
                            {this.props.fast360Reducer.followupData && this.props.fast360Reducer.followupData.followupReasons &&
                                this.props.fast360Reducer.followupData.followupReasons.map(item => (
                                    <option value={item.chr_description}>{item.chr_description}</option>
                                ))}
                        </Input>
                    </FormGroup></Col>
                </Row>
            </ModalBody >)
        }
        else
            return <ModalBody>Loading Notes</ModalBody>
    }

    componentWillUnmount() {

        setTimeout(() => {
            const { roleUsers, currentUserId, zebraId } = this.props;
            let id = zebraId ? zebraId : currentUserId
            const user = roleUsers.find(u => u.zebraId == id);
            // this.props.getUsersWorkList(user, id);
        }, 2000);
    }

    //test commit1 to trigger UAT
    render() {
        const payloadData = this.props.fast360Reducer.notesData;
        return (
            <>{this.state.showCancel && this.getCancelModal()}
                {!this.state.showCancel && <Modal size="xl" isOpen={true} style={{ height: '750px' }} scrollable={true}>
                    <ModalHeader toggle={this.toggle}>Order Detail Notes</ModalHeader>
                    {this.getModalBody(payloadData)}
                </Modal >}
            </>
        )
    }
}

const mapStateToProps = state => {
    //debugger;
    const roleUsers = state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
        fast360Reducer = state.FAST360.fast360Reducer || {},
        notesData = fast360Reducer.notesData || null,
        claim = notesData ? notesData.claim : null;
    return {
        fast360Reducer,
        currentUserId: authSelectors.getUserId(state),
        dxCodes: claim && claim.injuryList ? claim.injuryList.flatMap(i => i.diagnosisList.map(d => d.chr_icdfull)).join(", ") : "",
        userName: state.AUTH.user,
        roleUsers,
        lmsUserId: roleUsers.LmsUserId || '',
        updateTogVal: state.FAST360.fast360Reducer.updateToggleValues
    };
};

const mapDispatchToProps = dispatch => ({
    getNotesData: (data) => dispatch(fast360Operations.getNotesData(data)),
    getLoadNotes: (data) => dispatch(fast360Operations.getLoadNotes(data)),
    getFollowupData: (data) => dispatch(fast360Operations.getFollowupData(data)),
    saveNotesData: (data) => dispatch(fast360Operations.saveNotesData(data)),
    saveFollowUp: (data) => dispatch(fast360Operations.saveFollowUp(data)),
    resetNotes: (data) => dispatch(fast360Actions.updateGetNotesData(data)),
    logBreadCrumbs: data =>
        dispatch(fast360Operations.logBreadCrumbs(data)),
    getUsersWorkList: (data, currentUserId) => dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
    updateFollowUpDates: (data) => dispatch(fast360Actions.updateFollowUpDates(data)),
    updateToggleValues: (data) => dispatch(fast360Actions.updateToggleValues(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360Notes);