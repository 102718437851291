import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import {
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup,
  Label,
  InputGroup,
} from 'reactstrap';

import AppDateInput from 'shared/components/input/AppDateInput';

class SiteMaintenanceFormClient extends Component {
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.props.onInputChangeHandler(name, value);
  };

  render() {
    return (
      <>
        <Row>
          <Col lg="5">
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.props.inputValue.name || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="address1">Address</Label>
              <Input
                type="text"
                name="address1"
                value={this.props.inputValue.address1 || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="address2"
                value={this.props.inputValue.address2 || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormGroup row>
                <Col lg="12" xl="6">
                  <FormGroup>
                    <Input
                      type="text"
                      name="city"
                      disabled
                      value={this.props.inputValue.city || ''}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12" xl="6">
                  <Row>
                    <Col xl="6">
                      <FormGroup>
                        <Input
                          type="text"
                          name="state"
                          disabled
                          value={this.props.inputValue.clientState || ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Input
                          type="text"
                          onBlur={this.props.onZipCodeBlur}
                          name="zip"
                          value={this.props.inputValue.zip || ''}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="clientTin">TIN</Label>
              <Input
                type="text"
                name="clientTin"
                value={this.props.inputValue.clientTin || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="carrierType">Type</Label>
              <Input
                type="select"
                name="carrierType"
                value={this.props.inputValue.carrierType || ''}
                onChange={this.handleInputChange}
              >
                <option value="" hidden></option>
                <option>Carrier</option>
                <option>TPA</option>
                <option>TPA Location</option>
                <option>TPA Account</option>
                <option>Broker</option>
                <option>Law Firm</option>
                <option>Carrier Location</option>
                <option>Adjuster</option>
                <option>Service Company</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="contact">Contact</Label>
              <Input
                type="text"
                name="contact"
                value={this.props.inputValue.contact || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <InputMask
                mask="999-999-9999"
                alwaysShowMask={true}
                name="phone"
                value={this.props.inputValue.phone || ''}
                onChange={this.handleInputChange}
              >
                <Input />
              </InputMask>
            </FormGroup>
            <FormGroup>
              <Label for="fax">Fax</Label>
              <InputMask
                mask="999-999-9999"
                alwaysShowMask={true}
                name="fax"
                value={this.props.inputValue.fax || ''}
                onChange={this.handleInputChange}
              >
                <Input />
              </InputMask>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <div className="full-width">
                <AppDateInput
                  name="effDate"
                  onChange={value =>
                    this.props.onInputChangeHandler('effDate', value)
                  }
                  selected={this.props.inputValue.effDate}
                  label="Eff"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="full-width">
                <AppDateInput
                  name="termDate"
                  onChange={value =>
                    this.props.onInputChangeHandler('termDate', value)
                  }
                  selected={this.props.inputValue.termDate}
                  label="Term"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="logo">Logo</Label>
              <Input
                type="select"
                name="logo"
                value={this.props.inputValue.logo}
                onChange={this.handleInputChange}
              >
                <option key={0} value="" hidden></option>
                {this.props.logos.map((logo, index) => (
                  <option key={index}>{logo}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="naic">NAIC#</Label>
              <Input
                type="text"
                name="naic"
                value={this.props.inputValue.naic || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="svcCo">SVC CO</Label>
              <Input
                type="text"
                name="svcCo"
                value={this.props.inputValue.svcCo || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <FormGroup>
              <Label for="payerName">Payor</Label>
              <Input
                type="text"
                name="payerName"
                value={this.props.inputValue.payerName || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="payerAddress1">Address</Label>
              <Input
                type="text"
                name="payerAddress1"
                value={this.props.inputValue.payerAddress1 || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="payerAddress2"
                value={this.props.inputValue.payerAddress2 || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormGroup row>
                <Col lg="12" xl="6">
                  <FormGroup>
                    <Input
                      type="text"
                      name="payerCity"
                      disabled
                      value={this.props.inputValue.payerCity || ''}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12" xl="6">
                  <Row>
                    <Col xl="6">
                      <FormGroup>
                        <Input
                          type="text"
                          name="payerState"
                          disabled
                          value={this.props.inputValue.payerState || ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Input
                          type="text"
                          name="payerZip"
                          onBlur={this.props.onZipCodeBlur}
                          value={this.props.inputValue.payerZip || ''}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="payerTin">TIN</Label>
              <div className="right-one-third">
                <InputGroup>
                  <Input
                    type="text"
                    name="payerTin"
                    value={this.props.inputValue.payerTin || ''}
                    onChange={this.handleInputChange}
                  />
                  <Input
                    type="text"
                    name="payerTinExt"
                    value={this.props.inputValue.payerTinExt || ''}
                    onChange={this.handleInputChange}
                  />
                </InputGroup>
              </div>
            </FormGroup>
            <FormGroup>
              <CustomInput
                id="useBillApproval"
                name="useBillApproval"
                type="checkbox"
                label="Use Bill Approval"
                inline
              />
            </FormGroup>
            <FormGroup>
              <InputMask
                mask="999-999-9999"
                alwaysShowMask={true}
                name="phone"
                value={this.props.inputValue.phone || ''}
                onChange={this.handleInputChange}
              >
                <Input />
              </InputMask>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="eorText">EOR Text</Label>
              <Input
                type="textarea"
                name="eorText"
                value={this.props.inputValue.eorText || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input
                type="textarea"
                name="notes"
                value={this.props.inputValue.notes || ''}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

SiteMaintenanceFormClient.propTypes = {
  inputValue: PropTypes.object.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onZipCodeBlur: PropTypes.func.isRequired,
  logos: PropTypes.array.isRequired,
};

export default SiteMaintenanceFormClient;
