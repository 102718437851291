import { countDaysDiff } from 'shared/utils/shareUtils';
import { thousandsSeparatorFormat } from 'shared/utils/numberUtils';

import { groupByAdjuster, groupByBill, groupByClaim } from './constantUtils';

export const getDaysFromToday = date => {
  const firstDate = new Date(date);
  const secondDate = new Date(Date.now());
  return countDaysDiff(firstDate, secondDate);
};

export const formatCurrency = number =>
  '$' + thousandsSeparatorFormat(number.toFixed(2), ',');

export const getNounForm = (number, singular, plural) =>
  number < 2 ? `${number} ${singular}` : `${number} ${plural}`;

export const selectedByGroup = (item, selectedBillItem, groupBySelection) => {
  switch (groupBySelection) {
    case groupByClaim:
      return item.claimNumber === selectedBillItem.claimNumber;
    case groupByAdjuster:
      const adjusterLast1 = item.adjusterLast || '';
      const adjusterFirst1 = item.adjusterFirst || '';
      const adjusterLast2 = selectedBillItem.adjusterLast || '';
      const adjusterFirst2 = selectedBillItem.adjusterFirst || '';
      if (
        (adjusterLast1 === '' &&
        adjusterFirst1 === '' )||
        (adjusterLast2 === '' &&
        adjusterFirst2 === '')
      ) {
        return false;
      }
      const firstAdjusterName = `${adjusterLast1}, ${adjusterFirst1}`;
      const secondAdjusterName = `${adjusterLast2}, ${adjusterFirst2}`;
      return firstAdjusterName === secondAdjusterName;
    case groupByBill:
    default:
      return false;
  }
};

export const getProfileForPhp = profile => {
  if (profile === "Forte") {
    profile = "Attenta";
    } else if (profile === "WellComp") {
    profile = "Attenta";
    } else if (profile === "Reny") {
    profile = "slp_prod_loc_reny";
    } else if (profile === "CoV") {
    profile = "slp_cov";
    } else if (profile === "Fusion") {
    profile = "slp_fusion";
    } else if (profile === "Rancho") {
    profile = "slp_prod_loc_rancho";
    }
    return profile;
};