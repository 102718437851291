import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  fast360Selectors,
  fast360Actions,
  fast360Operations
} from '../store/index';
import { isEmpty } from 'lodash';
import { authSelectors } from 'modules/auth/store';
import { Button, Row, Col } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import MODDetailModal from './MODDetailModal';
import PopupModal from 'shared/components/PopupModal';

let details;
let dosDeatils;
const Fast360MODDetails = ({
  disableSave,
  audInfo,
  patientInfo,
  serviceIdFromRef,
  detailsForOrder,
  updateAudInfoTable,
  userId,
  getServiceIdFromReference,
  isFromLanding,
  deleteHCPCService,
  updateAudHcpcList,
  updateCostSavingList,
  loadAudiologyHcpcsList,
  loadCostSavings,
  logBreadCrumbs,
  isExternalReferral
}) => {
  const [openHCPCMODDetailModal, setOpenHCPCMODDetailModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [rowInfo, setRowInfo] = useState(undefined);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (detailsForOrder?.detailList && detailsForOrder?.serviceId === patientInfo?.serviceId &&
      detailsForOrder?.copiedService === undefined) {
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updateAudHcpcList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    } else if (detailsForOrder?.detailList &&
      detailsForOrder?.serviceId === patientInfo?.serviceId && detailsForOrder?.copiedService === true) {
      const updatedDetailsForOrder = audInfo[patientInfo.module + patientInfo.serviceId]
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updatedDetailsForOrder || detailsForOrder?.detailList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    }
  }, [updateCostSavingList, detailsForOrder])

  useEffect(() => {
    if (!isEmpty(detailsForOrder)) {
      const params = {
        functionName: 'getServiceIdFromReference',
        userId: userId,
        serviceId: detailsForOrder.serviceId,
        serviceType: detailsForOrder.module
      }
      getServiceIdFromReference(params);
    }
    if (!isEmpty(detailsForOrder) && (detailsForOrder?.newServiceId === undefined) && (detailsForOrder?.copiedService === undefined)) {
      const hcpcParams = {
        zebraUserId: userId,
        audiology_id: detailsForOrder?.serviceId,
        tableAbbrv: detailsForOrder?.module,
        transport_type: 0
      }
      loadAudiologyHcpcsList(hcpcParams);
      const costParams = {
        zebraUserId: userId,
        audiology_id: detailsForOrder?.serviceId,
        tableAbbrv: detailsForOrder?.module
      }
      setTimeout(() => {
        if(!isExternalReferral) {
          loadCostSavings(costParams);
        }
      }, 2000);
    }
  }, [detailsForOrder])

  const openHCPCMODService = (info = undefined, onSave) => {
    setSelectedIndex('');
    setOpenHCPCMODDetailModal(!openHCPCMODDetailModal);
    setRowInfo(info);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": onSave ? 'SAVEDETAILLINE' : 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    (!openHCPCMODDetailModal || onSave) && logBreadCrumbs(breadCrumb);
  };

  const deleteHCPCMODServiceRow = () => {
    if (rowInfo === undefined || Object.keys(rowInfo).length <= 0) {
      alert('Please select atleast one row to remove');
      return false;
    }

    let data = {
      id: rowInfo.id,
      functionName: 'delMODLeg',
      userId: userId,
      referralId: detailsForOrder.referralId,
      mainMod: detailsForOrder.module
    }
    if (detailsForOrder.serviceId > 50) {
      deleteHCPCService(data);
    } else {
      audInfo[patientInfo.module + patientInfo.serviceId].splice(selectedIndex, 1);
    }
    setRowInfo();
    setSelectedIndex('');
  };

  const onRowClick = (item, index) => {
    setSelectedIndex(index);
    setRowInfo({ ...item, index });
  }

  const onRowDoubleClick = (item, index) => {
    setSelectedIndex(index);
    openHCPCMODService({ ...item, index });
  }

  const daysBetween = (value, startDate, endDate) => {
    const firstDate = moment(startDate);
    const secondDate = moment(endDate);
    if (value === 'Day') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      return `${diffDays} Days`;
    } else if (value === 'Week') {
      let diffWeeks;
      diffWeeks = Math.round(
        Math.abs((firstDate - secondDate) / (7 * 24 * 60 * 60 * 1000))
      );
      return `${diffWeeks} Weeks`;
    } else if (value === 'Month') {
      let numberOfMonths;
      numberOfMonths = Math.floor(
        (secondDate - firstDate) / (1000 * 60 * 60 * 24 * 30)
      );
      return `${numberOfMonths} Months`;
    }
    return '';
  }

  const getBilledCharges = (item) => {
    if (item?.BilledCharges) {
      item.billCharges = Number(item?.BilledCharges).toFixed(2) || 0.00;
      return `$${Number(item?.BilledCharges).toFixed(2)}`;
    } else {
      //const chrge =  details?.costSavings.find(i => i.DetailId === item.id && Number(i.TotalAllowance).toFixed(2) === Number(Number(item.custCost) * Number(item.quantity)).toFixed(2));
      const chrgeList = details?.filter(i => i?.DetailId === item.id);
      const chrge = chrgeList && chrgeList[chrgeList?.length - 1];
      item.PercentAdjustment = chrge?.PercentAdjustment;
      item.rentalExt = item?.rentalExt;
      item.DetailId = chrge?.DetailId || item?.id;
      item.fieldId = chrge?.fields?.id;
      item.quantity = item?.quantity;
      item.billCharges = chrge?.BilledCharges || 0.00;
      item.BilledCharges = chrge?.BilledCharges || 0.00;
      item.serviceIdFromRef = detailsForOrder?.serviceTaskId;
      item.duration = daysBetween(item?.rental?.rentalUnit, item?.rental?.startExtDate, item?.rental?.endExtDate);
      return `$${Number(chrge?.BilledCharges).toFixed(2) || 0.00}`;
    }
  }

  const getTotalAllowance = item => {
    item.totAllow = getCustomerTotal(item).toFixed(2);
    return '$' + getCustomerTotal(item).toFixed(2);
    // if (item.TotalAllowance) {
    //   return `$${item?.TotalAllowance || 0.00}`;
    // } else {
    //   const chrgeList = details?.costSavings?.filter(i => i?.DetailId === item.id);
    //    const chrge = chrgeList && chrgeList[chrgeList?.length-1];
    //   return `$${chrge?.TotalAllowance || 0.00}`;
    // }
  };

  const getVendorTotal = item => {
    if (item.quantity && item.quantity > 0) {
      return Number(item.vendorCost) * Number(item.quantity);
    } else {
      return Number(item.vendorCost) * Number(item.quantity);
    }

  }

  const getCustomerTotal = item => {
    if (item.quantity && item.quantity > 0) {
      return Number(item.custCost) * Number(item.quantity);
    } else {
      return Number(item.custCost) * Number(item.quantity);
    }

  }

  const getMargin = item => {
    let val = Number(((getCustomerTotal(item) - getVendorTotal(item)) / getCustomerTotal(item)) * 100).toFixed(2);
    return `${isNaN(val) ? 0 : val}%`;
  }

  // const getMargin = (item) => {
  //   if (item.TotalAllowance) {
  //     let val;
  //     val = Number(((Number(item?.TotalAllowance) - Number(Number(item.vendorCost) * Number(item.quantity))) / Number(item?.TotalAllowance)) * 100).toFixed(2);
  //     return `${isNaN(val) ? 0 : val}%`;
  //   } else {
  //     let val;
  //     //const chrge =  details?.costSavings.find(i => i.DetailId === item.id && Number(i.TotalAllowance).toFixed(2) === Number(Number(item.custCost) * Number(item.quantity)).toFixed(2));
  //     const chrgeList = details?.filter(i => i?.DetailId === item.id);
  //     const chrge = chrgeList && chrgeList[chrgeList?.length-1];
  //     val = Number(((Number(chrge?.TotalAllowance) - Number(Number(item.vendorCost) * Number(item.quantity))) / Number(chrge?.TotalAllowance)) * 100).toFixed(2);
  //     return `${isNaN(val) ? 0 : val}%`;
  //   }
  // };

  const getDollarAmount = (item, value) => {
    let val;
    if (value === "feeSchedule") {
      return (val = `$${item.feeSchedule || 0.00}`);
    } else if (value === "ucSchedule") {
      return (val = `$${item.ucSchedule || 0.00}`);
    } else if (value === 'dos') {
      return (val = dosDeatils?.dos || patientInfo?.audZip || item.dos);
    } else if (value === 'serviceCode') {
      return (val = item.serviceCode + '-' + item.serviceDescription);
    } else if (value === "medicareAllowable") {
      return (val = `$${item.medicareAllowable || 0.00}`);
    } else if (value === "owcpRate") {
      return (val = `$${item.owcpRate || 0.00}`);
    } else if (value === 'BilledCharges') {
      return getBilledCharges(item);
    } else if (value === "startExtDate") {
      return (val = `${dateRowInfoFormat(item.rental?.startExtDate)}`);
    } else if (value === 'endExtDate') {
      return (val = `${dateRowInfoFormat(item.rental?.endExtDate)}`);
    } else if (value === 'custBuyOut') {
      return (val = `$${item.rental?.custBuyOut || 0.00}`);
    } else if (value === 'vendBuyOut') {
      return (val = `$${item.rental?.vendBuyOut || 0.00}`);
    } else if (value === "vendTotal") {
      return `$${getVendorTotal(item).toFixed(2)}`
      // val = Number(Number(item.vendorCost) * Number(item.quantity)).toFixed(2);
      //return `$${isNaN(val) ? 0 : val}`
    } else if (value === 'TotalAllowance') {
      return getTotalAllowance(item);
    } else if (value === 'margin') {
      return getMargin(item)
    }
  };

  const dateRowInfoFormat = data => {
    if (data) {
      let dos = data && data.split('-');
      return dos[1] + '/' + dos[2] + '/' + dos[0];
    }
    return '';
  };

  // const getRentalInfo = item => {
  //   if (item.primaryMod === 'Rental') {
  //     let val;
  //     val = 'Rental' + (item.rentalExt
  //       ? '-extenstion'+ ' ' +dateRowInfoFormat(item.startExtDate)+ ' - ' +dateRowInfoFormat(item.endExtDate)
  //       : ''
  //   );
  //     return val;
  //   } else {
  //     return item.modifier1;
  //   }
  // };

  const getRentalExt = (item) => {
    return item.rental?.rentalExt === false ? 'no' : 'yes';
  }

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const getDeleteBody = () => {
    return (
      <div className="text-center">
        Are you sure you wish to delete Service Detail?
      </div>
    );
  };

  const getDeleteFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteHCPCMODServiceRow();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <div>
      <Row>
        <Col lg="2">
          <Button type="button" disabled={isEmpty(detailsForOrder) || disableSave} onClick={() => openHCPCMODService()}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>

          <Button color="danger" type="button"  disabled={disableSave} onClick={confirmationDeleteToggle}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </Col>
        <Col sm='8'>
          <span style={{ float: 'right', marginRight: 219 }}>*Below grey background fields are rental information</span>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <AppTable
            resizable
            columns={[
              {
                header: 'Date Of Service',
                field: 'dos',
                render: item => getDollarAmount(item, 'dos')
              },
              {
                header: 'Qty',
                field: 'quantity'
              },
              {
                header: 'Modifier',
                field: 'primaryMod'
              },
              {
                header: 'Item Name',
                field: 'itemName'
              },
              {
                header: 'Service Description',
                field: 'description'
              },
              {
                header: 'Billed Charges',
                field: 'BilledCharges',
                render: item => getDollarAmount(item, 'BilledCharges'),
              },
              {
                header: 'Rental Ext',
                field: 'rentalExt',
                customColor: 'grey',
                render: item => getRentalExt(item)
              },
              {
                header: 'Rental Start',
                field: 'startExtDate',
                customColor: 'grey',
                render: item => getDollarAmount(item, "startExtDate"),
              },
              {
                header: 'Rental End',
                field: 'endExtDate',
                customColor: 'grey',
                render: item => getDollarAmount(item, "endExtDate"),
              },
              {
                header: 'Vend. Buyout',
                field: 'vendBuyOut',
                customColor: 'grey',
                render: item => getDollarAmount(item, "vendBuyOut"),
              },
              {
                header: 'Cust. Buyout',
                field: 'custBuyOut',
                customColor: 'grey',
                render: item => getDollarAmount(item, 'custBuyOut'),
              },
              {
                header: 'Vend. Total',
                field: 'vendTotal',
                render: item => getDollarAmount(item, 'vendTotal'),
              },
              {
                header: 'Cust. Total',
                field: 'TotalAllowance',
                render: item => getDollarAmount(item, 'TotalAllowance'),
              },
              {
                header: 'Margin',
                render: item => getDollarAmount(item, 'margin'),
              },
            ]}
            data={audInfo && patientInfo && audInfo[patientInfo.module + patientInfo.serviceId] ? audInfo[patientInfo.module + patientInfo.serviceId] : []}
            rowClickHandler={onRowClick}
            rowDoubleClickHandler={onRowDoubleClick}
            autoPagination={true}
          //sortAble={true}
          />
        </Col >
      </Row>
      <MODDetailModal
        isOpen={openHCPCMODDetailModal}
        onClose={openHCPCMODService}
        rowInfo={rowInfo}
        serviceIdFromRef={detailsForOrder?.serviceTaskId}
        serviceId={detailsForOrder?.serviceId}
        referralId={detailsForOrder?.referralId}
        module={detailsForOrder?.module}
        isFromLanding={isFromLanding}
        newServiceId={detailsForOrder?.newServiceId}
        newServiceId={detailsForOrder?.newServiceId}
        copiedService={detailsForOrder?.copiedService}
      />
      <PopupModal
        content={getDeleteBody()}
        title="Confirmation"
        externalToggle={confirmationDeleteToggle}
        isOpen={isConfirmDeleteModalOpen}
        footerContent={getDeleteFooter()}
        size="md"
      />
    </div>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  return {
    audInfo: fast360Info.audInfo,
    userId: user.id,
    patientInfo: fast360Info.patientZip,
    serviceIdFromRef: fast360Info.serviceIdFromRef,
    updateAudHcpcList: fast360Info.updateAudHcpcList,
    updateCostSavingList: fast360Info.updateCostSavingList
  }
}

const mapDispatchToProps = dispatch => ({
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  getServiceIdFromReference: data => dispatch(fast360Operations.getServiceIdFromReference(data)),
  loadFromWorkList: data => dispatch(fast360Operations.loadFromWorkList(data)),
  loadAudiologyHcpcsList: data => dispatch(fast360Operations.loadAudiologyHcpcsList(data)),
  loadCostSavings: data => dispatch(fast360Operations.loadCostSavings(data)),
  updateAudInfoTable: data => dispatch(fast360Actions.updateAudInfoTable(data)),
  deleteHCPCService: data => dispatch(fast360Operations.deleteHCPCService(data)),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360MODDetails);
