import React, { useContext, useEffect, useState } from 'react';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';
import { UpdateTypes, selectAll} from './utils/helper';
import { Row, Col, FormGroup, Label, TabPane, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

function updateAdvSearchForm(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_AdvSearch_Form',
        payload: { [key]: value },
    });
}

function updateCheckBox(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_CheckBox',
        payload: { [key]: value },
    });
}


const Interpretation = ({ language, appointmentType, translationCategory, useStoredData }) => {

    const { state, dispatch } = useContext(AdvSearchContext);
    const { state1, dispatch1 } = useContext(CheckBoxContext);
    const { interpretationTypes } = state;

    useEffect(() => {
        if(useStoredData){
            selectAll(true, translationCategory, dispatch, 'interpretationTypes')
        }
    },[translationCategory])
    
    const [ apptTypeInt, setApptTypeInt] = useState("Choose");

    useEffect(()=>{
      if(appointmentType){
      const intAppt = appointmentType.filter((type)=>type.id === state.interpretationApptType);
      intAppt.length> 0 && setApptTypeInt(intAppt[0].value)
      }
  
  
    },[state.interpretationApptType])

    const [ langInt, setLangInt] = useState("Choose");

    useEffect(()=>{
      if(language){
      const langUI = language.filter((type)=>type.lang_id === state.interpretationLanguageId);
      langUI.length> 0 && setLangInt(langUI[0].value)
      }
  
  
    },[state.interpretationLanguageId])

    return (
        <TabPane tabId="4" style={{ border: "1px solid #dee2e6", padding: "15px", overflow: "auto" }}>
            <FormGroup>
                <div style={{ float: "left", width: "60%" }}>
                    <Row xs={2} md={4}>
                        <Col >
                            <Label>Language: </Label>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    {langInt}
                                </DropdownToggle>
                                <DropdownMenu style={{ overflowY: "scroll", height: "150px" }} >
                                    {language && language.map((language, idx) => {
                                        return (
                                            <DropdownItem key={idx} onClick={(e) => updateAdvSearchForm(language.lang_id, 'interpretationLanguageId', dispatch)}>
                                                {language.value}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Total Cost From:</Label>
                                <Input
                                    value={state.interpretationTotalCostFrom}
                                    type=""
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationTotalCostFrom', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Billed Charges From:</Label>
                                <Input
                                    type=""
                                    value={state.interpretationTotalBilledChargesFrom}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationTotalBilledChargesFrom', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Order Margin from:</Label>
                                <Input
                                    type=""
                                    value={state.interpretationOrderMarginFrom}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationOrderMarginFrom', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs={2} md={4}>
                        <Col >
                            <Label>Appt Type: </Label>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    {apptTypeInt}
                                </DropdownToggle>
                                <DropdownMenu style={{ overflowY: "scroll", height: "150px" }}  >
                                    {appointmentType && appointmentType.map((appointmentType, idx) => {
                                        return (
                                            <DropdownItem key={idx} onClick={(e) => updateAdvSearchForm(appointmentType.id, 'interpretationApptType', dispatch)}>
                                                {appointmentType.value}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Total Cost To:</Label>
                                <Input
                                    type=""
                                    value={state.interpretationTotalCostTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationTotalCostTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Billed Charges To:</Label>
                                <Input
                                    type=""
                                    value={state.interpretationTotalBilledChargesTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationTotalBilledChargesTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>Order Margin To:</Label>
                                <Input
                                    type=""
                                    value={state.interpretationOrderMarginTo}
                                    onChange={(e) => updateAdvSearchForm(e.target.value, 'interpretationOrderMarginTo', dispatch)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Select the fields to display on the report</Col>
                    </Row>
                    <Row xs={2} md={6}>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationAddress}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationAddress', dispatch1)}
                                    />
                                                Interpretation Address
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationTime}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationTime', dispatch1)}
                                    />
                                                Duration Time
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationStartTime}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationStartTime', dispatch1)}
                                    />
                                                Start Time
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationLanguage}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationLanguage', dispatch1)}
                                    />
                                                Language
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationMileage}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationMileage', dispatch1)}
                                    />
                                                Mileage
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationTimeStaffed}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationTimeStaffed', dispatch1)}
                                    />
                                                Time Staffed
                                            </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs={2} md={6}>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationStaffedBy}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationStaffedBy', dispatch1)}
                                    />
                                                Staffed By
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationArrivalTime}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationArrivalTime', dispatch1)}
                                    />
                                                Arrival Time
                                            </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayInterpretationAppointmentType}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayInterpretationAppointmentType', dispatch1)}
                                    />
                                                Appointment Type
                                            </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Types:</Label>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                         checked={translationCategory &&  (translationCategory.length === interpretationTypes.length)}
                                        type="checkbox"
                                        onClick={(e) => selectAll(e.target.checked, translationCategory, dispatch, 'interpretationTypes')}
                                    /> Select All:
                                </Label>
                            </FormGroup>
                            <div style={{ border: "1px solid black", height: "150px", width: "290px", padding: "10px", overflowY: "scroll" }}>
                                {translationCategory &&
                                    translationCategory.map(element => {
                                        return (
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="checkbox"
                                                        checked={interpretationTypes.includes(element.id)}
                                                        onClick={(e) => UpdateTypes(e.target.checked, element.id, interpretationTypes, dispatch, 'interpretationTypes')}
                                                    />
                                                    {element.value}
                                                </Label>
                                            </FormGroup>
                                        );
                                    })}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </FormGroup>
        </TabPane>
    )
}

export default Interpretation