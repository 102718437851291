import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Fast360MatrixAIS from './Fast360MatrixAIS';
import Fast360Matrix from './Fast360Matrix';
import Fast360ServiceDetails from './Fast360ServiceDetails';
import Fast360HHCDetails from './Fast360HHCDetails';
import Fast360DTLDetails from './Fast360DTLDetails';
import Fast360MODDetails from './Fast360MODDetails';
import Fast360TransportDetails from './Fast360TransportDetails';
import Fast360SPTDetails from './Fast360SPTDetails';


const NewReferralHeaders = props => {
  const [serviceHeader, setServiceHeader] = useState(null);
  const [matrixHeader, setMatrixHeader] = useState(null);

  const {
    disableSave,
    setDisableSave,
    refTransportation,
    loadLegStatus,
    userId,
    timeZone,
    zipList,
    getLegStatus,
    getZipList,
    alert,
    updatedClaimInfo,
    loadlookUpLocation,
    workListData,
    data,
    serviceType,
    details,
    isFromLanding,
    isNew,
    isExternalReferral
  } = props;

  useEffect(() => {
    if(Object.keys(details).length == 0 && Object.keys(workListData).length == 0) return;

    if (refTransportation === 'TRN') {
      setServiceHeader(
        <Fast360TransportDetails
          disableSave={disableSave}
          loadLegStatus={loadLegStatus}
          getLegStatus={getLegStatus}
          userId={userId}
          timeZone={timeZone}
          zipList={zipList}
          getZipList={getZipList}
          alert={alert}
          updatedClaimInfo={updatedClaimInfo}
          workListData={workListData}
          detailsForOrder={details}
          isFromLanding={isFromLanding}
          isNew={isNew}
        />
      );
      setMatrixHeader(<Fast360MatrixAIS
        isFromLanding={isFromLanding}
        rfees={details?.rfees}
        rfeeIds={details?.rfeeIds}
        serviceFees={details?.serviceFees}
        daysStay={details?.daysDiff}
        selectedService={details}
        fees={details?.fees}
        feeIds={details?.feeIds}
        serviceCosts={details?.serviceCosts} />);
    } else if (refTransportation === 'ACC' || refTransportation === 'INT') {
      setServiceHeader(null);
      setMatrixHeader(<Fast360MatrixAIS
        isFromLanding={isFromLanding}
        rfees={details?.rfees}
        rfeeIds={details?.rfeeIds}
        serviceFees={details?.serviceFees}
        daysStay={details?.daysDiff}
        selectedService={details}
        fees={details?.fees}
        feeIds={details?.feeIds}
        serviceCosts={details?.serviceCosts} />);
    } else if (refTransportation === 'MOD') {
      setServiceHeader(<Fast360MODDetails
       disableSave={disableSave}
        workListData={workListData}
        data={data}
        serviceType={serviceType}
        detailsForOrder={details}
        isFromLanding={isFromLanding}
        isExternalReferral={isExternalReferral}
      />);
      setMatrixHeader(<Fast360Matrix
        detailsForOrder={details}
        isExternalReferral={isExternalReferral} />);
    } else if (refTransportation === 'SPT') {
      setServiceHeader(
        <Fast360SPTDetails
         disableSave={disableSave}
          loadLegStatus={loadLegStatus}
          getLegStatus={getLegStatus}
          userId={userId}
          timeZone={timeZone}
          loadlookUpLocation={loadlookUpLocation}
          alert={alert}
          detailsForOrder={details}
          isFromLanding={isFromLanding}
          isNew={isNew}
        />
      );
      setMatrixHeader(<Fast360MatrixAIS
        isFromLanding={isFromLanding}
        rfees={details?.rfees}
        rfeeIds={details?.rfeeIds}
        serviceFees={details?.serviceFees}
        daysStay={details?.daysDiff}
        selectedService={details}
        fees={details?.fees}
        feeIds={details?.feeIds}
        serviceCosts={details?.serviceCosts} />);
    } else if (refTransportation === 'HHC') {
      setServiceHeader(<Fast360HHCDetails
       disableSave={disableSave}
        workListData={workListData}
        data={data}
        serviceType={serviceType}
        detailsForOrder={details}
        isFromLanding={isFromLanding}
        isExternalReferral={isExternalReferral}
      />);
      setMatrixHeader(<Fast360Matrix
        detailsForOrder={details}
        isExternalReferral={isExternalReferral} />);
    } else if (refTransportation === 'DTL') {
      setServiceHeader(<Fast360DTLDetails
       disableSave={disableSave}
        workListData={workListData}
        data={data}
        serviceType={serviceType}
        detailsForOrder={details}
        isFromLanding={isFromLanding}
        isExternalReferral={isExternalReferral}
      />);
      setMatrixHeader(<Fast360Matrix
        detailsForOrder={details} 
        isExternalReferral={isExternalReferral} />);
    // } else if (refTransportation === 'MSE') {
    //   setServiceHeader(<Fast360MSEServiceDetails
    //     disableSave={disableSave}
    //     workListData={workListData}
    //     data={data}
    //     serviceType={serviceType}
    //     detailsForOrder={details}
    //     isFromLanding={isFromLanding}
    //     isExternalReferral={isExternalReferral} />);
    //   setMatrixHeader(<Fast360Matrix
    //     detailsForOrder={details}
    //     isExternalReferral={isExternalReferral} />);
    } else {
      setServiceHeader(<Fast360ServiceDetails
        disableSave={disableSave}
        workListData={workListData}
        data={data}
        serviceType={serviceType}
        detailsForOrder={details}
        isFromLanding={isFromLanding}
        isExternalReferral={isExternalReferral} />);
      setMatrixHeader(<Fast360Matrix
        detailsForOrder={details}
        isExternalReferral={isExternalReferral} />);
    }
  }, [refTransportation, loadLegStatus, zipList, details, workListData, disableSave]);

  return (
    <div>
      {serviceHeader}
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          textAlign: 'center',
          backgroundColor: '#505d78',
          padding: '5px',
          marginBottom: '5px',
        }}
      >
        <h6 style={{ color: '#ffffff' }}> Revenue Matrix</h6>
      </div>
      {refTransportation === 'ACC' ||
        refTransportation === 'INT' ||
        refTransportation === 'SPT' ||
        refTransportation === 'TRN' ? (
        matrixHeader
      ) : (
        <>
          <Row>
            <Col lg="3"></Col>
            <Col lg="6">{matrixHeader}</Col>
            <Col lg="3"></Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default NewReferralHeaders;
