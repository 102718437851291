import React, { useEffect, useState } from 'react';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppTable from 'shared/components/table/AppTable';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  CustomInput
} from 'reactstrap';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store/index';
import { authSelectors } from '../../auth/store';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { commonOperations } from 'common/store';
import Fast360AuthTable from 'shared/components/table/Fast360AuthTable';

let checkedItems = [];

const initialServicesChecked = {
  acc: false,
  aud: false,
  dtl: false,
  dgn: false,
  hhc: false,
  int: false,
  mod: false,
  mse: false,
  trn: false,
  spt: false,
};

const initialData = {
  startDate: '',
  endDate: '',
  authNum: '',
  visitsApproved: '',
  authNotes: ''
}

const AuthHistoryModal = ({ isOpen, onClose, getClaimAuth, historyData, worklistData, currentUserId, saveAuthHistory, alert, serviceID, serviceType, authData, updateSelectedAuthRecord }) => {
  const [fields, setFields] = useState(initialData);
  const [services, setServices] = useState(initialServicesChecked);
  const [disabledServices, setDisabledServices] = useState([]);


  const toggleModal = () => {
    setFields(initialData);
    setServices(initialServicesChecked);
    updateSelectedAuthRecord({ index: -1, state: false });
    onClose();
  };


  const getUpdatedHistory = () => {
    const payload = {
      "functionName": "loadClaimsAuth",
      "zebraUserId": currentUserId,
      "claimsId": worklistData.claimId
    }
    getClaimAuth(payload);
  }

  useEffect(() => {
    if(Object.keys(authData).length > 0 && historyData.length > 0) {
      const index = historyData.findIndex(item => item.id === authData.id);
      if(index >= 0) {
        updateSelectedAuthRecord({ index: index, state: true });
      }
    }
  }, [authData, historyData])

  useEffect(() => {
    if(historyData.length > 0 && fields.startDate != '') {
      const updatedDisabledServices = [];
      historyData.forEach(historyItem => {
        const startDate = moment(fields.startDate).isAfter(moment(historyItem.startDate)) || moment(fields.startDate).isSame(moment(historyItem.startDate));
        const endDate = moment(fields.startDate).isBefore(moment(historyItem.endDate)) || moment(fields.startDate).isSame(moment(historyItem.endDate));
        if(startDate && endDate) {
          historyItem.authServiceTypes.forEach(srv => {
            if(!updatedDisabledServices.includes(srv.serviceType)) {
              updatedDisabledServices.push(srv.serviceType);
            }
          })
        }
      });
      setDisabledServices(updatedDisabledServices);
    }
  }, [fields.startDate, historyData])

  useEffect(() => {
    if (isOpen) {
      getUpdatedHistory();
    }
  }, [isOpen])

  const onDateChangeHandler = object => {
    const date = moment(object.value).format('MM/DD/YYYY')
    setFields({ ...fields, [object.key]: date });
  };

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    const target = event.target.id;
    const value = event.target.value;
    if (target == 'visitsApproved') {
      if (re.test(value) || value == '') {
        setFields({ ...fields, [target]: value });
      }
    }
    else
      setFields({ ...fields, [target]: value });
  }

  const handleServicesChange = (event) => {
    const target = event.target.id;
    const value = event.target.checked;
    setServices({ ...initialServicesChecked, [target]: value });
  }

  const handlePlus = () => {

    const { authNotes, authNum, endDate, startDate, visitsApproved } = fields;
    let sType = '';
    Object.keys(services).map(i => { if (services[i] == true) sType = i; });
    const dateNow = new Date().toISOString();
    const formattedDate = `${dateNow.split('T')[0]} ${dateNow.split('T')[1].split('Z')[0]}`;
    const startDt = new Date(startDate).getTime();
    const endDt = new Date(endDate).getTime();
    if (startDate == "") {
      alert('Start date required to add a new row');
    }
    else if (endDate == "") {
      alert('End date required to add a new row');
    }
    else if (sType == "") {
      alert('Please select a service');
    }
    else if (startDt > endDt) {
      alert('End Date should be greater than start date')
    }
    else if (startDt == endDt) {
      alert('End Date should be greater than start date')
    }
    else {
      const payload = {
        "functionName": "saveAuth",
        "zebraUserId": currentUserId,
        "claimId": worklistData.claimId,
        "frequency": visitsApproved,
        "authNumber": authNum,
        "notes": authNotes,
        "startDate": `${moment(startDate).format('YYYY-MM-DD')} 00:00:00.0`,
        "endDate": `${moment(endDate).format('YYYY-MM-DD')} 00:00:00.0`,
        "authServiceTypes": [
          {
            "authId": 0,
            "serviceType": sType.toUpperCase(),
            "id": 0
          }
        ],
        "closeReasonId": 0,
        "closeDate": formattedDate,
        "id": 0
      }
      saveAuthHistory(payload);
      setFields(initialData);
      setServices(initialServicesChecked);
      setTimeout(() => {
        getUpdatedHistory();
      }, 2000)
    }
  }

  const renderStatus = (item) => {
    let status = '';
    const today = new Date().getTime();
    const endDt = new Date(item.endDate).getTime();
    if (item.closeReasonId) {
      status = 'Closed';
    }
    else if ((endDt - today) >= 0) {
      status = 'Open';
    }
    else
      status = 'Expired';

    return status;
  }

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggleModal}>Auth History</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label style={{ marginLeft: "10px", marginTop: "5px" }} for="startDate">
              Auth Date Start:
            </Label>
            <Col lg="2">
              <AppDateInput
                id="authDateStart"
                onChange={value => onDateChangeHandler({ key: 'startDate', value })}
                value={fields.startDate || ''}
              />
            </Col>
            <Label style={{ marginLeft: "10px", marginTop: "5px" }} for="endDate">
              Auth Date End:
            </Label>
            <Col lg="2">
              <AppDateInput
                id="authDateEnd"
                onChange={value => onDateChangeHandler({ key: 'endDate', value })}
                value={fields.endDate || ''}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ paddingLeft: 20 }}>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="acc"
                  checked={services.acc}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('ACC')}
                />
                {' ACC'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="aud"
                  checked={services.aud}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('AUD')}
                />
                {' AUD'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  checked={services.dtl}
                  id="dtl"
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('DTL')}
                />
                {' DTL'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="dgn"
                  checked={services.dgn}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('DGN')}
                />
                {' DGN'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="hhc"
                  checked={services.hhc}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('HHC')}
                />
                {' HHC'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="int"
                  checked={services.int}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('INT')}
                />
                {' INT'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="mod"
                  checked={services.mod}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('MOD')}
                />
                {' MOD'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="mse"
                  checked={services.mse}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('MSE')}
                />
                {' MSE'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="trn"
                  checked={services.trn}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('TRN')}
                />
                {' TRN'}
              </Label>
            </Col>
            <Col sm="1">
              <Label check>
                <Input
                  type="checkbox"
                  id="spt"
                  checked={services.spt}
                  onChange={(e) => handleServicesChange(e)}
                  disabled={disabledServices.includes('SPT')}
                />
                {' SPT'}
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label style={{ marginLeft: "10px", marginTop: "5px" }} for="authNum">
              Auth #:
            </Label>
            <Col sm="1">
              <Input
                id="authNum"
                type="text"
                value={fields.authNum}
                onChange={(e) => handleChange(e)}
              />
            </Col>
            <Label style={{ marginLeft: "10px", marginTop: "5px" }} for="visitsApproved">
              Total Visits Approved:
            </Label>
            <Col sm="1">
              <Input
                id="visitsApproved"
                type="number"
                value={fields.visitsApproved}
                onChange={(e) => handleChange(e)}
                min={0}
              />
            </Col>
            <Label style={{ marginLeft: "10px", marginTop: "5px" }} for="authNotes">
              Auth Note:
            </Label>
            <Col sm="5">
              <Input
                id="authNotes"
                type="text"
                value={fields.authNotes}
                onChange={(e) => handleChange(e)}
              />
            </Col>
            <Button type="button" onClick={handlePlus}>
              +
            </Button>
          </FormGroup>
        </Form>
        <Row>
          <Col>
            <Fast360AuthTable
              columns={[
                {
                  header: 'Show on Details',
                },
                {
                  header: 'Auth #',
                  render: item => item['authNumber'],
                },
                {
                  header: 'Visits',
                  render: item => item['frequency'],

                },
                {
                  header: 'Start Date',
                  render: item => moment(item['startDate']).format('MM/DD/YYYY'),
                },
                {
                  header: 'End Date',
                  render: item => moment(item['endDate']).format('MM/DD/YYYY'),
                },
                {
                  header: 'Auth Note',
                  render: item => item['notes'],

                },
                {
                  header: 'Service Types',
                  render: item => item['authServiceTypes'][0]?.serviceType,
                },
                {
                  header: 'Status',
                  // render: item => renderStatus(item),
                },
                {
                  header: 'Close Reason',
                },
                {
                  header: 'Close',
                },
              ]}
              data={historyData}
              autoPagination
              sortAble
              resizable
              sortImmediately={false}
              getUpdatedHistory={getUpdatedHistory}
              serviceID={serviceID}
              serviceType={serviceType}
              authData={authData}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  const initialData = fast360Selectors.getFast360InitialData(state);
  const userId = authSelectors.getUserId(state);
  return {
    historyData: state.FAST360.fast360Reducer.getClaimAuth ? state.FAST360.fast360Reducer.getClaimAuth : [],
    currentUserId: userId,
    authCloseReasons: initialData.authCloseReasons,
    roleUsers: initialData?.roleUsers?.users,
    worklistData: state.FAST360.fast360Reducer.updateLoadFromWorkList ? state.FAST360.fast360Reducer.updateLoadFromWorkList : [],
  };
};

const mapDispatchToProps = dispatch => ({
  getClaimAuth: data => dispatch(fast360Operations.getClaimAuth(data)),
  saveAuthHistory: data => dispatch(fast360Operations.saveAuthHistory(data)),
  updateSelectedAuthRecord: data => dispatch(fast360Actions.updateSelectedAuthRecord(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthHistoryModal);
