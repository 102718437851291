import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import { commonOperations } from 'common/store';

import PopupModal from 'shared/components/PopupModal';
import { formatYYYYMMDD } from 'shared/utils/shareUtils';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

import { qaBills, groupBySelection } from '../utils/constantUtils';

import QABillTopNav from './QABillTopNav';
import SummaryCountContainer from './SummaryCountContainer';
import TaskListTable from './TaskListTable';

class QABillContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
      isApproveQABills: false,
      dataFromTopNav: {}
    };
  }

  confirmSelectedQABills = async () => {
    this.stateConfirmModalToggle();
    this.setState({ isApproveQABills: true });
  };

  saveTaskListNote = (body) => {
  const dataFromNav =  this.state.dataFromTopNav;
  const req = {
    entryDate:
    dataFromNav.entryDate !== '' && dataFromNav.entryDate !== null
      ? formatYYYYMMDD(dataFromNav.entryDate, '-')
      : null,
  startId: dataFromNav.startId !== '' ? dataFromNav.startId : null,
  endId: dataFromNav.endId !== '' ? dataFromNav.endId : null,
  state: dataFromNav.state !== '' ? dataFromNav.state : null,
  siteId: dataFromNav.site !== '' ? dataFromNav.site : null,
  userId: this.props.currentUserId,
  profile: this.props.profile,
  isManager: this.props.manager
  }
  this.props.saveTaskListNote(body, req);
  };

  stateConfirmModalToggle = () => {
    this.setState(prevState => ({
      confirmModalOpen: !prevState.confirmModalOpen,
    }));
  };

  getConfirmationModalBodyContent() {
    return (
      <Row form>
        <Col lg={{ size: '4', offset: '4' }}>
          <span>QA Selected Bills?</span>
        </Col>
      </Row>
    );
  }

  refresh = () => {
    this.setState({ isApproveQABills: true });
    this.getQABills(this.state.dataFromTopNav);
    this.setState({ isApproveQABills: false }); 
  };

  getConfirmationModalFooterContent() {
    return (
      <>
        <Button color="secondary" onClick={this.stateConfirmModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.confirmSelectedQABills}>
          Yes
        </Button>
      </>
    );
  }

  getQABills = data => {
    if (data.startId === '' && data.endId === '') {
      this.props.alert('Please enter a starting bill number');
      return;
    }

    if (data.endId === '' && data.entryDate === '') {
      this.props.alert('Please enter an ending bill number');
    }

    this.props.getQABills({
      entryDate:
        data.entryDate !== '' && data.entryDate !== null
          ? formatYYYYMMDD(data.entryDate, '-')
          : null,
      startId: data.startId !== '' ? data.startId : null,
      endId: data.endId !== '' ? data.endId : null,
      state: data.state !== '' ? data.state : null,
      siteId: data.site !== '' ? data.site : null,
      userId: this.props.currentUserId,
      profile: this.props.profile,
      isManager: this.props.manager,
    });
  }

  findQABills = data => {
    this.setState({ dataFromTopNav: data })
    const { isApproveQABills } = this.state;

    if (isApproveQABills) {
      const { selectedBills, currentUserId, profile } = this.props;
      if (selectedBills && selectedBills.length < 1) {
        //this.getQABills(data)
        return;
      }
      const req = {
        entryDate:
          data.entryDate !== '' && data.entryDate !== null
            ? formatYYYYMMDD(data.entryDate, '-')
            : null,
        startId: data.startId !== '' ? data.startId : null,
        endId: data.endId !== '' ? data.endId : null,
        state: data.state !== '' ? data.state : null,
        siteId: data.site !== '' ? data.site : null,
        userId: this.props.currentUserId,
        profile: this.props.profile,
        isManager: this.props.manager,
      };
      const params = {
        billList: selectedBills,
        zebraUserId: currentUserId,
        profile: profile
      };
      this.props.confirmQABills(params, req);
      this.setState({ isApproveQABills: false });
    }
    else {
      this.getQABills(data);
    }
  };

  loadMasterClientDataProvider = value => {
    this.props.loadMasterClientDataProvider({
      profile: this.props.profile,
      siteName: value,
      excludeEmployers: true,
      zebraUserId: this.props.currentUserId,
    });
  };

  render() {
    return (
      <>
        <QABillTopNav
          currentUserId={this.props.currentUserId}
          states={this.props.states}
          clients={this.props.clients}
          findQABills={this.findQABills}
          isApproveQABills={this.state.isApproveQABills}
          masterClientDataProvider={this.props.masterClientDataProvider}
          loadMasterClientDataProvider={this.loadMasterClientDataProvider}
          transferBills={this.transferBills}
          updateGroupBySelection={this.props.updateGroupBySelection}
          updateSelectedBills={this.props.updateSelectedBills}
          groupBySelection={groupBySelection}
          selectedBills={this.props.selectedBills}
          bills={this.props.bills}
          groupBy={this.props.groupBy}
          settingColumns={this.props.settingColumns}
          updateGridSettingsCheckbox={this.props.updateGridSettingsCheckbox}
        />
        <TaskListTable
          data={this.props.bills}
          selectedBills={this.props.selectedBills}
          groupBy={this.props.groupBy}
          refresh={this.refresh}
          updateSelectedBills={this.props.updateSelectedBills}
          name={qaBills}
          currentUserId={this.props.currentUserId}
          isSlpUser={this.props.isSlpUser}
          profile={this.props.profile}
          settingColumns={this.props.settingColumns}
          saveTaskListNote={this.saveTaskListNote}
        />
        <Row>
          <Col lg="10">
            <SummaryCountContainer
              selectedBills={this.props.selectedBills}
              bills={this.props.bills}
              reviewType={qaBills}
            />
          </Col>
        </Row>
        <PopupModal
          content={this.getConfirmationModalBodyContent()}
          title="Confirmation"
          externalToggle={this.stateConfirmModalToggle}
          isOpen={this.state.confirmModalOpen}
          footerContent={this.getConfirmationModalFooterContent()}
          size="lg"
        />
        <Row className="float-right" style={{ marginTop: '20px' }}>
          <Button color="primary"
            style={{ marginRight: '20px' }}
            onClick={this.stateConfirmModalToggle}
            disabled={this.props.selectedBills.length < 1}>
            AGREE
       </Button>
        </Row>
      </>
    );
  }
}

QABillContainer.propTypes = {
  getQABills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  loadMasterClientDataProvider: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  updateSelectedBills: PropTypes.func.isRequired,
  bills: PropTypes.array.isRequired,
  masterClientDataProvider: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  groupBy: PropTypes.string,
  profile: PropTypes.string.isRequired,
  settingColumns: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isSlpUser: PropTypes.bool.isRequired,
  manager: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  updateGridSettingsCheckbox: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const qaBillState = billOperationsSelectors.getQABillState(state);
  const gridSettings = billOperationsSelectors.getTaskListGridSettingsState(
    state
  );
  const initialData = billOperationsSelectors.getInitialData(state);
  return {
    bills: qaBillState.qaBills,
    masterClientDataProvider: qaBillState.masterClientDataProvider,
    selectedBills: qaBillState.selectedBills,
    groupBy: qaBillState.groupBy,
    settingColumns: gridSettings.settingColumns,
    states: initialData.states || [],
    clients: initialData.slpsites || [],
  };
};

const mapDispatchToProps = dispatch => ({
  saveTaskListNote: (body, req) =>
  dispatch(billOperationsOperations.saveTaskListNote(body, false, req)),
  confirmQABills: (data, req) =>
    dispatch(billOperationsOperations.confirmQABills(data, req)),
  getQABills: data => dispatch(billOperationsOperations.getQABills(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updateGroupBySelection: groupBy =>
    dispatch(billOperationsActions.updateQABillGroupBy(groupBy)),
  updateSelectedBills: data =>
    dispatch(billOperationsActions.updateSelectedQABills(data)),
  loadMasterClientDataProvider: data =>
    dispatch(
      billOperationsOperations.loadQABillsMasterClientDataProvider(data)
    ),
  updateGridSettingsCheckbox: data =>
    dispatch(billOperationsActions.updateTaskListGridSettingsCheckbox(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QABillContainer);
