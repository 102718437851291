import React, { Component, useState } from 'react';
import { AppTableBodyPropType } from "./AppTablePropTypes";
import BillDetailDeny from "../../../modules/bill-operations/components/BillDetailDeny";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input
} from "reactstrap";
import {
    modifyBillLines
} from '../../../modules/bill-operations/utils/constantUtils';
import BillReviewAppTableBodyRow from "./BillReviewAppTableBodyRow";
import { connect } from 'react-redux';
import {
    billOperationsActions,
    billOperationsSelectors,
} from '../../../modules/bill-operations/store';
import { commonOperations } from 'common/store';

let denyReasonTextMap = new Map();
class BillReviewAppTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedRow: -1,
            modifyArray: null
        };
    }

    updateMap=(detailId, note) =>
    {
        denyReasonTextMap.set(detailId,note)
    }
    getUpdatedMap =()=>
    {
        return denyReasonTextMap;
    }
    postBillReviewData = (data, index) => {
        let { cpt, cptDescription, dos, lineDesc, lineNumber, billed, modifier, reductions, reimb, negReduct, provReimb,
            units, basicEmergency, basicNonEmergency } = this.props.data[index]
        let { extEmg, extNonEmg, selectedDec, selectedDenyReason, netSavInput, medPay, basicEmg, basicNonEmg,reasonOthertext, miscCode1, miscCode2, reasonMTGNote } = data
        
        let modifiedArray = this.props.data.map(function (item) {
            return {
                lineNumber: item.lineNumber,
                cpt: item.cpt,
                modifier: item.modifier,
                dos: item.dos,
                billed: item.billed,
                reductions: item.reductions,
                reimb: item.reimb,
                decision: "Undecided",
                denyReasons: "",
                negReduct: item.negReduct,
                provReimb: item.provReimb,
                units: item.units,
                lineDesc: item.lineDesc,
                cptDescription: item.cptDescription,
                basicNonEmergency: item.basicNonEmergency,
                basicEmergency: item.basicEmergency,
                extendedNonEmergency: item.extendedNonEmergency,
                extendedEmergency: item.extendedEmergency,
                medPayAllocation: item.medPayAllocation,
                denyReasonsOther : '',
                miscCode1 : '',
                miscCode2 : '',
                reasonMTGNote : ''
            };
        });

        let intLineNumber = index + 1;
        let stringLineNumber = intLineNumber.toString();
        if (this.props.billRevEditValFromState.length) {
            modifiedArray = this.props.billRevEditValFromState
        }
        modifiedArray[index] = {
            lineNumber: stringLineNumber,
            cpt: cpt,
            modifier: modifier,
            dos: dos,
            billed: billed,
            reductions: reductions,
            reimb: reimb,
            decision: selectedDec === "" ? 'Undecided' : selectedDec,
            denyReasons: Array.isArray(selectedDenyReason) ? selectedDenyReason.map(o => o.description) : [selectedDenyReason],
            negReduct: negReduct,
            provReimb: provReimb,
            units: units,
            lineDesc: lineDesc,
            cptDescription: cptDescription,
            basicNonEmergency: basicNonEmg,
            basicEmergency: basicEmg,
            extendedNonEmergency: extNonEmg,
            extendedEmergency: extEmg,
            medPayAllocation: medPay,
            denyReasonsOther : reasonOthertext,
            miscCode1 : miscCode1,
            miscCode2 : miscCode2,
            reasonMTGNote : reasonMTGNote
        }
        this.props.setBillRevEditVal(modifiedArray);
        this.props.billReviewSubmissionData(modifiedArray)
    }
    render() {
        const isClickable = !!this.props.clickHandler;
        const isSelectable = !!this.props.selectHandler;
        const isRowClickable = !!this.props.rowClickHandler;
        const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;
        denyReasonTextMap.clear();
        return (
            <>
                {
                  
                    this.props.data && this.props.data.map((dataObject, rowIndex) => {
                        const returnData = !!dataObject.fields ? dataObject.fields : dataObject;
                        return (

                            <BillReviewAppTableBodyRow rowIndex={rowIndex} columns={this.props.columns} highlightedRow={this.state.highlightedRow} isClickable={isClickable} isSelectable={isSelectable}
                                isRowClickable={isRowClickable} isRowDoubleClickable={isRowDoubleClickable} {...this.props} returnData={returnData} dataObject={dataObject} taskListData={this.props.taskListData}
                                claimant={this.props.claimant} billId={this.props.billId} postBillReviewData={this.postBillReviewData} allowMultiDenialReason={this.props.allowMultiDenialReason}
                                updateMap = {this.updateMap} getUpdatedMap={this.getUpdatedMap}
                            />

                        )
                    })
                }


            </>
        )
    }
}

const mapStateToProps = state => {
    const billReviewState = billOperationsSelectors.getBillReview(state);

    return {
        totalFromState: billReviewState.rembTotal,
        //denyReasonFromState: billReviewState.denyReasons ? billReviewState.denyReasons : [],
        billRevEditValFromState: billReviewState.billRevEditValues ? billReviewState.billRevEditValues : [],
    };
};

const mapDispatchToProps = dispatch => ({
    // setRembTotal: total => dispatch(billOperationsActions.setRembTotal(total)),
    // removeRembTotal: total =>
    //     dispatch(billOperationsActions.removeRembTotal(total)),
    setBillRevEditVal: value =>
        dispatch(billOperationsActions.setBillRevEditVal(value)),
    // setProvReimb: value =>
    //     dispatch(billOperationsActions.setProvReimb(value)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

BillReviewAppTableBody.propTypes = AppTableBodyPropType;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillReviewAppTableBody);