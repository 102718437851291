import helper from 'helper/helper';

import {
  transformItems,
  expertUsersCoreDataFieldMap,
  taskFieldMap,
} from './transform';

const getUserTasks = userId => {
  return helper.request.post('/GetUserTasks', { userId });
};

const getExpertCoreData = userId => {
  return helper.request.post('/LoadExpertCoreData', { userId });
};

const getInitialData = async userId => {
  const [userTasksResponse, expertCoreDataResponse] = await Promise.all([
    getUserTasks(userId),
    getExpertCoreData(userId),
  ]);
  const radius = expertCoreDataResponse.data.radius || {
    radius: [],
  };
  const network = expertCoreDataResponse.data.network || {
    expertnetworks: [],
  };
  const masterspecialtylist = expertCoreDataResponse.data
    .masterspecialtylist || {
    masterspecialtylist: [],
  };
  const services = expertCoreDataResponse.data.services || {
    expertDocumentTypes: [],
  };
  const expertgrouptype = expertCoreDataResponse.data.expertgrouptype || {
    expertgrouptype: [],
  };
  const provcredlist_state = expertCoreDataResponse.data.provcredlist_state || {
    provcredlist_state: [],
  };
  return {
    tasks: transformItems(userTasksResponse.data.tasks || [], taskFieldMap),
    faxes: userTasksResponse.data.faxes || [],
    pendingFax: userTasksResponse.data.pendingFax || [],
    historyFax: userTasksResponse.data.historyFax || [],
    expertUsers: transformItems(
      expertCoreDataResponse.data.expertUsers || [],
      expertUsersCoreDataFieldMap
    ),
    users: expertCoreDataResponse.data.users || [],
    states: expertCoreDataResponse.data.states || [],
    radiuses: radius.radius || [],
    expertNetworks: network.expertnetworks || [],
    masterSpecialties: masterspecialtylist.masterspecialtylist || [],
    services: services.expertDocumentTypes || [],
    expertGroupTypes: expertgrouptype.expertgrouptype || [],
    provCredStates: provcredlist_state.provcredlist_state || [],
    reportsSpecialties: expertCoreDataResponse.data.reportsspeccert || [],
  };
};

const getTasks = async userId => {
  const response = await getUserTasks(userId);
  return transformItems(response.data.tasks || [], taskFieldMap);
};

const transferTasks = async data => {
  const body = {
    taskIds: data.selectedTasks.join(','),
    userId: data.transferredUser,
  };
  const response = await helper.request.post('/transferTasks', body);
  if (response.data < 1) {
    throw new Error(response.data);
  }
  return response.data;
};

const findRadiusExperts = async data => {
  const body = {
    city: data.city,
    state: data.state,
    zip: data.zip,
    radius: data.radius,
    networkId: data.networkId,
    active: data.active,
    specialty_id: data.specialtyId,
    service: data.service,
    grouptypeId: data.groupTypeId,
  };
  const response = await helper.request.post('/ExpertRadiusSearch', body);
  return response.data;
};

const runExpertReport = async data => {
  const body = data;
  const response = await helper.request.post('/RunExpertReport', body);
  return response.data.expertReportResult || [];
};

const findExpertSimple = async searchValue => {
  if (isNaN(searchValue)) {
    const response = await helper.request.post('/FindExpertSimple', {
      fullName: searchValue,
      expertId: '0',
    });
    return response.data.expertSearch || [];
  }
  const response = await helper.request.post('/FindExpertSimple', {
    expertId: searchValue,
  });
  const experts = response.data.expertSearch || [];
  return experts.filter(item => item.expert_id === parseInt(searchValue));
};

const loadExpertSummaryData = data => {
  return helper.request.post('/findSummaryData', data);
};

const loadExpertPopupData = data => {
  return helper.request.post('/LoadPopupData', data);
};

const loadExpertUser = async data => {
  const [expertSummaryResponse, expertPopupResponse] = await Promise.all([
    loadExpertSummaryData({
      expertId: data.expertId,
      peopleId: data.peopleId,
    }),
    loadExpertPopupData({
      expertId: data.expertId,
    }),
  ]);
  const expertSummaryData = expertSummaryResponse.data;
  const expertPopupData = expertPopupResponse.data;
  return {
    expertNetworks: expertPopupData.vw_expert_networks || [],
    expertServices: expertPopupData.vw_expert_services || [],
    addresses: expertSummaryData.addresses || [],
    credentialTypes: expertPopupData.plistdp || [],
    credentials: expertSummaryData.expertcredentials || [],
    people: expertSummaryData.people
      ? expertSummaryData.people.length > 0
        ? expertSummaryData.people[0]
        : {}
      : {},
    groupExpert: expertSummaryData.groupExpert || [],
    expert: expertSummaryData.expert
      ? expertSummaryData.expert.length > 0
        ? expertSummaryData.expert[0]
        : {}
      : {},
  };
};

const findExpertGroup = async data => {
  const body = {
    groupname: data.groupName,
  };
  const response = await helper.request.post('/findExpertGroup', body);
  const responseData = response.data;
  return responseData.expertSearch || [];
};

const loadExpertAddresses = async data => {
  const body = {
    expertId: data.expertId,
  };
  const response = await helper.request.post('/findAddresses', body);
  return response.data;
};

const saveNewGroup = async data => {
  const body = {
    groupname: data.groupName,
    zebUserId: data.currentUserId.toString(),
  };
  const response = await helper.request.post('/saveNewGroup', body);
  if (response.data === -1) {
    throw new Error(response.data);
  }
  return response.data;
};

const findExpert = async data => {
  const body = {
    firstname: data.firstName,
    lastname: data.lastName,
  };
  const response = await helper.request.post('/findExpert', body);
  return response.data;
};

const saveNewExpert = async data => {
  const body = {
    firstname: data.firstName,
    lastname: data.lastName,
    zebUserId: data.currentUserId.toString(),
  };
  const response = await helper.request.post('/saveNewExpert', body);
  if (response.data === -1) {
    throw new Error(response.data);
  }
  return response.data;
};

export default {
  getInitialData,
  findRadiusExperts,
  findExpertSimple,
  loadExpertUser,
  getTasks,
  transferTasks,
  runExpertReport,
  findExpertGroup,
  loadExpertAddresses,
  saveNewGroup,
  findExpert,
  saveNewExpert,
};
