import * as R from 'ramda';
import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import {
  sortByType,
  formatMMDDYYYY,
  isValidDate,
} from 'shared/utils/shareUtils';

import { getValueByKey } from '../../../utils/billMaintenanceUtil';

class ClientRatesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editColumns: {},
      selectedRows: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const { data } = this.props;
      if (data) {
        this.setState({
          data: data,
        });
      }
    }
  }

  updateEditColumnsState = (id, value) => {
    this.setState({
      editColumns: {
        ...this.state.editColumns,
        [id]: value,
      },
    });
  };

  onChangeHandler = (targetId, value) => {
    this.updateEditColumnsState(targetId, value);
  };

  onChangeEffectiveDateHandler = (targetId, value) => {
    if (this.props.editColumns['effective']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeTerminatedDateHandler = (targetId, value) => {
    if (this.props.editColumns['terminated']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeTypeHandler = (targetId, event) => {
    if (this.props.editColumns['type']) {
      const value = event.target.value;
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onSelectLineOfBusinessHandler = (id, item) => {
    if (this.props.editColumns['lineOfBusiness']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.lobList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectRateTypeHandler = (id, item) => {
    if (this.props.editColumns['rateType']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.rateTypeList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectNetworkHandler = (id, item) => {
    if (this.props.editColumns['network']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.clientNetworkList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectBillTypeHandler = (id, item) => {
    if (this.props.editColumns['billType']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.billTypeList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onSelectStateHandler = (id, item) => {
    if (this.props.editColumns['state']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.stateList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  getDescriptionColumn(item) {
    return item.scheduleName || '';
  }

  getRateAmountColumn(item) {
    return item.amount || '';
  }

  getLineOfBusinessColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_billingType_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      //Set default value
      const key = item.hasOwnProperty('billingType') ? item.billingType : '';
      value = getValueByKey(this.props.lobList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.lobList}
        value={value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectLineOfBusinessHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['lineOfBusiness'],
        }}
      />
    );
  }

  getRateTypeColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_rateType_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      //Set default value
      const key = item.hasOwnProperty('rateType') ? item.rateType : '';
      value = getValueByKey(this.props.rateTypeList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.rateTypeList}
        value={value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectRateTypeHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['rateType'],
        }}
      />
    );
  }

  getNetworkColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_network_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      //Set default value
      const key = item.hasOwnProperty('ppoId') ? item.ppoId + '' : '';
      value = getValueByKey(this.props.clientNetworkList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.clientNetworkList}
        value={value === '' ? 'No PPO' : value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectNetworkHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['network'],
        }}
      />
    );
  }

  getBillTypeColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_billType_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      //Set default value
      const key = item.hasOwnProperty('billType') ? item.billType + '' : '';
      value = getValueByKey(this.props.billTypeList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.billTypeList}
        value={value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectBillTypeHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['billType'],
        }}
      />
    );
  }

  getStateColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_state_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = item.hasOwnProperty('state') ? item.state + '' : '';
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.stateList}
        value={value}
        displayOption="key"
        onChange={this.onChangeHandler}
        onSelect={this.onSelectStateHandler}
        showToolTip={false}
        updateOnLeave={false}
        placeholder=""
        inputProps={{
          disabled: !this.props.editColumns['state'],
        }}
      />
    );
  }

  getTypeColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_source_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = item.hasOwnProperty('source') ? item.source + '' : '';
    }
    return (
      <select
        name={id}
        id={id}
        value={value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
        onChange={event => this.onChangeTypeHandler(id, event)}
        className="form-control"
        disabled={!this.props.editColumns['type']}
      >
        <option value=""></option>
        <option value="Retail">Retail</option>
        <option value="Wholesale">WholeSale</option>
      </select>
    );
  }

  getEffectiveColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_effDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).effDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }

    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeEffectiveDateHandler(id, value)}
        selected={value}
        disabled={!this.props.editColumns['effective']}
      />
    );
  }

  getTerminatedColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_termDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).termDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }

    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeTerminatedDateHandler(id, value)}
        selected={value}
      />
    );
  }

  getSelectActionColumn(item) {
    if (!!!item.fields) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          id={`${this.props.name}-select-${item.fields.id}`}
          name={`${this.props.name}-select-${item.fields.id}`}
          type="checkbox"
          checked={this.state.selectedRows.includes(item)}
          onChange={event => {
            this.selectItem(item, event);
          }}
        />
      </div>
    );
  }

  sortColumn(item1, item2, order, field) {
    const value1 = item1.fields.hasOwnProperty(field)
      ? item1.fields[field] + ''
      : '';
    const value2 = item2.fields.hasOwnProperty(field)
      ? item2.fields[field] + ''
      : '';
    return sortByType(value1, value2, order);
  }

  sortEffectiveColumn(item1, item2, order) {
    const value1 = (item1.fields.dateRange || {}).effDate || '';
    const value2 = (item2.fields.dateRange || {}).effDate || '';
    return sortByType(value1, value2, order);
  }

  sortTerminatedColumn(item1, item2, order) {
    const value1 = (item1.fields.dateRange || {}).termDate || '';
    const value2 = (item2.fields.dateRange || {}).termDate || '';
    return sortByType(value1, value2, order);
  }

  selectAll = event => {
    const result = event.target.checked ? this.state.data.slice() : [];
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  selectItem = (item, event) => {
    const result = event.target.checked
      ? this.state.selectedRows.concat(item)
      : this.state.selectedRows.filter(row => row.fields.id !== item.fields.id);
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  updateTableData = (targetId, value) => {
    const [, columnId, itemId] = targetId.split('_');

    if (columnId && itemId) {
      let item = R.find(
        item => item.fields.id + '' === itemId,
        this.state.data
      );
      if (item) {
        if (columnId === 'termDate' || columnId === 'effDate') {
          item.fields.dateRange[columnId] = formatMMDDYYYY(value);
        } else {
          item.fields[columnId] = value;
        }
      }
    }
    if (!this.props.isSelectable) {
      this.props.updateTableData(this.state.data);
    }
  };

  render() {
    let { data } = this.state;
    const { filters } = this.props;
    if (filters) {
      if (filters.state) {
        data = data.filter(item => {
          return item.fields.state === filters.state;
        });
      }

      if (filters.lineOfBusiness) {
        data = data.filter(item => {
          return item.fields.billingType === filters.lineOfBusiness;
        });
      }

      if (filters.hasOwnProperty('effective')) {
        const today = new Date();
        data = data.filter(item => {
          //(item.hasOwnProperty('dateRange') && (effectiveClient))
          if (item.fields.hasOwnProperty('dateRange') && filters.effective) {
            const effDate = new Date(item.fields.dateRange.effDate);
            const termDate = new Date(item.fields.dateRange.termDate);
            if (filters.effective) {
              //item.dateRange.effDate <= today &&  item.dateRange.termDate >= today
              if (effDate <= today && termDate >= today) {
                return true;
              } else {
                return false;
              }
            } else {
              //(item.dateRange.effDate >= today ||  item.dateRange.termDate <= today)
              if (effDate >= today || termDate <= today) {
                return true;
              } else {
                return false;
              }
            }
          }
          return true;
        });
      }
    }
    let columns = [
      {
        header: 'Description',
        field: 'scheduleName',
        render: item => this.getDescriptionColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'scheduleName'),
      },
      {
        header: 'Line of Business',
        field: 'billingType',
        render: item => this.getLineOfBusinessColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'billingType'),
      },
      {
        header: 'Rate Type',
        field: 'rateType',
        render: item => this.getRateTypeColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'rateType'),
      },
      {
        header: 'Network',
        field: 'ppoId',
        render: item => this.getNetworkColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'ppoId'),
      },
      {
        header: 'Bill Type',
        field: 'billType',
        render: item => this.getBillTypeColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'billType'),
      },
      {
        header: 'Rate Amount',
        field: 'amount',
        render: item => this.getRateAmountColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'amount'),
      },
      {
        header: 'State',
        field: 'state',
        render: item => this.getStateColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'state'),
      },
      {
        header: 'Type',
        field: 'source',
        render: item => this.getTypeColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortColumn(item1, item2, order, 'source'),
      },
      {
        header: 'Effective',
        field: 'effDate',
        render: item => this.getEffectiveColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortEffectiveColumn(item1, item2, order),
      },
      {
        header: 'Terminated',
        field: 'termDate',
        render: item => this.getTerminatedColumn(item.fields),
        sortFunction: (item1, item2, order) =>
          this.sortTerminatedColumn(item1, item2, order),
      },
    ];
    if (this.props.isSelectable) {
      columns.push({
        header: (
          <div className="select-action">
            <CustomInput
              id={`${this.props.name}-selectAll`}
              name={`${this.props.name}-selectAll`}
              type="checkbox"
              checked={
                this.state.selectedRows.length === data.length &&
                data.length !== 0
              }
              onChange={this.selectAll}
            />
          </div>
        ),
        field: 'selectAction',
        sortAble: false,
        render: item => this.getSelectActionColumn(item),
      });
    }
    return (
      <AppTable
        columns={columns}
        data={data}
        autoPagination={true}
        sortAble={true}
        resizable={true}
      />
    );
  }
}

export default ClientRatesTable;
