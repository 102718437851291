import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Treebeard } from 'react-treebeard';

const treeStyle = {
    tree: {
        base: {
            listStyle: 'none',
            backgroundColor: '#e6e6e6',
            margin: 0,
            padding: 0,
            color: '#9DA5AB',
            fontFamily: 'sans-serif',
            fontSize: '16px'
        },
        node: {
            base: {
                position: 'relative',
                float: 'none',
                paddingRight: 0
            },
            link: {
                cursor: 'pointer',
                position: 'relative',
                padding: '0px 5px',
                display: 'block'
            },
            activeLink: {
                background: '#FFFFFF'
            },
            toggle: {
                base: {
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginLeft: '-5px',
                    height: '24px',
                    width: '24px'
                },
                wrapper: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-13px 0 0 -7px',
                    height: '14px'
                },
                height: 14,
                width: 14,
                arrow: {
                    fill: '#A9ABAD',
                    strokeWidth: 0
                }
            },
            header: {
                base: {
                    display: 'inline-block',
                    verticalAlign: 'top',
                    color: '#212529'
                },
                connector: {
                    width: '2px',
                    height: '12px',
                    borderLeft: 'solid 2px black',
                    borderBottom: 'solid 2px black',
                    position: 'absolute',
                    top: '0px',
                    left: '-21px'
                },
                title: {
                    lineHeight: '24px',
                    verticalAlign: 'middle'
                }
            },
            subtree: {
                listStyle: 'none',
                paddingLeft: '19px'
            },
            loading: {
                color: '#E2C089'
            }
        }
    }
}

class AppTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle(node, toggled) {
        const { cursor, data } = this.state;
        if (cursor) {
            this.setState(() => ({ cursor, active: false }));
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        this.setState(() => ({ cursor: node, data: { ...data } }));
    }

    render() {
        const style = this.props.style ? this.props.style : treeStyle;
        return (
            <Treebeard
                data={this.props.data}
                onToggle={this.onToggle}
                style={style}
            />
        )
    }

}

AppTree.propTypes = {
    data: PropTypes.array.isRequired,
    style: PropTypes.object
};

export default AppTree;