import * as R from 'ramda';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { assign } from 'lodash';
import { commonOperations } from 'common/store';
import { authSelectors } from 'modules/auth/store';
import AppInput from 'shared/components/input/AppInput';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

const AddManageBillsModal = ({
  isOpen,
  onClose,
  addManageBillsBillStatus,
  addManageBillsUsers,
  addManageBillsStates,
  siteDropDownData,
  addManageBillDataResponse,
  postAddManageBillData,
  billSearchData,
  updatedBillSearchData,
  selectedRecord,
  updateBillLinesDropDownData,
  userId,
  profile,
  alert,
  addNew,
}) => {
  let initialState = {
    searchName: '',
    billStatus: '',
    user: '',
    state: '',
    site: '',
    id: 0,
  };
  let checkBoxInitialState = false;
  if (selectedRecord && !addNew) {
    initialState = {
      searchName: selectedRecord.search_name,
      billStatus: selectedRecord.status_id,
      site: selectedRecord.site_id,
      user: selectedRecord.user_id,
      state: selectedRecord.state_id,
      id: selectedRecord.id,
    };
    checkBoxInitialState = selectedRecord.hide_printed;
  }

  let siteData = [...siteDropDownData];
  siteData.splice(0, 1);

  const [searchFields, setSearchFields] = useState(initialState);
  // const [fetchedData, setFetchedData] = useState([])
  const [checkBoxVal, setCheckBoxVal] = useState(checkBoxInitialState);

  const toggle = () => {
    resetFormData();
    onClose();
  };

  const onChangeHandler = (id, value) => {
    setSearchFields({ ...searchFields, [id]: value });
  };

  const billStatusChange = data => {
    setSearchFields({ ...searchFields, 'billStatus': data ? data.id : "" })
  }
  const userChangeHandler = data => {
    setSearchFields({ ...searchFields, 'user': data ? data.key : "" })
  }
  const stateChangeHandler = data => {
    setSearchFields({ ...searchFields, 'state': data ? data.abbreviation : "" })
  }
  const siteChangeHandler = data => {
    setSearchFields({ ...searchFields, 'site': data ? data.int_sitecode : "" })
  }

  const onchangeCheckboxChange = () => {
    if (checkBoxVal === false) setCheckBoxVal(true);
    else if (checkBoxVal === true) setCheckBoxVal(false);
  };
  const resetFormData = () => {
    setSearchFields({
      searchName: '',
      billStatus: '',
      user: '',
      state: '',
      site: '',
      id: 0,
      hide_printed: false,
    });
    // resetAddManageBillData();
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      checkBoxVal,
      userId,
      profile,
    };

    let itemObj = {
      hide_printed: params.checkBoxVal,
      id: params.id,
      search_name: params.searchName,
      search_user_id: Number(params.userId),
      site_id: params.site,
      state_id: params.state,
      status_id: params.billStatus,
      user_id: params.user,
    };

    const updateBillSearches = async data => {
      if (data) {
        // setFetchedData(data)
        let modifiedData = data;
        let index = R.findIndex(
          i => i.id !== 0 && i.id === searchFields['id'],
          data
        );
        if (index > -1) {
          modifiedData[index] = itemObj;
        } else {
          modifiedData.push(itemObj);
        }
        updateBillLinesDropDownData(modifiedData);
      }
    };

    let { searchName, site, state, user, billStatus } = params;
    if (searchName && (site || state || user || billStatus)) {
      let actionData = { updatedBillSearchData, params };
      postAddManageBillData(actionData);
      updateBillSearches(updatedBillSearchData);
      if (
        addManageBillDataResponse.length === 0 ||
        addManageBillDataResponse > 0
      ) {
        onClose();
      }
    } else if (!searchName && (site || state || user || billStatus)) {
      alert('Please enter a Search name.');
    } else if (searchName && !(site || state || user || billStatus)) {
      alert('Please select one or more search filters.');
    } else {
      alert('Please enter a Search name.');
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen} className="printEorModal">
        <ModalHeader toggle={toggle}>Manage Bill Searches...</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col xs="2">
                <div>Search Name</div>
              </Col>
            </Row>

            <Row>
              <Col xs="6">
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    value={searchFields.searchName}
                    placeholder="Search Name"
                    id="searchName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col>
                <div>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={checkBoxVal}
                        onChange={onchangeCheckboxChange}
                      />{' '}
                      Hide Printed Bills?
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div>Bill Status</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Choose..."
                    onChange={billStatusChange}
                    defaultValue={addManageBillsBillStatus.filter(option => option.id == searchFields.billStatus)}
                    name="billStatus"
                    options={
                      addManageBillsBillStatus.length
                        ? addManageBillsBillStatus.map(
                          obj => assign(obj, { label: obj.description, value: obj.id })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <div>User</div>
              </Col>

              <Col>
                <div>State</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Choose..."
                    onChange={userChangeHandler}
                    defaultValue={addManageBillsUsers.filter(option => option.key == searchFields.user)}
                    name="user"
                    options={
                      addManageBillsUsers.length
                        ? addManageBillsUsers.map(
                          obj => assign(obj, { label: obj.description, value: obj.key })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Choose..."
                    onChange={stateChangeHandler}
                    defaultValue={addManageBillsStates.filter(option => option.abbreviation == searchFields.state)}
                    name="state"
                    options={
                      addManageBillsStates.length
                        ? addManageBillsStates.map(
                          obj => assign(obj, { label: obj.name, value: obj.abbreviation })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Site</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Choose..."
                    onChange={siteChangeHandler}
                    defaultValue={siteData.filter(option => option.int_sitecode == searchFields.site)}
                    name="site"
                    options={
                      siteData.length
                        ? siteData.map(
                          obj => assign(obj, { label: obj.chr_sitename, value: obj.int_sitecode })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" id="odgSubmit">
              SAVE
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

AddManageBillsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  postAddManageBillData: PropTypes.func,
  resetAddManageBillData: PropTypes.func,
  alert: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  getBaseStateData: PropTypes.func,
  billSearchData: PropTypes.array,
  updateBillLinesDropDownData: PropTypes.func,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  billReviewInitialData.pickList = billReviewInitialData.pickList
    ? billReviewInitialData.pickList
    : {};

  return {
    siteDropDownData: billReviewInitialData.slpsites,
    addManageBillsBillStatus: billReviewInitialData.statuses,
    addManageBillsUsers: billReviewInitialData.processors,
    addManageBillsStates: billReviewInitialData.states,
    addManageBillsSites: billReviewInitialData.slpSites,
    userId: user && user.user_id,
    profile: billReviewInitialData.currentProfile,
    addManageBillDataResponse: billReviewState.addManageBillData,
    billSearchData: billReviewInitialData.billsearches,
    updatedBillSearchData: billReviewState.updatedBillSearches,
  };
};

const mapDispatchToProps = dispatch => ({
  postAddManageBillData: data =>
    dispatch(billOperationsOperations.postAddManageBillData(data)),
  getAddManageBillData: data =>
    dispatch(billOperationsOperations.getAddManageBillData(data)),
  resetAddManageBillData: () =>
    dispatch(billOperationsActions.resetAddManageBillData()),
  updateBillLinesDropDownData: data =>
    dispatch(billOperationsActions.updateBillLinesDropDownData(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddManageBillsModal);