import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label, Col, Row, Button } from 'reactstrap';

class ManagerOverrideModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: "",
            password: ""
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = () => {
        this.props.clicked()
    };

    handleDropdown = (e) => {

        this.setState({ selectedVal: e })
    }

    handlePasswordChange = (e) => {
        this.setState({ password: e })
    }

    handleClick = () => {
      if (this.state.password === 'LetsDoThis'){
        this.props.setDisableSave(false);
        this.props.clicked();
      }else{
        alert('Incorrect password.');
      }
    }


    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '225px' }} scrollable={'true'}>
                <ModalHeader toggle={this.toggle}>
                    Manager Override Password
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xl={3} lg={3} style={{ marginTop: '6px' }}>Password: </Col>
                        <Col xl={7} lg={7} style={{ marginTLeft: '-40px' }}>
                            <FormGroup>
                                <Input
                                    type="password"
                                    placeholder="Enter Manager Override Password"
                                    value={this.state.password}
                                    onChange={e => this.handlePasswordChange(e.target.value)}
                                ></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} lg={2} style={{ marginLeft: '75px' }}>
                            <Button onClick={this.handleClick} disabled={this.state.password == ""} style={{ width: '125px' }}>OK
                            </Button>
                        </Col>
                        <Col xl={2} lg={2} style={{ marginLeft: '75px' }}>
                            <Button onClick={this.toggle} style={{ width: '125px' }}>Cancel
                            </Button>
                        </Col>
                    </Row>

                </ModalBody>
            </Modal >
        )
    }
}

export default ManagerOverrideModal;
