import * as R from 'ramda';

export const initialDataTransform = {
  fromResponseBody: (object, stateDocuments) => {
    return {
      ...object,
      billLineOptions: object.billLineOptions.billlineoptions
        ? object.billLineOptions.billlineoptions
        : [],
      billTypes: object.billTypes.billtypes ? object.billTypes.billtypes : [],
      processors: object.billTypes.billtypes
        ? object.processors.processors
        : [],
      currentProfile: object.currentProfile,
      stateDocuments: stateDocuments,
    };
  },
};

export const findClaimsTransform = {
  toRequestBody: data => {
    const requestBody = {
      action: 'getclaim',
      claimNumber: '',
      lastNameOrSsn: '',
      billId: '',
      tin: '',
      firstName: '',
      client: '',
      state: '',
      siteId: '',
      billtypes: '',
      userId: '',
      profile: '',
      siteId: '',
    };

    return {
      ...requestBody,
      claimNumber: data.claimNumber || '',
      lastNameOrSsn: data.lastNameOrSsn,
      billId: data.billId,
      tin: data.tin,
      firstName: data.firstName,
      client: data.client,
      state: data.state,
      siteId: data.siteId,
      billtypes: data.billTypes,
      userId: data.userId,
      profile: data.profile,
      siteId: data.siteId || '',
    };
  },
  fromResponseBody: response => {
    //TODO: Remove once /SLPWebHistoryHandlerGetClaim returns an array of objects
    if (R.isEmpty(response) && response.constructor === Object) {
      return [];
    }

    return response;
  },
};

export const urDecisionTransform = {
  toRequestBody: claim => {
    return {
      functionName: 'getPreCerts',
      claimantID: claim.int_claimantId,
      siteID: claim.int_siteID,
      claimID: claim.int_claimcode,
      state: claim.chr_statejuris,
      zebraUserId: claim.userId,
      profile: claim.profile,
    };
  },
};

export const findBillsTransform = {
  toRequestBody: data => {
    const requestBody = {
      action: 'getclaimdetails',
      claimNumber: '',
      tin: '',
      billId: '',
      billIdEnd: '',
      externalId: '',
      billstatus: '',
      processor: '',
      state: '',
      client: '',
      billtypes: '',
      entryDate: '',
      userId: '',
      profile: '',
      siteId: '',
    };

    return {
      ...requestBody,
      claimNumber: data.claimNumber || '',
      tin: data.tin || '',
      billId: data.billScan || '',
      billIdEnd: data.end || '',
      externalId: data.externalId || '',
      billstatus: data.billStatus || '',
      processor: data.processor || '',
      state: data.state || '',
      client: data.client || '',
      billtypes: data.billTypes || '',
      entryDate: data.entryDate || '',
      userId: data.userId || '',
      profile: data.profile || '',
      siteId: data.siteId || '',
    };
  },
  fromResponseBody: response => {
    return response;
  },
};
