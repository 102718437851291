import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row, Col, Button, Form } from 'reactstrap';

import { commonOperations } from 'common/store';
import * as TypeUtils from 'shared/utils/typeUtils';

import operations from '../store/operations';
import selectors from '../store/selectors';
import { authSelectors } from 'modules/auth/store';

import UserTab from '../components/UserTab';

export const tabs = [
  {
    index: '1',
    name: 'Basics',
  },
  {
    index: '2',
    name: 'Access',
  },
  {
    index: '3',
    name: 'Ancil Access',
  },
  {
    index: '4',
    name: 'Bill Review',
  },
  {
    index: '5',
    name: 'Bill Approval',
  },
  {
    index: '6',
    name: 'Adjuster Mapping',
  },
  {
    index: '7',
    name: 'Clients',
  },
  {
    index: '8',
    name: 'Comments',
  },
];

const EditUser = () => {
  const dispatch = useDispatch();
  const { state: routerState } = useLocation();
  const user = useSelector(selectors.getUser, shallowEqual);
  //const userId = useSelector((state) => state.FAST360.initialData.roleUsers.LmsUserId);
  const lmsUser = useSelector(authSelectors.getUser, shallowEqual);
  const userId = lmsUser.user_id;
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    if (routerState.userId) {
      dispatch(operations.getUserInformation(routerState.userId));
    } else {
      dispatch(operations.getUserInformation('0'));
    }
  }, [dispatch, routerState.userId]);

  return (
    <Form>
      <UserTab
        tabs={tabs}
        toggleTab={tab => setActiveTab(tab)}
        activeTab={activeTab}
      />
      <Row style={{ marginTop: '20px' }}>
        <Col className="text-right" lg="12">
          <Button
            outline
            color="primary"
            style={{ marginRight: '20px' }}
            onClick={() => dispatch(operations.cancelAdminUserForm())}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            onClick={() => {
              const { userAccess, userBasics } = user;

              if (TypeUtils.isNilOrEmpty(userBasics.firstName)) {
                dispatch(
                  commonOperations.addAlert(
                    'First Name field is required.',
                    'danger'
                  )
                );
              } else if (TypeUtils.isNilOrEmpty(userBasics.lastName)) {
                dispatch(
                  commonOperations.addAlert(
                    'Last Name field is required.',
                    'danger'
                  )
                );
              } else if (
                TypeUtils.isNilOrEmpty(userBasics.primaryEmailAddress)
              ) {
                dispatch(
                  commonOperations.addAlert(
                    'Primary Email Address field is required.',
                    'danger'
                  )
                );
              } else if (
                TypeUtils.isNilOrEmpty(userBasics.location) ||
                userBasics.location === 0
              ) {
                dispatch(
                  commonOperations.addAlert(
                    'Location field is required.',
                    'danger'
                  )
                );
              } else if (TypeUtils.isNilOrEmpty(userAccess.userName)) {
                dispatch(
                  commonOperations.addAlert(
                    'Username field is required.',
                    'danger'
                  )
                );
              } else if (
                TypeUtils.isNilOrEmpty(userAccess.roleSelect) ||
                userAccess.roleSelect === 0
              ) {
                dispatch(
                  commonOperations.addAlert(
                    'Roles field is required.',
                    'danger'
                  )
                );
              } else {
                dispatch(operations.deleteWorklistLinks(user, userId));
                dispatch(operations.saveAdminUserForm(user, userId));                
                //dispatch(operations.saveAdminAncilUser(user, userId)); // commented out, gets called on postback
              }
            }}
          >
            SAVE
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUser;
