import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';

import VendorLookupModal from './VendorLookupModal';
import { connect } from 'react-redux';
import VendorLookupConfirmModal from './VendorLookupConfirmModal';
import { fast360Operations, fast360Selectors } from '../store/index';
import { Fast360VendorSearchContext } from './AdvanceSearch/contexts/Fast360VendorSearchContext';
import { useAuth } from 'modules/auth/hooks/useAuth';

const Fast360VendorLookupModal = ({
  isOpen,
  rowIndex,
  selectedRowIndex,
  clicked,
  dataObject,
  claimantData,
  lmsUserId,
  currentUserId,
  refreshCall,
  setVendorSearchCall,
  vendorSearchCall,
  vendorLookupSuggestions,
  currentWorkList,
  loadReferralList,
  selectedSubService,
  loadReferralandVender,
  fromLanding,
  landingData,
  setVendorLookupClicked,
  vendorLookupClicked,
  setVendorLookupOpen
}) => {
  const subServiceMapper = {
    AUD: 'audCategoryID',
    HHC: 'categoryId',
    ACC: 'categoryId',
    DTL: 'dtlcategoryId',
    DGN: 'examType',
    INT: 'translationCategoryId',
    MOD: 'modCategoryId',
    MSE: 'categoryId',
    TRN: 'transportationCategoryId',
    SPT: 'specialTransportationCategoryId',
  };
  const serviceTypeMap = {
    acc: 'accommodation',
    accommodation: 'accommodation',
    aud: 'audiology',
    audiology: 'audiology',
    dtl: 'dental',
    dental: 'dental',
    dgn: 'diagnostics',
    diagnostics: 'diagnostics',
    int: 'interpretation',
    interpretation: 'interpretation',
    trn: 'transportation',
    transportation: 'transportation',
    spt: 'special_transp',
    special_transp: 'special_transp',
    mse: 'mse',
    hhc: 'hhc',
    mod: 'modifications',
    modification: 'modifications'
  }
  const [openVendorLookupModal, setOpenVendorLookupModal] = useState(false);
  const { userId } = useAuth();

  const [
    openVendorLookupConfirmModal,
    setOpenVendorLookupConfirmModal,
  ] = useState(false);

  const loadVendorLookupData = () => {
    var subServiceKey = selectedSubService?.module && subServiceMapper[selectedSubService?.module];
    let subServiceId;
    if (selectedSubService && selectedSubService[subServiceKey]) {
      subServiceId = selectedSubService[subServiceKey];
    } else if (selectedSubService?.module == 'AUD') {
      subServiceId = 1097;
    } else if (selectedSubService?.module == 'DTL') {
      subServiceId = 1098;
    }
    let payload = {
      radius: 50,
      tableAbbrv: selectedSubService?.module,
      table: serviceTypeMap[selectedSubService?.module.toLowerCase()],
    };

    if (claimantData && claimantData?.address) {
      payload.vendorZip = claimantData?.address?.zipCode?.zip;
      payload.serviceType = {
        value: selectedSubService?.module,
        label: serviceTypeMap[selectedSubService?.module.toLowerCase()],
      };
      payload.subService = subServiceId;
    }


    dispatchFields({
      type: 'UPDATE_INPUT_FIELDS',
      payload: payload,
    });
  };

  const toggleConfirmModal = isConfirmOpen => {
    if (isConfirmOpen) {
      setOpenVendorLookupConfirmModal(true);
      setVendorLookupOpen && setVendorLookupOpen(!isOpen)
    } else {
      clicked();
    }
  };

  const { inputFields, dispatchFields } = useContext(
    Fast360VendorSearchContext
  );

  const vendorSearch = (
    data,
    userID,
    lmsUserId,
    loadReferralList
  ) => {
    if (vendorSearchCall) {
      setVendorSearchCall(false);
      const request = {
        functionName: 'vendorLookupSuggestions',
        zebraUserId: userID,
        subService: data?.subService,
        lmsUserId: lmsUserId,
        fromLanguage:
          (loadReferralList &&
            loadReferralList?.translationList[0] &&
            loadReferralList?.translationList[0]?.fromLanguageId) ||
          '',
        referralId: selectedSubService?.referralId || currentWorkList?.FK_referral_id,
        dos: moment(currentWorkList?.serviceDate).format('YYYY-MM-DD'),
        dbaName: data?.dbaName,
        vendorName: data?.vendorName,
        toLanguage:
          (loadReferralList &&
            loadReferralList?.translationList[0] &&
            loadReferralList?.translationList[0]?.LanguageId) ||
          '',
        tableAbbrv: selectedSubService?.chr_reference_table || data?.tableAbbrv || currentWorkList.chr_reference_table,
        limit: data?.limit && parseInt(data.limit, 10),
        nationwide: '' + data.nationwide,
        vendorZip: data?.vendorZip,
        serviceId: selectedSubService?.serviceId ||
          currentWorkList?.int_reference_table_id,
        radius: data.nationwide ? 9999 : data.radius,
        table: selectedSubService?.ServiceType?.toLowerCase() || data?.table.toLowerCase() || currentWorkList.ServiceType?.toLowerCase(),
      };
      vendorLookupSuggestions(request);
    } else if (selectedSubService?.referralId) {
      const request = {
        functionName: 'vendorLookupSuggestions',
        zebraUserId: userId,
        referralId: selectedSubService?.referralId,
        tableAbbrv: selectedSubService?.module,
        serviceId: selectedSubService?.serviceId ||
          currentWorkList?.int_reference_table_id
      };
      vendorLookupSuggestions(request);
    }
  };

  useEffect(() => {
    if(!vendorSearchCall){
      vendorSearch(
        inputFields,
        currentUserId,
        lmsUserId,
        loadReferralList
      );
    }
  }, []);
  useEffect(() => {
    if (vendorSearchCall) {
      vendorSearch(
        inputFields,
        currentUserId,
        lmsUserId,
        loadReferralList
      );
    }
  }, [vendorSearchCall]);
  useEffect(() => {
    loadVendorLookupData();
  }, [selectedSubService]);
  useEffect(() => {
    if(isOpen){
      setOpenVendorLookupModal(isOpen);
    }
  }, [isOpen]);

  return (
    <>
      {openVendorLookupModal && (
        <VendorLookupModal
          isOpen={openVendorLookupModal}
          refreshCall={refreshCall}
          rowIndex={rowIndex}
          selectedRowIndex={selectedRowIndex}
          onClose={isConfirmOpen => {
            setOpenVendorLookupModal(false);
            toggleConfirmModal(isConfirmOpen);
            setVendorLookupClicked && setVendorLookupClicked(!vendorLookupClicked);
          }}
          lmsUserId={lmsUserId}
          inputFields={inputFields}
          dataObject={dataObject}
          dispatchFields={dispatchFields}
          setVendorSearchCall={setVendorSearchCall}
          selectedSubService={selectedSubService}
          loadReferralandVender={loadReferralandVender}
          vendorSearch={vendorSearch}
          currentUserId={currentUserId}
          currentWorkList={currentWorkList}
          loadReferralList={loadReferralList}
        />
      )}
      <VendorLookupConfirmModal
        isOpen={openVendorLookupConfirmModal}
        onClose={() => {
          setOpenVendorLookupConfirmModal(false);
          clicked();
        }}
      />
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    lmsUserId: state.FAST360.initialData.roleUsers
      ? state.FAST360.initialData.roleUsers.LmsUserId
      : '',
    currentWorkList: fast360Info.currentWorkListItem,
    loadReferralList:
      fast360Info.updateLoadFromWorkList.translationList &&
      fast360Info.updateLoadFromWorkList,
    claimantData:
      fast360Info.updateLoadFromWorkList.claim &&
      fast360Info.updateLoadFromWorkList.claim.claimant,
  };
};

const mapDispatchToProps = dispatch => ({
  vendorLookupSuggestions: data =>
    dispatch(fast360Operations.vendorLookupSuggestions(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fast360VendorLookupModal);
