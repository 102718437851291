import React, { useState, useContext, useEffect } from 'react';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';
import { UpdateTypes, selectAll, selectAllSubCategory, UpdateServiceTypes} from './utils/helper';
import {
  Row,
  Col,
  FormGroup,
  Label,
  TabPane,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

function updateAdvSearchForm(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_AdvSearch_Form',
    payload: { [key]: value },
  });
}

function updateCheckBox(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_CheckBox',
    payload: { [key]: value },
  });
}

function resetCheckbox(dispatch){
  dispatch({
      type:"RESET"
  })
}

function updateServiceTabs(value, dispatch) {
  dispatch({
    type: 'UPDATE_SERVICE',
    payload: value ,
  });
}

const Transportation = ({
  transportCategory,
  specialTransportCategory,
  appointmentType,
  loadLookupReportAction,
  transportButton,
  setTransportButton,
  useStoredData
}) => {
  const { state, dispatch } = useContext(AdvSearchContext);
  const { state1, dispatch1 } = useContext(CheckBoxContext);
  const { transportTypes } = state;  
  useEffect(()=> {
    if(!useStoredData){
      selectAllSubCategory(true, transportCategory, dispatch, 'transportTypes', 'transport')
    }
  },[transportCategory]);
  useEffect(()=> {
    if(!useStoredData){
      selectAllSubCategory(true, specialTransportCategory, dispatch, 'transportTypes', 'specialTransport')
    }
  },[specialTransportCategory]);

  const [ abc, setabc] = useState('initial');
  const [ apptTypeSpl, setApptTypeSpl] = useState("Choose");
  const [ apptTypeTrn, setApptTypeTrn] = useState("Choose");
  const [ apptType, setApptType] = useState("Choose");



  useEffect(()=>{
    if(appointmentType){
      if(abc === "splTransportation"){
        const splAppt = appointmentType.filter((type)=>type.id === state.transportApptType);
        splAppt.length> 0 && setApptTypeSpl(splAppt[0].value)
      }
      else if( abc === "transportation"){
        const trnAppt = appointmentType.filter((type)=>type.id === state.transportApptType);
        trnAppt.length> 0 && setApptTypeTrn(trnAppt[0].value)
      }
      else if(abc === "initial"){
        const apptType = appointmentType.filter((type)=>type.id === state.transportApptType);
        apptType.length> 0 && setApptType(apptType[0].value)
      }
    }
  },[state.transportApptType, abc]) 

  return (
    <TabPane
      tabId="3"
      style={{ border: '1px solid #dee2e6', padding: '15px', overflow: 'auto' }}
    >
      <Row xs={2} md={4} style={{ paddingBottom: '15px' }}>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                checked={transportButton === 'transportation' }
                type="radio"
                name="radio1"
                onClick={() => {setTransportButton('transportation');updateAdvSearchForm('','transportApptType',dispatch);resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('TRN', dispatch)}}
              />{' '}
              Transportation
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                checked={transportButton === 'specialTransportation' }
                type="radio"
                name="radio1"
                onClick={() => {setTransportButton('specialTransportation');updateAdvSearchForm('','transportApptType',dispatch);resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('SPT', dispatch)}}
              />{' '}
              Special Transportation
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {transportButton == '' && (
        <FormGroup>
          <div style={{ float: 'left', width: '60%' }}>
            <Row xs={2} md={4}>
              <Col>
                <Label>Appt Type: </Label>
                <UncontrolledDropdown>
                  <DropdownToggle caret>
                  {apptType}
                  </DropdownToggle>
                  <DropdownMenu style={{overflowY:"scroll", height: "150px"}}>
                    {appointmentType &&
                      appointmentType.map((appointmentType, idx) => {
                        return (
                          <DropdownItem key={idx} onClick={(e)=>{updateAdvSearchForm(appointmentType.id,'transportApptType', dispatch);setabc('initial')}}>
                            {appointmentType.value}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed Charges From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin from:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={4}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.transportationFirstLegOnly}
                      onClick={e =>
                        updateAdvSearchForm(
                          e.target.checked,
                          'transportationFirstLegOnly',
                          dispatch
                        )
                      }
                    />{' '}
                    1st Leg Only
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed Charges To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>Select the fields to display on the report</Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportPickUpAddress}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportPickUpAddress',
                          dispatch1
                        )
                      }
                    />{' '}
                    Pick Up Address
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportDropOffAddress}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportDropOffAddress',
                          dispatch1
                        )
                      }
                    />{' '}
                    Drop Off Address
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportPickUpTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportPickUpTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Pick Up Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportDropOffTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportDropOffTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Drop Off Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportAppointmentType}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportAppointmentType',
                          dispatch1
                        )
                      }
                    />{' '}
                    Appointment Type
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportMileage}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportMileage',
                          dispatch1
                        )
                      }
                    />{' '}
                    Mileage
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportStaffedBy}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportStaffedBy',
                          dispatch1
                        )
                      }
                    />{' '}
                    Staffed By
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportTimeStaffed}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportTimeStaffed',
                          dispatch1
                        )
                      }
                    />{' '}
                    Time Staffed
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportNumberOfLegs}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportNumberOfLegs',
                          dispatch1
                        )
                      }
                    />{' '}
                    Number of Legs
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value={state1.displayTransportReturnType}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportReturnType',
                          dispatch1
                        )
                      }
                    />{' '}
                    Return Type
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportWaitingOnVendorConf}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportWaitingOnVendorConf',
                          dispatch1
                        )
                      }
                    />{' '}
                    Waiting on Vendor Conf
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportDateTimeBooked}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportDateTimeBooked',
                          dispatch1
                        )
                      }
                    />{' '}
                    Date/Time Booked
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <FormGroup>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Select All:
                  </Label>
                </FormGroup>
              </FormGroup>
              <div
                style={{
                  border: '1px solid black',
                  height: '150px',
                  width: '200px',
                  padding: '10px',
                  overflowY: 'scroll',
                }}
              ></div>
            </Col>
          </Row>
        </FormGroup>
      )}
      {transportButton == 'transportation' && (
        <FormGroup>
          <div style={{ float: 'left', width: '60%' }}>
            <Row xs={2} md={4}>
              <Col>
              <Label>Appt Type: </Label>
                <UncontrolledDropdown>
                  <DropdownToggle caret>
                  {apptTypeTrn}
                  </DropdownToggle>
                  <DropdownMenu style={{overflowY:"scroll", height: "150px"}}>
                    {appointmentType &&
                      appointmentType.map(appointmentType => {
                        return (
                          <DropdownItem onClick={(e)=>{updateAdvSearchForm(appointmentType.id, 'transportApptType', dispatch);setabc('transportation')}}>
                          {appointmentType.value}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed Charges From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin from:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={4}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value={state.transportationFirstLegOnly}
                      onClick={e =>
                        updateAdvSearchForm(
                          e.target.checked,
                          'transportationFirstLegOnly',
                          dispatch
                        )
                      }
                    />{' '}
                    1st Leg Only
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed Charges To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>Select the fields to display on the report</Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationPickUpAddress}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationPickUpAddress',
                          dispatch1
                        )
                      }
                    />{' '}
                    Pick Up Address
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationDropOffAddress}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationDropOffAddress',
                          dispatch1
                        )
                      }
                    />{' '}
                    Drop Off Address
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationPickUpTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationPickUpTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Pick Up Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationDropOffTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationDropOffTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Drop Off Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationMileage}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationMileage',
                          dispatch1
                        )
                      }
                    />{' '}
                    Mileage
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationStaffedBy}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationStaffedBy',
                          dispatch1
                        )
                      }
                    />{' '}
                    Staffed By
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationTimeStaffed}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationTimeStaffed',
                          dispatch1
                        )
                      }
                    />{' '}
                    Time Staffed
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationNumberOfLegs}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationNumberOfLegs',
                          dispatch1
                        )
                      }
                    />{' '}
                    Number of Legs
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationReturnType}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationReturnType',
                          dispatch1
                        )
                      }
                    />{' '}
                    Return Type
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationWaitingOnVendorConf}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationWaitingOnVendorConf',
                          dispatch1
                        )
                      }
                    />{' '}
                    Waiting on Vendor Conf
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayTransportationDateTimeBooked}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displayTransportationDateTimeBooked',
                          dispatch1
                        )
                      }
                    />{' '}
                    Date/Time Booked
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <FormGroup>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input 
                    type="checkbox"
                    checked={transportTypes.transport.length === 11}
                    onClick={(e)=> selectAllSubCategory(e.target.checked, transportCategory, dispatch, 'transportTypes', 'transport')}
                    /> Select All:
                  </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '230px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {transportCategory &&
                    transportCategory.map(element => {
                      return (
                        <FormGroup check>
                          <Label check>
                            <Input 
                            type="checkbox" 
                            checked={transportTypes.transport.includes(element.id)}
                            onClick={(e) => UpdateServiceTypes(e.target.checked, element.id, transportTypes.transport, dispatch, 'transportTypes','transport')}
                            />
                            {element.value}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
      )}
      {transportButton == 'specialTransportation' && (
        <FormGroup>
          <div style={{ float: 'left', width: '60%' }}>
            <Row xs={2} md={4}>
              <Col>
                <Label>Appt Type: </Label>
                <UncontrolledDropdown>
                  <DropdownToggle caret>
                  {apptTypeSpl}
                  </DropdownToggle>
                  <DropdownMenu style={{overflowY:"scroll", height: "150px"}}>
                    {appointmentType &&
                      appointmentType.map(appointmentType => {
                        return (
                          <DropdownItem onClick={(e)=>{updateAdvSearchForm(appointmentType.id, 'transportApptType', dispatch); setabc('splTransportation')}}>
                          {appointmentType.value}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed Charges From:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin from:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginFrom}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginFrom',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={4}>
              <Col></Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Total Cost To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalCostTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalCostTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Billed charges To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationTotalBilledChargesTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationTotalBilledChargesTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label style={{width:'116px'}}>Order Margin To:</Label>
                  <input
                    style={{width:'140px'}}
                    type="number"
                    value={state.transportationOrderMarginTo}
                    onChange={e =>
                      updateAdvSearchForm(
                        e.target.value,
                        'transportationOrderMarginTo',
                        dispatch
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>Select the fields to display on the report</Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationDropOffAddress}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationDropOffAddress',
                          dispatch1
                        )
                      }
                    />{' '}
                    Drop Off Address
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationAppointmentType}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationAppointmentType',
                          dispatch1
                        )
                      }
                    />{' '}
                    Appointment Type
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationDepartingDateTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationDepartingDateTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Departing Date/Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationArrivingDateTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationArrivingDateTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Arriving date/Time
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationDateTimeCreated}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationDateTimeCreated',
                          dispatch1
                        )
                      }
                    />{' '}
                    Date/Time Created
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationDateTimeBooked}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationDateTimeBooked',
                          dispatch1
                        )
                      }
                    />{' '}
                    Date/Time Booked
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationAirline}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationAirline',
                          dispatch1
                        )
                      }
                    />{' '}
                    Airline
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationDepartingAirport}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationDepartingAirport',
                          dispatch1
                        )
                      }
                    />{' '}
                    Departing Airport
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationArrivingAirport}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationArrivingAirport',
                          dispatch1
                        )
                      }
                    />{' '}
                    Arriving Airport
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationFlightClass}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationFlightClass',
                          dispatch1
                        )
                      }
                    />{' '}
                    Flight Class
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row xs={2} md={5}>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationBookingSite}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationBookingSite',
                          dispatch1
                        )
                      }
                    />{' '}
                    Booking Site
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displaySplTransportationLayOverTime}
                      onClick={e =>
                        updateCheckBox(
                          e.target.checked,
                          'displaySplTransportationLayOverTime',
                          dispatch1
                        )
                      }
                    />{' '}
                    Lay Over Time
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <FormGroup>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input 
                    type="checkbox" 
                    checked={transportTypes.specialTransport.length === 6}
                    onClick={(e)=> selectAllSubCategory(e.target.checked, specialTransportCategory, dispatch, 'transportTypes', 'specialTransport')}
                    /> Select All:
                  </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '200px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {specialTransportCategory &&
                    specialTransportCategory.map(element => {
                      return (
                        <FormGroup check>
                          <Label check>
                            <Input 
                            type="checkbox" 
                            checked={transportTypes.specialTransport.includes(element.id)}
                            onClick={(e) => UpdateServiceTypes(e.target.checked, element.id, transportTypes.specialTransport, dispatch, 'transportTypes','specialTransport')}
                            />
                            {element.value}
                          </Label>
                        </FormGroup>
                      );
                    })}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
      )}
    </TabPane>
  );
};

export default Transportation;
