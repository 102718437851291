import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { isEqual } from 'lodash';
import { commonOperations } from 'common/store';
import { authSelectors } from 'modules/auth/store';
import PopupModal from 'shared/components/PopupModal';
import AppTable from 'shared/components/table/AppTable';
import { billOperationsOperations, billOperationsSelectors } from '../store';
import { nurseAuditsType, codeReviewsType } from '../utils/constantUtils';
class AgreeBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  getBody = () => {
    if (this.ShowGrid().length > 0) {
      return (
        <span>
          <AppTable
            modalHeight={150}
            size="sm"
            columns={[
              {
                header: 'Bill ID',
                // field: 'billId',
                // render: item => this.setBillId(item),
                render: item => item?.billId,
                percent: '10',
              },
              {
                header: 'Last Modified',
                // field: 'lastModified',
                // render: item => this.setLastModifies(item),
                render: item => item?.lastModified,
                percent: '20',
              },
              {
                header: 'Coverage Limits',
                // field: 'limit',
                render: item => this.setLimits(item),
                percent: '45',
                size: 'sm',
              },
              {
                header: 'Warnings',
                // field: 'warning',
                percent: '25',
                render: item => this.setWarnings(item),
                size: 'sm',
              },
            ]}
            data={this.props.aoInfo}
          ></AppTable>
          Are you sure? This cannot be undone.
        </span>
      );
    } else {
      return <span>Are you sure? This cannot be undone.</span>;
    }
  };

  getFooter() {
    return (
      <>
        <Button color="secondary" onClick={this.toggle}>
          NO
        </Button>
        <Button color="primary" onClick={this.handleSave} disabled={!this.proceedCheck()}>
          YES
        </Button>
      </>
    );
  }

  setWarnings = searchResult => {
    return (
      <ul>
        {searchResult?.warning && searchResult?.warning?.map(item => (
          <li className="red-text italic-text" key={item}>
            {item}
          </li>
        ))}
      </ul>
    );
  };
  setLimits = searchResult => {
    return (
      <ul>
        {searchResult?.limit?.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  handleClick = () => {
    const { reviewType, selectedAdjusterName, selectedBills } = this.props;
    if (
      (reviewType === nurseAuditsType || reviewType === codeReviewsType) &&
      selectedAdjusterName === 'Generic List'
    ) {
      this.props.alert('You cannot modify bills from the Generic List');
      return;
    }
    if (reviewType === nurseAuditsType || reviewType === codeReviewsType) {
      this.approveBills(selectedBills);
      return;
    }

    const needPaycode = this.props.bills.filter(
      item =>
        this.props.selectedBills.includes(item.id) &&
        item.allowMultiDenialReason === true && item.paycodeId === 0 && item.juris !== 'WC'
    );
    var isAO = true;
    if(needPaycode.length > 0){
      isAO = false;
      this.props.alert(
        <span>
          The following bills are missing a paycode:
          <br></br>
          {needPaycode.map(item => item.id).join(',')}
        </span>
      );
      return;
    }

    const deniedBills = this.props.bills.filter(
      item =>
        this.props.selectedBills.includes(item.id) &&
        item.allowApproval !== true
    );
    if (deniedBills.length > 0) {
      this.props.alert(
        <span>
          The recommended reimbursement for following bills exceeds your
          approval threshold. This bill must be transferred to your supervisor
          for approval.
          <br></br>
          {deniedBills.map(item => item.id).join(',')}
        </span>
      );
    }
    
    if (this.ShowGrid().length > 0 && isAO) {
      this.loadAORemaining(this.getBillItems());
    }
    if (this.allowApprovalBills().length > 0) {
      this.toggle();
    }
  };

  handleSave = () => {
    this.toggle();
    this.approveBills(this.allowApprovalBills());
  };

  getBillItems() {
    return this.props.bills
      .filter(
        item =>
          this.props.selectedBills.includes(item.id) &&
          item.allowApproval === true
      )
      .map(item => item);
  }
  ShowGrid() {
    return this.props.bills
      .filter(
        item =>
          this.props.selectedBills.includes(item.id) &&
          item.allowApproval === true &&
          item.enablePaycode === 1 &&
          item.allowMultiDenialReason === 0
      )
      .map(item => item.id);
  }

  allowApprovalBills() {
    return this.props.bills
      .filter(
        item =>
          this.props.selectedBills.includes(item.id) &&
          item.allowApproval === true
      )
      .map(item => item.id);
  }

  approveBills = bills => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
    } = this.props;
    this.props.approveBills({
      currentUserId,
      profile,
      selectedAdjusterId,
      name: selectedAdjusterName,
      reviewType,
      selectedBills: bills,
    });
  };

  loadAORemaining = data => {
    const { currentUserId, profile } = this.props;
    this.props.loadAORemaining({
      currentUserId,
      profile,
      selectedBills: data,
    });
  };

  proceedCheck = () => {
    let checkProceed = true;
    this.props.bills.forEach((b) => this.props.selectedBills.forEach((sb) => {
      if (b.id === sb && b.enablePaycode === 1 && b.paycodeId === 0) {
        checkProceed = false;
      }
    }
    ));
    return checkProceed;
  };

  render() {
    return (
      <>
        <PopupModal
          content={this.getBody()}
          title="Agree selected Bills?"
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          footerContent={this.getFooter()}
          onClosed={this.resetModal}
          size="lg"
        />
        <Button
          type="button"
          onClick={this.handleClick}
          disabled={this.props.disabled}
          style={{ width: '100%' }}
          color="primary"
        >
          AGREE
        </Button>
      </>
    );
  }
}

AgreeBill.propTypes = {
  alert: PropTypes.func.isRequired,
  aoInfo: PropTypes.array,
  approveBills: PropTypes.func.isRequired,
  loadAORemaining: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  bills: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  selectedAdjusterId: PropTypes.number.isRequired,
  selectedBills: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  const initialUsers = approveBillState.users;
  const selectedAdjusterId = initialUsers['adjusters'].length ? initialUsers.adjusters.filter(t => t.name == state.AUTH.user.fullname).length && initialUsers.adjusters.filter(t => t.name == state.AUTH.user.fullname)[0].id : 0;
  return {
    bills: approveBillState.bills,
    aoInfo: approveBillState.aoInfo,
    profile: initialData.currentProfile,
    reviewType: approveBillState.reviewType,
    selectedAdjusterId: selectedAdjusterId,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
    selectedBills: approveBillState.selectedBills,
    currentUserId: user.id || -1,
  };
};

const mapDispatchToProps = dispatch => ({
  approveBills: data => dispatch(billOperationsOperations.approveBills(data)),
  loadAORemaining: data =>
    dispatch(billOperationsOperations.loadAORemaining(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreeBill);
