import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import { moduleName } from './constants';
import reducer from './reducers';

reducerRegistry.register(moduleName, reducer);

export { default as billReviewActions } from './actions';
export { default as billReviewConstants } from './constants';
export { default as billReviewSelectors } from './selectors';

export default reducer;
