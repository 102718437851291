import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, FormGroup, Input, CustomInput } from 'reactstrap';

import { commonOperations } from 'common/store';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { expertsSelectors, expertsOperations } from '../store';

const radiusDefaultValue = '5';
const radiusDefaultLabel = '5 Miles';
class ExpertRadiusSearchTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      state: '',
      stateValue: '',
      zip: '',
      radius: radiusDefaultLabel,
      radiusValue: radiusDefaultValue,
      network: '',
      networkValue: '',
      active: true,
      specialty: '',
      specialtyValue: '',
      service: '',
      serviceValue: '',
      groupType: '',
      groupTypeValue: '',
    };
  }

  onCityChangeHandler = (targetId, value) => {
    this.onChangeHandler(targetId, value);
    if (value !== '') {
      this.setState({
        zip: '',
      });
    }
  };

  onStateChangeHandler = (targetId, value) => {
    this.onChangeHandler(targetId, value);
    if (value !== '') {
      this.setState({
        zip: '',
      });
    }
  };

  onZipChangeHandler = (targetId, value) => {
    this.onChangeHandler(targetId, value);
    if (value !== '') {
      this.setState({
        city: '',
        state: '',
        stateValue: '',
      });
    }
  };

  onChangeHandler = (targetId, value) => {
    this.setState({
      [targetId]: value,
    });
  };

  onStateSelectHandler = (targetId, displayValue, value) => {
    this.onSelectHandler(targetId, displayValue, value);
    if (value !== '') {
      this.setState({
        zip: '',
      });
    }
  };

  onSelectHandler = (targetId, displayValue, value) => {
    this.setState({
      [targetId]: displayValue,
      [`${targetId}Value`]: value,
    });
  };

  onFindHandler = () => {
    if (
      (this.state.city === '' || this.state.state === '') &&
      (this.state.zip === '' || this.state.radiusValue === '')
    ) {
      this.props.alert('You must enter a city & state or zipcode AND radius.');
      return;
    }
    if (
      this.state.networkValue === '' &&
      this.state.specialtyValue === '' &&
      this.state.serviceValue === '' &&
      this.state.groupTypeValue === ''
    ) {
      this.props.alert(
        'You must select one or more of either the Network, Service, Specialty or Group Type filters.'
      );
      return;
    }

    const data = {
      city: this.state.city,
      state: this.state.stateValue,
      zip: this.state.zip,
      radius: this.state.radiusValue,
      networkId:
        this.state.networkValue === '' ? '-1' : this.state.networkValue,
      active: this.state.active ? '1' : '0',
      specialtyId: this.state.specialtyValue ? this.state.specialtyValue : '-1',
      service: this.state.serviceValue === '' ? '-1' : this.state.serviceValue,
      groupTypeId:
        this.state.groupTypeValue === '' ? '-1' : this.state.groupTypeValue,
    };
    this.props.findExperts(data);
  };

  onResetHandler = () => {
    this.setState({
      city: '',
      state: '',
      stateValue: '',
      zip: '',
      radius: radiusDefaultLabel,
      radiusValue: radiusDefaultValue,
      network: '',
      networkValue: '',
      active: true,
      specialty: '',
      specialtyValue: '',
      service: '',
      serviceValue: '',
      groupType: '',
      groupTypeValue: '',
    });
  };

  onRadiusSelect = (_id, item) => {
    const radiusComponents = item.element.split(' ');
    this.setState({
      radius: item.element,
      radiusValue: radiusComponents[0],
    });
  };

  onRadiusClear = () => {
    this.setState({
      radius: radiusDefaultLabel,
      radiusValue: radiusDefaultValue,
    });
  };

  render() {
    return (
      <>
        <Row>
          <Col lg="8">
            <Row>
              <Col lg="2">
                <FormGroup>
                  <AppTooltip
                    idWrapTag="div"
                    text="City"
                    id="city"
                    placement="bottom"
                  >
                    <Input
                      onChange={event =>
                        this.onCityChangeHandler(
                          event.target.id,
                          event.target.value
                        )
                      }
                      placeholder="City"
                      id="city"
                      type="text"
                      value={this.state.city}
                    />
                  </AppTooltip>
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup>
                  <AppAutocomplete
                    id="state"
                    data={this.props.states}
                    value={this.state.state}
                    displayOption="name"
                    returnValue="abbreviation"
                    onChange={this.onStateChangeHandler}
                    onSelect={this.onStateSelectHandler}
                    showToolTip={true}
                    placeholder="State"
                    isSuggested={true}
                  />
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup>
                  <AppTooltip
                    idWrapTag="div"
                    text="Zip Code"
                    id="zip"
                    placement="bottom"
                  >
                    <Input
                      onChange={event =>
                        this.onZipChangeHandler(
                          event.target.id,
                          event.target.value
                        )
                      }
                      placeholder="Zip Code"
                      id="zip"
                      type="text"
                      value={this.state.zip}
                    />
                  </AppTooltip>
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup>
                  <AppAutocomplete
                    id="radius"
                    data={this.props.radiuses}
                    value={this.state.radius}
                    displayOption="element"
                    onChange={this.onChangeHandler}
                    onSelect={this.onRadiusSelect}
                    showToolTip={true}
                    placeholder="Radius"
                    isSuggested={true}
                    clearInput={this.onRadiusClear}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <AppAutocomplete
                    id="network"
                    data={this.props.expertNetworks}
                    value={this.state.network}
                    displayOption="element"
                    returnValue="id"
                    onChange={this.onChangeHandler}
                    onSelect={this.onSelectHandler}
                    showToolTip={true}
                    placeholder="Network"
                    isSuggested={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col lg="2">
            <FormGroup>
              <CustomInput
                onChange={event =>
                  this.onChangeHandler(event.target.id, event.target.checked)
                }
                label="Only Active"
                id="active"
                type="checkbox"
                checked={this.state.active}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Row>
              <Col lg="4">
                <FormGroup>
                  <AppAutocomplete
                    id="specialty"
                    data={this.props.masterSpecialties}
                    value={this.state.specialty}
                    displayOption="element"
                    returnValue="description"
                    onChange={this.onChangeHandler}
                    onSelect={this.onSelectHandler}
                    showToolTip={true}
                    placeholder="Specialty"
                    isSuggested={true}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <AppAutocomplete
                    id="service"
                    data={this.props.services}
                    value={this.state.service}
                    displayOption="element"
                    returnValue="id"
                    onChange={this.onChangeHandler}
                    onSelect={this.onSelectHandler}
                    showToolTip={true}
                    placeholder="Service"
                    isSuggested={true}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <AppAutocomplete
                    id="groupType"
                    data={this.props.expertGroupTypes}
                    value={this.state.groupType}
                    displayOption="element"
                    returnValue="id"
                    onChange={this.onChangeHandler}
                    onSelect={this.onSelectHandler}
                    showToolTip={true}
                    placeholder="Group Types"
                    isSuggested={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col lg={{ size: '2', offset: '2' }}>
            <Row>
              <Col lg="6">
                <Button
                  color="danger"
                  style={{ width: '100%' }}
                  type="button"
                  onClick={this.onResetHandler}
                >
                  Reset
                </Button>
              </Col>
              <Col lg="6">
                <Button
                  style={{ width: '100%' }}
                  type="button"
                  onClick={this.onFindHandler}
                >
                  FIND
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

ExpertRadiusSearchTop.propTypes = {
  states: PropTypes.array.isRequired,
  radiuses: PropTypes.array.isRequired,
  expertNetworks: PropTypes.array.isRequired,
  masterSpecialties: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  expertGroupTypes: PropTypes.array.isRequired,
  findExperts: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialDataState = expertsSelectors.getInitialData(state);
  return {
    states: initialDataState.states,
    radiuses: initialDataState.radiuses,
    expertNetworks: initialDataState.expertNetworks,
    masterSpecialties: initialDataState.masterSpecialties,
    services: initialDataState.services,
    expertGroupTypes: initialDataState.expertGroupTypes,
  };
};

const mapDispatchToProps = dispatch => ({
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  findExperts: data => dispatch(expertsOperations.findRadiusExperts(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertRadiusSearchTop);
