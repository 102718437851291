import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Input, Label, Form, Button } from 'reactstrap';
import ListErrors from 'shared/components/ListErrors';
import { useAuth } from '../hooks/useAuth';
import ClientJS from 'clientjs'
import { connect } from 'react-redux';
import { billOperationsOperations,billOperationsActions } from 'modules/bill-operations/store/index';

const LoginForm = ({ clearPreviousData,setLoginState, onSubmit, setUsername, username, systemId, setSystemId, setPassword, password }) => {

   const {
    errors: authErrors,
    login,
    userId,
    errorMessage,
    currentQuestionId,
    loginResult,
  } = useAuth();

  const [loginFormState, setLoginFormstate] = useState('failure');
  const[firstAttempt, setFirstAttempt] = useState('true');

  const onSubmitLoginForm = async (event, username, password, systemId) => {
    event.preventDefault();
    await login(username, password, systemId);
    loginFormState === 'success' && onSubmit();
  }

  useEffect(() => {
    if(firstAttempt){
      setFirstAttempt(false);
      clearPreviousData() 
    }
  })

  useEffect(() => {
    const client = new window.ClientJS();
    const fingerprint = client.getFingerprint();
    setSystemId(fingerprint);
  }, [])


  useEffect(() => {
    if (
      userId != null &&
      errorMessage != 'Invalid password' &&
      currentQuestionId != null
    ) {
      setLoginFormstate('success');
    }
  }, [userId, errorMessage, currentQuestionId]);



  return (
      <Form
        onSubmit={(event)=>onSubmitLoginForm(event, username, password, systemId) }
        className="york-form login-form"
      >
        <Col lg="12" className="marginTop25">
          <FormGroup className="group full">
            <Label for="username">Username</Label>
            <Input
              autoFocus
              type="text"
              name="username"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="group full">
            <Label for="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          {errorMessage === 'Invalid Username or Password' ? (
            <span style={{ color: 'red' }}>You have entered an invalid User ID and/or Password.</span>
          ) : (
            ''
          )}
        </Col>
        <Col lg="12">
          {loginResult === 8 ? (
            <span style={{ color: 'red' }}>Your account has been disabled. Please use the Forgot Password' link, enter your username, and reset your password.  Please use the temporary password provided in the email you receive as the CURRENT password when prompted to change.</span>
          ) : (
          ''
          )}
        </Col>
      
      <Col lg="12">
        <div className="forgot-password">
          <Button
            color="link"
            className="h6"
            onClick={() => setLoginState('forgot')
            }
          >
            Forgot Password
            </Button>
        </div>
      </Col>
      <ListErrors errors={authErrors} />
      <Col lg="12">
        <Row className="action">
          <Col lg="6" xs="6">
            <button
              className="link-underline"
              type="submit"
              style={{ paddingLeft: '5px' }}
            >
              SUBMIT
              </button>
          </Col>
        </Row>
      </Col>
    </Form>    
  )
};

const mapDispatchToProps = dispatch => ({
  clearPreviousData: () =>
   dispatch(billOperationsOperations.resetAppData())
});

export default connect(null, mapDispatchToProps)(LoginForm);
