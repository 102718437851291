import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Col, Row, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import AppTableNurseAudit from 'shared/components/table/AppTableNurseAudit';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

const ModifyBillLinesModal = ({
  isOpen,
  onClose,
  modifyBillLinespData,
  postBillLinesDataRes,
  modifyBillTotalAmount,
  resetBillTotalData,
  resetBillLinesData,
  getBillLinesData,
  postBillLinesData,
  getBillTotalData,
  userId,
  profile,
  data,
  date,
  resetDenyReason,
  claimNumber,
  format,
  billId,
  claimant,
  denyReasons,
  rembTotal,
  formattedDOIDate,
  alert,
  reviewType,
  refresh
}) => {
  const [notes, setNotes] = useState('');

  const toggle = () => {
    resetBillLinesData();
    resetBillTotalData();
    onClose();
    // refresh();
  };
  const onChangeHandler = (id, value) => {
    setNotes(value);
  };

  useEffect(() => {
    const params = {
      userId,
      profile,
      billId,
      claimant,
      denyReasons,
    };

    if (isOpen) {
      resetBillLinesData();
      resetBillTotalData();
      resetDenyReason();
      getBillLinesData(params);
      getBillTotalData(params);
    }
  }, [isOpen]);

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      notes,
      userId,
      profile,
      billId,
      denyReasons,
      reviewType,
    };
    postBillLinesData(params);
    if (postBillLinesDataRes != -1) {
      setTimeout(() => {
        refresh();
      }, 2000);
      onClose();
    }
  };

  const sortColumn = (item1, item2, order, field) => {
    const field1 = item1[field] || {};
    const field2 = item2[field] || {};
    return sortByType(field1[field], field2[field], order);
  };

  const getImbTotal = () => { };

  const getBillLinesAppTable = () => {
    return (
      <div className="bill-lines">
        <Row>
          <div style={{ marginRight: '290px' }} className="CliamAdjust">
            BILL LINES
          </div>
          <Col>
            <Row>
              <Col>Claim #: {claimNumber}</Col>
              <Col>DOI: {date}</Col>
              <Col>Bill Total : {modifyBillTotalAmount}</Col>
              <Col>Reimb Total $: {rembTotal}</Col>
            </Row>
          </Col>
        </Row>

        <AppTableNurseAudit
          columns={[
            {
              header: 'Line',
              field: 'lineNumber',
            },
            {
              header: 'CPT',
              field: 'cpt',

              sortFunction: (item1, item2, order) =>
                sortColumn(item1, item2, order, 'icdDesc'),
            },
            {
              header: 'CPT Description',
              field: 'cptDescription',
            },
            {
              header: 'Units',
              field: 'units',
            },
            {
              header: 'Modifiers',
              field: 'modifier',
            },
            {
              header: 'DOS',
              field: 'dos',
            },
            {
              header: 'Billed',
              field: 'billed',
            },
            {
              header: 'Reductions',
              field: 'reductions',
            },
            {
              header: 'Total Paid',
              field: 'reimb',
            },
            {
              header: 'Decision',
              field: 'decision',
            },
          ]}
          data={modifyBillLinespData}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          key="bill-lines-table"
          claimant={claimant}
          billId={billId}
        />
      </div>
    );
  };

  return (
    <>
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Modify Bill Lines</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row>
              <Col lg="12">{getBillLinesAppTable()}</Col>
            </Row>

            <Row>
              <Col>
                <FormGroup row>
                  <Label for="firstName" className="CliamAdjustNote">
                    Notes
                  </Label>

                  <Col>
                    <AppInput
                      changeHandler={onChangeHandler}
                      placeholder="Notes"
                      id="notes"
                      type="textarea"
                    />
                  </Col>
                </FormGroup>
              </Col>

              <Col>
                <Button type="submit" id="odgSubmit">
                  SUBMIT{' '}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ModifyBillLinesModal.propTypes = {
  data: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modifyBillLinespData: PropTypes.array,
  postBillLinesDataRes: PropTypes.number,
  modifyBillTotalAmount: PropTypes.string,
  rembTotal: PropTypes.number,
  postBillLinesData: PropTypes.func,
  getBillLinesData: PropTypes.func,
  resetBillLinesData: PropTypes.func,
  resetBillTotalData: PropTypes.func,
  date: PropTypes.string,
  claimNumber: PropTypes.string.isRequired,
  billId: PropTypes.number.isRequired,
  claimant: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  alert: PropTypes.func.isRequired,
  reviewType: PropTypes.string,
  refresh: PropTypes.func
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);

  return {
    modifyBillLinespData: billReviewState.modifyBillLinespData,
    postBillLinesDataRes: billReviewState.postBillLinesDataRes,
    modifyBillTotalAmount: billReviewState.modifyBillTotalAmount,
    userId: user.user_id,
    profile: billReviewInitialData.currentProfile,
    denyReasons: billReviewState.denyReasons ? billReviewState.denyReasons : [],
    rembTotal: billReviewState.rembTotal,
  };
};

const mapDispatchToProps = dispatch => ({
  getBillLinesData: data =>
    dispatch(billOperationsOperations.getBillLinesData(data)),
  resetBillLinesData: () =>
    dispatch(billOperationsActions.resetBillLinesData()),
  getBillTotalData: data =>
    dispatch(billOperationsOperations.getBillTotalData(data)),
  resetBillTotalData: () =>
    dispatch(billOperationsActions.resetBillTotalData()),
  postBillLinesData: data =>
    dispatch(billOperationsOperations.postBillLinesData(data)),

  resetDenyReason: reason =>
    dispatch(billOperationsActions.resetDenyReason(reason)),

  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyBillLinesModal);
