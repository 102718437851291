import constants from './constants';
import actionCreatorUtils from 'core/utils/actionCreatorUtils';

const switchMenu = () => {
  return actionCreatorUtils.create(constants.SWITCH_MENU);
};

const switchModule = module => {
  return actionCreatorUtils.create(constants.SWITCH_MODULE, { module });
};

const error = () => {
  return actionCreatorUtils.create(constants.ERROR);
};

const clearError = () => {
  return actionCreatorUtils.create(constants.CLEAR_APP_ERROR);
};

const addAlert = (id, message, level = constants.DEFAULT_ALERT_LEVEL) => {
  return actionCreatorUtils.create(constants.ADD_APP_ALERT, {
    id,
    message,
    level,
  });
};

const clearAlert = id => {
  clearTimeout(id);
  return actionCreatorUtils.create(constants.CLEAR_APP_ALERT, { id });
};

const setLoading = () => {
  return actionCreatorUtils.create(constants.SET_APP_LOADING);
};

const clearLoading = () => {
  return actionCreatorUtils.create(constants.CLEAR_APP_LOADING);
};

export default {
  switchMenu,
  switchModule,
  error,
  clearError,
  addAlert,
  clearAlert,
  setLoading,
  clearLoading,
};
