import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as R from 'ramda';
import React, { Component } from 'react';

import { AppTableHeaderPropType } from './AppTablePropTypes';

class BillReviewTableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceColumn: {},
            destinationColumn: {},
        };
    }

    renderHeader = column => {
        if (column.renderHeader) {
            return column.renderHeader();
        }
        return column.header;
    };

    renderArrow = column => {
        if (!this.props.sortAble) {
            return '';
        }
        if (!this.props.sortImmediately) {
            return '';
        }
        if (column.sortAble !== undefined && column.sortAble === false) {
            return '';
        }
        const sortColumn = R.find(
            item => item.field === column.field,
            this.props.sortColumns
        );
        return this.props.sortBy === column.field ? (
            <FontAwesomeIcon
                className="grey-text"
                style={{ marginLeft: 5 }}
                icon={sortColumn.sortOrder ? faArrowUp : faArrowDown}
            />
        ) : (
                ''
            );
    };

    renderResizeBorder = column => {
        if (!this.props.resizable) {
            return '';
        }
        if (column.resizable !== undefined && column.resizable === false) {
            return '';
        }
        if (
            R.findIndex(item => item.field === column.field, this.props.columns) ===
            this.props.columns.length - 1
        ) {
            return '';
        }
        return (
            <span
                onMouseDown={e => e.persist() || this.onMouseDown(e, column)}
                draggable="false"
                className="resize-border"
            />
        );
    };

    getClassName = column => {
        if (!this.props.sortAble) {
            return '';
        }
        if (column.sortAble !== undefined && column.sortAble === false) {
            return '';
        }
        return 'cursor-hover';
    };

    onClickHandler = column => {
        if (!this.props.sortAble) {
            return;
        }
        if (column.sortAble !== undefined && column.sortAble === false) {
            return;
        }
        this.props.sort(column);
    };

    onMouseDown = (e, column) => {
        const offsetElement = e.target.offsetParent || e.target;
        const offsetParentElement = offsetElement.offsetParent || offsetElement;
        const startX = e.clientX;
        const width = offsetElement.offsetWidth;

        const onMouseMove = e => {
            const currentX = e.clientX;
            const deltaX = currentX - startX;
            const resizeColumns = this.props.resizeColumns.map(item => {
                if (item.field === column.field) {
                    return {
                        ...item,
                        percent: ((width + deltaX) / offsetParentElement.offsetWidth) * 100,
                    };
                }
                return { ...item };
            });
            this.props.updateResizeColumns(resizeColumns);
        };

        const onMouseUp = _e => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    getPercentWidth = column => {
        if (this.props.resizable) {
            const resizeColumn = R.find(
                item => item.field === column.field,
                this.props.resizeColumns
            );
            return resizeColumn.percent;
        }
        return column.percent;
    };

    onDragStart = (event, column) => {
        event.stopPropagation();
        this.setState({ sourceColumn: column });
    };
    onDragOver = (event, column) => {
        event.preventDefault();
        if (this.state.sourceColumn !== column) {
            this.setState({ destinationColumn: column });
        }
    };
    onDragEnd = event => {
        event.stopPropagation();
        const destinationColumnIndex = this.props.columns.indexOf(
            this.state.destinationColumn
        );
        if (destinationColumnIndex < 0) {
            return;
        }
        let newColumns = this.props.columns.filter(
            column => column !== this.state.sourceColumn
        );
        newColumns.splice(destinationColumnIndex, 0, this.state.sourceColumn);
        this.setState({
            sourceColumn: {},
            destinationColumn: {},
        });
        this.props.onColumnsChange(newColumns);
    };

    render() {
        return (
            <thead className={this.props.dark ? 'thead-dark' : ''}>
                <tr>
                    {this.props.isSelectable ? <th /> : null}
                    {this.props.columns.map((column, index) => {
                        return (
                            !column.hide &&
                            <th
                                key={index}
                                style={{ width: this.getPercentWidth(column) + '%' }}
                            >
                                <div
                                    draggable={this.props.draggable}
                                    style={{
                                        width: '80%',
                                        wordBreak: 'break-word'
                                    }}
                                    onDragStart={event => this.onDragStart(event, column)}
                                    onDragOver={event => this.onDragOver(event, column)}
                                    onDragEnd={this.onDragEnd}
                                    className={this.getClassName(column)}
                                    onClick={() => this.onClickHandler(column)}
                                >
                                    {this.renderHeader(column)}
                                    {this.renderArrow(column)}
                                </div>
                                {this.renderResizeBorder(column)}
                            </th>
                        );
                    })}
                    {this.props.isClickable ? <th /> : null}
                </tr>
            </thead>
        );
    }
}

BillReviewTableHeader.propTypes = AppTableHeaderPropType;

BillReviewTableHeader.defaultProps = {
    dark: true,
    sortAble: false,
    draggable: true,
    resizable: false,
};

export default BillReviewTableHeader;