import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { expertsSelectors } from '../store';

import ExpertFaxPendingTable from './ExpertFaxPendingTable';
import ExpertFaxHistoryTable from './ExpertFaxHistoryTable';
import ExpertFaxTable from './ExpertFaxTable';

const FAX_LIST = 'FAX_LIST';
const PENDING = 'PENDING';
const HISTORY = 'HISTORY';
class ExpertFaxTabContainer extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: FAX_LIST,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { faxes } = this.props;
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === FAX_LIST,
              })}
              onClick={() => {
                this.toggle(FAX_LIST);
              }}
            >
              Fax List ({faxes.faxes.length})
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === PENDING,
              })}
              onClick={() => {
                this.toggle(PENDING);
              }}
            >
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === HISTORY,
              })}
              onClick={() => {
                this.toggle(HISTORY);
              }}
            >
              History
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={this.state.activeTab}
          style={{ paddingTop: '10px' }}
        >
          <TabPane tabId={FAX_LIST}>
            <ExpertFaxTable data={faxes.faxes} />
          </TabPane>
          <TabPane tabId={PENDING}>
            <ExpertFaxPendingTable data={faxes.pendingFax} />
          </TabPane>
          <TabPane tabId={HISTORY}>
            <ExpertFaxHistoryTable data={faxes.historyFax} />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

ExpertFaxTabContainer.propTypes = {
  faxes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const faxes = expertsSelectors.getFaxes(state);
  return {
    faxes: faxes,
  };
};

export default connect(mapStateToProps)(ExpertFaxTabContainer);
