import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, CustomInput, Col, Row } from 'reactstrap';

import * as FileUtils from 'shared/utils/fileUtils';

import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

import { billOperationsActions, billOperationsSelectors } from '../store';

let checkedItems = [];
let oldDocFileName;

const ManageAttachmentsModal = ({
  documents,
  downloadFileName,
  downloadFileData,
  isOpen,
  onAttach,
  onDelete,
  onRetrieve,
  onToggle,
  resetRetrieveReferralDocument,
}) => {
  const [checkedVal, setCheckedVal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const resetModal = () => {
    checkedItems = [];
    setCheckedVal(false);
  };

  const stateDeleteModalToggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  const handleAttachFiles = e => {
    e.preventDefault();

    onAttach();
  };

  const handleDeleteFiles = e => {
    e.preventDefault();

    onDelete(checkedItems);
    resetModal();
    stateDeleteModalToggle();
  };

  const handleDownloadFile = item => {
    onRetrieve({
      fileName: item.path.split('/')[1],
      description: item.description,
    });
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked
      ? checkedItems.concat(item)
      : checkedItems.filter(
        row => row.id !== item.id
      );
    if (checkedItems.length > 0) {
      setCheckedVal(true);
    } else {
      setCheckedVal(false);
    }
  }

  const renderCheckBoxColumn = (item) => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.id}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />
      </div>
    );
  }

  const getDeleteModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={stateDeleteModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={handleDeleteFiles}>
          Yes
        </Button>
      </>
    );
  }

  const getBody = () => {
    return (
      <AppTable
        modalHeight={300}
        columns={[
          {
            header: 'Date Added',
            field: 'upload_date',
          },
          {
            header: 'Document Type',
            render: () => '',
          },
          {
            header: 'Description',
            field: 'description',
          },
          {
            header: 'Delete',
            render: item => renderCheckBoxColumn(item)
          },
          {
            header: 'Active',
            field: 'bol_active',
            render: item => {
              const className = item.bol_active
                ? 'active-green-circle'
                : 'red-text';
              return <FontAwesomeIcon className={className} icon={faCircle} />;
            },
          },
        ]}
        data={documents}
        rowDoubleClickHandler={handleDownloadFile}
      />
    );
  };

  const getFooter = () => {
    const text = checkedVal ? 'DELETE FILE(S)' : 'UPLOAD FILE(S)';
    const clickHandler = checkedVal ? stateDeleteModalToggle : handleAttachFiles;

    return <Button onClick={clickHandler}>{text}</Button>;
  };

  const getDeleteModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to delete these file(s)?</span>
        </Col>
      </Row>
    );
  }

  useEffect(() => {
    oldDocFileName =
      oldDocFileName === downloadFileName ? oldDocFileName : undefined;
    if (downloadFileData && !oldDocFileName) {
      FileUtils.saveBase64File(downloadFileName, downloadFileData);
      oldDocFileName = downloadFileName;
      resetRetrieveReferralDocument();
    }
  }, [downloadFileName, downloadFileData, resetRetrieveReferralDocument]);

  return (
    <>
      <PopupModal
        content={getBody()}
        title="ADD A FILE"
        externalToggle={onToggle}
        isOpen={isOpen}
        footerContent={getFooter()}
        onClosed={resetModal}
        size="xl"
      />
      <PopupModal
        content={getDeleteModalBodyContent()}
        title="Confirm Delete"
        isOpen={isDeleteModalOpen}
        externalToggle={stateDeleteModalToggle}
        footerContent={getDeleteModalFooterContent()}
        size="md"
      />
    </>
  );
};

ManageAttachmentsModal.propTypes = {
  documents: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAttach: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRetrieve: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  resetRetrieveReferralDocument: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const referralState = billOperationsSelectors.getReferralState(state);
  return {
    documents: referralState.documents,
    downloadFileName:
      referralState.retrieveDocument &&
      referralState.retrieveDocument.description,
    downloadFileData:
      referralState.retrieveDocument && referralState.retrieveDocument.fileData,
  };
};

const mapDispatchToProps = dispatch => ({
  resetRetrieveReferralDocument: () =>
    dispatch(billOperationsActions.resetRetrieveReferralDocument()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAttachmentsModal);
