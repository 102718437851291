import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { getNounForm, formatCurrency } from '../utils/billUtils';
import { adjustersType, qaBills } from '../utils/constantUtils';

export default class SummaryCountContainer extends Component {
  getTotalSelectedCharge = (selectedBills, bills, reviewType) => {
    return bills
      .filter(item => selectedBills.includes(item.id))
      .map(item =>
        (reviewType === adjustersType || reviewType === qaBills) ? item.charge : item.formattedCharge
      )
      .reduce((total, currentValue) => total + currentValue, 0);
  };

  render() {
    const { selectedBills, bills, reviewType } = this.props;
    return (
      <Row>
        <Col lg="2">{getNounForm(bills.length, 'Bill', 'Bills')}</Col>
        <Col lg="10">
          <div className="float-right bill-review-summary">
            <span>Total Selected Bills: {selectedBills.length}</span>
            <span>
              Total Selected Charges:{' '}
              {formatCurrency(
                this.getTotalSelectedCharge(selectedBills, bills, reviewType)
              )}
            </span>
          </div>
        </Col>
      </Row>
    );
  }
}

SummaryCountContainer.defaultProps = {
  reviewType: adjustersType || qaBills,
};

SummaryCountContainer.propTypes = {
  bills: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  reviewType: PropTypes.string.isRequired,
};
