import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';

import AppTable from 'shared/components/table/AppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { billOperationsSelectors } from '../store';
import { commonOperations } from 'common/store';
import BillSearchHeader from './BillSearchHeader';
import * as FetchUtils from 'shared/utils/fetchUtils';

class URDecision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      urDecisionSubTableData: [],
    };
  }

  topTableRowClick = data => {
    this.setState({
      notes: data.notes,
      urDecisionSubTableData: data.detail,
    });
  };

  componentDidUpdate(prevProps) {
    const { urDecision } = this.props;

    if (!isEqual(prevProps.urDecision, urDecision) && urDecision.length) {
      this.topTableRowClick(urDecision && urDecision[0]);
    }
  }

  handleExit=()=> {
    this.setState({notes: ""}, () => {
      this.props.exitHandler();
    })
  }

  urClickHandler = (id, notes, loiId) => {

    const params = new URLSearchParams();

    var actionUrl = null;
    if (this.isLOI(notes)) {
      if (loiId != null && loiId > 0) {
        actionUrl = new URL('/loiLetter.php', process.env.REACT_APP_SLP_BASE_URL);
        params.append("loiID", loiId);
      } else {
        alert('The LOI document has not been received yet. Please try again later.');
      }
    } else {      
      actionUrl = new URL('/urLetter.php', process.env.REACT_APP_SLP_BASE_URL);
      params.append("ref", id);
    }

    if (actionUrl != null) {
      actionUrl.search = params.toString();    

      FetchUtils.openWindowWithPost(
        actionUrl.toString(),
        {
          username: process.env.REACT_APP_SLP_USERNAME,
          password: process.env.REACT_APP_SLP_PASSWORD,
        },
        `UR-Docs${new Date().getTime()}`
      );
    }
  };

  isDisabled = notes => {
    if (notes.indexOf("Status: NULLIFIED") > -1) {
      return true;
    }
    return false;
  };

  isLOI = notes => {
    if (notes.indexOf("Status: LOI") > -1) {
      return true;
    }
    return false;
  }

  getDocuments = data => {
    return (this.isDisabled(data.notes) ? <></> :
      <>
        <AppTooltip
          text={'Show the bills UR'}
          id={`document-ur-toolTip-${data.id}`}
          placement="bottom"
        >
          <Button
            onClick={() => this.urClickHandler(data.referralId, data.notes, data.loiId)}
            color="primary"
            disabled={this.isDisabled(data.notes)}
          >
            UR
          </Button>          
        </AppTooltip>
      </>
    );
  };

  getDecision = value => {
    let diplayText = '';
    switch (value.decision) {
      case 0:
        return (diplayText = 'Allow');
      case 1:
        return (diplayText = 'Part Denial');
      case 2:
        return (diplayText = 'Full Denial');
      default:
        return diplayText;
    }
  };

  render() {
    return (
      <>
        <BillSearchHeader
          onChangeHandler={() => { }}
          sites={this.props.sites}
          hideControls={true}
          claim={
            !!this.props.claimData ? this.props.claimData : this.props.claim
          }
          exit={this.handleExit}
        />
        <div className="client-ppo-table">
          <AppTable
            modalHeight={130}
            resizable
            columns={[
              {
                header: 'Flag Id',
                field: 'flagName',
                rowKey: true,
              },
              {
                header: 'Bill Type',
                field: 'claimType',
              },
              {
                header: 'Provider',
                field: 'providerName',
              },
              {
                header: 'Tin',
                field: 'tin',
              },
              {
                header: 'Diag(s)',
                field: 'diagnosis',
              },
              {
                header: 'Eff',
                field: 'planEffDateString',
              },
              {
                header: 'Term',
                field: 'planTermDateString',
              },
              {
                header: 'ST',
                field: 'stateInitals',
              },
              {
                header: 'LOB',
                field: 'lob',
                render: item => ((item.lob || {}).fields || {}).lob || '',
              },
              {
                header: 'Scope',
                field: 'scope',
              },
              {
                header: 'Documents',
                field: 'documents',
                render: item => this.getDocuments(item),
              },
            ]}
            data={this.props.urDecision}
            autoPagination={true}
            rowClickHandler={this.topTableRowClick}
            highlightedRow={0}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Label for="notesInput">Notes</Label>
          <Input
            type="textarea"
            name="text"
            id="notesInput"
            value={this.state.notes}
            onChange={() => { }}
          />
        </div>
        <div className="client-ppo-table">
          <AppTable
            modalHeight={150}
            resizable
            columns={[
              {
                header: 'Services Reviewed',
                field: 'serviceReviewed',
                render: item => (
                  
                  item.serviceReviewed!==null? item.serviceReviewed:'')
              },
              {
                header: 'Code(s)',
                field: 'codes',
                render: item => (item.codes || {}).range || '',
              },
              {
                header: 'Modifier',
                field: 'modifiers',
                render: item => (item.modifiers || {}).range || '',
              },
              {
                header: 'Time(s)',
                field: 'times',
                render: item => item.times || 0,
              },
              {
                header: 'Day(s)',
                field: 'days',
                render: item => item.days || 0,
              },
              {
                header: 'Eff Date',
                field: 'effDate',
                render: item => (item.dateRange || {}).effDate || '',
              },
              {
                header: 'Term Date',
                field: 'termDate',
                render: item => (item.dateRange || {}).termDate || '',
              },
              {
                header: 'Decision',
                field: 'decision',
                render: item => this.getDecision(item),
              },
            ]}
            data={this.state.urDecisionSubTableData}
            autoPagination={true}
          />
        </div>
        <Button
          color="primary"
          style={{ float: 'right' }}
          onClick={this.props.reReviewButtonHandler}
          disabled={!this.props.enableButtons}
        >
          RE-REVIEW
        </Button>
      </>
    );
  }
}

URDecision.propTypes = {
  exitHandler: PropTypes.func.isRequired,
  claimData: PropTypes.object,
  urDecision: PropTypes.array.isRequired,
  enableButtons: PropTypes.bool,
  reReviewButtonHandler: PropTypes.func,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const findBills = billOperationsSelectors.getFindBills(state);

  return {
    claim: findBills.claim,
    urDecision: findBills.urDecision,
    sites: initialData.slpsites,
  };
};

const mapDispatchToProps = dispatch => ({
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(URDecision);