import * as TypeUtils from './typeUtils';

const MILLISECOND = 1;
const SECOND = MILLISECOND * 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

export function elapsedDate(dOne, dTwo = null) {
  if (TypeUtils.isNil(dOne)) {
    return false;
  }

  if (TypeUtils.isNil(dTwo)) {
    dTwo = new Date();
  }

  return new Date(dOne.getTime() - dTwo.getTime());
}

export function elapsedMilliseconds(dOne, dTwo = null, bDisregard = false) {
  if (bDisregard) {
    return elapsedDate(dOne, dTwo).getUTCMilliseconds();
  }

  if (TypeUtils.isNil(dOne) || TypeUtils.isNil(dTwo)) {
    return false;
  }

  return dOne.getTime() - dTwo.getTime();
}

export function elapsedDays(dOne, dTwo = null, bDisregard = false) {
  if (bDisregard) {
    return elapsedDate(dOne, dTwo).getUTCDate();
  }

  if (TypeUtils.isNil(dOne) || TypeUtils.isNil(dTwo)) {
    return false;
  }

  return Math.floor(elapsedMilliseconds(dOne, dTwo) / DAY);
}

export function getTimeObj (data) {
  let Hr = 0,
    Min = 0,
    ampm = undefined;
  if (data) {
    if (data.includes(':')) {
      const time = data;
      if (Number(time.split(':')[0]) > 12) {
        Hr = Number(time.split(':')[0]) - 12;
        ampm = '0';
      } else if (Number(time.split(':')[0]) == 0) {
        Hr = 12;
        ampm = '1';
      } else {
        Hr = Number(time.split(':')[0]);
        ampm = '1';
      }
      Min = Number(time.split(':')[1].split(' ')[0]);
      return { Hr: ('0' + Hr).slice(-2), Min:  ('0' + Min).slice(-2), ampm };
    } else {
      const time = data;
      const hrs = Number(time.substring(0, 2));
      const min = Number(time.substring(2, 4));
      if (hrs <= 11) {
        ampm = '1';
        Hr = hrs === 0 ? 12 : hrs;
      } else {
        ampm = '0';
        Hr = hrs;
      }
      Min = min;
      return { Hr: ('0' + Hr).slice(-2), Min:  ('0' + Min).slice(-2), ampm };
    }
  }
};
