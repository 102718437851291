import * as R from 'ramda';
import { combineReducers } from 'redux';

import {
  UPDATE_APPROVE_BILL_TABLE,
  UPDATE_AOREMAINING_INFO_DATA,
  UPDATE_APPROVE_BILL_INITIAL_DATA,
  UPDATE_SELECTED_APPROVE_BILLS,
  UPDATE_APPROVE_BILL_REVIEW_TYPE,
  UPDATE_APPROVE_BILL_SELECTED_ADJUSTER,
  UPDATE_APPROVE_BILL_GROUP_BY,
  UPDATE_QA_BILL_TABLE,
  UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER,
  UPDATE_SELECTED_QA_BILLS,
  UPDATE_QA_BILL_GROUP_BY,
  FIND_BILLS,
  LOAD_BILL_REVIEW_DATA,
  LOAD_MORE_INFO,
  UNPEND_BILL,
  SWITCH_VIEW,
  UPDATE_FIND_BILLS_TABLE,
  UPDATE_SELECTED_BILLS_FROM_CLAIM,
  UPDATE_SEND_EDI_SETUP_DATA,
  RESET_SEND_EDI_SETUP_DATA,
  UPDATE_ODG_LOOKUP_DATA,
  RESET_ODG_LOOKUP_DATA,
  UPDATE_BILL_LINES_DATA,
  UPDATE_POST_BILL_LINES_DATA,
  UPDATE_BILL_TOTAL_DATA,
  RESET_BILL_TOTAL_DATA,
  RESET_BILL_LINES_DATA,
  UPDATE_BILL_DETAIL_DROPDOWN_DATA,
  RESET_BILL_DETAIL_DROPDOWN_DATA,
  UPDATE_PRINT_EOR_DATA,
  RESET_PRINT_EOR_DATA,
  UPDATE_SEARCH_PPO_DATA,
  RESET_SEARCH_PPO_DATA,
  UPDATE_START_DATE_DATA,
  RESET_START_DATE_DATA,
  UPDATE_SITE_OPTIONS_DATA,
  RESET_SITE_OPTIONS_DATA,
  UPDATE_CASH_APP_DATA,
  RESET_CASH_APP_DATA,
  RESET_STORE_STATE,
  UPDATE_CURRENT_PROFILE,
  UPDATE_MANAGE_BILL_DATA,
  RESET_MANAGE_BILL_DATA,
  UPDATE_PRINT_EOR_DROPDOWN_USER_DATA,
  RESET_PRINT_EOR_DROPDOWN_USER_DATA,
  UPDATE_PRINT_REPORTS_DATA,
  RESET_PRINT_REPORTS_DATA,
  UPDATE_CLAIM_LIST_DATA,
  RESET_CLAIM_LIST_DATA,
  UPDATE_EMPLOYER_LIST_DATA,
  RESET_EMPLOYER_LIST_DATA,
  UPDATE_PROVIDER_LIST_DATA,
  RESET_PROVIDER_LIST_DATA,
  UPDATE_CLAIMANT_LIST_DATA,
  RESET_CLAIMANT_LIST_DATA,
  UPDATE_CLIENTS_PPO_DATA,
  RESET_CLIENTS_PPO_DATA,
  UPDATE_FEE_LOOKUP_NPI_DATA,
  RESET_FEE_LOOKUP_NPI_DATA,
  UPDATE_FEE_LOOKUP_DATA,
  RESET_FEE_LOOKUP_DATA,
  UPDATE_FEE_LOOKUP_DATA_UC,
  UPDATE_FEE_LOOKUP_DATA_UHUC,
  UPDATE_FEE_LOOKUP_DATA_BY_ZIP,
  UPDATE_FEE_LOOKUP_DATA_MED,
  UPDATE_FEE_LOOKUP_DATA_SSD,
  RESET_FEE_LOOKUP_DATA_UC,
  RESET_FEE_LOOKUP_DATA_UHUC,
  RESET_FEE_LOOKUP_DATA_BY_ZIP,
  RESET_FEE_LOOKUP_DATA_MED,
  RESET_FEE_LOOKUP_DATA_SSD,
  LOAD_TASK_LIST_GRID_SETTINGS,
  UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX,
  LOAD_FIND_BILLS_GRID_SETTINGS,
  UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX,
  UPDATE_BILLS_FROM_CLAIM,
  UPDATE_CLAIM_FROM_TASK_LIST,
  BILLS_FROM_TASK_LIST_CLAIM,
  UPDATE_CLAIM_DATA,
  UPDATE_UR_DECISION_TABLE,
  UPDATE_RECENT_ACTIVITY_INITIAL_DATA,
  UPDATE_RECENT_ACTIVITY_TABLE,
  UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER,
  UPDATE_SEARCH_FIELDS,
  UPDATE_REFERRAL_DOCUMENTS,
  RESET_REFERRAL_DOCUMENTS,
  UPDATE_RETRIEVE_REFERRAL_DOCUMENT,
  RESET_RETRIEVE_REFERRAL_DOCUMENT,
  UPDATE_SELECTED_DEMAND_BILLS,
  UPDATE_DEMAND_DOCUMENTS,
  UPDATE_RETRIVE_DEMAND_DOCUMENT,
  RESET_RETRIVE_DEMAND_DOCUMENT,
  UPDATE_REFERRAL_EMAIL_TEMPLATES,
  RESET_REFERRAL,
  UPDATE_REFERRAL_SPECIALTIES,
  SET_DENY_REASON,
  RESET_DENY_REASON,
  SET_MCNS_EDIT_VAL,
  RESET_MCNS_EDIT_VAL,
  SET_REMB_TOTAL,
  RESET_REMB_TOTAL,
  REMOVE_REMB_TOTAL,
  UPDATE_BILL_LINES_DROPDOWN_DATA,
  SET_BILL_LINES_DATA,
  UPDATE_STATUS_HISTORY_DATA,
  RESET_STATUS_HISTORY_DATA,
  UPDATE_STATUS_LIST_DATA,
  RESET_STATUS_LIST_DATA,
  UPDATE_UNPEND_DATA,
  RESET_UNPEND_DATA,
  UPDATE_PROFILE_DRODOWN_DATA,
  RESET_PROFILE_DRODOWN_DATA,
  UPDATE_POST_MCNS_BILLS_DATA,
  RESET_POST_MCNS_BILLS_DATA,
  SET_BILL_REVIEW_REV_EDITVAL,
  RESET_BILL_REVIEW_REV_EDITVAL,
  UPDATE_POST_BILL_REVIEW_DATA,
  RESET_POST_BILL_REVIEW_DATA,
  UPDATE_FINDBILLS_FROM_TABS,
  UPDATE_FIND_BILLS_FROM_RECENT,
  UPDATE_POST_BILL_REVIEW_AO_DATA,
  RESET_POST_BILL_REVIEW_AO_DATA,
  UPDATE_BILLCLAIM_NOTES,
  UPDATE_NEW_MED,
  UPDATE_CORRECTION_REASONS,
  RESET_TASK_LIST,
} from './constants';

import {
  adjustersType,
  mcnsUsersType,
  nurseAuditsType,
  codeReviewsType,
  groupByBill,
  findBillsGridSettingColumns,
  taskListGridSettingColumns,
} from '../utils/constantUtils';
let profile = ''
let gridSettings_load = true
const defaultState = {
  role: FIND_BILLS,
  defaultView: '',
  currentView: FIND_BILLS,
  initialData: {
    states: [],
    billTypes: [],
    currentProfile: '',
    activeCurrentProfile: '',
    slpsites: [],
    users: [],
    statuses: [],
    processors: [],
    stateDocuments: {
      documents: [],
      picklist: [],
      stateList: [],
    },
    manager: false,
    slpUserId: -1,
  },
  sendEDISetupData: [],
  odgLookupData: [],
  printEORData: [],
  searchPPOData: [],
  addManageBillData: [],
  clientsPPOData: [],
  startDateData: {},
  siteOptionsData: [],
  cashAppData: [],
  npiFeeLookupData: [],
  feeLookupData: [],
  feeLookupDataMed: [],
  feeLookupDataUC: [],
  feeLookupDataUHUC: [],
  feeLookupDataByZip: [],
  feeLookupDataByMed: [],
  feeLookupDataSsDesc: [],
  printEORDropdownUserData: [],
  modifyBillLinespData: [],
  rembTotal: 0,
  printReportsData: {},
  employerListData: [],
  claimListData: [],
  claimantListData: [],
  providerListData: [],
  profileDrodownData: {},
  billDetailDropdownData: [],
  updateFindBillsFromTabsClick: {},
  moreInfoData: [],
  unpendCodeOnBillInfoData: [],
  newMedValue: []
};

const billReviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'CANCEL_NEW_USER':
      return {
        ...defaultState,
      };

    case SWITCH_VIEW:
      return {
        ...state,
        ...action.payload,
      };

    case LOAD_BILL_REVIEW_DATA:
      profile = state.initialData && state.initialData.activeCurrentProfile !== ''
        ? state.initialData.activeCurrentProfile
        : action.payload && action.payload.currentProfile
        return {
          ...state,
          initialData: {
            activeCurrentProfile:
              state.initialData && state.initialData.activeCurrentProfile,
            ...action.payload,
            currentProfile:
              state.initialData && state.initialData.activeCurrentProfile !== ''
                ? state.initialData.activeCurrentProfile
                : action.payload && action.payload.currentProfile,
          },
        };
    case LOAD_MORE_INFO:
      return {
        ...state,
        moreInfoData: action.payload,
      };
    case UNPEND_BILL:
      return {
        ...state,
        unpendCodeOnBillInfoData: action.payload,
      };
    case RESET_STATUS_HISTORY_DATA:
      return {
        ...state,
        statusHistoryData: [],
      };
    case UPDATE_CURRENT_PROFILE:
      return {
        ...state,
        initialData: {
          ...state.initialData,
          currentProfile: action.payload,
          activeCurrentProfile: action.payload,
        },
      };


  
    case UPDATE_SEND_EDI_SETUP_DATA:
      return {
        ...state,
        sendEDISetupData: action.payload,
      };
    case RESET_SEND_EDI_SETUP_DATA:
      return {
        ...state,
        sendEDISetupData: [],
      };
    case UPDATE_ODG_LOOKUP_DATA:
      return {
        ...state,
        odgLookupData: action.payload,
      };
    case RESET_ODG_LOOKUP_DATA:
      return {
        ...state,
        odgLookupData: [],
      };
    case UPDATE_BILL_LINES_DROPDOWN_DATA:
      return {
        ...state,
        updatedBillSearches: action.payload,
      };
    case UPDATE_POST_BILL_LINES_DATA:
      return {
        ...state,
        postBillLinesDataRes: action.payload,
      };

    case UPDATE_BILL_TOTAL_DATA:
      return {
        ...state,
        modifyBillTotalAmount: action.payload,
      };
    case RESET_BILL_TOTAL_DATA:
      return {
        ...state,
        modifyBillTotalAmount: [],
      };
    case UPDATE_BILLCLAIM_NOTES:
      return {
        ...state,
        billClaimData: action.payload,
      };
    case UPDATE_BILL_LINES_DATA:
      return {
        ...state,
        modifyBillLinespData: action.payload.billDetail,
        modifyBillLinesFullData: action.payload,
      };

    case RESET_BILL_LINES_DATA:
      return {
        ...state,
        modifyBillLinespData: [],
        modifyBillLinesFullData: {},
      };

    case UPDATE_POST_MCNS_BILLS_DATA:
      return {
        ...state,
        postMcnsBillData: action.payload,
      };
    case UPDATE_POST_BILL_REVIEW_DATA:
      return {
        ...state,
        postBillReviewDataRes: action.payload,
      };

    case RESET_POST_BILL_REVIEW_DATA:
      return {
        ...state,
        postBillReviewDataRes: [],
      };
    case UPDATE_POST_BILL_REVIEW_AO_DATA:
      return {
        ...state,
        postBillReviewAoDataRes: action.payload,
      };

    case RESET_POST_BILL_REVIEW_AO_DATA:
      return {
        ...state,
        postBillReviewAoDataRes: [],
      };

    case RESET_POST_MCNS_BILLS_DATA:
      return {
        ...state,
        postMcnsBillData: [],
      };
    case UPDATE_BILL_DETAIL_DROPDOWN_DATA:
      return {
        ...state,
        billDetailDropdownData: action.payload,
      };
    case RESET_BILL_DETAIL_DROPDOWN_DATA:
      return {
        ...state,
        billDetailDropdownData: [],
      };
    case SET_DENY_REASON:
      return {
        ...state,
        denyReasons: action.data,
      };
    case RESET_DENY_REASON:
      return {
        ...state,
        denyReasons: [],
      };
    case SET_MCNS_EDIT_VAL:
      return {
        ...state,
        mcnsEditvalues: action.data,
      };
    case SET_BILL_REVIEW_REV_EDITVAL:
      return {
        ...state,
        billRevEditValues: action.data,
      };

    case RESET_BILL_REVIEW_REV_EDITVAL:
      return {
        ...state,
        billRevEditValues: [],
      };

    case RESET_MCNS_EDIT_VAL:
      return {
        ...state,
        mcnsEditvalues: {},
      };

    case SET_REMB_TOTAL:
      return {
        ...state,
        rembTotal: state.rembTotal + action.data,
      };
    case RESET_REMB_TOTAL:
      return {
        ...state,
        rembTotal: [],
      };
    case REMOVE_REMB_TOTAL:
      return {
        ...state,
        rembTotal: state.rembTotal - action.data,
      };
    // case UPDATE_DELETE_BILL_LINES_DATA:
    //   console.log('delete reducer');
    //   return {
    //     ...state,
    //     updatedBillsearches: action.data,
    //   };

    case SET_BILL_LINES_DATA:
      return {
        ...state,
        updatedBillSearches: action.payload,
      };

    case UPDATE_PRINT_EOR_DATA:
      return {
        ...state,
        printEORData: action.payload,
      };
    case RESET_PRINT_EOR_DATA:
      return {
        ...state,
        printEORData: [],
      };
    case UPDATE_SEARCH_PPO_DATA:
      return {
        ...state,
        searchPPOData: action.payload,
      };
    case RESET_PROFILE_DRODOWN_DATA:
      return {
        ...state,
        profileDrodownData: {},
      };

    case UPDATE_PROFILE_DRODOWN_DATA:
      return {
        ...state,
        profileDrodownData: action.payload,
      };
    case RESET_SEARCH_PPO_DATA:
      return {
        ...state,
        searchPPOData: [],
      };
    case UPDATE_START_DATE_DATA:
      return {
        ...state,
        startDateData: action.payload,
      };
    case RESET_START_DATE_DATA:
      return {
        ...state,
        startDateData: {},
      };
    case UPDATE_SITE_OPTIONS_DATA:
      return {
        ...state,
        siteOptionsData: action.payload,
      };
    case RESET_SITE_OPTIONS_DATA:
      return {
        ...state,
        siteOptionsData: [],
      };
    case UPDATE_CASH_APP_DATA:
      return {
        ...state,
        cashAppData: action.payload,
      };
    case RESET_CASH_APP_DATA:
      return {
        ...state,
        cashAppData: [],
      };

    case UPDATE_MANAGE_BILL_DATA:
      let arr = action.payload.data;
      let i = R.findIndex(
        t => t.id === action.payload.params.id,
        action.payload.data
      );
      if (i > -1) arr[i].id = action.payload.response;
      return {
        ...state,
        updatedBillsearches: action.arr,
      };
    case RESET_MANAGE_BILL_DATA:
      return {
        ...state,
        addManageBillData: [],
      };

    case UPDATE_PRINT_EOR_DROPDOWN_USER_DATA:
      return {
        ...state,
        printEORDropdownUserData: action.payload,
      };
    case RESET_PRINT_EOR_DROPDOWN_USER_DATA:
      return {
        ...state,
        printEORDropdownUserData: [],
      };

    case UPDATE_PRINT_REPORTS_DATA:
      return {
        ...state,
        printReportsData: action.payload,
      };
    case RESET_PRINT_REPORTS_DATA:
      return {
        ...state,
        printReportsData: {},
      };
    case UPDATE_CLAIM_LIST_DATA:
      return {
        ...state,
        claimListData: action.payload,
      };
    case RESET_CLAIM_LIST_DATA:
      return {
        ...state,
        claimListData: [],
      };
    case UPDATE_EMPLOYER_LIST_DATA:
      return {
        ...state,
        employerListData: action.payload,
      };
    case RESET_EMPLOYER_LIST_DATA:
      return {
        ...state,
        employerListData: [],
      };
    case UPDATE_PROVIDER_LIST_DATA:
      return {
        ...state,
        providerListData: action.payload,
      };
    case RESET_PROVIDER_LIST_DATA:
      return {
        ...state,
        providerListData: [],
      };
    case UPDATE_CLAIMANT_LIST_DATA:
      return {
        ...state,
        claimantListData: action.payload,
      };
    case RESET_CLAIMANT_LIST_DATA:
      return {
        ...state,
        claimantListData: [],
      };
    case UPDATE_CLIENTS_PPO_DATA:
      return {
        ...state,
        clientsPPOData: action.payload,
      };
    case RESET_CLIENTS_PPO_DATA:
      return {
        ...state,
        clientsPPOData: [],
      };
      case UPDATE_FEE_LOOKUP_NPI_DATA:
      return {
        ...state,
        npiFeeLookupData: action.payload,
      };
    case RESET_FEE_LOOKUP_NPI_DATA:
      return {
        ...state,
        npiFeeLookupData: [],
      };
    case UPDATE_FEE_LOOKUP_DATA:
      return {
        ...state,
        feeLookupData: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA:
      return {
        ...state,
        feeLookupData: [],
      };
    case UPDATE_FEE_LOOKUP_DATA_SSD:
      return {
        ...state,
        feeLookupDataSsDesc: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA_SSD:
      return {
        ...state,
        feeLookupDataSsDesc: [],
      };

    case UPDATE_FEE_LOOKUP_DATA_UC:
      return {
        ...state,
        feeLookupDataUC: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA_UC:
      return {
        ...state,
        feeLookupDataUC: [],
      };
    case UPDATE_FEE_LOOKUP_DATA_UHUC:
      return {
        ...state,
        feeLookupDataUHUC: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA_UHUC:
      return {
        ...state,
        feeLookupDataUHUC: [],
      };

    case UPDATE_FEE_LOOKUP_DATA_BY_ZIP:
      return {
        ...state,
        feeLookupDataByZip: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA_BY_ZIP:
      return {
        ...state,
        feeLookupDataByZip: [],
      };

    case UPDATE_FEE_LOOKUP_DATA_MED:
      return {
        ...state,
        feeLookupDataMed: action.payload,
      };
    case UPDATE_NEW_MED:
      return {
        ...state,
        newMedValue: action.payload,
      };
    case RESET_FEE_LOOKUP_DATA_MED:
      return {
        ...state,
        feeLookupDataMed: [],
      };
    case UPDATE_FINDBILLS_FROM_TABS:
      return {
        ...state,
        updateFindBillsFromTabsClick: action.payload,
      };
    case UPDATE_FIND_BILLS_FROM_RECENT:
      return {
        ...state,
        updateFindBillsFromRecentActivity: action.payload,
      };
    default:
      return { ...state };
  }
};

const findBillState = {
  findResults: [],
  billsInClaim: [],
  claimInTaskList: [],
  billsFromTaskListClaim: [],
  selectedBills: [],
  selectedClaims: [],
  settingColumns: findBillsGridSettingColumns,
  searchFields: {
    claimNumber: '',
    billScan: '',
    end: '',
    client: '',
    state: '',
    billTypes: '',
    billStatus: '',
    processor: '',
    entryDate: '',
    tin: '',
    lastNameOrSsn: '',
    firstName: '',
    siteId: '',
  },
  urDecision: [],
  claim: {},
  statusHistoryData: [],
  statusListData: [],
  unpendedData: [],
};

const findBillsReducer = (state = findBillState, action) => {
  switch (action.type) {
    case UPDATE_FIND_BILLS_TABLE:
      return {
        ...state,
        findResults: action.payload,
      };
    case UPDATE_BILLS_FROM_CLAIM:
      return {
        ...state,
        billsInClaim: action.payload,
      };

    case UPDATE_CLAIM_FROM_TASK_LIST:
      return {
        ...state,
        claimInTaskList: action.payload,
      };

    case BILLS_FROM_TASK_LIST_CLAIM:
      return {
        ...state,
        billsFromTaskListClaim: action.payload,
        searchFields: '',
      };

    case UPDATE_SELECTED_BILLS_FROM_CLAIM:
      return {
        ...state,
        selectedBills: action.payload,
      };

    case UPDATE_SEARCH_FIELDS:
      return {
        ...state,
        searchFields: action.payload,
      };

    case LOAD_FIND_BILLS_GRID_SETTINGS:
      if (action.payload.fields === undefined) {
        return {
          ...state,
        };
      }

      const gridSettings = action.payload.fields.detailList;
      let settingsState = state.settingColumns;
      gridSettings.forEach((col, index) => {
        if (col.fields.columnName !== undefined) {
          let checkbox = R.find(
            value => value.columnName === col.fields.columnName,
            settingsState
          );
          if (checkbox) {
            checkbox.state = true;
          }
        }
      });

      return {
        ...state,
        settingColumns: [...settingsState],
      };

    case UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX:
      return {
        ...state,
        settingColumns: action.payload,
      };

    case UPDATE_UR_DECISION_TABLE:
      return {
        ...state,
        urDecision: action.payload,
      };

    case UPDATE_STATUS_HISTORY_DATA:
      return {
        ...state,
        statusHistoryData: action.payload,
      };

    case RESET_STATUS_HISTORY_DATA:
      return {
        ...state,
        statusHistoryData: [],
      };

    case UPDATE_STATUS_LIST_DATA:
      return {
        ...state,
        statusListData: action.payload,
      };

    case RESET_STATUS_LIST_DATA:
      return {
        ...state,
        statusListData: [],
      };

    case UPDATE_UNPEND_DATA:
      return {
        ...state,
        unpendedData: action.payload,
      };

    case RESET_UNPEND_DATA:
      return {
        ...state,
        unpendedData: [],
      };

    case UPDATE_CLAIM_DATA:
      return {
        ...state,
        claim: action.payload,
      };
    
    default:
      return { ...state };
  }
};

const approveBillState = {
  reviewType: adjustersType,
  selectedAdjusterId: -1,
  selectedAdjusterName: '',
  groupBy: groupByBill,
  users: {
    [adjustersType]: [],
    [mcnsUsersType]: [],
    [nurseAuditsType]: [],
    [codeReviewsType]: [],
  },
  bills: [],
  aoInfo: [],
  selectedBills: [],
  correctionReasonList:[],
};

const approveBillReducer = (state = approveBillState, action) => {
  switch (action.type) {
    case UPDATE_APPROVE_BILL_TABLE:
      return {
        ...state,
        bills: action.payload,
      };
    case UPDATE_AOREMAINING_INFO_DATA:
      return {
        ...state,
        aoInfo: action.payload,
      };
    case UPDATE_APPROVE_BILL_INITIAL_DATA:
      return {
        ...state,
        users: action.payload.approveAdjusters,
        selectedAdjusterId: action.payload.selectedApproveBillAdjusterId,
        selectedAdjusterName: action.payload.selectedApproveBillAdjusterName,
      };
    case UPDATE_SELECTED_APPROVE_BILLS:
      return {
        ...state,
        selectedBills: action.payload,
      };
    case UPDATE_APPROVE_BILL_REVIEW_TYPE:
      return {
        ...state,
        reviewType: action.payload,
      };
    case UPDATE_APPROVE_BILL_SELECTED_ADJUSTER:
      return {
        ...state,
        selectedAdjusterId: action.payload.selectedAdjusterId,
        selectedAdjusterName: action.payload.selectedAdjusterName,
      };
    case UPDATE_APPROVE_BILL_GROUP_BY:
      return {
        ...state,
        groupBy: action.payload,
      };
    case UPDATE_CORRECTION_REASONS:
      return {
        ...state,
        correctionReasonList: action.payload,
      };
    case RESET_TASK_LIST:
      return {
        ...state,
        resetTaskList: action.payload,
      }  
    default:
      return { ...state };
  }
};

const qaBillDefaultState = {
  qaBills: [],
  masterClientDataProvider: [],
  selectedBills: [],
  groupBy: groupByBill,
};

const qaBillReducer = (state = qaBillDefaultState, action) => {
  switch (action.type) {
    case UPDATE_QA_BILL_TABLE:
      return { ...state, qaBills: action.payload };
    case UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER:
      return { ...state, masterClientDataProvider: action.payload };
    case UPDATE_SELECTED_QA_BILLS:
      return { ...state, selectedBills: action.payload };
    case UPDATE_QA_BILL_GROUP_BY:
      return { ...state, groupBy: action.payload };
    default:
      return { ...state };
  }
};

const recentActivityState = {
  bills: [],
  selectedAdjusterId: 0,
  selectedAdjusterName: '',
  adjusters: [],
};

const recentActivityReducer = (state = recentActivityState, action) => {
  switch (action.type) {
    case UPDATE_RECENT_ACTIVITY_INITIAL_DATA:
      return {
        ...state,
        selectedAdjusterId: action.payload.selectedRecentActivityAdjusterId,
        selectedAdjusterName: action.payload.selectedRecentActivityAdjusterName,
        adjusters: action.payload.recentActivityAdjusters,
      };
    case UPDATE_RECENT_ACTIVITY_TABLE:
      return {
        ...state,
        bills: action.payload,
      };
    case UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER:
      return {
        ...state,
        selectedAdjusterId: action.payload.selectedAdjusterId,
        selectedAdjusterName: action.payload.selectedAdjusterName,
      };
    default:
      return { ...state };
  }
};

const taskListGridSettingsState = {
  settingColumns: taskListGridSettingColumns,
};

const taskListGridSettingsReducer = (
  state = taskListGridSettingsState,
  action
) => {
  switch (action.type) {
    case LOAD_TASK_LIST_GRID_SETTINGS:
      if (action.payload.fields === undefined) {
        return {
          ...state,
        };
      }
      gridSettings_load = false
      const gridSettings = action.payload.fields.detailList;
      const settingsState = state.settingColumns;
      let checkboxflag = [];
      gridSettings.forEach((col, index) => {
        if (col.fields.columnName !== undefined) {
          const checkbox = R.find(
            value => value.columnName === col.fields.columnName,
            settingsState
          );
          const inx = settingsState.indexOf(checkbox);
          if (inx >= 0)
            settingsState.splice(inx, 1);
          if (checkbox) {
            checkbox.state = true;
            checkboxflag.push(checkbox);
          }
        }
      });
      settingsState.forEach((element, index) => {
        element.state = false;
        checkboxflag.push(element);
      });
      return {
        ...state,
        settingColumns: checkboxflag,
      };

    case UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX:
      return {
        ...state,
        settingColumns: action.payload,
      };
    default:
      const settingsState_default = state.settingColumns;
      if (gridSettings_load && (profile != '' && profile.toLowerCase() !== 'mba')) {
        settingsState_default[settingsState_default.length - 1].state = false
        settingsState_default[settingsState_default.length - 2].state = false
        return {
          ...state,
          settingColumns: [...settingsState_default]
        };
      }

      return { ...state };
  }
};

const referralDefaultState = {
  documents: [],
  emailTemplates: [],
  retrieveDocument: {},
  specialties: [],
};

const referralReducer = (state = referralDefaultState, action) => {
  switch (action.type) {
    case UPDATE_REFERRAL_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case RESET_REFERRAL_DOCUMENTS:
      return {
        ...state,
        documents: [],
      };
    case UPDATE_RETRIEVE_REFERRAL_DOCUMENT:
      return {
        ...state,
        retrieveDocument: action.payload,
      };
    case RESET_RETRIEVE_REFERRAL_DOCUMENT:
      return {
        ...state,
        retireveDocument: {},
      };
    case UPDATE_REFERRAL_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
      };
    case UPDATE_REFERRAL_SPECIALTIES:
      return {
        ...state,
        specialties: action.payload,
      };
    case RESET_REFERRAL:
      return {
        ...state,
        emailTemplates: [],
        specialties: [],
      };
    default:
      return {
        ...state,
      };
  }
};

const demandDefaultState = {
  demandDoc: [],
  retireveDemandDoc: {},
  selectedBills: [],
};

const demandReducer = (state = demandDefaultState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_DEMAND_BILLS:
      return {
        ...state,
        selectedBills: action.payload,
      };
    case UPDATE_DEMAND_DOCUMENTS:
      return {
        ...state,
        demandDoc: action.payload,
      };
    case UPDATE_RETRIVE_DEMAND_DOCUMENT:
      return {
        ...state,
        retireveDemandDoc: action.payload,
      };
    case RESET_RETRIVE_DEMAND_DOCUMENT:
      return {
        ...state,
        retireveDemandDoc: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const taskListReducer = combineReducers({
  approveBill: approveBillReducer,
  qaBill: qaBillReducer,
  recentActivity: recentActivityReducer,
  gridSettings: taskListGridSettingsReducer,
});

const topReducer = combineReducers({
  billReview: billReviewReducer,
  findBills: findBillsReducer,
  taskList: taskListReducer,
  referral: referralReducer,
  demand: demandReducer,
});

const reducer=(state,action)=>{

  if (action.type===RESET_STORE_STATE){
   return topReducer(undefined, {})
  }

  return topReducer(state,action)
}

export default reducer;
