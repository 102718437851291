import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  fast360Selectors,
  fast360Actions,
  fast360Operations
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { Button, Row, Col } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import HCPCDetailModal from './HCPCDetailModal';
import PopupModal from 'shared/components/PopupModal';
import Fast360DetailProvider from './Fast360DetailProvider';

let details;
let dosDeatils;
const Fast360ServiceDetails = ({
  disableSave,
  setDisableSave,
  audInfo,
  patientInfo,
  serviceIdFromRef,
  detailsForOrder,
  workListData,
  updateAudInfoTable,
  userId,
  getServiceIdFromReference,
  isFromLanding,
  deleteHCPCService,
  updateAudHcpcList,
  updateCostSavingList,
  loadAudiologyHcpcsList,
  loadCostSavings,
  logBreadCrumbs,
  isExternalReferral
}) => {
  const [openHCPCServiceDetailModal, setOpenHCPCServiceDetailModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [rowInfo, setRowInfo] = useState(undefined);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [openDetailProvider, setOpenDetailProvider] = useState(false);
  const [detailProviderInfo, setDetailProviderInfo] = useState({
    serviceType: '',
    serviceId: 0
  });
  const [serviceDetailId, setServiceDetailId] = useState(0);

  useEffect(() => {
    if (detailsForOrder?.module === 'MSE' && detailsForOrder?.detailList && detailsForOrder?.serviceId === patientInfo?.serviceId &&
      detailsForOrder?.copiedService === undefined) {
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updateAudHcpcList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    } else if (detailsForOrder?.module === 'MSE' && detailsForOrder?.detailList &&
      detailsForOrder?.serviceId === patientInfo?.serviceId && detailsForOrder?.copiedService === true) {
      const updatedDetailsForOrder = audInfo[patientInfo.module + patientInfo.serviceId]
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updatedDetailsForOrder || detailsForOrder?.detailList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    } else if ((detailsForOrder?.module === 'AUD' || detailsForOrder?.module === 'DGN') && detailsForOrder?.hcpcsList && detailsForOrder?.serviceId === patientInfo?.serviceId &&
      detailsForOrder?.copiedService === undefined) {
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updateAudHcpcList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    } else if ((detailsForOrder?.module === 'AUD' || detailsForOrder?.module === 'DGN') && detailsForOrder?.hcpcsList &&
      detailsForOrder?.serviceId === patientInfo?.serviceId && detailsForOrder?.copiedService === true) {
      const updatedDetailsForOrder = audInfo[patientInfo.module + patientInfo.serviceId]
      updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: updatedDetailsForOrder || detailsForOrder?.hcpcsList });
      details = updateCostSavingList;
      dosDeatils = detailsForOrder;
    }
  }, [updateCostSavingList, detailsForOrder])


  useEffect(() => {
    if (!isEmpty(detailsForOrder)) {
      const params = {
        functionName: 'getServiceIdFromReference',
        userId: userId,
        serviceId: detailsForOrder.serviceId,
        serviceType: detailsForOrder.module
      }
      getServiceIdFromReference(params);
      if (detailsForOrder.module != null && detailsForOrder.module != '') {
        setDetailProviderInfo({
          serviceType: detailsForOrder.module,
          serviceId: detailsForOrder.serviceId
        });
      }
    }
    if (!isEmpty(detailsForOrder) && (detailsForOrder?.newServiceId === undefined) && (detailsForOrder?.copiedService === undefined)) {
      const hcpcParams = {
        zebraUserId: userId,
        audiology_id: detailsForOrder?.serviceId,
        tableAbbrv: detailsForOrder?.module,
        transport_type: 0
      }
      loadAudiologyHcpcsList(hcpcParams);
      const costParams = {
        zebraUserId: userId,
        audiology_id: detailsForOrder?.serviceId,
        tableAbbrv: detailsForOrder?.module
      }
      setTimeout(() => {
        if(!isExternalReferral) {
          loadCostSavings(costParams);
        }
      }, 2000);
    }
  }, [detailsForOrder])

  const openHCPCService = (info = undefined) => {
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": openHCPCServiceDetailModal ? 'SAVEDETAILLINE' : 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    logBreadCrumbs(breadCrumb);
    setSelectedIndex('');
    setOpenHCPCServiceDetailModal(!openHCPCServiceDetailModal);
    setRowInfo(info)
  };

  const deleteHCPCServiceRow = () => {
    if (rowInfo === undefined || Object.keys(rowInfo).length <= 0) {
      alert('Please select atleast one row to remove');
      return false;
    }

    const delFunctions = {
      AUD: 'delAudiologyHcpc',
      DGN: 'delDiagHcpc',
      MSE: 'delMSEHcpc',
      SPT: 'delSPTLeg'
    }
    if (detailsForOrder && delFunctions[detailsForOrder.module] !== undefined) {
      let data = {
        id: rowInfo.id,
        functionName: delFunctions[detailsForOrder.module],
        userId: userId,
        referralId: detailsForOrder.referralId,
        mainMod: detailsForOrder.module
      }
      if (detailsForOrder.serviceId > 50) {
        deleteHCPCService(data);
      } else {
        audInfo[patientInfo.module + patientInfo.serviceId].splice(selectedIndex, 1);
      }
      setRowInfo();
      setSelectedIndex('');
    }
  };

  const onRowClick = (item, index) => {
    setSelectedIndex(index);
    setRowInfo({ ...item, index });
  }

  const onRowDoubleClick = (item, index) => {
    setSelectedIndex(index);
    openHCPCService({ ...item, index });
  }

  const daysBetween = (value, startDate, endDate) => {
    const firstDate = moment(startDate);
    const secondDate = moment(endDate);
    if (value === 'Day') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      return `${diffDays} Days`;
    } else if (value === 'Week') {
      let diffWeeks;
      diffWeeks = Math.round(
        Math.abs((firstDate - secondDate) / (7 * 24 * 60 * 60 * 1000))
      );
      return `${diffWeeks} Weeks`;
    } else if (value === 'Month') {
      let numberOfMonths;
      numberOfMonths = Math.floor(
        (secondDate - firstDate) / (1000 * 60 * 60 * 24 * 30)
      );
      return `${numberOfMonths} Months`;
    }
    return '';
  }

  const getBilledCharges = (item) => {
    if (item?.BilledCharges) {
      item.serviceIdFromRef = dosDeatils?.serviceTaskId;
      item.vendUnitCost = item?.vendDiscountedFrom === 'FS' ?
        item?.feeSchedule : item?.vendDiscountedFrom === 'UC' ?
          item?.ucSchedule : item?.vendDiscountedFrom === 'MCA' ?
            item?.medicareAllowable : item?.vendDiscountedFrom === 'OWCP' ?
              item?.owcpRate : item?.vendDiscountedFrom === 'MSRP' ? item.msrp : '';
      item.custUnitCost = item?.custDiscountedFrom === 'FS' ?
        item?.feeSchedule : item?.custDiscountedFrom === 'UC' ?
          item?.ucSchedule : item?.custDiscountedFrom === 'MCA' ?
            item?.medicareAllowable : item?.custDiscountedFrom === 'OWCP' ?
              item?.owcpRate : item?.custDiscountedFrom === 'MSRP' ? 
                item.msrp : item?.custDiscountedFrom === 'FLAT' ? 
                  item.clientFlatRate : '';
      return (`$${item?.BilledCharges || 0.00}`);
    } else {
      const chrgeList = details?.filter(i => i?.DetailId === item.id);
      const chrge = chrgeList && chrgeList[chrgeList?.length - 1];
      item.PercentAdjustment = chrge?.PercentAdjustment;
      item.rentalExt = item?.rentalExt;
      item.vendUnitCost = item?.vendDiscountedFrom === 'FS' ?
        item?.feeSchedule : item?.vendDiscountedFrom === 'UC' ?
          item?.ucSchedule : item?.vendDiscountedFrom === 'MCA' ?
            item?.medicareAllowable : item?.vendDiscountedFrom === 'OWCP' ?
              item?.owcpRate : item?.vendDiscountedFrom === 'MSRP' ? item.msrp : '';
      item.custUnitCost = item?.custDiscountedFrom === 'FS' ?
        item?.feeSchedule : item?.custDiscountedFrom === 'UC' ?
          item?.ucSchedule : item?.custDiscountedFrom === 'MCA' ?
            item?.medicareAllowable : item?.custDiscountedFrom === 'OWCP' ?
              item?.owcpRate : item?.custDiscountedFrom === 'MSRP' ? 
                item.msrp : item?.custDiscountedFrom === 'FLAT' ? 
                  item.clientFlatRate : '';
      item.DetailId = chrge?.DetailId || item?.id;
      item.fieldId = chrge?.fields?.id;
      item.billCharges = chrge?.BilledCharges || 0.00;
      item.serviceIdFromRef = dosDeatils?.serviceTaskId;
      //  item.startExtDate = item?.rental?.startExtDate;
      //  item.endExtDate = item?.rental?.endExtDate;
      item.duration = daysBetween(item?.rental?.rentalUnit, item?.startExtDate, item?.endExtDate);
      return (`$${chrge?.BilledCharges || 0.00}`);
    }
  }
  const getFormattedDate = d => {
    if (d) {
      return moment(d).format('MM/DD/YYYY')
    } else {
      return '';
    }
  }

  const getTotalAllowance = item => {
    if (item.TotalAllowance) {
      item.totAllow = item?.TotalAllowance || 0;
      return `$${item?.TotalAllowance || 0.00}`;
    } else {
      const chrgeList = details?.filter(i => i?.DetailId === item.id);
      const chrge = chrgeList && chrgeList[chrgeList?.length - 1];
      item.totAllow = chrge?.TotalAllowance || 0;
      return `$${(Number(item.custCost) === 0) ? 0 : (chrge?.TotalAllowance || 0.00)}`;
    }
  };

  const getMargin = (item) => {
    if (item.TotalAllowance) {
      let val;
      val = Number(((Number(item?.TotalAllowance) - Number(Number(item.vendCost) * Number(item.quantity))) / Number(item?.TotalAllowance)) * 100).toFixed(2);
      return `${isNaN(val) ? 0 : val}%`;
    } else {
      let val;
      const chrgeList = details?.filter(i => i?.DetailId === item.id);
      const chrge = chrgeList && chrgeList[chrgeList?.length - 1];
      if ((Number(item.custCost) === 0)) {
        val = Number(((Number(item?.custCost) - Number(Number(item.vendCost) * Number(item.quantity))) / Number(item?.custCost)) * 100).toFixed(2);
      } else {
        val = Number(((Number(chrge?.TotalAllowance) - Number(Number(item.vendCost) * Number(item.quantity))) / Number(chrge?.TotalAllowance)) * 100).toFixed(2);
      }
      return `${isNaN(val) ? 0 : (val === "-Infinity") ? 0 : val}%`;
    }
  };

  const getDollarAmount = (item, value) => {
    let val;
    if (value === 'modifier1') {
      return getRentalInfo(item);
    } else if (value === "feeSchedule") {
      return (val = `$${item.feeSchedule || 0.00}`);
    } else if (value === "ucSchedule") {
      return (val = `$${item.ucSchedule || 0.00}`);
    } else if (value === 'dos') {
      return (val = dosDeatils?.dos || patientInfo?.audZip || item.dos);
    } else if (value === 'serviceCode') {
      if (item.altDescription) {
        return (val = item.serviceCode + '-' + item.altDescription);
      } else {
        return (val = item.serviceCode + '-' + item.serviceDescription);
      }
    } else if (value === "medicareAllowable") {
      return (val = `$${item.medicareAllowable || 0.00}`);
    } else if (value === "owcpRate") {
      return (val = `$${item.owcpRate || 0.00}`);
    } else if (value === 'BilledCharges') {
      return getBilledCharges(item);
    } else if (value === "vendCost") {
      return (val = `$${item.vendCost || 0.00}`);
    } else if (value === "custCost") {
      return (val = `$${item.custCost || 0.00}`);
    } else if (value === "vendTotal") {
      return (val = `$${Number(Number(item.vendCost) * Number(item.quantity)).toFixed(2)}`);
    } else if (value === 'TotalAllowance') {
      return getTotalAllowance(item);
    } else if (value === 'vendPercentDiscount') {
      return (val = `${Number(Number(item.vendPercentDiscount === undefined ? 0 : item.vendPercentDiscount) * 100 || 0).toFixed(2)}%`);
    } else if (value === 'custPercentDiscount') {
      return (val = `${Number(Number(item.custPercentDiscount === undefined ? 0 : item.custPercentDiscount) * 100 || 0).toFixed(2)}%`);
    } else if (value === 'margin') {
      return getMargin(item)
    }
  };

  const detailProviderRender = (item) => {
    return (
      <>
        <input
          id={`${item.id}_checkbox`}
          type="checkbox"
          checked={dpIsChecked(item)}
          onChange={e => onDPCheckboxChangeHandler(e, item)}
        />
      </>);
  };

  const dpIsChecked = (item) => {
    return (item.detailProviderId > 0);
  }

  const onDPCheckboxChangeHandler = (e, item) => {
    setServiceDetailId(item.id);
  };

  useEffect(() => {
    if (serviceDetailId > 0) {
      setOpenDetailProvider(true);
    }
  }, [serviceDetailId]);

  const onCloseDetailProvider = () => {
    setServiceDetailId(0);
    setOpenDetailProvider(false);
  };

  const dateRowInfoFormat = data => {
    if (data) {
      let dos = data && data.split('-');
      return dos[1] + '/' + dos[2] + '/' + dos[0];
    }
    return;
  };

  const getRentalInfo = item => {
    if (item.modifier1 === 'Rental') {
      let val;
      val = 'Rental' + (item.rentalExt
        ? '-extenstion' + ' ' + dateRowInfoFormat(item.startExtDate) + ' - ' + dateRowInfoFormat(item.endExtDate)
        : ''
      );
      return val;
    } else {
      return item.modifier1;
    }
  };

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const getDeleteBody = () => {
    return (
      <div className="text-center">
        Are you sure you wish to delete Service Detail?
      </div>
    );
  };

  const getDeleteFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteHCPCServiceRow();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const renderModifier = (item) => {
    if (item.modifier1 === 'Rental') {
      return <div>{item.modifier1} <br /> <span >{getFormattedDate(item.startExtDate)} - {getFormattedDate(item.endExtDate)}</span></div>;
    } else {
      return item.modifier1;
    }

  }

  return (
    <div>
      <Row>
        <Col lg="2">
          <Button type="button" disabled={(isEmpty(detailsForOrder) || disableSave)} onClick={() => openHCPCService()}>
            <FontAwesomeIcon icon={faPlus}  />
          </Button>

          <Button color="danger" type="button" onClick={confirmationDeleteToggle} disabled={disableSave}> 
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <AppTable
            resizable
            columns={[
              {
                header: 'Date Of Service',
                field: 'dos',
                render: item => getDollarAmount(item, 'dos')
              },
              {
                header: 'Modifier',
                field: 'modifier1',
                render: item => renderModifier(item)
              },
              {
                header: '2nd Modifier',
                field: 'modifier2'
              },
              {
                header: 'HCPC',
                field: 'serviceCode',
                render: item => getDollarAmount(item, 'serviceCode')
              },
              {
                header: 'Rev. Code',
                field: 'refCodeDesc'
              },
              {
                header: 'FS',
                field: 'feeSchedule',
                render: item => getDollarAmount(item, "feeSchedule"),
              },
              {
                header: 'UC',
                field: 'ucSchedule',
                render: item => getDollarAmount(item, 'ucSchedule'),
              },
              {
                header: 'MCA',
                field: 'medicareAllowable',
                render: item => getDollarAmount(item, 'medicareAllowable'),
              },
              {
                header: 'OWCP',
                field: 'owcpRate',
                render: item => getDollarAmount(item, 'owcpRate'),
              },
              {
                header: 'Billed Charges',
                field: 'BilledCharges',
                render: item => getDollarAmount(item, 'BilledCharges'),
              },
              {
                header: 'Qty',
                field: 'quantity'
              },
              {
                header: 'Vend. Unit Cost',
                field: 'vendCost',
                render: item => getDollarAmount(item, 'vendCost'),
              },
              {
                header: 'Vend. Disc From',
                field: 'vendDiscountedFrom'
              },
              {
                header: 'Vend. Disc %',
                field: 'vendPercentDiscount',
                render: item => getDollarAmount(item, 'vendPercentDiscount'),
              },
              {
                header: 'Cust. Unit Cost',
                field: 'custCost',
                render: item => getDollarAmount(item, 'custCost'),
              },
              {
                header: 'Cust. Disc From',
                field: 'custDiscountedFrom'
              },
              {
                header: 'Cust. Disc %',
                field: 'custPercentDiscount',
                render: item => getDollarAmount(item, 'custPercentDiscount'),
              },
              {
                header: 'Vend. Total',
                field: 'vendTotal',
                render: item => getDollarAmount(item, 'vendTotal'),
              },
              {
                header: 'Cust. Total',
                field: 'TotalAllowance',
                render: item => getDollarAmount(item, 'TotalAllowance'),
              },
              {
                header: 'Margin',
                render: item => getDollarAmount(item, 'margin'),
              },
              {
                header: '',
                headerWidth: '25 px',
                field: '',
                render: item => detailProviderRender(item),
              },
            ]}
            data={audInfo && patientInfo && audInfo[patientInfo.module + patientInfo.serviceId] ? audInfo[patientInfo.module + patientInfo.serviceId] : []}
            rowClickHandler={onRowClick}
            rowDoubleClickHandler={onRowDoubleClick}
            autoPagination={true}
          // sortAble={true}
          />

        </Col >
      </Row>
      <HCPCDetailModal
        disableSave={disableSave}
        isOpen={openHCPCServiceDetailModal}
        onClose={openHCPCService}
        rowInfo={rowInfo}
        serviceIdFromRef={detailsForOrder?.serviceTaskId}
        serviceId={detailsForOrder?.serviceId}
        referralId={detailsForOrder?.referralId}
        module={detailsForOrder?.module}
        isFromLanding={isFromLanding}
        workListData={workListData}
        newServiceId={detailsForOrder?.newServiceId}
        copiedService={detailsForOrder?.copiedService}
        vendorId={detailsForOrder?.vendor?.id}
      />
      <Fast360DetailProvider
        disableSave={disableSave}
        isOpen={openDetailProvider}
        service_type={detailProviderInfo.serviceType}
        service_detail_id={serviceDetailId}
        service_id={detailProviderInfo.serviceId}
        onClose={() => onCloseDetailProvider()}
      />

      <PopupModal
        content={getDeleteBody()}
        title="Confirmation"
        externalToggle={confirmationDeleteToggle}
        isOpen={isConfirmDeleteModalOpen}
        footerContent={getDeleteFooter()}
        size="md"
      />
    </div>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  return {
    audInfo: fast360Info.audInfo,
    userId: user.id,
    patientInfo: fast360Info.patientZip,
    serviceIdFromRef: fast360Info.serviceIdFromRef,
    updateAudHcpcList: fast360Info.updateAudHcpcList,
    updateCostSavingList: fast360Info.updateCostSavingList
  }
}

const mapDispatchToProps = dispatch => ({
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  getServiceIdFromReference: data => dispatch(fast360Operations.getServiceIdFromReference(data)),
  loadAudiologyHcpcsList: data => dispatch(fast360Operations.loadAudiologyHcpcsList(data)),
  loadCostSavings: data => dispatch(fast360Operations.loadCostSavings(data)),
  loadFromWorkList: data => dispatch(fast360Operations.loadFromWorkList(data)),
  deleteHCPCService: data => dispatch(fast360Operations.deleteHCPCService(data)),
  updateAudInfoTable: data => dispatch(fast360Actions.updateAudInfoTable(data)),
  getDetailProvider: data => dispatch(fast360Operations.getDetailProvider(data)),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360ServiceDetails);
