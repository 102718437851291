import React, { Component } from "react";
import { AppTableBodyPropType } from "./AppTablePropTypes";
import ExternalReferralTableBodyRow from "./ExternalReferralTableBodyRow.js";


class ExternalReferralTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
        };
    }


    setSelectedRecords = (data, checked) => {
        let fIndex = this.props.updatedRec.findIndex(item => item.confNo === data.confNo);
        let iData = this.props.initialWorkList;
        let rec = iData.map(item => {
            if (item.confNo === data.confNo) {
                item.selected = checked;
            }
            return item
        });
        this.props.updateUsersWorkList(rec);

        if (fIndex === -1) {
            let localRec = this.props.updatedRec;
            localRec.push(data);
            this.props.updateClickRecord(localRec)
        }
        else {
            let localRec = this.props.updatedRec;
            localRec.splice(fIndex, 1);
            this.props.updateClickRecord(localRec)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRows: [] });
    }

    render() {
        const isClickable = !!this.props.clickHandler;
        const isSelectable = !!this.props.selectHandler;
        const isRowClickable = !!this.props.rowClickHandler;
        const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;



        return (
            <>
                {
                    this.props.data && this.props.data.map((dataObject, rowIndex) => {
                        const returnData = !!dataObject.fields ? dataObject.fields : dataObject;
                        return (

                            <ExternalReferralTableBodyRow rowIndex={rowIndex}
                                highlightedRow={this.state.highlightedRow} isClickable={isClickable} isSelectable={isSelectable}
                                isRowClickable={isRowClickable} isRowDoubleClickable={isRowDoubleClickable} {...this.props}
                                returnData={returnData} dataObject={dataObject}
                            />

                        )
                    })
                }


            </>
        )
    }
}

ExternalReferralTableBody.propTypes = AppTableBodyPropType;


export default ExternalReferralTableBody
