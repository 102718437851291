import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store/index';
import { authSelectors } from '../../auth/store';
import { Button, Input, Row, Col } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import moment from '../../../../node_modules/moment/moment';
import PopupModal from 'shared/components/PopupModal';


import Fast360PayableLinesModal from './Fast360PayableLinesModal';


const Fast360PayableLines = ({ currentUserId, payableLinesList, loadPayableLines, requiredServiceData, deletePayableLines, updatePayableLines, }) => {

  const [showPayableLinesModal, setShowPayableLinesModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
  const [payableLines, setPayableLines] = useState([]);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);


  useEffect(() => {
    return () => {
      updatePayableLines([]);
    }
  }, [])

  useEffect(() => {
    if (requiredServiceData && Object.keys(requiredServiceData) && Object.keys(requiredServiceData).length &&
    Object.keys(requiredServiceData).length !== 0) {
      getPayableLines();
    }
    
   
  }, [requiredServiceData])

  useEffect(() => {
    const updatedPayableLines = [...payableLines];
    if(selectedDataIndex >= 0) {

      updatedPayableLines.map((i, index) => {
        if(index === selectedDataIndex) {
          return i.selected = true;
        }
        return i.selected = false;
      });
      setSelectedData(updatedPayableLines[selectedDataIndex]);
    } else {
      updatedPayableLines.map((i, index) => {
        return i.selected = false;
      });
      setSelectedData(null);
    }

    setPayableLines(updatedPayableLines);
  }, [selectedDataIndex])

  useEffect(() => {
    //All data structure from payableLines Object for reference
    //   {
    //     "bol_active": "Y",
    //     "notes": "this is test",
    //     "venInvApprDte": "09/20/2021",
    //     "decision": "Approved",
    //     "vendor_billed_amt": 25.0,
    //     "PK_service_id": 60537,
    //     "modifier": "test Modifier11",
    //     "recdChangDte": "09/23/2021",
    //     "DOS": "Sep 20, 2021, 6:24:12 AM",
    //     "vendor_name": "VName999999",
    //     "PK_accounts_payable_id": 9,
    //     "item_name": "testItem",
    //     "tax_id": "11",
    //     "chr_vendor_invoice_num": "101",
    //     "reconsideration_amt": 25.0,
    //     "dbl_vendor_invoice_amt": 200.0,
    //     "qty": 2,
    //     "apprvd_amt_paid": 25.0,
    //     "vendor_cntrct_per_unit": 12.5,
    //     "total_vendor_cntrct_amt": 25.0,
    //     "venInvRcvdDte": "09/20/2021",
    //     "crtDte": "09/21/2021",
    //     "invDate": "09/17/2021",
    //     "reconsideration_decision": "Approved"
    // }
    if (payableLinesList && payableLinesList.length > 0) {
      const loadedPayableLines = payableLinesList.map(item => ({
        apId: item.PK_accounts_payable_id,
        pkServiceId: item.PK_service_id,
        dos: new Date(item.dos),
        qty: item.qty,
        modifier: item.modifier,
        itemName: item.item_name,
        vendorName: item.vendor_name,
        taxId: item.tax_id,
        vendInvNo: item.chr_vendor_invoice_num,
        vendorInvDate: item.invDate ? new Date(item.invDate) : "",
        vendorCntrctPerUnit: item.vendor_cntrct_per_unit,
        totalVendorCntrctAmt: item.total_vendor_cntrct_amt,
        vendorInvRcvdDate: new Date(item.venInvRcvdDte),
        vendorInvApprvDate: new Date(item.venInvApprDte),
        vendorBilledAmt: item.vendor_billed_amt,
        apprvdAmtPaid: item.apprvd_amt_paid,
        vendorInvDecision: item.vendor_inv_decision,
        notes: item.notes,
        reconsiderationDecision: item.reconsideration_decision,
        reconsiderationAmt: item.reconsideration_amt,
        hcpcDtlSubsrvcId: item.FK_hcpc_dtl_subsrvc_id,
        selected: false,
        reason: item.reason,
        reasonId: item.reasonId
      }));
      setPayableLines(loadedPayableLines);
    } else {
      setPayableLines([]);
    }
  }, [payableLinesList])

  const getPayableLines = () => {
     let refId =  requiredServiceData.referralId;
     let serviceId = requiredServiceData.serviceId;
     let module =  requiredServiceData.module;
    // const refId =   requiredServiceData && requiredServiceData.referralId ;
    // const serviceId =  requiredServiceData && requiredServiceData.serviceId; 
    // const module =   requiredServiceData && requiredServiceData.module;
    // Real request data

    if(requiredServiceData && requiredServiceData.selectedService) {
      refId =  requiredServiceData.selectedService.referralId;
      serviceId = requiredServiceData.selectedService.serviceId;
      module =  requiredServiceData.selectedService.module;
    }

    const req = {
      functionName:"getPayableLines",
      zebraUserId: currentUserId,
      referralId:  refId,
      serviceId: serviceId,
      referenceTable: module
  }



  setTimeout(() => {
    loadPayableLines(req);
  }, [3000])
  }


  const renderItemSelected = (item, index) => {
    return (
      <>
        <input
          id={`${item.id}_checkbox`}
          type="checkbox"
          checked={isChecked(item)}
          onChange={e => onCheckboxChangeHandler(item, index)}
        />
      </>);
  };


  const getDeleteFooter = () => {
    return (
      <>
        {!selectedData &&  
          <Button color="secondary" onClick={confirmationDeleteToggle}>
          OK
        </Button>
        }
        { selectedData  && <> <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteRowHandler();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button> </> }
      </>
    );
  };
  const getDeleteBody = () => {
    return (
      <div className="text-center">
       { selectedData ? 'Are you sure you wish to delete Item?' : 'Please select the item first!' } 
      </div>
    );
  };


  const isChecked = (item) => {
    return item.selected;
    
  }

  const onCheckboxChangeHandler = (item, index) => {
    if(!item.selected) {
      setSelectedDataIndex(index);
    } else {
      setSelectedDataIndex(-1)
    }
  }

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const deleteRowHandler = () => {
    const req = {
      functionName: "deletePayableLines",
      zebraUserId: currentUserId,
      apId: selectedData.apId,
      referralId:  requiredServiceData.referralId,
      serviceId: requiredServiceData.serviceId,
      referenceTable: requiredServiceData.module
    }
    setSelectedDataIndex(-1);
    deletePayableLines(req);
  } 

  const confIdHandler = (item) => {
    return requiredServiceData.referralId + '-' + requiredServiceData.module + '-' + requiredServiceData.serviceId
  }



  return <>
    {/* <Row>
        <Col lg="12">
            <div style={ {textAlign: 'center', backgroundColor: 'rgb(80, 93, 120)',  padding: '5px', marginBottom: '5px'}}>
                <h6 style={{color: 'rgb(255, 255, 255)'}}> PAYABLE LINE(S)</h6>
            </div>
         </Col>
    </Row> */}
    <Row>
      <Col lg="2">
        <Button type="button" onClick={() => { setShowPayableLinesModal(true) }}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>

        <Button color="danger" type="button" onClick={confirmationDeleteToggle}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
      </Col>
    </Row>
    <Row>
      <Col lg="12">
        <AppTable
          style={{ height: 'auto' }}
          resizable
          columns={[
            {
              header: 'Confirmation Number ',
              field: requiredServiceData?.referralId + '-' + requiredServiceData?.module + '-' + requiredServiceData?.serviceId,
              render: item => confIdHandler(item)
            },
            {
              header: 'Date of Service',
              field: 'dos',
              render: item => item.dos && moment(item.dos).format('MM/DD/YYYY')
            },
            {
              header: 'Quantity',
              field: 'qty',
              render: item => item.qty + ''
            },
            {
              header: 'Modifier',
              field: 'modifier',
            },
            {
              header: 'Item Name',
              field: 'itemName',

            },
            {
              header: 'Vendor Name',
              field: 'vendorName',

            },
            {
              header: 'Tax ID',
              field: 'taxId',

            },
            {
              header: 'Vendor Invoice Number',
              field: 'vendInvNo',
              
            },
            {
              header: 'Vendor Invoice Date',
              field: 'vendorInvDate',
              render: item => item.vendorInvDate && moment(item.vendorInvDate).format('MM/DD/YYYY')
            },
            {
              header: 'Vendor Contract Per Unit',
              field: 'vendorCntrctPerUnit',
              render: item => item.vendorCntrctPerUnit && `$${item.vendorCntrctPerUnit}`

            },
            {
              header: 'Total Vendor Contract Amount',
              field: 'totalVendorCntrctAmt',
              render: item => item.totalVendorCntrctAmt && `$${item.totalVendorCntrctAmt}`
            },
            {
              header: 'Vendor Invoice Received Date',
              field: 'vendorInvRcvdDate',
              render: item => item.vendorInvRcvdDate && moment(item.vendorInvRcvdDate).format('MM/DD/YYYY')

            },
            {
              header: 'Vendor Invoice Approve Date',
              field: 'vendorInvApprvDate',
              render: item => item.vendorInvApprvDate && moment(item.vendorInvApprvDate).format('MM/DD/YYYY')

            },
            {
              header: 'Vendor Billed Amount',
              field: 'vendorBilledAmt',
              render: item => item.vendorBilledAmt && <div><span style={item.vendorBilledAmt > item.totalVendorCntrctAmt ? { background: 'red', padding: '0 5px' }: null}>${item.vendorBilledAmt}</span></div>
            },
            {
              header: 'Approved Amount Paid',
              field: 'apprvdAmtPaid',
              render: item => item.apprvdAmtPaid && `$${item.apprvdAmtPaid}`

            },
            {
              header: 'Decision',
              field: 'vendorInvDecision',
              // render: item => <div>{item.vendorInvDecision} <br /> Paid per Participating Provider Agreement </div>
              render: item => <div>{item.vendorInvDecision} <br /> {item.reason} </div>
            },
            {
              header: 'Notes',
              field: 'notes',
            },
            {
              header: 'Reconsideration Decision',
              field: 'reconsiderationDecision',
            },
            {
              header: 'Reconsideration Amount',
              field: 'reconsiderationAmt',
              render: item => item.reconsiderationAmt && `$${item.reconsiderationAmt}`
            },
            {
              header: '',
              headerWidth: '25 px',
              field: '',
              render: (item, index) => renderItemSelected(item, index),
            }
          ]}
          data={payableLines || []}
          rowDoubleClickHandler={(item) => { setSelectedData(item); setShowPayableLinesModal(true) }}
          rowClickHandler={() => { }}
        />
      </Col>
    </Row>

    {showPayableLinesModal &&

      <Fast360PayableLinesModal
        isOpen={showPayableLinesModal}
        data={selectedData}
        onSave={getPayableLines}
        payableLines={payableLines}
        onClose={() => { setShowPayableLinesModal(false); setSelectedData(null); }}
      />

    }

    <PopupModal
            content={getDeleteBody()}
            title="Confirmation"
            externalToggle={confirmationDeleteToggle}
            isOpen={isConfirmDeleteModalOpen}
            footerContent={getDeleteFooter()}
            size="md"
          />
  </>
}


const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  return {
    userId: user.id,
    currentUserId: authSelectors.getUserId(state),
    currentWorkList: state.FAST360.fast360Reducer.currentWorkListItem,
    payableLinesList: state.FAST360.fast360Reducer.payableLinesList,
    lmsUserId: state.FAST360.initialData.roleUsers
    ? state.FAST360.initialData.roleUsers.LmsUserId
    : '',
    requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadPayableLines: data => dispatch(fast360Operations.loadPayableLines(data)),
    deletePayableLines: data => dispatch(fast360Operations.deletePayableLines(data)),
    updatePayableLines: data => dispatch(fast360Actions.updatePayableLines(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fast360PayableLines);