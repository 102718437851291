import actions from './actions';
import constants from './constants';

const addAlert = (message, level = constants.DEFAULT_ALERT_LEVEL) => {
  return dispatch => {
    const id = new Date(Date.now()).getTime();
    dispatch(actions.addAlert(id, message, level));
    setTimeout(
      () => dispatch(actions.clearAlert(id)),
      constants.DEFAULT_ALERT_TIMEOUT
    );
  };
};

export default { addAlert };
