import * as R from 'ramda';
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

const Fast360VendorEvaluateModal = ({
  evaluateVendorData,
  isOpen,
  onClose,
  onHide,
}) => {
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader toggle={onClose}>Vendor Lookup</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Col lg={{ size: 3, offset: 9 }} align="right">
              <Button type="button" onClick={() => onHide()}>
                Vendor Assignment
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <AppTable
          columns={[
            {
              header: 'CPT Range',
              field: 'CPTRange',
              percent: '16',
            },
            {
              header: 'Disc Description',
              field: 'DiscDesc',
              percent: '16',
            },
            {
              header: 'Discounted From',
              field: 'DiscountedFrom',
              percent: '16',
            },
            {
              header: 'Fee Type',
              field: 'FeeType',
              percent: '16',
            },
            {
              header: 'Modifier',
              field: 'Modif',
              percent: '16',
            },
            {
              header: 'Fee Amount',
              field: 'FeeAmount',
              percent: '16',
            },
            {
              header: '',
              field: 'Percent',
              percent: '16',
            },
          ]}
          data={evaluateVendorData}
          autoPagination
          sortAble
          resizable
          sortImmediately={false}
          key="vendor-evaluate"
        />
        <Row>
          <Col align="right">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Fast360VendorEvaluateModal;
