import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { commonActions } from 'common/store';
import appModules, { EXPERT_MODULE } from 'common/appModules';

import GroupContainer from '../expert-info/components/GroupContainer';
import IndividualExpertContainer from '../expert-info/components/IndividualExpertContainer';

import './assets/static/expert.scss';
import ExpertPage from './components/ExpertPage';

class Experts extends Component {
  componentDidMount() {
    this.props.switchModule(appModules.getModule(EXPERT_MODULE));
  }

  render() {
    return (
      <Switch>
        <Route exact path="/experts" component={ExpertPage} />
        <Route
          exact
          path="/experts/info/group/:id"
          component={GroupContainer}
        />
        <Route
          exact
          path="/experts/info/user/:id"
          component={IndividualExpertContainer}
        />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  switchModule: module => dispatch(commonActions.switchModule(module)),
});

export default connect(null, mapDispatchToProps)(Experts);
