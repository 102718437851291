import { createSelector } from 'reselect';
import constants from './constants';

const getModule = state => state[constants.MODULE_NAME];

const getInitialData = createSelector(getModule, state => state.initialData);
const getTasks = createSelector(getModule, state => state.tasks);
const getFaxes = createSelector(getModule, state => state.faxes);
const getRadiusSearch = createSelector(getModule, state => state.radiusSearch);
const getReport = createSelector(getModule, state => state.report);

const getPreview = createSelector(getModule, state => state.preview);

const getNewExpertGroup = createSelector(getModule, state => state.newExpertGroup);

const getExpertInfoModule = createSelector(getModule, state => state[constants.EXPERT_INFO_MODULE]);

const getNewExpert = createSelector(getModule, state => state.newExpert);

export default {
    getTasks,
    getFaxes,
    getRadiusSearch,
    getPreview,
    getInitialData,
    getReport,
    getNewExpertGroup,
    getModule,
    getExpertInfoModule,
    getNewExpert
}
