import * as R from 'ramda';
import React, { useState, useEffect, Component } from 'react';
import { ServiceExecutor } from 'core/services/serviceExecutor';
import * as TypeUtils from 'shared/utils/typeUtils';
import { commonActions } from 'common/store';
import billOperationsService from '../services/billOperationsService';
import billReviewService from '../../bill-review/services/billReviewService';
import actions from './actions';

import { authActions } from '../../auth/store';

import {
  adjustersType,
  findBillsGridSettingName,
  taskListGridSettingModuleId,
  taskListGridSettingName,
} from '../utils/constantUtils';

const findApproveBills = (userId, name, profile, adjuster, reviewType) => {
  return async dispatch => {
    dispatch(actions.updateApproveBillTable([]));
    dispatch(actions.updateSelectedApproveBills([]));
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.findApproveBills(
          userId,
          name,
          profile,
          adjuster,
          reviewType
        )
      )
      .withResponseHandler(data => {
        dispatch(actions.updateApproveBillTable(data));
      })
      .withFailureAlert('An error occurred while loading the bill list.')
      .build()
      .execute();
  };
};

const getTaskListInitialData = ({ userId, slpUserId, profile }) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.getTaskListInitialData({
          userId,
          slpUserId,
          profile,
        })
      )
      .withResponseHandler(data => {
        dispatch(actions.updateApproveBillInitialData(data));
        dispatch(actions.updateRecentActivityInitialData(data));
        //dispatch(
        //  findApproveBills(userId, slpUserId, profile, slpUserId, adjustersType)
        //);
      })
      .withFailureAlert('An error occurred while loading initial data.')
      .build()
      .execute();
  };
};

const approveBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.approveBills(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully approved bills.')
      .withFailureAlert('An error occurred while approving bills.')
      .build()
      .execute();
  };
};

const denyBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.denyBills(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully denied bills.')
      .withFailureAlert('An error occurred while denying bills.')
      .build()
      .execute();
  };
};

const pendBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.pendBills(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully pended bills.')
      .withFailureAlert('An error occurred while pending bills.')
      .build()
      .execute();
  };
};

const pendBillsWorklist = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.pendBillsWorklist(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully pended bills.')
      .withFailureAlert('An error occurred while pending bills.')
      .build()
      .execute();
  };
};

const unpendCodeOnBill = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.unpendCodeOnBill(data))

      .withSuccessAlert(
        data.currentUserId +
        ' ' +
        data.name +
        ' ' +
        data.profile +
        ' ' +
        data.selectedAdjusterId +
        ' ' +
        data.reviewType
      )
      .withFailureAlert('An error occurred while unpending bill.')
      .build()
      .execute();
  };
};

const unpendCodeOnBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.unpendCodeOnBills(data))
      .withResponseHandler(() => dispatch(actions.resetTaskList(true)))
      .withSuccessAlert(
        data.currentUserId +
        ' ' +
        data.name +
        ' ' +
        data.profile +
        ' ' +
        data.selectedAdjusterId +
        ' ' +
        data.reviewType
      )
      .withFailureAlert('An error occurred while unpending bill.')
      .build()
      .execute();
  };
};

const correctBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.correctBills(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully corrected bills.')
      .withFailureAlert('An error occurred while correcting bills.')
      .build()
      .execute();
  };
};

const loadCorrectionReasons = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.loadCorrectionReasons(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateCorrectionReasons(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading correction reasons.'
      )
      .build()
      .execute();
  };
};

const modifyBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.modifyBills(data))
      .withResponseHandler(() => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Successfully modified bills.')
      .withFailureAlert('An error occurred while modifying bills.')
      .build()
      .execute();
  };
};

const findClaims = (data, taskListType = false) => {
  return dispatch => {
    if (taskListType) {
      dispatch(actions.updateClaimFromTaskList([]));
    } else {
      dispatch(actions.updateFindBillsTable([]));
    }
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return billOperationsService.findClaims(data);
      })
      .withResponseHandler(response => {
        if (taskListType) {
          dispatch(actions.updateClaimFromTaskList(response));
        } else {
          dispatch(actions.updateFindBillsTable(response));
        }
      })
      .withFailureAlert('An error occurred while searching for claims.')
      .build()
      .execute();
  };
};

const getClaim = data => {
  return dispatch => {
    dispatch(actions.updateClaimData({}));
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return billOperationsService.findClaims(data);
      })
      .withResponseHandler(response => {
        dispatch(
          actions.updateClaimData(response.length > 0 ? response[0] : {})
        );
      })
      .withFailureAlert('No Claim found for Bill')
      .build()
      .execute();
  };
};

const findBills = (data, claimType = undefined) => {
  return dispatch => {
    dispatch(actions.updateClaimData({}));
    dispatch(actions.updateSelectedBillsFromClaim([]));
    if (claimType && claimType === 'billsFromClaim') {
      dispatch(actions.updateBillsFromClaim([]));
    } else if (claimType && claimType === 'billsFromTaskList') {
      dispatch(actions.updateBillsFromTaskListClaim([]));
    } else {
      dispatch(actions.updateFindBillsTable([]));
    }

    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return billOperationsService.findBills(data);
      })
      .withResponseHandler(response => {
        if (claimType && claimType === 'billsFromClaim') {
          dispatch(actions.updateSearchFields(data));
          dispatch(actions.updateBillsFromClaim(response));
        } else if (claimType && claimType === 'billsFromTaskList') {
          dispatch(actions.updateBillsFromTaskListClaim(response));
        } else {
          dispatch(actions.updateFindBillsTable(response));
        }
      })
      .withFailureAlert('An error occurred while searching for bills.')
      .build()
      .execute();
  };
};

const getURDecision = claim => {
  return dispatch => {
    dispatch(actions.updateURDecisionData([]));
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return billOperationsService.getURDecision(claim);
      })
      .withResponseHandler(response => {
        dispatch(actions.updateURDecisionData(response));
      })
      .withFailureAlert(
        'An error occurred while searching for UR Decision data.'
      )
      .build()
      .execute();
  };
};

const loadBillReviewData = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return billOperationsService.getBillReviewData(userId);
      })
      .withResponseHandler(response => {
        dispatch(actions.loadBillReviewData(response));
        dispatch(
          getTaskListInitialData({
            userId,
            slpUserId: response.slpUserId,
            profile: response.currentProfile,
          })
        );
        dispatch(
          getGridSettings({
            gridName: taskListGridSettingName,
            userId,
            moduleId: taskListGridSettingModuleId,
            profile: response.currentProfile,
            zebraUserId: userId,
          })
        );
      })
      .withFailureAlert(
        'An error occurred while loading the Bill Review module data.'
      )
      .build()
      .execute();
  };
};

const transferBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.transferBills(data))
      .withResponseHandler(responseData => {
        dispatch(
          findApproveBills(
            data.currentUserId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
      })
      .withSuccessAlert('Transferred successfully')
      .withFailureHandler(responseData => {
        if (responseData !== 'true') {
          var msg = responseData.message;
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      //.withFailureAlert(responseData => responseData.message)
      .build()
      .execute();
  };
};

const getEDISetupData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getEDISetupData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSendEDIData(responseData));
      })
      .withFailureAlert('An error occurred while finding edi setup data.')
      .build()
      .execute();
  };
};

const getOdgLookupData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getOdgLookupData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateOdgLookupData(responseData));
      })
      .withFailureAlert('An error occurred while finding odg lookup data.')
      .build()
      .execute();
  };
};

const getBillLinesData = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getBillLinesData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateBillLinesData(responseData));
      })
      .withFailureAlert('An error occurred while finding bill lines data.')
      .build()
      .execute();
  };
};

const getBillLinesDataMcns = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getBillLinesData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateBillLinesData(responseData));
        dispatch(actions.updateBillTotalData(responseData.billTotal));
      })
      .withFailureAlert('An error occurred while finding bill lines data.')
      .build()
      .execute();
  };
};

const getBillTotalData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getBillLinesData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateBillTotalData(responseData.billTotal));
      })
      .withFailureAlert(
        'An error occurred while finding bill lines Total data.'
      )
      .build()
      .execute();
  };
};

const loadAORemaining = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadAORemaining(data))
      .withResponseHandler(response => {
        dispatch(actions.updateAORemainingTable(response));
      })
      .build()
      .execute();
  };
};

const getBillDetailDropdownData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.getBillDetailDropdownData(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateBillDetailDropdownData(responseData));
      })
      .withFailureAlert('An error occurred while finding bill lines deny data.')
      .build()
      .execute();
  };
};

const postBillLinesData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.postBillLinesData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePostBillLinesData(responseData));
      })
      .withFailureAlert('An error occurred while posting bill lines  data.')
      .build()
      .execute();
  };
};
const postMcnsBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.postMcnsBills(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatepostMcnsBills(responseData));
        dispatch(actions.setMcnsEditVal({}));
      })
      .withFailureAlert('An error occurred while posting bill lines  data.')
      .build()
      .execute();
  };
};

const postCodeReviewBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.postCodeReviewBills(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatepostMcnsBills(responseData));
        dispatch(actions.setMcnsEditVal({}));
        dispatch(
          findApproveBills(
            data.userId,
            data.name,
            data.profile,
            data.selectedAdjusterId,
            data.reviewType
          )
        );
        console.log("returned");
      })
      .withFailureAlert('An error occurred while posting bill lines  data.')
      .build()
      .execute();
  };
};

const postBillReviewData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.postBillReviewData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePostBillReviewData(responseData));
      })
      .withFailureAlert('An error occurred while Submitting bill review  data.')
      .build()
      .execute();
  };
};
const postBillReviewAoData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.postBillReviewAoData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePostBillReviewAoData(responseData));
      })
      .withFailureAlert('An error occurred while Submitting bill review  data.')
      .build()
      .execute();
  };
};

const getPrintEORDropdownUserData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.getPrintEORDropdownUserData(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updatePrintEORDropdownUserData(responseData));
      })
      .withFailureAlert('An error occurred while finding EOR User data.')
      .build()
      .execute();
  };
};

const getPrintEORData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getPrintEORData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePrintEORData(responseData));
      })
      .withFailureAlert('An error occurred while finding Print EOR data.')
      .build()
      .execute();
  };
};

const getPrintReportsData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getPrintReportsData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePrintReportsData(responseData));
      })
      .withFailureAlert(
        'An error occurred while retrieving Print Reports data.'
      )
      .build()
      .execute();
  };
};

const getClaimList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getClaimList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClaimList(responseData));
      })
      .withFailureAlert('An error occurred while retrieving Claim list.')
      .build()
      .execute();
  };
};

const getEmployerList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getEmployerList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateEmployerList(responseData));
      })
      .withFailureAlert('An error occurred while retrieving Employer list.')
      .build()
      .execute();
  };
};

const getProviderList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getProviderList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateProviderList(responseData));
      })
      .withFailureAlert('An error occurred while retrieving Provider list.')
      .build()
      .execute();
  };
};

const getClaimantList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getClaimantList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClaimantList(responseData));
      })
      .withFailureAlert('An error occurred while retrieving Claimant list.')
      .build()
      .execute();
  };
};

const getOdgLookupDataByBill = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getOdgLookupDataByBill(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateOdgLookupData(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading odg lookup data for this bill.'
      )
      .build()
      .execute();
  };
};

const savePaycode = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.savePaycode(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSavePayCodeData(responseData));
      })
      .withFailureAlert('An error occurred while saving paycode.')
      .build()
      .execute();
  };
};

const sendProfileDrodownData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.sendProfileDrodownData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateProfileDrodownData(responseData));
      })
      .withFailureAlert('An error occurred while saving profile.')
      .build()
      .execute();
  };
};

const getSearchPPOData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getSearchPPOData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchPPOData(responseData));
      })
      .withFailureAlert('An error occurred while finding search ppo data.')
      .build()
      .execute();
  };
};

const getStartDateData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getStartDateData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateStartDateData(responseData));
      })
      .withFailureAlert('An error occurred while finding start date data.')
      .build()
      .execute();
  };
};

const postAddManageBillData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.postAddManageBillData(data.params)
      )
      .withResponseHandler(responseData => {
        let actionData = {
          params: data.params,
          response: responseData,
          data: data.updatedBillSearchData,
        };
        if (responseData > -1)
          dispatch(actions.updateAddManageBillData(actionData));
      })
      .withFailureAlert('An error occurred while sending add manage bill data')
      .build()
      .execute();
  };
};

const getSiteOptionsData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getSiteOptionsData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSiteOptionsData(responseData));
      })
      .withFailureAlert('An error occurred while finding site options data.')
      .build()
      .execute();
  };
};

const getCashPopulateData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getCashPopulateData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateCashAppData(responseData));
      })
      .withSuccessAlert(
        parseFloat(data.amount) > 0
          ? 'Special Billing has been submitted'
          : 'Cash app is being populated, you will receive an e-mail when it has completed.'
      )
      .withFailureAlert('An error occurred while updating cash app data.')
      .build()
      .execute();
  };
};

const deleteBillLines = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.deleteBillLines(data))
      .withResponseHandler(responseData => {
        // dispatch(actions.updateDeleteBillLines(responseData));
      })
      .withFailureAlert('An error occurred.')
      .build()
      .execute();
  };
};

const getFeeLookupData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getFeeLookupData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateFeeLookupData(responseData.state));
        dispatch(actions.updateFeeLookupDataUC(responseData.uc));
        dispatch(actions.updateFeeLookupDataUHUC(responseData.uhuc));
        dispatch(
          actions.updateFeeLookupDataSsDesc(responseData.state[0].ssDescription)
        );
        dispatch(actions.updateFeeLookupDataMed(responseData.med[0].state));
        dispatch(actions.updateNewMed(responseData.state));
      })
      .withFailureAlert('An error occurred while finding fee lookup data.')
      .build()
      .execute();
  };
};

const getLookupfeescheduleNPI = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getLookupfeescheduleNPI(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLookupfeescheduleNPIData(responseData));
      })
      .withFailureAlert('An error occurred while finding fee lookup npi data.')
      .build()
      .execute();
  };
};

// const getFeeLookupDataUC = data => {
//   return async dispatch => {
//     await ServiceExecutor.Builder(dispatch)
//       .withServiceCall(() => billOperationsService.getFeeLookupDataUC(data))
//       .withResponseHandler(responseData => {
//         dispatch(actions.updateFeeLookupDataUC(responseData));
//       })
//       .withFailureAlert('An error occurred while finding fee lookup data.')
//       .build()
//       .execute();
//   };
// };

const getClientsPPOData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getClientsPPOData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClientsPPOData(responseData));
      })
      .withFailureAlert('An error occurred while finding search ppo data.')
      .build()
      .execute();
  };
};
// const getFeeLookupDataSsDesc = data => {
//   return async dispatch => {
//     await ServiceExecutor.Builder(dispatch)
//       .withServiceCall(() => billOperationsService.getFeeLookupDataSsDesc(data))
//       .withResponseHandler(responseData => {
//         dispatch(actions.updateFeeLookupDataSsDesc(responseData));
//       })
//       .withFailureAlert('An error occurred while finding fee lookup data.')
//       .build()
//       .execute();
//   };
// };
// const getFeeLookupDataUHUC = data => {
//   return async dispatch => {
//     await ServiceExecutor.Builder(dispatch)
//       .withServiceCall(() => billOperationsService.getFeeLookupDataUHUC(data))
//       .withResponseHandler(responseData => {
//         dispatch(actions.updateFeeLookupDataUHUC(responseData));
//       })
//       .withFailureAlert('An error occurred while finding fee lookup data.')
//       .build()
//       .execute();
//   };
// };

const getFeeLookupDataByZip = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getFeeLookupDataByZip(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateFeeLookupDataByZip(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading fee lookup data for this zip.'
      )
      .build()
      .execute();
  };
};
// const getFeeLookupDataMed = data => {
//   return async dispatch => {
//     await ServiceExecutor.Builder(dispatch)
//       .withServiceCall(() => billOperationsService.getFeeLookupDataMed(data))
//       .withResponseHandler(responseData => {
//         dispatch(actions.updateFeeLookupDataMed(responseData));
//       })
//       .withFailureAlert(
//         'An error occurred while loading fee lookup data for this zip.'
//       )
//       .build()
//       .execute();
//   };
// };

const getQABills = stateObject => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getQABills(stateObject))
      .withResponseHandler(data => {
        dispatch(actions.updateQABillTable(data));
      })
      .withFailureAlert('An error occurred while loading data for QA Bill tab.')
      .build()
      .execute();
  };
};

const loadQABillsMasterClientDataProvider = searchData => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billReviewService.findClients(searchData))
      .withResponseHandler(responseData => {
        dispatch(actions.updateQABillMasterClientDataProvider(responseData));
      })
      .withFailureAlert('An error occurred while finding clients.')
      .build()
      .execute();
  };
};

const confirmQABills = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.confirmQABills(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSelectedQABills(responseData));
        if (req) {
          dispatch(getQABills(req));
        }
      })
      .withSuccessAlert('Successfully QAd bills')
      .withFailureAlert('An error occurred while confirming QA bills.')
      .build()
      .execute();
  };
};

const getGridSettings = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getGridSettings(data))
      .withResponseHandler(responseData => {
        if (
          responseData.fields &&
          responseData.fields.gridName === findBillsGridSettingName
        ) {
          dispatch(actions.getFindBillsGridSettings(responseData));
        } else if (
          responseData.fields &&
          responseData.fields.gridName === taskListGridSettingName
        ) {
          dispatch(actions.getTaskListGridSettings(responseData));
        }
      })
      .withFailureAlert('An error occurred while getting grid settings.')
      .build()
      .execute();
  };
};

const changeGridSettings = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.changeGridSettings(data))
      .withResponseHandler(() => { })
      .withSuccessAlert('The Grid Settings have been saved.')
      .withFailureAlert('An error occurred while saving grid settings.')
      .build()
      .execute();
  };
};

const findRecentActivityBills = data => {
  return async dispatch => {
    dispatch(actions.updateRecentActivityTable([]));
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.findRecentActivityBills(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateRecentActivityTable(responseData));
      })
      .withFailureAlert('An error occurred while loading the recent bill list.')
      .build()
      .execute();
  };
};

const reverseAdjusterAction = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.reverseAdjusterAction(data))
      .withResponseHandler(() => {
        dispatch(
          findRecentActivityBills({
            adjuster: data.adjuster,
            profile: data.profile,
            isManager: data.isManager,
          })
        );
      })
      .withSuccessAlert('Item has been reversed')
      .withFailureAlert('An error occurred while reversing this activity.')
      .build()
      .execute();
  };
};

/**
 * FIXME: Refactor? Operations should only concern themselves with maintaining
 * the store and not impose on the caller what happens to the data
 */
const openPpoLink = data => {
  return async dispatch => {
    return await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getPpoLink(data))
      .withResponseHandler(responseData => {
        if (responseData.fields.chr_linkaddress) {
          window.open(responseData.fields.chr_linkaddress);
        }
      })
      .withFailureAlert('Keep trying bill.')
      .build()
      .execute();
  };
};

const loadMoreInfo = data => {
  return async dispatch => {
    return await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadMoreInfo(data))
      .withResponseHandler(response => {
        dispatch(actions.loadMoreInfo(response));
      })
      .withFailureAlert('An error occurred while getting more information.')
      .build()
      .execute();
  };
};

const saveNote = (body, searchFields, claimNumber) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.saveClientRecords(body))
      .withResponseHandler(() => {
        dispatch(() => { });
      })
      .withFailureAlert('An error occurred while saving notes.')
      .build()
      .execute();
  };
};

const saveTaskListNote = (body, searchFields, req) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.saveClientRecords(body))
      .withResponseHandler(() => {
        dispatch(() => { });
      })
      .withFailureAlert('An error occurred while saving notes.')
      .build()
      .execute();
  };
};

const deleteReferralDocuments = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.deleteReferralDocuments(data)
      )
      .build()
      .execute();
  };
};

const loadReferralDocuments = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadReferralDocuments(data))
      .withResponseHandler(responseData => {
        const results = R.pathOr(
          [],
          ['response', 'results', 'row'],
          responseData
        );

        dispatch(
          actions.updateReferralDocuments(
            TypeUtils.isArray(results) ? results : [results]
          )
        );
      })
      .withFailureAlert('An error occurred while loading referral documents.')
      .build()
      .execute();
  };
};

const retrieveReferralDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.retrieveReferralDocument(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateRetrieveReferralDocument(responseData));
      })
      .withFailureAlert('Failed to retrieve file.')
      .build()
      .execute();
  };
};

const confirmDemandBills = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.confirmDemandBills(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSelectedDemandBills(responseData));
      })
      .withSuccessAlert('Successfully Added Demand status')
      .withFailureAlert('An error occurred while confirming QA bills.')
      .build()
      .execute();
  };
};

const loadDemandDocuments = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadDemandDocuments(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDemandDocuments(responseData));
      })
      .withFailureAlert('An error occurred while loading demand documents.')
      .build()
      .execute();
  };
};

const demandFileUpload = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.demandFileUpload(data))
      .withFailureAlert('Failed to upload file.')
      .build()
      .execute();
  };
};

const loadDeleteDemandDocuments = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.loadDeleteDemandDocuments(data)
      )
      .withFailureAlert('Failed to delete file.')
      .build()
      .execute();
  };
};

const retriveDemandDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.retriveDemandDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateRetriveDemandDocument(responseData));
      })
      .withFailureAlert('Failed to retrive file.')
      .build()
      .execute();
  };
};

const loadReferralEmailTemplates = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.loadReferralEmailTemplates(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateReferralEmailTemplates(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading referral email templates.'
      )
      .build()
      .execute();
  };
};

const loadReferralEmailTemplatesInjury = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.loadReferralEmailTemplatesInjury(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateReferralEmailTemplates(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading referral email templates.'
      )
      .build()
      .execute();
  };
};

const loadReferralSpecialties = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadSpecialties(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateReferralSpecialties(responseData));
      })
      .withFailureAlert('An error occurred while loading specialties.')
      .build()
      .execute();
  };
};

const sendEmail = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.sendEmail(data))
      .withSuccessAlert('Email sent successfully')
      .withFailureAlert(
        'Email failed to send.  Please contact the help desk with the Bill Id.'
      )
      .build()
      .execute();
  };
};

const fileUpload = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.fileUpload(data))
      .withFailureAlert('Failed to upload file.')
      .build()
      .execute();
  };
};
const loadBillClaimNotes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.loadBillClaimNotes(data))
      .withResponseHandler(responseData => {
        dispatch(actions.UpdateBillClaimNotes(responseData));
      })
      .withFailureAlert('An error occurred while loading notes.')
      .build()
      .execute();
  };
};

const getAccessLevelData = async userId => {
  const response = await billOperationsService
    .getAccessData(userId)
    .then(function (response) {
      return response;
    });
  return response;
};

const addPendCodeToBill = (data, searchFields, claimType = undefined) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.addPendCodeToBill(data))
      .withResponseHandler(() => {
        dispatch(findBills(searchFields, claimType));
      })
      .withSuccessAlert('All bills pended successfully.')
      .withFailureAlert('Not all bills were successfully pended.')
      .build()
      .execute();
  };
};

const sendRereviewNote = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.sendRereviewNote(data))
      .withSuccessAlert('Save Successful')
      .withFailureAlert('An error occurred while sending bill for Re-Review')
      .build()
      .execute();
  };
};

const getStatusHistoryData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.getStatusHistoryData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateStatusHistoryData(responseData));
      })
      .withFailureAlert('An error occurred while finding status history data.')
      .build()
      .execute();
  };
};

const sendStatusListDropdownData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billOperationsService.sendStatusListDropdownData(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateStatusListData(responseData));
      })
      .withSuccessAlert('Status Flag updated successfully')
      .withFailureAlert(
        'An error occurred while updating status list dropdown data.'
      )
      .build()
      .execute();
  };
};

const sendUnPendData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billOperationsService.sendUnPendData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateUnPendData(responseData));
      })
      .withSuccessAlert('Unpend successful.')
      .withFailureAlert('An error occurred while updating unpended data.')
      .build()
      .execute();
  };
};

const resetAppData = () => {
  return async dispatch => {
   dispatch(actions.resetStoreState());
  }
}



export default {
  resetAppData,
  getAccessLevelData,
  findApproveBills,
  getTaskListInitialData,
  getQABills,
  loadQABillsMasterClientDataProvider,
  confirmQABills,
  findBills,
  approveBills,
  denyBills,
  pendBills,
  pendBillsWorklist,
  unpendCodeOnBill,
  unpendCodeOnBills,
  correctBills,
  loadCorrectionReasons,
  modifyBills,
  findClaims,
  getURDecision,
  getClaim,
  loadBillReviewData,
  transferBills,
  getEDISetupData,
  getOdgLookupData,
  getBillLinesData,
  getBillLinesDataMcns,
  getBillTotalData,
  getBillDetailDropdownData,
  postBillLinesData,
  getPrintEORData,
  getPrintReportsData,
  getClaimList,
  getProviderList,
  getEmployerList,
  getClaimantList,
  getSearchPPOData,
  getStartDateData,
  getSiteOptionsData,
  getCashPopulateData,
  postAddManageBillData,
  deleteBillLines,
  getClientsPPOData,
  getOdgLookupDataByBill,
  getPrintEORDropdownUserData,
  getFeeLookupData,
  getLookupfeescheduleNPI,
  //getFeeLookupDataUC,
  //getFeeLookupDataSsDesc,
  //getFeeLookupDataUHUC,
  getFeeLookupDataByZip,
  //getFeeLookupDataMed,
  getGridSettings,
  changeGridSettings,
  findRecentActivityBills,
  reverseAdjusterAction,
  openPpoLink,
  loadMoreInfo,
  saveNote,
  saveTaskListNote,
  deleteReferralDocuments,
  loadReferralDocuments,
  retrieveReferralDocument,
  confirmDemandBills,
  loadDemandDocuments,
  demandFileUpload,
  loadDeleteDemandDocuments,
  retriveDemandDocument,
  loadReferralEmailTemplates,
  loadReferralEmailTemplatesInjury,
  loadReferralSpecialties,
  sendEmail,
  fileUpload,
  addPendCodeToBill,
  sendRereviewNote,
  getStatusHistoryData,
  sendStatusListDropdownData,
  sendUnPendData,
  sendProfileDrodownData,
  postMcnsBills,
  postCodeReviewBills,
  postBillReviewData,
  savePaycode,
  postBillReviewAoData,
  loadAORemaining,
  loadBillClaimNotes,
};
