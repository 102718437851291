import * as R from 'ramda';

import { loginResultCodes } from '../constants';

export const login = {
  toRequestBody: (username, password, systemId) => ({
    functionName: 'getLoginInfo',
    username,
    password,
    profile: 'ScheduleLink',
    zebraUserId: 0,
    systemId,
  }),
  fromResponseBody: (response, username_in) => {
    const errorMessage = R.pathOr(
      null,
      ['data', 'result', 'options', 'loginErrorMessage'],
      response
    );
    const loginResult = R.pathOr(
      null,
      ['data', 'result', 'options', 'loginResult'],
      response
    );
    const currentQuestionId = R.pathOr(
      null,
      ['data', 'result', 'options', 'CurrentQuestionId'],
      response
    );
    const currentQuestion = R.pathOr(
      null,
      ['data', 'result', 'options', 'CurrentQuestion'],
      response
    );

    const options = response.data.result.options;
    const userList = R.pathOr([], ['data', 'result', 'list'], response);
    const isAuthCodeRequired = R.pathOr(false, ['data', 'result', 'options','needsCode'], response);
    const user = loginResult === loginResultCodes.SUCCESS ? userList[0] : null; 
    const username = loginResult === loginResultCodes.SUCCESS ? userList[0].username : username_in;
    const userId = loginResult === loginResultCodes.SUCCESS ? userList[0].user_id : 0;

    if (loginResult === loginResultCodes.SUCCESS) {
      localStorage.setItem('auth_token', options.sessionId);
    }
    
    return {
      errorMessage,
      loginResult,
      user, 
      userId,
      username,
      currentQuestion,
      currentQuestionId,
      isAuthCodeRequired
    };
  },
};

export const checkAuthCode = {
  toRequestBody: (username, authCode, systemId) => ({
    username,
    authCode, 
    systemId: systemId.toString()
  }),
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ["data"],
      response
    );
    return {
      result
    };
  },
};

export const resendAuthCode = {
  toRequestBody: (username) => ({
    username,
  }),
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ["data"],
      response
    );
    return {
      result
    };
  },
};

export const resetPassword = {
  toRequestBody: (username, user_id) => ({
    username,
    user_id
  }),
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ['data'],
      response
    );
    return {
      result
    };
  },
};

export const changePassword = {
  toRequestBody: (username, oldPassword, newPassword) => ({
    username,
    oldPassword,
    newPassword
  }),
  fromResponseBody: response => {
    const passwordUpdateErrorMessage = R.pathOr(
      null,
      ['data', 'result'],
      response
    );
    return {
      passwordUpdateErrorMessage
    };
  },
};

export const loadSecurityQuestions = {
  toRequestBody: (userId) => ({
    functionName: 'loadSecurityQuestions',
    zebraUserId: userId,
    userID:0,
    profile:"S4"
  }),
  fromResponseBody: response => {
    const questions = R.pathOr(
      null,
      ['data'],
      response
    );
    const securityQuestions = questions[0];
    return {
      securityQuestions
    };
  },
};

export const saveSecurityQuestions = {
  toRequestBody: (answer, questionId, userId) => ({
    functionName: 'saveSecurityQuestion',
    profile:"S4",
    zebraUserId: userId,
    userId: userId,
    questionId: questionId,
    answer: answer
  })
};

export const checkSecurityQuestions = {
  toRequestBody: (answer, questionId, userId) => ({
    functionName: 'checkSecurityQuestion',
    profile:"Mba",
    zebraUserId: userId,
    userId: userId,
    questionId: questionId,
    answer: answer
  }),
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ['data','result'],
      response
    );
    if (result) {
      localStorage.setItem('auth_token', response.data.sessionId);
    }
  return {
    result
  };
},
};

export const zebraTimeoutSeconds = {
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ['data'],
      response
    );
  return {
    result
  };
},
};

export const logout = {
  toRequestBody: (username, userId) => ({
    username: username,
    userId: userId
  }),
  fromResponseBody: response => {
    const result = R.pathOr(
      null,
      ['data'],
      response
    ); 
  return {
    result
  };
},
};
