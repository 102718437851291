import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import PopupModal from 'shared/components/PopupModal';
import { commonOperations } from 'common/store';
import { assign, isEmpty } from 'lodash';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import HCPCSearchModal from './HCPCSearch';
import Fast360FeeLookupModal from './Fast360FeeLookupModal';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  primaryData,
  secondaryData,
  discountData,
  calcData,
  calculator,
  rentalUnit,
} from '../utils/constantUtils';

let checkCode = '';
const Fast360HHCLookupModal = ({
  hhcCategory,
  isOpen,
  onClose,
  getClientInfo,
  getVendorsList,
  vendorList,
  clientInfo,
  resetVendorLookupTable,
  resetSearchClientTable,
  resetLoadHCPCTable,
  hcpcList,
  userId,
  loadHCPC,
  patientInfo,
  BRTypes,
  hcpcCheck,
  hcpcTermCheck
}) => {
  const [openHCPCServiceDetail, setOpenHCPCServiceDetail] = useState(false);
  const [isHCPCCodeDesc, setIsHCPCCodeDesc] = useState({});
  const [showHCPCSearchModal, setShowHCPCSearchModal] = useState(false);
  const [lookupModal, setLookupModal] = useState(false);
  const [checkCodeModal, setCheckCodeModal] = useState(false);
  const [formCustomizableFields, setFormCustomizableFields] = useState({});
  const [formHCPCFields, setFormHCPCFields] = useState({
    qty: 1,
    hcpcCode: '',
    hcpcDesc: '',
    mod1: '',
    mod2: '',
    clientText: '',
    vendorText: '',
    hhcService: '',
    revenueCode: '',
    costPercentSavings: 30,
    vendorDisc: '',
    custDisc: '',
    fs: '',
    uc: '',
    mca: '',
    owcp: '',
    msrp: '',
    flat: '',
    calcValue: '',
    calOption: '',
    calcData: '',
    totValue: '',
    startDate: '',
    endDate: '',
    hhcDos: '',
    duration: '',
    rentUnit: '',
    vendBuyout: '',
    custBuyout: '',
    rentExt: false,
    finalBilledCharge: `${0.0}`,
    finalCostSavingPercent: `${0.0}`,
  });

  const toggle = () => {
    onClose();
    resetFormData();
    resetLoadHCPCTable();
    resetSearchClientTable();
  };

  const resetFormData = () => {
    setFormHCPCFields({
      hcpcCode: '',
      hcpcDesc: '',
      mod1: '',
      mod2: '',
      qty: 1,
      hhcService: '',
      revenueCode: '',
      costPercentSavings: 30,
      vendorDisc: '',
      custDisc: '',
      venFlatRate: 0,
      clientFlatRate: 0,
      fs: '',
      uc: '',
      mca: '',
      owcp: '',
      msrp: '',
      flat: '',
      calcValue: '',
      calOption: '',
      calcData: '',
      totValue: '',
      startDate: '',
      endDate: '',
      hhcDos: '',
      duration: '',
      rentUnit: '',
      vendBuyout: '',
      custBuyout: '',
      rentExt: false,
      finalBilledCharge: `${0.0}`,
      finalCostSavingPercent: `${0.0}`,
    });
    setFormCustomizableFields({});
    setIsHCPCCodeDesc({});
  };

  useEffect(() => {
    if (isOpen) {
      setOpenHCPCServiceDetail(true);
    } else {
      setOpenHCPCServiceDetail(false);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (!isEmpty(isHCPCCodeDesc)) {
  //     const detailParams = {
  //       functionName: 'getDetailLineFee',
  //       userId: userId,
  //       vendorId: 0,
  //       serviceTypeId: 0,
  //       hcpc: formHCPCFields.hcpcCode,
  //       fs: formHCPCFields.fs,
  //       mca: formHCPCFields.mca,
  //       uc: formHCPCFields.uc,
  //       owcp: formHCPCFields.owcp,
  //       zipCodeId: formHCPCFields.zipCode,
  //       // state: patientInfo.statePatient,
  //       dos: dateFormat(formHCPCFields.hhcDos),
  //       serviceId: 0,
  //       serviceType: 'HHC',
  //     };
  //     getDetailLineFee(detailParams);
  //     const feeParams = {
  //       functionName: 'clientFeeLookup',
  //       userId: userId,
  //       clientId: patientInfo.clientPatientId,
  //       serviceTypeId: 0,
  //       hcpc: formHCPCFields.hcpcCode,
  //       fs: formHCPCFields.fs,
  //       mca: formHCPCFields.mca,
  //       uc: formHCPCFields.uc,
  //       owcp: formHCPCFields.owcp,
  //       zipCodeId: patientInfo.zipPatient,
  //       state: patientInfo.statePatient,
  //     };
  //     clientFeeLookup(feeParams);
  //   }
  // }, [isHCPCCodeDesc]);

  const handleHCPCFields = (event, id, actionType) => {
    if (actionType && actionType.action === 'clear') {
      switch (id) {
        case 'hcpcCode':
          resetLoadHCPCTable();
          break;
      }
      resetHCPCClearFormData();
      // setFormHCPCFields({
      //   ...formHCPCFields,
      //   hcpcCode: '',
      //   hcpcDesc: '',
      // });
      setIsHCPCCodeDesc({});
    } else {
      const selectedHCPC =
        hcpcList.length > 0 &&
        hcpcList.find(obj => obj.int_id === event.int_id);
      if (selectedHCPC) {
        setFormHCPCFields({
          ...formHCPCFields,
          hcpcCode: selectedHCPC.chr_code,
          hcpcDesc: selectedHCPC.chr_description,
          fs: selectedHCPC.dbl_fee,
          uc: selectedHCPC.dbl_fee80,
          mca: selectedHCPC.mca,
          owcp: selectedHCPC.owcp,
          vendorDisc: 'UC',
          custDisc: 'UC',
          venFlatRate: 0,
          clientFlatRate: 0,
        });
        setDefaultVendCustomFields(selectedHCPC);
        setFormCustomizableFields({
          ...formCustomizableFields,
          UC: {
            vendorDisc: 'UC',
            venUnitCost: selectedHCPC.dbl_fee80,
            venDiscQuantity: 0,
            venBillChrg: 0,
            custDisc: 'UC',
            custUnitCost: selectedHCPC.dbl_fee80,
            custDiscPercent: 0,
            custBillChrg: 0,
          },
        });
        setIsHCPCCodeDesc(selectedHCPC);
      }
      resetLoadHCPCTable();
    }
  };

  const handleClientFields = (event, id, actionType) => {
    if (actionType && actionType.action === 'clear') {
      switch (id) {
        case 'clientText':
          resetSearchClientTable();
          break;
      }
    } else {
      const selectedHCPC =
      clientInfo.length > 0 &&
      clientInfo.find(obj => obj.PK_client_id === event.PK_client_id);
      if (selectedHCPC) {
        setFormHCPCFields({
          ...formHCPCFields,
          clientText: selectedHCPC.chr_companyname, 
        });
      }
      resetSearchClientTable();
    }
  };

  const handleVendorFields = (event, id, actionType) => {
    if (actionType && actionType.action === 'clear') {
      switch (id) {
        case 'vendorText':
          resetVendorLookupTable();
          break;
      }
    } else {
      const selectedHCPC =
      vendorList.length > 0 &&
      vendorList.find(obj => obj.id === event.id);
      if (selectedHCPC) {
        setFormHCPCFields({
          ...formHCPCFields,
          vendorText: selectedHCPC.name, 
        });
      }
      resetVendorLookupTable();
    }
  };

  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    //formHCPCFields.hhcService = name;
    //formHCPCFields.hhcService = target.value;
    setFormHCPCFields({...formHCPCFields, hhcService: target.value });
  };

  const setDefaultVendCustomFields = obj => {
    if (!isEmpty(obj)) {
      setFormCustomizableFields({
        FS: {
          vendorDisc: 'FS',
          venUnitCost: obj.dbl_fee,
          venDiscQuantity: 0,
          venBillChrg: 0,
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
        UC: {
          vendorDisc: 'UC',
          venUnitCost: obj.dbl_fee80,
          venDiscQuantity: 0,
          venBillChrg: 0,
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
        MCA: {
          vendorDisc: 'MCA',
          venUnitCost: obj.mca,
          venDiscQuantity: 0,
          venBillChrg: 0,
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
        OWCP: {
          vendorDisc: 'OWCP',
          venUnitCost: obj.owcp,
          venDiscQuantity: 0,
          venBillChrg: 0,
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
        MSRP: {
          vendorDisc: 'MSRP',
          venUnitCost: formHCPCFields.msrp,
          venDiscQuantity: 0,
          venBillChrg: 0,
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
        FLAT: {
          vendorDisc: 'FLAT',
          venUnitCost: 0,
          venDiscQuantity: 0,
          venBillChrg: '0.00',
          custDisc: formHCPCFields.custDisc,
          custUnitCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg:
            formCustomizableFields[formHCPCFields.custDisc] &&
            formCustomizableFields[formHCPCFields.custDisc].custBillChrg,
        },
      });
    }
  };

  const setDefaultCustCustomFields = obj => {
    if (!isEmpty(obj)) {
      setFormCustomizableFields({
        FS: {
          custDisc: 'FS',
          custUnitCost: obj.dbl_fee,
          custDiscPercent: 0,
          custBillChrg: 0,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        UC: {
          custDisc: 'UC',
          custUnitCost: obj.dbl_fee80,
          custDiscPercent: 0,
          custBillChrg: 0,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MCA: {
          custDisc: 'MCA',
          custUnitCost: obj.mca,
          custDiscPercent: 0,
          custBillChrg: 0,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        OWCP: {
          custDisc: 'OWCP',
          custUnitCost: obj.owcp,
          custDiscPercent: 0,
          custBillChrg: 0,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MSRP: {
          custDisc: 'MSRP',
          custUnitCost: formHCPCFields.msrp,
          custDiscPercent: 0,
          custBillChrg: 0,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        FLAT: {
          custDisc: 'FLAT',
          custUnitCost: 0,
          custDiscPercent: 0,
          custBillChrg: '0.00',
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscQuantity:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity,
          venBillChrg:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
      });
    }
  };

  const handleTypeAheadSearch = (event, type) => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      let params = {
        functionName: 'loadHCPC',
        userId: userId,
        code: event.target.value,
        date: formHCPCFields.hhcDos,
        zipcode: formHCPCFields.zipCode,
        mod1: formHCPCFields.mod1,
        mod2: formHCPCFields.mod2,
      };
      loadHCPC(params);
      checkCode=event.target.value;
    } else if (event.target.value.length > 0 && hcpcList && hcpcList.length) {
      resetLoadHCPCTable();
    }
  };

  const handleClientTypeAheadSearch = (event, type) => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        clientName: event.target.value,
        userId: userId,
      };
      getClientInfo(params);
    } else if (event.target.value.length > 0 && clientInfo && clientInfo.length) {
      resetSearchClientTable();
    }
  };

  const handleVendorTypeAheadSearch = (event, type) => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        vendorText: '%'+event.target.value+'%',
        userId: userId,
      };
      getVendorsList(params);
    } else if (event.target.value.length > 0 && vendorList && vendorList.length) {
      resetVendorLookupTable();
    }
  };

  const setCalValue = (id, value) => {
    switch (value) {
      case 'FS':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.fs,
          totValue: formHCPCFields.fs,
        });
        break;
      case 'UC':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.uc,
          totValue: formHCPCFields.uc,
        });
        break;
      case 'MCA':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.mca,
          totValue: formHCPCFields.mca,
        });
        break;
      case 'V.Billing Charges':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formCustomizableFields[formHCPCFields.vendorDisc]
            ? formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            : '',
          totValue: formCustomizableFields[formHCPCFields.vendorDisc]
            ? formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            : '',
        });
        break;
      default:
        return '';
    }
  };

  const onChangeHandlerPrimary = (id, value) => {
    setFormHCPCFields({ ...formHCPCFields, [id]: value });
    if (id === 'vendorDisc') {
      setDefaultVendCustomFields(isHCPCCodeDesc);
    }
    if (id === 'custDisc') {
      setDefaultCustCustomFields(isHCPCCodeDesc);
      //if (!isEmpty(isHCPCCodeDesc)) {
        if (value === 'UC') {
          calcBillCharge(
            formHCPCFields.uc,
            isHCPCCodeDesc.dbl_fee80,
            isHCPCCodeDesc.dbl_fee80,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
        } else if (value === 'FS') {
          calcBillCharge(
            formHCPCFields.fs,
            isHCPCCodeDesc.dbl_fee,
            isHCPCCodeDesc.dbl_fee,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
        } else if (value === 'OWCP') {
          calcBillCharge(
            formHCPCFields.owcp,
            isHCPCCodeDesc.owcp,
            isHCPCCodeDesc.owcp,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
        } else if (value === 'MCA') {
          calcBillCharge(
            formHCPCFields.mca,
            isHCPCCodeDesc.mca,
            isHCPCCodeDesc.mca,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
        } else if (value === 'MSRP') {
          calcBillCharge(
            formHCPCFields.msrp,
            formHCPCFields.msrp,
            formHCPCFields.msrp,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
        } else if (value === 'FLAT') {
          calcBillCharge(
            formHCPCFields.flat,
            0,
            0,
            value,
            0,
            formHCPCFields.costPercentSavings
          );
    //    } else {
      //    return '';
        //}
        } else {
          return '';
        }
    }
    if (id === 'calcValue') {
      setFormHCPCFields({ ...formHCPCFields, [id]: value });
      setCalValue(id, value);
    }
    if (id === 'calc') {
      const val =
        (Number(formHCPCFields.calOption) / 100) *
        Number(formHCPCFields.calcData);
      const finalVal =
        value === '+'
          ? Number(formHCPCFields.calOption) + val
          : Number(formHCPCFields.calOption) - val;
      setFormHCPCFields({
        ...formHCPCFields,
        [id]: value,
        totValue: finalVal.toFixed(2),
      });
    }
    if (id === 'mod2') {
      setFormHCPCFields({ ...formHCPCFields, [id]: value });
      // setCostPercentValue(id, value);
    }
  };

  useEffect(() => {
    if (
      formHCPCFields.mod2 ||
      formHCPCFields.costPercentSavings ||
      formHCPCFields.qty
    ) {
      if (formHCPCFields.custDisc === 'UC') {
        calcBillCharge(
          formHCPCFields.uc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FS') {
        calcBillCharge(
          formHCPCFields.fs,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'OWCP') {
        calcBillCharge(
          formHCPCFields.owcp,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MCA') {
        calcBillCharge(
          formHCPCFields.mca,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MSRP') {
        calcBillCharge(
          formHCPCFields.msrp,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FLAT') {
        calcBillCharge(
          formHCPCFields.flat,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
          ),
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else {
        return;
      }
      setFormHCPCFields({ ...formHCPCFields });
    }
  }, [
    formHCPCFields.mod2,
    formHCPCFields.costPercentSavings,
    formHCPCFields.qty,
  ]);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'rentExt') {
      formHCPCFields.rentExt =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    setFormHCPCFields({ ...formHCPCFields, [name]: value });
    if (name === 'calcData') {
      const val = (Number(formHCPCFields.calOption) / 100) * Number(value);
      const finalVal =
        formHCPCFields.calc === '+'
          ? Number(formHCPCFields.calOption) + val
          : Number(formHCPCFields.calOption) - val;
      setFormHCPCFields({
        ...formHCPCFields,
        [name]: value,
        totValue: finalVal.toFixed(2),
      });
    }
  };

  const handleVendChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'venDiscQuantity') {
      const val = Number(formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost)/100 * Number(value);
      const finalVal = Number(formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost) - val;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          venDiscQuantity: value,
          venBillChrg: finalVal.toFixed(2),
        },
      });
    } else if (name === 'venUnitCost') {
      const venVal = Number(value)/100 * Number(formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscQuantity);
      const venBill = Number(value) - venVal;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          [name]: value,
          venBillChrg: venBill.toFixed(2),
        },
      });
    } else {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          [name]: value,
        },
      });
    }
  };

  const handleCustChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'custDiscPercent') {
      const custVal = Number(formCustomizableFields[formHCPCFields.custDisc].custUnitCost)/100 * Number(value);
      const custFinalVal = Number(formCustomizableFields[formHCPCFields.custDisc].custUnitCost) - custVal;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          custDiscPercent: value,
          custBillChrg: custFinalVal.toFixed(2),
        },
      });
      if (formHCPCFields.custDisc === 'UC') {
        calcBillCharge(
          formHCPCFields.uc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FS') {
        calcBillCharge(
          formHCPCFields.fs,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'OWCP') {
        calcBillCharge(
          formHCPCFields.owcp,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MCA') {
        calcBillCharge(
          formHCPCFields.mca,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MSRP') {
        calcBillCharge(
          formHCPCFields.msrp,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FLAT') {
        calcBillCharge(
          formHCPCFields.flat,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost
          ),
          custFinalVal.toFixed(2),
          formHCPCFields.custDisc,
          value,
          formHCPCFields.costPercentSavings
        );
      } else {
        return '';
      }
    } else if (name === 'custUnitCost') {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          [name]: value,
        },
      });
      const cusVal = Number(value)/100 * Number(formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent);
      const custBill = Number(value) - cusVal;
      if (formHCPCFields.custDisc === 'UC') {
        calcBillCharge(
          formHCPCFields.uc,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FS') {
        calcBillCharge(
          formHCPCFields.fs,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'OWCP') {
        calcBillCharge(
          formHCPCFields.owcp,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MCA') {
        calcBillCharge(
          formHCPCFields.mca,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'MSRP') {
        calcBillCharge(
          formHCPCFields.msrp,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else if (formHCPCFields.custDisc === 'FLAT') {
        calcBillCharge(
          formHCPCFields.flat,
          value,
          custBill,
          formHCPCFields.custDisc,
          Number(
            formCustomizableFields[formHCPCFields.custDisc] &&
              formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
          ),
          formHCPCFields.costPercentSavings
        );
      } else {
        return '';
      }
    } else {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          [name]: value,
        },
      });
    }
  };

  const prepareDropDownOpt = obj => {
    let formattedOption =
      obj.chr_code +
      '-' +
      obj.chr_description +
      '  ' +
      ' Fee schedule:' +
      obj.dbl_fee +
      '  ' +
      ' Medicare:' +
      obj.mca +
      '  ' +
      ' U/C:' +
      obj.dbl_fee80 +
      '  ' +
      ' OWCP:' +
      obj.owcp;
    return formattedOption;
  };

  const prepareClientDropDownOpt = obj => {
      let print =''
      if (obj) {
        print = (
          <>
            <br/>
            {(obj.chr_street1 === undefined ? '' : obj.chr_street1)}
             <br/>
            {(obj.chr_city === undefined ? '' : obj.chr_city)+ ' ' + (obj.chr_state === undefined ? '' :obj.chr_state +',') + (obj.chr_zipcode === undefined ? '' :obj.chr_zipcode)} 
          </>
        );
      }
      return (
        <>
          {obj.chr_companyname}
          {print}
        </>
      );
    };

    const prepareVendorDropDownOpt = obj => {
      let formattedOption = obj.name
      return formattedOption;
    };


  const calcBillCharge = (
    checkVal,
    calVal,
    calBill,
    disc,
    percent,
    costPercent
  ) => {
    let billVal;
    let costPer;
    if (
      checkVal === 0 ||
      checkVal === '' ||
      checkVal === undefined ||
      checkVal === '0.00' ||
      checkVal === 0.0
    ) {
      billVal = Number((Number(calBill)/((100 - Number(costPercent))/100)) * Number(formHCPCFields.qty)).toFixed(2);
      costPer = costPercent;
      formHCPCFields.finalBilledCharge = billVal;
      formHCPCFields.finalCostSavingPercent = costPer;
    } else {
      billVal = Number((Number(calVal)* Number(formHCPCFields.qty))).toFixed(2);
      costPer = percent;
      formHCPCFields.finalBilledCharge = billVal;
      formHCPCFields.finalCostSavingPercent = costPer;
    }
    setFormHCPCFields({
      ...formHCPCFields,
      custDisc: disc,
      finalBilledCharge: billVal,
      finalCostSavingPercent: costPer,
    });
    setFormCustomizableFields({
      ...formCustomizableFields,
      [disc]: {
        ...formCustomizableFields[disc],
        custUnitCost: calVal,
        custBillChrg: calBill,
        custDiscPercent: percent,
      },
    });
  };

  const onChangeHandler = object => {
    if (object.key === 'startDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.startDate =
        mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    }
    if (object.key === 'endDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.endDate =
        mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    }
    if(object.key === 'hhcDos') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.hhcDos =
        mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    }
    setFormHCPCFields({ ...formHCPCFields });
  };

  const daysBetween = () => {
    const firstDate = new Date(formHCPCFields.startDate);
    const secondDate = new Date(formHCPCFields.endDate);
    if (formHCPCFields.rentUnit === 'Day') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      formHCPCFields.duration = `${diffDays} Days`;
      return `${diffDays} Days`;
    } else if (formHCPCFields.rentUnit === 'Week') {
      let diffWeeks;
      diffWeeks = Math.round(
        Math.abs((firstDate - secondDate) / (7 * 24 * 60 * 60 * 1000))
      );
      formHCPCFields.duration = `${diffWeeks} Weeks`;
      return `${diffWeeks} Weeks`;
    } else if (formHCPCFields.rentUnit === 'Month') {
      let numberOfMonths;
      numberOfMonths = Math.floor(
        (secondDate - firstDate) / (1000 * 60 * 60 * 24 * 30)
      );
      formHCPCFields.duration = `${numberOfMonths} Months`;
      return `${numberOfMonths} Months`;
    }
    return '';
  };

  const renderRentForm = () => {
    if (formHCPCFields.mod1 !== undefined) {
      if (formHCPCFields.mod1 === 'Rental') {
        return (
          <>
            <Row style={{ marginTop: 29 }}>
              <Col sm="12" md={{ size: 3, offset: 5 }}>
                <Label>
                  <b>Rental Info</b>
                </Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Unit:</Label>
              </Col>
              <Col sm="2">
                <AppInput
                  id="rentUnit"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.rentUnit || ''}
                  data={rentalUnit}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>Duration:</Label>
              </Col>
              <span>{daysBetween()}</span>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Start Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="startDate"
                  onChange={value =>
                    onChangeHandler({ key: 'startDate', value })
                  }
                  value={formHCPCFields.startDate || ''}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>End Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="endDate"
                  onChange={value => onChangeHandler({ key: 'endDate', value })}
                  value={formHCPCFields.endDate || ''}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Vendor Buyout:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="vendBuyout"
                  onChange={handleInputChange}
                  value={formHCPCFields.vendBuyout || ''}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>Customer Buyout:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="custBuyout"
                  onChange={handleInputChange}
                  value={formHCPCFields.custBuyout || ''}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Rental Ext:</Label>
              </Col>
              <Col sm="2">
                <Input
                  style={{ marginLeft: 1 }}
                  type="checkbox"
                  name="rentExt"
                  onChange={handleInputChange}
                  checked={formHCPCFields.rentExt}
                />
              </Col>
            </Row>
          </>
        );
      }
    } else {
      return '';
    }
  };

  const showHCPCSearchModalHandler = () => {
    setShowHCPCSearchModal(!showHCPCSearchModal);
  };

  const onLookupHandler = () => {
    setLookupModal(!lookupModal);
  }

  const resetHCPCClearFormData = () => { 
      setFormHCPCFields({
        hcpcCode: '',
        hcpcDesc: '',
        mod1: formHCPCFields.mod1,
        mod2: formHCPCFields.mod2,
        qty: formHCPCFields.qty,
        hhcService: formHCPCFields.hhcService,
        revenueCode: '',
        costPercentSavings: 30,
        vendorDisc: '',
        custDisc: '',
        venFlatRate: 0,
        clientFlatRate: 0,
        fs: '',
        uc: '',
        mca: '',
        owcp: '',
        msrp: '',
        flat: '',
        calcValue: '',
        calOption: '',
        calcData: '',
        totValue: '',
        startDate: '',
        endDate: '',
        hhcDos: '',
        duration: '',
        rentUnit: '',
        vendBuyout: '',
        custBuyout: '',
        rentExt: false,
        finalBilledCharge: `${0.0}`,
        finalCostSavingPercent: `${0.0}`,
      });
      setFormCustomizableFields({});
      setIsHCPCCodeDesc({});
    };

    const checkHandler = () => {
      const checkParams = {
        functionName: 'hcpcTermCheck',
        zebraUserId: userId,
        code: formHCPCFields.hcpcCode  && formHCPCFields.hcpcCode !== '' ? formHCPCFields.hcpcCode : checkCode,
        state: patientInfo.statePatient 
        };
        hcpcTermCheck(checkParams);
        formHCPCFields.hcpcCode = formHCPCFields.hcpcCode  && formHCPCFields.hcpcCode !== '' ? formHCPCFields.hcpcCode : checkCode;
        onToggle();
      }
  
    const onToggle = () => {
     setCheckCodeModal(!checkCodeModal);
    }
  
    const getCheckContent = () => {
      return (
        <Form>
          <Row>
            <Col>
              <span>
                {hcpcCheck}
                <br />
                <br />
                <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={onToggle}>
                Ok{' '}
              </Button>
              </span>
            </Col>
          </Row>
        </Form>
      );
    };

  return (
    <>
      <Modal size="lg" isOpen={openHCPCServiceDetail}>
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12" md={{ size: 3, offset: 5 }}>
              <Label>
                <b>Order Details</b>
              </Label>
            </Col>
          </Row>
          <Row>
          <Col className="text-right" style={{marginLeft: "-77px"}} sm="3">
              <Label>Client:</Label>
            </Col>
            <Col sm="8">
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleClientFields(e, 'clientText', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleClientTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  formHCPCFields.clientText
                    ? [
                        {
                          value: formHCPCFields.clientText,
                          label: formHCPCFields.clientText,
                        },
                      ]
                    : []
                }
                menuIsOpen={clientInfo && clientInfo.length}
                closeMenuOnSelect={true}
                options={
                    clientInfo && clientInfo.length
                    ? clientInfo.map(obj =>
                        assign(obj, {
                          label: prepareClientDropDownOpt(obj),
                          value: obj.chr_companyname,
                        })
                      )
                    : []
                }
                name="clientText"
                id="clientText"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
              </Row>
              <Row style={{marginTop: "17px"}}>
          <Col className="text-right" style={{marginLeft: "-77px"}} sm="3">
              <Label>Vendor:</Label>
            </Col>
            <Col sm="8">
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleVendorFields(e, 'vendorText', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleVendorTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  formHCPCFields.vendorText
                    ? [
                        {
                          value: formHCPCFields.vendorText,
                          label: formHCPCFields.vendorText,
                        },
                      ]
                    : []
                }
                menuIsOpen={vendorList && vendorList.length}
                closeMenuOnSelect={true}
                options={
                  vendorList && vendorList.length
                    ? vendorList.map(obj =>
                        assign(obj, {
                          label: prepareVendorDropDownOpt(obj),
                          value: obj.name,
                        })
                      )
                    : []
                }
                name="vendorText"
                id="vendorText"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
              </Row>
              <Row style={{ marginTop: 17, marginLeft: "-110px" }}>
            <Col className="text-right" sm="3">
              <Label>Zip:</Label>
            </Col>
            <Col sm="2">
            <Input
                name="zipCode"
                onChange={handleInputChange}
                value={formHCPCFields.zipCode || ''}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>DOS:</Label>
            </Col>
            <Col sm="2">
            <AppDateInput
                  id="hhcDos"
                  onChange={value =>
                    onChangeHandler({ key: 'hhcDos', value })
                  }
                  value={formHCPCFields.hhcDos || ''}
                />
            </Col>
          </Row>
          <Row style={{ marginTop: 17 }}>
            <Col sm="1" md={{ offset: 1 }}>
                <Label>Qty:</Label>
                <Input
                  name="qty"
                  type="number"
                  min={1}
                  onChange={handleInputChange}
                  value={formHCPCFields.qty}
                />
            </Col>
          <Col className="text-right" style={{ marginLeft: '8px' }} sm="8">
              <Row>
                <Label>Service:</Label>
                <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                placeholder="Choose..."
                value={formHCPCFields.hhcService || ''}
                id="hhcService"
              >
                <option value="default">Choose...</option>
                {hhcCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
              </Row>
            </Col>
            </Row>
          <Row style={{marginTop: "17px"}}>
            <Col sm="2">
              <Row>
                <Button
                  style={{ marginTop: 23 }}
                  disabled={!formHCPCFields.mod1}
                  onClick={showHCPCSearchModalHandler}
                >
                  HCPC Search
                </Button>
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Label>Primary: </Label>
                <AppInput
                  id="mod1"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.mod1 || ''}
                  data={primaryData}
                />
              </Row>
            </Col>
            <Col sm="3">
              <Row style={{ marginLeft: 2 }}>
                <Label>Secondary: </Label>
                <AppInput
                  id="mod2"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.mod2 || ''}
                  data={secondaryData}
                />
              </Row>
            </Col>
            <Col sm="3">
              <Label>Revenue Code:</Label>
              <AppInput
                id="revenueCode"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'description',
                }}
                placeholder="Choose Rev Code.."
                value={formHCPCFields.revenueCode || ''}
                data={BRTypes}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 17 }}>
            <Col sm="2">
              <Button style={{ marginTop: 29 }} onClick={onLookupHandler}>
                Lookup
              </Button>
              <Button
                style={{ marginTop: 29, marginLeft: 5 }}
                disabled={!hcpcList || (hcpcList && hcpcList.length)}
                onClick={checkHandler}
                title="Why can't I find this HCPC?"
              >
                ?
              </Button>
            </Col>
            <Col sm="4">
              <Label for="hcpcCode">HCPC: </Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleHCPCFields(e, 'hcpcCode', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  formHCPCFields.hcpcCode
                    ? [
                        {
                          value: formHCPCFields.hcpcCode,
                          label: formHCPCFields.hcpcCode,
                        },
                      ]
                    : []
                }
                menuIsOpen={hcpcList && hcpcList.length}
                closeMenuOnSelect={true}
                options={
                  hcpcList && hcpcList.length
                    ? hcpcList.map(obj =>
                        assign(obj, {
                          label: prepareDropDownOpt(obj),
                          value: obj.chr_code,
                        })
                      )
                    : []
                }
                name="hcpcCode"
                id="hcpcCode"
                isDisabled={R.isEmpty(formHCPCFields.mod1)}
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
            <Col sm="6">
              <Row>
                <Label>Description: </Label>
                <Input
                  name="hcpcDesc"
                  onChange={handleInputChange}
                  value={formHCPCFields.hcpcDesc || ''}
                  disabled
                />
              </Row>
            </Col>
          </Row>
          <Row style={{marginTop: "17px"}}>
            <Col xs="2">
              <Row>
                <Label>Vendor flat rate</Label>
                <Input
                  name="venFlatRate"
                  onChange={handleInputChange}
                  value={formHCPCFields.venFlatRate}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="2">
              <Row>
                <Label>Client flat rate</Label>
                <Input
                  name="clientFlatRate"
                  onChange={handleInputChange}
                  value={formHCPCFields.clientFlatRate}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>FS</Label>
                <Input
                  name="fs"
                  onChange={handleInputChange}
                  value={formHCPCFields.fs}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="2">
              <Row>
                <Label>U&C</Label>
                <Input
                  name="uc"
                  onChange={handleInputChange}
                  value={formHCPCFields.uc}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>MCA</Label>
                <Input
                  name="mca"
                  onChange={handleInputChange}
                  value={formHCPCFields.mca}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="2">
              <Row>
                <Label>OWCP</Label>
                <Input
                  name="owcp"
                  onChange={handleInputChange}
                  value={formHCPCFields.owcp}
                  disabled
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}></Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Discount From:</Label>
            </Col>
            <Col sm="2">
              <AppInput
                id="vendorDisc"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.vendorDisc || ''}
                data={discountData}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Discount From:</Label>
            </Col>
            <Col sm="2">
              <AppInput
                id="custDisc"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.custDisc || ''}
                data={discountData}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Unit Cost:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venUnitCost"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                        .venUnitCost
                    : ''
                }
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Unit Cost:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custUnitCost"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                        .custUnitCost
                    : ''
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Disc Percent:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venDiscQuantity"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                        .venDiscQuantity
                    : ''
                }
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Disc Percent:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custDiscPercent"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                        .custDiscPercent
                    : ''
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Billed Charges:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venBillChrg"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                        .venBillChrg
                    : ''
                }
                disabled
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Billed Charges:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custBillChrg"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                        .custBillChrg
                    : ''
                }
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col className="text-right" sm="3">
              <Label>Cost Savings %</Label>
            </Col>
            <Col sm="2">
              <Input
                name="costPercentSavings"
                onChange={handleInputChange}
                value={formHCPCFields.costPercentSavings || ''}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Billed Charge:</Label>
              <span>${formHCPCFields.finalBilledCharge}</span>
            </Col>
            <Col sm="3">
              <Label>Cost Savings:</Label>
              <span>{formHCPCFields.finalCostSavingPercent}%</span>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" style={{ marginTop: 29 }}>
              <AppInput
                id="calcValue"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.calcValue || ''}
                data={calcData}
              />
            </Col>
            <Col xs="2" style={{ marginTop: 9 }}>
              <Label></Label>
              <Input
                name="calOption"
                onChange={handleInputChange}
                value={formHCPCFields.calOption || ''}
              />
            </Col>
            <Col xs="2">
              <Row>
                <Label>Calculator</Label>
                <AppInput
                  id="calc"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.calc || ''}
                  data={calculator}
                />
              </Row>
            </Col>
            <Col xs="2" style={{ marginTop: 29 }}>
              <Input
                name="calcData"
                onChange={handleInputChange}
                value={formHCPCFields.calcData}
              />
            </Col>
            <Col xs="2" style={{ marginTop: 29 }}>
              <Input
                name="totValue"
                onChange={handleInputChange}
                value={formHCPCFields.totValue}
                disabled
              />
            </Col>
          </Row>
          {renderRentForm()}
          <HCPCSearchModal
            formHCPCFields={formHCPCFields}
            isOpen={showHCPCSearchModal}
            onClose={showHCPCSearchModalHandler}
          />
          <Fast360FeeLookupModal
            isOpen={lookupModal}
            isFrormService={true}
            onClose={onLookupHandler}
          />
          <PopupModal
            content={getCheckContent()}
            externalToggle={onToggle}
            title={''}
            isOpen={checkCodeModal}
            size="md"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    clientInfo: fast360Info.clientInfo,
    vendorList: fast360Info.vendorList,
    hcpcList: fast360Info.hcpcList,
    hcpcCheck: fast360Info.hcpcCheck,
    patientInfo: fast360Info.patientZip,
    userId: user.id,
    BRTypes: initialData.brTypes,
    hhcCategory: initialData.hhcCategory,
    defaultPercent: fast360Info.defaultPercent
  };
};

const mapDispatchToProps = dispatch => ({
  getClientInfo: data => dispatch(fast360Operations.getClientInfo(data)),
  getVendorsList: data => dispatch(fast360Operations.getVendorsList(data)),
  resetSearchClientTable: () =>
  dispatch(fast360Actions.resetSearchClientTable()),
  resetVendorLookupTable: () =>
  dispatch(fast360Actions.resetVendorLookupTable()),
  loadHCPC: data => dispatch(fast360Operations.loadHCPC(data)),
  hcpcTermCheck: data => dispatch(fast360Operations.hcpcTermCheck(data)),
  getDefaultCostSavingsPercent: data =>
    dispatch(fast360Operations.getDefaultCostSavingsPercent(data)),
  getDetailLineFee: data => dispatch(fast360Operations.getDetailLineFee(data)),
  clientFeeLookup: data => dispatch(fast360Operations.clientFeeLookup(data)),
  resetLoadHCPCTable: () => dispatch(fast360Actions.resetLoadHCPCTable()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fast360HHCLookupModal);
