import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import request from 'helper/request';
import defaultReducerRegistry from 'shared/store/reducerRegistry';
import * as ReduxUtils from 'shared/utils/reduxUtils';

import { AuthService } from 'modules/auth/services/AuthService';

const composeEnhancers = composeWithDevTools({});

const defaultStoreConfig = {
  history: createBrowserHistory(),
  reducerRegistry: defaultReducerRegistry,
};

// TODO: Export configureStore and merge provided config with defaultConfig
function mergeConfig(config1, config2) {
  const config = {};

  return config;
}

// const fsaMiddleware = () => next => action => {
//   return next(TypeUtils.isFunction(action.payload) ? action.payload : action);
// };

function configureStore(storeConfig) {
  const { history, reducerRegistry } = storeConfig || {};

  const authService = new AuthService(request);
  const middleware = [
    routerMiddleware(history),
    // fsaMiddleware,
    thunk.withExtraArgument({ authService }),
  ];

  const baseReducers = {
    errors: ReduxUtils.requestErrorsReducer,
    loading: ReduxUtils.requestLoadingReducer,
    router: connectRouter(history),
  };
  const registeredReducers = reducerRegistry.getReducers();

  const rootReducer = combineReducers({
    ...baseReducers,
    ...registeredReducers,
  });

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  store.getHistory = () => history;

  reducerRegistry.setChangeListener(reducers =>
    store.replaceReducer(combineReducers({ ...baseReducers, ...reducers }))
  );

  return store;
}

const defaultStore = configureStore(defaultStoreConfig);
export default defaultStore;
