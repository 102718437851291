import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';

import { expertInfoActions, expertInfoSelectors } from '../store';

import {
  BASIC_INFORMATION,
  RECRUITMENT_INFORMATION,
  DELIVERY_AND_NOTIFICATION,
  COMMENT,
  BIOGRAPHY,
} from '../utils/constantUtils';

import IndividualExpertRecordBasicInfoTab from './IndividualExpertRecordBasicInfoTab';
import RecordModalRecruitmentInfoTab from './RecordModalRecruitmentInfoTab';
import IndividualExpertRecordDeliveryNotificationTab from './IndividualExpertRecordDeliveryNotificationTab';
import RecordCommentTab from './RecordCommentTab';
import IndividualExpertRecordBiographyTab from './IndividualExpertRecordBiographyTab';

const TabHeader = ({ activeTab, toggle }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === BASIC_INFORMATION.key,
        })}
        onClick={() => toggle(BASIC_INFORMATION.key)}
      >
        {BASIC_INFORMATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === RECRUITMENT_INFORMATION.key,
        })}
        onClick={() => toggle(RECRUITMENT_INFORMATION.key)}
      >
        {RECRUITMENT_INFORMATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === DELIVERY_AND_NOTIFICATION.key,
        })}
        onClick={() => toggle(DELIVERY_AND_NOTIFICATION.key)}
      >
        {DELIVERY_AND_NOTIFICATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === BIOGRAPHY.key })}
        onClick={() => toggle(BIOGRAPHY.key)}
      >
        {BIOGRAPHY.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === COMMENT.key })}
        onClick={() => toggle(COMMENT.key)}
      >
        {COMMENT.value}
      </NavLink>
    </NavItem>
  </Nav>
);

const IndividualExpertRecordModalBody = ({
  updateInput,
  updateError,
  data,
  errors,
  expertStatuses,
  expertStatusProspect,
  expertStatusApplicant,
  recruitmentMethods,
  referralCodes,
  termReason,
  expertDocumentTypes,
  notificationTypes,
  salutations,
  languages,
  honorifics,
}) => {
  const [activeTab, setActiveTab] = useState(BASIC_INFORMATION.key);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const targetName = target.name;
    updateInput({ [targetName]: value });
    validate(targetName, value);
  };

  const handleCustomInputChange = (id, value) => {
    updateInput({ [id]: value });
    validate(id, value);
  };

  const handleInputMaskChange = event => {
    const target = event.target;
    const value = target.value.replace(/[^0-9]/g, '');
    const targetName = target.name;
    updateInput({ [targetName]: value });
    validate(targetName, value);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const validate = (id, value) => {
    switch (id) {
      case 'firstName':
        validateFirstName(value);
        return;
      case 'lastName':
        validateLastName(value);
        return;
      case 'ssn':
        validateSsn(value);
        return;
      case 'tinNumber':
        validateTinNumber(value);
        return;
      case 'vendor':
        validateVendor(value);
        return;
      case 'cellphone':
        validateCellPhone(value);
        return;
      case 'pager':
        validatePager(value);
        return;
      default:
        return;
    }
  };

  const validateFirstName = value => {
    if (value === '') {
      updateError({
        firstName: 'The first name field is required.',
      });
      return;
    }
    updateError({
      firstName: '',
    });
  };

  const validateLastName = value => {
    if (value === '') {
      updateError({
        lastName: 'The last name field is required.',
      });
      return;
    }
    updateError({
      lastName: '',
    });
  };

  const validateSsn = value => {
    if (value === '') {
      updateError({
        ssn: '',
      });
      return;
    }
    if (value.length !== 9) {
      updateError({
        ssn: 'The Social Security number must be 9 digits.',
      });
      return;
    }

    if (value.indexOf('000') === 0) {
      updateError({
        ssn: 'The Social Security number cannot start with 000.',
      });
      return;
    }

    updateError({
      ssn: '',
    });
  };

  const validateTinNumber = value => {
    if (value !== '' && value.length !== 9) {
      updateError({
        tinNumber: 'The Tin number field must be 9 characters long.',
      });
      return;
    }
    updateError({
      tinNumber: '',
    });
  };

  const validateVendor = value => {
    if (value !== '' && !/^[0-9a-zA-Z]{5,6}/.test(value)) {
      updateError({
        vendor:
          'The vendor field must contain only digit or letters, have length between 5 and 6.',
      });
      return;
    }
    updateError({
      vendor: '',
    });
  };

  const validateCellPhone = value => {
    if (value !== '' && value.length !== 10) {
      updateError({
        cellphone: 'The Cellphone field must contain at least 10 digits.',
      });
      return;
    }
    updateError({
      cellphone: '',
    });
  };

  const validatePager = value => {
    if (value !== '' && value.length !== 10) {
      updateError({
        pager: 'The Pager field must contain at least 10 digits.',
      });
      return;
    }
    updateError({
      pager: '',
    });
  };

  return (
    <>
      <TabHeader activeTab={activeTab} toggle={toggle} />
      <TabContent activeTab={activeTab} style={{ paddingTop: '10px' }}>
        <TabPane tabId={BASIC_INFORMATION.key}>
          <IndividualExpertRecordBasicInfoTab
            data={data}
            errors={errors}
            onInputChange={handleInputChange}
            onCustomInputChange={handleCustomInputChange}
            onInputMaskChange={handleInputMaskChange}
            salutations={salutations}
            languages={languages}
            honorifics={honorifics}
          />
        </TabPane>
        <TabPane tabId={RECRUITMENT_INFORMATION.key}>
          <RecordModalRecruitmentInfoTab
            data={data}
            onInputChange={handleInputChange}
            onCustomInputChange={handleCustomInputChange}
            expertStatuses={expertStatuses}
            expertStatusProspect={expertStatusProspect}
            expertStatusApplicant={expertStatusApplicant}
            recruitmentMethods={recruitmentMethods}
            referralCodes={referralCodes}
            termReason={termReason}
            expertDocumentTypes={expertDocumentTypes}
          />
        </TabPane>
        <TabPane tabId={DELIVERY_AND_NOTIFICATION.key}>
          <IndividualExpertRecordDeliveryNotificationTab
            data={data}
            onInputChange={handleInputChange}
            onInputMaskChange={handleInputMaskChange}
            notificationTypes={notificationTypes}
            errors={errors}
          />
        </TabPane>
        <TabPane tabId={BIOGRAPHY.key}>
          <IndividualExpertRecordBiographyTab
            data={data}
            onInputChange={handleInputChange}
          />
        </TabPane>
        <TabPane tabId={COMMENT.key}>
          <RecordCommentTab data={data} onInputChange={handleInputChange} />
        </TabPane>
      </TabContent>
    </>
  );
};

IndividualExpertRecordModalBody.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  expertStatusProspect: PropTypes.array.isRequired,
  expertStatusApplicant: PropTypes.array.isRequired,
  recruitmentMethods: PropTypes.array.isRequired,
  referralCodes: PropTypes.array.isRequired,
  termReason: PropTypes.array.isRequired,
  expertDocumentTypes: PropTypes.array.isRequired,
  notificationTypes: PropTypes.array.isRequired,
  salutations: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  honorifics: PropTypes.array.isRequired,
  updateInput: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialDataState = expertInfoSelectors.getInitialData(state);
  const expertState = expertInfoSelectors.getExpertInfo(state);
  return {
    data: expertState.data,
    errors: expertState.errors,
    expertStatuses: expertState.expertStatuses,
    expertStatusProspect: expertState.expertStatusProspect,
    expertStatusApplicant: expertState.expertStatusApplicant,
    recruitmentMethods: expertState.recruitmentMethods,
    referralCodes: expertState.referralCodes,
    termReason: expertState.termReason,
    expertDocumentTypes: initialDataState.expertDocumentTypes,
    notificationTypes: expertState.notificationTypes,
    salutations: expertState.salutations,
    languages: expertState.languages,
    honorifics: expertState.honorifics,
  };
};

const mapDispatchToProps = dispatch => ({
  updateInput: data => dispatch(expertInfoActions.updateExpertInput(data)),
  updateError: data => dispatch(expertInfoActions.updateExpertInputError(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualExpertRecordModalBody);
