import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';

import TaskListContainer from './TaskListContainer';

library.add(faSync);

class TaskListNav extends Component {
  render() {
    return <TaskListContainer />;
  }
}

export default TaskListNav;
