import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Button, Row, FormGroup, Col, Input } from 'reactstrap';

import { commonOperations } from 'common/store';
import { assign } from 'lodash';

import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';

import Select from 'react-select';

import { billOperationsOperations, billOperationsSelectors } from '../store';

import { nurseAuditsType, codeReviewsType } from '../utils/constantUtils';

class CorrectionBillContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      correctionReason: '',
      selectedReason: '',
      isAO: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
    if (this.state.modal === false) {
      this.loadCorrectionReasons(this.props.selectedBills);
      this.checkIsAO();
      debugger;
    }
  };

  checkIsAO = () => {
    if(this.props.selectedBills.length > 0 && this.props.bills[0].enablePaycode === 1){
      this.setState({
        isAO: true,
      });
    }
  }

  onReasonChangeHandler = item => {
    this.setState({
      selectedReason: item.value + ": ",
    });
  };

  onInputChangeHandler = event => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  getBody() {
    const { reasonList } = this.props;
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label for="correctionReason">Reasons for Correction</Label>
            {this.state.isAO === true && (
              <Select
                visible
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                placeholder="Choose..."
                onChange={this.onReasonChangeHandler}
                name="correctionReasonList"
                id="correctionReasonList"
                options={reasonList.length
                  ? reasonList[0].map(obj =>
                    assign(obj, {
                      label: obj.description,
                      value: obj.description,
                    })
                  )
                  : []}
              />
            )}
            <Input
              name="correctionReason"
              id="correctionReason"
              value={this.state.correctionReason}
              onChange={this.onInputChangeHandler}
              placeholder="Reasons for Correction"
              type="textarea"
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  getFooter() {
    return (
      <Button type="button" color="primary" onClick={this.handleSave}>
        SAVE
      </Button>
    );
  }

  handleClick = () => {
    const { reviewType, selectedAdjusterName } = this.props;
    if (
      (reviewType === nurseAuditsType || reviewType === codeReviewsType) &&
      selectedAdjusterName === 'Generic List'
    ) {
      this.props.alert('You cannot modify bills from the Generic List');
      return;
    }
    if (reviewType === nurseAuditsType || reviewType === codeReviewsType) {
      this.toggle();
      return;
    }

    // const deniedBills = this.props.bills.filter(
    //   item =>
    //     this.props.selectedBills.includes(item.id) &&
    //     item.allowApproval !== true
    // );
    // if (deniedBills.length > 0) {
    //   this.props.alert(
    //     <span>
    //       The recommended reimbursement for following bills exceeds your
    //       approval threshold. This bill must be transferred to your supervisor
    //       for approval.
    //       <br></br>
    //       {deniedBills.map(item => item.id).join(',')}
    //     </span>
    //   );
    // }

    if (this.allowApprovalBills().length > 0) {
      this.toggle();
    }
  };

  handleSave = () => {
    // copy the logic from legacy app, we don’t really know the reason why reason's length must be <=5
    if (this.state.correctionReason.length <= 5 || (this.state.isAO === true && !this.state.selectedReason)) {
      this.props.alert('You must select and enter a reason.');
      return;
    }

    this.toggle();

    if (
      this.props.reviewType === nurseAuditsType ||
      this.props.reviewType === codeReviewsType
    ) {
      this.correctBills(this.props.selectedBills);
      return;
    }

    this.correctBills(this.allowApprovalBills());
  };

  allowApprovalBills() {
    return this.props.bills
      .filter(
        item =>
          this.props.selectedBills.includes(item.id)
        //&&
        //item.allowApproval === true
      )
      .map(item => item.id);
  }

  correctBills = bills => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
    } = this.props;
    this.props.correctBills({
      currentUserId,
      profile,
      selectedAdjusterId,
      name: selectedAdjusterName,
      reviewType,
      selectedBills: bills,
      reason: this.state.selectedReason + this.state.correctionReason,
    });
  };

  loadCorrectionReasons = data => {
    const { currentUserId, profile } = this.props;
    this.props.loadCorrectionReasons({
      currentUserId,
      profile,
      selectedBills: data,
    });
  };

  resetModal = () => {
    this.setState({
      correctionReason: '',
      selectedReason: '',
      isAO: false,
    });
  };

  render() {
    return (
      <>
        <PopupModal
          content={this.getBody()}
          title="Bill Correction Reasons"
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          footerContent={this.getFooter()}
          onClosed={this.resetModal}
          size="lg"
        />
        <Button
          type="button"
          onClick={this.handleClick}
          disabled={this.props.disabled}
          style={{ width: '100%' }}
          color="primary"
        >
          CORRECTION
        </Button>
      </>
    );
  }
}

CorrectionBillContainer.propTypes = {
  alert: PropTypes.func.isRequired,
  correctBills: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  bills: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  selectedAdjusterId: PropTypes.number.isRequired,
  selectedBills: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  reasonList: PropTypes.array,
  loadCorrectionReasons: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  return {
    bills: approveBillState.bills,
    profile: initialData.currentProfile,
    reviewType: approveBillState.reviewType,
    selectedAdjusterId: approveBillState.selectedAdjusterId,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
    selectedBills: approveBillState.selectedBills,
    currentUserId: user.id || -1,
    reasonList: approveBillState.correctionReasonList,
  };
};

const mapDispatchToProps = dispatch => ({
  correctBills: data => dispatch(billOperationsOperations.correctBills(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  loadCorrectionReasons: data =>
    dispatch(billOperationsOperations.loadCorrectionReasons(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrectionBillContainer);
