import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter, Row, Col } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions, fast360Selectors } from '../store';
import { commonOperations } from 'common/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { shortToDatabaseTable } from 'modules/fast-360/utils/dataUtils';

class Fast360DetailEmail extends Component {
    constructor(props) {
        super(props);
        this.selectedAttachments = [];
        this.state = {
            sendTo: "",
            copySelf: false,
            cCTo: "",
            from: "",
            subject: "",
            body: '',
            bodyHtml: '',
            bodySuffix: '',
            bodySuffixHtml: '',
            fromWorkListLoaded: false,
            emailList: [],
            selectedEmails: [],
            templateDropdownList: [],
            lmsDocumentsEmailLoaded: false,
            loadedLmsDocumentsEmail: [],
            loadedAttachments: [],
            reportTemplatesBodyLoaded: false,
            reportTemplatesBody: {},
            selectedTemplateItem: {},
            fetchedAttachment: false,
            fetchedTemplateBody: false,
            attachmentValidated: false,
            detailEmailSentChecked: false,
            detailEmailSendFailed: false,
            showEmailHintModal: false,
            bodyTouched: false
        }
    }
    componentWillUnmount() {
        this.props.clearReportTemplatesBody();
        this.props.clearLSMDOcumentsEmail();
        this.props.resetDetailEmailSent();
        this.props.clearAttachmentValidationResult();
        this.setState({ emailList: [], selectedEmails: [], sendTo: '', fromWorkListLoaded: false, reportTemplatesBodyLoaded: false, lmsDocumentsEmailLoaded: false, copySelf: false, modalClosed: true, loadedLmsDocumentsEmail: [] });
    }

    toggle = () => {
        this.setState({ emailList: [], selectedEmails: [], sendTo: '', fromWorkListLoaded: false, reportTemplatesBodyLoaded: false, lmsDocumentsEmailLoaded: false, copySelf: false, modalClosed: true, loadedLmsDocumentsEmail: [] });
        this.props.clicked()
        this.props.clearAttachmentValidationResult();
    };

    componentDidMount() {
        const { currentUserId, currentUser, payableLinesList } = this.props;
        const { currentWorkList: { chr_claim_no, claimantName } } = this.props;
        const { currentWorkList, serviceData } = this.props;

        let updatedTemplateDropdownList = [];
        if (serviceData.module === 'TRN' || serviceData.module === 'INT') {
            updatedTemplateDropdownList.push({ key: 'VendorDay', value: 'Vendor Auth / Agreement Confirmed' });
            updatedTemplateDropdownList.push({ key: 'TRNINTVendorReceivedOrderRequest', value: 'T&T - Vendor has received the order request - to vendor' });
            updatedTemplateDropdownList.push({ key: 'TRNINTVendorDayBeforeReminder', value: 'T&T - Vendor day before reminder - to vendor' });
            updatedTemplateDropdownList.push({ key: 'TRNINTVendorCompletedServices', value: 'T&T - Vendor completed services - to vendor' });

            if (serviceData.module === 'TRN') {
                updatedTemplateDropdownList.push({ key: 'TransportPatientPickup', value: 'Patient Picked Up' });

            } else if (serviceData.module === 'INT') {
                updatedTemplateDropdownList.push({ key: 'InterpreterArrived', value: "Interpreter Arrived" });
                updatedTemplateDropdownList.push({ key: 'INTPatientNoShow', value: "Patient No Show - INT" });
            }
        } else if (serviceData.module !== 'SPT') {
            updatedTemplateDropdownList.push({ key: 'OrderComplete', value: 'Order Complete' });
        }
        updatedTemplateDropdownList.push({ key: 'PatientDay', value: 'Patient Day Before Complete' });
        updatedTemplateDropdownList.push({ key: 'VendorAssignment', value: 'Vendor Assignment' });

        if (serviceData.module === 'MSE') {
            updatedTemplateDropdownList.push({ key: 'MSEvendorAssignProvider', value: 'Vendor Assign Provider / Send Intake PO to' });
            updatedTemplateDropdownList.push({ key: 'MSEvendorPOSend', value: 'Vendor PO Send' });
            updatedTemplateDropdownList.push({ key: 'MSEvendorReceiptPOConf', value: 'Vendor Receipt of PO Confirmation' });
            updatedTemplateDropdownList.push({ key: 'MSEvendorOrderStatus', value: 'Vendor Order Status Update' });
        }
        if (serviceData.module != "DTL" && serviceData.module != "HHC") {
            updatedTemplateDropdownList.push({ key: 'Authorization', value: 'Authorization Received' });
        }
        updatedTemplateDropdownList.push({ key: 'Quote', value: 'Written Authorization Request' });
        // updatedTemplateDropdownList.push('Quote (for customer use - FS/UC)');
        // updatedTemplateDropdownList.push('Quote (for customer use - OWCP)');

        if (serviceData.module == "TRN" || serviceData.module == "SPT" || serviceData.module == "INT") {

            updatedTemplateDropdownList.push({ key: 'TRNSPTINTMissedAppt', value: 'Missed Appt Notice/Unable to Staff' });
            updatedTemplateDropdownList.push({ key: 'TRNSPTINTPatientCancel', value: 'Patient Cancel-INT/TRN' });

            if (serviceData.module == "TRN" || serviceData.module == "SPT") {
                updatedTemplateDropdownList.push({ key: 'TRNSPTPatientNoShow', value: 'Patient No Show - TRN' });

            }
        }

        if (serviceData.module == "AUD" || serviceData.module == "DTL" || serviceData.module == "HHC" || serviceData.module == "MSE" || serviceData.module == "MOD") {
            updatedTemplateDropdownList.push({ key: 'CloseReferral', value: 'Closing Referral Due to Patient Not Responsive' });
        }

        if(payableLinesList.length > 0) {
            updatedTemplateDropdownList.push({ key: 'EOR', value: 'EOR' });
        }
 
    
 
        updatedTemplateDropdownList = updatedTemplateDropdownList.map(template => {
            return {
                label: template.value,
                value: template.key
            };
        });

        let bodySuffix = "\n\n----------------------------------------\n";
        bodySuffix += currentUser.firstname + " " + currentUser.lastname;
        bodySuffix += "\n";
        if (currentUser.phone !== "") {
            bodySuffix += currentUser.phone;
            bodySuffix += "\n";
        }

        bodySuffix += currentUser.location;
        bodySuffix += "\n";
        bodySuffix += currentUser.email;

        let bodySuffixHtml = "<br><br><br>\n\n----------------------------------------\n";
        bodySuffixHtml += currentUser.firstname + " " + currentUser.lastname;
        bodySuffixHtml += "\n";
        if (currentUser.phone !== "") {
            bodySuffixHtml += currentUser.phone;
            bodySuffixHtml += "\n";
        }

        bodySuffixHtml += currentUser.location;
        bodySuffixHtml += "\n";
        bodySuffixHtml += currentUser.email;

        this.setState({ ...this.state, bodySuffix: bodySuffix, bodySuffixHtml: bodySuffixHtml, body: bodySuffix, from: currentUser.email, subject: `${claimantName} - ${chr_claim_no}`, templateDropdownList: updatedTemplateDropdownList });

        if (this.props.serviceData && this.props.serviceData.referralId && this.props.serviceData.module && this.props.serviceData.serviceId) {
            this.loadAttachments();
        }

    }


    loadAttachments = () => {
        if (this.state.lmsDocumentsEmailLoaded) {
            return;
        }
        // {
        //     "functionName": "loadLMSDocumentsEmail",
        //     "zebraUserId": "14951",
        //     "referral_id": "70314",
        //     "referral_service_id":"30461",
        //     "tablename":"TRN"
        // }

        const req = {
            functionName: "loadLMSDocumentsEmail",
            userId: this.props.currentUserId,
            referralId: this.props.currentWorkList && this.props.currentWorkList.FK_referral_id ? this.props.currentWorkList.FK_referral_id : null,
            serviceId: this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId : null,
            refTable: this.props.serviceData && this.props.serviceData.module ? shortToDatabaseTable(this.props.serviceData.module) : null
        }


        this.props.getLMSDocumentsEmail(req);

    }

    validateAttachments = (attachments) => {

        // {
        //     "functionName": "loadCCDocumentData",
        //     "zebraUserId": "13461",
        //     "fileName": "1622736520891.pdf",
        //     "description": "testFromz3",
        //     "path": "20210603/1622736520891.pdf"
        // }

        if (attachments && attachments.length > 0) {
            const req = {
                functionName: "loadCCDocuments",
                zebraUserId: this.props.currentUserId,
                referral_id: this.props.currentWorkList && this.props.currentWorkList.FK_referral_id ? this.props.currentWorkList.FK_referral_id : null,
                referral_service_id: this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId : null,
                tablename: this.props.serviceData && this.props.serviceData.module ? shortToDatabaseTable(this.props.serviceData.module) : null
            }
            this.props.getValidAttachment(req);

        }



        // if(attachments && attachments.length > 0) {
        //     attachments.forEach(attachment => {

        //         const req = {
        //         "functionName": "loadCCDocumentData",
        //         "zebraUserId": this.props.currentUserId,
        //         "fileName": attachment.path.substring(attachment.path.lastIndexOf('/') + 1),
        //         "description": attachment.description,
        //         "path": attachment.path
        //         }
        //         this.props.getValidAttachment(req);
        //     })

        // }


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.serviceData && this.props.serviceData.referralId && this.props.serviceData.module && this.props.serviceData.serviceId && !this.state.fetchedTemplateBody) {
            this.setState({ fetchedTemplateBody: true })
            this.fetchTemplateBody();
        }
        if (this.props.reportTemplatesBody && Object.keys(this.props.reportTemplatesBody).length !== 0 && !this.state.reportTemplatesBodyLoaded) {
            const updatedTemplateDropdownList = [...this.state.templateDropdownList ];
            if(this.props.payableLinesList.length > 0 && this.props.reportTemplatesBody['EOR'] !== undefined)  {
                updatedTemplateDropdownList.push({ label: 'EOR', value: 'EOR' });
            }
            
            this.setState({ 
                reportTemplatesBodyLoaded: true, 
                reportTemplatesBody: this.props.reportTemplatesBody,
                templateDropdownList : updatedTemplateDropdownList 
            });
            this.handleTemplateDropdownChange(this.state.selectedTemplateItem);
        }


        if (this.props.lmsDocumentsEmail && this.props.lmsDocumentsEmail.length > 0 && prevProps.lmsDocumentsEmail !== this.props.lmsDocumentsEmail) {
            const attachmenets = [];
            this.props.lmsDocumentsEmail.forEach(attachment => {

                if (attachment.path === null || attachment.path === undefined) {
                    return;
                }

                attachmenets.push({
                    value: attachment.path,
                    label: attachment.logical_name !== '' ? attachment.logical_name : attachment.path,
                    id: attachment.id
                });
            })

            this.setState({ loadedLmsDocumentsEmail: attachmenets, lmsDocumentsEmailLoaded: true }, () => {
                this.validateAttachments(this.props.lmsDocumentsEmail);
            });
        }

        if (this.props.attachmentValidationResult && this.state.lmsDocumentsEmailLoaded && this.props.attachmentValidationResult.length > 0 && !this.state.attachmentValidated) {
            //console.log('Item', this.props.attachmentValidationResult);
            if (this.props.attachmentValidationResult.length === this.state.loadedLmsDocumentsEmail.length) {
                const updatedAttachment = [];
                this.props.attachmentValidationResult.forEach(item => {
                    const index = this.state.loadedLmsDocumentsEmail.findIndex(itm => itm.value == item.path);
                    if (index !== -1 && item.bol_active === true) {
                        updatedAttachment.push(this.state.loadedLmsDocumentsEmail[index]);
                    }
                })

                this.setState({ attachmentValidated: true, loadedLmsDocumentsEmail: updatedAttachment });
            }
        }

        if (!this.state.fromWorkListLoaded) {
            if (Object.keys(this.props.fromWorkList).length !== 0) {
                const emailList = [];
                if (this.props.fromWorkList?.adjuster && this.props.fromWorkList?.adjuster?.people?.email1 && this.props.fromWorkList?.adjuster?.people?.email1 !== '' && this.props.fromWorkList?.adjuster?.people?.email1 != 'NA') {
                    const adj = this.props.fromWorkList?.adjuster?.people;
                    emailList.push({ key: 'adjuster', name: `${adj.first} ${adj.last} - Adjuster`, email: adj.email1 })
                }
                if (this.props.fromWorkList?.referrer && this.props.fromWorkList?.referrer?.people?.email1 && this.props.fromWorkList?.referrer?.people?.email1 !== '' && this.props.fromWorkList?.referrer?.people?.email1 !== 'NA') {
                    const referrer = this.props.fromWorkList?.referrer?.people;
                    emailList.push({ key: 'referrer', name: `${referrer.first} ${referrer.last} - Referrer`, email: referrer.email1 })
                }
                if (this.props.fromWorkList?.claim && this.props.fromWorkList?.claim?.claimant?.people?.email1 && this.props.fromWorkList?.claim?.claimant?.people?.email1 !== '' && this.props.fromWorkList?.claim?.claimant?.people?.email1 !== 'NA') {
                    const claimant = this.props.fromWorkList?.claim?.claimant?.people;
                    emailList.push({ key: 'patient ', name: `${claimant.first} ${claimant.last} - Patient`, email: claimant.email1 })
                }
                if (this.props.fromWorkList?.claim && this.props.fromWorkList?.claim?.attorney?.people?.email1 && this.props.fromWorkList?.claim?.attorney?.people?.email1 !== '' && this.props.fromWorkList?.claim?.attorney?.people?.email1 !== 'NA') {
                    const attorney = this.props.fromWorkList?.claim?.attorney?.people;
                    emailList.push({ key: 'attorney', name: `${attorney.first} ${attorney.last} - Attorney`, email: attorney.email1 })
                }
                if (this.props.fromWorkList?.physician && this.props.fromWorkList?.physician?.people?.email1 && this.props.fromWorkList?.physician?.people?.email1 !== '' && this.props.fromWorkList?.physician?.people?.email1 !== 'NA') {
                    const physician = this.props.fromWorkList?.physician?.people;
                    emailList.push({ key: 'physician', name: `${physician.first} ${physician.last} - Physician`, email: physician.email1 })
                }
                if (this.props.fromWorkList?.payor && this.props.fromWorkList?.payor?.primaryContact?.people?.email1 && this.props.fromWorkList?.payor?.primaryContact?.people?.email1 !== '' && this.props.fromWorkList?.payor?.primaryContact?.people?.email1 !== 'NA') {
                    const payor = this.props.fromWorkList?.payor?.primaryContact?.people;
                    emailList.push({ key: 'payer', name: `${payor?.primaryContact?.first} ${payor?.primaryContact?.last} - Payor`, email: payor?.primaryContact?.email1 })
                }

                this.setState({ emailList: emailList, fromWorkListLoaded: true });
            }
        }

        if (this.props.detailEmailRespnse && !this.state.detailEmailSentChecked) {
            if (this.props.detailEmailSent) {
                this.setState({ emailResponseModalContent: 'Email sent successfully', showEmailResponseModal: true, detailEmailSendFailed: false });

            } else {
                this.setState({ emailResponseModalContent: 'Email sending failed', showEmailResponseModal: true, detailEmailSendFailed: true });
            }
            this.setState({ detailEmailSentChecked: true });
        }
    }


    bodyOnChange = (e) => {
        //let updatedBodyHtml = e.replace(this.state.bodySuffix, '');
        let updatedBodyHtml = e.replaceAll('\n', '<br />');
        this.setState({ bodyHtml: updatedBodyHtml, body: e, bodyTouched: true });
    }

    setSendTo = (e) => {
        // 165277 dyu - Reworking how the emails are parsed
        const updatedSelectedEmails = [];
        if (e != "") {
            const splitEmails = e.replace(';',',').split(',');
            splitEmails.forEach(email => {
               let emailObj = {
                key: email,
                email: email
               } 
               updatedSelectedEmails.push(emailObj);
            });
        }
        this.setState({ selectedEmails: updatedSelectedEmails, sendTo: e.replace(';',',') });
    }

    setCCTo = (e) => {
        this.setState({ cCTo: e })
    }

    setFrom = (e) => {
        this.setState({ from: e })
    }

    setSubject = (e) => {
        this.setState({ subject: e })
    }

    handleRecipientClick = (event) => {
        const val = event.target.value;
        if (val === "0") {
            this.setState({ sendTo: "", selectedEmails: [] });
            return;
        }
        const updatedSelectedEmails = [...this.state.selectedEmails];
        const selectedEmail = [...this.state.emailList].find(user => user.key === val);
        const index = updatedSelectedEmails.findIndex(el => el.key === selectedEmail.key);
        if (index === -1 && selectedEmail) {
            updatedSelectedEmails.push(selectedEmail);
        }
        const emailsSting = updatedSelectedEmails.map(e => e.email).join(', ');
        this.setState({ sendTo: emailsSting, selectedEmails: updatedSelectedEmails });
        //this.props.info(["Press the CTRL key and use the mouse to select multiple recipients.", "Add additional users by separating email addresses with a comma"]);
    }
    handleRemoveRecipient = (key) => {
        const updatedSelectedEmails = [...this.state.selectedEmails];
        const index = updatedSelectedEmails.findIndex(el => el.key === key);
        if (index >= 0) {
            updatedSelectedEmails.splice(index, 1);
        }
        const emailsSting = updatedSelectedEmails.map(e => e.email).join(', ');
        this.setState({ sendTo: emailsSting, selectedEmails: updatedSelectedEmails });
    }

    handleSelectAttachmentClick = (option) => {

        const op = { ...option };
        const updatedAttchments = [...this.state.loadedAttachments.map(at => ({ ...at }))];
        const index = updatedAttchments.findIndex(el => el.id === op.id);
        if (index >= 0) {
            return;
        }
        updatedAttchments.push(op);
        this.setState({ loadedAttachments: updatedAttchments });
        // this.props.info("Press the CTRL key and use the mouse to select multiple files");
    }

    handleDropdownHint = () => {
        this.setState({ showEmailHintModal: true })

    }

    handleDelAttach = (item) => {
        // return;
        // if (this.selectedAttachments.length === 0) {
        //     return;
        // }
        let updatedAttchments = [...this.state.loadedAttachments.map(at => ({ ...at }))];
        updatedAttchments = updatedAttchments.filter(attach => attach.label !== item.id);
        // this.selectedAttachments.forEach(attachment => {
        // })
        this.setState({ loadedAttachments: updatedAttchments });
    }

    handleSelectRowsHandler = (attachments) => {
        const selectedAttachments = [...attachments.map(attach => ({ ...attach }))];
        // const updatedAttchments =  [...this.state.loadedAttachments.map(at => ({...at}))];

        // selectedAttachments.forEach(attachment => {
        //     let i = updatedAttchments.findIndex(attach => attach.id === attachment.id);

        //     if(i >= 0) {
        //         updatedAttchments[i].selected = true;
        //     }
        // });

        this.setState({ selectedAttachments: selectedAttachments });
    }

    handleCopy = () => {
        this.setState((prevState) => {
            return { ...prevState, copySelf: !prevState.copySelf };
        }, () => {
            if (this.state.copySelf) {
                this.setState({ ...this.state, cCTo: this.state.from })
            } else {
                this.setState({ ...this.state, cCTo: '' })
            }
        })

    }

    handleSend = () => {
        let breadCrumb = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": this.props.currentUserId,
            "userid": this.props.currentUserId,
            "breadcrumbkey": 'SENDEMAIL',
            "refTableId": this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId : null,
            "refId": this.props.serviceData && this.props.serviceData.referralId ? this.props.serviceData.referralId : null,
            "refTable": this.props.serviceData && this.props.serviceData.module ? this.props.serviceData.module : null,
        };

        this.props.logBreadCrumbs(breadCrumb)
        // {
        //     "functionName": "sendEmailWithAttachments",
        //     "zebraUserId": 14812,
        //     "from": "Transportation@careworks.com",
        //     "to": [
        //     "raghava.reddy@sedgwick.com"
        //     ],
        //     "cc": [
        //     "siddartha.nagabhairava@sedgwick.com"
        //     ],
        //     "filePathList": ["20210603/1622736520891.pdf"
        //     ],
        //     "subject": "Test subject",
        //     "body": "SAMPLE "
        //     }
        let toEmail;
        toEmail = this.state.selectedEmails.map(selectedEmail => selectedEmail.email);
        if (toEmail.length > 0) {
            if (toEmail[0] === undefined) {
                toEmail = this.state.sendTo;
            }
        } else {
            alert('No Email selected or entered.');
            return;
        }
        let finalBody = this.state.bodyTouched ? this.state.bodyHtml : (this.state.bodyHtml + this.state.bodySuffixHtml);
        finalBody = finalBody.replaceAll('\n', '<br />');
        //finalBody = finalBody.replaceAll('\r', '');
        let toEmailList = [];
        if (Array.isArray(toEmail)) {
            toEmail.forEach(email => {                
                email = email.replaceAll(';',',')
                if (email.indexOf(",") > -1) {
                    toEmailList.push(email.split(","));
                } else {
                    toEmailList.push(email);
                }
            })
            toEmailList = toEmail;
        } else {
            toEmailList = toEmail.replaceAll(';',',').split(',')
        }
        const req = {
            functionName: "sendEmailWithAttachments",
            zebraUserId: this.props.currentUserId,
            from: this.state.from,
            to: toEmailList,
            cc: this.state.cCTo.replaceAll(';', ',').split(','),
            filePathList: this.state.loadedAttachments.map(a => a.value),
            subject: this.state.subject,
            //body: (this.state.bodyHtml + this.state.bodySuffixHtml).replaceAll("\r\n", "<br/>"),
            body: finalBody
        }

        if (this.state.from === "" || (this.state.selectedEmails && this.state.selectedEmails.length === 0) || this.state.subject === "" || this.state.body === "") {
            // show send error modal
            this.setState({ emailResponseModalContent: 'Email sending failed', showEmailResponseModal: true, detailEmailSendFailed: true });
        } else {
            // show email success modal

            this.props.sendDetailEmail(req);
            this.addNote();

        }
    }

    addNote = () => {
        const itemNotes = [];
        const newNote = {
            'userName': `${this.props.userName.firstname} ${this.props.userName.lastname}`,
            'enteredDate': new Date(),
            'note': `From: ${this.state.from} \r\nTo: ${this.state.sendTo} \r\nSubject: ${this.state.subject} \r\nBody:\r\n${this.state.body}`,
            'noteType': 'General',
            'canClientSee': false,
            "id": null,
            "referenceTable": this.props.currentWorkList.chr_reference_table,
            "referenceId": this.props.currentWorkList.FK_referral_id,
            "internalUse": true,
            "userId": (this.props.currentWorkList?.new_id === 1111 || this.props.currentWorkList?.new_id === 2222) ? this.props.roleUsers.filter(i => i.zebraId == this.props.currentUserId)[0].id : this.props.currentWorkList.FK_assigned_user_id
        };
        itemNotes.push(newNote);
        let saveNotesPayload = {
            "functionName": "saveNotes",
            "zebraUserId": this.props.currentUserId,
            "serviceId": this.props.currentWorkList.int_reference_table_id,
            notes: itemNotes
        }
        this.props.saveNotesData(saveNotesPayload);
    }

    fetchTemplateBody = () => {
        // {
        //     "functionName": "getEmailReportTemplates",
        //     "zebraUserId": "13461",
        //     "referralId": 14777,
        //     "tableType": "DTL",
        //     "serviceId": 9,
        //     "subService": "",
        //     "vendorTotal": "222.49"
        // }
        const req = {
            functionName: "getEmailReportTemplates",
            zebraUserId: this.props.currentUserId,
            referralId: this.props.serviceData && this.props.serviceData.referralId ? this.props.serviceData.referralId : null,
            tableType: this.props.serviceData && this.props.serviceData.module ? this.props.serviceData.module : null,
            serviceId: this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId : null,
            subService: "",
            vendorTotal: null,
        }

        if (req.referralId == null || req.referralId == 0) {
            return;
        }

        this.props.getReportTemplatesBody(req);

    }

    handleTemplateDropdownChange = (item) => {
        // if(!this.state.reportTemplatesBodyLoaded) {
        //     this.setState({selectedTemplateItem: item});
        //     this.fetchTemplateBody();
        // } else {
        // }
        const templateBody = this.state.reportTemplatesBody[item.value] ? this.state.reportTemplatesBody[item.value] : "";
        this.setState({ body: templateBody + this.state.bodySuffix, bodyHtml: templateBody, bodyTouched: false });
    }

    getEmailConfirmationFooter = () => {
        return (
            <div className="d-flex flex-row justify-content-center w-100 m-0">
                <Button
                    color="primary"
                    className="px-5"
                    onClick={() => {
                        this.setState({ showEmailResponseModal: false })
                        setTimeout(() => {
                            if (!this.state.detailEmailSendFailed) {
                                this.toggle();
                            }
                        }, 200);
                    }}
                >
                    OK
                </Button>
            </div>
        );
    };

    getEmailHintFooter = () => {
        return (
            <div className="d-flex flex-row justify-content-center w-100 m-0">
                <Button
                    color="primary"
                    className="px-5"
                    onClick={() => {
                        this.setState({ showEmailHintModal: false })

                    }}
                >
                    OK
                </Button>
            </div>
        );
    }

    render() {
        return (
            <>
                <Modal size="lg" isOpen={this.props.isOpen}>
                    <ModalHeader toggle={this.toggle}> Email or Print </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <select
                                        className="basic-single form-control"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder="Select Recipients"
                                        name="recipients"
                                        id="recipients"
                                        onChange={this.handleRecipientClick}
                                    >
                                        <option value={0} selected>Select Recipients</option>
                                        {this.state.emailList.map(user => {
                                            return <option key={user.key} value={user.key}>{user.name}</option>
                                        })}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                                <FontAwesomeIcon icon={faQuestionCircle}
                                    onClick={this.handleDropdownHint} style={{ cursor: 'pointer' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                            </Col>
                            <Col lg={9} className="d-flex align-itmes-center">
                                {this.state.selectedEmails.map(user => {
                                    return <a href="#" style={{ fontSize: '0.9em', marginRight: '2px', marginBottom: '.5em', fontWeight: '400' }} key={user.key} className="badge badge-primary" onClick={(e) => this.handleRemoveRecipient(user.key)}>{user.email}  <FontAwesomeIcon icon={faWindowClose} /></a>
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">To:
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="sendTo"
                                        id="sendTo"
                                        value={this.state.sendTo}
                                        onChange={e => this.setSendTo(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                                <FontAwesomeIcon icon={faWindowClose}
                                    onClick={e => this.setSendTo("")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={10}>
                                <FormGroup>
                                    <Input type="checkbox" onChange={this.handleCopy} />
                                    Send copy of email to myself
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">Cc:
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="ccTo"
                                        id="ccTo"
                                        value={this.state.cCTo}
                                        onChange={e => this.setCCTo(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                                <FontAwesomeIcon icon={faWindowClose}
                                    onClick={e => this.setCCTo("")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">From:
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="from"
                                        id="from"
                                        value={this.state.from}
                                        onChange={e => this.setFrom(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">Subject:
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        value={this.state.subject}
                                        onChange={e => this.setSubject(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">Template
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder="Select E-Mail Body Template"
                                        name="templates"
                                        id="templates"
                                        onChange={this.handleTemplateDropdownChange}
                                        options={this.state.templateDropdownList}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className="text-right">Email Body:
                            </Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        name="emailBody"
                                        id="emailBody"
                                        rows={10}
                                        spellCheck
                                        onChange={e => this.bodyOnChange(e.target.value)}
                                        value={this.state.body}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder="Select Files to attach"
                                        name="selectAttachments"
                                        id="selectAttachments"
                                        onChange={this.handleSelectAttachmentClick}
                                        // onMenuOpen={() => console.log(this.state.loadedLmsDocumentsEmail)}
                                        options={this.state.loadedLmsDocumentsEmail}

                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                                <FontAwesomeIcon icon={faQuestionCircle}
                                    onClick={() => { }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={9}>
                                <FormGroup>
                                    <AppTable
                                        columns={[
                                            {
                                                header: 'Id',
                                                rowKey: true,
                                                field: 'id',
                                            },
                                            {
                                                header: 'Delete',
                                                render: item => <FontAwesomeIcon icon={faWindowClose} onClick={() => this.handleDelAttach(item)} />
                                            }
                                        ]}
                                        // data={[{'id':'File Attachment List...'}]}
                                        data={this.state.loadedAttachments.map(att => ({ id: att.label }))}
                                        striped
                                        modalHeight={150}
                                        showHeader={false}
                                        selectHandler={(val) => {
                                            this.selectedAttachments = val
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col lg={1}>
                            <FontAwesomeIcon icon={faWindowClose}
                                onClick={this.handleDelAttach} />
                        </Col> */}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '120px' }} color="primary" onClick={this.toggle}>Cancel</Button>
                        <Button style={{ width: '240px' }} color="primary" onClick={this.handleSend} >Send Email</Button>
                    </ModalFooter>
                </Modal>
                <PopupModal
                    title=""
                    content={
                        <div className="my-2 d-flex justify-content-center">
                            <span>{this.state.emailResponseModalContent}</span>
                        </div>}
                    isOpen={this.state.showEmailResponseModal}
                    externalToggle={() => {
                        this.setState({ showEmailResponseModal: false })
                        setTimeout(() => {
                            if (!this.state.detailEmailSendFailed) {
                                this.toggle();
                            }
                        }, 200);

                    }}
                    footerContent={this.getEmailConfirmationFooter()}
                    size="lg"
                />

                <PopupModal
                    title=""
                    content={
                        <div className="my-2 d-flex flex-column justify-content-center text-center">
                            <span>Add multiple emails by selecting users from dropdown.</span>
                            <span>Add additional users emails by separating email addresses with a comma.</span>
                        </div>}
                    isOpen={this.state.showEmailHintModal}
                    externalToggle={() => {
                        this.setState({ showEmailHintModal: false })
                    }}
                    footerContent={this.getEmailHintFooter()}
                    size="md"
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    const user = authSelectors.getUser(state);
    const fast360Info = fast360Selectors.getFast360Info(state);
    const roleUsers = state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [];
    return {
        currentUserId: userId,
        currentUser: user,
        currentWorkList: fast360Info.currentWorkListItem,
        fromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList ? state.FAST360.fast360Reducer.updateLoadFromWorkList : {},
        lmsDocumentsEmail: state.FAST360.fast360Reducer.lmsDocumentsEmail ? state.FAST360.fast360Reducer.lmsDocumentsEmail : [],
        reportTemplatesBody: state.FAST360.fast360Reducer.reportTemplatesBody ? state.FAST360.fast360Reducer.reportTemplatesBody : {},
        attachmentValidationResult: state.FAST360.fast360Reducer.attachmentValidationResult ? state.FAST360.fast360Reducer.attachmentValidationResult : [],
        // audInfo: fast360Info.audInfo,
        // patientInfo: fast360Info.patientZip
        serviceData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData : {},
        detailEmailSent: state.FAST360.fast360Reducer.detailEmailSent ? state.FAST360.fast360Reducer.detailEmailSent : false,
        detailEmailRespnse: state.FAST360.fast360Reducer.detailEmailRespnse ? state.FAST360.fast360Reducer.detailEmailRespnse : false,
        userName: state.AUTH.user,
        roleUsers,
        payableLinesList: state.FAST360.fast360Reducer.payableLinesList,
    }
}

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    info: msg => dispatch(commonOperations.addAlert(msg, 'info')),
    getLMSDocumentsEmail: data => dispatch(fast360Operations.getLMSDocumentsEmail(data)),
    sendDetailEmail: data => dispatch(fast360Operations.sendDetailEmail(data)),
    getReportTemplatesBody: data => dispatch(fast360Operations.getReportTemplatesBody(data)),
    clearLSMDOcumentsEmail: () => dispatch(fast360Operations.clearLSMDOcumentsEmail()),
    clearAttachmentValidationResult: () => dispatch(fast360Operations.clearAttachmentValidationResult()),
    clearReportTemplatesBody: () => dispatch(fast360Operations.clearReportTemplatesBody()),
    getValidAttachment: data => dispatch(fast360Operations.getValidAttachment(data)),
    resetDetailEmailSent: () => dispatch(fast360Actions.resetDetailEmailSent()),
    logBreadCrumbs: data =>
        dispatch(fast360Operations.logBreadCrumbs(data)),
    saveNotesData: (data) => dispatch(fast360Operations.saveNotesData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360DetailEmail);