import { createSelector } from 'reselect';
import { billReviewSelectors } from '../../bill-review/store';

const getBillOperations = createSelector(
  billReviewSelectors.getModule,
  state => state.billOperation
);

const getBillReview = createSelector(
  getBillOperations,
  state => state.billReview
);
const getInitialData = createSelector(
  getBillReview,
  state => state.initialData
);
const getFindBills = createSelector(
  getBillOperations,
  state => state.findBills
);
const getFindBillsGridSettingsState = createSelector(
  getFindBills,
  state => state.settingColumns
);

const getTaskList = createSelector(getBillOperations, state => state.taskList);
const getApproveBillState = createSelector(
  getTaskList,
  state => state.approveBill
);
const getQABillState = createSelector(getTaskList, state => state.qaBill);
const getRecentActivityState = createSelector(
  getTaskList,
  state => state.recentActivity
);
const getTaskListGridSettingsState = createSelector(
  getTaskList,
  state => state.gridSettings
);
const getReferralState = createSelector(
  getBillOperations,
  state => state.referral
);
const getDemandState = createSelector(
  getBillOperations,
  state => state.demand
);

export default {
  getBillReview,
  getApproveBillState,
  getFindBills,
  getInitialData,
  getQABillState,
  getRecentActivityState,
  getTaskListGridSettingsState,
  getFindBillsGridSettingsState,
  getReferralState,
  getDemandState,
};
