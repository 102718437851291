import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Button, Label, Input } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';
import AppDateInput from 'shared/components/input/AppDateInput';
import SearchableSelect from 'shared/components/input/SearchableSelect';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

class AddPPOSetupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      description: '',
      lineOfBusiness: '',
      network: '',
      rank: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    };
  }

  onChangeHandler = object => {
    this.setState({
      [object.key]: object.value,
    });
  };

  onAddNewData = () => {
    const {
      description,
      lineOfBusiness,
      network,
      rank,
      state,
      effective,
      terminated,
    } = this.state;

    if (lineOfBusiness === '') {
      return;
    }

    const siteId = this.props.siteDetails.id || 0;
    if (siteId === 0) {
      return;
    }

    const id =
      Math.min(
        Math.min(...this.props.data.map(item => (item.fields || {}).id || 0)),
        0
      ) - 1;

    let newRow = {
      fields: {
        id,
        ppoRank: rank,
        ppoId: network,
        dateRange: {
          effDate: effective,
          termDate: terminated,
        },
        name: description,
        state: state,
        lob: {
          fields: {
            lob: lineOfBusiness,
          },
          requiredFields: [],
        },
      },
      requiredFields: [],
    };
    this.props.addNewData(newRow);
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  resetModal = () => {
    this.setState({
      description: '',
      lineOfBusiness: '',
      network: '',
      rank: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    });
  };

  getModalFooter() {
    return (
      <>
        <Button type="button" onClick={this.onAddNewData}>
          ADD
        </Button>
      </>
    );
  }

  getModalBody() {
    const { ppoList, stateList, lobList } = this.props;
    return (
      <>
        <Row form>
          <Col lg="8">
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="text"
                onChange={event =>
                  this.onChangeHandler({
                    key: event.target.id,
                    value: event.target.value,
                  })
                }
                id="description"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="lineOfBusiness">Line of Business</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="lineOfBusiness"
                data={lobList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="3">
            <FormGroup>
              <Label for="network">Network</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="network"
                data={ppoList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>

          <Col lg="1">
            <FormGroup>
              <Label for="rank">Rank</Label>
              <Input
                type="text"
                onChange={event =>
                  this.onChangeHandler({
                    key: event.target.id,
                    value: event.target.value,
                  })
                }
                id="rank"
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label for="state">State</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="key"
                id="state"
                data={stateList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="effective">Effective</Label>
              <AppDateInput
                id="effective"
                onChange={value =>
                  this.onChangeHandler({ key: 'effective', value })
                }
                selected={this.state.effective}
                minDate={new Date()}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="terminated">Terminated</Label>
              <AppDateInput
                id="terminated"
                onChange={value =>
                  this.onChangeHandler({ key: 'terminated', value })
                }
                selected={this.state.terminated}
                minDate={new Date()}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        <PopupModal
          content={this.getModalBody()}
          title="Add Site Detail"
          externalToggle={this.toggleModal}
          isOpen={this.state.modalOpen}
          footerContent={this.getModalFooter()}
          onClosed={this.resetModal}
          size="lg"
        />
        <Button type="button" onClick={this.toggleModal}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </>
    );
  }
}

AddPPOSetupButton.propTypes = {
  data: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  addNewData: PropTypes.func.isRequired,
  siteDetails: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const initialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  return {
    ppoList: initialData.clientComboBoxes.ppoList,
    stateList: initialData.clientComboBoxes.stateList,
    lobList: initialData.clientComboBoxes.lobList,
    siteDetails: clientMaintenanceState.siteDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNewData: data => dispatch(billMaintenanceActions.addPPOSetup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPPOSetupButton);
