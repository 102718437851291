import React from 'react';
import { Button, Form } from 'reactstrap';
import PopupModal from 'shared/components/PopupModal';

const AboutUsModal = ({ isOpen, onToggle }) => {
  const getContent = () => {
    return (
      <Form>
        <span style={{wordBreak: 'break-word'}}>
          Access to the website is restricted and is controlled based upon user identity and password.
          To obtain access to the information in this website, you need to be an authorized user. This website
          is monitored for unauthorized activity, and any unauthorized users will be prosecuted.
        </span>
        <br/>
        <br/>
        <span style={{wordBreak: 'break-word'}}>
          All rights reserved. No part of this information may be reproduced in any form or by an electronic or mechanical
          means including information storage and retrieval systems without the written permission of Sedgwick Claims Management 
          Services, Inc.
        </span>
        <br/>
        <br/>
        <Button
          outline
          color="primary"
          style={{ marginLeft: '210px', marginRight: '210px' }}
        >
          Ok{' '}
        </Button>
      </Form>
    );
  };

  return (
    <PopupModal
      content={getContent()}
      externalToggle={onToggle}
      title={"About Us"}
      isOpen={isOpen}
      size="md"
    />
  );
}
export default AboutUsModal;