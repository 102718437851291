import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { commonOperations } from 'common/store';

import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';
import { isValidEmail } from 'shared/utils/shareUtils';

import '../assets/expert-info.scss';

import ExpertInfo from '../ExpertInfo';

import { expertInfoOperations, expertInfoSelectors } from '../store';

import { getElementFromId } from '../utils/expertInfoUtils';
import { APPLICANT, PROSPECT } from '../utils/constantUtils';

import IndividualExpertRecordModalBody from './IndividualExpertRecordModalBody';
import RecordModalFooter from './RecordModalFooter';

const IndividualExpertContainer = ({
  match,
  currentUserId,
  loadInitialData,
  loadExpert,
  saveExpert,
  alert,
  data,
  errors,
  expertStatuses,
}) => {
  const [modal, setModal] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      const id = match.params.id;
      loadExpert({
        expertId: id,
      });
    }

    if (currentUserId && currentUserId !== 0) {
      loadInitialData(currentUserId);
    }
  }, [match, currentUserId, loadExpert, loadInitialData]);

  const toggle = () => {
    setModal(!modal);
  };

  const save = () => {
    if (data.firstName === '') {
      alert('Please enter a first name.');
      return;
    }

    if (data.lastName === '') {
      alert('Please enter a last name.');
      return;
    }

    const expertStatus = getElementFromId(expertStatuses, data.expertStatus);
    if (
      Number(data.expertStatusStep) === Number(-1) &&
      (expertStatus === PROSPECT || expertStatus === APPLICANT)
    ) {
      alert('Please select a Status Step Progress.');
      return;
    }

    if (
      (data.email1 !== '' && !isValidEmail(data.email1)) ||
      (data.email2 !== '' && !isValidEmail(data.email2))
    ) {
      alert(
        <span>
          Some problems were found:
          {!isValidEmail(data.email1) && data.email1 !== '' && (
            <span>
              <br />
              The primary email address is invalid.
            </span>
          )}
          {!isValidEmail(data.email2) && data.email2 !== '' && (
            <span>
              <br />
              The secondary email address is invalid.
            </span>
          )}
        </span>
      );
      return;
    }

    const inputErrors = Object.keys(errors).filter(
      key => errors[key].length > 0
    );

    if (inputErrors.length > 0) {
      alert(
        <span>
          The following problem(s) occurred:
          {inputErrors.map((key, index) => (
            <span key={index}>
              <br />
              {errors[key]}
            </span>
          ))}
        </span>
      );
      return;
    }

    saveExpert({
      ...data,
      currentUserId,
    });
  };

  return (
    <>
      <ExpertInfo id={data.id} />
      <PopupModal
        className="expert-info-modal"
        content={<IndividualExpertRecordModalBody />}
        title="Basic Information"
        externalToggle={toggle}
        isOpen={modal}
        footerContent={
          <RecordModalFooter
            data={data}
            toggle={toggle}
            save={save}
            expertStatuses={expertStatuses}
          />
        }
        size="lg"
      />
    </>
  );
};

IndividualExpertContainer.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  loadInitialData: PropTypes.func.isRequired,
  loadExpert: PropTypes.func.isRequired,
  saveExpert: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const expertState = expertInfoSelectors.getExpertInfo(state);
  const user = authSelectors.getUser(state);
  return {
    data: expertState.data,
    errors: expertState.errors,
    expertStatuses: expertState.expertStatuses,
    currentUserId: user.id || 0,
  };
};

const mapDispatchToProps = dispatch => ({
  loadInitialData: userId => dispatch(expertInfoOperations.initData(userId)),
  loadExpert: data => dispatch(expertInfoOperations.loadExpert(data)),
  saveExpert: data => dispatch(expertInfoOperations.saveExpert(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualExpertContainer);
