import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import { MODULE_NAME } from './constants';
import reducer from './reducers';

reducerRegistry.register(MODULE_NAME, reducer);

export { default as expertsConstants } from './constants';
export { default as expertsOperations } from './operations';
export { default as expertsSelectors } from './selectors';
export { default as expertsActions } from './actions';
export { default as expertReducer } from './reducers';
