export const initialDataTransform = {
  fromResponseBody: (object, Obj) => {
    return {
      ...object,
      ...Obj,
      accommodationType: object.accommodationType
        ? object.accommodationType
        : [],
      diagnosticType: object.diagnosticType ? object.diagnosticType : [],
      injuredBodyPart: object.injuredBodyPart ? object.injuredBodyPart : [],
      appointmentType: object.appointmentType ? object.appointmentType : [],
      ReferralSourceList: object.ReferralSourceList
        ? object.ReferralSourceList
        : [],
    };
  },
};
