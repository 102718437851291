import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppDateInput from 'shared/components/input/AppDateInput';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import DashboardButton from './DashboardButton';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppInput from 'shared/components/input/AppInput';
import Fast360FeeModal from './Fast360FeeModal';
import Fast360INTFeeModal from './Fast360INTFeeModal';
import Fast360TRNFeeModal from './Fast360TRNFeeModal';
import Fast360SPTFeeModal from './Fast360SPTFeeModal';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';

const Fast360PlaceOfService = ({
  placeOfServiceTypes,
  selectedService,
  getDefaultCostSavingsPercent,
  userId,
  defaultPercent,
  isFromLanding,
  requiredServiceData,
  resetDefaultPercent }) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const onChangeHandlerPlaceService = (id, value) => {
    selectedService.placeOfServiceId = value;
    setSelectedItem({ selectedService });
  };

  const [openFeeModal, setopenFeeModal] = useState(false);

  const openServiceFeeModal = () => {
    setopenFeeModal(!openFeeModal);
    const params = {
      functionName: 'getDefaultCostSavingsPercent',
      userId: userId,
      serviceType: selectedItem.module,
    };
    getDefaultCostSavingsPercent(params);
  };

  const [openIntFeeModal, setopenIntFeeModal] = useState(false);

  const openIntServiceFeeModal = () => {
    setopenIntFeeModal(!openIntFeeModal);
  };

  const [openTrnFeeModal, setopenTrnFeeModal] = useState(false);

  const openTrnServiceFeeModal = () => {
    setopenTrnFeeModal(!openTrnFeeModal);
  };

  const [openSplFeeModal, setopenSplFeeModal] = useState(false);

  const openSplServiceFeeModal = () => {
    setopenSplFeeModal(!openSplFeeModal);
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'quote') {
      selectedService.quote =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'prospective') {
      selectedService.prospective =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'retrospective') {
      selectedService.retrospective =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'recurring') {
      selectedService.recurring =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'converted') {
      selectedService.converted =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'vic') {
      selectedService.vic =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'specialneeds') {
      selectedService.specialneeds =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'nonworkcomp') {
      selectedService.nonworkcomp =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };
  return (
    <>
      <FormGroup>
        <br></br>

        <Row>

          <Col>
            <Button type="button" disabled={!isFromLanding ? true: false} style={{ width: '100%' }} onClick={openServiceFeeModal} >
              Fees
          </Button>
          </Col>

        </Row>
        <Row>
          <Col sm="10">
            <Label>Place Of Service: </Label>
            <AppTooltip text={'Place of Service is a required field'} id={'placeOService'}>
              <AppInput
                id="placeoOService"
                type="select"
                changeHandler={onChangeHandlerPlaceService}
                className={selectedService?.placeOfServiceId ? '' : "error"}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={selectedService?.placeOfServiceId || ''}
                data={placeOfServiceTypes}
              />
            </AppTooltip>
          </Col>
        </Row>
        <FormGroup>
          <Row>
            <Col sm="2">
              <br></br>
              <b>Referral Types</b>
              <br></br>
            </Col>
          </Row>
          <Row>
            <br></br>
            <Col sm="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="quote"
                  onChange={handleInputChange}
                  checked={selectedService?.quote}
                />{' '}
                Quote
              </Label>
            </Col>
            <Col xs="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="prospective"
                  onChange={handleInputChange}
                  checked={selectedService?.prospective}
                />{' '}
                Prospective
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="retrospective"
                  onChange={handleInputChange}
                  checked={selectedService?.retrospective}
                />{' '}
                Retrospective
              </Label>
            </Col>
            <Col xs="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="recurring"
                  onChange={handleInputChange}
                  checked={selectedService?.recurring}
                />{' '}
                Recurring Supply Shipment
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="converted"
                  onChange={handleInputChange}
                  checked={selectedService?.converted}
                />{' '}
                Quote converted to Order
              </Label>
            </Col>
            <Col xs="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="vic"
                  onChange={handleInputChange}
                  checked={selectedService?.vic}
                />{' '}
                VIC
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="specialneeds"
                  onChange={handleInputChange}
                  checked={selectedService?.specialneeds}
                />{' '}
                Special Needs
              </Label>
            </Col>
            <Col xs="4">
              <Label check>
                <Input
                  type="checkbox"
                  name="nonworkcomp"
                  onChange={handleInputChange}
                  checked={selectedService?.nonworkcomp}
                />{' '}
                NON-Work comp
              </Label>
            </Col>
          </Row>
          <Row></Row>
          {openFeeModal && <Fast360FeeModal
            isOpen={openFeeModal}
            setopenFeeModal={setopenFeeModal}
            onClose={openServiceFeeModal}
            rfees={selectedService?.rfees}
            rfeeIds={selectedService?.rfeeIds}
            serviceFees={selectedService?.serviceFees}
            daysStay={selectedService?.daysDiff}
            selectedService={selectedService}
            fees={selectedService?.fees}
            feeIds={selectedService?.feeIds}
            serviceCosts={selectedService?.serviceCosts}
            defaultPercent={defaultPercent?.percent}
            resetDefaultPercent={resetDefaultPercent}
          />}

        </FormGroup>
      </FormGroup>
    </>
  );
};

Fast360PlaceOfService.propTypes = {
  placeOfServiceTypes: PropTypes.array,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    placeOfServiceTypes: initialData.placeOfServiceTypes,
    userId: user.id,
    defaultPercent: fast360Info.defaultPercent,
  };
};

const mapDispatchToProps = dispatch => ({
  getDefaultCostSavingsPercent: data =>
    dispatch(fast360Operations.getDefaultCostSavingsPercent(data)),
  resetDefaultPercent: () =>
    dispatch(fast360Actions.resetDefaultPercent()),
    requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360PlaceOfService);
