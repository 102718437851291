import PropTypes from 'prop-types';
import React, { useState, useEffect, Component } from 'react';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import DemandButton from './DemandButton';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import {
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

class BillSearchFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }
  state = {
    accessData : []
  }

  componentDidMount() {
    let data = billOperationsOperations
            .getAccessLevelData(this.props.userId)
             .then((response) => {
               this.setState({
                 accessData: response
               });
             })
  }
  
  render() {
    const claimObject = this.props.claimData || {};
    let billsData = [];
    let selectedBillsData = [];
    if (!this.props.hideControls) {
      const { bills, selectedBills } = this.props;
      billsData = bills.map(bill => ({ ...bill, id: bill.int_billid }));
      selectedBillsData = Array.from(selectedBills, bill => bill.int_billid);
    }
    if((Object.keys(this.state).length != 0) &&
    ([1, 10, 12, 13, 16].indexOf(this.state.accessData.result.currentAccess) > -1)){
      return (
        <>
          <hr />
          <Row>
            <Col className="button-footer" style={{ width : '99%'}}>
              {claimObject && claimObject.isAO === "true" ? (
                    <DemandButton
                      isShowDemandButton={true}
                      name="findDemandSetting"
                      selectedBills={selectedBillsData}
                      bills={billsData}
                      claimObject={claimObject}
                      searchFields={this.props.searchFields}
                      claimType={this.props.claimType}
                    />) : null }
              <Button
                color="primary"
                onClick={this.props.reReviewButtonHandler}
                style={{ float: 'right' }}
                disabled={!this.props.enableButtons}
              >
                RE-REVIEW
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if((Object.keys(this.state).length != 0) &&
    ([1, 10, 12, 13, 16].indexOf(this.state.accessData.result.currentAccess) == -1)) {
    return (
      <>
        <hr />
        <Row>
          <Col lg="1">{this.props.billsFound} Bill(s) Found</Col>
          <Col lg={{ size: '3', offset: '4' }}>
            {!this.props.claimControlHandler && (
              <CustomInput
                onChange={this.props.showPendOnlyHandler}
                type="checkbox"
                label="Show Pendable Bills Only"
                id="showPendOnly"
              />
            )}
          </Col>
          <Col className="button-footer" lg={{ size: '4' }}>
            {!this.props.claimControlHandler && (
              <>
                <Button color="warning" onClick={this.props.agreeButtonHandler}>
                  AGREE
                </Button>
                <Button
                  color="primary"
                  style={{margin: '0 40px'}}
                  onClick={this.props.pendButtonHandler}
                  disabled={!this.props.enableButtons}
                >
                  PEND
                </Button>
              </>
            )}
            {claimObject && claimObject.isAO === "true" ? (
                  <DemandButton
                    isShowDemandButton={true}
                    name="findDemandSetting"
                    selectedBills={selectedBillsData}
                    bills={billsData}
                    claimObject={claimObject}
                    searchFields={this.props.searchFields}
                    claimType={this.props.claimType}
                  />) : null }
            <Button
              color="primary"
              onClick={this.props.reReviewButtonHandler}
              style={{ float: 'right' }}
              disabled={!this.props.enableButtons}
            >
              RE-REVIEW
            </Button>
          </Col>
        </Row>
      </>
    );
   } else {
     return(<></>);
   }
  }
}

BillSearchFooter.propTypes = {
  billsFound: PropTypes.number.isRequired,
  agreeButtonHandler: PropTypes.func.isRequired,
  pendButtonHandler: PropTypes.func.isRequired,
  reReviewButtonHandler: PropTypes.func.isRequired,
  demandButtonHandler: PropTypes.func.isRequired,
  showPendOnlyHandler: PropTypes.func.isRequired,
  enableButtons: PropTypes.bool,
  hideControls: PropTypes.bool,
  selectedBills: PropTypes.array,
  claimData: PropTypes.object
};

BillSearchFooter.defaultProps = {
  billsFound: 0,
  hideControls: false,
};

const mapStateToProps = state => {
  const findBills = billOperationsSelectors.getFindBills(state);
  const id = authSelectors.getUser(state);
  return {
    selectedBills: findBills.selectedBills,
    searchFields: findBills.searchFields,
    userId: id.user_id
  };
};

export default connect(mapStateToProps, null)(BillSearchFooter);
