import { commonActions } from 'common/store';
import { commonOperations } from 'common/store/index';

import { push } from 'connected-react-router';

import { ServiceExecutor } from 'core/services/serviceExecutor';

import adminService from '../services/adminService';
import actions from './actions';

const findUsers = (name, location) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.findUser(name, location);
      })
      .withResponseHandler(response => {
        dispatch(actions.updateUserTable(response.data.result.list));
      })
      .withFailureAlert('An error occurred while searching.')
      .build()
      .execute();
  };
};

const clearUsers = () => {
  return dispatch => dispatch(actions.updateUserTable([]));
};

const getUserInformation = (
  userId,
  alertMsg = 'An error occurred while loading the user data.'
) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.getUserInformation(userId);
      })
      .withResponseHandler(response => {
        dispatch(actions.loadUserInformation(response));
        //dispatch(actions.updateUserClients(response.userBillApproval.availableClients, response.userBillApproval.linkClients));
      })
      .withFailureAlert(alertMsg)
      .build()
      .execute();
  };
};

const getAdjusterMappingLists = (userId, profileId) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.getAdjusterMappingLists(userId, profileId);
      })
      .withResponseHandler(response => {
        dispatch(actions.loadAdjusterMappingLists(response));
      })
      .withFailureAlert(
        'An error occurred while loading the adjuster mapping lists.'
      )
      .build()
      .execute();
  };
};

const getUserLocations = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => adminService.loadLocations())
      .withResponseHandler(responseData => {
        dispatch(actions.updateLocationList(responseData));
      })
      .withFailureAlert('An error occurred while loading userlocation list.')
      .build()
      .execute();
  };
};


const addAdjuster = (billId, userId, profile, adjuster) => {
  const updateAdjusterMapData = dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.updateAdjusterMapData(
          userId,
          adjuster.int_userid,
          profile,
          adjuster.chr_firstname,
          adjuster.chr_lastname
        );
      })
      .withResponseHandler(response => {
        if (response.data === 1) {
          dispatch(actions.addAdjuster(adjuster));
        } else {
          dispatch(
            commonActions.addAlert(
              new Date().getTime(),
              'Unable to add the adjuster.',
              'danger'
            )
          );
        }
      })
      .withFailureAlert('An error occurred while updating adjuster map data.')
      .build()
      .execute();
  };

  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.canUserTransferBill(billId, userId, profile);
      })
      .withResponseHandler(response => {
        if (response.data === 1) {
          dispatch(updateAdjusterMapData);
        } else {
          dispatch(
            commonActions.addAlert(
              new Date().getTime(),
              'You are not permitted to transfer this bill type to the user requested.',
              'danger'
            )
          );
        }
      })
      .withFailureAlert(
        'An error occurred while checking users ability to transfer bill data.'
      )
      .build()
      .execute();
  };
};

const getAdjusterList = profileId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.getAdjusterList(profileId);
      })
      .withResponseHandler(response => {
        dispatch(actions.loadAdjusterList(response.data));
      })
      .withFailureAlert('An error occurred while loading the adjuster list.')
      .build()
      .execute();
  };
};

const saveAdminUserForm = (user, userId) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.saveUserInformation(user);
      })
      .withResponseHandler(response => {
        dispatch(saveAdminAncilUser(user, userId));
        if (response.data.result === '1') {
          dispatch(actions.saveAdminUserForm(response.data));
          dispatch(
            commonOperations.addAlert('User saved successfully.', 'success')
          );
          dispatch(push('/administration/search'));
        } else if (response.data.result === '0') {
          dispatch(
            commonOperations.addAlert(
              'Failed to save user information.',
              'danger'
            )
          );
        } else if (response.data.result === '-1') {
          dispatch(
            commonOperations.addAlert(
              'This user already exists in within the application, use update.',
              'danger'
            )
          );
        } else if (response.data.result === '-2') {
          dispatch(
            commonOperations.addAlert(
              'Error creating/updating user.',
              'danger'
            )
          );
        } else if (response.data.result === '-3') {
          dispatch(
            commonOperations.addAlert(
              'Error deleting modules.',
              'danger'
            )
          );
        } else if (response.data.result === '-4') {
          dispatch(
            commonOperations.addAlert(
              'Error inserting modules.',
              'danger'
            )
          );
        } else if (response.data.result === '-5') {
          dispatch(
            commonOperations.addAlert(
              'Error inserting/updating SLP user.',
              'danger'
            )
          );
        } else if (response.data.result === '-6') {
          dispatch(
            commonOperations.addAlert(
              'Error sending email.',
              'danger'
            )
          );
        } else if (response.data.result === '-7') {
          dispatch(
            commonOperations.addAlert(
              'SLP user already exists.  Application user created successfully.',
              'danger'
            )
          ); 
        } else {
          dispatch(
            commonOperations.addAlert(
              'Failed to save user information.',
              'danger'
            )
          );
        }             
      })
      .withFailureAlert('Failed to save user information.')
      .build()
      .execute();
  };
};

const saveAdminAncilUser = (user, userId) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.saveAncilUser(user, userId);
      })
      /*      
      .withResponseHandler(response => {
        dispatch(actions.saveAdminUserForm(response.data));
      })
      */
      .withFailureAlert('Failed to save ancil user information.')
      .build()
      .execute();
  };
};

const deleteWorklistLinks = (user, userId) => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.deleteWorklistLinks(user, userId);
      })
      .withFailureAlert('Failed to delete user worklist links.')
      .build()
      .execute();
  };
};

const cancelAdminUserForm = () => {
  return dispatch => {
    dispatch(actions.cancelAdminUserInput());
    dispatch(push('/administration/search'));
  };
};

const resetPassword = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.resetPassword(userId);
      })
      .withSuccessAlert('Password reset email sent.')
      .withFailureAlert('An error occurred while sending password reset email.')
      .build()
      .execute();
  };
};

const resendPassword = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.resendPassword(userId);
      })
      .withSuccessAlert('Intro email resent.')
      .withFailureAlert('An error occurred while resending intro email.')
      .build()
      .execute();
  };
};

const clearSecurityQuestion = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => {
        return adminService.clearSecurityQuestion(userId);
      })
      .withSuccessAlert('Security questions reset.')
      .withFailureAlert('An error occurred while resetting security questions.')
      .build()
      .execute();
  };
};

export default {
  findUsers,
  clearUsers,
  getUserLocations,
  getUserInformation,
  getAdjusterMappingLists,
  getAdjusterList,
  addAdjuster,
  saveAdminUserForm,
  saveAdminAncilUser,
  deleteWorklistLinks,
  cancelAdminUserForm,
  resetPassword,
  resendPassword,
  clearSecurityQuestion,
};
