import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const NewEmployerModal = ({
  isOpen,
  onClose,
  userId,
  userRole,
  getEmployerList,
  employerInfo,
  updatedEmployerInfo,
  resetSearchEmployerTable,
  getLoadEmployer,
  getLoadClaim,
  updatedClaimInfo,
  loadEmployerInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadEmployer,
  resetEmployerInfo,
  saveEmployer,
  alert,
  isNew,
  NewEmployerName,
  setIsExternal,
  setEmployerEdited,
  fromExternal,
  setEmpData,
  isFromLanding,
  fromWorklist,
  worklistData,
  loadInfoFromExistingPatient,
  logBreadCrumbs
}) => {
  const [isOpenLoadEmployerModal, setIsOpenLoadEmployerModal] = useState(false);
  const [isOpenSeachEmployer, setIsOpenSeachEmployer] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [empValidName, setEmpValidName] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [searchFields, setSearchFields] = useState({
    employerName: (NewEmployerName ? NewEmployerName : '') || (!isFromLanding && loadInfoFromExistingPatient?.name),
  });
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [formEmployerFields, setFormEmployerFields] = useState({
    empName: (fromExternal.fromExternal ? NewEmployerName : '') || (!isFromLanding && loadInfoFromExistingPatient?.name),
    empContact: '',
    empEmail: '',
    empPhone: '',
    empPhoneExt: '',
    empAddress: '',
    empAddress2: '',
    empCity: '',
    empCounty: '',
    empState: '',
    empZip: '',
    empZipCodeId: '',
    empZipId: '',
    empTimeZone: '',
  });
  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSeachEmployer(true);
      } else {
        setIsOpenLoadEmployerModal(true);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!formEmployerFields.empName)
      setEmpValidName(false);
    else setEmpValidName(true);
    setEmpData(formEmployerFields);
  }, [formEmployerFields])

  useEffect(() => {
    if (((updatedClaimInfo?.claim?.id > 0) || (fromWorklist && worklistData?.claim?.id > 0)) && !isEmpty(updatedEmployerInfo)) {
      const claimParams = {
        userId,
        functionName: 'loadClaim',
        claimsId: updatedClaimInfo?.claim?.id || worklistData?.claim?.id,
      };
      getLoadClaim(claimParams);
    }
  }, [updatedEmployerInfo])

  useEffect(() => {
    if (isOpen) {
      setSearchFields({
        employerName: (NewEmployerName ? NewEmployerName : '') || (!isFromLanding && isEmpty(updatedEmployerInfo) && loadInfoFromExistingPatient?.name || ''),
      });
      const payload = {
        employerName: (NewEmployerName ? NewEmployerName : '') || (!isFromLanding && isEmpty(updatedEmployerInfo) && loadInfoFromExistingPatient?.name || ''),
        userId,
      };
      NewEmployerName && getEmployerList(payload);
      (!isFromLanding && loadInfoFromExistingPatient?.name) && getEmployerList(payload);
    }

  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.claim?.employer?.id) {
        const item = employerInfo && employerInfo.filter(o => o.PK_employer_id === worklistData?.claim?.employer?.id);
        let index = employerInfo && employerInfo.findIndex(i => i.PK_employer_id === worklistData?.claim?.employer?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [employerInfo])

  useEffect(() => {
    if (isOpenLoadEmployerModal) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENEMP',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb)
      if (selectedItem) {
        const params = {
          userId,
          functionName: 'loadEmployer',
          id: selectedItem.PK_employer_id,
        };
        getLoadEmployer(params);
      } else if (!isNew && !employerInfo) {
        if (!isEmpty(updatedEmployerInfo)) {
          const pplCheck = updatedEmployerInfo.employer && updatedEmployerInfo.employer.people || {};
          const adrCheck = updatedEmployerInfo.employer && updatedEmployerInfo.employer.address || {};
          setFormEmployerFields({
            empName: updatedEmployerInfo.employer && updatedEmployerInfo.employer.name,
            empContact: pplCheck.first,
            empEmail: pplCheck.email1,
            empPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            empPhoneExt: pplCheck && pplCheck.phoneExt,
            empAddress: adrCheck.street1,
            empZipCodeId: adrCheck.zipCodeId,
            empZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            empTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            empAddress2: adrCheck.street2,
            empCity: adrCheck.zipCode && adrCheck.zipCode.city,
            empCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            empState: adrCheck.zipCode && adrCheck.zipCode.state,
            empZip: adrCheck.zipCode && adrCheck.zipCode.zip,
          });
        }
      }
    }
  }, [isOpenLoadEmployerModal]);

  useEffect(() => {
    if (!isEmpty(loadEmployerInfo)) {
      const pplCheck = loadEmployerInfo.people || {};
      const adrCheck = loadEmployerInfo.address || {};
      setFormEmployerFields({
        empName: loadEmployerInfo.name,
        empContact: pplCheck.first,
        empEmail: pplCheck.email1,
        empPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        empPhoneExt: pplCheck && pplCheck.phoneExt,
        empAddress: adrCheck.street1,
        empZipCodeId: adrCheck.zipCodeId,
        empZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        empTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        empAddress2: adrCheck.street2,
        empCity: adrCheck.zipCode && adrCheck.zipCode.city,
        empCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        empState: adrCheck.zipCode && adrCheck.zipCode.state,
        empZip: adrCheck.zipCode && adrCheck.zipCode.zip,
      });
    }
  }, [loadEmployerInfo]);

  const loadEmployerInfoToggle = () => {
    setIsOpenLoadEmployerModal(!isOpenLoadEmployerModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormEmployerFields({ ...formEmployerFields, empName: '' })
    }
  };

  const searchEmployerToggle = () => {
    setIsOpenSeachEmployer(!isOpenSeachEmployer);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      employerName: '',
    });
    resetSearchEmployerTable();
    resetLoadEmployer();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const onChangeHandlerEmployerType = (id, value) => {
    setFormEmployerFields({ ...formEmployerFields, [id]: value });
  };
  const setMap = {
    empName: setEmpValidName,
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "empPhone") {
      value = formatPhoneNum(value);
    }
    setFormEmployerFields({ ...formEmployerFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };


  const isDisabledInput = () => {
    return userRole === "Administrator" ? false : true;
  }

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const renderPhoneColumn = item => {
    const phone = item.chr_phone || '';
    if (phone) {
      return formatPhoneNumber(phone);
    }
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchEmployerTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'Employer Name',
              field: 'chr_employer_name',
            },
            {
              header: 'Contact Name',
              field: 'chr_firstname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'Phone No.',
              field: 'chr_phone',
              render: item => renderPhoneColumn(item),
            }
          ]}
          data={employerInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-Employer-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadEmployerModal = () => {
    setIsOpenSeachEmployer(false);
    setIsOpenLoadEmployerModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getEmployerList(params);
  };

  const onChangeCurrentEmployer = () => {
    setSearchFields({
      employerName: '',
    });
    setIsOpenLoadEmployerModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachEmployer(true);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormEmployerFields({
        ...formEmployerFields,
        empCity: selectedZip.chr_city,
        empState: selectedZip.chr_state,
        empCounty: selectedZip.chr_county,
        empZip: selectedZip.chr_zipcode,
        empZipCodeId: selectedZip.code_id,
        empZipId: selectedZip.code_id,
        empTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVEEMP',
      refTableId: 0,
      refId: loadEmployerInfo.id,
      refTable: '',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem) {
      const response = loadEmployerInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.name = formEmployerFields.empName;
      response.people.first = formEmployerFields.empContact ? formEmployerFields.empContact : "N/A";
      response.people.last = '';
      response.people.email1 = formEmployerFields.empEmail;
      response.people.phone =
        formEmployerFields.empPhone &&
        formEmployerFields.empPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formEmployerFields.empPhoneExt;
      response.address.street1 = formEmployerFields.empAddress;
      response.address.street2 = formEmployerFields.empAddress2;
      response.address.zipCode.city = formEmployerFields.empCity;
      response.address.zipCode.county = formEmployerFields.empCounty;
      response.address.zipCode.state = formEmployerFields.empState;
      response.address.zipCode.zip = formEmployerFields.empZip;
      response.address.zipCode.id = formEmployerFields.empZipId;
      response.address.zipCodeId = formEmployerFields.empZipCodeId;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.employerOffice;
      const employerId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        employerId,
        claimId
      };
      if (response.name === '' || response.name === undefined) {
        alert('You must provide a employer name in order to save.');
        return;
      }
      saveEmployer(params);
      loadEmployerInfoToggle();
    } else if (!isNew && !employerInfo) {
      const response = updatedEmployerInfo && updatedEmployerInfo.employer;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.name = formEmployerFields.empName;
      response.people.first = formEmployerFields.empContact ? formEmployerFields.empContact : "N/A";
      response.people.last = '';
      response.people.email1 = formEmployerFields.empEmail;
      response.people.phone =
        formEmployerFields.empPhone &&
        formEmployerFields.empPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formEmployerFields.empPhoneExt;
      response.address.street1 = formEmployerFields.empAddress;
      response.address.street2 = formEmployerFields.empAddress2;
      response.address.zipCode.city = formEmployerFields.empCity;
      response.address.zipCode.county = formEmployerFields.empCounty;
      response.address.zipCode.state = formEmployerFields.empState;
      response.address.zipCode.zip = formEmployerFields.empZip;
      response.address.zipCode.id = formEmployerFields.empZipId;
      response.address.zipCodeId = formEmployerFields.empZipCodeId;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.employerOffice;
      const employerId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        employerId,
        claimId
      };
      if (response.name === '' || response.name === undefined) {
        alert('You must provide a employer name in order to save.');
        return;
      }
      saveEmployer(params);
      loadEmployerInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.name = formEmployerFields.empName;
      response.people = {
        id: 0,
        first: formEmployerFields.empContact ? formEmployerFields.empContact : "N/A",
        email1: formEmployerFields.empEmail,
        last: '',
        phone:
          formEmployerFields.empPhone &&
          formEmployerFields.empPhone.replace(/[\s()-]/g, ''),
        phoneExt: formEmployerFields.empPhoneExt,
      };
      response.address = {
        id: 0,
        street1: formEmployerFields.empAddress,
        street2: formEmployerFields.empAddress2,
        zipCodeId: formEmployerFields.empZipCodeId,
        zipCode: {
          city: formEmployerFields.empCity,
          county: formEmployerFields.empCounty,
          state: formEmployerFields.empState,
          zip: formEmployerFields.empZip,
          id: formEmployerFields.empZipId,
          timeZone: formEmployerFields.empTimeZone,
        },
      };

      if (response.name === '' || response.name === undefined) {
        alert('You must provide a employer name in order to save.');
        return;
      }
      const employerId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        employerId,
        claimId,
      };
      saveEmployer(params);
      loadEmployerInfoToggle();
    }
    // setIsExternal();
    setEmployerEdited(true)
  };

  const getBody = () => {
    if (employerInfo && employerInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new Employer as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Employer?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setFormEmployerFields({
      empName: (fromExternal.fromExternal ? NewEmployerName : '') || (!isFromLanding && loadInfoFromExistingPatient?.name),
      empContact: '',
      empEmail: '',
      empPhone: '',
      empPhoneExt: '',
      empAddress: '',
      empAddress2: '',
      empCity: '',
      empCounty: '',
      empState: '',
      empZip: '',
      empZipCodeId: '',
      empZipId: '',
      empTimeZone: '',
    });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadEmployerModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadEmployerModal}>
        <ModalHeader toggle={loadEmployerInfoToggle}>
          ADD A NEW EMPLOYER
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={4}>
              <Label for="empName">Employer Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="empName"
                value={formEmployerFields.empName || ''}
                className={empValidName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={(selectedItem || (!isNew && !employerInfo)) && isDisabledInput()}
              />
            </Col>
            <Col sm={4}>
              <Label for="empContact">Employer Contact Name</Label>{' '}
              <Input
                type="text"
                id="empContact"
                value={formEmployerFields.empContact || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={(selectedItem || (!isNew && !employerInfo)) && isDisabledInput()}
              />
            </Col>
          </FormGroup>
          <Col style={{ top: "-8px", color: "red", paddingLeft: 0 }}> {(selectedItem || (!isNew && !employerInfo)) && <span>{isDisabledInput() ? "Please contact Administrator if you want to change a Employer Name" : "Warning: Changing the name on this record will also change it on any referral that this record is linked to!"}</span>}</Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="empEmail">Employer Email</Label>
              <Input
                type="text"
                id="empEmail"
                value={formEmployerFields.empEmail || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="empPhone">Employer Phone#</Label>
              <Input
                type="tel"
                id="empPhone"
                value={formEmployerFields.empPhone || ''}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="empPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="empPhoneExt"
                value={formEmployerFields.empPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="empAddress">Employer Address</Label>
              <Input
                type="text"
                id="empAddress"
                value={formEmployerFields.empAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="empAddress2">Employer Address 2</Label>
              <Input
                type="text"
                id="empAddress2"
                value={formEmployerFields.empAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="empCity">Employer City</Label>
              <Input
                type="text"
                id="empCity"
                value={formEmployerFields.empCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="empState">State</Label>
              <Input
                type="text"
                id="empState"
                value={formEmployerFields.empState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="empCounty">County</Label>
              <Input
                type="text"
                id="empCounty"
                value={formEmployerFields.empCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="empZip">Employer Zip</Label>
              <Input
                type="text"
                id="empZip"
                value={formEmployerFields.empZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="empZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'empZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="empZip"
                id="empZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentEmployer}>
              CHANGE CURRENT EMPLOYER
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSeachEmployer}>
        <ModalHeader toggle={searchEmployerToggle}>SEARCH EMPLOYER</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={4}>
                <FormGroup>
                  <Label for="employerName">Enter Employer Name</Label>
                  <Input
                    type="text"
                    id="employerName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.employerName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchEmployerTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!employerInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadEmployerModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
    </>
  );
};

NewEmployerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  employerInfo: PropTypes.array,
  getEmployerList: PropTypes.func,
  resetSearchEmployerTable: PropTypes.func,
  getLoadEmployer: PropTypes.func,
  loadEmployerInfo: PropTypes.object,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadEmployer: PropTypes.func,
  saveEmployer: PropTypes.func,
  alert: PropTypes.func,
  resetEmployerInfo: PropTypes.func
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);

  return {
    employerInfo: fast360Info.employerInfo,
    loadEmployerInfo: fast360Info.loadEmployerInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    updatedEmployerInfo: fast360Info.updatedEmployerInfo,
    updateSelectedItemRef: fast360Info.updateSelectedItemRef,
    updateDataFromRef: fast360Info.updateDataFromRef,
    updatedRefInfo: fast360Info.updatedRefInfo,
    updatedClaimInfo: fast360Info.updatedClaimInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getEmployerList: data => dispatch(fast360Operations.getEmployerList(data)),
  getLoadEmployer: data => dispatch(fast360Operations.getLoadEmployer(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  getLoadClaim: data => dispatch(fast360Operations.getLoadClaim(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchEmployerTable: () =>
    dispatch(fast360Actions.resetSearchEmployerTable()),
  resetLoadEmployer: () => dispatch(fast360Actions.resetLoadEmployer()),
  resetEmployerInfo: () => dispatch(fast360Actions.resetEmployerInfo()),
  saveEmployer: data => dispatch(fast360Operations.saveEmployer(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEmployerModal);
