import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faUser,
  faUsers,
  faCog,
  faSpinner,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';
import { countDaysDiff } from 'shared/utils/shareUtils';

export default class ExpertTaskListTable extends Component {
  onEditClickHandler = item => {
    // TODO
    console.log(item);
  };

  renderExpertColumn(item) {
    const type = item.type || '';
    if (type === 'Process') {
      return 'N/A';
    }
    const expertName = item.expert_name || '';
    const isGroup = item.isgroup || false;
    return (
      <span>
        <FontAwesomeIcon
          className="green-text"
          icon={isGroup ? faUsers : faUser}
        />
        <span style={{ marginLeft: 5 }}>{expertName}</span>
      </span>
    );
  }

  renderEditColumn(item) {
    return (
      <FontAwesomeIcon
        className="cursor-hover"
        onClick={() => {
          this.onEditClickHandler(item);
        }}
        icon="edit"
      />
    );
  }

  renderTaskColumn(item) {
    const type = item.type || '';
    const creatorId = item.creator_id || '';
    const description = item.description || '';
    const icon = () => {
      switch (type) {
        case 'System':
          return (
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              className="green-text"
              icon={faCog}
            />
          );
        case 'User':
          return (
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              className="green-text"
              icon={faUser}
            />
          );
        case 'Process':
          return (
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              className="green-text"
              icon={faSpinner}
            />
          );
        default:
          return (
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              className="red-text"
              icon={faExclamationTriangle}
            />
          );
      }
    };

    const processText = () => {
      const categoryName = item.category_name || '';
      const customInfo = item.custominfo || '';
      if (
        categoryName === 'Expert Assignment Needed' ||
        categoryName === 'Expert Re-Assignment Needed'
      ) {
        const textItems = customInfo.toString().split('|');
        if (textItems.length > 0) {
          return (
            <span>
              Claimant: {textItems[0] || ''}, {textItems[1] || ''}
              <br></br>
              Claim #: {textItems[2] || ''}
              <br></br>
              Specialty: {textItems[3] || ''}
              <br></br>
            </span>
          );
        }
      }
      return '';
    };

    return (
      <span>
        {icon()}
        {description}
        {type !== 'System' &&
          creatorId !== '' &&
          creatorId !== this.props.currentUserId && (
            <span className="italic-text">
              {' '}
              {this.getUserFullName(creatorId)}
            </span>
          )}
        {processText()}
      </span>
    );
  }

  getUserFullName(id) {
    const user = R.find(item => item.id === id, this.props.users);
    return user ? user.fullname || '' : '';
  }

  renderDueDateColumn(item) {
    const dueDate = item.due_date || '';
    const circleColor = () => {
      const dueDateDateTime = new Date(dueDate);
      const currentDate = new Date();
      const daysDiff = countDaysDiff(dueDateDateTime, currentDate);
      if (daysDiff > 0) {
        return 'green-text';
      } else if (daysDiff === 0) {
        return 'yellow-text';
      } else {
        return 'red-text';
      }
    };
    const circle = (
      <FontAwesomeIcon
        style={{ marginLeft: 5 }}
        className={circleColor()}
        icon={faCircle}
      />
    );
    return (
      <span>
        {item.due_date}
        {circle}
      </span>
    );
  }

  renderSelectColumn(item) {
    return (
      <CustomInput
        id={`task-list-select-${item.id}`}
        name={`task-list-select-${item.id}`}
        type="checkbox"
        checked={this.props.selectedTasks.includes(item.id)}
        onChange={event => {
          this.props.selectTask(item.id, event);
        }}
      />
    );
  }

  renderSelectHeader() {
    return (
      <CustomInput
        id={`task-list-selectAll`}
        name={`task-list-selectAll`}
        type="checkbox"
        checked={
          this.props.selectedTasks.length === this.props.data.length &&
          this.props.data.length !== 0
        }
        onChange={this.props.selectAll}
      />
    );
  }

  getColumnsDefinition() {
    const headers = [
      {
        header: 'Edit',
        field: 'edit',
        render: item => this.renderEditColumn(item),
        sortAble: false,
      },
      {
        header: 'Expert',
        field: 'expert_name',
        render: this.renderExpertColumn,
      },
      {
        header: 'Category',
        field: 'category_name',
      },
      {
        header: 'Task',
        field: 'description',
        render: item => this.renderTaskColumn(item),
      },
      {
        header: 'Due Date',
        field: 'due_date',
        render: this.renderDueDateColumn,
      },
    ];
    if (this.props.transferMode) {
      headers.push({
        header: this.renderSelectHeader(),
        field: 'selectColumn',
        render: item => this.renderSelectColumn(item),
        sortAble: false,
      });
    }
    return headers;
  }

  render() {
    return (
      <AppTable
        columns={this.getColumnsDefinition()}
        data={this.props.data}
        autoPagination={true}
        sortAble={true}
        sortBy="due_date"
        resizable
        key={`taskList${this.props.transferMode ? 'TransferMode' : ''}`}
      />
    );
  }
}

ExpertTaskListTable.propTypes = {
  data: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  transferMode: PropTypes.bool.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectTask: PropTypes.func.isRequired,
};
