import { countDaysDiff } from 'shared/utils/shareUtils';
import { thousandsSeparatorFormat } from 'shared/utils/numberUtils';

export const getDaysFromToday = date => {
  const firstDate = new Date(date);
  const secondDate = new Date(Date.now());
  return countDaysDiff(firstDate, secondDate);
};

export const formatCurrency = number =>
  '$' + thousandsSeparatorFormat(number.toFixed(2), ',');

export const getNounForm = (number, singular, plural) =>
  number < 2 ? `${number} ${singular}` : `${number} ${plural}`;

export const selectedByGroup = (item, selectedBillItem, groupBySelection) => {
  switch (groupBySelection) {
  
    default:
      return false;
  }
};
