import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import '../../assets/static/main-view.css';
import '../../assets/static/york-style/york-style.css';

import Header from './Header';

class MainView extends Component {
  render() {
    return (
      <Row>
        <Col className="main-view">
          <Header header={this.props.header} />
          <Row>
            <Col className="main-content">{this.props.children}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default MainView;
