import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row } from 'reactstrap';

import Month from './Month';

class YearlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const months = makeRange(0, 12).map(month => {
      const eventsInMonth = this.props.events.filter(
        event => event.start.getMonth() === month
      );
      return (
        <Month
          onClickEvent={this.props.onClickEvent}
          month={month}
          eventsInMonth={eventsInMonth}
          key={`month-${month}`}
          {...this.props}
        />
      );
    });
    return <Row className="m-0">{months}</Row>;
  }
}

YearlyCalendar.propTypes = {
  year: PropTypes.number.isRequired,
  events: PropTypes.array,
  onClickEvent: PropTypes.func,
};

YearlyCalendar.defaultProps = {
  events: [],
};

export function makeRange(start, stop, step) {
  if (stop == null) {
    stop = start || 0;
    start = 0;
  }
  step = step || 1;

  const length = Math.max(Math.ceil((stop - start) / step), 0);
  const range = Array(length);

  for (let idx = 0; idx < length; idx++, start += step) {
    range[idx] = start;
  }

  return range;
}

export default YearlyCalendar;
