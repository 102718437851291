import { ServiceExecutor } from 'core/services/serviceExecutor';

import billMaintenanceService from '../services/billMaintenanceService';

import actions from './actions';

const getInitialData = userId => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.getInitialData(userId))
      .withResponseHandler(data => {
        dispatch(actions.loadInitialData(data));
        dispatch(
          loadClientComboBoxes({
            profile: data.profile,
            zebraUserId: userId,
          })
        );
      })
      .withFailureAlert('An error occurred while loading initial data.')
      .build()
      .execute();
  };
};

const findClients = searchData => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.findClients(searchData))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClientRecordsTable(responseData));
      })
      .withFailureAlert('An error occurred while finding clients.')
      .build()
      .execute();
  };
};

const findGroupClients = searchData => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.findGroupClients(searchData))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGroupClient(responseData));
      })
      .withFailureAlert('An error occurred while finding clients.')
      .build()
      .execute();
  };
};
const getSLPProfilesForBillMaintenance = body => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.getSLPProfiles(body))
      .withResponseHandler(response => {
        dispatch(actions.initSLPProfilesData(response));
      })
      .withFailureAlert('An error occurred while loading the SLPProfiles.')
      .build()
      .execute();
  };
};
const lookUpGroups = body => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.lookUpClientRecords(body))
      .withResponseHandler(response => {
        dispatch(actions.findGroupByGroupName(response));
      })
      .withFailureAlert('An error occurred while looking up groups.')
      .build()
      .execute();
  };
};

const openGroup = body => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.loadGroupDetails(body))
      .withResponseHandler(response => {
        dispatch(actions.loadGroupDetailsAction(response));
      })
      .withFailureAlert('An error occurred while loading group details.')
      .build()
      .execute();
  };
};

const addBlankGroup = () => {
  return dispatch => dispatch(actions.addBlankGroup());
};

const saveGroup = body => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.saveClientRecords(body))
      .withResponseHandler(response => {
        dispatch(actions.saveGroupAction(response));
      })
      .withFailureAlert('An error occurred while saving groups.')
      .build()
      .execute();
  };
};

const loadMasterClientDataProvider = searchData => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.findClients(searchData))
      .withResponseHandler(responseData => {
        dispatch(actions.updateMasterClientDataProvider(responseData));
      })
      .withFailureAlert('An error occurred while finding clients.')
      .build()
      .execute();
  };
};

const loadCopySettingsModalClients = searchData => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.findClients(searchData))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSiteSettingsCopyModalClients(responseData));
      })
      .withFailureAlert('An error occurred while finding clients.')
      .build()
      .execute();
  };
};

const findClientRatesOnClientMaintenance = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billMaintenanceService.findClientRatesOnClientMaintenance(data)
      )
      .withResponseHandler(data => {
        dispatch(actions.updateClientRatesForClientMaintenance(data));
      })
      .withFailureAlert('An error occurred while searching radius.')
      .build()
      .execute();
  };
};

const loadSiteDetails = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.loadSiteDetails(data))
      .withResponseHandler(responseData => {
        if (data.isBeingCopied) {
          dispatch(actions.selectSiteCopySettingsModal(responseData));
        } else {
          data.siteId = responseData.parentId;
          const rateList = responseData.rateList || [];
          const fsSettingList =
            ((responseData.ppoFsSettingHelper || {}).fields || {})
              .fsSettingList || [];
          const ppoSettingList =
            ((responseData.ppoFsSettingHelper || {}).fields || {})
              .ppoSettingList || [];
          const settingsList = responseData.siteSettingMap || {};
          dispatch(actions.updateSiteDetails(responseData));
          dispatch(actions.updateClientRatesTable(rateList, false));
          dispatch(actions.updateUAndCSetupTable(fsSettingList, false));
          dispatch(actions.updatePPOSetupTable(ppoSettingList, false));
          dispatch(actions.updateSettingsListTable(settingsList));
          dispatch(loadMasterSiteDetails(data));
        }
      })
      .withFailureAlert('An error occurred while loading site details.')
      .build()
      .execute();
  };
};

const saveSiteDetails = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.saveSiteDetails(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveSiteDetails(responseData));
        data.siteId = responseData.parentId;
          const rateList = responseData.rateList || [];
          const fsSettingList =
            ((responseData.ppoFsSettingHelper || {}).fields || {})
              .fsSettingList || [];
          const ppoSettingList =
            ((responseData.ppoFsSettingHelper || {}).fields || {})
              .ppoSettingList || [];
          const settingsList = responseData.siteSettingMap || {};
          dispatch(actions.updateSiteDetails(responseData));
          dispatch(actions.updateClientRatesTable(rateList, false));
          dispatch(actions.updateUAndCSetupTable(fsSettingList, false));
          dispatch(actions.updatePPOSetupTable(ppoSettingList, false));
          dispatch(actions.updateSettingsListTable(settingsList));
          dispatch(loadMasterSiteDetails(data));
      })
      .withSuccessAlert('Successfully saved')
      .withFailureAlert('An error occurred while saving Site Info.')
      .build()
      .execute();
  };
};

const loadMasterSiteDetails = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.loadSiteDetails(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateMasterSiteDetails(responseData));
      })
      .withFailureAlert('An error occurred while loading master site details.')
      .build()
      .execute();
  };
};

const copySettingsToParent = data => dispatch => {
  const rateList = data.clientRates || [];
  const fsSettingList = data.uAndCdata || [];
  const ppoSettingList = data.ppoSetupData || [];
  const settingsList = data.settingsList || [];
  if (rateList.length > 0) {
    dispatch(actions.updateClientRatesTable(rateList, true));
  }
  if (fsSettingList.length > 0) {
    dispatch(actions.updateUAndCSetupTable(fsSettingList, true));
  }
  if (ppoSettingList.length > 0) {
    dispatch(actions.updatePPOSetupTable(ppoSettingList, true));
  }
  if (settingsList.length > 0) {
    dispatch(actions.updateSettingsListTable(settingsList));
  }
};

const loadClientComboBoxes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.loadClientComboBoxes(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClientComboBoxes(responseData));
      })
      .withFailureAlert('An error occurred while loading combo boxes.')
      .build()
      .execute();
  };
};

const loadStateCityByZipCode = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billMaintenanceService.loadStateCityByZipCode(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updateStateCityByZipCode(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading state and city by zip code.'
      )
      .build()
      .execute();
  };
};

const loadPayerStateCityByZipCode = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        billMaintenanceService.loadStateCityByZipCode(data)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.updatePayerStateCityByZipCode(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading state and city by zip code.'
      )
      .build()
      .execute();
  };
};

const loadSiteTree = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => billMaintenanceService.loadSiteTree(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSiteTree(responseData));
      })
      .withFailureAlert('An error occurred while loading site tree.')
      .build()
      .execute();
  };
};

export default {
  getInitialData,
  findClients,
  findGroupClients,
  getSLPProfilesForBillMaintenance,
  lookUpGroups,
  addBlankGroup,
  saveGroup,
  openGroup,
  findClientRatesOnClientMaintenance,
  loadMasterClientDataProvider,
  loadSiteDetails,
  loadMasterSiteDetails,
  loadClientComboBoxes,
  loadStateCityByZipCode,
  loadPayerStateCityByZipCode,
  copySettingsToParent,
  loadCopySettingsModalClients,
  saveSiteDetails,
  loadSiteTree,
}
