import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import AppDateInput from 'shared/components/input/AppDateInput';
import moment from 'moment';
import { connect } from 'react-redux';

const BillDetailDenyMisc= ({
    reasonCode,
    reasonOtherNote,
    miscCode,
    reasonMTGNote
}) => {
    const [reasonOther,setDenyText] = useState('');
    const [reasonDate, setReasonDate] = useState(null);
    const [carc, setCARC] = useState('');
    const [rarc, setRARC] = useState('');

    const onChangeDenyReasonsText = (id,value) =>
    {
        setDenyText(value);
        reasonOtherNote(value);
    }
    const onChangeMTGReasonsText = (id, value) =>
    {
        reasonMTGNote(value);
    }
    const onChangeSetMisc = (id, value) =>
    {
        setDenyText(value);
        miscCode(value);
    }
    const onChangeHandler = object => {
        /*
        this.setState({
          [object.key]: object.value,
        });
        */
        setDenyText(object.value);
        setReasonDate(object.value);
        miscCode(object.value.toISOString().substring(0, 10));
    }
    const otherSpecifyHandler = (id, value) => {
        if (id=="reasonOther") {
            setDenyText(value);
            reasonOtherNote(value);
        }else if (id=="carc") {
            setCARC(value);
            miscCode(rarc + "|" + value);
        } else if (id == "rarc") {
            setRARC(value);
            miscCode(value + "|" + carc);
        }
    }

    if(reasonCode.startsWith("c8112")) {
        return (
            <>
                {reasonCode}
                <AppInput
                changeHandler={otherSpecifyHandler}
                placeholder="Reason"
                id="reasonOther"
                type="textarea"
                />
                <AppInput
                changeHandler={otherSpecifyHandler}
                placeholder="RARC"
                id="rarc"
                type="textarea"
                />
                <AppInput
                changeHandler={otherSpecifyHandler}
                placeholder="CARC"
                id="carc"
                type="textarea"
                />
            </>
        );
    } else if(reasonCode.startsWith("c8113") || reasonCode.startsWith("c8114") || reasonCode.startsWith("c8116") || reasonCode.startsWith("c8117")
    || reasonCode.startsWith("c8118") || reasonCode.startsWith("c8119") || reasonCode.startsWith("c8120")) {
        return (
            <>
                {reasonCode}
                <AppInput
                changeHandler={onChangeMTGReasonsText}
                placeholder="Reason"
                id="reasonMTGOther"
                type="textarea"
                />
            </>
        );
    } else if(reasonCode.startsWith("c8115")) {
        return (
            <>
                {reasonCode}
                <AppInput
                changeHandler={onChangeMTGReasonsText}
                placeholder="Reason"
                id="reasonMTGOther"
                type="textarea"
                />
                <AppInput
                changeHandler={onChangeSetMisc}
                placeholder="RARC"
                id="miscReason"
                type="textarea"
                />
            </>
        );
    } else if(reasonCode.startsWith("c810c") || reasonCode.startsWith("c810d") || reasonCode.startsWith("c810e") || reasonCode.startsWith("c8103") || 
        reasonCode.startsWith("c810i") || reasonCode.startsWith("c810j")) {
        return (
            <>
                {reasonCode}
                <AppInput
                changeHandler={onChangeSetMisc}
                placeholder="Reason"
                id="miscReason"
                type="textarea"
                />
            </>
        );
    } else if (reasonCode.startsWith("c810a") || reasonCode.startsWith("c810b") || reasonCode.startsWith("c8110") || reasonCode.startsWith("c8111")) {
        return (
            <>
                {reasonCode}
                <AppDateInput
                    id="miscDate"
                    onChange={value =>
                        onChangeHandler({ key: 'miscDate', value })
                      }
                    selected={reasonDate}
                    blankDefault="1"
                    maxDate={moment().toDate()}
                    dateFormat="MM/dd/yyyy"
                />
            </>
        );
    } else {
        return null
    }
    
};

BillDetailDenyMisc.propTypes = {
    reasonCode: PropTypes.string.isRequired,
    miscCode: PropTypes.func.isRequired,
    reasonOtherNote: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(BillDetailDenyMisc);
