import React, { createContext, useReducer } from 'react';
import { isNil } from 'lodash';

export const Fast360VendorSearchContext = createContext();

const initialState = {
    serviceType: '',
    subService: '',
    dbaName:'',
    vendorName:'',
    limit: "10",
    nationwide: '',
    vendorZip: '',
    radius: '',
    table: '',
    tableAbbrv: '',
    city: '',
    state: '',
    allowZero: '',
}

const reducer = (inputFields, action) => {
    const detailsValue = action.payload;
    switch (action.type) {
        case 'UPDATE_INPUT_FIELDS':
            return {
                ...inputFields,
                serviceType: isNil(detailsValue.serviceType) ? inputFields.serviceType : detailsValue.serviceType,
                table: isNil(detailsValue.table) ? inputFields.table : detailsValue.table,
                tableAbbrv: isNil(detailsValue.tableAbbrv) ? inputFields.tableAbbrv : detailsValue.tableAbbrv,
                subService: isNil(detailsValue.subService) ? inputFields.subService : detailsValue.subService,
                dbaName: isNil(detailsValue.dbaName) ? inputFields.dbaName : detailsValue.dbaName,
                vendorName: isNil(detailsValue.vendorName) ? inputFields.vendorName : detailsValue.vendorName,
                limit: isNil(detailsValue.limit) ? inputFields.limit : detailsValue.limit,
                nationwide: isNil(detailsValue.nationwide) ? inputFields.nationwide : detailsValue.nationwide,
                vendorZip: isNil(detailsValue.vendorZip) ? inputFields.vendorZip : detailsValue.vendorZip,
                radius: isNil(detailsValue.radius) ? inputFields.radius : detailsValue.radius,
                allowZero: isNil(detailsValue.allowZero) ? inputFields.allowZero : detailsValue.allowZero,
                city: isNil(detailsValue.city) ? inputFields.city : detailsValue.city,
                state: isNil(detailsValue.state) ? inputFields.state : detailsValue.state,
            };
    }
};

export const Fast360VendorSearchContextProvider = ({ children }) => {
    const [inputFields, dispatchFields] = useReducer(reducer, initialState);

    return (
        <Fast360VendorSearchContext.Provider value={{ inputFields, dispatchFields }}>
        {children}
        </Fast360VendorSearchContext.Provider>
    );
};