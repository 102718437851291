import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Modal,
    ModalHeader, ModalBody
} from 'reactstrap';
import { Col, Row, Form, FormGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    billOperationsActions,
    billOperationsOperations,
    billOperationsSelectors,
} from "../store";
import { commonOperations } from 'common/store';
import AppDateInput from 'shared/components/input/AppDateInput';
import * as FetchUtils from 'shared/utils/fetchUtils';
import Select from 'react-select';
import { assign } from 'lodash';
const SendEDIModal = ({
    isOpen,
    onClose,
    sendEDISetupData,
    getEDISetupData,
    resetSendEDIData,
    alert
}) => {
    const [searchFields, setSearchFields] = useState({
        ediType: '',
    })

    useEffect(() => {
        if (isOpen) {
            resetSendEDIData();
            getEDISetupData();
        }
    }, [isOpen]);

    const toggle = () => {
        setSearchFields({
            ediType: '',
        });
        onClose();
    };

    const onChangeHandler = (id, value) => {
        setSearchFields({ ...searchFields, [id]: value })
    }

    const onChangeEdiTypeHandler = (id, value) => {
        if (id !== null) {
            let name = 'ediType'
            setSearchFields({ ...searchFields, [name]: id.id })
        } else {
            let name = 'ediType'
            setSearchFields({ ...searchFields, [name]: undefined })
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const result = Object.values(searchFields).every(value => value === "")
        if (Object.keys(searchFields).length === 0 || result) {
            alert('You must provide a Date and Edi Type in order to search.');
            return;
        }

        const actionUrl = new URL('/edi_run.php', process.env.REACT_APP_SLP_BASE_URL);
        const params = new URLSearchParams();
        if (searchFields.entryDate != null) {
            let sMonth = searchFields.entryDate.getMonth();
            sMonth += 1;
            params.append('edi', searchFields.ediType);
            params.append("bdatemm", ("00" + sMonth).toString().substr(-2));
            params.append("bdatedd", ("00" + searchFields.entryDate.getDate()).toString().substr(-2));
            params.append("bdateyy", (searchFields.entryDate.getFullYear()).toString());
            actionUrl.search = params.toString();

            FetchUtils.openWindowWithPost(
                actionUrl.toString(),
                {
                    username: process.env.REACT_APP_SLP_USERNAME,
                    password: process.env.REACT_APP_SLP_PASSWORD,
                },
                `EdiDocs${new Date().getTime()}`
                );
        };
    };
    return (
        <>
            <Modal size="lg" isOpen={isOpen}>
                <ModalHeader toggle={toggle}>EDI Selection Screen</ModalHeader>
                <ModalBody>
                    <Form onSubmit={onFormSubmit}>
                        <Row form>
                            <Col>
                                <FormGroup>
                                    <AppDateInput
                                        id="entryDate"
                                        onChange={value => onChangeHandler('entryDate', value)}
                                        selected={searchFields && searchFields.entryDate}
                                        maxDate={moment().toDate()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    {/* <AppInput
                                        changeHandler={onChangeHandler}
                                        option={{ value: 'id', displayValue: 'desc' }}
                                        placeholder="Choose..."
                                        data={sendEDISetupData}
                                        id="ediType"
                                    /> */}
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder="Choose..."
                                        onChange={onChangeEdiTypeHandler}
                                        //defaultValue={addManageBillsBillStatus.filter(option => option.id == searchFields.billStatus)}
                                        name="ediType"
                                        options={
                                            sendEDISetupData.length
                                                ? sendEDISetupData.map(
                                                    obj => assign(obj, { label: obj.desc, value: obj.id })
                                                )
                                                : []
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button type="submit">RUN</Button>
                            </Col>

                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

SendEDIModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sendEDISetupData: PropTypes.array,
    getEDISetupData: PropTypes.func,
    resetSendEDIData: PropTypes.func,
    alert: PropTypes.func
};

const mapStateToProps = state => {
    const billReviewState = billOperationsSelectors.getBillReview(state);
    return {
        sendEDISetupData: billReviewState.sendEDISetupData
    };
};

const mapDispatchToProps = dispatch => ({
    getEDISetupData: data =>
        dispatch(billOperationsOperations.getEDISetupData(data)),
    resetSendEDIData: () =>
        dispatch(billOperationsActions.resetSendEDIData()),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SendEDIModal);