import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import '../assets/expert-info.scss';

import { getElementFromId } from '../utils/expertInfoUtils';
import { RECOMMENDED } from '../utils/constantUtils';

const RecordModalFooter = ({ data, expertStatuses, toggle, save }) => {
  const circleColor = () => {
    return getElementFromId(expertStatuses, data.expertStatus) === RECOMMENDED
      ? 'green-text'
      : 'red-text';
  };

  return (
    <>
      <div>
        <FontAwesomeIcon className={circleColor()} icon={faCircle} />
      </div>
      <div>
        <Button
          color="danger"
          style={{ marginRight: '0.5em' }}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button color="warning" onClick={save}>
          Save
        </Button>
      </div>
    </>
  );
};

RecordModalFooter.propTypes = {
  data: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default RecordModalFooter;
