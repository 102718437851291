import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import PropTypes from 'prop-types';
import { Button, Input, Row, Col } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import Fast360SPTDetailModal from './Fast360SPTDetailModal';
import { selectedByGroup } from '../utils/ServicesUtils';
import { Fast360SPTDetailModalContext } from '../components/AdvanceSearch/contexts/Fast360SPTDetailModalContext';
import moment from '../../../../node_modules/moment/moment';
import PopupModal from 'shared/components/PopupModal';
import { getTimeObj } from 'shared/utils/dateTimeUtils';
import { isEmpty } from 'lodash';
const Fast360SPTDetails = ({
  disableSave,
  getLegStatus,
  loadLegStatus,
  userId,
  timeZone,
  loadlookUpLocation,
  alert,
  detailsForOrder,
  isFromLanding,
  setSptTableDetails,
  saveObj,
  newSptData,
  setSptDetails,
  isNew,
  sptDetailsList,
  updateSptDetailList,
  updateDeletedSPTList,
  sptDeletedList,
  logBreadCrumbs
}) => {
  const [
    openFast360SPTServiceDetails,
    setOpenFast360SPTServiceDetails,
  ] = useState(false);

  let { LegStatus } = loadLegStatus;
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(
    false
  );

  const [SPTData, setSPTData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  useEffect(() => {
    setSptTableDetails(SPTData);
  }, [SPTData]);

  useEffect(() => {
    if (
      detailsForOrder.detailList &&
      (detailsForOrder.detailList.length > 0 || sptDetailsList.length > 0)
    ) {
      let detailsArr = detailsForOrder.detailList.filter(
        detailObj => detailObj.leg
      );
      let newLegsArr = sptDetailsList.filter(
        detailObj => detailObj.serviceId == detailsForOrder.serviceId
      );
      if (sptDeletedList.length > 0) {
        sptDeletedList.map(delItem => {
          detailsArr = detailsArr.filter(detailObj => detailObj.id !== delItem);
        });
      }
      setSPTData([...detailsArr, ...newLegsArr]);
    } else {
      setSPTData([]);
    }
  }, [detailsForOrder, sptDetailsList]);

  useEffect(() => {
    if (isNew && detailsForOrder) {
      let index = newSptData.findIndex(
        i => i.serviceId == detailsForOrder.serviceId
      );
      if (index !== -1) setSPTData(newSptData[index].data);
    }
  }, [detailsForOrder, isNew]);

  const openSPTService = (onSave) => {
    setSelectedIndex('');
    setOpenFast360SPTServiceDetails(!openFast360SPTServiceDetails);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": onSave === true ? 'SAVEDETAILLINE' : 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    (!openFast360SPTServiceDetails || onSave === true) && logBreadCrumbs(breadCrumb);
  };

  const { sptData, dispatch } = useContext(Fast360SPTDetailModalContext);

  const deleteSPTServiceRow = () => {
    if (selectedIndex === undefined || selectedIndex === '') {
      alert('Please select at least one row to remove');
      return false;
    }

    let SPTDataDetails = [...SPTData];
    if (selectedIndex !== '') {
      let tripLeg = {
        functionName: 'delSPTLeg',
        zebraUserId: userId,
        id: SPTDataDetails[selectedIndex].id,
      };
      if (SPTDataDetails[selectedIndex].id > 0) {
        saveObj(tripLeg);
        updateDeletedSPTList(SPTDataDetails[selectedIndex].id);
        let sptList = sptDetailsList.filter(
          sptItem => sptItem.id !== SPTDataDetails[selectedIndex].id
        );
        if (sptDetailsList.length !== sptList.length) {
          updateSptDetailList(sptList);
        }
      } else {
        let sptList = sptDetailsList.filter(
          sptItem =>
            !sptItem.tempId ||
            sptItem.tempId !== SPTDataDetails[selectedIndex].tempId
        );
        if (sptDetailsList.length !== sptList.length) {
          updateSptDetailList(sptList);
        }
      }
      SPTDataDetails.splice(selectedIndex, 1);
    }
    if (SPTDataDetails.length === 0) {
      setSPTData([]);
    } else {
      setSPTData(SPTDataDetails);
    }
    setSelectedIndex('');
  };

  const onRowClick = (item, index) => {
    setSelectedIndex(index);
  };

  const onRowDoubleClick = (item, index) => {
    setSelectedIndex(index);
    setOpenFast360SPTServiceDetails(true);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": userId,
      "userid": userId,
      "breadcrumbkey": 'OPENDETAILLINE',
      "refTableId": detailsForOrder.serviceId,
      "refId": detailsForOrder.referralId,
      "refTable": detailsForOrder.module,
    };
    logBreadCrumbs(breadCrumb);
  };

  const renderDepartureAddressColumn = item => {
    let city = '';
    let facilityName = '';
    let street = '';
    if (item.pickupAddress?.city) {
      city = (
        <>
          <br />
          <span>{`${item.pickupAddress.city}, ${item.pickupAddress.state}  ${item.pickupAddress.zipcode}`}</span>
        </>
      );
    }
    if (item.pickupAddress?.facilityName) {
      facilityName = (
        <>
          <span>{`${item.pickupAddress.facilityName}`}</span>
        </>
      );
    }
    if (item.pickupAddress?.street) {
      street = (
        <>
          <br />
          <span>{`${item.pickupAddress.street}`}</span>
        </>
      );
    }
    return (
      <>
        {facilityName}
        {street}
        {city}
      </>
    );
  };

  const renderArrivalAddressColumn = item => {
    let city = '';
    let ArrivalLocation = '';
    let ArrivalStreet = '';
    if (item.dropOffAddress?.city) {
      city = (
        <>
          <br />
          <span>{`${item.dropOffAddress.city}, ${item.dropOffAddress.state}  ${item.dropOffAddress.zipcode}`}</span>
        </>
      );
    }
    if (item.dropOffAddress?.facilityName) {
      ArrivalLocation = (
        <>
          <span>{`${item.dropOffAddress.facilityName}`}</span>
        </>
      );
    }
    if (item.dropOffAddress?.street) {
      ArrivalStreet = (
        <>
          <br />
          <span>{`${item.dropOffAddress.street}`}</span>
        </>
      );
    }
    return (
      <>
        {ArrivalLocation}
        {ArrivalStreet}
        {city}
      </>
    );
  };

  const departureWaitTime = item => {
    let hours = '';
    let mins = '';
    if (item.tripDurationHrs) {
      hours = (
        <>
          <span>{`${item.tripDurationHrs} hours`}</span>
        </>
      );
    }
    if (item.tripDurationMins) {
      mins = (
        <>
          <span>{`${item.tripDurationMins} min`}</span>
        </>
      );
    }
    return (
      <>
        {hours} {mins}
      </>
    );
  };

  const layOverTime = item => {
    let hours = '';
    let mins = '';
    if (item.tripLayoverHrs) {
      hours = (
        <>
          <span>{`${item.tripLayoverHrs} hours`}</span>
        </>
      );
    }
    if (item.tripLayoverMins) {
      mins = (
        <>
          <span>{`${item.tripLayoverMins} min`}</span>
        </>
      );
    }
    return (
      <>
        {hours} {mins}
      </>
    );
  };
  const updateSPTDetails = inputObj => {
    let sptArr = [...SPTData];
    if (selectedIndex !== '') {
      sptArr[selectedIndex] = inputObj;
    } else {
      sptArr.push(inputObj);
    }
    setSPTData(sptArr);
    let obj = {
      serviceId: detailsForOrder.serviceId,
      data: sptArr,
    };
    let arrBefore = [...newSptData];
    let index = arrBefore.findIndex(
      i => i.serviceId == detailsForOrder.serviceId
    );
    if (index === -1) arrBefore.push(obj);
    else arrBefore[index] = obj;
    setSptDetails(arrBefore);
    setOpenFast360SPTServiceDetails(false);
  };

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const getDeleteBody = () => {
    return (
      <div className="text-center">
        Are you sure you wish to delete Service Detail?
      </div>
    );
  };

  const getDeleteFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            deleteSPTServiceRow();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const renderLegStatus = item => {
    let legStatusVal = '';
    if (item.legStatusId && LegStatus && LegStatus.length > 0) {
      let legStatusObj = LegStatus.filter(
        legstatus => legstatus.id == item.legStatusId
      )[0];
      legStatusVal = legStatusObj.value;
      return legStatusVal;
    }
  };

  return (
    <div>
      <Row>
        <Col lg="2">
          <Button
            type="button"
            disabled={isEmpty(detailsForOrder) || disableSave} 
            onClick={openSPTService}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>

          <Button
            color="danger"
            type="button"
            disabled={disableSave} 
            onClick={confirmationDeleteToggle}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <AppTable
            resizable
            columns={[
              {
                header: 'Leg',
                field: 'leg',
              },
              {
                header: 'Trip Status',
                field: 'Trip Status',
                render: item => {
                  return renderLegStatus(item);
                },
              },
              {
                header: 'Dept Time',
                field: 'Dept Time',
                render: item =>
                  `${moment(item.pickup).format('MM/DD/YYYY hh:mm A')}`,
              },
              {
                header: 'Departure Location',
                field: 'departure Location',
                render: item => renderDepartureAddressColumn(item),
              },
              {
                header: 'Arr. Time',
                field: 'Arr.Time',
                render: item =>
                  `${moment(item.dropOff).format('MM/DD/YYYY hh:mm A')}`,
              },
              {
                header: 'Arrival Location',
                field: 'Arrival Location',
                render: item => renderArrivalAddressColumn(item),
              },
              {
                header: 'Type',
                field: 'tripType',
              },
              {
                header: 'Duration',
                field: 'Duration',
                render: item => departureWaitTime(item),
              },
              {
                header: 'Layover',
                field: 'Layover',
                render: item => layOverTime(item),
              },
            ]}
            data={SPTData}
            autoPagination={true}
            sortAble={true}
            rowClickHandler={onRowClick}
            rowDoubleClickHandler={onRowDoubleClick}
          />
        </Col>
      </Row>
      <PopupModal
        content={getDeleteBody()}
        title="Confirmation"
        externalToggle={confirmationDeleteToggle}
        isOpen={isConfirmDeleteModalOpen}
        footerContent={getDeleteFooter()}
        size="md"
      />
      {openFast360SPTServiceDetails && (
        <Fast360SPTDetailModal
          isOpen={openFast360SPTServiceDetails}
          transportDetailObj={
            selectedIndex !== '' ? SPTData[selectedIndex] : {}
          }
          pickupAddr={
            SPTData.length > 0 ? SPTData[SPTData.length - 1].dropOffAddress : {}
          }
          onClose={openSPTService}
          onSave={updateSPTDetails}
          legLength={SPTData.length}
          loadLegStatus={loadLegStatus}
          timeZone={timeZone}
          userId={userId}
          referralId={detailsForOrder.referralId}
          serviceId={detailsForOrder.serviceId}
          detailsForOrder={detailsForOrder}
          setSPTData={setSPTData}
          sptDetails={SPTData}
          loadlookUpLocation={loadlookUpLocation}
          alert={alert}
        />
      )}
    </div>
  );
};

/*GroupBySelection.propTypes = {
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
};*/
const mapStateToProps = state => {
  return {
    newSptData: state.FAST360.fast360Reducer.sptData,
    sptDetailsList: state.FAST360.fast360Reducer.sptDetailsList
      ? state.FAST360.fast360Reducer.sptDetailsList
      : [],
    sptDeletedList: state.FAST360.fast360Reducer.sptDeletedList
      ? state.FAST360.fast360Reducer.sptDeletedList
      : [],
  };
};
const mapDispatchToProps = dispatch => ({
  setSptTableDetails: data => dispatch(fast360Actions.sptTableDetails(data)),
  saveObj: data => dispatch(fast360Operations.saveObj(data)),
  updateSptDetailList: data =>
    dispatch(fast360Actions.updateSptDetailList(data)),
  setSptDetails: data => dispatch(fast360Actions.setSptDetails(data)),
  updateDeletedSPTList: data =>
    dispatch(fast360Actions.updateDeletedSPTList(data)),
  logBreadCrumbs: data => dispatch(fast360Operations.logBreadCrumbs(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360SPTDetails);
