import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Input, Row } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

class StateDocumentsModal extends Component {
  onRowDoubleClick = data => {
    // TODO: Generate a link once the API call is available
    window.open(data.logicalName, '_blank');
  };

  getStateDocuments = () => {
    return this.props.documents.filter(
      doc => doc.recordId === this.props.stateId
    );
  };

  getModalContent = () => {
    return (
      <>
        <Row style={{ paddingBottom: '10px' }}>
          <Col lg="6">
            <Input
              type="select"
              value={this.props.stateDocSelect}
              onChange={e => this.props.setStateDocID(e.target.value)}
            >
              {this.props.stateList.map((state, index) => (
                <option key={index} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </Input>
          </Col>
          <Col lg={{ size: '3', offset: '3' }}>
            <span className="float-right">
              <PopupModal
                content={this.getUploadModalContent()}
                title="Upload Documents"
                text="Upload"
                size="lg"
                isButton={true}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppTable
              modalHeight={500}
              resizable
              columns={[
                {
                  header: 'Description',
                  field: 'description',
                  rowKey: true,
                  percent: '70',
                },
                {
                  header: 'Document Type',
                  field: 'type',
                },
              ]}
              data={this.getStateDocuments()}
              rowDoubleClickHandler={this.onRowDoubleClick}
              autoPagination={true}
            />
          </Col>
        </Row>
      </>
    );
  };

  getUploadModalContent = () => {
    return <></>;
  };

  render() {
    return (
      <PopupModal
        content={this.getModalContent()}
        title="State Bill Review Documents SDM"
        externalToggle={this.props.externalToggle}
        isOpen={this.props.isOpen}
        includeFooter={false}
        size="lg"
      />
    );
  }
}

StateDocumentsModal.propTypes = {
  data: PropTypes.array.isRequired,
  externalToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  stateDocSelect: PropTypes.string.isRequired,
  setStateDocID: PropTypes.func.isRequired,
  stateList: PropTypes.array.isRequired,
  stateId: PropTypes.number.isRequired,
};

StateDocumentsModal.defaultProps = {
  data: [],
  stateList: [],
  documents: [],
  stateId: 0,
};

export default StateDocumentsModal;
