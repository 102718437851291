import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import PopupModal from 'shared/components/PopupModal';

import { billOperationsSelectors } from '../../../bill-operations/store';

import {
  billMaintenanceOperations,
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../store';
import { renderClientName } from '../../utils/billMaintenanceUtil';

import ClientRatesContainer from '../client-maintenance/client-rates/ClientRatesContainer';
import UAndCSetupContainer from '../client-maintenance/u-and-c-setup/UAndCSetupContainer';
import PPOSetupContainer from '../client-maintenance/ppo-setup/PPOSetupContainer';

import SiteSettingsTab from './site-settings-tab/SiteSettingsTab';

import './CopySettingsModal.scss';

const CLIENT_RATES = 'CLIENT_RATES';
const U_AND_C_SETUP = 'U_AND_C_SETUP';
const PPO_SETUP = 'PPO_SETUP';
const SITE_SETTINGS = 'SITE_SETTINGS';
const USER_ID = '2591'; //TODO: hardcode

const NAME = 'copySettings';
class CopySettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: CLIENT_RATES,
      client: '',
      newClientRates: [],
      newUAndCData: [],
      newPPOSetupData: [],
      newSettingsList: [],
    };
  }

  toggle = isSaving => {
    if (isSaving) {
      this.props.copySettingsToParent({
        clientRates: this.state.newClientRates,
        uAndCdata: this.state.newUAndCData,
        ppoSetupData: this.state.newPPOSetupData,
        settingsList: this.state.newSettingsList,
      });
    }
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  changeTab = activeTab => {
    this.setState({
      activeTab: activeTab,
    });
  };

  buildModalBody = () => (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <FormGroup>
            <AppAutocomplete
              id="client"
              data={this.props.clients}
              value={this.state.client}
              displayOption="chr_sitename"
              placeholder=""
              onChange={this.onInputChangeHandler}
              onSelect={this.onClientSelect}
              renderItem={renderClientName}
              inputProps={{
                onKeyPress: this.onClientEnterPress,
              }}
              showToolTip={false}
              updateOnLeave={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({
                  active: this.state.activeTab === CLIENT_RATES,
                })}
                onClick={() => {
                  this.changeTab(CLIENT_RATES);
                }}
              >
                Client Rates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: this.state.activeTab === U_AND_C_SETUP,
                })}
                onClick={() => {
                  this.changeTab(U_AND_C_SETUP);
                }}
              >
                U And C Setup
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: this.state.activeTab === PPO_SETUP,
                })}
                onClick={() => {
                  this.changeTab(PPO_SETUP);
                }}
              >
                PPO Setup
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: this.state.activeTab === SITE_SETTINGS,
                })}
                onClick={() => {
                  this.changeTab(SITE_SETTINGS);
                }}
              >
                Site Settings
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={CLIENT_RATES} className="pt-2">
              <ClientRatesContainer
                isSelectable
                name={NAME}
                editColumns={{
                  lineOfBusiness: true,
                  rateType: true,
                  network: true,
                  billType: true,
                  state: true,
                  type: true,
                  effective: true,
                  terminated: true,
                }}
                onSelectRows={data =>
                  this.updateCopyData('newClientRates', data)
                }
                data={this.props.siteDataCopySettingsModal.rateList}
                haveAddNewButton={false}
              />
            </TabPane>
            <TabPane tabId={U_AND_C_SETUP} className="pt-2">
              <UAndCSetupContainer
                isSelectable
                name={NAME}
                editColumns={{
                  name: true,
                  lob: true,
                  amount: true,
                  state: true,
                  effDate: true,
                  termDate: true,
                }}
                onSelectRows={data => this.updateCopyData('newUAndCData', data)}
                data={
                  (
                    (
                      this.props.siteDataCopySettingsModal.ppoFsSettingHelper ||
                      {}
                    ).fields || {}
                  ).fsSettingList || []
                }
                haveAddNewButton={false}
                haveResetButton={false}
              />
            </TabPane>
            <TabPane tabId={PPO_SETUP} className="pt-2">
              <PPOSetupContainer
                isSelectable
                name={NAME}
                editColumns={{
                  name: true,
                  lob: true,
                  ppoId: true,
                  state: true,
                  ppoRank: true,
                  effDate: true,
                  termDate: true,
                }}
                onSelectRows={data =>
                  this.updateCopyData('newPPOSetupData', data)
                }
                data={
                  (
                    (
                      this.props.siteDataCopySettingsModal.ppoFsSettingHelper ||
                      {}
                    ).fields || {}
                  ).ppoSettingList || []
                }
                haveAddNewButton={false}
                haveResetButton={false}
                haveTerminateButton={false}
              />
            </TabPane>
            <TabPane tabId={SITE_SETTINGS}>
              <SiteSettingsTab
                updateSettingsList={data =>
                  this.updateCopyData('newSettingsList', data)
                }
                choices={R.map(
                  item => item[1][0],
                  this.props.siteDataCopySettingsModal.siteSettingMap
                )}
                settingsList={this.props.settingsList}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );

  buildModalFooter = () => (
    <>
      <Button color="primary" onClick={() => this.toggle(false)}>
        CANCEL
      </Button>
      <Button color="secondary" onClick={() => this.toggle(true)}>
        COPY
      </Button>
    </>
  );

  onClientEnterPress = event => {
    if (event.charCode !== 13) {
      return;
    }
    const { profile } = this.props;
    this.props.loadClients({
      profile,
      siteName: event.target.value,
      excludeEmployers: true,
      zebraUserId: USER_ID,
    });
  };

  onInputChangeHandler = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  onClientSelect = (_id, item) => {
    this.setState({
      client: item.chr_sitename,
    });
    this.props.loadSiteDetails({
      profile: this.props.profile,
      siteId: item.int_sitecode,
      isBeingCopied: true,
      zebraUserId: USER_ID,
    });
  };

  updateCopyData = (state, data) => {
    this.setState({
      [state]: data,
    });
  };

  resetModal = () => {
    this.setState({
      activeTab: CLIENT_RATES,
      client: '',
    });
    this.props.resetSiteCopySettingsModal();
  };

  render() {
    return (
      <>
        <Button
          type="button"
          onClick={() => this.toggle(false)}
          disabled={this.props.disabled}
        >
          {this.props.buttonLabel}
        </Button>
        <PopupModal
          text={this.props.buttonLabel}
          externalToggle={() => this.toggle(false)}
          content={this.buildModalBody()}
          title="Copy Site Settings"
          includeFooter
          footerContent={this.buildModalFooter()}
          isOpen={this.state.modal}
          size="xl"
          onClosed={this.resetModal}
        />
      </>
    );
  }
}

CopySettingsModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  clients: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  loadSiteDetails: PropTypes.func.isRequired,
  copySettingsToParent: PropTypes.func.isRequired,
  resetSiteCopySettingsModal: PropTypes.func.isRequired,
  siteDataCopySettingsModal: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const copySiteSettingsState = billMaintenanceSelectors.getClientMaintenanceCopySiteSettings(
    state
  );
  const initialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  return {
    profile: billReviewInitialData.currentProfile,
    clients: copySiteSettingsState.copySettingsModalClients,
    siteDataCopySettingsModal: copySiteSettingsState.siteDataCopySettingsModal,
    settingsList: initialData.clientComboBoxes.settingsList,
  };
};
const mapDispatchToProps = dispatch => ({
  loadClients: searchData =>
    dispatch(
      billMaintenanceOperations.loadCopySettingsModalClients(searchData)
    ),
  loadSiteDetails: data =>
    dispatch(billMaintenanceOperations.loadSiteDetails(data)),
  copySettingsToParent: data =>
    dispatch(billMaintenanceOperations.copySettingsToParent(data)),
  resetSiteCopySettingsModal: () =>
    dispatch(billMaintenanceActions.resetSiteCopySettingsModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CopySettingsModal);
