import invariant from 'invariant';
import * as R from 'ramda';

import * as TypeUtils from './typeUtils';

export function transformActionTypes(transform, actionTypes) {
  return R.map(actionType => {
    if (TypeUtils.isNil(actionType) || TypeUtils.isEmpty(actionType)) {
      return actionType;
    }

    if (TypeUtils.isObject(actionType)) {
      return transformActionTypes(transform, actionType);
    }

    return transform(actionType);
  }, actionTypes);
}

export function createAction(type, payloadCreator = R.identity, metaCreator) {
  invariant(
    TypeUtils.isFunction(payloadCreator) || TypeUtils.isNull(payloadCreator),
    'Expected payloadCreator to be function or null'
  );

  const finalPayloadCreator =
    TypeUtils.isNull(payloadCreator) || TypeUtils.isIdentity(payloadCreator)
      ? R.identity
      : (head, ...args) =>
          TypeUtils.isError(head) ? head : payloadCreator(head, ...args);

  const typeString = type.toString();

  const actionCreator = (...args) => {
    const payload = finalPayloadCreator(...args);
    const action = { type };

    if (TypeUtils.isError(payload)) {
      action.error = true;
    }

    if (payload !== undefined) {
      action.payload = payload;
    }

    if (TypeUtils.isFunction(metaCreator)) {
      action.meta = metaCreator(...args);
    }

    return action;
  };

  actionCreator.toString = () => typeString;

  return actionCreator;
}

export function createReducer(initialState, handlers) {
  return (state = initialState, action) =>
    R.propOr(R.identity, action.type, handlers)(state, action);
}

export function createFilteredReducer(reducer, predicate) {
  return (state, action) => {
    return predicate(action) || TypeUtils.isUndefined(state)
      ? reducer(state, action)
      : state;
  };
}

export function transformSelectors(transform, selectors) {
  return R.map(
    selector =>
      R.curryN(R.length(selector), (...args) =>
        selector(...R.adjust(-1, transform, args))
      ),
    selectors
  );
}

export function createModuleActionTypeTransform(moduleName) {
  return actionType => `~${moduleName}/${actionType}`;
}

export function createModuleSelectorTransform(moduleName) {
  return R.prop(moduleName);
}

export function createRequestActionTypes(type) {
  return {
    REQUEST: `${type}/REQUEST`,
    SUCCESS: `${type}/SUCCESS`,
    FAILURE: `${type}/FAILURE`,
  };
}

export function createRequestAction(
  actionTypes,
  requestCreator,
  payloadCreator = R.identity,
  metaCreator
) {
  const actions = {
    REQUEST: createAction(actionTypes.REQUEST),
    SUCCESS: createAction(actionTypes.SUCCESS, payloadCreator, metaCreator),
    FAILURE: createAction(actionTypes.FAILURE),
  };

  return (...args) => async (dispatch, getState, thunkArg) => {
    dispatch(actions.REQUEST());
    try {
      const response = await requestCreator(thunkArg)(...args);
      dispatch(actions.SUCCESS(response));
    } catch (error) {
      dispatch(actions.FAILURE(error));
    }
  };
}

export const requestErrorsReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)\/(REQUEST|FAILURE)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'FAILURE' ? payload.message : '',
  };
};

export const requestLoadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return { ...state, [requestName]: requestState === 'REQUEST' };
};

export function createErrorsSelector(actionTypes) {
  return state =>
    R.reject(
      R.isEmpty,
      R.map(type => R.path(['errors', type], state) || '', actionTypes)
    );
}

export function createLoadingSelector(actionTypes) {
  return state => R.any(type => R.path(['loading', type], state), actionTypes);
}
