import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { billOperationsSelectors } from '../bill-operations/store';

import './assets/static/billMaintenance.scss';

import { billMaintenanceOperations, billMaintenanceSelectors } from './store';

import ClientMaintenance from './components/ClientMaintenance';
import GroupMaintenance from './components/group-maintenance/GroupMaintenance';
import {
  CLIENT_MAINTENANCE,
  GROUP_MAINTENANCE,
  USER_FLAGS,
  CASH_RECEIPTS,
  EDI_MANAGER,
} from './utils/constantUtils';

const USER_ID = '2591'; //TODO: hardcode

class BillMaintenance extends Component {
  componentDidMount() {
    this.props.getClientComboBoxes({
      profile: this.props.profile,
      zebraUserId: USER_ID,
    });
  }

  render() {
    switch (this.props.currentView) {
      case CLIENT_MAINTENANCE:
        return <ClientMaintenance />;
      case GROUP_MAINTENANCE:
        return <GroupMaintenance currentUserId={USER_ID} />;
      // case USER_FLAGS:
      //   return <div>User Flags</div>;
      // case CASH_RECEIPTS:
      //   return <div>Cash Receipts</div>;
      // case EDI_MANAGER:
      // default:
      //   return <div>EDI Manager</div>;
    }
  }
}

BillMaintenance.propTypes = {
  currentView: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  getClientComboBoxes: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialData = billMaintenanceSelectors.getInitialData(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  return {
    profile: billReviewInitialData.currentProfile,
    currentView: initialData.currentView,
  };
};

const mapDispatchToProps = dispatch => ({
  getClientComboBoxes: data =>
    dispatch(billMaintenanceOperations.loadClientComboBoxes(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillMaintenance);
