import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations } from '../store';
import Fast360FileModal from '../components/Fast360FileModal';
import * as R from 'ramda';
class NewAttachmentPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            combinedArr: [],
            id: '',
            open: this.props.isOpen
        }
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onExit = this.onExit.bind(this);
    }

    handleChange = () => {
        this.setState({ checked: true, open: false });

    }
    toggle = () => {
        this.props.clicked(false);
        !this.props.isFromLanding && this.props.onNewRefExit();
    };
    updateSedgAttachments = (id) => {
        if(this.props.selectedExternalData){
            if (id == null || id == 0) 
            {
                return;
            }
            const reqBody = {
                functionName: 'updateSedgwickAttachments',
                zebraUserId: this.props.currentUserId,
                referralId: id,
                sedgwickReferralId: this.props.selectedExternalData.sedgwickReferralId,
              };
            this.props.updateSedgAttachments(reqBody);
        }
    }
    componentDidMount() {
        let audArr = [], hhcArr = [], accmArr = [], denArr = [], tranArr = [],
            diagArr = [], trnArr = [], mseArr = [], modArr = [], stpArr = [];
        const {
            audiologyList, hhcList, accommodationList,
            dentalList, translationList, diagnosticList,
            mseList, transportationList, modificationList, specialTransportationList, id
        } = this.props.updatedReferralInfo;


        audiologyList?.length > 0 && audiologyList.map(i => audArr.push({ ...i, modSelected: 'audiology' }));
        hhcList?.length > 0 && hhcList.map(i => hhcArr.push({ ...i, modSelected: 'hhc' }));
        accommodationList?.length > 0 && accommodationList.map(i => accmArr.push({ ...i, modSelected: 'accommodation' }));
        dentalList?.length > 0 && dentalList.map(i => denArr.push({ ...i, modSelected: 'dental' }));
        translationList?.length > 0 && translationList.map(i => tranArr.push({ ...i, modSelected: 'interpretation' }));
        diagnosticList?.length > 0 && diagnosticList.map(i => diagArr.push({ ...i, modSelected: 'diagnostics' }));
        mseList?.length > 0 && mseList.map(i => mseArr.push({ ...i, modSelected: 'mse' }));
        transportationList?.length > 0 && transportationList.map(i => trnArr.push({ ...i, modSelected: 'transportation' }));
        modificationList?.length > 0 && modificationList.map(i => modArr.push({ ...i, modSelected: 'modifications' }));
        specialTransportationList && specialTransportationList?.length > 0 && specialTransportationList.map(i => stpArr.push({ ...i, modSelected: 'special transport' }));

        let comb = [
            ...audArr, ...hhcArr, ...accmArr, ...denArr, ...tranArr, ...mseArr, ...trnArr, ...modArr, ...stpArr, ...diagArr
        ];
        this.setState({ combinedArr: comb, id: id })
        if (id != null && id != 0) {
            this.updateSedgAttachments(id)
        }        
    }
    componentWillUnmount() {
        this.onExit();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.isExternalReferral !== this.props.isExternalReferral){
        }
    }

    onExit = () => {
        this.setState({ checked: false })
        // this.props.setRefSaved(false)
    }

    render() {
        // console.log('updatedReferralInfo', this.state.checked, this.state);
        return (
            <>
                {this.state.checked && <Fast360FileModal newRef={true} dataObject={this.props.selectedExternalData} newData={this.state.combinedArr} refId={this.state.id} isOpen={this.state.checked} onExit={this.onExit} onNewRefExit={this.props.onNewRefExit} isExternalReferral={this.props.isExternalReferral} refSaved={this.props.refSaved} clicked={this.props.clicked}/>}
                <Modal size="md" isOpen={this.state.open} style={{ height: '100' }} scrollable={true}>
                    <ModalHeader toggle={this.toggle}> Confirmation</ModalHeader>
                    <ModalBody>
                        <div style={{ paddingBottom: '10px' }}>Do you wish to add any attachments to the referral? </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ marginLeft: 5 }}
                            type="button"
                            onClick={() => { this.toggle(); }} >NO</Button>
                        <Button style={{ marginLeft: 5 }}
                            type="button"
                            onClick={() => this.handleChange()}
                        >YES</Button>
                    </ModalFooter>
                </Modal >
            </>
        )
    }
}


const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    //const billReviewInitialData = billOperationsSelectors.getInitialData(state);
    return {
        currentUserId: userId,
        users: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        //lmsUserId: billReviewInitialData.LmsuserId,
        lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
        updatedReferralInfo: state.FAST360.fast360Reducer.updatedReferralInfo ? state.FAST360.fast360Reducer.updatedReferralInfo : {},
    };
};



const mapDispatchToProps = dispatch => ({
    postTransfer: (data) => dispatch(fast360Operations.postTransfer(data)),
    updateSedgAttachments : (data) => dispatch(fast360Operations.updateSedgwickAttachments(data)),

    // getUsersWorkList: (data, currentUserId) => dispatch(fast360Operations.getUsersWorkList(data, currentUserId))
});




export default connect(mapStateToProps, mapDispatchToProps)(NewAttachmentPrompt);

