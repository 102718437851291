import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter, Row, Col } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';
import { commonOperations } from 'common/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

class Fast360Eligilibity extends Component {
    constructor(props) {
        super(props);
        this.loadEligibility = this.loadEligibility.bind(this);
        this.state = {
            sendTo: "",
            copySelf: false,
            cCTo: "",
            from: "",
            subject: "",
            claimNo:"",
            overrideModalOpen: false,
            note: "",
            showNotEligibleDialog: false,
            eligibilityChecked: false,
            eligibilitySet: false
        }
    }

    toggle = () => {
        this.props.clicked();
        //to enable/disable eligibility check
       this.setState({eligibilityChecked: false, showNotEligibleDialog: false, eligibilitySet: false});
       this.props.clearEligibility();
       console.log('clearing eligibility');
    };

    setClaim = (e) => {
        this.setState({ claimNo: e })
    }


    setSubject = (e) => {
        this.setState({ subject: e })
    }

    loadEligibility(){
        let claimNo = "";
        if (this.state.claimNo === ""){
            claimNo = this.props.claimNo;
        }else{
            claimNo = this.state.claimNo;
        }
        let payload1 = {
            "functionName": "loadEligibility",
            "zebraUserId": this.props.currentUserId,
            "claimNo": claimNo
        }
      
        this.props.getEligibilityData(payload1);
    }

    openOverrideDialog = () =>  {
        this.setState({overrideModalOpen: true});
    }

    checkEligibility = ()  => {
        const req = {
            functionName: 'isServiceEligible',
            zebraUserId: this.props.currentUserId,
            tableType: this.props.serviceData && this.props.serviceData.module ? this.props.serviceData.module: null,
            serviceId: this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId: null,
            referralId: this.props.serviceData && this.props.serviceData.referralId ?  this.props.serviceData.referralId : null, 
        }
        console.log(req);
        this.props.checkEligibility(req);
    }
    
    componentDidMount() {
        const { currentUserId } = this.props;
        this.setClaim(this.props.claimNo);
        //to enable/disable eligibility check
        //this.checkEligibility();
        
        this.loadEligibility();
    }

    //to enable/disable eligibility check
    componentDidUpdate() {
        if(this.props.isOpen  && !this.state.eligibilityChecked) {
            this.checkEligibility();
            this.setState( {eligibilityChecked: true});
        }
        if(this.props.isOpen && !this.props.isEligible && !this.state.showNotEligibleDialog && !this.state.eligibilitySet) {
            this.setState({showNotEligibleDialog: true, eligibilitySet: true });
        }
    }

    saveOverride = () => {
        const req = {
            functionName: "saveOverrideEligibility",
            zebraUserId: this.props.currentUserId,
            tableType: this.props.serviceData && this.props.serviceData.module ? this.props.serviceData.module: null,
            serviceId: this.props.serviceData && this.props.serviceData.serviceId ? this.props.serviceData.serviceId: null,
            note: this.state.note
        }
        console.log(req);
        this.props.saveOverrideEligibility(req);
        this.setState({overrideModalOpen: false});
    }

    
    getOverrideModalFooter = () => {
        return (
            <div className="d-flex flex-row justify-content-center w-100 m-0">
                <Button
                  color="primary"
                  className="px-5"
                  onClick={() => {
                    this.saveOverride();
                  } }
                >
                  Override Eligibility
                </Button>
            </div>
          );
      }

      notEligibleDialogFooter = ()  => {
        return (
            <div className="d-flex flex-row justify-content-center w-100 m-0">
                <Button
                  color="primary"
                  className="px-5"
                  onClick={() => {
                    this.setState({showNotEligibleDialog: false});
                  } }
                >
                 OK
                </Button>
            </div>
          );
      }
    


    render() {
        return (
            <>
            <Modal size="lg" isOpen={this.props.isOpen}>
                <ModalHeader toggle={this.toggle}>  </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={4}>Claim No:
                        </Col>
                        <Col lg={8}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="claimNo"
                                    id="subject"
                                    value={this.state.claimNo ? this.state.claimNo : ''}
                                    onChange={e => this.setClaim(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>Status Date:
                        </Col>
                        <Col lg={8}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    value={this?.props?.eligibilityData[0]?.dat_statusdate  ? this.props.eligibilityData[0].dat_statusdate  : ''}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>Compensability Status:
                        </Col>
                        <Col lg={8}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    value={this?.props?.eligibilityData[0]?.chr_compenstat ? this.props.eligibilityData[0].chr_compenstat : ''}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>Claim Status:
                        </Col>
                        <Col lg={8}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    value={this?.props?.eligibilityData[0]?.chr_claimstat ? this.props.eligibilityData[0].chr_claimstat : ''}
                                    onChange={e => this.setSubject(e.target.value)}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>Settlement Date:
                        </Col>
                        <Col lg={8}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    value={this?.props?.eligibilityData[0]?.dat_settlementdate ? this.props.eligibilityData[0].dat_settlementdate : ''}
                                    onChange={e => this.setSubject(e.target.value)}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button style={{ width: '240px' }} color="primary" onClick={this.openOverrideDialog} >Compensability Override</Button>
                    <Button style={{ width: '240px' }} color="primary" onClick={this.loadEligibility} >Load Eligibility</Button>
                    <Button style={{ width: '120px' }} color="primary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <PopupModal
                    title="Adjuster Override" 
                    content={
                        <div className="my-2 d-flex justify-content-center text-center">
                            <Row>
                                <Col lg={4}>Note:
                                </Col>
                                <Col lg={8}>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="note"
                                            id="note"
                                            value={this.state.note}
                                            onChange={e => this.setState({note: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>}
                    isOpen={this.state.overrideModalOpen}
                    externalToggle={() => {
                        this.setState({overrideModalOpen: false})
                    }}
                    footerContent={this.getOverrideModalFooter()}
                    size="md"
                />      
                <PopupModal
                    title="" 
                    content={
                        <div className="my-2 d-flex justify-content-center text-center">
                            <div>WARNING: Eligibility Status has changed; 
                            You MUST contact the assigned adjuster for instructions on how to proceed before you will be able save your current task &nbsp;&nbsp;and move forward.</div>
                        </div>}
                    isOpen={this.state.showNotEligibleDialog}
                    externalToggle={() => {
                        this.setState({showNotEligibleDialog: false})
                    }}
                    footerContent={this.notEligibleDialogFooter()}
                    size="md"
                />      
            </>
        )
    }
}


const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        eligibilityData: state.FAST360.fast360Reducer.eligibilityData
        ? state.FAST360.fast360Reducer.eligibilityData
        : {},
        serviceData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData: {},
        isEligible: state.FAST360.fast360Reducer.isEligible,
    }
}

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    info: msg => dispatch(commonOperations.addAlert(msg, 'info')),
    getEligibilityData: (data) => dispatch(fast360Operations.loadEligibility(data)),
    saveOverrideEligibility: (data) => dispatch(fast360Operations.saveOverrideEligibility(data)),
    checkEligibility: (data) => dispatch(fast360Operations.checkEligibility(data)),
    clearEligibility: () => dispatch(fast360Operations.clearEligibility()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360Eligilibity);