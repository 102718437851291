import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import PopupModal from 'shared/components/PopupModal';

const UsernameEmptyModal = ({ isOpen, onToggle }) => {
  const onSaveHandler = () => {
    onToggle();
  }
  const getContent = () => {
    return (
      <Form>
        <span>Please enter your username.</span>
        <Button
          outline
          color="primary"
          style={{ marginLeft: '200px', marginRight: '200px' }}
          onClick={() => onSaveHandler()}>
          Ok{' '}
        </Button>
      </Form>
    );
  };

  return (
    <PopupModal
      content={getContent()}
      externalToggle={onToggle}
      title={"Password Reset"}
      isOpen={isOpen}
      size="md"
    />
  );
}
export default UsernameEmptyModal;