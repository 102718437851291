import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const NewAdjusterModal = ({
  isOpen,
  onClose,
  userId,
  userRole,
  getAdjusterList,
  adjusterInfo,
  updatedAdjusterInfo,
  resetSearchAdjusterTable,
  getLoadAdjuster,
  updateSelectedItemRef,
  updateDataFromRef,
  loadAdjusterInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadAdjuster,
  resetAdjusterInfo,
  saveAdjuster,
  alert,
  isNew,
  updatedRefInfo,
  NewAdjusterName,
  setIsExternal,
  setAdjusterEdited,
  externalRefData,
  fromExternal,
  fromWorklist,
  worklistData,
  setAdjData,
  referralId,
  isFromLanding,
  loadInfoFromExistingPatient,
  logBreadCrumbs
}) => {
  const [isOpenLoadAdjusterModal, setIsOpenLoadAdjusterModal] = useState(false);
  const [isConfirmSameAsRef, setIsConfirmSameAsRef] = useState(false);
  const [isFromSameAsRef, setIsFromSameAsRef] = useState(false);
  const [isOpenSeachAdjuster, setIsOpenSeachAdjuster] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [adjusterValidFirstName, setAdjusterValidFirstName] = useState(false);
  const [adjusterValidLastName, setAdjusterValidLastName] = useState(false);
  const [adjusterValidEmail, setAdjusterValidEmail] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [adjusterValidPhone, setAdjusterValidPhone] = useState(false);
  const [communicationPreferences, setCommunicationPrefrences] = useState([]);
  const [searchFields, setSearchFields] = useState({
    firstName: (NewAdjusterName ? NewAdjusterName : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    lastName: '',
    adjEmail: (externalRefData?.AdjusterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
    adjPhone: (externalRefData?.AdjusterPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),

  });
  const getSplitName = (name) => {
    let fname = '', lname = '';
    if (name.includes(',')) {
      let managerNameArr = name.split(',');
      fname = managerNameArr[managerNameArr.length - 1];
      lname = managerNameArr[0];
    }
    else {
      let managerNameArr = name.split(' ');
      fname = managerNameArr[0];
      lname = managerNameArr[managerNameArr.length - 1];
    }
    return { fname, lname };
  }
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [formAdjusterFields, setFormAdjusterFields] = useState({
    adjFirstName: (fromExternal.fromExternal ? getSplitName(NewAdjusterName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    adjLastName: (fromExternal.fromExternal ? getSplitName(NewAdjusterName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
    companyName: '',
    adjEmail: (externalRefData?.AdjusterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
    adjPhone: (externalRefData?.AdjusterPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
    adjPhoneExt: '',
    adjFax: '',
    adjAddress: '',
    adjAddress2: '',
    adjCity: '',
    adjCounty: '',
    adjState: '',
    adjZip: '',
    adjZipCodeId: '',
    adjZipId: '',
    adjTimeZone: '',
    adjNotes: '',
    communicationPreference: (externalRefData?.communicationPreference || '') || (!isFromLanding && loadInfoFromExistingPatient?.communicationPreference),
  });

  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSeachAdjuster(true);
      } else {
        setIsOpenLoadAdjusterModal(true);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!formAdjusterFields.adjFirstName)
      setAdjusterValidFirstName(false);
    else setAdjusterValidFirstName(true);
    if (!formAdjusterFields.adjLastName)
      setAdjusterValidLastName(false);
    else setAdjusterValidLastName(true);
    if (!formAdjusterFields.adjEmail)
      setAdjusterValidEmail(false);
    else setAdjusterValidEmail(true);
    if (!formAdjusterFields.adjPhone)
      setAdjusterValidPhone(false);
    else setAdjusterValidPhone(true);
  }, [formAdjusterFields])

  useEffect(() => {
    if(formAdjusterFields.adjFax && formAdjusterFields.adjFax !== "" ) {
      setCommunicationPrefrences([
        { value: 'Email', label: 'Email'},
        { value: 'Fax', label: 'Fax'},
        { value: 'Both', label: 'Both'},
      ]);
    } else {
      setCommunicationPrefrences([
        { value: 'Email', label: 'Email'},
      ]);
    }
  }, [formAdjusterFields.adjFax])

  useEffect(() => {
    setAdjData(formAdjusterFields)
  }, [formAdjusterFields])

  useEffect(() => {
    let fname = '', lname = '';
    if (NewAdjusterName) {
      if (NewAdjusterName.includes(',')) {
        let managerNameArr = NewAdjusterName.split(',');
        fname = managerNameArr[managerNameArr.length - 1];
        lname = managerNameArr[0];
      }

      else {
        let managerNameArr = NewAdjusterName.split(' ');
        fname = managerNameArr[0];
        lname = managerNameArr[managerNameArr.length - 1];
      }
    } else if (!isFromLanding && isEmpty(updatedAdjusterInfo) && !isEmpty(loadInfoFromExistingPatient)) {
      fname = loadInfoFromExistingPatient?.first || ''
      lname = loadInfoFromExistingPatient?.last || ''
    } else {
      if (fromWorklist && worklistData) {
        if (worklistData?.adjuster?.people?.first)
          fname = worklistData?.adjuster?.people?.first
        if (worklistData?.adjuster?.people?.last)
          lname = worklistData?.adjuster?.people?.last
      }
    }
    // let fname = '', lname = '';
    // if (NewAdjusterName) {
    //   let name = getSplitName(NewAdjusterName)
    //   fname = name.fname;
    //   lname = name.lname;
    // }
    if (isOpen) {
      setSearchFields({
        firstName: fname,
        lastName: lname,
      });
      const payload = {
        firstName: fname,
        lastName: lname,
        userId,
      };
      (NewAdjusterName || (fname || lname)) && getAdjusterList(payload);
    }

  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.adjuster?.id) {
        const item = adjusterInfo && adjusterInfo.filter(o => o.PK_adjuster_id === worklistData?.adjuster?.id);
        let index = adjusterInfo && adjusterInfo.findIndex(i => i.PK_adjuster_id === worklistData?.adjuster?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [adjusterInfo])


  useEffect(() => {
    if (isOpenLoadAdjusterModal) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENADJUSTER',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb)
      if (selectedItem) {
        const params = {
          userId,
          functionName: 'loadAdjuster',
          id: selectedItem.PK_adjuster_id,
        };
        getLoadAdjuster(params);
      } else if (!isNew && !adjusterInfo && !isFromSameAsRef) {
        if (!isEmpty(updatedAdjusterInfo)) {
          const pplCheck = updatedAdjusterInfo.adjuster.people || {};
          const adrCheck = updatedAdjusterInfo.adjuster.address || {};
          setFormAdjusterFields({
            adjFirstName: pplCheck.first,
            adjLastName: pplCheck.last,
            companyName: '',
            adjEmail: pplCheck.email1,
            adjPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            adjPhoneExt: pplCheck && pplCheck.phoneExt,
            adjFax: pplCheck && pplCheck.fax,
            adjAddress: adrCheck.street1,
            adjZipCodeId: adrCheck.zipCodeId,
            adjZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            adjTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            adjAddress2: adrCheck.street2,
            adjCity: adrCheck.zipCode && adrCheck.zipCode.city,
            adjCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            adjState: adrCheck.zipCode && adrCheck.zipCode.state,
            adjZip: adrCheck.zipCode && adrCheck.zipCode.zip,
            adjNotes: updatedAdjusterInfo.adjuster.comments,
            communicationPreference: updatedAdjusterInfo.adjuster.communicationPreference
          });
        }
      }
    }
  }, [isOpenLoadAdjusterModal]);

  useEffect(() => {
    if (!isEmpty(loadAdjusterInfo) && !isFromSameAsRef) {
      const pplCheck = loadAdjusterInfo.people || {};
      const adrCheck = loadAdjusterInfo.address || {};
      setFormAdjusterFields({
        adjFirstName: pplCheck.first,
        adjLastName: pplCheck.last,
        companyName: '',
        adjEmail: pplCheck.email1,
        adjPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        adjPhoneExt: pplCheck && pplCheck.phoneExt,
        adjFax: pplCheck && pplCheck.fax,
        adjAddress: adrCheck.street1,
        adjZipCodeId: adrCheck.zipCodeId,
        adjZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        adjTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        adjAddress2: adrCheck.street2,
        adjCity: adrCheck.zipCode && adrCheck.zipCode.city,
        adjCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        adjState: adrCheck.zipCode && adrCheck.zipCode.state,
        adjZip: adrCheck.zipCode && adrCheck.zipCode.zip,
        adjNotes: loadAdjusterInfo.comments,
        communicationPreference: loadAdjusterInfo.communicationPreference
      });
    }
  }, [loadAdjusterInfo]);

  const loadAdjusterInfoToggle = () => {
    setIsFromSameAsRef(false);
    setIsOpenLoadAdjusterModal(!isOpenLoadAdjusterModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormAdjusterFields({ ...formAdjusterFields, adjFirstName: '', adjLastName: '', adjEmail: '' })
    }
  };

  const searchAdjusterToggle = () => {
    setIsOpenSeachAdjuster(!isOpenSeachAdjuster);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    resetSearchAdjusterTable();
    resetLoadAdjuster();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const onChangeHandlerAdjusterType = (id, value) => {
    setFormAdjusterFields({ ...formAdjusterFields, [id]: value });
  };

  const setMap = {
    adjFirstName: setAdjusterValidFirstName,
    adjLastName: setAdjusterValidLastName,
    adjEmail: setAdjusterValidEmail,
    adjPhone: setAdjusterValidPhone
  }
  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "adjPhone") {
      value = formatPhoneNum(value);
    }
    setFormAdjusterFields({ ...formAdjusterFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };

  const isDisabledInput = () => {
    return userRole === "Administrator" ? false : true;
  }

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const renderPhoneColumn = item => {
    const phone = item.chr_phone || '';
    if (phone) {
      return formatPhoneNumber(phone);
    }
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchAdjusterTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'Phone No.',
              field: 'chr_phone',
              render: item => renderPhoneColumn(item),
            },
            {
              header: 'Type',
              field: 'refType',
            },
          ]}
          data={adjusterInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-Adjuster-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadAdjusterModal = () => {
    setIsOpenSeachAdjuster(false);
    setIsOpenLoadAdjusterModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getAdjusterList(params);
  };

  const onChangeCurrentAdjuster = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    setIsOpenLoadAdjusterModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachAdjuster(true);
    setIsFromSameAsRef(false);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormAdjusterFields({
        ...formAdjusterFields,
        adjCity: selectedZip.chr_city,
        adjState: selectedZip.chr_state,
        adjCounty: selectedZip.chr_county,
        adjZip: selectedZip.chr_zipcode,
        adjZipCodeId: selectedZip.code_id,
        adjZipId: selectedZip.code_id,
        adjTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVEATTORNEY',
      refTableId: loadAdjusterInfo.id,
      refId: 0,
      refTable: 'ATT',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem || isFromSameAsRef) {
      const response = loadAdjusterInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formAdjusterFields.adjFirstName;
      response.people.last = formAdjusterFields.adjLastName;
      response.people.email1 = formAdjusterFields.adjEmail;
      response.people.phone =
        formAdjusterFields.adjPhone &&
        formAdjusterFields.adjPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formAdjusterFields.adjPhoneExt;
      response.people.fax = formAdjusterFields.adjFax;
      response.address.street1 = formAdjusterFields.adjAddress;
      response.address.street2 = formAdjusterFields.adjAddress2;
      response.address.zipCode.city = formAdjusterFields.adjCity;
      response.address.zipCode.county = formAdjusterFields.adjCounty;
      response.address.zipCode.state = formAdjusterFields.adjState;
      response.address.zipCode.zip = formAdjusterFields.adjZip;
      response.address.zipCode.id = formAdjusterFields.adjZipId;
      response.address.zipCodeId = formAdjusterFields.adjZipCodeId;
      response.communicationPreference = formAdjusterFields.communicationPreference;
      response.comments = formAdjusterFields.adjNotes;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.adjusterOffice;
      const adjusterId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        adjusterId,
        refId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.people.phone === '' || response.people.phone === undefined) {
        alert('You must provide a 10 digit phone number in order to save.');
        return;
      }
      saveAdjuster(params);
      loadAdjusterInfoToggle();
    } else if (!isNew && !adjusterInfo && !isFromSameAsRef) {
      const response = updatedAdjusterInfo && updatedAdjusterInfo.adjuster;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formAdjusterFields.adjFirstName;
      response.people.last = formAdjusterFields.adjLastName;
      response.people.email1 = formAdjusterFields.adjEmail;
      response.people.phone =
        formAdjusterFields.adjPhone &&
        formAdjusterFields.adjPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formAdjusterFields.adjPhoneExt;
      response.people.fax = formAdjusterFields.adjFax;
      response.address.street1 = formAdjusterFields.adjAddress;
      response.address.street2 = formAdjusterFields.adjAddress2;
      response.address.zipCode.city = formAdjusterFields.adjCity;
      response.address.zipCode.county = formAdjusterFields.adjCounty;
      response.address.zipCode.state = formAdjusterFields.adjState;
      response.address.zipCode.zip = formAdjusterFields.adjZip;
      response.address.zipCode.id = formAdjusterFields.adjZipId;
      response.address.zipCodeId = formAdjusterFields.adjZipCodeId;
      response.communicationPreference = formAdjusterFields.communicationPreference;
      response.comments = formAdjusterFields.adjNotes;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.adjusterOffice;
      const adjusterId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        adjusterId,
        refId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.people.phone === '' || response.people.phone === undefined) {
        alert('You must provide a 10 digit phone number in order to save.');
        return;
      }
      saveAdjuster(params);
      loadAdjusterInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.communicationPreference = formAdjusterFields.communicationPreference;
      response.comments = formAdjusterFields.adjNotes;
      response.people = {
        id: 0,
        first: formAdjusterFields.adjFirstName,
        last: formAdjusterFields.adjLastName,
        email1: formAdjusterFields.adjEmail,
        phone:
          formAdjusterFields.adjPhone &&
          formAdjusterFields.adjPhone.replace(/[\s()-]/g, ''),
        phoneExt: formAdjusterFields.adjPhoneExt,
        fax: formAdjusterFields.adjFax,
      };
      response.address = {
        id: 0,
        street1: formAdjusterFields.adjAddress,
        street2: formAdjusterFields.adjAddress2,
        zipCodeId: formAdjusterFields.adjZipCodeId,
        zipCode: {
          city: formAdjusterFields.adjCity,
          county: formAdjusterFields.adjCounty,
          state: formAdjusterFields.adjState,
          zip: formAdjusterFields.adjZip,
          id: formAdjusterFields.adjZipId,
          timeZone: formAdjusterFields.adjTimeZone,
        },
      };

      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      const adjusterId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        adjusterId,
        refId
      };
      saveAdjuster(params);
      loadAdjusterInfoToggle();
    }
    // setIsExternal();
    setAdjusterEdited(true)
  };

  const getBody = () => {
    if (adjusterInfo && adjusterInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new Adjuster as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Adjuster?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsFromSameAsRef(false);
    setFormAdjusterFields({
      adjFirstName: (fromExternal.fromExternal ? getSplitName(NewAdjusterName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
      adjLastName: (fromExternal.fromExternal ? getSplitName(NewAdjusterName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
      companyName: '',
      adjEmail: (externalRefData?.AdjusterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
      adjPhone: (externalRefData?.AdjusterPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
      adjPhoneExt: '',
      adjFax: '',
      adjAddress: '',
      adjAddress2: '',
      adjCity: '',
      adjCounty: '',
      adjState: '',
      adjZip: '',
      adjZipCodeId: '',
      adjZipId: '',
      adjTimeZone: '',
      adjNotes: '',
      communicationPreference: (externalRefData?.communicationPreference || '') || (!isFromLanding && loadInfoFromExistingPatient?.communicationPreference),
    });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadAdjusterModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              Cannot assign Adjuster to a non Adjuster
              <br />
              <br />
              <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={() => onToggle()}>
                Ok{' '}
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  const onToggle = () => {
    setIsConfirmSameAsRef(!isConfirmSameAsRef);
    setIsFromSameAsRef(false);
  }

  const confirmSameAsRef = () => {
    setIsFromSameAsRef(true);
    if (updateSelectedItemRef === "Adjuster") {
      const params = {
        functionName: "loadAdjuster",
        userId: userId,
        id: updateDataFromRef
      }
      getLoadAdjuster(params);
      const pplCheck = updatedRefInfo.referrer && updatedRefInfo.referrer.people || {};
      const adrCheck = updatedRefInfo.referrer && updatedRefInfo.referrer.address || {};
      const result = {
        adjFirstName: pplCheck.first,
        adjLastName: pplCheck.last,
        companyName: '',
        adjEmail: pplCheck.email1,
        adjPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        adjPhoneExt: pplCheck && pplCheck.phoneExt,
        adjFax: pplCheck && pplCheck.fax,
        adjAddress: adrCheck.street1,
        adjZipCodeId: adrCheck.zipCodeId,
        adjZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        adjTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        adjAddress2: adrCheck.street2,
        adjCity: adrCheck.zipCode && adrCheck.zipCode.city,
        adjCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        adjState: adrCheck.zipCode && adrCheck.zipCode.state,
        adjZip: adrCheck.zipCode && adrCheck.zipCode.zip,
        adjNotes: updatedRefInfo.comments,
      };
      setFormAdjusterFields(result);
      openLoadAdjusterModal();
    } else {
      return (
        <>
          {onToggle()}
        </>
      )
    }
  }

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadAdjusterModal}>
        <ModalHeader toggle={loadAdjusterInfoToggle}>
          ADD A NEW ADJUSTER
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={3}>
              <Label for="adjFirstName">Adjuster First Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="adjFirstName"
                value={formAdjusterFields.adjFirstName || ''}
                className={adjusterValidFirstName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={(selectedItem || (!isNew && !adjusterInfo && !isFromSameAsRef) || isFromSameAsRef) && isDisabledInput()}
              />
            </Col>
            <Col sm={3}>
              <Label for="adjLastName">Adjuster Last Name</Label>{' '}
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="adjLastName"
                value={formAdjusterFields.adjLastName || ''}
                className={adjusterValidLastName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={(selectedItem || (!isNew && !adjusterInfo && !isFromSameAsRef) || isFromSameAsRef) && isDisabledInput()}
              />
            </Col>
            <Col sm={3}>
              <Label for="companyName">Company Name</Label>
              <Input
                type="text"
                id="companyName"
                value={formAdjusterFields.companyName || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <Col style={{ top: "-8px", color: "red", paddingLeft: 0 }}> {(selectedItem || (!isNew && !adjusterInfo && !isFromSameAsRef) || isFromSameAsRef) && <span>{isDisabledInput() ? "Please contact Administrator if you want to change a Adjuster Name" : "Warning: Changing the name on this record will also change it on any referral that this record is linked to!"}</span>}</Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="adjAddress">Adjuster Address</Label>
              <Input
                type="text"
                id="adjAddress"
                value={formAdjusterFields.adjAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="adjAddress2">Adjuster Address 2</Label>
              <Input
                type="text"
                id="adjAddress2"
                value={formAdjusterFields.adjAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="adjCity">Adjuster City</Label>
              <Input
                type="text"
                id="adjCity"
                value={formAdjusterFields.adjCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="adjState">State</Label>
              <Input
                type="text"
                id="adjState"
                value={formAdjusterFields.adjState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="adjCounty">County</Label>
              <Input
                type="text"
                id="adjCounty"
                value={formAdjusterFields.adjCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="adjZip">Adjuster Zip</Label>
              <Input
                type="text"
                id="adjZip"
                value={formAdjusterFields.adjZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="adjZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'adjZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="adjZip"
                id="adjZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="adjEmail">Adjuster Email</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="adjEmail"
                value={formAdjusterFields.adjEmail || ''}
                className={adjusterValidEmail ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="adjPhone">Adjuster Phone#</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="tel"
                id="adjPhone"
                value={formAdjusterFields.adjPhone || ''}
                className={adjusterValidPhone ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="adjPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="adjPhoneExt"
                value={formAdjusterFields.adjPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="adjFax">Adjuster Fax#</Label>
              <Input
                type="text"
                id="adjFax"
                value={formAdjusterFields.adjFax || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
              <Col sm={4} >
                  <Label for="communicationPreference">Communication Preference</Label>
                  <AppInput
                      id="communicationPreference"
                      type="select"
                      changeHandler={(e, val) => { 
                        setFormAdjusterFields({ ...formAdjusterFields, communicationPreference: val }) 
                          
                      }}
                      option={{
                          value: 'value',
                          displayValue: 'label',
                      }}
                      placeholder="Choose..."
                      value={(formAdjusterFields.communicationPreference === '' || formAdjusterFields.communicationPreference === undefined)  ? 'Email' : formAdjusterFields.communicationPreference}
                      data={communicationPreferences}
                  />
              </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label for="adjNotes">Comments</Label>
              <Input
                type="textarea"
                id="adjNotes"
                value={formAdjusterFields.adjNotes || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentAdjuster}>
              CHANGE CURRENT ADJUSTER
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSeachAdjuster}>
        <ModalHeader toggle={searchAdjusterToggle}>SEARCH ADJUSTER</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    id="firstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.firstName}

                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>{' '}
                  <Input
                    type="text"
                    id="lastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.lastName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
              <Col sm={3}>
                <br />
                <Button
                  style={{ marginTop: '7px' }}
                  type="button"
                  disabled={R.isEmpty(updatedRefInfo)}
                  onClick={confirmSameAsRef}
                >
                  SAME AS REF
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchAdjusterTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!adjusterInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadAdjusterModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
      <PopupModal
        content={getContent()}
        externalToggle={onToggle}
        title={''}
        isOpen={isConfirmSameAsRef}
        size="md"
      />
    </>
  );
};

NewAdjusterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  adjusterInfo: PropTypes.array,
  getAdjusterList: PropTypes.func,
  resetSearchAdjusterTable: PropTypes.func,
  getLoadAdjuster: PropTypes.func,
  loadAdjusterInfo: PropTypes.object,
  AdjusterType: PropTypes.array,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadAdjuster: PropTypes.func,
  saveAdjuster: PropTypes.func,
  alert: PropTypes.func,
  resetAdjusterInfo: PropTypes.func
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);

  return {
    adjusterInfo: fast360Info.adjusterInfo,
    loadAdjusterInfo: fast360Info.loadAdjusterInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    updatedAdjusterInfo: fast360Info.updatedAdjusterInfo,
    updateSelectedItemRef: fast360Info.updateSelectedItemRef,
    updateDataFromRef: fast360Info.updateDataFromRef,
    updatedRefInfo: fast360Info.updatedRefInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getAdjusterList: data => dispatch(fast360Operations.getAdjusterList(data)),
  getLoadAdjuster: data => dispatch(fast360Operations.getLoadAdjuster(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchAdjusterTable: () =>
    dispatch(fast360Actions.resetSearchAdjusterTable()),
  resetLoadAdjuster: () => dispatch(fast360Actions.resetLoadAdjuster()),
  resetAdjusterInfo: () => dispatch(fast360Actions.resetAdjusterInfo()),
  saveAdjuster: data => dispatch(fast360Operations.saveAdjuster(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAdjusterModal);
