import {CustomInput} from "reactstrap";
import React from "react";

export function SelectableCell({rowIndex, selectHandler, selected}) {
    return (
        <td>
            <CustomInput
                id={rowIndex}
                type="checkbox"
                checked={selected}
                onChange={e => selectHandler(rowIndex, e.target.checked)}/>
        </td>
    )
}
