import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AppTable from 'shared/components/table/AppTable';

import { administrationSelectors } from '../store';

library.add(faExternalLinkSquareAlt);

const UserResultsTable = () => {
  const search = useSelector(administrationSelectors.getSearch);

  return (
    <AppTable
      resizable
      columns={[
        {
          header: 'Id',
          rowKey: true,
          field: 'userId',
        },
        {
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Location',
          field: 'location',
        },
        {
          header: 'Role',
          field: 'ruleName',
        },
        {
          header: 'User Name',
          field: 'userName',
        },
        {
          header: 'Last Login',
          field: 'lastLogin',
        },
        {
          header: 'Tasks',
          field: 'totalTasks',
        },
        {
          header: 'Edit User',
          field: 'editUser',
          render: item => (
            <NavLink
              to={{
                pathname: '/administration/user',
                state: { userId: item.userId },
              }}
            >
              <FontAwesomeIcon icon="external-link-square-alt" />
            </NavLink>
          ),
        },
      ]}
      data={search.data || []}
      autoPagination
    />
  );
};

export default UserResultsTable;
