import React, { useEffect, useState } from 'react';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';

import Fast360EditHeader from './Fast360EditHeader';
import Fast360Services from './Fast360Services';
import { connect } from 'react-redux';
import AppInput from 'shared/components/input/AppInput';
import * as R from 'ramda';
import NewReferralHeaders from './Fast360NewReferralHeaders';
import { fast360Operations, fast360Selectors } from '../store/index';
import { authSelectors } from '../../auth/store';
import { TransportDetailContextProvider } from './TransportDetailModalContext';
import { Fast360SPTDetailModalContextProvider } from '../components/AdvanceSearch/contexts/Fast360SPTDetailModalContext';
import { Fast360VendorSearchContextProvider } from '../components/AdvanceSearch/contexts/Fast360VendorSearchContext';
import { commonOperations } from 'common/store';
import Drawer from '@material-ui/core/Drawer';
import Fast360PayableLines from './Fast360PayableLines';
import Fast360DGNMedicalQuestions from './Fast360DGNMedicalQuestions';

const Fast360Ref = props => {
  const listItems = R.map(([key, value]) => {
    return `${key}: ${value}`;
  }, props.externalReferralData.length > 0 && Object.entries(props.externalReferralData[0]));
  const textValue = R.join('\n', listItems);

  const [specialInstructions, setSpecialInstructions] = useState(textValue);
  
  const onChangeHandler = (id, value) => {
    setSpecialInstructions(value);
  };
  const [refTransportation, setRefTransportation] = useState('');
 // const [details, setDetails] = useState({});
  const [isOpen, setisOpen] = useState(false);
  // const [readyForBilling, setReadyForBilling] = useState(false);

  const objMap = {
    'mse': 'mseList',
    'hhc': 'hhcList',
    'dtl': 'dentalList',
    'int': 'translationList',
    'trn': 'transportationList',
    'acc': 'accommodationList',
    'aug': 'audiologyList',
    'mod': 'modificationList',
    'spt': 'specialTransportationList',
    'dgn': 'diagnosticList',
  }

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setisOpen(false);
  };

  useEffect(() => {
    props.getLegStatus(props.userId);
  }, [props.userId]);


  useEffect(() => {
    if (props.fromWorklist && props.workListData.instructions)
      setSpecialInstructions(props.workListData.instructions);
  }, [props.workListData]);

  useEffect(() => {
    const list = props?.workListData[objMap[props?.currentWorkList?.chr_reference_table?.toLowerCase()]];

    if (props.currentWorkList.currentMilestone === 'UnBilled' || props.currentWorkList.currentTaskName === 'UnBilled') {
      //setShow(true);
      if (list?.length > 0) {
        if (props.currentWorkList.ReadyforGP != undefined){
       /*     if (props.currentWorkList.ReadyforGP === "1" || props.currentWorkList.ReadyforGP === 1 || props.currentWorkList.ReadyforGP === true){
              props.setReadyForBilling(true);
            }else{
              props.setReadyForBilling(false);
            }*/
        }
        //props.setReadyForBilling(list[0].readyForGp)
        props.setListToUpdate(objMap[props.currentWorkList.chr_reference_table.toLowerCase()]);
      }
    }
  }, [props.currentWorkList.currentMilestone, props.currentWorkList.currentTaskName, props.workListData]);

  useEffect(() => {
    const req = {
      functionName :'skipPayableLines'
    };

    props.loadSkipPayableLines(req);
  }, [])
  return (
    <div>
      <FormGroup>
        <Fast360EditHeader fromExternal={props} isFromLanding={props.isFromLanding} />


        <hr></hr>
        <Row>
          <Col sm="8"> <Button style={{ width: '20%', marginBottom: '15px' }} onClick={() => setisOpen(true)}>Special Instructions</Button></Col>

        </Row>
        <Drawer anchor={'bottom'} open={isOpen} onClose={toggleDrawer()}>
          <Row style={{ margin: 20, height: 220 }}>
            <Col>
              <Label>Special Instructions: </Label>
              <AppInput
                type="textarea"
                placeholder=""
                name="specialInstructions"
                id="specialInstructions"
                value={specialInstructions}
                changeHandler={onChangeHandler}
                style={{ height: 200 }}
              />
            </Col>
          </Row>
        </Drawer>
        <Fast360VendorSearchContextProvider>
          <Fast360Services
            disableSave={props.disableSave}
            setDisableSave={props.setDisableSave}
            serviceVal={props.serviceVal}
            onSaveComp={props.onSaveComp}
            instValue={specialInstructions}
            setRefTransportation={setRefTransportation}
            setDetails={props.setDetails}
            details={props.details}
            refTransportation={refTransportation}
            isFromLanding={props.isFromLanding}
            lmsUserId={props.lmsUserId}
            userId={props.userId}
            serviceType={props.data.ServiceType}
            data={props.data}
            loadReferralWorkList={props.loadReferralWorkList}
            dataObject={props.dataObject}
            worklistData={props}
            readyForBilling={props.readyForBilling}
            setReadyForBilling={props.setReadyForBilling}
            loadReferralandVender={props.loadReferralandVender}
            fromExternal={props.fromExternal}
            setFollowupDate360={props.setFollowupDate}
            // diagnosticQuestions={diagnosticQuestions}
            onWorklistExit={props.onWorklistExit}
          />
        </Fast360VendorSearchContextProvider>

        { (props.requiredServiceData?.module === 'DGN' ||  props.requiredServiceData?.selectedService?.module === 'DGN')  &&  <Fast360DGNMedicalQuestions fromExternal={props}  />}
        <br />

        <br></br>
        <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active text-primary" data-toggle="tab" href="#orderDetails" >Order Details</a>
          </li>
          {props.userRole === "Invoice Coordinator" && !props.skipPayableLines &&
           <li class="nav-item">
          <a class="nav-link text-primary" data-toggle="tab" href="#payableLines">Payable Lines</a>
          </li> }
        </ul>


        {/* <TransportDetailContextProvider>
          <Fast360SPTDetailModalContextProvider>
            <NewReferralHeaders
              refTransportation={refTransportation}
              disableSave={props.disableSave}
              setDisableSave={props.setDisableSave}
              loadLegStatus={props.loadLegStatus}
              userId={props.userId}
              timeZone={props.timeZone}
              zipList={props.zipList}
              getLegStatus={props.getLegStatus}
              getZipList={props.getZipList}
              alert={props.alert}
              updatedClaimInfo={props.updatedClaimInfo}
              isFromLanding={props.isFromLanding}
              loadlookUpLocation={props.loadlookUpLocation}
              workListData={props.workListData}
              serviceType={props.data.ServiceType}
              data={props.data}
              serviceVal={props.serviceVal}
              onSaveComp={props.onSaveComp}
              details={props.details}
              setDetails={props.setDetails}
              isNew={props.isNewReferral}
            />
          </Fast360SPTDetailModalContextProvider>
        </TransportDetailContextProvider> */}

        <div class="tab-content mt-3" id="tabContent">
          <div class="tab-pane fade show active" id="orderDetails" role="tabpanel" aria-labelledby="Order Details">
          <TransportDetailContextProvider>
          <Fast360SPTDetailModalContextProvider>
            <NewReferralHeaders
              disableSave={props.disableSave}
              refTransportation={refTransportation}
              loadLegStatus={props.loadLegStatus}
              userId={props.userId}
              timeZone={props.timeZone}
              zipList={props.zipList}
              getLegStatus={props.getLegStatus}
              getZipList={props.getZipList}
              alert={props.alert}
              updatedClaimInfo={props.updatedClaimInfo}
              isFromLanding={props.isFromLanding}
              loadlookUpLocation={props.loadlookUpLocation}
              workListData={props.workListData}
              serviceType={props.data.ServiceType}
              data={props.data}
              serviceVal={props.serviceVal}
              onSaveComp={props.onSaveComp}
              details={props.details}
              setDetails={props.setDetails}
              isNew={props.isNewReferral}
              isExternalReferral={props.isExternalReferral}
            />
          </Fast360SPTDetailModalContextProvider>
        </TransportDetailContextProvider>
          </div>
          <div class="tab-pane fade" id="payableLines" role="tabpanel" aria-labelledby="Payable Lines">
          {props.userRole === "Invoice Coordinator" && !props.skipPayableLines && <Fast360PayableLines />}
          </div>
        </div>
        </div> 
       


      </FormGroup>
     
    </div >
  );
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    userId: user.id,
    userRole: user.role_name,
    loadLegStatus: initialData.loadLegStatus,
    timeZone: initialData.timeZone,
    zipList: fast360Info.zipList,
    loadlookUpLocation: initialData.loadlookUpLocation,
    updatedClaimInfo:
      fast360Info.updatedClaimInfo && fast360Info.updatedClaimInfo.claim,
    externalReferralData: state.FAST360.fast360Reducer.updatePostExternal
      ? state.FAST360.fast360Reducer.updatePostExternal
      : [],
    workListData: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    selectedVendorData: state.FAST360.fast360Reducer.selectedVendorData ? state.FAST360.fast360Reducer.selectedVendorData : [],
    taskData: state.FAST360.fast360Reducer.updateloadExistingTasks
      ? state.FAST360.fast360Reducer.updateloadExistingTasks
      : {},
    groupProgressData: state.FAST360.fast360Reducer.updateloadGroupProgression
      ? state.FAST360.fast360Reducer.updateloadGroupProgression
      : [],
    currentWorkList: fast360Info.currentWorkListItem,
    skipPayableLines: state.FAST360.fast360Reducer.skipPayableLines ? state.FAST360.fast360Reducer.skipPayableLines : false,
    requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData,
  };
  
};

const mapDispatchToProps = dispatch => ({
  getLegStatus: data => dispatch(fast360Operations.getLegStatus(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  loadSkipPayableLines: (data) => dispatch(fast360Operations.loadSkipPayableLines(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360Ref);
