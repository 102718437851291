import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import ValidationErrorsModal from './ValidationErrorsModal';

const UpdatePasswordForm = ({ setLoginState, setUsername, setPassword }) => {
  const { updatePassword, passwordUpdateErrorMessage, login, loginResult, currentQuestionId, currentQuestion, username } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypeNewPassword, setReTypeNewPassword] = useState('');
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState('');
  const [passwordUpdateValidationError, setPasswordUpdateValidationError] = useState('');

  useEffect(() => {
    let errorObject = {};
    if (passwordUpdateErrorMessage === 'New Password is same as one of previous 6 passwords' && validationErrors == '') {
      errorObject['Error message3'] = 'New Password is same as one of previous 6 passwords';
      setShowValidationModal(true);
      setPasswordUpdateValidationError(errorObject);
    }

    if (passwordUpdateErrorMessage === '') {
      setLoginState('login');
      setUsername(username);
      setPassword('');
    }
  }, [passwordUpdateErrorMessage]);
  
  const checkPasswordInvalid = (password, reTypeNewPassword) => {
    {
      let errors = false;
      let errorObject = {};
      if (oldPassword === '' && password !='' && reTypeNewPassword != '') {
        errorObject['Old password1'] = 'Invalid password'
        setShowValidationModal(true);
        errors = true;
      }
      if (password === '' && reTypeNewPassword === '') {
        errorObject['New password3'] = 'New password must be 8 characters in length.'
        errorObject['New password1'] = 'New password must contain atleast 1 upper case letter.'
        errorObject['New password2'] = 'New password must contain atleast 1 number.'
        errorObject['Confirmation password3'] = 'Confirmation password must be 8 characters in length.'
        errorObject['Confirmation password1'] = 'Confirmation password must contain atleast 1 upper case letter.'
        errorObject['Confirmation password2'] = 'Confirmation password must contain atleast 1 number.'
        setShowValidationModal(true);
        errors = true;
      }

      if (password === '' && reTypeNewPassword!= '') {
        errorObject['New password3'] = 'New password must be 8 characters in length.'
        errorObject['New password1'] = 'New password must contain atleast 1 upper case letter.'
        errorObject['New password2'] = 'New password must contain atleast 1 number.'
        setShowValidationModal(true);
        errors = true;
      }
      if (password!='' && !/^.{8,}/.test(password)) {
        errorObject['New password3'] = 'New password must be 8 characters in length.'
        setShowValidationModal(true);
        errors = true;
      }
      if (password!='' && !/^(?=(.*?[A-Z]){1,})/.test(password)) {
        errorObject['New password1'] = 'New password must contain atleast 1 upper case letter.'
        setShowValidationModal(true);
        errors = true;
      }
      if (password!='' && !/^(?=(.*?[0-9]){1,})/.test(password)) {
        errorObject['New password2'] = 'New password must contain atleast 1 number.'
        setShowValidationModal(true);
        errors = true;
      }
      if (password != '' && reTypeNewPassword === '') {
        errorObject['Confirmation password3'] = 'Confirmation password must be 8 characters in length.'
        errorObject['Confirmation password1'] = 'Confirmation password must contain atleast 1 upper case letter.'
        errorObject['Confirmation password2'] = 'Confirmation password must contain atleast 1 number.'
        errorObject['Error message2'] = 'The confirmation must match exactly the new password.';
        setShowValidationModal(true);
        errors = true;
      }

      if (reTypeNewPassword!= '' && !/^.{8,}/.test(reTypeNewPassword)) {
        errorObject['Confirmation password3'] = 'Confirmation password must be 8 characters in length.'
        setShowValidationModal(true);
        errors = true;
      }
      if (reTypeNewPassword!= '' && !/^(?=(.*?[A-Z]){1,})/.test(reTypeNewPassword)) {
        errorObject['Confirmation password1'] = 'Confirmation password must contain atleast 1 upper case letter.'
        setShowValidationModal(true);
        errors = true;
      }
      if (reTypeNewPassword!= '' && !/^(?=(.*?[0-9]){1,})/.test(reTypeNewPassword)) {
        errorObject['Confirmation password2'] = 'Confirmation password must contain atleast 1 number.'
        setShowValidationModal(true);
        errors = true;
      }
      if (oldPassword != '' && oldPassword === newPassword) {
        errorObject['Error message1'] = 'The new password must not be the same as your old password.';
        setShowValidationModal(true);
        errors = true;
      }
      if (newPassword != '' && reTypeNewPassword != '' && newPassword != reTypeNewPassword) {
        errorObject['Error message2'] = 'The confirmation must match exactly the new password.';
        setShowValidationModal(true);
        errors = true;
      }
      if (errors === true) {
        setValidationErrors(errorObject);
      }
      return errors;
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Form
        onSubmit={e => {
          e.preventDefault();
          {
            setValidationErrors('');
            setPasswordUpdateValidationError('');
            checkPasswordInvalid(newPassword, reTypeNewPassword) === false &&
              updatePassword(username, oldPassword, newPassword);
            if (passwordUpdateErrorMessage === 'New Password is same as one of previous 6 passwords' && checkPasswordInvalid(newPassword, reTypeNewPassword) === false) {
              setShowValidationModal(true);
              let errorObject = {}; 
              errorObject['Error message3'] = 'New Password is same as one of previous 6 passwords';
              setPasswordUpdateValidationError(errorObject);
            }
            if (passwordUpdateErrorMessage === '') {
              setLoginState('login');
              setUsername(username);
              setPassword('');
            }
          }
        }}
        className="york-form update-password-form"
      >
        <Col lg="12">
          <span>
            Please enter your Current Password then create a new password
    </span>
        </Col>
        <br />
        <Col lg="12">
        </Col>
        <Col lg="12">
          <br />
          <br />
          <FormGroup className="group full">
            <Label for="oldPassword">Current Password</Label>
            <Input
              type="password"
              name="oldPassword"
              id="oldPassword"
              onChange={e => setOldPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="group full">
            <Label for="newPassword">New Password</Label>
            <Input
              type="password"
              name="newPassword"
              id="newPassword"
              onChange={e => setNewPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="group full">
            <Label for="reTypeNewPassword">Retype New Password</Label>
            <Input
              type="password"
              name="reTypeNewPassword"
              id="reTypeNewPassword"
              onChange={e => setReTypeNewPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <Row className="action">
            <Col lg="6" xs="6">
              <button className="link-underline orange" type="submit">
                Update
              </button>
            </Col>
          </Row>
        </Col>
        <ValidationErrorsModal isOpen={showValidationModal} onToggle={() => { setShowValidationModal(false) }} errors={validationErrors == '' ? passwordUpdateValidationError : validationErrors} />
      </Form>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '96px', marginLeft: '10px' }}>
        <Row className="box">
          <div style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
            <b>New Password Rules</b>
            <br />
            <span>Must be 8 characters long</span>
            <br />
            <span>Must contain at least 1 capital letter</span>
            <br />
            <span>Must contain at least 1 number</span>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default UpdatePasswordForm;
