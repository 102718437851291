import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

const GROUP_NAME = 'Group Name';
export default class NewGroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: GROUP_NAME,
      sortOrder: true,
    };
  }

  getRow(item) {
    return {
      id: item.expert_id,
      groupName: item.groupname || '',
    };
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case GROUP_NAME:
      default:
        const groupName1 = item1.groupname || '';
        const groupName2 = item2.groupname || '';
        return sortOrder
          ? groupName1.localeCompare(groupName2)
          : groupName2.localeCompare(groupName1);
    }
  }

  render() {
    const data = this.props.data
      .sort((item1, item2) => this.sortData(item1, item2))
      .map((item, index) => this.getRow(item, index));
    return (
      <div className="row-clickable">
        <AppTable
          resizable
          columns={[
            {
              header: this.getHeader(GROUP_NAME),
              field: 'groupName',
            },
          ]}
          data={data}
          rowClickHandler={this.props.onGroupClickHandler}
          autoPagination={true}
        />
      </div>
    );
  }
}

NewGroupTable.propTypes = {
  data: PropTypes.array.isRequired,
  onGroupClickHandler: PropTypes.func.isRequired,
};
