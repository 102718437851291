import { combineReducers } from 'redux';

import {
  ADD_ADJUSTER,
  CANCEL_NEW_USER,
  SAVE_ADMIN_USER_FORM,
  CHANGE_PROFILE,
  OPEN_MODAL,
  UPDATE_DATA_TABLE,
  UPDATE_USER_INPUT,
  UPDATE_USER_CLIENTS,
  UPDATE_USER_TABLE,
  LOAD_USER_INFORMATION,
  SET_ACTIVE_ANCIL_ACCESS_PANEL,
  UPDATE_ANCIL_ACCESS,
  UPDATE_DEFAULT_CHECKBOX,
  UPDATE_CLIENT_LISTS,
  LOAD_ADJUSTER_MAPING_INFORMATION,
  LOAD_ADJUSTER_LIST,
  UPDATE_ALL_CHECKBOXES,
  APPLY_THRESHOLD_TO_SELECTED,
  UPDATE_LOCATION_LIST, 
} from './constants';

const adjusterDefaultState = {
  profileId: 'default',
  modal: false,
  currentAdjuster: {},
  selectedAdjusters: [],
};

const defaultState = {
  userLandingPage: {
    data: [],
  },
  userLocationList: [],
  userId: '',
  userBasics: {
    salutation: '',
    salutationSelect: [],
    firstName: '',
    lastName: '',
    gender: '',
    primaryEmailAddress: '',
    secondaryEmailAddress: '',
    honorific: '',
    honorificSelect: [],
    language: '',
    languageSelect: [],
    workPhone: '',
    ext: '',
    cellPhone: '',
    fax: '',
    pager: '',
    pin: '',
    location: '',
    applicationTimeout: '',
    taskNotificationMethod: '',
    locationList: [],
    carrier: '',
    carrierList: [],
  },
  userAccess: {
    userName: '',
    userDisabled: false,
    roleSelect: '',
    roles: [],
    dataTable: [
      {
        id: 'adminDashboard',
        moduleName: 'Admin Dashboard',
        allowAccess: false,
        default: false,
      },
      {
        id: 'administration',
        moduleName: 'Administration',
        allowAccess: false,
        default: false,
      },
      {
        id: 'billReview',
        moduleName: 'Bill Review',
        allowAccess: false,
        default: false,
      },
      {
        id: 'overRide',
        moduleName: 'Override',
        allowAccess: false,
        default: false,
      },
    ],
    dataAccessLevel: [
      {
        id: 'accessZebraSupportUser',
        value: 'Zebra Support User',
        checked: false,
      },
      {
        id: 'accessShowPrintDialog',
        value: 'Show Print Dialog (EVALS)',
        checked: false,
      },
      {
        id: 'accessOverridePPO',
        value: 'Override PPO Site Rules (BB)',
        checked: false,
      },
      {
        id: 'accessDefaultToTasklist',
        value: 'Defaults to Tasklist (BB)',
        checked: false,
      },
      {
        id: 'accessHideQAButton',
        value: 'Hide QA Button (BB)',
        checked: false,
      },
      {
        id: 'accessEditMBRData',
        value: 'Edit MBR Data',
        checked: false,
      },
      {
        id: 'accessCanSendReferrals',
        value: 'Can Send Referrals (EVALS)',
        checked: false,
      },
    ],
  },
  userAncilAccess: {
    dataTable: [
      {
        id: 'ancilAdmin',
        userRole: 'Admin',
        allowAccess: false,
        default: false,
        access: [
          {
            id: 'acc',
            value: 'Accommocation',
            checked: false,
          },
          {
            id: 'adj',
            value: 'Adjuster',
            checked: false,
          },
          {
            id: 'aud',
            value: 'Audiology',
            checked: false,
          },
          {
            id: 'clms',
            value: 'Claims',
            checked: false,
          },
          {
            id: 'clnts',
            value: 'Clients',
            checked: false,
          },
          {
            id: 'dtl',
            value: 'Dental',
            checked: false,
          },
          {
            id: 'dgn',
            value: 'Diagnostic',
            checked: false,
          },
          {
            id: 'hhc',
            value: 'HHC',
            checked: false,
          },
          {
            id: 'intr',
            value: 'Interpretation',
            checked: false,
          },
          {
            id: 'modi',
            value: 'Modification',
            checked: false,
          },
          {
            id: 'mse',
            value: 'MSE',
            checked: false,
          },
          {
            id: 'spt',
            value: 'Special Transport',
            checked: false,
          },
          {
            id: 'trn',
            value: 'Transportation',
            checked: false,
          },
          {
            id: 'ven',
            value: 'Vendors',
            checked: false,
          },
        ],
      },
      {
        id: 'ancilExternalUser',
        userRole: 'External User',
        allowAccess: false,
        default: false,
        access: [
          {
            id: 'ancilZebraSupportUser',
            value: 'Zebra Support User',
            checked: false,
          },
          {
            id: 'ancilShowPrintDialog',
            value: 'Show Print Dialog (EVALS)',
            checked: false,
          },
          {
            id: 'ancilOverridePPO',
            value: 'Override PPO Site Rules (BB)',
            checked: false,
          },
          {
            id: 'ancilDefaultToTasklist',
            value: 'Defaults to Tasklist (BB)',
            checked: false,
          },
          {
            id: 'ancilHideQAButton',
            value: 'Hide QA Button (BB)',
            checked: false,
          },
          {
            id: 'ancilEditMBRData',
            value: 'Edit MBR Data',
            checked: false,
          },
          {
            id: 'ancilCanSendReferrals',
            value: 'Can Send Referrals (EVALS)',
            checked: false,
          },
        ],
      },
      {
        id: 'ancilInvoiceCoordinator',
        userRole: 'Invoice Coordinator',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilMaintenance',
        userRole: 'Maintenance',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilProductSpecialist',
        userRole: 'Product Specialist',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilQASpecialist',
        userRole: 'QA Specialist',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilReferralEntry',
        userRole: 'Referral Entry',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilSales',
        userRole: 'Sales',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilVOS',
        userRole: 'VOS',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilVendorRecruitment',
        userRole: 'Vendor Recruitment',
        allowAccess: false,
        default: false,
      },
      {
        id: 'ancilVendorRelations',
        userRole: 'Vendor Relations',
        allowAccess: false,
        default: false,
      },
    ],
    ancilAccess: [
      {
        id: 'ancilZebraSupportUser',
        value: 'Zebra Support User',
        checked: false,
      },
      {
        id: 'ancilShowPrintDialog',
        value: 'Show Print Dialog (EVALS)',
        checked: false,
      },
      {
        id: 'ancilOverridePPO',
        value: 'Override PPO Site Rules (BB)',
        checked: false,
      },
      {
        id: 'ancilDefaultToTasklist',
        value: 'Defaults to Tasklist (BB)',
        checked: false,
      },
      {
        id: 'ancilHideQAButton',
        value: 'Hide QA Button (BB)',
        checked: false,
      },
      {
        id: 'ancilEditMBRData',
        value: 'Edit MBR Data',
        checked: false,
      },
      {
        id: 'ancilCanSendReferrals',
        value: 'Can Send Referrals (EVALS)',
        checked: false,
      },
    ],
    activeAccessPanel: '',
  },
  userBillReview: {
    initials: '',
    accessLevel: '',
    accessList: [],
    centerList: [],
    processingCenter: '',
    profiles: [],
  },
  userBillApproval: {
    availableClients: [],
    thresholdAmt: 0,
    draggedItem: {},
    linkClients: [],
  },
  userAdjusterMapping: {
    claimnumberList: [],
    billList: [],
    adjusterData: [],
    adjusterList: [],
    profiles: [],
    profileAdjusterList: [],
    adjusterDelete: [],
    billDelete: [],
    claimDelete: [],
  },
  userClients: {
    availableClients: [],
    linkedClients: [],
  },
  userComments: {
    comments: '',
  },
};

const adjusterReducer = (state = adjusterDefaultState, action) => {
  switch (action.type) {
    case CANCEL_NEW_USER:
      return { ...adjusterDefaultState };
    case SAVE_ADMIN_USER_FORM:
      return { ...adjusterDefaultState };
    case CHANGE_PROFILE:
      return { ...state, profileId: action.payload.profileId };
    case OPEN_MODAL:
      return { ...state, modal: !state.modal };
    default:
      return { ...state };
  }
};

const searchDefaultState = {
  data: [],
};

const searchReducer = (state = searchDefaultState, action) => {
  switch (action.type) {
    case UPDATE_USER_TABLE:
      return {
        ...state,
        pagination: {
          totalItems: action.payload.length,
        },
        data: action.payload,
      };
    default:
      return state;
  }
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CANCEL_NEW_USER:
      return {
        ...defaultState,
      };

    case SAVE_ADMIN_USER_FORM:
      return { ...defaultState };

    case UPDATE_USER_INPUT:
      return {
        ...state,
        [action.payload.objectName]: {
          ...state[action.payload.objectName],
          [action.payload.targetName]: action.payload.value,
        },
      };
    case UPDATE_USER_CLIENTS:
      return {
        ...state,
        userClients: {
          availableClients: action.payload.availableClients,
          linkedClients: action.payload.linkedClients,
        },
      };

    case UPDATE_DATA_TABLE:
      const module = action.module;
      const arrayName = action.arrayName;

      const data = state[module][arrayName].map(item => {
        if (item.id.toString() === action.id.toString()) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        [module]: {
          ...state[module],
          [arrayName]: data,
        },
      };

    case UPDATE_DEFAULT_CHECKBOX:
      const checkboxModule = action.module;
      const checkboxArrayName = action.arrayName;

      const checkboxData = state[checkboxModule][checkboxArrayName].map(
        item => {
          if (item.id === action.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return {
            ...item,
            default: false,
          };
        }
      );

      return {
        ...state,
        [checkboxModule]: {
          ...state[checkboxModule],
          [checkboxArrayName]: checkboxData,
        },
      };

    case UPDATE_ALL_CHECKBOXES:
      const allCheckboxData = state[action.module][action.arrayName].map(
        item => {
          return {
            ...item,
            ...action.payload,
          };
        }
      );

      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          [action.arrayName]: allCheckboxData,
        },
      };

    case UPDATE_USER_TABLE:
      return {
        ...state,
        userLandingPage: {
          ...state.userLandingPage,
          data: action.payload,
        },
      };

    case LOAD_USER_INFORMATION:
      return {
        ...state,
        ...action.payload,
      };

    case LOAD_ADJUSTER_MAPING_INFORMATION:
      return {
        ...state,
        userAdjusterMapping: {
          ...state.userAdjusterMapping,
          ...action.payload,
        },
      };
    case UPDATE_LOCATION_LIST:
        return {
          ...state,
          userLocationList: action.payload,
          
        };
  
    case LOAD_ADJUSTER_LIST:
      return {
        ...state,
        userAdjusterMapping: {
          ...state.userAdjusterMapping,
          profileAdjusterList: action.payload,
        },
      };

    case ADD_ADJUSTER:
      const duplicate = state.userAdjusterMapping.adjusterList.filter(
        adjuster => adjuster.id === action.payload.id
      );

      if (duplicate.length > 0) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        userAdjusterMapping: {
          ...state.userAdjusterMapping,
          adjusterList: [
            ...state.userAdjusterMapping.adjusterList,
            action.payload,
          ],
        },
      };

    case SET_ACTIVE_ANCIL_ACCESS_PANEL:
      return {
        ...state,
        userAncilAccess: {
          ...state.userAncilAccess,
          ...action.payload,
        },
      };

    case UPDATE_ANCIL_ACCESS:
      const dataTable = state.userAncilAccess.dataTable.map(item => {
        if (item.id === action.dataTableId) {
          return {
            ...item,
            access: item.access.map(nestedItem => {
              if (nestedItem.id === action.id) {
                return {
                  ...nestedItem,
                  checked: action.payload,
                };
              }
              return nestedItem;
            }),
          };
        }
        return item;
      });

      return {
        ...state,
        userAncilAccess: {
          ...state.userAncilAccess,
          dataTable: dataTable,
        },
      };

    case UPDATE_CLIENT_LISTS:
      return {
        ...state,
        userBillApproval: {
          ...state.userBillApproval,
          availableClients: action.payload.availableClients,
          linkClients: action.payload.linkClients,userReducer
        },
      };

    case APPLY_THRESHOLD_TO_SELECTED:
      return {
        ...state,
        userBillApproval: {
          ...state.userBillApproval,
          linkClients: action.payload,
        },
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  adjuster: adjusterReducer,
  user: userReducer,
  search: searchReducer,
});

export default reducer;
