import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
} from 'reactstrap';

import { billOperationsSelectors } from '../../bill-operations/store';

import { billMaintenanceSelectors } from '../store';

import SiteSettings from './client-maintenance/site-settings/SiteSettings';
import ClientRatesContainer from './client-maintenance/client-rates/ClientRatesContainer';
import UAndCSetupContainer from './client-maintenance/u-and-c-setup/UAndCSetupContainer';
import PPOSetupContainer from './client-maintenance/ppo-setup/PPOSetupContainer';

import CopySettingsModal from './copy-settings-modal/CopySettingsModal';

import SiteMaintenanceForm from './SiteMaintenanceForm';
import { billMaintenanceOperations } from '../store/index';
import { authSelectors } from 'modules/auth/store/index';

import SiteTreeModal from './client-maintenance/SiteTreeModal';

const SITE = 'SITE';
const CLIENT_RATES = 'CLIENT_RATES';
const U_AND_C_SETUP = 'U_AND_C_SETUP';
const PPO_SETUP = 'PPO_SETUP';
const SITE_SETTINGS = 'SITE_SETTINGS';

const NAME = 'clientMaintenance';

class ClientMaintenanceRight extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: SITE,
      activeSave: false,
      openSiteTreeModal: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onSave = () => {
    this.setState({
      activeSave: true,
    });
  };

  siteSaveComp = item => {
    // console.log("VALUES"+ JSON.stringify(item));
    const { profile, zebraUserId, siteDetails } = this.props;
    const response = siteDetails;

    response.notes = item.notes;
    response.siteNameList[1] = item.masterClient;
    response.name = item.name;
    response.address.fields.address1 = item.address1;
    response.address.fields.address2 = item.address2;
    response.carrierType = item.carrierType;
    // item.city;
    response.taxId = item.clientTin;
    response.contact.fields.contact = item.contact;
    response.dateRange.effDate = `${moment(item.effDate).format('MM/DD/YYYY')}`;
    response.eorText1 = item.eorText;
    response.contact.fields.fax = `${item.fax.replaceAll('-', '')}`;
    response.eorProfile = item.logo;
    response.naic = item.naic;
    response.payerAddress.fields.address1 = item.payerAddress1;
    response.payerAddress.fields.address2 = item.payerAddress2;
    // item.payerCity;
    response.payerPerson.fields.last = item.payerName;
    // item.payerState;
    response.payerTaxId = item.payerTin;
    response.payorCode = item.payerTinExt;
    response.payerAddress.fields.zipCode.fields.zip = item.payerZip;
    response.contact.fields.phone1 = `${item.phone.replaceAll('-', '')}`;
    response.serviceCompanyCode = item.svcCo;
    response.dateRange.termDate = `${moment(item.termDate).format(
      'MM/DD/YYYY'
    )}`;
    response.address.fields.zipCode.fields.zip = item.zip;

    response.settings.fields.options = R.map(
      item => parseInt(item.key),
      this.state.choiceData
    );
    response.rateList = R.map(
      item =>
        R.evolve(
          {
            fields: {
              dateRange: {
                effDate: d => `${moment(d).format('MM/DD/YYYY')}`,
                termDate: d => `${moment(d).format('MM/DD/YYYY')}`,
              },
            },
          },
          item
        ),
      this.props.clientRates
    );
    response.ppoFsSettingHelper.fields.fsSettingList = R.map(
      item =>
        R.evolve(
          {
            fields: {
              dateRange: {
                effDate: d => `${moment(d).format('MM/DD/YYYY')}`,
                termDate: d => `${moment(d).format('MM/DD/YYYY')}`,
              },
            },
          },
          item
        ),
      this.props.uAndCData
    );
    response.ppoFsSettingHelper.fields.ppoSettingList = R.map(
      item =>
        R.evolve(
          {
            fields: {
              dateRange: {
                effDate: d => `${moment(d).format('MM/DD/YYYY')}`,
                termDate: d => `${moment(d).format('MM/DD/YYYY')}`,
              },
            },
          },
          item
        ),
      this.props.ppoSetupData
    );

    this.props.saveSiteDetails({
      profile,
      zebraUserId,
      response,
    });
    this.setState({
      activeSave: false,
    });
  };

  choiceData = data => {
    this.setState({ choiceData: data });
  };

  siteTreeData = data => {
    this.setState({ siteTreeData: data });
  };

  openSiteTree = () => {
    this.setState({openSiteTreeModal: !this.state.openSiteTreeModal });
  };


  render() {
    var choiceData = this.choiceData;
    var siteTreeData = this.siteTreeData;
    return (
      <>
        <Row id="ClientMaintenanceRightHeader">
          <Col lg="7">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === SITE,
                  })}
                  onClick={() => {
                    this.toggle(SITE);
                  }}
                >
                  Site
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === CLIENT_RATES,
                  })}
                  onClick={() => {
                    this.toggle(CLIENT_RATES);
                  }}
                >
                  Client Rates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === U_AND_C_SETUP,
                  })}
                  onClick={() => {
                    this.toggle(U_AND_C_SETUP);
                  }}
                >
                  U And C Setup
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === PPO_SETUP,
                  })}
                  onClick={() => {
                    this.toggle(PPO_SETUP);
                  }}
                >
                  PPO Setup
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === SITE_SETTINGS,
                  })}
                  onClick={() => {
                    this.toggle(SITE_SETTINGS);
                  }}
                >
                  Site Settings
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col lg="5">
            <Row>
              <Col lg={{ size: '4' }}>
                <Button type="button" style={{ width: '100%' }} onClick={this.openSiteTree}>
                  SHOW TREE
                </Button>
              </Col>
              <Col lg="5">
                <CopySettingsModal
                  disabled={this.props.siteDetails.parentId === 0}
                  buttonLabel="COPY SETTINGS"
                />
              </Col>
              <Col lg="3">
                <Button
                  type="button"
                  style={{ width: '100%' }}
                  disabled={1===1}
                  //onClick={this.onSave}
                >
                  SAVE
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <SiteTreeModal
          isOpen={this.state.openSiteTreeModal}
          onClose={() => this.setState({openSiteTreeModal: false})}
          profile={this.props.profile}
          siteId={this.props.siteDetails.id}
          //treeData={siteTreeData}
        />
        <TabContent
          activeTab={this.state.activeTab}
          style={{ paddingTop: '10px' }}
        >
          <TabPane tabId={SITE}>
            <SiteMaintenanceForm
              activeVal={this.state.activeSave}
              siteSaveComp={this.siteSaveComp}
            />
          </TabPane>
          <TabPane tabId={CLIENT_RATES}>
            <ClientRatesContainer
              name={NAME}
              data={this.props.clientRates}
              editColumns={{
                lineOfBusiness: false,
                rateType: false,
                network: false,
                billType: false,
                state: false,
                type: false,
                effective: false,
                terminated: true,
              }}
            />
          </TabPane>
          <TabPane tabId={U_AND_C_SETUP}>
            <UAndCSetupContainer
              name={NAME}
              data={this.props.uAndCData}
              editColumns={{
                name: false,
                lob: false,
                amount: false,
                state: false,
                effDate: false,
                termDate: true,
              }}
            />
          </TabPane>
          <TabPane tabId={PPO_SETUP}>
            <PPOSetupContainer
              name={NAME}
              data={this.props.ppoSetupData}
              editColumns={{
                name: false,
                lob: false,
                ppoId: false,
                state: false,
                ppoRank: true,
                effDate: false,
                termDate: true,
              }}
            />
          </TabPane>
          <TabPane tabId={SITE_SETTINGS}>
            <SiteSettings clientMaintenanceChoiceData={choiceData} />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

ClientMaintenanceRight.propTypes = {
  siteDetails: PropTypes.object.isRequired,
  uAndCData: PropTypes.array.isRequired,
  clientRates: PropTypes.array.isRequired,
  ppoSetupData: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  zebraUserId: PropTypes.number.isRequired,
};
const mapStateToProps = state => {
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientRatesState = billMaintenanceSelectors.getClientMaintenanceClientRates(
    state
  );
  const uAndCSetupState = billMaintenanceSelectors.getClientMaintenanceUAndCSetupData(
    state
  );
  const ppoSetupData = billMaintenanceSelectors.getClientMaintenancePPOSetupData(
    state
  );
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);

  return {
    siteDetails: clientMaintenanceState.siteDetails,
    clientRates: clientRatesState.data,
    uAndCData: uAndCSetupState,
    ppoSetupData: ppoSetupData,
    profile: initialData.currentProfile,
    zebraUserId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  saveSiteDetails: data =>
    dispatch(billMaintenanceOperations.saveSiteDetails(data)),
  loadSiteTree: data =>
    dispatch(billMaintenanceOperations.loadSiteTree(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientMaintenanceRight);
