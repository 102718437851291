import PropTypes from 'prop-types';
import React from 'react';
import { Button, Row, FormGroup, Col, Input } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';

const NewExpertSimpleModal = props => {
  const getBody = () => (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Input
              name="simpleModalFirstName"
              value={props.firstName}
              onChange={props.onInputChangeHandler}
              placeholder="First Name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Input
              name="simpleModalLastName"
              value={props.lastName}
              onChange={props.onInputChangeHandler}
              onKeyPress={props.onInputKeyPressHandler}
              placeholder="Last Name *"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <span>Please enter the name of the new expert you wish to add.</span>
        </Col>
      </Row>
    </>
  );

  const getFooter = () => (
    <>
      <Button type="button" color="danger" onClick={props.modalToggle}>
        Cancel
      </Button>
      <Button
        disabled={props.lastName === ''}
        type="button"
        color="primary"
        onClick={props.onNextClickHandler}
      >
        Next
      </Button>
    </>
  );

  return (
    <>
      <PopupModal
        content={getBody()}
        title="Add a new Expert"
        externalToggle={props.modalToggle}
        isOpen={props.modalOpen}
        footerContent={getFooter()}
        onClosed={props.resetModal}
        size="lg"
      />
    </>
  );
};

NewExpertSimpleModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  modalToggle: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
  onNextClickHandler: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onInputKeyPressHandler: PropTypes.func.isRequired,
};

export default NewExpertSimpleModal;
