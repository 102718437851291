import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { Badge, Button, CustomInput, Row, Col } from 'reactstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { commonOperations } from 'common/store';

import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

import * as DateTimeUtils from 'shared/utils/dateTimeUtils';
import * as FetchUtils from 'shared/utils/fetchUtils';

import '../assets/bill-calendar.scss';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { authSelectors } from 'modules/auth/store';
import { columnName } from '../utils/constantUtils';

import BillSearchFooter from './BillSearchFooter';
import BillSearchHeader, { MONTH_VIEW } from './BillSearchHeader';
import NoteLinkAction from './NoteLinkAction';
import BillClaimNotesAction from './BillClaimNotesAction';
import RereviewModal from './RereviewModal';
import StateDocumentsModal from './StateDocumentsModal';
import StatusDocumentModal from './StatusDocumentModal';
import URDecision from './URDecision';
import EventDetailTooltip from './yearly-calendar/EventDetailTooltip';
import YearlyCalendar from './yearly-calendar/YearlyCalendar';
import { getProfileForPhp } from '../utils/billUtils';
import MoreInfoModal from './MoreInfoModal';

const localizer = momentLocalizer(moment);
const CALENDAR_VIEWS = ['month', 'day'];

class BillSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateDocSelect: '',
      showBillTable: true,
      stateDocModalOpen: false,
      statusHistoryModal: false,
      showURDecision: false,
      selectedRows: [],
      showPendableBillsOnly: false,
      showCalendar: false,
      calendarView: MONTH_VIEW,
      date: new Date(),
      activeBillId: '',
      monthCalendarView: CALENDAR_VIEWS[0],
      viewingEvent: {},
      cursorPosition: {
        clientY: 0,
        clientX: 0,
      },
      rereviewModalOpen: false,
      rereviewNoteValue: '',
      confirmModalOpen: false,
      demandModalOpen: false,
      selectedRowObj: {},
      showMoreInfoModal: false,
      reloadSearch: false,
    };
    this.getColumnDefinition = this.getColumnDefinition.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedBills !== prevProps.selectedBills) {
      const { selectedBills } = this.props;
      this.setState({
        selectedRows: selectedBills,
      });
    }

    let bills = this.props.searchResults;

    if (bills && bills.length === 1 && prevProps.searchResults !== bills) {
      this.onRowClick(bills[0]);
    }

    if (!this.state.showCalendar) {
      const allBillRows = document
        .getElementById('bill-table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr');
      let activeRow;
      for (let i = 0; i < allBillRows.length; i++) {
        activeRow = allBillRows[i];
        let scopeRow = activeRow.querySelectorAll('[scope="row"]');
        if (
          scopeRow.length > 0 &&
          scopeRow[0].innerText === this.state.activeBillId
        ) {
          activeRow.className = 'table-warning';
        }
      }
    }

    if (this.props.statusListData !== prevProps.statusListData && this.state.reloadSearch) {
      const { searchFields, claimType } = this.props;
      this.props.findBills({ ...searchFields, claimType });
      this.setState({ reloadSearch: !this.state.reloadSearch });
    }
  }

  onStatusHistoryClick = params => {
    const { userId, profile } = this.props;
    const billId = params.int_billid;
    const requestParams = {
      billId: billId,
      userId: userId,
      profile: profile,
    };
    this.props.getStatusHistoryData(requestParams);
    this.onRowClick(params);
    this.setState({ selectedRowObj: params });
  };

  onClickEvent = (event, e) => {
    this.setState({ showCalendar: false, activeBillId: event.id });
  };

  onClickDateInYearView = date =>
    this.setState({
      date: date,
      calendarView: MONTH_VIEW,
      monthCalendarView: CALENDAR_VIEWS[1],
    });

  onNavigateDate = (date, view, action) => this.setState({ date: date });

  onViewCalendar = view => this.setState({ monthCalendarView: view });

  buildEventDetailTooltip = () =>
    this.state.viewingEvent.hasOwnProperty('id') ? (
      <EventDetailTooltip cursorPosition={this.state.cursorPosition}>
        <div>{this.state.viewingEvent.title}</div>
        <div>{this.state.viewingEvent.start.toLocaleString()}</div>
        <div>{this.state.viewingEvent.end.toLocaleString()}</div>
        <div>{this.state.viewingEvent.treating}</div>
        <div>Billed: {this.state.viewingEvent.billed}</div>
      </EventDetailTooltip>
    ) : (
        ''
      );

  buildEvent = slot => {
    const event = slot.event;
    const slotId = `slot-${event.id}`;
    return (
      <div
        id={slotId}
        style={{ backgroundColor: '#' + event.color }}
        className="event-detail-wrapper"
        onMouseOver={e => this.onHoverEvent(e, event)}
        onMouseOut={this.onMouseOut}
      >
        <p>
          {event.title} - {event.id}
        </p>
      </div>
    );
  };

  onHoverEvent = (e, event) => {
    this.setState({
      viewingEvent: event,
      cursorPosition: {
        clientY: e.clientY,
        clientX: e.clientX,
      },
    });
  };

  onMouseOut = () => this.setState({ viewingEvent: {} });

  getBillId = searchResult => {
    let recon;
    let adjusterlink;
    let claimCheck;

    recon = '';
    if (searchResult.adjustedby != '') {
      recon = (
        <AppTooltip text={'Bill is a Reconsideration'} id={'billnts'}>
          <FontAwesomeIcon
            className="blue-text cursor-hover"
            icon={faExclamation}
          />
        </AppTooltip>
      );
    }

    if (searchResult.ebill == 1 || searchResult.worklistAdjuster != '') {
      var tooltp;
      tooltp = '';
      if (searchResult.ebill == 1) {
        tooltp = 'Electronic Bill';
      }
      adjusterlink = (
        <AppTooltip text={tooltp} id={'adjsTooltip'}>
          <FontAwesomeIcon
            className="blue-text cursor-hover"
            icon={faCheckCircle}
          />
        </AppTooltip>
      );
    }

    if (searchResult.isCCDLSPI) {
      claimCheck = (
        <AppTooltip text={'Claim is either closed, controverted, denied, litigated, settled, pending or under investigation'} id={'claimCheck-' + searchResult.id}>
          <FontAwesomeIcon
            className="cursor-hover"
            style={{ marginLeft: 5, color: 'red' }}
            icon={faTimesCircle}
          />
        </AppTooltip>
      );
    }

    return (
      <>
        {recon} {searchResult.int_billid || ''} {claimCheck} <br /> {adjusterlink}
        <i> {searchResult.worklistAdjuster || ''} </i>
      </>
    );
  };

  getClient = searchResult => {
    return <>{searchResult.chr_sitename || ''}</>;
  };

  getProcessor = searchResult => {
    return (
      <>
        {searchResult.chr_userinitials || ''} <br />
        <i>
          <font color="#666666">{searchResult.adjustedby || ''}</font>
        </i>
      </>
    );
  };

  getProcessingPrinted = searchResult => {
    let print = '';
    if (!!searchResult.dat_printdate) {
      print = (
        <>
          <br />
          <span className="grey-text italic-text">
            Print: {searchResult.dat_printdate}
          </span>
        </>
      );
    }
    return (
      <>
        {searchResult.dat_processingreceiveddate}
        {print}
      </>
    );
  };

  getCarrierReceived = searchResult => {
    const carrierReceived = new Date(searchResult.dat_carrierreceiveddate);
    let printed = new Date();
    if (searchResult.dat_printdate !== '') {
      printed = new Date(searchResult.dat_printdate);
    }

    const turnAroundTime = DateTimeUtils.elapsedDays(printed, carrierReceived);
    let daysdiff = '';

    if (!isNaN(turnAroundTime)) {
      if (turnAroundTime === 0) {
        daysdiff = 'Today';
      } else {
        if (turnAroundTime === 1) {
          daysdiff = 'yesterday';
        } else {
          daysdiff = turnAroundTime + ' days ';
        }
      }
    }

    return (
      <>
        {searchResult.dat_carrierreceiveddate}
        <br />
        <span className="grey-text italic-text">TAT: {daysdiff}</span>
      </>
    );
  };

  getDatesOfService = searchResult => {
    let html = '';
    if (searchResult.sdod === searchResult.edos) {
      html = searchResult.sdod;
    } else {
      html = (
        <>
          Start: {searchResult.sdod}
          <br />
          End: {searchResult.edos}
        </>
      );
    }

    return html;
  };

  getJuris = searchResult => {
    let html = '';
    if (searchResult.chr_juris == 'PI') {
      html = <>PIP</>;
    } else {
      html = <>{searchResult.chr_juris}</>;
    }

    return html;
  };

  getTreatingProvider = searchResult => {
    let tin = 'None';
    if (searchResult.chr_treatingtin && searchResult.chr_treatingtin !== '') {
      tin =
        searchResult.chr_treatingtin.substr(0, 2) +
        '-' +
        searchResult.chr_treatingtin.substr(2);
    }

    return (
      <>
        {searchResult.treating}
        <br />
        <span className="grey-text">TIN: {tin}</span>
      </>
    );
  };

  getBillingProvider = searchResult => {
    let tin = 'None';
    if (searchResult.chr_tin && searchResult.chr_tin !== '') {
      tin =
        searchResult.chr_tin.substr(0, 2) +
        '-' +
        searchResult.chr_tin.substr(2);
    }
    return (
      <>
        {searchResult.billing}
        <br />
        <span className="grey-text">TIN: {tin}</span>
      </>
    );
  };

  getTreatmentType = searchResult => {
    return (
      <>
        <AppTooltip
          text={
            <>
              <span>Total Charges: {searchResult.totalBilled}</span>
              <br />
              <span>Total Reductions: {searchResult.totalReductions}</span>
              <br />
              <span>Total Allowed: {searchResult.totalAllowed}</span>
            </>
          }
          id={`treatmentType-toolTip-${searchResult.int_billid}`}
          placement="bottom"
        >
          {searchResult.chr_typedescription}
          <br />
          <span className="grey-text italic-text">
            Billed Charges: {searchResult.totalBilled}
          </span>
        </AppTooltip>
      </>
    );
  };

  getState = searchResult => {
    return <>{searchResult.chr_state}</>;
  };

  getStatus = searchResult => {
    let approvalStatus = searchResult.approvalstatus || '';
    let color = '';

    switch (approvalStatus.toUpperCase()) {
      case 'A':
        approvalStatus = 'A';
        color = '#006633'; // Green
        break;
      case 'D':
        approvalStatus = 'D';
        color = '#CC0000'; // Red
        break;
      case 'P':
        approvalStatus = 'P';
        color = '#FF9900'; // Orange
        break;
      default:
        color = '#3c4659'; // Black
    }

    return (
      <>
        <AppTooltip
          text={'Click to View/Edit Status'}
          id={`status-toolTip-${searchResult.int_billid}`}
          placement="bottom"
        >
          <Button
            color="link"
            onClick={() => this.openStatusHistoryModal(searchResult)}
            style={{ color: '#0000FF' }}
          >
            {searchResult.status}
          </Button>{' '}
        </AppTooltip>
        <AppTooltip
          text={'Bill Approval Status Code'}
          id={`status-code-${searchResult.int_billid}`}
          placement="bottom"
        >
          <font color={color}>
            <b>{approvalStatus}</b>
          </font>
        </AppTooltip>
        {searchResult.sentToCarelink ? ( 
          <AppTooltip
            text={'Sent to Carelink'}
            id={`status-code-carelink-${searchResult.int_billid}`}
            placement="bottom"
          >
            <font color='FFA500'>
              <b>CL</b>
            </font>
          </AppTooltip>
        ) : null }
        <br />
        <span className="grey-text">{searchResult.checkDate}</span>
        <AppTooltip
          text={'Charge Master Code'}
          id={`master-code-${searchResult.int_billid}`}
          placement="bottom"
        >
          <font color='green'>
            <b>{(searchResult?.hasMiCmaster === true && searchResult?.chr_juris === 'PIP') ? 'C' : ''}</b>
          </font>
        </AppTooltip>
      </>
    );
  };

  getDocuments = searchResult => {
    return (
      <>
        <AppTooltip
          text={'Show the bills EOR'}
          id={`document-eor-toolTip-${searchResult.int_billid}`}
          placement="bottom"
        >
          <Badge
            color="primary"
            className="badge-document-icon"
            onClick={() => this.printEor(searchResult)}
          >
            EOR
          </Badge>
        </AppTooltip>{' '}
        {!!searchResult.location ? (
          <AppTooltip
            text={'Show any attached Medicals'}
            id={`document-med-toolTip-${searchResult.int_billid}`}
            placement="bottom"
          >
            <Badge
              color="success"
              className="badge-document-icon"
              onClick={() => this.printMed(searchResult)}
            >
              MED
            </Badge>
          </AppTooltip>
        ) : null}
      </>
    );
  };

  getNotes = searchResult => {
    return (
      <>
        {/* <NoteLinkAction
          recordId={searchResult.int_billid}
          iconOnly={true}
          notes={searchResult.internalnote}
          eorNotes={searchResult.notes}
          accountingNotes={searchResult.accountingNotes}
        /> */}
        <BillClaimNotesAction
          recordId={searchResult.int_billid}
          iconOnly={true}
          notes={searchResult.internalnote}
          eorNotes={searchResult.notes}
          accountingNotes={searchResult.accountingNotes}
        />
      </>
    );
  };

  getSelectActionColumn(item) {
    if (!!!item) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          id={`billSearchResult-select-${item.int_billid}`}
          name={`billSearchResult-select-${item.int_billid}`}
          type="checkbox"
          checked={this.state.selectedRows.includes(item)}
          onChange={event => {
            this.selectItem(item, event);
          }}
        />
      </div>
    );
  }

  onRowClick = data => {
    const { currentUserId, profile } = this.props;
    if (!this.props.claimControlHandler) {
      const requestBody = {
        claimNumber: '',
        lastNameOrSsn: '',
        billId: data.int_billid,
        tin: '',
        firstName: '',
        client: '',
        state: '',
        siteId: '',
        billtypes: '',
        userId: currentUserId,
        profile: profile,
      };

      this.props.getClaim(requestBody);
    }
  };

  agreeHandler = () => {
    this.stateConfirmModalToggle();
  };

  pendHandler = () => {
    const { currentUserId, profile, searchFields } = this.props;
    const billList = this.getSelectedBills();

    if (billList === '') {
      return;
    }

    this.props.pendBills(
      {
        billIds: billList,
        currentUserId: currentUserId,
        profile: profile,
      },
      searchFields,
      !!this.props.claimControlHandler
    );
  };

  reReviewHandler = () => {
    this.setState(prevState => ({
      rereviewModalOpen: !prevState.rereviewModalOpen,
    }));
  };

  showPendOnlyHandler = event => {
    this.setState({
      showPendableBillsOnly: event.target.checked,
    });
  };

  enableButtons = () => {
    return this.state.selectedRows.length > 0;
  };

  isReReview = () => {
    var isReReview = false;
    this.state.selectedRows.forEach(obj => {
      isReReview = isReReview || (obj.reReview == "1");
    })
    return isReReview;
  }

  isNotDocumented = () => {
    var isNotDocumented = false;
    this.state.selectedRows.forEach(obj => {
      isNotDocumented = isNotDocumented || (obj.chr_typedescription == "Not Documented" && (obj.canReReview === false || obj.canReReview === undefined));
    })
    return isNotDocumented;
  }

  onCombinedDocs = async event => {
    let mode = '';

    switch (window.combinedDocs.value) {
      case 'EORs':
        mode = 'eors';
        break;
      case 'medicals':
        mode = 'medicals';
        break;
      case 'EORsAndMedicals':
        mode = 'eors_medicals';
        break;
      //removed until 14.9
      case 'billOnly':
        mode = 'bill_only';
        break;
      case 'notesOnly':
        mode = 'notes_only';
        break;
      default:
        mode = '';
    }
    // window.alert(mode);

    const billList = this.getSelectedBillsProper();
    if (billList === '') {
      this.props.alert(
        'You must select at least one bill to generate a document.'
      );
      return;
    }
    var billId;
    if (billList.indexOf(',') > 0) {
      billId = billList.substring(0, billList.indexOf(','));
    } else {
      billId = billList;
    }
    const currentProfile = getProfileForPhp(this.props.profile);

    const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);

    const params = new URLSearchParams();

    params.append('injuryid', billId);
    params.append('profile', currentProfile);
    params.append('mode', mode);
    params.append('printflags', 0);
    params.append('script', 'Y');
    // if (billList.indexOf(',') > 0) {
    params.append('billlist', billList);
    //params.append('injury', billId);
    //}

    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `Combined-Docs${new Date().getTime()}`
    );
  };

  printEor = async searchResult => {
    let mode = '';

    // window.alert(mode);
    const currentProfile = getProfileForPhp(this.props.profile);

    const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);

    const params = new URLSearchParams();

    params.append('pdf', searchResult.int_billid);
    params.append('profile', currentProfile);
    params.append('printflags', 0);
    params.append('script', 'Y');

    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `EorDocs${new Date().getTime()}`
    );
  };

  printMed = async searchResult => {
    let mode = '';

    // window.alert(mode);
    const currentProfile = getProfileForPhp(this.props.profile);

    const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);

    const params = new URLSearchParams();
    params.append('injuryid', currentProfile + searchResult.int_billid);
    params.append('profile', currentProfile);
    params.append('script', 'Y');
    params.append('mode', 'medicals');
    params.append('printflags', 0);
    params.append('billlist', searchResult.int_billid);

    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `MedDocs${new Date().getTime()}`
    );
  };

  changeStateReviewDocs = event => {
    const value = event.target.id;
    this.setStateDocID(value);
    this.stateDocumentsToggle();
  };

  setStateDocID = stateAbbreviation => {
    const stateId = this.props.stateList
      .filter(state => state.abbreviation === stateAbbreviation)
      .map(state => {
        return state.id;
      });

    this.setState({
      stateDocSelect: stateAbbreviation,
      stateId: stateId[0],
    });
  };

  stateDocumentsToggle = () => {
    this.setState(prevState => ({
      stateDocModalOpen: !prevState.stateDocModalOpen,
    }));
  };

  openStatusHistoryModal = params => {
    this.onStatusHistoryClick(params);
    this.onStatusHistoryHandle();
  };

  onStatusHistoryHandle = () => {
    if (this.state.statusHistoryModal) {
      this.props.resetStatusHistoryData();
      //const { searchFields, claimType } = this.props;
      //this.props.findBills({ ...searchFields, claimType });
      this.setState({ reloadSearch: true });
    }
    this.setState({ statusHistoryModal: !this.state.statusHistoryModal });
  };



  toggleURDecision = () => {
    this.setState({
      showURDecision: !this.state.showURDecision,
    });

    if (!!this.props.toggleSearch) {
      this.props.toggleSearch();
    }
  };
  toggleCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  };

  exitURDecision = () => {
    this.toggleURDecision();
    this.props.clearURDecisionData();
  };
  onClickEvent = (event, e) => {
    this.setState({ showCalendar: false, activeBillId: event.id });
  };

  onClickDateInYearView = date =>
    this.setState({
      date: date,
      calendarView: MONTH_VIEW,
      monthCalendarView: CALENDAR_VIEWS[1],
    });

  onNavigateDate = (date, view, action) => this.setState({ date: date });

  onViewCalendar = view => this.setState({ monthCalendarView: view });

  buildEventDetailTooltip = () =>
    this.state.viewingEvent.hasOwnProperty('id') ? (
      <EventDetailTooltip cursorPosition={this.state.cursorPosition}>
        <div>{this.state.viewingEvent.title}</div>
        <div>{this.state.viewingEvent.start.toLocaleString()}</div>
        <div>{this.state.viewingEvent.end.toLocaleString()}</div>
        <div>{this.state.viewingEvent.treating}</div>
        <div>Billed: {this.state.viewingEvent.billed}</div>
      </EventDetailTooltip>
    ) : (
        ''
      );

  buildEvent = slot => {
    const event = slot.event;
    const slotId = `slot-${event.id}`;
    return (
      <div
        id={slotId}
        style={{ backgroundColor: '#' + event.color }}
        className="event-detail-wrapper"
        onMouseOver={e => this.onHoverEvent(e, event)}
        onMouseOut={this.onMouseOut}
      >
        <p>
          {event.title} - {event.id}
        </p>
      </div>
    );
  };

  onHoverEvent = (e, event) => {
    this.setState({
      viewingEvent: event,
      cursorPosition: {
        clientY: e.clientY,
        clientX: e.clientX,
      },
    });
  };

  onMouseOut = () => this.setState({ viewingEvent: {} });

  buildBillsView = (billData, events) => {
    const columns = this.getColumnDefinition();
    const tableKey = columns.map(column => column.field).join('-');

    return (
      <>
        <div
          className="bill-calendar-view"
          style={this.state.showCalendar ? {} : { display: 'none' }}
        >
          {this.state.calendarView === MONTH_VIEW ? (
            <Calendar
              localizer={localizer}
              views={CALENDAR_VIEWS}
              events={events}
              view={this.state.monthCalendarView}
              date={this.state.date.getMonth() ? this.state.date : new Date()}
              onNavigate={this.onNavigateDate}
              onView={this.onViewCalendar}
              tooltipAccessor=""
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={this.onClickEvent}
              toolbar={false}
              culture={'en-US'}
              components={{
                event: this.buildEvent,
              }}
            />
          ) : (
              <YearlyCalendar
                year={this.state.date.getFullYear() ? this.state.date.getFullYear() : new Date().getFullYear()}
                onClickEvent={this.onClickDateInYearView}
                events={events}
              />
            )}
          {this.buildEventDetailTooltip()}
        </div>
        <div style={!this.state.showCalendar ? {} : { display: 'none' }}>
          <div id="bill-table">
            <AppTable
              modalHeight={600}
              columns={columns}
              data={billData}
              rowClickHandler={this.onRowClick}
              autoPagination={this.props.envRef!==undefined && this.props.envRef?false:true}
              sortAble={true}
              resizable={true}
              key={`findBillsTable-${tableKey}`}
            />
          </div>
          <BillSearchFooter
            billsFound={this.props.searchResults.length}
            agreeButtonHandler={this.agreeHandler}
            pendButtonHandler={this.pendHandler}
            reReviewButtonHandler={this.reReviewHandler}
            demandButtonHandler={this.demandHandler}
            showPendOnlyHandler={this.showPendOnlyHandler}
            claimControlHandler={!!this.props.claimControlHandler}
            enableButtons={this.enableButtons() && !this.isReReview() && !this.isNotDocumented()}
            bills={this.props.searchResults}
            claimData={this.props.claimData}
          />
        </div>
      </>
    );
  };

  onDateChange = value => {
    this.setState({
      date: new Date(value.year, value.month),
    });
  };

  changeView = view =>
    this.setState({
      calendarView: view,
      monthCalendarView: CALENDAR_VIEWS[0],
    });

  makeBillList(checkedList, note) {
    let result = [];
    const pendedIds = [];
    for (let index = 0; index < checkedList.length; index++) {
      const element = checkedList[index];
      if (
        element.int_billid &&
        (element.status === 'Pended' || element.status === 'Open')
      ) {
        pendedIds.push(element.int_billid);
      } else if (
        element.int_billid &&
        (element.status === 'Locked' || element.status === 'Final')
      ) {
        result.push({
          id: element.int_billid,
          status: 835,
          SlpUserId: this.props.slpUserId,
          note: note,
        });
      }
    }
    if (pendedIds.length) {
      this.props.alert(
        `The following bills were not sent for Re-Review because they are not yet finalized: ${pendedIds.join(
          ', '
        )}`
      );
    }
    return result;
  }

  handleSubmitRereviewNote = () => {
    const { profile, currentUserId, searchFields, claimType } = this.props;
    const data = {
      profile: profile,
      currentUserId: currentUserId,
      billList: this.makeBillList(
        this.state.selectedRows,
        this.state.rereviewNoteValue
      ),
    };
    if (data.billList && data.billList.length > 0) {
      this.props.sendRereviewNote(data);
    }
    this.setState({
      rereviewNoteValue: '',
      rereviewModalOpen: false,
    });
    this.props.findBills({ ...searchFields, claimType });
  };

  handleChangeNoteValue = event => {
    this.setState({
      rereviewNoteValue: event.target.value,
    });
  };

  selectAll = event => {
    const billsFromTaskList = this.props.billsFromTaskList;
    const billsInClaim = this.props.billsInClaim;
    const searchResults = this.props.searchResults;
    let result = 0;
    if (
      this.props.claimType == 'billsFromClaim' &&
      (this.props.searchFields.claimNumber == '' ||
        this.props.searchFields.claimNumber == undefined ||
        this.props.searchFields.billScan == '' ||
        this.props.searchFields.end == '')
    ) {
      if (billsFromTaskList.length != 0) {
        result = event.target.checked ? billsFromTaskList.slice() : [];
      }
    }
    if (
      this.props.searchFields.claimNumber != '' &&
      this.props.searchFields.claimNumber != undefined
    ) {
      if (billsInClaim.length != 0) {
        result = event.target.checked ? billsInClaim.slice() : [];
      }
    }
    if (
      this.props.searchFields.billScan != '' &&
      this.props.searchFields.end != ''
    ) {
      if (searchResults != '') {
        result = event.target.checked ? searchResults.slice() : [];
      }
    }
    if (
      this.props.searchFields.billScan != '' &&
      this.props.searchFields.end == ''
    ) {
      if (searchResults != '') {
        result = event.target.checked ? searchResults.slice() : [];
      }
    }

    this.setState({
      selectedRows: result,
    });
    this.props.selectBills(result);
  };

  isChecked = () => {
    if (
      this.props.claimType == 'billsFromClaim' &&
      (this.props.searchFields.claimNumber == '' ||
        this.props.searchFields.claimNumber == undefined ||
        this.props.searchFields.billScan == '' ||
        this.props.searchFields.end == '') &&
      this.props.billsFromTaskList.length != 0
    ) {
      if (
        this.state.selectedRows.length == this.props.billsFromTaskList.length
      ) {
        return true;
      }
    }
    if (
      this.props.searchFields.claimNumber != '' &&
      this.props.searchFields.claimNumber != undefined &&
      this.props.billsInClaim.length != 0
    ) {
      if (this.state.selectedRows.length == this.props.billsInClaim.length) {
        return true;
      }
    }
    if (
      this.props.searchFields.billScan != '' &&
      this.props.searchFields.end != '' &&
      this.props.searchResults != ''
    ) {
      if (this.state.selectedRows.length == this.props.searchResults.length) {
        return true;
      }
    }
    if (
      this.props.searchFields.billScan != '' &&
      this.props.searchFields.end == '' &&
      this.props.searchResults != ''
    ) {
      if (this.state.selectedRows.length == this.props.searchResults.length) {
        return true;
      }
    }
    return false;
  };

  selectItem = (item, event) => {
    const result = event.target.checked
      ? this.state.selectedRows.concat(item)
      : this.state.selectedRows.filter(
        row => row.int_billid !== item.int_billid
      );
    this.setState({
      selectedRows: result,
    });
    this.props.selectBills(result);
  };

  getColumnDefinition() {
    const settingColumns = this.props.settingColumns;
    const columns = [
      {
        header: columnName.billId,
        field: 'int_billid',
        rowKey: true,
        render: item => this.getBillId(item),
        percent: '5',
      },
      {
        header: columnName.client,
        field: 'chr_sitename',
        render: item => this.getClient(item),
        percent: '5',
      },
      {
        header: columnName.processor,
        field: 'chr_userinitials',
        render: item => this.getProcessor(item),
        percent: '7',
      },
      {
        header: columnName.processingPrinted,
        field: 'dat_processingreceiveddate',
        render: item => this.getProcessingPrinted(item),
        percent: '8',
      },
      {
        header: columnName.lob,
        field: 'chr_juris',
        render: item => this.getJuris(item),
        percent: '4',
      },
      {
        header: columnName.carrierReceived,
        field: 'dat_carrierreceiveddate',
        render: item => this.getCarrierReceived(item),
        percent: '6.5',
      },
      {
        header: columnName.dateOfService,
        field: 'sdod',
        render: item => this.getDatesOfService(item),
        percent: '6',
      },
      {
        header: columnName.treatingProvider,
        field: 'treating',
        render: item => this.getTreatingProvider(item),
        percent: '6.5',
      },
      {
        header: columnName.billingProvider,
        field: 'billing',
        render: item => this.getBillingProvider(item),
        percent: '6.5',
      },
      {
        header: columnName.treatmentType,
        field: 'chr_typedescription',
        render: item => this.getTreatmentType(item),
        percent: '7',
      },
      {
        header: columnName.state,
        field: 'chr_state',
        render: item => this.getState(item),
        percent: '5',
      },
      {
        header: columnName.status,
        field: 'status',
        render: item => this.getStatus(item),
        percent: '7',
      },
      {
        header: columnName.documents,
        field: 'documents',
        render: item => this.getDocuments(item),
        percent: '9',
        sortAble: false,
      },
      {
        header: columnName.moreInfo,
        field: 'moreInfo',
        render: item => this.renderMoreInfoColumn(item),
        sortAble: false,
      },
      {
        header: columnName.notes,
        field: 'notes',
        render: item => this.getNotes(item),
        percent: '5',
        sortAble: false,
      },
      {
        header: (
          <div className="select-action">
            <CustomInput
              id={`billSearchResult-selectAll`}
              name={`billSearchResult-selectAll`}
              type="checkbox"
              checked={this.isChecked() == true ? true : false}
              onChange={this.selectAll}
            />
          </div>
        ),
        field: 'checkBox',
        render: item => this.getSelectActionColumn(item),
        percent: '3',
        sortAble: false,
      },
    ];

    return columns.filter(col => {
      const condition = R.find(
        val => val.columnName === col.header,
        settingColumns
      );
      if (condition && condition.state === false) {
        return false;
      }
      return true;
    });
  }

  getSelectedBills() {
    const sLPUser = this.props.sLPUser;
    let unlimitedBatchPend = false;
    if (sLPUser.length > 0) {
      unlimitedBatchPend = sLPUser[0].bol_unlimitedbatchpend;
    }
    let billList = '';

    const billSelected = this.props.selectedBills;

    billSelected.forEach(item => {
      if (
        unlimitedBatchPend === true ||
        (unlimitedBatchPend === false &&
          this.props.currentUserId === item.zebUserId)
      ) {
        billList += item.int_billid + ',';
      } else {
        this.props.alert(
          'You do not have permission to pend another processors bill. Please modify your selection.'
        );
        return '';
      }
    });

    if (billList !== '') {
      billList = billList.substr(0, billList.length - 1);
    }

    return billList;
  }

  getSelectedBillsProper() {
    let billList = '';

    const billSelected = this.props.selectedBills;

    billSelected.forEach(item => {
      billList += item.int_billid + ',';
    });

    if (billList !== '') {
      billList = billList.substr(0, billList.length - 1);
    }

    return billList;
  }

  stateConfirmModalToggle = () => {
    this.setState(prevState => ({
      confirmModalOpen: !prevState.confirmModalOpen,
    }));
  };

  getConfirmationModalBodyContent() {
    return (
      <Row form>
        <Col lg={{ size: '4', offset: '4' }}>
          <span>QA Selected Bills?</span>
        </Col>
      </Row>
    );
  }

  getConfirmationModalFooterContent() {
    return (
      <>
        <Button color="secondary" onClick={this.stateConfirmModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.confirmAgreeBills}>
          Yes
        </Button>
      </>
    );
  }

  confirmAgreeBills = async () => {
    this.stateConfirmModalToggle();
    const {
      selectedBills,
      currentUserId,
      profile,
      searchFields,
      claimType,
    } = this.props;
    let billList = [];
    selectedBills.forEach(item => {
      return billList.push(parseInt(item.int_billid));
    });
    const params = {
      billList: billList,
      zebraUserId: currentUserId,
      profile: profile,
    };
    await this.props.confirmQABills(params);
    this.props.findBills({ ...searchFields, claimType });
  };

  getDemandModalBodyContent() {
    return (
      <Row form>
        <Col>
          <span>
            The demand status has been added to the selected bills. Would you
            like to upload any documentation?
          </span>
        </Col>
      </Row>
    );
  }

  renderMoreInfoColumn(item) {
    return (
      <Button
        color="link"
        onClick={() => this.onMoreInfoClick(item, this.props.claimId)}
        style={{ cursor: 'pointer' }}
      >
        <span aria-hidden>
          {item.enablePaycode ? (
            <FontAwesomeIcon icon={faClipboard} style={{ display: 'block' }} />
          ) : (
            ''
          )}
        </span>
      </Button>
    );
  }

  onMoreInfoClick = async params => {
    const { currentUserId, profile, claim, claimData } = this.props;
    const requestParams = {
      zebraUserId: currentUserId,
      userId: currentUserId,
      claimId: !!claimData ? claimData.chr_claimnumber : claim.chr_claimnumber,
      billId: params.int_billid,
      profile: profile,
    };
    await this.props.loadMoreInfo(requestParams);
    this.setState({ showMoreInfoModal: true, selectedRow: params });
  };
  
  onMoreInfoModalClose = () => {
    this.setState({ showMoreInfoModal: !this.state.showMoreInfoModal });
  };

  render() {
    let events = [],
      dateArrayCollection = [];

    const { showPendableBillsOnly } = this.state;
    // Filter function used for showing/hiding printed bills
    let bills = this.props.searchResults;
    if (showPendableBillsOnly) {
      bills = bills.filter(item => {
        return item.zebraUserId === this.props.currentUserId;
      });
    }

    bills.forEach(result => {
      let timeStartHours = 8,
        timeStartMinutes = 0,
        addDate = true;
      for (let i = 0; i < dateArrayCollection.length; i++) {
        const dateTime = dateArrayCollection[i];
        if (dateTime.sdod === result.sdod) {
          if (dateTime.timeStartMinutes === 30) {
            dateTime.timeStartHours++;
            dateTime.timeStartMinutes = 0;
          } else {
            dateTime.timeStartMinutes = 30;
          }
          timeStartHours = dateTime.timeStartHours;
          timeStartMinutes = dateTime.timeStartMinutes;
          addDate = false;
        }
      }
      if (addDate) {
        let dateAdd = {};
        dateAdd.timeStartHours = 8;
        dateAdd.timeStartMinutes = 0;
        dateAdd.sdod = result.sdod;
        dateArrayCollection.push(dateAdd);
      }
      const eventStartTime = new Date(result.sdod);
      eventStartTime.setHours(timeStartHours, timeStartMinutes);
      const eventEndTime = new Date(result.edos);
      eventEndTime.setHours(timeStartHours, timeStartMinutes);
      let colorCode = result.colorCode
        ? result.colorCode
        : result.chr_typedescription === 'Outpatient'
          ? 'd1e8f8'
          : result.chr_typedescription === 'Office Visit'
            ? '009dff'
            : '999999';
      events.push({
        id: result.int_billid,
        title: result.chr_typedescription,
        start: eventStartTime,
        end: eventEndTime,
        treating: result.treating,
        billed: result.totalBilled,
        color: colorCode,
      });
    });

    const hide = {
      display: 'none',
    };
    return (
      <>
        <RereviewModal
          noteValue={this.state.rereviewNoteValue}
          modalOpen={this.state.rereviewModalOpen}
          modalToggle={this.reReviewHandler}
          submitNoteRereview={this.handleSubmitRereviewNote}
          changeNoteValue={this.handleChangeNoteValue}
        />
        <StateDocumentsModal
          documents={this.props.stateDocuments}
          externalToggle={this.stateDocumentsToggle}
          isOpen={this.state.stateDocModalOpen}
          stateDocSelect={this.state.stateDocSelect}
          setStateDocID={this.setStateDocID}
          stateList={this.props.stateList}
          stateId={this.state.stateId}
        />
        <StatusDocumentModal
          isOpen={this.state.statusHistoryModal}
          onClose={this.onStatusHistoryHandle}
          statusHistoryData={this.props.statusHistoryData}
          selectedRowObj={this.state.selectedRowObj}
          claim={
            !!this.props.claimData ? this.props.claimData : this.props.claim
          }
        />
        <MoreInfoModal
          moreInfoData={this.props.moreInfoData}
          showModal={this.state.showMoreInfoModal}
          onClose={this.onMoreInfoModalClose}
          selectedRow={this.state.selectedRow}
          claimNumber={!!this.props.claimData ? this.props.claimData.chr_claimnumber : this.props.claim.chr_claimnumber}
        />
        <div
          style={
            this.state.showBillTable && !this.state.showURDecision ? {} : hide
          }
        >
          <BillSearchHeader
            onChangeHandler={this.onCombinedDocs}
            claimControlHandler={this.props.claimControlHandler ? true : false}
            sites={this.props.sites}
            claim={
              !!this.props.claimData ? this.props.claimData : this.props.claim
            }
            urDecisionToggle={this.toggleURDecision}
            exit={!!this.props.exitHandler ? this.props.exitHandler : null}
            calendarToggle={this.toggleCalendar}
            showCalendar={this.state.showCalendar}
            date={events.length > 0 ? events[0].start : ''}
            onDateChangeHandler={this.onDateChange}
            changeViewHandler={this.changeView}
            bills={bills}
            envRef={this.props.envRef!==undefined?true:false}
            fromTaskList = {"billsFromTaskList"===this.props.claimType}
          />
          {this.buildBillsView(bills, events)}
        </div>

        <div style={this.state.showURDecision ? {} : hide}>
          <URDecision
            exitHandler={this.exitURDecision}
            claimData={this.props.claimData}
            urDecision={this.props.urDecision}
            enableButtons={this.enableButtons()}
            reReviewButtonHandler={this.reReviewHandler}
          />
        </div>

        <PopupModal
          content={this.getConfirmationModalBodyContent()}
          title="Confirmation"
          externalToggle={this.stateConfirmModalToggle}
          isOpen={this.state.confirmModalOpen}
          footerContent={this.getConfirmationModalFooterContent()}
          size="lg"
        />
        <form></form>
      </>
    );
  }
}

BillSearchResults.propTypes = {
  searchResults: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  claimControlHandler: PropTypes.func,
  stateDocuments: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  getClaim: PropTypes.func,
  claimData: PropTypes.object,
  exitHandler: PropTypes.func,
  selectedBills: PropTypes.array.isRequired,
  settingColumns: PropTypes.array.isRequired,
  selectBills: PropTypes.func.isRequired,
  statusHistoryData: PropTypes.array,
  getStatusHistoryData: PropTypes.func,
  resetStatusHistoryData: PropTypes.func,
  userId: PropTypes.string,
  profile: PropTypes.string,
  claimType: PropTypes.string,
  loadMoreInfo: PropTypes.func.isRequired,
  moreInfoData: PropTypes.array.isRequired,
  statusListData: PropTypes.array,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const findBills = billOperationsSelectors.getFindBills(state);
  const user = authSelectors.getUser(state);
  const gridSettings = billOperationsSelectors.getFindBillsGridSettingsState(
    state
  );
  const billReviewState = billOperationsSelectors.getBillReview(state);

  return {
    claim: findBills.claim,
    urDecision: findBills.urDecision,
    selectedBills: findBills.selectedBills,
    statusHistoryData: findBills.statusHistoryData,
    settingColumns: gridSettings,
    sLPUser: initialData.sLPUser,
    billsFromTaskList: findBills.billsFromTaskListClaim,
    billsInClaim: findBills.billsInClaim,
    searchFields: findBills.searchFields,
    slpUserId: initialData.slpUserId,
    userId: user && user.user_id,
    profile: initialData.currentProfile,
    moreInfoData: billReviewState.moreInfoData,
    statusListData: findBills.statusListData,
  };
};

const mapDispatchToProps = dispatch => ({
  getClaim: data => dispatch(billOperationsOperations.getClaim(data)),
  clearURDecisionData: () =>
    dispatch(billOperationsActions.updateURDecisionData([])),
  selectBills: data =>
    dispatch(billOperationsActions.updateSelectedBillsFromClaim(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  getStatusHistoryData: data =>
    dispatch(billOperationsOperations.getStatusHistoryData(data)),
  resetStatusHistoryData: () =>
    dispatch(billOperationsActions.resetStatusHistoryData()),
  pendBills: (data, searchFields, billsFromClaim) =>
    dispatch(
      billOperationsOperations.addPendCodeToBill(
        data,
        searchFields,
        billsFromClaim
      )
    ),
  sendRereviewNote: data =>
    dispatch(billOperationsOperations.sendRereviewNote(data)),
  findBills: data =>
    dispatch(billOperationsOperations.findBills(data, data.claimType)),
  confirmQABills: data =>
    dispatch(billOperationsOperations.confirmQABills(data)),
  loadMoreInfo: data => dispatch(billOperationsOperations.loadMoreInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillSearchResults);
