import * as R from 'ramda';
import React, { Component } from 'react';
import { CustomInput, Input } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import {
  sortByType,
  formatMMDDYYYY,
  isValidDate,
} from 'shared/utils/shareUtils';

import { getValueByKey } from '../../../utils/billMaintenanceUtil';

class UAndCSetupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRows: [],
      editColumns: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const { data } = this.props;
      if (data) {
        this.setState({
          data: data,
        });
      }
    }
  }

  updateEditColumnsState = (id, value) => {
    this.setState({
      editColumns: {
        ...this.state.editColumns,
        [id]: value,
      },
    });
  };

  onChangeHandler = (targetId, value) => {
    this.updateEditColumnsState(targetId, value);
  };

  onChangeEffectiveDateHandler = (targetId, value) => {
    if (this.props.editColumns['effDate']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeTerminatedDateHandler = (targetId, value) => {
    if (this.props.editColumns['termDate']) {
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onChangeDescriptionHandler = (targetId, event) => {
    if (this.props.editColumns['name']) {
      const value = event.target.value;
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onSelectLineOfBusinessHandler = (id, item) => {
    if (this.props.editColumns['lob']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.lobList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  onChangePCTHandler = (targetId, event) => {
    if (this.props.editColumns['amount']) {
      const value = event.target.value;
      this.updateEditColumnsState(targetId, value);
      this.updateTableData(targetId, value);
    }
  };

  onSelectStateHandler = (id, item) => {
    if (this.props.editColumns['state']) {
      this.updateEditColumnsState(
        id,
        getValueByKey(this.props.stateList, item.key)
      );
      this.updateTableData(id, item.key);
    }
  };

  getDescriptionColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_name_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      value = item.name || '';
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <Input
        id={id}
        type="text"
        name={id}
        value={value}
        onChange={event => this.onChangeDescriptionHandler(id, event)}
        disabled={!this.props.editColumns['name']}
      />
    );
  }

  getLineOfBusinessColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_lob_${item.id}`;
    let value = '';
    if (!this.state.editColumns.hasOwnProperty(id)) {
      const key = ((item.lob || {}).fields || {}).lob || '';
      value = getValueByKey(this.props.lobList, key);
    } else {
      value = this.state.editColumns[id];
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.lobList}
        value={value}
        displayOption="description"
        placeholder=""
        onChange={this.onChangeHandler}
        onSelect={this.onSelectLineOfBusinessHandler}
        showToolTip={false}
        updateOnLeave={false}
        inputProps={{
          disabled: !this.props.editColumns['lob'],
        }}
      />
    );
  }

  getPCTColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_amount_${item.id}`;
    let value = 0;
    if (!this.state.editColumns.hasOwnProperty(id)) {
      value = item.amount || 0;
    } else {
      value = this.state.editColumns[id];
    }
    return (
      <Input
        id={id}
        type="number"
        step="5"
        min={0}
        max={100}
        name={id}
        value={value}
        onChange={event => this.onChangePCTHandler(id, event)}
        disabled={!this.props.editColumns['amount']}
      />
    );
  }

  getStateColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_state_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = item.hasOwnProperty('state') ? item.state + '' : '';
    }

    return (
      <AppAutocomplete
        id={id}
        data={this.props.stateList}
        value={value}
        displayOption="key"
        onChange={this.onChangeHandler}
        onSelect={this.onSelectStateHandler}
        showToolTip={false}
        updateOnLeave={false}
        placeholder=""
        inputProps={{
          disabled: !this.props.editColumns['state'],
        }}
      />
    );
  }

  getEffectiveColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_effDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).effDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }

    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeEffectiveDateHandler(id, value)}
        selected={value}
        disabled={!this.props.editColumns['effDate']}
      />
    );
  }

  getTerminatedColumn(item) {
    if (!!!item) {
      return;
    }
    const id = `${this.props.name}_termDate_${item.id}`;
    let value = '';
    if (this.state.editColumns.hasOwnProperty(id)) {
      value = this.state.editColumns[id];
    } else {
      value = (item.dateRange || {}).termDate || '';
      value = new Date(value);
      value = isValidDate(value) ? value : '';
    }

    return (
      <AppDateInput
        id={id}
        onChange={value => this.onChangeTerminatedDateHandler(id, value)}
        selected={value}
      />
    );
  }

  getSelectActionColumn(item) {
    if (!!!item.fields) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          id={`${this.props.name}-select-${item.fields.id}`}
          name={`${this.props.name}-select-${item.fields.id}`}
          type="checkbox"
          checked={this.state.selectedRows.includes(item)}
          onChange={event => {
            this.selectItem(item, event);
          }}
        />
      </div>
    );
  }

  sortColumn(item1, item2, order, field) {
    const value1 = item1.fields.hasOwnProperty(field)
      ? item1.fields[field] + ''
      : '';
    const value2 = item2.fields.hasOwnProperty(field)
      ? item2.fields[field] + ''
      : '';
    return sortByType(value1, value2, order);
  }

  sortLineOfBusinessColumn(item1, item2, order) {
    const value1 = ((item1.fields.lob || {}).fields || {}).lob || '';
    const value2 = ((item2.fields.lob || {}).fields || {}).lob || '';
    return sortByType(value1, value2, order);
  }

  sortEffectiveColumn(item1, item2, order) {
    const value1 = (item1.fields.dateRange || {}).effDate || '';
    const value2 = (item2.fields.dateRange || {}).effDate || '';
    return sortByType(value1, value2, order);
  }

  sortTerminatedColumn(item1, item2, order) {
    const value1 = (item1.fields.dateRange || {}).termDate || '';
    const value2 = (item2.fields.dateRange || {}).termDate || '';
    return sortByType(value1, value2, order);
  }

  selectAll = event => {
    const result = event.target.checked ? this.state.data.slice() : [];
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  selectItem = (item, event) => {
    const result = event.target.checked
      ? [...this.state.selectedRows, item]
      : this.state.selectedRows.filter(row => row.fields.id === item.fields.id);
    this.setState({
      selectedRows: result,
    });
    this.props.onSelectRows(result);
  };

  updateTableData = (targetId, value) => {
    const [, columnId, itemId] = targetId.split('_');

    if (columnId && itemId) {
      let item = R.find(
        item => item.fields.id + '' === itemId,
        this.state.data
      );
      if (item) {
        if (columnId === 'termDate' || columnId === 'effDate') {
          item.fields.dateRange[columnId] = formatMMDDYYYY(value);
        } else if (columnId === 'lob') {
          item.fields.lob.fields.lob = value;
        } else {
          item.fields[columnId] = value;
        }
      }
    }
    if (!this.props.isSelectable) {
      this.props.updateTableData(this.state.data);
    }
  };

  renderColumns = data => {
    let columns = [
      {
        header: 'Description',
        field: 'name',
        render: item => this.getDescriptionColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortColumn(item1, item2, other, 'name'),
      },
      {
        header: 'Line of Business',
        field: 'lob',
        render: item => this.getLineOfBusinessColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortLineOfBusinessColumn(item1, item2, other),
      },
      {
        header: 'PCT',
        field: 'amount',
        render: item => this.getPCTColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortColumn(item1, item2, other, 'amount'),
      },
      {
        header: 'State',
        field: 'state',
        render: item => this.getStateColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortColumn(item1, item2, other, 'state'),
      },
      {
        header: 'Effective',
        field: 'effDate',
        render: item => this.getEffectiveColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortEffectiveColumn(item1, item2, other),
      },
      {
        header: 'Terminated',
        field: 'termDate',
        render: item => this.getTerminatedColumn(item.fields),
        sortFunction: (item1, item2, other) =>
          this.sortTerminatedColumn(item1, item2, other),
      },
    ];

    if (this.props.isSelectable) {
      columns.push({
        header: (
          <div className="select-action">
            <CustomInput
              id={`${this.props.name}-selectAll`}
              name={`${this.props.name}-selectAll`}
              type="checkbox"
              checked={
                this.state.selectedRows.length === data.length &&
                data.length !== 0
              }
              onChange={this.selectAll}
            />
          </div>
        ),
        field: 'selectAction',
        sortAble: false,
        render: item => this.getSelectActionColumn(item),
      });
    }

    return columns;
  };

  render() {
    let { data } = this.state;
    const { filters } = this.props;
    if (filters) {
      if (filters.state) {
        data = data.filter(item => {
          return item.fields.state === filters.state;
        });
      }

      if (filters.lineOfBusiness) {
        data = data.filter(item => {
          const lob = ((item.fields.lob || {}).fields || {}).lob || '';
          return lob === filters.lineOfBusiness;
        });
      }

      if (filters.hasOwnProperty('effective')) {
        const today = new Date();
        data = data.filter(item => {
          if (item.fields.hasOwnProperty('dateRange') && filters.effective) {
            const effDate = new Date(item.fields.dateRange.effDate);
            const termDate = new Date(item.fields.dateRange.termDate);
            if (filters.effective) {
              if (effDate <= today && termDate >= today) {
                return true;
              } else {
                return false;
              }
            } else {
              if (effDate >= today || termDate <= today) {
                return true;
              } else {
                return false;
              }
            }
          }
          return true;
        });
      }
    }

    return (
      <AppTable
        columns={this.renderColumns(data)}
        data={data}
        autoPagination={true}
        sortAble={true}
        resizable={true}
      />
    );
  }
}

export default UAndCSetupTable;
