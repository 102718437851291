import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomInput, Input, Tooltip } from 'reactstrap';
import { none } from 'ramda';

class AppInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  changeHandler = event => {
    const regex = /[^0-9]/g;
    let value = event.target.value;
    if (this.props.numberOnly && value.match(regex)) {
      value = value.replace(regex, '');
      event.target.value = value;
    } else {
      this.props.changeHandler(this.props.id, value);
    }
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    return (
      <>
        {this.props.type === 'checkbox' ? (
          <CustomInput
            type={this.props.type}
            id={this.props.id}
            onChange={e =>
              this.props.changeHandler(this.props.id, e.target.checked)
            }
            disabled={this.props.disabled}
            label={this.props.placeholder}
            checked={this.props.checked}
          />
        ) : (
          <>
            <Input
              type={this.props.type}
              id={this.props.id}
              onChange={this.changeHandler}
              placeholder={
                this.props.type !== AppInput.defaultProps.type
                  ? this.props.placeholder
                  : null
              }
              disabled={this.props.disabled}
              value={this.props.value}
              className={this.props.error ? 'error' : ''}
              style={this.props.type == 'textarea' ? this.props.style : {}}
            >
              {
                this.props.type === AppInput.defaultProps.type ? (
                  <>
                    <option value="default" style={{ display: 'none' }}>
                      {this.props.placeholder}
                    </option>
                    {this.props.data?.length > 0 && this.props.data.map((object, index) => (
                      <option key={index} value={object[this.props.option.value]}>
                        {object[this.props.option.displayValue]}
                      </option>
                    ))}
                  </>
                ) : null
              }
            </Input>
          </>
        )}
        {this.props.showToolTip ? (
          <Tooltip
            placement={this.props.tipPlacement}
            isOpen={this.state.tooltipOpen}
            target={this.props.id}
            delay={this.props.tooltipDelay}
            toggle={this.toggle}
          >
            {this.props.placeholder}
          </Tooltip>
        ) : null}
      </>
    );
  }
}

AppInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  data: PropTypes.array,
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  showToolTip: PropTypes.bool,
  tooltipDelay: PropTypes.shape({
    show: PropTypes.number,
    hide: PropTypes.number,
  }),
  numberOnly: PropTypes.bool,
  tipPlacement: PropTypes.string,
  value: PropTypes.any,
  style: PropTypes.any
};

AppInput.defaultProps = {
  type: 'select',
  tooltipDelay: { show: 800, hide: 250 },
  option: {
    value: '',
    displayValue: '',
  },
  showToolTip: true,
  disabled: false,
  numberOnly: false,
  tipPlacement: 'bottom',
  style: {}
};

export default AppInput;
