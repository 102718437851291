import * as R from 'ramda';

export function openWindowWithPost(url, params, windowName, windowFeatures) {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', url);
  form.setAttribute('target', windowName);

  R.forEachObjIndexed((value, key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }, params);

  document.body.appendChild(form);

  const submitWindow = window.open('', windowName, windowFeatures);

  if (submitWindow) {
    form.submit();
  } else {
    // TODO: Handle pop-up blocked
  }

  document.body.removeChild(form);
}
