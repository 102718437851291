import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store';
class StatusHover360 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChecked: true,
            list: []
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {
        let data = [], checkVal = [];
        if (!this.props.statusChanged || (this.props.prevUser != '' && this.props.userSelected != this.props.prevUser))
            this.props.dataFromReducer.status.map(item => data.push({ 'name': item.value, 'isChecked': true }))
        else
            data = this.props.statusData;
        data.map(i => checkVal.push(i.isChecked));
        if (checkVal.includes(false))
            this.setState({ allChecked: false })
        this.setState({ list: data });
        if( this.props.statusBackup &&  this.props.statusBackup.length === 0)  {

            this.props.setStatusBackup(this.props.dataFromReducer.initialWorkList);
        }
    }

    toggle = () => {
        this.props.clicked()
    };

    handleChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { list, allChecked } = prevState;
            if (itemName === "checkAll") {
                allChecked = checked;
                list = list.map(item => ({ ...item, isChecked: checked }));
            } else {
                list = list.map(item =>
                    item.name === itemName ? { ...item, isChecked: checked } : item
                );
                allChecked = list.every(item => item.isChecked);
            }
            return { list, allChecked };
        }, () => {
            this.filterSelectedItems();
            this.props.updateStatusClicked(true);
            this.props.updatedStatusData(this.state.list);
            this.props.updatePreviousUser(this.props.userSelected);
            this.props.setSelectedType('status');
            this.props.setSelectedFilterType('status');
        });
    };
    filterSelectedItems = () => {
        let selected = [], selectedWorkList = [];
        let selectedItems = this.state.list.filter(i => i.isChecked == true);
        selectedItems.map(i => selected.push(i.name.toLowerCase().replace(/ /g, '')));
       
        this.props.statusBackup.map(i => i.servStatus
            ? selected.includes(i.servStatus.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i)
            : i.servStatus ?  selectedWorkList.push(i) : this.state.allChecked ? selectedWorkList.push(i) :  null) ;
        // if (!this.state.allChecked) {
        //     this.props.dataFromReducer.backupWorkList.map(i => i.servStatus
        //         && selected.includes(i.servStatus.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
        // }
        this.props.updateNewUsersWorkList(selectedWorkList);
        this.props.statusWorkListBackup(selectedWorkList);
       
      
    }

    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '300px' }} scrollable={true}>
                <ModalHeader toggle={this.toggle}>
                    Status:
                </ModalHeader>
                <FormGroup check style={{ margin: '16px' }}>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChange}
                        />{' '}
                        {'Select All'}
                    </Label>
                </FormGroup>

                <ModalBody>
                    <Form>
                        <FormGroup>
                            {this.state.list.map((item, idx) => (
                                <FormGroup key={idx} check>
                                    <Label check>
                                        <Input key={idx}
                                            type="checkbox"
                                            name={item.name} value={item.name} checked={item.isChecked} onChange={this.handleChange}
                                        />

                                        {item.name}
                                    </Label>
                                </FormGroup>

                            ))}
                        </FormGroup>
                    </Form>

                </ModalBody>
            </Modal >
        )
    }
}


const mapStateToProps = state => {
    return {
        dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
        statusChanged: state.FAST360.fast360Reducer.statusClicked,
        statusData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatedStatusData : [],
        prevUser: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatePreviousUser : '',
        statusBackup: state.FAST360.fast360Reducer.statusBackUp

    };
};
const mapDispatchToProps = dispatch => ({
    updateNewUsersWorkList: (data) => dispatch(fast360Actions.updateNewUsersWorkList(data)),
    statusWorkListBackup: (data) => dispatch(fast360Actions.statusWorkListBackup(data)),
    updateStatusClicked: (data) => dispatch(fast360Actions.updateStatusClicked(data)),
    updatedStatusData: (data) => dispatch(fast360Actions.updatedStatusData(data)),
    updatePreviousUser: (data) => dispatch(fast360Actions.updatePreviousUser(data)),
    setSelectedType: (data) => dispatch(fast360Actions.setSelectedType(data)),
    setStatusBackup: (data) => dispatch(fast360Actions.statusBackUp(data)),
    setSelectedFilterType:(data) => dispatch(fast360Actions.setSelectedFilterType(data)),


});


export default connect(mapStateToProps, mapDispatchToProps)(StatusHover360);