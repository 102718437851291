import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import './ModuleDropdown.scss';

class ModuleDropdown extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    return (
      <Dropdown
        className="ModuleDropdown"
        onChange={this.props.switchModule}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle>{this.props.activeModule.name}</DropdownToggle>
        <DropdownMenu>
          {this.props.modules.map((module, index) => {
            return (
              <Link
                onClick={() => this.props.switchModule(module)}
                role="button"
                key={index}
                to={module.path}
              >
                <DropdownItem>{module.value}</DropdownItem>
              </Link>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ModuleDropdown.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  switchModule: PropTypes.func,
};
export default ModuleDropdown;
