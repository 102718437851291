import { combineReducers } from 'redux';

import { siteDetailsDefault } from '../services/transform';
import { CLIENT_MAINTENANCE } from '../utils/constantUtils';

import {
  BILL_MAINTENANCE_LOAD_INITIAL_DATA,
  UPDATE_CURRENT_VIEW,
  UPDATE_CLIENT_RECORDS_TABLE,
  UPDATE_GROUP_CLIENTS,
  UPDATE_SLP_PROFILES_INITIAL_DATA,
  LOOK_UP_GROUPS_BY_GROUP_NAME,
  ADD_NEW_BLANK_GROUP,
  SAVE_GROUP,
  LOAD_GROUP_DETAILS,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER,
  ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER,
  ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP,
  UPDATE_SITE_DETAILS,
  UPDATE_MASTER_SITE_DETAILS,
  UPDATE_MASTER_CLIENT_DATA_PROVIDER,
  UPDATE_CLIENT_COMBO_BOXES,
  UPDATE_STATE_CITY_BY_ZIP_CODE,
  UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE,
  SELECT_SITE_COPY_SETTINGS_MODAL,
  RESET_SITE_COPY_SETTINGS_MODAL,
  UPDATE_SITE_TREE,
  RESET_SITE_TREE,
  RESET_GROUP_CLIENTS,
  RESET_GROUP_DETAILS,
  UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE,
  UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS,
} from './constants';

const initialDefaultState = {
  currentView: CLIENT_MAINTENANCE,
  profile: '',
  userName: '',
};

const initialReducer = (state = initialDefaultState, action) => {
  switch (action.type) {
    case BILL_MAINTENANCE_LOAD_INITIAL_DATA:
      return {
        ...state,
        profile: action.payload.profile,
      };
    case UPDATE_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload,
      };
    default:
      return { ...state };
  }
};

const clientMaintenanceInitialState = {
  clientComboBoxes: {
    settingsList: [],
    ppoList: [],
    stateList: [],
    settingTypeList: [],
    rateTypeList: [],
    lobList: [],
    clientNetworkList: [],
    logoList: [],
    billTypeList: [],
  },
  clients: [],
  groupClients: [],
  siteDetails: siteDetailsDefault.fields,
  updateSiteData: []
};

const clientMaintenanceInitialReducer = (
  state = clientMaintenanceInitialState,
  action
) => {
  switch (action.type) {
    case UPDATE_CLIENT_COMBO_BOXES:
      const stateList = action.payload.stateList.map(state => {
        return {
          key: state,
        };
      });

      const comboBoxes = {
        ...action.payload,
        stateList: stateList,
      };

      return {
        ...state,
        clientComboBoxes: comboBoxes,
      };

    case UPDATE_CLIENT_RECORDS_TABLE:
      return {
        ...state,
        clients: action.payload,
      };

      case UPDATE_GROUP_CLIENTS:
      return {
        ...state,
        groupClients: action.payload,
      };
      case RESET_GROUP_CLIENTS:
        return {
          ...state,
          groupClients: [],
        };

    case UPDATE_SITE_DETAILS:
      return {
        ...state,
        siteDetails: action.payload,
      };
      case UPDATE_SITE_TREE:
      return {
        ...state,
        updateSiteData: action.payload,
      };
      case RESET_SITE_TREE:
      return {
        ...state,
        updateSiteData: [],
      };
    default:
      return { ...state };
  }
};

const siteState = {
  masterClientDataProvider: [],
  masterSiteDetails: siteDetailsDefault.fields,
  stateCity: {
    city: '',
    state: '',
  },
  payerStateCity: {
    city: '',
    state: '',
  },
};

const siteReducer = (state = siteState, action) => {
  switch (action.type) {
    case UPDATE_MASTER_SITE_DETAILS:
      return {
        ...state,
        masterSiteDetails: action.payload,
      };
    case UPDATE_MASTER_CLIENT_DATA_PROVIDER:
      return {
        ...state,
        masterClientDataProvider: action.payload,
      };
    case UPDATE_STATE_CITY_BY_ZIP_CODE:
      return {
        ...state,
        stateCity: action.payload,
      };
    case UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE:
      return {
        ...state,
        payerStateCity: action.payload,
      };
    case SELECT_SITE_COPY_SETTINGS_MODAL:
      return {
        ...state,
        siteDataCopySettingsModal: action.payload,
      };
    case RESET_SITE_COPY_SETTINGS_MODAL:
      return {
        ...state,
        siteDataCopySettingsModal: siteDetailsDefault.fields,
      };
    default:
      return { ...state };
  }
};

const copySiteSettingsDefaultState = {
  copySettingsModalClients: [],
  siteDataCopySettingsModal: siteDetailsDefault.fields,
};

const copySiteSettingsReducer = (
  state = copySiteSettingsDefaultState,
  action
) => {
  switch (action.type) {
    case UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS:
      return {
        ...state,
        copySettingsModalClients: action.payload,
      };
    case SELECT_SITE_COPY_SETTINGS_MODAL:
      return {
        ...state,
        siteDataCopySettingsModal: action.payload,
      };
    case RESET_SITE_COPY_SETTINGS_MODAL:
      return {
        ...state,
        ...copySiteSettingsDefaultState,
      };
    default:
      return { ...state };
  }
};

const clientRatesState = {
  data: [],
  filters: {
    state: '',
    effective: false,
    lineOfBusiness: '',
  },
};

const clientRatesReducer = (state = clientRatesState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE:
      return {
        ...state,
        data: action.isBeingCopied
          ? state.data.concat(action.payload)
          : action.payload,
      };

    case UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return { ...state };
  }
};

const uAndCSetupState = {
  data: [],
  filters: {
    state: '',
    effective: false,
    lineOfBusiness: '',
  },
};

const uAndCSetupReducer = (state = uAndCSetupState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE:
      return {
        ...state,
        data: action.isBeingCopied
          ? state.data.concat(action.payload)
          : action.payload,
      };

    case UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return { ...state };
  }
};

const pPOSetupState = {
  data: [],
  filters: {
    state: '',
    effective: false,
    lineOfBusiness: '',
  },
};

const pPOSetupReducer = (state = pPOSetupState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE:
      return {
        ...state,
        data: action.isBeingCopied
          ? state.data.concat(action.payload)
          : action.payload,
      };

    case UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return { ...state };
  }
};

const settingsListState = {
  data: [],
};

const settingsListReducer = (state = settingsListState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
};

//For Group Maintenance
const groupMaintenanceDefaultState = {
  sLPProfilesArray: [],
  groups: [],
  linkedUsers: [],
  saveGroupResult: []
};

const groupMaintenanceReducer = (
  state = groupMaintenanceDefaultState,
  action
) => {
  switch (action.type) {
    case UPDATE_SLP_PROFILES_INITIAL_DATA:
      return {
        ...state,
        sLPProfilesArray: action.payload,
      };
    case LOOK_UP_GROUPS_BY_GROUP_NAME:
      return {
        ...state,
        groups: action.payload,
      };
    case LOAD_GROUP_DETAILS:
      return {
        ...state,
        linkedUsers: action.payload,
      };
      case RESET_GROUP_DETAILS:
        return {
          ...state,
          linkedUsers: [],
        };
    case ADD_NEW_BLANK_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };
    case SAVE_GROUP:
      return {
        ...state,
        saveGroupResult: action.payload,
      };
    default:
      return { ...state };
  }
};

const clientMaintenanceReducer = combineReducers({
  initialData: clientMaintenanceInitialReducer,
  site: siteReducer,
  clientRates: clientRatesReducer,
  uAndCSetup: uAndCSetupReducer,
  pPOSetup: pPOSetupReducer,
  settingsList: settingsListReducer,
  copySiteSettings: copySiteSettingsReducer,
});

const reducer = combineReducers({
  initialData: initialReducer,
  groupMaintenance: groupMaintenanceReducer,
  clientMaintenance: clientMaintenanceReducer,
});

export default reducer;
