const pageNumberArray = (pageCount, pageLinkSize, currentPage) => {
    return range(arraySize(pageLinkSize, pageCount), startIndex(pageCount, pageLinkSize, currentPage))
}

const arraySize = (pageLinkSize, pageCount) => {
    return Math.min(pageLinkSize, pageCount)
}

const startIndex = (pageCount, pageLinkSize, currentPage) => {
    if (pageLinkSize >= pageCount) {
        return 0
    } else if (currentPage + Math.ceil(pageLinkSize / 2) >= pageCount) {
        return pageCount - pageLinkSize
    } else {
        return Math.max(0, currentPage - (Math.floor(pageLinkSize / 2)));
    }
}

const range = (size, startAt = 0) => {
    return [...Array(size).keys()].map(i => i + startAt)
}

export default {pageNumberArray};
