import { Modal, ModalHeader, ModalBody, Input, } from 'reactstrap';
import { Col, Row, Form, Button, } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import {
  fast360Operations,
  fast360Selectors,
} from '../store/index';
import PropTypes from 'prop-types';
import AppDateInput from 'shared/components/input/AppDateInput';
import { connect } from 'react-redux';
import { commonOperations } from 'common/store';
import moment from '../../../../node_modules/moment/moment';
import "react-datepicker/dist/react-datepicker.css";
import { authSelectors } from 'modules/auth/store';

const dropDown = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" }
]
const VendorMaintenanceNewEntry = ({
  isOpen,
  onClose,
  providerTypes,
  loadVMProviderTypes,
  saveProvider,
  userId,
  providerData,
}) => {

  const [formFields, setFormFields] = useState({
    id: null,
    tin: null,
    groupName: null,
    lastName: null,
    firstName: null,
    address1: null,
    address2: null,
    zipCode: null,
    phoneNumber: null,
    ucrPercent: null,
    billedCharges: null,
    fsPercent: null,
    medicarePercent: null,
    effDate: null,
    termDate: null,
    defaultTermDate: new Date("01/01/2099"),
    notes: null,
    faxNumber: null,
    contact: null,
    services: null,
    parentTIN: null,
    network: null,
    productLineId: null,
    groupId: null
  });

  const [windowTitle, setWindowTitle] = useState("Provider Entry");

  const toggle = () => {
    providerData = null;
    setWindowTitle("Provider Entry");
    resetFormData();
    onClose();
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === 'tin' || name === 'parentTIN') {
      if (value.length <= 9) {
        setFormFields({ ...formFields, [name]: formatNumeric(value) });
      }
    } else if (name === 'phoneNumber' || name === 'faxNumber') {
      setFormFields({ ...formFields, [name]: formatPhoneNum(value) });
    } else if (name === 'ucrPercent' || name === 'fsPercent' || name === 'billedCharges' || name === 'medicarePercent') {
      setFormFields({ ...formFields, [name]: formatDecimal(value) });
    } else if (name === 'effDate' || name === 'termDate') {
      let mon = value.getMonth() + 1;
      value =
        mon + '/' + value.getDate() + '/' + value.getFullYear();
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const onChangeDateHandler = object => {
    if (object.key === "effDate") {
      if (formFields.termDate && moment(formFields.termDate).isBefore(object.value)) {
        alert("Error: You must enter an Effective Date that is prior to the Term Date.");
        return;
      }
    } else {
      if (formFields.effDate && moment(formFields.effDate).isAfter(object.value)) {
        alert("Error: You can't enter a Term Date that is prior to the Effective Date.");
        return;
      }
    }
    let mon = object.value.getMonth() + 1;
    const dateText =
      mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    
    if(object.key === "termDate") {
      setFormFields({...formFields, [object.key]: dateText, defaultTermDate: object.value});
    }else{
      setFormFields({ ...formFields, [object.key]: dateText });
    }
  };

  const clearEffDate = () => {
    setFormFields({...formFields, effDate: null});
  }

  const clearTermDate = () => {
    setFormFields({...formFields, termDate: null});
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handleSelectChange = (id, value) => {
    setFormFields({ ...formFields, [id]: value });
  };

  const formatNumeric = (value) => {
    if (!value) return value;
    return value.replace(/[^\d]/g, "");
  }

  const formatDecimal = (value) => {
    if (!value) return value;
    return value.replace(/[^\d\.\d]/g, "");
  }

  useEffect(() => {
    resetFormData();
    const req = {
      functionName: "loadVMProviderTypes"
    }
    loadVMProviderTypes(req);
  }, []);

  useEffect(() => {
    if (providerData) {
      loadProviderData();
      setWindowTitle("Provider Edit");
    }
  }, [providerData]);

  const submitHandler = () => {
    var issues = "";
    if (!formFields.tin) {
      issues += "\nmissing TIN";
    } else if (formFields.tin.length < 9) {
      issues += "\nTIN must be 9 digits";
    }
    if (!formFields.groupName) {
      issues += "\nmissing Group Name";
    }
    if (!formFields.address1 || !formFields.zipCode) {
      issues += "\nmissing Address/Zip Code";
    }
    if (!formFields.phoneNumber) {
      issues += "\nmissing Phone Number";
    } else if (formFields.phoneNumber.replace(/[\s()-]/g, '').length < 10) {
      issues += "\nPhone Number is incomplete";
    }
    if (formFields.faxNumber && formFields.faxNumber.replace(/[\s()-]/g, '').length < 10) {
      issues += "\nFax Number is incomplete";
    }
    if (!formFields.contact) {
      issues += "\nmissing Contact";
    }
    if (!formFields.effDate) {
      issues += "\nmissing Effective Date";
    }
    if (!formFields.termDate) {
      issues += "\nmissing Term Date";
    }
    /*if (formFields.ucrPercent === null || formFields.ucrPercent === '') {
      issues += "\nmissing % Below UCR";
    }
    if (formFields.billedCharges === null || formFields.billedCharges === '') {
      issues += "\nmissing % Below Billed Charges";
    }
    if (formFields.fsPercent === null || formFields.fsPercent === '') {
      issues += "\nmissing % Below Fee Schedule";
    }
    if (formFields.medicarePercent === null || formFields.medicarePercent === '') {
      issues += "\nmissing % Below Medicare Allowable";
    }*/
    if (formFields.productLineId === null) {
      issues += "\nmissing Provider Type";
    }

    if (issues) {
      alert("Please correct the following issues before submitting: " + issues);
      return;
    }

    let params = formFields;
    params.uid = userId;
    params.effDate = moment(formFields.effDate).format('YYYY-MM-DD');
    params.termDate = moment(formFields.termDate).format('YYYY-MM-DD');
    params.ucrPercent = (formFields.ucrPercent !== null && formFields.ucrPercent !== "") ? formFields.ucrPercent : 0;
    params.fsPercent = (formFields.fsPercent !== null && formFields.fsPercent !== "") ? formFields.fsPercent : 0;
    params.billedCharges = (formFields.billedCharges !== null && formFields.billedCharges !== "") ? formFields.billedCharges : 0;
    params.medicarePercent = (formFields.medicarePercent !== null && formFields.medicarePercent !== "") ? formFields.medicarePercent : 0;
    params.phoneNumber = formFields.phoneNumber !== null ? formFields.phoneNumber.replace(/[\s()-]/g, '') : null;
    params.faxNumber = formFields.faxNumber !== null ? formFields.faxNumber.replace(/[\s()-]/g, '') : null;
    params.network = getBoolean(formFields.network);

    let isEdit = (windowTitle === "Provider Edit");

    saveProvider(params, resetFormData, isEdit);
  }

  const resetFormData = () => {
    setFormFields({
      id: null,
      tin: null,
      groupName: null,
      lastName: null,
      firstName: null,
      address1: null,
      address2: null,
      zipCode: null,
      phoneNumber: null,
      ucrPercent: null,
      billedCharges: null,
      fsPercent: null,
      medicarePercent: null,
      effDate: null,
      termDate: null,
      defaultTermDate: new Date("01/01/2099"),
      notes: null,
      faxNumber: null,
      contact: null,
      services: null,
      parentTIN: null,
      network: null,
      productLineId: null,
      groupId: null
    });
  }

  const loadProviderData = () => {
    setFormFields({
      id: providerData?.id ? providerData.id : null,
      tin: providerData?.tin ? providerData.tin : null,
      groupName: providerData?.groupName ? providerData.groupName : null,
      lastName: providerData?.lastName ? providerData.lastName : null,
      firstName: providerData?.firstName ? providerData.firstName : null,
      address1: providerData?.address1 ? providerData.address1 : null,
      address2: providerData?.address2 ? providerData.address2 : null,
      zipCode: providerData?.zipCode ? providerData.zipCode : null,
      phoneNumber: providerData?.phoneNumber ? providerData.phoneNumber : null,
      ucrPercent: providerData?.ucrPercent ? providerData.ucrPercent : 0,
      billedCharges: providerData?.billedCharges ? providerData.billedCharges : 0,
      fsPercent: providerData?.fsPercent ? providerData.fsPercent : 0,
      medicarePercent: providerData?.medicarePercent ? providerData.medicarePercent : 0,
      effDate: providerData?.effDate ? providerData.effDate : null,
      termDate: providerData?.termDate ? providerData.termDate : null,
      notes: providerData?.notes ? providerData.notes : null,
      faxNumber: providerData?.faxNumber ? providerData.faxNumber : null,
      contact: providerData?.contact ? providerData.contact : null,
      services: providerData?.services ? providerData.services : null,
      parentTIN: providerData?.parentTIN ? providerData.parentTIN : null,
      network: providerData?.network ? providerData.network : 0,
      productLineId: providerData?.productLineId ? providerData.productLineId : null,
      groupId: providerData?.groupId ? providerData.groupId : null
    });
  }

  const getBoolean = (value) => {
    switch (value) {
      case "true": case "1": return true;
      case "false": case "0": case null: return false;
      default: return Boolean(value);
    }
  }

  return (
    <>
      <Modal size="lg" isOpen={isOpen} modalClassName="vendor-entry-modal-body">
        <ModalHeader toggle={toggle} >{windowTitle}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}>  TIN: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '25%' }}
                    placeholder="#s only"
                    id="tin"
                    name="tin"
                    onChange={handleInputChange}
                    value={formFields.tin || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}>  Group Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '25%' }}
                    id="groupName"
                    name="groupName"
                    onChange={handleInputChange}
                    value={formFields.groupName || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> First Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="firstName"
                    name="firstName"
                    //value={}
                    onChange={handleInputChange}
                    value={formFields.firstName || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Last Name: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="lastName"
                    name="lastName"
                    //value={}
                    onChange={handleInputChange}
                    value={formFields.lastName || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Address: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="address1"
                    name="address1"
                    onChange={handleInputChange}
                    value={formFields.address1 || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Address 2: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="address2"
                    name="address2"
                    onChange={handleInputChange}
                    value={formFields.address2 || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Zip: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="zipCode"
                    name="zipCode"
                    onChange={handleInputChange}
                    value={formFields.zipCode || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Phone: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="(xxx)xxx-xxxx"
                    onChange={handleInputChange}
                    value={formatPhoneNum(formFields.phoneNumber) || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Fax: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="faxNumber"
                    name="faxNumber"
                    placeholder="(xxx)xxx-xxxx"
                    onChange={handleInputChange}
                    value={formatPhoneNum(formFields.faxNumber) || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Contact: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="contact"
                    name="contact"
                    onChange={handleInputChange}
                    value={formFields.contact || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> % below UCR: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="ucrPercent"
                    name="ucrPercent"
                    onChange={handleInputChange}
                    value={formFields.ucrPercent === null || formFields.ucrPercent === 0.0 ? '' : formFields.ucrPercent}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> % below Fee Schedule: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="fsPercent"
                    name="fsPercent"
                    onChange={handleInputChange}
                    value={formFields.fsPercent === null || formFields.fsPercent === 0.0 ? '' : formFields.fsPercent}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> % below Billed Charges: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="billedCharges"
                    name="billedCharges"
                    onChange={handleInputChange}
                    value={formFields.billedCharges === null || formFields.billedCharges === 0.0 ? '' : formFields.billedCharges}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> % below Medicare Allowable: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="medicarePercent"
                    name="medicarePercent"
                    onChange={handleInputChange}
                    value={formFields.medicarePercent === null || formFields.medicarePercent === 0.0 ? '' : formFields.medicarePercent}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Effective Date: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppDateInput
                    //style={{ width: '45%' }}
                    id="effDate"
                    name="effDate"
                    onChange={value =>
                      onChangeDateHandler({ key: 'effDate', value })
                    }
                    value={formFields.effDate ? moment(formFields.effDate).format('MM/DD/YYYY') : '' || ''}
                  />
                </span>
              </Col>
              <Col sm="1" style={{ marginTop: '8px', marginLeft: '-25px'}}>
              <Button
                    className="clearEffDate"
                    type="button"
                    color="danger"
                    onClick={clearEffDate}
                  >
                    X
                  </Button>
              </Col>
              <Col sm="2" className="text-right" style={{ marginLeft: '26px', paddingTop: '8px' }}> Term Date: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppDateInput
                    //style={{ width: '45%' }}
                    id="termDate"
                    name="termDate"
                    onChange={value =>
                      onChangeDateHandler({ key: 'termDate', value })
                    }
                    openToDate={formFields.defaultTermDate}
                    value={formFields.termDate ? moment(formFields.termDate).format('MM/DD/YYYY') : '' || ''}
                  />
                </span>
              </Col>
              <Col sm="1" style={{ marginTop: '8px', marginLeft: '-25px'}}>
              <Button
                    className="clearTermDate"
                    type="button"
                    color="danger"
                    onClick={clearTermDate}
                  >
                    X
                  </Button>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Parent TIN: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    //style={{ width: '45%' }}
                    id="parentTIN"
                    name="parentTIN"
                    onChange={handleInputChange}
                    value={formFields.parentTIN || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Is this a network: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppInput
                    id="network"
                    name="network"
                    type="select"
                    changeHandler={handleSelectChange}
                    value={formFields.network === null ? '' : getBoolean(formFields.network) ? 1 : 0}
                    option={{
                      value: 'id',
                      displayValue: 'name',
                    }}
                    placeholder="Choose..."
                    data={dropDown}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Provider Type: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <AppInput
                    id="productLineId"
                    name="productLineId"
                    type="select"
                    changeHandler={handleSelectChange}
                    value={formFields.productLineId || ''}
                    option={{
                      value: 'id',
                      displayValue: 'productName',
                    }}
                    placeholder="Choose..."
                    data={providerTypes}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col sm="3" className="text-right" style={{ marginLeft: '-50px', paddingTop: '8px' }}> Notes: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row', height: "100px" }}>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    onChange={handleInputChange}
                    value={formFields.notes || ''}
                  />
                </span>
              </Col>
              <Col sm="3" className="text-right" style={{ paddingTop: '8px' }}> Services: </Col>
              <Col sm="3" style={{ paddingTop: '8px' }}>
                <span style={{ display: 'flex', flexDirection: 'row', height: "100px" }}>
                  <Input
                    type="textarea"
                    name="services"
                    id="services"
                    onChange={handleInputChange}
                    value={formFields.services || ''}
                  />
                </span>
              </Col>

            </Row>
            <Row>
              <Col lg="8" className="text-right" style={{ paddingTop: '8px' }}>  </Col>
              <Col xs="2" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                <Button style={{ paddingTop: '8px' }} onClick={submitHandler}>Submit</Button>
              </Col>
              <Col xs="2" style={{ paddingTop: '8px' }}>
                <Button style={{ paddingTop: '8px' }} onClick={toggle} >Close</Button>
              </Col>

            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

VendorMaintenanceNewEntry.propTypes = {
  onClose: PropTypes.func,
  providerData: PropTypes.object,
};

const mapStateToProps = state => {
  const vmData = fast360Selectors.getVendorMaintenance(state);
  const user = authSelectors.getUser(state);
  return {
    providerTypes: vmData.vmProviderTypes,
    userId: user.id,
  };

};



const mapDispatchToProps = dispatch => ({
  loadVMProviderTypes: () => dispatch(fast360Operations.loadVMProviderTypes()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  saveProvider: (data, resetFormData, isEdit) => dispatch(fast360Operations.saveProvider(data, resetFormData, isEdit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaintenanceNewEntry);