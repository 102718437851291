import { push } from 'connected-react-router';

import { ServiceExecutor } from 'core/services/serviceExecutor';

import expertService from '../services/expertService';

import actions from './actions';

const getInitialData = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.getInitialData(userId))
      .withResponseHandler(data => {
        dispatch(actions.loadInitialData(data));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const getUserTasks = userId => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.getTasks(userId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateTaskTable(responseData));
      })
      .withFailureAlert('An error occurred while get user tasks.')
      .build()
      .execute();
  };
};

const transferTasks = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.transferTasks(data))
      .withResponseHandler(() => {
        dispatch(getUserTasks(data.selectedUser));
      })
      .withSuccessAlert('Successfully transferred tasks.')
      .withFailureAlert('An error occurred while transferring tasks.')
      .build()
      .execute();
  };
};

const findRadiusExperts = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.findRadiusExperts(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateRadiusSearchTable(responseData));
      })
      .withFailureAlert('An error occurred while searching radius.')
      .build()
      .execute();
  };
};

const findExpertSimple = searchValue => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.findExpertSimple(searchValue))
      .withResponseHandler(responseData => {
        dispatch(actions.updateExpertsSearchResult(responseData));
      })
      .withFailureAlert('An error occurred while finding experts.')
      .build()
      .execute();
  };
};

const getPreviewExpert = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.loadExpertUser(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePreviewUser(responseData));
      })
      .withFailureAlert('An error occurred while loading expert user.')
      .build()
      .execute();
  };
};

const runExpertReport = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.runExpertReport(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateExpertReportTable(responseData));
      })
      .withFailureAlert('An error occurred while running expert report.')
      .build()
      .execute();
  };
};

const checkForDuplicateExpertGroup = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.findExpertGroup(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDuplicateExpertGroup(responseData));
      })
      .withFailureAlert('An error occurred while finding expert groups.')
      .build()
      .execute();
  };
};

const loadExpertGroupAddresses = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.loadExpertAddresses(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSelectedExpertGroupAddresses(responseData));
      })
      .withFailureAlert(
        'An error occurred while loading expert group addresses.'
      )
      .build()
      .execute();
  };
};

const saveNewGroup = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.saveNewGroup(data))
      .withResponseHandler(groupId => {
        dispatch(push(`/experts/info/group/${groupId}`));
      })
      .withSuccessAlert('Successfully creating new group.')
      .withFailureAlert('An error occurred while creating new group.')
      .build()
      .execute();
  };
};

const checkForDuplicateExpert = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.findExpert(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDuplicateExpert(responseData));
      })
      .withFailureAlert('An error occurred while finding experts.')
      .build()
      .execute();
  };
};

const loadExpertAddresses = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.loadExpertAddresses(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSelectedExpertAddresses(responseData));
      })
      .withFailureAlert('An error occurred while loading expert addresses.')
      .build()
      .execute();
  };
};

const saveNewExpert = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertService.saveNewExpert(data))
      .withResponseHandler(userId => {
        dispatch(push(`/experts/info/user/${userId}`));
      })
      .withSuccessAlert('Successfully creating new expert.')
      .withFailureAlert('An error occurred while creating new expert.')
      .build()
      .execute();
  };
};

export default {
  getInitialData,
  getUserTasks,
  transferTasks,
  findRadiusExperts,
  findExpertSimple,
  getPreviewExpert,
  runExpertReport,
  checkForDuplicateExpertGroup,
  loadExpertGroupAddresses,
  saveNewGroup,
  checkForDuplicateExpert,
  loadExpertAddresses,
  saveNewExpert,
};
