import React, { Component } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import moment from 'moment'


class Fast360AppTableBodyRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
            rowIndex: null,
        };
    }
    toggleSelectedRow = (id, checked) => {
        this.setState(prevState => {
            return checked
                ? this.addRowFromState(prevState, id)
                : this.removeRowFromState(prevState, id);
        }, this.executeSelectHandler);
    };

    removeRowFromState = (state, id) => {
        return this.updateSelectedRows(
            state,
            state.selectedRows.filter(row => row !== id)
        );
    };

    addRowFromState = (state, id) => {
        return this.updateSelectedRows(state, state.selectedRows.concat(id));
    };

    updateSelectedRows = (state, selectedRows) => {
        return {
            ...state,
            selectedRows,
        };
    };

    executeSelectHandler = () => {
        const selectedRows = this.state.selectedRows.map(id => {
            return this.props.data[id];
        });
        this.props.selectHandler(selectedRows);
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRows: [] });
    }

    onRowClicked = (dataObject, rowIndex, event) => {
        if (event.target.tagName === 'TD') {
            this.props.rowClickHandler(dataObject);
            this.setState({
                highlightedRow: rowIndex,
            });
        }
    };


    render() {
        const rowIndex = this.props.rowIndex;
        const dataObject = this.props.dataObject;

        return (
            <>
                <tbody>
                    <tr
                        key={rowIndex}
                        style={
                            this.props.highlightedRow === rowIndex
                                ? { backgroundColor: '#cdf0f9' }
                                : null
                        }
                    >
                        {this.props.columns.map((column, columnIndex) => {
                            if (column.render) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {column.render(dataObject, rowIndex)}
                                        </th>
                                    );
                                }
                                return (
                                    <td key={columnIndex}>{column.render(dataObject, rowIndex)}</td>
                                );
                            }
                            if (column.header == 'Name') {
                                return (<td>
                                    {dataObject.userName || ''}
                                </td>)
                            }
                            if (column.header == 'Date') {
                                return (<td>
                                    { `${moment(dataObject.enteredDate).format('MM/DD/YYYY')} ${moment(dataObject.enteredDate).format('LT')}` || ''}
                                </td>)
                            }
                            if (column.header == 'Note') {
                                return (<td>
                                    <ul className="notes">
                                        {dataObject.note && dataObject.note.split('\n').map((note, index) => (
                                            <li key={index}>{note}</li>
                                        ))}
                                    </ul>
                                    {/* <div dangerouslySetInnerHTML={{ __html: dataObject.note }} /> */}
                                </td>)
                            }
                            if (column.header == 'Type') {
                                return (<td>
                                    {dataObject.noteType || ''}
                                </td>)
                            }
                            if (column.header == 'Send') {
                                return (<td>
                                    {dataObject.canClientSee === true ? 'Yes' : 'No'}
                                </td>)
                            }
                        })}
                    </tr>
                </tbody >
            </>
        );
    }
}


Fast360AppTableBodyRow.propTypes = AppTableBodyPropType;

export default Fast360AppTableBodyRow;
