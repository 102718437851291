import { createSelector } from 'reselect';

import { expertsSelectors } from '../../experts/store';

const getModule = state => expertsSelectors.getExpertInfoModule(state);

const getGroupInfo = createSelector(getModule, state => state.group);
const getExpertInfo = createSelector(getModule, state => state.expert);
const getInitialData = createSelector(getModule, state => state.initialData);

export default {
  getGroupInfo,
  getExpertInfo,
  getInitialData,
};
