import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppInput from 'shared/components/input/AppInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import DashboardButton from './DashboardButton';
import { faFilter } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const locationSame = '1';
const locationDiff = '0';
const am = '1';
const pm = '0';

const Fast360TRN = ({
  transportCategory,
  appointmentType,
  relationShipType,
  selectedService,
  userId,
  fromExt,
  trnPo,
  selectedMod,
  updatePostExternal,
  timeZone,
  requiredServiceData
}) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const [poNum, setPoNum] = useState('');
  const onTabRadioChangeHandler = event => {
    selectedService.locationSame = event.target.value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerTrnType = (e) => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.transportationCategoryId = target.value;
    setSelectedItem({ selectedService });
  };
  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);
  const setMap = {
    dos: setValidDos,
  }
  useEffect(() => {
    if (fromExt && selectedMod === 'TRN') {
      selectedService.poNumber = trnPo.UniquePortalAuthCode;
      setPoNum(trnPo.UniquePortalAuthCode)
      const filterData = transportCategory.filter(i => i.value == trnPo.ServiceType)[0];
      selectedService.transportationCategoryId = filterData.id;
      if (updatePostExternal.length > 0) {
        const postExternalData = updatePostExternal[0];
        selectedService.dos = moment(postExternalData.RequiredToDate).format('MM/DD/YYYY');
        // selectedService.dop = moment(postExternalData.RequiredToDate).format('MM/DD/YYYY');
        //selectedService.dod = moment(postExternalData.RequiredToDate).format('MM/DD/YYYY');
        /*if (postExternalData.RequestedAppointmentTime) {
          if (postExternalData.RequestedAppointmentTime.includes(':')) {
            const time = postExternalData.RequestedAppointmentTime;
            selectedService.aptHour = Number(time.split(':')[0])
            selectedService.aptMinutes = Number(time.split(':')[1].split(' ')[0])
            if (time.includes('AM')) selectedService.aptAMPM = am;
            else selectedService.aptAMPM = pm;
          }
          else {
            const time = postExternalData.RequestedAppointmentTime;
            const hrs = Number(time.substring(0, 2));
            const min = Number(time.substring(2, 4));
            if (hrs <= 11) {
              selectedService.aptAMPM = am;
              selectedService.aptHour = (hrs === 0) ? 12 : hrs;
            }
            else {
              selectedService.aptAMPM = pm;
              selectedService.aptHour = hrs;
            }
            selectedService.aptMinutes = min;
          }
        }*/
        //drop
        /*if (postExternalData.RequestedAppointmentTime) {
          if (postExternalData.RequestedAppointmentTime.includes(':')) {
            const time = postExternalData.RequestedAppointmentTime;
            selectedService.drpHour = Number(time.split(':')[0])
            selectedService.drpMinutes = Number(time.split(':')[1].split(' ')[0])
            if (time.includes('AM')) selectedService.drpAMPM = am;
            else selectedService.drpAMPM = pm;
          }
          else {
            const time = postExternalData.RequestedAppointmentTime;
            const hrs = Number(time.substring(0, 2));
            const min = Number(time.substring(2, 4));
            if (hrs <= 11) {
              selectedService.drpAMPM = am;
              selectedService.drpHour = (hrs === 0) ? 12 : hrs;
            }
            else {
              selectedService.drpAMPM = pm;
              selectedService.drpHour = hrs;
            }
            selectedService.drpMinutes = min;
          }
        }*/

        let dtlLine = {};
        let dtlList = [];
          let tempId = `trn-${Math.floor(Math.random()*1000)}`;
          dtlLine.tempId = tempId;
          dtlLine.id = 0;
          dtlLine.leg = 1;
          dtlLine.dropOffAddress = {};
          dtlLine.pickupHours = 12;
          dtlLine.pickupMin = 0;
          dtlLine.dropOffHours = 12;
          dtlLine.dropOffMin = 0;
          dtlLine.dropOffAddress.zipCode = {};
          dtlLine.dropOffDate = new Date();
          dtlLine.pickupDate = new Date();
          dtlLine.pickup = new Date();
          dtlLine.dropOff = new Date();
          dtlLine.dropOffAddress.street1 = postExternalData.DropOffStreet;
          dtlLine.dropOffAddress.zipCode.state = postExternalData.DropOffState;
          dtlLine.dropOffAddress.zipCode.city = postExternalData.DropOffCity;
          dtlLine.dropOffAddress.zipCode.zip = postExternalData.DropOffZip;
          dtlLine.dropOffAddress.phone = postExternalData.DropOffPhone;
          dtlLine.pickupAddress = {};
          dtlLine.pickupAddress.zipCode = {};
          dtlLine.pickupAddress.street1 = postExternalData.PickupAddress;
          dtlLine.pickupAddress.zipCode.city = postExternalData.PickupCity;
          dtlLine.pickupAddress.zipCode.state = postExternalData.PickupState;
          dtlLine.pickupAddress.zipCode.zip = postExternalData.PickupZip;
          dtlLine.pickupAddress.phone = postExternalData.PickupPhone;
        dtlList.push(dtlLine);

        selectedService.detailList = dtlList;
        const timezoneObj = {
          'Pacific': '221',
          'Eastern': '219',
          // 'Alaska-Hawaii Standard': '216',
          // 'Alaska Daylight':'210',
          // 'Alaska Standard':'217',
          'Central': '213',
          'Samara': '215',
          'Mountain': '222',
          'Hawaii-Aleutian Standard': '220',
          'Hawaii-Aleutian Daylight': '209'
        }

      }

    }
  }, [updatePostExternal.length])
  const onChangeHandlerAppType = (e) => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.apptType = name;
    selectedService.apptTypeId = target.value;
    if (target.value === '22' || target.value === '23' || target.value === '24' || target.value === '25' || target.value === '26') {
      selectedService.highPriority = true;
    }
    setSelectedItem({ selectedService });
  };
  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }
    else if (object.key === 'dop') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dop =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }

    else if (object.key === 'dod') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dod =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }
    setSelectedItem({ selectedService });
    setValidDos(true);
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
      // setPoNum(value)
    }

    if (name === 'arrivalHour') {
      selectedService.arrivalHour = value;
    }
    if (name === 'arrivalMinute') {
      selectedService.arrivalMin = value;
    }
    if (name === 'linesPerPage') {
      selectedService.linesPerPage = value;
    }
    if (name === 'ratePerLine') {
      selectedService.rate = value;
    }
    if (name === 'startHours') {
      selectedService.startHours = value;
    }
    if (name === 'startMinutes') {
      selectedService.startMinutes = value;
    }

    if (name === 'aptHour') {
      selectedService.aptHour = value;
    }
    if (name === 'aptMinutes') {
      selectedService.aptMinutes = value;
    }

    if (name === 'drpHour') {
      selectedService.drpHour = value;
    }
    if (name === 'drpMinutes') {
      selectedService.drpMinutes = value;
    }
    if (name === 'aptTZ') {
      selectedService.aptTZ = value;
    }
    if (name === 'arriveTZ') {
      selectedService.arriveTZ = value;
    }


    if (name === 'startTZ') {
      selectedService.startTZ = value;
    }
    if (name === 'endHour') {
      selectedService.endHour = value;
    }
    if (name === 'endMinute') {
      selectedService.endMinute = value;
    }
    if (name === 'endTZ') {
      selectedService.endTZ = value;
    }

    if (name === 'locationPhone') {
      if (value.length !== 10) {
        selectedService.facility.phone = value;
      } else {
        selectedService.facility.phone = formatPhoneNumber(value);
      }
    }

    if (name === 'recurring') {
      selectedService.recurring =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'locationName') {
      selectedService.facility.address.street1 = value;
    }
    if (name === 'locationName2') {
      selectedService.facility.address.street2 = value;
    }
    if (name === 'facilityName') {
      selectedService.facility.name = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'dropOffStreet') {
      selectedService.DropOffStreet = value;
    }
    if (name === 'dropOffCity') {
      selectedService.DropOffCity = value;
    }
    if (name === 'dropOffState') {
      selectedService.DropOffState = value;
    }
    if (name === 'dropOffZip') {
      selectedService.DropOffZip = value;
    }
    if (name === 'dropOffPhone') {
      if (value.length !== 10) {
        selectedService.DropOffPhone = value;
      } else {
        selectedService.DropOffPhone = formatPhoneNumber(value);
      }
    }
    if (name === 'pickUpName') {
      selectedService.PickupAddress = value;
    }
    if (name === 'pickUpStreet') {
      selectedService.PickupCity = value;
    }
    if (name === 'pickUpStreet1') {
      selectedService.PickupState = value;
    }
    if (name === 'pickUpZip') {
      selectedService.PickupZip = value;
    }
    if (name === 'pickupPhone') {
      if (value.length !== 10) {
        selectedService.PickupPhone = value;
      } else {
        selectedService.PickupPhone = formatPhoneNumber(value);
      }
    }



    var hours;
    var minutes;
    var stHr;
    var endHr;
    var stMin;
    var endMin;
    var arrHr;
    var arrMin;

    if (selectedService.startHours !== undefined) {
      if (selectedService.startAMPM !== undefined) {
        if (selectedService.startAMPM === "0") {
          if (selectedService.startHours === "12") {
            stHr = selectedService.startHours;
          } else {
            stHr = parseInt(selectedService.startHours) + 12;
          }
        } else {
          if (selectedService.startHours === "12") {
            stHr = 0;
          } else {
            stHr = selectedService.startHours;
          }
        }
      } else {
        stHr = selectedService.startHours
      }
    } else {
      stHr = 0
    }

    if (selectedService.endHour !== undefined) {
      if (selectedService.startAMPM !== undefined) {
        if (selectedService.endAMPM === "0") {
          if (selectedService.endHour == 12) {
            endHr = selectedService.endHour;
          } else {
            endHr = parseInt(selectedService.endHour) + 12;
          }
        } else {
          if (selectedService.endHour == 12) {
            endHr = 0;
          } else {
            endHr = selectedService.endHour;
          }
        }
      } else {
        endHr = selectedService.endHour
      }
    } else {
      stHr = 0
    }
    if (selectedService.endMinute !== undefined) {
      endMin = selectedService.endMinute
    } else {
      endMin = 0
    }
    if (selectedService.startMinutes) {
      stMin = selectedService.startMinutes
    } else {
      stMin = 0
    }

    if (selectedService.arrivalHour !== undefined) {
      if (selectedService.arriveAMPM !== undefined) {
        if (selectedService.arriveAMPM === "0") {
          if (selectedService.arrivalHour === "12") {
            arrHr = selectedService.arrivalHour;
          } else {
            arrHr = parseInt(selectedService.arrivalHour) + 12;
          }
        } else {
          if (selectedService.arrivalHour === "12") {
            arrHr = 0;
          } else {
            arrHr = selectedService.arrivalHour;
          }
        }
      } else {
        arrHr = selectedService.arrivalHour
      }
    } else {
      arrHr = 0
    }

    if (selectedService.arrivalMin) {
      arrMin = selectedService.arrivalMin
    } else {
      arrMin = 0
    }

    if (selectedService.startHours) {
      selectedService.startTime = stHr + ':' + stMin + ':' + '00';
    }

    if (selectedService.endHour) {
      selectedService.endTime = endHr + ':' + endMin + ':' + '00';
    }

    if (selectedService.arrivalHour) {
      selectedService.arrivalTime = arrHr + ':' + arrMin + ':' + '00';
    }

    if (parseInt(stMin) > parseInt(endMin)) {
      minutes = (parseInt(endMin) + 60) - stMin
      endHr = endHr - 1
    } else {
      minutes = parseInt(endMin) - parseInt(stMin)
    }
    hours = parseInt(endHr) - parseInt(stHr);
    selectedService.durationHour = hours;
    selectedService.durationMinutes = minutes;


    setSelectedItem({ selectedService });
  };
  const onChangePickUpTimeZone = (e) => {
    const target = e.target;
    selectedService.timeZoneId = target.value;
    setSelectedItem({ selectedService });
  };
  const onTabRadioAptChangeHandler = event => {
    selectedService.aptAMPM = event.target.value;
    setSelectedItem({ selectedService });
  };
  const onTabRadiodrpChangeHandler = event => {
    selectedService.drpAMPM = event.target.value;
    setSelectedItem({ selectedService });
  };
  const onChangeDropOffTimezone = (e) => {
    const target = e.target;
    selectedService.drptimeZoneId = target.value;
    setSelectedItem({ selectedService });
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subService'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerTrnType(e)}
                placeholder="Choose..."
                value={selectedService?.transportationCategoryId || ''}
                className={selectedService?.transportationCategoryId ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {transportCategory.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              // value={poNum || ''}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Appt Type: </Label>
            <AppTooltip text={'Appointment Type is a required field'} id={'appt'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAppType(e)}
                placeholder="Choose..."
                value={selectedService?.apptTypeId || ''}
                className={selectedService?.apptTypeId ? '' : "error"}
                id="apptType"
              >
                <option value="default">Choose...</option>
                {appointmentType.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                type="checkbox"
                name="recurring"
                onChange={handleInputChange}
                checked={selectedService?.recurring}
              />{' '}
              Recurring Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date oF Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'dos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>

          <Col xs="1"></Col>
        </Row>

      </FormGroup>
    </>
  );
};

Fast360TRN.propTypes = {
  transportCategory: PropTypes.array,
  appointmentType: PropTypes.array,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    transportCategory: initialData.transportCategory,
    appointmentType: initialData.appointmentType,
    userId: user.id,
    timeZone: initialData.timeZone,
  };
};

const mapDispatchToProps = dispatch => ({
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360TRN);
