import React, { Component } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { SelectableCell } from './SelectableCell';
import { ClickableCell } from './ClickableCell';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { isThisQuarter } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authSelectors } from 'modules/auth/store';
import {
    faAsterisk,
    faCircle,
    faEdit,
    faEnvelope,
    faExclamation,
    faFileUpload,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { Input, CustomInput } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import {
    fast360Operations,
    fast360Selectors,
    fast360Actions,
} from '../../../modules/fast-360/store';
import Fast360Notes from '../../../modules/fast-360/components/Fast360Notes';
import Fast360Email from '../../../modules/fast-360/components/Fast360Email';
import Fast360FileModal from 'modules/fast-360/components/Fast360FileModal';
import { shortToDatabaseTable } from 'modules/fast-360/utils/dataUtils';


class Fast360VendorTableBodyRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
            isOpen: false,
            selectedDec: '',
            rowIndex: null,
            flag: 0,
            checked: false,
            notesClicked: false,
            fileClicked: false,
            isOpenModalFiles: false
        };
    }
    toggleSelectedRow = (id, checked) => {
        this.setState(prevState => {
            return checked
                ? this.addRowFromState(prevState, id)
                : this.removeRowFromState(prevState, id);
        }, this.executeSelectHandler);
    };

    removeRowFromState = (state, id) => {
        return this.updateSelectedRows(
            state,
            state.selectedRows.filter(row => row !== id)
        );
    };

    addRowFromState = (state, id) => {
        return this.updateSelectedRows(state, state.selectedRows.concat(id));
    };

    updateSelectedRows = (state, selectedRows) => {
        // this.props.setSelectedBills(selectedRows)
        return {
            ...state,
            selectedRows,
        };
    };

    executeSelectHandler = () => {
        const selectedRows = this.state.selectedRows.map(id => {
            return this.props.data[id];
        });
        // this.props.selectHandler(selectedRows);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ selectedRows: [] });
            nextProps.data.map(item => {
                if (item.confNo == nextProps.dataObject.confNo)
                    this.setState({ checked: item.selected });
            });
        }
    }
    handleDblClick = () => {
        const { FK_referral_id } = this.props.dataObject;
        let postPayload = {
            "functionName": "loadReferral",
            "zebraUserId": this.props.currentUserId,
            "referral_id": FK_referral_id
        }
        this.props.loadFromWorkList(postPayload);
        this.props.dblClickWorklist(true);
    }

    onRowClicked = (dataObject, rowIndex, event) => {
        if (event.target.tagName === 'TD') {
            this.props.rowClickHandler(dataObject);
            this.setState({
                highlightedRow: rowIndex,
            });
        }
    };

    renderTooltip = apptType => {
        if (
            (apptType != '' && apptType == 'FCE') ||
            apptType == 'IME' ||
            apptType == 'QME' ||
            apptType == 'AME' ||
            apptType == 'Deposition'
        ) {
            return apptType;
        } else return 'High Priority';
    };
    onCheckboxChangeHandler = e => {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.setSelectedRecords(
                this.props.data[this.props.rowIndex],
                this.state.checked
            );
        });
    };

    handleNotesClick = () => {
        this.setState({ notesClicked: !this.state.notesClicked });
    };
    handleEmailClick = () => {
        this.setState({ emailClicked: !this.state.emailClicked });
    }
    handleFileClick = () => {
        this.setState({ fileClicked: !this.state.fileClicked });
        this.setState({isOpenModalFiles: !this.state.isOpenModalFiles})
    }

    render() {
        const rowIndex = this.props.rowIndex;
        const dataObject = this.props.dataObject;
        return (
            <>
                {this.state.notesClicked && (
                    <Fast360Notes
                        clicked={this.handleNotesClick}
                        refId={this.props.dataObject.FK_referral_id}
                        tableName={this.props.dataObject.chr_reference_table}
                        serviceId={this.props.dataObject.int_reference_table_id}
                        table={this.props.dataObject.chr_reference_table ? shortToDatabaseTable(this.props.dataObject.chr_reference_table) : ''}
                        dataObject={dataObject}
                        zebraId={this.props.zebraId}
                    />
                )}
                {this.state.emailClicked && (
                    <Fast360Email
                        clicked={this.handleEmailClick}
                        dataObject={dataObject}                        
                        refId={this.props.dataObject.FK_referral_id}
                        tableName={this.props.dataObject.chr_reference_table}
                        serviceId={this.props.dataObject.int_reference_table_id}
                        zebraId={this.props.zebraId}
                    />
                )}
                {this.state.fileClicked && (
                    <Fast360FileModal
                        isOpen={this.state.isOpenModalFiles}
                        clicked={this.handleFileClick}
                        dataObject={dataObject}                        
                        refId={this.props.dataObject.FK_referral_id}
                        tableName={this.props.dataObject.chr_reference_table}
                        serviceId={this.props.dataObject.int_reference_table_id}
                        zebraId={this.props.zebraId}
                    />
                )}
                <tbody>
                    <tr
                        key={rowIndex}
                        style={
                            this.props.highlightedRow === rowIndex
                                ? { backgroundColor: '#cdf0f9' }
                                : null
                        }
                        onDoubleClick={this.handleDblClick}
                        onClick={
                            this.props.isRowClickable
                                ? event => this.onRowClicked(dataObject, rowIndex, event)
                                : null
                        }
                    >
                        {this.props.isSelectable ? (
                            <SelectableCell
                                rowIndex={rowIndex}
                                selectHandler={this.toggleSelectedRow}
                                selected={this.state.selectedRows.includes(rowIndex)}
                            />
                        ) : null}
                        {this.props.columns.map((column, columnIndex) => {
                            if (column.render) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {column.render(dataObject, rowIndex)}
                                        </th>
                                    );
                                }

                                return (
                                    <td key={columnIndex}>
                                        {column.render(dataObject, rowIndex)}
                                    </td>
                                );
                            }

                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Priority') {
                                    return (
                                        <td>
                                            {(dataObject['serviceRush'] === 1 ||
                                                dataObject['bol_rush']) && (
                                                    <span
                                                        style={{
                                                            marginRight: '12px',
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="red-text"
                                                            icon={faExclamation}
                                                        />
                                                    </span>
                                                )}
                                            {dataObject['chr_priority'] === 'High' && (
                                                <>
                                                    {' '}
                                                    <FontAwesomeIcon
                                                        className="red-text"
                                                        icon={faAsterisk}
                                                        data-for="tooltip"
                                                        data-tip={this.renderTooltip(
                                                            dataObject.appointmentType
                                                        )}
                                                        data-iscapture="true"
                                                    />
                                                    <ReactTooltip id="tooltip" />
                                                </>
                                            )}
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'OOC') {
                                    let currentDate = moment(new Date()).format('YYYY-MM-DD');
                                    let followupDate = moment(
                                        dataObject.currentTaskCreateDate
                                    ).format('YYYY-MM-DD');
                                    let show = moment(followupDate).isBefore(currentDate);
                                    return (
                                        <td>
                                            {show && (
                                                <span
                                                    style={{
                                                        marginLeft: '12px',
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className="red-text"
                                                        icon={faCircle}
                                                    />
                                                </span>
                                            )}
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Notes') {
                                    return (
                                        <td>
                                            <span
                                                style={{
                                                    marginLeft: '12px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={this.handleNotesClick}
                                                />
                                            </span>
                                        </td>
                                    );
                                }
                            }



                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Files') {
                                    return (
                                        <td>
                                            <span
                                                style={{
                                                    marginLeft: '12px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                icon={faFileUpload}
                                                onClick={this.handleFileClick}/>
                                            </span>
                                        </td>
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Renewal') {
                                    return <td>{dataObject['isRenewal'] == 1 ? 'Yes' : 'No'}</td>;
                                }
                            }

                            //MM/DD/YYYY hh:mm am/pm
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Follow Up Date') {
                                    return (
                                        !column.hide && (
                                            <td>
                                                {moment(dataObject.FollowupDate).format('L')}{' '}
                                                {moment(dataObject.FollowupDate).format('hh:mm a')}
                                            </td>
                                        )
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Last Note Updated') {
                                    return (
                                        !column.hide && (
                                            <td>
                                                {moment(dataObject.lastNoteModifiedDate).format('L')}{' '}
                                                {moment(dataObject.lastNoteModifiedDate).format('LT')}
                                            </td>
                                        )
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'Date of Service') {
                                    return <td>{moment(dataObject.serviceDate).format('L')}</td>;
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == 'V.A') {
                                    return (
                                        !column.hide && (
                                            <td>
                                                {dataObject.vendorCount > 0 && (
                                                    <AppTooltip
                                                        text={'Vendor Suggestion Complete'}
                                                        id="vendorCount"
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </AppTooltip>
                                                )}
                                            </td>
                                        )
                                    );
                                }
                            }
                            if (this.props.returnData === dataObject) {
                                if (column.header == '') {
                                    return (
                                        <td>
                                            <div
                                                style={{
                                                    marginLeft: '12px',
                                                }}
                                            >
                                                <CustomInput
                                                    id={`${this.props.rowIndex}_checkbox`}
                                                    type="checkbox"
                                                    checked={this.state.checked}
                                                    onChange={e => this.onCheckboxChangeHandler(e)}
                                                />
                                            </div>
                                        </td>
                                    );
                                }
                            }

                            if (this.props.returnData === dataObject.fields) {
                                if (column.rowKey) {
                                    return (
                                        <th key={columnIndex} scope="row">
                                            {dataObject[column.field] || ''}
                                        </th>
                                    );
                                }

                                return (
                                    <td key={columnIndex}>
                                        {dataObject.fields[column.field] ||
                                            dataObject.fields.dateRange[column.field] ||
                                            ''}
                                    </td>
                                );
                            }
                        })}
                        {this.props.isClickable ? (
                            <ClickableCell
                                clickHandler={() => this.props.clickHandler(dataObject)}
                                location="/administration/user"
                            />
                        ) : null}
                    </tr>
                </tbody>
            </>
        );
    }
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        workList: state.FAST360.fast360Reducer.updateUsersWorkList ? state.FAST360.fast360Reducer.updateUsersWorkList : [],


    };
};

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    loadFromWorkList: (data) => dispatch(fast360Operations.loadFromWorkList(data)),
});

Fast360VendorTableBodyRow.propTypes = AppTableBodyPropType;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fast360VendorTableBodyRow);
