import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppInput from 'shared/components/input/AppInput';
import SearchableSelect from 'shared/components/input/SearchableSelect';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

class AddUAndCSetupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleModalOpen: false,
      description: '',
      lineOfBusiness: '',
      percent: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    };
  }

  onChangeHandler = object => {
    this.setState({
      [object.key]: object.value,
    });
  };

  onAddNewData = () => {
    const {
      description,
      lineOfBusiness,
      percent,
      state,
      effective,
      terminated,
    } = this.state;

    if (lineOfBusiness === '') {
      return;
    }

    const siteId = this.props.siteDetails.id || 0;
    if (siteId === 0) {
      return;
    }

    const id =
      Math.min(
        Math.min(...this.props.data.map(item => (item.fields || {}).id || 0)),
        0
      ) - 1;
    let newRow = {
      fields: {
        id,
        name: description,
        lob: {
          fields: {
            lob: lineOfBusiness,
          },
          requiredFields: [],
        },
        amount: percent,
        state: state,
        dateRange: {
          effDate: effective,
          termDate: terminated,
        },
      },
      requiredFields: [],
    };
    this.props.addNewData(newRow);
    this.stateSimpleModalToggle();
  };

  stateSimpleModalToggle = () => {
    this.setState(prevState => ({
      simpleModalOpen: !prevState.simpleModalOpen,
    }));
  };

  resetSimpleModal = () => {
    this.setState({
      description: '',
      lineOfBusiness: '',
      percent: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    });
  };

  getSimpleModalFooter() {
    return (
      <Button type="button" onClick={this.onAddNewData}>
        ADD
      </Button>
    );
  }

  getSimpleModalBody() {
    const { stateList, lobList } = this.props;
    return (
      <>
        <Row form>
          <Col lg="8">
            <FormGroup>
              <Label for="lbDescription">Description</Label>
              <AppInput
                type="text"
                changeHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                id="description"
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="lbLineOfBusiness">Line of Business</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="lineOfBusiness"
                data={lobList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="3">
            <FormGroup>
              <Label for="lbPercent">Percent</Label>
              <AppInput
                type="text"
                changeHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                id="percent"
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="lbState">State</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="key"
                id="state"
                data={stateList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="lbEffective">Effective</Label>
              <AppDateInput
                id="effective"
                onChange={value =>
                  this.onChangeHandler({ key: 'effective', value })
                }
                selected={this.state.effective}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="lbTerminated">Terminated</Label>
              <AppDateInput
                id="terminated"
                onChange={value =>
                  this.onChangeHandler({ key: 'terminated', value })
                }
                selected={this.state.terminated}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        <PopupModal
          content={this.getSimpleModalBody()}
          title="Add Site Detail"
          externalToggle={this.stateSimpleModalToggle}
          isOpen={this.state.simpleModalOpen}
          footerContent={this.getSimpleModalFooter()}
          onClosed={this.resetSimpleModal}
          size="lg"
        />
        <Button type="button" onClick={this.stateSimpleModalToggle}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </>
    );
  }
}

AddUAndCSetupButton.propTypes = {
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  addNewData: PropTypes.func.isRequired,
  siteDetails: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const initialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  return {
    stateList: initialData.clientComboBoxes.stateList,
    lobList: initialData.clientComboBoxes.lobList,
    siteDetails: clientMaintenanceState.siteDetails,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addNewData: data => dispatch(billMaintenanceActions.addUAndCSetup(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUAndCSetupButton);
