import PropTypes from "prop-types";

export const AppModule = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string
    }))
});
