import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import PopupModal from 'shared/components/PopupModal';
import { commonOperations } from 'common/store';
import { assign, isEmpty } from 'lodash';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  primaryData,
  rentalUnit,
} from '../utils/constantUtils';
import { array } from 'prop-types';

const MODDetailModal = ({
  isOpen,
  onClose,
  patientInfo,
  updateAudInfoTable,
  alert,
  rowInfo,
  audInfo,
  serviceIdFromRef,
  saveMODHCPC,
  serviceId,
  referralId,
  module,
  isFromLanding,
  userId,
  newServiceId,
  copiedService,
  loadFromWorkList
}) => {
  const [openHCPCServiceDetail, setOpenHCPCServiceDetail] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isRentExt, setIsRentExt] = useState(false);
  const [formHCPCFields, setFormHCPCFields] = useState({
    itemName: '',
    serviceDesc: '',
    mod1: '',
    hhcService: patientInfo && patientInfo.hhcService,
    qty: 1,
    costPercentSavings: 25,
    venUnitCost: '',
    custUnitCost: '',
    msrp: '',
    venBuyAmt: '',
    custBuyAmt: '',
    startDate: '',
    endDate: '',
    duration: '',
    rentUnit: '',
    finalBilledCharge: `${0.0}`,
    finalCostSavingPercent: `${0.0}`,
  });

  const toggle = () => {
    onClose();
    resetFormData();
  };

  const showConfirmationModalState = () => {
    resetFormData();
    setShowConfirmationModal(!showConfirmationModal);
  };

  const confirmLogoutState = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const calcMargin = (custCost, venCost) => {
    let val;
    val = Number(((custCost - venCost) / custCost) * 100).toFixed(2);
    return `${val}%`;
  };

  const saveFunction = fromNewRecord => {
    const newRow = {
      id: 0,
      dos: patientInfo && patientInfo.audZip,
      primaryMod: formHCPCFields.mod1,
      itemName: formHCPCFields.itemName,
      description: formHCPCFields.serviceDesc,
      hhcService: patientInfo && patientInfo.hhcService,
      rentalUnit: formHCPCFields.rentUnit,
      duration: formHCPCFields.duration,
      startExtDate: dateFormat(formHCPCFields.startDate),
      endExtDate: dateFormat(formHCPCFields.endDate),
      vendBuyOut:
        formHCPCFields.venBuyAmt === '' ? 0.0 : formHCPCFields.venBuyAmt,
      custBuyOut:
        formHCPCFields.custBuyAmt === '' ? 0.0 : formHCPCFields.custBuyAmt,
      rentalExt: isRentExt,
      PercentAdjustment: Number(formHCPCFields.costPercentSavings),
      finalCostSavingPercent: isNaN(Number(formHCPCFields.finalCostSavingPercent))
        ? Number(
          formHCPCFields.finalCostSavingPercent.replace('%', '')
        ).toFixed(2) : Number(
          formHCPCFields.finalCostSavingPercent
        ).toFixed(2),
      msrpAmt: Number(
        formHCPCFields.msrp === undefined ? 0.0 : formHCPCFields.msrp
      ).toFixed(2),
      BilledCharges: isNaN(Number(
        formHCPCFields.finalBilledCharge)) ?
        Number(
          formHCPCFields.finalBilledCharge.replace('$', '')
        ).toFixed(2) :
        Number(
          formHCPCFields.finalBilledCharge).toFixed(2),
      quantity: Number(formHCPCFields.qty === undefined ? 0 : formHCPCFields.qty),
      vendorCost: Number(formHCPCFields.venUnitCost),
      custCost: Number(formHCPCFields.custUnitCost),
      vendTotal:
        Number(formHCPCFields.venUnitCost) *
        Number(formHCPCFields.qty).toFixed(2),
      TotalAllowance:
        Number(formHCPCFields.custUnitCost) *
        Number(formHCPCFields.qty).toFixed(2),
      margin: calcMargin(
        Number(
          formHCPCFields.custUnitCost === undefined
            ? 0
            : formHCPCFields.custUnitCost
        ) * Number(formHCPCFields.qty).toFixed(2),
        Number(
          formHCPCFields.venUnitCost === undefined
            ? 0
            : formHCPCFields.venUnitCost
        ) * Number(formHCPCFields.qty).toFixed(2)
      ),
    };
    const cloneAudInfo =
      audInfo && audInfo[patientInfo.module + patientInfo.serviceId]
        ? audInfo[patientInfo.module + patientInfo.serviceId]
        : [];
    let formInfo = [];
    if (rowInfo && fromNewRecord) {
      newRow.id = rowInfo?.id;
      newRow.rental = {
        rentalExt: newRow?.rentalExt,
        rentalUnit: newRow?.rentalUnit,
        custBuyOut: newRow?.custBuyOut,
        vendBuyOut: newRow?.vendBuyOut,
        startExtDate: newRow?.startExtDate,
        endExtDate: newRow?.endExtDate
      }
      cloneAudInfo.splice(rowInfo.index, 1, newRow);
      formInfo = cloneAudInfo;
      newRow.costSaving = {
        id: rowInfo?.fieldId,
        ServiceId: serviceIdFromRef,
        DetailId: rowInfo?.DetailId,
        PercentAdjustment: newRow?.PercentAdjustment,
        BilledCharges: newRow?.BilledCharges,
        TotalAllowance: newRow?.TotalAllowance,
        fields: {},
        fields: {
          id: rowInfo?.fieldId
        }
      }
      newRow.functionName = "saveModificationDetail"
      newRow.zebraUserId = userId;
      newRow.serviceId = serviceId;
      let saveRow = [newRow];
      saveRow.forEach(i => {
        delete i.PercentAdjustment;
        delete i.clientUniCost;
        delete i.hcpcCodeDesc;
        delete i.hcpcCode;
        delete i.hcpcDesc;
        delete i.margin;
        delete i.venRealUniCost;
        delete i.vendTotal;
        delete i.billCharges;
        delete i.totAllow;
        delete i.serviceIdFromRef;
      });
      if (isFromLanding && newServiceId === undefined && copiedService === undefined) {
        let postPayload = {
          zebraUserId: userId,
          audiology_id: serviceId,
          tableAbbrv: module,
          transport_type: 0
        };
        saveMODHCPC(...saveRow, postPayload)
        updateAudInfoTable({
          ...audInfo,
          [patientInfo.module + patientInfo.serviceId]: formInfo,
        });
        onClose(undefined, true);
        resetFormData();
      } else {
        updateAudInfoTable({
          ...audInfo,
          [patientInfo.module + patientInfo.serviceId]: formInfo,
        });
        onClose(undefined, true);
        resetFormData();
      }
    } else {
      const cloneAudInfo =
      audInfo && audInfo[patientInfo.module + patientInfo.serviceId]
        ? audInfo[patientInfo.module + patientInfo.serviceId]
        : [];
     // formInfo = array.isArray ? cloneAudInfo?.concat([newRow]) : [];
      formInfo = cloneAudInfo?.concat([newRow]) || [];
      newRow.id = 0;
      newRow.rental = {
        rentalExt: newRow?.rentalExt,
        rentalUnit: newRow?.rentalUnit,
        custBuyOut: newRow?.custBuyOut,
        vendBuyOut: newRow?.vendBuyOut,
        startExtDate: newRow?.startExtDate,
        endExtDate: newRow?.endExtDate
      }
      newRow.costSaving = {
        id: 0,
        ServiceId: serviceIdFromRef,
        DetailId: 0,
        PercentAdjustment: newRow?.PercentAdjustment,
        BilledCharges: newRow?.BilledCharges,
        TotalAllowance: newRow?.TotalAllowance,
        fields: {},
        fields: {
          id: 0
        }
      }
      newRow.functionName = "saveModificationDetail";
      newRow.zebraUserId = userId;
      newRow.serviceId = serviceId;
      let saveRow = [newRow];
      saveRow.forEach(i => {
        delete i.PercentAdjustment;
        delete i.clientUniCost;
        delete i.hcpcCodeDesc;
        delete i.hcpcCode;
        delete i.hcpcDesc;
        delete i.margin;
        delete i.venRealUniCost;
        delete i.vendTotal;
        delete i.billCharges;
        delete i.totAllow;
        delete i.serviceIdFromRef;
      });
      let postPayload = {
        zebraUserId: userId,
        audiology_id: serviceId,
        tableAbbrv: module,
        transport_type: 0
      };
      if (isFromLanding && newServiceId === undefined) {
        let postPayload = {
          zebraUserId: userId,
          audiology_id: serviceId,
          tableAbbrv: module,
          transport_type: 0
        };
        saveMODHCPC(...saveRow, postPayload)
        updateAudInfoTable({
          ...audInfo,
          [patientInfo.module + patientInfo.serviceId]: formInfo,
        });
        onClose(undefined, true);
        resetFormData();
      } else {
        updateAudInfoTable({
          ...audInfo,
          [patientInfo.module + patientInfo.serviceId]: formInfo,
        });
        onClose(undefined, true);
        resetFormData();
      }
    }
    updateAudInfoTable({
      ...audInfo,
      [patientInfo.module + patientInfo.serviceId]: formInfo,
    });
    // onClose();
    resetFormData();
  };

  const dateFormat = data => {
    if (data) {
      let dos = data && data.split('/');
      return dos[2] + '-' + dos[0] + '-' + dos[1];
    }
    return;
  };

  const openHCPCService = (isSaving, fromNewRecord) => {
    if (isSaving && isFromLanding) {
      if (formHCPCFields.qty === '' || formHCPCFields.qty <= 0) {
        alert('Quantity must be greater than 0.');
      } else if (formHCPCFields.mod1 === 'Rental') {
        if (formHCPCFields.startDate === '' || formHCPCFields.endDate === '') {
          alert('Rental dates are required.');
        } else if (
          formHCPCFields.startDate &&
          formHCPCFields.endDate &&
          formHCPCFields.rentUnit === ''
        ) {
          alert(
            'You must enter a unit/frequency in order to save this detail line.'
          );
        } else {
          saveFunction(fromNewRecord);
        }
      } else {
        saveFunction(fromNewRecord);
      }
    } else if (isSaving && !isFromLanding) {
      if (formHCPCFields.qty === '' || formHCPCFields.qty <= 0) {
        alert('Quantity must be greater than 0.');
      } else if (formHCPCFields.mod1 === 'Rental') {
        if (formHCPCFields.startDate === '' || formHCPCFields.endDate === '') {
          alert('Rental dates are required.');
        } else if (
          formHCPCFields.startDate &&
          formHCPCFields.endDate &&
          formHCPCFields.rentUnit === ''
        ) {
          alert(
            'You must enter a unit/frequency in order to save this detail line.'
          );
        } else {
          saveFunction(fromNewRecord);
        }
      } else {
        saveFunction(fromNewRecord);
      }
    } else {
      toggle();
    }
  };

  const confirmationModalContent = () => {
    return (
      <span>
        Warning: This HCPC already exists. Are you sure you want to proceed?
      </span>
    );
  };

  const resetFormData = () => {
    setFormHCPCFields({
      qty: 1,
      revenueCode: '',
      costPercentSavings: 25,
      vendorDisc: '',
      custDisc: '',
      venFlatRate: 0,
      clientFlatRate: 0,
      fs: '',
      uc: '',
      mca: '',
      owcp: '',
      msrp: '',
      flat: '',
      calcValue: '',
      calOption: '',
      calcData: '',
      totValue: '',
      startDate: '',
      endDate: '',
      duration: '',
      rentUnit: '',
      venBuyAmt: '',
      custBuyAmt: '',
      finalBilledCharge: `${0.0}`,
      finalCostSavingPercent: `${0.0}`,
    });
    setIsRentExt(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (rowInfo) {
        setFormHCPCFields({
          id: rowInfo.id || 0,
          itemName: rowInfo.itemName,
          serviceDesc: rowInfo.description,
          mod1: rowInfo.primaryMod,
          qty: rowInfo.quantity,
          hhcService: rowInfo.hhcService,
          costPercentSavings: rowInfo.PercentAdjustment,
          msrp: rowInfo.msrpAmt,
          venUnitCost: rowInfo.vendorCost,
          custUnitCost: rowInfo.custCost,
          finalBilledCharge: rowInfo.BilledCharges,
          finalCostSavingPercent: rowInfo.PercentAdjustment,
          rentUnit: rowInfo.rental?.rentalUnit,
          duration: rowInfo.duration,
          startDate: dateRowInfoFormat(rowInfo.rental?.startExtDate),
          endDate: dateRowInfoFormat(rowInfo.rental?.endExtDate),
          venBuyAmt: rowInfo.rental?.vendBuyOut,
          custBuyAmt: rowInfo.rental?.custBuyOut,
        });
        setIsRentExt(rowInfo.rental?.rentalExt);
      } else {
        setFormHCPCFields({
          ...formHCPCFields,
          hhcService: patientInfo.hhcService,
        });
      }
      setOpenHCPCServiceDetail(true);
    } else {
      setOpenHCPCServiceDetail(false);
    }
  }, [isOpen]);

  const onChangeHandlerPrimary = (id, value) => {
    setFormHCPCFields({ ...formHCPCFields, [id]: value });
    if (id === 'rentUnit') {
      daysBetween(value);
    }
  };

  useEffect(() => {
    if (
      formHCPCFields.costPercentSavings ||
      formHCPCFields.qty ||
      formHCPCFields.duration
    ) {
      if (
        Number(formHCPCFields.msrp) === 0 ||
        formHCPCFields.msrp === '' ||
        formHCPCFields.msrp === undefined ||
        Number(formHCPCFields.msrp).toFixed(2) === 0.0 ||
        Number(formHCPCFields.msrp) === 0.0
      ) {
        calculateBillCharge(
          formHCPCFields.custUnitCost,
          formHCPCFields.qty,
          formHCPCFields.costPercentSavings,
          'custUnitCost'
        );
      } else {
        calculateBillCharge(
          formHCPCFields.msrp,
          formHCPCFields.qty,
          formHCPCFields.costPercentSavings,
          'msrp'
        );
      }
      setFormHCPCFields({ ...formHCPCFields, qty: formHCPCFields.qty });
    }
  }, [
    formHCPCFields.costPercentSavings,
    formHCPCFields.qty,
    formHCPCFields.duration,
  ]);

  const dateRowInfoFormat = data => {
    if (data) {
      let dos = data && data.split('-');
      return dos[1] + '/' + dos[2] + '/' + dos[0];
    }
    return;
  };

  const handleVendChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFormHCPCFields({ ...formHCPCFields, [name]: value });
  };

  const handleCustChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'qty') {
      setFormHCPCFields({ ...formHCPCFields, [name]: value });
    } else if (name === 'custUnitCost') {
      setFormHCPCFields({ ...formHCPCFields, [name]: value });
      calculateBillCharge(
        value,
        Number(formHCPCFields.qty),
        formHCPCFields.costPercentSavings,
        name
      );
    } else if (name === 'msrp') {
      setFormHCPCFields({ ...formHCPCFields, [name]: value });
      calculateBillCharge(
        value,
        Number(formHCPCFields.qty),
        formHCPCFields.costPercentSavings,
        name
      );
    } else {
      setFormHCPCFields({ ...formHCPCFields, [name]: value });
    }
  };

  const calculateBillCharge = (custCost, qty, percentage, name) => {
    let billVal;
    let costPer;
    if (name === 'custUnitCost') {
      if (
        (Number(custCost) === 0 ||
          custCost === '' ||
          custCost === undefined ||
          Number(custCost).toFixed(2) === 0.0 ||
          Number(custCost) === 0.0) &&
        (Number(formHCPCFields.msrp) === 0 ||
          formHCPCFields.msrp === '' ||
          formHCPCFields.msrp === undefined ||
          Number(formHCPCFields.msrp).toFixed(2) === 0.0 ||
          Number(formHCPCFields.msrp) === 0.0)
      ) {
        formHCPCFields.finalCostSavingPercent = '';
        setFormHCPCFields({
          ...formHCPCFields,
          [name]: custCost,
          finalBilledCharge: 0.0,
        });
      } else if (
        custCost &&
        (formHCPCFields.msrp === '' ||
          formHCPCFields.msrp === 0 ||
          formHCPCFields.msrp === undefined ||
          formHCPCFields.msrp === '0.00' ||
          formHCPCFields.msrp === 0.0)
      ) {
        billVal = Number(
          Number(Number(custCost) * Number(qty)) /
          ((100 - Number(percentage)) / 100)
        ).toFixed(2);
        costPer = percentage;
        formHCPCFields.finalBilledCharge = `$${billVal}`;
        formHCPCFields.finalCostSavingPercent = `${percentage}%`;
        setFormHCPCFields({ ...formHCPCFields, [name]: custCost });
      }
    } else if (name === 'msrp') {
      if (
        Number(custCost) === 0 ||
        custCost === '' ||
        custCost === undefined ||
        Number(custCost).toFixed(2) === 0.0 ||
        Number(custCost) === 0.0
      ) {
        billVal = Number(
          Number(Number(formHCPCFields.custUnitCost) * Number(qty)) /
          ((100 - Number(percentage)) / 100)
        ).toFixed(2);
        costPer = percentage;
        formHCPCFields.finalBilledCharge = `$${billVal}`;
        formHCPCFields.finalCostSavingPercent = `${percentage}%`;
        setFormHCPCFields({ ...formHCPCFields, [name]: custCost });
      } else {
        billVal = Number(Number(Number(custCost) * Number(qty))).toFixed(2);
        let totalAllowance = Number(
          Number(formHCPCFields.custUnitCost) * Number(qty)
        ).toFixed(2);
        formHCPCFields.finalBilledCharge = `$${billVal}`;
        costPer = Number(
          (Number(billVal - totalAllowance) / billVal) * 100
        ).toFixed(2);
        formHCPCFields.finalCostSavingPercent = `${costPer}%`;
        setFormHCPCFields({ ...formHCPCFields, [name]: custCost });
      }
    }
  };

  const onChangeHandler = object => {
    if (object.key === 'startDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.startDate =
        mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    }
    if (object.key === 'endDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.endDate =
        mon + '/' + object.value.getDate() + '/' + object.value.getFullYear();
    }
    if (formHCPCFields.startDate && formHCPCFields.endDate) {
      daysBetween(formHCPCFields.rentUnit);
    }
    setFormHCPCFields({ ...formHCPCFields });
  };

  const calDuration = (diff, val) => {
    formHCPCFields.qty = diff;
    setFormHCPCFields({ ...formHCPCFields, qty: diff, rentUnit: val });
  };

  const daysBetween = value => {
    const firstDate = moment(formHCPCFields.startDate);
    const secondDate = moment(formHCPCFields.endDate);
    // if (secondDate && secondDate.isValid()) {
    //   if (secondDate.isBefore(firstDate)) {
    //     throw 'End date must be greated than start date.';
    //   }
    // }
    if (value === 'Day') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      formHCPCFields.duration = `${diffDays} Days`;
      if (diffDays >= 0) {
        calDuration(diffDays, value);
      }
      return `${diffDays} Days`;
    } else if (value === 'Week') {
      let diffWeeks;
      diffWeeks = Math.round(
        Math.abs((firstDate - secondDate) / (7 * 24 * 60 * 60 * 1000))
      );
      formHCPCFields.duration = `${diffWeeks} Weeks`;
      if (diffWeeks >= 0) {
        calDuration(diffWeeks, value);
      }
      return `${diffWeeks} Weeks`;
    } else if (value === 'Month') {
      let numberOfMonths;
      numberOfMonths = Math.floor(
        (secondDate - firstDate) / (1000 * 60 * 60 * 24 * 30)
      );
      formHCPCFields.duration = `${numberOfMonths} Months`;
      if (numberOfMonths >= 0) {
        calDuration(numberOfMonths, value);
      }
      return `${numberOfMonths} Months`;
    }
    setFormHCPCFields({
      ...formHCPCFields,
      duration: formHCPCFields.duration,
      rentUnit: value,
    });
    return '';
  };

  const rentExtHandler = rent => {
    if (rent === 'yes') {
      setIsRentExt(true);
    } else if (rent === 'no') {
      setIsRentExt(false);
    } else {
      return '';
    }
  };

  const renderRentForm = () => {
    if (formHCPCFields.mod1 !== undefined) {
      if (formHCPCFields.mod1 === 'Rental') {
        return (
          <>
            <Row style={{ marginTop: 29 }}>
              <Col sm="12" md={{ size: 3, offset: 5 }}>
                <Label>
                  <b>Rental Info</b>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col className="text-right" sm="3" style={{ marginTop: 5 }}>
                <Label>Vend. Buyout Amt Per Item:</Label>
              </Col>
              <Col sm="3">
                <Input
                  name="venBuyAmt"
                  onChange={handleVendChange}
                  value={formHCPCFields.venBuyAmt}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-right" sm="3" style={{ marginTop: 5 }}>
                <Label>Cust. Buyout Amt Per Item:</Label>
              </Col>
              <Col sm="3" style={{ marginTop: 5 }}>
                <Input
                  name="custBuyAmt"
                  onChange={handleVendChange}
                  value={formHCPCFields.custBuyAmt}
                />
              </Col>
            </Row>
            <Row style={{ margin: 7 }}>
              <Col className="text-right" sm="3">
                <Label>Rental Ext:</Label>
              </Col>
              <Col>
                <Button
                  type="button"
                  className={
                    'btn btn-secondary ' +
                    classNames({ 'rent-button-active': isRentExt })
                  }
                  onClick={() => rentExtHandler('yes')}
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => rentExtHandler('no')}
                  className={
                    'btn btn-secondary ' +
                    classNames({ 'rent-button-active': !isRentExt })
                  }
                >
                  No
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Unit:</Label>
              </Col>
              <Col sm="2">
                <AppInput
                  id="rentUnit"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.rentUnit || ''}
                  data={rentalUnit}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>Duration:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="duration"
                  onChange={daysBetween}
                  value={formHCPCFields.duration}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Start Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="startDate"
                  onChange={value =>
                    onChangeHandler({ key: 'startDate', value })
                  }
                  value={formHCPCFields.startDate || ''}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>End Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="endDate"
                  onChange={value => onChangeHandler({ key: 'endDate', value })}
                  value={formHCPCFields.endDate || ''}
                />
              </Col>
            </Row>
          </>
        );
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={openHCPCServiceDetail}>
        <ModalHeader toggle={toggle}>
          <Row className="claimant-info">
            <Col>
              {patientInfo?.modService && patientInfo?.modService === 'Home'
                ? 'Home Modification'
                : 'Vehicle Modification'}
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12" md={{ size: 3, offset: 5 }}>
              <Label>
                <b>Modification Details</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="1" md={{ offset: 1 }}>
              <Row>
                <Label>Qty</Label>
                <Input
                  name="qty"
                  type="number"
                  min={1}
                  onChange={handleCustChange}
                  value={formHCPCFields.qty}
                />
              </Row>
            </Col>
            <Col sm="3">
              <Row style={{ marginLeft: 10 }}>
                <Label>Modifier: </Label>
                <AppInput
                  id="mod1"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.mod1 || ''}
                  data={primaryData}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" sm="6" md={{ offset: 1 }}>
              <Row>
                <Label>Item Name:</Label>
                <Input
                  name="itemName"
                  onChange={handleVendChange}
                  value={formHCPCFields.itemName}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" sm="6" md={{ offset: 1 }}>
              <Row>
                <Label>Service Description:</Label>
                <Input
                  name="serviceDesc"
                  type="textarea"
                  onChange={handleVendChange}
                  value={formHCPCFields.serviceDesc}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vend. Cost Per Item:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venUnitCost"
                onChange={handleVendChange}
                value={formHCPCFields.venUnitCost}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>CS Percent %</Label>
            </Col>
            <Col sm="2">
              <Input
                name="costPercentSavings"
                onChange={handleCustChange}
                value={formHCPCFields.costPercentSavings}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Cust. Cost Per Item:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custUnitCost"
                onChange={handleCustChange}
                value={formHCPCFields.custUnitCost}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Billed Charges:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="finalBilledCharge"
                onChange={handleCustChange}
                value={formHCPCFields.finalBilledCharge}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>MSRP:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="msrp"
                onChange={handleCustChange}
                value={formHCPCFields.msrp}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Cost Savings:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="finalCostSavingPercent"
                onChange={handleCustChange}
                value={formHCPCFields.finalCostSavingPercent}
                disabled
              />
            </Col>
          </Row>
          {renderRentForm()}
          <div style={{ float: 'right', marginTop: 7 }}>
            <Button type="button" onClick={() => openHCPCService(true, true)}>
              Save
            </Button>
            {isRentExt && (
              <Button
                style={{ marginLeft: 5 }}
                type="button"
                onClick={() => openHCPCService(true, false)}
              >
                Add New Record
              </Button>
            )}
            <Button
              style={{ marginLeft: 5 }}
              type="button"
              onClick={() => openHCPCService(false)}
            >
              Cancel
            </Button>
          </div>
          <PopupModal
            content={confirmationModalContent()}
            title="Duplicate HCPC:"
            isOpen={showConfirmationModal}
            externalToggle={showConfirmationModalState}
            footerContent={
              <>
                <Button color="secondary" onClick={showConfirmationModalState}>
                  NO
                </Button>
                <Button color="primary" onClick={confirmLogoutState}>
                  YES
                </Button>
              </>
            }
            size="md"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    hcpcList: fast360Info.hcpcList,
    patientInfo: fast360Info.patientZip,
    userId: user.id,
    BRTypes: initialData.brTypes,
    defaultPercent: fast360Info.defaultPercent,
    audInfo: fast360Info.audInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  loadHCPC: data => dispatch(fast360Operations.loadHCPC(data)),
  getDefaultCostSavingsPercent: data =>
    dispatch(fast360Operations.getDefaultCostSavingsPercent(data)),
  getDetailLineFee: data => dispatch(fast360Operations.getDetailLineFee(data)),
  saveMODHCPC: (data, req) => dispatch(fast360Operations.saveMODHCPC(data, req)),
  clientFeeLookup: data => dispatch(fast360Operations.clientFeeLookup(data)),
  updateAudInfoTable: data => dispatch(fast360Actions.updateAudInfoTable(data)),
  resetLoadHCPCTable: () => dispatch(fast360Actions.resetLoadHCPCTable()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MODDetailModal);
