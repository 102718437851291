import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Row, Col, FormGroup, Input } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';

import {
  taskListGridSettingName,
  taskListGridSettingModuleId,
} from '../utils/constantUtils';

import GridSettingsModal from './GridSettingsModal';
import TransferBillsModal from './TransferBillsModal';
import ReferralButton from './ReferralButton';
import GroupBySelection from './GroupBySelection';

const ApproveBillsTopNav = ({
  adjusters,
  selectedAdjusterName,
  reviewType,
  groupBy,
  reviewTypeSelection,
  groupBySelection,
  selectedBills,
  bills,
  refresh,
  handleBillReviewTypeChange,
  updateSelectedBills,
  updateGroupBySelection,
  handleUserDisplayChange,
  handleUserChange,
  transferBills,
  settingColumns,
  updateGridSettingsCheckbox,
}) => {
  return (
    <Row>
      <Col lg="2">
        <Row>
          <Col lg="2" style={{ fontSize: '25px' }}>
            <FontAwesomeIcon
              className="blue-text"
              icon="sync"
              style={{ cursor: 'pointer' }}
              onClick={refresh}
            />
          </Col>
          <Col lg="10">
            <Input
              type="select"
              name="reviewType"
              value={reviewType}
              onChange={handleBillReviewTypeChange}
            >
              {reviewTypeSelection.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </Col>
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="adjuster"
            data={adjusters}
            value={selectedAdjusterName}
            displayOption="name"
            returnValue="id"
            onChange={handleUserDisplayChange}
            onSelect={handleUserChange}
            showToolTip={true}
            placeholder="Adjuster"
            isSuggested={true}
            key={reviewType}
          />
        </FormGroup>
      </Col>
      <Col lg="6">
        <Row>
          <Col lg="4">
            <ReferralButton name="approveBillRef" selectedBills={selectedBills} bills={bills} />
          </Col>
          <Col lg="4">
            <GridSettingsModal
              name="approveBillSetting"
              settingColumns={settingColumns}
              updateCheckbox={updateGridSettingsCheckbox}
              gridSettingName={taskListGridSettingName}
              gridSettingModuleId={taskListGridSettingModuleId}
            />
          </Col>
          <Col lg="4">
            <TransferBillsModal
              users={adjusters}
              handleTransferClick={transferBills}
              selectedBills={selectedBills}
            />
          </Col>
        </Row>
      </Col>
      <Col lg="2">
        <FormGroup>
          <GroupBySelection
            groupBy={groupBy}
            groupBySelection={groupBySelection}
            selectedBills={selectedBills}
            updateSelectedBills={updateSelectedBills}
            bills={bills}
            updateGroupBySelection={updateGroupBySelection}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

ApproveBillsTopNav.propTypes = {
  refresh: PropTypes.func.isRequired,
  handleBillReviewTypeChange: PropTypes.func.isRequired,
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  handleUserDisplayChange: PropTypes.func.isRequired,
  handleUserChange: PropTypes.func.isRequired,
  transferBills: PropTypes.func.isRequired,
  adjusters: PropTypes.array.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  reviewTypeSelection: PropTypes.array.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
  settingColumns: PropTypes.array.isRequired,
};

export default ApproveBillsTopNav;
