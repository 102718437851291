import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';

import actions from '../store/actions';

const AVAILABLE_CLIENTS = 'AVAILABLE_CLIENTS';
const LINKED_CLIENTS = 'LINKED_CLIENTS';

class UserClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: null,
      selectedItems: [],
    };
  }

  allowDrop = event => {
    event.preventDefault();
  };

  drag = (event, data, origin) => {
    this.addRemoveSelect(event, data, origin, true);
  };

  clearSelected = () => {
    this.setState({
      selectedItems: [],
      origin: null,
    });
  };

  dropAvailableClients = () => {
    if (
      this.state.origin === LINKED_CLIENTS ||
      this.state.origin === null ||
      this.state.selectedItems === null
    ) {
      return;
    }

    this.transfer();
  };

  dropLinkedClients = () => {
    if (
      this.state.origin === AVAILABLE_CLIENTS ||
      this.state.origin === null ||
      this.state.selectedItems === null
    ) {
      return;
    }

    // Remove these items from the linked clients state and move them into the linked clients sate
    // Then push this to redux
    this.transfer();

    this.clearSelected();
  };

  transfer = (copyAll = false, removeAll = false) => {
    let tempAvailableClients = [...this.props.availableClients];
    let tempLinkedClients = [...this.props.linkedClients];

    if (this.state.origin === AVAILABLE_CLIENTS) {
      this.state.selectedItems.forEach(obj => {
        tempAvailableClients = tempAvailableClients.filter(
          item => item.id !== obj.id
        );
        tempLinkedClients.push(obj);
      });
    } else if (this.state.origin === LINKED_CLIENTS) {
      this.state.selectedItems.forEach(obj => {
        tempLinkedClients = tempLinkedClients.filter(
          item => item.id !== obj.id
        );
        tempAvailableClients.push(obj);
      });
    } else if (copyAll) {
      tempAvailableClients.forEach(obj => tempLinkedClients.push(obj));
      tempAvailableClients = [];
    } else if (removeAll) {
      tempLinkedClients.forEach(obj => tempAvailableClients.push(obj));
      tempLinkedClients = [];
    }

    this.props.onUpdateClients(tempAvailableClients, tempLinkedClients);
    this.clearSelected();
  };

  checkOrigin = origin => {
    let selectedItems = [];

    if (!this.state.origin) {
      this.setState({
        origin: origin,
      });
    } else if (origin !== this.state.origin) {
      this.setState({
        selectedItems: [],
        origin: origin,
      });
    } else {
      selectedItems = this.state.selectedItems;
    }

    return selectedItems;
  };

  addRemoveSelect = (event, obj, origin, isDragAction) => {
    let selectedItems = this.checkOrigin(origin);
    const ctrlPressed = event.ctrlKey;

    if (
      ctrlPressed &&
      R.find(element => element.id === obj.id, selectedItems)
    ) {
      if (isDragAction !== true) {
        selectedItems = selectedItems.filter(item => item.id !== obj.id);
      }
    } else if (
      !ctrlPressed &&
      isDragAction &&
      R.find(element => element.id === obj.id, selectedItems)
    ) {
      // Do nothing. Condition is for if elements are already selected and the user starts to drag using one of
      // those items
    } else if (ctrlPressed) {
      selectedItems.push(obj);
    } else {
      selectedItems = [obj];
    }

    this.setState({
      selectedItems: selectedItems,
    });
  };

  buildAvailableClients = () => {
    return (
      <> 
        <Col lg={5}  className="fixed-panel2" onDragOver={this.allowDrop} onDrop={this.dropLinkedClients}>
          <Label for="availableClients" className="adjust-label">
            Available Clients
          </Label>
          <ListGroup id="availableClients">
            {this.props.availableClients.map((obj, index) => (
              <ListGroupItem
                onDragStart={event => this.drag(event, obj, AVAILABLE_CLIENTS)}
                draggable={true}
                onClick={event =>
                  this.addRemoveSelect(event, obj, AVAILABLE_CLIENTS)
                }
                key={index}
                className={
                  R.find(
                    element => element.id === obj.id,
                    this.state.selectedItems
                  )
                    ? 'active'
                    : null
                }
              >
                {obj.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </>
    );
  };

  buildLinkedClients = () => {
    return (
      <Col
        lg={5}
        className="fixed-panel2"
        onDragOver={this.allowDrop}
        onDrop={this.dropAvailableClients}
      >
        <Label for="linkedClients" className="adjust-label">
          Linked Clients
        </Label>
        <ListGroup id="linkedClients">
          {this.props.linkedClients.map((obj, index) => (
            <ListGroupItem
              onDragStart={event => this.drag(event, obj, LINKED_CLIENTS)}
              draggable={true}
              onClick={event =>
                this.addRemoveSelect(event, obj, LINKED_CLIENTS)
              }
              key={index}
              className={
                R.find(
                  element => element.id === obj.id,
                  this.state.selectedItems
                )
                  ? 'active'
                  : null
              }
            >
              {obj.name}
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
    );
  };

  render() {
    return (
      <>
        <div className="clients-alert">
          Drag a client from either list to link or unlink them to this user.
        </div>
        <div className="clients-alert">
          Press and hold the CTRL button while clicking to select multpiple
          clients.
        </div>
        <Row >
          {this.buildAvailableClients()}
          <Col lg={2}>
            <Row className="mt-5 center-block">
              <Col align="center">
                <Button onClick={() => this.transfer(true)}>COPY ALL</Button>
              </Col>
            </Row>
            <Row className="mt-5 center-block">
              <Col align="center">
                <Button onClick={() => this.transfer(undefined, true)}>
                  REMOVE ALL
                </Button>
              </Col>
            </Row>
          </Col>
          {this.buildLinkedClients()}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    availableClients: state.ADMIN.user.userClients.availableClients,
    linkedClients: state.ADMIN.user.userClients.linkedClients,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateClients: (availableClients, linkedClients) =>
    dispatch(actions.updateUserClients(availableClients, linkedClients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserClients);
