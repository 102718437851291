import React, { connect } from 'react';
import { useSelector, useDispatch, useState } from 'react-redux';
import { fast360Operations } from '../store';
import DashboardWIPTable from './DashboardWIPTable';

const DashboardWIP = ({workloadData, wipDashboardData, userWIPTaskData, setSelectedUser, setUserWIPTaskData, setFilterData}) => {

  const users = useSelector((state) => state.FAST360.initialData.roleUsers.users);
  const sortByKey = key => (a, b) => a[key] > b[key] ? 1 : -1
  const CAdata = workloadData.reduce((users, {PK_user_id, zebraId, onTime, uname, late, confId}) => {
    const user = users && users[PK_user_id] || {
      PK_user_id,
      assigned: uname,
      onTime: 0,
      ooc: 0,
      total: 0,
      subRows: [],
      zebraId
    };
    user.subRows.push({assigned: confId, onTime, ooc: late, total: 1, subRows: undefined, zebraId, PK_user_id});
    user.onTime += onTime;
    user.zebraId = zebraId;
    user.ooc += late;
    user.total++;
    users[PK_user_id] = user;
   return users;
  }, []).flat().slice().sort(sortByKey('assigned'));
  
  const CAcolumns = [
    {
      Header: 'Currently Assigned',
      header: 'Currently Assigned',
      accessor: 'assigned',      
      field: 'assigned',            
    },
    {
      Header: 'On Time',
      header: 'On Time',
      accessor: 'onTime',
      field: 'onTime',         
    },
    {
      Header: 'OOC',
      header: 'OOC',
      accessor: 'ooc',
      field: 'ooc',         
    },
    {
      Header: 'Total',
      header: 'Total',
      accessor: 'total',
      field: 'total',         
    },
  ];

  const data2 = wipDashboardData.reduce((tasks, {PK_task_id, chr_name, chr_reference_table, onTime, late}) => {
    const task = tasks && tasks[PK_task_id] || {
      PK_task_id,
      status: chr_name,
      onTime: 0,
      ooc: 0,
      subRows: []
    };
    task.subRows.push({status: chr_reference_table, onTime, ooc: late, subRows: undefined, PK_task_id, chr_reference_table});
    task.onTime += onTime;
    task.ooc += late;
    tasks[PK_task_id] = task;
    return tasks;
  }, []).flat();
  
  const columns2 = [
    {
      Header: <br />,
      header: <br />,
      accessor: 'status',  
      field: 'status',                    
    },
    {
      Header: 'On Time',
      header: 'On Time',
      accessor: 'onTime',
      field: 'onTime',        
    },
    {
      Header: 'OOC',
      header: 'OOC',
      accessor: 'ooc',
      field: 'ooc',        
    }
  ];


  const columns3 = [
    {
      Header: <br />,
      header: <br />,
      accessor: 'assigned',     
      accessor: 'assigned',         
    },
    {
      Header: 'On Time',
      header: 'On Time',
      accessor: 'onTime',
      accessor: 'onTime',
    },
    {
      Header: 'OOC',
      header: 'OOC',
      accessor: 'ooc',
      accessor: 'ooc',
    },

  ];

   


  const handleUserChange = e => {
    const user =
      this.state.users &&
      this.state.users.find(u => u.zebraId == e.target.value);
    this.props.updatePreviousUser(this.state.userSelected);
    this.setState({
      userSelected: e.target.value,
      userId: this.props.userId,
      billingDate: '',
      filterData: '',
      productSelected: '',
      subProductSelected: '',
      subProduct: [],
    });
    this.props.setSelectedUser(e.target.value);
    this.props.setZebraId(user.zebraId);
    this.props.getUsersWorkList(user, this.props.currentUserId);
     this.props.updateClickRecord();
    this.props.updateStatusClicked(false);
    this.props.updateMilestoneClicked(false);
    this.props.updateTaskClicked(false);
    this.props.updateMilestoneData([]);
  };

  return (
    <>
      <span className="bold-text">Breakdown of Tasks By Service</span>
      {CAdata.length > 0 && <DashboardWIPTable data={CAdata} columns={CAcolumns} updateTable={handleUserChange} user={users} setSelectedUser={setSelectedUser}  userWIPTaskData={userWIPTaskData} setTaskData={setUserWIPTaskData} setFilterData={setFilterData}/> }
      {data2.length > 0 && <DashboardWIPTable data={data2} columns={columns2} updateField={"PK_task_id"} user={users} setSelectedUser={setSelectedUser}  userWIPTaskData={userWIPTaskData} setTaskData={setUserWIPTaskData} setFilterData={setFilterData}/>}
      {data2.length > 0 && <DashboardWIPTable data={userWIPTaskData} columns={columns3} updateField={"PK_task_id"} user={users} setSelectedUser={setSelectedUser} userWIPTaskData={userWIPTaskData} setTaskData={setUserWIPTaskData}  setFilterData={setFilterData}/>}
    </>
  );
};

export default DashboardWIP;
