import PropTypes from 'prop-types';
import React from 'react';

import { formatPhoneNumber } from '../utils/expertUtils';

const PhoneFaxRenderer = ({ item, lineBreak }) => {
  const phone = item.phone || '';
  const phoneExt = item.phone_ext || '';
  const fax = item.fax || '';

  return (
    <>
      <span>
        {phone !== '' &&
          `P: ${formatPhoneNumber(phone)}${
            phoneExt !== '' ? ` x ${phoneExt}` : ''
          } `}
        {lineBreak && <br></br>}
        {fax !== '' && `F: ${formatPhoneNumber(fax)}`}
        <br></br>
      </span>
    </>
  );
};

PhoneFaxRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  lineBreak: PropTypes.bool.isRequired,
};

export default PhoneFaxRenderer;
