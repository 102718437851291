import { createSelector } from 'reselect';

import constants from './constants';

const getModule = state => state[constants.moduleName];

const getAdjuster = createSelector(getModule, state => state.adjuster);
const getUser = createSelector(getModule, state => state.user);
const getSearch = createSelector(getModule, state => state.search);

export default { getAdjuster, getUser, getSearch };
