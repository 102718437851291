import React, { Component } from "react";
import { AppTableBodyPropType } from "./AppTablePropTypes";
import Fast360FileAppTableBodyRow from "./Fast360FileAppTableBodyRow";
import { fast360Actions, fast360Operations } from '../../../modules/fast-360/store';
import { connect } from 'react-redux';
import { authSelectors } from '../../../modules/auth/store';
import * as FileUtils from 'shared/utils/fileUtils';


class Fast360FileAppTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
            fileData: []
        };
        this.setRowData = this.setRowData.bind(this);
    }
    componentDidMount() {
        this.props.getDocTypes(this.props.userId);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRows: [] });
    }

    setRowData(data) {
        this.setState({ fileData: data })
        let payload = [];
        if (!this.props.newRef) {
            const { ServiceType, int_reference_table_id, referralId, module, id } = this.props.dataObject;
            let serviceTypeLower = ServiceType ? ServiceType?.toLowerCase() : module?.toLowerCase();
            const objMap = {
                'modification': 'modifications',
                'diagnostic': 'diagnostics',
                'special transport' : 'special_transp',
                'int': 'interpretation',
                'acc': 'accommodation',
                'aud': 'audiology',
                'dtl': 'dental',
                'dgn': 'diagnostics',
                'hhc': 'hhc',
                'mse': 'mse',
                'mod': 'modifications',
                'spt': 'special_transp',
                'trn': 'transportation',
                'transportation': 'transportation',
                'accommodation': 'accommodation',
                'audiology': 'audiology',
                'dental': 'dental',
                'interpretation': 'interpretation'
            }
            data.map(async (item) => {
                // const fileData = await FileUtils.fileToBase64(item.file);
                payload.push({
                    "functionName": "uploadCCDocuments",
                    "zebraUserId": this.props.userId,
                    // "filedata": fileData,
                    "filedescription": item.description,
                    "Filename": item.file.name,
                    "fileprivate": "false",
                    "filetype": item.docType,
                    "record_id": int_reference_table_id ? int_reference_table_id : id,
                    "table_name": (serviceTypeLower == 'modification' || serviceTypeLower == 'diagnostic' || serviceTypeLower == 'special transport'  || serviceTypeLower.length === 3 ) ? objMap[serviceTypeLower] : objMap[serviceTypeLower],
                    "upload": "submit Query",
                    "file": item.file,
                    "id": item.id
                })
            })
        }
        else {
            this.props.newData.map(ser =>
                data.map(async (item) => {
                    payload.push({
                        "functionName": "uploadCCDocuments",
                        "zebraUserId": this.props.userId,
                        "filedescription": item.description,
                        "Filename": item.file.name,
                        "fileprivate": "false",
                        "filetype": item.docType,
                        "record_id": ser.id,
                        "table_name": ser.modSelected === "special transport" ? "special_transp" : ser.modSelected,
                        "upload": "submit Query",
                        "file": item.file,
                        "id": item.id
                    })
                }))
        }
        this.props.updatedFilesData(payload)
    }

    render() {
        const isClickable = !!this.props.clickHandler;
        const isSelectable = !!this.props.selectHandler;
        const isRowClickable = !!this.props.rowClickHandler;
        const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;



        return (
            <>
                {
                    this.props.data && this.props.data.map((dataObject, rowIndex) => {
                        const returnData = !!dataObject.fields ? dataObject.fields : dataObject;
                        return (

                            <Fast360FileAppTableBodyRow rowIndex={rowIndex}/* dropdownData={this.dropdownData}*/
                                highlightedRow={this.state.highlightedRow} isClickable={isClickable} isSelectable={isSelectable}
                                isRowClickable={isRowClickable} isRowDoubleClickable={isRowDoubleClickable} {...this.props}
                                returnData={returnData} dataObject={dataObject}
                                claimant={this.props.claimant} billId={this.props.billId}
                                zebraId={this.props.zebraId} setRowData={this.setRowData}
                            />

                        )
                    })
                }


            </>
        )
    }
}


const mapStateToProps = state => {
    const user = authSelectors.getUser(state);
    return {
        userId: user.id,
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        initialWorkList: state.FAST360.initialData.initialWorkList ? state.FAST360.initialData.initialWorkList : []
    };
};

const mapDispatchToProps = dispatch => ({
    updateClickRecord: data => dispatch(fast360Actions.updateClickRecord(data)),
    updateNewUsersWorkList: data => dispatch(fast360Actions.updateNewUsersWorkList(data)),
    getDocTypes: data => dispatch(fast360Operations.getDocTypes(data)),
    updatedFilesData: data => dispatch(fast360Actions.updatedFilesData(data)),
});

Fast360FileAppTableBody.propTypes = AppTableBodyPropType;



export default connect(mapStateToProps, mapDispatchToProps)(Fast360FileAppTableBody);

