import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';

import LocalTimeClockRenderer from './LocalTimeClockRenderer';
import AddressRenderer from './AddressRenderer';
import CityStateZipRenderer from './CityStateZipRenderer';
import PhoneFaxRenderer from './PhoneFaxRenderer';

const LOCAL_TIME = 'Local Time';
const ADDRESS = 'Address';
const CITY_STATE_ZIP = 'City State Zip';
const PHONE_FAX = 'Phone Fax';
class ExpertAddressesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: LOCAL_TIME,
      sortOrder: true,
    };
  }

  getRow(item, index) {
    return {
      localTime: <LocalTimeClockRenderer item={item} />,
      address: (
        <AddressRenderer item={item} index={index} renderPhoneFax={false} />
      ),
      cityStateZip: <CityStateZipRenderer item={item} />,
      phoneFax: <PhoneFaxRenderer item={item} lineBreak={true} />,
    };
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case LOCAL_TIME:
        const timezone1 = item1.timezone || '';
        const timezone2 = item2.timezone || '';
        return sortOrder
          ? timezone1.localeCompare(timezone2)
          : timezone2.localeCompare(timezone1);
      case ADDRESS: {
        const address1 = item1.street1 || '';
        const address2 = item2.street1 || '';
        return sortOrder
          ? address1.localeCompare(address2)
          : address2.localeCompare(address1);
      }
      case CITY_STATE_ZIP: {
        const city1 = item1.city || '';
        const city2 = item2.city || '';
        return sortOrder
          ? city1.localeCompare(city2)
          : city2.localeCompare(city1);
      }
      case PHONE_FAX:
      default:
        const phone1 = item1.phone || '';
        const phone2 = item2.phone || '';
        return sortOrder
          ? phone1.localeCompare(phone2)
          : phone2.localeCompare(phone1);
    }
  }

  render() {
    const data = this.props.data
      .filter(item => item.active === true)
      .sort((item1, item2) => this.sortData(item1, item2))
      .map((item, index) => this.getRow(item, index));
    return (
      <Card>
        <CardHeader>Addresses</CardHeader>
        <CardBody>
          <AppTable
            resizable
            columns={[
              {
                header: this.getHeader(LOCAL_TIME),
                field: 'localTime',
              },
              {
                header: this.getHeader(ADDRESS),
                field: 'address',
              },
              {
                header: this.getHeader(CITY_STATE_ZIP),
                field: 'cityStateZip',
              },
              {
                header: this.getHeader(PHONE_FAX),
                field: 'phoneFax',
              },
            ]}
            data={data}
            autoPagination={true}
          />
        </CardBody>
      </Card>
    );
  }
}

ExpertAddressesCard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ExpertAddressesCard;
