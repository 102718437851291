
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fast360Actions } from '../store/index';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';


const Fast360DGNMedicalQuestions = ({ requiredServiceData, updatRequiredServiceData, fromExternal}) => {
    const [questions, setQuestions] = useState([
        {
            question: 'Have you ever had brain, heart, or spinal surgery?',
            key: 'brainHrtSpinInd',
            answer: ''
        },
        {
            question: 'Are you claustrophobic?',
            key: 'claustrophobicInd',
            answer: ''
        },
        {
            question: 'Do you have any medical devices or metal implants in your body such as a pacemaker, aneurysm clips, heart stents, pins, screws or plates?',
            key: 'metalImplantsInd',
            answer: ''
        },
        {
            question: 'Have you ever been diagnosed with cancer?',
            key: 'cancerInd',
            answer: ''
        },
        {
            question: 'Have you ever done any work with metal where it may have gotten in your eyes? Welding, grinding, etc?',
            key: 'metalWrkInd',
            answer: ''
        },
        {
            question: 'Are you diabetic?',
            key: 'diabeticInd',
            answer: ''
        },
        {
            question: 'Are you pregnant or trying to become pregnant?',
            key: 'pregnancyInd',
            answer: ''
        },
        {
            question: 'Do you have any kidney/renal problems?',
            key: 'rentalKidneyProbInd',
            answer: ''
        },
        {
            question: 'Have you had surgery within the last 6 weeks?',
            key: 'surgeryInd',
            answer: ''
        },
        {
            question: 'Are you allergic to iodine or shellfish?',
            key: 'iodineAllergyInd',
            answer: ''
        },
    ]);

    const [weight, setWeight] = useState(null);
    const [height, setHeight] = useState(null);
    const [ageOfInjuryRequest, setAgeOfInjuryRequest] = useState(false);


      useEffect(() => {

        if(Object.keys(requiredServiceData).length > 0 ) {
            
            let medicalQuestions = {};
            
            if(requiredServiceData.selectedService && requiredServiceData.selectedService.module === 'DGN')  {
                medicalQuestions = {...requiredServiceData.selectedService.medicalQuestions}
            } else if( requiredServiceData.module === 'DGN') {
                medicalQuestions = {...requiredServiceData.medicalQuestions};
            }

            //   updateWorkList(medicalQuestions);
            if( Object.keys(medicalQuestions).length === 0)  {
                resetQuestions();
                return;
            }

            let wt = medicalQuestions['patientWeight'];
            let ht = medicalQuestions['patientHeight']; 
            let ageOfInjReq = medicalQuestions['ageOfInjuryReqInd'] !== 0 ? true : false; 
            let updatedQuestions = [...questions];
            updatedQuestions = updatedQuestions.map((qst) => {
                let ans = '';
                if(medicalQuestions[qst.key] === 1) {
                    ans = 'YES';
                } else if(medicalQuestions[qst.key] === 0) {
                    ans = 'NO';
                }
                return {...qst, answer: ans};
            });
    
            setAgeOfInjuryRequest(ageOfInjReq);
            setWeight(wt);
            setHeight(ht);
            setQuestions(updatedQuestions);
        }
      }, [requiredServiceData]);


    
    const resetQuestions = () => {
        const updatedQuestions = questions.map((qst) => {
            return {...qst, answer: ''};
        });
        setQuestions(updatedQuestions);
        setWeight('');
        setHeight('');
        setAgeOfInjuryRequest(false);
    } 

    const updateServiceData = (updatedQuestions) => {
        const serviceData = requiredServiceData.selectedService ? requiredServiceData.selectedService : requiredServiceData;
        const updatedServiceData =  {selectedService: serviceData};
        updatedServiceData.selectedService.medicalQuestions = updatedQuestions;
        updatRequiredServiceData(updatedServiceData);
     }

    
    const updateDiagnosticQuestions = (key, value) => {
        const updatedQuestions  = {};

        questions.forEach(item => {
            if(item.answer === 'YES') {
                updatedQuestions[item.key] = 1;
            } else if(item.answer === 'NO') {
                updatedQuestions[item.key] = 0;
            } else {
                updatedQuestions[item.key] = '';
            }
        });

        updatedQuestions['patientWeight'] = weight;
        updatedQuestions['patientHeight'] = height;
        updatedQuestions['ageOfInjuryReqInd'] = ageOfInjuryRequest ? 1 : 0;

        updatedQuestions[key] = value;

        updateServiceData(updatedQuestions);

    }


    const handleWeightChange = (e) => {
        if(e.target.value.length > 3 ) return;
        let wt = parseInt(e.target.value);
        if(isNaN(wt)) {
            wt = '';
        }
        
       updateDiagnosticQuestions('patientWeight', wt);
       setWeight(wt);
    }

    const handleHeightChange = (e) => {
        if(e.target.value.length > 4 ) return;
        updateDiagnosticQuestions('patientHeight', e.target.value);
        setHeight(e.target.value);
    }

    const handleRadioChange = (answer, index) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = answer;

        const key =  updatedQuestions[index].key;
        const val = answer == 'YES' ? 1 : 0;
        updateDiagnosticQuestions(key, val);
        setQuestions(updatedQuestions);
    }

    const isSaveDisabled = () => {
        let formValid = true;
        questions.forEach((item ) => {
            if(item.answer === '') {
                formValid = false;
                return;
            }
        });

        if(!formValid || !height || !weight || ageOfInjuryRequest === false) {
            return true;
        } else return false;
 
    }

    return <>
        <div
            style={{
                textAlign: 'center',
                backgroundColor: '#505d78',
                padding: '5px',
                marginBottom: '5px',
            }}
        >
            <h6 style={{ color: '#ffffff' }}> Medical Questions</h6>
        </div>
        <br></br>
        <Row>
            <Col md="8">
                <Row>
                    {questions.map((item, index) => (
                        <Col lg={{ size: 5, offset: 1 }} key={item.question}>
                            <Row style={{ marginBottom: '2em' }}>
                                <Col md="8">{item.question}</Col>
                                <Col md="2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" className= {item.answer == "" ?  "custom-radio-error" : "" } name={'radio' + index + '1'} checked={item.answer == 'YES' ? true : false}
                                                onChange={() => handleRadioChange('YES', index)} />  YES </Label>

                                    </FormGroup>

                                </Col>
                                <Col md="2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" className= {item.answer == "" ?  "custom-radio-error" : "" } name={'radio' + index + '2'} checked={item.answer == 'NO' ? true : false}
                                                onChange={() => handleRadioChange('NO', index)} />  NO </Label>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Col>
                    ))}
                    <Col lg={{ size: 5, offset: 1 }}>
                        <FormGroup row>
                            <Label

                                for="patientWeight"
                                sm={8}
                            >
                                Patient Weight
                            </Label>
                            <Col sm={4}>
                                <Input
                                    id="patientWeight"
                                    name="patientWeight"
                                    type="text"
                                    onInput={handleWeightChange}
                                    value={weight}
                                    style={weight == '' ? {borderColor: 'red'}: {}}
                                />
                            </Col>

                        </FormGroup>
                    </Col>
                    <Col lg={{ size: 5, offset: 1 }}></Col>
                    <Col lg={{ size: 5, offset: 1 }}>
                        <FormGroup row>
                            <Label

                                for="patientHeight"
                                sm={8}
                            >
                                Patient Height
                            </Label>
                            <Col sm={4}>
                                <Input
                                    id="patientHeight"
                                    name="patientHeight"
                                    type="text"
                                    onInput={handleHeightChange}
                                    value={height}
                                    style={height == '' ? {borderColor: 'red'}: {}}
                                />
                            </Col>

                        </FormGroup>
                    </Col>
                    <Col lg={{ size: 5, offset: 1 }}>
                        <FormGroup
                            check
                            inline
                        >
                            <Input type="checkbox" value={ageOfInjuryRequest} checked={ageOfInjuryRequest}  onChange={() => {
                                const val =  ageOfInjuryRequest == 0 ? 1 : 0;
                              updateDiagnosticQuestions('ageOfInjuryReqInd', val);
                              setAgeOfInjuryRequest(!ageOfInjuryRequest);
                            }  } />
                            <Label check>
                                Age of Injury Request
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={{ size: 11, offset: 1 }}>
                        <div style={{ textAlign: 'center', marginTop: '2em' }}>
                            {/* <Button style={{ marginRight: '70px' }} disabled={isSaveDisabled()}>Save</Button> */}
                            { (fromExternal.isNewReferral || (fromExternal.fromExternal.fromExternal && fromExternal.externalReferralData.length > 0)) && <Button onClick={() => resetQuestions()}>Reset</Button>} 
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row>
        <br></br><br></br>
    </>
}

const mapStateToProps = state => {
    return {
        currentWorkList: state.FAST360.fast360Reducer.currentWorkListItem,
        requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData : {},
        diagnosticList: state.FAST360.fast360Reducer.updateLoadFromWorkList.diagnosticList
        ? state.FAST360.fast360Reducer.updateLoadFromWorkList.diagnosticList
        : [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatRequiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fast360DGNMedicalQuestions);