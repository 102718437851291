import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Form, Button } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import TwoFactorAuthModal from './TwoFactorAuthModal';

const TwoFactor = ({ username, systemId, checkAuthCodeResult, setLoginState, currentQuestionId, setShowSecurityQuestions }) => {

  const {
    checkAuthCode,
    resendAuthCode
  } = useAuth();
  const [authoCode, setAuthCode] = useState('');

  const [ showTwoFactorAuthModal, setShowTwoFactorAuthModal ] = useState(false);

  const onAuthCodeSubmit =  async (e) => { 
      e.preventDefault();
      await checkAuthCode(username, authoCode, systemId);
      setAuthCode("");

      if(checkAuthCodeResult == 1){
        if(currentQuestionId != 0){
          setLoginState('security')
       }else if(currentQuestionId == 0){
        setLoginState('securitySetup')
        setShowSecurityQuestions(true);
       }
      }
    }

    useEffect(()=>{
      if(checkAuthCodeResult == 1){
        if(currentQuestionId != 0){
          setLoginState('security')
       }else if(currentQuestionId == 0){
        setLoginState('securitySetup')
        setShowSecurityQuestions(true);
       }
      }
    },[checkAuthCodeResult])

  return (
    <>
      <Form style={{width: "100%"}} onSubmit={(e)=>onAuthCodeSubmit(e)}>
        <Col lg="12" className="marginTop25">
          <FormGroup className="group full">
            <p style={{fontSize: "15px"}}>
              An email has been sent with an authorization code.<br/>
              Please enter the authorization code to continue.
            </p>
            <Input
              type="text"
              value={authoCode}
              onChange={e => setAuthCode(e.target.value)}
              name="code"
              id="code"
            />
            {checkAuthCodeResult === -1 && <p style={{ color: 'red' }}> Code is incorrect. Please enter the correct code, or have a new one resent. </p>}
          </FormGroup>
        </Col>
        <Col lg="12">
        <button
              className="link-underline"
              type="submit"
              style={{ paddingTop: "2px", float:'left', zIndex:"99" }}
            >
              CONTINUE
          </button>
        </Col>
        <Col lg="12">
            <button
              className="link-underline"
              type="button"
              style={{marginLeft: "30px"}}
              onClick={async ()=> {
                await resendAuthCode(username)
                setShowTwoFactorAuthModal(true)
              }}
            >
              RESEND AUTHORIZATION CODE
          </button>
        </Col>
        
      </Form>
      <TwoFactorAuthModal isOpen={showTwoFactorAuthModal} onToggle={() => { setShowTwoFactorAuthModal(!showTwoFactorAuthModal) }} />
    </>

  );
};
export default TwoFactor;
