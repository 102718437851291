import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'reactstrap';

import UserAccessTable from 'shared/components/table/UserAccessTable';
import ListCheckBoxesPanel from 'shared/components/ListCheckBoxesPanel';

import { ANCIL_ACCESS_MODULE } from '../store/constants';
import actions from '../store/actions';

const columns = [
  {
    header: 'User Role',
    field: 'userRole',
  },
  {
    header: 'Allow Access',
    field: 'allowAccess',
    id: 'id',
  },
  {
    header: 'Default',
    field: 'default',
    id: 'id',
  },
];

const UserAncilAccessContent = props => (
  <Row>
    <Col lg="8">
      <UserAccessTable
        columns={props.columns}
        data={props.dataTable}
        updateDataTable={props.updateDataTable}
        showAccessCheckboxes={props.showAccessCheckboxes}
        activeAccessPanel={props.activeAccessPanel}
      />
    </Col>
    <Col lg="4">
      <div id="accessContainer">
        {props.dataTable.map((dataObj, index) => {
          if (dataObj.access !== undefined) {
            return (
              <div
                style={
                  props.activeAccessPanel === dataObj.id
                    ? {}
                    : { display: 'none' }
                }
                key={index}
              >
                <ListCheckBoxesPanel
                  data={dataObj.access}
                  handleInputChange={props.updateAncilAccess}
                  panelId={dataObj.id}
                />
              </div>
            );
          }
        })}
      </div>
    </Col>
  </Row>
);

class UserAncilAccess extends Component {
  updateAncilAccess = event => {
    this.props.onUpdateAncilAccess(
      event,
      this.props.userAncilAccess.activeAccessPanel
    );
  };

  render() {
    return (
      <Card body>
        <UserAncilAccessContent
          columns={columns}
          dataTable={this.props.userAncilAccess.dataTable}
          dataAncilAccess={this.props.userAncilAccess.ancilAccess}
          updateDataTable={this.props.onUpdateDataTable}
          updateAncilAccess={this.updateAncilAccess}
          showAccessCheckboxes={this.props.onUpdateActiveAccessPanel}
          activeAccessPanel={this.props.userAncilAccess.activeAccessPanel}
        />
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    userAncilAccess: {
      selectedDataAncilAccess: [],
      dataTable: state.ADMIN.user.userAncilAccess.dataTable,
      ancilAccess: state.ADMIN.user.userAncilAccess.ancilAccess,
      activeAccessPanel: state.ADMIN.user.userAncilAccess.activeAccessPanel,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateDataTable: (event, module, arrayName) =>
    dispatch(
      actions.updateObjectArray(event, ANCIL_ACCESS_MODULE, 'dataTable')
    ),
  onUpdateAncilAccess: (event, dataTableId) =>
    dispatch(actions.updateAncilAccess(event, dataTableId)),
  onUpdateActiveAccessPanel: value =>
    dispatch(actions.setAncilAccessPanel(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAncilAccess);
