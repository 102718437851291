import { createSelector } from 'reselect';

import { moduleName } from './constants';

const getModule = state => state[moduleName];

const getBillReview = createSelector(getModule, state => state.billReview);

export default {
  getModule,
  getBillReview,
};
