import * as R from 'ramda';
import React from 'react';
import {
  Button,
  Col,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';


const VendorLookupConfirmModal = ({ isOpen, onClose }) => {
 


  return (
    <Modal size="md" isOpen={isOpen}>
      <ModalHeader toggle={onClose}></ModalHeader>
      <ModalBody className="text-break">   
        <Row> 
          <Col>
             <div style={{wordBreak: 'break-word'}}>Your search has been submitted, please check the worklist to see when search is finished.</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button type="button" onClick={() => onClose()}>
              Ok
            </Button>
          </Col>
          </Row>
      </ModalBody>
    </Modal>
  );
};

export default VendorLookupConfirmModal;
