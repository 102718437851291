import axios from 'axios';
import { useAuth } from 'modules/auth/hooks/useAuth';

const zebraRequest = axios.create({
  baseURL: process.env.REACT_APP_ZEBRA_API_BASE_URL,
});

const slpAuthFormData = new FormData();
slpAuthFormData.append('username', process.env.REACT_APP_SLP_USERNAME);
slpAuthFormData.append('password', process.env.REACT_APP_SLP_PASSWORD);

const authInterceptor = zebraRequest.interceptors.request.use(config => {
  // FIXME
  // console.log(`Test Interceptor: ${config.url}`);
  let token = localStorage.getItem('auth_token');

  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

zebraRequest.interceptors.response.use(
  response => response,
  error => {
    console.dir(error);
    if (error.response && error.response.status === 401) {
      // Redirect to login page if we are unable to authenticate
      // Do we need to call logout function?
      window.location.href = '/login';
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default zebraRequest;
