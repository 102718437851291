import React, { useCallback, useEffect, useState } from 'react';
import {
  fast360Actions,
  fast360Selectors
} from '../store/index';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import DashboardButton from './DashboardButton';

const Fast360AUD = ({ appointmentType, selectedService, fromExt, audPo, selectedMod,
  isFromLanding,
  dataFromWorklist,
  updatePatientZip,
  patientInfo,
  requiredServiceData }) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  const [poNum, setPoNum] = useState('');
  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);
  useEffect(() => {
    if (selectedService || isFromLanding || !isEmpty(dataFromWorklist)) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: 1097,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: 1097,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService, isFromLanding, dataFromWorklist]);


  useEffect(() => {
    if (selectedService?.dos ||
      selectedService?.subService ||
      isFromLanding ||
      !isEmpty(dataFromWorklist)) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          modService: selectedService?.subService,
          serviceTypeId: 1097,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: 1097,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService?.dos,
  selectedService?.subService,
    isFromLanding,
    dataFromWorklist]);

  const onChangeHandlerAccType = (id, value) => {
    selectedService.product = value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerApptType = (id, value) => {
    selectedService.apptTypeId = value;
    setSelectedItem({ selectedService });
  };
  useEffect(() => {
    if (fromExt && selectedMod === 'AUD') {
      // setPoNum(audPo.UniquePortalAuthCode)
      selectedService.poNumber = audPo.UniquePortalAuthCode;
    }
  }, [])
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const setMap = {
    dos: setValidDos,
  }
  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let mon = object.value.getMonth() + 1;
      selectedService.dos =
        mon +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }
    setSelectedItem({ selectedService });
    setValidDos(true);
  };


  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'poNumber') {
      selectedService.poNumber = value;
      // setPoNum(value)

    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              name="poNumber"
              onChange={handleInputChange}
              // value={poNum || ''}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                type="checkbox"
                name="rush"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Col xs="1"></Col>
            <Label check>
              <Input
                type="checkbox"
                name="highPriority"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id="auddos">
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos} />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    patientInfo: fast360Info.patientZip,
  }
}

const mapDispatchToProps = dispatch => ({
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360AUD);
