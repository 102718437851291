import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Input, ListGroup, ListGroupItem, Row, } from 'reactstrap';
import { billMaintenanceSelectors } from '../../../store';
import './SiteSettings.scss';
class SiteSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateCount: 0,
      sites: [],
      choices: [],
      activeSite: {},
      activeChoice: {},
      filterSiteText: '',
      filterChoiceText: '',
      filterTypeListSelect: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.settingsList.length === 0) {
      this.setState({
        sites: this.props.settingsList,
      });
    }
    if (prevProps.choiceSettings !== this.props.choiceSettings) {
      let choices1 = [];
      let newChoices = [];
      let choices = [];
      if (Array.isArray(this.props.siteDetails.settings.fields.options)) {
        choices  = this.props.siteDetails.settings.fields;
      } else {
        choices1 = R.map(item => item[0], this.props.choiceSettings);
      }

      const newSites = this.props.settingsList.reduce((newSite, site) => {
        if (Array.isArray(choices.options) && choices.options.includes(parseInt(site.key))) {
          newChoices.push(site);
          return newSite;
        } else {
          return newSite.concat(site);
        }
      }, []);
      this.setState({
        sites: newSites,
        choices: newChoices,
      });
   }
   this.sendChoiceSettingData(this.state.choices);
  }

  buildListSites = (data, header, activeProperty) => {
    return (
      <Col lg={5}>
        <Row>
          <Col>
            <h6>{header}</h6>
            <ListGroup className="settings-list border rounded">
              {this.buildSiteSpecific(data, activeProperty)}
            </ListGroup>
          </Col>
        </Row>
      </Col>
    );
  };

  buildSiteSpecific = (data, activeProperty) => {
    activeProperty = `active${activeProperty}`;
    return data
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(site => (
        <ListGroupItem
          active={this.state[activeProperty] === site}
          action
          key={site.key}
          tag="button"
          onClick={() => this.selectSite(site, activeProperty)}
        >
          {site.description}
        </ListGroupItem>
      ));
  };

  filterOnChange = (e, activeProperty) => {
    this.setState({
      [`filter${activeProperty}Text`]: e.target.value,
    });
  };

  selectSite = (site, activeProperty) => {
    this.setState({
      [activeProperty]: site,
    });
  };

  moveSite = (source, destination, activeProperty) => {
    if (!this.state[activeProperty].hasOwnProperty('key')) {
      return;
    }
    this.setState({
      [source]: this.state[source].filter(
        site => site.key !== this.state[activeProperty].key
      ),
      [destination]: this.state[destination].concat(this.state[activeProperty]),
      [activeProperty]: {},
    });
  };

  buildActionColumn = () => {
    return (
      <Col lg={2}>
        <Row className="mt-5">
          <Col>
            <Button
              onClick={() => this.moveSite('sites', 'choices', 'activeSite')}
              color="primary"
            >
              {'Add >>'}
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button
              onClick={() => this.moveSite('choices', 'sites', 'activeChoice')}
              color="primary"
            >
              {'<< Remove'}
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  buildSettingTypeSelect = () => {
    return this.props.settingTypeList.map(type => (
      <option key={type.key} value={type.key}>
        {type.description}
      </option>
    ));
  };

  onSettingTypeSelectChange = e => {
    this.setState({
      filterTypeListSelect: e.target.value,
    });
  };

  buildFilter = (site, activeProperty) => {
    return (
      site.description.includes(this.state[`filter${activeProperty}Text`]) &&
      (this.state.filterTypeListSelect === '' ||
        this.state.filterTypeListSelect === site.misc.settingType)
    );
  };

  buildBottomRow = () => {
    return (
      <Row className="mt-3 mb-3">
        <Col lg={5}>
          <Input type="text" onChange={e => this.filterOnChange(e, 'Site')} />
        </Col>
        <Col lg={2}>
          <Input
            type="select"
            name="select"
            id="setting-type-list"
            onChange={this.onSettingTypeSelectChange}
          >
            <option value="">All</option>
            {this.buildSettingTypeSelect()}
          </Input>
        </Col>
        <Col lg={5}>
          <Input type="text" onChange={e => this.filterOnChange(e, 'Choice')} />
        </Col>
      </Row>
    );
  };

  sendChoiceSettingData = (choices)=> {
    this.props.clientMaintenanceChoiceData(choices);
  }

  render() {
    const sites = this.props.siteDetails.id !== 0 ? this.state.sites : [];
    const choices = this.props.siteDetails.id !== 0 ? this.state.choices : [];
    this.state.choices = choices;
    
    return (
      <div id="site-settings">
        <Container>
          <Row>
            {this.buildListSites(
              sites.filter(site => this.buildFilter(site, 'Site')),
              'Site Specific',
              'Site'
            )}
            {this.buildActionColumn()}
            {this.buildListSites(
              choices.filter(site => this.buildFilter(site, 'Choice')),
              'Active Choices',
              'Choice'
            )}
          </Row>
          {this.buildBottomRow()}
        </Container>
      </div>
    );
  }
}

SiteSettings.propTypes = {
  settingsList: PropTypes.array.isRequired,  
  settingTypeList: PropTypes.array.isRequired,
  choiceSettings: PropTypes.any.isRequired,
  siteDetails: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  const clientMaintenance = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const settingsList = billMaintenanceSelectors.getClientMaintenanceSettingsList(
    state
  );
  return {
    settingsList: clientMaintenance.clientComboBoxes.settingsList,
    settingTypeList: clientMaintenance.clientComboBoxes.settingTypeList,
    choiceSettings: settingsList.data,
    siteDetails: clientMaintenance.siteDetails,
  };
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);
