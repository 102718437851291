import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Button,
} from 'reactstrap';

import { expertsSelectors } from '../store';

import ExpertGeneralInformation from './ExpertGeneralInformation';
import ExpertBiography from './ExpertBiography';

const GENERAL_INFORMATION_TAB = 'GENERAL_INFORMATION_TAB';
const BIOGRAPHY_TAB = 'BIOGRAPHY_TAB';
const CALENDAR_TAB = 'CALENDAR_TAB';
class ExpertPreviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: GENERAL_INFORMATION_TAB,
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderFullName(selectedExpert, people) {
    const groupMode = selectedExpert.isGroup === 1;
    const salutation = groupMode ? '' : `${people.salutation || ''} `;
    const honor = groupMode ? ' ' : ` ${people.honor || ''} `;
    const userIcon = groupMode ? faUsers : faUser;
    return (
      <div style={{ marginTop: 5 }}>
        <FontAwesomeIcon className="green-text" icon={userIcon} />
        <span className="bold-text" style={{ marginLeft: 5 }}>
          {salutation}
          {this.props.selectedExpert.fullName}
          {honor}
        </span>
        <span className="bold-text grey-text italic-text">
          {this.props.selectedExpert.status}
        </span>
      </div>
    );
  }

  render() {
    return (
      <>
        <Nav tabs>
          <Col lg="1" style={{ paddingLeft: 0 }}>
            <Button type="button" style={{ width: '100%' }}>
              Edit
            </Button>
          </Col>
          <Col lg="3" style={{ paddingLeft: 0 }}>
            {this.props.selectedExpert.expertId &&
              this.renderFullName(
                this.props.selectedExpert,
                this.props.expert.people
              )}
          </Col>
          <Col lg={{ size: '3', offset: '4' }} style={{ paddingRight: 0 }}>
            <NavItem style={{ paddingRight: 5 }}>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === GENERAL_INFORMATION_TAB,
                })}
                onClick={() => {
                  this.toggle(GENERAL_INFORMATION_TAB);
                }}
              >
                General information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === BIOGRAPHY_TAB,
                })}
                onClick={() => {
                  this.toggle(BIOGRAPHY_TAB);
                }}
              >
                Biography
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === CALENDAR_TAB,
                })}
                onClick={() => {
                  this.toggle(CALENDAR_TAB);
                }}
              >
                Calendar
              </NavLink>
            </NavItem>
          </Col>
          <Col lg="1" style={{ paddingRight: 0 }}>
            <Button type="button" style={{ width: '100%' }}>
              Assignment
            </Button>
          </Col>
        </Nav>
        <TabContent
          activeTab={this.state.activeTab}
          style={{ paddingTop: '10px' }}
        >
          <TabPane tabId={GENERAL_INFORMATION_TAB}>
            <ExpertGeneralInformation
              expert={this.props.expert}
              selectedExpert={this.props.selectedExpert}
            />
          </TabPane>
          <TabPane tabId={BIOGRAPHY_TAB}>
            <ExpertBiography expert={this.props.expert} />
          </TabPane>
          <TabPane tabId={CALENDAR_TAB}>Calendar</TabPane>
        </TabContent>
      </>
    );
  }
}

ExpertPreviewContainer.propTypes = {
  expert: PropTypes.shape({
    expertNetworks: PropTypes.array.isRequired,
    expertServices: PropTypes.array.isRequired,
    addresses: PropTypes.array.isRequired,
    credentials: PropTypes.array.isRequired,
    credentialTypes: PropTypes.array.isRequired,
    people: PropTypes.object.isRequired,
    expert: PropTypes.object.isRequired,
    groupExpert: PropTypes.array.isRequired,
  }).isRequired,
  selectedExpert: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    expertId: PropTypes.number,
    status: PropTypes.string.isRequired,
    isGroup: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const previewState = expertsSelectors.getPreview(state);
  return {
    selectedExpert: previewState.selectedExpert,
    expert: previewState.expert,
    credentialTypes: previewState.credentialTypes,
  };
};

export default connect(mapStateToProps)(ExpertPreviewContainer);
