import React, { useContext, useEffect } from 'react';
import ReactExport from "react-data-export";
import { Button } from 'reactstrap';
import moment from '../../../../node_modules/moment/moment';


const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

const hasValue = (item) => {
    if(item){
        return item;
    }
    return "";
}

const formatName = (item) => {
    if(item.firstName && item.lastName){
        return item.firstName + " " + item.lastName;
    }
}

const formatDecimal = (item) => {
    if(item) {
        return parseFloat(item).toFixed(2);
    }
    return "0.00";
}

const formatDate = (item) => {
    if(item) {
        return moment(item).format('MM/DD/YYYY');
    }
    return "";
}

const formatDateTime = (item) => {
    if(item) {
        return moment(item).format('MM/DD/YYYY HH:mm:ss');
    }
    return "";
}

const formatBoolean = (item) => {
    if(item !== undefined) {
        if(item === true) {
            return "yes";
        }
        return "no";
    }
    return "";
}

const getProviderType = (item, list) => {
    let filteredItem = {};
    if (item?.productLineId) {
      filteredItem = list.filter((providerItem) => providerItem.id === item.productLineId)[0] || {};
    }
    return filteredItem?.productName ?filteredItem?.productName : '';
  }

  const getGroupMembership = (item, list) => {
    let filteredItem = {};
    if (item?.groupId) {
      filteredItem = list.filter((groupItem) => groupItem.id === item.groupId)[0] || {};
    }
    return filteredItem?.groupName ? filteredItem.groupName : '';
  }

function VendorMaintenanceExcelExport({ data, check, providerTypes, groupList }) {

    let idx = 1;
    let allColumns = [];

    check.displayTin && allColumns.push(<ExcelColumn key={idx++} label="TIN" value="tin" />);
    check.displayGroupName && allColumns.push(<ExcelColumn key={idx++} label="Group_Name" value={(col)  => hasValue(col.groupName)} />);
    check.displayFLName && allColumns.push(<ExcelColumn key={idx++} label="First_Last_Name" value={(col) => formatName(col)} />);
    check.displayAddress && allColumns.push(<ExcelColumn key={idx++} label="Address" value={(col)  => hasValue(col.address1)} />);
    check.displayState && allColumns.push(<ExcelColumn key={idx++} label="State" value={(col)  => hasValue(col.state)} />);
    check.displayZip && allColumns.push(<ExcelColumn key={idx++} label="Zip_Code" value={(col)  => hasValue(col.zipCode)} />);
    check.displayPhone && allColumns.push(<ExcelColumn key={idx++} label="Phone" value={(col)  => hasValue(col.phoneNumber)} />);
    check.displayPctBelow && allColumns.push(<ExcelColumn key={idx++} label="UCR" value={(col)  => formatDecimal(col.ucrPercent)} />);
    check.displayPctBelow && allColumns.push(<ExcelColumn key={idx++} label="FS" value={(col)  => formatDecimal(col.fsPercent)} />);
    check.displayPctBelow && allColumns.push(<ExcelColumn key={idx++} label="BC" value={(col)  => formatDecimal(col.billedCharges)} />);
    check.displayPctBelow && allColumns.push(<ExcelColumn key={idx++} label="MCR" value={(col)  => formatDecimal(col.medicarePercent)} />);
    check.displayLastModifiedBy && allColumns.push(<ExcelColumn key={idx++} label="Last_Modified_By" value={(col)  => (col.modifiedBy ? col.modifiedBy + " " : "") + formatDateTime(col.modifiedDate)} />);
    check.displayEffTermDates && allColumns.push(<ExcelColumn key={idx++} label="Effective_Date" value={(col)  => formatDate(col.effDate)} />);
    check.displayEffTermDates && allColumns.push(<ExcelColumn key={idx++} label="Term_Date" value={(col)  => formatDate(col.termDate)} />);
    check.displayFax && allColumns.push(<ExcelColumn key={idx++} label="Fax" value={(col)  => hasValue(col.faxNumber)} />);
    check.displayContact && allColumns.push(<ExcelColumn key={idx++} label="Contact" value={(col)  => hasValue(col.contact)} />);
    check.displayIsANetwork && allColumns.push(<ExcelColumn key={idx++} label="Is_A_Network" value={(col)  => formatBoolean(col.network)} />);
    check.displayProviderType && allColumns.push(<ExcelColumn key={idx++} label="Provider_Type" value={(col)  => getProviderType(col, providerTypes)} />);
    check.displayGroupMembership && allColumns.push(<ExcelColumn key={idx++} label="Group_Membership" value={(col)  => getGroupMembership(col, groupList)} />);



    return (
        <ExcelFile element={
            <Button disabled={data && data.length === 0} type="button" style={{ paddingTop: '8px' }}>
                Export
            </Button>}
        >
            <ExcelSheet data={data} name="Report">
                {allColumns.map(el => el)}

            </ExcelSheet>
        </ExcelFile>

    )
}

export default VendorMaintenanceExcelExport;