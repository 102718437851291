import React, { Component } from 'react';
import { Input, Table } from 'reactstrap';

const AdjusterMappingTable = props => (
  <Table size="sm" striped={true}>
    <thead className={'thead-dark'}>
      <tr>
        {props.columns.map((column, index) => {
          if (column.field === 'checked') {
            return (
              <th key={index}>
                {column.header}
                <span className="float-right">
                  <Input type="checkbox" onChange={props.handleCheckedAll} />
                </span>
              </th>
            );
          } else {
            return <th key={index}>{column.header}</th>;
          }
        })}
      </tr>
    </thead>
    <tbody>
      {props.data.map((dataObj, index) => (
        <tr key={index}>
          {props.columns.map((column, colIndex) => {
            let field = column.field;
            if (field === 'checked') {
              return (
                <td key={colIndex} align="right">
                  <Input
                    type="checkbox"
                    name={dataObj.id + '_' + column.field}
                    id={dataObj.id + '_' + column.field}
                    onChange={props.handleInputChange}
                    checked={dataObj[field]}
                  />
                </td>
              );
            } else {
              return <td key={colIndex}>{dataObj[field]}</td>;
            }
          })}
        </tr>
      ))}
    </tbody>
  </Table>
);

class UserAdjusterMappingChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    this.handleCheckedAll = this.handleCheckedAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(dataObj, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === dataObj.id) {
        data[i].selected = !data[i].selected;
        break;
      }
    }
    this.setState({
      data: data,
    });
  }

  handleCheckedAll = () => {
    const { data, checkedAll } = this.state;
    for (let i = 0; i < data.length; i++) {
      data[i].selected = !checkedAll;
    }
    this.setState({
      data: data,
      checkedAll: !checkedAll,
    });
  };

  render() {
    return (
      <AdjusterMappingTable
        columns={this.props.columns}
        data={this.props.data}
        handleChange={this.handleChange}
        handleCheckedAll={this.props.handleCheckedAll}
        handleInputChange={this.props.handleInputChange}
      />
    );
  }
}

export default UserAdjusterMappingChild;
