import React, { useState } from 'react';
import { Input, Button, Col, Form, Row } from 'reactstrap';
import PopupModal from 'shared/components/PopupModal';
import FillAnswersModal from './FillAnswersModal';
import AppTable from 'shared/components/table/AppTable';
import { useAuth } from '../hooks/useAuth';
import { ToggleButton } from '../../../../node_modules/react-bootstrap/esm/index';

const SecurityQuestionsModal = ({ isOpen, onToggle, setLoginState, password, setPassword }) => {
  const { securityQuestions, userId, saveSecurityQuestions } = useAuth();
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  var temp = [];
  const renderAnswerColumn = item => {
    return (
      <>
        <Input
          type="text"
          name="answer"
          id="answer"
          onChange={e => {
            e.preventDefault();
            updateAnswer(item.PK_user_question_id, e.target.value);
          }}
        />
      </>
    );
  };

  const updateAnswer = (PK_user_question_id, value) => {
    temp[PK_user_question_id] = value;
    setAnswers(temp);
  }

  const saveHandler = () => {
    if (answers.length != 5) {
      setShowModal(true);
    }
    if (answers != null && answers.length === 5) {
      for (let i = 1; i <= 4; i++) {
        saveSecurityQuestions(answers[i], i, userId);
      }
      onToggle();
      setLoginState('login')
    }
  }

  const handleCancel = () => {
    setLoginState('login');
    setPassword(password);
    onToggle();
  }
  const getModalFooter = () => {
    return (
      <div>
        <Button outline color="primary" type="button" onClick={handleCancel}>
          CANCEL
      </Button>
      &nbsp;
        <Button outline type="button" color="primary" onClick={() => saveHandler()}>
          SAVE
      </Button>
      </div>
    );
  }
  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              Our records indicate you have to set up your security questions.
              </span>
            <br />
            <br />
            <span>Security Questions</span>
            <span style={{ marginLeft: '360px' }}>Limit 25 characters per answer</span>
            <AppTable
              columns={[
                {
                  header: 'Question',
                  rowKey: true,
                  field: 'chr_challengequestion',
                },
                {
                  header: 'Answer',
                  rowKey: true,
                  field: 'answer',
                  render: item => renderAnswerColumn(item),
                }
              ]}
              data={securityQuestions != null ? securityQuestions : []}
            />
          </Col>
        </Row>
        <FillAnswersModal isOpen={showModal} onToggle={() => { setShowModal(false) }} />
      </Form>
    );
  }
  return (
    <PopupModal
      content={getContent()}
      externalToggle={handleCancel}
      title="USER SETTINGS"
      isOpen={isOpen}
      footerContent={getModalFooter()}
      size="lg"
    />
  );
};

export default SecurityQuestionsModal;