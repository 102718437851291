import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button, CustomInput, FormGroup } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';

import { billOperationsSelectors } from '../../bill-operations/store';

import {
  billMaintenanceOperations,
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../store';
import { renderClientName } from '../utils/billMaintenanceUtil';

const USER_ID = '2591'; //TODO: hardcode

class ClientMaintenanceLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteName: '',
      excludeEmployers: true,
    };
  }

  onSiteNameSearchKeyPressHandler = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.findClients();
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  onFindClickHandler = () => {
    this.findClients();
  };

  findClients() {
    const { profile } = this.props;
    const { siteName, excludeEmployers } = this.state;

    this.props.findClients({
      profile,
      siteName,
      excludeEmployers,
      zebraUserId: USER_ID,
    });
  }

  onSelectSiteHandler = item => {
    if (item.int_sitecode === 0) {
      return;
    }
    const { profile } = this.props;
    this.props.loadSiteDetails({
      profile,
      siteId: item.int_sitecode,
      zebraUserId: USER_ID,
    });
  };

  addClient = () => {
    this.props.updateClientRecordsTable([
      {
        int_sitecode: 0,
      },
      ...this.props.clients,
    ]);
  };

  render() {
    return (
      <>
        <Row>
          <Col lg="8">
            <FormGroup>
              <Input
                name="siteName"
                id="siteName"
                value={this.state.siteName}
                onChange={this.handleInputChange}
                onKeyPress={this.onSiteNameSearchKeyPressHandler}
                placeholder="Find Client Records"
                type="text"
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <Button type="button" onClick={this.addClient}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
          <Col lg="2">
            <Button type="button" onClick={this.onFindClickHandler}>
              FIND
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <CustomInput
                id="excludeEmployers"
                name="excludeEmployers"
                type="checkbox"
                checked={this.state.excludeEmployers}
                onChange={this.handleInputChange}
                label="Exclude Employers"
                inline
              />
            </FormGroup>
          </Col>
        </Row>
        <Row id="clientMaintenanceLeftSiteName">
          <Col lg="12" >
            <div className="client-records-table" id="clientMaintenanceLeftSiteNamecol">
              <AppTable
                resizable
                columns={[
                  {
                    header: 'SITE NAME',
                    field: 'siteName',
                    render: renderClientName,
                  },
                ]}
                data={this.props.clients}
                rowClickHandler={this.onSelectSiteHandler}
                sortAble={true}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

ClientMaintenanceLeft.propTypes = {
  clients: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  findClients: PropTypes.func.isRequired,
  loadSiteDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const clientMaintenanceInitialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const initialData = billOperationsSelectors.getInitialData(state);
  return {
    clients: clientMaintenanceInitialData.clients,
    profile: initialData.currentProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  findClients: data => dispatch(billMaintenanceOperations.findClients(data)),
  loadSiteDetails: data =>
    dispatch(billMaintenanceOperations.loadSiteDetails(data)),
  updateClientRecordsTable: data =>
    dispatch(billMaintenanceActions.updateClientRecordsTable(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientMaintenanceLeft);
