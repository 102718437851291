import React, { Component } from 'react';
import { CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';

class ListCheckBoxesPanel extends Component {
  render() {
    return (
      <ListGroup>
        {' '}
        {this.props.data.map((dataObj, index) => (
          <ListGroupItem key={index}>
            <CustomInput
              onChange={this.props.handleInputChange}
              type="checkbox"
              label={dataObj.value}
              id={this.props.panelId + dataObj.id}
              name={dataObj.id}
              checked={dataObj.checked}
            />
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  }
}

ListCheckBoxesPanel.defaultProps = {
  draggable: false,
  data: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default ListCheckBoxesPanel;
