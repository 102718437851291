import PropTypes from "prop-types";
import propTypes from "modules/administration/store/propTypes";

export const ColumnPropType = PropTypes.shape({
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    rowKey: PropTypes.bool,
    field: PropTypes.string,
    renderHeader: PropTypes.func,
    sortAble: PropTypes.bool,
    resizable: PropTypes.bool,
    render: PropTypes.func,
    dropDown: PropTypes.bool,
});

export const ColumnArrayPropType = PropTypes.arrayOf(ColumnPropType).isRequired;
export const DataArrayPropType = PropTypes.arrayOf(PropTypes.object).isRequired;

export const AppTablePaginationPropType = {
    totalItems: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    selectPage: PropTypes.func,
    pageLinkSize: PropTypes.number,
    fast360: PropTypes.bool,
    updateClickRecord: PropTypes.any
};

export const AppTablePropType = {
    columns: ColumnArrayPropType,
    size: PropTypes.string,
    dark: PropTypes.bool,
    striped: PropTypes.bool,
    data: DataArrayPropType,
    clickHandler: PropTypes.func,
    clickTooltip: PropTypes.string,
    selectHandler: PropTypes.func,
    autoPagination: PropTypes.bool,
    totalItems: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    selectPage: PropTypes.func,
    rowClickHandler: PropTypes.func,
    rowDoubleClickHandler: PropTypes.func,
    sortAble: PropTypes.bool,
    sortImmediately: PropTypes.bool,
    sortBy: PropTypes.string,
    resizable: PropTypes.bool,
    rowNumberDescription: PropTypes.func,
    hover: PropTypes.bool,
    showHeader: PropTypes.bool,
    highlightedRow: PropTypes.number,
    taskListData: PropTypes.array,
    billId: PropTypes.number,
    claimant: PropTypes.string,
    mcnsZipCodeM: PropTypes.string,
    isExtended: PropTypes.bool,
    fast360Zip: PropTypes.bool,
    fast360FS: PropTypes.bool,
    fast360UC: PropTypes.bool,
    fast360MCA: PropTypes.bool,
    getSelectedZip: PropTypes.any,
    getSelectedFS: PropTypes.any,
    getSelectedUC: PropTypes.any,
    getSelectedMCA: PropTypes.any,
};

export const AppTableHeaderPropType = {
    columns: ColumnArrayPropType,
    dark: PropTypes.bool,
    isSelectable: PropTypes.bool,
    isClickable: PropTypes.bool,
    sortAble: PropTypes.bool,
    sortImmediately: PropTypes.bool,
    sortBy: PropTypes.string,
    sortColumns: PropTypes.array,
    sort: PropTypes.func,
    draggable: PropTypes.bool,
    resizable: PropTypes.bool,
    resizeColumns: PropTypes.array,
    updateResizeColumns: PropTypes.func,
    onColumnsChange: PropTypes.func,
};

export const AppTableBodyPropType = {
    columns: ColumnArrayPropType,
    data: DataArrayPropType,
    clickHandler: PropTypes.func,
    clickTooltip: PropTypes.string,
    selectHandler: PropTypes.func,
    rowClickHandler: PropTypes.func,
    rowDoubleClickHandler: PropTypes.func,
    highlightedRow: PropTypes.number,
    selectedDecision: PropTypes.string,
    totalFromState: PropTypes.number,
    setRembTotal: PropTypes.func,
    removeRembTotal: PropTypes.func,
    taskListData: PropTypes.array,
    billId: PropTypes.number,
    claimant: PropTypes.string,
    postBillReviewData: PropTypes.func,
    mcnsZipCodeM: PropTypes.string,
    isExtended: PropTypes.bool,
    fast360Zip: PropTypes.bool,
    fast360FS: PropTypes.bool,
    fast360UC: PropTypes.bool,
    fast360MCA: PropTypes.bool,
    getSelectedZip: PropTypes.any,
    getSelectedFS: PropTypes.any,
    getSelectedUC: PropTypes.any,
    getSelectedMCA: PropTypes.any,
};


