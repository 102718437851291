import React, { useState } from 'react';
import AppTable from 'shared/components/table/AppTable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';

class ClaimClientVenNot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      alertData: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.props.setIsNoteOpen(false);
    this.props.setReferralAlertData([]);
    this.props.updateloadReferralAlerts([]);
  };

  render() {
    return (
      <>
        <Modal
          size="md"
          isOpen={this.props.isOpen}
          style={{ height: '300px' }}
          //scrollable={'true'}
        >
          <ModalHeader> Alerts on this Referral</ModalHeader>
          <ModalBody>
            <Form>
              <AppTable
                resizable
                columns={[
                  {
                    header: 'Alert Type',
                    field: 'alertName',
                    percent: '20',
                  },
                  {
                    header: 'Alert Text',
                    percent: '80',
                    field: 'alert',
                  },
                ]}
                data={this.props.alertData || []}
                autoPagination={true}
                // sortAble={true}
                modalHeight={400}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button
              style={{ width: '120px' }}
              color="primary"
              onClick={this.toggle}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ClaimClientVenNot;
