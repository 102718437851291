import React, { Component } from "react";
import { AppTableBodyPropType } from "./AppTablePropTypes";
import Fast360VendorTableBodyRow from "./Fast360VendorTableBodyRow";
import { fast360Actions } from '../../../modules/fast-360/store';
import { connect } from 'react-redux';


class Fast360VendorTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            highlightedRow: -1,
        };
    }


    setSelectedRecords = (data, checked) => {
        let fIndex = this.props.updatedRec.findIndex(item => item.confNo === data.confNo);
        let iData = this.props.initialWorkList;
        let rec = iData.map(item => {
            if (item.confNo === data.confNo) {
                item.selected = checked;
            }
            return item
        });
        this.props.updateNewUsersWorkList(rec);

        if (fIndex === -1) {
            let localRec = this.props.updatedRec;
            localRec.push(data);
            this.props.updateClickRecord(localRec)
        }
        else {
            let localRec = this.props.updatedRec;
            localRec.splice(fIndex, 1);
            this.props.updateClickRecord(localRec)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRows: [] });
    }

    render() {
        const isClickable = !!this.props.clickHandler;
        const isSelectable = !!this.props.selectHandler;
        const isRowClickable = !!this.props.rowClickHandler;
        const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;



        return (
            <>
                {
                    this.props.data && this.props.data.map((dataObject, rowIndex) => {
                        const returnData = !!dataObject.fields ? dataObject.fields : dataObject;
                        return (

                            <Fast360VendorTableBodyRow rowIndex={rowIndex}/* dropdownData={this.dropdownData}*/
                                highlightedRow={this.state.highlightedRow} isClickable={isClickable} isSelectable={isSelectable}
                                isRowClickable={isRowClickable} isRowDoubleClickable={isRowDoubleClickable} {...this.props}
                                returnData={returnData} dataObject={dataObject} taskListData={this.props.taskListData}
                                claimant={this.props.claimant} billId={this.props.billId} mcnsZipCodeM={this.props.mcnsZipCodeM}
                                setSelectedRecords={this.setSelectedRecords} zebraId={this.props.zebraId} dblClickWorklist={this.props.dblClickWorklist}
                            />

                        )
                    })
                }


            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        initialWorkList: state.FAST360.initialData.initialWorkList ? state.FAST360.initialData.initialWorkList : []
    };
};

const mapDispatchToProps = dispatch => ({
    updateClickRecord: data => dispatch(fast360Actions.updateClickRecord(data)),
    updateNewUsersWorkList: data => dispatch(fast360Actions.updateNewUsersWorkList(data))
});

Fast360VendorTableBody.propTypes = AppTableBodyPropType;


export default connect(mapStateToProps, mapDispatchToProps)(Fast360VendorTableBody);
