import PropTypes from 'prop-types';
import React from 'react';

const CityStateZipRenderer = ({ item }) => {
  const city = item.city || '';
  const state = item.state || '';
  const zipCode = item.zipcode || '';
  return (
    <>
      <div>{city}</div>
      <div>
        {state} {zipCode}
      </div>
    </>
  );
};

CityStateZipRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CityStateZipRenderer;
