import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { commonOperations } from 'common/store';

import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';

import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { formatMMDDYYYY } from 'shared/utils/shareUtils';

import ManageAttachmentsModal from './ManageAttachmentsModal';
import UploadFilesModal from './UploadFilesModal';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

const EMAIL_DOMAIN = process.env.REACT_APP_EMAIL_DOMAIN;
const refferal_disable = process.env.REACT_APP_BUTTON_DIABLE

const ReferralButton = ({
  deleteReferralDocuments,
  loadReferralDocuments,
  retrieveReferralDocument,
  loadReferralEmailTemplates,
  loadReferralEmailTemplatesInjury,
  loadReferralSpecialties,
  resetReferral,
  sendEmail,
  alert,
  profile,
  user,
  selectedBills,
  bills,
  claim,
  documents,
  emailTemplates,
  specialties,
  name,
  injuryId,
  envRef,
  resetReferralDocuments,
  fromTaskList 
}) => {
  const [isOpenCreateReferral, setIsOpenCreateReferral] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isOpenAttachFiles, setIsOpenAttachFiles] = useState(false);
  const [isRefreshDocuments, setIsRefreshDocuments] = useState(true);
  const [inputValue, setInputValue] = useState({
    referralType: '',
    serviceType: '',
    emailText: '',
    ccEmail: '',
  });
  const [showServiceTypeInput, setShowServiceTypeInput] = useState(true);
  const [recordId, setRecordId] = useState(0);
  const [tableName, setTableName] = useState("")

  useEffect(() => {
    if (isRefreshDocuments && selectedBills.length > 0) {
      loadReferralDocuments({
        profile,
        billId: selectedBills[0],
        tableName,
      });

      setIsRefreshDocuments(false);
      resetReferralDocuments();
    } else if (isRefreshDocuments) {
      loadReferralDocuments({
        profile,
        billId: recordId,
        tableName,
      });

      setIsRefreshDocuments(false);
    }
  }, [loadReferralDocuments, profile, isRefreshDocuments, selectedBills]);

  const onInputChangeHandler = event => {
    const { target } = event;
    const { name: inputName } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setInputValue({
      ...inputValue,
      [inputName]: value,
    });
  };
  const onInputSpecialhandlechange = event => {
    const { value } = event && event.fields.value;
    setInputValue({
      ...inputValue,
      serviceType: value,
    });
  };

  const onReferralTypeChangeHandler = event => {
    const value = event && event.int_id;
    const templateEmail = R.find(
      item => item.int_id === Number(value),
      emailTemplates
    );
    if (!templateEmail) {
      setInputValue({
        ...inputValue,
        referralType: value,
      });
      return;
    }
    const emailText = () => {
      const phone = user.phone || '';
      const template = (templateEmail.chr_template || '')
        .replace('[Requestor Name]', user.fullname || '')
        .replace('[Requestor E-mail]', user.email || '')
        .replace(
          '[Requestor Phone]',
          `${phone.substr(0, 3)}-${phone.substr(3, 3)}-${phone.substr(5, 4)}`
        );
      var bill = R.find(item => item.id === selectedBills[0], bills);
      var claimId = "";
      var site = "";
      var adjusterName = "";
      var doi = "";
      var client = "";
      var jurIs = "";
      var stateJurIs = "";
      var ssn = "";
      var dob = "";
      var patient = "";
      if (bill != null) {
        claimId = bill.chr_claimnumber;
        if (!bill || !claimId) {
          return template;
        }
        site = bill.site || '';
        adjusterName = bill.adjname || '';
        doi = bill.dat_injurydate || '';
        client = bill.chr_sitename || '';
        jurIs = bill.chr_juris || '';
        stateJurIs = bill.chr_statejuris || '';
        ssn = bill.chr_ssn || '';
        dob = bill.dat_dob || '';
        patient =
          (bill.chr_firstname || '') +
          (bill.chr_lastname === '' ? '' : ' ') +
          (bill.chr_lastname || '');
      } else {
        if (claim != null) {
          claimId = claim.chr_claimnumber;
          site = claim.site || '';
          adjusterName = claim.adjname || '';
          doi = claim.dat_injurydate || '';
          client = claim.chr_sitename || '';
          jurIs = claim.chr_juris || '';
          stateJurIs = claim.chr_statejuris || '';
          ssn = claim.chr_ssn || '';
          dob = claim.dat_dob || '';
          patient =
            (claim.chr_firstname || '') +
            (claim.chr_lastname === '' ? '' : ' ') +
            (claim.chr_lastname || '');
        }
      }
      
      return template
        .replace('[Now()]', formatMMDDYYYY(Date.now()))
        .replace('[Site]', site)
        .replace('[claimnumber]', claimId)
        .replace('[adjuster]', adjusterName)
        .replace('[DOI]', doi)
        .replace('[Client]', client)
        .replace('[Line of Business]', jurIs)
        .replace('[Jurisdiction]', stateJurIs)
        .replace('[Claimant ssn#]', ssn)
        .replace('[Date of Birth]', dob)
        .replace('[patient]', patient);
    };
    setInputValue({
      ...inputValue,
      referralType: value,
      emailText: emailText(),
    });
    if (
      templateEmail.element.indexOf('IME') !== -1 ||
      templateEmail.element.indexOf('Peer') !== -1
    ) {
      setShowServiceTypeInput(true);
      loadReferralSpecialties({
        profile,
        currentUserId: user.id,
      });
      return;
    }
    setShowServiceTypeInput(false);
  };

  const toggleCreateReferral = () => {
    setIsOpenCreateReferral(!isOpenCreateReferral);
    resetReferralDocuments();
  };

  const toggleUploadFiles = () => {
    setIsOpenUploadFiles(!isOpenUploadFiles);
  };

  const toggleAttachFiles = () => {
    setIsOpenAttachFiles(!isOpenAttachFiles);
  };

  const getCcList = () => {
    return [inputValue.ccEmail ? inputValue.ccEmail : ''];
  };

  const getFileList = () => {
    const fileList = [];

    if (documents.length > 0) {
      R.forEach(item => {
        fileList.push(item.path.split('/').pop());
      }, documents);
    }
    return fileList;
  };

  const getSubject = () => {
    if (showServiceTypeInput) {
      return `${inputValue.serviceType} - Referral Service Requested From Zebra Bill Review`;
    }
    return 'Referral Service Requested From Zebra Bill Review';
  };

  const getBody = () => {
    return documents.length > 0
      ? `The EOR and Medical documents have been attached to this email. \n${inputValue.emailText}`
      : inputValue.emailText;
  };

  const sendReferral = () => {
    const templateEmail = R.find(
      item => item.int_id === Number(inputValue.referralType),
      emailTemplates
    );

    if (!templateEmail) {
      return;
    }

    const email = {
      replyList: `DoNotReply@${EMAIL_DOMAIN}`,
      to: [templateEmail.chr_notificationlink],
      ccList: getCcList(),
      fileList: getFileList(),
      email: [`DoNotReply@${EMAIL_DOMAIN}`],
      subject: getSubject(),
      body: getBody(),
    };
    console.log(email);

    sendEmail(email);

    R.forEach(
      item => deleteReferralDocuments({ documentId: item.id }),
      documents
    );

    setTimeout(() => {
      toggleCreateReferral();
      setIsRefreshDocuments(true);
    }, 1000);
  };

  const openCreateReferral = () => {
    setInputValue({
      ...inputValue,
      ccEmail: user.email || '',
    });
    if (selectedBills.length < 1) {
      /*
      if (bills.length > 0) {
        setRecordId(bills[0].int_billid);
      } else {
        setRecordId(0);
      }
      */
      setRecordId(parseInt(claim.int_claimcode));
      setTableName(profile + "_claim_referral");
      loadReferralEmailTemplatesInjury({
        profile,
        currentUserId: user.id,
        injuryId: injuryId,
      });
    } else {
      setRecordId(selectedBills[0]);
      setTableName(profile + "_bill_referral");
      loadReferralEmailTemplates({
        profile,
        currentUserId: user.id,
        billId: selectedBills[0],
      });
    }
    toggleCreateReferral();
    //setIsRefreshDocuments(true);
  };

  const getCreateReferralBody = () => {
    const attachFilesLabel =
      documents.length < 1
        ? 'No attachments have been selected.'
        : ` ${documents.length} documents have been selected.`;

    return (
      <>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="referralType">Select Referral Type</Label>
              {/* <Input
                type="select"
                name="referralType"
                id="referralType"
                value={inputValue.referralType}
                onChange={onReferralTypeChangeHandler}
              >
                <option key="0" value="" disabled>
                  Choose...
                </option>
                {emailTemplates.map((emailTemplate, index) => (
                  <option key={index} value={emailTemplate.int_id}>
                    {emailTemplate.element}
                  </option>
                ))}
              </Input> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                placeholder="Choose..."
                onChange={onReferralTypeChangeHandler}
                name="referralType"
                id="referralType"
                options={
                  emailTemplates.length
                    ? emailTemplates.map(
                      obj => assign(obj, { label: obj.element, value: obj.int_id })
                    )
                    : []
                }
              />
            </FormGroup>
          </Col>
          {showServiceTypeInput && (
            <Col lg={6}>
              <FormGroup>
                <Label for="serviceType">Select Service</Label>
                {/* <Input
                  type="select"
                  name="serviceType"
                  id="serviceType"
                  value={inputValue.serviceType}
                  onChange={onInputChangeHandler}
                >
                  <option key="0" value="" disabled>
                    Choose...
                  </option>
                  {specialties.map((specialty, index) => (
                    <option key={index} value={specialty.fields.value}>
                      {specialty.fields.value}
                    </option>
                  ))}
                </Input> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Choose..."
                  onChange={onInputSpecialhandlechange}
                  name="serviceType"
                  id="serviceType"
                  options={
                    specialties.length
                      ? specialties.map(
                        obj => assign(obj, { label: obj.fields.value, value: obj.fields.value })
                      )
                      : []
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup>
              <Input
                type="textarea"
                name="emailText"
                id="emailText"
                rows={10}
                spellCheck
                value={inputValue.emailText}
                onChange={onInputChangeHandler}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Button type="button" onClick={toggleUploadFiles}>
                Select Attachments
              </Button>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormText>{attachFilesLabel}</FormText>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <FormGroup row>
              <Label for="ccEmail" lg={3}>
                CC Email:
              </Label>
              <Col lg={9}>
                <Input
                  type="email"
                  name="ccEmail"
                  id="ccEmail"
                  value={inputValue.ccEmail}
                  onChange={onInputChangeHandler}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  };

  const getCreateReferralFooter = () => {
    return (
      <Button type="button" color="danger" onClick={sendReferral}>
        Send Referral Request
      </Button>
    );
  };

  const resetReferralModal = () => {
    setInputValue({
      referralType: '',
      serviceType: '',
      emailText: '',
      ccEmail: '',
    });
    resetReferral();
  };

  return (
    <>
      <AppTooltip
        text="Create and send referrals from the bills selected"
        id={`btn${name}`}
      >
        <Button
          style={{ width: '100%' }}
          type="button"
          size="md"
          onClick={openCreateReferral}
          disabled={fromTaskList !==undefined && fromTaskList ?
            false
            :(envRef===undefined || envRef===false) ? 
            selectedBills.length<1?true
            :false:false} 
        >
          CREATE REFERRAL
        </Button>
      </AppTooltip>
      <PopupModal
        content={getCreateReferralBody()}
        title="Create a Referral"
        externalToggle={toggleCreateReferral}
        isOpen={isOpenCreateReferral}
        footerContent={getCreateReferralFooter()}
        onClosed={resetReferralModal}
        size="lg"
      />
      <UploadFilesModal
        recordId={recordId}
        isOpen={isOpenUploadFiles}
        onToggle={() => {
          toggleUploadFiles();
          toggleAttachFiles();
        }}
        onUpload={async () => {
          setTimeout(() => {
            setIsRefreshDocuments(true);
            resetReferralDocuments();
          }, 2000);

          toggleUploadFiles();
          toggleAttachFiles();
        }}
        tableName={tableName}
      />
      <ManageAttachmentsModal
        isOpen={isOpenAttachFiles}
        onAttach={() => {
          toggleAttachFiles();
          toggleUploadFiles();
        }}
        onDelete={async checkedItems => {
          await Promise.all([
            checkedItems.map(item => {
              const documentId = item.id;

              return deleteReferralDocuments({ documentId });
            }),
          ]).then(() => {
            setTimeout(() => {
              setIsRefreshDocuments(true);
              resetReferralDocuments();
            }, 2000);
          });
        }}
        onRetrieve={data => retrieveReferralDocument(data)}
        onToggle={toggleAttachFiles}
      />
    </>
  );
};

ReferralButton.propTypes = {
  deleteReferralDocuments: PropTypes.func.isRequired,
  loadReferralDocuments: PropTypes.func.isRequired,
  retrieveReferralDocument: PropTypes.func.isRequired,
  loadReferralEmailTemplates: PropTypes.func.isRequired,
  loadReferralEmailTemplatesInjury: PropTypes.func.isRequired,
  loadReferralSpecialties: PropTypes.func.isRequired,
  resetReferral: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  bills: PropTypes.array.isRequired,
  claim: PropTypes.object,
  selectedBills: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  specialties: PropTypes.array.isRequired,
  injuryId: PropTypes.string,
  resetReferralDocuments: PropTypes.func,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  const referralState = billOperationsSelectors.getReferralState(state);
  return {
    profile: initialData.currentProfile,
    user,
    documents: referralState.documents,
    emailTemplates: referralState.emailTemplates,
    specialties: referralState.specialties,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteReferralDocuments: data =>
    dispatch(billOperationsOperations.deleteReferralDocuments(data)),
  loadReferralDocuments: data =>
    dispatch(billOperationsOperations.loadReferralDocuments(data)),
  resetReferralDocuments: () => dispatch(billOperationsActions.resetReferralDocuments()),
  retrieveReferralDocument: data =>
    dispatch(billOperationsOperations.retrieveReferralDocument(data)),
  loadReferralEmailTemplates: data =>
    dispatch(billOperationsOperations.loadReferralEmailTemplates(data)),
  loadReferralEmailTemplatesInjury: data =>
    dispatch(billOperationsOperations.loadReferralEmailTemplatesInjury(data)),
  loadReferralSpecialties: data =>
    dispatch(billOperationsOperations.loadReferralSpecialties(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  resetReferral: () => dispatch(billOperationsActions.resetReferral()),
  sendEmail: data => dispatch(billOperationsOperations.sendEmail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralButton);