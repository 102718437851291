import * as R from 'ramda';
import helper from 'helper/helper';

const getBaseStateData = userId => {
  return helper.request.post('/BaseStateData',{ userId });
};

const getInitialData = async userId => {
  const baseStateDataResponse = await getBaseStateData(userId);
  const baseStateData = baseStateDataResponse.data;
  const profile = getValueByKey(baseStateData.currentProfile || [], 'profile');
  return {
    profile,
  };
};

const findClients = async data => {
  const body = {
    profile: data.profile,
    siteName: data.siteName,
    zebraUserId: data.zebraUserId,
  };
  if (data.excludeEmployers) {
    body.functionName = 'siteLookUpNoEmp';
  } else {
    body.functionName = 'siteLookUp';
  }

  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : [];
};

const findGroupClients = async data => {
  let body;
  if (data.siteLookUpByDB) {
     body = {
      profile: data.profile,
      db: data.db,
      siteName: data.siteName,
      zebraUserId: data.zebraUserId,
    }
    body.functionName = 'siteLookUpByDB';
  } else {
     body = {
      profile: data.profile,
      siteName: data.siteName,
      zebraUserId: data.zebraUserId,
    };
    if (data.excludeEmployers) {
      body.functionName = 'siteLookUpNoEmp';
    } else {
      body.functionName = 'siteLookUp';
    }
  }

  const response = await helper.request.post('/loadGuiZebraInterfaceloadArrayData', body);
  return response.data.obj && response.data.obj.length > 0
  ? response.data.obj
  : [];
};

const loadSiteDetails = async data => {
  const body = {
    functionName: 'loadSiteDetail',
    profile: data.profile,
    siteId: data.siteId,
    zebraUserId: data.zebraUserId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  const responseData = response.data.length > 0 ? response.data[0] : {};
  return responseData.fields || {};
};
const saveSiteDetails = async data => {
  const body = {
    functionName: 'saveSite',
    profile: data.profile,
    zebraUserId: data.zebraUserId,
    site: data.response
  };
  
  const [
    siteDetailsResponse
  ] = await Promise.all([
    helper.request.post('/loadGuiDaosaveObjectData', body)
  ]);

  const body1 = {
    functionName: 'loadSiteDetail',
    profile: data.profile,
    siteId: data.response.id,
    zebraUserId: data.zebraUserId,
  };

  const [loadSiteDetailsResponse] = await Promise.all([helper.request.post('/loadGuiDaoloadArrayData', body1)]);
  // const responseData = response.data.length > 0 ? response.data[0] : {};
  // return responseData.fields || {};
  var responseData = "";
  if(siteDetailsResponse.data == 1) {
    responseData = loadSiteDetailsResponse.data.length > 0 ? loadSiteDetailsResponse.data[0] : {};
  } else {
   responseData = siteDetailsResponse.data;
  }
 return responseData.fields || {};
};

const loadClientComboBoxes = async data => {
  const body = {
    functionName: 'loadClientComboBoxes',
    profile: data.profile,
    zebraUserId: data.zebraUserId,
  };
  const response = await helper.request.post('/loadGuiDaoloadArrayData', body);
  return response.data.length > 0 ? response.data[0] : {};
};

export const getValueByKey = (data, key) => {
  const resultItem = R.find(item => item.hasOwnProperty(key), data);
  return resultItem ? resultItem[key] : '';
};
const getSLPProfiles = async body => {
  body['functionName'] = 'loadSLPProfiles';
  const response = await helper.request.post('/loadGuiZebraInterfaceloadArrayData', body);
  return response.data.obj && response.data.obj.length > 0
  ? response.data.obj
  : [];
};
const lookUpClientRecords = async body => {
  body['functionName'] = 'groupLookup';
  const response = await helper.request.post(
    '/loadGuiZebraInterfaceloadArrayData',
    body
  );
  return response.data.obj && response.data.obj.length > 0
    ? response.data.obj
    : [];
};
const loadGroupDetails = async body => {
  body['functionName'] = 'loadLinkedUsers';
  const response = await helper.request.post(
    '/loadGuiZebraInterfaceloadArrayData',
    body
  );
  return response.data.obj && response.data.obj.length > 0
    ? response.data.obj
    : [];
};

const saveClientRecords = async body => {
  body['functionName'] = 'saveAdjusterGroup';
  const response = await helper.request.post(
    '/SaveGuiZebraInterfaceloadArrayData',
    body
  );
  return response != null ? response : [];
};

const loadStateCityByZipCode = async data => {
  const body = {
    profile: data.profile,
    userId: data.userId,
    zipcode: data.zipCode,
  };
  const response = await helper.request.post('/slpZipCodeLookup', body);
  const responseData = response.data.result || [];
  const stateCity =
    (responseData.length > 0 ? responseData[0] : {}).fields || {};
  const city = stateCity.city || '';
  const state = ((stateCity.state || {}).fields || {}).abbreviation || '';
  return {
    city,
    state,
  };
};

const loadSiteTree = async data => {
  const body = {
    functionName: 'loadReactSiteTree',
    profile: data.profile,
    siteId: data.siteName,
    zebraUserId: data.zebraUserId,
  };
  const response = await helper.request.post('/loadReactSiteTree', body);
  return response.data && response.data.length > 0
    ? response.data
    : [];
};


export default {
  getInitialData,
  findClients,
  findGroupClients,
  getSLPProfiles,
  lookUpClientRecords,
  saveClientRecords,
  loadGroupDetails,
  loadSiteDetails,
  loadClientComboBoxes,
  loadStateCityByZipCode,
  saveSiteDetails,
  loadSiteTree,
};
