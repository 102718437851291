import {
  faFolder,
  faCircle,
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import AppTree from 'shared/components/tree/AppTree';
import { countDaysDiff } from 'shared/utils/shareUtils';

const BOARD_CERTIFICATION = 'Board Certification';
const CLINICAL_PRACTICE_STATUS = 'Clinical Practice Status';
const LICENSE = 'License';
const ACADEMIC_AFFILIATIONS = 'Academic Affiliations';
const PROCEDURES_DISEASES_TREATMENTS = 'Procedures/diseases/treatments';
const SANCTION = 'Sanction';
const HOSPITAL_PRIVILEGES = 'Hospital Privileges';
const FELLOWSHIP_TRAINING_INFORMATION = 'Fellowship Training Information';
const PROFESSIONAL_LIABILITY_INSURANCE = 'Professional Liability Insurance';

const ExpireIcon = ({ checkActive, item }) => {
  const EXPIRED = 0;
  const ABOUT_TO_EXPIRED = 15;

  if (checkActive) {
    if (item.active === false) {
      return (
        <FontAwesomeIcon
          style={{ marginLeft: 2, marginRight: 8 }}
          className="red-text"
          icon={faTimes}
        />
      );
    }
  }

  const expirationDate = item.expirationdate || '';
  if (expirationDate === '') {
    return (
      <FontAwesomeIcon
        style={{ marginRight: 5 }}
        className="green-text"
        icon={faCircle}
      />
    );
  }

  const expireDate = new Date(expirationDate);
  const currentDate = new Date();
  const daysDiff = countDaysDiff(expireDate, currentDate);
  if (daysDiff < EXPIRED) {
    return (
      <FontAwesomeIcon
        style={{ marginRight: 5 }}
        className="red-text"
        icon={faCircle}
      />
    );
  }

  if (daysDiff < ABOUT_TO_EXPIRED) {
    return (
      <FontAwesomeIcon
        style={{ marginRight: 5 }}
        className="yellow-text"
        icon={faCircle}
      />
    );
  }

  return (
    <FontAwesomeIcon
      style={{ marginRight: 5 }}
      className="green-text"
      icon={faCircle}
    />
  );
};

const groupModeCredentials = [
  'board certification',
  'license',
  'professional liability insurance',
  'procedures/diseases/treatments',
];

class ExpertCredentialsCard extends Component {
  appendExpirationDate(credential) {
    const expirationDate = credential.expirationdate || '';
    return expirationDate !== '' ? ` - ${expirationDate}` : '';
  }

  getAcademicAffiliations(credential) {
    const name = credential.credential || '';
    const title = credential.title || '';
    return (
      <span>
        {name}
        {title !== '' && `: ${title}`}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getBoardCertification(credential) {
    const circle = <ExpireIcon checkActive={true} item={credential} />;
    const path = credential.path || '';
    const name = credential.credential || '';
    const pathComponents = path.split('_');
    if (pathComponents.length < 3) {
      return name;
    }
    const [firstPart, secondPart, ...otherPart] = pathComponents;
    if (firstPart !== BOARD_CERTIFICATION) {
      return (
        <span>
          {circle}
          {name}
          {this.appendExpirationDate(credential)}
        </span>
      );
    }
    const otherPartRenderer =
      otherPart.length > 0 ? `: ${otherPart.join(' - ')}` : '';
    return (
      <span>
        {circle}
        {secondPart}
        {otherPartRenderer}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getClinicalPracticeStatus(credential) {
    const name = credential.credential || '';
    const hourPerWeek = credential.hoursperweek || '';
    return (
      <span>
        {name}
        {hourPerWeek !== '' && `: ${hourPerWeek} hrs/wk`}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getFellowshipTrainingInformation(credential) {
    const name = credential.credential || '';
    const subject = credential.subject;
    return (
      <span>
        {name}
        {subject !== '' && `: ${subject}`}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getHospitalPrivileges(credential) {
    const state = credential.state || '';
    const name = credential.credential || '';
    const circle =
      credential.active === false ? (
        <FontAwesomeIcon
          style={{ marginLeft: 2, marginRight: 8 }}
          className="red-text"
          icon={faTimes}
        />
      ) : (
        ''
      );
    return (
      <span>
        {circle}
        {name}
        {state !== '' && ` - ${state}`}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getLicense(credential) {
    const state = credential.state || '';
    const name = credential.credential || '';
    return (
      <span>
        <ExpireIcon checkActive={true} item={credential} />
        {state !== '' && `${state}`}
        {name}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getProceduresDiseasesTreatments(credential) {
    return (
      <span>
        {credential.credential || ''}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getProfessionalLiabilityInsurance(credential) {
    const name = credential.credential || '';
    return (
      <span>
        <ExpireIcon checkActive={false} item={credential} />
        {name}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getSanction(credential) {
    const name = credential.credential || '';
    const verificationDate = credential.verificationdate || '';
    const state = credential.state || '';
    const circle =
      credential.sanction === true ? (
        <FontAwesomeIcon
          style={{ marginRight: 5 }}
          className="red-text"
          icon={faExclamationTriangle}
        />
      ) : (
        ''
      );
    return (
      <span>
        {circle}
        {name}
        {state !== '' && ` - ${state}`}
        {verificationDate !== '' && ` - ${verificationDate}`}
        {this.appendExpirationDate(credential)}
      </span>
    );
  }

  getCredential(credential) {
    switch (credential.credentialtype) {
      case ACADEMIC_AFFILIATIONS:
        return this.getAcademicAffiliations(credential);
      case BOARD_CERTIFICATION:
        return this.getBoardCertification(credential);
      case CLINICAL_PRACTICE_STATUS:
        return this.getClinicalPracticeStatus(credential);
      case FELLOWSHIP_TRAINING_INFORMATION:
        return this.getFellowshipTrainingInformation(credential);
      case HOSPITAL_PRIVILEGES:
        return this.getHospitalPrivileges(credential);
      case LICENSE:
        return this.getLicense(credential);
      case PROCEDURES_DISEASES_TREATMENTS:
        return this.getProceduresDiseasesTreatments(credential);
      case PROFESSIONAL_LIABILITY_INSURANCE:
        return this.getProfessionalLiabilityInsurance(credential);
      case SANCTION:
        return this.getSanction(credential);
      default:
        return credential.credential || '';
    }
  }

  render() {
    const data = this.props.expert.credentialTypes
      .filter(
        item =>
          !this.props.groupMode ||
          groupModeCredentials.includes(item.element.toLowerCase())
      )
      .sort((item1, item2) => item1.element.localeCompare(item2.element))
      .map(credentialType => {
        const credentials = this.props.expert.credentials
          .filter(
            credential => credential.credentialtype === credentialType.element
          )
          .map(credential => {
            return {
              name: (
                <span style={{ marginLeft: 21 }}>
                  {this.getCredential(credential)}
                </span>
              ),
            };
          });
        const name = (
          <span style={{ marginLeft: credentials.length > 0 ? 0 : 19 }}>
            <FontAwesomeIcon
              className="yellow-text"
              style={{ marginRight: 5 }}
              icon={faFolder}
            />
            {credentialType.element} ({credentials.length})
          </span>
        );
        const item = {
          name,
          toggled: false,
        };
        if (credentials.length > 0) {
          item.children = credentials;
        }

        return item;
      });

    return (
      <Card className="fix-height-card">
        <CardHeader>Credentials</CardHeader>
        <CardBody>
          <AppTree data={data} />
        </CardBody>
      </Card>
    );
  }
}

ExpertCredentialsCard.propTypes = {
  expert: PropTypes.shape({
    credentials: PropTypes.array.isRequired,
    credentialTypes: PropTypes.array.isRequired,
  }).isRequired,
  groupMode: PropTypes.bool.isRequired,
};

export default ExpertCredentialsCard;
