import React, { Component } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { SelectableCell } from './SelectableCell';
import { ClickableCell } from './ClickableCell';
import { Button } from 'bootstrap';
import { formatPhoneNumber } from 'shared/utils/numberUtils';

class AppTableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      highlightedRow:
        props.highlightedRow !== undefined ? props.highlightedRow : -1,
    };
  }

  toggleSelectedRow = (id, checked) => {
    this.setState(prevState => {
      return checked
        ? this.addRowFromState(prevState, id)
        : this.removeRowFromState(prevState, id);
    }, this.executeSelectHandler);
  };

  removeRowFromState = (state, id) => {
    return this.updateSelectedRows(
      state,
      state.selectedRows.filter(row => row !== id)
    );
  };

  addRowFromState = (state, id) => {
    return this.updateSelectedRows(state, state.selectedRows.concat(id));
  };

  updateSelectedRows = (state, selectedRows) => {
    return {
      ...state,
      selectedRows,
    };
  };

  executeSelectHandler = () => {
    const selectedRows = this.state.selectedRows.map(id => {
      return this.props.data[id];
    });
    this.props.selectHandler(selectedRows);
  };

  componentWillReceiveProps(nextProps) {
    this.state.selectedRows &&
      this.state.selectedRows.length &&
      this.setState({ selectedRows: [] });
    if (this.props.highlightedRow !== nextProps.highlightedRow) {
      this.setState({ highlightedRow: nextProps.highlightedRow })
    }
  }

  onRowClicked = (dataObject, rowIndex, event) => {
    if (event.target.tagName === 'TD') {
      this.props.rowClickHandler(dataObject, rowIndex);
      this.setState({
        highlightedRow: rowIndex,
      });
    }
  };

  handleRowClick = (dataObject, rowIndex, event) => {
    const isRowClickable = !!this.props.rowClickHandler;
    if (isRowClickable) {
      this.onRowClicked(dataObject, rowIndex, event);
    }
    if (this.props.fast360Zip)
      this.props.getSelectedZip(dataObject.fields.owcpCFactor);
    if (this.props.fast360FS) this.props.getSelectedFS(dataObject.ssSchedule);
    if (this.props.fast360UC) this.props.getSelectedUC(dataObject.uc80);
    if (this.props.fast360MCA) this.props.getSelectedMCA(dataObject.ssSchedule);
  };

  render() {
    const isClickable = !!this.props.clickHandler;
    const isSelectable = !!this.props.selectHandler;
    const isRowClickable = !!this.props.rowClickHandler;
    const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;

    return (
      <tbody>
        {this.props.data.length > 0 &&
          this.props.data.map((dataObject, rowIndex) => {
            const returnData = dataObject && !!dataObject.fields
              ? dataObject.fields
              : (dataObject || {});

            return (
              <tr
                key={rowIndex}
                // style={
                //   this.state.highlightedRow === rowIndex
                //     ? { backgroundColor: '#cdf0f9' }
                //     : null
                // }
                style={
                  dataObject.approvalStatus !== undefined &&
                    dataObject.approvalStatus === 'P'
                    ? { opacity: 0.5, pointerEvents: 'none' }
                    : this.state.highlightedRow === rowIndex
                      ? { backgroundColor: '#cdf0f9' }
                      : null
                }
                onDoubleClick={
                  isRowDoubleClickable
                    ? () => this.props.rowDoubleClickHandler(returnData, rowIndex)
                    : null
                }
                onClick={event =>
                  this.handleRowClick(dataObject, rowIndex, event)
                }
              >
                {isSelectable ? (
                  <SelectableCell
                    rowIndex={rowIndex}
                    selectHandler={this.toggleSelectedRow}
                    selected={this.state.selectedRows.includes(rowIndex)}
                  />
                ) : null}
                {this.props.columns.map((column, columnIndex) => {
                  if (column.render) {
                    if (column.rowKey) {
                      return (
                        <th key={columnIndex} scope="row">
                          {column.render(dataObject, rowIndex)}
                        </th>
                      );
                    }

                    return (
                      <td key={columnIndex}>
                        {column.render(dataObject, rowIndex)}
                      </td>
                    );
                  }
                  if (column.buttons && column.buttons.length > 0) {
                    return (
                      <td>
                        {column.buttons.map((buttonItem, ind) => {
                          return (
                            <div>
                              <button
                                type="button"
                                style={{
                                  width: '100%',
                                  color: '#ffffff',
                                  backgroundColor: '#4b5770',
                                  borderColor: '#465169',
                                  marginBottom: '5px',
                                  borderRadius: '3px',
                                }}
                                onClick={() => {
                                  buttonItem.click(dataObject);
                                }}
                              >
                                {buttonItem.name}
                              </button>
                            </div>
                          );
                        })}
                      </td>
                    );
                  }

                  if (returnData === dataObject) {
                    if (column.rowKey) {
                      return (
                        <th key={columnIndex} scope="row">
                          {dataObject[column.field] || ''}
                        </th>
                      );
                    }
                    if (
                      column.field &&
                      column.field == 'Address' &&
                      !dataObject[column.field]
                    ) {
                      return (
                        <td>
                          <div>{dataObject.chr_street1 || ''}</div>
                          <div>
                            {dataObject.chr_city || ''}{' '}
                            {dataObject.chr_state || ''},{' '}
                            {dataObject.chr_zipcode || ''}{' '}
                            {dataObject.chr_county || ''}
                          </div>
                          <div>
                            ph: {dataObject.chr_phoneprimary ? formatPhoneNumber(dataObject.chr_phoneprimary) : ''} email:{' '}
                            {dataObject.chr_emailprimary || ''}
                          </div>
                        </td>
                      );
                    } else if (column.field && column.field == 'chr_firmname') {
                      return (
                        <td>
                          <div>{dataObject.chr_firmname || ''}</div>
                          <div style={{ color: 'red' }}>
                            {dataObject.txt_alert || ''}
                          </div>
                          <div style={{ color: 'red' }}>
                            {dataObject.dbaname || ''}
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={columnIndex}>
                          {dataObject[column.field] || ''}
                        </td>
                      );
                    }
                  }

                  if (returnData === dataObject.fields) {
                    if (column.rowKey) {
                      return (
                        <th key={columnIndex} scope="row">
                          {dataObject[column.field] || ''}
                        </th>
                      );
                    }

                    return (
                      <td key={columnIndex}>
                        {dataObject.fields[column.field] ||
                          dataObject.fields.dateRange[column.field] ||
                          ''}
                      </td>
                    );
                  }
                })}
                {isClickable ? (
                  <ClickableCell
                    clickHandler={() => this.props.clickHandler(dataObject)}
                    location="/administration/user"
                  />
                ) : null}
              </tr>
            );
          })}
      </tbody>
    );
  }
}

AppTableBody.propTypes = AppTableBodyPropType;

export default AppTableBody;