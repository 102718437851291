import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { fast360Selectors, fast360Operations, fast360Actions } from '../store';


const Fast360SideNav = ({ open, patientData, refData, adjData, caseManData, phyData, attData, empData, serviceData, updatedClaimInfo, updatedRefInfo
    , updatedAdjusterInfo, updatedCaseManagerInfo, sptSaveinfo, trnsaveInfo, trnTableDetails, sptTableDetails, trnDetailModaldata, updatedPhysicianInfo, updatedAttorneyInfo, updatedEmployerInfo, loadClaimantByIdInfo, setReqFields, populatedRef, isFromLanding, newTrnData, newSptData }) => {
    const [fields, setFields] = useState([]);
    
    useEffect(() => {
        let serviceReqFields = [];
        let patientFields = isFromLanding ? getPatientFields(undefined) : getPatientFields(patientData);
        let refFields = populatedRef || isFromLanding ? getRefFields(undefined) : getRefFields(refData);
        let AdjFields = populatedRef || isFromLanding ? getAdjFields(undefined) : getAdjFields(adjData);
        let caseManDataFields = isFromLanding ? getCaseManData(undefined) : getCaseManData(caseManData);
        let phyFields = isFromLanding ? getPhyFields(undefined) : getPhyFields(phyData);
        let attFields = isFromLanding ? getAttFields(undefined) : getAttFields(attData);
        let empFields = isFromLanding ? getEmpFields(undefined) : getEmpFields(empData);
        if (!sptSaveinfo && sptTableDetails.length == 0 && !serviceReqFields.includes('At least one SPECIAL TRANSPORTATION LEG is required.'))
            serviceReqFields.push('At least one SPECIAL TRANSPORTATION LEG is required.');
        if (!trnsaveInfo && trnTableDetails.length == 0 && !serviceReqFields.includes('At least one TRANSPORTATION LEG is required.'))
            serviceReqFields.push('At least one TRANSPORTATION LEG is required.');
        const serviceFields = serviceData?.hasOwnProperty('selectedService') ? serviceData?.selectedService : serviceData;
        if (serviceFields?.module == "ACC") {

            if (serviceFields?.categoryId == "") serviceReqFields.push('SUB PRODUCT is a required field');
            if (serviceFields?.apptTypeId == "") serviceReqFields.push('APPOINTMENT TYPE is a required field');
            if (!serviceFields.checkInDate || serviceFields?.checkInDate == "") serviceReqFields.push('CHECK IN DATE is a required field');
            if (!serviceFields.checkOutDate || serviceFields?.checkOutDate == "") serviceReqFields.push('CHECK OUT DATE is a required field');
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');
        }
        if (serviceFields?.module == "DGN") {
            if (!serviceFields.examType || serviceFields?.examType == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');
            
           
            if (!(serviceFields.medicalQuestions && 
                serviceFields.medicalQuestions?.brainHrtSpinInd !== "" &&
                serviceFields.medicalQuestions?.claustrophobicInd !== "" &&
                serviceFields.medicalQuestions?.metalImplantsInd !== "" && 
                serviceFields.medicalQuestions?.cancerInd !== "" && 
                serviceFields.medicalQuestions?.metalWrkInd !== "" && 
                serviceFields.medicalQuestions?.diabeticInd !== "" && 
                serviceFields.medicalQuestions?.pregnancyInd !== "" && 
                serviceFields.medicalQuestions?.rentalKidneyProbInd !== "" && 
                serviceFields.medicalQuestions?.surgeryInd !== "" && 
                serviceFields.medicalQuestions?.iodineAllergyInd !== "" && 
                serviceFields.medicalQuestions?.patientWeight !== "" && 
                serviceFields.medicalQuestions?.patientHeight !== "")
                ) {
                    serviceReqFields.push('MEDICAL QUESTIONS is a required field.');
                }
        }
        if (serviceFields?.module == "AUD") {
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');
        }
        if (serviceFields?.module == "DTL") {
            if (!serviceFields.serviceAddress.street1 || serviceFields.serviceAddress.street1 == "") serviceReqFields.push('SERVICE ADDRESS is a required field');
            if (!serviceFields.serviceAddress.zipCode?.zip || serviceFields.serviceAddress.zipCode?.zip == "") serviceReqFields.push('ZIPCODE is a required field');
            if (!serviceFields.serviceAddSameAsClmnt && serviceFields.serviceAddress.phone.trim() == "") serviceReqFields.push('LOCATION PHONE is a required field');
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');

        }
        if (serviceFields?.module == "SPT") {
            if (!serviceFields.specialTransportationCategoryId || serviceFields?.specialTransportationCategoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (sptTableDetails.length == 0 && !serviceReqFields.includes('At least one SPECIAL TRANSPORTATION LEG is required.')) serviceReqFields.push('At least one SPECIAL TRANSPORTATION LEG is required.');

        }
        if (serviceFields?.module == "TRN") {
            if (!serviceFields.transportationCategoryId || serviceFields?.transportationCategoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (!serviceFields.apptTypeId || serviceFields?.apptTypeId == "") serviceReqFields.push('APPOINTMENT TYPE is a required field');
            if (trnTableDetails.length == 0 && !serviceReqFields.includes('At least one TRANSPORTATION LEG is required.')) serviceReqFields.push('At least one TRANSPORTATION LEG is required.');
        }
        if (serviceFields?.module == "MSE") {
            if (!serviceFields.categoryId || serviceFields?.categoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
        }
        if (serviceFields?.module == "MOD") {
            if (!serviceFields.modCategoryId || serviceFields?.modCategoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');
        }
        if (serviceFields?.module == "INT") {
            if (!serviceFields?.translationCategoryId || serviceFields?.translationCategoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (!serviceFields.fromLanguageId || serviceFields?.fromLanguageId == "") serviceReqFields.push('FROM LANGUAGE is a required field');
            if (!serviceFields.languageId || serviceFields?.languageId == "") serviceReqFields.push('TARGET LANGUAGE is a required field');
            if (!serviceFields.aptAMPM) {
                if (serviceFields?.translationCategoryId === 1391 || serviceFields?.translationCategoryId === 1392 ||
                    serviceFields?.translationCategoryId === 898 || serviceFields?.translationCategoryId === 900 || 
                    serviceFields?.translationCategoryId === 1689
                ) {
                    serviceReqFields.push('AM/PM is a required field')
                }
            }
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');

            if (serviceFields?.subService == "Certified Legal - On-Site Interpretation" ||
                serviceFields?.subService == "Certified Medical – On-Site Interpretation" || serviceFields?.subService == "On-Site Interpretation") {
                if (!serviceFields.facility || serviceFields.facility.phone == "") serviceReqFields.push('FACILITY PHONE is a required field');
                if (!serviceFields.facility || serviceFields.facility.name == undefined || serviceFields.facility.name == "") serviceReqFields.push('FACILITY NAME is a required field');
                if (!serviceFields.facility || serviceFields.facility?.address?.street1 == undefined || serviceFields.facility?.address?.street1 == "") serviceReqFields.push('FACILITY ADDRESS is a required field');
            }
        }

        if (serviceFields?.module == "HHC") {
            if (!serviceFields?.categoryId || serviceFields?.categoryId == "") serviceReqFields.push('SUB SERVICE is a required field');
            if (!serviceFields.dos || serviceFields?.dos == "") serviceReqFields.push('DATE OF SERVICE is a required field');
            if (!serviceFields.serviceAddress.street1 || serviceFields.serviceAddress.street1 == "") serviceReqFields.push('SERVICE ADDRESS is a required field');
            if (!serviceFields.serviceAddress.zipCode?.zip || serviceFields.serviceAddress.zipCode?.zip == "") serviceReqFields.push('ZIPCODE is a required field');
            if (!serviceFields.serviceAddSameAsClmnt && serviceFields.serviceAddress.phone.trim() == "") serviceReqFields.push('HHC LOCATION & PHONE is a required field');
        }

        if (serviceFields && Object.keys(serviceFields).length === 0) {
            serviceReqFields.push('You must have at least one SERVICE');
            serviceReqFields.push('PLACE OF SERVICE is required field');
        }

        if (serviceFields && Object.keys(serviceFields).length > 0 && (!serviceFields.module || serviceFields.module === "")) {
            serviceReqFields.push('You must have at least one SERVICE');
        }

        if (serviceFields && Object.keys(serviceFields).length > 0 && (!serviceFields.placeOfServiceId || serviceFields.placeOfServiceId === "")) {
            serviceReqFields.push('PLACE OF SERVICE is required field');
        }

        //    if(
        //     Object.keys(loadClaimantByIdInfo).length == 0 ) {
        //         serviceReqFields.push('You must have at least one SERVICE');
        //         serviceReqFields.push('PLACE OF SERVICE is required field');
        //     } else {
        //         let isServiesEmpty = true;

        //         if( loadClaimantByIdInfo.audiologyList && loadClaimantByIdInfo.audiologyList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }
        //         if( loadClaimantByIdInfo.accommodationList && loadClaimantByIdInfo.accommodationList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }
        //         if(  loadClaimantByIdInfo.dentalList &&  loadClaimantByIdInfo.dentalList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }
        //         if(  loadClaimantByIdInfo.diagnosticList &&  loadClaimantByIdInfo.diagnosticList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.specialTransportationList &&  loadClaimantByIdInfo.specialTransportationList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.transportationList &&  loadClaimantByIdInfo.transportationList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.mseList &&  loadClaimantByIdInfo.mseList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.modificationList &&  loadClaimantByIdInfo.modificationList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.hhcList &&  loadClaimantByIdInfo.hhcList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }

        //         if(  loadClaimantByIdInfo.interpratationList &&  loadClaimantByIdInfo.interpratationList.length != 0 && isServiesEmpty) {
        //             isServiesEmpty = false;
        //         }


        //         if(isServiesEmpty) {
        //             serviceReqFields.push('You must have at least one SERVICE');
        //             serviceReqFields.push('PLACE OF SERVICE is required field');
        //         } else {

        //             if( loadClaimantByIdInfo.audiologyList && loadClaimantByIdInfo?.audiologyList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.audiologyList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.accommodationList && loadClaimantByIdInfo?.accommodationList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.accommodationList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.dentalList && loadClaimantByIdInfo?.dentalList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.dentalList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.diagnosticList && loadClaimantByIdInfo?.diagnosticList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.diagnosticList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.specialTransportationList && loadClaimantByIdInfo?.specialTransportationList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.specialTransportationList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.transportationList && loadClaimantByIdInfo?.transportationList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.transportationList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.mseList && loadClaimantByIdInfo?.mseList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.mseList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.modificationList && loadClaimantByIdInfo?.modificationList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.modificationList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             } else if( loadClaimantByIdInfo.hhcList && loadClaimantByIdInfo?.hhcList?.length > 0) {
        //                 if( !loadClaimantByIdInfo.hhcList[0].placeOfServiceId ) {
        //                     serviceReqFields.push('PLACE OF SERVICE is required field');
        //                 }
        //             }

        //         }


        //     }


        setFields([...patientFields, ...refFields, ...AdjFields, ...caseManDataFields, ...phyFields, ...attFields, ...empFields, ...serviceReqFields]);
        setReqFields([...patientFields, ...refFields, ...AdjFields, ...caseManDataFields, ...phyFields, ...attFields, ...empFields, ...serviceReqFields]);

    },
        [patientData, refData, adjData, caseManData, phyData, attData, empData, serviceData, updatedClaimInfo, updatedRefInfo, updatedAdjusterInfo,
            updatedCaseManagerInfo, updatedPhysicianInfo, updatedAttorneyInfo, updatedEmployerInfo, loadClaimantByIdInfo, populatedRef, trnTableDetails, sptTableDetails, newTrnData, newSptData]);

    const getPatientFields = (data) => {

        let arr = [];
        if (data && !updatedClaimInfo) {
            if (!data.claimantFirstName || data.claimantFirstName == "") arr.push("CLAIMANT FIRST NAME is a required field")
            if (!data.claimantLastName || data.claimantLastName == "") arr.push("CLAIMANT LAST NAME is a required field")
            if (!data.claimantAddress || data.claimantAddress == "") arr.push("CLAIMANT ADDRESS is a required field")
            if (!data.claimantZip || data.claimantZip == "") arr.push("CLAIMANT ZIP is a required field")
            if (!data.claimNumber || data.claimNumber == "") arr.push("CLAIM NUMBER is a required field")
            if (!data.claimDOI || data.claimDOI == "") arr.push("DATE OF INJURY is a required field")
        }
        return arr;
    }

    const getRefFields = (data) => {
        let arr = [];
        if (data && !Object.keys(updatedRefInfo).length) {
            if (!data.refFirstName || data.refFirstName == "") arr.push("REFERRER FIRST NAME is a required field")
            if (!data.refLastName || data.refLastName == "") arr.push("REFERRER LAST NAME is a required field")
            if (!data.refEmail || data.refEmail == "") arr.push("REFERRER EMAIL is a required field")
            if (!data.insComp || data.insComp == "") arr.push("INSURANCE is a required field")
        }
        return arr;
    }

    const getAdjFields = (data) => {
        let arr = [];
        if (data && !Object.keys(updatedAdjusterInfo).length) {
            if (!data.adjFirstName || data.adjFirstName == "") arr.push("ADJUSTER FIRST NAME is a required field")
            if (!data.adjLastName || data.adjLastName == "") arr.push("ADJUSTER LAST NAME is a required field")
            if (!data.adjEmail || data.adjEmail == "") arr.push("ADJUSTER EMAIL is a required field")
            // if (data.adjPhone == "") arr.push("ADJUSTER PHONE# is a required field")
        }
        return arr;
    }

    const getCaseManData = (data) => {
        let arr = [];
        if (data && !Object.keys(updatedCaseManagerInfo).length) {
            // if (!data.caseManagerFirstName || data.caseManagerFirstName == "") arr.push("CASE MANAGER FIRST NAME is a required field")
            // if (!data.caseManagerLastName || data.caseManagerLastName == "") arr.push("CASE MANAGER LAST NAME is a required field")
            // if (!data.caseManagerEmail || data.caseManagerEmail == "") arr.push("CASE MANAGER EMAIL is a required field")
            // if (!data.caseManagerPhone || data.caseManagerPhone == "") arr.push("CASE MANAGER PHONE# is a required field")
        }
        return arr;
    }

    const getPhyFields = (data) => {
        let arr = [];
        if (data && !updatedPhysicianInfo) {
            // if (!data.phyLastName || data.phyLastName == "") arr.push(" PHYSICIAN LAST NAME is a required field ")
            // if (!data.phyGroupName || data.phyGroupName == "") arr.push(" PHYSICIAN GROUP NAME is a required field")
            // if (!data.phyLicenseNo || data.phyLicenseNo == "") arr.push("PHYSICIAN LICENSE NO is a required field")
            // if (!data.phyNpi || data.phyNpi == "") arr.push("PHYSICIAN NPI# is a required field")
        }
        return arr;
    }

    const getAttFields = (data) => {
        let arr = [];
        if (data && !updatedAttorneyInfo) {
            // if (!data.attLastName || data.attLastName == "") arr.push("ATTORNEY LAST NAME is a required field")
            // if (!data.attFirmName || data.attFirmName == "") arr.push("ATTORNEY FIRM NAME is a required field")
            // if (!data.attAttorneyType || data.attAttorneyType == "") arr.push("ATTORNEY TYPE is a required field")
        }
        return arr;
    }

    const getEmpFields = (data) => {
        let arr = [];
        if (data && !Object.keys(updatedEmployerInfo).length) {
            // if (!data.empName && data.empName == "") arr.push("EMPLOYER NAME is a required field")
        }
        return arr;
    }
    return (
        <div
            id="rightSideNav"
            className="sidenav-right"
            style={open ? { width: '400px' } : { width: '0px' }}
        >
            {' '}
            <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                {fields.map((i, idx) => <div key={idx}> <FontAwesomeIcon className="red-text" icon={faCircle} /> {i}</div>)}
            </div>

        </div>
    );
};

const mapStateToProps = state => {
    const fast360Info = fast360Selectors.getFast360Info(state);
    return {
        serviceData: state.FAST360.fast360Reducer.requiredServiceData,
        updatedClaimInfo: fast360Info.updatedClaimInfo && fast360Info.updatedClaimInfo.claim,
        updatedRefInfo: fast360Info.updatedRefInfo,
        updatedAdjusterInfo: fast360Info.updatedAdjusterInfo,
        updatedCaseManagerInfo: fast360Info.updatedCaseManagerInfo,
        updatedEmployerInfo: fast360Info.updatedEmployerInfo,
        updatedPhysicianInfo: fast360Info.updatedPhysicianInfo,
        updatedAttorneyInfo: fast360Info.updatedAttorneyInfo,
        loadClaimantByIdInfo: fast360Info.loadClaimantByIdInfo,
        trnDetailModaldata: fast360Info.trnDetailModaldata,
        trnTableDetails: state.FAST360.fast360Reducer.trnTableDetails,
        sptTableDetails: state.FAST360.fast360Reducer.sptTableDetails,
        newTrnData: state.FAST360.fast360Reducer.trnData,
        newSptData: state.FAST360.fast360Reducer.sptData,
        sptSaveinfo: state.FAST360.fast360Reducer.sptSaveinfo,
        trnsaveInfo: state.FAST360.fast360Reducer.trnsaveInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    setReqFields: (data) => dispatch(fast360Actions.reqFields(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360SideNav);
