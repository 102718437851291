import React from 'react';
import { Input, Row, Col, Label } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

class Fast360MatrixAIS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      rfees: {},
      rfeeIds: {},
      backupFees: [],
      serviceFees: [],
      fees: {},
      feeIds: {},
      serviceCost: [],
      backupCost: [],
      billCharges: 0,
      costSavings: [],
      dayStay: 0,
      defaultPercent: 0,
      serviceId: 0,
      selectedService: {},
      isRefreshFeesModalOpen: false,
      isRefreshModalOpen: false,
      isRefreshCostModalOpen: false,
      isSaveModalOpen: false,
      totalFee: 0,
      totalCostFee: 0,
      margin: 0,
      totalDiff: 0
    };
  }

  componentDidMount() {
    if (this.props.isFromLanding) {
      this.getLineItems(
        this.props.rfees,
        this.props.rfeeIds,
        this.props.serviceFees,
        'left'
      );
      this.getLineItems(
        this.props.fees,
        this.props.feeIds,
        this.props.serviceCosts,
        'right'
      );
      this.calcMarginAndDiff();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.serviceFees !== prevProps.serviceFees) {
      this.getLineItems(
        this.props.rfees,
        this.props.rfeeIds,
        this.props.serviceFees,
        'left'
      );
    }
    if (this.props.serviceCosts !== prevProps.serviceCosts) {
      this.getLineItems(
        this.props.fees,
        this.props.feeIds,
        this.props.serviceCosts,
        'right'
      );
    }
    if((prevState.totalFee !== this.state.totalFee) || (prevState.totalCostFee !== this.state.totalCostFee)) {
    this.calcMarginAndDiff();
    }
  }

  getLineItems = (fees, feeIds, lItems, side) => {
    lItems = lItems ? JSON.parse(JSON.stringify(lItems)) : [];
    var lineItems = [];
    for (const lineItem of lItems) {
      lineItem.side = side;
      if (lineItem.fieldName === 'Rate_pline') {
        lineItem.serviceDescription = 'Line Cost';
      } else if (lineItem.fieldName === 'Rate_ppage') {
        lineItem.serviceDescription = 'Pages Cost';
      } else if (lineItem.fieldName === 'Rate_pword') {
        lineItem.serviceDescription = 'Word Cost';
      } else if (lineItem.fieldName === 'Rate_pline') {
        lineItem.serviceDescription = 'Line Cost';
      } else if (lineItem.fieldName === 'Hourly_Rate') {
        lineItem.serviceDescription = 'Hourly Cost';
      } else if (lineItem.fieldName === 'Transcript_Hourly_Rate') {
        lineItem.serviceDescription = 'Hourly Cost';
      } else if (lineItem.fieldName === 'Non_Prof_Rate') {
        lineItem.serviceDescription = 'NPO Cost';
      } else if (lineItem.fieldName === 'Rate_per_Minute') {
        lineItem.serviceDescription = 'Call Cost';
      } else if (lineItem.fieldName === 'Additional_Passenger_Rate') {
        lineItem.serviceDescription = 'Additional Passenger Rate';
      } else if (lineItem.fieldName === 'Dead_Miles_Rate') {
        lineItem.serviceDescription = 'Dead Miles Cost';
      } else if (lineItem.fieldName === 'Extra_Crew_Rate') {
        lineItem.serviceDescription = 'Extra Crew Rate';
      } else if (lineItem.fieldName === 'Flat_Fee') {
        lineItem.serviceDescription = 'Flat Fee';
      } else if (lineItem.fieldName === 'ParkingToll_Fees') {
        lineItem.serviceDescription = 'Parking/Toll Fees';
      } else if (lineItem.fieldName === 'Fuel_Surcharge') {
        lineItem.serviceDescription = 'Fuel Surcharge';
      } else if (lineItem.fieldName === 'Load_Fee') {
        lineItem.serviceDescription = 'Load Fee';
      } else if (lineItem.fieldName === 'Late_Cancel') {
        lineItem.serviceDescription = 'Late Cancel';
      } else if (lineItem.fieldName === 'No_Show_Fee') {
        lineItem.serviceDescription = 'No Show Fee';
      } else if (lineItem.fieldName === 'Rush_Fee') {
        lineItem.serviceDescription = 'Rush Fee';
      } else if (lineItem.fieldName === 'Wheel_Chair_Rental_Fee') {
        lineItem.serviceDescription = 'Wheel Chair Rental Fee';
      } else if (lineItem.fieldName === 'Mileage_Rate') {
        lineItem.serviceDescription = 'Mileage Cost';
      } else if (lineItem.fieldName === 'Rate_per_Mile') {
        lineItem.serviceDescription = 'Mileage Cost';
      } else if (lineItem.fieldName === 'Wait_Time_Fee') {
        lineItem.serviceDescription = 'Wait Time Fee';
      } else if (lineItem.fieldName === 'Rebooking_Fee') {
        lineItem.serviceDescription = 'Rebooking Fee';
      } else if (lineItem.fieldName === 'Ticket_Cost') {
        lineItem.serviceDescription = 'Ticket Cost';
      } else if (lineItem.fieldName === 'Flight_Credit') {
        lineItem.serviceDescription = 'Flight Credit';
      } else if (lineItem.fieldName === 'Preferred_Seating') {
        lineItem.serviceDescription = 'Preferred Seating';
      } else if (lineItem.fieldName === 'Inflight_Meal') {
        lineItem.serviceDescription = 'Inflight Meal';
      } else if (lineItem.fieldName === 'Checked_Bag_1') {
        lineItem.serviceDescription = 'Checked Bag 1';
      } else if (lineItem.fieldName === 'Checked_Bag_2') {
        lineItem.serviceDescription = 'Checked Bag 2';
      } else if (lineItem.fieldName === 'Daily_Fee') {
        lineItem.serviceDescription = 'Daily Fee';
      } else if (lineItem.fieldName === 'Monthly_Fee') {
        lineItem.serviceDescription = 'Monthly Fee';
      } else if (lineItem.fieldName === 'Weekly_Fee') {
        lineItem.serviceDescription = 'Weekly Fee';
      } else if (lineItem.fieldName === 'Meal_per_Diem') {
        lineItem.serviceDescription = 'Meal per Diem';
      } else if (lineItem.fieldName === 'Incidental') {
        lineItem.serviceDescription = 'Incidental';
      } else if (lineItem.fieldName === 'Resort_Fee') {
        lineItem.serviceDescription = 'Resort Fee';
      } else if (lineItem.fieldName === 'Parking') {
        lineItem.serviceDescription = 'Parking';
      }
       lineItem.percentAdjustment = 0;
       if(lineItem.id) {
       const line =  this.props.selectedService?.costSavings.find(i => i.DetailId === lineItem.id);
       lineItem.percentAdjustment = line && line.PercentAdjustment;
       }
        
       if (!lineItem.applied /*|| resetAllFees*/) {
        if (lineItem.fieldName === 'Rate_pline') {
          lineItem.feeTypeId = feeIds.Rate_pline;
          if (fees.Min_Line_Count < this.props.selectedService.linesPerPage) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.state.linesPerPage;
            } else if (
              lineItem.qty != this.props.selectedService.linesPerPage &&
              this.props.selectedService.linesPerPage > 0
            ) {
              lineItem.qty = this.state.linesPerPage;
            }

            lineItem.clientFee = fees.Rate_pline;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.linesPerPage * fees.Rate_pline;
          } else {
            if (lineItem.qty <= fees.Min_Line_Count) {
              lineItem.qty = fees.Min_Line_Count;
            }
            lineItem.clientFee = fees.Rate_pline;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = (
              fees.Min_Line_Count * fees.Rate_pline
            ).toFixed(2);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_ppage') {
          lineItem.serviceDescription = 'Pages Cost';
          lineItem.feeTypeId = feeIds.Rate_ppage;
          if (fees.Min_Page_Count < 0) {
            lineItem.qty = 0;
            lineItem.clientFee = fees.Rate_ppage;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = 0.0;
          } else {
            if (lineItem.qty <= fees.Min_Page_Count) {
              lineItem.qty = fees.Min_Page_Count;
            }
            lineItem.clientFee = fees.Rate_ppage;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = fees.Min_Page_Count * fees.Rate_ppage;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_pword') {
          lineItem.serviceDescription = 'Word Cost';
          lineItem.feeTypeId = feeIds.Rate_pword;
          if (fees.Min_pword_count < 0) {
            if (lineItem.qty <= 0) {
              lineItem.qty = 0;
            }
            lineItem.clientFee = fees.Rate_pword;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = 0.0;
          } else {
            if (lineItem.qty <= fees.Min_pword_count) {
              lineItem.qty = fees.Min_pword_count;
            }
            lineItem.clientFee = fees.Rate_pword;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = fees.Minimum_Word_count * fees.Rate_pword;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Hourly_Rate') {
          lineItem.serviceDescription = 'Hourly Cost';
          lineItem.feeTypeId = feeIds.Hourly_Rate;
          if (fees.Hourly_Minimum < this.props.selectedService.durationHour) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.props.selectedService.durationHour;
            } else if (
              lineItem.qty != this.props.selectedService.durationHour &&
              this.props.selectedService.durationHour > 0
            ) {
              lineItem.qty = this.props.selectedService.durationHour;
            }

            lineItem.clientFee = fees.Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.durationHour * (fees.Hourly_Rate / 60);
          } else {
            if (lineItem.qty <= fees.Hourly_Minimum) {
              lineItem.qty = fees.Hourly_Minimum;
            }

            lineItem.clientFee = fees.Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              fees.Hourly_Minimum * (fees.Hourly_Rate / 60);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Transcript_Hourly_Rate') {
          lineItem.serviceDescription = 'Hourly Cost';
          lineItem.feeTypeId = feeIds.Transcript_Hourly_Rate;
          if (
            fees.Transcript_Hourly_Min < this.props.selectedService.durationHour
          ) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.props.selectedService.durationHour;
            } else if (
              lineItem.qty != this.props.selectedService.durationHour &&
              this.props.selectedService.durationHour > 0
            ) {
              lineItem.qty = this.props.selectedService.durationHour;
            }

            //lineItem.qty = ifnull(parentWindow.INTduration.text, '0')
            lineItem.clientFee = fees.Transcript_Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.durationHour *
              (fees.Transcript_Hourly_Rate / 60);
          } else {
            if (lineItem.qty <= fees.Transcript_Hourly_Min) {
              lineItem.qty = fees.Transcript_Hourly_Min;
            }
            lineItem.clientFee = fees.Transcript_Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              fees.Transcript_Hourly_Min * (fees.Transcript_Hourly_Rate / 60);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Non_Prof_Rate') {
          lineItem.serviceDescription = 'NPO Cost';

          if (lineItem.qty <= fees.Non_Prof_Units) {
            lineItem.qty = fees.Non_Prof_Units;
          }

          lineItem.clientFee = fees.Non_Prof_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          lineItem.calculatedCost = fees.Non_Prof_Units * fees.Non_Prof_Rate;

          lineItem.feeTypeId = feeIds.Non_Prof_Rate;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_per_Minute') {
          lineItem.serviceDescription = 'Call Cost';
          lineItem.feeTypeId = feeIds.Rate_per_Minute;
          if (fees.Min_Call_Time < this.props.selectedService.durationHour) {
            if (lineItem.qty <= 0) {
              lineItem.qty =
                this.props.selectedService.durationHour * 60 +
                this.props.selectedService.durationMin;
            }
            lineItem.clientFee = fees.Rate_per_Minute;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = lineItem.qty * fees.Rate_per_Minute;
          } else {
            if (lineItem.qty <= fees.Min_Call_Time) {
              lineItem.qty = fees.Min_Call_Time;
            }
            lineItem.clientFee = fees.Rate_per_Minute;
            lineItem.calculatedCost = fees.Min_Call_Time * fees.Rate_per_Minute;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Additional_Passenger_Rate') {
          lineItem.serviceDescription = 'Additional Passenger Rate';
          lineItem.feeTypeId = feeIds.Additional_Passenger_Rate;
          lineItem.clientFee = fees.Additional_Passenger_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Dead_Miles_Rate') {
          lineItem.serviceDescription = 'Dead Miles Total';
          lineItem.qty = fees.deadMileTot;
          lineItem.feeTypeId = feeIds.Dead_Miles_Rate;
          lineItem.clientFee = fees.Dead_Miles_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Extra_Crew_Rate') {
          lineItem.serviceDescription = 'Extra Crew Rate';
          lineItem.feeTypeId = feeIds.Extra_Crew_Rate;
          lineItem.clientFee = fees.Extra_Crew_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.extraPassenger) {
                lineItem.qty = '1';
              }
              if (obj.extraPassengerRate > 0) {
                lineItem.clientFee = obj.extraPassengerRate;
              }
            }
          }

          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Flat_Fee') {
          lineItem.serviceDescription = 'Flat Fee';
          lineItem.feeTypeId = feeIds.Flat_Fee;
          lineItem.clientFee = fees.Flat_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'ParkingToll_Fees') {
          lineItem.serviceDescription = 'Parking/Toll Fees';
          lineItem.feeTypeId = feeIds.ParkingToll_fees;
          lineItem.clientFee = fees.ParkingToll_fees;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Fuel_Surcharge') {
          lineItem.serviceDescription = 'Fuel Surcharge';
          lineItem.feeTypeId = feeIds.Fuel_Surcharge;
          lineItem.clientFee = fees.Fuel_Surcharge;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Load_Fee') {
          lineItem.serviceDescription = 'Load Fee';

          if (this.props.selectedService.detailList === undefined) {
            lineItem.qty = 0;
          } else {
            lineItem.qty = this.props.selectedService.detailList.length;
          }

          lineItem.feeTypeId = feeIds.Load_Fee;
          lineItem.clientFee = fees.Load_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Late_Cancel') {
          lineItem.serviceDescription = 'Late Cancel';
          lineItem.feeTypeId = feeIds.Late_Cancel;
          lineItem.clientFee = fees.Late_Cancel;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'No_Show_Fee') {
          lineItem.serviceDescription = 'No Show Fee';

          if (
            this.props.selectedService.serviceStatusId == 44 ||
            this.props.selectedService.serviceStatusId == 5
          ) {
            lineItem.qty = '1';
          } else {
            lineItem.qty = '0';
          }
          lineItem.feeTypeId = feeIds.No_Show_Fee;
          lineItem.clientFee = fees.No_Show_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rush_Fee') {
          lineItem.serviceDescription = 'Rush Fee';
          if (this.props.selectedService.rush) {
            lineItem.qty = '1';
          } else {
            lineItem.qty = '0';
          }
          lineItem.feeTypeId = feeIds.Rush_Fee;
          lineItem.clientFee = fees.Rush_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Wheel_Chair_Rental_Fee') {
          lineItem.serviceDescription = 'Wheel Chair Rental Fee';
          lineItem.clientFee = fees.Wheel_Chair_Rental_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.wheelChairRental) {
                lineItem.qty = '1';
              }
              if (obj.wheelChairRentalRate > 0) {
                lineItem.clientFee = obj.wheelChairRentalRate;
              }
            }
          }
          lineItem.feeTypeId = feeIds.Wheel_Chair_Rental_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Mileage_Rate') {
          let legs;
          lineItem.serviceDescription = 'Mileage Cost';
          if (this.props.selectedService.detailList != undefined) {
            legs = this.props.selectedService.detailList.length;
          } else {
            legs = 1;
          }
          if (this.props.selectedService.detailList != undefined && (fees.Minimum_Miles === undefined || fees.Minimum_Miles >= 0)) {
            lineItem.qty = 0;
            for (const obj of this.props.selectedService.detailList) {
              if (obj.estimatedMillage) {
                lineItem.qty = lineItem.qty + obj.estimatedMillage;
              }else {
                lineItem.qty = fees.Minimum_Miles * legs
              }
            
          if (fees.Minimum_Miles * legs < (fees.mileage || 0)) {
            lineItem.qty = fees.mileage || 0;
            lineItem.clientFee = fees.Mileage_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = (obj.estimatedMillage || 0) * fees.Mileage_Rate;
          }
        }
          } else {
            if (lineItem.qty <= fees.Minimum_Miles * legs) {
              lineItem.qty = fees.Minimum_Miles * legs;
            }
            lineItem.clientFee = fees.Mileage_Rate;
            lineItem.calculatedCost = fees.Minimum_Miles * fees.Mileage_Rate;
          }
          lineItem.feeTypeId = feeIds.Mileage_Rate;
          if (lineItem.modifiedCost === undefined || lineItem.clientFee === lineItem.modifiedCost) {
            lineItem.modifiedCost = 0;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Rate_per_Mile') {
          lineItem.serviceDescription = 'Mileage Cost';
          if (fees.Mileage_Unit < (this.props.selectedService.mileage || 0)) {
            lineItem.qty = this.props.selectedService.mileage || 0;
            lineItem.clientFee = fees.Rate_per_Mile;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            if (fees.Mileage_Unit < (this.state.selectedService.mileage || 0)) {
              lineItem.calculatedCost =
                (this.props.selectedService.mileage || 0) * fees.Rate_per_Mile;
            }
          } else {
            if (lineItem.qty <= fees.Mileage_Unit) {
              lineItem.qty = fees.Mileage_Unit;
            }
            lineItem.clientFee = fees.Rate_per_Mile;
            lineItem.calculatedCost = fees.Mileage_Unit * fees.Rate_per_Mile;
          }
          lineItem.feeTypeId = feeIds.Rate_per_Mile;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Wait_Time_Fee') {
          lineItem.serviceDescription = 'Wait Time Fee';
          lineItem.qty = 0;
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.waitHour > 0) {
                lineItem.qty = lineItem.qty + obj.waitHour;
              }
              if (obj.waitMinute > 0) {
                if (obj.waitMinute < 15) {
                  //do Nothing
                } else if (obj.waitMinute < 30) {
                  lineItem.qty = lineItem.qty + 0.5;
                } else if (obj.waitMinute > 30) {
                  lineItem.qty = lineItem.qty + 1;
                }
              }
              if (lineItem.qty < 1 && lineItem.qty > 0) {
                lineItem.qty = 1;
              }
              lineItem.clientFee = fees.Wait_Time_Fee;
            }
          }
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }

          lineItem.feeTypeId = feeIds.Wait_Time_Fee;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName == 'Rebooking_Fee') {
          lineItem.serviceDescription = 'Rebooking Fee';
          lineItem.clientFee = fees.Rebooking_Fee;
          //lineItem.clientFee = 0
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee = lineItem.clientFee + obj.transpRebookFee;
              }
            }
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Rebooking_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Ticket_Cost') {
          lineItem.serviceDescription = 'Ticket Cost';
          lineItem.clientFee = fees.Ticket_Cost;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee = lineItem.clientFee + obj.transpCostAmt;
              }
            }
          }
          if (this.props.selectedService.addPassName != undefined) {
            lineItem.qty = 2;
          } else {
            lineItem.qty = 1;
          }

          lineItem.feeTypeId = feeIds.Ticket_Cost;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName == 'Flight_Credit') {
          lineItem.serviceDescription = 'Flight Credit';
          lineItem.clientFee = fees.Flight_Credit;
          lineItem.clientFee = 0;
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee =
                  lineItem.clientFee + obj.transpServiceCredit;
              }
            }
          }
          lineItem.clientFee = lineItem.clientFee * -1;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.feeTypeId = feeIds.Flight_Credit;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Preferred_Seating') {
          lineItem.serviceDescription = 'Preferred Seating';
          lineItem.clientFee = fees.Preferred_Seating;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Preferred_Seating;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Inflight_Meal') {
          lineItem.serviceDescription = 'Inflight Meal';
          lineItem.clientFee = fees.Inflight_Meal;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Inflight_Meal;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName == 'Checked_Bag_1') {
          lineItem.serviceDescription = 'Checked Bag 1';
          lineItem.clientFee = fees.Checked_Bag_1;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Checked_Bag_1;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Checked_Bag_2') {
          lineItem.serviceDescription = 'Checked Bag 2';
          lineItem.clientFee = fees.Checked_Bag_2;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Checked_Bag_2;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        //ACC Fees
        if (lineItem.fieldName === 'Daily_Fee') {
          lineItem.serviceDescription = 'Daily Fee';
          lineItem.clientFee = fees.Daily_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;

          lineItem.feeTypeId = feeIds.Daily_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Monthly_Fee') {
          lineItem.serviceDescription = 'Monthly Fee';
          lineItem.clientFee = fees.Monthly_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;
          lineItem.feeTypeId = feeIds.Monthly_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Weekly_Fee') {
          lineItem.serviceDescription = 'Weekly Fee';
          lineItem.clientFee = fees.Weekly_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;
          lineItem.feeTypeId = feeIds.Monthly_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Meal_per_Diem') {
          lineItem.serviceDescription = 'Meal per Diem';
          lineItem.clientFee = fees.Meal_per_Diem;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Meal_per_Diem;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Incidental') {
          lineItem.serviceDescription = 'Incidental';
          lineItem.clientFee = fees.Incidental;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Incidental;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push.apply(lineItems, lineItem);
        }
        if (lineItem.fieldName === 'Resort_Fee') {
          lineItem.serviceDescription = 'Resort Fee';
          lineItem.clientFee = fees.Resort_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Resort_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Parking') {
          lineItem.serviceDescription = 'Parking';
          lineItem.clientFee = fees.Parking;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Parking;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Telephone_Call') {
          lineItem.serviceDescription = 'Telephone Call';
          lineItem.clientFee = fees.Telephone_Call;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Telephone_Call;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
      
      } else {
        if(lineItem.applied) {
         if(lineItem.clientFee === lineItem.modifiedCost || lineItem.modifiedCost === undefined) {
           lineItem.modifiedCost = 0;
         }
        }
        lineItems.push(lineItem);
      }
    }
    if (side === 'left') {
      let lineItem = lineItems.filter(i => i.applied === true);
      this.setState({ serviceFees: [...lineItem] }, () => {
      this.calcTotalFee();
      });
    } else {
      let lineItem = lineItems.filter(i => i.applied === true);
      this.setState({ serviceCost: [...lineItem] }, () => {
      this.calcTotalCostFee();
      });
    }
    //return lineItems
  };

  calcTotalFee = () => {
    let total = 0;
     this.state.serviceFees.forEach(obj => {
      if (
        obj.applied
      ) {
        total = Number(Number(total) + Number(obj.calculatedCost)).toFixed(2);
      }
    });
    this.setState({ totalFee: total });
  };

  calcTotalCostFee = () => {
    let total = 0;
    let calcCost = 0;
     this.state.serviceCost.forEach(obj => {
      if (
        obj.applied
      ) {
        if (Number(obj.modifiedCost) === 0 || obj.modifiedCost === '' || obj.modifiedCost === undefined) {
          calcCost = Number(
            Number(obj.qty) * Number(obj.clientFee)
          ).toFixed(2);
        } else {
          calcCost = Number(
            Number(obj.qty) * Number(obj.modifiedCost)
          ).toFixed(2);
        }
        total = Number(Number(total) + Number(calcCost)).toFixed(2);
       }
    });
    this.setState({ totalCostFee: total });
  };

  calcMarginAndDiff = () => {
    this.setState({margin: Math.round(Number(Number(Number(this.state.totalFee) - Number(this.state.totalCostFee)) / Number(this.state.totalFee)) * 100), totalDiff : Number(Number(this.state.totalFee) - Number(this.state.totalCostFee)).toFixed(2)});
  }

  getDescription(lineDetail) {
    return <>{lineDetail.applied === true ? lineDetail.serviceDescription : '' || ''}</>;
  }

  getQty = lineDetail => {
    const item = lineDetail;
    return <>{item.applied === true ? item.qty : '' || ''}</>;
  };
  
  getClientFee = lineDetail => {
    let totalCostFee = 0;
    let totalFee = 0;
    let calcCost = 0;
    this.state.serviceCost.forEach(obj => {
      if (
        obj.applied
      ) {
        if (Number(obj.modifiedCost) === 0 || obj.modifiedCost === '' || obj.modifiedCost === undefined) {
          calcCost = Number(
            Number(obj.qty) * Number(obj.clientFee)
          ).toFixed(2);
        } else {
          calcCost = Number(
            Number(obj.qty) * Number(obj.modifiedCost)
          ).toFixed(2);
        }
        totalCostFee = Number(Number(totalCostFee) + Number(calcCost)).toFixed(2);
       }
    });
    this.state.serviceFees.forEach(obj => {
      if (
        obj.applied
      ) {
        totalFee = Number(Number(totalFee) + Number(obj.calculatedCost)).toFixed(2);
      }
    });
    this.state.margin = Math.round(Number((Number(totalFee) - Number(totalCostFee)) / Number(totalFee)) * 100);
    this.state.totalDiff = Number(Number(totalFee) - Number(totalCostFee)).toFixed(2);
    return <>${lineDetail.applied === true ? lineDetail.clientFee : '' || ''}</>;
  };

  getModifiedFee = lineDetail => {
    const item = lineDetail;
    return <>${item.applied === true ? item.modifiedCost : '' || ''}</>;
  };

  getTotal = lineDetail => {
    let calculatedCost;
    if (lineDetail.modifiedCost > 0) {
      calculatedCost = Number(lineDetail.qty * lineDetail.modifiedCost).toFixed(
        2
      );
    } else {
      calculatedCost = Number(lineDetail.qty * lineDetail.clientFee).toFixed(2);
    }
    return <>${ lineDetail.applied === true ? isNaN(Number(calculatedCost)) ? 0 : Number(calculatedCost) : '' || ''}</>;
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <AppTable
              resizable
              columns={[
                {
                  header: 'Client Fee Description',
                  render: item => this.getDescription(item),
                  disabled: true
                },
                {
                  header: 'Qty',
                  render: item => this.getQty(item),
                  disabled: true
                },
                {
                  header: 'Contracted',
                  render: item => this.getClientFee(item),
                  disabled: true
                },
                {
                  header: 'Modified',
                  render: item => this.getModifiedFee(item),
                  disabled: true
                },
                {
                  header: 'Total',
                  render: item => this.getTotal(item),
                  disabled: true
                },
              ]}
              data={this.state.serviceFees || []}
              autoPagination={true}
              headerWidth="100%"
              modalHeight={400}
            />
          </Col>
          <Col>
            <AppTable
              resizable
              columns={[
                {
                  header: 'Provider Fee Description',
                  render: item => this.getDescription(item),
                },
                {
                  header: 'Qty',
                  render: item => this.getQty(item),
                },
                {
                  header: 'Contracted',
                  render: item => this.getClientFee(item),
                },
                {
                  header: 'Modified',
                  render: item => this.getModifiedFee(item),
                },
                {
                  header: 'Total',
                  render: item => this.getTotal(item),
                },
              ]}
              data={this.state.serviceCost || []}
              autoPagination={true}
              // sortAble={true}
              modalHeight={400}
            />
          </Col>
        </Row>
        <Row>
          <Col md='6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Label md={{ size: 3, offset: 2 }}>
              Total Fees Applied:
        </Label>
            <Input style={{ width: '50%' }} placeholder="$0.00" value={`$${this.state.totalFee}`} disabled />
          </Col>
          <Col md='6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Label md={{ size: 3, offset: 2 }}>
              Total Costs Applied:
        </Label>
            <Input placeholder="$0.00" value={`$${this.state.totalCostFee}`} disabled />
          </Col>
        </Row>
        <Row style={{ padding: '3px' }}>
          <Col md='12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Label md={{ size: 1, offset: 9 }}>
              Margin:
        </Label>
            <Input placeholder="$0.00" value={`${this.state.margin}%`} disabled />
            <Input placeholder="NaN%" value={`$${this.state.totalDiff}`} disabled />
          </Col>
        </Row>
      </>
    );
  };
}

export default Fast360MatrixAIS;
