import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const NewCaseManagerModal = ({

  logBreadCrumbs,
  isOpen,
  onClose,
  userId,
  userRole,
  getCaseManagerList,
  caseManagerInfo,
  updatedCaseManagerInfo,
  resetSearchCaseManagerTable,
  getLoadCaseManager,
  updateSelectedItemRef,
  updateDataFromRef,
  loadCaseManagerInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadCaseManager,
  resetCaseManagerInfo,
  saveCaseManager,
  alert,
  isNew,
  updatedRefInfo,
  NewCaseManagerName,
  worklistData,
  fromWorklist,
  setCaseManagerEdited,
  fromExternal,
  externalRefData,
  setCaseManData,
  referralId,
  isFromLanding,
  loadInfoFromExistingPatient,
}) => {
  const [isOpenLoadCaseManagerModal, setIsOpenLoadCaseManagerModal] = useState(
    false
  );
  const [isConfirmSameAsRef, setIsConfirmSameAsRef] = useState(false);
  const [isFromSameAsRef, setIsFromSameAsRef] = useState(false);
  const [isOpenSeachCaseManager, setIsOpenSeachCaseManager] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [caseManagerValidFirstName, setCaseManagerValidFirstName] = useState(false);
  const [caseManagerValidLastName, setCaseManagerValidLastName] = useState(false);
  const [caseManagerValidEmail, setCaseManagerValidEmail] = useState(false);
  const [caseManagerValidPhone, setCaseManagerValidPhone] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [searchFields, setSearchFields] = useState({
    firstName: (NewCaseManagerName ? NewCaseManagerName : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    lastName: '',
  });
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [communicationPreferences, setCommunicationPrefrences] = useState([]);
  const getSplitName = (name) => {
    let fname = '', lname = '';
    if (name) {
      if (name.includes(',')) {
        let managerNameArr = name.split(',');
        fname = managerNameArr[managerNameArr.length - 1];
        lname = managerNameArr[0];
      }
      else {
        let managerNameArr = name.split(' ');
        fname = managerNameArr[0];
        lname = managerNameArr[managerNameArr.length - 1];
      }
    }
    return { fname, lname };
  }
  const [formCaseManagerFields, setFormCaseManagerFields] = useState({
    caseManagerFirstName: (fromExternal.fromExternal ? getSplitName(NewCaseManagerName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    caseManagerLastName: (fromExternal.fromExternal ? getSplitName(NewCaseManagerName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
    companyName: '',
    caseManagerEmail: (externalRefData?.CaseManagerEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
    caseManagerPhone: (externalRefData?.CaseManagerPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
    caseManagerPhoneExt: '',
    caseManagerFax: '',
    caseManagerAddress: '',
    caseManagerAddress2: '',
    caseManagerCity: '',
    caseManagerCounty: '',
    caseManagerState: '',
    caseManagerZip: '',
    caseManagerZipCodeId: '',
    caseManagerZipId: '',
    caseManagerTimeZone: '',
    caseManagerNotes: '',
    communicationPreference: (externalRefData?.communicationPreference || '') || (!isFromLanding && loadInfoFromExistingPatient?.communicationPreference),
  });

  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSeachCaseManager(true);
      } else {
        setIsOpenLoadCaseManagerModal(true);
      }

    }
  }, [isOpen]);

  useEffect(() => {
    if(formCaseManagerFields.caseManagerFax && formCaseManagerFields.caseManagerFax !== "" ) {
      setCommunicationPrefrences([
        { value: 'Email', label: 'Email'},
        { value: 'Fax', label: 'Fax'},
        { value: 'Both', label: 'Both'},
      ]);
    } else {
      setCommunicationPrefrences([
        { value: 'Email', label: 'Email'},
      ]);
    }
  }, [formCaseManagerFields.caseManagerFax])

  useEffect(() => {
    setCaseManData(formCaseManagerFields)
  }, [formCaseManagerFields])


  useEffect(() => {
    if (!formCaseManagerFields.caseManagerFirstName)
      setCaseManagerValidFirstName(false);
    else setCaseManagerValidFirstName(true);
    if (!formCaseManagerFields.caseManagerLastName)
      setCaseManagerValidLastName(false);
    else setCaseManagerValidLastName(true);
    if (!formCaseManagerFields.caseManagerEmail)
      setCaseManagerValidEmail(false);
    else setCaseManagerValidEmail(true);
    if (!formCaseManagerFields.caseManagerPhone)
      setCaseManagerValidPhone(false);
    else setCaseManagerValidPhone(true);

  }, [formCaseManagerFields])

  useEffect(() => {
    if (isOpenLoadCaseManagerModal) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENCM',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb)
      if (selectedItem) {
        const params = {
          userId,
          functionName: 'loadCaseManager',
          id: selectedItem.PK_casemanager_id,
        };
        getLoadCaseManager(params);
      } else if (!isNew && !caseManagerInfo && !isFromSameAsRef) {
        if (!isEmpty(updatedCaseManagerInfo)) {
          const pplCheck = updatedCaseManagerInfo.caseManager.people || {};
          const adrCheck = updatedCaseManagerInfo.caseManager.address || {};
          setFormCaseManagerFields({
            caseManagerFirstName: pplCheck.first,
            caseManagerLastName: pplCheck.last,
            companyName: '',
            caseManagerEmail: pplCheck.email1,
            caseManagerPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            caseManagerPhoneExt: pplCheck && pplCheck.phoneExt,
            caseManagerFax: pplCheck && pplCheck.fax,
            caseManagerAddress: adrCheck.street1,
            caseManagerZipCodeId: adrCheck.zipCodeId,
            caseManagerZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            caseManagerTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            caseManagerAddress2: adrCheck.street2,
            caseManagerCity: adrCheck.zipCode && adrCheck.zipCode.city,
            caseManagerCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            caseManagerState: adrCheck.zipCode && adrCheck.zipCode.state,
            caseManagerZip: adrCheck.zipCode && adrCheck.zipCode.zip,
            caseManagerNotes: updatedCaseManagerInfo.caseManager.comments,
          });
        }
      }
    }
  }, [isOpenLoadCaseManagerModal]);

  useEffect(() => {
    let fname = '', lname = '';
    if (NewCaseManagerName) {
      if (NewCaseManagerName.includes(',')) {
        let managerNameArr = NewCaseManagerName.split(',');
        fname = managerNameArr[managerNameArr.length - 1];
        lname = managerNameArr[0];
      } else {
        let managerNameArr = NewCaseManagerName.split(' ');
        fname = managerNameArr[0];
        lname = managerNameArr[managerNameArr.length - 1];
      }
    } else {
      if (fromWorklist && worklistData) {
        if (worklistData?.caseManager?.people?.first)
          fname = worklistData?.caseManager?.people?.first
        if (worklistData?.caseManager?.people?.last)
          lname = worklistData?.caseManager?.people?.last
      }
    }
    if (!isFromLanding && isEmpty(updatedCaseManagerInfo) && !isEmpty(loadInfoFromExistingPatient)) {
      fname = loadInfoFromExistingPatient?.first || ''
      lname = loadInfoFromExistingPatient?.last || ''
    } else {
      if (fromWorklist && worklistData) {
        if (worklistData?.caseManager?.people?.first)
          fname = worklistData?.caseManager?.people?.first
        if (worklistData?.caseManager?.people?.last)
          lname = worklistData?.caseManager?.people?.last
      }
    }
    if (isOpen) {
      setSearchFields({
        firstName: fname,
        lastName: lname,
      });
      const payload = {
        firstName: fname,
        lastName: lname,
        userId,
      };
      (NewCaseManagerName || (fname || lname)) && getCaseManagerList(payload);
    }
  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.caseManager?.id) {
        const item = caseManagerInfo && caseManagerInfo.filter(o => o.PK_casemanager_id === worklistData?.caseManager?.id);
        let index = caseManagerInfo && caseManagerInfo.findIndex(i => i.PK_casemanager_id === worklistData?.caseManager?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [caseManagerInfo])

  useEffect(() => {
    if (!isEmpty(loadCaseManagerInfo) && !isFromSameAsRef) {
      const pplCheck = loadCaseManagerInfo.people || {};
      const adrCheck = loadCaseManagerInfo.address || {};
      setFormCaseManagerFields({
        caseManagerFirstName: pplCheck.first,
        caseManagerLastName: pplCheck.last,
        companyName: '',
        caseManagerEmail: pplCheck.email1,
        caseManagerPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        caseManagerPhoneExt: pplCheck && pplCheck.phoneExt,
        caseManagerFax: pplCheck && pplCheck.fax,
        caseManagerAddress: adrCheck.street1,
        caseManagerZipCodeId: adrCheck.zipCodeId,
        caseManagerZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        caseManagerTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        caseManagerAddress2: adrCheck.street2,
        caseManagerCity: adrCheck.zipCode && adrCheck.zipCode.city,
        caseManagerCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        caseManagerState: adrCheck.zipCode && adrCheck.zipCode.state,
        caseManagerZip: adrCheck.zipCode && adrCheck.zipCode.zip,
        caseManagerNotes: loadCaseManagerInfo.comments,
        communicationPreference: loadCaseManagerInfo.communicationPreference
      });
    }
  }, [loadCaseManagerInfo]);

  const loadCaseManagerInfoToggle = () => {
    setIsFromSameAsRef(false);
    setIsOpenLoadCaseManagerModal(!isOpenLoadCaseManagerModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormCaseManagerFields({ ...formCaseManagerFields, caseManagerFirstName: '', caseManagerLastName: '', caseManagerEmail: '' })
    }
  };

  const searchCaseManagerToggle = () => {
    setIsOpenSeachCaseManager(!isOpenSeachCaseManager);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    resetSearchCaseManagerTable();
    resetLoadCaseManager();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const onChangeHandlerCaseManagerType = (id, value) => {
    setFormCaseManagerFields({ ...formCaseManagerFields, [id]: value });
  };

  const setMap = {
    caseManagerFirstName: setCaseManagerValidFirstName,
    caseManagerLastName: setCaseManagerValidLastName,
    caseManagerEmail: setCaseManagerValidEmail,
    caseManagerPhone: setCaseManagerValidPhone
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "caseManagerPhone") {
      value = formatPhoneNum(value);
    }
    setFormCaseManagerFields({ ...formCaseManagerFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };

  const isDisabledInput = () => {
    return userRole === 'Administrator' ? false : true;
  };

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const renderPhoneColumn = item => {
    const phone = item.chr_phone || '';
    if (phone) {
      return formatPhoneNumber(phone);
    }
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchCaseManagerTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'Phone No.',
              field: 'chr_phone',
              render: item => renderPhoneColumn(item),
            },
            {
              header: 'Type',
              field: 'refType',
            },
          ]}
          data={caseManagerInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-CaseManager-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadCaseManagerModal = () => {
    setIsOpenSeachCaseManager(false);
    setIsOpenLoadCaseManagerModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getCaseManagerList(params);
  };

  const onChangeCurrentCaseManager = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    setIsOpenLoadCaseManagerModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachCaseManager(true);
    setIsFromSameAsRef(false);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormCaseManagerFields({
        ...formCaseManagerFields,
        caseManagerCity: selectedZip.chr_city,
        caseManagerState: selectedZip.chr_state,
        caseManagerCounty: selectedZip.chr_county,
        caseManagerZip: selectedZip.chr_zipcode,
        caseManagerZipCodeId: selectedZip.code_id,
        caseManagerZipId: selectedZip.code_id,
        caseManagerTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVECM',
      refTableId: loadCaseManagerInfo.id,
      refId: 0,
      refTable: 'CM',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem || isFromSameAsRef) {
      const response = loadCaseManagerInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formCaseManagerFields.caseManagerFirstName;
      response.people.last = formCaseManagerFields.caseManagerLastName;
      response.people.email1 = formCaseManagerFields.caseManagerEmail;
      response.people.phone =
        formCaseManagerFields.caseManagerPhone &&
        formCaseManagerFields.caseManagerPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formCaseManagerFields.caseManagerPhoneExt;
      response.people.fax = formCaseManagerFields.caseManagerFax;
      response.address.street1 = formCaseManagerFields.caseManagerAddress;
      response.address.street2 = formCaseManagerFields.caseManagerAddress2;
      response.address.zipCode.city = formCaseManagerFields.caseManagerCity;
      response.address.zipCode.county = formCaseManagerFields.caseManagerCounty;
      response.address.zipCode.state = formCaseManagerFields.caseManagerState;
      response.address.zipCode.zip = formCaseManagerFields.caseManagerZip;
      response.address.zipCode.id = formCaseManagerFields.caseManagerZipId;
      response.address.zipCodeId = formCaseManagerFields.caseManagerZipCodeId;
      response.comments = formCaseManagerFields.caseManagerNotes;
      response.communicationPreference = formCaseManagerFields.communicationPreference;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.caseManagerOffice;
      const caseManagerId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        caseManagerId,
        refId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.people.phone === '' || response.people.phone === undefined) {
        alert('You must provide a 10 digit phone number in order to save.');
        return;
      }
      saveCaseManager(params);
      loadCaseManagerInfoToggle();
    } else if (!isNew && !caseManagerInfo && !isFromSameAsRef) {
      const response =
        updatedCaseManagerInfo && updatedCaseManagerInfo.caseManager;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formCaseManagerFields.caseManagerFirstName;
      response.people.last = formCaseManagerFields.caseManagerLastName;
      response.people.email1 = formCaseManagerFields.caseManagerEmail;
      response.people.phone =
        formCaseManagerFields.caseManagerPhone &&
        formCaseManagerFields.caseManagerPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formCaseManagerFields.caseManagerPhoneExt;
      response.people.fax = formCaseManagerFields.caseManagerFax;
      response.address.street1 = formCaseManagerFields.caseManagerAddress;
      response.address.street2 = formCaseManagerFields.caseManagerAddress2;
      response.address.zipCode.city = formCaseManagerFields.caseManagerCity;
      response.address.zipCode.county = formCaseManagerFields.caseManagerCounty;
      response.address.zipCode.state = formCaseManagerFields.caseManagerState;
      response.address.zipCode.zip = formCaseManagerFields.caseManagerZip;
      response.address.zipCode.id = formCaseManagerFields.caseManagerZipId;
      response.address.zipCodeId = formCaseManagerFields.caseManagerZipCodeId;
      response.communicationPreference = formCaseManagerFields.communicationPreference;
      response.comments = formCaseManagerFields.caseManagerNotes;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.caseManagerOffice;
      const caseManagerId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        caseManagerId,
        refId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.people.phone === '' || response.people.phone === undefined) {
        alert('You must provide a 10 digit phone number in order to save.');
        return;
      }
      saveCaseManager(params);
      loadCaseManagerInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.comments = formCaseManagerFields.caseManagerNotes;
      response.people = {
        id: 0,
        first: formCaseManagerFields.caseManagerFirstName,
        last: formCaseManagerFields.caseManagerLastName,
        email1: formCaseManagerFields.caseManagerEmail,
        phone:
          formCaseManagerFields.caseManagerPhone &&
          formCaseManagerFields.caseManagerPhone.replace(/[\s()-]/g, ''),
        phoneExt: formCaseManagerFields.caseManagerPhoneExt,
        fax: formCaseManagerFields.caseManagerFax,
      };
      response.address = {
        id: 0,
        street1: formCaseManagerFields.caseManagerAddress,
        street2: formCaseManagerFields.caseManagerAddress2,
        zipCodeId: formCaseManagerFields.caseManagerZipCodeId,
        zipCode: {
          city: formCaseManagerFields.caseManagerCity,
          county: formCaseManagerFields.caseManagerCounty,
          state: formCaseManagerFields.caseManagerState,
          zip: formCaseManagerFields.caseManagerZip,
          id: formCaseManagerFields.caseManagerZipId,
          timeZone: formCaseManagerFields.caseManagerTimeZone,
        },
      };
      response.communicationPreference = formCaseManagerFields.communicationPreference;

      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      const caseManagerId = response.id;
      const refId = referralId || 0;
      const params = {
        response,
        userId,
        caseManagerId,
        refId,
      };
      saveCaseManager(params);
      loadCaseManagerInfoToggle();
    }
    // setIsExternal();
    setCaseManagerEdited(true)
  };

  const getBody = () => {
    if (caseManagerInfo && caseManagerInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new Case Manager as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Case Manager?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsFromSameAsRef(false);
    setFormCaseManagerFields({
      caseManagerFirstName: (fromExternal.fromExternal ? getSplitName(NewCaseManagerName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
      caseManagerLastName: (fromExternal.fromExternal ? getSplitName(NewCaseManagerName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
      companyName: '',
      caseManagerEmail: (externalRefData?.CaseManagerEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
      caseManagerPhone: (externalRefData?.CaseManagerPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
      caseManagerPhoneExt: '',
      caseManagerFax: '',
      caseManagerAddress: '',
      caseManagerAddress2: '',
      caseManagerCity: '',
      caseManagerCounty: '',
      caseManagerState: '',
      caseManagerZip: '',
      caseManagerZipCodeId: '',
      caseManagerZipId: '',
      caseManagerTimeZone: '',
      caseManagerNotes: '',
    });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadCaseManagerModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              Cannot assign Case Manager to a non Case Manager
              <br />
              <br />
              <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={() => onToggle()}
              >
                Ok{' '}
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  const onToggle = () => {
    setIsConfirmSameAsRef(!isConfirmSameAsRef);
    setIsFromSameAsRef(false);
  };

  const confirmSameAsRef = () => {
    setIsFromSameAsRef(true);
    if (updateSelectedItemRef === 'Case Manager') {
      const params = {
        functionName: 'loadCaseManager',
        userId: userId,
        id: updateDataFromRef,
      };
      getLoadCaseManager(params);
      const pplCheck =
        (updatedRefInfo.referrer && updatedRefInfo.referrer.people) || {};
      const adrCheck =
        (updatedRefInfo.referrer && updatedRefInfo.referrer.address) || {};
      const result = {
        caseManagerFirstName: pplCheck.first,
        caseManagerLastName: pplCheck.last,
        companyName: '',
        caseManagerEmail: pplCheck.email1,
        caseManagerPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        caseManagerPhoneExt: pplCheck && pplCheck.phoneExt,
        caseManagerFax: pplCheck && pplCheck.fax,
        caseManagerAddress: adrCheck.street1,
        caseManagerZipCodeId: adrCheck.zipCodeId,
        caseManagerZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        caseManagerTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        caseManagerAddress2: adrCheck.street2,
        caseManagerCity: adrCheck.zipCode && adrCheck.zipCode.city,
        caseManagerCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        caseManagerState: adrCheck.zipCode && adrCheck.zipCode.state,
        caseManagerZip: adrCheck.zipCode && adrCheck.zipCode.zip,
        caseManagerNotes: loadCaseManagerInfo.comments,
        communicationPreference: loadCaseManagerInfo.communicationPreference,
      };
      setFormCaseManagerFields(result);
      openLoadCaseManagerModal();
    } else {
      return <>{onToggle()}</>;
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadCaseManagerModal}>
        <ModalHeader toggle={loadCaseManagerInfoToggle}>
          ADD A NEW Case Manager
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={3}>
              <Label for="caseManagerFirstName">Case Mgr First Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="caseManagerFirstName"
                className={caseManagerValidFirstName ? '' : "error"}
                value={formCaseManagerFields.caseManagerFirstName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem ||
                    (!isNew && !caseManagerInfo && !isFromSameAsRef) ||
                    isFromSameAsRef) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={3}>
              <Label for="caseManagerLastName">Case Mgr Last Name</Label>{' '}
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="caseManagerLastName"
                className={caseManagerValidLastName ? '' : "error"}
                value={formCaseManagerFields.caseManagerLastName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem ||
                    (!isNew && !caseManagerInfo && !isFromSameAsRef) ||
                    isFromSameAsRef) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={3}>
              <Label for="companyName">Company Name</Label>
              <Input
                type="text"
                id="companyName"
                value={formCaseManagerFields.companyName || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <Col style={{ top: '-8px', color: 'red', paddingLeft: 0 }}>
            {(selectedItem ||
              (!isNew && !caseManagerInfo && !isFromSameAsRef) ||
              isFromSameAsRef) && (
                <span>
                  {isDisabledInput()
                    ? 'Please contact Administrator if you want to change a Case Manager Name'
                    : 'Warning: Changing the name on this record will also change it on any referral that this record is linked to!'}
                </span>
              )}
          </Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="caseManagerAddress">Case Mgr Address</Label>
              <Input
                type="text"
                id="caseManagerAddress"
                value={formCaseManagerFields.caseManagerAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="caseManagerAddress2">Case Mgr Address 2</Label>
              <Input
                type="text"
                id="caseManagerAddress2"
                value={formCaseManagerFields.caseManagerAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="caseManagerCity">Case Mgr City</Label>
              <Input
                type="text"
                id="caseManagerCity"
                value={formCaseManagerFields.caseManagerCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="caseManagerState">State</Label>
              <Input
                type="text"
                id="caseManagerState"
                value={formCaseManagerFields.caseManagerState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="caseManagerCounty">County</Label>
              <Input
                type="text"
                id="caseManagerCounty"
                value={formCaseManagerFields.caseManagerCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="caseManagerZip">Case Mgr Zip</Label>
              <Input
                type="text"
                id="caseManagerZip"
                value={formCaseManagerFields.caseManagerZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="caseManagerZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'caseManagerZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="caseManagerZip"
                id="caseManagerZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="caseManagerEmail">Case Mgr Email</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="caseManagerEmail"
                className={caseManagerValidEmail ? '' : "error"}
                value={formCaseManagerFields.caseManagerEmail || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="caseManagerPhone">Case Mgr Phone#</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="tel"
                id="caseManagerPhone"
                value={formCaseManagerFields.caseManagerPhone || ''}
                className={caseManagerValidPhone ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="caseManagerPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="caseManagerPhoneExt"
                value={formCaseManagerFields.caseManagerPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="caseManagerFax">Case Mgr Fax#</Label>
              <Input
                type="text"
                id="caseManagerFax"
                value={formCaseManagerFields.caseManagerFax || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
              <Col sm={4} >
                  <Label for="communicationPreference">Communication Preference</Label>
                  <AppInput
                      id="communicationPreference"
                      type="select"
                      changeHandler={(e, val) => { 
                        setFormCaseManagerFields({ ...formCaseManagerFields, communicationPreference: val }) 
                          
                      }}
                      option={{
                          value: 'value',
                          displayValue: 'label',
                      }}
                      placeholder="Choose..."
                      value={(formCaseManagerFields.communicationPreference === '' || formCaseManagerFields.communicationPreference === undefined)  ? 'Email' : formCaseManagerFields.communicationPreference}
                      data={communicationPreferences}
                  />
              </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label for="caseManagerNotes">Comments</Label>
              <Input
                type="textarea"
                id="caseManagerNotes"
                value={formCaseManagerFields.caseManagerNotes || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentCaseManager}>
              CHANGE CURRENT CASE MANAGER
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSeachCaseManager}>
        <ModalHeader toggle={searchCaseManagerToggle}>
          SEARCH CASE MANAGER
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    id="firstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.firstName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>{' '}
                  <Input
                    type="text"
                    id="lastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.lastName}

                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
              <Col sm={3}>
                <br />
                <Button
                  style={{ marginTop: '7px' }}
                  type="button"
                  disabled={R.isEmpty(updatedRefInfo)}
                  onClick={confirmSameAsRef}
                >
                  SAME AS REF
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchCaseManagerTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!caseManagerInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadCaseManagerModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
      <PopupModal
        content={getContent()}
        externalToggle={onToggle}
        title={''}
        isOpen={isConfirmSameAsRef}
        size="md"
      />
    </>
  );
};

NewCaseManagerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  caseManagerInfo: PropTypes.array,
  getCaseManagerList: PropTypes.func,
  resetSearchCaseManagerTable: PropTypes.func,
  getLoadCaseManager: PropTypes.func,
  loadCaseManagerInfo: PropTypes.object,
  caseManagerType: PropTypes.array,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadCaseManager: PropTypes.func,
  saveCaseManager: PropTypes.func,
  alert: PropTypes.func,
  resetCaseManagerInfo: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);

  return {
    caseManagerInfo: fast360Info.caseManagerInfo,
    loadCaseManagerInfo: fast360Info.loadCaseManagerInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    updatedCaseManagerInfo: fast360Info.updatedCaseManagerInfo,
    updateSelectedItemRef: fast360Info.updateSelectedItemRef,
    updateDataFromRef: fast360Info.updateDataFromRef,
    updatedRefInfo: fast360Info.updatedRefInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getCaseManagerList: data =>
    dispatch(fast360Operations.getCaseManagerList(data)),
  getLoadCaseManager: data =>
    dispatch(fast360Operations.getLoadCaseManager(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchCaseManagerTable: () =>
    dispatch(fast360Actions.resetSearchCaseManagerTable()),
  resetLoadCaseManager: () => dispatch(fast360Actions.resetLoadCaseManager()),
  resetCaseManagerInfo: () => dispatch(fast360Actions.resetCaseManagerInfo()),
  saveCaseManager: data => dispatch(fast360Operations.saveCaseManager(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCaseManagerModal);
