import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';

import { expertsOperations, expertsActions } from '../store';
import { PREVIEW_TAB } from '../utils/constantUtils';

const ID = 'ID';
const EXPERT_NAME = 'Expert Name';
class FindExpertResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: ID,
      sortOrder: true,
    };
  }

  getRow(item) {
    return {
      id: item.expert_id || 0,
      fullNameRenderer: this.getFullNameColumn(item),
      status: item.status || '',
      isGroup: item.isgroup || 0,
      peopleId: item.people_id || 0,
      fullName: item.fullname || '',
    };
  }

  getFullNameColumn(item) {
    const userIcon = item.isgroup === 0 ? faUser : faUsers;

    return (
      <span>
        <FontAwesomeIcon className="green-text" icon={userIcon} />
        <span style={{ marginLeft: 5 }}>{item.fullname || ''}</span>
      </span>
    );
  }

  onRowClickHandler = item => {
    this.props.updateSelectedExpert({
      fullName: item.fullName,
      expertId: item.id,
      status: item.status,
      isGroup: item.isGroup,
    });
    this.props.getPreviewExpert({
      expertId: item.id,
      peopleId: item.isGroup === 0 ? item.peopleId : null,
    });
    this.props.externalToggle();
    this.props.updateActiveTab(PREVIEW_TAB);
  };

  getModalBody() {
    const data = this.props.data
      .sort((item1, item2) => this.sortData(item1, item2))
      .map(item => this.getRow(item));
    return (
      <>
        <div className="row-clickable">
          <AppTable
            resizable
            columns={[
              {
                header: this.getHeader(ID),
                field: 'id',
              },
              {
                header: this.getHeader(EXPERT_NAME),
                field: 'fullNameRenderer',
              },
            ]}
            data={data}
            autoPagination={true}
            rowClickHandler={this.onRowClickHandler}
          />
        </div>
        <span>{data.length === 0 ? 'No' : data.length} experts found</span>
      </>
    );
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case ID:
        return sortOrder
          ? item1.expert_id - item2.expert_id
          : item2.expert_id - item1.expert_id;
      case EXPERT_NAME:
      default: {
        const fullName1 = item1.fullname || '';
        const fullName2 = item2.fullname || '';
        return sortOrder
          ? fullName1.localeCompare(fullName2)
          : fullName2.localeCompare(fullName1);
      }
    }
  }

  render() {
    return (
      <PopupModal
        content={this.getModalBody()}
        title="Results"
        externalToggle={this.props.externalToggle}
        isOpen={this.props.isOpen}
        size="lg"
      />
    );
  }
}

FindExpertResultModal.propTypes = {
  data: PropTypes.array.isRequired,
  externalToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  updateSelectedExpert: PropTypes.func.isRequired,
  getPreviewExpert: PropTypes.func.isRequired,
  updateActiveTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  updateSelectedExpert: data =>
    dispatch(expertsActions.updateSelectedExpert(data)),
  getPreviewExpert: data => dispatch(expertsOperations.getPreviewExpert(data)),
  updateActiveTab: activeTab =>
    dispatch(expertsActions.updateExpertActiveTab(activeTab)),
});

export default connect(null, mapDispatchToProps)(FindExpertResultModal);
