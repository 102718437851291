import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import AppTable from 'shared/components/table/AppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { billOperationsActions, billOperationsOperations } from '../store';
import { authSelectors } from 'modules/auth/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import BillSearchResults from './BillSearchResults';
import LinkActions from './LinkActions';
import URDecision from './URDecision';

const ClaimSearchResults = ({
  searchResults,
  sites,
  toggleSearch,
  findBills,
  onURDecision,
  clearURDecisionData,
  stateList,
  stateDocuments,
  billsInClaim,
  currentUserId,
  profile,
  userId,
  isFromManageBill,
  searchCriteria,
  envRef
}) => {
  const [showBillTable, setShowBillTable] = useState(false);
  const [showURDecision, setShowURDecision] = useState(false);
  const [claimItem, setClaimItem] = useState({});

  const hide = {
    display: 'none',
  };

  const getClaimColumn = item => {
    let claimCheck;

    if (item.isCCDLSPI == "true") {
      claimCheck = (
        <AppTooltip text={'Claim is either closed, controverted, denied, litigated, settled, pending or under investigation'} id={'claimCheck-' + item.id} container="body">
          <FontAwesomeIcon
            className="cursor-hover"
            style={{ marginLeft: 5, color: 'red' }}            
            icon={faTimesCircle}
          />
        </AppTooltip>
      );
    }

    return (
      <>
        {item.chr_claimnumber} {claimCheck}

        <br />
        <span style={{ fontStyle: 'italic' }}>
          {item.billcount === '0'
            ? 'No Bills Found'
            : item.billcount + ' bill(s)'}
        </span>
      </>
    );
  };

  const getPatientColumn = item => {
    return (
      <>
        {item.chr_firstname + ' ' + item.chr_lastname}
        <br />
        <span style={{ fontStyle: 'italic' }}>DOI: {item.dat_injurydate}</span>
      </>
    );
  };

  const getEmployerColumn = item => {
    return <>{item.employer}</>;
  };

  const getClientColumn = (sites, item) => {
    const site = sites.filter(
      site => site.int_sitecode === parseInt(item.int_siteID)
    );

    return (
      <>
        {item.chr_sitename}
        <div style={{ fontStyle: 'italic' }}>Adjuster: {item.adjname}</div>
      </>
    );
  };

  const getActionsColumn = item => {
    return (
      <LinkActions
        notes={item.notes}
        urData={item}
        urAction={urDecision}
        detailLink={`${process.env.REACT_APP_SLP_BASE_URL}/reports/claimhistory.php?injuryid=${item.int_injurycode}&profile=${item.profile}&script=Y`}
      />
    );
  };

  const urDecision = claim => {
    onURDecision({ ...claim, userId });
    setClaimItem(claim);
    toggleURDecision();
  };

  const toggleURDecision = () => {
    setShowURDecision(!showURDecision);
    toggleSearch();
  };

  const toggleBillTable = () => {
    setShowBillTable(!showBillTable);
    toggleSearch();
  };

  const exitURDecision = () => {
    setClaimItem({});
    clearURDecisionData();
    toggleURDecision();
  };

  const getColumnDefinition = () => {
    const columns = [
      {
        header: 'Claim #',
        field: 'chr_claimnumber',
        rowKey: true,
        render: item => getClaimColumn(item),
        percent: '5',
      },
      {
        header: 'Patient',
        field: 'chr_firstname',
        render: item => getPatientColumn(item),
        percent: '5',
      },
      {
        header: 'Employer',
        field: 'employer',
        render: item => getEmployerColumn(item),
        percent: '5',
      },
      {
        header: 'Client',
        field: 'int_siteID',
        render: item => getClientColumn(sites, item),
        percent: '5',
      },
      {
        header: 'Actions',
        field: 'actions',
        render: item => getActionsColumn(item),
        percent: '5',
        sortAble: false,
      },
    ];

    return columns;
  };

  const getBillsFromClaim = item => {
    const requestBody = {
      claimNumber: item.int_injurycode,
      billScan: '',
      end: '',
      client: '',
      state: '',
      billTypes: '',
      billStatus: '',
      processor: '',
      entryDate: '',
      tin: (searchCriteria === null ? '' : searchCriteria.tin),
      lastNameOrSsn: '',
      firstName: '',
      siteId: '',
      userId: currentUserId,
      profile: profile,
    };
    findBills(requestBody);
    setClaimItem(item);
    toggleBillTable();
  };

  return (
    <>
      {/* Claim Table */}
      <div style={!showBillTable && !showURDecision ? {} : hide}>
        <AppTable
          columns={getColumnDefinition()}
          data={searchResults}
          autoPagination={envRef?false:true}
          sortAble={true}
          resizable={true}
          rowDoubleClickHandler={getBillsFromClaim}
          key={`findBillsTable-${getColumnDefinition()
            .map(column => column.field)
            .join('-')}`}
        />
      </div>

      {/* Bill Table */}
      <div style={showBillTable && !showURDecision ? {} : hide}>
        <BillSearchResults
          searchResults={billsInClaim}
          stateList={stateList}
          currentUserId={currentUserId}
          stateDocuments={stateDocuments}
          claimControlHandler={toggleBillTable}
          claimType="billsFromClaim"
          sites={sites}
          claimData={claimItem}
          exitHandler={toggleBillTable}
          envRef={envRef}
        />
      </div>
      {/*URDecision Table  */}
      <div style={showURDecision ? {} : hide}>
        <URDecision exitHandler={exitURDecision} claimData={claimItem} />
      </div>
    </>
  );
};

ClaimSearchResults.propTypes = {
  searchResults: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  billsInClaim: PropTypes.array.isRequired,
  stateDocuments: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  findBills: PropTypes.func.isRequired,
  onURDecision: PropTypes.func.isRequired,
  clearURDecisionData: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  profile: PropTypes.string.isRequired,
  userId: PropTypes.string,
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);

  return {
    userId: user && user.user_id,
  };
};

const mapDispatchToProps = dispatch => ({
  findBills: data =>
    dispatch(billOperationsOperations.findBills(data, 'billsFromClaim')),
  clearURDecisionData: () =>
    dispatch(billOperationsActions.updateURDecisionData([])),
  onURDecision: claim =>
    dispatch(billOperationsOperations.getURDecision(claim)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSearchResults);
