import { combineReducers } from 'redux';
import { expertInfoReducers } from '../../expert-info/store';

import {
  EXPERT_LOAD_INITIAL_DATA,
  UPDATE_RADIUS_SEARCH_TABLE,
  UPDATE_TASK_TABLE,
  UPDATE_EXPERT_REPORT_TABLE,
  UPDATE_EXPERTS_SEARCH_RESULT,
  UPDATE_SELECTED_EXPERT,
  UPDATE_PREVIEW_USER,
  UPDATE_EXPERT_ACTIVE_TAB,
  UPDATE_CHECK_DUPLICATE_EXPERT_GROUP,
  UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES,
  RESET_NEW_EXPERT_GROUP_VIEW,
  EXPERT_INFO_MODULE,
  UPDATE_CHECK_DUPLICATE_EXPERT,
  UPDATE_SELECTED_EXPERT_ADDRESSES,
  RESET_NEW_EXPERT_VIEW,
} from './constants';
import { TASK_LIST_TAB } from '../utils/constantUtils';

const taskListDefaultState = {
  tasks: [],
};

const taskListReducer = (state = taskListDefaultState, action) => {
  switch (action.type) {
    case EXPERT_LOAD_INITIAL_DATA:
      return {
        ...state,
        tasks: action.payload.tasks,
      };
    case UPDATE_TASK_TABLE:
      return {
        ...state,
        tasks: action.payload,
      };
    default:
      return { ...state };
  }
};

const faxListDefaultState = {
  faxes: [],
  pendingFax: [],
  historyFax: [],
};
const faxListReducer = (state = faxListDefaultState, action) => {
  switch (action.type) {
    case EXPERT_LOAD_INITIAL_DATA:
      return {
        ...state,
        faxes: action.payload.faxes,
        pendingFax: action.payload.pendingFax,
        historyFax: action.payload.historyFax,
      };
    default:
      return { ...state };
  }
};

const reportDefaultState = {
  data: [],
};
const reportReducer = (state = reportDefaultState, action) => {
  switch (action.type) {
    case UPDATE_EXPERT_REPORT_TABLE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
};

const radiusDefaultState = {
  experts: [],
};
const radiusReducer = (state = radiusDefaultState, action) => {
  switch (action.type) {
    case UPDATE_RADIUS_SEARCH_TABLE:
      return {
        ...state,
        experts: action.payload,
      };
    default:
      return { ...state };
  }
};

const previewDefaultState = {
  experts: [],
  selectedExpert: {
    fullName: '',
    expertId: null,
    status: '',
    isGroup: 0,
  },
  expert: {
    expertNetworks: [],
    expertServices: [],
    addresses: [],
    credentials: [],
    credentialTypes: [],
    people: {},
    groupExpert: [],
    expert: {},
  },
};
const previewReducer = (state = previewDefaultState, action) => {
  switch (action.type) {
    case UPDATE_EXPERTS_SEARCH_RESULT:
      return {
        ...state,
        experts: action.payload,
      };
    case UPDATE_SELECTED_EXPERT:
      return {
        ...state,
        selectedExpert: action.payload,
      };
    case UPDATE_PREVIEW_USER:
      return {
        ...state,
        expert: action.payload,
      };
    default:
      return { ...state };
  }
};

const newExpertGroupDefaultState = {
  duplicateExpertGroups: [],
  selectedExpertGroupAddresses: [],
};

const newExpertGroupReducer = (state = newExpertGroupDefaultState, action) => {
  switch (action.type) {
    case UPDATE_CHECK_DUPLICATE_EXPERT_GROUP:
      return {
        ...state,
        duplicateExpertGroups: action.payload,
      };
    case UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES:
      return {
        ...state,
        selectedExpertGroupAddresses: action.payload,
      };
    case RESET_NEW_EXPERT_GROUP_VIEW:
      return {
        ...newExpertGroupDefaultState,
      };
    default:
      return { ...state };
  }
};

const newExpertDefaultState = {
  duplicateExperts: [],
  selectedExpertAddresses: [],
};

const newExpertReducer = (state = newExpertDefaultState, action) => {
  switch (action.type) {
    case UPDATE_CHECK_DUPLICATE_EXPERT:
      return {
        ...state,
        duplicateExperts: action.payload,
      };
    case UPDATE_SELECTED_EXPERT_ADDRESSES:
      return {
        ...state,
        selectedExpertAddresses: action.payload,
      };
    case RESET_NEW_EXPERT_VIEW:
      return {
        ...newExpertDefaultState,
      };
    default:
      return { ...state };
  }
};

const initialDataDefault = {
  states: [],
  provCredStates: [],
  radiuses: [],
  expertNetworks: [],
  masterSpecialties: [],
  reportsSpecialties: [],
  services: [],
  expertGroupTypes: [],
  expertUsers: [],
  users: [],
  activeTab: TASK_LIST_TAB,
  expertReport: [],
};
const initialDataReducer = (state = initialDataDefault, action) => {
  switch (action.type) {
    case EXPERT_LOAD_INITIAL_DATA:
      return {
        ...state,
        states: action.payload.states,
        radiuses: action.payload.radiuses,
        expertNetworks: action.payload.expertNetworks,
        masterSpecialties: action.payload.masterSpecialties,
        services: action.payload.services,
        expertGroupTypes: action.payload.expertGroupTypes,
        expertUsers: action.payload.expertUsers,
        users: action.payload.users,
        provCredStates: action.payload.provCredStates,
        reportsSpecialties: action.payload.reportsSpecialties,
      };
    case UPDATE_EXPERT_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return { ...state };
  }
};

const expertReducer = combineReducers({
  initialData: initialDataReducer,
  tasks: taskListReducer,
  faxes: faxListReducer,
  report: reportReducer,
  radiusSearch: radiusReducer,
  preview: previewReducer,
  newExpertGroup: newExpertGroupReducer,
  [EXPERT_INFO_MODULE]: expertInfoReducers,
  newExpert: newExpertReducer,
});

export default expertReducer;
