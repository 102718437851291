import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import {
    fast360Operations,
    fast360Selectors,
    fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const QuickSearch = ({
    isOpen,
    onClose,
    quickSearchList,
    getQuickSearchList,
    resetQuickSearchList,
    userId,
    setFromQuickSearch,
    openReferral,
    setAdvValues,
    alert,
    setCurrentWorkList,
}) => {
    const [isOpenSearchClient, setIsOpenSearchClient] = useState(false);
    const [searchFields, setSearchFields] = useState({
        confReferralId: '',
        confServiceType: '',
        confServiceId: '',
        claimantFirst: '',
        claimantLast: '',
        claimNumber: '',
        vendorName: '',
        clientName: ''
    });

    useEffect(() => {
        if (isOpen) {
            setIsOpenSearchClient(true);
        }
    }, [isOpen]);

    const searchClientToggle = () => {
        setIsOpenSearchClient(false);
        onClose();
        resetFormData();
        resetQuickSearchList();
    };

    const resetFormData = () => {
        setSearchFields({
            confReferralId: '',
            confServiceType: '',
            confServiceId: '',
            claimantFirst: '',
            claimantLast: '',
            claimNumber: '',
            vendorName: '',
            clientName: ''
        });
    }

    const onQuickSearchHandler = event => {
        const target = event.target;
        let value = target.value;
        const name = target.id;
        setSearchFields({ ...searchFields, [name]: value });
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const params = {
            ...searchFields,
            userId,
        };
        if (searchFields.confServiceType === '' || searchFields.confServiceType === undefined) {
            alert('For efficiency purposes please add the 3 letter abbreviation for the Conf Number.');
            return;
        }
        getQuickSearchList(params);
    };

    const resetHandler = () => {
        resetFormData();
        resetQuickSearchList();
    }

    const onRowDoubleClick = (item) => {
        setFromQuickSearch(true);
        searchClientToggle();
        setAdvValues(null);
        openReferral(item?.PK_referral_id,
            item?.chr_reference_table,
            item?.int_reference_table_id,
            0,
            item?.chr_claim_no,
            item?.chr_reference_table,
            false)
        setCurrentWorkList(item);
    }

    const getQickSearchListTable = () => {
        return (
            <div>
                <AppTable
                    modalHeight={400}
                    columns={[
                        {
                            header: 'Conf#',
                            field: 'ConfirmNumber',
                        },
                        {
                            header: 'Claimant Name',
                            field: 'claimantName',
                        },
                        {
                            header: 'Claim#',
                            field: 'chr_claim_no',
                        },
                        {
                            header: 'Vendor Name',
                            field: 'vendorName',
                        },
                        {
                            header: 'Client Name',
                            field: 'clientName',
                        },
                        {
                            header: 'DOS',
                            field: 'dos',
                        },
                        {
                            header: 'HCPC',
                            field: 'hcpc',
                        },
                    ]}
                    data={quickSearchList || []}
                    autoPagination={true}
                    sortAble={true}
                    resizable={true}
                    rowDoubleClickHandler={onRowDoubleClick}
                    sortImmediately={false}
                    key="quick-search-table"
                />
            </div>
        );
    };

    return (
        <>
            <Modal size="lg" isOpen={isOpenSearchClient}>
                <ModalHeader toggle={searchClientToggle}>QUICK SEARCH</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs='4'>
                            <Row style={{ marginLeft: '122px' }}>
                                <Label>Confirmation#</Label>
                            </Row>
                        </Col>
                        <Col xs='3'>
                            <Row>
                                <Input
                                    value={searchFields.confReferralId || ''}
                                    style={{ width: '50%', marginLeft: '-23px' }}
                                    id="confReferralId"
                                    name="confReferralId"
                                    onChange={e => onQuickSearchHandler(e)}
                                />-
                            </Row>
                        </Col>
                        <Col xs='2'>
                            <Row>
                                <Input
                                    value={searchFields.confServiceType || ''}
                                    style={{ width: '50%', marginLeft: '-109px' }}
                                    id="confServiceType"
                                    name="confServiceType"
                                    onChange={e => onQuickSearchHandler(e)}
                                />-
                            </Row>
                        </Col>
                        <Col xs='3'>
                            <Row>
                                <Input
                                    value={searchFields.confServiceId || ''}
                                    style={{ width: '50%', marginLeft: '-165px' }}
                                    id="confServiceId"
                                    name="confServiceId"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='3' md={{ size: 2, offset: 2 }}>
                            <Row>
                                <Label>Claimant Name</Label>
                            </Row>
                        </Col>
                        <Col xs='4'>
                            <Row>
                                <Input
                                    value={searchFields.claimantFirst || ''}
                                    style={{ width: '50%', marginLeft: '-23px' }}
                                    id="claimantFirst"
                                    name="claimantFirst"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                        <Col xs='4'>
                            <Row>
                                <Input
                                    value={searchFields.claimantLast || ''}
                                    style={{ width: '50%', marginLeft: "-133px" }}
                                    id="claimantLast"
                                    name="claimantLast"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='4' md={{ size: 3, offset: 2 }}>
                            <Row>
                                <Label>Claim#</Label>
                            </Row>
                        </Col>
                        <Col xs='7'>
                            <Row>
                                <Input
                                    value={searchFields.claimNumber || ''}
                                    style={{ width: '62%', marginLeft: '-88px' }}
                                    id="claimNumber"
                                    name="claimNumber"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='4' md={{ size: 3, offset: 2 }}>
                            <Row>
                                <Label>Vendor Name</Label>
                            </Row>
                        </Col>
                        <Col xs='7'>
                            <Row>
                                <Input
                                    value={searchFields.vendorName || ''}
                                    style={{ width: '62%', marginLeft: '-88px' }}
                                    id="vendorName"
                                    name="vendorName"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='4' md={{ size: 3, offset: 2 }}>
                            <Row>
                                <Label>Client Name</Label>
                            </Row>
                        </Col>
                        <Col xs='7'>
                            <Row>
                                <Input
                                    value={searchFields.clientName || ''}
                                    style={{ width: '62%', marginLeft: '-88px' }}
                                    id="clientName"
                                    name="clientName"
                                    onChange={e => onQuickSearchHandler(e)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='4' md={{ size: 2, offset: 2 }}>
                            <Button type='submit' style={{ width: '157px' }} onClick={onFormSubmit}>SEARCH
                            </Button>
                        </Col>
                        <Col xs='4' >
                            <Button type='reset' style={{ width: '157px', marginLeft: "62px" }} onClick={resetHandler}>RESET
                            </Button>
                        </Col>
                        <Col xs='4'>
                            <Button type='cancel' style={{ width: '157px' }} onClick={searchClientToggle}>CANCEL
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    {getQickSearchListTable()}
                </ModalBody>
            </Modal>
        </>
    );
};

QuickSearch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    clientInfo: PropTypes.array,
    getClientInfo: PropTypes.func,
    resetSearchClientTable: PropTypes.func,
    loadClientWithFees: PropTypes.func,
    saveObj: PropTypes.func,
    loadClientInfo: PropTypes.object,
    resetLoadClient: PropTypes.func,
    alert: PropTypes.func,
};

const mapStateToProps = state => {
    const fast360Info = fast360Selectors.getFast360Info(state);
    const user = authSelectors.getUser(state);
    const initialData = fast360Selectors.getFast360InitialData(state);
    return {
        quickSearchList: fast360Info.quickSearchList,
        userId: user.id,
        currentTaskId: state.FAST360.fast360Reducer.updateloadCurrentTaskId
            ? state.FAST360.fast360Reducer.updateloadCurrentTaskId
            : [],
    };
};

const mapDispatchToProps = dispatch => ({
    getQuickSearchList: data => dispatch(fast360Operations.getQuickSearchList(data)),
    resetQuickSearchList: () =>
        dispatch(fast360Actions.resetQuickSearchList()),
    loadFromWorkList: data => dispatch(fast360Operations.loadFromWorkList(data)),
    getCurrentTaskId: (data) => dispatch(fast360Operations.getCurrentTaskId(data)),
    setAdvValues: (data) => dispatch(fast360Actions.setAdvValues(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    setCurrentWorkList: data => dispatch(fast360Actions.setCurrentWorkList(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
