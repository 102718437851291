import React, { useEffect, useState } from 'react';
import AppTable from 'shared/components/table/AppTable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import appModules, { FAST_360_MODULE } from 'common/appModules';
import { commonActions } from 'common/store';
import { fast360Selectors } from './store/index';

import { authSelectors } from 'modules/auth/store';
import { Col, Row, Button, FormGroup } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';

import Fast360TopMenu from './components/Fast360TopMenu';
import Fast360TopNav from './components/Fast360TopNav';
import Fast360LandingTable from './components/Fast360LandingTable';
import Fast360Ref from './components/fast360ref';
import { commonOperations } from 'common/store';
import { AdvSearchContextProvider } from './components/AdvanceSearch/contexts/AdvSearchContext';
import { CheckBoxContextProvider } from './components/AdvanceSearch/contexts/CheckBoxContext';
import { fast360Operations, fast360Actions } from './store';
import AdvSearch from './components/AdvanceSearch/Fast360AdvSearch';
import NewAttachmentPrompt from './components/NewAttachmentPrompt';
import GpIntegrationsModal from './components/GpIntegrationsModal';
import ManagerOverrideModal from './components/ManagerOverrideModal';
import QuickSearch from './components/QuickSearch';
import moment from 'moment';
import { useEventListener } from 'shared/hooks/useEventListener';
import Fast360InvoiceCoordinator from './components/Fast360InvoiceCoordinator';
const Fast360 = ({
  currentUserId,
  loadInitialData,
  switchModule,
  getUsersWorkList,
  dataFromReducer,
  resetReferrerInfo,
  resetAdjusterInfo,
  resetEmployerInfo,
  resetPhysicianInfo,
  resetAttorneyInfo,
  resetClientInfo,
  resetCaseManagerInfo,
  resetSaveClaim,
  resetLoadClaimantById,
  resetAudInfoTable,
  updatePreviousUser,
  updateStatusClicked,
  updateMilestoneClicked,
  updateTaskClicked,
  updateMilestoneData,
  updatedPhysicianInfo,
  updatedEmployerInfo,
  updatedAttorneyInfo,
  updatedPayorInfo,
  updatedCaseManagerInfo,
  updatedAdjusterInfo,
  updatedClaimInfo,
  loadClaimInfo,
  updatedRefInfo,
  saveReferral,
  updateSaveReferral,
  updatedReferralInfo,
  loadClaimantByIdInfo,
  exteneralReferralData,
  alert,
  updatePostExternal,
  updateLoadFromWorkList,
  updateloadReferralAlerts,
  selectedExternalData,
  loadFromWorkList,
  loadUpdatedVendor,
  loadExistingTasks,
  loadReferralAlerts,
  callDynamicLoad,
  groupTasksProgression,
  groupTasksProgression2,
  getCurrentTaskId,
  logBreadCrumbs,
  getFollowupData,
  updateGetFollowupData,
  saveFollowUp,
  selectedWorklist,
  workListData,
  lmsUserId,
  followupData,
  audInfo,
  alertData,
  resetVendorLookupData,
  referralId,
  setCurrentWorkList,
  requiredServiceData,
  resetSaveReferral,
  clearAuthService,
  trnDetailsList,
  sptDetailsList,
  updateSelectedAuthRecord,
  currentWorkListItem,
  updateClickRecord,
  reqFields,
  setReqFields,
  resetAudiologyHcpcsList,
  resetCostSavings,
  setTrnDetails,
  setSptDetails,
  trnSaveData,
  sptsaveData,
  resetTRNInfoTable,
  resetSPTInfoTable,
  followupChangeEvent,
  followEvent,
  followupDateEvent,
  followdateEvent,
  setAdvValues,
  resetAdvancedSearchReport
}) => {
  const [isOpenManagerOverrideModal, setIsOpenManagerOverrideModal] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [isNewReferral, setIsNewReferral] = useState(false);
  const [enableVendorGrid, setEnableVendorGrid] = useState(false);
  const [isExternalReferral, setIsExternalReferral] = useState(false);
  const [fromAdvRef, setFromAdvRef] = useState(false);
  const [fromQuickSearch, setFromQuickSearch] = useState(false);
  const [isAdvSearch, setIsAdvSearch] = useState(false);
  const [isFromService, setIsFromService] = useState(false);
  const [details, setDetails] = useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedBills, setSelectedBills] = useState([]);
  const [zebraId, setZebraId] = useState('');
  const [gpIntegration, setGpIntegration] = useState('');
  const [isExternal, setIsExternal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isOpenQuickSearchModal, setIsOpenQuickSearchModal] = useState(false);
  const [dataObject, setDataObject] = useState({});
  // const [filteredVendorServices, setFilteredVendorServices] = useState([]);
  const setIsExt = () => {
    setIsExternal(!isExternal);
  };
  const [isPatientEdited, setPatientEdited] = useState(false);
  const [isReferrerEdited, setReferrerEdited] = useState(false);
  const [isAdjusterEdited, setAdjusterEdited] = useState(false);
  const [isCaseManagerEdited, setCaseManagerEdited] = useState(false);
  const [isPayorEdited, setPayorEdited] = useState(false);
  const [isPhyEdited, setPhyEdited] = useState(false);
  const [isEttorneyEdited, setEttorneyEdited] = useState(false);
  const [isEmployerEdited, setEmployerEdited] = useState(false);
  const [fromWorklist, setFromWorklist] = useState(false);
  const [currentTaskId, setCurrentTask] = useState(0);
  const [assignedGrid, setAssignedGrid] = useState([]);
  const [data, setData] = useState({});
  const [time, setTime] = useState({});
  const [followupDate, setFollowupDate] = useState('');
  const [fHrs, setFhrs] = useState('');
  const [fMin, setFmin] = useState('');
  const [timeZ, setTimeZ] = useState('');
  const [refSaved, setRefSaved] = useState(false);
  const [readyForBilling, setReadyForBilling] = useState(false);
  const [listToUpdate, setListToUpdate] = useState('');
  const [isInvoiceCoordinatorOpen, setIsInvoiceCoordinatorOpen] = useState(false);
  const dblClickWorklist = () => {
    setFromWorklist(!fromWorklist);
  };
  const [advSearchState, setAdvSearchState] = useState(null);
  const [advSearchState1, setAdvSearchState1] = useState(null);
  const [advSearchData, setAdvSearchData] = useState(null);
  const [useStoredData, setUseStoredData] = useState(false);
  

  const setWorklistTask = () => {
    setCurrentTask(currentTaskId);
  };
  //Comment because it is needed to push for a rebuild.
  useEffect(() => {
    if (!isEmpty(updatedReferralInfo) && (isNewReferral)) {
      // onNewRefExit();
      setRefSaved(true);
    }
  }, [updatedReferralInfo, isFromService])

  useEffect(() => {
    switchModule(appModules.getModule(FAST_360_MODULE));
    if (currentUserId && currentUserId !== 0) {
      // loadInitialData({ currentUserId });
      loadInitialData({ currentUserId }).then(data => console.log(data));
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: currentUserId,
        userid: currentUserId,
        breadcrumbkey: 'USERLOGIN',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      //helper.request.post('/SaveObject', breadCrumb);

      logBreadCrumbs(breadCrumb);
    }
    return () => {
      // second, we return an anonymous clean up function
      updatePreviousUser('');
      updateStatusClicked(false);
      updateMilestoneClicked(false);
      updateTaskClicked(false);
      updateMilestoneData([]);
    };
  }, [currentUserId, loadInitialData, switchModule]);

  const openQuickSearchModal = () => {
    setIsOpenQuickSearchModal(!isOpenQuickSearchModal);
  }

  const onNewReferral = () => {
    setIsNewReferral(!isNewReferral);
    resetReferrerInfo();
    resetAdjusterInfo();
    resetEmployerInfo();
    resetPhysicianInfo();
    resetAttorneyInfo();
    resetClientInfo();
    resetCaseManagerInfo();
    resetSaveClaim();
    resetAudiologyHcpcsList();
    resetCostSavings();
    resetLoadClaimantById();
    setIsFromService(false);
    setIsExternal(false);
    resetAudInfoTable();

    let taskPayload = {
      loadCurrentTaskId: true,
      loadGroupTaskProgression: false,
      loadExistingTasks: true,
      loadReferralAlerts: true,
      loadFollowUpdInfo : false,
      zebraUserId: currentUserId,
      table: '',
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
      currentTask: 0,
      referenceTable: 0,
      claimNo: '',
      userid: currentUserId,
      breadcrumbkey: 'ENTERNEWREF',
      breadcrumbrefTableId: 0,
      breadcrumbrefId: 0,
      breadcrumbrefTable: '',
    };
    callDynamicLoad(taskPayload);
    /*let alertPayload = {
      functionName: 'loadReferralAlerts',
      zebraUserId: currentUserId,
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
    };
    let taskPayload = {
      functionName: 'loadExistingTasks',
      zebraUserId: currentUserId,
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
      from: 'fromzeroservice',
    };
    let getTaskIdPayload = {
      functionName: 'getCurrentTaskId',
      zebraUserId: currentUserId,
      tableAbbrv: '',
      serviceId: 0,
    };

    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: currentUserId,
      breadcrumbkey: 'ENTERNEWREF',
      refTableId: 0,
      refId: 0,
      refTable: '',
    };
    logBreadCrumbs(breadCrumb)
    getCurrentTaskId(getTaskIdPayload);
    loadExistingTasks(taskPayload);
    loadReferralAlerts(alertPayload);*/
    resetSaveReferral();
    setData({ referralId: 0 });
    setIsNew(true);
  };

  const openExitModal = () => {
    setIsExitModalOpen(!isExitModalOpen);
  }

  const getRefreshFeesModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to exit?  All unsaved changes will be lost.</span>
        </Col>
      </Row>
    );
  };

  const getRefreshFeesModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={openExitModal}>
          No
        </Button>
        <Button color="primary" onClick={onNewRefExit}>
          Yes
        </Button>
      </>
    );
  };



  const onNewRefExit = (isSedgewickReferral) => {
    if (isSedgewickReferral) {
      onExternalReferralExit(isSedgewickReferral);
    } else if(isExternalReferral) {
      onExternalReferralExit();
    } else {
      setIsExitModalOpen(false);
      onNewReferral();
      setPatientEdited(false);
      setReferrerEdited(false);
      setAdjusterEdited(false);
      setCaseManagerEdited(false);
      setPayorEdited(false);
      setPhyEdited(false);
      setEttorneyEdited(false);
      setEmployerEdited(false);
      requiredServiceData({});
      resetSaveReferral();
      setIsNew(false);
      updateSelectedAuthRecord({ index: -1, state: false });
      setRefSaved(false);
      setReqFields([]);
      resetAudiologyHcpcsList();
      resetCostSavings();
      resetTRNInfoTable();
      resetSPTInfoTable();
    }
    setTrnDetails([]);
    setSptDetails([]);
    trnSaveData(true);
    sptsaveData(true);
    followupChangeEvent(false);
    followupDateEvent(false);
    setAdvValues('');

  };

  const onSaveHandler = () => {
    if (reqFields.length === 0) {
      if (updatedClaimInfo === null || updatedClaimInfo === undefined) {
        alert('You must provide an Patient Info in order to save.');
        return;
      }
      if ((updatedPayorInfo === null || updatedPayorInfo === undefined) && (isEmpty(loadClaimantByIdInfo?.payor) ||
        (loadClaimantByIdInfo?.payor?.name === '' || loadClaimantByIdInfo?.payor?.name === undefined))) {
        alert('You must provide an Payor Info in order to save.');
        return;
      }
      if (((updatedRefInfo && updatedRefInfo.referrer === null) ||
        (updatedRefInfo && updatedRefInfo.referrer === undefined)) && (isEmpty(loadClaimantByIdInfo?.referrer) ||
          ((loadClaimantByIdInfo?.referrer?.people?.first === '' || loadClaimantByIdInfo?.referrer?.people?.first === undefined) &&
            (loadClaimantByIdInfo?.referrer?.people?.last === '' || loadClaimantByIdInfo?.referrer?.people?.last === undefined)))) {
        alert('You must provide an Referrer Info in order to save.');
        return;
      }
      if (((updatedAdjusterInfo && updatedAdjusterInfo.adjuster === null) || (updatedAdjusterInfo && updatedAdjusterInfo.adjuster === undefined)) &&
        (isEmpty(loadClaimantByIdInfo?.adjuster) ||
          ((loadClaimantByIdInfo?.adjuster?.people?.first === '' || loadClaimantByIdInfo?.adjuster?.people?.first === undefined) &&
            (loadClaimantByIdInfo?.adjuster?.people?.last === '' || loadClaimantByIdInfo?.adjuster?.people?.last === undefined)))) {
        alert('You must provide an Adjuster Info in order to save.');
        return;
      }

      setIsFromService(!isFromService);
    }
    else if (reqFields.includes('You must have at least one SERVICE')) {
      alert('You must have at least one SERVICE')
    }
    else if (reqFields.includes('At least one TRANSPORTATION LEG is required.')) {
      alert('At least one TRANSPORTATION LEG is required.')
    }

    else if (reqFields.includes('At least one SPECIAL TRANSPORTATION LEG is required.')) {
      alert('At least one SPECIAL TRANSPORTATION LEG is required.')
    }

    // else if (reqFields.includes('Leg status is required to add/change a leg.')) {
    //   alert('Leg status is required to add/change a leg.')
    // }


    else alert('Please Fill in all the required Fields in order to Save the referral')
  };
  const onSaveFromExternal = () => {
    // setIsFromService(!isFromService);
    onSaveHandler();
  };

  const dosFormat = data => {
    if (data) {
      let dos = data && data.split('/');
      dos[0] = dos[0].length === 1 ? '0' + dos[0] : dos[0];
      dos[1] = dos[1]?.length === 1 ? '0' + dos[1] : dos[1];
      return dos[2] + '-' + dos[0] + '-' + dos[1];
    }
    return;
    // 0000-00-00 00:00:00
  };

  const accmAttr = j => {
    j.attributes = [
      {
        attributeId: j.quote ? 1480 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.prospective ? 1481 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.retrospective ? 1482 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.recurring ? 1483 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.converted ? 1484 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.vic ? 1485 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.specialneeds ? 1486 : 0,
        id: 0,
        fields: {},
      },
      {
        attributeId: j.nonworkcomp ? 1487 : 0,
        id: 0,
        fields: {},
      },
    ];
    delete j.quote;
    delete j.prospective;
    delete j.retrospective;
    delete j.converted;
    delete j.vic;
    delete j.specialneeds;
    delete j.nonworkcomp;
    delete j.product;
    delete j.module;
    delete j.serviceId;
    delete j.locationSame;
    delete j.daysDiff;
  };
  //completed

  //save -ui-update- success- data save- exit-reopen -updated data(ui)
  //save - ui update-error-data not saved-exit-reopen-not updated data(ui)
  const physicianPayload = workListData => {
    const physicianData = workListData.physician;
    let payload = {};
    if (workListData && physicianData) {
      payload = {
        people: physicianData.people,
        address: physicianData.address,
        medicalProvider: physicianData.medicalProvider,
        active: physicianData.active,
        licenseNumber: physicianData.licenseNumber,
        npi: physicianData.npi,
        id: physicianData.id,
      };
    }
    return payload;
  };
  //completed
  const payorPayload = workListData => {
    const payorData = workListData.payor;
    let payload = {};
    if (workListData && payorData) {
      payload = {
        addressId: payorData.addressId,
        address: payorData.address,
        remitAddress: payorData.remitAddress,
        name: payorData.name,
        ucFlag: payorData.ucFlag,
        active: payorData.active,
        payor: payorData.payor,
        client: payorData.client,
        phone: payorData.phone,
        level: payorData.level,
        primaryContact: {
          peopleId: payorData.primaryContact.peopleId,
          people: {
            first: payorData.primaryContact.people.first,
            last: payorData.primaryContact.people.last,
            id: payorData.primaryContact.people.id,
          },
          contactTypeId: payorData.primaryContact.contactTypeId,
          active: payorData.primaryContact.active,
          primary: payorData.primaryContact.primary,
          referralReceived: payorData.primaryContact.referralReceived,
          qaAssignedConfirmed: payorData.primaryContact.qaAssignedConfirmed,
          psAssignedConfirmed: payorData.primaryContact.psAssignedConfirmed,
          vosAssignedConfirmed: payorData.primaryContact.vosAssignedConfirmed,
          orderCancelledBilled: payorData.primaryContact.orderCancelledBilled,
          id: payorData.primaryContact.id,
        },
        primaryContactId: payorData.primaryContactId,
        contactList: payorData.contactList || [],
        serviceList: payorData.serviceList,
        dmeUpdates: payorData.dmeUpdates,
        contactDBF: payorData.contactDBF,
        confirmWClaimant: payorData.confirmWClaimant,
        contracted: payorData.contracted,
        gpCode: payorData.gpCode,
        hasChildren: payorData.hasChildren,
        feeCount: payorData.feeCount,
        sedgwickClientId: payorData.sedgwickClientId || 0,
        id: payorData.id,
      };
    }
    return payload;
  };
  //accidentState, alert,languageId
  const claimPayload = workListData => {
    const claimData = workListData.claim;
    let payload = {};
    if (workListData && claimData) {
      payload = {
        claimantId: claimData.claimantId,
        claimant: {
          peopleId: claimData.claimant.peopleId,
          people: {
            first: claimData.claimant.people.first,
            last: claimData.claimant.people.last,
            dob: claimData.claimant.people.dob,
            phone: claimData.claimant.people.phone,
            cellPhone: claimData.claimant.people.cellPhone,
            id: claimData.claimant.people.id,
            ssn: claimData.claimant.people.ssn,
            genderId: claimData.claimant.people.genderId,
            gender: claimData.claimant.people.genderId,
          },
          addressId: claimData.claimant.addressId,
          address: claimData.claimant.address,
          languageId: claimData.claimant.languageId, //
          gender: claimData.claimant.gender,
          active: claimData.claimant.active,
          okToContact: claimData.claimant.okToContact,
          heightFeet: claimData.claimant.heightFeet,
          heightInch: claimData.claimant.heightInch,
          weight: claimData.claimant.weight,
          okToText: claimData.claimant.okToText,
          claimantLocations: claimData.claimant.claimantLocations,
          alert:
            claimData.claimant.alert,
          id: claimData.claimant.id,
        },
        employer: claimData.employer,
        attorney: claimData.attorney,
        jurisState: claimData.jurisState,
        accidentState: claimData.accidentState, //
        disabled: claimData.disabled,
        doi: claimData.doi,
        claimNo: claimData.claimNo,
        contactComplete: claimData.contactComplete,
        callDayBefore: claimData.callDayBefore,
        callDayAfter: claimData.callDayAfter,
        openAuth: claimData.openAuth,
        catastrophic: claimData.catastrophic,
        claimAuths: claimData.catastrophic,
        addressList: claimData.addressList,
        injuryList: claimData.injuryList,
        id: claimData.id,
      };
    }
    return payload;
  };

  const referrerPaylaod = workListData => {
    const referrerData = workListData.referrer;
    let payload = {};
    if (workListData && referrerData) {
      payload = {
        peopleId: referrerData.peopleId,
        people: {
          first: referrerData.people.first,
          last: referrerData.people.last,
          email1: referrerData.people.email1,
          id: referrerData.people.id,
          fax: referrerData.people.fax,
          phone: referrerData.people.phone,

        },
        addressId: referrerData.addressId,
        address: {
          zipCodeId: referrerData.address.zipCodeId,
          zipCode: {
            city: referrerData.address.zipCode.city,
            state: referrerData.address.zipCode.state,
            zip: referrerData.address.zipCode.zip,
            county: referrerData.address.zipCode.county,
            longitude: referrerData.address.zipCode.longitude,
            latitude: referrerData.address.zipCode.latitude,
            timeZone: referrerData.address.zipCode.timeZone,
            dts: referrerData.address.zipCode.dts,
            id: referrerData.address.zipCode.id,
          },
          street1: referrerData.address.street1,
          active: referrerData.address.active,
          permAddress: referrerData.address.permAddress,
          id: referrerData.address.id,
        },
        clientId: referrerData.clientId,
        client: {
          addressId: referrerData.client.addressId,
          address: {
            zipCodeId: referrerData.client.address.zipCodeId,
            zipCode: {
              city: referrerData.client.address.zipCode.city,
              state: referrerData.client.address.zipCode.state,
              zip: referrerData.client.address.zipCode.zip,
              county: referrerData.client.address.zipCode.county,
              longitude: referrerData.client.address.zipCode.longitude,
              latitude: referrerData.client.address.zipCode.latitude,
              timeZone: referrerData.client.address.zipCode.timeZone,
              dts: referrerData.client.address.zipCode.dts,
              id: referrerData.client.address.zipCode.id,
            },
            street1: referrerData.client.address.street1,
            active: referrerData.client.address.active,
            permAddress: referrerData.client.address.permAddress,
            id: referrerData.client.address.id,
          },
          remitAddress: {
            zipCode: {
              longitude: referrerData.client.remitAddress.zipCode.longitude,
              latitude: referrerData.client.remitAddress.zipCode.latitude,
              dts: referrerData.client.remitAddress.zipCode.dts,
            },
            active: referrerData.client.remitAddress.active,
            permAddress: referrerData.client.remitAddress.permAddress,
          },
          name: referrerData.client.name,
          ucFlag: referrerData.client.ucFlag,
          active: referrerData.client.active,
          payor: referrerData.client.payor,
          client: referrerData.client.client,
          phone: referrerData.client.phone,
          level: referrerData.client.level,
          primaryContact: referrerData.client.primaryContact,
          primaryContactId: referrerData.client.primaryContactId,
          contactList: referrerData.client.contactList,
          serviceList: referrerData.client.serviceList,
          dmeUpdates: referrerData.client.dmeUpdates,
          contactDBF: referrerData.client.contactDBF,
          confirmWClaimant: referrerData.client.confirmWClaimant,
          contracted: referrerData.client.contracted,
          gpCode: referrerData.client.gpCode,
          hasChildren: referrerData.client.hasChildren,
          feeCount: referrerData.client.feeCount,
          sedgwickClientId: referrerData.client.sedgwickClientId,
          id: referrerData.client.id,
        },
        active: referrerData.active,
        insurer: referrerData.insurer,
        id: referrerData.id,
      };
    }
    return payload;
  };

  const adjusterPayload = workListData => {
    const adjusterData = workListData.adjuster;
    let payload = {};
    if (workListData && adjusterData) {
      payload = {
        peopleId: adjusterData.peopleId,
        people: {
          first: adjusterData.people.first,
          last: adjusterData.people.last,
          phone: adjusterData.people.phone,
          phoneExt: adjusterData.people.phoneExt,
          fax: adjusterData.people.fax,
          email1: adjusterData.people.email1,
          id: adjusterData.people.id,
        },
        addressId: adjusterData.addressId,
        address: {
          zipCodeId: adjusterData.address.zipCodeId,
          zipCode: {
            city: adjusterData.address.zipCode.city,
            state: adjusterData.address.zipCode.state,
            zip: adjusterData.address.zipCode.zip,
            county: adjusterData.address.zipCode.county,
            longitude: adjusterData.address.zipCode.longitude,
            latitude: adjusterData.address.zipCode.latitude,
            timeZone: adjusterData.address.zipCode.timeZone,
            dts: adjusterData.address.zipCode.dts,
            id: adjusterData.address.zipCode.id,
          },
          street1: adjusterData.address.street1,
          active: adjusterData.address.active,
          permAddress: adjusterData.address.permAddress,
          id: adjusterData.address.id,
        },
        active: adjusterData.active,
        id: adjusterData.id,
      };
    }
    return payload;
  };

  const caseManagerPayload = workListData => {
    const caseManagerData = workListData.caseManager;
    let payload = {};
    if (workListData && caseManagerData) {
      payload = {
        peopleId: caseManagerData.peopleId,
        people: {
          first: caseManagerData.people.first,
          last: caseManagerData.people.last,
          phone: caseManagerData.people.phone,
          email1: caseManagerData.people.email1,
          fax: caseManagerData.people.fax,
          id: caseManagerData.people.id,
        },
        addressId: caseManagerData.addressId,
        address: {
          zipCodeId: caseManagerData.address.zipCodeId,
          zipCode: {
            city: caseManagerData.address.zipCode.city,
            state: caseManagerData.address.zipCode.state,
            zip: caseManagerData.address.zipCode.zip,
            county: caseManagerData.address.zipCode.county,
            longitude: caseManagerData.address.zipCode.longitude,
            latitude: caseManagerData.address.zipCode.latitude,
            timeZone: caseManagerData.address.zipCode.timeZone,
            dts: caseManagerData.address.zipCode.dts,
            id: caseManagerData.address.zipCode.id,
          },
          street1: caseManagerData.address.street1,
          active: caseManagerData.address.active,
          permAddress: caseManagerData.address.permAddress,
          id: caseManagerData.address.id,
        },
        active: caseManagerData.active,
        id: caseManagerData.id,
      };
    }
    return payload;
  };

  const hcpcListFormat = i => {
    let list = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        list = audInfo[mod];
      }
    });
    let listHcpc = [];
    if (list === undefined){
      list = [];
    }
    list.length > 0 && list.forEach(j => {
      listHcpc.push({
        rental: {
          custBuyOut: j.custBuyOut,
          vendBuyOut: j.vendBuyOut,
          rentalExt: j.rentalExt,
          startExtDate: dateFormat(j.startExtDate),
          endExtDate: dateFormat(j.endExtDate),
          rentalUnit: j.rentalUnit,
        },
        modifier1: j.modifier1,
        modifier2: j.modifier2,
        serviceCode: j.serviceCode,
        vendDiscountedFrom: j.vendDiscountedFrom,
        vendPercentDiscount: j.vendPercentDiscount,
        custDiscountedFrom: j.custDiscountedFrom,
        custPercentDiscount: j.custPercentDiscount,
        quantity: j.quantity,
        ucSchedule: j.ucSchedule,
        feeSchedule: j.feeSchedule,
        medicareAllowable: j.medicareAllowable,
        refCodeDesc: j.refCodeDesc,
        serviceDescription: j.serviceDescription,
        altDescription: j.altDescription,
        custCost: j.custCost,
        vendCost: j.vendCost,
        owcpRate: j.owcpRate,
        msrp: j.msrp,
        startExtDate: dateFormat(j.startExtDate),
        endExtDate: dateFormat(j.endExtDate),
        id: (fromWorklist || fromAdvRef || fromQuickSearch ) && i.copiedService === undefined ? j.id : 0,
        detailProviderId: j.detailProviderId || 0,
      });
    });
    return listHcpc;
  };

  const costSavingsListFormat = i => {
    let costList = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        costList = audInfo[mod];
      }
    });
    let listCostSavings = [];
    costList && costList.length && costList.length > 0 && costList.forEach(j => {
      if (i.copiedService === true) {
        listCostSavings.push({
          ServiceId: 0,
          DetailId: 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: 0,
          },
        });
      } else {
        listCostSavings.push({
          id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          ServiceId: i?.serviceTaskId || j?.serviceId || 0,
          DetailId: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.id : 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          },
        });
      }
    });
    return listCostSavings;
  };

  const hcpcListDentFormat = i => {
    let list = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        list = audInfo[mod];
      }
    });
    let listHcpc = [];
    list.length > 0 && list.forEach(j => {
      listHcpc.push({
        rental: {
          rentalExt: j.rentalExt,
        },
        modifier1: j.modifier1,
        modifier2: j.modifier2,
        serviceCode: j.serviceCode,
        vendDiscountedFrom: j.vendDiscountedFrom,
        tooth: j.tooth,
        vendorQuantity: j.vendorQuantity,
        custDiscountedFrom: j.custDiscountedFrom,
        custPercentDiscount: j.custPercentDiscount,
        vendPercentDiscount: j.vendPercentDiscount,
        quantity: j.quantity,
        ucSchedule: j.ucSchedule,
        feeSchedule: j.feeSchedule,
        medicareAllowable: j.medicareAllowable,
        refCodeDesc: j.refCodeDesc,
        serviceDescription: j.serviceDescription,
        altDescription: j.altDescription,
        custCost: j.custCost,
        vendCost: j.vendCost,
        owcpRate: j.owcpRate,
        id: (fromWorklist || fromAdvRef || fromQuickSearch ) && i.copiedService === undefined ? j.id : 0,
        detailProviderId: j.detailProviderId || 0,
      });
    });
    return listHcpc;
  };

  const costSavingsDentListFormat = i => {
    let costList = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        costList = audInfo[mod];
      }
    });
    let listCostSavings = [];
    costList.length > 0 && costList.forEach(j => {
      if (i.copiedService === true) {
        listCostSavings.push({
          ServiceId: 0,
          DetailId: 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: 0,
          },
        });
      } else {
        listCostSavings.push({
          id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          ServiceId: i?.serviceTaskId || j?.serviceId || 0,
          DetailId: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.id : 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          },
        });
      }
    });
    return listCostSavings;
  };

  const dateFormat = data => {
    if (data) {
      let dos = data && data.split('/');
      if(dos.length > 1) {
        return dos[2] + '-' + dos[0] + '-' + dos[1];
      } else {
        return data;
      }
    }
    return;
  };

  const hcpcListHHCFormat = i => {
    let list = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        list = audInfo[mod];
      }
    });
    let listHcpc = [];
    list.length > 0 && list.forEach(j => {
      listHcpc.push({
        rental: {
          rentalExt: j.rentalExt,
        },
        modifier1: j.modifier1,
        modifier2: j.modifier2,
        serviceCode: j.serviceCode,
        vendDiscountedFrom: j.vendDiscountedFrom,
        vendorQuantity: j.vendorQuantity,
        custPercentDiscount: j.custPercentDiscount,
        vendPercentDiscount: j.vendPercentDiscount,
        custDiscountedFrom: j.custDiscountedFrom,
        quantity: j.quantity,
        ucSchedule: j.ucSchedule,
        feeSchedule: j.feeSchedule,
        medicareAllowable: j.medicareAllowable,
        refCodeDesc: j.refCodeDesc,
        serviceDescription: j.serviceDescription,
        altDescription: j.altDescription,
        custCost: j.custCost,
        vendCost: j.vendCost,
        owcpRate: j.owcpRate,
        vendorFlatRate: j.vendorFlatRate,
        clientFlatRate: j.clientFlatRate,
        id: (fromWorklist || fromAdvRef || fromQuickSearch ) && i.copiedService === undefined ? j.id : 0,
        detailProviderId: j.detailProviderId || 0,
      });
    });
    return listHcpc;
  };

  const costSavingsHHCListFormat = i => {
    let costList = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        costList = audInfo[mod];
      }
    });
    let listCostSavings = [];
    costList.length > 0 && costList.forEach(j => {
      if (i.copiedService === true) {
        listCostSavings.push({
          ServiceId: 0,
          DetailId: 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: 0,
          },
        });
      } else {
        listCostSavings.push({
          id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          ServiceId: i?.serviceTaskId || j?.serviceId || 0,
          DetailId: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.id : 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          },
        });
      }
    });
    return listCostSavings;
  };

  const hcpcListMODFormat = i => {
    let list = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        list = audInfo[mod];
      }
    });
    let listHcpc = [];
    list.length > 0 && list.forEach(j => {
      listHcpc.push({
        modificationId: (fromWorklist || fromAdvRef || fromQuickSearch ) && i.copiedService === undefined ? j.id : 0,
        description: j.description,
        msrpAmt: j.msrpAmt,
        vendorCost: j.vendorCost,
        custCost: j.custCost,
        itemName: j.itemName,
        primaryMod: j.primaryMod,
        quantity: j.quantity,
        rental: {
          custBuyOut: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.custBuyOut : j.custBuyOut,
          vendBuyOut: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.vendBuyOut : j.vendBuyOut,
          rentalExt: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.rentalExt : j.rentalExt,
          startExtDate: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.startExtDate : j.startExtDate,
          endExtDate: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.endExtDate : j.endExtDate,
          rentalUnit: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.rental?.rentalUnit : j.rentalUnit,
        },
        id: (fromWorklist || fromAdvRef || fromQuickSearch ) && i.copiedService === undefined ? j.id : 0,
      });
    });
    return listHcpc;
  };

  const costSavingsMODListFormat = i => {
    let costList = [];
    Object.keys(audInfo).map(mod => {
      if (mod === i.module + i.serviceId) {
        costList = audInfo[mod];
      }
    });
    let listCostSavings = [];
    costList.length > 0 && costList.forEach(j => {
      if (i.copiedService === true) {
        listCostSavings.push({
          ServiceId: 0,
          DetailId: 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: 0,
          },
        });
      } else {
        listCostSavings.push({
          id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          ServiceId: i?.serviceTaskId || j?.serviceId || 0,
          DetailId: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.id : 0,
          PercentAdjustment: j.PercentAdjustment,
          BilledCharges: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.billCharges : j.BilledCharges,
          TotalAllowance: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.totAllow : j.TotalAllowance,
          fields: {
            id: (fromWorklist || fromAdvRef || fromQuickSearch ) ? j.fieldId : 0,
          },
        });
      }
    });
    return listCostSavings;
  };


  const onSaveComp = (item, instValue, combinedServicesCount) => {
    let response = {};
    if (fromWorklist || fromAdvRef || fromQuickSearch || isExternalReferral) {
      response.physician = externalData.isPhyEdited
        ? updatedPhysicianInfo
        : physicianPayload(workListData);
      response.employer = externalData.isEmployerEdited
        ? updatedEmployerInfo && updatedEmployerInfo.employer
        : workListData && workListData.employer; //
      response.attorney = externalData.isEttorneyEdited
        ? updatedAttorneyInfo
        : updatedAttorneyInfo; //
      response.payor = externalData.isPayorEdited
        ? updatedPayorInfo
        : payorPayload(workListData);
      response.payorId = externalData.isPayorEdited
        ? updatedPayorInfo && updatedPayorInfo.id
        : workListData.payor && workListData.payor.id;
      response.client = externalData.isPayorEdited
        ? updatedPayorInfo
        : payorPayload(workListData);
      response.clientId = externalData.isPayorEdited
        ? updatedPayorInfo && updatedPayorInfo.id
        : workListData.payor && workListData.payor.id;
      response.caseManager = externalData.isCaseManagerEdited
        ? updatedCaseManagerInfo && updatedCaseManagerInfo.caseManager
        : caseManagerPayload(workListData);
      response.caseManagerId = workListData && workListData.caseManagerId;
      response.adjuster = externalData.isAdjusterEdited
        ? updatedAdjusterInfo && updatedAdjusterInfo.adjuster
        : adjusterPayload(workListData);
      response.adjusterId = externalData.isAdjusterEdited
        ? updatedAdjusterInfo &&
        updatedAdjusterInfo.adjuster &&
        updatedAdjusterInfo.adjuster.id
        : workListData && workListData.adjuster && workListData.adjuster.id;
      response.claim = externalData.isPatientEdited
        ? updatedClaimInfo
        : claimPayload(workListData);
      let resExistClaim = response.claim || {};
      resExistClaim.attorney = externalData.isEttorneyEdited
        ? updatedAttorneyInfo
        : workListData && workListData.claim && workListData.claim.attorney;
      resExistClaim.employer = externalData.isEmployerEdited
        ? updatedEmployerInfo && updatedEmployerInfo.employer
        : workListData && workListData.claim && workListData.claim.employer;
      response.claimId = externalData.isPatientEdited
        ? updatedClaimInfo && updatedClaimInfo.id
        : workListData && workListData.claim && workListData.claim.id;
      response.referrer = externalData.isReferrerEdited
        ? updatedRefInfo && updatedRefInfo.referrer
        : referrerPaylaod(workListData);
      response.referrerId = externalData.isReferrerEdited
        ? updatedRefInfo &&
        updatedRefInfo.referrer &&
        updatedRefInfo.referrer.id
        : workListData && workListData.referrer && workListData.referrer.id;
      response.status = workListData.status || 0;
      response.instructions = instValue;
    } else {
      response.physician = (updatedPhysicianInfo) || (isNewReferral && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician);
      response.employer = (updatedEmployerInfo && updatedEmployerInfo.employer) || (isNewReferral && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer);
      response.attorney = (updatedAttorneyInfo) || (isNewReferral && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney);
      response.payor = (updatedPayorInfo) || (isNewReferral && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor);
      response.payorId = (updatedPayorInfo && updatedPayorInfo.id) || (isNewReferral && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.id);
      response.client = (updatedPayorInfo) || (isNewReferral && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.client);
      response.clientId = (updatedPayorInfo && updatedPayorInfo.id) || (isNewReferral && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.client?.id);
      response.caseManager =
        (updatedCaseManagerInfo && updatedCaseManagerInfo.caseManager) || (isNewReferral && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager);
      response.adjuster = (updatedAdjusterInfo && updatedAdjusterInfo.adjuster) || (isNewReferral && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster);
      response.adjusterId = (updatedAdjusterInfo &&
        updatedAdjusterInfo.adjuster &&
        updatedAdjusterInfo.adjuster.id) || (isNewReferral && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.id);
      response.claim = ((updatedClaimInfo && updatedClaimInfo.id !== 0) && (!isEmpty(updatedAttorneyInfo) || !isEmpty(updatedEmployerInfo))) ? loadClaimInfo : updatedClaimInfo;
      let resClaim = response.claim || {};
      resClaim.employer = (updatedEmployerInfo && updatedEmployerInfo.employer) || (isNewReferral && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer);
      resClaim.attorney = (updatedAttorneyInfo) || (isNewReferral && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney);
      response.claimId = ((updatedClaimInfo && updatedClaimInfo.id !== 0) && (!isEmpty(updatedAttorneyInfo) || !isEmpty(updatedEmployerInfo))) ? loadClaimInfo && loadClaimInfo.id : updatedClaimInfo && updatedClaimInfo.id;
      response.referrer = (updatedRefInfo && updatedRefInfo.referrer) || (isNewReferral && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer);
      response.referrerId =
        (updatedRefInfo && updatedRefInfo.referrer && updatedRefInfo.referrer.id) || (isNewReferral && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.id);
      response.status = 0;
      response.instructions = instValue;
    }
    let serviceClone = item.map(a => {
      return { ...a };
    });
    let audResponse = [];
    let accomResponse = [];
    let dentResponse = [];
    let dgnResponse = [];
    let hhcResponse = [];
    let intResponse = [];
    let modResponse = [];
    let mseResponse = [];
    serviceClone.map(mod => {
      if (mod.module === 'AUD') {
        audResponse.push(mod);
      } else if (mod.module === 'ACC') {
        accomResponse.push(mod);
      } else if (mod.module === 'DTL') {
        dentResponse.push(mod);
      } else if (mod.module === 'DGN') {
        dgnResponse.push(mod);
      } else if (mod.module === 'HHC') {
        hhcResponse.push(mod);
      } else if (mod.module === 'INT') {
        intResponse.push(mod);
      } else if (mod.module === 'MOD') {
        modResponse.push(mod);
      } else if (mod.module === 'MSE') {
        mseResponse.push(mod);
      }
    });

    audResponse.length > 0 && audResponse.forEach(i => {
      i.dos = dosFormat(i.dos);
      i.hcpcsList = hcpcListFormat(i);
      i.costSavings = costSavingsListFormat(i);
      i.copiedService === true ? delete i.id : delete i.copiedService;
      i.cancelStatus = i.reasonId;
      delete i.reasonId;
      accmAttr(i);
      delete i.serviceAddress;
      delete i.recurring;
      delete i.newServiceId;
      delete i.copiedService;
    });
    accomResponse.length > 0 && accomResponse.forEach(j => {
      accmAttr(j);
      j.dos = dosFormat(j.dos);
      j.checkInDate = j.checkInDate ? dosFormat(j.checkInDate) : "0000-00-00 00:00:00";
      j.checkOutDate = j.checkOutDate ? dosFormat(j.checkOutDate) : "0000-00-00 00:00:00";
      j.copiedService === true ? delete j.id : delete j.copiedService;
      j.cancelStatus = j.reasonId;
      delete j.reasonId;
      delete j.serviceAddress;
      delete j.recurring;
      delete j.newServiceId;
      delete j.copiedService;
    });
    dentResponse.length > 0 && dentResponse.forEach(k => {
      k.hcpcsList = hcpcListDentFormat(k);
      k.costSavings = costSavingsDentListFormat(k);
      accmAttr(k);
      k.dos = dosFormat(k.dos);
      k.copiedService === true ? delete k.id : delete k.copiedService;
      k.cancelStatus = k.reasonId;
      delete k.reasonId;
      delete k.recurring;
      delete k.serviceAddressId;
      delete k.locationPhone;
      delete k.newServiceId;
      delete k.copiedService;
      k.serviceAddress.phone =
        k.serviceAddress.phone &&
        k.serviceAddress.phone.replace(/[\s()-]/g, '');
    });
    dgnResponse.length > 0 && dgnResponse.forEach(m => {
      m.hcpcsList = hcpcListFormat(m);
      m.costSavings = costSavingsListFormat(m);
      accmAttr(m);
      m.readyForGp = m.readyForGp;
      m.dos = dosFormat(m.dos);
      m.copiedService === true ? delete m.id : delete m.copiedService;
      m.cancelStatus = m.reasonId;
      delete m.reasonId;
      delete m.recurring;
      delete m.locationPhone;
      delete m.serviceAddress;
      delete m.bodyPartDesc;
      delete m.bodyPart;
      delete m.newServiceId;
      delete m.copiedService;
    });
    hhcResponse.length > 0 && hhcResponse.forEach(l => {
      l.hcpcsList = hcpcListHHCFormat(l);
      l.costSavings = costSavingsHHCListFormat(l);
      // l.id = l.serviceId;
      l.serviceAddSameAsClmnt = l.locationSame === '1' ? true : false;
      accmAttr(l);
      l.dos = dosFormat(l.dos);
      l.isCopy = l.copiedService;
      l.copiedService === true ? delete l.id : delete l.copiedService;
      l.cancelStatus = l.reasonId;
      delete l.reasonId;
      delete l.locationPhone;
      delete l.serviceAddressId;
      delete l.recurring;
      delete l.newServiceId;
      delete l.copiedService;
      l.serviceAddress.phone =
        l.serviceAddress.phone &&
        l.serviceAddress.phone.replace(/[\s()-]/g, '');
      if (l.locationSame != undefined && l.locationSame !== 0) {
        if (l.serviceAddress.id === undefined) {
          delete l.serviceAddress;
        }
      }
    });
    intResponse.length > 0 && intResponse.forEach(l => {
      accmAttr(l);
      if(isNewReferral && l.translationCategoryId == '900'){
        response.noIntroEmail = true
      }
      l.dos = dosFormat(l.dos);
      l.arrivalDate = dosFormat(l.arrivalDate);
      if (l.referralId === '') {
        l.referralId = 0;
      }
      delete l.locationPhone;
      l.facility.phone =
        l.facility.phone && l.facility.phone.replace(/[\s()-]/g, '');
      l.arrivalDate =
        (l.dos ? l.dos : '0000-00-00') + ' ' + (l.arrivalTime ? l.arrivalTime : '00:00:00');
      l.timeZoneId = l.startTZ ? l.startTZ : l.timeZoneId;
      l.fromLanguageId = l.fromLanguageId ? l.fromLanguageId : '';
      l.languageId = l.languageId ? l.languageId : '670';
      l.copiedService === true ? delete l.id : delete l.copiedService;
      l.cancelStatus = l.reasonId;
      delete l.reasonId;
      delete l.serviceAddress;
      delete l.aptAMPM;
      delete l.aptHour;
      delete l.aptMinutes;
      delete l.arrivalHour;
      delete l.arrivalMin;
      delete l.arrivalTime;
      delete l.arriveAMPM;
      delete l.authTravel;
      delete l.durationHour;
      delete l.durationMinutes;
      delete l.endAMPM;
      delete l.endHour;
      delete l.endMinute;
      delete l.startAMPM;
      delete l.startHours;
      delete l.startMinutes;
      delete l.endTZ;
      delete l.startTZ;
      delete l.arriveTZ;
      delete l.newServiceId;
      delete l.copiedService;
    });
    modResponse.length > 0 && modResponse.forEach(n => {
      n.detailList = hcpcListMODFormat(n);
      n.costSavings = costSavingsMODListFormat(n);
      n.modifyOk = n.modYN === '1' ? true : false;
      n.ownOrRent = n.ownVRent === '0' ? 'Rent' : 'Own';
      accmAttr(n);
      n.dos = dosFormat(n.dos);
      n.copiedService === true ? delete n.id : delete n.copiedService;
      n.cancelStatus = n.reasonId;
      delete n.reasonId;
      delete n.serviceAddress;
      delete n.ownVRent;
      delete n.modYN;
      delete n.locSameDif;
      delete n.hoaYN;
      delete n.hitchYesNo;
      delete n.recurring;
      delete n.newServiceId;
      delete n.copiedService;
      n.landlordPhone =
        n.landlordPhone && n.landlordPhone.replace(/[\s()-]/g, '');
      n.landlordFax = n.landlordFax && n.landlordFax.replace(/[\s()-]/g, '');
      n.hoaPhone = n.hoaPhone && n.hoaPhone.replace(/[\s()-]/g, '');
      n.hoaFax = n.hoaFax && n.hoaFax.replace(/[\s()-]/g, '');
      if (n.val == 'Vehicle') {
        n.vhHitch = n.hitchYesNo === '1' ? true : false;
        n.landlordAddress = {
          "zipCodeId": 0,
          "zipCode": {
            "id": 0,
            "zip": ""
          },
          "street1": "",
          "id": 0
        };
        n.locationAddress = {
          "zipCodeId": 0,
          "zipCode": {
            "id": 0,
            "zip": ""
          },
          "street1": "",
          "id": 0
        }
        n.hoaAddress = {
          "zipCodeId": 0,
          "zipCode": {
            "id": 0,
            "zip": ""
          },
          "street1": "",
          "id": 0
        };
        delete n.ownVRent;
        delete n.ownOrRent;
        delete n.locSameDif;
        delete n.modlocClaimant;
        delete n.numStories;
        delete n.numBedrooms;
        delete n.numBaths;
        delete n.numPeople;
        delete n.lanlordName;
        delete n.hoaName;
        delete n.landlordEmail;
        delete n.landlordPhone;
        delete n.listelementHomestructId;
        delete n.listelementHometypeId;

      }
      else if (n.val == 'Home') {
        n.modlocClaimant = n.locSameDif === '1' ? true : false;
        n.hoaApproval = n.hoaYN === '1' ? true : false;
        delete n.vhYearbuilt;
        delete n.vhMake;
        delete n.vhModel;
        delete n.vhNnoofmiles;
        delete n.vhTypeId;
        delete n.vhNoofdoors;
        delete n.hitchYesNo;
        delete n.vhHitch;
      }
      // n.subService = n.val;
      // n.id = 0;
      delete n.val;
    });
    mseResponse.length > 0 && mseResponse.forEach(o => {
      o.detailList = hcpcListFormat(o);
      o.deliverAddSameAsClmnt = o.locationSame === '1' ? true : false;
      o.costSavings = costSavingsListFormat(o);
      accmAttr(o);
      o.copiedService === true ? delete o.id : delete o.copiedService;
      o.copiedService === true ? delete o.serviceTaskId : delete o.copiedService;
      o.dos = dosFormat(o.dos);
      o.cancelStatus = o.reasonId;
      delete o.reasonId;
      delete o.serviceAddress;
      delete o.recurring;
      delete o.newServiceId;
      delete o.copiedService;
    });
    let trnClone = item.map(a => {
      return { ...a, serviceId: a.serviceId };
    });
    let trnResponse = [];
    const getList = (serId) => {
      return trnDetailsList.filter(i => i.serviceId == serId)
    }
    trnClone.map(mod => {
      if (mod.module === 'TRN') {
        if ((isNewReferral || mod.serviceId < 100) && (trnDetailsList.length > 0)) {
          const newList = getList(mod.serviceId) || [];
          if (newList.length > 0 && mod.detailList.length === 1) {
            mod.detailList = [];
          }
          if (mod.referralId === '') {
            mod.referralId = 0;
          }
        }
        let obj = { ...mod, serIdd: mod.serviceId };
        // let objStr = JSON.stringify(obj)
        trnResponse.push(JSON.parse(JSON.stringify(obj)));
      }
    });

    trnResponse.length > 0 && trnResponse.forEach(p => {
      accmAttr(p);
      p.dos = dosFormat(p.dos);
      if (p.copiedService) {
        const newList = JSON.parse(JSON.stringify(getList(p.serIdd) || []));
        p.detailList = [...p.detailList, ...newList];
        p.detailList && p.detailList.forEach(d => {
          d.id = 0;
          d.transportationId = 0;
          if (!d.pickupAddress) {
            d.pickupAddress = { zipCode: {} };
          }
          d.pickupAddress.id = 0;
          if (!d.dropOffAddress) {
            d.dropOffAddress = { zipCode: {} };
          }
          d.dropOffAddress.id = 0;
        })
      } else {
        const newList = JSON.parse(JSON.stringify(getList(p.serIdd) || []));
        p.detailList = [...p.detailList, ...newList];
      }
      if (p.detailList.length > 0) {
        p.detailList && p.detailList.forEach(d => {
          delete d.dropOffAM;
          delete d.dropOffDate;
          delete d.dropOffHours;
          delete d.dropOffMin;
          delete d.pickupAM;
          delete d.pickupDate;
          delete d.pickupHours;
          delete d.pickupMin;
          delete d.deadMileage;
          delete d.serviceId;
          delete d.tempId;
        })
      }
      p.copiedService === true ? delete p.id : delete p.copiedService;
      p.cancelStatus = p.reasonId;
      delete p.reasonId;
      delete p.serviceAddress;
      delete p.durationMinutes;
      delete p.newServiceId;
      delete p.copiedService;
      delete p.serIdd;
    });
    let sptClone = item.map(a => {
      return { ...a, serviceId: a.serviceId };
    });
    let sptResponse = [];
    const getDetailList = (serId) => {
      return sptDetailsList.filter(i => i.serviceId == serId);
    }
    sptClone.map(mod => {
      if (mod.module === 'SPT') {
        sptResponse.push({ ...mod, serIdd: mod.serviceId });
      }
    });


    sptResponse.length > 0 && sptResponse.forEach(q => {
      accmAttr(q);
      q.dos = dosFormat(q.dos);
      const newList = JSON.parse(JSON.stringify(getDetailList(q.serIdd) || []));
      q.detailList = [...q.detailList, ...newList];
      if (q.detailList.length > 0) {
        q.detailList && q.detailList.forEach(d => {
          delete d.serviceId;
          delete d.tempId;
        })
      }
      q.addPassPhone = q.addPassPhone && q.addPassPhone.replace(/[\s()-]/g, '');
      q.copiedService === true ? delete q.id : delete q.copiedService;
      q.cancelStatus = q.reasonId;
      delete q.reasonId;
      delete q.serviceAddress;
      delete q.newServiceId;
      delete q.copiedService;
      delete q.serIdd;
    });
    response.audiologyList = audResponse;
    response.accommodationList = accomResponse;
    response.dentalList = dentResponse;
    response.diagnosticList = dgnResponse;
    response.hhcList = hhcResponse;
    response.modificationList = modResponse;
    response.mseList = mseResponse;
    response.translationList = intResponse;
    response.transportationList = trnResponse;
    response.specialTransportationList = sptResponse;
    response.notes = [];
    response.id = fromWorklist || fromAdvRef || fromQuickSearch ? data.referralId : 0;
    if (listToUpdate !== '' && response[listToUpdate] && response[listToUpdate].length > 0)
      response[listToUpdate][0].readyForGp = readyForBilling;

    if (isExternalReferral) {
      response.AuthCode = exteneralReferralData[0]?.UniquePortalAuthCode ? exteneralReferralData[0]?.UniquePortalAuthCode : '0';
      response.svc = exteneralReferralData[0]?.svc;
      if (exteneralReferralData[0]?.int_incoming_sedgwick_referral_id === undefined) {
        response.sedgwickReferralId = 0;
      } else {
        response.sedgwickReferralId = exteneralReferralData[0]?.int_incoming_sedgwick_referral_id;
      }
      if (exteneralReferralData[0]?.svc == 'ACC') {
        response.tblName = 'accommodation';
        response.idCol = 'PK_accommodation_id';
        response.stagingTable = 'YorkAccommodationStaging';
      } else if (exteneralReferralData[0]?.svc == 'AUD') {
        response.tblName = 'audiology';
        response.idCol = 'PK_audiology_id';
        response.stagingTable = 'YorkAudiologyStaging';
      } else if (exteneralReferralData[0]?.svc == 'DTL') {
        response.tblName = 'dental';
        response.idCol = 'PK_dental_id';
        response.stagingTable = 'YorkDentalStaging';
      } else if (exteneralReferralData[0]?.svc == 'HHC') {
        response.tblName = 'hhc';
        response.idCol = 'PK_hhc_id';
        response.stagingTable = 'YorkHomeHealthStaging';
      } else if (exteneralReferralData[0]?.svc == 'INT') {
        response.tblName = 'interpretation';
        response.idCol = 'PK_interpretation_id';
        response.stagingTable = 'YorkInterpretationStaging';
      } else if (exteneralReferralData[0]?.svc == 'MOD') {
        response.tblName = 'modifications';
        response.idCol = 'PK_modifications_id';
        response.stagingTable = 'YorkModStaging';
      } else if (exteneralReferralData[0]?.svc == 'MSE') {
        response.tblName = 'mse';
        response.idCol = 'PK_mse_id';
        response.stagingTable = 'YorkDMEStaging';
      } else if (exteneralReferralData[0]?.svc == 'TRN') {
        if (sptResponse.length > 0) {
          response.svc = 'SPT';
          response.table = 'SPT';
          response.tblName = 'special_transp';
          response.idCol = 'PK_special_transp_id';
          response.stagingTable = 'YorkTransportationStaging';
        } else {
          response.tblName = 'transportation';
          response.idCol = 'PK_transportation_id';
          response.stagingTable = 'YorkTransportationStaging';
        }
      }
    } else {
      response.AuthCode = '';
      response.svc = ''
      response.table = '';
      response.tblName = '';
      response.idCol = '';
      response.stagingTable = 'YorkTransportationStaging';
    }
    const referralId = fromWorklist || fromAdvRef || fromQuickSearch ? data.referralId : 0; //this should be the referralId from LoadReferral if it is from worklist
    const referral_id = referralId;
    const params = {
      response,
      currentUserId,
      referralId,
      referral_id,
    };

    if (isFromService && (response.audiologyList?.length === 0 &&
      response.accommodationList?.length === 0 &&
      response.dentalList?.length === 0 &&
      response.diagnosticList?.length === 0 &&
      response.hhcList?.length === 0 &&
      response.modificationList?.length === 0 &&
      response.mseList?.length === 0 &&
      response.translationList?.length === 0 &&
      response.transportationList?.length === 0 &&
      response.specialTransportationList?.length === 0
    )) {
      alert('You must provide an service Info in order to save.');
    } else if (isFromService) {
      if (reqFields.length == 0) {
        saveReferral(params);
        // resetTRNInfoTable();
        // resetSPTInfoTable();
      } else {
        alert('Please Fill in all the required Fields in order to Save the referral');
      }
      // if ((fromWorklist || fromAdvRef || fromQuickSearch) && item.length > combinedServicesCount) {
      //   setRefSaved(true);
      // }
    }
    setIsFromService(false);
  }

  const vendorAssignmentGrid = () => {
    if (enableVendorGrid) {
      return (
        <AppTable modalHeight={100}

          columns={[
            {
              header: 'Claimant Name',
              field: 'claimantName',
              percent: '6',
            },
            {
              header: 'Conf #',
              field: 'confNo',
              percent: '13',

            },
            {
              header: 'Status',
              percent: '3',
            },

            {
              header: 'Sub Product',
              field: 'product',
              percent: '4.5',
            },
            {
              header: 'Date of Service',
              field: 'serviceDate',
              percent: '4.5',
            },

          ]}
          data={assignedGrid}
          rowDoubleClickHandler={onRowDoubleClick}

        />
      );
    }
    return '';
  };

  useEffect(() => {
    console.log('Fast360.js: onExternalReferral - referral id in data is setting to 0 ', data?.referralId);
  }, [data?.referralId]);

  const onExternalReferral = () => {
    console.log('Fast360.js: onExternalReferral - isExternalReferral is ', isExternalReferral);
    console.log('Fast360.js: onExternalReferral - referral id in data is ', data?.referralId);
    setData({ referralId: 0 });
    //setIsExternalReferral(!isExternalReferral);
    setIsExternalReferral(true);
    onNewReferral();
    setIsNew(false);
  };

  const clearAllSavedData = () => {
    resetReferrerInfo();
    resetClientInfo();
    resetAdjusterInfo();
    resetSaveClaim();
    resetLoadClaimantById();
    resetCaseManagerInfo();
    resetEmployerInfo();
    resetPhysicianInfo();
    resetAttorneyInfo();
    resetAudInfoTable();
  };
  const onExternalReferralExit = (isSedgewickReferral) => {
    //setIsExternalReferral(!isExternalReferral);
    setIsExternalReferral(false);
    onNewReferral();
    setIsNewReferral(false);
    updatePostExternal([]);
    setIsExternal(false);
    resetClientInfo();
    setPatientEdited(false);
    setReferrerEdited(false);
    setAdjusterEdited(false);
    setCaseManagerEdited(false);
    setPayorEdited(false);
    setPhyEdited(false);
    setEttorneyEdited(false);
    setEmployerEdited(false);
    setCurrentTask(0);
    selectedExternalData({});
    setCurrentWorkList({});
    requiredServiceData({});
    setIsNew(false);
    setRefSaved(false);
    updateSelectedAuthRecord({ index: -1, state: false });
    setReqFields([]);
    setTrnDetails([]);
    setSptDetails([]);
    trnSaveData(true);
    sptsaveData(true);
    followupChangeEvent(false);
    followupDateEvent(false);
    setAdvValues('');
    resetTRNInfoTable();
  };

  const onWorklistExit = () => {
    setFromWorklist(false);
    updateLoadFromWorkList([]);
    setPatientEdited(false);
    setReferrerEdited(false);
    setAdjusterEdited(false);
    setCaseManagerEdited(false);
    setPayorEdited(false);
    setPhyEdited(false);
    setEttorneyEdited(false);
    setEmployerEdited(false);
    setFromAdvRef(false);
    setFromQuickSearch(false);
    updateGetFollowupData({});
    clearAllSavedData();
    refreshCall();
    setCurrentWorkList({});
    requiredServiceData({});
    clearAuthService();
    updateSelectedAuthRecord({ index: -1, state: false });
    updateClickRecord([]);
    setReqFields([]);
    setTrnDetails([]);
    setSptDetails([]);
    trnSaveData(true);
    sptsaveData(true);
    resetTRNInfoTable();
    resetSPTInfoTable();
    followupChangeEvent(false);
    followupDateEvent(false);
    setAdvValues('');


  };

  const onAdvSearch = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: currentUserId,
      breadcrumbkey: 'OPENADVSEARCH',
      refTableId: 0,
      refId: 0,
      refTable: '',
    };
    logBreadCrumbs(breadCrumb)
    setIsAdvSearch(true);


  };

  const onResetState = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: currentUserId,
      breadcrumbkey: 'CLOSEADVSEARCH',
      refTableId: 0,
      refId: 0,
      refTable: '',
    };
    logBreadCrumbs(breadCrumb)
    setIsNewReferral(false);
    setIsAdvSearch(false);
    setFromQuickSearch(false);
    setFromWorklist(false);
    setAdvValues('');
    resetAdvancedSearchReport();
    setUseStoredData(true);

  };

  const onCloseGpIntegration = () => {
    setGpIntegration('');
  };

  const onRowDoubleClick = (item, index) => {
    const { FK_referral_id } = item;
    const { int_reference_table_id } = item;
    const { chr_claim_no } = item;
    const { chr_reference_table } = item;
    const { currentTaskID } = item;
    const { ServiceType } = item;

    openReferral(FK_referral_id, chr_reference_table, int_reference_table_id, currentTaskID, chr_claim_no, ServiceType, true);
  }

  const openReferral = (
    referralId,
    tableName,
    serviceId,
    currentTaskId,
    claimNo,
    ServiceType,
    enableGrid
  ) => {
    if (data.referralId !== referralId) {
      resetVendorLookupData();
    }
    ///PutFilterHere

    //   initialWorkList
    setEnableVendorGrid(enableGrid);
    setData({
      referralId,
      tableName,
      serviceId,
      currentTaskId,
      claimNo,
      ServiceType,
      currentUserId,
    });

    const objMap = {
      'modification': 'modifications',
      'special transport': 'special_transp',
      "diagnostic": 'diagnostics'
    }

    if (!ServiceType) {
      ServiceType = tableName.toUpperCase() === "ACC" ? 'accommodation' :
        tableName.toUpperCase() === "AUD" ? 'audiology' :
          tableName.toUpperCase() === "DTL" ? 'dental' :
            tableName.toUpperCase() === "DGN" ? 'diagnostics' :
              tableName.toUpperCase() === "MOD" ? 'modifications' :
                tableName.toUpperCase() === "INT" ? 'interpretation' :
                  tableName.toUpperCase() === "TRN" ? 'transportation' :
                    tableName.toUpperCase() === "SPT" ? 'special_transp' :
                      tableName.toUpperCase() === "MSE" ? 'mse' :
                        tableName.toUpperCase() === "HHC" ? 'hhc' : '';
    }

    if (ServiceType.toUpperCase() === "ACC"){
      ServiceType = 'accommodation';
    }else if(ServiceType.toUpperCase() === "AUD"){
      ServiceType = 'audiology';
    }else if(ServiceType.toUpperCase() === "DTL"){
      ServiceType = 'dental';
    }else if(ServiceType.toUpperCase() === "DGN"){
      ServiceType = 'diagnostics';
    }else if(ServiceType.toUpperCase() === "MOD"){
      ServiceType = 'modifications';
    }else if(ServiceType.toUpperCase() === "INT"){
      ServiceType = 'interpretation';
    }else if(ServiceType.toUpperCase() === "TRN"){
      ServiceType = 'transportation';
    }else if(ServiceType.toUpperCase() === "SPT"){
      ServiceType = 'special_transp';
    }else if(ServiceType.toUpperCase() === "MSE"){
      ServiceType = 'mse';
    }else if(ServiceType.toUpperCase() === "HHC"){
      ServiceType = 'hhc';
    }

    let postPayload = {
      zebraUserId: currentUserId,
      referral_id: referralId,
      mainMod: tableName,
    };

    let taskPayload = {
      loadCurrentTaskId :true,
      loadGroupTaskProgression: true,
      loadExistingTasks : false,
      loadReferralAlerts : true,
      loadFollowUpdInfo : true,
      zebraUserId: currentUserId,
      referralId: referralId,
      tableAbbrv: tableName,
      serviceId: serviceId,
      currentTask: currentUserId,
      referenceTable: tableName,
      claimNo: claimNo,
      table: (ServiceType == 'Diagnostic' || ServiceType == 'Modification' || ServiceType == 'Special Transport') ? objMap[ServiceType.toLowerCase()] : ServiceType.toLowerCase(),
      userid: currentUserId,
      breadcrumbkey: '',
      breadcrumbrefTableId: 0,
      breadcrumbrefId: 0,
      breadcrumbrefTable: '',
    };
    callDynamicLoad(taskPayload);

    /*let getTaskIdPayload = {
      functionName: 'getCurrentTaskId',
      zebraUserId: currentUserId,
      tableAbbrv: tableName,
      serviceId: serviceId,
    };

    getCurrentTaskId(getTaskIdPayload);

    let groupProgressPayload = {
      functionName: 'groupTasksProgression',
      zebraUserId: currentUserId,
      currentTask: currentTaskId,
      referenceTable: tableName,
      claimNo: claimNo,
      serviceId: serviceId,
      fast360: 'Fast360Main'
    };
    

    let alertPayload = {
      functionName: 'loadReferralAlerts',
      zebraUserId: currentUserId,
      referralId: referralId,
      tableAbbrv: tableName,
      serviceId: serviceId,
    };
    
    let followUpPayLoad = {
      functionName: 'loadFollowupInfo',
      zebraUserId: currentUserId,
      serviceId: serviceId,
      table: (ServiceType == 'Diagnostic' || ServiceType == 'Modification' || ServiceType == 'Special Transport') ? objMap[ServiceType.toLowerCase()] : ServiceType.toLowerCase(),
      tableName: tableName,
    };*/

    //this.props.selectFromWorkListService(dataObject);
    //LoadObject here for retrieve
    loadFromWorkList(postPayload);
    //loadReferralAlerts(alertPayload);
    //getFollowupData(followUpPayLoad);
    //groupTasksProgression(groupProgressPayload);
    if (enableGrid && !fromWorklist) {
      setFromWorklist(!fromWorklist);
    } else if (!enableGrid) {
      setFromWorklist(!fromWorklist);
    }
    //setCurrentTask(currentTaskID);
    //this.dblClickWorklist(true);
    //this.setWorklistTask(currentTaskID);
    /*
    if (claimNo != null && currentTaskID != null) {
      
    } else {
      // We don't have currentTaskID or claim number so go get and then call
      let groupProgressPayload = {
        "functionName": "groupTasksProgression2",
        "zebraUserId": currentUserId,
        "referenceTable": tableName,
        "referralId": referralId,
        "serviceId": serviceId,
      }
      groupTasksProgression2(groupProgressPayload);
      setWorklistTask(currentTaskID); // ?? need to trigger this some how
    }
    */
  };
  const loadReferralWorkList = (subService) => {
    let postPayload = {
      zebraUserId: currentUserId,
      referral_id: data.referralId,
      mainMod: data.tableName,
    };
    loadFromWorkList(postPayload);
  }

  const loadReferralandVender = (serviceData, vendorId, onClose) => {
    let postPayload = {
      zebraUserId: currentUserId,
      functionName: "loadVendor",
      vendor_id: vendorId
    };
    loadUpdatedVendor(postPayload, serviceData, workListData, onClose);
  }

  const openOverride = () => {
    setIsOpenManagerOverrideModal(!isOpenManagerOverrideModal)
  }

  const saveFollowupCall = () => {
    if (reqFields.length === 0) {
      setIsFromService(!isFromService);
      let fHr = fHrs;
      fHr = timeZ == 'PM' && Number(fHr) !== 12 ? Number(fHr) + 12 : fHr;
      if (timeZ == 'AM' && Number(fHr) === 12) {
        fHr = 0;
      }
      let hrs = Number(fHr) > 9 ? fHr : `0${fHr}`;
      let min = Number(fMin) > 9 ? fMin : `0${fMin}`;
      let timeStamp = `${hrs}:${min}:00.0`;
      let serviceTypeMap = {
        acc: 'accommodation',
        accommodation: 'accommodation',
        aud: 'audiology',
        audiology: 'audiology',
        dtl: 'dental',
        dental: 'dental',
        dgn: 'diagnostics',
        diagnostics: 'diagnostics',
        int: 'interpretation',
        interpretation: 'interpretation',
        trn: 'transportation',
        transportation: 'transportation',
        spt: 'special_transp',
        'special transport': 'special_transp',
        mse: 'mse',
        hhc: 'hhc',
        MOD: 'modifications',
        modification: 'modifications'
      }
    if(details?.newServiceId !==0 && details?.copiedService !== true) {
      let followUpdate = followupDate ? moment(followupDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      let saveFollowupPayload = {
        functionName: 'saveFollowup',
        zebraUserId: currentUserId,
        serviceId: details.serviceId,
        table: details.module && serviceTypeMap[details.module.toLowerCase()] ? serviceTypeMap[details.module.toLowerCase()] : '',
        followupDate: `${followUpdate} ${timeStamp}`,
        // "followupDate": followupData.followupDate,
        followupType: data.followupType
          ? data.followupType
          : followupData.followupType,
        servAbbrv: details.module,
        lmsUserId: lmsUserId,
      };
      saveFollowUp(saveFollowupPayload);
      let breadCrumb = {
        "functionName": 'logBreadCrumbs',
        "zebraUserId": currentUserId,
        "userid": currentUserId,
        "breadcrumbkey": 'FOLLOWUPDATECHANGE',
        "refTableId": details.serviceId,
        "refId": data.referralId,
        "refTable": details.module,
      };
      followdateEvent && logBreadCrumbs(breadCrumb)
      let breadCrumb2 = {
        "functionName": 'logBreadCrumbs',
        "zebraUserId": currentUserId,
        "userid": currentUserId,
        "breadcrumbkey": 'FOLLOWUPCHANGE',
        "refTableId": details.serviceId,
        "refId": data.referralId,
        "refTable": details.module,
      };
      followEvent && logBreadCrumbs(breadCrumb2)
    }else{
      data.referralId = details.referralId
      setData(data)
    }
  }
    else if (reqFields.includes('You must have at least one SERVICE')) {
      alert('You must have at least one SERVICE')
    }
    else if (reqFields.includes('At least one TRANSPORTATION LEG is required.')) {
      alert('At least one TRANSPORTATION LEG is required.')
    }

    else if (reqFields.includes('At least one SPECIAL TRANSPORTATION LEG is required.')) {
      alert('At least one SPECIAL TRANSPORTATION LEG is required.')
    }


    else alert('Please Fill in all the required Fields in order to Save the referral')

    // refreshCall();
    // onSaveHandler();
  }

  const refreshCall = () => {


    setTimeout(() => {
      let id = zebraId ? zebraId : currentUserId;
      let user = dataFromReducer.roleUsers.users.find(u => u.zebraId == id);
      if (id == "1111") {
        user = {
          zebraId: 1111,
          username: 'Unbilled/Unassigned',
          id: -1,
        };
        // userSelected = this.props.currentUserId;
      }
      else if (id == "2222") {
        user = {
          zebraId: 2222,
          id: 0,
          username: 'Unassigned',
        };
        // userSelected = this.props.currentUserId;
      }
      setSelectedUser(user.zebraId);
      getUsersWorkList(user, id);
    }, 3000);
  };

  const setFollowupData = item => {
    setData({ ...data, followupType: item, currentUserId });
  };

  const setFollowupTime = item => {
    setTime({ ...time, item });
  };

  let externalData = {};
  useEffect(() => {
    if (dataFromReducer && dataFromReducer.roleUsers) {
      const user = dataFromReducer.roleUsers.users.find(
        u => u.zebraId == currentUserId
      );
      getUsersWorkList(user).then(data => console.log(data));
    }
  }, [dataFromReducer.roleUsers, getUsersWorkList]);
  const loadView = () => {

    if (isNewReferral && !isExternalReferral) {
      externalData = {
        fromExternal: isExternal,
        setIsExternal: setIsExt,
        setPatientEdited: setPatientEdited,
        isPatientEdited: isPatientEdited,
        isReferrerEdited: isReferrerEdited,
        setReferrerEdited: setReferrerEdited,
        isAdjusterEdited: isAdjusterEdited,
        setAdjusterEdited: setAdjusterEdited,
        isCaseManagerEdited: isCaseManagerEdited,
        setCaseManagerEdited: setCaseManagerEdited,
        isPayorEdited: isPayorEdited,
        setPayorEdited: setPayorEdited,
        isPhyEdited: isPhyEdited,
        setPhyEdited: setPhyEdited,
        isEttorneyEdited: isEttorneyEdited,
        setEttorneyEdited: setEttorneyEdited,
        setWorklistTask: setWorklistTask,
        isEmployerEdited: isEmployerEdited,
        setEmployerEdited: setEmployerEdited,
      };

      return (
        <>
          <br />
       
          <Button
            className="exitButton"
            type="button"
            color="primary"
            onClick={onSaveHandler}
            style={{ marginRight: '64px' }}
          >
            SAVE
          </Button>
          <br />
          <Button
            className="exitButton"
            type="button"
            color="danger"
            onClick={openExitModal}
          >
            EXIT
          </Button>
          <Fast360Ref
            disableSave={disableSave}
            setDisableSave={setDisableSave}
            initialData={loadInitialData}
            details={details}
            setDetails={setDetails}
            fromExternal={externalData}
            onSaveComp={onSaveComp}
            serviceVal={isFromService}
            isFromLanding={false}
            setFollowupData={setFollowupData}
            setFollowupTime={setFollowupTime}
            setFollowupDate360={setFollowupDate}
            setFhrs360={setFhrs}
            setFmin360={setFmin}
            setTimeZ360={setTimeZ}
            lmsUserId={lmsUserId}
            data={data}
            onWorklistExit={onWorklistExit}
            refreshCall={refreshCall}
            alertData={alertData}
            isNewReferral={isNew}
            readyForBilling={readyForBilling}
            setReadyForBilling={setReadyForBilling}
            setListToUpdate={setListToUpdate}
            loadReferralandVender={loadReferralandVender}

          />
          <PopupModal
            content={getRefreshFeesModalBodyContent()}
            title="Confirmation"
            isOpen={isExitModalOpen}
            externalToggle={openExitModal}
            footerContent={getRefreshFeesModalFooterContent()}
            size="md"
          />
        </>
      );
    } else if (isAdvSearch) {
      return (
        <>
          <Button
            className="exitButton"
            type="button"
            color="danger"
            onClick={onResetState}
          >
            EXIT
          </Button>
          <CheckBoxContextProvider>
            <AdvSearchContextProvider>
              <AdvSearch
                setFromAdvRef={setFromAdvRef}
                setIsAdvSearch={setIsAdvSearch}
                setFast360Data={setData}
                openReferral={openReferral}
                updateState={setAdvSearchState}
                updateState1={setAdvSearchState1}
                storedState={advSearchState}
                storedState1={advSearchState1}
                updateData={setAdvSearchData}
                advSearchData={advSearchData}
                useStoredData={useStoredData}
                setUseStoredData={setUseStoredData}
              />
            </AdvSearchContextProvider>
          </CheckBoxContextProvider>
        </>
      );
    } else if (isExternalReferral) {
      externalData = {
        fromExternal: !isExternal,
        setIsExternal: setIsExt,
        setPatientEdited: setPatientEdited,
        isPatientEdited: isPatientEdited,
        isReferrerEdited: isReferrerEdited,
        setReferrerEdited: setReferrerEdited,
        isAdjusterEdited: isAdjusterEdited,
        setAdjusterEdited: setAdjusterEdited,
        isCaseManagerEdited: isCaseManagerEdited,
        setCaseManagerEdited: setCaseManagerEdited,
        isPayorEdited: isPayorEdited,
        setPayorEdited: setPayorEdited,
        isPhyEdited: isPhyEdited,
        setPhyEdited: setPhyEdited,
        isEttorneyEdited: isEttorneyEdited,
        setEttorneyEdited: setEttorneyEdited,
        setWorklistTask: setWorklistTask,
        isEmployerEdited: isEmployerEdited,
        setEmployerEdited: setEmployerEdited,
      };
      return (
        <>
          <Button
            className="exitButton"
            type="button"
            color="primary"
            onClick={onSaveHandler}
            style={{ marginRight: '64px' }}
          >
            SAVE
          </Button>
          <br />
          <Button
            className="exitButton"
            type="button"
            color="danger"
            onClick={onExternalReferralExit}
          >
            EXIT
          </Button>
          <Fast360Ref
            disableSave={disableSave}
            setDisableSave={setDisableSave}
            initialData={loadInitialData}
            fromExternal={externalData}
            serviceVal={isFromService}
            onSaveComp={onSaveComp}
            isFromLanding={false}
            setFollowupData={setFollowupData}
            setFollowupTime={setFollowupTime}
            setFollowupDate360={setFollowupDate}
            setFhrs360={setFhrs}
            setFmin360={setFmin}
            setTimeZ360={setTimeZ}
            data={data}
            details={details}
            setDetails={setDetails}
            onWorklistExit={onWorklistExit}
            refreshCall={refreshCall}
            isNewReferral={isNew}
            alertData={[]}
            readyForBilling={readyForBilling}
            setReadyForBilling={setReadyForBilling}
            setListToUpdate={setListToUpdate}
            loadReferralandVender={loadReferralandVender}
            isExternalReferral = {isExternalReferral}
          />
        </>
      );
    } else if (fromWorklist || fromAdvRef || fromQuickSearch) {
      externalData = {
        setPatientEdited: setPatientEdited,
        isPatientEdited: isPatientEdited,
        isReferrerEdited: isReferrerEdited,
        setReferrerEdited: setReferrerEdited,
        isAdjusterEdited: isAdjusterEdited,
        setAdjusterEdited: setAdjusterEdited,
        isCaseManagerEdited: isCaseManagerEdited,
        setCaseManagerEdited: setCaseManagerEdited,
        isPayorEdited: isPayorEdited,
        setPayorEdited: setPayorEdited,
        isPhyEdited: isPhyEdited,
        setPhyEdited: setPhyEdited,
        isEttorneyEdited: isEttorneyEdited,
        setEttorneyEdited: setEttorneyEdited,
        setWorklistTask: setWorklistTask,
        isEmployerEdited: isEmployerEdited,
        setEmployerEdited: setEmployerEdited,
      };
      return (
        <>
          {vendorAssignmentGrid()}
          <Button
            type="button"
            style={{ float: 'right' }}
            onClick={openQuickSearchModal}
          >
            QUICK SEARCH
          </Button>
          <Button
            type="button"
            style={{ float: 'right' }}
            onClick={onAdvSearch}
          >
            ADV SEARCH
          </Button>
          <br />
          <Button
            className="exitButton"
            type="button"
            color="primary"
            onClick={openOverride}
            disabled={!disableSave}
            style={{ marginRight: '132px' }}
          >
            MANAGER OVERRIDE
          </Button>
          <Button
            className="exitButton"
            type="button"
            color="primary"
            onClick={saveFollowupCall}
            disabled={disableSave}
            style={{ marginRight: '64px' }}
          >
            SAVE
          </Button>
          <br />
          <Button
            className="exitButton"
            type="button"
            color="danger"
            onClick={onWorklistExit}
          >
            EXIT
          </Button>
          <Fast360Ref
            disableSave={disableSave}
            setDisableSave={setDisableSave}
            initialData={loadInitialData}
            isFromLanding={true}
            fromExternal={false}
            fromWorklist={true}
            fromQuickSearch={true}
            setFromWorklist={setFromWorklist}
            currentTaskId={currentTaskId}
            externalData={externalData}
            onSaveComp={onSaveComp}
            serviceVal={isFromService}
            setFollowupData={setFollowupData}
            setFollowupTime={setFollowupTime}
            setFollowupDate360={setFollowupDate}
            setFhrs360={setFhrs}
            setFmin360={setFmin}
            setTimeZ360={setTimeZ}
            lmsUserId={lmsUserId}
            data={data}
            setDetails={setDetails}
            details={details}
            onWorklistExit={onWorklistExit}
            refreshCall={refreshCall}
            alertData={alertData}
            loadReferralWorkList={loadReferralWorkList}
            loadReferralandVender={loadReferralandVender}
            isNewReferral={isNew}
            readyForBilling={readyForBilling}
            setReadyForBilling={setReadyForBilling}
            setListToUpdate={setListToUpdate}
          />
          <QuickSearch
            isOpen={isOpenQuickSearchModal}
            onClose={openQuickSearchModal}
            openReferral={openReferral}
            setFromQuickSearch={setFromQuickSearch}
          />
          {isOpenManagerOverrideModal && <ManagerOverrideModal clicked={openOverride} setDisableSave={setDisableSave}/>}
        </>
      );
    } else {
      return (
        <>
          <Fast360TopNav
            onNewReferral={onNewReferral}
            onAdvSearch={onAdvSearch}
            gpIntegration={gpIntegration}
            onSelectGpIntegration={value => setGpIntegration(value)}
            currentUserId={currentUserId}
            setSelectedUser={setSelectedUser}
            setZebraId={setZebraId}
            openReferral={openReferral}
            selectedUser={selectedUser}
            setFromQuickSearch={setFromQuickSearch}
            onInvoiceCooridinatorToggle={() => setIsInvoiceCoordinatorOpen(!isInvoiceCoordinatorOpen)}
            isInvoiceCoordinatorOpen={isInvoiceCoordinatorOpen}
          />
          {! isInvoiceCoordinatorOpen && <Fast360LandingTable
            selectedUser={selectedUser}
            zebraId={zebraId}
            openReferral={openReferral}
            setAssignedGrid={setAssignedGrid}
            refreshCall={refreshCall}
          /> }

          {isInvoiceCoordinatorOpen &&  <Fast360InvoiceCoordinator 
            openReferral={openReferral}
          />}

          <GpIntegrationsModal
            isOpen={gpIntegration !== ''}
            onClose={onCloseGpIntegration}
            gpIntegration={gpIntegration}
          />
        </>
      );
    }
  };
  return (
    <div>
      {refSaved && isNewReferral && <NewAttachmentPrompt onNewRefExit={onNewRefExit} clicked={setRefSaved} isOpen={true} isExternalReferral={isExternalReferral} refSaved={refSaved} selectedExternalData={dataObject}/>}
      {refSaved && !isNewReferral && <NewAttachmentPrompt isFromLanding={true} clicked={setRefSaved} isOpen={true} isExternalReferral={isExternalReferral} refSaved={refSaved} selectedExternalData={dataObject}/>}
      <Fast360TopMenu onNewReferral={onExternalReferral} onOpenReferral={(dataObj) => setDataObject(dataObj)} />
      {loadView()}

    </div>
  );
};

Fast360.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  resetReferrerInfo: PropTypes.func,
  resetAdjusterInfo: PropTypes.func,
  resetEmployerInfo: PropTypes.func,
  resetPhysicianInfo: PropTypes.func,
  resetAttorneyInfo: PropTypes.func,
  resetClientInfo: PropTypes.func,
  resetCaseManagerInfo: PropTypes.func,
  resetSaveClaim: PropTypes.func,
  resetAudInfoTable: PropTypes.func
};

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    updatedReferralInfo: fast360Info.updatedReferralInfo,
    loadClaimantByIdInfo: fast360Info.loadClaimantByIdInfo,
    audInfo: fast360Info.audInfo,
    currentUserId: userId || 0,
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    updatedPhysicianInfo: fast360Info.updatedPhysicianInfo,
    updatedEmployerInfo: fast360Info.updatedEmployerInfo,
    updatedAttorneyInfo: fast360Info.updatedAttorneyInfo,
    updatedPayorInfo: fast360Info.updatedPayorInfo,
    updatedCaseManagerInfo: fast360Info.updatedCaseManagerInfo,
    updatedAdjusterInfo: fast360Info.updatedAdjusterInfo,
    updatedClaimInfo:
      fast360Info.updatedClaimInfo && fast360Info.updatedClaimInfo.claim,
    loadClaimInfo: fast360Info.loadClaimInfo,
    updatedRefInfo: fast360Info.updatedRefInfo,
    workList: state.FAST360.fast360Reducer.updateUsersWorkList
      ? state.FAST360.fast360Reducer.updateUsersWorkList
      : [],
    taskData: state.FAST360.fast360Reducer.updateloadExistingTasks
      ? state.FAST360.fast360Reducer.updateloadExistingTasks
      : [],
    currentTaskId: state.FAST360.fast360Reducer.updateloadCurrentTaskId
      ? state.FAST360.fast360Reducer.updateloadCurrentTaskId
      : [],
    alertData: state.FAST360.fast360Reducer.updateloadReferralAlerts
      ? state.FAST360.fast360Reducer.updateloadReferralAlerts
      : [],
    groupTasksProgression: state.FAST360.fast360Reducer
      .updateloadGroupProgression
      ? state.FAST360.fast360Reducer.updateloadGroupProgression
      : [],
    selectedWorklist: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    workListData: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    lmsUserId: state.FAST360.initialData.roleUsers
      ? state.FAST360.initialData.roleUsers.LmsUserId
      : '',
    followupData: state.FAST360.fast360Reducer.followupData
      ? state.FAST360.fast360Reducer.followupData
      : {},
    trnDetailsList: state.FAST360.fast360Reducer.trnDetailsList
      ? state.FAST360.fast360Reducer.trnDetailsList
      : [],
    sptDetailsList: state.FAST360.fast360Reducer.sptDetailsList
      ? state.FAST360.fast360Reducer.sptDetailsList
      : [],
    currentWorkListItem: state.FAST360.fast360Reducer.currentWorkListItem ? state.FAST360.fast360Reducer.currentWorkListItem : {},
    exteneralReferralData: state.FAST360.fast360Reducer.updatePostExternal,
    reqFields: state.FAST360.fast360Reducer.reqFields,
    followEvent: state.FAST360.fast360Reducer.followupChangeEvent,
    followdateEvent: state.FAST360.fast360Reducer.followupDateEvent
  };
};

const mapDispatchToProps = dispatch => ({
  switchModule: module => dispatch(commonActions.switchModule(module)),
  loadInitialData: data => dispatch(fast360Operations.loadInitialData(data)),
  getUsersWorkList: data => dispatch(fast360Operations.getUsersWorkList(data)),
  resetReferrerInfo: () => dispatch(fast360Actions.resetReferrerInfo()),
  resetSaveReferral: () => dispatch(fast360Actions.resetSaveReferral()),
  resetAdjusterInfo: () => dispatch(fast360Actions.resetAdjusterInfo()),
  resetEmployerInfo: () => dispatch(fast360Actions.resetEmployerInfo()),
  resetPhysicianInfo: () => dispatch(fast360Actions.resetPhysicianInfo()),
  resetAttorneyInfo: () => dispatch(fast360Actions.resetAttorneyInfo()),
  resetClientInfo: () => dispatch(fast360Actions.resetClientInfo()),
  resetCaseManagerInfo: () => dispatch(fast360Actions.resetCaseManagerInfo()),
  resetSaveClaim: () => dispatch(fast360Actions.resetSaveClaim()),
  resetLoadClaimantById: () => dispatch(fast360Actions.resetLoadClaimantById()),
  resetAudInfoTable: () => dispatch(fast360Actions.resetAudInfoTable()),
  updatePreviousUser: data => dispatch(fast360Actions.updatePreviousUser(data)),
  updateStatusClicked: data =>
    dispatch(fast360Actions.updateStatusClicked(data)),
  updateMilestoneClicked: data =>
    dispatch(fast360Actions.updateMilestoneClicked(data)),
  updateTaskClicked: data => dispatch(fast360Actions.updateTaskClicked(data)),
  updateMilestoneData: data =>
    dispatch(fast360Actions.updateMilestoneData(data)),
  saveReferral: data => dispatch(fast360Operations.saveReferral(data)),
  updateSaveReferral: data =>
    dispatch(fast360Actions.updateSaveReferral(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updatePostExternal: data => dispatch(fast360Actions.updatePostExternal(data)),
  updateLoadFromWorkList: data =>
    dispatch(fast360Actions.updateLoadFromWorkList(data)),
  selectedExternalData: data =>
    dispatch(fast360Actions.selectedExternalData(data)),
  loadFromWorkList: (data, serviceData) => dispatch(fast360Operations.loadFromWorkList(data, serviceData)),
  loadUpdatedVendor: (data, serviceData, workListData, onClose) => dispatch(fast360Operations.loadUpdatedVendor(data, serviceData, workListData, onClose)),
  resetVendorLookupData: data =>
    dispatch(fast360Actions.resetVendorLookupData(data)),
  loadExistingTasks: data =>
    dispatch(fast360Operations.loadExistingTasks(data)),
  getCurrentTaskId: data =>
    dispatch(fast360Operations.getCurrentTaskId(data)),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
  loadReferralAlerts: data =>
    dispatch(fast360Operations.loadReferralAlerts(data)),
  //selectFromWorkListService: (data) => dispatch(fast360Actions.selectFromWorkListService(data)),
  callDynamicLoad: data =>
    dispatch(fast360Operations.callDynamicLoad(data)),
  groupTasksProgression: data =>
    dispatch(fast360Operations.groupTasksProgression(data)),
  groupTasksProgression2: data =>
    dispatch(fast360Operations.groupTasksProgression(data)),
  getFollowupData: data => dispatch(fast360Operations.getFollowupData(data)),
  updateGetFollowupData: data =>
    dispatch(fast360Actions.updateGetFollowupData(data)),
  saveFollowUp: data => dispatch(fast360Operations.saveFollowUp(data)),
  getUsersWorkList: (data, currentUserId) =>
    dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
  setCurrentWorkList: (data) => dispatch(fast360Actions.setCurrentWorkList(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data)),
  clearAuthService: data => dispatch(fast360Actions.updateClearAuthService({})),
  updateSelectedAuthRecord: data => dispatch(fast360Actions.updateSelectedAuthRecord(data)),
  updateClickRecord: data => dispatch(fast360Actions.updateClickRecord(data)),
  setReqFields: (data) => dispatch(fast360Actions.reqFields(data)),
  resetAudiologyHcpcsList: () => dispatch(fast360Actions.resetAudiologyHcpcsList()),
  resetCostSavings: () => dispatch(fast360Actions.resetCostSavings()),
  setTrnDetails: (data) => dispatch(fast360Actions.setTrnDetails(data)),
  setSptDetails: (data) => dispatch(fast360Actions.setSptDetails(data)),
  trnSaveData: data => dispatch(fast360Actions.trnSaveData(data)),
  sptsaveData: data => dispatch(fast360Actions.sptsaveData(data)),
  resetTRNInfoTable: data => dispatch(fast360Actions.resetTRNInfoTable(data)),
  resetSPTInfoTable: data => dispatch(fast360Actions.resetSPTInfoTable(data)),
  followupChangeEvent: data => dispatch(fast360Actions.followupChangeEvent(data)),
  followupDateEvent: data => dispatch(fast360Actions.followupDateEvent(data)),
  setAdvValues: (data) => dispatch(fast360Actions.setAdvValues(data)),
  resetAdvancedSearchReport: (data) => dispatch(fast360Actions.resetAdvancedSearchReport(data)),


});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360);
