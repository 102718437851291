import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { recentActivity } from '../utils/constantUtils';
import { getNounForm } from '../utils/billUtils';
import { authSelectors } from 'modules/auth/store';

import RecentActivityTopNav from './RecentActivityTopNav';
import TaskListTable from './TaskListTable';

const RecentActivityBottom = props => {
  return (
    <Row>
      <Col lg="12">{getNounForm(props.totalBills, 'Bill', 'Bills')}</Col>
    </Row>
  );
};

class RecentActivityContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentSelection !== this.props.currentSelection && nextProps.currentSelection === "recentActivity") {
      let adjusterData = nextProps.adjusters.filter(t => t.name == nextProps.user.fullname)[0];
      if (adjusterData != null) {
        this.props.updateSelectedUser({
          selectedAdjusterId: adjusterData.id,
          selectedAdjusterName: adjusterData.name,
        });
        this.props.findBills({
          adjuster: adjusterData.id,
          profile: this.props.profile,
          isManager: this.props.manager,
        });
      }
    }
  }
  handleUserDisplayChange = (_fieldName, value) => {
    this.props.updateSelectedUser({
      selectedAdjusterId: this.props.selectedAdjusterId,
      selectedAdjusterName: value,
    });
  };

  handleUserChange = (_fieldName, displayValue, value) => {

    this.props.updateSelectedUser({
      selectedAdjusterId: value,
      selectedAdjusterName: displayValue,
    });

    this.props.findBills({
      adjuster: value,
      profile: this.props.profile,
      isManager: this.props.manager,
    });
  };

  onReverseActivity = bill => {
    const { profile, selectedAdjusterId, manager } = this.props;
    this.props.reverseActivity({
      billId: bill.id,
      profile: profile,
      zebraUserId: this.props.currentUserId,
      adjuster: selectedAdjusterId,
      isManager: manager,
    });
  };

  render() {
    return (
      <Row>
        <Col lg="12">
          <RecentActivityTopNav
            adjusters={this.props.adjusters}
            selectedAdjusterName={this.props.selectedAdjusterName}
            handleUserDisplayChange={this.handleUserDisplayChange}
            handleUserChange={this.handleUserChange}
            settingColumns={this.props.settingColumns}
            updateGridSettingsCheckbox={this.props.updateGridSettingsCheckbox}
          />

          <TaskListTable
            data={this.props.bills}
            name={recentActivity}
            currentUserId={this.props.currentUserId}
            isSlpUser={this.props.isSlpUser}
            profile={this.props.profile}
            settingColumns={this.props.settingColumns}
            onReverseActivity={this.onReverseActivity}
          />

          <RecentActivityBottom totalBills={this.props.bills.length} />
        </Col>
      </Row>
    );
  }
}

RecentActivityContainer.propTypes = {
  adjusters: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  selectedAdjusterId: PropTypes.number.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  manager: PropTypes.bool.isRequired,
  findBills: PropTypes.func.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  reverseActivity: PropTypes.func.isRequired,
  settingColumns: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isSlpUser: PropTypes.bool.isRequired,
  updateGridSettingsCheckbox: PropTypes.func.isRequired,
  currentSelection: PropTypes.any
};

const mapStateToProps = state => {
  const recentActivityState = billOperationsSelectors.getRecentActivityState(
    state
  );
  const gridSettings = billOperationsSelectors.getTaskListGridSettingsState(
    state
  );
  const user = authSelectors.getUser(state);
  return {
    adjusters: recentActivityState.adjusters,
    selectedAdjusterId: recentActivityState.selectedAdjusterId,
    selectedAdjusterName: recentActivityState.selectedAdjusterName,
    bills: recentActivityState.bills,
    settingColumns: gridSettings.settingColumns,
    user: user
  };
};

const mapDispatchToProps = dispatch => ({
  findBills: data =>
    dispatch(billOperationsOperations.findRecentActivityBills(data)),
  updateSelectedUser: userId =>
    dispatch(
      billOperationsActions.updateRecentActivitySelectedAdjuster(userId)
    ),
  reverseActivity: data =>
    dispatch(billOperationsOperations.reverseAdjusterAction(data)),
  updateGridSettingsCheckbox: data =>
    dispatch(billOperationsActions.updateTaskListGridSettingsCheckbox(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentActivityContainer);
