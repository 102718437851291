import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

import '../assets/static/adminmodule-user.scss';

import actions from '../store/actions';
import { COMMENTS_MODULE } from '../store/constants';

class UserComment extends Component {
  render() {
    return (
      <FormGroup className="comment-box group full">
        <Input
          type="textarea"
          name="comments"
          id="comments"
          className="comment-box"
          value={this.props.comments}
          onChange={this.props.onUpdateComments}
        />
      </FormGroup>
    );
  }
}

const mapStateToProps = state => {
  return {
    comments: state.ADMIN.user.userComments.comments,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateComments: event =>
    dispatch(actions.updateInput(event, COMMENTS_MODULE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComment);
