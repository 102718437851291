import * as R from 'ramda';
import React from 'react';

export const renderClientName = item => {
  if (item.int_sitecode === 0) {
    return <div>New Client</div>;
  }
  const siteName = item.chr_sitename || '';
  const carrierType = item.chr_carriertype || '';
  const address1 = item.chr_address1 || '';
  const address2 = item.chr_address2 || '';
  const city = item.chr_city || '';
  const state = item.chr_state || '';
  const zip = item.chr_zip || '';
  return (
    <>
      <span>{siteName}</span>
      <br />
      <span>Carrier Type: {carrierType}</span>
      <br />
      {address1 !== '' && (
        <>
          <span className="grey-text">{address1}</span>
          <br />
        </>
      )}
      {address2 !== '' && (
        <>
          <span className="grey-text">{address2}</span>
          <br />
        </>
      )}
      {city !== '' && (
        <>
          <span className="grey-text">
            {city}, {state} {zip}
          </span>
          <br />
        </>
      )}
    </>
  );
};

export const getValueByKey = (data, key) => {
  const resultItem = R.find(
    item => item.hasOwnProperty('key') && item.key === key,
    data
  );
  return resultItem ? resultItem.description || resultItem.key : '';
};
