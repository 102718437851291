import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormFeedback,
} from 'reactstrap';

import AppDateInput from 'shared/components/input/AppDateInput';
import AppMultiSelect from 'shared/components/multiselect/AppMultiselect';

import { GENDER } from '../utils/constantUtils';

const IndividualExpertRecordBasicInfoTab = ({
  data,
  errors,
  onInputChange,
  onCustomInputChange,
  onInputMaskChange,
  salutations,
  languages,
  honorifics,
}) => (
  <Row>
    <Col lg="12">
      <Row>
        <Col lg="2">
          <FormGroup>
            <Label for="salutation">Salutation</Label>
            <Input
              type="select"
              name="salutation"
              id="salutation"
              value={data.salutation}
              onChange={onInputChange}
            >
              <option key={-1} value="-1" disabled>
                Choose...
              </option>
              {salutations.map((item, index) => (
                <option key={index} value={item.element}>
                  {item.element}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="firstName">First Name *</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={data.firstName}
              onChange={onInputChange}
              maxLength="200"
              invalid={errors.firstName.length > 0}
            />
            {errors.firstName.length > 0 && (
              <FormFeedback tooltip>{errors.firstName}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="middleInitial">Middle Name</Label>
            <Input
              type="text"
              name="middleInitial"
              id="middleInitial"
              value={data.middleInitial}
              onChange={onInputChange}
              maxLength="25"
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="lastName">Last Name *</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={data.lastName}
              onChange={onInputChange}
              maxLength="200"
              invalid={errors.lastName.length > 0}
            />
            {errors.lastName.length > 0 && (
              <FormFeedback tooltip>{errors.lastName}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <AppDateInput
              id="dob"
              label="Date of Birth"
              selected={data.dob}
              onChange={value => onCustomInputChange('dob', value)}
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              dropdownMode="select"
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              name="gender"
              id="gender"
              value={data.gender}
              onChange={onInputChange}
            >
              <option key={-1} value="-1" disabled>
                Choose...
              </option>
              {GENDER.map((value, index) => (
                <option key={index} value={index}>
                  {value}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="2">
          <FormGroup>
            <Label for="ssn">SSN</Label>
            <InputMask
              mask="999-99-9999"
              alwaysShowMask={true}
              name="ssn"
              id="ssn"
              value={data.ssn}
              onChange={onInputMaskChange}
              invalid={errors.ssn.length > 0}
            >
              <Input />
            </InputMask>
            {errors.ssn.length > 0 && (
              <FormFeedback tooltip>{errors.ssn}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="tinNumber">Tin #</Label>
            <InputMask
              mask="99-9999999"
              alwaysShowMask={true}
              name="tinNumber"
              id="tinNumber"
              value={data.tinNumber}
              onChange={onInputMaskChange}
              invalid={errors.tinNumber.length > 0}
            >
              <Input />
            </InputMask>
            {errors.tinNumber.length > 0 && (
              <FormFeedback tooltip>{errors.tinNumber}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label for="payableTo">Make Payable to</Label>
            <Input
              type="text"
              name="payableTo"
              id="payableTo"
              value={data.payableTo}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <FormGroup>
            <Label for="npi">Nat. Prov.ID</Label>
            <Input
              type="text"
              name="npi"
              id="npi"
              value={data.npi}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Label for="vendor">Vendor ID</Label>
            <Input
              type="text"
              name="vendor"
              id="vendor"
              value={data.vendor}
              onChange={onInputChange}
              maxLength="6"
              invalid={errors.vendor.length > 0}
            />
            {errors.vendor.length > 0 && (
              <FormFeedback tooltip>{errors.vendor}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <AppMultiSelect
            id="language"
            selected={data.language}
            options={languages}
            placeholder="Languages..."
            selectedChanged={onCustomInputChange}
            displayKey="element"
            valueKey="element"
          />
        </Col>
        <Col lg="6">
          <AppMultiSelect
            id="honor"
            selected={data.honor}
            options={honorifics}
            placeholder="Honor..."
            selectedChanged={onCustomInputChange}
            displayKey="element"
            valueKey="element"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <FormGroup>
            <Label for="previousJericho">Previous Jericho ID</Label>
            <Input
              type="text"
              name="previousJericho"
              id="previousJericho"
              value={data.previousJericho}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="4">{/* TODO: Add component upload */}</Col>
        <Col lg="4"></Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="underInvestigation"
              id="underInvestigation"
              label="Under Investigation"
              checked={data.underInvestigation}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <Input
              type="textarea"
              name="reason"
              id="reason"
              value={data.reason}
              onChange={onInputChange}
              spellCheck={true}
              rows={5}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

IndividualExpertRecordBasicInfoTab.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCustomInputChange: PropTypes.func.isRequired,
  onInputMaskChange: PropTypes.func.isRequired,
  salutations: PropTypes.array.isRequired,
  honorifics: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
};

export default IndividualExpertRecordBasicInfoTab;
