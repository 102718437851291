export const SWITCH_MENU = 'SWITCH_MENU';
export const SWITCH_MODULE = 'SWITCH_MODULE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const ERROR = 'ERROR';
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR';
export const ADD_APP_ALERT = 'ADD_APP_ALERT';
export const CLEAR_APP_ALERT = 'CLEAR_APP_ALERT';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const CLEAR_APP_LOADING = 'CLEAR_APP_LOADING';

export const DEFAULT_ALERT_LEVEL = 'primary';
export const DEFAULT_ALERT_TIMEOUT = 10000;

export const moduleName = 'COMMON';

export default {
  SWITCH_MENU,
  SWITCH_MODULE,
  OPEN_MODAL,
  ERROR,
  CLEAR_APP_ERROR,
  ADD_APP_ALERT,
  CLEAR_APP_ALERT,
  SET_APP_LOADING,
  CLEAR_APP_LOADING,
  DEFAULT_ALERT_LEVEL,
  DEFAULT_ALERT_TIMEOUT,
  moduleName,
};
