import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import { fast360Selectors, fast360Operations } from './store/index';
import AppTable from 'shared/components/table/AppTable';
import moment from '../../../node_modules/moment/moment';
import { connect } from 'react-redux';
import { authSelectors } from '../auth/store';


const Fast360RentalDetailsModal = ({ isOpen, onClose, currentUserId, currentWorkList, serviceData, loadByServiceReferral, rentalServiceReferral }) => {

    const [rentalDetails, setRentalDetails] = useState([]);


    useEffect(() => {

        setRentalDetails(rentalServiceReferral);
    }, [rentalServiceReferral])

    useEffect(() => {
        let functionName = ''
        if (serviceData.module === "MOD") {
            functionName = 'loadbyMODReferral';
        } else if (serviceData.module === "MSE") {
            functionName = 'loadbyMSEReferral'
        }

        const req = {
            functionName: functionName,
            zebraUserId: currentUserId,
            referralId: serviceData.referralId ? serviceData.referralId : null,
        }

        loadByServiceReferral(req);


    }, [serviceData, currentUserId])

    const getMargin = (item) => {

        let val = Number(((item.custCost - item.vendCost) / item.custCost) * 100).toFixed(2);
        return `${val}%`;
    }

    const getFormattedDate = d => {
        if (d) {
            return moment(d).format('MM/DD/YYYY')
        } else {
            return '';
        }
    }


    const getCustomerTotal = item => {
        return Number(item.custCost) * Number(item.quantity);


    }

    const getVendorTotal = item => {
        return Number(item.vendCost) * Number(item.quantity);


    }

    const getOffset = ( el ) =>  {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        // _x = el.getBoundingClientRect().x;
        // _y = el.getBoundingClientRect().y;
        return { top: _y, left: _x };
    }

    useEffect(() => {
        let interval;
        if (serviceData.module === "MOD") {
            const header = document.createElement('div');
            header.style.position =  'absolute';
            header.style.top =  0;
            header.style.color =  'white';
            header.style.zIndex =  111;
            header.style.background =  '#343a40';
            header.style.borderBottom = '1px solid white';
            header.style.textAlign = 'center';
            header.innerHTML = '<span class="text-center">Rental Information</span>';
            let midCols;
            let count = 0;

            interval = setInterval(() => {
                const tableHead = document.querySelector('.mod-rental-table thead');
                const startCol = tableHead.querySelectorAll('tr th')[7];
                const endCol = tableHead.querySelectorAll('tr th')[12];
                midCols = tableHead.querySelectorAll('tr th');


                if (tableHead && startCol && endCol && midCols.length > 0) {
                    //clearInterval(interval);
                   
                    midCols.forEach((el, index) => {
                        if(index >=7 && index <= 11) {
                            el.style.whiteSpace = 'nowrap'
                        }
                    });
           
                    const theadLeft = getOffset(tableHead).left;
                    const startLeft = getOffset(startCol).left - theadLeft;
                    const endLeft = getOffset(endCol).left - theadLeft;
                  
                    header.style.left =  `${startLeft}px`;
                    header.style.width =  `${(endLeft - startLeft) -2}px`;

                    tableHead.append(header);
                    if(count > 5) {
                        clearInterval(interval);
                    }
                    count++;
                }

            }, 500);


        }

        return () => {
            clearInterval(interval);
        }
    }, [serviceData]);

    return <>

        <Modal size="xl" isOpen={isOpen} >
            <ModalHeader toggle={onClose}>Rental Details</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg="12">
                        {serviceData.module === "MSE" && <AppTable
                            style={{ height: 'auto' }}
                            resizable
                            columns={[
                                {
                                    header: 'Service Id',
                                    field: 'trn',

                                },
                                {
                                    header: 'Date of Service',
                                    field: 'dos',
                                    render: item => item.dos && moment(item.dos).format('MM/DD/YYYY') + '\n' + item.status
                                },

                                {
                                    header: 'Modifier',
                                    field: 'modifier1',
                                    render: item => item.modifier1 + '\n' + getFormattedDate(item.startExtDate) + ' - ' + getFormattedDate(item.endExtDate)
                                },
                                {
                                    header: 'HCPC',
                                    field: 'serviceCode',

                                },
                                {
                                    header: '2nd Modifier',
                                    field: 'modifier2',
                                },
                                {
                                    header: 'Rev. Code',
                                    field: 'revCode',
                                },
                                {
                                    header: 'FS',
                                    field: 'feeSchedule',
                                    render: item => '$' + item.feeSchedule.toFixed(2)
                                },
                                {
                                    header: 'UC',
                                    field: 'ucSchedule',
                                    render: item => '$' + item.ucSchedule

                                },
                                {
                                    header: 'MCA',
                                    field: 'medicareAllowable',
                                    render: item => '$' + item.medicareAllowable.toFixed(2)

                                },
                                {
                                    header: 'Billed Changes',
                                    field: '',
                                },
                                {
                                    header: 'Qty',
                                    field: 'quantity',
                                },
                                {
                                    header: 'Vend. Unit Cost',
                                    field: 'vendCost',
                                    render: item => '$' + item.vendCost
                                },
                                {
                                    header: 'Vend. Disc From',
                                    field: 'vendDiscountedFrom',
                                },
                                {
                                    header: 'Vend Disc %',
                                    field: 'vendPercentDiscount',
                                    render: item => item.vendPercentDiscount ? item.vendPercentDiscount + ' %' : 'null %'
                                },
                                {
                                    header: 'Cust. Unit Cost',
                                    field: 'custCost',
                                    render: item => '$' + item.custCost
                                },
                                {
                                    header: 'Cust. Disc From',
                                    field: 'custDiscountedFrom',
                                },
                                {
                                    header: 'Cust Disc %',
                                    field: 'custPercentDiscount',
                                    render: item => item.custPercentDiscount ? item.custPercentDiscount + ' %' : 'null %'
                                },
                                {
                                    header: 'Vend. Total',
                                    field: 'vendCost',
                                    render: item => '$' + getVendorTotal(item).toFixed(2)
                                },
                                {
                                    header: 'Cust Total',
                                    field: 'custCost',
                                    render: item => '$' + getCustomerTotal(item).toFixed(2)
                                },
                                {
                                    header: 'Margin',
                                    field: '',
                                    // render: item => (item.custCost- item.vendCost)
                                    render: item => getMargin(item)
                                },
                            ]}
                            data={rentalDetails}
                            rowClickHandler={() => { }}
                            rowDoubleClickHandler={() => { }}
                        />}
                        {serviceData.module === "MOD" && <div className="mod-rental-table" style={{position: 'relative'}}><AppTable
                            style={{ height: 'auto' }}
                            resizable

                            columns={[
                                {
                                    header: 'Service Id',
                                    field: 'trn',

                                },
                                {
                                    header: 'DOS',
                                    field: 'dos',
                                    render: item => item.dos && moment(item.dos).format('MM/DD/YYYY') + '\n' + item.status
                                },

                                {
                                    header: 'QTY',
                                    field: 'quantity',

                                },
                                {
                                    header: 'Modifier',
                                    field: 'primaryMod',
                                },
                                {
                                    header: 'Item Name',
                                    field: 'itemName',
                                },


                                {
                                    header: 'Service Description',
                                    field: 'description',
                                },

                                {
                                    header: 'Billed Changes',
                                    field: 'msrpAmt',
                                    render: item => `$${item.msrpAmt}`
                                },
                                {
                                    header: 'Rental Ext',
                                    field: '',
                                    render: item => item.rental && item.rental.rentalExt ? 'Yes' : 'No'
                                },
                                {
                                    header: 'Rental Start Date',
                                    field: '',
                                    render: item => item.rental && item.rental.startExtDate && moment(item.rental.startExtDate).format('MM/DD/YYYY')
                                },
                                {
                                    header: 'Rental End Date',
                                    field: '',
                                    render: item => item.rental && item.rental.endExtDate && moment(item.rental.endExtDate).format('MM/DD/YYYY')
                                },
                                {
                                    header: 'Vend. Buyout',
                                    field: '',
                                    render: item => item.rental && item.rental.vendBuyOut ? `$${item.rental.vendBuyOut.toFixed(2)}` : ``
                                },
                                {
                                    header: 'Cust. Buyout',
                                    field: '',
                                    render: item => item.rental && item.rental.custBuyOut ? `$${item.rental.custBuyOut.toFixed(2)}` : ``
                                },

                                {
                                    header: 'Vend. Tota Cost',
                                    field: '',
                                    render: item => '$' + (item.vendorCost * item.quantity).toFixed(2)
                                },
                                {
                                    header: 'Cust. Total Cost',
                                    field: '',
                                    render: item => '$' + (Number(item.custCost) * Number(item.quantity)).toFixed(2)
                                },
                                {
                                    header: 'Margin',
                                    field: '',
                                    // render: item => (item.custCost- item.vendCost)
                                    render: item => Number(((item.custCost - item.vendorCost) / item.custCost) * 100).toFixed(2) + ' %'
                                },
                            ]}
                            data={rentalDetails}
                            rowClickHandler={() => { }}
                            rowDoubleClickHandler={() => { }}
                            
                        /></div>}
                    </Col>
                </Row>


                <div style={{ float: 'right' }}>
                    <Button type="button" onClick={onClose}>
                        Close
                    </Button>

                </div>
            </ModalBody>
        </Modal>
    </>
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    const fast360Info = fast360Selectors.getFast360Info(state);
    return {
        currentUserId: userId,
        currentWorkList: fast360Info.currentWorkListItem,
        rentalServiceReferral: fast360Info.rentalServiceReferral || [],
        serviceData: fast360Info.requiredServiceData ? fast360Info.requiredServiceData : {},

    }
}

const mapDispatchToProps = dispatch => ({
    loadByServiceReferral: data => dispatch(fast360Operations.loadByServiceReferral(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(Fast360RentalDetailsModal);