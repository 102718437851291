import React, { useContext, useEffect} from 'react';
import ReactExport from "react-data-export";
import { Button } from 'reactstrap';
import { CheckBoxContext } from './contexts/CheckBoxContext';


const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

function ExcelExport({data}) {

    const { state1, dispatch1 } = useContext(CheckBoxContext);
    let idx = 1;
    // column you always want to include
let allColumns = [<ExcelColumn key={idx++} label="Conf_No" value="ConfirmNumber" />]  

    state1.displayAdjuster &&
    allColumns.push(<ExcelColumn key={idx++} label="Adjuster_Name"
    value={(col) => `${col.adjusterLastName},${col.adjusterFirstName}`} />);
state1.displayAttorneyName &&
    allColumns.push(<ExcelColumn key={idx++} label="Attorney_Name"
    value={(col) => `${col.ATTORNEY_NAME},${col.ATTORNEY_LAST}`} />);
state1.displayBilledStatus && allColumns.push(<ExcelColumn key={idx++} label="Billed_Status" value="claimantCity" />);
state1.displayBilledDate && allColumns.push(<ExcelColumn key={idx++} label="Billed_Date" value="billed" />);
state1.displayCaseManager &&
    allColumns.push(<ExcelColumn key={idx++} label="Case_Manager"
    value={(col) => `${col.caseManagerLastName},${col.caseManagerFirstName}`} />);
state1.displayCity && allColumns.push(<ExcelColumn key={idx++} label="displayCity" value="claimantCity" />);
state1.displayClaimant &&
    allColumns.push(<ExcelColumn key={idx++} label="Claimant_Name"
    value={(col) => `${col.claimantLastName},${col.claimantFirstName}`} />);
state1.displayCompletedDate && allColumns.push(<ExcelColumn key={idx++} label="Completed_Date" value="completedDate" />);
state1.displayDayBeforePTConf && allColumns.push(<ExcelColumn key={idx++} label="Day_Before_PT_Conf" value="dayBeforePTConf" />);
state1.displayDayBeforeFacilityConf && allColumns.push(<ExcelColumn key={idx++} label="Day_BeforeFacilityConf" value="dayBeforeFacilityConf" />);
state1.displayDayBeforeProviderConf && allColumns.push(<ExcelColumn key={idx++} label="Day_Before_Provider_Conf" value="dayBeforeProviderConf" />);
state1.displayDOS && allColumns.push(<ExcelColumn key={idx++} label="DOS" value="dos" />);
state1.displayEnteredBy && allColumns.push(<ExcelColumn key={idx++} label="Entered_By" value="origEnteredBy" />);
state1.displayEventId && allColumns.push(<ExcelColumn key={idx++} label="dEvent_Id" value="int_event_id" />);
state1.displayFaxConfirmation && allColumns.push(<ExcelColumn key={idx++} label="Fax_Confirmation" value="vendorFax" />);
state1.displayLastModifiedBy && allColumns.push(<ExcelColumn key={idx++} label="Last_Modified_By" value="lastModifiedBy" />);
state1.displayLastModifiedDate && allColumns.push(<ExcelColumn key={idx++} label="Last_Modified_Date" value="lastModifiedDate" />);
state1.displayOrderDate && allColumns.push(<ExcelColumn key={idx++} label="Order_Date" value="orderCreateDate" />);
state1.displayPhysicianName && allColumns.push(<ExcelColumn key={idx++} label="Physician_Name" value="physicianName" />);
state1.displayPayer && allColumns.push(<ExcelColumn key={idx++} label="Payer" value="payorName" />);
state1.displayProductType && allColumns.push(<ExcelColumn key={idx++} label="Product_Type" value="productType" />);
state1.displayReferrerName && allColumns.push(<ExcelColumn key={idx++} label="Referrer_Name" value="referrerName" />);
state1.displayUSState && allColumns.push(<ExcelColumn key={idx++} label="US_State" value="claimantState" />);
state1.displayType && allColumns.push(<ExcelColumn key={idx++} label="Type" value="servType" />);
state1.displayVendorId && allColumns.push(<ExcelColumn key={idx++} label="displayVendorId" value="vendorId" />);
state1.displayVendorName && allColumns.push(<ExcelColumn key={idx++} label="Vendor_Name" value="vendorName" />);
state1.displayVendorCityState && allColumns.push(<ExcelColumn key={idx++} label="Vendor_City_State" value="vendorCityState" />);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label="Do_Business_As" value="vendorDBA"/>);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label=" Vendor_GP_Code" value="vendorGPCode" />);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label=" Vendor_TIN" value="vendorTIN" />);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label=" Vendor_Type" value="vendorType" />);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label=" Vendor_Active" value="vendorActive" />);
state1.displayVendorExtra && allColumns.push(<ExcelColumn key={idx++} label=" Vendor_Contracted" value="vendorContracted" />);
state1.displayPONumber && allColumns.push(<ExcelColumn key={idx++} label="PO_Number" value="chr_po_number" />);
state1.displayClientId && allColumns.push(<ExcelColumn key={idx++} label="Client_Id" value="clientID" />);
state1.displayClientName && allColumns.push(<ExcelColumn key={idx++} label="Client_Name" value="clientName" />);
state1.displayOutcome && allColumns.push(<ExcelColumn key={idx++} label="Outcome" value="orderStatus" />);
state1.displayReadyForBilling && allColumns.push(<ExcelColumn key={idx++} label="Ready_For_Billing" value="vendorContracted" />);
state1.displayCurrentlyAssignedTo &&
    allColumns.push(<ExcelColumn key={idx++} label="Currently_Assigned_To"
    value={(col) => `${col.assignedUserLastName},${col.assignedUserFirstName}`} />);
state1.displayYorkClientName && allColumns.push(<ExcelColumn key={idx++} label="York_Client_Name" value="yorkClientName" />);
state1.displayYorkEmployerName && allColumns.push(<ExcelColumn key={idx++} label="York_Employer_Name" value="displayYorkEmployerName" />);
state1.displayProgramName && allColumns.push(<ExcelColumn key={idx++} label="Program_Name" value="displayProgramName" />);
state1.displayServiceLocation && allColumns.push(<ExcelColumn key={idx++} label="Service_Location" value="serviceLocation" />);
state1.displayServiceHCPC && allColumns.push(<ExcelColumn key={idx++} label="Service_HCPC" value="hcpc" />);
state1.displayServiceRentals && allColumns.push(<ExcelColumn key={idx++} label="Service_Rentals" value="rental" />);
state1.displayServiceRentalStartDate && allColumns.push(<ExcelColumn key={idx++} label="Rental_Start_Date" value="rentalStartDate" />);
state1.displayServiceRentalEndDate && allColumns.push(<ExcelColumn key={idx++} label="Rental_EndDate" value="rentalEndDate" />);
state1.displayServiceRentalFreq && allColumns.push(<ExcelColumn key={idx++} label="Rental_Freq" value="rentalFreq" />);
state1.displayServiceRenewal && allColumns.push(<ExcelColumn key={idx++} label="Renewal" value="renewal" />);
state1.displayAccommodationCheckInDateTime && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Check_In_Date_Time" value="hotelCheckin" />);
state1.displayAccommodationCheckOutDateTime && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Check_Out_Date_Time" value="hotelCheckout" />);
state1.displayAccommodationNumberOfNights && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Number_Of_Nights" value="numberOfNights" />);
state1.displayAccommodationHotelAddress && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Hotel_Address" value="hotelAddress" />);
state1.displayPricing && allColumns.push(<ExcelColumn key={idx++} label="Total_Cost" value="totalCost" />);
state1.displayPricing && allColumns.push(<ExcelColumn key={idx++} label="Total_Billed_Charges" value="totalBilledCharges" />);
state1.displayPricing && allColumns.push(<ExcelColumn key={idx++} label="Order_Margin" value="margin" />);
state1.displayAccommodationNumberOfRooms && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Number_Of_Rooms" value="numberOfRooms" />);
state1.displayAccommodationDateTimeCreated && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Date_Time_Created" value="billedDate" />);
state1.displayAccommodationDateTimeBooked && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Date_Time_Booked" value="bookedDate" />);
state1.displayAccommodationBookingSite && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Booking_Site" value="bookingSite" />);
state1.displayAccommodationHotelContact && allColumns.push(<ExcelColumn key={idx++} label="Accommodation_Hotel_Contact" value="hotelContact" />);
state1.displayTransportPickUpAddress && allColumns.push(<ExcelColumn key={idx++} label="Transport_Pick_Up_Address" value="pickUpAddress" />);
state1.displayTransportDropOffAddress && allColumns.push(<ExcelColumn key={idx++} label="Transport_Drop_Off_Address" value="dropOffAddress" />);
state1.displayTransportPickUpTime && allColumns.push(<ExcelColumn key={idx++} label="Transport_Pick_Up_Time" value="transportationPickupDate" />);
state1.displayTransportDropOffTime && allColumns.push(<ExcelColumn key={idx++} label="Transport_Drop_Off_Time" value="transportationDropoffDate" />);
state1.displayTransportAppointmentType && allColumns.push(<ExcelColumn key={idx++} label="Transport_Appointment_Type" value="appointmentType" />);
state1.displayTransportMileage && allColumns.push(<ExcelColumn key={idx++} label="Transport_Mileage" value="mileage" />);
state1.displayTransportStaffedBy && allColumns.push(<ExcelColumn key={idx++} label="Transport_Staffed_By" value="TransportStaffedBy" />);
state1.displayTransportTimeStaffed && allColumns.push(<ExcelColumn key={idx++} label="Transport_Time_Staffed" value="TransportTimeStaffed" />);
state1.displayTransportNumberOfLegs && allColumns.push(<ExcelColumn key={idx++} label="Transport_Number_Of_Legs" value="int_leg_no" />);
state1.displayTransportReturnType && allColumns.push(<ExcelColumn key={idx++} label="Transport_Return_Type" value="transportationReturnType" />);
state1.displayTransportWaitingOnVendorConf && allColumns.push(<ExcelColumn key={idx++} label="Transport_Waiting_On_Vendor_Conf" value="waitingOnVendorConf" />);
state1.displayTransportDateTimeBooked && allColumns.push(<ExcelColumn key={idx++} label="Transport_Date_Time_Booked" value="bookedDate" />);
state1.displayTransportationPickUpAddress && allColumns.push(<ExcelColumn key={idx++} label="Pick_Up_Address" value="pickUpAddress" />);
state1.displayTransportationDropOffAddress && allColumns.push(<ExcelColumn key={idx++} label="Drop_Off_Address" value="dropOffAddress" />);
state1.displayTransportationPickUpTime && allColumns.push(<ExcelColumn key={idx++} label="Pick_Up_Time" value="transportationPickupDate" />);
state1.displayTransportationDropOffTime && allColumns.push(<ExcelColumn key={idx++} label="Drop_Off_Time" value="transportationDropoffDate" />);
state1.displayTransportationMileage && allColumns.push(<ExcelColumn key={idx++} label="Mileage" value="mileage" />);
state1.displayTransportationStaffedBy && allColumns.push(<ExcelColumn key={idx++} label="Staffed_By" value="TransportStaffedBy" />);
state1.displayTransportationTimeStaffed && allColumns.push(<ExcelColumn key={idx++} label="Time_Staffed" value="TransportTimeStaffed" />);
state1.displayTransportationNumberOfLegs && allColumns.push(<ExcelColumn key={idx++} label="Number_of_Legs" value="numberOfLegs" />);
state1.displayTransportationReturnType && allColumns.push(<ExcelColumn key={idx++} label="Return_Type" value="transportationReturnType" />);
state1.displayTransportationWaitingOnVendorConf && allColumns.push(<ExcelColumn key={idx++} label="Waiting_on_Vendor_Conf" value="waitingOnVendorConf" />);
state1.displayTransportationDateTimeBooked && allColumns.push(<ExcelColumn key={idx++} label="DateTime_Booked" value="bookedDate" />);
state1.displaySplTransportationDropOffAddress && allColumns.push(<ExcelColumn key={idx++} label="Drop_Off_Address" value="sptDropOffAddress" />);
state1.displaySplTransportationAppointmentType && allColumns.push(<ExcelColumn key={idx++} label="Appointment_Type" value="appointmentType_special_transp" />);
state1.displaySplTransportationDepartingDateTime && allColumns.push(<ExcelColumn key={idx++} label="Departing_DateTime" value="departingDateTime" />);
state1.displaySplTransportationArrivingDateTime && allColumns.push(<ExcelColumn key={idx++} label="Arriving_DateTime" value="arrivingDateTime" />);
state1.displaySplTransportationDateTimeCreated && allColumns.push(<ExcelColumn key={idx++} label="DateTime_Created" value="completedDate" />);
state1.displaySplTransportationDateTimeBooked && allColumns.push(<ExcelColumn key={idx++} label="DateTime_Booked" value="bookedDate" />);
state1.displaySplTransportationAirline && allColumns.push(<ExcelColumn key={idx++} label="Airline" value="airline" />);
state1.displaySplTransportationDepartingAirport && allColumns.push(<ExcelColumn key={idx++} label="Departing_Airport" value="departingAirport" />);
state1.displaySplTransportationArrivingAirport && allColumns.push(<ExcelColumn key={idx++} label="Arriving_Airport" value="arrivingAirport" />);
state1.displaySplTransportationFlightClass && allColumns.push(<ExcelColumn key={idx++} label="Flight_Class" value="flightClass" />);
state1.displaySplTransportationBookingSite && allColumns.push(<ExcelColumn key={idx++} label="Booking_Site" value="bookingSite" />);
state1.displaySplTransportationLayOverTime && allColumns.push(<ExcelColumn key={idx++} label="Lay_Over_Time" value="layOverTime" />);
state1.displaySplTransportDropOffAddress && allColumns.push(<ExcelColumn key={idx++} label="Spl_Transport_Drop_Off_Address" value="sptDropOffAddress" />);
state1.displayInterpretationAddress && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Address" value="interpretationAddress" />);
state1.displayInterpretationTime && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Time" value="timeDuration" />);
state1.displayInterpretationStartTime && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Start_Time" value="interpretationStartTime" />);
state1.displayInterpretationLanguage && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Language" value="languageName" />);
state1.displayInterpretationMileage && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Mileage" value="mileage" />);
state1.displayInterpretationTimeStaffed && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Time_Staffed" value="TimeStaffed" />);
state1.displayInterpretationStaffedBy && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_StaffedBy" value="StaffedBy" />);
state1.displayInterpretationArrivalTime && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_ArrivalTime" value="interpretationArrivalTime" />);
state1.displayInterpretationAppointmentType && allColumns.push(<ExcelColumn key={idx++} label="Interpretation_Appointment_Type" value="appointmentType_interpretation" />);
state1.displayHHCHCPC && allColumns.push(<ExcelColumn key={idx++} label="HCPC" value="hcpc" />);
state1.displayHHCServiceLocation && allColumns.push(<ExcelColumn key={idx++} label="Service_Location" value="serviceLocation" />);


    return (
        <ExcelFile element={
            <Button disabled={data && data.length === 0} type="button" style={{ width: '100%' }}>
                Export
            </Button>}
        >
            <ExcelSheet data={data} name="Report">
            {allColumns.map(el => el)}
        
            </ExcelSheet>
        </ExcelFile>

    )
}

export default ExcelExport;