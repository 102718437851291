import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faChartBar,
  faEnvelope,
  faFile,
  faUserTie,
  faHandsHelping,
  faBars,
  faArrowAltCircleLeft,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { AppModule } from 'common/store/propTypes';

import '../../assets/static/menu.css';

import ModuleDropdown from './ModuleDropdown';

library.add(
  faHome,
  faCalendarAlt,
  faChartBar,
  faEnvelope,
  faFile,
  faUserTie,
  faHandsHelping,
  faBars,
  faArrowAltCircleLeft,
  faTimes
);

export const FullMenu = props => (
  <div>
    <FontAwesomeIcon
      onClick={props.onClick}
      className="fab-btn close-menu-btn"
      icon={['fas', 'arrow-alt-circle-left']}
    />
    <Row>
      <Col align="center" className="page-name-section">
        <span className="page-name">Claims System</span>
      </Col>
    </Row>
    <Row>
      <Col align="center" className="bill-review-section">
        <ModuleDropdown
          activeModule={props.activeModule}
          switchModule={props.onSwitchModule}
          modules={props.modules}
        />
      </Col>
    </Row>
    <Row>
      <Col className="menu-items">{props.listMenuItems}</Col>
    </Row>
  </div>
)

export const SmallMenu = props => (
  <div>
    <Row>
      <Col align="center" className="page-name-section">
        <span>
          <FontAwesomeIcon
            onClick={props.onClick}
            className="fab-btn"
            icon={['fas', 'bars']}
          />
        </span>
      </Col>
    </Row>
    <Row>
      <Col className="menu-items">{props.listMenuItems}</Col>
    </Row>
  </div>
);

class SideNavMenu extends Component {
  render() {
    let hide = process.env.REACT_APP_SLP_BASE_URL == "https://slp.yorkrisk.com"
    ? true : false;
    const close = this.props.close;
    const currentModule = this.props.activeModule;
    const listMenuItems = [];
    /*currentModule.links.map((link, index) => (
      <NavLink
        className={close ? 'small-menu' : 'full-menu'}
        key={index}
        to={link.path}
        activeClassName="selected-item"
      >
        <FontAwesomeIcon icon={link.icon} />
        <span>{link.name}</span>
      </NavLink>
    ));*/
    return (
      <div
        id="leftSideNav"
        className="sidenav"
        style={close ? { width: '60px' } : { width: '200px' }}
      >
        {' '}
        {close  ? (
          <SmallMenu
            onClick={this.props.switchMenu}
            listMenuItems={listMenuItems}
            modules={this.props.modules}
          />
          ) : (
          <FullMenu
            onSwitchModule={this.props.switchModule}
            onClick={this.props.switchMenu}
            listMenuItems={listMenuItems}
            modules={this.props.modules}
            activeModule={this.props.activeModule}
          />
        )}
      </div>
    );
  }
}

SideNavMenu.propTypes = {
  activeModule: AppModule.isRequired,
  switchMenu: PropTypes.func.isRequired,
  switchModule: PropTypes.func.isRequired,
  close: PropTypes.bool,
  modules: PropTypes.arrayOf(AppModule),
};

export default withRouter(SideNavMenu);

