import React, { useState, useEffect } from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, CustomInput } from 'reactstrap';
import moment from 'moment';
import Fast360SaveTask from './Fast360SaveTask';
import Fast360Notes from './Fast360Notes';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import { shortToDatabaseTable } from '../utils/dataUtils'
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';


const TaskProgressModal = ({
  isOpen,
  onClose,
  currentTaskData,
  selectedTaskData,
  groupProgressData,
  currentTaskId,
  currentUserId,
  worklistData,
  selectedItem,
  currentOutcomeDesc,
  followupData,
  followupType,
  followupDate,
  followupReasonChange,
  handleRadioChange,
  handleDateChange,
  handleHrChange,
  handleMinChange,
  fHrs,
  fMin,
  timeZ,
  userId,
  saveFollowUp,
  logBreadCrumbs,
  lmsUserId,

}) => {
  const [rowInfo, setRowInfo] = useState({});
  const [enableCloseTask, setEnableClostTask] = useState(false);
  const [openTaskModal, setopenTaskModal] = useState(false);
  const [openSaveTaskModal, setopenSaveTaskModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [openNotes, setOpenNotes] = useState(false);
  const [isVendorRequiredModalOpen, setIsVendorRequiredModalOpen] = useState(false);

  const onRowClick = (item, index) => {
    if (currentTaskId === item.taskId) {
      if (currentTaskId > 4 && (selectedItem.vendor.id == undefined || selectedItem.vendor.id <= 0)) {
        setEnableClostTask(true);
      } else if (currentTaskId === 4 && (selectedItem.vendor.id == undefined || selectedItem.vendor.id <= 0)) {
        setEnableClostTask(false);
      } else {
        setEnableClostTask(false);
      }
    } else {
      setEnableClostTask(true);
    }
    item.followUpDate = moment(item.reminderDate).format('MM/DD/YYYY');
    if (item.reminderDate === undefined) {
      item.ooc = ''
    } else {
      item.ooc = moment(item.dueDate).format('MM/DD/YYYY h:mm a');
    }
    item.fuhour = moment(item.reminderDate).format('h');
    item.fuminute = moment(item.reminderDate).format('mm');
    item.fuampm = moment(item.reminderDate).format('a');
    if (item.dateCompleted === undefined) {
      item.compdate = ''
    } else {
      item.compdate = moment(item.dateCompleted).format('MM/DD/YYYY h:mm a');
    }
    setRowInfo(item);
  }

  const getTableFromMod = (serviceAbrv) => {
    if (serviceAbrv === "HHC") {
      return "hhc";
    } else if (serviceAbrv === "AUD") {
      return "audiology";
    } else if (serviceAbrv === "DTL") {
      return "dental";
    } else if (serviceAbrv === "DGN") {
      return "diagnostics";
    } else if (serviceAbrv === "MOD") {
      return "modifications";
    } else if (serviceAbrv === "MSE") {
      return "mse";
    } else if (serviceAbrv === "TRN") {
      return "transportation";
    } else if (serviceAbrv === "INT") {
      return "interpretation";
    } else if (serviceAbrv === "SPT") {
      return "special_transp";
    } else if (serviceAbrv === "ACC") {
      return "accommodation";
    }
    return "";
  };

  const saveFollowupCall = () => {
    let fHr = fHrs;
    fHr = timeZ == 'PM' && Number(fHr) !== 12 ? Number(fHr) + 12 : fHr;
    if (timeZ == 'AM' && Number(fHr) === 12) {
      fHr = 0;
    }
    let hrs = Number(fHr) > 9 ? fHr : `0${fHr}`;
    let min = Number(fMin) > 9 ? fMin : `0${fMin}`;
    let timeStamp = `${hrs}:${min}:00.0`;
    let saveFollowupPayload = {
      functionName: 'saveFollowup',
      zebraUserId: currentUserId,
      serviceId: selectedItem.serviceId,
      table: getTableFromMod(selectedItem.module) ? getTableFromMod(selectedItem.module).toLowerCase() : '',
      followupDate: `${moment(followupDate).format('YYYY-MM-DD')} ${timeStamp}`,
      // "followupDate": followupData.followupDate,
      followupType: followupType
        ? followupType
        : '',
      servAbbrv: selectedItem.module,
      lmsUserId: lmsUserId,
    };
    saveFollowUp(saveFollowupPayload);
    let breadCrumb = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": currentUserId,
      "userid": currentUserId,
      "breadcrumbkey": 'FOLLOWUPDATECHANGE',
      "refTableId": selectedItem.serviceId,
      "refId": selectedItem.referralId,
      "refTable": selectedItem.module,
    };
    logBreadCrumbs(breadCrumb)
    let breadCrumb2 = {
      "functionName": 'logBreadCrumbs',
      "zebraUserId": currentUserId,
      "userid": currentUserId,
      "breadcrumbkey": 'FOLLOWUPCHANGE',
      "refTableId": selectedItem.serviceId,
      "refId": selectedItem.referralId,
      "refTable": selectedItem.module,
    };
    logBreadCrumbs(breadCrumb2)

    closeNoAdvance();
  }

  const getCompDate = item => {
    let compDate
    if (item.dateCompleted === undefined) {
      compDate = ''
    } else {
      compDate = moment(item.dateCompleted).format('MM/DD/YYYY h:mm a');
    }
    return (
      <>
        {compDate}
      </>
    )
  }


  const openSaveTaskProgress = () => {
    if (currentTaskId === 4 && (selectedItem.vendor.id == undefined || selectedItem.vendor.id <= 0)) {
      vendorRequiredToggle();
    } else if (currentTaskId < 10) {
      setopenSaveTaskModal(!openSaveTaskModal);
    } else if (currentTaskId === 10 && (selectedItem.outcomeId == undefined || selectedItem.outcomeId <= 39)) {
      alert('Please select an outcome before proceeding');
    }
  };

  const openTaskProgress = () => {
    if (openSaveTaskModal) {
      setopenSaveTaskModal(!openSaveTaskModal);
      onClose();
    }
    setopenTaskModal(!openTaskModal);
    // onClose();
  };

  const closeNoAdvance = () => {
    setopenTaskModal(!openTaskModal);
    // onClose();
  };

  const openNotesModal = () => {

    setOpenNotes(!openNotes);
  }

  useEffect(() => {
    if (isOpen) {
      onRowClick(selectedTaskData, currentTaskId - 1);
      setopenTaskModal(true);
    } else {
      setopenTaskModal(false);
    }
  }, [isOpen]);

  const vendorRequiredToggle = () => {
    setIsVendorRequiredModalOpen(!isVendorRequiredModalOpen);
  };

  const getVendorRequiredFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={vendorRequiredToggle}>
          OK
        </Button>
      </>
    );
  };

  const getVendorRequiredBody = () => {
    return (
      <div className="text-center">
        Must have vendor assigned to progress task beyond this point.
      </div>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={openTaskModal}>
        <ModalHeader>Edit Task</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <AppTable modalHeight={250}
                size="sm"
                resizable
                columns={[
                  {
                    header: '',
                    field: 'taskId',
                    width: '0'

                  },
                  {
                    header: 'Task Name',
                    field: 'fulldescription',
                    width: '35'
                  },
                  {
                    header: 'Completed Date',
                    field: 'dateCompleted',
                    render: item => getCompDate(item),
                    percent: '20',
                    size: 'sm',
                  },
                  {
                    header: 'Completed By',
                    field: 'completedByUser',
                    percent: '20',
                    size: 'sm',

                  },


                ]}
                data={currentTaskData}
                sortAble={true}
                sort={2}
                highlightedRow={currentTaskId - 1}
                sortImmediately={true}
                rowClickHandler={onRowClick}
                resizable={true}
              />
            </Col >
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>Current Milestone: </Label>
            </Col>
            <Col xs="5">
              <Input
                id='MileStone'
                value={rowInfo.mileStoneName || ''}
              />{' '}
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>Task Name: </Label>
            </Col>
            <Col xs="5">
              <Input
                id='taskName'
                value={rowInfo.fulldescription || ''}
              />{' '}
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>Follow Up: </Label>
            </Col>
            <Col xs="3">
              <AppDateInput
                id="FollowupDate"
                onChange={date => handleDateChange(date)}
                selected={followupDate
                  ? followupDate
                  : new Date()}
              //minDate={moment().toDate()}
              />
            </Col>
            <Col sm="2">
              <Input
                type="number"
                // min={min}
                // max={max}
                min={1}
                max={12}
                value={Number(fHrs)}
                onChange={(e) => handleHrChange(e)}
              >
              </Input>{' '}
            </Col>
            <Col sm="2">
              <Input
                type="number"
                min={0}
                max={59}
                value={Number(fMin)}
                onChange={(e) => handleMinChange(e)}
              />{' '}
            </Col>
            <FormGroup className="form-check-inline">
              <Label check>
                <Input type="radio" name="radio2" checked={timeZ === 'AM' ? true : false}
                  onChange={() => handleRadioChange('AM')} />  AM </Label>
            </FormGroup>
            <FormGroup className="form-check-inline">
              <Label check>
                <Input type="radio" name="radio2" checked={timeZ === 'PM' ? true : false}
                  onChange={() => handleRadioChange('PM')} /> PM
              </Label>

            </FormGroup>

          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>Follow Up Reason: </Label>
            </Col>
            <Col xs="5">
              <Input
                type="select"
                defaultValue=""
                placeholder="Select a Followup reason"
                value={followupType ? followupType : ''}
                onChange={e => followupReasonChange(e.target.value)}
              >
                <option value="" disabled={true}>Select a Followup reason</option>

                {followupData && followupData.followupReasons &&
                  followupData.followupReasons.map((item, index) => (
                    <option key={index} value={item.chr_description}>{item.chr_description}</option>
                  ))}

              </Input>{' '}
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>OOC: </Label>
            </Col>
            <Col xs="3">
              <Label>{rowInfo.ooc}</Label>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" className="text-right">
              <Label>Completed: </Label>
            </Col>
            <Col xs="3">
              <Label>{rowInfo.compdate}</Label>
            </Col>
          </Row>
          <Form>

            <div style={{ float: 'right' }}>
              <Button style={{ width: '179px' }}
                type="button"
                disabled={enableCloseTask}
                onClick={openSaveTaskProgress}
              >
                Mark As Complete
              </Button>

            </div>
            <br></br>
            <br></br>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                onClick={openNotesModal}
              >
                Notes
              </Button>
              <Button style={{ marginLeft: 5 }}
                type="button"
                onClick={closeNoAdvance}
              >
                Close
              </Button>
              <Button style={{ marginLeft: 5 }}
                type="button"
                onClick={saveFollowupCall}
              >
                Save
              </Button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      <Fast360SaveTask
        isOpen={openSaveTaskModal}
        onClose={openTaskProgress}
        groupProgressData={groupProgressData}
        services={selectedServices}
        currentTaskData={selectedTaskData}
        currentTaskId={currentTaskId}
        currentUserId={currentUserId}
        worklistData={worklistData}
      //selectedTaskData = {currentTaskData[currentTaskId - 1]}
      />
      {openNotes && (
        <Fast360Notes
          clicked={openNotesModal}
          refId={selectedItem?.referralId ? selectedItem.referralId : 0}
          tableName={selectedItem?.module ? selectedItem.module : ''}
          serviceId={selectedItem?.serviceId ? selectedItem.serviceId : 0}
          table={selectedItem?.module ? shortToDatabaseTable(selectedItem.module) : ''}
          dataObject={selectedItem ? selectedItem : {}}
          zebraId={currentUserId}
          currentOutcomeDesc={currentOutcomeDesc}
          worklistData={worklistData}
        />
      )}
      <PopupModal
        content={getVendorRequiredBody()}
        title="Vendor Required"
        externalToggle={vendorRequiredToggle}
        isOpen={isVendorRequiredModalOpen}
        footerContent={getVendorRequiredFooter()}
        size="md"
      />
    </>
  );
};


const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  return {

  }
}

const mapDispatchToProps = dispatch => ({
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
  saveFollowUp: data => dispatch(fast360Operations.saveFollowUp(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskProgressModal);