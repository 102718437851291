import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  formatDateWithAmPmSuffix,
  getTimezoneUTC,
  getCurrentTimeByUTCOffset,
  getTimezoneAcronym,
} from '../utils/expertUtils';

export default class LocalTimeClockRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      currentTime: '',
      timezone: '',
      dayLightSavings: false,
      UTCOffset: 0,
      timezoneAcronym: '',
    };
  }

  componentDidMount() {
    if (this.props.item) {
      this.startClock();
    }
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.state.intervalId !== null) {
      clearInterval(this.state.intervalId);
    }
  }

  startClock() {
    const timezone = this.props.item.timezone || 'GMT';
    const dayLightSavings = this.props.item.daylightsavings || false;
    const UTCOffset = getTimezoneUTC(timezone, dayLightSavings);
    const timezoneAcronym = getTimezoneAcronym(timezone, dayLightSavings);
    const currentTime = formatDateWithAmPmSuffix(
      getCurrentTimeByUTCOffset(this.state.UTCOffset)
    );
    this.setState({
      timezone,
      UTCOffset,
      dayLightSavings,
      timezoneAcronym,
      currentTime,
      intervalId: setInterval(() => this.timer(), 1000),
    });
  }

  timer() {
    const currentTime = formatDateWithAmPmSuffix(
      getCurrentTimeByUTCOffset(this.state.UTCOffset)
    );
    this.setState({ currentTime });
  }

  render() {
    const { currentTime, timezone, timezoneAcronym } = this.state;
    return (
      <>
        <div>{currentTime}</div>
        <div className="grey-text italic-text">
          {timezone} ({timezoneAcronym})
        </div>
      </>
    );
  }
}

LocalTimeClockRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};
