import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Button, ModalFooter, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';

class Fast360EmailAuthDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };
    }

    componentDidMount() {
        // Load Auth dates?
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleComplete = () => {
        this.props.onSubmit(this.state.startDate, this.state.endDate);
    }

    setStart = (date) => {
        this.setState({ startDate : date });
    }

    setEnd = (date) => {
        if (date < this.state.startDate) {
            this.props.alert("End Date must be equal to or greater than Start Date");
        } else {
            this.setState({ endDate : date });
        }
    }

    render() {
        return (
            <Modal size="lg" isOpen={true} style={{ height: '300px' }}>
                <ModalHeader toggle={this.handleCancel}> HHC Authorization Dates </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col>
                                <DatePicker
                                selected={this.state.startDate}
                                onChange={this.setStart}
                                locale="en"
                                inline
                                />
                                </Col>
                                <Col>
                                <DatePicker
                                selected={this.state.endDate}
                                onChange={this.setEnd}
                                locale="en"
                                inline
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                    {this.state.startDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-center">
                                    {this.state.endDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}
                                    </div>                                
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ width: '120px' }} color="primary" onClick={this.handleCancel} >CANCEL</Button>
                    <Button style={{ width: '120px' }} color="primary" onClick={this.handleComplete} >COMPLETE</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    const user = authSelectors.getUser(state);
    return {
        userId: user && user.user_id,
    };
};

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360EmailAuthDateModal);