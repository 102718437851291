import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap';

const ExpertBiography = props => {
  // TODO must print pdf from server
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col lg="2">
            <div>Biography</div>
          </Col>
          <Col lg={{ size: '2', offset: '8' }}>
            <Button type="button" style={{ width: '100%' }}>
              Print Biography Form
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>{props.expert.expert.biography || ''}</div>
      </CardBody>
    </Card>
  );
};

ExpertBiography.propTypes = {
  expert: PropTypes.shape({
    expert: PropTypes.object.isRequired,
  }).isRequired,
};

export default ExpertBiography;
