const create = (type, payload) => {
  if (!payload) {
    return {
      type: type,
    };
  }

  return {
    type: type,
    payload,
  };
};

export default { create };
