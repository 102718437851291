import 'core-js/stable';
import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux'
import AppTableHeader from 'shared/components/table/AppTableHeader';
import PropTypes from "prop-types";
import { useTable, useExpanded } from 'react-table'
import '../assets/static/Dashboard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { fast360Selectors, fast360Operations, fast360Actions } from '../store/index';

import services from '../services/service';
import Row from '../../../../node_modules/react-bootstrap/esm/Row';

class DashboardWIPTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          users: props.user,
          filterData:props.filterData
        };
        this.handleUserChange = this.handleUserChange.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState) {
    }

    sort = column => {
        this.setState({
            sortImmediately: true,
        });

        if (this.state.sortBy !== column.field) {
            this.setState({
                sortBy: column.field,
            });
            return;
        }

        const sortColumns = this.state.sortColumns.map(item => {
            return item.field === column.field
                ? {
                    ...item,
                    sortOrder: !item.sortOrder,
                }
                : { ...item };
        });
        this.setState({
            sortColumns,
        });
    };
    clickTableRow(row) {
      const user = row && row.values;
      const zebraId = user && this.state.users.find(u => u.id === user.original.PK_user_id);

      if(zebraId !== null) this.props.getUsersWorkList(user, zebraId);
    }
    handleUserChange = row => {
      debugger;
      if (row.original.PK_task_id == undefined && row.original.subRows != undefined){
        const user =
          this.state.users &&
          this.state.users.find(u => u.id == row.original.PK_user_id);
      // this.props.updatePreviousUser(this.state.userSelected);
        this.setState({
          userSelected: user,
          userId: row.original.PK_user_id,
          billingDate: '',
          filterData: '',
          productSelected: '',
          subProductSelected: '',
          subProduct: [],
        });
        this.props.setSelectedUser(user.zebraId);
        this.props.getUsersWorkList(user, user.zebraId);
        this.props.updateClickRecord();
        this.props.updateStatusClicked(false);
        this.props.updateMilestoneClicked(false);
        this.props.updateTaskClicked(false);
        this.props.updateMilestoneData([]);
      }else if (row.original.PK_task_id == undefined && row.original.subRows == undefined){
        //open referral here
        if (row.original.assigned.includes('-')){
          
          const values = row.original.assigned.split('-')
          this.props.setFilterData(row.original.assigned);
          let quickSearchPayload = {
            "functionName": "quickSearchCheck",
            "zebraUserId": this.props.userId,
            "referralId": values[0],
            "serviceType": values[1],
            "serviceId": values[2],
          }
          this.props.runQuickSearchCheck(quickSearchPayload);
        }
      }else{
        const promise = services.loadUserWIPTaskData(row.original.PK_task_id, row.original.chr_reference_table, 2591);
        Promise.all([promise]).then(([taskData]) => {
          const sortByKey = key => (a, b) => a[key] > b[key] ? 1 : -1
          const CAdata = taskData.reduce((users, {fast360User, username, onTime, uname, late, confId}) => {
            const user = users && users[fast360User] || {
              PK_user_id: fast360User,
              assigned: username,
              onTime: 0,
              ooc: 0,
              total: 0,
              subRows: [],
              zebraId: fast360User
            };
            user.subRows.push({assigned: confId, onTime, ooc: late, total: 1, subRows: undefined, zebraId:fast360User, PK_user_id:fast360User});
            user.onTime += onTime;
            user.zebraId = fast360User;
            user.ooc += late;
            user.total++;
            users[fast360User] = user;
            return users;
          }, []).flat().flat().slice().sort(sortByKey('assigned'));;
          
         
          this.props.setTaskData(CAdata);
        })

       

      }
    };
  
    
   
    render() {
      let tempColumns = [
        {
          // Build our expander column
          id: 'expander', // Make sure it has an ID
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? <FontAwesomeIcon icon={faFolderOpen} /> : <FontAwesomeIcon icon={faFolder} />}
            </span>
          ),
          Cell: ({ row }) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: { paddingLeft: `${row.depth * 2}rem` },
                })}
              >
                {row.isExpanded ? <FontAwesomeIcon icon={faFolderOpen} /> : <FontAwesomeIcon icon={faFolder} />}
              </span>
            ) : null,
        }
      ];
      
      Array.prototype.push.apply(tempColumns, this.props.columns);

      return (
        <>
          <div className="WIPTable">
            <Table sortAble={true} columns={tempColumns} data={this.props.data} updateField={this.props.updateField} updateTable={this.handleUserChange} sort={this.sort}/>
          </div>            
        </>
        );
    }
}

function Table({ columns: userColumns, data, updateTable, updateField, sort }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  )
  


  return (
    <>
      <table {...getTableProps()} className='table table-striped'>

        <AppTableHeader
            columns={userColumns}
           // sortAble={true}
         //   sortImmediately={false}
           // resizable={false}
          //  sort={sort}
            
        />
       
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {   
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onDoubleClick={(e) => {updateTable(row)}}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{row.depth > 0 ? <div className="indent">{cell.render('Cell')}</div>:cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

DashboardWIPTable.propTypes = {
    size: PropTypes.string,
    striped: PropTypes.bool,
    hover: PropTypes.bool,
    clickTableRow: PropTypes.func,
    updateField: PropTypes.string
};

DashboardWIPTable.defaultProps = {
    size: 'sm',
    striped: true,
    howver: true
}

const mapStateToProps = state => {
  const fast360Info = state.FAST360.initialData.roleUsers.users;
  return ({
    fast360Users: state.FAST360.initialData.roleUsers.users,
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
    userId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    quickSearchCheck: state.FAST360.fast360Reducer.quickSearchCheck ? state.FAST360.fast360Reducer.quickSearchCheck : {},
  });
}

const mapDispatchToProps = dispatch => ({
  getUsersWorkList: (data, zebraId) => fast360Operations.getUsersWorkList(data, zebraId),
  updateNewUsersWorkList: data =>
    dispatch(fast360Actions.updateNewUsersWorkList(data)),
  getUsersWorkList: (data, currentUserId) =>
    dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
  updateClickRecord: () => dispatch(fast360Actions.updateClickRecord([])),
  updatePreviousUser: data => dispatch(fast360Actions.updatePreviousUser(data)),
  updateStatusClicked: data =>
    dispatch(fast360Actions.updateStatusClicked(data)),
  updateMilestoneClicked: data =>
    dispatch(fast360Actions.updateMilestoneClicked(data)),
  updateTaskClicked: data => dispatch(fast360Actions.updateTaskClicked(data)),
  updateMilestoneData: data =>
    dispatch(fast360Actions.updateMilestoneData(data)),
  runQuickSearchCheck: (data) => dispatch(fast360Operations.quickSearchCheck(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWIPTable);
