import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from 'reactstrap';

import UserAccessTable from 'shared/components/table/UserAccessTable';
import ListCheckBoxesPanel from 'shared/components/ListCheckBoxesPanel';

import '../assets/static/adminmodule-user.scss';

import actions from '../store/actions';
import operations from '../store/operations';
import {
  ACCESS_MODULE,
  DATA_ACCESS_LEVEL,
  DATA_TABLE,
} from '../store/constants';

const columns = [
  {
    header: 'Module Name',
    field: 'moduleName',
  },
  {
    header: 'Allow Access',
    field: 'allowAccess',
    id: 'id',
  },
  {
    header: 'Default',
    field: 'default',
    id: 'id',
  },
];

const UserAccessContent = props => (
  <Card body>
    <Row>
      <Col lg="2">
        <FormGroup>
          <Label for="username">
            Username <span style={{ color: 'red' }}>(*)</span>
          </Label>
          <Input
            disabled={!props.disableHandler}
            type="text"
            name="userName"
            id="userName"
            onChange={props.handleInputChange}
            value={props.userName}
          />
        </FormGroup>
      </Col>
      <Col lg="2" className="align-balance-without-label">
        <Button
          color="primary"
          onClick={props.resetPassword}
          disabled={props.disableHandler}
        >
          RESET PASSWORD
        </Button>
      </Col>
      <Col lg="3" className="align-balance-without-label">
        <Button
          color="primary"
          onClick={props.resendPassword}
          disabled={props.disableHandler}
        >
          RESEND INTRO EMAIL
        </Button>
      </Col>
      <Col lg="2" className="align-balance-without-label">
        <Button
          color="primary"
          onClick={props.clearSecurityQuestion}
          disabled={props.disableHandler}
        >
          RESET QUESTIONS
        </Button>
      </Col>
      <Col lg="3" sm="12" className="align-balance-without-label">
        <CustomInput
          type="checkbox"
          label="If Checked, User is disabled"
          id="userDisabled"
          name="userDisabled"
          checked={props.userDisabled}
          onChange={props.handleInputChange}
        />
      </Col>
    </Row>
    <Row>
      <Col lg="8">
        <FormGroup>
          <Label for="role">
            Roles <span style={{ color: 'red' }}>(*)</span>
          </Label>
          <Input
            type="select"
            name="roleSelect"
            id="roleSelect"
            value={props.roleSelect}
            onChange={props.handleInputChange}
            className="dropdown"
          >
            <option value="default">Choose Role</option>
            {props.roles.map((role, index) => (
              <option key={index} value={role.id}>
                {role.roleName}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col lg="8">
        <UserAccessTable
          columns={props.columns}
          data={props.dataTable}
          caption={
            <span style={{ color: 'red' }}>
              (*) - At least one module is required
            </span>
          }
          updateDataTable={props.updateDataTable}
        />
      </Col>
      <Col lg="4">
        <ListCheckBoxesPanel
          handleInputChange={props.updateDataAccessLevel}
          data={props.dataAccessLevel}
          panelId={0}
        />
      </Col>
    </Row>
  </Card>
);

class UserAccess extends Component {
  onResetPassword = () => {
    this.props.onResetPassword(this.props.userId);
  };

  onResendPassword = () => {
    this.props.onResendPassword(this.props.userId);
  };

  onClearSecurityQuestion = () => {
    this.props.onClearSecurityQuestion(this.props.userId);
  };

  isNewUser = () => {
    return `${this.props.userId}` === '-1';
  };

  render() {
    return (
      <UserAccessContent
        columns={columns}
        dataTable={this.props.dataTable}
        userName={this.props.userName}
        roleSelect={this.props.roleSelect}
        dataAccessLevel={this.props.dataAccessLevel}
        handleInputChange={this.props.onUpdateInput}
        updateDataAccessLevel={this.props.onUpdateDataAccessLevel}
        updateDataTable={this.props.onUpdateDataTable}
        userDisabled={this.props.userDisabled}
        roles={this.props.roles}
        resetPassword={this.onResetPassword}
        resendPassword={this.onResendPassword}
        clearSecurityQuestion={this.onClearSecurityQuestion}
        disableHandler={this.isNewUser()}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.ADMIN.user.userId,
    userName: state.ADMIN.user.userAccess.userName,
    userDisabled: state.ADMIN.user.userAccess.userDisabled,
    roleSelect: state.ADMIN.user.userAccess.roleSelect,
    dataTable: state.ADMIN.user.userAccess.dataTable,
    dataAccessLevel: state.ADMIN.user.userAccess.dataAccessLevel,
    roles: state.ADMIN.user.userAccess.roles,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateDataTable: event =>
    dispatch(actions.updateObjectArray(event, ACCESS_MODULE, DATA_TABLE)),
  onUpdateDataAccessLevel: event =>
    dispatch(
      actions.updateObjectArray(event, ACCESS_MODULE, DATA_ACCESS_LEVEL)
    ),
  onUpdateInput: event => dispatch(actions.updateInput(event, ACCESS_MODULE)),
  onResetPassword: userId => dispatch(operations.resetPassword(userId)),
  onResendPassword: userId => dispatch(operations.resendPassword(userId)),
  onClearSecurityQuestion: userId =>
    dispatch(operations.clearSecurityQuestion(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
