import React from 'react';
import {
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
} from 'reactstrap';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import DashboardButton from './DashboardButton';
import StatusHover360 from './StatusHover360';
import MilestoneHover360 from './MilestoneHover360';
import TaskHover360 from './TaskHover360';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppDateInput from 'shared/components/input/AppDateInput';
import moment from 'moment';
import TrasnserFast360 from './TrasnserFast360';
import { commonOperations, commonSelectors } from 'common/store';
import QuickSearch from './QuickSearch';
import { tableNameToShort } from 'modules/fast-360/utils/dataUtils';

class Fast360TopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusClicked: false,
      milestoneClicked: false,
      taskClicked: false,
      subProduct: [],
      userSelected: this.props.selectedUser ? (this.props.selectedUser.zebraId || this.props.selectedUser) : this.props.currentUserId,
      users: [],
      billingDate: '',
      filterData: '',
      isOpen: false,
      productSelected: '',
      subProductSelected: '',
      isOpenQuickSearchModal: false,
      invoiceCoordinatorOpen: false,
      restoreFilter: false,
      backupFilterData: {},
    };
  }
  componentDidMount() {
    const value = this.props.selectedProduct;

    if (value == 'Accommodation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .AccommodationType,
      });
    } else if (value == 'Diagnostic') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList.DiagnosticType,
      });
    } else if (value == 'HHC') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList.HHCCategory,
      });
    } else if (value == 'Interpretation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .TranslationCategory,
      });
    } else if (value == 'Modification') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .ModificationCategory,
      });
    } else if (value == 'MSE') {
      let MSE = [];
      this.props.dataFromReducer.gpIntegrationList.MSECategory.map(item => {
        MSE.push({
          id: item.PK_listelement_id,
          value: item.value,
          product: item.product,
        });
      });
      this.setState({ subProduct: MSE });
    } else if (value == 'Transportation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .TransportCategory,
      });
    } else if (value == 'Special Transportation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .SpecialTransportCategory,
      });

    } else this.setState({ subProduct: [] });

    if (this.props.isLogout) {
      this.setState({
        statusClicked: false,
        milestoneClicked: false,
        taskClicked: false,
        subProduct: [],
        userSelected: this.props.selectedUser ? this.props.selectedUser.zebraId : this.props.currentUserId,
        users: [],
        billingDate: '',
        filterData: '',
        isOpen: false,
        productSelected: '',
        subProductSelected: '',
        isOpenQuickSearchModal: false
      })
    }
    else
      this.setState({ productSelected: value, subProductSelected: this.props.selectedSubProduct, filterData: this.props.inputField });




  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.appLoading.loading !== this.props.appLoading.loading && this.props.dataFromReducer && this.props.appLoading.loading === false) {
      if (this.state.filterData !== '') {
        this.handleFilter({ target: { value: this.state.filterData } });
      }

    }



    if (prevProps.quickSearchCheck !== this.props.quickSearchCheck) {
      // We've got response from quickSearchCheck
      if (this.props.quickSearchCheck.id != null) {
        //let values = this.state.quickSearchValue.split("-");
        let values = this.state.filterData.split("-");
        this.props.openReferral(values[0], values[1], values[2], this.props.quickSearchCheck.taskId, this.props.quickSearchCheck.claimNo, false);
      } else {
        this.props.alert("Conf # entered could not be found. Please try again");
      }
    }

    //  updating record only after  referral is being transfered sucessfully and after getting a response from server
    if (this.props.referralTransfered && prevProps.referralTransfered !== this.props.referralTransfered) {
      this.updateRecord();
      this.restoreFilterData();
      this.state.restoreFilter = true;
    }
    if ((prevProps.usersTotList !== this.props.usersTotList) ||
      (prevProps.usersOOCList !== this.props.usersOOCList) ||
      (prevProps.usersRushList !== this.props.usersRushList) ||
      (prevProps.usersNewList !== this.props.usersNewList) ||
      (prevProps.usersFollowUpList !== this.props.usersFollowUpList)) {
      this.state.userSelected = this.props.currentUserId;
    }
    if (prevProps.appLoading.loading !== this.props.appLoading.loading &&
      this.props.appLoading.loading == false &&
      this.props.dataFromReducer.backupWorkList &&
      this.props.dataFromReducer.backupWorkList.length > 0
    ) {
      if (!this.props.isLogout)
        this.restoreServiceFilter();
      else {
        this.setState({
          statusClicked: false,
          milestoneClicked: false,
          taskClicked: false,
          subProduct: [],
          userSelected: this.props.selectedUser ? this.props.selectedUser.zebraId : this.props.currentUserId,
          users: [],
          billingDate: '',
          filterData: '',
          isOpen: false,
          productSelected: '',
          subProductSelected: '',
          isOpenQuickSearchModal: false
        });
        this.props.updateIsLogout(false);
      }
    }
  }

  restoreServiceFilter = () => {
    // this.filterStatus();
    // this.filterMilestones();
    // this.filterTasks();
    
    if(this.state.restoreFilter){
      this.props.setTaskBackUp(this.props.dataFromReducer.initialWorkList);
      this.props.setMilestoneBackUp(this.props.dataFromReducer.initialWorkList);
      this.props.setStatusBackup(this.props.dataFromReducer.initialWorkList);
    }

    if (this.props.selectedFilterType !== '') {
      if (this.props.selectedFilterType === 'task') {
        this.filterTasks();
      } else if (this.props.selectedFilterType === 'mileStone') {
        this.filterMilestones();
      } else if (this.props.selectedFilterType === 'status') {
        this.filterStatus();
      }
    }



    if (this.props.lastServiceModule && this.props.lastServiceModule !== '') {

      this.setState({ productSelected: this.props.lastServiceModule }, () => {
        const filterData = this.handleProductChange({ target: { value: this.props.lastServiceModule } });
        // const filterData = this.productHelper({ target: { value: this.props.lastServiceModule } });
        if (this.props.lastSubServiceModule && this.props.lastSubServiceModule !== '') {
          this.handleSubProductChange({ target: { value: this.props.lastSubServiceModule, filteredProductData: filterData } });
        }
      })
    }

    setTimeout(() => {

      if (this.props.inputField && this.props.inputField !== '') {
        this.handleFilter({ target: { value: this.props.inputField } });
      }
    }, 100)

  }

  filterStatus = () => {
    if (this.props.statusData.length === 0) return;
    let selected = [], selectedWorkList = [], allChecked = false;
    let selectedItems = this.props.statusData.filter(i => i.isChecked == true);
    selectedItems.map(i => i.name && selected.push(i.name.toLowerCase().replace(/ /g, '')));

    allChecked = this.props.statusData.every(item => item.isChecked);
    if (allChecked) return;
    if(this.state.restoreFilter){
      this.props.dataFromReducer.initialWorkList.map(i => i.servStatus
        ? selected.includes(i.servStatus.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i)
        : i.servStatus ? selectedWorkList.push(i) : allChecked ? selectedWorkList.push(i) : null);
      }else{
        this.props.statusBackup.map(i => i.servStatus
          ? selected.includes(i.servStatus.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i)
          : i.servStatus ? selectedWorkList.push(i) : allChecked ? selectedWorkList.push(i) : null);
      }
    this.props.updateNewUsersWorkList(selectedWorkList);
    //this.props.setProductBackUp(selectedWorkList);

  }

  filterMilestones = () => {
    if (this.props.milestoneData.length === 0) return;
    let selected = [], selectedWorkList = [];
    const allChecked = this.props.milestoneData.every(item => item.isChecked);
    if (allChecked) return;
    let selectedItems = this.props.milestoneData.filter(i => i.isChecked == true);
    selectedItems.map(i => selected.push(i.name.toLowerCase().replace(/ /g, '')));
    if(this.state.restoreFilter){
      this.props.dataFromReducer.initialWorkList.map(i => selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
    }else{
      this.props.mileStoneBackUp.map(i => selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
    }
    this.props.updateNewUsersWorkList(selectedWorkList);
    //this.props.setProductBackUp(selectedWorkList);

  }

  filterTasks = () => {
    if (this.props.taskData.length === 0) return;
    let selected = [], selectedWorkList = [];
    const allChecked = this.props.taskData.every(item => item.isChecked);
    if (allChecked) return;
    let selectedItems = this.props.taskData.filter(i => i.isChecked == true);
    selectedItems.map(i => selected.push(i.chr_name.toLowerCase().replace(/ /g, '')));

    if(this.state.restoreFilter){
      this.props.dataFromReducer.initialWorkList.map(i => selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) === true && selectedWorkList.push(i));
    }else{
      this.props.taskBackUp.map(i => selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) === true && selectedWorkList.push(i));
    }
    this.props.updateNewUsersWorkList(selectedWorkList);
    //this.props.setProductBackUp(selectedWorkList);
  }

  // componentDidMount() {
  //   this.setState({ filterData: this.props.inputField });
  //   //this.restoreServiceFilter();
  // }



  componentWillReceiveProps(nextProps) {

    if (
      nextProps.dataFromReducer !== this.props.dataFromReducer &&
      nextProps.dataFromReducer.roleUsers
    ) {
      if (
        nextProps.dataFromReducer.roleUsers &&
        nextProps.dataFromReducer.roleUsers.users
      ) {
        let users = nextProps.dataFromReducer.roleUsers.users;
        if (this.state.users.length > 1 && this.state.users[0].id !== -1) {
          users.unshift(
            {
              zebraId: 1111,
              username: 'Unbilled/Unassigned',
              id: -1,
            },
            {
              zebraId: 2222,
              id: 0,
              username: 'Unassigned',
            }
          );
        }
        this.setState({ users: users });

      }
    }

    // if (!nextProps.isLogout && this.props.isLogout !== nextProps.isLogout) {
    //   this.setState({
    //     statusClicked: false,
    //     milestoneClicked: false,
    //     taskClicked: false,
    //     subProduct: [],
    //     userSelected: this.props.selectedUser ? this.props.selectedUser.zebraId : this.props.currentUserId,
    //     users: [],
    //     billingDate: '',
    //     filterData: '',
    //     isOpen: false,
    //     productSelected: '',
    //     subProductSelected: '',
    //     isOpenQuickSearchModal: false
    //   })
    //   this.props.updateIsLogout(false);
    // }
  }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  handleClick = () => {
    this.setState({ statusClicked: !this.state.statusClicked });
  };

  milestoneHandleClick = () => {
    this.setState({ milestoneClicked: !this.state.milestoneClicked });
  };

  taskHandleclick = () => {
    this.setState({ taskClicked: !this.state.taskClicked });
  };

  openQuickSearchModal = () => {
    this.setState({ isOpenQuickSearchModal: !this.state.isOpenQuickSearchModal });
  }

  handleProductChange = item => {
    let filterResult = [];
    let { value } = item.target;
    this.props.setSelectedProduct(value);
    this.props.setSelectedSubProduct('');
    this.props.setLastServiceModule(value);
    this.props.setTaskBackUp([]);
    this.props.setMilestoneBackUp([]);
    this.props.setStatusBackup([]);
    let filterFrom = [];
    if (this.state.productSelected == '' || this.state.restoreFilter) {
      console.log("check", this.state.restoreFilter);
      filterFrom = this.props.dataFromReducer.initialWorkList;
      this.props.setProductBackUp(this.props.dataFromReducer.initialWorkList);
    }
    else
      filterFrom = this.props.productBackUp;
    if (value == 'Accommodation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .AccommodationType,
      });
    } else if (value == 'Diagnostic') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList.DiagnosticType,
      });
    } else if (value == 'HHC') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList.HHCCategory,
      });
    } else if (value == 'Interpretation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .TranslationCategory,
      });
    } else if (value == 'Modification') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .ModificationCategory,
      });
    } else if (value == 'MSE') {
      let MSE = [];
      this.props.dataFromReducer.gpIntegrationList.MSECategory.map(item => {
        MSE.push({
          id: item.PK_listelement_id,
          value: item.value,
          product: item.product,
        });
      });
      this.setState({ subProduct: MSE });
    } else if (value == 'Transportation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .TransportCategory,
      });
    } else if (value == 'Special Transportation') {
      this.setState({
        subProduct: this.props.dataFromReducer.gpIntegrationList
          .SpecialTransportCategory,
      });
      let filterData = filterFrom.filter(
        i => i.ServiceType.toLowerCase() == 'special transport'
      );
      this.props.updateNewUsersWorkList(filterData);
      filterResult = filterData;
    } else this.setState({ subProduct: [] });
    this.setState({ productSelected: value, subProductSelected: '' });
    this.props.setLastServiceModule(value);
    if (value != 'Special Transportation') {
      let filterData = filterFrom.filter(
        i => i.ServiceType.toLowerCase() == value.toLowerCase()
      );
      this.props.updateNewUsersWorkList(filterData);
      filterResult = filterData;
    }

    this.state.restoreFilter = false;
    this.props.setSelectedVendor([]);
    this.props.setSelectedType('');
    return filterResult;
  };

  productHelper = e => {

    this.setState({productSelected : e.target.value})
    // this.props.setLastServiceModule(e.target.value);
    let rec = this?.props?.dataFromReducer?.backupWorkList?.map(item => {
      item.selected = false;
      return item;
    });

    this.props.updateNewUsersWorkList(rec);
    
    const tableShortName = tableNameToShort(e.target.value); 

    const searchRes = rec.filter( res => res.chr_reference_table == tableShortName);

    this.props.updateNewUsersWorkList(searchRes);
    this.props.setLastServiceModule(e.target.value);
    return searchRes;
  };

  handleSubProductChange = item => {
    this.props.setSelectedType('');
    let { value, filteredProductData } = item.target;
    this.props.setSelectedSubProduct(value);
    this.props.setLastSubServiceModule(value);
    this.setState({ subProductSelected: value });

    let backup = filteredProductData ? filteredProductData : this.props.productBackUp;

    let filterData = backup.filter(
      i =>
        i.ServiceType.toLowerCase() ==
        this.state.productSelected.toLowerCase() &&
        i.product.toLowerCase().replace(/ /g, '') ==
        value.toLowerCase().replace(/ /g, '')
    );

    if (this.state.productSelected === 'Special Transportation') {
      filterData = backup.filter(i => {
        return i.product && i.product.toLowerCase() === value.toLowerCase();
      });
    }

    this.props.updateNewUsersWorkList(filterData);
  };

  handleGpIntegrationChange = e => {
    this.props.onSelectGpIntegration(e.target.value);
  };



  handleUserChange = e => {
    const user =
      this.state.users &&
      this.state.users.find(u => u.zebraId == e.target.value);
    this.props.updatePreviousUser(this.state.userSelected);
    this.setState({
      userSelected: e.target.value,
      userId: this.props.userId,
      billingDate: '',
      filterData: '',
      productSelected: '',
      subProductSelected: '',
      subProduct: [],
    }, () => { this.handleRefreshClick(); });
    this.props.setSelectedUser(e.target.value);
    this.props.setZebraId(user.zebraId);
    this.props.getUsersWorkList(user, this.props.currentUserId);
    this.props.updateClickRecord();
    this.props.updateStatusClicked(false);
    this.props.updateMilestoneClicked(false);
    this.props.updateTaskClicked(false);
    this.props.updateMilestoneData([]);
    this.props.resetFollowups();
    this.props.resetNewOrders();
    this.props.resetUsersRush();
    this.props.resetUsersOOC();
    this.props.resetUsersTotOrders();

  };
  setFilterData = stng => {
    this.setState({ filterData: stng });
  }

  handleFilter = e => {
    this.setState({ filterData: e.target.value.toLowerCase() });
    this.props.setInputFields(e.target.value.toLowerCase());
    const lowercasedFilter = e.target.value.toLowerCase();

    let rec = this?.props?.dataFromReducer?.backupWorkList?.map(item => {
      item.selected = false;
      return item;
    });

    this.props.updateNewUsersWorkList(rec);
    //  if(lowercasedFilter.length === 0) {
    //   return;
    //  }

    const filteredData = [];
    // this.props.dataFromReducer.initialWorkList.map(item => {
    rec.map(item => {
      if (
        (item.confNo && item.confNo.toLowerCase().includes(lowercasedFilter)) ||
        (item.chr_claim_no &&
          item.chr_claim_no.toLowerCase().includes(lowercasedFilter)) ||
        (item.claimantName &&
          item.claimantName.toLowerCase().includes(lowercasedFilter)) ||
        (item.product &&
          item.product.toLowerCase().includes(lowercasedFilter)) ||
        (item.Vendor && item.Vendor.toLowerCase().includes(lowercasedFilter)) ||
        (item.serviceDate &&
          moment(item.serviceDate)
            .format('MM/DD/YYYY')
            .includes(lowercasedFilter)) ||
        (item.lang && item.lang.toLowerCase().includes(lowercasedFilter))
      ) {
        const itemService = item.ServiceType.trim();
        const selectedService = this.props.selectedProduct.trim();
        const selectedSubService = this.props.selectedSubProduct.trim();
        const itemSubService = item.product;

        if (selectedService !== "" && itemService === selectedService) {
          if (selectedSubService !== "" && itemSubService === selectedSubService) {
            filteredData.push(item);
          } else if (selectedSubService === "") {
            filteredData.push(item);
          }
        } else if (selectedService === "") {
          filteredData.push(item);
        }


      }
    });

    this.props.updateNewUsersWorkList(filteredData);

  };

  handleKeyPress = target => {
    if (target.charCode == 13) {
      const confNo = this.state.filterData;
      let values = confNo.split("-");
      if (confNo == "" || values.length == 0 || values.length < 3) {
        this.props.alert("Not a valid conf #. Please try again")
      } else {
        let quickSearchPayload = {
          "functionName": "quickSearchCheck",
          "zebraUserId": this.props.currentUserId,
          "referralId": values[0],
          "serviceType": values[1],
          "serviceId": values[2],
        }
        this.props.runQuickSearchCheck(quickSearchPayload);
        //this.setState({ quickSearchValue : confNo });
      }
    }
  };

  updateRecord = () => {

    this.state.backupFilterData = {
      subProduct: this.state.subProductSelected,
      product: this.state.productSelected,
      milestones: this.props.milestoneData,
      tasks: this.props.taskData,
      statuses: this.props.statusData,
      filterData: this.state.filterData,
      lastService: this.props.lastServiceModule,
      lastSubService: this.props.lastSubServiceModule,
      input: this.props.inputField,
      filterType: this.props.selectedFilterType,
    }
    
    this.props.clearReferralTransfered();
    this.handleRefreshClick();

  }

  restoreFilterData = () => {
    this.setState({
      subProductSelected: this.state.backupFilterData.subProduct,
      filterData: this.state.backupFilterData.filterData,
    });
    if(this.state.backupFilterData.milestones){
      this.props.updateMilestoneClicked(true);
      this.props.updateMilestoneData(this.state.backupFilterData.milestones);
    }
    if(this.state.backupFilterData.tasks){
      this.props.updateTaskClicked(true);
      this.props.updateTaskData(this.state.backupFilterData.tasks);
    }
    if(this.state.backupFilterData.statuses){
      this.props.updateStatusClicked(true);
      this.props.updatedStatusData(this.state.backupFilterData.statuses);
    }
    if(this.state.backupFilterData.lastService){
      this.props.setLastServiceModule(this.state.backupFilterData.lastService);
    }
    if(this.state.backupFilterData.lastSubService){
      this.props.setLastSubServiceModule(this.state.backupFilterData.lastSubService);
    }
    if(this.state.backupFilterData.inputField){
      this.props.setInputFields(this.state.backupFilterData.inputField);
    }
    if(this.state.backupFilterData.filterType){
      this.props.setSelectedFilterType(this.state.backupFilterData.filterType);
    }
  }

  handleTransfer = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleTransferFromModal = () => {

    // let user = {};
    // let userSelected = this.state.userSelected;
    // if (this.state.userSelected == '1111') {
    //   user = {
    //     zebraId: 1111,
    //     username: 'Unbilled/Unassigned',
    //     id: -1,
    //   };
    //   userSelected = this.props.currentUserId;
    // } else if (this.state.userSelected == '2222') {
    //   user = {
    //     zebraId: 2222,
    //     id: 0,
    //     username: 'Unassigned',
    //   };
    //   userSelected = this.props.currentUserId;
    // } else
    //   user =
    //     this.state.users &&
    //     this.state.users.find(
    //       u => u.zebraId == Number(this.state.userSelected)
    //     );
    this.setState({ isOpen: !this.state.isOpen });
    // this.props.updateClickRecord();

    // setTimeout(() => {
    //   this.props.getUsersWorkList(user, userSelected);
    //   this.setState({
    //     subProductSelected: '',
    //     productSelected: '',
    //     subProduct: [],
    //   });
    // }, 5000);
  };
  handleRefreshClick = (resetLastService = true) => {
    if (!Array.isArray(this.props?.dataFromReducer?.backupWorkList)){     
      return;
    }
    let rec = this.props.dataFromReducer.backupWorkList.map(item => {
      item.selected = false;
      return item;
    });

    const user =
      this.state.users &&
      this.state.users.find(u => u.zebraId == this.state.userSelected);
    user && this.props.getUsersWorkList(user, this.props.currentUserId);
    this.setState({
      subProductSelected: '',
      productSelected: '',
      subProduct: [],
      filterData: ''
    });
    let mdata = [], tdata = [], sData = [];
    this.props.dataFromReducer.milestone.map(item => mdata.push({ 'name': item, 'isChecked': true }));
    this.props.dataFromReducer.tasks.map(item => tdata.push({ ...item, 'isChecked': true }));
    this.props.dataFromReducer.status.map(item => sData.push({ ...item, 'isChecked': true }))
    this.props.setSelectedSubProduct('');
    this.props.setSelectedProduct('');
    this.props.updateNewUsersWorkList(rec);
    // this.props.updatePreviousUser('');
    this.props.updateClickRecord();
    this.props.setSelectedType('');
    this.props.setSelectedFilterType('');
    this.props.updateStatusClicked(false);
    this.props.updateMilestoneClicked(false);
    this.props.updateTaskClicked(false);
    this.props.updateMilestoneData(mdata);
    this.props.updateTaskData(tdata);
    this.props.updatedStatusData(sData);
    this.props.setProductBackUp([]);
    this.props.setTaskBackUp([]);
    this.props.setMilestoneBackUp([]);
    this.props.setStatusBackup([]);
    if (resetLastService) {
      this.props.setLastServiceModule('');
      this.props.setLastSubServiceModule('');
      this.props.setInputFields('');

    }

  };

  isInvoiceCoordinator = () => {
    return this.props.user.role_name === "Invoice Coordinator";
  }

  toggleInvliceCoordinator = () => {
    this.props.onInvoiceCooridinatorToggle();
    this.props.setSelectedInvoiceCoordinatorTable('');
    this.props.setSelectedInvoiceCoordinatorUser('');

  }
  componentWillUnmount() {
    // this.handleRefreshClick();
  }

  render() {

    return (
      <>
        <Row>
          <Col lg="auto">
            <FormGroup>
              <DashboardButton handleUserChange={this.handleUserChange} userId={this.props.userId} zebraId={this.props.currentUserId} setSelectedUser={this.props.setSelectedUser} setFilterData={this.setFilterData} />
            </FormGroup>
          </Col>
          {this.isInvoiceCoordinator() && <Col xl="2" lg={{ offset: 3 }}>
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%', marginLeft: "90px" }}
                color={this.props.isInvoiceCoordinatorOpen ? 'primary' : 'secondary'}
                onClick={this.toggleInvliceCoordinator}
              >
                REVENUE MANAGEMENT
              </Button>
            </FormGroup>
          </Col>}
          <Col xl="2" lg={{ offset: this.isInvoiceCoordinator() ? 0 : 5 }}>
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%', marginLeft: "90px" }}
                onClick={this.openQuickSearchModal}
              >
                QUICK SEARCH
              </Button>
            </FormGroup>
          </Col>
          <Col xl="2">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%', marginLeft: '90px' }}
                onClick={this.props.onAdvSearch}
              >
                ADV SEARCH
              </Button>
            </FormGroup>
          </Col>
          <Col xl="2">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%', marginLeft: '90px' }}
                onClick={this.props.onNewReferral}
              >
                NEW REFERRAL
              </Button>
            </FormGroup>
          </Col>
        </Row>
        {!this.props.isInvoiceCoordinatorOpen && <Row>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={this.handleRefreshClick}
              >
                REFRESH
              </Button>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Input
                type="select"
                name="gpIntegration"
                value={this.props.gpIntegration}
                onChange={this.handleGpIntegrationChange}
              >
                <option value="" disabled={true}>
                  GP Integrations
                </option>
                {this.props.dataFromReducer?.gpIntegrationList?.productList?.map(
                  (item, key) => (
                    <option key={key} value={item.name}>{item.name}</option>
                  )
                )}
              </Input>
            </FormGroup>
          </Col>
          {/* <Col lg="1">
            <Row>
              <Col lg="4" style={{ marginTop: '6px' }}>
                Billed Date:
              </Col>
              <Col lg="7" style={{ marginLeft: '-25px' }}>
                <AppDateInput
                  id="billedDate"
                  onChange={date => this.handleBillingDateChange(date)}
                  selected={this.state.billingDate}
                  maxDate={moment().toDate()}
                />
              </Col>
              <Col lg="1" style={{ marginLeft: '-18px' }}>
                <Button
                  onClick={() => {
                    this.setState({ billingDate: '' });
                    this.props.updateNewUsersWorkList(
                      this.props.dataFromReducer.backupWorkList
                    );
                  }}
                  color="danger"
                  name={'cleanbilledDate'}
                >
                  X
                </Button>
              </Col>
            </Row>
          </Col> */}
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={this.handleClick}
              >
                Status
              </Button>
              {this.state.statusClicked && (
                <StatusHover360
                  clicked={this.handleClick}
                  userSelected={this.state.userSelected}
                />
              )}
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={this.milestoneHandleClick}
              >
                Milestones
              </Button>
              {this.state.milestoneClicked && (
                <MilestoneHover360
                  clicked={this.milestoneHandleClick}
                  userSelected={this.state.userSelected}
                />
              )}
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={this.taskHandleclick}
              >
                Tasks
              </Button>
              {this.state.taskClicked && (
                <TaskHover360
                  clicked={this.taskHandleclick}
                  userSelected={this.state.userSelected}
                />
              )}
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Input
                type="select"
                onChange={this.handleProductChange}
                // onChange={this.productHelper}
                value={this.state.productSelected}
              >
                <option value="" disabled={true}>
                  Product
                </option>
                {this.props.dataFromReducer.gpIntegrationList?.productList.map(
                  (item, key) => (
                    <option key={key} value={item.name}>{item.name}</option>
                  )
                )}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Input
                type="select"
                disabled={this.state.subProduct.length == 0}
                onChange={this.handleSubProductChange}
                value={this.state.subProductSelected}
              >
                <option value="" disabled={true}>
                  Sub Product
                </option>
                {this.state.subProduct.map(item => (
                  <option>{item.value}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={this.props.selectedUser ? this.props.selectedUser : this.state.userSelected}
                onChange={this.handleUserChange}
              >
                {this.state.users &&
                  this.state.users.map((item, idx) => (
                    <option key={idx} value={item.zebraId}>{item.username}</option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="text"
                placeholder="Filter Worklist"
                value={this.state.filterData}
                // onChange={e => this.handleFilter(e)}
                onInput={e => this.handleFilter(e)}
                onKeyPress={target => this.handleKeyPress(target)}

              ></Input>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              <Button
                type="button"
                onClick={this.handleTransfer}
                style={{ width: '100%' }}
                disabled={this.props?.updatedRec?.length ? false : true}
              >
                TRANSFER
              </Button>
              {this.state.isOpen && (
                <TrasnserFast360
                  handleTransferFromModal={this.handleTransferFromModal}
                  clicked={this.handleTransfer}
                  selectedUser={this.state.userSelected}
                />
              )}
            </FormGroup>
          </Col>
        </Row>}


        <QuickSearch
          isOpen={this.state.isOpenQuickSearchModal}
          onClose={this.openQuickSearchModal}
          setFromQuickSearch={this.props.setFromQuickSearch}
          openReferral={this.props.openReferral}
        />

      </>
    );
  }
}

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  const appLoading = commonSelectors.appLoading(state);
  const user = authSelectors.getUser(state);

  return {
    currentUserId: authSelectors.getUserId(state),
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    usersTotList: state.FAST360.initialData.usersTotList ? state.FAST360.initialData.usersTotList : undefined,
    usersOOCList: state.FAST360.initialData.usersOOCList ? state.FAST360.initialData.usersOOCList : undefined,
    usersRushList: state.FAST360.initialData.usersRushList ? state.FAST360.initialData.usersRushList : undefined,
    usersNewList: state.FAST360.initialData.usersNewList ? state.FAST360.initialData.usersNewList : undefined,
    usersFollowUpList: state.FAST360.initialData.usersFollowUpList ? state.FAST360.initialData.usersFollowUpList : undefined,
    updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
    userId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    quickSearchCheck: state.FAST360.fast360Reducer.quickSearchCheck ? state.FAST360.fast360Reducer.quickSearchCheck : {},
    referralTransfered: state.FAST360.fast360Reducer.referralTransfered ? state.FAST360.fast360Reducer.referralTransfered : false,
    worklistLoaded: state.FAST360.initialData.worklistLoaded ? state.FAST360.initialData.worklistLoaded : false,
    selectedProduct: state.FAST360.fast360Reducer.selectedProduct,
    selectedSubProduct: state.FAST360.fast360Reducer.selectedSubProduct,
    inputField: state.FAST360.fast360Reducer.inputField,
    productBackUp: state.FAST360.fast360Reducer.productBackUp,
    subProductBackUp: state.FAST360.fast360Reducer.subProductBackUp,
    lastServiceModule: state.FAST360.fast360Reducer.lastServiceModule ? state.FAST360.fast360Reducer.lastServiceModule : '',
    lastSubServiceModule: state.FAST360.fast360Reducer.lastSubServiceModule ? state.FAST360.fast360Reducer.lastSubServiceModule : '',
    appLoading: appLoading,
    setVendorData: state.FAST360.fast360Reducer.setVendorData,
    statusData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatedStatusData : [],
    statusBackup: state.FAST360.fast360Reducer.statusBackUp ? state.FAST360.fast360Reducer.statusBackUp : [],
    milestoneData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateMilestoneData : [],
    mileStoneBackUp: state.FAST360.fast360Reducer.mileStoneBackUp ? state.FAST360.fast360Reducer.mileStoneBackUp : [],
    taskData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateTaskData : [],
    taskBackUp: state.FAST360.fast360Reducer.taskBackUp ? state.FAST360.fast360Reducer.taskBackUp : [],
    selectedFilterType: state.FAST360.fast360Reducer.selectedFilterType,
    user: user,
    isLogout: state.FAST360.fast360Reducer.updateIsLogout,

  };
};

const mapDispatchToProps = dispatch => ({
  updateNewUsersWorkList: data =>
    dispatch(fast360Actions.updateNewUsersWorkList(data)),
  getUsersWorkList: (data, currentUserId) =>
    dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
  updateClickRecord: () => dispatch(fast360Actions.updateClickRecord([])),
  updatePreviousUser: data => dispatch(fast360Actions.updatePreviousUser(data)),
  updateStatusClicked: data =>
    dispatch(fast360Actions.updateStatusClicked(data)),
  updateMilestoneClicked: data =>
    dispatch(fast360Actions.updateMilestoneClicked(data)),
  updateTaskClicked: data => dispatch(fast360Actions.updateTaskClicked(data)),
  updateMilestoneData: data =>
    dispatch(fast360Actions.updateMilestoneData(data)),
  runQuickSearchCheck: (data) => dispatch(fast360Operations.quickSearchCheck(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  setSelectedVendor: data => dispatch(fast360Actions.setSelectedVendor(data)),
  clearReferralTransfered: () => dispatch(fast360Actions.clearReferralTransfered()),
  resetUsersTotOrders: () => dispatch(fast360Actions.resetUsersTotOrders()),
  resetUsersRush: () => dispatch(fast360Actions.resetUsersRush()),
  resetUsersOOC: () => dispatch(fast360Actions.resetUsersOOC()),
  resetNewOrders: () => dispatch(fast360Actions.resetNewOrders()),
  resetFollowups: () => dispatch(fast360Actions.resetFollowups()),
  setSelectedVendor: data => dispatch(fast360Actions.setSelectedVendor(data)),
  setSelectedProduct: data => dispatch(fast360Actions.selectedProduct(data)),
  setSelectedSubProduct: data => dispatch(fast360Actions.selectedSubProduct(data)),
  updateTaskData: (data) => dispatch(fast360Actions.updateTaskData(data)),
  updatedStatusData: (data) => dispatch(fast360Actions.updatedStatusData(data)),
  setSelectedType: (data) => dispatch(fast360Actions.setSelectedType(data)),
  setInputFields: data => dispatch(fast360Actions.setInputFields(data)),
  setProductBackUp: (data) => dispatch(fast360Actions.productBackUp(data)),
  setSubProductBackUp: (data) => dispatch(fast360Actions.subProductBackUp(data)),
  setLastServiceModule: data => dispatch(fast360Actions.setLastServiceModule(data)),
  setLastSubServiceModule: data => dispatch(fast360Actions.setLastSubServiceModule(data)),
  setStatusBackup: (data) => dispatch(fast360Actions.statusBackUp(data)),
  setMilestoneBackUp: (data) => dispatch(fast360Actions.mileStoneBackUp(data)),
  setTaskBackUp: (data) => dispatch(fast360Actions.taskBackUp(data)),
  setSelectedFilterType: (data) => dispatch(fast360Actions.setSelectedFilterType(data)),
  updateIsLogout: (data) => dispatch(fast360Actions.updateIsLogout(data)),
  setSelectedInvoiceCoordinatorTable: (data) => dispatch(fast360Actions.setSelectedInvoiceCoordinatorTable(data)),
  setSelectedInvoiceCoordinatorUser: data =>  dispatch(fast360Actions.setSelectedInvoiceCoordinatorUser(data)),


});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360TopNav);
