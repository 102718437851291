import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFileAlt,
  faBan,
  faClipboardList,
  faEdit,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import '../assets/linkActions.css';

import LinkAction from './LinkAction';
import NoteLinkAction from './NoteLinkAction';
import BillClaimNotesAction from './BillClaimNotesAction'

library.add(faFileAlt, faBan, faClipboardList, faEdit, faFolderOpen);

class LinkActions extends Component {
  render() {
    return (
      <ul>
        <LinkAction type="Detail" link={this.props.detailLink} />
        <BillClaimNotesAction
          recordId={this.props.urData.int_claimcode}
          notes={this.props.notes}
          isFromClaim="true"
          isFindBillClaimSearch={true}
          claimId={this.props.int_claimcode}
        />
        <LinkAction
          type="UR"
          urAction={this.props.urAction}
          urData={this.props.urData}
        />
      </ul>
    );
  }
}

LinkActions.propTypes = {
  detailLink: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  urData: PropTypes.object.isRequired,
  urAction: PropTypes.func.isRequired,
};

LinkActions.defaultProps = {
  detailLink: '',
  notes: '',
};

export default LinkActions;
