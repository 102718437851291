import React from 'react';

export function isIE(){
  return navigator.userAgent.indexOf("MSIE")>-1 || navigator.appVersion.indexOf("Trident/")>-1
}

export function UpdateTypes(operation, value, types, dispatch, key) {
  if (operation) {
    const Types = [...types];
    Types.push(value);
    dispatch({
      type: 'UPDATE_Types',
      payload: { [key]: Types },
    });
  } else {
    const updatedTypes = types.filter(element => element !== value);
    dispatch({
      type: 'UPDATE_Types',
      payload: { [key]: updatedTypes },
    });
  }
}
export function selectAll(operation, allTypes, dispatch, key) {
  if (operation) {
    if (key === 'orderStatusIds') {
      dispatch({
        type: 'UPDATE_Types',
        payload: {
          [key]: allTypes && allTypes.map(element => element.status_id),
        },
      });
    } else if (key === 'states') {
      dispatch({
        type: 'UPDATE_Types',
        payload: { [key]: allTypes && allTypes.map(element => element.value) },
      });
    } else if (key === 'accommodationTypes') {
      dispatch({
        type: 'UPDATE_Types',
        payload: { [key]: allTypes && allTypes.map(element => element.id) },
      });
    } else if (key === 'interpretationTypes') {
      dispatch({
        type: 'UPDATE_Types',
        payload: { [key]: allTypes && allTypes.map(element => element.id) },
      });
    } else if (key === 'hhcTypes') {
      dispatch({
        type: 'UPDATE_Types',
        payload: { [key]: allTypes && allTypes.map(element => element.id) },
      })
    }
  } else {
    dispatch({
      type: 'UPDATE_Types',
      payload: { [key]: [] },
    });
  }
}

export function selectAllSubCategory(
  operation,
  allTypes,
  dispatch,
  key,
  subCategory
) {
  if (operation) {
    if (allTypes) {
      if (key === 'serviceTypes') {
        dispatch({
          type: 'SERVICE_TYPES_TABS',
          payload: {
            [key]: { [subCategory]: allTypes.map(element => element.id) },
          },
        });
      } else if (key === 'transportTypes') {
        dispatch({
          type: 'TRANSPORT_TYPES_TABS',
          payload: {
            [key]: { [subCategory]: allTypes.map(element => element.id) },
          },
        });
      }
    }
  } else {
    if (key === 'serviceTypes') {
      dispatch({
        type: 'SERVICE_TYPES_TABS',
        payload: { [key]: { [subCategory]: [] } },
      });
    } else if (key === 'transportTypes') {
      dispatch({
        type: 'TRANSPORT_TYPES_TABS',
        payload: { [key]: { [subCategory]: [] } },
      });
    }
  }
}

export function selectAllMseCategory(
  operation,
  allTypes,
  dispatch,
  key,
  subCategory
) {
  if (operation) {
    if (key === 'serviceTypes')
      allTypes &&
        dispatch({
          type: 'SERVICE_TYPES_TABS',
          payload: {
            [key]: { [subCategory]: allTypes.map(element => element.product) },
          },
        });
  } else {
    dispatch({
      type: 'SERVICE_TYPES_TABS',
      payload: { [key]: { [subCategory]: [] } },
    });
  }
}

export function UpdateServiceTypes(
  operation,
  value,
  types,
  dispatch,
  key,
  subCategory
) {
  if (operation) {
    const Types = [...types];
    Types.push(value);
    if (key === 'serviceTypes') {
      dispatch({
        type: 'SERVICE_TYPES_TABS',
        payload: { [key]: { [subCategory]: Types } },
      });
    } else if (key === 'transportTypes') {
      dispatch({
        type: 'TRANSPORT_TYPES_TABS',
        payload: { [key]: { [subCategory]: Types } },
      });
    }
   } else {
      if (key === 'serviceTypes') {
        const updatedTypes = types.filter(element => element !== value);
        dispatch({
          type: 'SERVICE_TYPES_TABS',
          payload: { [key]: { [subCategory]: updatedTypes } },
        });
      } else if (key === 'transportTypes') {
        const updatedTypes = types.filter(element => element !== value);
        dispatch({
          type: 'TRANSPORT_TYPES_TABS',
          payload: { [key]: { [subCategory]: updatedTypes } },
        });
      }
    }
  }
