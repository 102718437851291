import React from 'react';
import { Modal, ModalHeader, ModalBody, CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import { fast360Operations, fast360Selectors } from '../store/index';
import PopupModal from 'shared/components/PopupModal';
import { isEqual } from 'lodash';
import { authSelectors } from 'modules/auth/store';
import { Col, Row, Label, Form, Input, Button } from 'reactstrap';
import {
  fast360Actions,
} from '../store/index';
class Fast360FeeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      rfees: {},
      rfeeIds: {},
      backupFees: [],
      serviceFees: [],
      fees: {},
      feeIds: {},
      serviceCost: [],
      backupCost: [],
      billCharges: 0,
      costSavings: [],
      dayStay: 0,
      defaultPercent: 0,
      serviceId: 0,
      selectedService: {},
      isRefreshFeesModalOpen: false,
      isRefreshModalOpen: false,
      isRefreshCostModalOpen: false,
      isSaveModalOpen: false,
      totalFee: 0,
      totalCostFee: 0,
      margin: 0,
      totalDiff: 0
    };
    
  }

  toggle = () => {
    this.props.setopenFeeModal();
    this.props.resetDefaultPercent();
  };

  selectItem = (item, event) => {
    const result = event.target.checked;
    item.applied = result;
    const newServiceFee = this.state.serviceFees.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceFees: newServiceFee }, () => {
      this.calcTotalFee();
    }); 
    this.qtyChangeCalculation(item);
  };

  calcTotalFee = () => {
    let total = 0;
    let margin;
    let totalDiff;
     this.state.serviceFees.forEach(obj => {
      if (
        obj.applied
      ) {
        total = Number(Number(total) + Number(obj.calculatedCost)).toFixed(2);
      }
    });
    margin = Math.round(Number(Number(Number(total) - Number(this.state.totalCostFee)) / Number(total)) * 100);
    totalDiff = Number(Number(total) - Number(this.state.totalCostFee)).toFixed(2);
    this.setState({ totalFee: total, margin: margin, totalDiff: totalDiff });
  };

  getSelectActionColumn = lineDetail => {
    const item = lineDetail;
    if (!!!item) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          type="checkbox"
          id={item.fieldName + '' + item.side}
          name={item.id + '' + item.side}
          key={item.id + '' + item.side}
          checked={item.applied || false}
          onChange={event => {
            this.selectItem(item, event);
          }}
        />
      </div>
    );
  };

  selectCostItem = (item, event) => {
    const result = event.target.checked;
    item.applied = result;
    const newServiceFee = this.state.serviceCost.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceCost: newServiceFee }, () => {
      this.calcTotalCostFee();
    });
  };

  calcTotalCostFee = () => {
    let total = 0;
    let margin;
    let totalDiff;
    let calCost = 0;
     this.state.serviceCost.forEach(obj => {
      if (
        obj.applied
      ) {
        if (Number(obj.modifiedCost) === 0 || obj.modifiedCost === '' || obj.modifiedCost === undefined) {
          calCost = Number(
            Number(obj.qty) * Number(obj.clientFee)
          ).toFixed(2);
        } else {
          calCost = Number(
            Number(obj.qty) * Number(obj.modifiedCost)
          ).toFixed(2);
        }

        total = Number(Number(total) + Number(calCost)).toFixed(2);
       }
    });
    margin = Math.round(Number((Number(this.state.totalFee) - Number(total)) / Number(this.state.totalFee)) * 100);
    totalDiff = Number(Number(this.state.totalFee) - Number(total)).toFixed(2);
    this.setState({ totalCostFee: total, margin: margin, totalDiff: totalDiff });
  };

  getSelectActionCostColumn = lineDetail => {
    const item = lineDetail;
    if (!!!item) {
      return;
    }
    return (
      <div className="select-action">
        <CustomInput
          type="checkbox"
          id={item.fieldName + '' + item.side}
          name={item.id + '' + item.side}
          key={item.id + '' + item.side}
          checked={item.applied || false}
          onChange={event => {
            this.selectCostItem(item, event);
          }}
        />
      </div>
    );
  };

  getBilledCharges = item => {
    let billCharges;
    if (item.billedCharges !== undefined) {
      return (
        <>
          <Input
            value={`$${Number(item.billedCharges)}`}
            disabled={!item.applied}
          />
        </>
      );
    } else if (
      item.percentAdjustment === undefined ||
      item.percentAdjustment === 0 ||
      isNaN(Number(item.percentAdjustment))
    ) {
      item.percentAdjustment = this.state.defaultPercent;
      if (
        Number(item.modifiedCost) === 0 ||
        item.modifiedCost === '' ||
        item.modifiedCost === undefined
      ) {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.clientFee)
        ).toFixed(2);
      } else {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.modifiedCost)
        ).toFixed(2);
      }
      billCharges = Number(
        isNaN(Number(item.calculatedCost))
          ? 0
          :(Number(item.calculatedCost) /
          (1 - item.percentAdjustment / 100)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
     this.state.billCharges = billCharges;
      return (
        <>
          <Input
            value={`$${Number(
              isNaN(Number(item.calculatedCost))
                ? 0
                :(Number(item.calculatedCost) /
                (1 - item.percentAdjustment / 100)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])}`}
            disabled={!item.applied}
          />
        </>
      );
    } else if (
      (Number(item.percentAdjustment) === 0 ||
        item.percentAdjustment === undefined) &&
      (Number(this.state.defaultPercent) === 0 ||
        this.state.defaultPercent === undefined ||
        this.state.defaultPercent === '')
    ) {
      if (
        Number(item.modifiedCost) === 0 ||
        item.modifiedCost === '' ||
        item.modifiedCost === undefined
      ) {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.clientFee)
        ).toFixed(2);
      } else {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.modifiedCost)
        ).toFixed(2);
      }
      let noDefault = 50;
      if (module == 'ACC' || module == 'INT' || module == 'MOD' || module == 'SPT' || module == 'TRN') {
        noDefault = 25;
      }
      billCharges = Number(
        isNaN(Number(item.calculatedCost))
          ? 0
          : (Number(item.calculatedCost) /
              (1 - (Number(this.props.defaultPercent === undefined ? noDefault : this.props.defaultPercent) * 100) / 100)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.state.billCharges = billCharges;
      return (
        <>
          <Input
            value={`$${Number(
              isNaN(Number(item.calculatedCost))
                ? 0
                : (Number(item.calculatedCost) /
                    (1 - (Number(this.props.defaultPercent === undefined ? noDefault : this.props.defaultPercent) * 100) / 100)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])}`}
            disabled={!item.applied}
          />
        </>
      );
    } else {
      if (
        Number(item.modifiedCost) === 0 ||
        item.modifiedCost === '' ||
        item.modifiedCost === undefined
      ) {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.clientFee)
        ).toFixed(2);
      } else {
        item.calculatedCost = Number(
          Number(item.qty) * Number(item.modifiedCost)
        ).toFixed(2);
      }
      billCharges = Number(
        isNaN(Number(item.calculatedCost))
          ? 0
          : (Number(item.calculatedCost) /
              (1 - item.percentAdjustment / 100)
      ).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.state.billCharges = billCharges;
      return (
        <>
          <Input
            value={`$${Number(
              isNaN(Number(item.calculatedCost))
                ? 0
                : (Number(item.calculatedCost) /
                    (1 - item.percentAdjustment / 100)
            ).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])}`}
            disabled={!item.applied}
          />
        </>
      ); 
    }
  };

  getBillingCharges = item => {
    let billedCharges;
    if (item.id === 0) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = billedCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else {
      billedCharges = this.getBilledCharges(item);
      /*  for each(var costSaving:Object in costSavings) {
      if (costSaving.detailId == item.id) {
        foundCS = true;
        costSaving.totalAllowance = item.calculatedCost;
        billedCharges = getBilledCharges(item);
        costSaving.billedCharges = billedCharges;
      }
    }
    if (!foundCS) {					
      var costSaving:Object = {};
      costSaving.totalAllowance = item.calculatedCost;
      costSaving.detailId = item.id;
      costSaving.serviceId = serviceId;
      
      costSaving.billedCharges = billedCharges;
      if (costSavings == null || costSavings.length == 0) {
        costSavings = new ArrayCollection();
      }
      costSavings.addItem(costSaving);*/
    }
    return (
      <>
        <Input value={billedCharges || '$0.00'} disabled={!item.applied} />
      </>
    );
  };

  getLineItems = (fees, feeIds, lItems, side) => {
    lItems = lItems && JSON.parse(JSON.stringify(lItems));
    var lineItems = [];
    for (const lineItem of lItems) {
      lineItem.side = side;
      if (lineItem.fieldName === 'Rate_pline') {
        lineItem.serviceDescription = 'Line Cost';
      } else if (lineItem.fieldName === 'Rate_ppage') {
        lineItem.serviceDescription = 'Pages Cost';
      } else if (lineItem.fieldName === 'Rate_pword') {
        lineItem.serviceDescription = 'Word Cost';
      } else if (lineItem.fieldName === 'Rate_pline') {
        lineItem.serviceDescription = 'Line Cost';
      } else if (lineItem.fieldName === 'Hourly_Rate') {
        lineItem.serviceDescription = 'Hourly Cost';
      } else if (lineItem.fieldName === 'Transcript_Hourly_Rate') {
        lineItem.serviceDescription = 'Hourly Cost';
      } else if (lineItem.fieldName === 'Non_Prof_Rate') {
        lineItem.serviceDescription = 'NPO Cost';
      } else if (lineItem.fieldName === 'Rate_per_Minute') {
        lineItem.serviceDescription = 'Call Cost';
      } else if (lineItem.fieldName === 'Additional_Passenger_Rate') {
        lineItem.serviceDescription = 'Additional Passenger Rate';
      } else if (lineItem.fieldName === 'Dead_Miles_Rate') {
        lineItem.serviceDescription = 'Dead Miles Cost';
      } else if (lineItem.fieldName === 'Extra_Crew_Rate') {
        lineItem.serviceDescription = 'Extra Crew Rate';
      } else if (lineItem.fieldName === 'Flat_Fee') {
        lineItem.serviceDescription = 'Flat Fee';
      } else if (lineItem.fieldName === 'ParkingToll_Fees') {
        lineItem.serviceDescription = 'Parking/Toll Fees';
      } else if (lineItem.fieldName === 'Fuel_Surcharge') {
        lineItem.serviceDescription = 'Fuel Surcharge';
      } else if (lineItem.fieldName === 'Load_Fee') {
        lineItem.serviceDescription = 'Load Fee';
      } else if (lineItem.fieldName === 'Late_Cancel') {
        lineItem.serviceDescription = 'Late Cancel';
      } else if (lineItem.fieldName === 'No_Show_Fee') {
        lineItem.serviceDescription = 'No Show Fee';
      } else if (lineItem.fieldName === 'Rush_Fee') {
        lineItem.serviceDescription = 'Rush Fee';
      } else if (lineItem.fieldName === 'Wheel_Chair_Rental_Fee') {
        lineItem.serviceDescription = 'Wheel Chair Rental Fee';
      } else if (lineItem.fieldName === 'Mileage_Rate') {
        lineItem.serviceDescription = 'Mileage Cost';
      } else if (lineItem.fieldName === 'Rate_per_Mile') {
        lineItem.serviceDescription = 'Mileage Cost';
      } else if (lineItem.fieldName === 'Wait_Time_Fee') {
        lineItem.serviceDescription = 'Wait Time Fee';
      } else if (lineItem.fieldName === 'Rebooking_Fee') {
        lineItem.serviceDescription = 'Rebooking Fee';
      } else if (lineItem.fieldName === 'Ticket_Cost') {
        lineItem.serviceDescription = 'Ticket Cost';
      } else if (lineItem.fieldName === 'Flight_Credit') {
        lineItem.serviceDescription = 'Flight Credit';
      } else if (lineItem.fieldName === 'Preferred_Seating') {
        lineItem.serviceDescription = 'Preferred Seating';
      } else if (lineItem.fieldName === 'Inflight_Meal') {
        lineItem.serviceDescription = 'Inflight Meal';
      } else if (lineItem.fieldName === 'Checked_Bag_1') {
        lineItem.serviceDescription = 'Checked Bag 1';
      } else if (lineItem.fieldName === 'Checked_Bag_2') {
        lineItem.serviceDescription = 'Checked Bag 2';
      } else if (lineItem.fieldName === 'Daily_Fee') {
        lineItem.serviceDescription = 'Daily Fee';
      } else if (lineItem.fieldName === 'Monthly_Fee') {
        lineItem.serviceDescription = 'Monthly Fee';
      } else if (lineItem.fieldName === 'Weekly_Fee') {
        lineItem.serviceDescription = 'Weekly Fee';
      } else if (lineItem.fieldName === 'Meal_per_Diem') {
        lineItem.serviceDescription = 'Meal per Diem';
      } else if (lineItem.fieldName === 'Incidental') {
        lineItem.serviceDescription = 'Incidental';
      } else if (lineItem.fieldName === 'Resort_Fee') {
        lineItem.serviceDescription = 'Resort Fee';
      } else if (lineItem.fieldName === 'Parking') {
        lineItem.serviceDescription = 'Parking';
      }
       lineItem.percentAdjustment = 0;
       if(lineItem.id) {
        const lineList =  this.props.selectedService?.costSavings.filter(i => i.DetailId === lineItem.id);
        const line = lineList[lineList?.length-1];
        lineItem.percentAdjustment = line && line.PercentAdjustment;
        lineItem.billedCharges = line && line.BilledCharges;
        // add cost saving object
        if (line) {
          if (lineItem.costSaving === undefined) {
            lineItem.costSaving = {};
          }
          lineItem.costSaving.totalAllowance = line && line.TotalAllowance;
          lineItem.costSaving.serviceId = line && line.ServiceId;
          lineItem.costSaving.billedCharges = line && line.BilledCharges;
          lineItem.costSaving.detailId = line && line.DetailId;
          lineItem.costSaving.fields = line && line.fields;
          lineItem.costSaving.percentAdjustment = line && line.PercentAdjustment;
          lineItem.costSaving.id = line && line.id;
        }
       }
        
       if (!lineItem.applied /*|| resetAllFees*/) {
        if (lineItem.fieldName === 'Rate_pline') {
          lineItem.feeTypeId = feeIds.Rate_pline;
          if (fees.Min_Line_Count < this.props.selectedService.linesPerPage) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.state.linesPerPage;
            } else if (
              lineItem.qty != this.props.selectedService.linesPerPage &&
              this.props.selectedService.linesPerPage > 0
            ) {
              lineItem.qty = this.state.linesPerPage;
            }

            lineItem.clientFee = fees.Rate_pline;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.linesPerPage * fees.Rate_pline;
          } else {
            if (lineItem.qty <= fees.Min_Line_Count) {
              lineItem.qty = fees.Min_Line_Count;
            }
            lineItem.clientFee = fees.Rate_pline;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = (
              fees.Min_Line_Count * fees.Rate_pline
            ).toFixed(2);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_ppage') {
          lineItem.serviceDescription = 'Pages Cost';
          lineItem.feeTypeId = feeIds.Rate_ppage;
          if (fees.Min_Page_Count < 0) {
            lineItem.qty = 0;
            lineItem.clientFee = fees.Rate_ppage;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = 0.0;
          } else {
            if (lineItem.qty <= fees.Min_Page_Count) {
              lineItem.qty = fees.Min_Page_Count;
            }
            lineItem.clientFee = fees.Rate_ppage;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = fees.Min_Page_Count * fees.Rate_ppage;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_pword') {
          lineItem.serviceDescription = 'Word Cost';
          lineItem.feeTypeId = feeIds.Rate_pword;
          if (fees.Min_pword_count < 0) {
            if (lineItem.qty <= 0) {
              lineItem.qty = 0;
            }
            lineItem.clientFee = fees.Rate_pword;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = 0.0;
          } else {
            if (lineItem.qty <= fees.Min_pword_count) {
              lineItem.qty = fees.Min_pword_count;
            }
            lineItem.clientFee = fees.Rate_pword;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = fees.Minimum_Word_count * fees.Rate_pword;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Hourly_Rate') {
          lineItem.serviceDescription = 'Hourly Cost';
          lineItem.feeTypeId = feeIds.Hourly_Rate;
          if (fees.Hourly_Minimum < this.props.selectedService.durationHour) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.props.selectedService.durationHour;
            } else if (
              lineItem.qty != this.props.selectedService.durationHour &&
              this.props.selectedService.durationHour > 0
            ) {
              lineItem.qty = this.props.selectedService.durationHour;
            }

            lineItem.clientFee = fees.Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.durationHour * (fees.Hourly_Rate / 60);
          } else {
            if (lineItem.qty <= fees.Hourly_Minimum) {
              lineItem.qty = fees.Hourly_Minimum;
            }

            lineItem.clientFee = fees.Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              fees.Hourly_Minimum * (fees.Hourly_Rate / 60);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Transcript_Hourly_Rate') {
          lineItem.serviceDescription = 'Hourly Cost';
          lineItem.feeTypeId = feeIds.Transcript_Hourly_Rate;
          if (
            fees.Transcript_Hourly_Min < this.props.selectedService.durationHour
          ) {
            if (lineItem.qty <= 0) {
              lineItem.qty = this.props.selectedService.durationHour;
            } else if (
              lineItem.qty != this.props.selectedService.durationHour &&
              this.props.selectedService.durationHour > 0
            ) {
              lineItem.qty = this.props.selectedService.durationHour;
            }

            //lineItem.qty = ifnull(parentWindow.INTduration.text, '0')
            lineItem.clientFee = fees.Transcript_Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              this.props.selectedService.durationHour *
              (fees.Transcript_Hourly_Rate / 60);
          } else {
            if (lineItem.qty <= fees.Transcript_Hourly_Min) {
              lineItem.qty = fees.Transcript_Hourly_Min;
            }
            lineItem.clientFee = fees.Transcript_Hourly_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost =
              fees.Transcript_Hourly_Min * (fees.Transcript_Hourly_Rate / 60);
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Non_Prof_Rate') {
          lineItem.serviceDescription = 'NPO Cost';

          if (lineItem.qty <= fees.Non_Prof_Units) {
            lineItem.qty = fees.Non_Prof_Units;
          }

          lineItem.clientFee = fees.Non_Prof_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          lineItem.calculatedCost = fees.Non_Prof_Units * fees.Non_Prof_Rate;

          lineItem.feeTypeId = feeIds.Non_Prof_Rate;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rate_per_Minute') {
          lineItem.serviceDescription = 'Call Cost';
          lineItem.feeTypeId = feeIds.Rate_per_Minute;
          if (fees.Min_Call_Time < this.props.selectedService.durationHour) {
            if (lineItem.qty <= 0) {
              lineItem.qty =
                this.props.selectedService.durationHour * 60 +
                this.props.selectedService.durationMin;
            }
            lineItem.clientFee = fees.Rate_per_Minute;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = lineItem.qty * fees.Rate_per_Minute;
          } else {
            if (lineItem.qty <= fees.Min_Call_Time) {
              lineItem.qty = fees.Min_Call_Time;
            }
            lineItem.clientFee = fees.Rate_per_Minute;
            lineItem.calculatedCost = fees.Min_Call_Time * fees.Rate_per_Minute;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Additional_Passenger_Rate') {
          lineItem.serviceDescription = 'Additional Passenger Rate';
          lineItem.feeTypeId = feeIds.Additional_Passenger_Rate;
          lineItem.clientFee = fees.Additional_Passenger_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Dead_Miles_Rate') {
          lineItem.serviceDescription = 'Dead Miles Total';
          lineItem.qty = fees.deadMileTot;
          lineItem.feeTypeId = feeIds.Dead_Miles_Rate;
          lineItem.clientFee = fees.Dead_Miles_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Extra_Crew_Rate') {
          lineItem.serviceDescription = 'Extra Crew Rate';
          lineItem.feeTypeId = feeIds.Extra_Crew_Rate;
          lineItem.clientFee = fees.Extra_Crew_Rate;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.extraPassenger) {
                lineItem.qty = '1';
              }
              if (obj.extraPassengerRate > 0) {
                lineItem.clientFee = obj.extraPassengerRate;
              }
            }
          }

          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Flat_Fee') {
          lineItem.serviceDescription = 'Flat Fee';
          lineItem.feeTypeId = feeIds.Flat_Fee;
          lineItem.clientFee = fees.Flat_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'ParkingToll_Fees') {
          lineItem.serviceDescription = 'Parking/Toll Fees';
          lineItem.feeTypeId = feeIds.ParkingToll_fees;
          lineItem.clientFee = fees.ParkingToll_fees;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Fuel_Surcharge') {
          lineItem.serviceDescription = 'Fuel Surcharge';
          lineItem.feeTypeId = feeIds.Fuel_Surcharge;
          lineItem.clientFee = fees.Fuel_Surcharge;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Load_Fee') {
          lineItem.serviceDescription = 'Load Fee';

          if (this.props.selectedService.detailList === undefined) {
            lineItem.qty = 0;
          } else {
            lineItem.qty = this.props.selectedService.detailList.length;
          }

          lineItem.feeTypeId = feeIds.Load_Fee;
          lineItem.clientFee = fees.Load_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Late_Cancel') {
          lineItem.serviceDescription = 'Late Cancel';
          lineItem.feeTypeId = feeIds.Late_Cancel;
          lineItem.clientFee = fees.Late_Cancel;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'No_Show_Fee') {
          lineItem.serviceDescription = 'No Show Fee';

          if (
            this.props.selectedService.serviceStatusId == 44 ||
            this.props.selectedService.serviceStatusId == 5
          ) {
            lineItem.qty = '1';
          } else {
            lineItem.qty = '0';
          }
          lineItem.feeTypeId = feeIds.No_Show_Fee;
          lineItem.clientFee = fees.No_Show_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Rush_Fee') {
          lineItem.serviceDescription = 'Rush Fee';
          if (this.props.selectedService.rush) {
            lineItem.qty = '1';
          } else {
            lineItem.qty = '0';
          }
          lineItem.feeTypeId = feeIds.Rush_Fee;
          lineItem.clientFee = fees.Rush_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Wheel_Chair_Rental_Fee') {
          lineItem.serviceDescription = 'Wheel Chair Rental Fee';
          lineItem.clientFee = fees.Wheel_Chair_Rental_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.wheelChairRental) {
                lineItem.qty = '1';
              }
              if (obj.wheelChairRentalRate > 0) {
                lineItem.clientFee = obj.wheelChairRentalRate;
              }
            }
          }
          lineItem.feeTypeId = feeIds.Wheel_Chair_Rental_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Mileage_Rate') {
          let legs;
          lineItem.serviceDescription = 'Mileage Cost';
          if (this.props.selectedService.detailList != undefined) {
            legs = this.props.selectedService.detailList.length;
          } else {
            legs = 1;
          }
          if (this.props.selectedService.detailList != undefined && (fees.Minimum_Miles === undefined || fees.Minimum_Miles >= 0)) {
            lineItem.qty = 0;
            for (const obj of this.props.selectedService.detailList) {
              if (obj.estimatedMillage) {
                lineItem.qty = lineItem.qty + obj.estimatedMillage;
              }else {
                lineItem.qty = fees.Minimum_Miles * legs
              }
            
          if (fees.Minimum_Miles * legs < (fees.mileage || 0)) {
            lineItem.qty = fees.mileage || 0;
            lineItem.clientFee = fees.Mileage_Rate;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            lineItem.calculatedCost = (obj.estimatedMillage || 0) * fees.Mileage_Rate;
          }
        }
          } else {
            if (lineItem.qty <= fees.Minimum_Miles * legs) {
              lineItem.qty = fees.Minimum_Miles * legs;
            }
            lineItem.clientFee = fees.Mileage_Rate;
            lineItem.calculatedCost = fees.Minimum_Miles * fees.Mileage_Rate;
          }
          lineItem.feeTypeId = feeIds.Mileage_Rate;
          if (lineItem.modifiedCost === undefined || lineItem.clientFee === lineItem.modifiedCost) {
            lineItem.modifiedCost = 0;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Rate_per_Mile') {
          lineItem.serviceDescription = 'Mileage Cost';
          if (fees.Mileage_Unit < (this.props.selectedService.mileage || 0)) {
            lineItem.qty = this.props.selectedService.mileage || 0;
            lineItem.clientFee = fees.Rate_per_Mile;
            if (lineItem.clientFee === undefined) {
              lineItem.clientFee = 0;
            }
            if (fees.Mileage_Unit < (this.state.selectedService.mileage || 0)) {
              lineItem.calculatedCost =
                (this.props.selectedService.mileage || 0) * fees.Rate_per_Mile;
            }
          } else {
            if (lineItem.qty <= fees.Mileage_Unit) {
              lineItem.qty = fees.Mileage_Unit;
            }
            lineItem.clientFee = fees.Rate_per_Mile;
            lineItem.calculatedCost = fees.Mileage_Unit * fees.Rate_per_Mile;
          }
          lineItem.feeTypeId = feeIds.Rate_per_Mile;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Wait_Time_Fee') {
          lineItem.serviceDescription = 'Wait Time Fee';
          lineItem.qty = 0;
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.waitHour > 0) {
                lineItem.qty = lineItem.qty + obj.waitHour;
              }
              if (obj.waitMinute > 0) {
                if (obj.waitMinute < 15) {
                  //do Nothing
                } else if (obj.waitMinute < 30) {
                  lineItem.qty = lineItem.qty + 0.5;
                } else if (obj.waitMinute > 30) {
                  lineItem.qty = lineItem.qty + 1;
                }
              }
              if (lineItem.qty < 1 && lineItem.qty > 0) {
                lineItem.qty = 1;
              }
              lineItem.clientFee = fees.Wait_Time_Fee;
            }
          }
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }

          lineItem.feeTypeId = feeIds.Wait_Time_Fee;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName == 'Rebooking_Fee') {
          lineItem.serviceDescription = 'Rebooking Fee';
          lineItem.clientFee = fees.Rebooking_Fee;
          //lineItem.clientFee = 0
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee = lineItem.clientFee + obj.transpRebookFee;
              }
            }
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Rebooking_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Ticket_Cost') {
          lineItem.serviceDescription = 'Ticket Cost';
          lineItem.clientFee = fees.Ticket_Cost;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee = lineItem.clientFee + obj.transpCostAmt;
              }
            }
          }
          if (this.props.selectedService.addPassName != undefined) {
            lineItem.qty = 2;
          } else {
            lineItem.qty = 1;
          }

          lineItem.feeTypeId = feeIds.Ticket_Cost;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName == 'Flight_Credit') {
          lineItem.serviceDescription = 'Flight Credit';
          lineItem.clientFee = fees.Flight_Credit;
          lineItem.clientFee = 0;
          if (this.props.selectedService.detailList != undefined) {
            for (const obj of this.props.selectedService.detailList) {
              if (obj.transpCostAmt > 0) {
                lineItem.clientFee =
                  lineItem.clientFee + obj.transpServiceCredit;
              }
            }
          }
          lineItem.clientFee = lineItem.clientFee * -1;
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.feeTypeId = feeIds.Flight_Credit;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Preferred_Seating') {
          lineItem.serviceDescription = 'Preferred Seating';
          lineItem.clientFee = fees.Preferred_Seating;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Preferred_Seating;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Inflight_Meal') {
          lineItem.serviceDescription = 'Inflight Meal';
          lineItem.clientFee = fees.Inflight_Meal;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Inflight_Meal;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName == 'Checked_Bag_1') {
          lineItem.serviceDescription = 'Checked Bag 1';
          lineItem.clientFee = fees.Checked_Bag_1;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Checked_Bag_1;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Checked_Bag_2') {
          lineItem.serviceDescription = 'Checked Bag 2';
          lineItem.clientFee = fees.Checked_Bag_2;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Checked_Bag_2;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        //ACC Fees
        if (lineItem.fieldName === 'Daily_Fee') {
          lineItem.serviceDescription = 'Daily Fee';
          lineItem.clientFee = fees.Daily_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;

          lineItem.feeTypeId = feeIds.Daily_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Monthly_Fee') {
          lineItem.serviceDescription = 'Monthly Fee';
          lineItem.clientFee = fees.Monthly_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;
          lineItem.feeTypeId = feeIds.Monthly_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Weekly_Fee') {
          lineItem.serviceDescription = 'Weekly Fee';
          lineItem.clientFee = fees.Weekly_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }
          lineItem.qty = this.props.daysStay;
          lineItem.feeTypeId = feeIds.Monthly_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }

        if (lineItem.fieldName === 'Meal_per_Diem') {
          lineItem.serviceDescription = 'Meal per Diem';
          lineItem.clientFee = fees.Meal_per_Diem;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Meal_per_Diem;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Incidental') {
          lineItem.serviceDescription = 'Incidental';
          lineItem.clientFee = fees.Incidental;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Incidental;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push.apply(lineItems, lineItem);
        }
        if (lineItem.fieldName === 'Resort_Fee') {
          lineItem.serviceDescription = 'Resort Fee';
          lineItem.clientFee = fees.Resort_Fee;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Resort_Fee;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Parking') {
          lineItem.serviceDescription = 'Parking';
          lineItem.clientFee = fees.Parking;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Parking;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
        if (lineItem.fieldName === 'Telephone_Call') {
          lineItem.serviceDescription = 'Telephone Call';
          lineItem.clientFee = fees.Telephone_Call;
          if (lineItem.clientFee === undefined) {
            lineItem.clientFee = 0;
          }
          if (lineItem.modifiedCost === null) {
            lineItem.modifiedCost = lineItem.clientFee;
          }

          lineItem.feeTypeId = feeIds.Telephone_Call;

          lineItem.calculatedCost = lineItem.qty * lineItem.modifiedCost;
          lineItems.push(lineItem);
        }
      
      } else {
        if(lineItem.applied) {
         if(lineItem.clientFee === lineItem.modifiedCost || lineItem.modifiedCost === undefined) {
           lineItem.modifiedCost = 0;
         }
        }
        lineItems.push(lineItem);
      }
    }
    if (side === 'left') {
      this.setState({ serviceFees: [...lineItems] }, () => {
      this.calcTotalFee();
      });
    } else {
      this.setState({ serviceCost: [...lineItems] }, () => {
        this.calcTotalCostFee();
      });
    }
    //return lineItems
  };

  componentDidUpdate(prevProps) {
    const { defaultPercent } = this.props;
    if (!isEqual(prevProps.defaultPercent, defaultPercent) && defaultPercent ) {
      this.setState({ defaultPercent: Number(defaultPercent) * 100 });
      if( !this.props.rFees && 
          !this.props.rfeeIds &&  
          !this.props.serviceFees && 
          !this.props.fees &&
          !this.props.feeIds &&
          !this.props.serviceCosts
        ) {
          return;
        }

      this.getLineItems(
        this.props.rfees,
        this.props.rfeeIds,
        this.props.serviceFees,
        'left'
      );
      this.getLineItems(
        this.props.fees,
        this.props.feeIds,
        this.props.serviceCosts,
        'right'
      );
    }
  }

  componentDidMount() {
  
    if ( this.props.rfees != undefined){
    
      this.getLineItems(
        this.props.rfees,
        this.props.rfeeIds,
        this.props.serviceFees,
        'left'
      );
      this.getLineItems(
        this.props.fees,
        this.props.feeIds,
        this.props.serviceCosts,
        'right'
      );
      const params = {
        functionName: 'getServiceIdFromReference',
        userId: this.props.userId,
        serviceId: this.props.selectedService && this.props.selectedService.serviceId,
        serviceType: this.props.selectedService && this.props.selectedService.module
      }
      this.props.getServiceIdFromReference(params);
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: this.props.userId ? this.props.userId : 0,
        userid:this.props.userId ? this.props.userId : 0 ,
        breadcrumbkey: 'FEESOPEN',
        refTableId:  this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0,
        refId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
        refTable:  this.props.selectedService.module ? this.props.selectedService.module : '' ,
      };
      this.props.logBreadCrumbs(breadCrumb);
    }
    // var totalInvoice:Number
    // totalInvoice = 0
    /* for each(var li:Object in serviceFees){
      if (li.applied){
        if (li.credit){
          totalInvoice = totalInvoice - Number(li.modifiedCost * li.qty)
        }else{
          if (li.modifiedCost != null && li.modifiedCost > 0){
            totalInvoice = totalInvoice + Number(li.modifiedCost * li.qty)
          }else{
            totalInvoice = totalInvoice + Number(li.clientFee * li.qty)
          }
        }
      }
    }
    invAmtUI.text = moneyFormatter.format(totalInvoice)
  }
  
  if (currentServ.hasOwnProperty('fees')){
    vendorCosts = getLineItems(currentServ.fees, currentServ.feeIds, currentServ.serviceCosts)
    var totalCosts:Number
    totalCosts = 0.00 
    for each(var li2:Object in vendorCosts){
      if (li2.applied){
        if (li2.credit){
          totalCosts = totalCosts - Number(li2.modifiedCost * li2.qty)
        }else{
          if (li2.modifiedCost != null && li2.modifiedCost > 0){
            totalCosts = totalCosts + Number(li2.modifiedCost * li2.qty)
          }else{
            totalCosts = totalCosts + Number(li2.clientFee * li2.qty)
          }
        }
      }
    }
    
    if (currentState != null){
      paymentAmtUI.text = moneyFormatter.format(totalCosts)
    }
  }
  
  calcMargins()
  
  resetAllFees = false;*/
  }

  getDescription(lineDetail) {
    return <>{lineDetail.serviceDescription || ''}</>;
  }

  handleQtyChange = (item, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    item.qty = value;
    this.qtyChangeCalculation(item);
  };

  qtyChangeCalculation = (item) => {
    if (Number(item.modifiedCost) === 0 || item.modifiedCost === '' || item.modifiedCost === undefined) {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.clientFee)
      ).toFixed(2);
    } else {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.modifiedCost)
      ).toFixed(2);
    }
    let billedCharges;
    // Unset the loading value
    if (item.billedCharges !== undefined) {
      item.billedCharges = undefined;
    }
    if (item.id == 0) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else if(item.id && (this.props.selectedService?.costSavings?.filter(i => i.DetailId === item.id).length === 0)) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else {
      billedCharges = this.getBilledCharges(item);
      if (item.billedCharges === undefined || item.billedCharges) {
        item.billedCharges = this.state.billCharges;
        item.totalAllowance = item.calculatedCost;
      }
    }
    const newServiceFee = this.state.serviceFees.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceFees: newServiceFee }, () => {
      this.calcTotalFee();
    });
  }

  getQty = lineDetail => {
    const item = lineDetail;
    return (
      <>
        <Input
          onChange={e => this.handleQtyChange(item, e)}
          value={item.qty || 0}
        />
      </>
    );
  };

  handleCostQtyChange = (item, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    item.qty = value;
    if (Number(item.modifiedCost) === 0 || item.modifiedCost === '' || item.modifiedCost === undefined) {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.clientFee)
      ).toFixed(2);
    } else {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.modifiedCost)
      ).toFixed(2);
    }
    const newServiceFee = this.state.serviceCost.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceCost: newServiceFee }, () => {
      this.calcTotalCostFee();
    });
  };

  getCostQty = lineDetail => {
    const item = lineDetail;
    return (
      <>
        <Input
          onChange={e => this.handleCostQtyChange(item, e)}
          value={item.qty || 0}
        />
      </>
    );
  };

  getClientFee = lineDetail => {
    return <>${lineDetail.clientFee || 0.0}</>;
  };

  handleModifiedChange = (item, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    item.modifiedCost = value;
    if (Number(item.modifiedCost) === 0 || item.modifiedCost === '') {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.clientFee)
      ).toFixed(2);
    } else {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.modifiedCost)
      ).toFixed(2);
    }
    let billedCharges;
    if (item.billedCharges !== undefined) {
      item.billedCharges = undefined;
    }
    if (item.id == 0) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else if(item.id && (this.props.selectedService?.costSavings?.filter(i => i.DetailId === item.id).length === 0)) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else {
      billedCharges = this.getBilledCharges(item);
      if (item.billedCharges === undefined || item.billedCharges) {
        item.billedCharges = this.state.billCharges;
        item.totalAllowance = item.calculatedCost;
      }
    }
    const newServiceFee = this.state.serviceFees.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceFees: newServiceFee }, () => {
      this.calcTotalFee();
    });
  };

  getModifiedFee = lineDetail => {
    const item = lineDetail;
    return (
      <>
        <Input
          onChange={e => this.handleModifiedChange(item, e)}
          value={item.modifiedCost || 0.0}
        />
      </>
    );
  };

  handleModifiedCostChange = (item, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    item.modifiedCost = value;
    if (Number(item.modifiedCost) === 0 || item.modifiedCost === '') {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.clientFee)
      ).toFixed(2);
    } else {
      item.calculatedCost = Number(
        Number(item.qty) * Number(item.modifiedCost)
      ).toFixed(2);
    }
    const newServiceFee = this.state.serviceCost.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceCost: newServiceFee }, () => {
      this.calcTotalCostFee();
    });
  };

  getModifiedCostFee = lineDetail => {
    const item = lineDetail;
    return (
      <>
        <Input
          onChange={e => this.handleModifiedCostChange(item, e)}
          value={item.modifiedCost || 0.0}
        />
      </>
    );
  };

  getMedCode = lineDetail => {
    return <>{lineDetail.medCode || ''}</>;
  };

  getContracted = lineDetail => {
    return <>${lineDetail.clientFee || 0.0}</>;
  };

  getTotal = lineDetail => {
    let calculatedCost;
    if (lineDetail.modifiedCost > 0) {
      calculatedCost = Number(lineDetail.qty * lineDetail.modifiedCost).toFixed(
        2
      );
    } else {
      calculatedCost = Number(lineDetail.qty * lineDetail.clientFee).toFixed(2);
    }
    return <>${isNaN(Number(calculatedCost)) ? 0 : Number(calculatedCost) || 0.0}</>;
  };

  handleCSChange = (item, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    item.percentAdjustment = value;
    this.state.defaultPercent = value;
    let billedCharges;
    if (item.id == 0) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else if(item.id && (this.props.selectedService?.costSavings?.filter(i => i.DetailId === item.id).length === 0)) {
      if (item.costSaving === undefined) {
        item.costSaving = {};
      }
      item.costSaving.totalAllowance = item.calculatedCost;
      item.costSaving.serviceId = this.props.serviceIdFromRef;
      billedCharges = this.getBilledCharges(item);
      item.costSaving.billedCharges = this.state.billCharges;
      item.costSaving.detailId = 0;
      if (item.percentAdjustment != null) {
        item.costSaving.percentAdjustment = item.percentAdjustment;
      }
    } else {
      billedCharges = this.getBilledCharges(item);
      if (item.billedCharges === undefined || item.billedCharges) {
        item.billedCharges = this.state.billCharges;
        item.totalAllowance = item.calculatedCost;
      }
    }
    const newServiceFee = this.state.serviceFees.map(obj => {
      if (obj.fieldName === item.fieldName && obj.side === item.side) {
        obj = item;
      }
      return obj;
    });
    this.setState({ serviceFees: newServiceFee, defaultPercent: value },() => {
      this.calcTotalFee();
    });
  };

  costSavings = lineDetail => {
    const item = lineDetail;
    return (
      <>
        <Input
          onChange={e => this.handleCSChange(item, e)}
          value={item.percentAdjustment || this.state.defaultPercent || 0}
          disabled={true}
        />
      </>
    );
  };

  savings = item => {
    if (
      item.percentAdjustment === '' ||
      Number(item.percentAdjustment) === 0 ||
      item.percentAdjustment === undefined
    ) {
      return (
        <>
          <Input
            value={Number(this.props.defaultPercent == null ? 0 : this.props.defaultPercent) * 100}
            disabled
          />
        </>
      );
    } else {
      return (
        <>
          <Input
            value={item.percentAdjustment || this.state.defaultPercent}
            disabled
          />
        </>
      );
    }
  };

  getRefreshFeesModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to reset client fees?</span>
        </Col>
      </Row>
    );
  };

  getRefreshFeesModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={this.refreshFeesModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.handleRefreshClientFees}>
          Yes
        </Button>
      </>
    );
  };

  getRefreshModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to reset fees?</span>
        </Col>
      </Row>
    );
  };

  getRefreshModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={this.refreshModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.handleRefreshFees}>
          Yes
        </Button>
      </>
    );
  };

  refreshFeesModalToggle = () => {
    this.setState({ isRefreshFeesModalOpen: !this.state.isRefreshFeesModalOpen });
  };

  refreshModalToggle = () => {
    this.setState({ isRefreshModalOpen: !this.state.isRefreshModalOpen });
  };

  getVendorKeyInfo = (type) => {
    const {selectedService = {}} = this.props;
    let retObj = {};
    if (selectedService.module === 'AUD')
    {
      retObj.catId = 1097;
      retObj.table='audiology';
    } else if (selectedService.module === 'DTL') {
      retObj.catId = 1098;
      retObj.table='dental';
    } else if (selectedService.module === 'DGN') {
      retObj.catId = selectedService.DiagExamType;
      retObj.table='diagnostics';
    } else if (selectedService.module === 'MSE') {
      retObj.catId = selectedService.MSECatId;
      retObj.table='mse';
    } else if (selectedService.module === 'HHC') {
      retObj.catId = selectedService.HHCCatId;
      retObj.table='hhc';
    } else if (selectedService.module === 'TRN') {
      retObj.catId = selectedService.transportationCategoryId;
      retObj.table='transportation';
    } else if (selectedService.module === 'SPT') {
      retObj.catId = selectedService.specialTransportationCategoryId;
      retObj.table='special_transp';
    } else if (selectedService.module === 'ACC') {
      retObj.catId = selectedService.categoryId;
      retObj.table='accommodation';
    } else if (selectedService.module === 'INT'    ) {
      retObj.catId = selectedService.translationCategoryId;
      retObj.table='interpretation';
    } else if (selectedService.module === 'MOD') {
      retObj.catId = selectedService.MODCatId;
      retObj.table='modifications';
    }
    return retObj[type];
  }


  handleRefreshFees = e => {
    e.preventDefault();

   this.setState({serviceFees : [], serviceCost : []}, () => {
    this.getLineItems(
      this.props.rfees,
      this.props.rfeeIds,
      this.props.serviceFees,
      'left'
    );
    this.getLineItems(
      this.props.fees,
      this.props.feeIds,
      this.props.serviceCosts,
      'right'
    );
   });
  
    this.refreshModalToggle();
    setTimeout(() => {
      const modifiedServiceCost = this.refreshedCost();
      const modifiedServiceFees = this.refreshedClientFees();
      this.setState({serviceFees : modifiedServiceFees, serviceCost : modifiedServiceCost});
    }, 1000)
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: this.props.userId ? this.props.userId : 0,
      userid:this.props.userId ? this.props.userId : 0 ,
      breadcrumbkey: 'FEESRESET',
      refTableId:  this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0,
      refId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      refTable:  this.props.selectedService.module ? this.props.selectedService.module : '' ,
    };
    this.props.logBreadCrumbs(breadCrumb);

  };

  refreshedClientFees = () => {
   const modifiedServiceFees = [];

    this.state.serviceFees.forEach(item => {
      const itemIndex = this.props.serviceFees.findIndex(srvFee => srvFee.subservFeetypeId === item.subservFeetypeId);
      if(itemIndex => 0) {
          const clientFee = item.clientFee;
          const resetItem = {...item, ...this.props.serviceFees[itemIndex]};
          resetItem.applied = false;
          resetItem.calculatedCost = 0;
          resetItem.costSaving = {};
          resetItem.clientFee = clientFee;
          resetItem.modifiedCost = 0;
          resetItem.percentAdjustment = 0;
          resetItem.qty = 0;
          modifiedServiceFees.push(resetItem);
      }
    })

    return modifiedServiceFees;
  }

  getCategoryId = () => {
    const {selectedService = {}} = this.props;
    let catId;
    if (selectedService.module === 'AUD' || selectedService.module === 'DTL' || selectedService.module === 'DGN' || selectedService.module === 'MSE' || selectedService.module === 'HHC') {
      catId = selectedService.categoryId;
    } else if (selectedService.module === 'TRN') {
      catId = selectedService.transportationCategoryId;
    } else if (selectedService.module === 'SPT') {
      catId = selectedService.specialTransportationCategoryId;
    } else if (selectedService.module === 'ACC') {
      catId = selectedService.categoryId;
    } else if (selectedService.module === 'INT'    ) {
      catId = selectedService.translationCategoryId;
    } else if (selectedService.module === 'MOD') {
      catId = selectedService.MODCatId;
    }  
    return catId;
  }

  handleRefreshClientFees = e => {
    e.preventDefault();
    
    const reqBody = {
      functionName: "refreshClientFees",
      zebraUserId: this.props.userId ? this.props.userId : 0,
      clientId: this.props.updateLoadFromWorkList ? this.props.updateLoadFromWorkList.clientId : 0,
      serviceId: this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0 ,
      referralId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      serviceTypeId: this.getCategoryId(),
      state: this.props.updateLoadFromWorkList?.claim?.claimant?.address?.zipCode ? this.props.updateLoadFromWorkList.claim.claimant.address.zipCode.state : 0,
      zipCodeId:  this.props.updateLoadFromWorkList?.claim?.claimant?.address ? this.props.updateLoadFromWorkList.claim.claimant.address.zipCodeId : 0,
      langId: this.props.selectedService?.fromLanguageId ? this.props.selectedService.fromLanguageId : 0,
      tableAbbr: this.props.selectedService.module || '',
      tolangId: this.props.selectedService?.languageId ? this.props.selectedService.languageId : 0,
    };
    this.props.refreshClientFees(reqBody);

    this.setState({serviceFees: []}, () => {

      this.getLineItems(
        this.props.rfees,
        this.props.rfeeIds,
        this.props.serviceFees,
        'left'
      );
    })
    this.refreshFeesModalToggle();
    setTimeout(() => {
      const modifiedServiceFees = this.refreshedClientFees();
      this.setState({serviceFees : modifiedServiceFees});
    }, 100)
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: this.props.userId ? this.props.userId : 0,
      userid:this.props.userId ? this.props.userId : 0 ,
      breadcrumbkey: 'CLIENTFEESREFRESH',
      refTableId:  this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0,
      refId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      refTable:  this.props.selectedService.module ? this.props.selectedService.module : '' ,
    };
    this.props.logBreadCrumbs(breadCrumb);
  };

  getRefreshCostModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to reset vendor fees?</span>
        </Col>
      </Row>
    );
  };

  getRefreshCostModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={this.refreshCostModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.handleRefreshCost}>
          Yes
        </Button>
      </>
    );
  };

  refreshCostModalToggle = () => {
    this.setState({
      isRefreshCostModalOpen: !this.state.isRefreshCostModalOpen,
    });
  };

  refreshedCost = () => {
    const modifiedServiceCost = [];

    this.state.serviceCost.forEach(item => {
      const itemIndex = this.props.serviceCosts.findIndex(srvFee => srvFee.subservFeetypeId === item.subservFeetypeId);
      if(itemIndex => 0) {
          const clientFee = item.clientFee;
          const resetItem = {...item, ...this.props.serviceCosts[itemIndex]};
          resetItem.applied = false;
          resetItem.calculatedCost = 0;
          resetItem.costSaving = {};
          resetItem.clientFee = clientFee;
          resetItem.modifiedCost = 0;
          resetItem.percentAdjustment = 0;
          modifiedServiceCost.push(resetItem);
      }
    });
    return modifiedServiceCost;
  }

  handleRefreshCost = e => {
    e.preventDefault();

    const reqBody = {
      functionName: "refreshVendorFees",
      zebraUserId: this.props.userId ? this.props.userId : 0,
      vendorId: this.props.selectedService.vendorId ? this.props.selectedService.vendorId : 0,
      serviceId: this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0 ,
      referralId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      table: this.getVendorKeyInfo('table'),
      serviceTypeId: this.getVendorKeyInfo('catId'),
      state: this.props.updateLoadFromWorkList?.claim?.claimant?.address?.zipCode ? this.props.updateLoadFromWorkList.claim.claimant.address.zipCode.state : 0,
      zipCodeId:  this.props.updateLoadFromWorkList?.claim?.claimant?.address ? this.props.updateLoadFromWorkList.claim.claimant.address.zipCodeId : 0,
      langId: this.props.selectedService?.languageId ? this.props.selectedService.languageId : 0,
      feeType: null,
      serviceType: this.props.selectedService.serviceAbrv,
      tableAbbr: this.props.selectedService.module || '',
      fromLangId: this.props.selectedService?.fromLanguageId ? this.props.selectedService.fromLanguageId : 0,
      dos: this.props.selectedService.dos,
    };
    this.props.refreshVendorFees(reqBody);
   
   this.setState({serviceCost : []}, () => {
    this.getLineItems(
      this.props.fees,
      this.props.feeIds,
      this.props.serviceCosts,
      'right'
    );
   });
   
    this.refreshCostModalToggle();
    setTimeout(() => {
      const modifiedServiceCost = this.refreshedCost();
      this.setState({serviceCost : modifiedServiceCost});
    }, 100)
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: this.props.userId ? this.props.userId : 0,
      userid:this.props.userId ? this.props.userId : 0 ,
      breadcrumbkey: 'VENDORFEESREFRESH',
      refTableId:  this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0,
      refId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      refTable:  this.props.selectedService.module ? this.props.selectedService.module : '' ,
    };
    this.props.logBreadCrumbs(breadCrumb);
  };

  getSaveModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to save?</span>
        </Col>
      </Row>
    );
  };

  getSaveModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={this.saveModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={this.handleSave}>
          Yes
        </Button>
      </>
    );
  };

  saveModalToggle = () => {
    this.setState({
      isSaveModalOpen: !this.state.isSaveModalOpen,
    });
  };

  handleSave = e => {
    e.preventDefault();
    let costsClone = this.state.serviceCost.map(a => {
      return { ...a };
    });
    let costsResponse = [];
    costsClone.map(mod => {
      costsResponse.push(mod);
    });
    costsResponse.forEach(i => {
      delete i.serviceDescription;
      delete i.side;
    });
    const costRes = this.props.selectedService && this.props.selectedService.costSavings;
    let costsavingRes = [];
     this.state.serviceFees.find(i => {
       if(i.id && i.applied) {
        let modList = costRes.filter(j => 
          j.DetailId === i.id
        );
        const mod = modList[modList?.length-1];
        if(mod) {
          mod.PercentAdjustment =  i.percentAdjustment;
          mod.id = mod.fields && mod.fields.id;
          mod.ServiceId = mod.ServiceId;
          mod.BilledCharges = i.billedCharges === undefined ? mod.BilledCharges : i.billedCharges;
          mod.TotalAllowance = i.totalAllowance === undefined ? mod.TotalAllowance : i.totalAllowance;
        }
          costsavingRes.push(mod);
       }
    });
    let feesClone = this.state.serviceFees.map(a => {
      return { ...a };
    });
    let feesResponse = [];
    feesClone.map(mod => {
      feesResponse.push(mod);
    });
    feesResponse.forEach(i => {
      delete i.serviceDescription;
      delete i.side;
      delete i.percentAdjustment;
      delete i.billedCharges;
      delete i.totalAllowance;
    });
    let catId;
    if (
      (this.props.selectedService &&
        this.props.selectedService.module === 'AUD') ||
      (this.props.selectedService &&
        this.props.selectedService.module === 'DTL') ||
      (this.props.selectedService &&
        this.props.selectedService.module === 'DGN') ||
      (this.props.selectedService &&
        this.props.selectedService.module === 'MSE') ||
      (this.props.selectedService &&
        this.props.selectedService.module === 'HHC')
    ) {
      catId = this.props.selectedService.categoryId;
    } else if (
      this.props.selectedService &&
      this.props.selectedService.module === 'TRN'
    ) {
      catId = this.props.selectedService.transportationCategoryId;
    } else if (
      this.props.selectedService &&
      this.props.selectedService.module === 'SPT'
    ) {
      catId = this.props.selectedService.specialTransportationCategoryId;
    } else if (
      this.props.selectedService &&
      this.props.selectedService.module === 'ACC'
    ) {
      catId = this.props.selectedService.categoryId;
    } else if (
      this.props.selectedService &&
      this.props.selectedService.module === 'INT'
    ) {
      catId = this.props.selectedService.translationCategoryId;
    } else if (
      this.props.selectedService &&
      this.props.selectedService.module === 'MOD'
    ) {
      catId = this.props.selectedService.MODCatId;
    }  
    const feeParams = {
      functionName: 'saveFees',
      userId: this.props.userId,
      serviceId: this.props.selectedService && this.props.selectedService.serviceId,
      referralId: this.props.selectedService && this.props.selectedService.referralId,
      serviceFees: feesResponse,
      costSavings: costsavingRes,
      catId: catId || 0,
      fromLang: this.props.selectedService && this.props.selectedService.fromLanguageId || 0,
      toLang: this.props.selectedService && this.props.selectedService.languageId || 0,
      table: this.props.selectedService && this.props.selectedService.module,
    }
    let postPayload = {
      zebraUserId: this.props.userId,
      referral_id: this.props.selectedService?.referralId,
      mainMod: this.props.selectedService?.module,
    };
    this.props.saveFees(feeParams, postPayload);
    const costParams = {
      functionName: 'saveCosts',
      userId: this.props.userId,
      serviceId: this.props.selectedService && this.props.selectedService.serviceId,
      referralId: this.props.selectedService && this.props.selectedService.referralId,
      serviceCosts: costsResponse,
      catId: catId || 0,
      fromLang: this.props.selectedService && this.props.selectedService.fromLanguageId || 0,
      toLang: this.props.selectedService && this.props.selectedService.languageId || 0,
      table: this.props.selectedService && this.props.selectedService.module,
    }
    this.props.saveCosts(costParams);

    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: this.props.userId ? this.props.userId : 0,
      userid:this.props.userId ? this.props.userId : 0 ,
      breadcrumbkey: 'SAVEFEES',
      refTableId:  this.props.selectedService.serviceId ? this.props.selectedService.serviceId :0,
      refId: this.props.selectedService.referralId ? this.props.selectedService.referralId : 0 ,
      refTable:  this.props.selectedService.module ? this.props.selectedService.module : '' ,
    };
    this.props.logBreadCrumbs(breadCrumb);
    this.saveModalToggle();
    this.toggle();
  };

  render() {
    return (
      <>
        <Modal
          size="lg"
          style={{ maxWidth: '1600px', width: '80%' }}
          isOpen={true}
        >
          <ModalHeader toggle={this.toggle}>Revenue Matrix</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <Row>
                    <Label>
                      <b>Client Fees</b>
                    </Label>
                  </Row>
                  <Row>
                    <Label>Select Services Fees To Apply</Label>
                  </Row>
                  <AppTable
                    resizable
                    columns={[
                      {
                        header: 'Fee Description',
                        render: item => this.getDescription(item),
                      },
                      {
                        header: 'Qty',
                        render: item => this.getQty(item),
                      },
                      {
                        header: 'Contracted',
                        render: item => this.getClientFee(item),
                      },
                      {
                        header: 'Modified',
                        render: item => this.getModifiedFee(item),
                      },
                      {
                        header: 'Total',
                        render: item => this.getTotal(item),
                      },
                      {
                        header: 'CS Percentage',
                        render: item => this.costSavings(item),
                      },
                      {
                        header: 'Billed Charges',
                        render: item => this.getBilledCharges(item),
                      },
                      {
                        header: 'Savings',
                        render: item => this.savings(item),
                      },
                      {
                        header: 'Apply',
                        render: item => this.getSelectActionColumn(item),
                        field: '',
                      },
                    ]}
                    data={this.state.serviceFees || []}
                    autoPagination={true}
                    // sortAble={true}
                    headerWidth="100%"
                    modalHeight={400}
                  />
                  <Row style={{ marginTop: '35px' }}>
                    <Col>
                      <Button color="primary" onClick={this.refreshFeesModalToggle}>
                        REFRESH CLIENT FEES
                      </Button>
                    </Col>
                    <Label style={{ padding: '5px' }}>
                      Total Fees Applied:
                    </Label>
                    <Col>
                      <Input value={`$${this.state.totalFee}`} disabled />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Label>
                      <b>Vendor Costs</b>
                    </Label>
                  </Row>
                  <Row>
                    <Label>Select Services To Apply</Label>
                  </Row>
                  <AppTable
                    resizable
                    columns={[
                      {
                        header: 'Fee Description',
                        render: item => this.getDescription(item),
                      },
                      {
                        header: 'Qty',
                        render: item => this.getCostQty(item),
                      },
                      {
                        header: 'Med Code',
                        render: item => this.getMedCode(item),
                      },
                      {
                        header: 'Contracted',
                        render: item => this.getContracted(item),
                      },
                      {
                        header: 'Modified',
                        render: item => this.getModifiedCostFee(item),
                      },
                      {
                        header: 'Total',
                        render: item => this.getTotal(item),
                      },
                      {
                        header: 'Apply',
                        field: '',
                        render: item => this.getSelectActionCostColumn(item),
                      },
                    ]}
                    data={this.state.serviceCost || []}
                    autoPagination={true}
                    // sortAble={true}
                    modalHeight={400}
                  />
                  <Row style={{ marginTop: '35px' }}>
                    <Col>
                      <Button
                        color="primary"
                        onClick={this.refreshCostModalToggle}
                      >
                        REFRESH VENDOR FEES
                      </Button>
                    </Col>
                    <Label style={{ padding: '5px' }}>
                      Total Costs Applied:
                    </Label>
                    <Col>
                     <Input value={`$${this.state.totalCostFee}`} disabled />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '70px' }}>
                    <Label style={{ padding: '5px' }}>Margin:</Label>
                    <Col>
                      <Input value={`${this.state.margin}%`} disabled />
                    </Col>
                    <Col>
                      <Input value={`$${this.state.totalDiff}`} disabled  />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div style={{ float: 'right', marginTop: '20px' }}>
                <Button type="button" onClick={this.refreshModalToggle}>
                  RESET
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  color="primary"
                  onClick={this.saveModalToggle}>
                  SAVE
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <PopupModal
          content={this.getRefreshFeesModalBodyContent()}
          title="Confirmation"
          isOpen={this.state.isRefreshFeesModalOpen}
          externalToggle={this.refreshFeesModalToggle}
          footerContent={this.getRefreshFeesModalFooterContent()}
          size="md"
        />
        <PopupModal
          content={this.getRefreshCostModalBodyContent()}
          title="Confirmation"
          isOpen={this.state.isRefreshCostModalOpen}
          externalToggle={this.refreshCostModalToggle}
          footerContent={this.getRefreshCostModalFooterContent()}
          size="md"
        />
        <PopupModal
          content={this.getSaveModalBodyContent()}
          title="Confirmation"
          isOpen={this.state.isSaveModalOpen}
          externalToggle={this.saveModalToggle}
          footerContent={this.getSaveModalFooterContent()}
          size="md"
        />
        <PopupModal
          content={this.getRefreshModalBodyContent()}
          title="Confirmation"
          isOpen={this.state.isRefreshModalOpen}
          externalToggle={this.refreshModalToggle}
          footerContent={this.getRefreshModalFooterContent()}
          size="md"
        />
      </>
    );
  }
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    userId: user.id,
    serviceIdFromRef: fast360Info.serviceIdFromRef,
    updateLoadFromWorkList: fast360Info.updateLoadFromWorkList,
  };
};

const mapDispatchToProps = dispatch => ({
  saveFees: (data, req) => dispatch(fast360Operations.saveFees(data, req)),
  saveCosts: data => dispatch(fast360Operations.saveCosts(data)),
  getServiceIdFromReference: data => dispatch(fast360Operations.getServiceIdFromReference(data)),
  resetDefaultPercent: () =>
    dispatch(fast360Actions.resetDefaultPercent()),
  logBreadCrumbs : data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
  refreshClientFees : data =>
    dispatch(fast360Operations.refreshClientFees(data)),
  refreshVendorFees : data => 
    dispatch(fast360Operations.refreshClientFees(data)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Fast360FeeModal);