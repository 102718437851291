import React from 'react';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from 'common/store';
import './AppAlert.scss';
import { Alert, Col, Row } from 'reactstrap';

const AppAlert = props => {
return (
    <Col lg={{ size: '12', offset: 1 }}>
      <div id="alert-overlay">
        {props.alerts.length > 0 && (
          <div id="alert-container">
            {props.alerts.map(appAlert => {
              return (
                <Alert key={appAlert.id} color={appAlert.level}>
                  <Row>
                      <Col lg={11}>
                    {Array.isArray(appAlert.message) ? appAlert.message.map(line => (
                        <div>{line}<br /></div>
                    )) : appAlert.message }
                      </Col>
                      <Col lg={1}>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={props.onCloseAlert(appAlert.id)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Col>
                    </Row>
                  </Alert>
              );
            })}
          </div>
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = state => {
  return { alerts: [...commonSelectors.appAlerts(state)] };
};

const mapDispatchToProps = dispatch => ({
  onCloseAlert: id => () => dispatch(commonActions.clearAlert(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppAlert);
