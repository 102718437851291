import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Col, Row, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
    billOperationsActions,
    billOperationsOperations,
    billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import AppTableNurseAudit from 'shared/components/table/AppTableNurseAudit';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

const McnsModal = ({
    isOpen,
    onClose,
    modifyBillLinespData,
    postBillLinesDataRes,
    modifyBillTotalAmount,
    resetBillTotalData,
    resetBillLinesData,
    getBillLinesDataMcns,
    postBillLinesData,
    getBillTotalData,
    postMcnsBills,
    mcnsEditvalues,
    userId,
    profile,
    data,
    date,
    claimNumber,
    billId,
    claimant,
    alert,
    refresh,
    postBillReviewDataRes,
    mcnsZipCode,
    setMcnsEditVal
}) => {
    const [notes, setNotes] = useState('');
    const [isOpenFeeLookup, setIsOpenFeeLookup] = useState(true);
    // const [mcnsZipCodeState, setMcnsZipCodeState] = useState(0);



    const toggle = () => {
        resetBillLinesData();
        resetBillTotalData();
        onClose();
        setIsOpenFeeLookup(true);
    };
    const onChangeHandler = (id, value) => {
        setNotes(value);
    };

    useEffect(() => {
        const params = {
            userId,
            profile,
            billId,
            claimant,
        };

        if (isOpen && isOpenFeeLookup) {
            resetBillLinesData();
            resetBillTotalData();
            getBillLinesDataMcns(params);
            // getBillTotalData(params);
            // setIsOpenFeeLookup(false);
        }
        return (() => {
            setMcnsEditVal({})
        })
    }, [isOpen]);


    const onFormSubmit = e => {
        e.preventDefault();
        const params = {
            notes,
            userId,
            profile,
            billId,
            mcnsEditvalues,
        };
        if (isOpenFeeLookup && postBillReviewDataRes !== -1) {
            postMcnsBills(params);
            setTimeout(() => {
                refresh();
            }, 2000);
            onClose();
        }
    };

    const sortColumn = (item1, item2, order, field) => {
        const field1 = item1[field] || {};
        const field2 = item2[field] || {};
        return sortByType(field1[field], field2[field], order);
    };

    const getImbTotal = () => { };

    const getBillLinesAppTable = () => {
        return (
            <div className="bill-lines">
                <Row>
                    <div style={{ marginRight: '290px' }} className="CliamAdjust">BILL LINES</div>
                    <Col>
                        <Row>
                            <Col>Claim #: {claimNumber}</Col>
                            <Col>DOI: {date}</Col>
                            <Col>Bill Total : {modifyBillTotalAmount}</Col>
                            <div className="col-4">Total Amount Approved $: 0.00 </div>
                        </Row>
                    </Col>
                </Row>
                <AppTableNurseAudit
                    columns={[
                        {
                            header: 'Line',
                            field: 'lineNumber',
                            percent: '4',
                        },
                        {
                            header: 'CPT',
                            field: 'cpt',
                            sortFunction: (item1, item2, order) => sortColumn(item1, item2, order, 'icdDesc'),
                            percent: '5',
                        },
                        {
                            header: 'CPT Description',
                            field: 'cptDescription',
                            percent: '10',
                        },
                        {
                            header: 'Units',
                            field: 'units',
                            percent: '5',
                        },
                        {
                            header: 'Modifiers',
                            field: 'modifier',
                            percent: '7',
                        },
                        {
                            header: 'DOS',
                            field: 'dos',
                            percent: '7',
                        },
                        {
                            header: 'Billed',
                            field: 'billed',
                            percent: '5',
                        },
                        {
                            header: 'Reductions',
                            field: 'reductions',
                            percent: '8',
                        },
                        {
                            header: 'Total Paid',
                            field: 'reimb',
                            percent: '8',
                        },
                        {
                            header: 'Network Savings',
                            field: 'negReduct',
                            percent: '6',
                        },
                        {
                            header: 'MCNS Reimbursement',
                            field: 'reimb',
                            percent: '12',
                        },
                        {
                            header: 'MCNS Savings',
                            field: 'reimb',
                            percent: '6',
                        },
                        {
                            header: 'Prov Reimb',
                            field: 'provReimb',
                            percent: '8',
                        },
                    ]}
                    data={modifyBillLinespData}
                    sortAble={true}
                    resizable={true}
                    sortImmediately={false}
                    key="bill-lines-table"
                    taskListData={data}
                    billId={billId}
                    mcnsZipCodeM={mcnsZipCode}
                />
            </div>
        );
    };

    return (
        <Modal size="xl" isOpen={isOpen}>
            <ModalHeader toggle={toggle}>Approve Bill Lines</ModalHeader>
            <ModalBody>
                <Form onSubmit={onFormSubmit}>
                    <Row><Col lg="12">{getBillLinesAppTable()}</Col></Row>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="firstName" className="CliamAdjustNote">Notes</Label>
                                <Col>
                                    <AppInput changeHandler={onChangeHandler} placeholder="Notes" id="notes" type="textarea" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button type="submit" id="odgSubmit">SUBMIT</Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

McnsModal.propTypes = {
    data: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    modifyBillLinespData: PropTypes.array,
    postBillLinesDataRes: PropTypes.number,
    modifyBillTotalAmount: PropTypes.string,
    rembTotal: PropTypes.number,
    postBillLinesData: PropTypes.func,
    postMcnsBills: PropTypes.func,
    getBillLinesDataMcns: PropTypes.func,
    resetBillLinesData: PropTypes.func,
    resetBillTotalData: PropTypes.func,
    date: PropTypes.string,
    claimNumber: PropTypes.string.isRequired,
    billId: PropTypes.number.isRequired,
    claimant: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    alert: PropTypes.func.isRequired,
    reviewType: PropTypes.string,
    refresh: PropTypes.func,
    postBillReviewDataRes: PropTypes.any,
    mcnsZipCode: PropTypes.number,
};

const mapStateToProps = state => {
    const billReviewState = billOperationsSelectors.getBillReview(state);
    const user = authSelectors.getUser(state);
    const billReviewInitialData = billOperationsSelectors.getInitialData(state);

    return {
        modifyBillLinespData: billReviewState.modifyBillLinespData,
        postBillLinesDataRes: billReviewState.postBillLinesDataRes,
        modifyBillTotalAmount: billReviewState.modifyBillTotalAmount,
        mcnsEditvalues: billReviewState.mcnsEditvalues ? billReviewState.mcnsEditvalues : {},
        userId: user.user_id,
        profile: billReviewInitialData.currentProfile,
        rembTotal: billReviewState.rembTotal,
        postBillReviewDataRes: billReviewState.postBillReviewDataRes,
    };
};

const mapDispatchToProps = dispatch => ({
    getBillLinesDataMcns: data => dispatch(billOperationsOperations.getBillLinesDataMcns(data)),
    resetBillLinesData: () => dispatch(billOperationsActions.resetBillLinesData()),
    getBillTotalData: data => dispatch(billOperationsOperations.getBillTotalData(data)),
    resetBillTotalData: () => dispatch(billOperationsActions.resetBillTotalData()),
    postBillLinesData: data => dispatch(billOperationsOperations.postBillLinesData(data)),
    //postBillLinesData: data => dispatch(billOperationsOperations.postBillLinesData(data)),
    postMcnsBills: data => dispatch(billOperationsOperations.postMcnsBills(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    setMcnsEditVal: value => dispatch(billOperationsActions.setMcnsEditVal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(McnsModal);