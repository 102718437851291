import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Input, Button, Label } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppInput from 'shared/components/input/AppInput';
import SearchableSelect from 'shared/components/input/SearchableSelect';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

class AddClientRatesButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleModalOpen: false,
      description: '',
      lineOfBusiness: '',
      rateType: '',
      network: '',
      billType: '',
      rate: '',
      type: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    };
  }

  onChangeHandler = object => {
    this.setState({
      [object.key]: object.value,
    });
  };

  onAddClientRates = () => {
    const {
      description,
      lineOfBusiness,
      rateType,
      network,
      billType,
      rate,
      type,
      state,
      effective,
      terminated,
    } = this.state;

    if (lineOfBusiness === '' || rateType === '') {
      return;
    }

    const siteId = this.props.siteDetails.id || 0;
    if (siteId === 0) {
      return;
    }

    const id =
      Math.min(
        Math.min(...this.props.data.map(item => (item.fields || {}).id || 0)),
        0
      ) - 1;

    let newRow = {
      fields: {
        id,
        scheduleName: description,
        billingType: lineOfBusiness,
        rateType: rateType,
        ppoId: network,
        billType: billType,
        amount: rate,
        state: state,
        source: type,
        dateRange: {
          effDate: effective,
          termDate: terminated,
        },
      },
      requiredFields: [],
    };
    this.props.addClientRates(newRow);
    this.stateSimpleModalToggle();
  };

  stateSimpleModalToggle = () => {
    this.setState(prevState => ({
      simpleModalOpen: !prevState.simpleModalOpen,
    }));
  };

  resetSimpleModal = () => {
    this.setState({
      description: '',
      lineOfBusiness: '',
      rateType: '',
      network: '',
      billType: '',
      rate: '',
      type: '',
      state: '',
      effective: '',
      terminated: new Date('12/31/2022'),
    });
  };

  getSimpleModalFooter() {
    return (
      <Button type="button" onClick={this.onAddClientRates}>
        ADD
      </Button>
    );
  }

  getSimpleModalBody() {
    const {
      ppoList,
      stateList,
      rateTypeList,
      lobList,
      billTypeList,
    } = this.props;
    return (
      <>
        <Row form>
          <Col lg="8">
            <FormGroup>
              <Label for="lbDescription">Description</Label>
              <AppInput
                type="text"
                changeHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                id="description"
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="lbLineOfBusiness">Line of Business</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="lineOfBusiness"
                data={lobList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4">
            <FormGroup>
              <Label for="lbRateType">Rate Type</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="rateType"
                data={rateTypeList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label for="lbNetwork">Network</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="network"
                data={ppoList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Label for="lbBillType">Bill Type</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="description"
                id="billType"
                data={billTypeList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="2">
            <FormGroup>
              <Label for="lbRate">Rate</Label>
              <AppInput
                type="text"
                changeHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                id="rate"
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label for="lbType">Type</Label>
              <Input
                type="select"
                name="type"
                id="type"
                onChange={e =>
                  this.onChangeHandler({
                    key: e.target.id,
                    value: e.target.value,
                  })
                }
              >
                <option value="">Choose</option>
                <option value="Retail">Retail</option>
                <option value="Wholesale">WholeSale</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label for="lbState">State</Label>
              <SearchableSelect
                placeholder="Choose"
                returnValue="key"
                stateHandler={(id, value) =>
                  this.onChangeHandler({ key: id, value })
                }
                displayOption="key"
                id="state"
                data={stateList}
                showToolTip={true}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="lbEffective">Effective</Label>
              <AppDateInput
                id="effective"
                onChange={value =>
                  this.onChangeHandler({ key: 'effective', value })
                }
                selected={this.state.effective}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="lbTerminated">Terminated</Label>
              <AppDateInput
                id="terminated"
                onChange={value =>
                  this.onChangeHandler({ key: 'terminated', value })
                }
                selected={this.state.terminated}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        <PopupModal
          content={this.getSimpleModalBody()}
          title="Add Site Detail"
          externalToggle={this.stateSimpleModalToggle}
          isOpen={this.state.simpleModalOpen}
          footerContent={this.getSimpleModalFooter()}
          onClosed={this.resetSimpleModal}
          size="lg"
        />
        <Button type="button" onClick={this.stateSimpleModalToggle}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </>
    );
  }
}

AddClientRatesButton.propTypes = {
  ppoList: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  rateTypeList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  billTypeList: PropTypes.array.isRequired,
  addClientRates: PropTypes.func.isRequired,
  siteDetails: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const initialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  return {
    ppoList: initialData.clientComboBoxes.ppoList,
    stateList: initialData.clientComboBoxes.stateList,
    rateTypeList: initialData.clientComboBoxes.rateTypeList,
    lobList: initialData.clientComboBoxes.lobList,
    billTypeList: initialData.clientComboBoxes.billTypeList,
    siteDetails: clientMaintenanceState.siteDetails,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addClientRates: data =>
      dispatch(billMaintenanceActions.addClientRates(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddClientRatesButton);
