import React, { Component, useState } from "react";
import { AppTableBodyPropType } from "./AppTablePropTypes";
import BillDetailDeny from "../../../modules/bill-operations/components/BillDetailDeny";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input
} from "reactstrap";
import {
    modifyBillLines
} from '../../../modules/bill-operations/utils/constantUtils';
import AppTableBodyNurseAuditRow from "./AppTableBodyNurseAuditRow";


class AppTableBodyNurseAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedRow: -1,
        };
    }


    render() {
        const isClickable = !!this.props.clickHandler;
        const isSelectable = !!this.props.selectHandler;
        const isRowClickable = !!this.props.rowClickHandler;
        const isRowDoubleClickable = !!this.props.rowDoubleClickHandler;



        return (
            <>
                {
                    this.props.data && this.props.data.map((dataObject, rowIndex) => {
                        const returnData = !!dataObject.fields ? dataObject.fields : dataObject;
                        return (

                            <AppTableBodyNurseAuditRow rowIndex={rowIndex}/* dropdownData={this.dropdownData}*/
                                highlightedRow={this.state.highlightedRow} isClickable={isClickable} isSelectable={isSelectable}
                                isRowClickable={isRowClickable} isRowDoubleClickable={isRowDoubleClickable} {...this.props}
                                returnData={returnData} dataObject={dataObject} taskListData={this.props.data}
                                claimant={this.props.claimant} billId={this.props.billId} mcnsZipCodeM={this.props.mcnsZipCodeM}
                            />

                        )
                    })
                }


            </>
        )
    }
}

AppTableBodyNurseAudit.propTypes = AppTableBodyPropType;

export default AppTableBodyNurseAudit;
