import * as ReduxUtils from 'shared/utils/reduxUtils';

import { moduleName } from '../constants';

const transform = ReduxUtils.createModuleActionTypeTransform(moduleName);

export const actionTypes = ReduxUtils.transformActionTypes(transform, {
  LOGIN: ReduxUtils.createRequestActionTypes('LOGIN'),
  LOGOUT: ReduxUtils.createRequestActionTypes('LOGOUT'),
  CHECKAUTHCODE: ReduxUtils.createRequestActionTypes('CHECKAUTHCODE'),
  RESENDAUTHCODE: ReduxUtils.createRequestActionTypes('RESENDAUTHCODE'),
  RESET: ReduxUtils.createRequestActionTypes('RESET'),
  UPDATE: ReduxUtils.createRequestActionTypes('UPDATE'),
  LOADQSNS: ReduxUtils.createRequestActionTypes('LOADQSNS'),
  SAVEQSNS: ReduxUtils.createRequestActionTypes('SAVEQSNS'),
  CHECKQSN: ReduxUtils.createRequestActionTypes('CHECKQSN'),
  ZEBRATIMEOUT: ReduxUtils.createRequestActionTypes('ZEBRATIMEOUT'),
});

const actions = {
  login: ReduxUtils.createRequestAction(
    actionTypes.LOGIN,
    thunkArg => thunkArg.authService.login
  ),
  logout: ReduxUtils.createRequestAction(
    actionTypes.LOGOUT,
    thunkArg => thunkArg.authService.logout
  ),
  checkAuthCode: ReduxUtils.createRequestAction(
    actionTypes.CHECKAUTHCODE,
    thunkArg => thunkArg.authService.checkAuthCode
  ),
  resendAuthCode: ReduxUtils.createRequestAction(
    actionTypes.RESENDAUTHCODE,
    thunkArg => thunkArg.authService.resendAuthCode
  ),
  resetPassword: ReduxUtils.createRequestAction(
    actionTypes.RESET,
    thunkArg => thunkArg.authService.resetPassword
  ),
  changePassword: ReduxUtils.createRequestAction(
  actionTypes.UPDATE,
  thunkArg => thunkArg.authService.changePassword
),
  loadSecurityQuestions: ReduxUtils.createRequestAction(
  actionTypes.LOADQSNS,
  thunkArg => thunkArg.authService.loadSecurityQuestions
),
  saveSecurityQuestions: ReduxUtils.createRequestAction(
  actionTypes.SAVEQSNS,
  thunkArg => thunkArg.authService.saveSecurityQuestions
),
  checkSecurityQuestions: ReduxUtils.createRequestAction(
    actionTypes.CHECKQSN,
    thunkArg => thunkArg.authService.checkSecurityQuestions
  ),
  zebraTimeoutSeconds: ReduxUtils.createRequestAction(
    actionTypes.ZEBRATIMEOUT,
    thunkArg => thunkArg.authService.zebraTimeoutSeconds
  )
};

export default actions;
