import * as R from 'ramda';
import React, { Component } from 'react';
import { Table } from 'reactstrap';

import { AppTablePropType } from './AppTablePropTypes';
import AppTableHeader from './AppTableHeader';
import AppTableBodyNurseAudit from './AppTableBodyNurseAudit';
import AppTablePagination from './AppTablePagination';
import { sortByType } from '../../utils/shareUtils';

class AppTableNurseAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
    };
    if (this.props.autoPagination) {
      this.state = {
        currentPage: 0,
        ...this.state,
      };
    }
    if (this.props.sortAble) {
      this.state = {
        sortColumns: this.props.columns.map(column => {
          return {
            field: column.field,
            sortOrder: column.sortOrder !== false,
          };
        }),
        sortBy: this.props.sortBy || this.props.columns[0].field,
        sortImmediately: this.props.sortImmediately,
        ...this.state,
      };
    }

    if (this.props.resizable) {
      this.state = {
        resizeColumns: this.props.columns.map(column => {
          return {
            field: column.field,
            percent: column.percent || null,
          };
        }),
        ...this.state,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const diff = (column1, column2) =>
      column1.header === column2.header &&
      column1.rowKey === column2.rowKey &&
      column1.field === column2.field &&
      column1.sortAble === column2.sortAble &&
      column1.resizable === column2.resizable;
    if (
      R.differenceWith(diff, this.props.columns, prevProps.columns).length > 0
    ) {
      if (this.props.sortAble) {
        this.setState({
          sortColumns: prevState.sortColumns,
          sortBy: this.props.sortBy || prevState.sortBy,
        });
      }
      this.setState({ columns: this.props.columns });
    }
    if (!R.equals(this.props.data, prevProps.data)) {
      if (this.props.sortAble) {
        this.setState({
          sortImmediately: this.props.sortImmediately,
        });
      }

      if (this.props.autoPagination) {
        this.setState({
          currentPage: 0,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <Table
          size={this.props.size}
          striped={this.props.striped}
          hover={this.props.hover}
          responsive
        >
          <AppTableHeader
            columns={this.state.columns}
            isSelectable={!!this.props.selectHandler}
            isClickable={!!this.props.clickHandler}
            sortAble={this.props.sortAble}
            sortImmediately={this.state.sortImmediately}
            sortColumns={this.state.sortColumns}
            sortBy={this.state.sortBy}
            sort={this.sort}
            resizable={this.props.resizable}
            resizeColumns={this.state.resizeColumns}
            updateResizeColumns={this.updateResizeColumns}
            onColumnsChange={this.onColumnsChange}
          />
          <AppTableBodyNurseAudit
            columns={this.state.columns}
            data={this.getData()}
            clickHandler={this.props.clickHandler}
            selectHandler={this.props.selectHandler}
            rowClickHandler={this.props.rowClickHandler}
            rowDoubleClickHandler={this.props.rowDoubleClickHandler}
            taskListData={this.props.taskListData}
            claimant={this.props.claimant}
            billId={this.props.billId}
            mcnsZipCodeM={this.props.mcnsZipCodeM}
          />
        </Table>
        {/* {!this.isPaginationEnabled() ? null : <AppTablePagination {...this.createPaginationProps()} />} */}
        {this.props.rowNumberDescription && (
          <span>{this.props.rowNumberDescription(this.props.data.length)}</span>
        )}
      </div>
    );
  }

  onColumnsChange = newColumns => {
    this.setState({ columns: newColumns });
  };

  createPaginationProps = () => {
    return {
      totalItems: this.props.autoPagination
        ? this.props.data.length
        : this.props.totalItems,
      pageSize: this.props.pageSize,
      currentPage: this.getCurrentPage(),
      selectPage: this.createSelectPageHandler(),
    };
  };

  createSelectPageHandler = () => {
    return this.props.autoPagination
      ? pageNumber => {
        this.setState({ currentPage: pageNumber });
      }
      : this.props.selectPage;
  };

  getData = () => {
    const data = this.sortAbleData(this.props.data);
    return this.props.autoPagination
      ? data.slice(
        this.state.currentPage * this.props.pageSize,
        (this.state.currentPage + 1) * this.props.pageSize
      )
      : data;
  };

  getCurrentPage = () => {
    return this.props.autoPagination
      ? this.state.currentPage
      : this.props.currentPage;
  };

  // isPaginationEnabled = () => {
  //     return this.props.data.length > this.props.pageSize && (this.props.autoPagination || !!this.props.pagination)
  // }

  sortAbleData = () => {
    if (this.props.sortAble) {
      if (!this.state.sortImmediately) {
        return this.props.data;
      }
      const sortBy = this.state.sortBy;
      const column = R.find(item => item.field === sortBy, this.props.columns);
      return [...this.props.data].sort((item1, item2) =>
        this.sortItem(item1, item2, column)
      );
    }
    return this.props.data;
  };

  sort = column => {
    this.setState({
      sortImmediately: true,
    });

    if (this.state.sortBy !== column.field) {
      this.setState({
        sortBy: column.field,
      });
      return;
    }

    const sortColumns = this.state.sortColumns.map(item => {
      return item.field === column.field
        ? {
          ...item,
          sortOrder: !item.sortOrder,
        }
        : { ...item };
    });

    this.setState({
      sortColumns,
    });
  };

  sortItem = (item1, item2, column) => {
    const order = R.find(
      item => item.field === column.field,
      this.state.sortColumns
    );
    if (column.sortFunction) {
      return column.sortFunction(item1, item2, order.sortOrder);
    }
    return sortByType(
      item1[column.field],
      item2[column.field],
      order.sortOrder
    );
  };

  updateResizeColumns = resizeColumns => {
    this.setState({
      resizeColumns,
    });
  };
}

AppTableNurseAudit.propTypes = AppTablePropType;

AppTableNurseAudit.defaultProps = {
  size: 'sm',
  dark: true,
  striped: true,
  currentPage: 0,
  pageSize: 20,
  sortAble: false,
  sortImmediately: true,
  resizable: false,
  hover: true,
};

export default AppTableNurseAudit;
