import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import classNames from 'classnames';
import { faAsterisk, faWindowClose, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  ModalHeader,
  ModalBody,
  CustomInput,
  Col,
  Row,
  Label,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign, isEmpty, isUndefined } from 'lodash';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { genderData, sideOfBody } from '../utils/constantUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { propTypes } from '../../../../node_modules/react-bootstrap/esm/Image';

let diagnosisItems = {};
let newInjuryList = [];
const NewClaimantModal = ({
  logBreadCrumbs,
  isOpen,
  onClose,
  userId,
  userRole,
  getClaimantList,
  claimantInfo,
  updatedClaimInfo,
  resetSearchClaimant,
  getLoadClaimantById,
  getLoadClaim,
  getDxCodeDesc,
  deleteICD,
  deleteInjury,
  loadDXInfo,
  language,
  states,
  injuredBodyPart,
  workStatus,
  causes,
  injuryNature,
  resetLoadClaim,
  loadClaimInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadDX,
  saveClaim,
  resetSaveClaim,
  alert,
  isNew,
  setPatientEdited,
  claimFeet,
  externalRefData,
  fromExternal,
  fromWorklist,
  worklistData,
  updatePatientZip,
  patientInfo,
  setPatientData,
  setPopulatedRef,
  updatedEmployerInfo,
  updatedAttorneyInfo,
  loadClientWithFees,
  updatedPayorInfo
}) => {
  const [isOpenLoadClaimantModal, setIsOpenLoadClaimantModal] = useState(false);
  const [isDiagnosisList, setIsDiagnosisList] = useState({});
  const [injuryIndex, setInjuryIndex] = useState(null);
  const [isDXCodeDesc, setIsDXCodeDesc] = useState({});
  const [isViewMode, setIsViewMode] = useState(false);
  const [isOpenSeachClaimant, setIsOpenSeachClaimant] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDXOpen, setIsDXOpen] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(
    false
  );
  const [zipDropdownOpen, setZipDropdownOpen] = useState(false);



  const getFirstName = (data) => {
    let value = '';
    if (fromWorklist && data && data.claim && data.claim.claimant && data.claim.claimant.people.first)
      value = data.claim.claimant.people.first
    else if (!fromWorklist && externalRefData && externalRefData.PatientFirstName)
      value = externalRefData?.PatientFirstName
    return value;
  }
  const getLastName = (data) => {
    let value = '';
    if (fromWorklist && data && data.claim && data.claim.claimant && data.claim.claimant.people.last)
      value = data.claim.claimant.people.last
    else if (!fromWorklist && externalRefData && externalRefData.PatientLastName)
      value = externalRefData?.PatientLastName
    return value;
  }
  const getClaimNumber = (data) => {
    let value = '';
    if (fromWorklist && data && data.claim && data.claim.claimNo)
      value = data.claim.claimNo
    else if (!fromWorklist && externalRefData && externalRefData.ClaimNumber)
      value = externalRefData?.ClaimNumber
    return value;
  }
  const [searchFields, setSearchFields] = useState({
    firstName: getFirstName(worklistData),
    lastName: getLastName(worklistData),
    claimNumber: getClaimNumber(worklistData),
    claimantAddress: externalRefData?.PatientAddress || '',
    claimantCity: externalRefData?.PatientCity || '',
    claimantState: externalRefData?.PatientState || '',
    claimantZip: externalRefData?.PatientZip || '',
    claimantPhone: externalRefData?.PatientPhone || '',
    claimantDOB: '',//externalRefData?.PatientDateOfBirth != "" ? new Date(externalRefData.PatientDateOfBirth) : '',
    claimDOI: '',// externalRefData?.PatientDateOfInjury != "" ? new Date(externalRefData.PatientDateOfInjury) : '',
    claimantHeight: externalRefData?.PatientHeightFeet || '',
    claimantInches: externalRefData?.PatientHeightInches || '',
    claimantWeight: externalRefData?.PatientWeight || '',
  });
  const [isShowClaimant, setIsShowClaimant] = useState(true);
  const [isShowClaim, setIsShowClaim] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [formClaimantFields, setFormClaimantFields] = useState({
    claimantFirstName: externalRefData?.PatientFirstName || '',
    claimantMI: '',
    claimantLastName: externalRefData?.PatientLastName || '',
    claimYorkClient: '',
    claimPolicyNo: '',
    claimYorkClientId: '',
    claimProgramName: '',
    claimPolicyHolder: '',
    claimNumber: externalRefData?.ClaimNumber || '',
    claimDOI: '',// externalRefData?.PatientDateOfInjury != "" ? new Date(externalRefData.PatientDateOfInjury) : '',
    claimDisStart: '',
    claimDisEnd: '',
    claimantEmail: '',
    claimantAlert: '',
    okToContact: true,
    okToText: true,
    catastrophic: false,
    claimStateOfAccident: '',
    claimStateOfJuris: '',
    claimSettlementDate: '',
    claimInjuredBodyPart: '',
    claimNatureOfInjury: '',
    claimCauseOfInjury: '',
    claimWorkStatus: '',
    claimStatus: '',
    claimantDOB: '',// externalRefData?.PatientDateOfBirth != "" ? new Date(externalRefData.PatientDateOfBirth) : '',
    claimantSSN: '',
    claimantHeight: externalRefData?.PatientHeightFeet || '',
    claimantInches: externalRefData?.PatientHeightInches || '',
    claimantGender: '',
    claimantWeight: externalRefData?.PatientWeight || '',
    claimantPhone: externalRefData?.PatientPhone || '',
    claimantPhoneExt: '',
    claimantCell: '',
    claimantAddress: externalRefData?.PatientAddress || '',
    claimantAddress2: '',
    claimantLang: '',
    claimantCity: externalRefData?.PatientCity || '',
    claimantCounty: '',
    claimantState: externalRefData?.PatientState || '',
    claimantZip: externalRefData?.PatientZip || '',
    claimantZipCodeId: '',
    claimantZipId: '',
    claimantTimeZone: '',
    claimantNotes: '',
    claimDXCode: [],
    claimDXDesc: [],
  });
  const [validClaimNum, setValidClaimNum] = useState(true);
  const [validDoi, setValidDoi] = useState(true);
  const [validFirstname, setValidFirstname] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validAddress, setValidAddress] = useState(true);
  const [validZip, setValidZip] = useState(true);


  useEffect(() => {
    const zip = externalRefData?.PatientZip
    const params = {
      userId: userId,
      zipCode: zip && zip.substr(0, 5),
    };
    if (isOpen)
      getZipList(params);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENCLAIMANT',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb);
      if (isNew || updatedClaimInfo.claim.id === 0) {
        if (!isNew && !claimantInfo) {
          const params = {
            firstName:
              updatedClaimInfo.claim &&
              updatedClaimInfo.claim.claimant &&
              updatedClaimInfo.claim.claimant.people.first,
            lastName:
              updatedClaimInfo.claim &&
              updatedClaimInfo.claim.claimant &&
              updatedClaimInfo.claim.claimant.people.last,
            claimNumber:
              updatedClaimInfo.claim && updatedClaimInfo.claim.claimNo,
            userId,
          };
          getClaimantList(params);
        }
        setIsOpenSeachClaimant(true);
      } else {
        setIsOpenLoadClaimantModal(true);
      }
      setSearchFields({
        firstName: getFirstName(worklistData),
        lastName: getLastName(worklistData),
        claimNumber: getClaimNumber(worklistData),
        claimantAddress: externalRefData?.PatientAddress || '',
        claimantCity: externalRefData?.PatientCity || '',
        claimantState: externalRefData?.PatientState || '',
        claimantZip: externalRefData?.PatientZip || '',
        claimantPhone: externalRefData?.PatientPhone || '',
        claimantDOB: '',// externalRefData?.PatientDateOfBirth != "" ? new Date(externalRefData.PatientDateOfBirth) : '',
        claimantHeight: externalRefData?.PatientHeightFeet || '',
        claimantInches: externalRefData?.PatientHeightInches || '',
        claimantWeight: externalRefData?.PatientWeight || '',
        PatientFeet: claimFeet ? claimFeet : '',
        claimDOI: '',// externalRefData?.PatientDateOfInjury != "" ? new Date(externalRefData.PatientDateOfInjury) : '',
      });
      const payload = {
        firstName: getFirstName(worklistData),
        lastName: getLastName(worklistData),
        claimNumber: getClaimNumber(worklistData),
        userId,
      };
      getFirstName(worklistData) && getClaimantList(payload);
    }
  }, [isOpen]);

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.claim?.id) {
        const item = claimantInfo && claimantInfo.filter(o => o.PK_claims_id === worklistData?.claim?.id);
        let index = claimantInfo && claimantInfo.findIndex(i => i.PK_claims_id === worklistData?.claim?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [claimantInfo])

  useEffect(() => {
    if (isOpenLoadClaimantModal) {
      if (selectedItem) {
        const claimParams = {
          userId,
          functionName: 'loadClaim',
          claimsId: selectedItem.PK_claims_id,
        };
        getLoadClaim(claimParams);
      } else if (!isNew && !claimantInfo) {
        if (!isEmpty(updatedClaimInfo) && updatedClaimInfo.claim.id !== 0) {
          const params = {
            userId,
            functionName: 'loadClaim',
            claimsId: updatedClaimInfo.id,
          };
          getLoadClaim(params);
        } else {
          return;
        }
        if (!isEmpty(updatedClaimInfo)) {
          const pplCheck = updatedClaimInfo.claim.claimant.people || {};
          const adrCheck = updatedClaimInfo.claim.claimant.address || {};
          let changeDate = '';
          if (pplCheck.dob) {
            changeDate = pplCheck.dob.replace(/-/g, '/');
          }
          let doiDate = '';
          if (updatedClaimInfo.claim.doi) {
            doiDate = updatedClaimInfo.claim.doi.replace(/-/g, '/');
          }
          let openonDate = '';
          if (updatedClaimInfo.claim.openon) {
            openonDate = updatedClaimInfo.claim.openon.replace(/-/g, '/');
          }
          let closedonDate = '';
          if (updatedClaimInfo.claim.closedon) {
            closedonDate = updatedClaimInfo.claim.closedon.replace(/-/g, '/');
          }
          setFormClaimantFields({
            claimantFirstName: pplCheck.first,
            claimantMI: pplCheck.middle,
            claimantLastName: pplCheck.last,
            claimantPhone:
              pplCheck && pplCheck.phone && formatPhoneNum(pplCheck.phone),
            claimantPhoneExt: pplCheck && pplCheck.phoneExt,
            claimantEmail: pplCheck.email1,
            claimantSSN: pplCheck.ssn,
            claimantGender: pplCheck.genderId,
            claimantCell:
              pplCheck &&
              pplCheck.cellPhone &&
              formatPhoneNum(pplCheck.cellPhone),
            claimantAddress: adrCheck.street1,
            claimantZipCodeId: adrCheck.zipCodeId,
            claimantZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            claimantTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            claimantAddress2: adrCheck.street2,
            claimantCity: adrCheck.zipCode && adrCheck.zipCode.city,
            claimantCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            claimantState: adrCheck.zipCode && adrCheck.zipCode.state,
            claimantZip: adrCheck.zipCode && adrCheck.zipCode.zip,
            claimDOI: doiDate && new Date(doiDate),
            claimDisStart: openonDate && new Date(openonDate),
            claimDisEnd: openonDate && new Date(closedonDate),
            claimantDOB: changeDate && new Date(changeDate),
            claimantHeight: updatedClaimInfo.claim.claimant.heightFeet,
            claimantInches: updatedClaimInfo.claim.claimant.heightInch,
            claimantLang: updatedClaimInfo.claim.claimant.languageId,
            claimantWeight: updatedClaimInfo.claim.claimant.weight,
            okToContact: updatedClaimInfo.claim.claimant.okToContact,
            okToText: updatedClaimInfo.claim.claimant.okToText,
            claimantAlert: updatedClaimInfo.claim.claimant.alert,
            claimYorkClient: updatedClaimInfo.claim.yorkClient,
            claimPolicyNo: updatedClaimInfo.claim.policyNumber,
            claimYorkClientId: updatedClaimInfo.claim.yorkClientId,
            claimProgramName: updatedClaimInfo.claim.programName,
            claimPolicyHolder: updatedClaimInfo.claim.policyHolder,
            claimNumber: updatedClaimInfo.claim.claimNo,
            claimStateOfAccident: updatedClaimInfo.claim.accidentState,
            claimStateOfJuris: updatedClaimInfo.claim.jurisState,
            claimSettlementDate: updatedClaimInfo.claim.settlementdate,
            claimInjuredBodyPart: updatedClaimInfo.claim.injuredBodyPart,
            claimNatureOfInjury: updatedClaimInfo.claim.injNatureId,
            claimCauseOfInjury: updatedClaimInfo.claim.injCauseId,
            claimWorkStatus: updatedClaimInfo.claim.workStatusId,
            claimStatus: updatedClaimInfo.claim.claimstat,
            catastrophic: updatedClaimInfo.claim.catastrophic,
          });
        }
      }
    }
  }, [isOpenLoadClaimantModal]);

  useEffect(() => {
    if (!isEmpty(loadClaimInfo)) {
      const pplCheck =
        (loadClaimInfo.claimant && loadClaimInfo.claimant.people) || {};
      const adrCheck =
        (loadClaimInfo.claimant && loadClaimInfo.claimant.address) || {};
      let changeDate = '';
      if (pplCheck.dob) {
        changeDate = pplCheck.dob.replace(/-/g, '/');
      }
      let doiDate = '';
      if (loadClaimInfo.doi) {
        doiDate = loadClaimInfo.doi.replace(/-/g, '/');
      }
      let openonDate = '';
      if (loadClaimInfo.openon) {
        openonDate = loadClaimInfo.openon.replace(/-/g, '/');
      }
      let closedonDate = '';
      if (loadClaimInfo.closedon) {
        closedonDate = loadClaimInfo.closedon.replace(/-/g, '/');
      }
      setFormClaimantFields({
        claimantFirstName: pplCheck.first,
        claimantMI: pplCheck.middle,
        claimantLastName: pplCheck.last,
        claimYorkClient: loadClaimInfo.yorkClient,
        claimPolicyNo: loadClaimInfo.policyNumber,
        claimYorkClientId: loadClaimInfo.yorkClientId,
        claimProgramName: loadClaimInfo.programName,
        claimPolicyHolder: loadClaimInfo.policyHolder,
        claimNumber: loadClaimInfo.claimNo,
        claimDOI: doiDate && new Date(doiDate),
        claimDisStart: openonDate && new Date(openonDate),
        claimDisEnd: closedonDate && new Date(closedonDate),
        claimStateOfAccident: loadClaimInfo.accidentState,
        claimStateOfJuris: loadClaimInfo.jurisState,
        claimSettlementDate: loadClaimInfo.settlementdate,
        claimInjuredBodyPart: loadClaimInfo.injuredBodyPart,
        claimNatureOfInjury: loadClaimInfo.injNatureId,
        claimCauseOfInjury: loadClaimInfo.injCauseId,
        claimWorkStatus: loadClaimInfo.workStatusId,
        claimStatus: loadClaimInfo.claimstat,
        claimantEmail: pplCheck.email1,
        claimantAlert: loadClaimInfo.claimant && loadClaimInfo.claimant.alert,
        okToContact:
          loadClaimInfo.claimant && loadClaimInfo.claimant.okToContact,
        okToText: loadClaimInfo.claimant && loadClaimInfo.claimant.okToText,
        catastrophic: loadClaimInfo.catastrophic,
        claimantDOB: changeDate && new Date(changeDate),
        claimantSSN: pplCheck.ssn,
        claimantHeight:
          loadClaimInfo.claimant && loadClaimInfo.claimant.heightFeet,
        claimantInches:
          loadClaimInfo.claimant && loadClaimInfo.claimant.heightInch,
        claimantLang:
          loadClaimInfo.claimant && loadClaimInfo.claimant.languageId,
        claimantGender: pplCheck.genderId,
        claimantWeight: loadClaimInfo.claimant && loadClaimInfo.claimant.weight,
        claimantPhone:
          pplCheck && pplCheck.phone && formatPhoneNum(pplCheck.phone),
        claimantPhoneExt: pplCheck && pplCheck.phoneExt,
        claimantCell:
          pplCheck &&
          pplCheck.cellPhone &&
          formatPhoneNum(pplCheck.cellPhone),
        claimantAddress: adrCheck.street1,
        claimantZipCodeId: adrCheck.zipCodeId,
        claimantZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        claimantTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        claimantAddress2: adrCheck.street2,
        claimantCity: adrCheck.zipCode && adrCheck.zipCode.city,
        claimantCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        claimantState: adrCheck.zipCode && adrCheck.zipCode.state,
        claimantZip: adrCheck.zipCode && adrCheck.zipCode.zip,
      });
    }
  }, [loadClaimInfo]);

  useEffect(() => {
    if (formClaimantFields.claimantZip || fromWorklist || (!isEmpty(worklistData))) {
      if (fromWorklist && (!isEmpty(worklistData))) {
        updatePatientZip({
          ...patientInfo, zipPatient: worklistData?.claim?.claimant?.address?.zipCode?.zip || '',
          statePatient: worklistData?.claim?.claimant?.address?.zipCode?.state || '',
          jurisPatientState: worklistData?.claim?.jurisState
        })
      }
      else if (formClaimantFields.claimantZip) {
        updatePatientZip({
          ...patientInfo, zipPatient: formClaimantFields.claimantZip, statePatient: formClaimantFields.claimantState,
          jurisPatientState: formClaimantFields.claimStateOfJuris
        })
      }
    }
  }, [formClaimantFields.claimantZip, fromWorklist, worklistData]);
  
  useEffect(() => {
    if(!isEmpty(loadClaimInfo) && isEmpty(updatedPayorInfo)) {
      if(loadClaimInfo?.sedgwickPKClientId) {
      const clientParams = {
        userId,
        functionName: 'loadClientData',
        id: loadClaimInfo?.sedgwickPKClientId
      };
      loadClientWithFees(clientParams);
    }
  }
  },[loadClaimInfo]);

  const loadClaimantInfoToggle = () => {
    setIsOpenLoadClaimantModal(!isOpenLoadClaimantModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    setIsShowClaimant(true);
    setIsShowClaim(false);
    setIsDXCodeDesc({});
    setIsDiagnosisList({});
    diagnosisItems = {};
    newInjuryList = [];
    if (isNew) {
      setFormClaimantFields({ ...formClaimantFields, claimantFirstName: '', claimantLastName: '', claimantAddress: '', claimantZip: '', claimNumber: '', claimDOI: '' })
    }
  };

  const searchClaimantToggle = () => {
    setIsOpenSeachClaimant(!isOpenSeachClaimant);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      ...searchFields,
      firstName: '',
      lastName: '',
      claimNumber: '',
    });
    resetSearchClaimant();
    resetLoadClaim();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };
  useEffect(() => {
    if (!formClaimantFields.claimNumber)
      setValidClaimNum(false);
    else setValidClaimNum(true);
    if (!formClaimantFields.claimDOI)
      setValidDoi(false);
    else setValidDoi(true);
    if (!formClaimantFields.claimantFirstName)
      setValidFirstname(false);
    else setValidFirstname(true);
    if (!formClaimantFields.claimantLastName)
      setValidLastName(false);
    else setValidLastName(true);
    if (!formClaimantFields.claimantAddress)
      setValidAddress(false);
    else setValidAddress(true);
    if (!formClaimantFields.claimantZip)
      setValidZip(false);
    else setValidZip(true);
  }, [formClaimantFields])

  const setMap = {
    claimNumber: setValidClaimNum,
    claimDOI: setValidDoi,
    claimantFirstName: setValidFirstname,
    claimantLastName: setValidLastName,
    claimantAddress: setValidAddress,
    claimantZip: setValidZip
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "claimantPhone" || name == "claimantCell") {
      value = formatPhoneNum(value);
    }
    setFormClaimantFields({ ...formClaimantFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };

  const onDXToggle = () => {
    setIsDXOpen(!isDXOpen);
  }

  const getContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              Please select an injury from the list.
              <br />
              <br />
              <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={() => onDXToggle()}>
                Ok{' '}
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  const onLoadInjuryHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (selectedItem || (!isNew && !claimantInfo)) {
      loadClaimInfo.injuryList[injuryIndex] = {
        ...isDiagnosisList,
        [name]: value,
      };
    } else {
      newInjuryList[injuryIndex] = {
        ...isDiagnosisList,
        [name]: value,
      };
    }
    setIsDiagnosisList({ ...isDiagnosisList, [name]: value });
    diagnosisItems = { ...isDiagnosisList, [name]: value };
  };

  const onChangeInjuryHandlerType = (id, value) => {
    if (selectedItem || (!isNew && !claimantInfo)) {
      loadClaimInfo.injuryList[injuryIndex] = {
        ...isDiagnosisList,
        [id]: value,
      };
    } else {
      newInjuryList[injuryIndex] = {
        ...isDiagnosisList,
        [id]: value,
      };
    }
    setIsDiagnosisList({ ...isDiagnosisList, [id]: value });
    diagnosisItems = { ...isDiagnosisList, [id]: value };
  };

  const onChangeDateHandler = (id, value) => {
    setFormClaimantFields({ ...formClaimantFields, [id]: value });
  };

  const isDisabledInput = () => {
    return userRole === 'Administrator' ? false : true;
  };
  const getClaimNo = item => {
    return item.chr_claim_no?.split(' ').join('\u00A0')
  }
  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchClaimantTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'Claim#',
              field: 'chr_claim_no',
              render: item => getClaimNo(item),
            },
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'DOI',
              field: 'dat_doi',
            },
            {
              header: 'DOB',
              field: 'dat_dob',
            },
            {
              header: 'MCCI',
              field: 'fromMCCI',
              render: (item) => {
                if (item.fromMCCI && item.fromMCCI == 1) {
                  return <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: '#33aa33' }}
                  />
                } else {
                  return '';
                }
              }
            }
          ]}
          data={claimantInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-Claimant-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadClaimantModal = () => {
    setIsOpenSeachClaimant(false);
    setIsOpenLoadClaimantModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getClaimantList(params);
  };

  const onChangeCurrentClaimant = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
      claimNumber: '',
    });
    setIsOpenLoadClaimantModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachClaimant(true);
    setIsDXCodeDesc({});
    setIsDiagnosisList({});
    newInjuryList = [];
    diagnosisItems = {};
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length == 0 &&
      (event.keyCode === 8)
    ) {
      return;
    }

    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      setZipDropdownOpen(true);
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      setZipDropdownOpen(false);
      resetZipList();
    }
  };

  const handleTypeAheadDXSearch = (event, type) => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      let params = {};
      if (type === 'claimDXCode') {
        params = {
          functionName: 'dxLookup',
          userId: userId,
          code: event.target.value,
          desc: '',
        };
      } else {
        params = {
          functionName: 'dxLookup',
          userId: userId,
          code: '',
          desc: event.target.value,
        };
      }
      getDxCodeDesc(params);
    } else if (event.target.value.length > 0 && loadDXInfo.length) {
      resetLoadDX();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    if (!event) {
      return;
    }
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormClaimantFields({
        ...formClaimantFields,
        claimantCity: selectedZip.chr_city,
        claimantState: selectedZip.chr_state,
        claimantCounty: selectedZip.chr_county,
        claimantZip: selectedZip.chr_zipcode,
        claimantZipCodeId: selectedZip.code_id,
        claimantZipId: selectedZip.code_id,
        claimantTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const handleDXFields = (event, id, actionType) => {
    if (actionType && actionType.action === 'clear') {
      switch (id) {
        case 'claimDXCode':
          resetLoadDX();
          break;
        case 'claimDXDesc':
          resetLoadDX();
          break;
      }
      setFormClaimantFields({
        ...formClaimantFields,
        claimDXCode: [],
        claimDXDesc: [],
      });
      setIsDXCodeDesc({});
    } else {
      const selectedDX =
        loadDXInfo.length > 0 &&
        loadDXInfo.find(obj => obj.int_id === event.value);
      if (selectedDX) {
        setFormClaimantFields({
          ...formClaimantFields,
          claimDXCode: [
            { value: selectedDX.int_id, label: selectedDX.chr_icd },
          ],
          claimDXDesc: [
            { value: selectedDX.int_id, label: selectedDX.chr_description },
          ],
        });
        setIsDXCodeDesc(selectedDX);
      }
      resetLoadDX();
    }
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVECLAIMANT',
      refTableId: loadClaimInfo.id,
      refId: 0,
      refTable: 'CLM',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem) {
      const response = loadClaimInfo;
      const inj = loadClaimInfo.injuryList;
      let addId;
      addId = response.claimant?.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.claimant.people.first = formClaimantFields.claimantFirstName;
      response.claimant.people.middle = formClaimantFields.claimantMI;
      response.yorkClient = formClaimantFields.claimYorkClient;
      response.yorkClientId = formClaimantFields.claimYorkClientId;
      response.programName = formClaimantFields.claimProgramName;
      response.policyHolder = formClaimantFields.claimPolicyHolder;
      response.claimNo = formClaimantFields.claimNumber;
      response.doi = formClaimantFields.claimDOI
        ? moment(formClaimantFields.claimDOI).format('YYYY-MM-DD')
        : '';
      response.openon = formClaimantFields.claimDisStart
        ? moment(formClaimantFields.claimDisStart).format('YYYY-MM-DD')
        : '';
      response.closedon = formClaimantFields.claimDisEnd
        ? moment(formClaimantFields.claimDisEnd).format('YYYY-MM-DD')
        : '';
      response.policyNumber = formClaimantFields.claimPolicyNo;
      response.accidentState = formClaimantFields.claimStateOfAccident;
      response.jurisState = formClaimantFields.claimStateOfJuris;
      response.settlementdate = formClaimantFields.claimSettlementDate;
      response.injNatureId = formClaimantFields.claimNatureOfInjury;
      response.injCauseId = formClaimantFields.claimCauseOfInjury;
      response.workStatusId = formClaimantFields.claimWorkStatus;
      response.claimstat = formClaimantFields.claimStatus;
      response.claimant.people.last = formClaimantFields.claimantLastName;
      response.claimant.people.email1 = formClaimantFields.claimantEmail;
      response.claimant.people.dob = formClaimantFields.claimantDOB
        ? moment(formClaimantFields.claimantDOB).format('YYYY-MM-DD')
        : '';
      response.claimant.people.ssn = formClaimantFields.claimantSSN;
      response.claimant.people.genderId = formClaimantFields.claimantGender;
      response.claimant.languageId = formClaimantFields.claimantLang;
      response.claimant.heightFeet = formClaimantFields.claimantHeight;
      response.claimant.heightInch = formClaimantFields.claimantInches;
      response.claimant.weight = formClaimantFields.claimantWeight;
      response.claimant.alert = formClaimantFields.claimantAlert;
      response.claimant.okToContact = formClaimantFields.okToContact;
      response.catastrophic = formClaimantFields.catastrophic;
      response.claimant.okToText = formClaimantFields.okToText;
      response.claimant.people.phone =
        formClaimantFields.claimantPhone &&
        formClaimantFields.claimantPhone.replace(/[\s()-]/g, '');
      response.claimant.people.phoneExt = formClaimantFields.claimantPhoneExt;
      response.claimant.people.cellPhone =
        formClaimantFields.claimantCell &&
        formClaimantFields.claimantCell.replace(/[\s()-]/g, '');
      response.claimant.address.street1 = formClaimantFields.claimantAddress;
      response.claimant.address.street2 = formClaimantFields.claimantAddress2;
      response.claimant.address.zipCode.city = formClaimantFields.claimantCity;
      response.claimant.address.zipCode.county =
        formClaimantFields.claimantCounty;
      response.claimant.address.zipCode.state =
        formClaimantFields.claimantState;
      response.claimant.address.zipCode.zip = formClaimantFields.claimantZip;
      response.claimant.address.zipCode.id = formClaimantFields.claimantZipId;
      response.claimant.address.zipCodeId =
        formClaimantFields.claimantZipCodeId;
      response.injuryList = inj;
      response.jsonClaimCore = response.jsonClaimCore
        ? response.jsonClaimCore
        : '';
      response.jsonClaimant = response.jsonClaimant
        ? response.jsonClaimant
        : '';
      response.jsonClaimExpanded = response.jsonClaimExpanded
        ? response.jsonClaimExpanded
        : '';
      response.jsonClaimContact = response.jsonClaimContact
        ? response.jsonClaimContact
        : '';
      response.jsonClaimId = response.jsonClaimId ? response.jsonClaimId : '';
      response.jurisProperClaimId = response.jurisProperClaimId
        ? response.jurisProperClaimId
        : 0;
      response.jurisShellClaimId = response.jurisShellClaimId
        ? response.jurisShellClaimId
        : 0;
      const claimId = response.id;
      const params = {
        response,
        userId,
        claimId,
      };
      if (
        response.claimant.people.first === '' ||
        response.claimant.people.first === undefined
      ) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (
        response.claimant.people.last === '' ||
        response.claimant.people.last === undefined
      ) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.claimant.address.zipCode.zip === '' ||
        response.claimant.address.zipCode.zip === undefined
      ) {
        alert('You must provide a zip in order to save.');
        return;
      }
      if (
        response.claimant.address.street1 === '' ||
        response.claimant.address.street1 === undefined
      ) {
        alert('You must provide a street address in order to save.');
        return;
      }
      if (response.claimNo === '' || response.claimNo === undefined) {
        alert('You must provide a Claim Number in order to save.');
        return;
      }
      if (response.doi === '' || response.doi === undefined) {
        alert('You must provide a date of Injury in order to save.');
        return;
      }
      saveClaim(params);
      const paramsClaim = {
        userId,
        functionName: 'loadByClaimId',
        claimsId: claimId,
      };
      setPopulatedRef(true);
      getLoadClaimantById(paramsClaim);
      loadClaimantInfoToggle();
    } else if (!isNew && !claimantInfo) {
      const response = updatedClaimInfo.claim;
      const inj = loadClaimInfo.injuryList;
      let addId;
      addId = response.claimant.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.claimant.people.first = formClaimantFields.claimantFirstName;
      response.claimant.people.middle = formClaimantFields.claimantMI;
      response.claimant.people.last = formClaimantFields.claimantLastName;
      response.claimant.people.email1 = formClaimantFields.claimantEmail;
      response.claimant.people.dob = formClaimantFields.claimantDOB
        ? moment(formClaimantFields.claimantDOB).format('YYYY-MM-DD')
        : '';
      response.claimant.people.ssn = formClaimantFields.claimantSSN;
      response.claimant.people.genderId = formClaimantFields.claimantGender;
      response.claimant.people.phone =
        formClaimantFields.claimantPhone &&
        formClaimantFields.claimantPhone.replace(/[\s()-]/g, '');
      response.claimant.people.phoneExt = formClaimantFields.claimantPhoneExt;
      response.claimant.people.cellPhone =
        formClaimantFields.claimantCell &&
        formClaimantFields.claimantCell.replace(/[\s()-]/g, '');
      response.claimant.address.street1 = formClaimantFields.claimantAddress;
      response.claimant.address.street2 = formClaimantFields.claimantAddress2;
      response.claimant.address.zipCode.city = formClaimantFields.claimantCity;
      response.claimant.address.zipCode.county =
        formClaimantFields.claimantCounty;
      response.claimant.address.zipCode.state =
        formClaimantFields.claimantState;
      response.claimant.address.zipCode.zip = formClaimantFields.claimantZip;
      response.claimant.address.zipCode.id = formClaimantFields.claimantZipId;
      response.claimant.address.zipCodeId =
        formClaimantFields.claimantZipCodeId;
      response.claimant.languageId = formClaimantFields.claimantLang;
      response.claimant.heightFeet = formClaimantFields.claimantHeight;
      response.claimant.heightInch = formClaimantFields.claimantInches;
      response.claimant.weight = formClaimantFields.claimantWeight;
      response.claimant.alert = formClaimantFields.claimantAlert;
      response.claimant.okToContact = formClaimantFields.okToContact;
      response.claimant.okToText = formClaimantFields.okToText;
      response.yorkClient = formClaimantFields.claimYorkClient;
      response.yorkClientId = formClaimantFields.claimYorkClientId;
      response.programName = formClaimantFields.claimProgramName;
      response.policyHolder = formClaimantFields.claimPolicyHolder;
      response.claimNo = formClaimantFields.claimNumber;
      response.doi = formClaimantFields.claimDOI
        ? moment(formClaimantFields.claimDOI).format('YYYY-MM-DD')
        : '';
      response.openon = formClaimantFields.claimDisStart
        ? moment(formClaimantFields.claimDisStart).format('YYYY-MM-DD')
        : '';
      response.closedon = formClaimantFields.claimDisEnd
        ? moment(formClaimantFields.claimDisEnd).format('YYYY-MM-DD')
        : '';
      response.policyNumber = formClaimantFields.claimPolicyNo;
      response.accidentState = formClaimantFields.claimStateOfAccident;
      response.jurisState = formClaimantFields.claimStateOfJuris;
      response.settlementdate = formClaimantFields.claimSettlementDate;
      response.injNatureId = formClaimantFields.claimNatureOfInjury;
      response.injCauseId = formClaimantFields.claimCauseOfInjury;
      response.workStatusId = formClaimantFields.claimWorkStatus;
      response.claimstat = formClaimantFields.claimStatus;
      response.catastrophic = formClaimantFields.catastrophic;
      response.injuryList = inj;
      response.jsonClaimCore = response.jsonClaimCore
        ? response.jsonClaimCore
        : '';
      response.jsonClaimant = response.jsonClaimant
        ? response.jsonClaimant
        : '';
      response.jsonClaimExpanded = response.jsonClaimExpanded
        ? response.jsonClaimExpanded
        : '';
      response.jsonClaimContact = response.jsonClaimContact
        ? response.jsonClaimContact
        : '';
      response.jsonClaimId = response.jsonClaimId ? response.jsonClaimId : '';
      response.jurisProperClaimId = response.jurisProperClaimId
        ? response.jurisProperClaimId
        : 0.0;
      response.jurisShellClaimId = response.jurisShellClaimId
        ? response.jurisShellClaimId
        : 0.0;
      const claimId = response.id;
      const params = {
        response,
        userId,
        claimId,
      };
      if (
        response.claimant.people.first === '' ||
        response.claimant.people.first === undefined
      ) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (
        response.claimant.people.last === '' ||
        response.claimant.people.last === undefined
      ) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.claimant.address.zipCode.zip === '' ||
        response.claimant.address.zipCode.zip === undefined
      ) {
        alert('You must provide a zip in order to save.');
        return;
      }
      if (
        response.claimant.address.street1 === '' ||
        response.claimant.address.street1 === undefined
      ) {
        alert('You must provide a street address in order to save.');
        return;
      }
      if (response.claimNo === '' || response.claimNo === undefined) {
        alert('You must provide a Claim Number in order to save.');
        return;
      }
      if (response.doi === '' || response.doi === undefined) {
        alert('You must provide a date of Injury in order to save.');
        return;
      }
      saveClaim(params);
      loadClaimantInfoToggle();
    } else {
      let response = {};
      const inj = newInjuryList;
      let zipID;
      const zipId = () => {
        if (fromExternal.fromExternal) {
          if (zipList.length) {
            zipID = zipList[0].code_id;
          }
          else
            zipID = '0'
        }
        else
          zipID = formClaimantFields.claimantZipId
        return zipID;
      }
      response = {
        id: 0,
        catastrophic: formClaimantFields.catastrophic,
        yorkClient: formClaimantFields.claimYorkClient,
        policyNumber: formClaimantFields.claimPolicyNo,
        yorkClientId: formClaimantFields.claimYorkClientId,
        programName: formClaimantFields.claimProgramName,
        policyHolder: formClaimantFields.claimPolicyHolder,
        claimNo: formClaimantFields.claimNumber,
        injuryList: inj,
        jsonClaimCore: '',
        jsonClaimant: '',
        jsonClaimExpanded: '',
        jsonClaimContact: '',
        jsonClaimId: '',
        jurisProperClaimId: 0.0,
        jurisShellClaimId: 0.0,
        doi: formClaimantFields.claimDOI
          ? moment(formClaimantFields.claimDOI).format('YYYY-MM-DD')
          : '',
        accidentState: formClaimantFields.claimStateOfAccident,
        jurisState: formClaimantFields.claimStateOfJuris,
        settlementdate: formClaimantFields.claimSettlementDate,
        // injuredBodyPart: formClaimantFields.claimInjuredBodyPart,
        injNatureId: formClaimantFields.claimNatureOfInjury,
        injCauseId: formClaimantFields.claimCauseOfInjury,
        workStatusId: formClaimantFields.claimWorkStatus,
        claimstat: formClaimantFields.claimStatus,
        claimant: {
          peopleId: 0,
          addressId: 0,
          languageId: formClaimantFields.claimantLang,
          heightFeet: formClaimantFields.claimantHeight,
          heightInch: formClaimantFields.claimantInches,
          weight: formClaimantFields.claimantWeight,
          alert: formClaimantFields.claimantAlert,
          okToContact: formClaimantFields.okToContact,
          okToText: formClaimantFields.okToText,
          people: {
            id: 0,
            first: formClaimantFields.claimantFirstName,
            middle: formClaimantFields.claimantMI,
            last: formClaimantFields.claimantLastName,
            email1: formClaimantFields.claimantEmail,
            heightFeet: formClaimantFields.claimantHeight,
            heightInch: formClaimantFields.claimantInches,
            weight: formClaimantFields.claimantWeight,
            dob: formClaimantFields.claimantDOB
              ? moment(formClaimantFields.claimantDOB).format('YYYY-MM-DD')
              : '',
            ssn: formClaimantFields.claimantSSN,
            genderId: formClaimantFields.claimantGender,
            phone:
              formClaimantFields.claimantPhone &&
              formClaimantFields.claimantPhone.replace(/[\s()-]/g, ''),
            phoneExt: formClaimantFields.claimantPhoneExt,
            cellPhone:
              formClaimantFields.claimantCell &&
              formClaimantFields.claimantCell.replace(/[\s()-]/g, ''),
          },
          address: {
            id: 0,
            street1: formClaimantFields.claimantAddress,
            street2: formClaimantFields.claimantAddress2,
            zipCodeId: zipId(),
            zipCode: {
              city: formClaimantFields.claimantCity,
              county: formClaimantFields.claimantCounty,
              state: formClaimantFields.claimantState,
              zip: formClaimantFields.claimantZip,
              id: zipId(),
              timeZone: formClaimantFields.claimantTimeZone,
            },
          },
        },
        employerId: updatedEmployerInfo && updatedEmployerInfo?.employer?.id || 0,
        employer: updatedEmployerInfo?.employer || {
          people: {},
          address: {
            zipCode: {},
          },
          id: 0,
        },
        attorneyId: updatedAttorneyInfo && updatedAttorneyInfo.id || 0,
        attorney: updatedAttorneyInfo || {
          people: {},
          address: {
            zipCode: {},
          },
          id: 0,
        },
      };
      if (
        response.claimant.people.first === '' ||
        response.claimant.people.first === undefined
      ) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (
        response.claimant.people.last === '' ||
        response.claimant.people.last === undefined
      ) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.claimant.address.zipCode.zip === '' ||
        response.claimant.address.zipCode.zip === undefined
      ) {
        alert('You must provide a zip in order to save.');
        return;
      }
      if (
        response.claimant.address.street1 === '' ||
        response.claimant.address.street1 === undefined
      ) {
        alert('You must provide a street address in order to save.');
        return;
      }
      if (response.claimNo === '' || response.claimNo === undefined) {
        alert('You must provide a Claim Number in order to save.');
        return;
      }
      if (response.doi === '' || response.doi === undefined) {
        alert('You must provide a date of Injury in order to save.');
        return;
      }
      const claimId = response.id;

      const params = {
        response,
        userId,
        claimId,
      };
      saveClaim(params);
      loadClaimantInfoToggle();
    }
    // setIsExternal();
    setPatientEdited(true);
  };

  const getBody = () => {
    if (claimantInfo && claimantInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new Claimant as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Claimant?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    resetLoadClaim();
    newInjuryList = [];
    setFormClaimantFields({
      claimantFirstName: externalRefData?.PatientFirstName || '',
      claimantMI: '',
      claimYorkClient: '',
      claimPolicyNo: '',
      claimYorkClientId: '',
      claimProgramName: '',
      claimPolicyHolder: '',
      claimNumber: externalRefData?.ClaimNumber || '',
      claimDOI: '', //fromExternal.fromExternal && externalRefData?.PatientDateOfInjury != "" ? new Date(externalRefData.PatientDateOfInjury) : '',
      claimDisStart: '',
      claimDisEnd: '',
      claimStateOfJuris: '',
      claimSettlementDate: '',
      claimInjuredBodyPart: '',
      claimNatureOfInjury: '',
      claimCauseOfInjury: '',
      claimWorkStatus: '',
      claimStatus: '',
      claimStateOfAccident: '',
      claimantLastName: externalRefData?.PatientLastName || '',
      claimantEmail: '',
      claimantDOB: '', //fromExternal.fromExternal && externalRefData?.PatientDateOfBirth != "" ? new Date(externalRefData.PatientDateOfBirth) : '',
      claimantSSN: '',
      claimantHeight: externalRefData?.PatientHeightFeet || '',
      claimantInches: externalRefData?.PatientHeightInches || '',
      claimantGender: '',
      claimantWeight: externalRefData?.PatientWeight || '',
      claimantAlert: '',
      okToContact: true,
      okToText: true,
      catastrophic: false,
      claimantPhone: externalRefData?.PatientPhone || '',
      claimantPhoneExt: '',
      claimantCell: '',
      claimantAddress: externalRefData?.PatientAddress || '',
      claimantAddress2: '',
      claimantCity: externalRefData?.PatientCity || '',
      claimantLang: '',
      claimantCounty: '',
      claimantState: externalRefData?.PatientState || '',
      claimantZip: externalRefData?.PatientZip || '',
      claimantZipCodeId: '',
      claimantZipId: '',
      claimantTimeZone: '',
      claimantNotes: '',
      claimDXCode: [],
      claimDXDesc: [],
    });
  };

  useEffect(() => {
    setPatientData(formClaimantFields)
  }, [formClaimantFields])

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadClaimantModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const confirmationDeleteToggle = () => {
    setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);
  };

  const getDeleteBody = () => {
    return (
      <div className="text-center">
        Are you sure you wish to delete Injury Record?
      </div>
    );
  };

  const getDeleteFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationDeleteToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onDeleteInjuryRow();
            confirmationDeleteToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  const toggleClaimant = () => {
    setIsShowClaimant(true);
    setIsShowClaim(false);
  };

  const toggleClaim = () => {
    setIsShowClaim(!isShowClaim);
    setIsShowClaimant(false);
  };

  const onCheckedContact = e => {
    const id = e.target.id;
    setFormClaimantFields({
      ...formClaimantFields,
      [id]: !formClaimantFields[id],
    });
  };

  const primaryChecked = e => {
    const id = e.target.id;
    if (selectedItem || (!isNew && !claimantInfo)) {
      loadClaimInfo.injuryList = loadClaimInfo.injuryList.map(obj =>
        obj.primaryInjury === true ? { ...obj, primaryInjury: false } : obj
      );
      loadClaimInfo.injuryList[injuryIndex] = {
        ...isDiagnosisList,
        [id]: !isDiagnosisList[id],
      };
    } else {
      newInjuryList = newInjuryList.map(obj =>
        obj.primaryInjury === true ? { ...obj, primaryInjury: false } : obj
      );
      newInjuryList[injuryIndex] = {
        ...isDiagnosisList,
        [id]: !isDiagnosisList[id],
      };
    }
    setIsDiagnosisList({
      ...isDiagnosisList,
      [id]: !isDiagnosisList[id],
    });
    diagnosisItems = {
      ...isDiagnosisList,
      [id]: !isDiagnosisList[id],
    };
  };

  const onChangeHandlerLangType = (id, value) => {
    setFormClaimantFields({ ...formClaimantFields, [id]: value });
  };

  const handleOnInputMaskChange = event => {
    const target = event.target;
    let value = target.value.replace(/[^0-9]/g, '');
    const name = target.id;
    setFormClaimantFields({ ...formClaimantFields, [name]: value });
  };

  const onViewHandler = () => {
    setIsViewMode(!isViewMode);
  };

  const onInjuryRowClickHandler = (item, index) => {
    setIsDiagnosisList(item);
    setInjuryIndex(index);
    diagnosisItems = item;
  };

  const onDXClickHandler = () => {
    if (!R.isEmpty(isDXCodeDesc)) {
      isDiagnosisList.diagnosisList.push(isDXCodeDesc);
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'DXCODEADD',
        refTableId: loadClaimInfo.id,
        refId: 0,
        refTable: 'CLM',
      };
      logBreadCrumbs(breadCrumb)
      setFormClaimantFields({
        ...formClaimantFields,
        claimDXCode: [],
        claimDXDesc: [],
      });
      setIsDXCodeDesc({});
      setIsDiagnosisList(isDiagnosisList);
    } else if (R.isEmpty(isDiagnosisList)) {
      setIsDXOpen(true);
    }
  };

  const onAddInjury = () => {
    const defaultInjuryObj = {
      bodyPartId: null,
      claimId: loadClaimInfo.id,
      description: '',
      diagnosisList: [],
      id: null,
      primaryInjury: false,
      sideOfBody: '',
    };
    if (selectedItem || (!isNew && !claimantInfo)) {
      loadClaimInfo.injuryList.push(defaultInjuryObj);
      onInjuryRowClickHandler(
        defaultInjuryObj,
        loadClaimInfo.injuryList.length - 1
      );
    } else {
      newInjuryList.push(defaultInjuryObj);
      onInjuryRowClickHandler(defaultInjuryObj, newInjuryList.length - 1);
    }
  };

  const onDeleteDXRow = (item, list1) => {
    let diagList = [];
    if (item.id) {
      const params = {
        userId: userId,
        functionName: 'delICD9',
        id: item.id,
      };
      diagList = diagnosisItems.diagnosisList.filter(
        (e, i) => e.id !== item.id
      );
      diagnosisItems.diagnosisList = diagList;
      deleteICD(params);
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'DXCODEDELETE',
        refTableId: loadClaimInfo.id,
        refId: 0,
        refTable: 'CLM',
      };
      logBreadCrumbs(breadCrumb)
    } else {
      diagList = diagnosisItems.diagnosisList.filter(
        (e, i) => e.int_id !== item.int_id
      );
      diagnosisItems.diagnosisList = diagList;
    }
    setIsDiagnosisList({
      isDiagnosisList: diagnosisItems,
      diagnosisList: diagList,
    });
  };

  const onDeleteInjuryRow = () => {
    if (isDiagnosisList.id) {
      const params = {
        userId: userId,
        functionName: 'delInjury',
        id: isDiagnosisList.id,
      };
      if (selectedItem || (!isNew && !claimantInfo)) {
        loadClaimInfo.injuryList = loadClaimInfo.injuryList.filter(
          (e, i) => e.id !== isDiagnosisList.id
        );
      } else {
        newInjuryList = newInjuryList.filter(
          (e, i) => e.id !== isDiagnosisList.id
        );
      }
      deleteInjury(params);
    } else {
      if (selectedItem || (!isNew && !claimantInfo)) {
        loadClaimInfo.injuryList = loadClaimInfo.injuryList.filter(
          (e, i) => i !== injuryIndex
        );
      } else {
        newInjuryList = newInjuryList.filter((e, i) => i !== injuryIndex);
      }
    }
    setInjuryIndex(null);
    setIsDiagnosisList({});
    diagnosisItems = {};
  };

  const renderDeleteDxColumn = (item, list) => {
    return (
      <FontAwesomeIcon
        icon={faWindowClose}
        style={{ color: '#5F6F8F' }}
        onClick={() => onDeleteDXRow(item, list)}
      />
    );
  };

  const renderDescColumn = (item, itemIndex) => {
    return item.primaryInjury ? (
      <div
        style={{ fontWeight: 500, color: 'blue' }}
        onClick={() => onInjuryRowClickHandler(item, itemIndex)}
      >
        {' '}
        {item.description}
      </div>
    ) : (
      <div onClick={() => onInjuryRowClickHandler(item, itemIndex)}>
        {item.description}
      </div>
    );
  };

  const getClaimContent = () => {
    return (
      <>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimNumber">Claim Number</Label>
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <Input
              type="text"
              id="claimNumber"
              className={validClaimNum ? '' : "error"}
              value={formClaimantFields.claimNumber || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={3}>
            <Label for="claimDOI">Date of Injury(DOI):</Label>{' '}
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <AppDateInput
              id="claimDOI"
              onChange={value => onChangeDateHandler('claimDOI', value)}
              selected={formClaimantFields ? formClaimantFields.claimDOI : ''}
              maxDate={moment().toDate()}
              error={!validDoi}
            />
          </Col>
          <Col sm={4}>
            <Label for="claimYorkClient">York Client</Label>
            <Input
              type="text"
              id="claimYorkClient"
              value={formClaimantFields.claimYorkClient || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label for="claimPolicyNo">Policy No</Label>
            <Input
              type="text"
              id="claimPolicyNo"
              value={formClaimantFields.claimPolicyNo || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={4}>
            <Label for="claimPolicyHolder">Policy Holder</Label>
            <Input
              type="text"
              id="claimPolicyHolder"
              value={formClaimantFields.claimPolicyHolder || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={4}>
            <Label for="claimYorkClientId">York Client ID</Label>
            <Input
              type="text"
              id="claimYorkClientId"
              value={formClaimantFields.claimYorkClientId || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimDisStart">Disability Start</Label>{' '}
            <AppDateInput
              id="claimDisStart"
              onChange={value => onChangeDateHandler('claimDisStart', value)}
              selected={formClaimantFields.claimDisStart}
            />
          </Col>
          <Col sm={3}>
            <Label for="claimDisEnd">Disability End:</Label>{' '}
            <AppDateInput
              id="claimDisEnd"
              onChange={value => onChangeDateHandler('claimDisEnd', value)}
              selected={formClaimantFields.claimDisEnd}
            />
          </Col>
          <Col sm={2} style={{ marginTop: '34px' }}>
            <CustomInput
              id="catastrophic"
              name="catastrophic"
              type="checkbox"
              label="Catastrophic"
              onChange={onCheckedContact}
              checked={formClaimantFields.catastrophic}
              inline
            />
          </Col>
          <Col sm={4}>
            <Label for="claimProgramName">Program Name</Label>
            <Input
              type="text"
              id="claimProgramName"
              value={formClaimantFields.claimProgramName || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label for="claimStateOfAccident">State Of Accident</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'lang_id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimStateOfAccident || ''}
              data={states}
              id="claimStateOfAccident"
            />
          </Col>
          <Col sm={4}>
            <Label for="claimStateOfJuris">State Of Jurisdiction</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimStateOfJuris || ''}
              data={states}
              id="claimStateOfJuris"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimStatusDate">Status Date</Label>
            <Input
              type="text"
              id="claimStatusDate"
              value={formClaimantFields.claimStatusDate || ''}
              onChange={e => onLoadChangeHandler(e)}
              readOnly
            />
          </Col>
          <Col sm={3}>
            <Label for="claimCompStatus">Compensability Status</Label>
            <Input
              type="text"
              id="claimCompStatus"
              value={formClaimantFields.claimCompStatus || ''}
              onChange={e => onLoadChangeHandler(e)}
              readOnly
            />
          </Col>
          <Col sm={3}>
            <Label for="claimStatus">Claim Status</Label>
            <Input
              type="text"
              id="claimStatus"
              value={formClaimantFields.claimStatus || ''}
              onChange={e => onLoadChangeHandler(e)}
              readOnly
            />
          </Col>
          <Col sm={3}>
            <Label for="claimSettlementDate">Settlement Date</Label>
            <Input
              type="text"
              id="claimSettlementDate"
              value={formClaimantFields.claimSettlementDate || ''}
              onChange={e => onLoadChangeHandler(e)}
              readOnly
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Button style={{ marginLeft: '166px' }} onClick={onAddInjury}>
              +
            </Button>{' '}
            <Button
              style={{ backgroundColor: 'red' }}
              type="button"
              onClick={confirmationDeleteToggle}
            >
              -
            </Button>
            <AppTable
              modalHeight={150}
              resizable
              columns={[
                {
                  header: 'Injury',
                  field: 'description',
                  render: (item, itemIndex) =>
                    renderDescColumn(item, itemIndex),
                },
              ]}
              data={loadClaimInfo.injuryList || newInjuryList || []}
              rowClickHandler={onInjuryRowClickHandler}
              autoPagination={true}
            />
            <Button
              style={{ marginLeft: '224px', marginTop: '4px' }}
              onClick={onDXClickHandler}
            >
              +
            </Button>{' '}
          </Col>
          <Col sm={4}>
            <Label for="description">Injury Description</Label>
            <Input
              type="text"
              id="description"
              value={
                isDiagnosisList.description || diagnosisItems.description || ''
              }
              onChange={e => onLoadInjuryHandler(e)}
              disabled={R.isEmpty(isDiagnosisList)}
            />
            <br />
            <div>
              <Label for="bodyPartId">Injured Body Part</Label>
              <AppInput
                changeHandler={onChangeInjuryHandlerType}
                option={{
                  value: 'part_id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={
                  isDiagnosisList.bodyPartId || diagnosisItems.bodyPartId || ''
                }
                data={injuredBodyPart}
                id="bodyPartId"
                disabled={R.isEmpty(isDiagnosisList)}
              />
            </div>
            <br />
            <div>
              <Label for="claimDXCode">Enter DX Code:</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleDXFields(e, 'claimDXCode', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadDXSearch(e, 'claimDXCode')}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={formClaimantFields.claimDXCode || ''}
                menuIsOpen={loadDXInfo.length}
                closeMenuOnSelect={true}
                options={
                  loadDXInfo.length
                    ? loadDXInfo.map(obj =>
                      assign(obj, {
                        label: obj.chr_icd,
                        value: obj.int_id,
                      })
                    )
                    : []
                }
                name="claimDXCode"
                id="claimDXCode"
                isDisabled={R.isEmpty(isDiagnosisList)}
              />
              <span>type in 3+ letters and hit tab to search</span>
            </div>
          </Col>
          <Col sm={4} style={{ marginTop: '34px' }}>
            <CustomInput
              id="primaryInjury"
              name="primaryInjury"
              type="checkbox"
              label="Primary Injury?"
              onChange={primaryChecked}
              checked={
                isDiagnosisList.primaryInjury || diagnosisItems.primaryInjury
              }
              inline
              disabled={R.isEmpty(isDiagnosisList)}
            />
            <br />
            <div style={{ marginTop: '26px' }}>
              <Label for="sideOfBody">Side Of Body</Label>
              <AppInput
                changeHandler={onChangeInjuryHandlerType}
                option={{
                  value: 'name',
                  displayValue: 'name',
                }}
                placeholder="Choose..."
                value={
                  isDiagnosisList.sideOfBody || diagnosisItems.sideOfBody || ''
                }
                data={sideOfBody}
                id="sideOfBody"
                disabled={R.isEmpty(isDiagnosisList)}
              />
            </div>
            <br />
            <div>
              <Label for="claimDXDesc">Desc:</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleDXFields(e, 'claimDXDesc', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadDXSearch(e, 'claimDXDesc')}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={formClaimantFields.claimDXDesc || ''}
                menuIsOpen={loadDXInfo.length}
                closeMenuOnSelect={true}
                options={
                  loadDXInfo.length
                    ? loadDXInfo.map(obj => ({
                      ...obj,
                      label: obj.chr_description,
                      value: obj.int_id,
                    }))
                    : []
                }
                name="claimDXDesc"
                id="claimDXDesc"
                isDisabled={R.isEmpty(isDiagnosisList)}
              />
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
            <AppTable
              modalHeight={150}
              resizable
              columns={[
                {
                  header: 'DX Code',
                  field: 'chr_icdfull',
                },
                {
                  header: 'DX Code Type',
                  field: 'chr_type',
                },
                {
                  header: 'DX Long Description',
                  field: 'chr_description',
                },
                {
                  header: '',
                  field: '',
                  render: item =>
                    renderDeleteDxColumn(item, isDiagnosisList.diagnosisList),
                },
              ]}
              data={isDiagnosisList.diagnosisList || []}
              autoPagination={true}
              sortAble={true}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label for="claimCauseOfInjury">Cause of Injury</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'part_id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimCauseOfInjury || ''}
              data={causes}
              id="claimCauseOfInjury"
            />
          </Col>
          <Col sm={4}>
            <Label for="claimWorkStatus">Work Status</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'status_id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimWorkStatus || ''}
              data={workStatus}
              id="claimWorkStatus"
            />
          </Col>
          <Col sm={4}>
            <Label for="claimNatureOfInjury">Nature of Injury</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'id',
                displayValue: 'chr_element',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimNatureOfInjury || ''}
              data={injuryNature}
              id="claimNatureOfInjury"
            />
          </Col>
        </FormGroup>
      </>
    );
  };

  const getClaimantContent = () => {
    return (
      <>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimantFirstName">Claimant First Name</Label>
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <Input
              type="text"
              id="claimantFirstName"

              value={formClaimantFields.claimantFirstName || ''}
              className={validFirstname ? '' : "error"}
              onChange={e => onLoadChangeHandler(e)}
              readOnly={
                (selectedItem || (!isNew && !claimantInfo)) && isDisabledInput()
              }
            />
          </Col>
          <Col sm={2}>
            <Label for="claimantMI">M.I</Label>
            <Input
              type="text"
              id="claimantMI"
              value={formClaimantFields.claimantMI || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={3}>
            <Label for="claimantLastName">Claimant Last Name</Label>{' '}
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <Input
              type="text"
              id="claimantLastName"
              className={validLastName ? '' : "error"}
              value={formClaimantFields.claimantLastName || ''}
              onChange={e => onLoadChangeHandler(e)}
              readOnly={
                (selectedItem || (!isNew && !claimantInfo)) && isDisabledInput()
              }
            />
          </Col>
          <Col sm={4}>
            <Label for="claimantEmail">Claimant Email</Label>
            <Input
              type="text"
              id="claimantEmail"
              value={formClaimantFields.claimantEmail || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <Col style={{ top: '-8px', color: 'red', paddingLeft: 0 }}>
          {' '}
          {(selectedItem || (!isNew && !claimantInfo)) && (
            <span>
              {isDisabledInput()
                ? 'Please contact Administrator if you want to change a Claimant Name'
                : 'Warning: Changing the name on this record will also change it on any referral that this record is linked to!'}
            </span>
          )}
        </Col>
        <FormGroup row>
          <Col sm={4}>
            <Label for="claimantAddress">Claimant Address</Label>
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <Input
              type="text"
              id="claimantAddress"
              className={validAddress ? '' : "error"}
              value={formClaimantFields.claimantAddress || ''}
              onChange={e => onLoadChangeHandler(e)}
            // readOnly={searchFields.p1Address}
            />
          </Col>
          <Col sm={4}>
            <Label for="claimantAddress2">Claimant Address 2</Label>
            <Input
              type="text"
              id="claimantAddress2"
              value={formClaimantFields.claimantAddress2 || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimantCity">Claimant City</Label>
            <Input
              type="text"
              id="claimantCity"
              value={formClaimantFields.claimantCity || ''}
              readOnly
            />
          </Col>
          <Col sm={1}>
            <Label for="claimantState">State</Label>
            <Input
              type="text"
              id="claimantState"
              value={formClaimantFields.claimantState || ''}
              readOnly
            />
          </Col>
          <Col sm={2}>
            <Label for="claimantCounty">County</Label>
            <Input
              type="text"
              id="claimantCounty"
              value={formClaimantFields.claimantCounty || ''}
              readOnly
            />
          </Col>
          <Col sm={2}>
            <Label for="claimantZip">Claimant Zip</Label>
            <FontAwesomeIcon
              className="red-text"
              icon={faAsterisk}
              style={{ fontSize: '8px' }}
            />
            <Input
              type="text"
              id="claimantZip"
              className={formClaimantFields.claimantZip ? '' : "error"}
              value={formClaimantFields.claimantZip || ''}
              readOnly
            />
          </Col>
          <Col sm={4}>
            <Label for="claimantZip">Lookup Zip</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              onChange={(e, actionType) => {
                setZipDropdownOpen(false);
                handleFormFields(e, 'claimantZip', actionType)
              }}
              placeholder=""
              menuPlacement="bottom"
              onKeyDown={e => handleTypeAheadSearch(e)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              //menuIsOpen={fromExternal.fromExternal ? false : zipList.length}
              menuIsOpen={zipDropdownOpen && zipList.length > 0}
              value=''
              closeMenuOnSelect={true}
              options={
                zipList.length
                  ? zipList.map(obj =>
                    assign(obj, {
                      label: obj.zip_type_with_county,
                      value: obj.code_id,
                    })
                  )
                  : []
              }
              name="claimantZip"
              id="claimantZip"
            />
            <span>type in 3+ digits and hit tab to search</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimantPhone">Claimant Home Phone#</Label>
            <Input
              type="tel"
              id="claimantPhone"
              value={formClaimantFields.claimantPhone || searchFields.PatientPhone || ''}
              onChange={e => onLoadChangeHandler(e)}
              placeholder="(xxx)xxx-xxxx"
            />
          </Col>
          <Col sm={3}>
            <Label for="claimantCell">Claimant Cell#</Label>
            <Input
              type="text"
              id="claimantCell"
              value={formClaimantFields.claimantCell || ''}
              onChange={e => onLoadChangeHandler(e)}
              placeholder="(xxx)xxx-xxxx"
            />
          </Col>
        </FormGroup>
        <Row>
          <Col sm={3}>
            <CustomInput
              id="okToContact"
              name="okToContact"
              type="checkbox"
              label="OK to Contact?"
              onChange={onCheckedContact}
              checked={formClaimantFields.okToContact}
              inline
            />
          </Col>
          <Col>
            <CustomInput
              id="okToText"
              name="okToText"
              type="checkbox"
              onChange={onCheckedContact}
              checked={formClaimantFields.okToText}
              label="Authorized to Text Claimant for Reminders?"
              inline
            />
          </Col>
        </Row>
        <FormGroup row>
          <Col sm={4}>
            <Label for="claimantLang">Language</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'lang_id',
                displayValue: 'value',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimantLang || ''}
              data={language}
              id="claimantLang"
            />
          </Col>
          <Col>
            <Label for="claimantAlert">Claimant Alert</Label>
            <Input
              type="text"
              id="claimantAlert"
              value={formClaimantFields.claimantAlert || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <Label for="claimantDOB">Claimant DOB</Label>
            <AppDateInput
              id="claimantDOB"
              onChange={value => onChangeDateHandler('claimantDOB', value)}
              selected={formClaimantFields ? formClaimantFields.claimantDOB : ''}
              maxDate={moment().toDate()}
            />
          </Col>
          <Col sm={3}>
            <Label for="claimantSSN">Claimant SSN</Label>
            <Input
              type={isViewMode ? 'text' : 'password'}
              maxLength="9"
              name="claimantSSN"
              id="claimantSSN"
              value={formClaimantFields.claimantSSN || ''}
              onChange={e => handleOnInputMaskChange(e)}
              readOnly={
                ((selectedItem &&
                  loadClaimInfo &&
                  loadClaimInfo.claimant &&
                  loadClaimInfo.claimant.people &&
                  loadClaimInfo.claimant.people.ssn) ||
                  (!isNew &&
                    !claimantInfo &&
                    loadClaimInfo &&
                    loadClaimInfo.claimant &&
                    loadClaimInfo.claimant.people &&
                    loadClaimInfo.claimant.people.ssn)) &&
                isDisabledInput()
              }
            />
          </Col>
          <Col sm={3}>
            <br />
            <Button
              type="button"
              style={{ marginTop: '7px' }}
              onClick={onViewHandler}
            >
              {isViewMode ? 'HIDE' : 'VIEW'}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label for="claimantHeight">Height</Label>
            <Input
              type="text"
              id="claimantHeight"
              value={formClaimantFields.claimantHeight || searchFields.PatientFeet || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={2}>
            <Label for="claimantInches">Inches</Label>
            <Input
              type="text"
              id="claimantInches"
              value={formClaimantFields.claimantInches || searchFields.patientInch || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={2}>
            <Label for="claimantWeight">Weight(Lbs)</Label>
            <Input
              type="text"
              id="claimantWeight"
              value={formClaimantFields.claimantWeight || searchFields.patientWeight || ''}
              onChange={e => onLoadChangeHandler(e)}
            />
          </Col>
          <Col sm={3}>
            <Label for="claimantGender">Claimant Gender</Label>
            <AppInput
              changeHandler={onChangeHandlerLangType}
              option={{
                value: 'id',
                displayValue: 'name',
              }}
              placeholder="Choose..."
              value={formClaimantFields.claimantGender || ''}
              data={genderData}
              id="claimantGender"
            />
          </Col>
        </FormGroup>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadClaimantModal}>
        <ModalHeader toggle={loadClaimantInfoToggle}>
          <div style={{ display: 'inlineBlock', marginLeft: '20px', width: '31%', position: 'absolute', top: '-8px', right: '51px' }}>
            <FormGroup>
              <Label for="referringClient" style={{ fontSize: '13px', marginBottom: 0, textTransform: 'uppercase', letterSpacing: 'normal', position: 'relative', top: '2px' }}>Referring Client:</Label>
              <Input
                type="text"
                id="referringClient"
                value={loadClaimInfo ? loadClaimInfo.sedgwickClient : ''}
              />
            </FormGroup>
          </div>
          <Row className="claimant-info">
            <Col>
              {isShowClaimant && !isShowClaim
                ? 'ADD A CLAIMANT INFO'
                : 'ADD A CLAIM INFO'}
              <Button
                style={{ marginLeft: '20px' }}
                size="md"
                type="button"
                onClick={toggleClaimant}
                className={
                  'btn btn-secondary ' +
                  classNames({ 'claim-button-active': !isShowClaim })
                }
              >
                SHOW CLAIMANT
              </Button>{' '}
              <Button
                size="md"
                type="button"
                onClick={toggleClaim}
                className={
                  'btn btn-secondary ' +
                  classNames({ 'claim-button-active': isShowClaim })
                }
              >
                SHOW CLAIM
              </Button>

            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          {isShowClaimant && !isShowClaim
            ? getClaimantContent()
            : getClaimContent()}
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentClaimant}>
              CHANGE CURRENT CLAIMANT
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSeachClaimant}>
        <ModalHeader toggle={searchClaimantToggle}>SEARCH CLAIMANT</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    id="firstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.firstName || ''}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>{' '}
                  <Input
                    type="text"
                    id="lastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.lastName || ''}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="claimNumber">Claim Number</Label>{' '}
                  <Input
                    type="text"
                    id="claimNumber"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.claimNumber || ''}

                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchClaimantTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!claimantInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadClaimantModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
      <PopupModal
        content={getDeleteBody()}
        title="Confirmation"
        externalToggle={confirmationDeleteToggle}
        isOpen={isConfirmDeleteModalOpen}
        footerContent={getDeleteFooter()}
        size="md"
      />
      <PopupModal
        content={getContent()}
        externalToggle={onDXToggle}
        title=''
        isOpen={isDXOpen}
        size="md"
      />
    </>
  );
};

NewClaimantModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  claimantInfo: PropTypes.array,
  getClaimantList: PropTypes.func,
  resetSearchClaimant: PropTypes.func,
  getLoadClaimantById: PropTypes.func,
  getLoadClaim: PropTypes.func,
  loadClaimInfo: PropTypes.object,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadDX: propTypes.func,
  saveClaim: PropTypes.func,
  alert: PropTypes.func,
  language: PropTypes.array,
  states: PropTypes.array,
  resetLoadClaim: PropTypes.func,
  updatePatientZip: PropTypes.func
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    claimantInfo: fast360Info.claimantInfo,
    loadClaimInfo: fast360Info.loadClaimInfo,
    loadClaimantByIdInfo: fast360Info.loadClaimantByIdInfo,
    loadDXInfo: fast360Info.loadDXInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    language: initialData.language,
    states: initialData.states,
    injuredBodyPart: initialData.injuredBodyPart,
    workStatus: initialData.workStatus,
    causes: initialData.causes,
    injuryNature: initialData.injuryNature,
    updatedClaimInfo: fast360Info.updatedClaimInfo,
    patientInfo: fast360Info.patientZip,
    updatedEmployerInfo: fast360Info.updatedEmployerInfo,
    updatedAttorneyInfo: fast360Info.updatedAttorneyInfo,
    updatedPayorInfo: fast360Info.updatedPayorInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getClaimantList: data => dispatch(fast360Operations.getClaimantList(data)),
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  getLoadClaimantById: data =>
    dispatch(fast360Operations.getLoadClaimantById(data)),
  getLoadClaim: data => dispatch(fast360Operations.getLoadClaim(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  getDxCodeDesc: data => dispatch(fast360Operations.getDxCodeDesc(data)),
  deleteICD: data => dispatch(fast360Operations.deleteICD(data)),
  deleteInjury: data => dispatch(fast360Operations.deleteInjury(data)),
  resetLoadDX: () => dispatch(fast360Actions.resetLoadDX()),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchClaimant: () => dispatch(fast360Actions.resetSearchClaimant()),
  resetLoadClaim: () => dispatch(fast360Actions.resetLoadClaim()),
  resetSaveClaim: () => dispatch(fast360Actions.resetSaveClaim()),
  saveClaim: data => dispatch(fast360Operations.saveClaim(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
  loadClientWithFees: data =>
    dispatch(fast360Operations.loadClientWithFees(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewClaimantModal);
