import React from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import * as billOperationsConstants from './store/constants';
import { billOperationsSelectors, billOperationsOperations } from './store';
import FindBills from './components/FindBills';
import TaskList from './components/TaskList';


class BillOperations extends React.Component {
  constructor(props) {
    super(props);
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.currentView === "taskList" && (nextProps.currentSelection === "approveBills" || !nextProps.currentSelection)) {
      // if (Object.keys(nextProps.selectedData).length && nextProps.selectedData.selectedAdjusterId > 0 && nextProps.selectedData.reviewType === "adjusters") {
      //   console.log('inside if');
      //   const { currentUserId, selectedAdjusterName, profile, reviewType, selectedAdjusterId } = nextProps.selectedData
      //   this.props.findBills(currentUserId, selectedAdjusterName, profile, selectedAdjusterId, reviewType)
      // }
      if (nextProps.currentProfile !== "" && Object.keys(nextProps.selectedData).length === 0) {
        this.props.findBills(Number(nextProps.id), nextProps.name, nextProps.currentProfile, Number(nextProps.selectedAdjusterId), "adjusters")
      }
    }

  }
  render() {
    return (
      <>
        <TabContent activeTab={this.props.currentView} style={{ paddingTop: '10px' }}>
          <TabPane tabId={billOperationsConstants.FIND_BILLS}>
            <FindBills />
          </TabPane>
          <TabPane tabId={billOperationsConstants.TASK_LIST}>
            <TaskList />
          </TabPane>
        </TabContent>
      </>
    );
  }
}


const mapStateToProps = state => {
  const billReviewOperation = billOperationsSelectors.getBillReview(state);
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const initialUsers = approveBillState.users;
  const selectedAdjusterId = initialUsers['adjusters'].length
    ? ((initialUsers.adjusters.filter(t => t.name == state.AUTH.user.fullname)[0] != null) ? initialUsers.adjusters.filter(t => t.name == state.AUTH.user.fullname)[0].id : 0 )
    : 0;

  return {
    role: billReviewOperation.role,
    currentView: billReviewOperation.currentView,
    selectedData: billReviewOperation.updateFindBillsFromTabsClick,
    selectedAdjusterId: selectedAdjusterId,
    currentProfile: initialData.currentProfile,
    name: state.AUTH.user.fullname,
    id: state.AUTH.user.user_id,
    currentSelection: billReviewOperation.updateFindBillsFromRecentActivity
  };
};


const mapDispatchToProps = dispatch => ({
  findBills: (userId, name, profile, adjuster, reviewType) =>
    dispatch(
      billOperationsOperations.findApproveBills(
        userId,
        name,
        profile,
        adjuster,
        reviewType
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillOperations);
