import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector,connect } from 'react-redux';
import { Button, Form, Input, FormGroup, Alert, Col, Row, Label } from 'reactstrap';

import operations from '../store/operations';
import selectors from '../store/selectors';
import {
  administrationSelectors,
} from '../store';

import NewUserButton from './NewUserButton';

const EmptyNameField = () => (
  <Alert color="warning" style={{ marginTop: '10px' }}>
    Enter a name before searching
  </Alert>
);

const FindUsersToolbar = ({userLocationList,
  getUserLocations
}) => {
  const dispatch = useDispatch();

  const { userBasics } = useSelector(selectors.getUser);

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  
  useEffect(() => {
    dispatch(operations.clearUsers());
    if ( userLocationList === undefined || userLocationList.length <= 0 ){
      getUserLocations();
    }
  }, [dispatch]);

  return (
    <div id="findUsersToolbarContainer">
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (name !== undefined && name !== '') {
            setShowAlert(false);
            dispatch(operations.findUsers(name, location));
          } else {
            setShowAlert(true);
          }
        }}
      > 
        <Row>
          <Col lg={3}>
            <Label>
              Name
            </Label>
          </Col>
          <Col>
           <Label>
             Location Filter
           </Label>
          </Col>
        </Row>
        <Row form>
          <Col lg={3}>
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="nameInput"
                placeholder="Find User Records"
                onChange={e => {
                  setShowAlert(false);
                  setName(e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Input
                type="select"
                name="location"
                id="locationFilterDropdown"
                onChange={e => setLocation(e.target.value)}
              >
                <option value="default">Choose a location...</option>
                {userLocationList?.pickList?.locations.map((loc, index) => (
                  <option key={index} value={loc.element}>
                    {loc.element}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={1}>
            <Button type="submit">FIND</Button>
          </Col>
          <Col className="text-right" lg={{ offset: 3, size: 2 }}>
            <NewUserButton />
          </Col>
        </Row>
      </Form>
      {showAlert ? <EmptyNameField /> : ''}
    </div>
  );
};

const mapStateToProps = state => {
  const user = administrationSelectors.getUser(state);

  return {
    userLocationList: user.userLocationList,
   
  };
};

const mapDispatchToProps = dispatch => ({
  getUserLocations: () => dispatch(operations.getUserLocations()),
  });

export default connect(
  mapStateToProps, mapDispatchToProps
)(FindUsersToolbar);

