import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { Col, Row, Form, FormGroup, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import { sortByType } from 'shared/utils/shareUtils';
import * as FetchUtils from 'shared/utils/fetchUtils';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getProfileForPhp } from '../utils/billUtils';
import Select from 'react-select';
import { assign } from 'lodash';

const PrintEORModal = ({
  isOpen,
  onClose,
  printEORDropdownUserData,
  printEORDropdownSiteData,
  printEORDropdownRangeData,
  printEORDropdownOutputData,
  printEORDropdownOrderBillsByData,
  printEORDropdownOrderDetailByData,
  resetPrintEORData,
  getPrintEORData,
  getPrintEORDropdownUserData,
  resetPrintEORDropdownUserData,
  slpUserId,
  profile,
  alert,
}) => {
  const [searchFields, setSearchFields] = useState({
    // id: '',
    eorSiteType: '-1',
    eorRangeType: '5315',
    eorOutputType: '5317',
    eorOrderBillsType: '5319',
    eorOrderDetailsType: '5322',
    billIDStart: '',
    billIDEnd: '',
    userValue: '',
    eorValue: '',
  });
  const [isShowCurrentStatus, setIsShowCurrentStatus] = useState({});
  const [id, setId] = useState('');
  //  const [userValue, setUserValue] = useState('');

  useEffect(() => {
    const params = {
      slpUserId,
      profile,
    };

    if (isOpen) {
      resetPrintEORDropdownUserData();
      getPrintEORDropdownUserData(params);
    }
  }, [isOpen]);
  const toggle = () => {
    resetFormData();
    onClose();
  };

  const onChangeHandler = (id, value) => {
    setSearchFields({ ...searchFields, [id]: value });
  };

  const onChangeHandlerUser = (id, value) => {
    if (id !== null) {
      let nameSelect = value.name;
      let valueSelect = id.id;
      setSearchFields({ ...searchFields, [nameSelect]: valueSelect });
    } else {
      let nameSelect = 'userValue';
      let valueSelect = '';
      setSearchFields({ ...searchFields, [nameSelect]: valueSelect });
    }
  };
  const onChangeHandlerSite = (id, value) => {
    if (id !== null) {
      let nameSelect = value.name;
      let valueSelect = id.int_sitecode;
      setSearchFields({ ...searchFields, [nameSelect]: valueSelect });
    } else {
      let nameSelect = 'eorValue';
      let valueSelect = '';
      setSearchFields({ ...searchFields, [nameSelect]: valueSelect });
    }
  };

  const resetFormData = () => {
    setSearchFields({
      id: '',
      eorSiteType: '-1',
      eorRangeType: '5315',
      eorOutputType: '5317',
      eorOrderBillsType: '5319',
      eorOrderDetailsType: '5322',
      billIDStart: '',
      billIDEnd: '',
      userValue: '',
      eorValue: '',
    });
    resetPrintEORData();
  };
  const changeIdHandler = e => {
    e.length && setId(e[0].id);
  };

  const onCheckboxHandler = event => {
    setIsShowCurrentStatus({
      ...isShowCurrentStatus,
      [event.target.id]: event.target.checked,
    });
  };

  const myFunction = () => {
    var elem = document.getElementById('id-user');

    if (elem.style.display == 'block') {
      elem.style.display = 'none';
    } else {
      elem.style.display = 'block';
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    let okToPrint = true;
    let selectedUser = searchFields.userValue;
    let selectedSite = searchFields.eorValue;

    if (selectedUser == '') {
      okToPrint = false;
      alert('You must select a full user name');
    } else if (selectedSite == '') {
      okToPrint = false;
      alert('You must select a full site name');
    } else if (
      searchFields.billIDStart === '' &&
      searchFields.billIDEnd !== ''
    ) {
      okToPrint = false;
      alert('You must enter a bill start range');
     } else if (
      searchFields.billIDStart === ''
    ) {
      okToPrint = false;
      alert('You must enter a bill start range');
    } else if (
      searchFields.billIDStart === '' &&
      searchFields.billIDEnd === '' &&
      selectedUser === ''
    ) {
      okToPrint = false;
      alert('You must select a user when printing a range of bills');
    }

    if (okToPrint) {
      let range = '';
      if (searchFields.eorRangeType === '5315') {
        range = 'Range';
      } else {
        range = 'All';
      }

      if (selectedSite === -1) {
        selectedSite = '';
      }

      let output = '';
      if (searchFields.eorOutputType === '5317') {
        output = 'Draft';
      } else {
        output = 'Final';
      }

      let orderBillsBy = '';
      if (searchFields.eorOrderBillsType === '5319') {
        orderBillsBy = 'B';
      } else if (searchFields.eorOrderBillsType === '5320') {
        orderBillsBy = 'N';
      } else if (searchFields.eorOrderBillsType === '5969') {
        orderBillsBy = 'C';
      } else {
        orderBillsBy = 'R';
      }

      let orderDetailBy = '';
      if (searchFields.eorOrderDetailsType === '5322') {
        orderDetailBy = 'L';
      } else if (searchFields.eorOrderDetailsType === '5323') {
        orderDetailBy = 'S';
      } else {
        orderDetailBy = 'P';
      }

      let skipDraft = isShowCurrentStatus.skipDraft === true ? '1' : '0';
      let skipBillReprints =
        isShowCurrentStatus.skipBillReprints === true ? '1' : '0';
      let printMCMCEOP = isShowCurrentStatus.printMCMCEOP === true ? '1' : '0';
      let printFlagNames =
        isShowCurrentStatus.printFlagNames === true ? '1' : '0';
      const script = 'Y';
      const currentProfile = getProfileForPhp(profile);
      const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);
      const params = new URLSearchParams();
      params.append('pdf_srange', searchFields.billIDStart);
      params.append('pdf_erange', searchFields.billIDEnd);
      params.append('username', selectedUser);
      params.append('site', selectedSite);
      params.append('rangetype', range);
      params.append('printtype', output);
      params.append('orderby', orderBillsBy);
      params.append('orderbydetail', orderDetailBy);
      params.append('script', script);
      params.append('profile', currentProfile);
      params.append('userid',  slpUserId);
      params.append('skipdrafts', skipDraft);
      params.append('skipreprints', skipBillReprints);
      params.append('printflags', printFlagNames);
      params.append('printeop', printMCMCEOP);
      actionUrl.search = params.toString();

      FetchUtils.openWindowWithPost(
        actionUrl.toString(),
        {
          username: process.env.REACT_APP_SLP_USERNAME,
          password: process.env.REACT_APP_SLP_PASSWORD,
        },
        `EorDocs${new Date().getTime()}`
      );
    }
  };

  const handleUserDisplayChange = (id, value) => {
    setSearchFields({ ...searchFields, [id]: value });
    // setUserValue(value)
  };

  const handleUserChange = value => {
    // setUserValue(value)
  };
  const sortColumn = (item1, item2, order, field) => {
    const field1 = item1[field] || {};
    const field2 = item2[field] || {};
    return sortByType(field1[field], field2[field], order);
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen} className="printEorModal">
        <ModalHeader toggle={toggle}>EOR Printing</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col xs="2">
                <div>Bill ID Start</div>
              </Col>

              <Col xs="2">
                <div className="billEndEor">Bill ID End</div>
              </Col>

              <Col>
                <div className="userEor">User</div>
              </Col>
            </Row>

            <Row>
              <Col xs="2">
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    placeholder="Bill Id Start"
                    id="billIDStart"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col xs="2">
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    placeholder="Bill Id End"
                    id="billIDEnd"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  {/* <AppInput
                    changeHandler={onChangeHandler}
                    option={{ value: 'id', displayValue: 'username' }}
                    placeholder="Choose..."
                    data={printEORDropdownUserData}
                    id="id"
                  /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    onChange={onChangeHandlerUser}
                    //defaultValue={addManageBillsBillStatus.filter(option => option.id == searchFields.billStatus)}
                    name="userValue"
                    id="userValue"
                    options={
                      printEORDropdownUserData.length
                        ? printEORDropdownUserData.map(obj =>
                          assign(obj, { label: obj.username, value: obj.id })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <div>Site</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  {/* <AppInput
                    changeHandler={onChangeHandler}
                    option={{
                      value: 'int_sitecode',
                      displayValue: 'chr_sitename',
                    }}
                    value={searchFields.eorSiteType}
                    placeholder="Choose..."
                    data={printEORDropdownSiteData}
                    id="eorSiteType"
                  /> */}

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    onChange={onChangeHandlerSite}
                    //defaultValue={addManageBillsBillStatus.filter(option => option.id == searchFields.billStatus)}
                    name="eorValue"
                    id="eorValue"
                    options={
                      printEORDropdownSiteData.length
                        ? printEORDropdownSiteData.map(obj =>
                          assign(obj, {
                            label: obj.chr_sitename,
                            value: obj.int_sitecode,
                          })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Range</div>
              </Col>

              <Col>
                <div>Output</div>
              </Col>

              <Col>
                <div>Order Bills By</div>
              </Col>

              <Col>
                <div>Order Detail By</div>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    option={{ value: 'id', displayValue: 'element' }}
                    placeholder="Choose..."
                    value={searchFields.eorRangeType}
                    data={printEORDropdownRangeData}
                    id="eorRangeType"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    option={{ value: 'id', displayValue: 'element' }}
                    placeholder="Choose..."
                    value={searchFields.eorOutputType}
                    data={printEORDropdownOutputData}
                    id="eorOutputType"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    option={{ value: 'id', displayValue: 'element' }}
                    placeholder="Choose..."
                    value={searchFields.eorOrderBillsType}
                    data={printEORDropdownOrderBillsByData}
                    id="eorOrderBillsType"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={onChangeHandler}
                    option={{ value: 'id', displayValue: 'element' }}
                    placeholder="Choose..."
                    value={searchFields.eorOrderDetailsType}
                    data={printEORDropdownOrderDetailByData}
                    id="eorOrderDetailsType"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <div id="printEorDiv">
                <div className="mt-3">
                  <Row>
                    <Col>
                      <div>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="skipDraft"
                              type="checkbox"
                              onChange={onCheckboxHandler}
                            />{' '}
                            Skip Draft Bills
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="skipBillReprints"
                              type="checkbox"
                              onChange={onCheckboxHandler}
                            />{' '}
                            Skip Bill Reprints
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="includeMCMCFees"
                              type="checkbox"
                              onChange={onCheckboxHandler}
                            />{' '}
                            Include MCMC Fees
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="mt-4">
                  <Row>
                    <Col>
                      <div>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="printMCMCEOP"
                              type="checkbox"
                              onChange={onCheckboxHandler}
                            />{' '}
                            Print MCNS EOP
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>

                    <Col id="printFlag">
                      <div>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="printFlagNames"
                              type="checkbox"
                              onChange={onCheckboxHandler}
                            />{' '}
                            Print Flag Names(Draft Only)
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>

            <Button type="submit" id="odgSubmit">
              PRINT{' '}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

PrintEORModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  printEORData: PropTypes.array,
  printEORDropdownUserData: PropTypes.array,
  printEORDropdownRangeData: PropTypes.array,
  printEORDropdownOutputData: PropTypes.array,
  printEORDropdownOrderBillsByData: PropTypes.array,
  printEORDropdownOrderDetailByData: PropTypes.array,
  getPrintEORData: PropTypes.func,
  resetPrintEORData: PropTypes.func,
  getPrintEORDropdownUserData: PropTypes.func,
  resetPrintEORDropdownUserData: PropTypes.func,
  alert: PropTypes.func.isRequired,
  slpUserId: PropTypes.string.isRequired,
  profile: PropTypes.string,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  //const user = billReviewInitialData;
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  billReviewInitialData.pickList = billReviewInitialData.pickList
    ? billReviewInitialData.pickList
    : {};

  return {
    printEORData: billReviewState.printEORData,
    printEORDropdownUserData: billReviewState.printEORDropdownUserData,
    printEORDropdownSiteData: billReviewInitialData.slpsites,
    printEORDropdownRangeData: billReviewInitialData.pickList.eorrange,
    printEORDropdownOutputData: billReviewInitialData.pickList.eoroutput,
    printEORDropdownOrderBillsByData: billReviewInitialData.pickList.eororderby,
    printEORDropdownOrderDetailByData:
      billReviewInitialData.pickList.eororderdetailby,

    slpUserId: billReviewInitialData.slpUserId,
    profile: billReviewInitialData.currentProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  getPrintEORData: data =>
    dispatch(billOperationsOperations.getPrintEORData(data)),
  resetPrintEORData: () => dispatch(billOperationsActions.resetPrintEORData()),
  getPrintEORDropdownUserData: data =>
    dispatch(billOperationsOperations.getPrintEORDropdownUserData(data)),
  resetPrintEORDropdownUserData: () =>
    dispatch(billOperationsActions.resetPrintEORDropdownUserData()),
  updateSelectedUser: slpUserId =>
    dispatch(
      billOperationsActions.updateRecentActivitySelectedAdjuster(slpUserId)
    ),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintEORModal);