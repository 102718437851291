import {
  EXPERT_INFO_UPDATE_GROUP_INPUT,
  EXPERT_INFO_UPDATE_EXPERT_INPUT,
  EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR,
  EXPERT_INFO_UPDATE_EXPERT_DATA,
  EXPERT_INFO_UPDATE_GROUP_DATA,
  EXPERT_INFO_LOAD_INITIAL_DATA,
  EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR,
} from './constants';

const loadInitialData = data => {
  return {
    type: EXPERT_INFO_LOAD_INITIAL_DATA,
    payload: data,
  };
};

const updateExpertData = data => {
  return {
    type: EXPERT_INFO_UPDATE_EXPERT_DATA,
    payload: data,
  };
};

const updateExpertInput = data => {
  return {
    type: EXPERT_INFO_UPDATE_EXPERT_INPUT,
    payload: data,
  };
};

const updateExpertInputError = data => {
  return {
    type: EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR,
    payload: data,
  };
};

const updateGroupData = data => {
  return {
    type: EXPERT_INFO_UPDATE_GROUP_DATA,
    payload: data,
  };
};

const updateGroupInput = data => {
  return {
    type: EXPERT_INFO_UPDATE_GROUP_INPUT,
    payload: data,
  };
};

const updateGroupInputError = data => {
  return {
    type: EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR,
    payload: data,
  };
};

export default {
  loadInitialData,
  updateGroupInput,
  updateGroupInputError,
  updateExpertInput,
  updateExpertInputError,
  updateExpertData,
  updateGroupData,
};
