import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

const STREET = 'Street';
const CITY_STATE_ZIP = 'City, State Zip';
export default class NewGroupAddressTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: STREET,
      sortOrder: true,
    };
  }

  getRow(item) {
    return {
      street: item.street1 || '',
      cityStateZip: item.city || '',
    };
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case STREET: {
        const street1 = item1.street1 || '';
        const street2 = item2.street1 || '';
        return sortOrder
          ? street1.localeCompare(street2)
          : street2.localeCompare(street1);
      }
      case CITY_STATE_ZIP:
      default:
        const city1 = item1.city || '';
        const city2 = item2.city || '';
        return sortOrder
          ? city1.localeCompare(city2)
          : city2.localeCompare(city1);
    }
  }

  render() {
    const data = this.props.data
      .sort((item1, item2) => this.sortData(item1, item2))
      .map((item, index) => this.getRow(item, index));
    return (
      <AppTable
        resizable
        columns={[
          {
            header: this.getHeader(STREET),
            field: 'street',
          },
          {
            header: this.getHeader(CITY_STATE_ZIP),
            field: 'cityStateZip',
          },
        ]}
        data={data}
        autoPagination={true}
      />
    );
  }
}

NewGroupAddressTable.propTypes = {
  data: PropTypes.array.isRequired,
};
