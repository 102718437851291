import * as R from 'ramda';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

import { selectedByGroup } from '../utils/ServicesUtils';
import { fast360Actions, fast360Selectors, fast360Operations } from '../store/index';
import { connect } from 'react-redux';

//const onRowClickHandler = item => {
//  setSelectedModule(item.module);
//};

const ServicesSelection = ({
  groupBy,
  groupBySelection,
  servicesData,
  updateSelectedBills,
  setSelectedModule,
  bills,
  updateGroupBySelection,
  rerender,
  index,
  requiredServiceData
}) => {

  useEffect(() => {
    servicesData.length && requiredServiceData(servicesData[index]);
  }, [index, servicesData.length]);

  return (
    <AppTable
      resizable
      columns={[
        {
          header: 'Services',
          field: 'module',
          render: item => renderConfId(item),
        },
      ]}
      data={servicesData || []}
      rowClickHandler={setSelectedModule}
      // autoPagination={true}
      // sortAble={true}
      highlightedRow={index}
    />
  );
};

const renderConfId = item => {
  return (
    <>
      {item.referralId ? <span>{item.referralId + '-' + item.module + '-' + item.serviceId}</span>
        : <span>{item.module + '-' + item.serviceId}</span>}
      <br />
      <span>DOS:  {item.dos} <br></br>Order Date:  {item.createDate}</span>
    </>
  );
};

/*GroupBySelection.propTypes = {
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
};*/

const mapDispatchToProps = dispatch => ({
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(null, mapDispatchToProps)(ServicesSelection);
