export const formatDateWithAmPmSuffix = date => {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 ? hour % 12 : 12;
    const formattedHourWithZeroPrefix = formattedHour < 10 ? '0' + formattedHour.toString() : formattedHour.toString();
    const formattedMinute = minute < 10 ? '0' + minute.toString() : minute.toString();
    return formattedHourWithZeroPrefix + ':' + formattedMinute + ' ' + suffix;
}

export const getCurrentTimeByUTCOffset = offset => {
    const now = new Date(Date.now());

    const currentTzOffset = -now.getTimezoneOffset() / 60;
    const deltaTimezoneOffset = offset - currentTzOffset;

    const nowTimestamp = now.getTime();
    const deltaTzOffsetMilliseconds = deltaTimezoneOffset * 1000 * 60 * 60;
    return new Date(nowTimestamp + deltaTzOffsetMilliseconds);
}

const timezoneMapping = {
    'Newfoundland': -3.5,
    'Atlantic': -4,
    'Eastern': -5,
    'Central': -6,
    'Mountain': -7,
    'Pacific': -8,
    'Alaska': -9,
    'Hawaii-Aleutian': -10,
    'GMT': 0
}
export const getTimezoneUTC = (timezone, observesDST) => {
    const utcZone = timezoneMapping[timezone] || 0;
    return observesDST && isDaylightSavingsTime() ? utcZone + 1 : utcZone;
}

const timezoneAcronymMapping = {
    'Newfoundland': ['NDT', 'NST'],
    'Atlantic': ['ADT', 'AST'],
    'Eastern': ['EDT', 'EST'],
    'Central': ['CDT', 'CST'],
    'Mountain': ['MDT', 'MST'],
    'Pacific': ['PDT', 'PST'],
    'Alaska': ['AKDT', 'AKST'],
    'Hawaii-Aleutian': ['HADT', 'HAST'],
    'GMT': ['GMT', 'GMT']
};
export const getTimezoneAcronym = (timezone, observesDST) => {
    const acronym = timezoneAcronymMapping[timezone] || ['GMT', 'GMT'];
    return observesDST && isDaylightSavingsTime() ? acronym[0] : acronym[1];
}

export const isDaylightSavingsTime = () => {
    const now = new Date(Date.now());
    let dateStart = new Date(now.getUTCFullYear(), 2, 1, 1, 0, 0, 0);
    let dateEnd = new Date(now.getUTCFullYear(), 10, 1, 1, 0, 0, 0);
    let count = 0;
    do {
        if (dateStart.getUTCDay() === 0) {
            count++;
        }
        if (count < 2) {
            dateStart.setDate(dateStart.getDate() + 1);
        }
    } while (count < 2);
    while (dateEnd.getUTCDay() !== 0) {
        dateEnd.setDate(dateEnd.getDate() + 1);
    }
    return (now.getTime() >= dateStart.getTime() && now.getTime() <= dateEnd.getTime());
}

export const formatPhoneNumber = phoneNumber => {
    if (phoneNumber === null || phoneNumber === '' || phoneNumber.length < 7
        || typeof phoneNumber !== 'string') {
        return '';
    }
    return `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, phoneNumber.length - 1)}`;
}

export const formatSSN = ssnNumber => {
    if (ssnNumber === null || ssnNumber === '' || ssnNumber.length < 9
        || typeof ssnNumber !== 'string') {
        return '';
    }
    return `${ssnNumber.substr(0, 3)}-${ssnNumber.substr(3, 2)}-${ssnNumber.substr(5, ssnNumber.length - 1)}`;
}

export const getItemAddressServices = item => {
    const servicesMap = {
        isservice: 'Service',
        ismailing: 'Mailing',
        isbilling: 'Billing',
        ishome: 'Home',
        iswork: 'Work',
        isphone: 'Phone',
        isother: 'Other',
        ispayment: 'Payment',
        isscheduling: 'Scheduling'
    };
    return Object.keys(servicesMap).map(key => {
        if (item[key]) {
            if (item[key] === true || item[key] === '1') {
                return servicesMap[key];
            }
        }
        return '';
    }).filter(service => service !== '');
}

export const EXPERT_SPECIALTY_BY_STATE = 'Expert Specialty By State';
export const EXPERT_SPECIALTY_BY_STATE_ID = '5';
export const NETWORK_DIRECTORY_REPORT = 'Network Directory Report';
export const NETWORK_DIRECTORY_REPORT_ID = '9';
export const RE_CREDENTIALING_REPORT = 'Recredentialing Report';
export const RE_CREDENTIALING_REPORT_ID = '7';
export const SPECIALTIES_COUNT = 'Specialties Count';
export const SPECIALTIES_COUNT_ID = '4';
export const SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY = 'Specialty Count by State license and Primary';
export const SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID = '6';
export const STATE_LICENSE_REPORT = 'State License Report';
export const STATE_LICENSE_REPORT_ID = '1';
export const YTD_CREDENTIALING_BY_STATE_REPORT = 'YTD Credentialing by State Report';
export const YTD_CREDENTIALING_BY_STATE_REPORT_ID = '3';
export const YTD_CREDENTIALING_REPORT = 'YTD Credentialing Report';
export const YTD_CREDENTIALING_REPORT_ID = '2';
export const COLUMNS_REPORT_TYPE_FOR_STATE_LICENSE_REPORT = [
    {
        header: 'Expert',
        field: 'expert_name'
    },
    {
        header: 'State',
        field: 'state_name'
    },
    {
        header: 'License Type',
        field: 'license_type'
    },
    {
        header: 'License Number',
        field: 'licensenumber'
    },
    {
        header: 'Expiration Date',
        field: 'expirationdate'
    },

];
export const COLUMNS_REPORT_TYPE_FOR_YTD_CREDENTIALING_REPORT = [
    {
        header: 'Expert',
        field: 'fullname'
    },
    {
        header: 'Recruitment Date',
        field: 'recommendedon'
    }
];
export const COLUMNS_REPORT_TYPE_FOR_SPECIALTIES_COUNT = [
    {
        header: 'Experts',
        field: 'expert_count'
    },
    {
        header: 'Specialty',
        field: 'specialty'
    }
];
export const COLUMNS_REPORT_TYPE_FOR_YTD_CREDENTIALING_BY_STATE_REPORT_ID = [
    {
        header: 'Experts',
        field: 'expert_count'
    },
    {
        header: 'State Licensed In',
        field: 'state_name'
    },
    {
        header: 'Specialty',
        field: 'specialty'
    }
];
export const COLUMNS_REPORT_TYPE_FOR_EXPERT_SPECIALTY_BY_STATE = [
    {
        header: 'Expert',
        field: 'fullname'
    },
    {
        header: 'Network',
        field: 'network'
    },
    {
        header: 'State',
        field: 'state'
    },
    {
        header: 'Active Practice',
        field: 'active'
    },
    {
        header: 'Specialty',
        field: 'specialty'
    },
];
export const COLUMNS_REPORT_TYPE_FOR_SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY = [
    {
        header: 'Experts',
        field: 'expert_count'
    },
    {
        header: 'State Licensed In',
        field: 'state_name'
    },
    {
        header: 'Primary Address State',
        field: 'primary_state_name'
    },
    {
        header: 'Specialty',
        field: 'specialty'
    },
];
export const COLUMNS_REPORT_RE_CREDENTIALING = [
    {
        header: 'Expert',
        field: 'fullname'
    },
    {
        header: 'Recruitment Date',
        field: 'recommendedon'
    },
    {
        header: 'Last Credentialed On',
        field: 'lastcredentialledon'
    }
];
export const COLUMNS_REPORT_NETWORK_DIRECTORY = [
    {
        header: 'groupname',
        field: 'groupname'
    },
    {
        header: 'specialty',
        field: 'specialty'
    },
    {
        header: 'street1',
        field: 'street1'
    },
    {
        header: 'city',
        field: 'city'
    },
    {
        header: 'phone',
        field: 'phone',
        render: item => formatPhoneNumber(item.phone || {}),
    },
    {
        header: 'state',
        field: 'state'
    },
    {
        header: 'name_combo',
        field: 'name_combo'
    },
    {
        header: 'zipcode',
        field: 'zipcode'
    },
    {
        header: 'street2',
        field: 'street2'
    },
    {
        header: 'tinnumber',
        field: 'tinnumber'
    }
];
export const EXPERT_REPORT_TYPE_ITEMS = [
    {
        id: EXPERT_SPECIALTY_BY_STATE_ID,
        name: EXPERT_SPECIALTY_BY_STATE
    },
    {
        id: NETWORK_DIRECTORY_REPORT_ID,
        name: NETWORK_DIRECTORY_REPORT
    },
    {
        id: RE_CREDENTIALING_REPORT_ID,
        name: RE_CREDENTIALING_REPORT
    },
    {
        id: SPECIALTIES_COUNT_ID,
        name: SPECIALTIES_COUNT
    },
    {
        id: SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID,
        name: SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY
    },
    {
        id: STATE_LICENSE_REPORT_ID,
        name: STATE_LICENSE_REPORT
    },
    {
        id: YTD_CREDENTIALING_BY_STATE_REPORT_ID,
        name: YTD_CREDENTIALING_BY_STATE_REPORT
    },
    {
        id: YTD_CREDENTIALING_REPORT_ID,
        name: YTD_CREDENTIALING_REPORT
    },

];

export const reportColumns = {
    [EXPERT_SPECIALTY_BY_STATE_ID]: COLUMNS_REPORT_TYPE_FOR_EXPERT_SPECIALTY_BY_STATE,
    [NETWORK_DIRECTORY_REPORT_ID]: COLUMNS_REPORT_NETWORK_DIRECTORY,
    [RE_CREDENTIALING_REPORT_ID]: COLUMNS_REPORT_RE_CREDENTIALING,
    [SPECIALTIES_COUNT_ID]: COLUMNS_REPORT_TYPE_FOR_SPECIALTIES_COUNT,
    [SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID]: COLUMNS_REPORT_TYPE_FOR_SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY,
    [STATE_LICENSE_REPORT_ID]: COLUMNS_REPORT_TYPE_FOR_STATE_LICENSE_REPORT,
    [YTD_CREDENTIALING_BY_STATE_REPORT_ID]: COLUMNS_REPORT_TYPE_FOR_YTD_CREDENTIALING_BY_STATE_REPORT_ID,
    [YTD_CREDENTIALING_REPORT_ID]: COLUMNS_REPORT_TYPE_FOR_YTD_CREDENTIALING_REPORT

}

export const allYearsValue = -1;
export const allYearsDisplay = 'All Years';

export const getPreviousFiveYearsFromCurrentYear = () => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let result = [{ id: allYearsValue, name: allYearsDisplay }];
    for (let i = 0; i < 5; i++) {
        let year = (currentYear - i);
        result.push({ id: year, name: '' + year });
    }
    return result;
};

export const buildGoogleMapLink = (street, city, state, zipCode, latitude, longitude) => {
    return `http://maps.google.com/maps?f=q&hl=en&geocode=&time=&date=&ttype=&q=${street} ${city}, ${state} ${zipCode}&sll=${latitude},${longitude}&sspn=0,0&ie=UTF8&z=16&om=1`;
};

export const extractIdFromElementId = (elementId, prefix) => {
    return elementId.replace(prefix, '');
};
