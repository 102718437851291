import React, { createContext, useReducer } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';

export const TransportDetailModalContext = createContext();

const initialState = {
  id: 0,
  leg: 1,
  legStatusId: null,
  legStatus: '',
  returnTypeDesc: '',
  pickupDate: '',
  pickupHours: "0",
  pickupMin: "00",
  pickupAM: '',
  pickupTz: '',
  locationName: '',
  pickupAddressId: 0,
  pickupAddress: {
    street2: '',
    street1: '',
    phone: '',
    zipCode: {
      city: '',
      state: '',
      zip: '',
      county: '',
      longitude: null,
      latitude: null,
      timeZone: '',
      dts: true,
      id: 0
    },
    active: false,
    permAddress: false,
    id: 0
  },
  dropOffDate: '',
  dropOffHours: "0",
  dropOffMin: "00",
  dropOffAM: '',
  dropOffTz: '',
  dropLocationName: '',
  dropOffAddressId: 0,
  dropOffAddress: {
    street2: '',
    street1: '',
    phone: '',
    zipCode: {
      city: '',
      state: '',
      zip: '',
      county: '',
      longitude: null,
      latitude: null,
      timeZone: '',
      dts: true,
      id: 0
    },
    active: false,
    permAddress: false,
    id: 0
  },
  estimatedMillage: '',
  extraPassenger: '',
  deadMileage: '',
  proWaitHour: '',
  proWaitMinute: '',
  waitHour: '',
  waitMinute: '',
  wheelChairRental: false,
};

const reducer = (inputData, action) => {
  const detailsValue = action.payload;
  switch (action.type) {
    case 'RESET_MODAL_DATA':
      return {
        ...initialState,
        dropOffAddress: {
          street2: '',
          street1: '',
          phone: '',
          active: false,
          permAddress: false,
          id: 0,
          zipCode: {
            city: '',
            state: '',
            zip: '',
            county: '',
            longitude: null,
            latitude: null,
            timeZone: '',
            dts: true,
            id: 0
          }
        },
        pickupAddress: {
          street2: '',
          street1: '',
          phone: '',
          active: false,
          permAddress: false,
          id: 0,
          zipCode: {
            city: '',
            state: '',
            zip: '',
            county: '',
            longitude: null,
            latitude: null,
            timeZone: '',
            dts: true,
            id: 0
          }
        },
      };
    case 'CLEAR_DROPOFF_ADDRESS':
      return {
        ...inputData,
        dropLocationName: '',
        dropOffAddress: {
          street2: '',
          street1: '',
          phone: '',
          active: false,
          permAddress: false,
          id: 0,
          zipCode: {
            city: '',
            state: '',
            zip: '',
            county: '',
            longitude: null,
            latitude: null,
            timeZone: '',
            dts: true,
            id: 0
          }
        },
      };
    case 'CLEAR_PICKUP_ADDRESS':
      return {
        ...inputData,
        locationName: '',
        pickupAddress: {
          street2: '',
          street1: '',
          phone: '',
          active: false,
          permAddress: false,
          id: 0,
          zipCode: {
            city: '',
            state: '',
            zip: '',
            county: '',
            longitude: null,
            latitude: null,
            timeZone: '',
            dts: true,
            id: 0
          }
        },
      };
    case 'UPDATE_MODAL_DATA':
      return {
        ...inputData,
        id: isNil(detailsValue.id)
        ? inputData.id
        : detailsValue.id,
        leg: isNil(detailsValue.leg) ? inputData.leg : detailsValue.leg,
        legStatus: isNil(detailsValue.legStatus)
          ? inputData.legStatus
          : detailsValue.legStatus,
        returnTypeDesc: isNil(detailsValue.returnTypeDesc)
          ? inputData.returnTypeDesc
          : detailsValue.returnTypeDesc,
        pickupDate: detailsValue.pickupDate === undefined
          ? inputData.pickupDate
          : detailsValue.pickupDate,
        pickupHours: isNil(detailsValue.pickupHours)
          ? inputData.pickupHours
          : detailsValue.pickupHours,
        pickupMin: isNil(detailsValue.pickupMin)
          ? inputData.pickupMin
          : detailsValue.pickupMin,
        pickupAM: isNil(detailsValue.pickupAM)
          ? inputData.pickupAM
          : detailsValue.pickupAM,
        pickupTz: isNil(detailsValue.pickupTz)
          ? inputData.pickupTz
          : detailsValue.pickupTz,
        locationName: isNil(detailsValue.locationName)
          ? inputData.locationName
          : detailsValue.locationName,
        pickupAddress: !detailsValue.pickupAddress
          ? inputData.pickupAddress
          : detailsValue.pickupAddress,
        pickupAddressId: !detailsValue.pickupAddressId
          ? inputData.pickupAddressId
          : detailsValue.pickupAddressId,
        dropOffDate: detailsValue.dropOffDate  === undefined
          ? inputData.dropOffDate
          : detailsValue.dropOffDate,
        dropOffHours: isNil(detailsValue.dropOffHours)
          ? inputData.dropOffHours
          : detailsValue.dropOffHours,
        dropOffMin: isNil(detailsValue.dropOffMin)
          ? inputData.dropOffMin
          : detailsValue.dropOffMin,
        dropOffAM: isNil(detailsValue.dropOffAM)
          ? inputData.dropOffAM
          : detailsValue.dropOffAM,
        dropOffTz: isNil(detailsValue.dropOffTz)
          ? inputData.dropOffTz
          : detailsValue.dropOffTz,
        droplocationName: isNil(detailsValue.droplocationName)
          ? inputData.droplocationName
          : detailsValue.droplocationName,
        dropOffAddress: !detailsValue.dropOffAddress
          ? inputData.dropOffAddress
          : detailsValue.dropOffAddress,
        dropOffAddressId: !detailsValue.dropOffAddressId
          ? inputData.dropOffAddressId
          : detailsValue.dropOffAddressId,
        estimatedMillage: isNil(detailsValue.estimatedMillage)
          ? inputData.estimatedMillage
          : detailsValue.estimatedMillage,
        extraPassenger: isNil(detailsValue.extraPassenger)
          ? inputData.extraPassenger
          : detailsValue.extraPassenger,
        dropLocationName: isNil(detailsValue.dropLocationName)
          ? inputData.dropLocationName
          : detailsValue.dropLocationName,
        deadMileage: isNil(detailsValue.deadMileage)
          ? inputData.deadMileage
          : detailsValue.deadMileage,
        wheelChairRental: isNil(detailsValue.wheelChairRental)
          ? inputData.wheelChairRental
          : detailsValue.wheelChairRental,
        proWaitHour: isNil(detailsValue.proWaitHour)
          ? inputData.proWaitHour
          : detailsValue.proWaitHour,
        proWaitMinute: isNil(detailsValue.proWaitMinute)
          ? inputData.proWaitMinute
          : detailsValue.proWaitMinute,
        waitHour: isNil(detailsValue.waitHour)
          ? inputData.waitHour
          : detailsValue.waitHour,
        waitMinute: isNil(detailsValue.waitMinute)
          ? inputData.waitMinute
          : detailsValue.waitMinute,
      };
  }
};

export const TransportDetailContextProvider = ({ children }) => {
  const [inputData, dispatch] = useReducer(reducer, { ...initialState, pickupAddress: {...initialState.pickupAddress}, dropOffAddress: {...initialState.dropOffAddress} });

  return (
    <TransportDetailModalContext.Provider value={{ inputData, dispatch }}>
      {children}
    </TransportDetailModalContext.Provider>
  );
};
