import * as R from 'ramda';

export class ReducerRegistry {
  #onChange;

  #registeredReducers;

  constructor() {
    this.#onChange = null;
    this.#registeredReducers = {};
  }

  getReducers() {
    return { ...this.#registeredReducers };
  }

  register(name, reducer) {
    this.#registeredReducers = R.assoc(name, reducer, this.#registeredReducers);
    if (this.#onChange) {
      this.#onChange(this.getReducers());
    }
  }

  setChangeListener(callback) {
    this.#onChange = callback;
  }
}

const defaultReducerRegistry = new ReducerRegistry();
export default defaultReducerRegistry;
