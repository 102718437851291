import PropTypes from 'prop-types';
import React from 'react';
import { Button, Row, Col, FormGroup } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';

const DefaultModeButtons = props => {
  return (
    <Col lg={{ size: '4', offset: '6' }}>
      <Row>
        <Col lg="8">
          <AppAutocomplete
            id="selectedUser"
            data={props.expertUsers}
            value={props.selectedUserName}
            displayOption="fullname"
            returnValue="id"
            onChange={props.handleUserChange}
            onSelect={props.handleUserSelect}
            showToolTip={true}
            placeholder="Tasklist you are currently viewing"
            isSuggested={true}
          />
        </Col>
        <Col lg="4">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={props.switchMode}
          >
            Transfer
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const TransferModeButtons = props => {
  return (
    <Col lg={{ size: '7', offset: '3' }}>
      <Row>
        <Col lg="3">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={props.selectAllClickHandler}
          >
            Select All
          </Button>
        </Col>
        <Col lg="4">
          <AppAutocomplete
            id="transferredUser"
            data={props.transferredOptions}
            value={props.transferredUserName}
            displayOption="fullname"
            returnValue="id"
            onChange={props.handleTransferredUserChange}
            onSelect={props.handleTransferredUserSelect}
            showToolTip={true}
            placeholder="Choose..."
            isSuggested={true}
          />
        </Col>
        <Col lg="2">
          <Button
            style={{ width: '100%' }}
            type="button"
            color="danger"
            onClick={props.switchMode}
          >
            Cancel
          </Button>
        </Col>
        <Col lg="3">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={props.transferTasks}
            disabled={
              props.transferredUser === '' || props.selectedTasks.length < 1
            }
          >
            Transfer
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const ExpertTaskListTop = props => {
  return (
    <FormGroup>
      <Row>
        <Col lg="2">
          <Row>
            <Col lg="4">
              <Button style={{ width: '100%' }} type="button" disabled>
                {props.taskLength}
              </Button>
            </Col>
            <Col lg="8">
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={props.refresh}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
        {!props.transferMode ? (
          <DefaultModeButtons
            handleUserChange={props.handleUserChange}
            handleUserSelect={props.handleUserSelect}
            expertUsers={props.expertUsers}
            selectedUser={props.selectedUser}
            selectedUserName={props.selectedUserName}
            switchMode={props.switchMode}
          />
        ) : (
          <TransferModeButtons
            handleUserChange={props.handleUserChange}
            transferredOptions={props.transferredOptions}
            switchMode={props.switchMode}
            selectAllClickHandler={props.selectAllClickHandler}
            handleTransferredUserChange={props.handleTransferredUserChange}
            handleTransferredUserSelect={props.handleTransferredUserSelect}
            transferTasks={props.transferTasks}
            transferredUser={props.transferredUser}
            transferredUserName={props.transferredUserName}
            selectedTasks={props.selectedTasks}
          />
        )}
      </Row>
    </FormGroup>
  );
};

ExpertTaskListTop.propTypes = {
  taskLength: PropTypes.number.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedUserName: PropTypes.string.isRequired,
  expertUsers: PropTypes.array.isRequired,
  transferredOptions: PropTypes.array.isRequired,
  transferMode: PropTypes.bool.isRequired,
  transferredUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  transferredUserName: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  handleUserChange: PropTypes.func.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  switchMode: PropTypes.func.isRequired,
  selectAllClickHandler: PropTypes.func.isRequired,
  handleTransferredUserChange: PropTypes.func.isRequired,
  handleTransferredUserSelect: PropTypes.func.isRequired,
  transferTasks: PropTypes.func.isRequired,
};

export default ExpertTaskListTop;
