import React, { useContext, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';
import { UpdateTypes, selectAll} from './utils/helper';
import { Row, Col, FormGroup, Label, TabPane, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

function updateAdvSearchForm(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_AdvSearch_Form',
        payload: { [key]: value },
    });
}

function updateCheckBox(value, key, dispatch) {
    dispatch({
        type: 'UPDATE_CheckBox',
        payload: { [key]: value },
    });
}

const HHC = ({ hhcCategory, getLoadHCPCQuick, userId, loadHCPCLookUp, setQueryStr, useStoredData }) => {

    const { state, dispatch } = useContext(AdvSearchContext);
    const { state1, dispatch1 } = useContext(CheckBoxContext);
    const { hhcTypes } = state;

    useEffect(() => {
        if(!useStoredData){
            selectAll(true, hhcCategory, dispatch, 'hhcTypes')
        }
    }, [hhcCategory])

    return (
        <TabPane tabId="5" style={{ border: "1px solid #dee2e6", padding: "15px" }}>
            <FormGroup>
                <div style={{ float: "left", width: "60%" }}>
                    <Row xs={1} md={2}>
                        <Col>
                            <FormGroup>
                                <Label style={{ paddingRight: "10px" }}>HCPC:</Label>
                                <AsyncTypeahead
                                    filterBy={['chr_code', 'chr_description']}
                                    placeholder="Search"
                                    id="async-example"
                                    isLoading={false}
                                    labelKey={option => `${option.chr_code}-${option.chr_description}`}
                                    minLength={1}
                                    onSearch={
                                        (query) => {
                                            getLoadHCPCQuick(query, userId);
                                        }
                                    }
                                    onChange={(e) => {
                                        if(e && e.length > 0){
                                            setQueryStr(e[0].chr_description);
                                        }
                                    }}
                                    options={loadHCPCLookUp}
                                    renderMenuItemChildren={(option) => (
                                        <span>{option.chr_code}-{option.chr_description}</span>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Select the fields to display on the report</Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayHHCHCPC}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayHHCHCPC', dispatch1)}
                                    />
                                            HCPC
                                        </Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        checked={state1.displayHHCServiceLocation}
                                        onClick={(e) => updateCheckBox(e.target.checked, 'displayHHCServiceLocation', dispatch1)}
                                    />
                                            Service Location
                                        </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Types:</Label>
                            <FormGroup check>
                                <Label check>
                                    <Input 
                                    checked={hhcCategory && hhcTypes &&  (hhcCategory.length === hhcTypes.length)}
                                    type="checkbox" 
                                    onClick={(e)=> selectAll(e.target.checked, hhcCategory, dispatch, 'hhcTypes')}
                                    /> Select All:
                                </Label>
                            </FormGroup>
                            <div style={{ border: "1px solid black", height: "150px", width: "270px", padding: "10px", overflowY: "scroll" }}>
                                {hhcCategory && hhcCategory.map((element, idx) => {
                                    return (
                                        <FormGroup key={idx} check>
                                            <Label check>
                                                <Input 
                                                type="checkbox" 
                                                checked={ hhcTypes && hhcTypes.includes(element.id)}
                                                onClick={(e) => UpdateTypes(e.target.checked, element.id, hhcTypes, dispatch, 'hhcTypes')}
                                                />
                                                {element.value}
                                            </Label>
                                        </FormGroup>
                                    );
                                })}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </FormGroup>
        </TabPane>
    )
}

export default HHC 