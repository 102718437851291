import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';

class CancelFast360Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelReason: ''
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle = () => {
        this.props.clicked()
    };


    componentDidMount() {
        const { currentUserId } = this.props;
        let payload = {
            "functionName": "loadWorklistCancelReasonList",
            "zebraUserId": currentUserId,
        }
        this.props.getCancelReasons(payload);
    }

    handleChange = (e) => {
        this.setState({ cancelReason: e })
    }


    handleSave = () => {

        const { currentUserId, updatedRec, selectedUser, roleUsers } = this.props;
        let usertoCompare = selectedUser ? selectedUser : currentUserId
        const user = roleUsers && roleUsers.find(u => u.zebraId == usertoCompare);
        let payload1 = {}, payload2 = {}
        updatedRec.map(item => {
            payload1 = {
                "functionName": "cancelService",
                "zebraUserId": currentUserId,
                "referralId": item.FK_referral_id,
                "refTable": item.chr_reference_table,
                "serviceId": item.int_reference_table_id
            }
            payload2 = {
                "functionName": "updateOutcomes",
                "zebraUserId": currentUserId,
                "outcomeId": 52,
                "reasonId": this.state.cancelReason,
                // "refTable": item.ServiceType.toLowerCase(),
                "refTable": item.chr_reference_table,
                "serviceId": item.int_reference_table_id
            }
            this.props.postCancelService(payload1)
            this.props.postOutcomes(payload2)
        })
        setTimeout(() => {
            this.props.getUsersWorkList(user, usertoCompare)
        }, 3000);
        this.props.clicked();
        this.props.updateClickRecord();
    }


    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '300px' }} scrollable={'true'}>
                <ModalHeader toggle={this.toggle}> SELECT REASON </ModalHeader>
                <ModalBody>
                    <div style={{ paddingBottom: '10px' }}>Please Select  a Reason</div>
                    <Form>
                        <FormGroup>
                            <Input type="select" onChange={e => this.handleChange(e.target.value)} defaultValue="">
                                <option value="" disabled={true}>Reason</option>
                                {this.props.cancelReasons && this.props.cancelReasons.map(item => (
                                    <option value={item.status_id}>{item.value}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter> <Button style={{ width: '120px' }} color="primary" onClick={this.toggle} >CANCEL</Button>
                    <Button disabled={this.state.cancelReason == '' ? true : false} style={{ width: '120px' }} color="primary" onClick={this.handleSave} >SAVE</Button></ModalFooter>
            </Modal >
        )
    }
}


const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        cancelReasons: state.FAST360.fast360Reducer.updateGetCancelReasons ? state.FAST360.fast360Reducer.updateGetCancelReasons : [],
        roleUsers: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],

    };
};

const mapDispatchToProps = dispatch => ({
    getCancelReasons: (data) => dispatch(fast360Operations.getCancelReasons(data)),
    postCancelService: (data) => dispatch(fast360Operations.postCancelService(data)),
    postOutcomes: (data) => dispatch(fast360Operations.postOutcomes(data)),
    getUsersWorkList: (data, currentUserId) => dispatch(fast360Operations.getUsersWorkList(data, currentUserId)),
    updateClickRecord: () => dispatch(fast360Actions.updateClickRecord([])),
});




export default connect(mapStateToProps, mapDispatchToProps)(CancelFast360Modal);
