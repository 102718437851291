import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';

export default class ExpertFaxPendingTable extends Component {
  onAttachmentViewClickHandler = item => {
    // TODO
    console.log(item);
  };

  getRow(item) {
    return {
      dateSent: item.timesent,
      to: item.faxnumber,
      transactionID: item.transactionid,
      status: item.STATUS,
      attachment: (
        <Button
          type="button"
          onClick={() => {
            this.onAttachmentViewClickHandler(item);
          }}
        >
          View
        </Button>
      ),
    };
  }

  render() {
    const data = this.props.data.map(item => this.getRow(item));
    return (
      <AppTable
        resizable
        columns={[
          {
            header: 'Date Sent',
            field: 'dateSent',
          },
          {
            header: 'To',
            field: 'to',
          },
          {
            header: 'Transaction ID',
            field: 'transactionID',
          },
          {
            header: 'Status',
            field: 'status',
          },
          {
            header: 'Attachment',
            field: 'attachment',
          },
        ]}
        data={data}
        autoPagination={true}
      />
    );
  }
}

ExpertFaxPendingTable.propTypes = {
  data: PropTypes.array.isRequired,
};
