import React, { useState, useEffect } from 'react';
import {
    Row,
    Button,
    Col,
    FormGroup,
    Input

} from 'reactstrap';
import Fast360AppTable from 'shared/components/table/Fast360AppTable';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';
import moment from 'moment';
import { commonSelectors } from 'common/store';

const Fast360InvoiceCoordinator = ({ 
    userId, 
    loadInvoiceTaskData, 
    updateInvoiceTaskData, 
    invoiceTaskData, 
    dataFromReducer, 
    appLoading, 
    openReferral, 
    selectedTable, 
    setSelectedTable, 
    setSelectedUser,
    selectedUser,
}) => {
    const [taskData, setTaskData] = useState([]);
    const [filterInput, setFilterInput] = useState('');
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState({
        zebraId: '',
        id: '',
        username: '',
    });
    const { roleUsers } = dataFromReducer;

    useEffect(() => {
        if(selectedTable === '') {
            setSelectedTable('invoiceAttached')
        }
    }, [selectedTable])

    useEffect(() => {
        if (users && users.length > 0) {

            let currentUser;

            if(selectedUser && selectedUser !== '') {
                currentUser =  users &&
                users.find(u => u.zebraId == selectedUser);
            } else {
               currentUser =  users &&
                users.find(u => u.zebraId == userId);
            }

            if (currentUser) {
                setUserSelected(currentUser);
            }
        }
    }, [users])

    useEffect(() => {
        if (roleUsers && roleUsers.users && roleUsers.users.length > 0) {
            const usersList = [...roleUsers.users];
            usersList && usersList.unshift(

                {
                    zebraId: -100,
                    id: -100,
                    username: 'All',
                }
            );

            setUsers(usersList);

        }
    }, [roleUsers])

    useEffect(() => {
        let taskName = '';
        if (selectedTable === 'unbilled') {
            taskName = 'unbilled';
        }
        if (selectedTable === 'invoiceAttached') {
            taskName = 'invoice';
        }
        if (selectedTable === 'readyForBilling') {
            taskName = 'billing';
        }

        updateInvoiceTaskData([]);
        setTaskData([]);

        let req = {
            functionName: "loadWorkQueueData",
            zebraUserId: userId,
            taskName: taskName,
            userId: userSelected.id
        }

        if (selectedTable === 'wcedi') {
            req = {
                functionName: "loadWCEDIWorkQueueData"
            }

            loadInvoiceTaskData(req);

        } else {

            if (taskName !== '' && userSelected.id !== '') {

                loadInvoiceTaskData(req)
            }
        }


    }, [selectedTable, userSelected]);


    useEffect(() => {
        if (invoiceTaskData.length > 0) {
            setTaskData([...invoiceTaskData]);
            handleFilter({ target: { value: filterInput } });
        }
    }, [invoiceTaskData]);


    const handleUserChange = e => {
        const selectedUser =
            users &&
            users.find(u => u.zebraId == e.target.value);

        if (selectedUser) {
            setUserSelected(selectedUser);
            setSelectedUser(e.target.value);
        }

    };


    const handleFilter = e => {
        setFilterInput(e.target.value.toLowerCase());
        const lowercasedFilter = e.target.value.toLowerCase();

        const rec = [...invoiceTaskData];

        const filteredData = [];

        if (lowercasedFilter.length === 0) {
            setTaskData([...invoiceTaskData]);
            return;
        }

        rec.forEach((item) => {
            if (
                (item.confNo && item.confNo.toLowerCase().includes(lowercasedFilter)) ||
                (item.chr_claim_no &&
                    item.chr_claim_no.toLowerCase().includes(lowercasedFilter)) ||
                (item.claimantName &&
                    item.claimantName.toLowerCase().includes(lowercasedFilter)) ||
                (item.product &&
                    item.product.toLowerCase().includes(lowercasedFilter)) ||
                (item.Vendor && item.Vendor.toLowerCase().includes(lowercasedFilter)) ||
                (item.serviceDate &&
                    moment(item.serviceDate)
                        .format('MM/DD/YYYY')
                        .includes(lowercasedFilter)) ||
                (item.lang && item.lang.toLowerCase().includes(lowercasedFilter))
            ) {
                filteredData.push(item);
            }


        });

        setTaskData([...filteredData]);


    }

    const handleOpenReferral = (referralId, tableName, serviceId, currentTaskId, claimNo, ServiceType, enableGrid, currentItem) => {
        if (!currentItem.confNo) {
            return;
        }
        const confNo = currentItem.confNo.split('-');
        if (!referralId) {
            referralId = confNo[0];
        }
        if (!tableName) {
            tableName = confNo[1];
        }

        if (!serviceId) {
            serviceId = confNo[2].split(' ')[0];
        }

        openReferral(referralId, tableName, serviceId, currentItem.currentTaskID, currentItem.chr_claim_no, currentItem.product_desc, false);
    }

    return <>
        <Row>
            <Col xs="2" lg="1">
                <FormGroup>
                    <Button
                        type="button"
                        color={selectedTable === 'unbilled' ? "primary" : "secondary"}
                        style={{ width: '100%' }}
                        onClick={() => { setSelectedTable('unbilled') }}
                    >
                        Unbilled
                    </Button>
                </FormGroup>
            </Col>
            <Col xs="2" lg="1">
                <FormGroup>
                    <Button
                        type="button"
                        color={selectedTable === 'invoiceAttached' ? "primary" : "secondary"}
                        style={{ width: '100%' }}
                        onClick={() => { setSelectedTable('invoiceAttached') }}
                    >
                        Invoice Attached
                    </Button>
                </FormGroup>
            </Col>
            <Col xs="2" lg="1">
                <FormGroup>
                    <Button
                        type="button"
                        style={{ width: '100%' }}
                        color={selectedTable === 'readyForBilling' ? "primary" : "secondary"}
                        onClick={() => { setSelectedTable('readyForBilling') }}
                    >
                        Ready for Billing
                    </Button>
                </FormGroup>
            </Col>
            <Col xs="2" lg="1">
                <FormGroup>
                    <Button
                        type="button"
                        style={{ width: '100%' }}
                        color={selectedTable === 'inquiry' ? "primary" : "secondary"}
                        onClick={() => { setSelectedTable('inquiry') }}
                        disabled
                    >
                        Inquiry
                    </Button>
                </FormGroup>
            </Col>
            <Col xs="2" lg="1">
                <FormGroup>
                    <Button
                        type="button"
                        color={selectedTable === 'wcedi' ? "primary" : "secondary"}
                        style={{ width: '100%' }}
                        onClick={() => { setSelectedTable('wcedi') }}
                       // disabled
                    >
                        WC EDI
                    </Button>
                </FormGroup>
            </Col>
            <Col lg="2">
                <FormGroup>
                    <Input
                        type="select"
                        value={userSelected.zebraId}
                        onChange={handleUserChange}
                    >
                        {users &&
                            users.map((item, idx) => (
                                <option key={idx} value={item.zebraId}>{item.username}</option>
                            ))}
                    </Input>
                </FormGroup>
            </Col>
            <Col lg="2">
                <FormGroup>
                    <Input
                        type="text"
                        placeholder="Filter Tasks"
                        value={filterInput}
                        style={{ width: '100%' }}
                        onInput={e => handleFilter(e)}

                    ></Input>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                {selectedTable === 'unbilled' &&
                    <Fast360AppTable
                        columns={[
                            {
                                header: 'Conf #',
                                field: 'confNo',
                            },
                            {
                                header: 'Assigned User',
                                field: 'assignedUser',
                            },
                            {
                                header: 'Client Name',
                                field: 'clientName',
                            },
                            {
                                header: 'OOC',
                                field: 'OOC',
                            },
                            {
                                header: 'Claimant Name',
                                field: 'claimantName',
                            },
                            {
                                header: 'Claim #',
                                field: 'chr_claim_no',
                            },
                            {
                                header: 'Product',
                                field: 'product',
                            },
                            {
                                header: 'Date Of Service',
                                field: 'serviceDate',
                                render: item => item.serviceDate ? moment(item.serviceDate)
                                    .format('MM/DD/YYYY') : ''
                            },
                            {
                                header: 'Vendor Name',
                                field: 'Vendor',
                            },
                            {
                                header: 'Product Type',
                                field: 'product_type',
                            },
                            {
                                header: 'Vendor ID',
                                field: 'vendor_id',
                            },


                        ]}
                        data={taskData}
                        autoPagination={true}
                        sortAble={true}
                        sortImmediately={false}
                        resizable={true}
                        openReferral={handleOpenReferral}

                    />

                }

                {selectedTable === 'invoiceAttached' &&
                    <Fast360AppTable
                        columns={[
                            {
                                header: 'Invoice Attached Date',
                                field: 'dat_billed',
                            },
                            {
                                header: 'Conf #',
                                field: 'confNo',
                            },
                            {
                                header: 'Assigned User',
                                field: 'assignedUser',
                            },
                            {
                                header: 'Client Name',
                                field: 'clientName',
                            },


                            {
                                header: 'Claimant Name',
                                field: 'claimantName',
                            },
                            {
                                header: 'Claim #',
                                field: 'chr_claim_no',
                            },
                            {
                                header: 'Date Of Service',
                                field: 'serviceDate',
                                render: item => item.serviceDate ? moment(item.serviceDate)
                                    .format('MM/DD/YYYY') : ''
                            },

                            {
                                header: 'Vendor Name',
                                field: 'Vendor',
                            },

                            {
                                header: 'Product Type',
                                field: 'product_type',
                            },
                            {
                                header: 'Vendor ID',
                                field: 'vendor_id',
                            },


                        ]}
                        data={taskData}
                        autoPagination={true}
                        sortAble={true}
                        sortImmediately={false}
                        resizable={true}
                        openReferral={handleOpenReferral}


                    />

                }

                {selectedTable === 'readyForBilling' &&
                    <Fast360AppTable
                        columns={[
                            {
                                header: 'Invoice Attached Date',
                                field: 'dat_billed',
                            },
                            {
                                header: 'Conf #',
                                field: 'confNo',
                            },
                            {
                                header: 'Assigned User',
                                field: 'assignedUser',
                            },
                            {
                                header: 'Client Name',
                                field: 'clientName',
                            },


                            {
                                header: 'Claimant Name',
                                field: 'claimantName',
                            },
                            {
                                header: 'Claim #',
                                field: 'chr_claim_no',
                            },

                            {
                                header: 'Date Of Service',
                                field: 'serviceDate',
                                render: item => item.serviceDate ? moment(item.serviceDate)
                                    .format('MM/DD/YYYY') : ''
                            },
                            {
                                header: 'Vendor Name',
                                field: 'Vendor',
                            },
                            {
                                header: 'Product Type',
                                field: 'product_type',
                            },
                            {
                                header: 'Vendor ID',
                                field: 'vendor_id',
                            },


                        ]}
                        data={taskData}
                        autoPagination={true}
                        sortAble={true}
                        sortImmediately={false}
                        resizable={true}
                        openReferral={handleOpenReferral}

                    />

                }

                {selectedTable === 'wcedi' &&
                    <Fast360AppTable
                        columns={[
                            // {
                            //     header: 'Billed Date',
                            //     field: 'dat_billed',
                            // },
                            {
                                header: 'Conf #',
                                field: 'confNo',
                            },
                            {
                                header: 'Client Name',
                                field: 'clientName',
                            },
                            {
                                header: 'OOC',
                                field: 'OOC',
                            },
                            {
                                header: 'Claimant Name',
                                field: 'claimantName',
                            },
                            {
                                header: 'Claim #',
                                field: 'chr_claim_no',
                            },
                            {
                                header: 'Product',
                                field: 'product',
                            },
                            {
                                header: 'Date Of Service',
                                field: 'serviceDate',
                                render: item => item.serviceDate ? moment(item.serviceDate)
                                    .format('MM/DD/YYYY') : ''
                            },
                            {
                                header: 'Vendor Name',
                                field: 'Vendor',
                            },
                            {
                                header: 'Product Type',
                                field: 'product_type',
                            },
                            {
                                header: 'Vendor ID',
                                field: 'vendor_id',
                            },
                            {
                                header: 'Bill Date',
                                field: 'dat_sentToStrata',
                            },
                            {
                                header: 'Status',
                                field: 'chr_status',
                            },
                            {
                                header: 'Reason',
                                field: 'chr_WCEDI_Error_Type',
                            },
                            {
                                header: 'Provider Payment Terms',
                                field: 'chr_terms',
                            },


                        ]}
                        data={taskData}
                        autoPagination={true}
                        sortAble={true}
                        sortImmediately={false}
                        resizable={true}
                        openReferral={handleOpenReferral}

                    />

                }



            </Col>
        </Row>


    </>;
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    const appLoading = commonSelectors.appLoading(state);

    return {
        userId: userId,
        invoiceTaskData: state.FAST360.fast360Reducer.invoiceTaskData,
        dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
        appLoading: appLoading,
        selectedTable: state.FAST360.fast360Reducer.selectedInvoiceCoordinatorTable,
        selectedUser: state.FAST360.fast360Reducer.selectedInvoiceCoordinatorUser,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadInvoiceTaskData: (data) => dispatch(fast360Operations.loadInvoiceTaskData(data)),
        updateInvoiceTaskData: (data) => dispatch(fast360Actions.updateInvoiceTaskData(data)),
        setSelectedTable: (data) => dispatch(fast360Actions.setSelectedInvoiceCoordinatorTable(data)),
        setSelectedUser: data =>  dispatch(fast360Actions.setSelectedInvoiceCoordinatorUser(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fast360InvoiceCoordinator);