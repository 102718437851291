import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import { assign, isEmpty } from 'lodash';
import { Col, Row, Label, Input, Button, Form } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import HCPCSearchModal from './HCPCSearch';
import Fast360FeeLookupModal from './Fast360FeeLookupModal';
import AppDateInput from 'shared/components/input/AppDateInput';
import PopupModal from 'shared/components/PopupModal';
import { primaryData, secondaryData, discountData, calcData, calculator, rentalUnit } from '../utils/constantUtils';

let checkCode = '';
const HCPCDetailModal = ({
  disableSave, 
  isOpen,
  onClose,
  resetLoadHCPCTable,
  hcpcList,
  userId,
  loadHCPC,
  patientInfo,
  BRTypes,
  getDefaultCostSavingsPercent,
  getDetailLineFee,
  clientFeeLookup,
  updateAudInfoTable,
  alert,
  rowInfo,
  audInfo,
  hcpcCheck,
  hcpcTermCheck,
  serviceIdFromRef,
  saveMSEHCPC,
  serviceId,
  referralId,
  module,
  isFromLanding,
  workListData,
  detailLineFeeInfo,
  clientFeeInfo,
  resetGetDetailLineFeeTable,
  resetClientFeeLookupTable,
  loadAudiologyHcpcsList,
  loadCostSavings,
  newServiceId,
  copiedService,
  vendorId
}) => {
  const [openHCPCServiceDetail, setOpenHCPCServiceDetail] = useState(false);
  const [isHCPCCodeDesc, setIsHCPCCodeDesc] = useState({});
  const [showHCPCSearchModal, setShowHCPCSearchModal] = useState(false);
  const [checkCodeModal, setCheckCodeModal] = useState(false);
  const [lookupModal, setLookupModal] = useState(false);
  const [showCheckHcpcCodeModal, setShowCheckHcpcCodeModal] = useState(false);
  const [formCustomizableFields, setFormCustomizableFields] = useState({});
  const [formHCPCFields, setFormHCPCFields] = useState({
    hcpcCode: '',
    hcpcDesc: '',
    mod1: '',
    mod2: '',
    qty: 0,
    revenueCode: '',
    costPercentSavings: 25,
    vendorDisc: '',
    custDisc: '',
    fs: '',
    uc: '',
    mca: '',
    owcp: '',
    msrp: '',
    flat: '',
    calcValue: '',
    calOption: '',
    calcData: '',
    totValue: '',
    startDate: '',
    endDate: '',
    duration: '',
    rentUnit: '',
    vendBuyout: '',
    custBuyout: '',
    rentExt: false,
    finalBilledCharge: `${0.00}`,
    finalCostSavingPercent: `${0.00}`
  });

  const toggle = () => {
    onClose();
    resetFormData();
    resetLoadHCPCTable();
  }

  const calcMargin = (custCost, venCost) => {
    let val;
    val = Number(((custCost - venCost) / custCost) * 100).toFixed(2);
    return `${val}%`;
  }

  const confirmHcpcCodeCheck = () => {
    setShowCheckHcpcCodeModal(!showCheckHcpcCodeModal);
    resetFormData();
  };

  const getHcpcCodeCheckContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              {(formHCPCFields.hcpcCode === 'A9999' || formHCPCFields.hcpcCode === 'A9999') ?
                "HCPC E1399 must be used for this juris state" : "HCPC A9999 must be used for juris states of CA, FL, and KY."}
              <br />
              <br />
              <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={confirmHcpcCodeCheck}>
                Ok{' '}
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  const openHCPCService = (isSaving) => {
    if (isSaving && isFromLanding && newServiceId === undefined && copiedService === undefined) {
      if (formHCPCFields.qty === '' || formHCPCFields.qty <= 0) {
        alert('Quantity must be greater than 0.')
      } else if (formHCPCFields.hcpcDesc === '' || formHCPCFields.hcpcDesc === undefined) {
        alert('HCPC description is required.')
      } else {
        const newRow = {
          id: 0,
          dos: patientInfo.audZip,
          modifier1: formHCPCFields.mod1,
          modifier2: formHCPCFields.mod2,
          hcpcCodeDesc: formHCPCFields.hcpcCode + '-' + formHCPCFields.hcpcDesc,
          hcpcCode: formHCPCFields.hcpcCode,
          hcpcDesc: formHCPCFields.hcpcDesc,
          serviceCode: formHCPCFields.hcpcCode,
          serviceDescription: formHCPCFields.hcpcDesc,
          altDescription: formHCPCFields.hcpcDesc,
          revCode: formHCPCFields.revenueCode,
          rentalUnit: formHCPCFields.rentUnit,
          duration: formHCPCFields.duration,
          startExtDate: dateFormat(formHCPCFields.startDate),
          endExtDate: dateFormat(formHCPCFields.endDate),
          vendBuyOut: formHCPCFields.vendBuyout,
          custBuyOut: formHCPCFields.custBuyout,
          rentalExt: formHCPCFields.rentExt,
          PercentAdjustment: Number(formHCPCFields.costPercentSavings) || 25,
          clientFlatRate: Number(
            formHCPCFields.clientFlatRate === undefined ? 0.0 : formHCPCFields.clientFlatRate
          ).toFixed(2),
          feeSchedule: Number(
            formHCPCFields.fs === undefined ? 0.0 : formHCPCFields.fs
          ).toFixed(2),
          medicareAllowable: Number(
            formHCPCFields.mca === undefined ? 0.0 : formHCPCFields.mca
          ).toFixed(2),
          ucSchedule: Number(
            formHCPCFields.uc === undefined ? 0.0 : formHCPCFields.uc
          ).toFixed(2),
          owcpRate: Number(
            formHCPCFields.owcp === undefined ? 0.0 : formHCPCFields.owcp
          ).toFixed(2),
          msrp: Number(formHCPCFields.msrp === undefined ? 0.0 : formHCPCFields.msrp
          ).toFixed(2),
          BilledCharges: Number(
            formHCPCFields.finalBilledCharge
          ).toFixed(2),
          quantity: formHCPCFields.qty,
          venRealUniCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost
            ).toFixed(2),
          vendCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ).toFixed(2),
          vendDiscountedFrom: formHCPCFields.vendorDisc,
          vendPercentDiscount:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent ===
                undefined
                ? 0
                : Number(formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent) / 100
            ),
          clientUniCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custUnitCost
            ).toFixed(2),
          custCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2),
          custDiscountedFrom: formHCPCFields.custDisc,
          custPercentDiscount:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent ===
              undefined
              ? 0
              : Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent) / 100),
          vendTotal:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          TotalAllowance:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          margin: calcMargin(formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2), formCustomizableFields[formHCPCFields.vendorDisc] &&
          Number(
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
              undefined
              ? 0
              : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
          ).toFixed(2)),
        }
        const cloneAudInfo = audInfo && audInfo[patientInfo.module + patientInfo.serviceId] ? audInfo[patientInfo.module + patientInfo.serviceId] : [];
        let formInfo = [];
        if (rowInfo) {
          newRow.id = rowInfo?.id;
          newRow.rental = {
            rentalExt: newRow?.rentalExt,
            rentalUnit: newRow?.rentalUnit,
            custBuyOut: newRow?.custBuyOut,
            vendBuyOut: newRow?.vendBuyOut
          }
          cloneAudInfo.splice(rowInfo.index, 1, newRow);
          formInfo = cloneAudInfo;
          newRow.costSaving = {
            id: rowInfo?.fieldId,
            ServiceId: serviceIdFromRef,
            DetailId: rowInfo?.DetailId,
            PercentAdjustment: newRow?.PercentAdjustment,
            BilledCharges: newRow?.BilledCharges,
            TotalAllowance: newRow?.TotalAllowance,
            fields: {},
            fields: {
              id: rowInfo?.fieldId
            }
          }
          newRow.functionName = (module === 'MSE') ? "saveMSEHCPC" : (module === 'AUD') ? 'saveAudHCPC' : (module === 'DGN') ? 'saveDiagHCPC' : '';
          newRow.zebraUserId = userId;
          newRow.serviceId = serviceId;
          let saveRow = [newRow];
          saveRow.forEach(i => {
            delete i.PercentAdjustment;
            delete i.clientUniCost;
            delete i.dos;
            delete i.hcpcCodeDesc;
            delete i.hcpcCode;
            delete i.hcpcDesc;
            delete i.margin;
            delete i.venRealUniCost;
            delete i.vendTotal;
          });
          let postPayload = {
            zebraUserId: userId,
            audiology_id: serviceId,
            tableAbbrv: module,
            transport_type: 0
          };
          saveMSEHCPC(...saveRow, postPayload);
        } else {
          formInfo = cloneAudInfo?.concat([newRow]);
          newRow.id = 0;
          newRow.rental = {
            rentalExt: newRow?.rentalExt,
            rentalUnit: newRow?.rentalUnit,
            custBuyOut: newRow?.custBuyOut,
            vendBuyOut: newRow?.vendBuyOut
          }
          newRow.costSaving = {
            id: 0,
            ServiceId: serviceIdFromRef,
            DetailId: 0,
            PercentAdjustment: newRow?.PercentAdjustment,
            BilledCharges: newRow?.BilledCharges,
            TotalAllowance: newRow?.TotalAllowance,
            fields: {},
            fields: {
              id: 0
            }
          }
          newRow.functionName = (module === 'MSE') ? "saveMSEHCPC" : (module === 'AUD') ? 'saveAudHCPC' : (module === 'DGN') ? 'saveDiagHCPC' : '';
          newRow.zebraUserId = userId;
          newRow.serviceId = serviceId;
          let saveRow = [newRow];
          saveRow.forEach(i => {
            delete i.PercentAdjustment;
            delete i.clientUniCost;
            delete i.dos;
            delete i.hcpcCodeDesc;
            delete i.hcpcCode;
            delete i.hcpcDesc;
            delete i.margin;
            delete i.venRealUniCost;
            delete i.vendTotal;
          });
          let postPayload = {
            zebraUserId: userId,
            audiology_id: serviceId,
            tableAbbrv: module,
            transport_type: 0
          };
          saveMSEHCPC(...saveRow, postPayload);
        }
        // updateAudInfoTable({...audInfo, [patientInfo.module+patientInfo.serviceId]: formInfo})
        onClose();
        resetFormData();
      }
    } else if (isSaving && !isFromLanding) {
      if (formHCPCFields.qty === '' || formHCPCFields.qty <= 0) {
        alert('Quantity must be greater than 0.')
      } else if (formHCPCFields.hcpcDesc === '' || formHCPCFields.hcpcDesc === undefined) {
        alert('HCPC description is required.')
      } else {
        const newRow = {
          id: 0,
          dos: patientInfo.audZip,
          modifier1: formHCPCFields.mod1,
          modifier2: formHCPCFields.mod2,
          hcpcCodeDesc: formHCPCFields.hcpcCode + '-' + formHCPCFields.hcpcDesc,
          hcpcCode: formHCPCFields.hcpcCode,
          hcpcDesc: formHCPCFields.hcpcDesc,
          //revCode: formHCPCFields.revenueCode,
          serviceCode: formHCPCFields.hcpcCode,
          serviceDescription: formHCPCFields.hcpcDesc,
          altDescription: formHCPCFields.hcpcDesc,
          revCode: formHCPCFields.revenueCode,
          rentalUnit: formHCPCFields.rentUnit,
          duration: formHCPCFields.duration,
          startExtDate: formHCPCFields.startDate,
          endExtDate: formHCPCFields.endDate,
          vendBuyOut: formHCPCFields.vendBuyout,
          custBuyOut: formHCPCFields.custBuyout,
          rentalExt: formHCPCFields.rentExt,
          PercentAdjustment: Number(formHCPCFields.costPercentSavings) || 25,
          clientFlatRate: Number(
            formHCPCFields.clientFlatRate === undefined ? 0.0 : formHCPCFields.clientFlatRate
          ).toFixed(2),
          feeSchedule: Number(
            formHCPCFields.fs === undefined ? 0.0 : formHCPCFields.fs
          ).toFixed(2),
          medicareAllowable: Number(
            formHCPCFields.mca === undefined ? 0.0 : formHCPCFields.mca
          ).toFixed(2),
          ucSchedule: Number(
            formHCPCFields.uc === undefined ? 0.0 : formHCPCFields.uc
          ).toFixed(2),
          owcpRate: Number(
            formHCPCFields.owcp === undefined ? 0.0 : formHCPCFields.owcp
          ).toFixed(2),
          msrp: Number(formHCPCFields.msrp === undefined ? 0.0 : formHCPCFields.msrp
          ).toFixed(2),
          BilledCharges: Number(
            formHCPCFields.finalBilledCharge
          ).toFixed(2),
          quantity: formHCPCFields.qty,
          venRealUniCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost
            ).toFixed(2),
          vendCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ).toFixed(2),
          vendDiscountedFrom: formHCPCFields.vendorDisc,
          vendPercentDiscount:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent ===
                undefined
                ? 0
                : Number(formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent) / 100
            ),
          clientUniCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custUnitCost
            ).toFixed(2),
          custCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2),
          custDiscountedFrom: formHCPCFields.custDisc,
          custPercentDiscount:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent ===
              undefined
              ? 0
              : Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent) / 100
            ),
          vendTotal:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          TotalAllowance:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          margin: calcMargin(formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2), formCustomizableFields[formHCPCFields.vendorDisc] &&
          Number(
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
              undefined
              ? 0
              : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
          ).toFixed(2)),
        }
        const cloneAudInfo = audInfo && audInfo[patientInfo.module + patientInfo.serviceId] ? audInfo[patientInfo.module + patientInfo.serviceId] : [];
        let formInfo = [];
        if (rowInfo) {
          cloneAudInfo.splice(rowInfo.index, 1, newRow);
          formInfo = cloneAudInfo;
        } else {
          formInfo = cloneAudInfo.concat([newRow]);
        }
        updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: formInfo })
        onClose();
        resetFormData();
      }
    } else if (isSaving && isFromLanding && (newServiceId === 0 || copiedService === true)) {
      if (formHCPCFields.qty === '' || formHCPCFields.qty <= 0) {
        alert('Quantity must be greater than 0.')
      } else if (formHCPCFields.hcpcDesc === '' || formHCPCFields.hcpcDesc === undefined) {
        alert('HCPC description is required.')
      } else {
        const newRow = {
          id: 0,
          dos: patientInfo.audZip,
          modifier1: formHCPCFields.mod1,
          modifier2: formHCPCFields.mod2,
          hcpcCodeDesc: formHCPCFields.hcpcCode + '-' + formHCPCFields.hcpcDesc,
          hcpcCode: formHCPCFields.hcpcCode,
          hcpcDesc: formHCPCFields.hcpcDesc,
          //revCode: formHCPCFields.revenueCode,
          serviceCode: formHCPCFields.hcpcCode,
          serviceDescription: formHCPCFields.hcpcDesc,
          altDescription: formHCPCFields.hcpcDesc,
          revCode: formHCPCFields.revenueCode,
          rentalUnit: formHCPCFields.rentUnit,
          duration: formHCPCFields.duration,
          startExtDate: formHCPCFields.startDate,
          endExtDate: formHCPCFields.endDate,
          vendBuyOut: formHCPCFields.vendBuyout,
          custBuyOut: formHCPCFields.custBuyout,
          rentalExt: formHCPCFields.rentExt,
          PercentAdjustment: Number(formHCPCFields.costPercentSavings) || 25,
          clientFlatRate: Number(
            formHCPCFields.clientFlatRate === undefined ? 0.0 : formHCPCFields.clientFlatRate
          ).toFixed(2),
          feeSchedule: Number(
            formHCPCFields.fs === undefined ? 0.0 : formHCPCFields.fs
          ).toFixed(2),
          medicareAllowable: Number(
            formHCPCFields.mca === undefined ? 0.0 : formHCPCFields.mca
          ).toFixed(2),
          ucSchedule: Number(
            formHCPCFields.uc === undefined ? 0.0 : formHCPCFields.uc
          ).toFixed(2),
          owcpRate: Number(
            formHCPCFields.owcp === undefined ? 0.0 : formHCPCFields.owcp
          ).toFixed(2),
          msrp: Number(formHCPCFields.msrp === undefined ? 0.0 : formHCPCFields.msrp
          ).toFixed(2),
          BilledCharges: Number(
            formHCPCFields.finalBilledCharge
          ).toFixed(2),
          quantity: formHCPCFields.qty,
          venRealUniCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost
            ).toFixed(2),
          vendCost:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ).toFixed(2),
          vendDiscountedFrom: formHCPCFields.vendorDisc,
          vendPercentDiscount:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent ===
                undefined
                ? 0
                : Number(formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent) / 100
            ),
          clientUniCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custUnitCost ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custUnitCost
            ).toFixed(2),
          custCost:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2),
          custDiscountedFrom: formHCPCFields.custDisc,
          custPercentDiscount:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent ===
              undefined
              ? 0
              : Number(formCustomizableFields[formHCPCFields.custDisc].custDiscPercent) / 100
            ),
          vendTotal:
            formCustomizableFields[formHCPCFields.vendorDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          TotalAllowance:
            formCustomizableFields[formHCPCFields.custDisc] &&
            Number(Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ) * Number(formHCPCFields.qty)).toFixed(2),
          margin: calcMargin(formCustomizableFields[formHCPCFields.custDisc] &&
            Number(
              formCustomizableFields[formHCPCFields.custDisc].custBillChrg ===
                undefined
                ? 0
                : formCustomizableFields[formHCPCFields.custDisc].custBillChrg
            ).toFixed(2), formCustomizableFields[formHCPCFields.vendorDisc] &&
          Number(
            formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg ===
              undefined
              ? 0
              : formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
          ).toFixed(2)),
        }
        const cloneAudInfo = audInfo && audInfo[patientInfo.module + patientInfo.serviceId] ? audInfo[patientInfo.module + patientInfo.serviceId] : [];
        let formInfo = [];
        if (rowInfo) {
          cloneAudInfo.splice(rowInfo.index, 1, newRow);
          formInfo = cloneAudInfo;
        } else {
          formInfo = cloneAudInfo.concat([newRow]);
        }
        updateAudInfoTable({ ...audInfo, [patientInfo.module + patientInfo.serviceId]: formInfo })
        onClose();
        resetFormData();
      }
    } else {
      resetFormData();
      toggle();
    }
  };

  const resetFormData = () => {
    setFormHCPCFields({
      hcpcCode: '',
      hcpcDesc: '',
      mod1: '',
      mod2: '',
      qty: 0,
      revenueCode: '',
      costPercentSavings: 25,
      vendorDisc: '',
      custBillChrg: '',
      venBillChrg: '',
      custDisc: '',
      venFlatRate: 0,
      clientFlatRate: 0,
      fs: '',
      uc: '',
      mca: '',
      owcp: '',
      msrp: '',
      flat: '',
      calcValue: '',
      calOption: '',
      calcData: '',
      totValue: '',
      startDate: '',
      endDate: '',
      duration: '',
      rentUnit: '',
      vendBuyout: '',
      custBuyout: '',
      rentExt: false,
      finalBilledCharge: `${0.0}`,
      finalCostSavingPercent: `${0.0}`,
    });
    setFormCustomizableFields({});
    setIsHCPCCodeDesc({});
  };

  const resetHCPCClearFormData = () => {
    setFormHCPCFields({
      hcpcCode: '',
      hcpcDesc: '',
      mod1: formHCPCFields.mod1,
      mod2: formHCPCFields.mod2,
      qty: formHCPCFields.qty,
      revenueCode: '',
      costPercentSavings: 25,
      vendorDisc: '',
      custDisc: '',
      fs: '',
      uc: '',
      mca: '',
      owcp: '',
      msrp: '',
      flat: '',
      venFlatRate: 0,
      clientFlatRate: 0,
      calcValue: '',
      calOption: '',
      calcData: '',
      totValue: '',
      startDate: '',
      endDate: '',
      duration: '',
      rentUnit: '',
      vendBuyout: '',
      custBuyout: '',
      rentExt: false,
      finalBilledCharge: `${0.0}`,
      finalCostSavingPercent: `${0.0}`,
    });
    setFormCustomizableFields({});
    setIsHCPCCodeDesc({});
    resetGetDetailLineFeeTable();
    resetClientFeeLookupTable();
  };

  useEffect(() => {
    if (!isEmpty(detailLineFeeInfo) && !isEmpty(clientFeeInfo)) {
      let vendorUnitCost = detailLineFeeInfo?.discountedFrom === 'UC' ?
        formHCPCFields.uc : detailLineFeeInfo?.discountedFrom === 'OWCP' ?
          formHCPCFields.owcp : detailLineFeeInfo?.discountedFrom === 'FS' ?
            formHCPCFields.fs : detailLineFeeInfo?.discountedFrom === 'MCA' ?
              formHCPCFields.mca : '';
      let customerUnitCost = clientFeeInfo?.discountedFrom === 'UC' ?
        formHCPCFields.uc : clientFeeInfo?.discountedFrom === 'OWCP' ?
          formHCPCFields.owcp : clientFeeInfo?.discountedFrom === 'FS' ?
            formHCPCFields.fs : clientFeeInfo?.discountedFrom === 'MCA' ?
              formHCPCFields.mca : clientFeeInfo?.discountedFrom === 'FLAT' ?
              clientFeeInfo?.flatRt : '';
      let finalBillCharge = Number((Number(customerUnitCost) * Number(formHCPCFields.qty)*2)).toFixed(2);
      if(formHCPCFields.uc && formHCPCFields.fs && Number(formHCPCFields.fs) > Number(formHCPCFields.uc)) {
        finalBillCharge = Number(Number(formHCPCFields.fs) * Number(formHCPCFields.qty)).toFixed(2) * 2; 
      } else if(formHCPCFields.uc && Number(formHCPCFields.uc) > 0) {
        finalBillCharge = Number(Number(formHCPCFields.uc) * Number(formHCPCFields.qty)).toFixed(2); 
      }
      // let finalSavingsPercent = 0;
      // if(customerUnitCost) {
      //   finalSavingsPercent = Number((((Number(customerUnitCost) * Number(formHCPCFields.qty)) - (Number(clientFeeInfo?.amount)* formHCPCFields.qty))/Number(Number(customerUnitCost) * Number(formHCPCFields.qty)))*100).toFixed(0)
      // }
      let billVal;
      let costPer = 0;
      let checkVal = customerUnitCost;
      let calBill = Number(clientFeeInfo?.amount) > 0 ? Number(clientFeeInfo?.amount).toFixed(2) : customerUnitCost;
      let calVal = customerUnitCost;
      let costPercent = 0;
      let disc = clientFeeInfo?.discountedFrom;
      let percent =  Number(Number(clientFeeInfo.percentDiscount === undefined ? 0 : clientFeeInfo.percentDiscount) * 100 || 0).toFixed(2);

      if (checkVal === 0 || checkVal === "" || checkVal === undefined || checkVal === '0.00' || checkVal === 0.00) {
        billVal = Number((Number(calBill) / ((100 - Number(costPercent)) / 100)) * Number(formHCPCFields.qty)).toFixed(2);
        costPer = costPercent;
        formHCPCFields.finalBilledCharge = billVal;
      } else {
        if ((Number(formHCPCFields.fs) > 0)  && (Number(formHCPCFields.uc) > 0) &&  (Number(formHCPCFields.fs) > Number(formHCPCFields.uc)) ){
          billVal = (Number(formHCPCFields.fs) * Number(formHCPCFields.qty)).toFixed(2);
          costPer = percent;
          formHCPCFields.finalBilledCharge = billVal;
        }else if ((disc != 'UC') && (formHCPCFields.uc === undefined || Number(formHCPCFields.uc) === 0 ) ){
          billVal = (Number((Number(calVal) / ((100 - Number(costPercent)) / 100)) * Number(formHCPCFields.qty))).toFixed(2) ;
          costPer = percent;
          formHCPCFields.finalBilledCharge = billVal;
        }else if ((disc != 'UC') && (Number(formHCPCFields.uc) > 0) ){
          billVal = (Number(formHCPCFields.uc) * Number(formHCPCFields.qty)).toFixed(2) ;
          costPer = percent;
          formHCPCFields.finalBilledCharge = billVal;
        }else{
          billVal = Number((Number(calVal) * Number(formHCPCFields.qty))).toFixed(2);
          costPer = percent;
          formHCPCFields.finalBilledCharge = billVal;
        }
       
      }
      costPer = Number(
        ((Number(billVal) - Number(calBill)) /
          Number(formHCPCFields.finalBilledCharge)) *
          100
      ).toFixed(0);
      
      setFormHCPCFields({
        ...formHCPCFields,
        vendorDisc: detailLineFeeInfo?.discountedFrom,
        custDisc: clientFeeInfo?.discountedFrom,
        finalCostSavingPercent: costPer,
        finalBilledCharge: finalBillCharge,
        clientFlatRate: clientFeeInfo?.flatRt,
      });
      if (detailLineFeeInfo?.discountedFrom === clientFeeInfo?.discountedFrom) {
        let vendorUnitCost = detailLineFeeInfo?.discountedFrom === 'UC' ?
          formHCPCFields.uc : detailLineFeeInfo?.discountedFrom === 'OWCP' ?
            formHCPCFields.owcp : detailLineFeeInfo?.discountedFrom === 'FS' ?
              formHCPCFields.fs : detailLineFeeInfo?.discountedFrom === 'MCA' ?
                formHCPCFields.mca : '';
        let customerUnitCost = clientFeeInfo?.discountedFrom === 'UC' ?
          formHCPCFields.uc : clientFeeInfo?.discountedFrom === 'OWCP' ?
            formHCPCFields.owcp : clientFeeInfo?.discountedFrom === 'FS' ?
              formHCPCFields.fs : clientFeeInfo?.discountedFrom === 'MCA' ?
                formHCPCFields.mca : clientFeeInfo?.discountedFrom === 'FLAT' ?
                clientFeeInfo?.flatRt : '';
        setFormCustomizableFields({
          ...formCustomizableFields,
          [clientFeeInfo?.discountedFrom]: {
            custDisc: clientFeeInfo?.discountedFrom,
            custUnitCost: customerUnitCost,
            custDiscPercent: Number(Number(clientFeeInfo.percentDiscount === undefined ? 0 : clientFeeInfo.percentDiscount) * 100 || 0).toFixed(2),
            custBillChrg: Number(clientFeeInfo?.amount) > 0 ? Number(clientFeeInfo?.amount).toFixed(2) : customerUnitCost,
            vendorDisc: detailLineFeeInfo?.discountedFrom,
            venUnitCost: vendorUnitCost,
            venDiscQuantity: 0,
            venDiscPercent: Number(Number(detailLineFeeInfo.percentDiscount === undefined ? 0 : detailLineFeeInfo.percentDiscount) * 100 || 0).toFixed(2),
            venBillChrg: Number(detailLineFeeInfo?.amount) > 0 ? Number(detailLineFeeInfo?.amount).toFixed(2) : vendorUnitCost,
          },
        });
      } else {
        setFormCustomizableFields({
          [detailLineFeeInfo?.discountedFrom]: {
            vendorDisc: detailLineFeeInfo?.discountedFrom,
            venUnitCost: detailLineFeeInfo?.discountedFrom === 'UC' ?
              formHCPCFields.uc : detailLineFeeInfo?.discountedFrom === 'OWCP' ?
                formHCPCFields.owcp : detailLineFeeInfo?.discountedFrom === 'FS' ?
                  formHCPCFields.fs : detailLineFeeInfo?.discountedFrom === 'MCA' ?
                    formHCPCFields.mca : '',
            venDiscQuantity: 0,
            venDiscPercent: Number(Number(detailLineFeeInfo.percentDiscount === undefined ? 0 : detailLineFeeInfo.percentDiscount) * 100 || 0).toFixed(2),
            venBillChrg: Number(detailLineFeeInfo?.amount) > 0 ? Number(detailLineFeeInfo?.amount).toFixed(2) : vendorUnitCost,
          },
          [clientFeeInfo?.discountedFrom]: {
            custDisc: clientFeeInfo?.discountedFrom,
            custUnitCost: clientFeeInfo?.discountedFrom === 'UC' ?
              formHCPCFields.uc : clientFeeInfo?.discountedFrom === 'OWCP' ?
                formHCPCFields.owcp : clientFeeInfo?.discountedFrom === 'FS' ?
                  formHCPCFields.fs : clientFeeInfo?.discountedFrom === 'MCA' ?
                    formHCPCFields.mca : clientFeeInfo?.discountedFrom === 'FLAT' ?
                    clientFeeInfo?.flatRt : '',
            custDiscPercent: Number(Number(clientFeeInfo.percentDiscount === undefined ? 0 : clientFeeInfo.percentDiscount) * 100 || 0).toFixed(2),
            custBillChrg: Number(clientFeeInfo?.amount) > 0 ? Number(clientFeeInfo?.amount).toFixed(2) : customerUnitCost,
          }
        });
      }
    // calcBillCharge(formHCPCFields.uc, isHCPCCodeDesc.dbl_fee80, isHCPCCodeDesc.dbl_fee80, value, 0, formHCPCFields.costPercentSavings);
      // calcBillCharge(
      //   customerUnitCost, 
      //   customerUnitCost,
      //   Number(clientFeeInfo?.amount) > 0 ? Number(clientFeeInfo?.amount).toFixed(2) : customerUnitCost,  
      //   clientFeeInfo?.discountedFrom, 
      //   Number(Number(clientFeeInfo.percentDiscount === undefined ? 0 : clientFeeInfo.percentDiscount) * 100 || 0).toFixed(2),
      //   formHCPCFields.costPercentSavings
      // );
      
    }

  }, [detailLineFeeInfo, clientFeeInfo]);

  useEffect(() => {
    if (isOpen) {
      if (rowInfo) {
        let custBillCharge = isNaN(
          Number(
            Number(Number(rowInfo.custCost) * Number(rowInfo.quantity)).toFixed(
              2
            ) / Number(rowInfo.quantity)
          ).toFixed(2)
        )
          ? 0
          : Number(
              Number(
                Number(rowInfo.custCost) * Number(rowInfo.quantity)
              ).toFixed(2) / Number(rowInfo.quantity)
            ).toFixed(2);       
        setFormHCPCFields({
          id: rowInfo.id || 0,
          hcpcCode: rowInfo.serviceCode,
          hcpcDesc: rowInfo.altDescription ? rowInfo.altDescription : rowInfo.serviceDescription,
          mod1: rowInfo.modifier1,
          mod2: rowInfo.modifier2,
          qty: rowInfo.quantity,
          revenueCode: rowInfo.revCode,
          costPercentSavings: rowInfo.PercentAdjustment,
          vendorDisc: rowInfo.vendDiscountedFrom,
          custDisc: rowInfo.custDiscountedFrom,
          fs: rowInfo.feeSchedule === undefined ? 0 : rowInfo.feeSchedule,
          uc: rowInfo.ucSchedule,
          mca: rowInfo.medicareAllowable,
          msrp: rowInfo.msrp,
          owcp: rowInfo.owcpRate,
          venFlatRate: 0,
          clientFlatRate: rowInfo.clientFlatRate,
          finalBilledCharge: rowInfo.BilledCharges,
          finalCostSavingPercent: Number(((Number(rowInfo.billCharges) - (Number(custBillCharge)* Number(rowInfo.quantity)))/Number(rowInfo.billCharges))*100).toFixed(0),
          duration: rowInfo.duration,
          startDate: dateRowInfoFormat(rowInfo.startExtDate),
          endDate: dateRowInfoFormat(rowInfo.endExtDate),
          vendBuyout: rowInfo.rental?.vendBuyOut,
          custBuyout: rowInfo.rental?.custBuyOut,
          rentUnit: rowInfo.rental?.rentalUnit,
          rentExt: rowInfo.rentalExt
        });
        if (rowInfo.vendDiscountedFrom === rowInfo.custDiscountedFrom) {
          setFormCustomizableFields({
            [rowInfo.vendDiscountedFrom]: {
              vendorDisc: rowInfo.vendDiscountedFrom,
              venUnitCost: Number(rowInfo.vendUnitCost),
              venDiscPercent: Number(Number(rowInfo.vendPercentDiscount === undefined ? 0 : rowInfo.vendPercentDiscount) * 100 || 0).toFixed(2),
              venBillChrg: Number(Number(Number(rowInfo.vendCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2),
              custDisc: rowInfo.custDiscountedFrom,
              custUnitCost: rowInfo.custUnitCost,
              custDiscPercent: Number(Number(rowInfo.custPercentDiscount === undefined ? 0 : rowInfo.custPercentDiscount) * 100 || 0).toFixed(2),
              custBillChrg: isNaN(Number(Number(Number(rowInfo.custCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2)) ?
                0 : Number(Number(Number(rowInfo.custCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2)
            },
          });
        } else {
          setFormCustomizableFields({
            [rowInfo.vendDiscountedFrom]: {
              vendorDisc: rowInfo.vendDiscountedFrom,
              venUnitCost: rowInfo.vendUnitCost,
              venDiscPercent: Number(Number(rowInfo.vendPercentDiscount === undefined ? 0 : rowInfo.vendPercentDiscount) * 100 || 0).toFixed(2),
              venBillChrg: Number(Number(Number(rowInfo.vendCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2),
            },
            [rowInfo.custDiscountedFrom]: {
              custDisc: rowInfo.custDiscountedFrom,
              custUnitCost: rowInfo.custUnitCost,
              custDiscPercent: Number(Number(rowInfo.custPercentDiscount === undefined ? 0 : rowInfo.custPercentDiscount) * 100 || 0).toFixed(2),
              custBillChrg: isNaN(Number(Number(Number(rowInfo.custCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2)) ?
                0 : Number(Number(Number(rowInfo.custCost) * Number(rowInfo.quantity)).toFixed(2) / Number(rowInfo.quantity)).toFixed(2),
            },
          });
        }
      } else {
        const params = {
          functionName: 'getDefaultCostSavingsPercent',
          userId: userId,
          serviceType: module,
        };
        getDefaultCostSavingsPercent(params);
        setFormHCPCFields({ ...formHCPCFields });
      }
      setOpenHCPCServiceDetail(true);
    } else {
      setOpenHCPCServiceDetail(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(isHCPCCodeDesc)) {
      const detailParams = {
        functionName: "getDetailLineFee",
        userId: userId,
        vendorId: vendorId || 0,
        clientId: workListData?.clientId || patientInfo?.clientPatientId || 0,
        serviceTypeId: patientInfo.serviceTypeId || 0,
        hcpc: formHCPCFields.hcpcCode,
        fs: formHCPCFields.fs || 0.00,
        mca: formHCPCFields.mca || 0.00,
        uc: formHCPCFields.uc || 0.00,
        owcp: formHCPCFields.owcp || 0.00,
        zipCodeId: patientInfo.zipPatient,
        state: patientInfo.statePatient,
        dos: dateFormat(patientInfo.audZip),
        serviceId: serviceId || 0,
        serviceType: patientInfo.module,
      };
      getDetailLineFee(detailParams);
      const feeParams = {
        functionName: 'clientFeeLookup',
        userId: userId,
        clientId: workListData?.clientId || patientInfo?.clientPatientId || 0,
        serviceTypeId: patientInfo.serviceTypeId || 0,
        serviceId: serviceId || 0,
        hcpc: formHCPCFields.hcpcCode,
        fs: formHCPCFields.fs || 0.00,
        mca: formHCPCFields.mca || 0.00,
        uc: formHCPCFields.uc || 0.00,
        owcp: formHCPCFields.owcp || 0.00,
        zipCodeId: patientInfo.zipPatient,
        state: patientInfo.statePatient,
      };
      clientFeeLookup(feeParams);
    }
  }, [isHCPCCodeDesc]);

  const handleHCPCFields = (event, id, actionType) => {
    if (actionType && actionType.action === 'clear') {
      switch (id) {
        case 'hcpcCode':
          resetLoadHCPCTable();
          break;
      }
      resetHCPCClearFormData();
      // setFormHCPCFields({
      //   ...formHCPCFields,
      //   hcpcCode: '',
      //   hcpcDesc: '',
      // });
      setIsHCPCCodeDesc({});
    } else {
      const selectedHCPC =
        hcpcList && hcpcList.length > 0 &&
        hcpcList.find(obj => obj.int_id === event.int_id);
      if (selectedHCPC) {
        if ((selectedHCPC.chr_code === 'a9999' || selectedHCPC.chr_code === 'A9999') &&
          !(patientInfo.statePatient === 'KY' || patientInfo.statePatient === 'FL' || patientInfo.statePatient === 'CA')) {
          setShowCheckHcpcCodeModal(true);
        } else if ((selectedHCPC.chr_code === 'e1399' || selectedHCPC.chr_code === 'E1399') &&
          (patientInfo.statePatient === 'KY' || patientInfo.statePatient === 'FL' || patientInfo.statePatient === 'CA')) {
          setShowCheckHcpcCodeModal(true);
        }
        setFormHCPCFields({
          ...formHCPCFields,
          hcpcCode: selectedHCPC.chr_code,
          hcpcDesc: (selectedHCPC.chr_code === 'a9999' || selectedHCPC.chr_code === 'A9999' ||
            selectedHCPC.chr_code === 'e1399' || selectedHCPC.chr_code === 'E1399') ? '' : selectedHCPC.chr_description,
          fs: selectedHCPC.dbl_fee,
          uc: selectedHCPC.dbl_fee80,
          mca: selectedHCPC.mca,
          owcp: selectedHCPC.owcp,
          // vendorDisc: 'UC',
          venFlatRate: 0,
          clientFlatRate: 0,
        });
        setDefaultVendCustomFields(selectedHCPC);
        setIsHCPCCodeDesc(selectedHCPC);
      }
      resetLoadHCPCTable();
    }
  };

  const dateFormat = data => {
    if (data) {
      let dos = data && data.split('/');
      return dos[2] + '-' + dos[0] + '-' + dos[1];
    }
    return;
  };

  const dateRowInfoFormat = data => {
    if (data) {
      let dos = data && data.split('-');
      return dos[1] + '/' + dos[2] + '/' + dos[0];
    }
    return;
  };

  const setDefaultVendCustomFields = (obj) => {
    if (!isEmpty(obj)) {
      setFormCustomizableFields({
        FS: {
          vendorDisc: 'FS',
          venUnitCost: obj.dbl_fee,
          venDiscPercent: 0,
          venBillChrg: obj.dbl_fee,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        UC: {
          vendorDisc: 'UC',
          venUnitCost: obj.dbl_fee80,
          venDiscPercent: 0,
          venBillChrg: obj.dbl_fee80,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        MCA: {
          vendorDisc: 'MCA',
          venUnitCost: obj.mca,
          venDiscPercent: 0,
          venBillChrg: obj.mca,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        OWCP: {
          vendorDisc: 'OWCP',
          venUnitCost: obj.owcp,
          venDiscPercent: 0,
          venBillChrg: obj.owcp,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        MSRP: {
          vendorDisc: 'MSRP',
          venUnitCost: formHCPCFields.msrp,
          venDiscPercent: 0,
          venBillChrg: formHCPCFields.msrp,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        FLAT: {
          vendorDisc: 'FLAT',
          venUnitCost: 0,
          venDiscPercent: 0,
          venBillChrg: "0.00",
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        }
      })
    } else if (rowInfo) {
      setFormCustomizableFields({
        FS: {
          vendorDisc: 'FS',
          venUnitCost: rowInfo.feeSchedule === undefined ? '' : rowInfo.feeSchedule,
          venDiscPercent: 0,
          venBillChrg: rowInfo.feeSchedule,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        UC: {
          vendorDisc: 'UC',
          venUnitCost: rowInfo.ucSchedule === undefined ? '' : rowInfo.ucSchedule,
          venDiscPercent: 0,
          venBillChrg: rowInfo.uc,
          custDisc: rowInfo.ucSchedule,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        MCA: {
          vendorDisc: 'MCA',
          venUnitCost: rowInfo.medicareAllowable === undefined ? '' : rowInfo.medicareAllowable,
          venDiscPercent: 0,
          venBillChrg: rowInfo.medicareAllowable,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        OWCP: {
          vendorDisc: 'OWCP',
          venUnitCost: rowInfo.owcpRate === undefined ? '' : rowInfo.owcpRate,
          venDiscPercent: 0,
          venBillChrg: rowInfo.owcpRate,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        MSRP: {
          vendorDisc: 'MSRP',
          venUnitCost: rowInfo.msrp === undefined ? '' : rowInfo.msrp,
          venDiscPercent: 0,
          venBillChrg: rowInfo.msrp === undefined ? '' : rowInfo.msrp,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        },
        FLAT: {
          vendorDisc: 'FLAT',
          venUnitCost: rowInfo.flat === undefined ? '' : rowInfo.flat,
          venDiscPercent: 0,
          venBillChrg: rowInfo.flat === undefined ? '' : rowInfo.flat,
          custDisc: formHCPCFields.custDisc,
          custUnitCost: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custUnitCost,
          custDiscPercent: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent,
          custBillChrg: formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        }
      })
    }
  }

  const setDefaultCustCustomFields = (obj) => {
    if (!isEmpty(obj)) {
      setFormCustomizableFields({
        FS: {
          custDisc: 'FS',
          custUnitCost: obj.dbl_fee,
          custDiscPercent: 0,
          custBillChrg: obj.dbl_fee,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        UC: {
          custDisc: 'UC',
          custUnitCost: obj.dbl_fee80,
          custDiscPercent: 0,
          custBillChrg: obj.dbl_fee80,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MCA: {
          custDisc: 'MCA',
          custUnitCost: obj.mca,
          custDiscPercent: 0,
          custBillChrg: obj.mca,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        OWCP: {
          custDisc: 'OWCP',
          custUnitCost: obj.owcp,
          custDiscPercent: 0,
          custBillChrg: obj.owcp,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MSRP: {
          custDisc: 'MSRP',
          custUnitCost: formHCPCFields.msrp,
          custDiscPercent: 0,
          custBillChrg: formHCPCFields.msrp,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        FLAT: {
          custDisc: 'FLAT',
          custUnitCost: 0,
          custDiscPercent: 0,
          custBillChrg: "0.00",
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        }
      })
    } else if (rowInfo) {
      setFormCustomizableFields({
        FS: {
          custDisc: 'FS',
          custUnitCost: rowInfo.feeSchedule === undefined ? '' : rowInfo.feeSchedule,
          custDiscPercent: 0,
          custBillChrg: rowInfo.feeSchedule,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        UC: {
          custDisc: 'UC',
          custUnitCost: rowInfo.ucSchedule === undefined ? '' : rowInfo.ucSchedule,
          custDiscPercent: 0,
          custBillChrg: rowInfo.ucSchedule,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MCA: {
          custDisc: 'MCA',
          custUnitCost: rowInfo.medicareAllowable === undefined ? '' : rowInfo.medicareAllowable,
          custDiscPercent: 0,
          custBillChrg: rowInfo.medicareAllowable,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        OWCP: {
          custDisc: 'OWCP',
          custUnitCost: rowInfo.owcpRate === undefined ? '' : rowInfo.owcpRate,
          custDiscPercent: 0,
          custBillChrg: rowInfo.owcpRate,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        MSRP: {
          custDisc: 'MSRP',
          custUnitCost: rowInfo.msrp,
          custDiscPercent: 0,
          custBillChrg: rowInfo.msrp,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        },
        FLAT: {
          custDisc: 'FLAT',
          custUnitCost: rowInfo.flat,
          custDiscPercent: 0,
          custBillChrg: rowInfo.flat,
          vendorDisc: formHCPCFields.vendorDisc,
          venUnitCost: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost,
          venDiscPercent: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent,
          venBillChrg: formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg,
        }
      })
    }
  }

  const handleTypeAheadSearch = (event, type) => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      let params = {
        functionName: 'loadHCPC',
        userId: userId,
        code: event.target.value,
        date: patientInfo.audZip,
        zipcode: patientInfo.zipPatient,
        mod1: formHCPCFields.mod1,
        mod2: formHCPCFields.mod2
      };
      loadHCPC(params);
      checkCode = event.target.value;
    } else if (event.target.value.length > 0 && hcpcList && hcpcList.length) {
      resetLoadHCPCTable();
    }
  };

  const setCalValue = (id, value) => {
    switch (value) {
      case 'FS':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.fs,
          totValue: formHCPCFields.fs,
        });
        break;
      case 'UC':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.uc,
          totValue: formHCPCFields.uc,
        });
        break;
      case 'MCA':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formHCPCFields.mca,
          totValue: formHCPCFields.mca,
        });
        break;
      case 'V.Billing Charges':
        setFormHCPCFields({
          ...formHCPCFields,
          [id]: value,
          calOption: formCustomizableFields[formHCPCFields.vendorDisc]
            ? formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            : '',
          totValue: formCustomizableFields[formHCPCFields.vendorDisc]
            ? formCustomizableFields[formHCPCFields.vendorDisc].venBillChrg
            : '',
        });
        break;
      default:
        return '';
    }
  };

  const onChangeHandlerPrimary = (id, value) => {
    setFormHCPCFields({ ...formHCPCFields, [id]: value });
    if (id === 'vendorDisc') {
      setDefaultVendCustomFields(isHCPCCodeDesc);
    }
    if (id === 'custDisc') {
      setDefaultCustCustomFields(isHCPCCodeDesc);
      if (!isEmpty(isHCPCCodeDesc)) {
        if (value === 'UC') {
          calcBillCharge(formHCPCFields.uc, isHCPCCodeDesc.dbl_fee80, isHCPCCodeDesc.dbl_fee80, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'FS') {
          calcBillCharge(formHCPCFields.fs, isHCPCCodeDesc.dbl_fee, isHCPCCodeDesc.dbl_fee, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'OWCP') {
          calcBillCharge(formHCPCFields.owcp, isHCPCCodeDesc.owcp, isHCPCCodeDesc.owcp, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'MCA') {
          calcBillCharge(formHCPCFields.mca, isHCPCCodeDesc.mca, isHCPCCodeDesc.mca, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'MSRP') {
          calcBillCharge(formHCPCFields.msrp, formHCPCFields.msrp, formHCPCFields.msrp, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'FLAT') {
          calcBillCharge(formHCPCFields.clientFlatRate, formHCPCFields.clientFlatRate, formHCPCFields.clientFlatRate, value, 0, formHCPCFields.costPercentSavings);
        } else {
          return ''
        }
      } else if (rowInfo) {
        if (value === 'UC') {
          calcBillCharge(
            formHCPCFields.uc,
            formHCPCFields.uc === undefined ? '' : formHCPCFields.uc,
            formHCPCFields.uc === undefined ? '' : formHCPCFields.uc,
            value,
            0,
            formHCPCFields.costPercentSavings);
        } else if (value === 'FS') {
          calcBillCharge(
            formHCPCFields.fs,
            formHCPCFields.fs === undefined ? '' : formHCPCFields.fs,
            formHCPCFields.fs === undefined ? '' : formHCPCFields.fs,
            value,
            0,
            formHCPCFields.costPercentSavings);
        } else if (value === 'OWCP') {
          calcBillCharge(
            formHCPCFields.owcp,
            formHCPCFields.owcp === undefined ? '' : formHCPCFields.owcp,
            formHCPCFields.owcp === undefined ? '' : formHCPCFields.owcp,
            value,
            0,
            formHCPCFields.costPercentSavings);
        } else if (value === 'MCA') {
          calcBillCharge(
            formHCPCFields.mca,
            formHCPCFields.mca === undefined ? '' : formHCPCFields.mca,
            formHCPCFields.mca === undefined ? '' : formHCPCFields.mca,
            value,
            0,
            formHCPCFields.costPercentSavings);
        } else if (value === 'MSRP') {
          calcBillCharge(formHCPCFields.msrp, formHCPCFields.msrp === undefined ? '' : formHCPCFields.msrp, formHCPCFields.msrp === undefined ? '' : formHCPCFields.msrp, value, 0, formHCPCFields.costPercentSavings);
        } else if (value === 'FLAT') {
          calcBillCharge(formHCPCFields.clientFlatRate, formHCPCFields.clientFlatRate, formHCPCFields.clientFlatRate, value, 0, formHCPCFields.costPercentSavings);
        } else {
          return ''
        }
      }
    }
    if (id === 'calcValue') {
      setFormHCPCFields({ ...formHCPCFields, [id]: value });
      setCalValue(id, value);
    }
    if (id === 'calc') {
      const val =
        (Number(formHCPCFields.calOption) / 100) *
        Number(formHCPCFields.calcData);
      const finalVal =
        value === '+'
          ? Number(formHCPCFields.calOption) + val
          : Number(formHCPCFields.calOption) - val;
      setFormHCPCFields({ ...formHCPCFields, [id]: value, totValue: finalVal.toFixed(2) });
    }
    if (id === 'mod2') {
      setFormHCPCFields({ ...formHCPCFields, [id]: value });
      setCostPercentValue(id, value);
    }
  };
  const getUnitBillValue = () => {
    let billVal = 0;
    if (formHCPCFields.custDisc === 'UC') {
      billVal = formHCPCFields.uc;
    } else if (formHCPCFields.custDisc === 'FS') {
      billVal = formHCPCFields.fs;
    } else if (formHCPCFields.custDisc === 'OWCP') {
      billVal = formHCPCFields.owcp;
    } else if (formHCPCFields.custDisc === 'MCA') {
      billVal = formHCPCFields.mca;
    } else if (formHCPCFields.custDisc === 'MSRP') {
      billVal = formHCPCFields.msrp;
    } else if (formHCPCFields.custDisc === 'FLAT') {
      billVal = formHCPCFields.flat;
    }
    if ((Number(formHCPCFields.fs) > 0)  && (Number(formHCPCFields.uc) > 0) &&  (Number(formHCPCFields.fs) > Number(formHCPCFields.uc)) ){
      billVal = formHCPCFields.fs;
    } else if (formHCPCFields.uc && Number(formHCPCFields.uc) > 0 ){
      billVal = formHCPCFields.uc;
    }
    return billVal;
  }

  useEffect(() => {
    if (formHCPCFields.mod2 || formHCPCFields.costPercentSavings || formHCPCFields.qty || formHCPCFields.duration) {
      let billVal = getUnitBillValue();
      calcBillCharge(
        billVal,
        Number(
          formCustomizableFields[formHCPCFields.custDisc] &&
          formCustomizableFields[formHCPCFields.custDisc].custUnitCost
        ),
        Number(
          formCustomizableFields[formHCPCFields.custDisc] &&
          formCustomizableFields[formHCPCFields.custDisc].custBillChrg
        ),
        formHCPCFields.custDisc,
        Number(
          formCustomizableFields[formHCPCFields.custDisc] &&
          formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
        ),
        formHCPCFields.costPercentSavings
      );
      // setFormHCPCFields({ ...formHCPCFields });
    }
  }, [formHCPCFields.mod2, formHCPCFields.costPercentSavings, formHCPCFields.qty || formHCPCFields.duration]);

  const setCostPercentValue = (id, value) => {
    const costPercent =
      value === 'RX' || value === 'RP' || value === 'SI' || value === 'VG/PGO'
        ? '10'
        : '25';
    setFormHCPCFields({
      ...formHCPCFields,
      [id]: value,
      costPercentSavings: costPercent,
    });
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'rentExt') {
      formHCPCFields.rentExt =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    setFormHCPCFields({ ...formHCPCFields, [name]: value });
    if (name === 'calcData') {
      const val = (Number(formHCPCFields.calOption) / 100) * Number(value);
      const finalVal =
        formHCPCFields.calc === '+'
          ? Number(formHCPCFields.calOption) + val
          : Number(formHCPCFields.calOption) - val;
      setFormHCPCFields({
        ...formHCPCFields,
        [name]: value,
        totValue: finalVal.toFixed(2),
      });
    }
  };

  const onChangeHandlerRentalUnit = (id, value) => {
    setFormHCPCFields({ ...formHCPCFields, [id]: value });
    if (id === 'rentUnit') {
      daysBetween(value);
    }
  };

  const handleVendChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'venDiscPercent') {
      const val = Number(formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost) / 100 * Number(value);
      const finalVal = Number(formCustomizableFields[formHCPCFields.vendorDisc].venUnitCost) - val;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          venDiscPercent: value,
          venBillChrg: finalVal.toFixed(2),
        },
      });
    } else if (name === 'venUnitCost') {
      const venVal = Number(value) / 100 * Number(formCustomizableFields[formHCPCFields.vendorDisc] && formCustomizableFields[formHCPCFields.vendorDisc].venDiscPercent);
      const venBill = Number(value) - venVal;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          [name]: value,
          venBillChrg: venBill.toFixed(2),
        },
      });
    } else {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.vendorDisc]: {
          ...formCustomizableFields[formHCPCFields.vendorDisc],
          [name]: value,
        },
      });
    }
  };

  const handleCustChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let billVal = getUnitBillValue();
    if (name === 'custDiscPercent') {
      const custVal = Number(formCustomizableFields[formHCPCFields.custDisc].custUnitCost) / 100 * Number(value);
      const custFinalVal = Number(formCustomizableFields[formHCPCFields.custDisc].custUnitCost) - custVal;
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          custDiscPercent: value,
          custBillChrg: custFinalVal.toFixed(2),
        },
      });
      calcBillCharge(
        billVal,
        Number(
          formCustomizableFields[formHCPCFields.custDisc] &&
          formCustomizableFields[formHCPCFields.custDisc].custUnitCost
        ),
        custFinalVal.toFixed(2),
        formHCPCFields.custDisc,
        value,
        formHCPCFields.costPercentSavings
      );
      // if (formHCPCFields.custDisc === 'UC') {
      //   calcBillCharge(
      //     formHCPCFields.uc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'FS') {
      //   calcBillCharge(
      //     formHCPCFields.fs,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'OWCP') {
      //   calcBillCharge(
      //     formHCPCFields.owcp,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'MCA') {
      //   calcBillCharge(
      //     formHCPCFields.mca,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'MSRP') {
      //   calcBillCharge(
      //     formHCPCFields.msrp,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'FLAT') {
      //   calcBillCharge(
      //     formHCPCFields.flat,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custUnitCost
      //     ),
      //     custFinalVal.toFixed(2),
      //     formHCPCFields.custDisc,
      //     value,
      //     formHCPCFields.costPercentSavings
      //   );
      // } else {
      //   return '';
      // }
    } else if (name === 'custUnitCost') {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          [name]: value,
        },
      });
      const cusVal = Number(value) / 100 * Number(formCustomizableFields[formHCPCFields.custDisc] && formCustomizableFields[formHCPCFields.custDisc].custDiscPercent);
      const custBill = Number(value) - cusVal;
      
      calcBillCharge(
        billVal,
        value,
        custBill,
        formHCPCFields.custDisc,
        Number(
          formCustomizableFields[formHCPCFields.custDisc] &&
          formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
        ),
        formHCPCFields.costPercentSavings
      );
      // if (formHCPCFields.custDisc === 'UC') {
      //   calcBillCharge(
      //     formHCPCFields.uc,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'FS') {
      //   calcBillCharge(
      //     formHCPCFields.fs,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'OWCP') {
      //   calcBillCharge(
      //     formHCPCFields.owcp,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'MCA') {
      //   calcBillCharge(
      //     formHCPCFields.mca,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'MSRP') {
      //   calcBillCharge(
      //     formHCPCFields.msrp,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else if (formHCPCFields.custDisc === 'FLAT') {
      //   calcBillCharge(
      //     formHCPCFields.flat,
      //     value,
      //     custBill,
      //     formHCPCFields.custDisc,
      //     Number(
      //       formCustomizableFields[formHCPCFields.custDisc] &&
      //       formCustomizableFields[formHCPCFields.custDisc].custDiscPercent
      //     ),
      //     formHCPCFields.costPercentSavings
      //   );
      // } else {
      //   return '';
      // }
    } else {
      setFormCustomizableFields({
        ...formCustomizableFields,
        [formHCPCFields.custDisc]: {
          ...formCustomizableFields[formHCPCFields.custDisc],
          [name]: value,
        },
      });
    }
  };

  const prepareDropDownOpt = obj => {
    let formattedOption =
      obj.chr_code +
      '-' +
      obj.chr_description +
      '  ' +
      ' Fee schedule:' +
      obj.dbl_fee +
      '  ' +
      ' Medicare:' +
      obj.mca +
      '  ' +
      ' U/C:' +
      obj.dbl_fee80 +
      '  ' +
      ' OWCP:' +
      obj.owcp;
    // delete obj.chr_code;
    // formattedOption = formattedOption + Object.keys(obj).map(item => {
    //  return obj[item]
    // }).join(' ');
    return formattedOption;
  };

  const calcBillCharge = (checkVal, calVal, calBill, disc, percent, costPercent) => {
    let billVal;
    let costPer;
    //console.log("CheckVal: " + checkVal + " calVal: " + calVal + " calBill: " + calBill + " disc: " + disc + " percent: " + percent + " costPercent: " + costPercent);
    if (checkVal === 0 || checkVal === "" || checkVal === undefined || checkVal === '0.00' || checkVal === 0.00) {
      billVal = Number((Number(calBill) / ((100 - Number(costPercent)) / 100)) * Number(formHCPCFields.qty)).toFixed(2);
      costPer = costPercent;
      formHCPCFields.finalBilledCharge = billVal;
      // formHCPCFields.finalCostSavingPercent = costPer
    } else {
      if (disc === 'MSRP') {
        billVal = (Number(formHCPCFields.msrp) * Number(formHCPCFields.qty)).toFixed(2);
        costPer = percent;
        formHCPCFields.finalBilledCharge = billVal;
      }else if ((Number(formHCPCFields.fs) > 0)  && (Number(formHCPCFields.uc) > 0) &&  (Number(formHCPCFields.fs) > Number(formHCPCFields.uc)) ){
        billVal = (Number(formHCPCFields.fs) * Number(formHCPCFields.qty)).toFixed(2);
        costPer = percent;
        formHCPCFields.finalBilledCharge = billVal;
        // formHCPCFields.finalCostSavingPercent = costPer;
      }else if ((disc != 'UC') && (formHCPCFields.uc === undefined || Number(formHCPCFields.uc) === 0 ) ){
        billVal = (Number((Number(calBill) / ((100 - Number(costPercent)) / 100)) * Number(formHCPCFields.qty))).toFixed(2);
        costPer = percent;
        formHCPCFields.finalBilledCharge = billVal;
        // formHCPCFields.finalCostSavingPercent = costPer;
      }else if ((disc != 'UC') && (Number(formHCPCFields.uc) > 0) ){
        billVal = (Number(formHCPCFields.uc) * Number(formHCPCFields.qty)).toFixed(2) ;
        costPer = percent;
        formHCPCFields.finalBilledCharge = billVal;
        // formHCPCFields.finalCostSavingPercent = costPer;
      }else{
        billVal = Number((Number(calVal) * Number(formHCPCFields.qty))).toFixed(2);
       // billVal = Number((Number(calVal) * Number(formHCPCFields.qty))).toFixed(2);
        costPer = percent;
        formHCPCFields.finalBilledCharge = billVal;
        // formHCPCFields.finalCostSavingPercent = costPer; 
        // formCustomizableFields[disc].custBillChrg
        // formHCPCFields.qty
      }
     
    }
    costPer = Number(
      ((Number(billVal) - Number(calBill)) /
        Number(formHCPCFields.finalBilledCharge)) *
        100
    ).toFixed(0);
    setFormHCPCFields({ ...formHCPCFields, custDisc: disc, finalBilledCharge: billVal, finalCostSavingPercent: costPer })
    setFormCustomizableFields({
      ...formCustomizableFields,
      [disc]: {
        ...formCustomizableFields[disc],
        custUnitCost: isNaN(calVal) ? 0 : calVal,
        custBillChrg: isNaN(calBill) ? 0 : calBill,
        custDiscPercent:isNaN(percent) ? 0 : percent
      },
    });
  }

  const onChangeHandler = object => {
    if (object.key === 'startDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.startDate = mon + '/' +
        object.value.getDate() + '/' + object.value.getFullYear();
    }
    if (object.key === 'endDate') {
      let mon = object.value.getMonth() + 1;
      formHCPCFields.endDate =
        mon + '/' +
        object.value.getDate() + '/' + object.value.getFullYear();
    }
    if (formHCPCFields.startDate && formHCPCFields.endDate) {
      daysBetween(formHCPCFields.rentUnit);
    }
    setFormHCPCFields({ ...formHCPCFields });
  };

  const calDuration = (diff, val) => {
    formHCPCFields.qty = diff;
    setFormHCPCFields({ ...formHCPCFields, qty: diff, rentUnit: val });
  };

  const daysBetween = value => {
    const firstDate = moment(formHCPCFields.startDate);
    const secondDate = moment(formHCPCFields.endDate);
    if (value === 'Day') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      formHCPCFields.duration = `${diffDays} Days`;
      if (diffDays >= 0) {
        calDuration(diffDays, value);
      }
      return `${diffDays} Days`;
    } else if (value === 'Week') {
      let diffWeeks;
      diffWeeks = Math.round(
        Math.abs((firstDate - secondDate) / (7 * 24 * 60 * 60 * 1000))
      );
      formHCPCFields.duration = `${diffWeeks} Weeks`;
      if (diffWeeks >= 0) {
        calDuration(diffWeeks, value);
      }
      return `${diffWeeks} Weeks`;
    } else if (value === 'Month') {
      let diffDays;
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      
      let numberOfMonths;
      numberOfMonths = Math.round(diffDays / 30);
      formHCPCFields.duration = `${numberOfMonths} Months`;
      if (numberOfMonths >= 0) {
        calDuration(numberOfMonths, value);
      }
      return `${numberOfMonths} Months`;
    }
    setFormHCPCFields({
      ...formHCPCFields,
      duration: formHCPCFields.duration,
      rentUnit: value,
    });
    return '';
  };

  const renderRentForm = () => {
    if (formHCPCFields.mod1 !== undefined) {
      if (formHCPCFields.mod1 === 'Rental') {
        return (
          <>
            <Row style={{ marginTop: 29 }}>
              <Col sm="12" md={{ size: 3, offset: 5 }}>
                <Label>
                  <b>Rental Info</b>
                </Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Unit:</Label>
              </Col>
              <Col sm="2">
                <AppInput
                  id="rentUnit"
                  type="select"
                  changeHandler={onChangeHandlerRentalUnit}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.rentUnit || ''}
                  data={rentalUnit}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>Duration:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="duration"
                  onChange={daysBetween}
                  value={formHCPCFields.duration}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Start Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="startDate"
                  onChange={value => onChangeHandler({ key: 'startDate', value })}
                  value={formHCPCFields.startDate || ''}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>End Date:</Label>
              </Col>
              <Col sm="2">
                <AppDateInput
                  id="endDate"
                  onChange={value => onChangeHandler({ key: 'endDate', value })}
                  value={formHCPCFields.endDate || ''}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Vendor Buyout:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="vendBuyout"
                  onChange={handleInputChange}
                  value={formHCPCFields.vendBuyout || ''}
                />
              </Col>
              <Col className="text-right" sm="3">
                <Label>Customer Buyout:</Label>
              </Col>
              <Col sm="2">
                <Input
                  name="custBuyout"
                  onChange={handleInputChange}
                  value={formHCPCFields.custBuyout || ''}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col className="text-right" sm="3">
                <Label>Rental Ext:</Label>
              </Col>
              <Col sm="2">
                <Input
                  style={{ marginLeft: 1 }}
                  type="checkbox"
                  name="rentExt"
                  onChange={handleInputChange}
                  checked={formHCPCFields.rentExt}
                />
              </Col>
            </Row>
          </>
        );
      }
    } else {
      return '';
    }
  };

  const showHCPCSearchModalHandler = () => {
    setShowHCPCSearchModal(!showHCPCSearchModal);
    // resetLoadHCPCWithLimit();
    // setSearchField({hcpcSearch: 'Description',
    // searchText: ''});
  };

  const onLookupHandler = () => {
    setLookupModal(!lookupModal);
  }

  const checkHandler = () => {
    const checkParams = {
      functionName: 'hcpcTermCheck',
      zebraUserId: userId,
      code: formHCPCFields.hcpcCode && formHCPCFields.hcpcCode !== '' ? formHCPCFields.hcpcCode : checkCode,
      state: patientInfo.statePatient
    };
    hcpcTermCheck(checkParams);
    formHCPCFields.hcpcCode = formHCPCFields.hcpcCode && formHCPCFields.hcpcCode !== '' ? formHCPCFields.hcpcCode : checkCode;
    onToggle();
  }

  const onToggle = () => {
    setCheckCodeModal(!checkCodeModal);
  }

  const getCheckContent = () => {
    return (
      <Form>
        <Row>
          <Col>
            <span>
              {hcpcCheck}
              <br />
              <br />
              <Button
                outline
                color="primary"
                style={{ marginLeft: '200px', marginRight: '200px' }}
                onClick={onToggle}>
                Ok{' '}
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={openHCPCServiceDetail}>
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12" md={{ size: 3, offset: 5 }}>
              <Label>
                <b>Order Details</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="1" md={{ offset: 1 }}>
              <Row>
                <Label>Qty</Label>
                <Input
                  name="qty"
                  type="number"
                  min={0}
                  onChange={handleInputChange}
                  value={formHCPCFields.qty}
                />
              </Row>
            </Col>
            <Col sm="3" md={{ offset: 1 }}>
              <Row>
                <Label>Primary: </Label>
                <AppInput
                  id="mod1"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.mod1 || ''}
                  data={primaryData}
                />
              </Row>
            </Col>
            <Col sm="3" md={{ offset: 1 }}>
              <Row>
                <Label>Secondary: </Label>
                <AppInput
                  id="mod2"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.mod2 || ''}
                  data={secondaryData}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col sm="2">
              <Button style={{ marginTop: 29 }} onClick={onLookupHandler}>
                Lookup
              </Button>
              <Button
                style={{ marginTop: 29, marginLeft: 5 }}
                disabled={!hcpcList || (hcpcList && hcpcList.length > 0)}
                onClick={checkHandler}
                title="Why can't I find this HCPC?"
              >
                ?
              </Button>
            </Col>
            <Col sm="4">
              <Label for="hcpcCode">HCPC: </Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleHCPCFields(e, 'hcpcCode', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  formHCPCFields.hcpcCode
                    ? [
                      {
                        value: formHCPCFields.hcpcCode,
                        label: formHCPCFields.hcpcCode,
                      },
                    ]
                    : []
                }
                menuIsOpen={hcpcList && hcpcList.length}
                closeMenuOnSelect={true}
                options={
                  hcpcList && hcpcList.length
                    ? hcpcList.map(obj =>
                      assign(obj, {
                        label: prepareDropDownOpt(obj),
                        value: obj.chr_code,
                      })
                    )
                    : []
                }
                name="hcpcCode"
                id="hcpcCode"
                isDisabled={R.isEmpty(formHCPCFields.mod1)}
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
            <Col sm="6">
              <Row>
                <Label>Description: </Label>
                <Input
                  name="hcpcDesc"
                  onChange={handleInputChange}
                  value={formHCPCFields.hcpcDesc || ''}
                  disabled={!(formHCPCFields.hcpcCode === 'E1399' ||
                    formHCPCFields.hcpcCode === 'A9999' ||
                    formHCPCFields.hcpcCode === 'e1399' ||
                    formHCPCFields.hcpcCode === 'a9999'
                  )}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col sm="2">
              <Button
                style={{ marginTop: 27 }}
                disabled={!formHCPCFields.mod1}
                onClick={showHCPCSearchModalHandler}
              >
                HCPC Search
              </Button>
            </Col>
            <Col xs="2" style={{ marginLeft: "-13px" }}>
              <Row>
                <Label>Vendor flat rate</Label>
                <Input
                  name="venFlatRate"
                  onChange={handleInputChange}
                  value={formHCPCFields.venFlatRate || ''}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="2">
              <Row>
                <Label>Client flat rate</Label>
                <Input
                  name="clientFlatRate"
                  onChange={handleInputChange}
                  value={formHCPCFields.clientFlatRate || ''}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>FS</Label>
                <Input
                  name="fs"
                  onChange={handleInputChange}
                  value={formHCPCFields.fs}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>U&C</Label>
                <Input
                  name="uc"
                  onChange={handleInputChange}
                  value={formHCPCFields.uc}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>MCA</Label>
                <Input
                  name="mca"
                  onChange={handleInputChange}
                  value={formHCPCFields.mca}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="2">
              <Row>
                <Label>OWCP</Label>
                <Input
                  name="owcp"
                  onChange={handleInputChange}
                  value={formHCPCFields.owcp}
                  disabled
                />
              </Row>
            </Col>
            <Col xs="1">
              <Row>
                <Label>MSRP</Label>
                <Input
                  name="msrp"
                  onChange={handleInputChange}
                  value={formHCPCFields.msrp || ''}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col sm="4" md={{ offset: 3 }}>
              <Label>Revenue Code:</Label>
            </Col>
            <Col sm="6" style={{ marginLeft: 5 }}>
              <AppInput
                id="revenueCode"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'description',
                }}
                placeholder="Choose Rev Code.."
                value={formHCPCFields.revenueCode || ''}
                data={BRTypes}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Discount From:</Label>
            </Col>
            <Col sm="2">
              <AppInput
                id="vendorDisc"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.vendorDisc || ''}
                data={discountData}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Discount From:</Label>
            </Col>
            <Col sm="2">
              <AppInput
                id="custDisc"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.custDisc || ''}
                data={discountData}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Unit Cost:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venUnitCost"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                      .venUnitCost
                    : ''
                }
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Unit Cost:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custUnitCost"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                      .custUnitCost
                    : ''
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Disc Percent:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venDiscPercent"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                      .venDiscPercent
                    : ''
                }
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Disc Percent:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custDiscPercent"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                      .custDiscPercent
                    : ''
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col className="text-right" sm="3">
              <Label>Vendor Billed Charges:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="venBillChrg"
                onChange={handleVendChange}
                value={
                  formCustomizableFields[formHCPCFields.vendorDisc]
                    ? formCustomizableFields[formHCPCFields.vendorDisc]
                      .venBillChrg
                    : ''
                }
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Client Billed Charges:</Label>
            </Col>
            <Col sm="2">
              <Input
                name="custBillChrg"
                onChange={handleCustChange}
                value={
                  formCustomizableFields[formHCPCFields.custDisc]
                    ? formCustomizableFields[formHCPCFields.custDisc]
                      .custBillChrg
                    : ''
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col className="text-right" sm="3">
              <Label>Cost Savings %</Label>
            </Col>
            <Col sm="2">
              <Input
                name="costPercentSavings"
                disabled
                onChange={handleInputChange}
                value={formHCPCFields.costPercentSavings || ''}
              />
            </Col>
            <Col className="text-right" sm="3">
              <Label>Billed Charge:</Label>
              <span>${formHCPCFields.finalBilledCharge}</span>
            </Col>
            <Col sm="3">
              <Label>Cost Savings:</Label>
              <span>{formHCPCFields.finalCostSavingPercent}%</span>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col xs="2" style={{ marginTop: 29 }}>
              {/* <Input type="select" style={{ marginTop: 29 }} /> */}
              <AppInput
                id="calcValue"
                type="select"
                changeHandler={onChangeHandlerPrimary}
                option={{
                  value: 'value',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formHCPCFields.calcValue || ''}
                data={calcData}
              />
            </Col>
            <Col xs="2" style={{ marginTop: 9 }}>
              <Label></Label>
              <Input
                name="calOption"
                onChange={handleInputChange}
                value={formHCPCFields.calOption || ''}
              />
            </Col>
            <Col xs="2">
              <Row>
                <Label>Calculator</Label>
                <AppInput
                  id="calc"
                  type="select"
                  changeHandler={onChangeHandlerPrimary}
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose..."
                  value={formHCPCFields.calc || ''}
                  data={calculator}
                />
              </Row>
            </Col>
            <Col xs="2" style={{ marginTop: 29 }}>
              <Input
                name="calcData"
                onChange={handleInputChange}
                value={formHCPCFields.calcData}
              />
            </Col>
            <Col xs="2" style={{ marginTop: 29 }}>
              <Input
                name="totValue"
                onChange={handleInputChange}
                value={formHCPCFields.totValue}
                disabled
              />
            </Col>
          </Row>
          {renderRentForm()}
          <div style={{ float: 'right' }}>
            <Button type="button"  disabled={disableSave}  onClick={() => openHCPCService(true)}>
              Save
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              type="button"
              onClick={() => openHCPCService(false)}
            >
              Cancel
            </Button>
          </div>
          <HCPCSearchModal
            formHCPCFields={formHCPCFields}
            isOpen={showHCPCSearchModal}
            onClose={showHCPCSearchModalHandler}
          />
          <Fast360FeeLookupModal
            isOpen={lookupModal}
            isFrormService={true}
            onClose={onLookupHandler}
          />
          <PopupModal
            content={getCheckContent()}
            externalToggle={onToggle}
            title={''}
            isOpen={checkCodeModal}
            size="md"
          />
          <PopupModal
            content={getHcpcCodeCheckContent()}
            externalToggle={confirmHcpcCodeCheck}
            title={''}
            isOpen={showCheckHcpcCodeModal}
            size="md"
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    hcpcList: fast360Info.hcpcList,
    hcpcListLimit: fast360Info.hcpcListLimit,
    hcpcCheck: fast360Info.hcpcCheck,
    patientInfo: fast360Info.patientZip,
    userId: user.id,
    BRTypes: initialData.brTypes,
    defaultPercent: fast360Info.defaultPercent,
    audInfo: fast360Info.audInfo,
    detailLineFeeInfo: fast360Info.detailLineFeeInfo,
    clientFeeInfo: fast360Info.clientFeeInfo,
    updateAudHcpcList: fast360Info.updateAudHcpcList,
    updateCostSavingList: fast360Info.updateCostSavingList,
  }
}

const mapDispatchToProps = dispatch => ({
  loadHCPC: data => dispatch(fast360Operations.loadHCPC(data)),
  lookupHcpcWithLimit: data => dispatch(fast360Operations.lookupHcpcWithLimit(data)),
  hcpcTermCheck: data => dispatch(fast360Operations.hcpcTermCheck(data)),
  getDefaultCostSavingsPercent: data => dispatch(fast360Operations.getDefaultCostSavingsPercent(data)),
  saveMSEHCPC: (data, req) => dispatch(fast360Operations.saveMSEHCPC(data, req)),
  getDetailLineFee: data => dispatch(fast360Operations.getDetailLineFee(data)),
  loadAudiologyHcpcsList: data => dispatch(fast360Operations.loadAudiologyHcpcsList(data)),
  loadCostSavings: data => dispatch(fast360Operations.loadCostSavings(data)),
  clientFeeLookup: data => dispatch(fast360Operations.clientFeeLookup(data)),
  updateAudInfoTable: data => dispatch(fast360Actions.updateAudInfoTable(data)),
  resetLoadHCPCTable: () => dispatch(fast360Actions.resetLoadHCPCTable()),
  resetLoadHCPCWithLimit: () => dispatch(fast360Actions.resetLoadHCPCWithLimit()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  resetGetDetailLineFeeTable: () => dispatch(fast360Actions.resetGetDetailLineFeeTable()),
  resetClientFeeLookupTable: () => dispatch(fast360Actions.resetClientFeeLookupTable()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HCPCDetailModal);