export const shortToDatabaseTable = (serviceAbrv) => {
    if (serviceAbrv === "HHC") {
        return "hhc";
    } else if (serviceAbrv === "AUD") {
        return "audiology";
    } else if (serviceAbrv === "DTL") {
        return "dental";
    } else if (serviceAbrv === "DGN") {
        return "diagnostics";
    } else if (serviceAbrv === "MOD") {
        return "modifications";
    } else if (serviceAbrv === "MSE") {
        return "mse";
    } else if (serviceAbrv === "TRN") {
        return "transportation";
    } else if (serviceAbrv === "INT") {
        return "interpretation";
    } else if (serviceAbrv === "SPT") {
        return "special_transp";
    } else if (serviceAbrv === "ACC") {
        return "accommodation";
    }
    return "";
};

export const tableNameToShort = (tableName) => {
    if (tableName.toLowerCase() === "hhc") {
        return "HHC";
    } else if (tableName.toLowerCase() === "audiology") {
        return "AUD";
    } else if (tableName.toLowerCase() === "dental") {
        return "DTL";
    } else if (tableName.toLowerCase() === "diagnostics") {
        return "DGN";
    } else if (tableName.toLowerCase() === "modifications") {
        return "MOD";
    } else if (tableName.toLowerCase() === "mse") {
        return "MSE";
    } else if (tableName.toLowerCase() === "transportation") {
        return "TRN";
    } else if (tableName.toLowerCase() === "interpretation") {
        return "INT";
    } else if (tableName.toLowerCase() === "special_transp") {
        return "SPT";
    }
    return ""
};