import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const IdWrapTag = ({ children, ...props }) => {
    return React.createElement(
        props.tag,
        {
            id: props.id
        },
        children
    );
}

class AppTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <>
                <IdWrapTag tag={this.props.idWrapTag} id={this.props.id}>
                    {this.props.children}
                </IdWrapTag>
                <Tooltip placement={this.props.placement} isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle}>
                    {this.props.text}
                </Tooltip>
            </>
        )
    }
}

AppTooltip.defaultProps = {
    placement: 'top',
    idWrapTag: 'span'
};

AppTooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
    ]),
    id: PropTypes.string.isRequired,
    placement: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    idWrapTag: PropTypes.string
};

export default AppTooltip;
