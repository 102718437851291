import {
  LOAD_TASKS,
  LOAD_FAX,
  GET_USER_TASKS,
  EXPERT_LOAD_INITIAL_DATA,
  UPDATE_TASK_TABLE,
  UPDATE_RADIUS_SEARCH_TABLE,
  UPDATE_EXPERTS_SEARCH_RESULT,
  UPDATE_SELECTED_EXPERT,
  UPDATE_PREVIEW_USER,
  UPDATE_EXPERT_ACTIVE_TAB,
  UPDATE_EXPERT_REPORT_TABLE,
  UPDATE_CHECK_DUPLICATE_EXPERT_GROUP,
  UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES,
  RESET_NEW_EXPERT_GROUP_VIEW,
  UPDATE_CHECK_DUPLICATE_EXPERT,
  UPDATE_SELECTED_EXPERT_ADDRESSES,
  RESET_NEW_EXPERT_VIEW,
} from './constants';

const loadInitialData = data => {
  return {
    type: EXPERT_LOAD_INITIAL_DATA,
    payload: data,
  };
};

const getUserTasks = data => {
  return {
    type: GET_USER_TASKS,
    payload: data,
  };
};

const loadTasks = data => {
  return {
    type: LOAD_TASKS,
    payload: data,
  };
};

const loadFax = data => {
  return {
    type: LOAD_FAX,
    payload: data,
  };
};

const updateTaskTable = data => {
  return {
    type: UPDATE_TASK_TABLE,
    payload: data,
  };
};

const updateRadiusSearchTable = data => {
  return {
    type: UPDATE_RADIUS_SEARCH_TABLE,
    payload: data,
  };
};

const updateExpertReportTable = data => {
  return {
    type: UPDATE_EXPERT_REPORT_TABLE,
    payload: data,
  };
};

const updateExpertsSearchResult = data => {
  return {
    type: UPDATE_EXPERTS_SEARCH_RESULT,
    payload: data,
  };
};

const updateSelectedExpert = data => {
  return {
    type: UPDATE_SELECTED_EXPERT,
    payload: data,
  };
};

const updatePreviewUser = data => {
  return {
    type: UPDATE_PREVIEW_USER,
    payload: data,
  };
};

const updateExpertActiveTab = activeTab => {
  return {
    type: UPDATE_EXPERT_ACTIVE_TAB,
    payload: activeTab,
  };
};

const updateDuplicateExpertGroup = data => {
  return {
    type: UPDATE_CHECK_DUPLICATE_EXPERT_GROUP,
    payload: data,
  };
};

const updateSelectedExpertGroupAddresses = data => {
  return {
    type: UPDATE_SELECTED_EXPERT_GROUP_ADDRESSES,
    payload: data,
  };
};

const resetNewExpertGroupView = () => {
  return {
    type: RESET_NEW_EXPERT_GROUP_VIEW,
  };
};

const updateDuplicateExpert = data => {
  return {
    type: UPDATE_CHECK_DUPLICATE_EXPERT,
    payload: data,
  };
};

const updateSelectedExpertAddresses = data => {
  return {
    type: UPDATE_SELECTED_EXPERT_ADDRESSES,
    payload: data,
  };
};

const resetNewExpertView = () => {
  return {
    type: RESET_NEW_EXPERT_VIEW,
  };
};

export default {
  loadInitialData,
  loadTasks,
  loadFax,
  getUserTasks,
  updateTaskTable,
  updateRadiusSearchTable,
  updateExpertsSearchResult,
  updateSelectedExpert,
  updatePreviewUser,
  updateExpertActiveTab,
  updateExpertReportTable,
  updateDuplicateExpertGroup,
  updateSelectedExpertGroupAddresses,
  resetNewExpertGroupView,
  updateDuplicateExpert,
  updateSelectedExpertAddresses,
  resetNewExpertView,
};
