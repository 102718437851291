import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import adminConstants from './constants';
import reducer from './reducers';

reducerRegistry.register(adminConstants.moduleName, reducer);

export { default as administrationActions } from './actions';
export { default as administrationOperations } from './operations';
export { default as administrationPropTypes } from './propTypes';
export { default as administrationSelectors } from './selectors';

export default reducer;
