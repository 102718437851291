import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PopupModal from 'shared/components/PopupModal';
import { Row, Col, FormGroup, Button, Label, Input } from 'reactstrap';
import Select from 'react-select';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { commonOperations } from 'common/store';
import { assign } from 'lodash';
import {
  adjustersType,
  mcnsUsersType,
  nurseAuditsType,
  codeReviewsType,
  groupBySelection,
  adjustersTypeSelection,
  mcnsUsersTypeSelection,
  nurseAuditsTypeSelection,
  codeReviewsTypeSelection,
  approveBills,
} from '../utils/constantUtils';

import ApproveBillsBottomNav from './ApproveBillsBottomNav';
import TaskListTable from './TaskListTable';
import ApproveBillsTopNav from './ApproveBillsTopNav';
import SearchableSelect from 'shared/components/input/SearchableSelect';

class ApproveBillContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      denialReason: null,
      notes: '',
      unpendedBills: [],
      checkAll: false,
    };
  }
  
  componentDidUpdate(prevprops) {
    if (this.props.resetTaskList == true) {
      this.props.updateResetTaskList(false);
      this.refresh();
    }
  }
  
  refresh = () => {
    const {
      profile,
      reviewType,
      currentUserId,
      selectedAdjusterName,
      selectedAdjusterId
    } = this.props;
    const selectedAdjusterIdForRefresh = this.props.initialUsers[this.props.reviewType]
      .length
      ? this.props.initialUsers.adjusters.filter(
        t => t.name == this.props.selectedAdjusterName
      )[0].id
      : 0;

    this.props.findBills(
      currentUserId,
      selectedAdjusterName,
      profile,
      selectedAdjusterIdForRefresh,
      reviewType
    );
    // let selectedData = {
    //   currentUserId: currentUserId,
    //   selectedAdjusterName: selectedAdjusterName,
    //   profile: profile,
    //   selectedAdjusterId: selectedAdjusterId,
    //   reviewType: reviewType
    // }
    // this.props.updateFindBillsFromTabs(selectedData)
    this.props.setMcnsEditVal({});
    this.setState({unpendedBills: []});
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  handleUserDisplayChange = (_fieldName, value) => {
    this.props.updateSelectedUser({
      selectedAdjusterId: this.props.selectedAdjusterId,
      selectedAdjusterName: value,
    });
    this.setState({unpendedBills: []});
  };

  handleBillReviewTypeChange = event => {
    const reviewType = event.target.value;
    this.props.updateReviewType(reviewType);
    const { profile, currentUserId, initialUsers } = this.props;
    const selectedAdjusterId =
      initialUsers[reviewType].length > 0 ? initialUsers[reviewType][0].id : 0;
    const selectedAdjusterName =
      initialUsers[reviewType].length > 0
        ? initialUsers[reviewType][0].name
        : '';
    this.props.updateSelectedUser({
      selectedAdjusterId,
      selectedAdjusterName,
    });
    this.props.findBills(
      currentUserId,
      selectedAdjusterName,
      profile,
      selectedAdjusterId,
      reviewType
    );
    let selectedData = {
      currentUserId: currentUserId,
      selectedAdjusterName: selectedAdjusterName,
      profile: profile,
      selectedAdjusterId: selectedAdjusterId,
      reviewType: reviewType,
    };
    this.props.updateFindBillsFromTabs(selectedData);
    this.setState({unpendedBills: []});
  };

  handleUserChange = (_fieldName, displayValue, value) => {
    this.props.updateSelectedUser({
      selectedAdjusterId: value,
      selectedAdjusterName: displayValue,
    });
    const { profile, reviewType, currentUserId } = this.props;
    this.props.findBills(
      currentUserId,
      displayValue,
      profile,
      value,
      reviewType
    );
    let selectedData = {
      currentUserId: currentUserId,
      selectedAdjusterName: displayValue,
      profile: profile,
      selectedAdjusterId: value,
      reviewType: reviewType,
    };
    // this.props.updateFindBillsFromTabs(selectedData)
    this.setState({unpendedBills: []});
  };

  transferBills = assignedUser => {
    const {
      selectedBills,
      reviewType,
      profile,
      selectedAdjusterName,
      selectedAdjusterId,
      currentUserId,
    } = this.props;
    if (selectedBills.length < 1) {
      return;
    }
    const data = {
      assignedUser,
      selectedBills,
      currentUserId,
      name: selectedAdjusterName,
      profile,
      selectedAdjusterId,
      reviewType,
      groupByType: this.props.groupBy,
    };
    this.props.transferBills(data);
  };

  getReviewTypeSelection() {
    const { initialUsers, slpUserId } = this.props;
    const reviewTypeSelection = [adjustersTypeSelection];
    if (R.find(item => item.id === slpUserId, initialUsers[mcnsUsersType])) {
      reviewTypeSelection.push(mcnsUsersTypeSelection);
    }
    if (R.find(item => item.id === slpUserId, initialUsers[nurseAuditsType])) {
      reviewTypeSelection.push(nurseAuditsTypeSelection);
    }
    if (R.find(item => item.id === slpUserId, initialUsers[codeReviewsType])) {
      reviewTypeSelection.push(codeReviewsTypeSelection);
    }
    return reviewTypeSelection;
  }

  onChangeHandler = item => {
    this.setState({
      denialReason: item.value,
    });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getModalBody() {
    const { billDetailDropdownData } = this.props;
    return (
      <>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="denialReason">Reasons For Denial</Label>
              <Select
                onChange={this.onChangeHandler}
                placeholder="Choose.."
                options={
                  billDetailDropdownData.length
                    ? billDetailDropdownData.map(obj =>
                      assign(obj, {
                        label: obj.description,
                        value: obj.description,
                      })
                    )
                    : []
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Input
                type="textarea"
                name="notes"
                value={this.state.notes}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  onsavehandler = () => {
    const {
      selectedBills,
      reviewType,
      profile,
      selectedAdjusterName,
      currentUserId,
      selectedAdjusterId
    } = this.props;
    // const selectedAdjusterId = this.props.initialUsers[this.props.reviewType]
    //   .length
    //   ? this.props.initialUsers.adjusters.filter(
    //     t => t.name == this.props.selectedAdjusterName
    //   )[0].id
    //   : 0;
    const selectedAdjusterIdForRefresh = this.props.initialUsers[this.props.reviewType]
      .length
      ? this.props.initialUsers.adjusters.filter(
        t => t.name == this.props.selectedAdjusterName
      )[0].id
      : 0;
    const data = {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId : selectedAdjusterIdForRefresh,
      name: selectedAdjusterName,
      selectedBills,
      reason: this.state.notes,
      denialCode: this.state.denialReason,
    };
    this.props.denyBills(data);
    this.toggleModal();
  };

  getModalFooter = () => {
    return (
      <>
        <Button
          type="button"
          disabled={!this.state.denialReason && !this.state.notes}
          onClick={this.onsavehandler}
        >
          SAVE
        </Button>
      </>
    );
  };

  handleDenyBills = () => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
      selectedBills,
      bills,
      getBillDetailDropdownData,
      resetBillDetailDropdownData,
    } = this.props;
    const data = {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      name: selectedAdjusterName,
      selectedBills,
    };
    const bill = bills.find(obj => obj.id === selectedBills[0]);

    const needPaycode = bills.filter(
      item =>
        selectedBills.includes(item.id) &&
        item.allowMultiDenialReason === true && item.paycodeId === 0 && item.juris !== 'WC'
    );
    if(needPaycode.length > 0){
      this.props.alert(
        <span>
          The following bills are missing a paycode:
          <br></br>
          {needPaycode.map(item => item.id).join(',')}
        </span>
      );
      return;
    }

    const params = {
      userId: currentUserId,
      profile,
      billId: bill.id,
      claimant: bill.claimant,
      siteId: bill.siteId,
    };
    resetBillDetailDropdownData();
    getBillDetailDropdownData(params);
    this.toggleModal();
  };

  handlePendBillsWorklist = () => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
      selectedBills,
    } = this.props;
    const data = {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      name: selectedAdjusterName,
      selectedBills,
    };
    this.props.pendBillsWorklist(data);
    this.refresh();
  };

  handleUnpendBillsWorklist = async () => {
    // Send unpend for all selected bills
    const { 
      currentUserId,      
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
    } = this.props;
    const requestParams = {
      currentUserId,
      userId: currentUserId,
      billIds: this.state.unpendedBills,
      profile: profile,
      name: selectedAdjusterName,
      selectedAdjusterId: selectedAdjusterId,
      reviewType: reviewType,
    };
    await this.props.unpendCodeOnBills(requestParams);
    //await this.refresh();
  };

  handleUnpendBillUpdate = (event, item) => {
    if (event.target.checked) {
      this.setState({
        unpendedBills: [...this.state.unpendedBills, ...item],
      });
    } else {
      this.setState({
        unpendedBills: this.state.unpendedBills.filter(value => !item.includes(value)),
      });
    }
    //console.log(this.state.unpendedBills);
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState({checkAll: true});
    } else {
      this.setState({checkAll: false});
    }
  }

  saveTaskListNote = body => {
    const {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      selectedAdjusterName,
      selectedBills,
    } = this.props;
    const data = {
      currentUserId,
      profile,
      reviewType,
      selectedAdjusterId,
      name: selectedAdjusterName,
      selectedBills,
    };
    this.props.saveTaskListNote(body, data);
  };

  resetDenyReason = () => {
    this.setState({
      denialReason: null,
      notes: '',
      denialReason2: null,
      notes2: '',
    });
    this.props.resetBillDetailDropdownData();
  };

  render() {
    return (
      <>
        <ApproveBillsTopNav
          adjusters={this.props.initialUsers[this.props.reviewType]}
          selectedAdjusterName={this.props.selectedAdjusterName}
          reviewType={this.props.reviewType}
          groupBy={this.props.groupBy}
          reviewTypeSelection={this.getReviewTypeSelection()}
          groupBySelection={groupBySelection}
          bills={this.props.bills}
          selectedBills={this.props.selectedBills}
          refresh={this.refresh}
          handleBillReviewTypeChange={this.handleBillReviewTypeChange}
          handleUserDisplayChange={this.handleUserDisplayChange}
          handleUserChange={this.handleUserChange}
          transferBills={this.transferBills}
          updateGroupBySelection={this.props.updateGroupBySelection}
          updateSelectedBills={this.props.updateSelectedBills}
          settingColumns={this.props.settingColumns}
          updateGridSettingsCheckbox={this.props.updateGridSettingsCheckbox}
        />
        <TaskListTable
          refresh={this.refresh}
          data={this.props.bills}
          selectedBills={this.props.selectedBills}
          reviewType={this.props.reviewType}
          groupBy={this.props.groupBy}
          updateSelectedBills={this.props.updateSelectedBills}
          name={approveBills}
          currentUserId={this.props.currentUserId}
          isSlpUser={this.props.isSlpUser}
          profile={this.props.profile}
          settingColumns={this.props.settingColumns}
          saveTaskListNote={this.saveTaskListNote}
          handleUnpendBillUpdate={this.handleUnpendBillUpdate}
          unpendedBills={this.state.unpendedBills}
          handleSelectAllClick={this.handleSelectAllClick}
        />
        <hr />
        <ApproveBillsBottomNav
          bills={this.props.bills}
          selectedBills={this.props.selectedBills}
          reviewType={this.props.reviewType}
          handleDisagreeButtonClick={this.handleDenyBills}
          handlePendButtonClick={this.handlePendBillsWorklist}
          handleUnpendButtonClick={this.handleUnpendBillsWorklist}
          unpendDisabled={this.state.unpendedBills.length < 1 || this.state.checkAll}
        />
        <PopupModal
          content={this.getModalBody()}
          title="Bill Denial Reason..."
          externalToggle={this.toggleModal}
          isOpen={this.state.modalOpen}
          footerContent={this.getModalFooter()}
          onClosed={this.resetDenyReason}
          size="xl"
        />
      </>
    );
  }
}

ApproveBillContainer.propTypes = {
  findBills: PropTypes.func.isRequired,
  updateReviewType: PropTypes.func.isRequired,
  updateSelectedUser: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  updateSelectedBills: PropTypes.func.isRequired,
  transferBills: PropTypes.func.isRequired,
  denyBills: PropTypes.func.isRequired,
  pendBillsWorklist: PropTypes.func.isRequired,
  initialUsers: PropTypes.shape({
    [adjustersType]: PropTypes.array,
    [mcnsUsersType]: PropTypes.array,
    [nurseAuditsType]: PropTypes.array,
    [codeReviewsType]: PropTypes.array,
  }),
  bills: PropTypes.array.isRequired,
  slpUserId: PropTypes.number.isRequired,
  profile: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  selectedAdjusterId: PropTypes.number.isRequired,
  selectedAdjusterName: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  selectedBills: PropTypes.array.isRequired,
  settingColumns: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isSlpUser: PropTypes.bool.isRequired,
  updateGridSettingsCheckbox: PropTypes.func.isRequired,
  billDetailDropdownData: PropTypes.array,
  getBillDetailDropdownData: PropTypes.func,
  resetBillDetailDropdownData: PropTypes.func,
  updateFindBillsFromTabs: PropTypes.func,
  alert: PropTypes.func.isRequired,
  unpendCodeOnBills: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const gridSettings = billOperationsSelectors.getTaskListGridSettingsState(
    state
  );
  return {
    initialUsers: approveBillState.users,
    bills: approveBillState.bills,
    reviewType: approveBillState.reviewType,
    selectedAdjusterId: approveBillState.selectedAdjusterId,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
    groupBy: approveBillState.groupBy,
    selectedBills: approveBillState.selectedBills,
    settingColumns: gridSettings.settingColumns,
    billDetailDropdownData: billReviewState.billDetailDropdownData,
    resetTaskList: approveBillState.resetTaskList,
  };
};

const mapDispatchToProps = dispatch => ({
  findBills: (userId, name, profile, adjuster, reviewType) =>
    dispatch(
      billOperationsOperations.findApproveBills(
        userId,
        name,
        profile,
        adjuster,
        reviewType
      )
    ),
  updateReviewType: reviewType =>
    dispatch(billOperationsActions.updateApproveBillReviewType(reviewType)),
  updateSelectedUser: userId =>
    dispatch(billOperationsActions.updateApproveBillSelectedAdjuster(userId)),
  transferBills: (data, reviewType) =>
    dispatch(billOperationsOperations.transferBills(data, reviewType)),
  updateGroupBySelection: groupBy =>
    dispatch(billOperationsActions.updateApproveBillGroupBy(groupBy)),
  updateSelectedBills: data =>
    dispatch(billOperationsActions.updateSelectedApproveBills(data)),
  denyBills: data => dispatch(billOperationsOperations.denyBills(data)),
  pendBillsWorklist: data =>
    dispatch(billOperationsOperations.pendBillsWorklist(data)),
  updateGridSettingsCheckbox: data =>
    dispatch(billOperationsActions.updateTaskListGridSettingsCheckbox(data)),
  saveTaskListNote: (body, searchFields) =>
    dispatch(
      billOperationsOperations.saveTaskListNote(body, searchFields, false)
    ),
  getBillDetailDropdownData: data =>
    dispatch(billOperationsOperations.getBillDetailDropdownData(data)),
  resetBillDetailDropdownData: () =>
    dispatch(billOperationsActions.resetBillDetailDropdownData()),
  updateFindBillsFromTabs: data =>
    dispatch(billOperationsActions.updateFindBillsFromTabs(data)),
  setMcnsEditVal: value =>
    dispatch(billOperationsActions.setMcnsEditVal(value)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  unpendCodeOnBills: data =>
    dispatch(billOperationsOperations.unpendCodeOnBills(data, true)),
  updateResetTaskList: data =>
    dispatch(billOperationsActions.resetTaskList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveBillContainer);
