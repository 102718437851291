import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as ReduxUtils from 'shared/utils/reduxUtils';

import { actionTypes } from './actions';

/**
 * FIXME: Make sure to properly handle a "failed"/"offline" logout. Discuss the
 * possible methods I've researched with the team. (DC)
 */

const errorMessageReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'errorMessage'], action),
});

const userReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'user'], action),
  [actionTypes.LOGOUT.SUCCESS]: () => null,
});

const usernameReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'username'], action),
});

const passwordUpdateErrorMessageReducer = ReduxUtils.createReducer(null, {
  [actionTypes.UPDATE.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'passwordUpdateErrorMessage'], action),
  [actionTypes.LOGOUT.SUCCESS]: () => null, 
});

const loginResultReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'loginResult'], action),
  [actionTypes.LOGOUT.SUCCESS]: () => null, 
  [actionTypes.UPDATE.SUCCESS]: () => null,
});

const currentQuestionIdReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'currentQuestionId'], action),
});

const currentQuestionReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'currentQuestion'], action),
});

const securityQuestionsReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOADQSNS.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'securityQuestions'], action),
});

const userIdReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'userId'], action),
});

const resetPasswordResultReducer = ReduxUtils.createReducer(null, {
  [actionTypes.RESET.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'result'], action),
});

const checkSecurityQuestionReducer = ReduxUtils.createReducer(null, {
  [actionTypes.CHECKQSN.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'result'], action),
  [actionTypes.LOGOUT.SUCCESS]: () => null,
});

const zebraTimeoutSecondReducer = ReduxUtils.createReducer(null, {
  [actionTypes.ZEBRATIMEOUT.SUCCESS]: (state, action) =>
    R.pathOr(null, ['payload', 'result'], action),
});

const logoutMessageReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGOUT.SUCCESS]: (state, action) => 
    R.pathOr(null, ['payload', 'result'], action),
});
const checkAuthCodeReducer = ReduxUtils.createReducer(null, {
  [actionTypes.CHECKAUTHCODE.SUCCESS]: (state, action) => 
  R.pathOr(null, ['payload', 'result'], action),
})

const isAuthCodeRequiredReducer = ReduxUtils.createReducer(null, {
  [actionTypes.LOGIN.SUCCESS]: (state, action) =>
  R.pathOr(null, ['payload', 'isAuthCodeRequired'], action),
})

export default combineReducers({
  errorMessage: errorMessageReducer,
  loginResult: loginResultReducer,
  passwordUpdateErrorMessage: passwordUpdateErrorMessageReducer,
  resetPasswordResult: resetPasswordResultReducer,
  securityQuestionResult: checkSecurityQuestionReducer,
  zebraTimeoutResult: zebraTimeoutSecondReducer,
  currentQuestion: currentQuestionReducer,
  currentQuestionId: currentQuestionIdReducer,
  securityQuestions: securityQuestionsReducer,
  userId: userIdReducer,
  username: usernameReducer,
  user: userReducer,
  logoutMessage: logoutMessageReducer,
  checkAuthCode: checkAuthCodeReducer,
  isAuthCodeRequired: isAuthCodeRequiredReducer
});
