import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

import UAndCSetupTable from './UAndCSetupTable';
import TopNavActions from '../TopNavActions';
import AddUAndCSetupButton from './AddUAndCSetupButton';

class UAndCSetupContainer extends Component {
  addNewUAndCSetup = () => {
    return <AddUAndCSetupButton data={this.props.data} />;
  };

  render() {
    return (
      <>
        <TopNavActions
          name={`${this.props.name}_topUAndCSetup`}
          stateList={this.props.stateList}
          lobList={this.props.lobList}
          updateFilter={this.props.updateFilter}
          addNewButton={this.addNewUAndCSetup}
          haveAddNewButton={this.props.haveAddNewButton}
          haveResetButton={this.props.haveResetButton}
        />
        <UAndCSetupTable
          isSelectable={this.props.isSelectable}
          name={`${this.props.name}_tableUAndCSetup`}
          onSelectRows={this.props.onSelectRows}
          data={this.props.data}
          updateTableData={this.props.updateTableData}
          lobList={this.props.lobList}
          stateList={this.props.stateList}
          editColumns={this.props.editColumns}
          filters={this.props.filters}
        />
      </>
    );
  }
}

UAndCSetupContainer.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  editColumns: PropTypes.object.isRequired,
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateTableData: PropTypes.func.isRequired,
  haveAddNewButton: PropTypes.bool,
  haveResetButton: PropTypes.bool,
};

UAndCSetupContainer.defaultProps = {
  haveAddNewButton: true,
  haveResetButton: true,
};

const mapStateToProps = state => {
  const initialDataState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const uAndCSetupFilters = billMaintenanceSelectors.getClientMaintenanceUAndCSetupFilter(
    state
  );

  return {
    filters: uAndCSetupFilters,
    stateList: initialDataState.clientComboBoxes.stateList,
    lobList: initialDataState.clientComboBoxes.lobList,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateFilter: data =>
      dispatch(billMaintenanceActions.updateUAndCSetupFilter(data)),
    updateTableData: data =>
      dispatch(billMaintenanceActions.updateUAndCSetupTable(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UAndCSetupContainer);
