import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter, Row, Col } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions, fast360Selectors } from '../store';
import Select from 'react-select';
import { assign } from 'lodash';
import { commonOperations } from 'common/store';
import * as FetchUtils from 'shared/utils/fetchUtils';
import { shortToDatabaseTable } from '../utils/dataUtils'
import Fast360EmailAuthDateModal from './Fast360EmailAuthDateModal';

class Fast360Email extends Component {
    constructor(props) {
        super(props);
        var defaultDate = new Date();
        defaultDate.setHours(0,0,0,0);
        this.state = {
            emailId: '',
            emailTemplates: [],
            body: "",
            selectedReportId: "",
            sendTo: "",
            params: null,
            showAuth: false,
            startDate: defaultDate,
            endDate: defaultDate,
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle = () => {
        this.props.clicked()
    };

    componentDidMount() {
        const { currentUserId, refId, tableName, serviceId } = this.props;
        const user = this.props.users && this.props.users.find(u => u.zebraId == currentUserId);
        let payload = {
            "functionName": "loadEmailUserInfo",
            "zebraUserId": currentUserId,
            "user_id": user.id
        }
        this.props.getEmailAddress(payload);
        
        if (tableName != "TRN" && tableName != "SPT" && tableName != "MOD" && tableName != "INT") {
            if (refId != null && refId != 0) {
                let templateLoad = {
                    "functionName" : "getWorklistEmailReportTemplates",
                    "zebraUserId": currentUserId,
                    "user_id": user.id,
                    "referralId" : refId,
                    "tableType" : tableName,
                    "serviceId" : serviceId
                }
                this.props.getEmailReportTemplates(templateLoad);
            }
        } else {
            // Build templates
            this.buildTemplates();
        }
        let breadCrumb = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": currentUserId,
            "userid":currentUserId,
            "breadcrumbkey": 'EMAILREPORTOPEN',
            "refTableId": serviceId,
            "refId":  refId,
            "refTable": tableName,
          };
        
          this.props.logBreadCrumbs(breadCrumb)
        // TODO: Handle auth date load I don't think this is currently implemented
    }

    buildTemplates() {
        // emailReportTemplates - This is the return object we are reading from
        // emailTemplates - This is the state object we are saving to, to build the dropdown
        const { tableName } = this.props;
        let tempReports = [];
        let counter = 0;
        if (tableName != "TRN" && tableName != "SPT" && tableName != "INT") {
            tempReports.push({"label" : "Completed (for internal use)", "value" : counter, "filename" : "lmsCompleted.pdf", "body" : ""});
            counter++;
            if (tableName === "HHC") {
                tempReports.push({"label" : "HHC Authorized (for vendor use)", "value" : counter, "filename" : "lmsHHCAuthorized.pdf", "body" : ""});
                counter++;
                tempReports.push({"label" : "HHC Cancelled (for vendor use)", "value" : counter, "filename" : "lmsHHCCancelled.pdf", "body" : ""});
                counter++;
            } else {
                tempReports.push({"label" : "Authorized (for vendor use)", "value" : counter, "filename" : "lmsAuthorized.pdf", "body" : ""});
                counter++;
                tempReports.push({"label" : "Cancelled (for vendor use)", "value" : counter, "filename" : "lmsCancelled.pdf", "body" : ""});
                counter++;
            }
            tempReports.push({"label" : "Quote (for customer use - MSRP)", "value" : counter, "filename" : "lmsQuoteMSRP.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteMSRP)});
            counter++;
            if (tableName != "MOD") {
                tempReports.push({"label" : "Quote (for customer use - FS/UC)", "value" : counter, "filename" : "lmsQuoteFS.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteFS)});
                counter++;
                tempReports.push({"label" : "Quote (for customer use - OWCP)", "value" : counter, "filename" : "lmsQuoteOWCP.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteOWCP)});
                counter++;
            }
        } else if (tableName === "INT") {
            tempReports.push({"label" : "Vendor Interpretation Report", "value" : counter, "filename" : "lmsVendorInterp.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Interpretation Report", "value" : counter, "filename" : "lmsInternalInterp.pdf", "body" : ""});
            counter++;
        } else if (tableName === "TRN") {
            tempReports.push({"label" : "Vendor Transport Report", "value" : counter, "filename" : "lmsVendorTransport.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Transport Report", "value" : counter, "filename" : "lmsInternalTransport.pdf", "body" : ""});
            counter++;
        } else if (tableName === "SPT") {
            tempReports.push({"label" : "Vendor Special Transport Report", "value" : counter, "filename" : "lmsVendorTransport.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Special Transport Report", "value" : counter, "filename" : "lmsInternalTransport.pdf", "body" : ""});
            counter++;
        }
        this.setState( { emailTemplates: tempReports });
    }

    componentDidUpdate(prevProps) {
        if (this.props.emailReportTemplates !== prevProps.emailReportTemplates) {
            // Now that we got the templates build the dropdown
            this.buildTemplates();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.getEmail !== nextProps.getEmail) {
            nextProps.getEmail.length > 0 && this.setState({ emailId: nextProps.getEmail[0].email1 })
        }        
    }

    setSendTo = (e) => {
        this.setState({ sendTo: e.replace(';', ',') })
    }

    onChangeEmail = (e) => {
        this.setState({ emailId: e })
    }

    reportSelectOnChangeHandler = (event) => {
        if (event.value === null) {
            this.setState({
                body : "",
                selectedReportId : "",
            });
        } else {
            this.setState({
                body : this.state.emailTemplates[event.value].body,
                selectedReportId : event.value 
            });
        }        
    }

    bodyOnChange = (e) => {
        this.setState({ body: e })
    }

    handlePreview = () => {        
        if (this.state.selectedReportId === "") {
            this.props.alert("Must select a report type to continue");
            return;
        }

        const params = new URLSearchParams();
        params.append('printType', "Preview");

        this.buildRequest(params);        
    }
    
    handleSend = () => {
        const { tableName } = this.props;
        // Handle the send.
        if (this.state.selectedReportId === "") {
            this.props.alert("Must select a report type to continue");
            return;
        }

        const params = new URLSearchParams();
        params.append('printType', "SendEmail");

        this.buildRequest(params);
        let breadCrumb = {
            "functionName": 'logBreadCrumbs',
            "zebraUserId": this.props.currentUserId,
            "userid": this.props.currentUserId,
            "breadcrumbkey": 'SENDREPORT',
            "refTableId": this.props && this.props.serviceId ? this.props.serviceId: null,
            "refId":  this.props && this.props.referralId ? this.props.referralId: null,
            "refTable": this.props && this.props.module ? this.props.module: null,
          };
        
          this.props.logBreadCrumbs(breadCrumb)

        // TODO: Save the sent email to a note

        const emailFrom = this.state.from ? this.state.from : '';
        const emailTo = this.state.sendTo ? this.state.sendTo : '';
        const emailSub = this.state.subject ? this.state.subject : '';
        const user_id = this.props.users && this.props.users.find(u => u.zebraId == this.props.currentUserId);
        const itemNotes = [];
        const newNote = {
            'userName': `${this.props.userName.firstname} ${this.props.userName.lastname}`,
            'enteredDate': new Date(),
            'note': `From: ${emailFrom} \r\nTo: ${emailTo} \r\nSubject: ${emailSub} \r\nBody:\r\n${this.state.body}`,
            'noteType': 'General',
            'canClientSee': false,
            "id": null,
            "referenceTable": tableName,
            "referenceId": this.props.refId,
            "internalUse": true,
            "userId": user_id.id
        };
        itemNotes.push(newNote);
        let saveNotesPayload = {
            "functionName": "saveNotes",
            "zebraUserId": this.props.currentUserId,
            "serviceId": this.props.serviceId,
            notes: itemNotes
        }
        this.props.saveNotesData(saveNotesPayload);
        // TODO: Log bread crumbs for SENDREPORT and EMAILREPORTCLOSE
        // Close after sending if it's not HHC
        if (tableName != "HHC") {
            this.toggle();
        }
    }

    buildRequest = (params) => {
        const { refId, tableName, serviceId } = this.props;
        const selectedTemplate = this.state.emailTemplates[this.state.selectedReportId];

        params.append('reportName', selectedTemplate.filename);
        params.append('referralId', refId);
        params.append('serviceId', serviceId);
        params.append('tablename', shortToDatabaseTable(tableName));
        params.append('tableType', tableName);        
        params.append('sendTo', this.state.sendTo);
        params.append('msgBody', this.state.body);
        params.append('authStart', "");
        params.append('authEnd', "");

        if (tableName === "HHC" && 
            (params.get("reportName") === "lmsHHCAuthorized.pdf" || 
             params.get("reportName") === "lmsHHCCancelled.pdf")) {
            this.setState({ showAuth: true });
            this.setState({ params: params });
        } else {
            this.sendRequest(params);
        }
    }

    sendRequest = (params) => {        
        const actionUrl = new URL('/fast360/letters/createLetter.php', process.env.REACT_APP_SLP_BASE_URL);
        actionUrl.search = params.toString();

        FetchUtils.openWindowWithPost(
            actionUrl.toString(),
            {
                username: process.env.REACT_APP_SLP_USERNAME,
                password: process.env.REACT_APP_SLP_PASSWORD,
            },
            `CreateLetter${new Date().getTime()}`
        );
    }

    handleAuthSubmit = (startDate, endDate) => {
        this.setState({ showAuth: false });
        let params = this.state.params;

        params.append('authStart', startDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
        params.append('authEnd', endDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));

        this.sendRequest(params);

        if (params.get("printType") === "SendEmail") {
            this.toggle();
        }
    }

    handleAuthCancel = () => {
        // Close Auth pop up and clear the params
        this.setState({ showAuth: false });
        this.setState({ params: null });
    }

    render() {
        return (
            <Modal size="lg" isOpen={true}>
                <ModalHeader toggle={this.toggle}> Email/Reporting </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={3} style={{ marginTop: '10px' }}> To:
                        </Col>
                        <Col lg={9} style={{ marginLeft: '-120px' }} >
                            <FormGroup >
                                <Input
                                    type="text"
                                    name="sendTo"
                                    id="sendTo"
                                    onChange={e => this.setSendTo(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={3} style={{ marginTop: '10px' }}> From:
                        </Col>
                        <Col lg={9} style={{ marginLeft: '-120px' }}>
                            <FormGroup >
                                <Input type="text"
                                    onChange={e => this.onChangeEmail(e.target.value)}
                                    name="fromEmail"
                                    id="fromEmail"
                                    value={this.state.emailId}>
                                </Input>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={3} style={{ marginTop: '10px' }}> Send As:
                        </Col>
                        <Col lg={9} style={{ marginLeft: '-120px' }}>
                            <FormGroup>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder="Select Report to send"
                                    onChange={event => this.reportSelectOnChangeHandler(event)}
                                    name="report"
                                    id="report"
                                 options={this.state.emailTemplates}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}> Email Body:
                        </Col>
                        <Col lg={9} style={{ marginLeft: '-120px' }}>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="emailText"
                                    id="emailText"
                                    rows={10}
                                    spellCheck
                                    onChange={e => this.bodyOnChange(e.target.value)}
                                    value={this.state.body}
                                    
                                    
                                // onChange={onInputChangeHandler}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.showAuth && (
                        <Fast360EmailAuthDateModal
                            startDate = {this.state.startDate}
                            endDate = {this.state.endDate}
                            onSubmit = {this.handleAuthSubmit}
                            onCancel = {this.handleAuthCancel}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button style={{ width: '120px' }} color="primary" onClick={this.handlePreview} >Preview</Button>
                    <Button style={{ width: '240px' }} color="primary" onClick={this.handleSend} >Generate & Send Email</Button>
                    <Button style={{ width: '120px' }} color="primary" onClick={this.toggle}>Close</Button>
                </ModalFooter>
            </Modal >
        )
    }
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        getEmail: state.FAST360.fast360Reducer.updateGetEmailAddress ? state.FAST360.fast360Reducer.updateGetEmailAddress : [],
        emailReportTemplates: state.FAST360.fast360Reducer.updateWorklistEmailReportTemplates ? state.FAST360.fast360Reducer.updateWorklistEmailReportTemplates : [],
        users: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
        userName: state.AUTH.user,

    };
};
const mapDispatchToProps = dispatch => ({
    getEmailAddress: (data) => dispatch(fast360Operations.getEmailAddress(data)),
    getEmailReportTemplates: (data) => dispatch(fast360Operations.getWorklistEmailReportTemplates(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    logBreadCrumbs : (data) =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
    saveNotesData: (data) => dispatch(fast360Operations.saveNotesData(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360Email);