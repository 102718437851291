import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {NavLink} from "react-router-dom";

library.add(faExternalLinkSquareAlt);

const style = {
    cursor: 'pointer'
};

export function ClickableCell({clickHandler, location}) {
    return (
        <td style={style} onClick={() => clickHandler()}>
            <NavLink to={location}>
                <FontAwesomeIcon
                    icon="external-link-square-alt"/>
             </NavLink>
        </td>
    )
}
