import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';
import {
  faRandom,
  faExclamationCircle,
  faDollarSign,
  faFile,
  faArrowAltCircleLeft,
  faExclamationTriangle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  billOperationsOperations,
  billOperationsActions,
  billOperationsSelectors,
} from '../store';
import { getProfileForPhp } from '../utils/billUtils';
import { authSelectors } from 'modules/auth/store';
import StatusDocumentModal from './StatusDocumentModal';
import {
  CustomInput,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
} from 'reactstrap';
import moment from 'moment';
import AppTable from 'shared/components/table/AppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import PopupModal from 'shared/components/PopupModal';
import { formatMMDDYYYY } from 'shared/utils/shareUtils';
import * as FetchUtils from 'shared/utils/fetchUtils';

import {
  nurseAuditsType,
  adjustersType,
  codeReviewsType,
  mcnsUsersType,
  paycodeList51,
  paycodeList50,
  paycodeList57,
  columnName,
  recentActivity,
  approveBills,
  qaBills,
} from '../utils/constantUtils';
import { getDaysFromToday, selectedByGroup } from '../utils/billUtils';

import IconBasedContent from './IconBasedContent';
import BillClaimNotesModal from './BillClaimNotesModal';
import MoreInfoModal from './MoreInfoModal';
import OdgLookupModal from './OdgLookupModal';
import ModifyBillLinesModal from './ModifyBillLinesModal';
import BillReviewBillsModal from './BillReviewBillsModal';
import McnsModal from './McnsModal';
import CodeReviewModal from './CodeReviewModal';
import ClaimResultModal from './ClaimResultModal';
import AppInput from 'shared/components/input/AppInput';
let currPageBillsSelected = [];
export class TaskListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      odgModalOpen: false,
      reverseModalOpen: false,
      modalItem: null,
      billIdmodal: false,
      claimIdmodal: false,
      isOpenOdgLookup: false,
      statusHistoryModal: false,
      item: null,
      claimant: '',
      billId: 1,
      claimNumber: '',
      formattedDOIDate: '',
      mcnsZipCodeState: 0,
      selectedRowObj: {},
      modal: false,
      billIdHoverContent: '',
      showMoreInfoModal: false,
      isPended: true,
      selectedRow: {},
      paycodeParms: {},
      paycodeChangeOpen: false,
      chrCode: ''
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };
  onBillNotesClickHandler = item => {
    // TODO
    console.log(item);
  };
  onClaimClickHandler = item => {
    // TODO
    console.log(item);
  };
  onIdClickHandler = item => {
    return <></>;
  };
  onEorClickHandler = item => {
    // TODO
    let mode = '';

    // window.alert(mode);

    const currentProfile = getProfileForPhp(this.props.profile);

    const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);

    const params = new URLSearchParams();

    params.append('pdf', item.id);
    params.append('profile', currentProfile);
    params.append('printflags', 0);
    params.append('script', 'Y');

    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `EorDocsTaskList${new Date().getTime()}`
    );
    //console.log(this.props.data);
  };

  onMedsClickHandler = item => {
    // TODO
    let mode = '';

    // window.alert(mode);

    const currentProfile = getProfileForPhp(this.props.profile);

    const actionUrl = new URL('/eor.php', process.env.REACT_APP_SLP_BASE_URL);

    const params = new URLSearchParams();
    params.append('injuryid', currentProfile + item.id);
    params.append('profile', currentProfile);
    params.append('script', 'Y');
    params.append('mode', 'medicals');
    params.append('printflags', 0);
    params.append('billlist', item.id);

    actionUrl.search = params.toString();

    FetchUtils.openWindowWithPost(
      actionUrl.toString(),
      {
        username: process.env.REACT_APP_SLP_USERNAME,
        password: process.env.REACT_APP_SLP_PASSWORD,
      },
      `MedDocs${new Date().getTime()}`
    );
    // console.log(this.props.data);
  };

  onNotesClickHandler = item => {
    // TODO
    console.log(item);
  };

  onBADocsClickHandler = item => {
    // TODO
    console.log(item);
    //console.log(this.props.data);
  };

  onOdgClickHandler = item => {
    this.setState({
      modalItem: item,
      billId: item.id,
    });
    this.odgModalToggle();
  };

  odgModalToggle = () => {
    this.setState(prevState => ({
      odgModalOpen: !prevState.odgModalOpen,
    }));
  };

  paycodeChangeOpenToggle = () => {
    this.setState(prevState => ({
      paycodeChangeOpen: !prevState.paycodeChangeOpen,
    }));
  };

  onReverseClickHandler = item => {
    this.setState({
      modalItem: item,
    });
    this.reverseModalToggle();
  };

  reverseModalToggle = () => {
    this.setState(prevState => ({
      reverseModalOpen: !prevState.reverseModalOpen,
    }));
  };

  openStatusHistoryModal = params => {
    this.onStatusHistoryClick(params);
    this.onStatusHistoryHandle();
  };

  onStatusHistoryHandle = () => {
    if (this.state.statusHistoryModal) {
      this.props.resetStatusHistoryData();
    }
    this.setState({ statusHistoryModal: !this.state.statusHistoryModal });
  };

  onStatusHistoryClick = params => {
    const { currentUserId, profile } = this.props;
    const billId = params.id;
    const requestParams = {
      billId: billId,
      userId: currentUserId,
      profile: profile,
    };
    this.props.getStatusHistoryData(requestParams);
    this.setState({ selectedRowObj: params });
  };

  onMoreInfoClick = async params => {
    const { currentUserId, profile } = this.props;
    const billId = params.id;
    const requestParams = {
      zebraUserId: currentUserId,
      userId: currentUserId,
      claimId: params.claimNumber,
      billId: billId,
      profile: profile,
    };
    await this.props.loadMoreInfo(requestParams);
    this.setState({ showMoreInfoModal: true, selectedRow: params });
  };

  unpendOnClick = async params => {
    const { currentUserId, profile } = this.props;
    const billId = params.id;
    const requestParams = {
      currentUserId,
      userId: currentUserId,
      claimId: params.claimNumber,
      billId: billId,
      profile: profile,
      name: this.props.selectedAdjusterName,
      selectedAdjusterId: this.props.selectedAdjusterId,
      reviewType: this.props.reviewType,
    };
    await this.props.unpendCodeOnBill(requestParams);
    await this.props.refresh();
    params.pended = false;
    //this.setState({ isPended: false });
    // this.setState({ showMoreInfoModal: true, selectedRow: params });
  };

  onMoreInfoModalClose = () => {
    this.setState({ showMoreInfoModal: !this.state.showMoreInfoModal });
  };

  onBillIdClickHandler = item => {
    let mcnsZipObj = this.props.data.filter(zipObj => zipObj.id == item.id);
    let mcnsZipCode = mcnsZipObj[0].chr_svczip;

    this.setState({
      mcnsZipCodeState: mcnsZipCode,
    });

    this.setState({
      modalItem: item,
      claimNumber: item.claimNumber,
      billId: item.id,
      claimant: item.claimant,
      siteId: item.siteId,
      formattedDOIDate: item.formattedDOIDate,
      paycodeId: item.paycodeId,
      enablePaycode: item.enablePaycode,
      juris: item.juris,
      //Zebra-520
      isApricus: item.isApricus, // Zebra-520 mshiferaw
      isOmni: item.isOmni, // 159216 - mshiferaw(1.26.23)
      chrCode: item.chrCode,
      allowMultiDenialReason: item.allowMultiDenialReason,
      isVanliner: item.isVanliner,
      isNationalInterstateAuto: item.isNationalInterstateAuto,
    });

    this.billIdtoggle();
  };

  billIdtoggle = () => {
    this.setState(prevState => ({
      billIdmodal: !prevState.billIdmodal,
    }));
  };

  onClickBillIdHoverHandle(item) {
    const notes = item.notes || '';

    const reason = () => {
      if (notes !== '' && notes !== ' ') {
        return notes.split('[]**').join('\r\n');
      }

      if (item.claimPaidMoreThanReserve) {
        return `The claim paid amount is greater than the current claim reserve (${item.claimPaidMoreThanReserve ||
          ''}).`;
      }

      if (item.claimClosedVoidDenied) {
        return 'Claim is Closed, Void, or Denied.';
      }

      if (item.hcnMpnPpo) {
        return 'This HCN / MPN claim is not pended for network discount.';
      }
    };

    this.setState({
      billIdHoverContent: reason(),
    });

    this.toggle();
  }

  onClaimIdClickHandler = item => {
    const { profile, userId } = this.props;
    const requestBody = {
      claimNumber: item.claimNumber,
      billScan: '',
      end: '',
      client: '',
      state: '',
      billTypes: '',
      billStatus: '',
      processor: '',
      entryDate: '',
      tin: '',
      lastNameOrSsn: '',
      firstName: '',
      siteId: '',
      userId: userId,
      profile: profile,
    };
    this.props.findClaims(requestBody);
    this.claimIdToggle();
  };

  claimIdToggle = () => {
    this.setState(prevState => ({
      claimIdmodal: !prevState.claimIdmodal,
    }));
  };

  onReverseConfirmHandler = () => {
    this.props.onReverseActivity(this.state.modalItem);
    this.reverseModalToggle();
  };

  dataSelected = (dataArray)=> {
    currPageBillsSelected.splice(0,currPageBillsSelected.length);
    dataArray.map(item=> {
      if(item.approvalStatus!=='P' && !(this.state.isPended && item.pended))
      currPageBillsSelected.push(item.id)
    });
    return currPageBillsSelected;
  }

  selectAll = event => {
    event.target.checked
      ? this.props.updateSelectedBills(currPageBillsSelected)
      : this.props.updateSelectedBills([]);
    this.props.handleSelectAllClick(event);
  };

  onChangeHandler = (id, value, chr_code, item, tg) => {
    const target = tg.target;
    const name = target.name;
    const requestParams = {
      pid: id,
      pvalue: value,
      pchr_code: chr_code,
      pitem: item,
      ptg: tg,
    };
    this.setState({ paycodeParms: requestParams });
    if (
      (item.chrCode === '5' || item.chrCode === '15') &&
      item.juris !== 'WC' &&
      item.billState === 'MI'
    ) {
      this.paycodeChangeOpenToggle();
    } else {
      this.submitPaycodeChange(id, value, chr_code, item, tg);
    }
  };

  changePaycodeFromModal = () => {
    this.submitPaycodeChange(
      this.state.paycodeParms.pid,
      this.state.paycodeParms.pvalue,
      this.state.paycodeParms.pchr_code,
      this.state.paycodeParms.pitem,
      this.state.paycodeParms.ptg
    );
    this.paycodeChangeOpenToggle();
  };

  submitPaycodeChange = (id, value, chr_code, item, tg) => {
    const requestBody = {
      billId: item.id,
      listId: value,
      oldPaycode: item.paycodeId,
      newPaycode: chr_code,
      userId: this.props.userId,
      profile: this.props.profile,
    };
    item.paycodeId = value;
    item.chrCode = chr_code;
    this.props.savePaycode(requestBody);
    this.setPaycodeID(value);
  };

  selectItem = (id, event) => {
    const selectedBillItem = R.find(item => item.id === id, this.props.data);
    const groupSelectedBillIds = this.props.data
      .filter(item =>
        selectedByGroup(item, selectedBillItem, this.props.groupBy)
      )
      .map(item => item.id);
    const selectedBillId = [...groupSelectedBillIds, id];
    const selectedBillIds = selectedBillId.filter((c, index) => {
      return selectedBillId.indexOf(c) === index;
    });
    event.target.checked
      ? this.props.updateSelectedBills([
          ...this.props.selectedBills,
          ...selectedBillIds,
        ])
      : this.props.updateSelectedBills(
          this.props.selectedBills.filter(
            value => !selectedBillIds.includes(value)
          )
        );
  };

  unpendItem = (id, event) => {
    const selectedBillItem = R.find(item => item.id === id, this.props.data);
    const groupSelectedBillIds = this.props.data
      .filter(item =>
        selectedByGroup(item, selectedBillItem, this.props.groupBy)
      )
      .map(item => item.id);
    const selectedBillId = [...groupSelectedBillIds, id];
    const selectedBillIds = selectedBillId.filter((c, index) => {
      return selectedBillId.indexOf(c) === index;
    });
    this.props.handleUnpendBillUpdate(event, selectedBillIds);
  };

  setPaycodeID = paycodeId => {
    this.setState({ paycodeId: paycodeId });
  };

  setChrCode = chrCode => {
    this.setState({ chrCode: chrCode });
  };
  renderEorColumn(item) {
    return (
      <Badge
        color="primary"
        className="badge-document-icon"
        onClick={() => this.onEorClickHandler(item)}
      >
        EOR
      </Badge>
    );
  }

  renderMedsColumn(item) {
    const image = item.image || '';
    const location = item.location || '';
    return (
      <Badge
        color="success"
        className="badge-document-icon"
        onClick={() => this.onMedsClickHandler(item)}
      >
        MED
      </Badge>
    );
  }

  renderMoreInfoColumn(item) {
    return (
      <Button
        color="link"
        onClick={() => this.onMoreInfoClick(item)}
        style={{ cursor: 'pointer' }}
      >
        <span aria-hidden>
          {item.enablePaycode ? (
            <FontAwesomeIcon icon={faClipboard} style={{ display: 'block' }} />
          ) : (
            ''
          )}
        </span>
      </Button>
    );
  }

  renderNotesColumn(item) {
    const notes = item.notes || '';
    const eorNotes = item.eorNotes || '';
    return (
      <BillClaimNotesModal
        iconOnly={true}
        recordId={item.id}
        notes={notes}
        eorNotes={eorNotes}
        submitSaveNotes={this.props.saveTaskListNote}
      />
    );
  }

  renderAuHistColumn(item) {
    const fullName = (item.fullName || '').trim();
    const transferDate = item.transferDate || '';
    const transferredBy = item.transferredBy || '';
    return (
      <>
        {fullName !== '' ? (
          <AppTooltip
            text={`This bill was last transferred by ${fullName} on ${formatMMDDYYYY(
              transferDate
            )} by ${transferredBy}`}
            id={`${this.props.name}-auHist-${item.id}`}
          >
            <FontAwesomeIcon icon={faRandom} />
          </AppTooltip>
        ) : (
          'N/A'
        )}
      </>
    );
  }

  getStatus = item => {
    return (
      <>
        <AppTooltip
          text={'Click to View/Edit Status'}
          id={`status-toolTip-${item.int_billid}`}
          placement="bottom"
        >
          <Button
            color="link"
            onClick={() => this.openStatusHistoryModal(item)}
            style={{ color: '#0000FF' }}
          >
            {item.approvalStatus}
          </Button>
        </AppTooltip>
      </>
    );
  };

  renderBADocsColumn(item) {
    return (
      <AppTooltip
        text="View Reductions for the Bill"
        id={`${this.props.name}-bADocs-${item.id}`}
      >
        <FontAwesomeIcon
          className="green-text cursor-hover"
          icon={faDollarSign}
          onClick={() => {
            this.onBADocsClickHandler(item);
          }}
        />
      </AppTooltip>
    );
  }

  renderOdgColumn(item) {
    return (
      <AppTooltip text="ODG Lookup" id={`${this.props.name}-odg-${item.id}`}>
        <FontAwesomeIcon
          className={item.odgFlag + "-text cursor-hover"}
          icon={faExclamationCircle}
          onClick={() => {
            this.onOdgClickHandler(item);
          }}
        />
      </AppTooltip>
    );
  }

  renderSelectActionColumn(item) {
    return (
      <div className="select-action">
        {item.pended && this.state.isPended ? (
          <>
            <Button color="primary" onClick={() => this.unpendOnClick(item)}>
              Unpend
            </Button>
            <CustomInput
            id={`${this.props.name}-unpend-${item.id}`}
            name={`${this.props.name}-unpend-${item.id}`}
            type="checkbox"
            checked={this.props.unpendedBills.includes(item.id)}
            onChange={event => {
              this.unpendItem(item.id, event);
            }}
            />
          </>
        ) : item.approvalStatus !== 'P' ? (
          <CustomInput
            id={`${this.props.name}-select-${item.id}`}
            name={`${this.props.name}-select-${item.id}`}
            type="checkbox"
            checked={this.props.selectedBills.includes(item.id)}
            onChange={event => {
              this.selectItem(item.id, event);
            }}
          />
        ) : (
          <p style={{ color: 'red' }}>Pending</p>
        )}
      </div>
    );
  }

  renderBillIdColumn(item) {
    const adjId = item.adjId || 0;
    const billType = item.billtype || '';
    const notes = item.notes || '';
    const claimCheck = item.isCCDLSPI || '';
    var className = '';
    const reason = () => {
      if (notes !== '' && notes !== ' ') {
        return notes.split('[]**').join('\r\n');
      }

      if (item.claimPaidMoreThanReserve) {
        return `The claim paid amount is greater than the current claim reserve (${item.claimPaidMoreThanReserve ||
          ''}).`;
      }

      if (item.claimClosedVoidDenied) {
        return 'Claim is Closed, Void, or Denied.';
      }

      if (item.hcnMpnPpo) {
        return 'This HCN / MPN claim is not pended for network discount.';
      }
    };

    if (this.props.name !== 'recentActivity') {
      className = 'button-link';
    }

    return (
      <>
        <span
          className={className}
          onClick={() => {
            this.onBillIdClickHandler(item);
          }}
        >
          {item.id}
        </span>
        {notes !== '' && notes !== ' ' && (
          <AppTooltip text={reason()} id={`${this.props.name}-idNotes-${item.id}`}>
            <FontAwesomeIcon
              className="cursor-hover"
              style={{ marginLeft: 5 }}
              icon={faExclamationCircle}
              onClick={() => {
                this.onClickBillIdHoverHandle(item);
              }}
            />
          </AppTooltip>
        )}
        {claimCheck !== '' && claimCheck !== ' ' && (
          <AppTooltip text={'Claim is either closed, controverted, denied, litigated, settled, pending or under investigation'} id={`${this.props.name}-idClaim-${item.id}`}>
            <FontAwesomeIcon
              className="cursor-hover"
              style={{ marginLeft: 5, color: 'red' }}
              icon={faTimesCircle}
            />
          </AppTooltip>
        )}
        {item.approvalStatus === 'P' && (
          <AppTooltip
            text={'Pending Status'}
            id={`${this.props.name}-idApproval-${item.id}`}
          >
            <FontAwesomeIcon
              className="cursor-hover"
              style={{ marginLeft: 5, color: 'red' }}
              icon={faQuestionCircle}
            />
          </AppTooltip>
        )}
        {adjId !== 0 && (
          <span className="slate-500-text italic-text">
            {adjId}
            <br />
          </span>
        )}
        {(this.props.reviewType === nurseAuditsType ||
          this.props.reviewType === codeReviewsType) && (
          <span className="grey-text italic-text">{billType}</span>
        )}

        {/* <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>BILL ALERT</ModalHeader>
          <ModalBody>
            {reason()}
          </ModalBody>
        </Modal > */}
      </>
    );
  }

  renderClaimantColumn(item) {
    const claimant = item.claimant || '';
    const formattedDOIDate = item.formattedDOIDate || '';
    return (
      <>
        <span>{claimant}</span>
        <br />
        <span className="grey-text italic-text">{formattedDOIDate}</span>
      </>
    );
  }

  renderClaimColumn(item) {
    const adjuster = () => {
      const adjusterFirst = item.adjusterFirst || '';
      const adjusterLast = item.adjusterLast || '';
      if (adjusterFirst !== '') {
        return `${adjusterLast}, ${adjusterFirst}`;
      }
      return adjusterLast;
    };
    return (
      <>
        <span
          className="button-link"
          onClick={() => {
            this.onClaimIdClickHandler(item);
          }}
        >
          {item.claimNumber}
        </span>
        <br />
        <span className={classnames(this.italicTextClass(item))}>
          {adjuster()}
        </span>
      </>
    );
  }

  getJuris = searchResult => {
    let html = '';
    if (searchResult.juris == 'PI') {
      html = <>PIP</>;
    } else {
      html = <>{searchResult.juris}</>;
    }

    return html;
  };

  //Zebra-520
  getNetwork = searchResult => {
    let html = '';
      if (searchResult.isApricus === 1) {
        html = <>Apricus</>;
      }
      else if (searchResult.isOmni === 1) {
        html = <>OMNI</>
      }
      else {
        html = <>MCNS</>;
      } 
    return html;
  }

  renderDateOfServiceColumn(item) {
    const formattedStartDate = item.formattedStartDate || '';
    const formattedEndDate = item.formattedEndDate || '';
    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    }
    return (
      <span>
        Start: {item.formattedStartDate}
        <br />
        End {item.formattedEndDate}
      </span>
    );
  }

  renderBillingProviderColumn(item) {
    return this.renderProvider(
      {
        providerTin: item.providerTin || '',
        providerName: item.providerName || '',
      },
      item
    );
  }

  renderTreatingProviderColumn(item) {
    return this.renderProvider(
      {
        providerTin: item.treatingProviderTin || '',
        providerName: item.treatingProviderName || '',
      },
      item
    );
  }

  renderProvider(data, item) {
    const providerTin = data.providerTin;
    const providerName = data.providerName;
    const mcnsType = item.MCNS_type || '';
    const tin = () => {
      if (providerTin === '') {
        return 'TIN: None';
      }
      if (mcnsType === '') {
        return `TIN: ${providerTin.substr(0, 2)}-${providerTin.substr(2, 7)}`;
      }
      return `TIN: ${providerTin.substr(0, 2)}-${providerTin.substr(
        2,
        7
      )}  ${mcnsType}`;
    };
    return (
      <>
        {providerName}
        <br />
        <span className={classnames(this.italicTextClass(item))}>
          {tin()}
          <br />
        </span>
      </>
    );
  }
  renderPaycodeColumn(item) {
    const paycode = () => {
      if (item.enablePaycode === 1) {
        if (item.juris === 'WC') {
          return (
            <>
              <Input
                type="select"
                id="paycodeUI"
                value={item.paycodeId}
                onChange={event =>
                  this.onChangeHandler(
                    event.target.key,
                    event.target.value,
                    event.target.childNodes[
                      event.target.selectedIndex
                    ].getAttribute('chrcode'),
                    item,
                    event
                  )
                }
                data={this.props.paycodeList50}

                //style="width:50%"
              >
                <option defaultValue key={-1} value="">
                  {' '}
                </option>
                {this.props.paycodeList50.map((item, index) => (
                  <option
                    key={item.paycodeId}
                    value={item.paycodeId}
                    chrcode={item.chr_code}
                  >
                    {item.description}
                  </option>
                ))}
              </Input>
            </>
          );
        } else if(this.props.profile === "Mba") {
          return (
            <>
              <Input
                type="select"
                value={item.paycodeId}
                onChange={event =>
                  this.onChangeHandler(
                    event.target.key,
                    event.target.value,
                    event.target.childNodes[
                      event.target.selectedIndex
                    ].getAttribute('chrcode'),
                    item,
                    event
                  )
                }
                data={this.props.paycodeList50}
                //style="width:50%"
              >
                <option defaultValue key={-1} value="">
                  {' '}
                </option>
                {this.props.paycodeList51.map((item, index) => (
                  <option
                    key={item.paycodeId}
                    value={item.paycodeId}
                    chrcode={item.chr_code}
                  >
                    {item.description}
                  </option>
                ))}
              </Input>
            </>
          );
        }else{
          return (
            <>
              <Input
                type="select"
                value={item.paycodeId}
                onChange={event =>
                  this.onChangeHandler(
                    event.target.key,
                    event.target.value,
                    event.target.childNodes[
                      event.target.selectedIndex
                    ].getAttribute('chrcode'),
                    item,
                    event
                  )
                }
                data={this.props.paycodeList57}
                //style="width:50%"
              >
                <option defaultValue key={-1} value="">
                  {' '}
                </option>
                {this.filterList57(this.props.paycodeList57, item.isVanliner, item.isNationalInterstateAuto).map((item, index) => (
                  <option
                    key={item.paycodeId}
                    value={item.paycodeId}
                    chrcode={item.chr_code}
                  >
                    {item.description}
                  </option>
                ))}
              </Input>
            </>
          );
        }
      } else {
        return (
          <span className={classnames(this.italicTextClass(item))}>
            <br />
          </span>
        );
      }
    };

    return <>{paycode()}</>;
    /*const allow = () => {
      const formattedPaid = item.formattedPaid || '';
      if (
        formattedPaid === '' ||
        formattedPaid === '0' ||
        formattedPaid === '$0.00'
      ) {
        return <span className="red-text italic-text">A: $0.00</span>;
      }
      return (
        <span className={classnames(this.italicTextClass(item))}>
          A: {formattedPaid}
        </span>
      );
    };*/

    //return <>0</>;
  }

  renderChargeAllowColumn(item) {
    const charge = () => {
      if (item.formattedCharge) {
        return (
          <span className={classnames(this.italicTextClass(item))}>
            C: {item.formattedCharge}
          </span>
        );
      }

      return (
        <span className={classnames(this.italicTextClass(item))}>
          C: 0.00
          <br />
        </span>
      );
    };

    const allow = () => {
      const formattedPaid = item.formattedPaid || '';
      if (
        formattedPaid === '' ||
        formattedPaid === '0' ||
        formattedPaid === '$0.00'
      ) {
        return <span className="red-text italic-text">A: $0.00</span>;
      }
      return (
        <span className={classnames(this.italicTextClass(item))}>
          A: {formattedPaid}
        </span>
      );
    };

    return (
      <>
        {charge()}
        <br />
        {allow()}
      </>
    );
  }

  renderCarrierReceivedColumn(item) {
    const formattedCarrierReceivedDate =
      item.formattedCarrierReceivedDate || '';

    const daysDiff = () => {
      const diff = getDaysFromToday(formattedCarrierReceivedDate);
      if (diff === 0) {
        return <span className="grey-text italic-text">Today</span>;
      }
      if (diff === 1) {
        return <span className="grey-text italic-text">yesterday</span>;
      }
      if (diff <= 15) {
        return <span className="grey-text italic-text">{diff} days ago</span>;
      }
      return (
        <span className="red-text italic-text" style={{ fontWeight: 700 }}>
          {Math.round(diff)} days ago
        </span>
      );
    };
    return (
      <>
        <span>{formattedCarrierReceivedDate}</span>
        <br />
        {daysDiff()}
      </>
    );
  }

  renderSelectActionHeader() {
    return (
      <div className="select-action">
        <Label for="selectAll">Action</Label>
        <CustomInput
          id={`${this.props.name}-selectAll`}
          name={`${this.props.name}-selectAll`}
          type="checkbox"
          checked={
            this.props.selectedBills.length === currPageBillsSelected.length &&
            this.props.data.length !== 0
          }
          onChange={this.selectAll}
        />
      </div>
    );
  }

  renderApproveStatusColumn(item) {
    const approvalStatus = item.approvalStatus || '';
    const formattedCarrierReceivedDate =
      item.formattedCarrierReceivedDate || '';
    const approveText = () => {
      const getApproveStatusItem = () => {
        switch (approvalStatus) {
          case 'A':
            return {
              text: 'Approved',
              colorClass: 'green-text',
            };
          case 'D':
            return {
              text: 'Denied',
              colorClass: 'red-text',
            };
          case 'T':
            return {
              text: 'Transferred',
              colorClass: 'black-text',
            };
          default:
            return {
              text: '',
              colorClass: 'black-text',
            };
        }
      };
      const approveStatusItem = getApproveStatusItem();
      if (item.reversible === true) {
        return (
          <span className={`${approveStatusItem.colorClass} bold-text`}>
            {approveStatusItem.text}
          </span>
        );
      }
      return <span className="grey-text">{approveStatusItem.text}</span>;
    };

    const reversibleIcon = () => {
      if (item.reversible === true) {
        return (
          <FontAwesomeIcon
            onClick={() => {
              this.onReverseClickHandler(item);
            }}
            style={{ marginLeft: 5 }}
            className="blue-text cursor-hover"
            icon={faArrowAltCircleLeft}
          />
        );
      }
      return '';
    };
    let formattedDate = item.approvalStatusDate
      ? moment(item.approvalStatusDate).format('MM/DD/YYYY')
      : '';
    const daysDiff = () => {
      const diff = getDaysFromToday(formattedDate);
      if (diff === -1) {
        return 'Unknown';
      }
      if (diff === 0) {
        return 'Today';
      }
      if (diff === 1) {
        return 'Yesterday';
      }
      if (diff > 15) {
        return <span className="grey-text italic-text">{diff} days ago</span>;
      }

      return `${Math.round(diff)} days ago`;
    };

    return (
      <>
        {approveText()}
        {reversibleIcon()}
        <br />
        <span className="grey-text italic-text">{daysDiff()}</span>
      </>
    );
  }

  italicTextClass(item) {
    const color =
      this.props.name === recentActivity && item.reversible === false
        ? 'grey-text'
        : 'slate-500-text';
    return {
      [color]: true,
      'italic-text': true,
    };
  }

  getColumnDefinition() {
    const settingColumns = this.props.settingColumns;
    const columns = [
      {
        header: columnName.billId,
        field: 'id',
        render: item => this.renderBillIdColumn(item),
      },
      ...(this.props.reviewType === nurseAuditsType
        ? [
            {
              header: columnName.flag,
              field: 'flagDesc',
            },
          ]
        : []),
      {
        header: columnName.claimant,
        field: 'claimant',
        render: item => this.renderClaimantColumn(item),
      },
      {
        header: columnName.claim,
        field: 'claimNumber',
        render: item => this.renderClaimColumn(item),
      },
      {
        header: columnName.client,
        field: 'siteName',
      },
      {
        header: columnName.state,
        field: 'billState',
        sortOrder: false,
      },
      {
        header: columnName.lob,
        field: 'juris',
        render: item => this.getJuris(item),
      },
      //Zebra-520
      {
        header: columnName.network,
        field: 'isApricus',
        render: item => this.getNetwork(item),
      },
      {
        header: columnName.dateOfService,
        field: 'startDate',
        render: item => this.renderDateOfServiceColumn(item),
      },
      // {
      //   header: columnName.baDoc,
      // },
      {
        header: columnName.billingProvider,
        field: 'providerName',
        render: item => this.renderBillingProviderColumn(item),
      },
      {
        header: columnName.treatingProvider,
        field: 'treatingProviderName',
        render: item => this.renderTreatingProviderColumn(item),
      },
      {
        header: columnName.paycode,
        field: 'paycodeId',
        render: item => this.renderPaycodeColumn(item),
      },
      {
        header: columnName.chargeAllow,
        field: 'formattedCharge',
        render: item => this.renderChargeAllowColumn(item),
      },
      {
        header: columnName.carrierReceived,
        field: 'carrierReceivedDate',
        render: item => this.renderCarrierReceivedColumn(item),
        sortOrder: false,
      },
      ...(this.props.name !== recentActivity
        ? [
            {
              header: columnName.eor,
              field: 'eor',
              render: item => this.renderEorColumn(item),
              sortAble: false,
            },
            {
              header: columnName.meds,
              field: 'location',
              render: item => this.renderMedsColumn(item),
              sortAble: false,
            },
            {
              header: columnName.moreInfo,
              field: 'moreInfo',
              render: item => this.renderMoreInfoColumn(item),
              sortAble: false,
            },
            {
              header: columnName.notes,
              field: 'notes',
              render: item => this.renderNotesColumn(item),
              sortAble: false,
            },
          ]
        : []),
      ...(this.props.name === approveBills
        ? [
            {
              header: columnName.auHist,
              field: 'hist',
              render: item => this.renderAuHistColumn(item),
              sortAble: false,
            },
          ]
        : []),
      {
        header: columnName.billType,
        field: 'billType',
      },
      {
        header: columnName.odg,
        field: 'odg',
        render: item => this.renderOdgColumn(item),
        sortAble: false,
      },
      ...(this.props.isSlpUser
        ? [
            {
              header: columnName.baDoc,
              field: 'bADocs',
              render: item => this.renderBADocsColumn(item),
              sortAble: false,
            },
          ]
        : []),
      ...(this.props.name === qaBills
        ? [
            {
              header: columnName.status,
              field: 'approvalStatus',
              sortAble: false,
              render: item => this.getStatus(item),
            },
          ]
        : []),
      ...(this.props.name !== recentActivity
        ? [
            {
              header: 'SelectColumn',
              renderHeader: () => this.renderSelectActionHeader(),
              field: 'selectAction',
              render: item => this.renderSelectActionColumn(item),
              sortAble: false,
            },
          ]
        : []),
      ...(this.props.name === recentActivity
        ? [
            {
              header: 'Activity',
              field: 'formattedApprovalStatusDate',
              render: item => this.renderApproveStatusColumn(item),
              sortAble: false,
            },
          ]
        : []),
    ];

    return columns.filter(col => {
      const condition = R.find(
        val => val.columnName === col.header,
        settingColumns
      );
      if (condition && condition.state === false) {
        return false;
      }
      return true;
    });
  }
  
  filterList57(list57, isVL, isNIS){
    if(isNIS ){
      return list57.filter(paycode => {
        // paycode for National Interstate Insurance and Auto only filter (nisFilter)
        if(paycode.nisFilter === true && (paycode.clientFilter === 0 || paycode.clientFilter === 1)){
          return true;
        }
        return false;
      });
    }
    if(isVL){
      return list57.filter(paycode => {
        if(paycode.clientFilter === 1 || paycode.clientFilter === 2){
          return true;
        }
        return false;
      });
    }
    else{
      return list57.filter(paycode => {
        if(paycode.clientFilter === 0 || paycode.clientFilter === 1){
          return true;
        }
        return false;
      });
    }
  }

  render() {
    const columns = this.getColumnDefinition();
    const tableKey = columns.map(column => column.field).join('-');
    return (
      <>
        <PopupModal
          content={this.state.billIdHoverContent}
          title="BILL ALERT"
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          onClose={this.toggle}
          size="md"
        />
        <PopupModal
          content={
            <span>
              Are you sure you want to change the original value of 5/15?
              Attendant Care policy money cannot be used for this bill unless
              the bill has a paycode of 5 or 15.
            </span>
          }
          title="Confirmation"
          externalToggle={this.paycodeChangeOpenToggle}
          isOpen={this.state.paycodeChangeOpen}
          size="lg"
          footerContent={
            <>
              <Button color="secondary" onClick={this.paycodeChangeOpenToggle}>
                NO
              </Button>
              <Button color="primary" onClick={this.changePaycodeFromModal}>
                YES
              </Button>
            </>
          }
        />
        <OdgLookupModal
          profile={this.props.profile}
          currentUserId={this.props.currentUserId}
          item={this.state.modalItem}
          externalToggle={this.odgModalToggle}
          isOpen={this.state.odgModalOpen}
          onClose={this.odgModalToggle}
          billId={this.state.billId}
          fromOptions={false}
        />
        <PopupModal
          content={<span>Are you sure you wish to reverse this activity?</span>}
          title="Confirmation"
          externalToggle={this.reverseModalToggle}
          isOpen={this.state.reverseModalOpen}
          size="lg"
          footerContent={
            <>
              <Button color="secondary" onClick={this.reverseModalToggle}>
                NO
              </Button>
              <Button color="primary" onClick={this.onReverseConfirmHandler}>
                YES
              </Button>
            </>
          }
        />

        {((this.props.reviewType === nurseAuditsType &&
          this.props.selectedAdjusterName === 'GENERIC LIST') ||
          (this.props.reviewType === mcnsUsersType &&
            this.props.selectedAdjusterName === 'GENERIC LIST') ||
          (this.props.reviewType === nurseAuditsType &&
            this.props.selectedAdjusterName != this.props.userName) ||
          (this.props.reviewType === mcnsUsersType &&
            this.props.selectedAdjusterName != this.props.userName) ||
          this.props.reviewType === codeReviewsType) && (
          <PopupModal
            content={
              <span>
                You cannot edit bills that are not in your worklist. Please
                transfer to your worklist for editing
              </span>
            }
            externalToggle={this.billIdtoggle}
            isOpen={this.state.billIdmodal}
            size="lg"
            footerContent={
              <>
                <Button color="primary" onClick={this.billIdtoggle}>
                  Ok
                </Button>
              </>
            }
          />
        )}
        {(this.props.reviewType === adjustersType ||
          //this.props.reviewType === codeReviewsType ||
          this.props.name === qaBills) && (
          <BillReviewBillsModal
            refresh={this.props.refresh}
            profile={this.props.profile}
            currentUserId={this.props.currentUserId}
            item={this.state.modalItem}
            //externalToggle={this.billIdtoggle}
            isOpen={this.state.billIdmodal}
            onClose={this.billIdtoggle}
            data={this.props.data}
            claimant={this.state.claimant}
            siteId={this.state.siteId}
            billId={this.state.billId}
            claimNumber={this.state.claimNumber}
            date={this.state.formattedDOIDate}
            paycodeList={
              this.state.juris === 'WC'
                ? this.props.paycodeList50
                : this.props.profile === "Mba" 
                ? this.props.paycodeList51
                : this.props.paycodeList57
            }
            enablePaycode={this.state.enablePaycode}
            paycodeId={this.state.paycodeId}
            setPaycodeID={this.setPaycodeID}
            setChrCode={this.setChrCode}
            chrCode={this.state.chrCode}
            allowMultiDenialReason={this.state.allowMultiDenialReason}
            isVanliner={this.state.isVanliner}
            isNationalInterstateAuto={this.isNationalInterstateAuto}
            juris={this.state.juris}
          />
        )}

        {((this.props.reviewType === mcnsUsersType &&
          this.props.selectedAdjusterName == this.props.userName) //||
          /*this.props.reviewType === codeReviewsType*/) && (
          <McnsModal
            refresh={this.props.refresh}
            profile={this.props.profile}
            currentUserId={this.props.currentUserId}
            item={this.state.modalItem}
            //externalToggle={this.billIdtoggle}
            isOpen={this.state.billIdmodal}
            onClose={this.billIdtoggle}
            data={this.props.data}
            claimant={this.state.claimant}
            billId={this.state.billId}
            claimNumber={this.state.claimNumber}
            date={this.state.formattedDOIDate}
            mcnsZipCode={this.state.mcnsZipCodeState}
            setChrCode={this.setChrCode}
            chrCode={this.state.chrCode}
          />
        )}

        {((this.props.reviewType === nurseAuditsType &&
          this.props.selectedAdjusterName == this.props.userName) //||
          /*this.props.reviewType === codeReviewsType*/) && (
          <ModifyBillLinesModal
            refresh={this.props.refresh}
            profile={this.props.profile}
            currentUserId={this.props.currentUserId}
            item={this.state.modalItem}
            //externalToggle={this.billIdtoggle}
            isOpen={this.state.billIdmodal}
            onClose={this.billIdtoggle}
            data={this.props.data}
            claimant={this.state.claimant}
            billId={this.state.billId}
            claimNumber={this.state.claimNumber}
            date={this.state.formattedDOIDate}
            setChrCode={this.setChrCode}
            chrCode={this.state.chrCode}
          />
        )}

        {((this.props.reviewType === codeReviewsType && 
          this.props.selectedAdjusterName == this.props.userName)) && (
          <CodeReviewModal
            refresh={this.props.refresh}
            profile={this.props.profile}
            currentUserId={this.props.currentUserId}
            item={this.state.modalItem}
            //externalToggle={this.billIdtoggle}
            isOpen={this.state.billIdmodal}
            onClose={this.billIdtoggle}
            data={this.props.data}
            claimant={this.state.claimant}
            billId={this.state.billId}
            claimNumber={this.state.claimNumber}
            date={this.state.formattedDOIDate}
            mcnsZipCode={this.state.mcnsZipCodeState}
            setChrCode={this.setChrCode}
            chrCode={this.state.chrCode}
          />
        )}        

        <AppTable
          columns={this.getColumnDefinition()}
          data={this.props.data}
          dataSelected = {this.dataSelected}
          autoPagination={true}
          sortAble={true}
          sortBy={
            this.props.name !== recentActivity
              ? this.props.name === approveBills
                ? 'carrierReceivedDate'
                : 'id'
              : 'billState'
          }
          resizable={true}
          key={`${this.props.name}-${tableKey}`}
        />
        <ClaimResultModal
          isOpen={this.state.claimIdmodal}
          onClose={this.claimIdToggle}
          findResults={this.props.claimInTaskList}
        />
        <StatusDocumentModal
          isOpen={this.state.statusHistoryModal}
          onClose={this.onStatusHistoryHandle}
          statusHistoryData={this.props.statusHistoryData}
          selectedRowObj={this.state.selectedRowObj}
        />

        <MoreInfoModal
          moreInfoData={this.props.moreInfoData}
          showModal={this.state.showMoreInfoModal}
          onClose={this.onMoreInfoModalClose}
          selectedRow={this.state.selectedRow}
        />
      </>
    );
  }
}

TaskListTable.propTypes = {
  data: PropTypes.array.isRequired,
  selectedBills: PropTypes.array,
  reviewType: PropTypes.string,
  refresh: PropTypes.func,
  selectedAdjusterName: PropTypes.string,
  groupBy: PropTypes.string,
  updateSelectedBills: PropTypes.func,
  name: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isSlpUser: PropTypes.bool.isRequired,
  settingColumns: PropTypes.array.isRequired,
  onReverseActivity: PropTypes.func,
  onClose: PropTypes.func,
  userName: PropTypes.string,
  findClaims: PropTypes.func.isRequired,
  savePaycode: PropTypes.func.isRequired,
  claimInTaskList: PropTypes.array.isRequired,
  getStatusHistoryData: PropTypes.func,
  resetStatusHistoryData: PropTypes.func,
  loadMoreInfo: PropTypes.func.isRequired,
  unpendCodeOnBill: PropTypes.func.isRequired,
  moreInfoData: PropTypes.array.isRequired,
  statusHistoryData: PropTypes.array,
  unpendedBills: PropTypes.array,
  handleUnpendBillUpdate: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const findBills = billOperationsSelectors.getFindBills(state);
  const user = authSelectors.getUser(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const billReviewState = billOperationsSelectors.getBillReview(state);

  return {
    selectedAdjusterId: approveBillState.selectedAdjusterId,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
    userName: user.lastname + ', ' + user.firstname,
    searchFields: findBills.searchFields,
    claim: findBills.claim,
    userId: user && user.user_id,
    profile: initialData.currentProfile,
    claimInTaskList: findBills.claimInTaskList,
    statusHistoryData: findBills.statusHistoryData,
    paycodeList51: initialData.paycode51,
    paycodeList50: initialData.paycode50,
    paycodeList57: initialData.paycode57,
    moreInfoData: billReviewState.moreInfoData,
    unpend: findBills.unpendCodeOnBill,
  };
};

const mapDispatchToProps = dispatch => ({
  findClaims: data => dispatch(billOperationsOperations.findClaims(data, true)),
  unpendCodeOnBill: data =>
    dispatch(billOperationsOperations.unpendCodeOnBill(data, true)),
  savePaycode: data =>
    dispatch(billOperationsOperations.savePaycode(data, true)),
  getStatusHistoryData: data =>
    dispatch(billOperationsOperations.getStatusHistoryData(data)),
  resetStatusHistoryData: () =>
    dispatch(billOperationsActions.resetStatusHistoryData()),
  loadMoreInfo: data => dispatch(billOperationsOperations.loadMoreInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskListTable);