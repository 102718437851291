import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Row, Button } from 'reactstrap';

import SearchableSelect from 'shared/components/input/SearchableSelect';
import AppInput from 'shared/components/input/AppInput';
import DropdownMultiSelect from 'shared/components/dropdown-multi-select/dropdown-multi-select';

import { billOperationsSelectors } from '../store';

class AdvancedClaimSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isViewMode: false,
      mockValue: '',
      realValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    const {isClear} = this.props;
    if((prevProps.isClear !== isClear) && isClear) {
      document.getElementById("client").value = '';
     this.setState({ 
      isViewMode: false,
      mockValue: '',
      realValue: ''})
    }

  }

  handelValue = event => {
    const value = event.target.value;
    let mockValue = '';
    let noMoreHash = false;
    for (let i = 0; i < value.length; i++) {
      const isString = isNaN(parseInt(value.charAt(i)));
      if (isString) {
        mockValue += value.charAt(i);
        noMoreHash = true;
      } else {
        mockValue += noMoreHash || i >= 5 ? value.charAt(i) : '#';
      }
    }
    this.setState({ mockValue: mockValue, realValue: value });
    this.props.onChangeHandler('lastNameOrSsn', value);
  };

  onViewHandler = () => {
    this.setState({
      isViewMode: !this.state.isViewMode,
    });
  };

  render() {
    const { mockValue, isViewMode, realValue } = this.state;
    return (
      <div id="advancedClaimSearchContainer">
        <Row form>
          <Col lg="3">
            <FormGroup>
              <input type="text" placeholder="Last Name or SSN" value={mockValue} className="form-control" style={{border: "0px"}}/>
              <input
                className={isViewMode ? "show-real": "real-element"}
                type="text"
                value={realValue}
                onChange={this.handelValue}
              />
            </FormGroup>
          </Col>
          <Col lg="1">
            <Button type="button" onClick={this.onViewHandler}>
              {isViewMode ? 'HIDE' : 'VIEW'}
            </Button>
          </Col>
          <Col>
            <FormGroup>
              <AppInput
                changeHandler={this.props.onChangeHandler}
                placeholder="First Name"
                id="firstName"
                type="text"
                showToolTip={false}
                value={this.props.searchFields.firstName}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <AppInput
                changeHandler={this.props.onChangeHandler}
                placeholder="Provider TIN"
                id="tin"
                type="text"
                showToolTip={false}
                value={this.props.searchFields.tin}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <SearchableSelect
                data={this.props.clients}
                id="client"
                returnValue="int_sitecode"
                displayOption="chr_sitename"
                placeholder="Client"
                stateHandler={this.props.onChangeHandler}
                filterType="startWith"
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <DropdownMultiSelect
                id="billTypes"
                accessor={{
                  key: 'key',
                  value: 'description',
                }}
                options={this.props.billTypes}
                placeHolder="Bill Type"
                optionColumnHeader="Bill Type"
                changeHandler={this.props.onChangeHandler}
                selected={this.props.searchFields.billTypes === "" ? [] : this.props.searchFields.billTypes}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

AdvancedClaimSearch.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  billTypes: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  isClear: PropTypes.bool.isRequired,
  searchFields: PropTypes.object
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);

  return {
    billTypes: initialData.billTypes,
    clients: initialData.slpsites,
  };
};

export default connect(mapStateToProps)(AdvancedClaimSearch);
