import * as transform from './transform';

export class AuthService {
  #zebraApiClient;

  constructor(zebraApiClient) {
    this.#zebraApiClient = zebraApiClient;
  }

  login = (username, password,systemId) => {
    return this.#zebraApiClient
      .post('/LoginUser', transform.login.toRequestBody(username, password,systemId))
      .then(response => { return transform.login.fromResponseBody(response,username) });
  };

  logout = (username, userId) => {
    return this.#zebraApiClient
      .post('/logout', transform.logout.toRequestBody(username, userId))
      .then(transform.logout.fromResponseBody);
  };

  checkAuthCode = (username, authCode, systemId) => {
    return this.#zebraApiClient
      .post('/checkAuthCode', transform.checkAuthCode.toRequestBody(username, authCode, systemId))
      .then(transform.checkAuthCode.fromResponseBody);
  };

  resendAuthCode = (username) => {
    return this.#zebraApiClient
      .post('/resendAuthCode', transform.resendAuthCode.toRequestBody(username))
      .then(transform.resendAuthCode.fromResponseBody);
  };

  resetPassword = (username,userId) => {
    return this.#zebraApiClient
      .post('/resetPassword', transform.resetPassword.toRequestBody(username,userId))
      .then(transform.resetPassword.fromResponseBody);
  };

  changePassword = (username, oldPassword, newPassword) => {
    return this.#zebraApiClient
      .post('/changePassword', transform.changePassword.toRequestBody(username, oldPassword, newPassword))
      .then(transform.changePassword.fromResponseBody);
  };

  loadSecurityQuestions = (userId) => {
    return this.#zebraApiClient
      .post('/loadSecurityQuestions', transform.loadSecurityQuestions.toRequestBody(userId))
      .then(transform.loadSecurityQuestions.fromResponseBody);
  };

  saveSecurityQuestions = (answer, questionId, userId) => {
    return this.#zebraApiClient
      .post('/saveSecurityQuestions', transform.saveSecurityQuestions.toRequestBody(answer, questionId, userId))
      .then(transform.saveSecurityQuestions.fromResponseBody);
  };

  checkSecurityQuestions = (answer, questionId, userId) => {
    return this.#zebraApiClient
      .post('/checkSecurityQuestions', transform.checkSecurityQuestions.toRequestBody(answer, questionId, userId))
      .then(transform.checkSecurityQuestions.fromResponseBody);
  };

  zebraTimeoutSeconds = () => {
    return this.#zebraApiClient
      .get('/ZebraTimeoutSeconds', transform.zebraTimeoutSeconds)
      .then(transform.zebraTimeoutSeconds.fromResponseBody);
  };
}
