import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Input, Label, Form, Button } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import AnswerEmptyModal from './AnswerEmptyModal'

const SecurityQuestionForm = () => {
  const { checkSecurityQuestions, currentQuestion, currentQuestionId, securityQuestionResult, userId } = useAuth();
  const [answer, setAnswer] = useState('');
  const [showAnswerEmptyModal, setShowAnswerEmptyModal] = useState(false);

  const isAnswerEmpty = () => {
    if (answer.length === 0) {
      setShowAnswerEmptyModal(!showAnswerEmptyModal)
      return true;
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    if (securityQuestionResult === false) {
      setShowAnswerEmptyModal(!showAnswerEmptyModal);
    }
  }, [securityQuestionResult]);

  return (
    <>
      <Form
        onSubmit={async e => {
          e.preventDefault();
          {
          isAnswerEmpty() === false &&
            await checkSecurityQuestions(answer, currentQuestionId, userId);
            (securityQuestionResult === false && setShowAnswerEmptyModal(true));
          }
        }}
        className="york-form reset-password-form"
      >
        <Col lg="12">
          <FormGroup className="group full">
            <Label for="username">Security Question</Label>
            <Input
              type="text"
              name="question"
              id="question"
              value={currentQuestion}
              style={{ width: '400px'}}
              readOnly
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="group full">
            <Label for="username">Answer</Label>
            <Input
              type="password"
              name="answer"
              id="answer"
              autoFocus
              style={{ width: '400px'}}
              onChange={e => setAnswer(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
        <span style={{fontStyle: 'italic'}}><b>Answers are not retrievable by application support.</b></span>
        <br/>
        <span style={{fontStyle: 'italic'}}><b>Please contact the help desk to request a reset of these questions.</b></span>
        </Col>
        <br/>
        <Col lg="12">
          <Row className="action">
            <Col lg="6" xs="6">
              <button className="link-underline orange" type="submit">
                Continue
            </button>
            </Col>
          </Row>
        </Col>
      </Form>
      <AnswerEmptyModal isOpen={showAnswerEmptyModal} onToggle={() => { setShowAnswerEmptyModal(!showAnswerEmptyModal) }} />
    </>
  );
};

export default SecurityQuestionForm;
