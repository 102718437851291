import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

class MoreInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.showModal,
    };
  }

  setLimits = item => {
    return (
      item.value.map(x => (
          this.addBreaks(x)
        ))
    );
  };

  addBreaks = item =>{
    if(item !== null){
      let lines = item.split('\n');
      let linesHTML  = [lines[0], <br/>];
      if(lines.length > 1){
        for(var i = 1; i < lines.length; i++){
          linesHTML.push(lines[i]);
          linesHTML.push(<br/>);
        }
      }
      return linesHTML;
    }
    return item;
  }

  render() {
    return (
      <li>
        <Modal
          isOpen={this.props.showModal}
          toggle={this.props.onClose}
          size="lg"
        >
          <ModalHeader toggle={this.props.onClose}>Additional Claim Information</ModalHeader>
          <ModalBody>
            <span>Bill ID: {this.props.selectedRow != undefined ? (this.props.selectedRow.id != undefined ? this.props.selectedRow.id : this.props.selectedRow.int_billid) : this.props.billId}</span>
            <span style={{ marginLeft: '360px' }}>Claim #: {this.props.selectedRow != undefined ? (this.props.selectedRow.claimNumber != undefined ? this.props.selectedRow.claimNumber : this.props.claimNumber) : this.props.claimNumber} </span>
            {<span>
              <AppTable
                size="sm"
                columns={[
                  {
                    header: '',
                    field: 'label',
                    percent: '25',
                  },
                  {
                    header: '',
                    field: '',
                    render: item => this.setLimits(item),
                    percent: '65',
                    size: 'sm',
                  },
                ]}
                data={this.props.moreInfoData != null ? this.props.moreInfoData : []}
              ></AppTable>
            </span>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.onClose}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </li>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};
export default connect(mapStateToProps)(MoreInfoModal);
