import React from 'react';
import { Row, Col } from 'reactstrap';

import ClientMaintenanceLeft from './ClientMaintenanceLeft';
import ClientMaintenanceRight from './ClientMaintenanceRight';

const ClientMaintenance = () => (
  <Row>
    <Col lg="3" id="clientMaintenanceLeft">
      <ClientMaintenanceLeft />
    </Col>
    <Col lg={{ size: '9' }} id="clientMaintenanceRight">
      <ClientMaintenanceRight />
    </Col>
  </Row>
);

export default ClientMaintenance;
