import { ServiceExecutor } from 'core/services/serviceExecutor';

import expertInfoService from '../services/expertInfoService';

import actions from './actions';

const initData = userId => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertInfoService.getInitialData(userId))
      .withResponseHandler(data => {
        dispatch(actions.loadInitialData(data));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const saveExpertGroup = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertInfoService.saveExpertGroup(data))
      .withResponseHandler(() => {
        dispatch(
          loadExpertGroup({
            expertId: data.id,
          })
        );
      })
      .withSuccessAlert('Save successfully')
      .withFailureAlert('An error occurred while saving group.')
      .build()
      .execute();
  };
};

const saveExpert = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertInfoService.saveExpert(data))
      .withResponseHandler(() => {
        dispatch(
          loadExpert({
            expertId: data.id,
          })
        );
      })
      .withSuccessAlert('Save successfully')
      .withFailureAlert('An error occurred while saving expert.')
      .build()
      .execute();
  };
};

const loadExpert = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertInfoService.loadExpert(data))
      .withResponseHandler(data => {
        dispatch(actions.updateExpertData(data));
      })
      .withFailureAlert('An error occurred while loading expert data.')
      .build()
      .execute();
  };
};

const loadExpertGroup = data => {
  return dispatch => {
    ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => expertInfoService.loadExpertGroup(data))
      .withResponseHandler(data => {
        dispatch(actions.updateGroupData(data));
      })
      .withFailureAlert('An error occurred while loading expert group data.')
      .build()
      .execute();
  };
};

export default {
  initData,
  saveExpertGroup,
  saveExpert,
  loadExpert,
  loadExpertGroup,
};
