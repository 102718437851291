import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

import AddressRenderer from './AddressRenderer';
import CityStateZipRenderer from './CityStateZipRenderer';

export default class ExpertRadiusTable extends Component {
  getDistanceMilesColumn(item) {
    const distance = item.distance || 0;
    return `${Number(distance.toFixed(1))} Miles`;
  }

  getExpertNameSpecialtiesColumn(item) {
    const isGroup = item.isgroup || '0';
    const groupName = item.groupname || '';
    const fullName = item.fullname || '';
    const specialties = item.specialties || '';
    const specialtiesHtml = specialties.split(',').map((specialty, index) => (
      <span className="grey-text italic-text" key={index}>
        {specialty}
        <br></br>
      </span>
    ));
    return (
      <div>
        <div style={{ float: 'left' }}>
          {isGroup === '1' ? (
            <FontAwesomeIcon className="green-text" icon={faUsers} />
          ) : (
            <FontAwesomeIcon className="green-text" icon={faUser} />
          )}
        </div>
        <div style={{ float: 'left', marginLeft: 5 }}>
          <span>
            {isGroup === '1' ? groupName : fullName}
            <br></br>
          </span>
          {specialtiesHtml}
        </div>
      </div>
    );
  }

  getAddressColumn(item, index) {
    return <AddressRenderer item={item} index={index} renderPhoneFax={true} />;
  }

  getCityStateZipColumn(item) {
    return <CityStateZipRenderer item={item} />;
  }

  getServiceColumn(item) {
    const services = item.services || '';
    const dpt = item.dpt || '';
    return (
      <>
        <span className="bold-text">{services}</span>
        <br></br>
        <span className="bold-text">{dpt}</span>
        <br></br>
      </>
    );
  }

  getNetworksColumn(item) {
    const networks = item.networks || '';
    return networks
      .split(',')
      .map((network, index) => <span key={index}>{network}</span>);
  }

  getExpertActivityIconColumn(item) {
    return (
      <FontAwesomeIcon
        className="cursor-hover"
        icon={faExclamationCircle}
        onClick={() => {
          this.onExpertActivityIconHandler(item);
        }}
      />
    );
  }

  onExpertActivityIconHandler = item => {
    console.log(item); // TODO
  };

  render() {
    return (
      <AppTable
        resizable
        columns={[
          {
            header: 'Distance',
            field: 'distance',
            render: this.getDistanceMilesColumn,
          },
          {
            header: 'Expert Name / Specialties',
            field: 'fullname',
            render: this.getExpertNameSpecialtiesColumn,
          },
          {
            header: 'Address',
            field: 'groupname',
            render: this.getAddressColumn,
          },
          {
            header: 'City State Zip',
            field: 'city',
            render: this.getCityStateZipColumn,
          },
          {
            header: 'Services / DPT',
            field: 'services',
            render: this.getServiceColumn,
          },
          {
            header: 'Networks',
            field: 'networks',
            render: this.getNetworksColumn,
          },
          {
            header: '',
            field: 'expertActivityIcon',
            sortAble: false,
            render: item => this.getExpertActivityIconColumn(item),
          },
        ]}
        data={this.props.data}
        autoPagination={true}
        sortAble={true}
        rowNumberDescription={number => `${number} experts found`}
      />
    );
  }
}

ExpertRadiusTable.propTypes = {
  data: PropTypes.array.isRequired,
};
