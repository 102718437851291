import * as R from 'ramda';

// Compiling all the "safest" type-checking methods I've found, for easy reuse

export const isArray = value => Array.isArray(value);

export const isEmpty = value => R.isEmpty(value);

export const isError = value => R.is(Error, value);

export const isFunction = value => typeof value === 'function';

export const isIdentity = value => value === R.identity;

// Note: divide-by-zero results in Infinity
export const isInfinity = value => value === Infinity;

export const isInteger = value => Number.isInteger(value);

export const isNaN = value => Number.isNaN(value);

// Null or Undefined
export const isNil = value => R.isNil(value);

export const isNull = value => value === null;

export const isNilOrEmpty = value => R.isNil(value) || R.isEmpty(value);

// Only returns true for *actual* numbers. Numeric strings return false.
export const isNumeric = value =>
  !Number.isNaN(parseFloat(value)) && Number.isFinite(value);

export const isObject = value => R.is(Object, value);

export const isSafeInteger = value => Number.isSafeInteger(value);

export const isUndefined = value => typeof value === 'undefined';

export const valueOrDefault = (value, fallback) =>
  isNilOrEmpty(value) ? fallback : value;
