import React, { useCallback, useEffect, useState, useContext } from 'react';
import moment from 'moment';

import AppDateInput from 'shared/components/input/AppDateInput';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import DashboardButton from './DashboardButton';
//import VendorSearchModal from './VendorSearchModal';
import VendorLookupModal from './VendorLookupModal';
import AuthHistoryModal from './AuthHistoryModal';
import { fast360Selectors, fast360Operations } from '../store/index';
import { Fast360VendorSearchContext } from '../components/AdvanceSearch/contexts/Fast360VendorSearchContext';
import { connect } from 'react-redux';
import VendorLookupConfirmModal from './VendorLookupConfirmModal';
import _ from 'lodash';
import { formatPhoneNumber } from 'shared/utils/numberUtils';
import { authSelectors } from '../../auth/store';

import PopupModal from 'shared/components/PopupModal';
import Fast360VendorLookupModal from './Fast360VendorLookupModal';
import Fast360RentalDetailsModal from '../Fast360RentalDetailsModal';

const fromEmails = [
  {
    name: 'Interpretation',
    email: 'Interpretation@careworks.com'
  },
  {
    name: 'Transportation',
    email: 'Transportation@careworks.com'
  },
];
const Fast360Vendor = ({
  disableSave,
  userId,
  lmsUserId,
  currentWorkList,
  loadReferralList,
  vendorLookupSuggestions,
  claimantData,
  serviceType,
  selectedSubService,
  loadWorkList,
  loadReferralWorkList,
  fromWorklist,
  selectedItem,
  currentUserId,
  getAuthService,
  claimantAuthData,
  getAuthServiceCall,
  gpIntegrationList,
  updateLoadFromWorkList,
  getFinalizeAssignmentEmailBody,
  finalizeEmailAssignmentData,
  vendorData,
  fetchConfirmWithContactList,
  confirmWithContactList,
  sendFinalizeAssignmentEmail,
  fromWorkList,
  requiredServiceData,
  loadReferralandVender,
  productCatalog

}) => {
  const [vendorLookupOpen, setVendorLookupOpen] = useState(false);
  const [
    vendorLookupClicked,
    setVendorLookupClicked,
  ] = useState(false);
  const [openAuthHistoryModal, setOpenAuthHistoryModal] = useState(false);
  const [vendorSearchCall, setVendorSearchCall] = useState(false);
  let vendor = selectedSubService?.vendor;

  const [authData, setAuthData] = useState({});
  const [showVendorConfirmationButton, setShowVendorConfirmationButton] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [confirmBodyPreview, setConfirmBodyPreview] = useState(false);
  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] = useState(false);
  const [confirmWithContacts, setConfirmWithContacts] = useState([]);
  const [selectedConfirmContact, setSelectedConfirmContact] = useState({ fname: '', lname: '', email: '' });
  const [confirmEditMode, setConfirmEditMode] = useState(false);
  const [toEmailErrorShow, setToEmailErrorShow] = useState(false);
  const [confirmContactErrorShow, setConfirmContactErrorShow] = useState(false);
  const [showRentalDetailsModal, setShowRentalDetailsModal] = useState(false)
  useEffect(() => {
    if(vendorData?.name) {
      vendor = vendorData;
    }
  },[vendorData])

  const openAuthHistory = () => {
    setOpenAuthHistoryModal(!openAuthHistoryModal);
  };
  const handleVendorLookupClick = () => {
    // if (!vendorLookupClicked) {
    // loadReferralWorkList();
    setVendorLookupClicked(!vendorLookupClicked);
    // }
    setVendorLookupOpen(!vendorLookupOpen);
  }

  useEffect(() => {
    const payload = {
      "functionName": "getAuthFromService",
      "zebraUserId": currentUserId,
      "serviceType": requiredServiceData.module,
      "serviceId": requiredServiceData.serviceId
    }
    if(requiredServiceData.serviceId) {
      getAuthServiceCall(payload);
    }
  }, [requiredServiceData])

  useEffect(() => {
    setAuthData(selectedItem)
  }, [selectedItem]);
  useEffect(() => {
    let data;
    if(Object.keys(getAuthService).length === 0) {
      setAuthData({});
      return;
    }
   
   claimantAuthData && claimantAuthData.map(i => { if (i.id == getAuthService.authId) data = i });
   if(data) {
     setAuthData(data)
   } else {
     setAuthData({})
   }
  }, [claimantAuthData, getAuthService]);

  useEffect(() => {
    if (confirmWithContactList.Contacts) {
      setConfirmWithContacts(confirmWithContactList.Contacts);
    };
  }, [confirmWithContactList]);


  useEffect(() => {
    if (requiredServiceData && (requiredServiceData.module === 'INT' || requiredServiceData.module === 'TRN') && (!showVendorConfirmationButton || vendorData)) {
      if (requiredServiceData.vendorAssignmentEmailSent) {
        setShowVendorConfirmationButton(true);
        if (!vendorData) return;
        const req = {
          functionName: "loadContacts",
          userId: userId,
          vendorId: vendorData.id,
        }
        fetchConfirmWithContactList(req);
      }
    }
  }, [currentWorkList.ServiceType, setShowVendorConfirmationButton, showVendorConfirmationButton, vendorData]);

  useEffect(() => {
    if (finalizeEmailAssignmentData && finalizeEmailAssignmentData.length > 0) {
      setEmailSubject(finalizeEmailAssignmentData[0].chr_subject);
      setEmailContent(finalizeEmailAssignmentData[0].chr_body);
    }
  }, [finalizeEmailAssignmentData]);

  const showVendorConfirmationModal = () => {
    const categories = gpIntegrationList.productList.map(list => list.name).filter(category => category === 'Interpretation' || category === 'Transportation');
    let servicesMap = {'INT': 'Interpretation', 'TRN': 'Transportation'}
    const currentCategory = servicesMap[requiredServiceData.module];
    const result = currentCategory && categories.includes(currentCategory);
    if (result) {
      let email = '';
      let toEmail = '';
      let subject = '';

      // const primaryMail = vendor.primaryContact && vendor.primaryContact.people ? vendor.primaryContact.people.email1 : '';
      const referrerEmail = fromWorkList && fromWorkList.referrer ? fromWorkList.referrer.people.email1 : '';
      const adjusterEmail = fromWorkList && fromWorkList.adjuster ? fromWorkList.adjuster.people.email1 : '';
      if (referrerEmail && referrerEmail !== '' && (referrerEmail.trim().toUpperCase() !== 'NA' && referrerEmail.trim().toUpperCase() !== 'N')) {
        toEmail = referrerEmail + ', ';
      }
      if (adjusterEmail && adjusterEmail !== '' && (adjusterEmail.trim().toUpperCase() !== 'NA' && adjusterEmail.trim().toUpperCase() !== 'N')) {
        toEmail += adjusterEmail;
      }
      setVendorEmail(toEmail);

      if (currentCategory === "Interpretation") {
        email = fromEmails.find(email => email.name === "Interpretation").email;
        setFromEmail(email);
      } else if (currentCategory === 'Transportation') {
        email = fromEmails.find(email => email.name === "Transportation").email;
        setFromEmail(email);
      }
      const serviceDate = new Date(requiredServiceData.dos);
      const day = serviceDate.getDate();
      const month = serviceDate.getMonth() + 1;
      const fullYear = serviceDate.getFullYear();
      let peopleObj = updateLoadFromWorkList?.claim?.claimant?.people || {};
      subject = `${currentCategory} Service Confirmation(s) for ${peopleObj.last} ${peopleObj.first} (${updateLoadFromWorkList?.claim?.claimNo}) ${month}/${day}/${fullYear}`;
      setEmailSubject(subject);
    }
    setEmailContent('');
    setConfirmationModalOpen(true);
  }

  const sendConfirmationEmail = () => {
    if (vendorEmail.trim() === "") {
      setToEmailErrorShow(true);
      return;
    }

    if (selectedConfirmContact.fname === "" || selectedConfirmContact.lname === "" || selectedConfirmContact.email === "") {
      setConfirmContactErrorShow(true);
      return;
    }
    const vendorEmailArray = vendorEmail.split(',');
    const req = {
      functionName: "sendEmail",
      userId: userId,
      emailFrom: fromEmail,
      emailTo: [...vendorEmailArray],
      subject: emailSubject,
      body: emailContent
    };
    sendFinalizeAssignmentEmail(req);
    setConfirmationModalOpen(false);
    setEmailConfirmationModalOpen(true);
  }

  const getEmailConfirmationBody = () => {
    return (
      <div className="my-2 d-flex justify-content-center">
        <span>Email Sent Successfully.</span>
      </div>
    )
  }

  const getEmailConfirmationFooter = () => {
    return (
      <div className="d-flex flex-row justify-content-center w-100 m-0">
        <Button
          color="primary"
          className="px-5"
          onClick={() => {
            setEmailConfirmationModalOpen(false);
            setConfirmContactErrorShow(false);
            setToEmailErrorShow(false);
          }}
        >
          OK
        </Button>
      </div>
    );
  };


  const getEmailBody = () => {

    return (
      <div style={{ minHeight: '300px' }}>
        <div className="d-flex flex-row">
          <div className="flex-fill pr-5">
            <div class="d-flex flex-row align-items-center">
              <div className="form-group w-100 d-flex">
                <label style={{ minWidth: '75px' }} className="col-form-label" for="verndor-email">To:</label>
                <input type="text" name="verndor-email" id="verndor-email" onChange={e => setVendorEmail(e.target.value)} className="form-control" value={vendorEmail} />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div className="form-group w-100 d-flex">
                <label style={{ minWidth: '75px' }} className="col-form-label" for="from-email">From:</label>
                <input type="text" name="from-email" id="from-email" onChange={e => setFromEmail(e.target.value)} className="form-control" value={fromEmail} />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div className="form-group w-100 d-flex">
                <label style={{ minWidth: '75px' }} className="col-form-label" for="email-subject">Subject:</label>
                <input type="text" name="email-subject" id="email-subject" onChange={e => setEmailSubject(e.target.value)} className="form-control" value={emailSubject} />
              </div>
            </div>
          </div>

          <div className="flex-fill">

            <div class="d-flex flex-row align-items-center">
              <div className="form-group w-100 d-flex">
                <label style={{ minWidth: '100px' }} className="col-form-label" for="confirmed-with">Confirmed  With:</label>
                <select className="form-control" onChange={handleSelectConfirmContact}>
                  <option selected value="0">New Contact</option>
                  {confirmWithContacts.length > 0 && confirmWithContacts.map((contact, index) => {
                    return <option key={contact.id} value={contact.id}>{contact.name}</option>
                  })}
                </select>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div className="form-group flex-fill d-flex pr-4">
                <label style={{ minWidth: '100px' }} className="col-form-label" for="confirm-with-fn">First Name:</label>
                <input type="text" name="confirm-with-fn" id="confirm-with-fn" disabled={confirmEditMode} className="form-control" onChange={(e) => !confirmEditMode ? setSelectedConfirmContact({ ...selectedConfirmContact, fname: e.target.value }) : null} value={selectedConfirmContact ? selectedConfirmContact.fname : ''} />
              </div>
              <div className="form-group flex-fill d-flex">
                <label style={{ minWidth: '75px' }} className="col-form-label" for="confirm-with-ln">Last Name:</label>
                <input type="text" name="confirm-with-ln" id="confirm-with-ln" disabled={confirmEditMode} className="form-control" onChange={(e) => !confirmEditMode ? setSelectedConfirmContact({ ...selectedConfirmContact, lname: e.target.value }) : null} value={selectedConfirmContact ? selectedConfirmContact.lname : ''} />
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div className="form-group w-100 d-flex">
                <label style={{ minWidth: '100px' }} className="col-form-label" for="from-email">Email:</label>
                <input type="text" name="confirmed-with-email" id="confirmed-with-email" disabled={confirmEditMode} className="form-control" onChange={(e) => !confirmEditMode ? setSelectedConfirmContact({ ...selectedConfirmContact, email: e.target.value }) : null} value={selectedConfirmContact ? selectedConfirmContact.email : ''} />
              </div>
            </div>

          </div>
        </div>
        <div class="d=flex flex-column">
          {confirmBodyPreview ? (
            <div className="form-group w-100 d-flex" dangerouslySetInnerHTML={{ __html: emailContent }}>
            </div>
          ) : (
            <div className="form-group w-100 d-flex">
              <label style={{ minWidth: '75px' }} className="col-form-label" for="email-body">Email Body:</label>
              <textarea rows={10} className="px-3 d-block w-100 form-control" onChange={e => setEmailContent(e.target.value)} value={emailContent} />
            </div>
          )}

        </div>
      </div>
    )
  }

  const handleSelectConfirmContact = (event) => {
    const selectedContactId = parseInt(event.target.value);
    if (selectedContactId !== 0) {
      setConfirmEditMode(true);
    } else {
      setConfirmEditMode(false);
    }
    const selectedCoontact = confirmWithContacts.find(contact => contact.id === selectedContactId);
    setSelectedConfirmContact(selectedCoontact);
  }
  const emailOptionSelectHandler = (event) => {

    if (!(event.target.value == 'vendor-confirmation')) {
      setEmailSubject('');
      setEmailContent('');
      return;
    }

    const req = {
      functionName: "getVendorEmailTemplates",
      userId: userId,
      referralId: requiredServiceData.referralId,
      serviceIds: requiredServiceData.serviceId, // comma seperated multile serivce ids
      refTable: requiredServiceData.module,
      typeId: requiredServiceData.module === 'INT' ? 1 : 2,
      phaseId: 2
    }

    getFinalizeAssignmentEmailBody(req);
  }
  const getMailDialogFooter = () => {
    return (
      <div className="d-flex flex-row justify-content-between w-100">
        <div>
          <select className="form-control" onChange={emailOptionSelectHandler}>
            <option selected>Select Template</option>
            <option value='vendor-confirmation'>Vendor Confirmation</option>
          </select>
        </div>
        <div>
          {/* <Button className="mr-2" color="info" onClick={() => setConfirmBodyPreview(!confirmBodyPreview)}>
            Preview
          </Button> */}
          <Button
            color="primary"
            onClick={sendConfirmationEmail}
            className="mr-2"
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={() => setConfirmationModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };


  
  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="4">
            <Label>
              <b>Vendor</b>{' '}
            </Label>
            <br></br>
            <div>
              <Label>{vendor?.name || 'Vendor Name'}</Label>
              <br></br>
              <Label>
                {vendor?.address && vendor?.address.street1 ? (
                  <span>
                    {vendor?.address.street1}, <br />
                    {vendor?.address.street2}
                  </span>
                ) : (
                  'Vendor Address'
                )}
              </Label>
              <br></br>
              <Label>
                {vendor?.address && vendor?.address.zipCode ? (
                  <span>
                    {vendor?.address.zipCode.city} /{' '}
                    {vendor?.address.zipCode.state} /{' '}
                    {vendor?.address.zipCode.zip}
                  </span>
                ) : (
                  'Vendor city/state/zip'
                )}
              </Label>
              <br></br>
            </div>
            <br></br>
          </Col>
          <Col sm="4">
            <Label>
              <b>Vendor Contact</b>{' '}
            </Label>
            <br></br>
            <div>
              <Label>
                {vendor?.primaryContact && vendor?.primaryContact.people ? (
                  <span>
                    {vendor?.primaryContact.people.first}{' '}
                    {vendor?.primaryContact.people.last}
                  </span>
                ) : (
                  'Vendor Contact Name'
                )}
              </Label>
              <br></br>
              <Label>
                {vendor?.phone
                  ? formatPhoneNumber(vendor?.phone)
                  : 'Vendor Phone'}
              </Label>
              <br></br>
              <Label>
                {vendor?.address && vendor?.address.zipCode ? (
                  <span>{vendor?.address.zipCode.county}</span>
                ) : (
                  ''
                )}
              </Label>
              <br></br>
              <Label>
                {vendor?.primaryContact && vendor?.primaryContact.people ? (
                  <span>{vendor?.primaryContact.people.email1}</span>
                ) : (
                  'Vendor Email'
                )}
              </Label>
            </div>
            <br></br>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                disabled={disableSave || productCatalog}
                onClick={() => {
                  handleVendorLookupClick();
                }}
              >
                Vendor Search
              </Button>
            </FormGroup>
          </Col>
          {requiredServiceData && (requiredServiceData.module === "MOD" || requiredServiceData.module === "MSE") && <Col lg="3">
            <FormGroup>
              <Button type="button" style={{ width: '100%' }} onClick={() => setShowRentalDetailsModal(true)}>
                Rental History
              </Button>
            </FormGroup>
          </Col>}
        </Row>
        {fromWorklist && <Row>
          <br></br>
          <br></br>
          <Col lg="3">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={openAuthHistory}
              >
                Auth History
              </Button>
            </FormGroup>
          </Col>
          {showVendorConfirmationButton && <Col lg="3">
            <FormGroup>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={showVendorConfirmationModal}
              >
                Vendor Confirmation
              </Button>
            </FormGroup>
          </Col>}

        </Row>}
        {fromWorklist && <Row>
          <Col sm="4">
            <Label>Auth Date Start: </Label>
            <AppDateInput
              id="authDateStart"
              value={authData.startDate ? moment(authData.startDate).format('MM/DD/YYYY') : ''}
              disabled
            />
          </Col>
          <Col sm="4">
            <Label>Auth Date End: </Label>
            <AppDateInput
              id="authDateStart"
              value={authData.endDate ? moment(authData.endDate).format('MM/DD/YYYY') : ''}
              disabled
            />
          </Col>
        </Row>}
        {fromWorklist && <Row style={{ marginTop: '10px' }}>
          <Col sm="8">
            <Input type="textarea" name="amenitiesUI" value={authData.notes || ''} readOnly />
          </Col>
        </Row>}
        {vendorLookupClicked && <Fast360VendorLookupModal
          clicked={handleVendorLookupClick}
          setVendorSearchCall={setVendorSearchCall}
          vendorSearchCall={vendorSearchCall}
          isOpen={vendorLookupOpen}
          currentUserId={lmsUserId}
          refId={currentWorkList.FK_referral_id}
          tableName={currentWorkList.chr_reference_table}
          dataObject={currentWorkList}
          refreshCall={loadReferralWorkList}
          selectedSubService={selectedSubService}
          loadReferralandVender={loadReferralandVender}
          setVendorLookupClicked={setVendorLookupClicked}
          vendorLookupClicked={vendorLookupClicked}
          setVendorLookupOpen={setVendorLookupOpen}

        />}
        <AuthHistoryModal
          isOpen={openAuthHistoryModal}
          onClose={() => setOpenAuthHistoryModal(false)}
          serviceID={requiredServiceData.serviceId}
          serviceType={requiredServiceData.module}
          authData={authData}
        />
      </FormGroup>
      {
        showRentalDetailsModal && <Fast360RentalDetailsModal
          isOpen={showRentalDetailsModal}
          onClose={() => setShowRentalDetailsModal(false)}
        />
      }
      <PopupModal
        content={getEmailBody()}
        title="Email/Reporting"
        externalToggle={() => setConfirmationModalOpen(false)}
        size="xl"
        isOpen={confirmationModalOpen}
        footerContent={getMailDialogFooter()}
      />
      <PopupModal
        title=""
        content={getEmailConfirmationBody()}
        isOpen={emailConfirmationModalOpen}
        externalToggle={() => setEmailConfirmationModalOpen(false)}
        footerContent={getEmailConfirmationFooter()}
        size="lg"
      />
      <PopupModal
        title=""
        content={
          <div className="my-2 d-flex justify-content-center">
            <span>Please put in the email to address</span>
          </div>}
        isOpen={toEmailErrorShow}
        externalToggle={() => setToEmailErrorShow(false)}
        footerContent={getEmailConfirmationFooter()}
        size="lg"
      />
      <PopupModal
        title=""
        content={
          <div className="my-2 d-flex justify-content-center">
            <span>You must select an email address for new contact. You must select a first name for new contact. You must select a last name for new contact.</span>
          </div>}
        isOpen={confirmContactErrorShow}
        externalToggle={() => setConfirmContactErrorShow(false)}
        footerContent={getEmailConfirmationFooter()}
        size="lg"
      />
    </>
  );
};

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  const fast360InitialData =
    fast360Selectors.getFast360InitialData(state) &&
    fast360Selectors.getFast360InitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    currentUserId: userId,
    initialworklist:
      fast360InitialData.initialWorkList && fast360InitialData.initialworklist,
    currentWorkList: fast360Info.currentWorkListItem,
    loadReferralList:
      fast360Info.updateLoadFromWorkList.translationList &&
      fast360Info.updateLoadFromWorkList,
    claimantData:
      fast360Info.updateLoadFromWorkList.claim &&
      fast360Info.updateLoadFromWorkList.claim.claimant,
    loadWorkList: fast360Info.updateLoadFromWorkList,
    productCatalog: state.FAST360.fast360Reducer.productCatalog,
    selectedItem: state.FAST360.fast360Reducer.clearAuthService ? state.FAST360.fast360Reducer.clearAuthService : {},
    claimantAuthData:
      fast360Info.updateLoadFromWorkList.claim &&
      fast360Info.updateLoadFromWorkList.claim.claimAuths,
    getAuthService: state.FAST360.fast360Reducer.getAuthService ? state.FAST360.fast360Reducer.getAuthService : {},
    gpIntegrationList: fast360InitialData.gpIntegrationList,
    updateLoadFromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    finalizeEmailAssignmentData: fast360Info.finalizeEmailAssignmentData,
    vendorData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData.vendor : {},
    confirmWithContactList: state.FAST360.fast360Reducer.confirmWithContactList ? state.FAST360.fast360Reducer.confirmWithContactList : {},
    fromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList ? state.FAST360.fast360Reducer.updateLoadFromWorkList : {},
    requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData : {},
  };
};

const mapDispatchToProps = dispatch => ({
  vendorLookupSuggestions: data =>
    dispatch(fast360Operations.vendorLookupSuggestions(data)),
  getAuthServiceCall: data =>
    dispatch(fast360Operations.getAuthService(data)),
  getFinalizeAssignmentEmailBody: data => dispatch(fast360Operations.getFinalizeAssignmentEmailBody(data)),
  fetchConfirmWithContactList: data => dispatch(fast360Operations.fetchConfirmWithContactList(data)),
  sendFinalizeAssignmentEmail: data => dispatch(fast360Operations.sendFinalizeAssignmentEmail(data)),


});
export default connect(mapStateToProps, mapDispatchToProps)(Fast360Vendor);
