import * as R from 'ramda';
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';

const Fast360VendorHistoryModal = ({ isOpen, onClose, onHide, vendorHistoryData }) => {
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader toggle={onClose}>Vendor Lookup</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Col lg={{ size: 3, offset: 9 }} align="right">
              <Button type="button" onClick={() => onHide()}>Vendor Assignment</Button>
            </Col>
          </FormGroup>
        </Form>
        <AppTable
          columns={[
            {
              header: 'Conf No',
              field: 'confNo',
              percent: '16',
            },
            {
              header: 'DOS',
              field: 'dos',
              percent: '16',
            },
            {
              header: 'Description',
              field: 'feetypedescription',
              percent: '16',
            },
            {
              header: 'Price',
              field: 'cost',
              percent: '16',
            },
          ]}
          data={vendorHistoryData}
          autoPagination
          sortAble
          resizable
          sortImmediately={false}
          key="vendor-history"
        />
        <Row>
          <Col align="right">
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Fast360VendorHistoryModal;
