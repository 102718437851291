import React, { useEffect, useState } from 'react';
import TreeMenu from 'react-simple-tree-menu';
import '../../../../../node_modules/react-simple-tree-menu/dist/main.css';
import { connect } from 'react-redux';
import {
  billMaintenanceOperations,
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../store';
import { authSelectors } from 'modules/auth/store/index';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';

const DEFAULT_PADDING = 16;
const ICON_SIZE = 8;
const LEVEL_SPACE = 16;

const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? '-' : '+'}</span>;

const SiteTreeModal = ({
  isOpen,
  onClose,
  siteId,
  zebraUserId,
  loadSiteTree,
  loadSiteDetails,
  updateSiteData,
  profile,
  resetSiteTree
}) => {
  const [treeData, setTreeData] = useState([]);
  const toggleModal = () => {
    onClose();
    resetSiteTree();
  };

  useEffect(() => {
    if (isOpen) {
      if (siteId !== null) {
        const params = {
          profile: profile,
          siteName: siteId,
          zebraUserId: zebraUserId
        }
        loadSiteTree(params);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (updateSiteData && updateSiteData.length) {
      setSiteTreeData();
    } else {
      setTreeData([]);
    }
  }, [updateSiteData])

  const setSiteTreeData = () => {
    var treeData = [];
    var count = 1;
    updateSiteData[0].map(function (site) {
      if (treeData.length === 0) {
        var level = updateSiteData[0].length - count;
        if (updateSiteData[1].length > 0) {
          var childNode = [];
          updateSiteData[1].map(function (site) {
            var newNode = { key: updateSiteData[0].length.toString(), label: site.chr_sitename, id: site.int_sitecode };
            childNode.push(newNode);
          });
          treeData = [{ key: level.toString(), label: site.fields.name, id: site.fields.id, nodes: childNode }];
        } else {
          treeData = [{ key: level.toString(), label: site.fields.name, id: site.fields.id }];
        }
        count++;
      } else {
        var level = updateSiteData[0].length - count;
        var oldNode = treeData;
        treeData = [{ key: level.toString(), label: site.fields.name, id: site.fields.id, nodes: oldNode }];
        count++;
      }
    });
    setTreeData(treeData);
  }

  const loadSite = (data) => {
    loadSiteDetails({
      profile,
      siteId: data,
      zebraUserId: zebraUserId,
    });
    toggleModal();
  }

  const printSiteTree = () => {
    debugger;
    var printData = "<table><thead><tr><th><div>Id</id></th><th><div>Site</div></th></tr></thead><tbody>";
    var tableData = "";
    updateSiteData[0].map(function (site) {
      var oldTableData = tableData;
      tableData = "<tr><td>" + site.fields.id + "</td><td>" + site.fields.name + "</td></tr>" + oldTableData;
    });
    printData += tableData + "</tbody></table>";
    var style = "<style>";
    style = style + "table {width: 100%;}";
    style = style + "table,th,td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + " thead {transform: none !important}";
    style = style + "</style>";
    const newWin = window.open("");
    newWin.document.write(style);
    newWin.document.write("<P></P>")
    newWin.document.write(printData);
    newWin.print();
    newWin.close();
  }

  const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    ...props
  }) => (
    <ListGroupItem
      {...props}
      style={{
        paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
        cursor: 'pointer',
        boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
        zIndex: focused ? 999 : 'unset',
        position: 'relative',
      }}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={e => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}
        >
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {label}
    </ListGroupItem>
  );

  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader toggle={toggleModal}>Site Tree</ModalHeader>
      <ModalBody>
        <TreeMenu data={treeData}
          debounceTime={125}
          hasSearch={false}
          initialOpenNodes={["0", "0/1", "0/1/2", "0/1/2/3", "0/1/2/3/4", "0/1/2/3/4/5", "0/1/2/3/4/5/6", "0/1/2/3/4/5/6/7", "0/1/2/3/4/5/6/7/8", "0/1/2/3/4/5/6/7/8/9",
            "0/1/2/3/4/5/6/7/8/9/10", "0/1/2/3/4/5/6/7/8/9/10/11", "0/1/2/3/4/5/6/7/8/9/10/11/12", "0/1/2/3/4/5/6/7/8/9/10/11/12/13", "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14",
            "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14/15", "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14/15/16", "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14/15/16/17",
            "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14/15/16/17/18", "0/1/2/3/4/5/6/7/8/9/10/11/12/13/14/15/16/17/18/19"]} //the tree load is limited to 20 results
          onClickItem={({ key, label, ...props }) => {
            loadSite(props.id);
          }}>
          {({ items }) => (
            <>
              <ListGroup style={{ maxHeight: "500px", overflowY: "scroll" }}>
                {items.map(props => (
                  <ListItem {...props} />
                ))}
              </ListGroup>
            </>
          )}
        </TreeMenu>
        <Row className="float-right">
          <Button type="submit" style={{ marginTop: "10px" }} onClick={printSiteTree}>Print</Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  const clientMaintenanceState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const user = authSelectors.getUser(state);
  return {
    updateSiteData: clientMaintenanceState.updateSiteData,
    zebraUserId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  loadSiteTree: data =>
    dispatch(billMaintenanceOperations.loadSiteTree(data)),
  resetSiteTree: () => dispatch(billMaintenanceActions.resetSiteTree()),
  loadSiteDetails: data =>
    dispatch(billMaintenanceOperations.loadSiteDetails(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteTreeModal);

