import { createSelector } from 'reselect';

import { billReviewSelectors } from '../../bill-review/store';

const getBillMaintenance = createSelector(
  billReviewSelectors.getModule,
  state => state.billMaintenance
);

const getInitialData = createSelector(
  getBillMaintenance,
  state => state.initialData
);

const getClientMaintenance = createSelector(
  getBillMaintenance,
  state => state.clientMaintenance
);
const getClientMaintenanceInitialData = createSelector(
  getClientMaintenance,
  state => state.initialData
);
const getClientMaintenanceSite = createSelector(
  getClientMaintenance,
  state => state.site
);
const getClientMaintenanceCopySiteSettings = createSelector(
  getClientMaintenance,
  state => state.copySiteSettings
);
const getClientMaintenanceClientRates = createSelector(
  getClientMaintenance,
  state => state.clientRates
);
const getClientMaintenanceClientRatesData = createSelector(
  getClientMaintenanceClientRates,
  state => state.data
);
const getClientMaintenanceClientRatesStateFilter = createSelector(
  getClientMaintenanceClientRates,
  state => state.filters.state
);
const getClientMaintenanceClientRatesEffectiveFilter = createSelector(
  getClientMaintenanceClientRates,
  state => state.filters.effective
);
const getClientMaintenanceClientRatesLineOfBusinessFilter = createSelector(
  getClientMaintenanceClientRates,
  state => state.filters.lineOfBusiness
);
const getClientMaintenanceClientRatesFilter = createSelector(
  getClientMaintenanceClientRatesStateFilter,
  getClientMaintenanceClientRatesEffectiveFilter,
  getClientMaintenanceClientRatesLineOfBusinessFilter,
  (state, effective, lineOfBusiness) => {
    return {
      state,
      effective,
      lineOfBusiness,
    };
  }
);

const getClientMaintenanceUAndCSetup = createSelector(
  getClientMaintenance,
  state => state.uAndCSetup
);
const getClientMaintenanceUAndCSetupData = createSelector(
  getClientMaintenanceUAndCSetup,
  state => state.data
);
const getClientMaintenanceUAndCSetupStateFilter = createSelector(
  getClientMaintenanceUAndCSetup,
  state => state.filters.state
);
const getClientMaintenanceUAndCSetupEffectiveFilter = createSelector(
  getClientMaintenanceUAndCSetup,
  state => state.filters.effective
);
const getClientMaintenanceUAndCSetupLineOfBusinessFilter = createSelector(
  getClientMaintenanceUAndCSetup,
  state => state.filters.lineOfBusiness
);
const getClientMaintenanceUAndCSetupFilter = createSelector(
  getClientMaintenanceUAndCSetupStateFilter,
  getClientMaintenanceUAndCSetupEffectiveFilter,
  getClientMaintenanceUAndCSetupLineOfBusinessFilter,
  (state, effective, lineOfBusiness) => {
    return {
      state,
      effective,
      lineOfBusiness,
    };
  }
);

const getClientMaintenancePPOSetup = createSelector(
  getClientMaintenance,
  state => state.pPOSetup
);
const getClientMaintenancePPOSetupData = createSelector(
  getClientMaintenancePPOSetup,
  state => state.data
);
const getClientMaintenancePPOSetupStateFilter = createSelector(
  getClientMaintenancePPOSetup,
  state => state.filters.state
);
const getClientMaintenancePPOSetupEffectiveFilter = createSelector(
  getClientMaintenancePPOSetup,
  state => state.filters.effective
);
const getClientMaintenancePPOSetupLineOfBusinessFilter = createSelector(
  getClientMaintenancePPOSetup,
  state => state.filters.lineOfBusiness
);
const getClientMaintenancePPOSetupFilter = createSelector(
  getClientMaintenancePPOSetupStateFilter,
  getClientMaintenancePPOSetupEffectiveFilter,
  getClientMaintenancePPOSetupLineOfBusinessFilter,
  (state, effective, lineOfBusiness) => {
    return {
      state,
      effective,
      lineOfBusiness,
    };
  }
);

const getClientMaintenanceSettingsList = createSelector(
  getClientMaintenance,
  state => state.settingsList
);

const getGroupMaintenance = createSelector(
  getBillMaintenance,
  state => state.groupMaintenance
);

export default {
  getBillMaintenance,
  getInitialData,
  getGroupMaintenance,
  getClientMaintenance,
  getClientMaintenanceInitialData,
  getClientMaintenanceSite,
  getClientMaintenanceCopySiteSettings,
  getClientMaintenanceClientRates,
  getClientMaintenanceClientRatesData,
  getClientMaintenanceClientRatesFilter,
  getClientMaintenanceUAndCSetup,
  getClientMaintenanceUAndCSetupData,
  getClientMaintenanceUAndCSetupFilter,
  getClientMaintenancePPOSetup,
  getClientMaintenancePPOSetupData,
  getClientMaintenancePPOSetupFilter,
  getClientMaintenanceSettingsList,
};
