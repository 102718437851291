import React, { Component } from 'react';
import { AppTableHeaderPropType } from './AppTablePropTypes';

class UserAccessTableHeader extends Component {
    render() {
        return (
            <thead className={this.props.dark ? 'thead-dark' : ''}>
            <tr>
                {this.props.columns.map((column, index) => {
                    return (<th key={index}>{column.header}</th>)
                })}
            </tr>
            </thead>
        )
    }
}

UserAccessTableHeader.propTypes = AppTableHeaderPropType;

UserAccessTableHeader.defaultProps = {
    dark: true,
};

export default UserAccessTableHeader;