import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { Col, Row, Form, FormGroup, Button, CustomInput } from 'reactstrap';
import { formatMMDDYYYY } from 'shared/utils/shareUtils';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import moment from 'moment';
import AppTable from 'shared/components/table/AppTable';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import Select from 'react-select';
import { assign } from 'lodash';
let checkedItems = [];
const PopulateCashApplication = ({
  isOpen,
  onClose,
  cashAppSiteDropdownData,
  defaultEmail,
  startDateData,
  resetStartDateData,
  userId,
  profile,
  getStartDateData,
  getSiteOptionsData,
  resetSiteOptionsData,
  siteOptionsData,
  alert,
  getCashPopulateData,
}) => {
  const [searchFields, setSearchFields] = useState({
    siteType: 'MASTER',
    endDate: '',
    entryDate: '',
    email: defaultEmail,
    amount: '0.00',
  });

  useEffect(() => {
    if (startDateData.startDate) {
      setSearchFields({
        ...searchFields,
        entryDate: new Date(startDateData.startDate),
      });
    }
  }, [startDateData.startDate]);

  useEffect(() => {
    const params = {
      ...searchFields,
      userId,
      profile,
    };
    if (isOpen) {
      getStartDateData(params);
      getSiteOptionsData(params);
    }
  }, [isOpen, searchFields.siteType]);

  const toggle = () => {
    resetFormData();
    onClose();
  };

  const onChangeHandler = (id, value) => {
    setSearchFields({ ...searchFields, [id]: value });
  };
  const onChangeBillStatusHandler = (id, value) => {
    if (id !== null) {
      let name = 'billStatus'
      setSearchFields({ ...searchFields, [name]: id.int_sitecode });
    } else {
      let name = 'billStatus'
      setSearchFields({ ...searchFields, [name]: '' });

    }

  };

  const siteChangeHandler = (value) => {
    setSearchFields({ ...searchFields, 'siteType': value });
  };
  const resetFormData = () => {
    setSearchFields({
      siteType: 'MASTER',
      email: defaultEmail,
      amount: '0.00',
    });
    resetStartDateData();
    resetSiteOptionsData();
  };

  const getSelectActionColumn = item => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.description}
          name={item.description}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />
      </div>
    );
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked ? [item] : [];
  };

  const onFormSubmit = e => {
    const checkedItemId = checkedItems.length ? checkedItems[0].id : '';
    e.preventDefault();
    const params = {
      checkedItemId,
      ...searchFields,
      userId,
      profile,
    };

    if (searchFields.endDate && searchFields.endDate !== '') {
      params.entryDate = formatMMDDYYYY(params.entryDate);
      params.endDate = formatMMDDYYYY(params.endDate);
      getCashPopulateData(params);
    } else {
      alert('You must provide an end date');
      return;
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen} className="populateCashApp">
        <ModalHeader toggle={toggle}>Populate Cash App</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="site">Site Name</Label>
                  {/* <AppInput
                    changeHandler={onChangeHandler}
                    option={{
                      value: 'int_sitecode',
                      displayValue: 'chr_sitename',
                    }}
                    value={searchFields.siteType}
                    placeholder="Choose..."
                    data={cashAppSiteDropdownData}
                    id="siteType"
                  /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Choose..."
                    onChange={onChangeBillStatusHandler}
                    defaultValue={cashAppSiteDropdownData.filter(option => option.chr_sitename == 'MASTER')}
                    name="billStatus"
                    options={
                      cashAppSiteDropdownData.length
                        ? cashAppSiteDropdownData.map(
                          obj => assign(obj, { label: obj.chr_sitename, value: obj.int_sitecode })
                        )
                        : []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="entryDate">Start Date</Label>
                  <AppDateInput
                    id="entryDate"
                    onChange={value => onChangeHandler('entryDate', value)}
                    selected={searchFields.entryDate}
                    maxDate={moment().toDate()}
                    dateFormat="MM/dd/yyyy"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="endDate">End Date</Label>
                  <AppDateInput
                    id="endDate"
                    onChange={value => onChangeHandler('endDate', value)}
                    selected={searchFields.endDate}
                    maxDate={moment().toDate()}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="amount">Amount</Label>
                  <AppInput
                    changeHandler={onChangeHandler}
                    placeholder="0.00"
                    id="amount"
                    type="text"
                    value={searchFields.amount}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <Input
                    onChange={event =>
                      onChangeHandler('email', event.target.value)
                    }
                    type="email"
                    name="email"
                    id="email"
                    defaultValue={defaultEmail}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Site Options:</div>
                <br />
                <AppTable
                  modalHeight={100}
                  resizable
                  columns={[
                    {
                      header: 'Description',
                      field: 'description',
                    },
                    {
                      header: 'Select',
                      field: '',
                      render: item => getSelectActionColumn(item),
                    },
                  ]}
                  data={siteOptionsData}
                  autoPagination={true}
                />
              </Col>
            </Row>
            <Row className="float-right" style={{ marginRight: '0px' }}>
              <Button type="submit">
                {parseFloat(searchFields.amount) > 0
                  ? 'ADD SPECIAL BILLING'
                  : 'POPULATE CASH APP'}
              </Button>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

PopulateCashApplication.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getStartDateData: PropTypes.func,
  resetStartDateData: PropTypes.func,
  getSiteOptionsData: PropTypes.func,
  resetSiteOptionsData: PropTypes.func,
  startDateData: PropTypes.object,
  alert: PropTypes.func,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  defaultEmail: PropTypes.string,
  siteOptionsData: PropTypes.array,
  getCashPopulateData: PropTypes.func,
  cashAppData: PropTypes.array,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);

  return {
    cashAppSiteDropdownData: billReviewInitialData.slpsites,
    defaultEmail: user && user.email,
    siteOptionsData: billReviewState.siteOptionsData,
    cashAppData: billReviewState.cashAppData,
    startDateData: billReviewState.startDateData,
    userId: user && user.user_id,
    profile: billReviewInitialData.currentProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  getStartDateData: data =>
    dispatch(billOperationsOperations.getStartDateData(data)),
  resetStartDateData: () =>
    dispatch(billOperationsActions.resetStartDateData()),
  getSiteOptionsData: data =>
    dispatch(billOperationsOperations.getSiteOptionsData(data)),
  resetSiteOptionsData: () =>
    dispatch(billOperationsActions.resetSiteOptionsData()),
  getCashPopulateData: data =>
    dispatch(billOperationsOperations.getCashPopulateData(data)),
  resetCashAppData: () => dispatch(billOperationsActions.resetCashAppData()),

  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopulateCashApplication);
