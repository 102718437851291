import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import AppInput from 'shared/components/input/AppInput';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppDateInput from 'shared/components/input/AppDateInput';
import {
  Row,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import DashboardButton from './DashboardButton';
import AppTooltip from 'shared/components/tooltip/AppTooltip';

const Fast360DGN = ({
  injuredBodyPart,
  diagnosticType,
  selectedService,
  claimantAddress,
  zipList,
  getZipList,
  resetZipList,
  userId,
  updatePatientZip,
  patientInfo,
  isFromLanding,
  dataFromWorklist,
  requiredServiceData
}) => {
  const [tab, setTab] = useState('0');
  const [selectedItem, setSelectedItem] = useState(selectedService);
  const [validDos, setValidDos] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);


  useEffect(() => {
    if (selectedService || isFromLanding || !isEmpty(dataFromWorklist)) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.examType,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.examType,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService, isFromLanding, dataFromWorklist]);

  useEffect(() => {
    if (selectedService?.dos ||
      selectedService?.subService ||
      isFromLanding ||
      !isEmpty(dataFromWorklist)) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          modService: selectedService?.subService,
          serviceTypeId: selectedService?.examType,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.examType,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService?.dos,
  selectedService?.subService,
    isFromLanding,
    dataFromWorklist]);

  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.examType = target.value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerBodyPart = (e) => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.bodyPartDesc = name;
    selectedService.bodyPart = target.value;
    setSelectedItem({ selectedService });
  };

  const setMap = {
    dos: setValidDos,
  }

  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }
    setSelectedItem({ selectedService });
    setValidDos(true);

  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
    }

    if (name === 'locationPhone') {
      if (value.length !== 10) {
        selectedService.locationPhone = value;
      } else {
        selectedService.locationPhone = formatPhoneNumber(value);
      }
    }

    if (name === 'locationAddress') {
      selectedService.serviceAddress.locationStreet = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  const onTabRadioChangeHandler = event => {
    selectedService.locationSame = event.target.value;
    if (selectedService.locationSame === '1' && claimantAddress != undefined) {
      selectedService.serviceAddress.locationStreet = claimantAddress.street1
      selectedService.serviceAddress.zipCode.id = claimantAddress.zipCode.id;
      selectedService.serviceAddress.city = claimantAddress.zipCode.city;
      selectedService.serviceAddress.state = claimantAddress.zipCode.state;
      selectedService.serviceAddress.county = claimantAddress.zipCode.county;
      selectedService.serviceAddress.zip = claimantAddress.zipCode.zip;
      selectedService.serviceAddress.zipCodeId = claimantAddress.zipCodeId;
      selectedService.serviceAddress.zipId = claimantAddress.zipCodeId;
      selectedService.serviceAddress.tz = claimantAddress.timezone;
    } else {
      selectedService.locationPhone = '';
      selectedService.serviceAddress.locationStreet = '';
      selectedService.serviceAddress.zipCode.id = '';
      selectedService.serviceAddress.city = '';
      selectedService.serviceAddress.state = '';
      selectedService.serviceAddress.county = '';
      selectedService.serviceAddress.zip = '';
      selectedService.serviceAddress.zipCodeId = '';
      selectedService.serviceAddress.zipId = '';
      selectedService.serviceAddress.tz = '';

    }
    setSelectedItem({ selectedService });
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip != null) {
      selectedService.serviceAddress.zipCode.id = selectedZip.code_id;
      selectedService.serviceAddress.city = selectedZip.chr_city;
      selectedService.serviceAddress.state = selectedZip.chr_state;
      selectedService.serviceAddress.county = selectedZip.chr_county;
      selectedService.serviceAddress.zip = selectedZip.chr_zipcode;
      selectedService.serviceAddress.zipCodeId = selectedZip.code_id;
      selectedService.serviceAddress.zipId = selectedZip.code_id;
      selectedService.serviceAddress.tz = selectedZip.chr_timezone;
      setSelectedItem({ selectedService });
    }
    resetZipList();
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'dos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'dgnsub'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                placeholder="Choose..."
                value={selectedService?.examType || ''}
                className={selectedService?.examType ? '' : "error"}
                id="subService"
              >
                <option value="default">Choose...</option>
                {diagnosticType.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Injured Body Part: </Label>
            <Input
              type="select"
              className="dropdown"
              onChange={e => onChangeHandlerBodyPart(e)}
              placeholder="Choose..."
              value={selectedService?.bodyPart || ''}
              id="bodyPartUI"
            >
              <option value="default">Choose...</option>
              {injuredBodyPart.map((id, index) => (
                <option key={index} value={id.part_id}>
                  {id.value}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        {/* <Row>
          <Col sm="3">
            <Label>Location: </Label>
            <Input
              name="locationAddress"
              onChange={handleInputChange}
              value={selectedService.serviceAddress.locationStreet || ''}
            />
          </Col>
          <Col sm="5">
            <Row>
              <Label>City/State/Zip: </Label>
            </Row>
            <Row>
              <Label>
                {selectedService.serviceAddress.city}{' '}
                {selectedService.serviceAddress.state},{' '}
                {selectedService.serviceAddress.zip}
              </Label>
            </Row>
          </Col>
          <Col sm="5">
            <Label for="locZip">Lookup Zip</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              onChange={(e, actionType) =>
                handleFormFields(e, 'locZip', actionType)
              }
              placeholder=""
              menuPlacement="bottom"
              onKeyDown={e => handleTypeAheadSearch(e)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              menuIsOpen={zipList.length}
              value={selectedService.serviceAddress.zip}
              closeMenuOnSelect={true}
              options={
                zipList.length
                  ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                  : []
              }
              name="locZip"
              id="locZip"
            />
          </Col>
        </Row> */}
        <Row></Row>
      </FormGroup>
    </>
  );
};

Fast360DGN.propTypes = {
  diagnosticType: PropTypes.array,
  injuredBodyPart: PropTypes.array,
  claimantAddress: PropTypes.any,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  var claimantAddress;
  if (fast360Info.updatedClaimInfo.claim != undefined) {
    if (fast360Info.updatedClaimInfo.claim.claimant != undefined) {
      if (fast360Info.updatedClaimInfo.claim.claimant.address != undefined) {
        claimantAddress = fast360Info.updatedClaimInfo.claim.claimant.address
      } else {
        claimantAddress = undefined;
      }
    } else {
      claimantAddress = undefined;
    }
  } else {
    claimantAddress = undefined;
  }
  return {
    claimantAddress: claimantAddress,
    diagnosticType: initialData.diagnosticType,
    injuredBodyPart: initialData.injuredBodyPart,
    zipList: fast360Info.zipList,
    userId: user.id,
    patientInfo: fast360Info.patientZip,
  };
};

const mapDispatchToProps = dispatch => ({
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360DGN);
