import React, { useState, useContext, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import AppDateInput from 'shared/components/input/AppDateInput';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { selectAllSubCategory, UpdateServiceTypes, selectAllMseCategory} from './utils/helper';
import {
  Row,
  Col,
  FormGroup,
  Label,
  TabPane,
  Input,
} from 'reactstrap';
import { AdvSearchContext } from './contexts/AdvSearchContext';
import { CheckBoxContext } from './contexts/CheckBoxContext';

function updateAdvSearchForm(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_AdvSearch_Form',
    payload: { [key]: value },
  });
}

function updateCheckBox(value, key, dispatch) {
  dispatch({
    type: 'UPDATE_CheckBox',
    payload: { [key]: value },
  });
}

function resetCheckbox(dispatch){
  dispatch({
      type:"RESET"
  })
}

function updateServiceTabs(value, dispatch) {
  dispatch({
    type: 'UPDATE_SERVICE',
    payload: value ,
  });
}

const Services = ({ diagnosticType, mseCategory, modificationCategory, getLoadHCPCQuick, loadHCPCLookUp, userId, loadLookupReportAction, audiologyButton, setAudiologyButton, setQueryStr, useStoredData}) => {

  const { state, dispatch } = useContext(AdvSearchContext);
  const { state1, dispatch1 } = useContext(CheckBoxContext);
  const { serviceTypes } = state;

  useEffect(()=> {
    if(!useStoredData){
      selectAllSubCategory(true, diagnosticType, dispatch, 'serviceTypes', 'diagnostics');
    }
  },[diagnosticType]);
  useEffect(()=> {
    if(!useStoredData){
      selectAllMseCategory(true, mseCategory, dispatch, 'serviceTypes', 'mse');
    }
  },[mseCategory]);
  useEffect(()=> {
    if(!useStoredData){
      selectAllSubCategory(true, modificationCategory, dispatch, 'serviceTypes', 'modification');
    }
  },[modificationCategory]);
  return (
    <TabPane tabId="1" style={{ border: '1px solid #dee2e6', padding: '15px' }}>
      <FormGroup tag="fieldset">
        <Row style={{ paddingBottom: '15px' }}>
          <Col lg="auto" xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  checked={audiologyButton === 'audiology' }
                  type="radio"
                  name="radio1"
                  onClick={() => {setAudiologyButton('audiology');resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('AUD', dispatch)}}
                />{' '}
                Audiology
              </Label>
            </FormGroup>
          </Col>
          <Col lg="auto" xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  checked={audiologyButton === 'dental' }
                  type="radio"
                  name="radio1"
                  onClick={() => {setAudiologyButton('dental');resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('DTL', dispatch)}}
                />{' '}
                Dental
              </Label>
            </FormGroup>
          </Col>
          <Col lg="auto" xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  checked={audiologyButton === 'diagnostic' }
                  type="radio"
                  name="radio1"
                  onClick={() => {setAudiologyButton('diagnostic');resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('DGN', dispatch)}}
                />{' '}
                Diagnostic
              </Label>
            </FormGroup>
          </Col>
          <Col lg="auto" xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  checked={audiologyButton === 'mse' }
                  type="radio"
                  name="radio1"
                  onClick={() => {setAudiologyButton('mse');resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('MSE', dispatch)}}
                />{' '}
                MSE
              </Label>
            </FormGroup>
          </Col>
          <Col lg="auto" xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  checked={audiologyButton === 'modification' }
                  type="radio"
                  name="radio1"
                  onClick={() => {setAudiologyButton('modification');resetCheckbox(dispatch1);loadLookupReportAction([]);updateServiceTabs('MOD', dispatch)}}
                />{' '}
                Modification
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {audiologyButton === '' && (
          <FormGroup>
            <div style={{ float: 'left', width: '60%' }}>
              <Row xs={4} md={2}>
                <Col>
                  <FormGroup>
                    <Label style={{ paddingRight: "10px" }}>HCPC:</Label>
                    <AsyncTypeahead
                      filterBy={['chr_code', 'chr_description']}
                      placeholder="Search"
                      id="async-example"
                      isLoading={false}
                      labelKey={option => `${option.chr_code}-${option.chr_description}`}
                      minLength={1}
                      onSearch={(query) => getLoadHCPCQuick(query, userId)}
                      onChange={(e) => {
                        if(e && e.length > 0){
                            setQueryStr(e[0].chr_description);
                        }
                    }}
                      options={loadHCPCLookUp}
                      renderMenuItemChildren={(option) => (
                        <span>{option.chr_code}-{option.chr_description}</span>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      value={state.rental}
                      onChange={(e) => updateAdvSearchForm(e.target.value, 'rental', dispatch)}
                    /> Rental
                  </Label>
                </Col>
              </Row>
              <Row xs={4}>
                <Col>
                  <FormGroup>
                    <Label for="exampleDate">Rental Start: </Label>
                    <AppDateInput
                  onChange={value => updateAdvSearchForm(value, 'serviceRentalStartDate', dispatch)}
                  selected={state.serviceRentalStartDate}
                  value={state?.serviceRentalStartDate || ''}
                  dateFormat="MM/dd/yyyy"
                />
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Label for="exampleDate">Rental End: </Label>
                  <AppDateInput
                  onChange={value => updateAdvSearchForm(value, 'serviceRentalEndDate', dispatch)}
                  selected={state.serviceRentalEndDate}
                  value={state?.serviceRentalEndDate || ''}
                  dateFormat="MM/dd/yyyy"
                />
                </FormGroup>
              </Row>
              <Row>
                <Col>Select the fields to display on the report</Col>
              </Row>
              <Row xs={2} md={4} style={{ paddingLeft: '40px' }}>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceHCPC}
                      onClick={(e) => {updateCheckBox(e.target.checked, 'displayServiceHCPC', dispatch1);}}
                    /> HCPC
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceLocation}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceLocation', dispatch1)}
                    /> Service Location
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRentals}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentals', dispatch1)}
                    /> Rentals
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRentalStartDate}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalStartDate', dispatch1)}
                    /> Rental Start date
                  </Label>
                </Col>
              </Row>
              <Row xs={2} md={4} style={{ paddingLeft: '40px' }}>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRentalEndDate}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalEndDate', dispatch1)}
                    /> Rental End date
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRentalFreq}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalFreq', dispatch1)}
                    /> Rental Frequency
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRenewal}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRenewal', dispatch1)}
                    /> Renewal
                  </Label>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Types:</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" /> Select All:
                    </Label>
                  </FormGroup>
                  <div
                    style={{
                      border: '1px solid black',
                      height: '150px',
                      width: '200px',
                      padding: '10px',
                      overflowY: 'scroll',
                    }}
                  ></div>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        )}
        {audiologyButton === 'audiology' && (
          <Row>
            <Col>
              <Label>Select the fields to display on the report</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={state1.displayServiceHCPC}
                    onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceHCPC', dispatch1)}
                  /> HCPC
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={state1.displayServiceRentals}
                    onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentals', dispatch1)}
                  /> Rentals
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Select All:
                    </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '200px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                ></div>
              </FormGroup>
            </Col>
          </Row>
        )}
        {audiologyButton == 'dental' && (
          <FormGroup>
            <Row>
              <Col>
                <Label>Select the fields to display on the report</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceLocation}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceLocation', dispatch1)}
                    /> Service Location
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Types:</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" /> Select All:
                    </Label>
                  </FormGroup>
                  <div
                    style={{
                      border: '1px solid black',
                      height: '150px',
                      width: '200px',
                      padding: '10px',
                      overflowY: 'scroll',
                    }}
                  >
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        )}
        {audiologyButton == 'diagnostic' && (
          <FormGroup>
            <Row>
              <Label>Select the fields to display on the report</Label>
            </Row>
            <Row>
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceHCPC}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceHCPC', dispatch1)}
                    /> HCPC
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={state1.displayServiceRentals}
                      onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentals', dispatch1)}
                    /> Rentals
                  </Label>
                </FormGroup>
              </Col>
              <FormGroup>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input 
                    type="checkbox"
                    checked={serviceTypes.diagnostics.length === 17}
                    onClick={(e)=> selectAllSubCategory(e.target.checked, diagnosticType, dispatch, 'serviceTypes', 'diagnostics')}
                    /> Select All:
                    </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '200px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {diagnosticType && diagnosticType.map(element => {
                    return (
                      <FormGroup check>
                        <Label check>
                          <Input 
                          type="checkbox" 
                          checked={serviceTypes.diagnostics.includes(element.id)}
                          onClick={(e) => UpdateServiceTypes(e.target.checked, element.id, serviceTypes.diagnostics, dispatch, 'serviceTypes','diagnostics')}
                          />
                          {element.value}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </div>
              </FormGroup>
            </Row>
          </FormGroup>
        )}
        {audiologyButton == 'mse' && (
          <FormGroup>
            <div style={{ float: 'left', width: '60%' }}>
              <Row>
                <Col >
                  <FormGroup>
                    <Label for="exampleDate">Rental Start: </Label>
                    <AppDateInput
                     onChange={value => updateAdvSearchForm(value, 'serviceRentalStartDate', dispatch)}
                     selected={state.serviceRentalStartDate}
                     value={state?.serviceRentalStartDate || ''}
                     dateFormat="MM/dd/yyyy"
                     />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="exampleDate">Rental End: </Label>
                     <AppDateInput
                      onChange={value => updateAdvSearchForm(value, 'serviceRentalEndDate', dispatch)}
                      selected={state.serviceRentalEndDate}
                      value={state?.serviceRentalEndDate || ''}
                      dateFormat="MM/dd/yyyy"
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>Select the fields to display on the report</Col>
              </Row>
              <Row xs={2} md={4}>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceHCPC}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceHCPC', dispatch1)}
                      /> HCPC
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentals}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentals', dispatch1)}
                      /> Rentals
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalStartDate}
                        value={state?.displayServiceRentalStartDate || ''}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalStartDate', dispatch1)}
                      /> Rental Start Date
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row xs={2} md={4}>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalEndDate}
                        value={state?.displayServiceRentalEndDate || ''}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalEndDate', dispatch1)}
                      /> Rental End Date
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalFreq}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalFreq', dispatch1)}
                      /> Rental Frequency
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRenewal}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRenewal', dispatch1)}
                      /> Renewal
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input 
                    type="checkbox"
                    checked={serviceTypes.mse.length === 6}
                    onClick={(e)=> selectAllMseCategory(e.target.checked, mseCategory, dispatch, 'serviceTypes', 'mse')}
                    /> Select All:
                    </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '200px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {mseCategory && mseCategory.map(element => {
                    return (
                      <FormGroup check>
                        <Label check>
                          <Input 
                          type="checkbox" 
                          checked={serviceTypes.mse.includes(element.product)}
                          onClick={(e) => UpdateServiceTypes(e.target.checked, element.product, serviceTypes.mse, dispatch, 'serviceTypes','mse')}
                          />
                          {element.value}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </FormGroup>
        )}
        {audiologyButton == 'modification' && (
          <FormGroup>
            <div style={{ float: 'left', width: '60%' }}>
              <Row xs={4}>
                <Col>
                  <FormGroup>
                    <Label for="exampleDate">Rental Start: </Label>
                    <AppDateInput
                     onChange={value => updateAdvSearchForm(value, 'serviceRentalStartDate', dispatch)}
                     selected={state.serviceRentalStartDate}
                     value={state?.serviceRentalStartDate || ''}
                     dateFormat="MM/dd/yyyy"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="exampleDate">Rental End: </Label>
                    <AppDateInput
                     onChange={value => updateAdvSearchForm(value, 'serviceRentalEndDate', dispatch)}
                     selected={state.serviceRentalEndDate}
                     value={state?.serviceRentalEndDate || ''}
                     dateFormat="MM/dd/yyyy"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>Select the fields to display on the report</Col>
              </Row>
              <Row xs={2} md={4}>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentals}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentals', dispatch1)}
                      /> Rentals
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalStartDate}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalStartDate', dispatch1)}
                      /> Rental Start Date
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalEndDate}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalEndDate', dispatch1)}
                      /> Rental End Date
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row xs={2} md={4}>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRentalFreq}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRentalFreq', dispatch1)}
                      /> Rental Frequency
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={state1.displayServiceRenewal}
                        onClick={(e) => updateCheckBox(e.target.checked, 'displayServiceRenewal', dispatch1)}
                      /> Renewal
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Label>Types:</Label>
                <FormGroup check>
                  <Label check>
                    <Input 
                    type="checkbox"
                    checked={serviceTypes.modification.length === 2}
                    onClick={(e)=> selectAllSubCategory(e.target.checked, modificationCategory, dispatch, 'serviceTypes', 'modification')}
                    /> Select All:
                    </Label>
                </FormGroup>
                <div
                  style={{
                    border: '1px solid black',
                    height: '150px',
                    width: '200px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {modificationCategory && modificationCategory.map(element => {
                    return (
                      <FormGroup check>
                        <Label check>
                          <Input 
                          type="checkbox" 
                          checked={serviceTypes.modification.includes(element.id)}
                          onClick={(e) => UpdateServiceTypes(e.target.checked, element.id, serviceTypes.modification, dispatch, 'serviceTypes','modification')}
                          />
                          {element.value}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </FormGroup>
        )}
      </FormGroup>
    </TabPane>
  );
};

export default Services;
