import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Button, ModalFooter } from 'reactstrap';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';
import { billOperationsSelectors } from '../../bill-operations/store';
import moment from 'moment';

class SwitchServices extends Component {
    constructor(props) {
        super(props);

    }

    updateWorkList() {
        let postPayload = {
            zebraUserId: this.props.currentUserId,
            referral_id: this.props.requiredServiceData.referralId,
            mainMod: this.props.requiredServiceData.module,
        };
        this.props.loadFromWorkList(postPayload);
    }

    render() {
        return (
            <Modal size="md" isOpen={true} style={{ height: '100' }} scrollable={true}>
                <ModalHeader toggle={this.toggle}> Switch Services</ModalHeader>
                <ModalBody>
                    <div style={{ paddingBottom: '10px' }}>Change Referral Serivce? </div>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ marginLeft: 5 }}
                        type="button"
                        onClick={() => this.props.serviceGridchange('NO')}>NO</Button>
                    <Button style={{ marginLeft: 5 }}
                        type="button"
                        onClick={() => {
                            this.props.serviceGridchange('YES');
                            this.props.vendorLookupSuggestions({});
                            this.props.resetVendorData();
                            //this.updateWorkList();
                        }}>YES</Button>
                </ModalFooter>
            </Modal >
        )
    }
}


const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    //const billReviewInitialData = billOperationsSelectors.getInitialData(state);
    return {
        currentUserId: userId,
        users: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
        updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
        //lmsUserId: billReviewInitialData.LmsuserId,
        lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
        requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData ? state.FAST360.fast360Reducer.requiredServiceData : [],
    };
};



const mapDispatchToProps = dispatch => ({
    postTransfer: (data) => dispatch(fast360Operations.postTransfer(data)),
    vendorLookupSuggestions: data =>
        dispatch(fast360Operations.vendorLookupSuggestions(data)),
    resetVendorData: data =>
        dispatch(fast360Actions.resetVendorData(data)),
    loadFromWorkList: (data) => dispatch(fast360Operations.loadFromWorkList(data))
    // getUsersWorkList: (data, currentUserId) => dispatch(fast360Operations.getUsersWorkList(data, currentUserId))
});




export default connect(mapStateToProps, mapDispatchToProps)(SwitchServices);