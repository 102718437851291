import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';
import { connect } from 'react-redux';
import moment from 'moment';
import * as FileUtils from 'shared/utils/fileUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
    },
});

const Fast360TreeStructure = ({ dataObject, currentUserId, getCcDocumentData, updatedFilesData, retrieveDoc
    , getCcDocument, saveFileUpload, updatedFilesDataCall, deleteDocument, retrieveReferralDocument, updateRetriveDocument }) => {

    const [docData, setDocData] = useState([]);
    const [fileName, setFileName] = useState('')
    let uniqueTypes = [];
    const getCCDocCall = () => {
        const { FK_referral_id, ServiceType, int_reference_table_id } = dataObject;
        const { referralId, module, id } = dataObject;
    
        let serviceTypeLower = ServiceType ? ServiceType?.toLowerCase() : module?.toLowerCase();
        const objMap = {
            'modification': 'modifications',
            'diagnostic': 'diagnostics',
            'special transport' : 'special_transp',
            'int': 'interpretation',
            'acc': 'accommodation',
            'aud': 'audiology',
            'dtl': 'dental',
            'dgn': 'diagnostics',
            'hhc': 'hhc',
            'mse': 'mse',
            'mod': 'modifications',
            'spt': 'special_transp',
            'trn': 'transportation',
            'transportation': 'transportation',
            'accommodation': 'accommodation',
            'audiology': 'audiology',
            'dental': 'dental',
            'interpretation': 'interpretation'

        }
        let postPayload = {
            "functionName": 'loadCCDocuments',
            "zebraUserId": currentUserId,
            "referral_id": FK_referral_id ? FK_referral_id : referralId,
            "referral_service_id": int_reference_table_id ? int_reference_table_id : id,
            "tablename": (serviceTypeLower == 'modification' || serviceTypeLower == 'diagnostic' || serviceTypeLower == 'special transport' || serviceTypeLower.length === 3 ) ? objMap[serviceTypeLower] : objMap[serviceTypeLower],
        };
        getCcDocument(postPayload)
    }


    useEffect(() => {
        getCCDocCall();
    }, []);

    useEffect(() => {
        getDocs();
    }, [getCcDocumentData]);



    const getDocs = () => {
        let finalArr = [], arr = [];
        if (getCcDocumentData.length) {
            uniqueTypes = [...new Set(getCcDocumentData.map(i => i.type))];
            uniqueTypes.map(item => { finalArr.push({ type: item, documents: [] }) });
            uniqueTypes.map((item, index) => {
                getCcDocumentData.map(i => {
                    if (item == i?.type && i?.bol_active) {
                        finalArr[index].documents.push(i);
                    }
                })
            })
        }
        finalArr.map(i => i.documents.length && arr.push(i))
        setDocData(finalArr);
    }

    const handleClick = (item) => {
        const fileName = item.path.split('/')[1];
        const desc = item.description;
        retrieveReferralDocument({
            "functionName": "loadCCDocumentData",
            "zebraUserId": currentUserId,
            fileName,
            "description": desc,
                         "path": item.path,
        });
        setFileName(fileName);
    }

    useEffect(() => {
        if (retrieveDoc && retrieveDoc.fileData) {
            if (retrieveDoc.fileData !== 'Unable to open file!')
                debugBase64(retrieveDoc.fileData, fileName);
            updateRetriveDocument({})
        }
    }, [retrieveDoc?.description]);


    const debugBase64 = (fileDoc, fileDesc) => {
        FileUtils.saveBase64File(fileDesc, fileDoc);
    };

    const classes = useStyles();
    return (

        <>
            {docData.map((item, index) => {
                if (item.documents.length > 0) {
                    return (<TreeView
                        className={classes.root}
                        defaultCollapseIcon={<FontAwesomeIcon
                            className="text-colour"
                            icon={faFolder}
                        />}
                        defaultExpandIcon={<FontAwesomeIcon
                            className="text-colour"
                            icon={faFolder}
                        />}
                    >
                        <TreeItem nodeId={`${index + 1}`} label={`${item.type} (${item?.documents?.length})`} style={{ marginBottom: 12 }}>
                            {item.documents.map((i) =>
                                <div onDoubleClick={() => handleClick(i)}>
                                    <div style={{ fontSize: 15 }}>{moment(i.upload_date).format('YYYY-MM-DD')} {i.type}-{i.description}</div>
                                </div>
                            )}
                        </TreeItem>
                    </TreeView>)
                }
            }
            )}
        </>
    )
}

const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        getCcDocumentData: state.FAST360.fast360Reducer.updateGetCcDocument ? state.FAST360.fast360Reducer.updateGetCcDocument : {},
        updatedFilesData: state.FAST360.fast360Reducer.updatedFilesData ? state.FAST360.fast360Reducer.updatedFilesData : [],
        retrieveDoc: state.FAST360.fast360Reducer.retrieveDocument,
    };
};

const mapDispatchToProps = dispatch => ({
    getCcDocument: (data) => dispatch(fast360Operations.getCcDocument(data)),
    saveFileUpload: data => dispatch(fast360Operations.saveFileUpload(data)),
    updatedFilesDataCall: data => dispatch(fast360Actions.updatedFilesData(data)),
    deleteDocument: data => dispatch(fast360Operations.deleteDocument(data)),
    retrieveReferralDocument: data => dispatch(fast360Operations.retriveDocument(data)),
    updateRetriveDocument: data => dispatch(fast360Actions.updateRetriveDocument(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360TreeStructure);
