import {
  BILL_MAINTENANCE_LOAD_INITIAL_DATA,
  UPDATE_CURRENT_VIEW,
  UPDATE_CLIENT_RECORDS_TABLE,
  UPDATE_GROUP_CLIENTS,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER,
  UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE,
  UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER,
  ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER,
  UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE,
  ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP,
  UPDATE_MASTER_CLIENT_DATA_PROVIDER,
  UPDATE_SITE_DETAILS,
  UPDATE_MASTER_SITE_DETAILS,
  UPDATE_CLIENT_COMBO_BOXES,
  UPDATE_SLP_PROFILES_INITIAL_DATA,
  LOOK_UP_GROUPS_BY_GROUP_NAME,
  ADD_NEW_BLANK_GROUP,
  SAVE_GROUP,
  LOAD_GROUP_DETAILS,
  EMPTY_GROUP_DATA,
  UPDATE_STATE_CITY_BY_ZIP_CODE,
  UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE,
  UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS,
  SELECT_SITE_COPY_SETTINGS_MODAL,
  UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE,
  RESET_SITE_COPY_SETTINGS_MODAL,
  UPDATE_SITE_TREE,
  RESET_SITE_TREE,
  RESET_GROUP_CLIENTS,
  RESET_GROUP_DETAILS,
  UPDATE_SAVE_SITE_DETAILS
} from './constants';

const updateCurrentView = currentView => {
  return {
    type: UPDATE_CURRENT_VIEW,
    payload: currentView,
  };
};

const loadInitialData = data => {
  return {
    type: BILL_MAINTENANCE_LOAD_INITIAL_DATA,
    payload: data,
  };
};

const updateClientRecordsTable = data => {
  return {
    type: UPDATE_CLIENT_RECORDS_TABLE,
    payload: data,
  };
};

const updateGroupClient = data => {
  return {
    type: UPDATE_GROUP_CLIENTS,
    payload: data,
  };
};

const initSLPProfilesData = data => {
  return {
    type: UPDATE_SLP_PROFILES_INITIAL_DATA,
    payload: data,
  };
};
const findGroupByGroupName = data => {
  return {
    type: LOOK_UP_GROUPS_BY_GROUP_NAME,
    payload: data,
  };
};
const loadGroupDetailsAction = data => {
  return {
    type: LOAD_GROUP_DETAILS,
    payload: data,
  };
};
const addBlankGroup = () => {
  return {
    type: ADD_NEW_BLANK_GROUP,
    payload: EMPTY_GROUP_DATA,
  };
};
const saveGroupAction = data => {
  return {
    type: SAVE_GROUP,
    payload: data,
  };
};

const updateMasterClientDataProvider = data => {
  return {
    type: UPDATE_MASTER_CLIENT_DATA_PROVIDER,
    payload: data,
  };
};

const updateClientRatesFilter = data => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_FILTER,
    payload: data,
  };
};

const addClientRates = data => {
  return {
    type: ADD_NEW_CLIENT_MAINTENANCE_CLIENT_RATES,
    payload: data,
  };
};

const updateClientRatesTable = (data, isBeingCopied) => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_CLIENT_RATES_TABLE,
    isBeingCopied: isBeingCopied,
    payload: data,
  };
};

const updateUAndCSetupFilter = data => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_FILTER,
    payload: data,
  };
};

const addUAndCSetup = data => {
  return {
    type: ADD_NEW_CLIENT_MAINTENANCE_U_AND_C_SETUP,
    payload: data,
  };
};

const updateUAndCSetupTable = (data, isBeingCopied) => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_U_AND_C_SETUP_TABLE,
    isBeingCopied: isBeingCopied,
    payload: data,
  };
};

const updatePPOSetupFilter = data => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_FILTER,
    payload: data,
  };
};

const updateSettingsListTable = data => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_SETTINGS_LIST_TABLE,
    payload: data,
  };
};

const addPPOSetup = data => {
  return {
    type: ADD_NEW_CLIENT_MAINTENANCE_PPO_SETUP,
    payload: data,
  };
};

const updatePPOSetupTable = (data, isBeingCopied) => {
  return {
    type: UPDATE_CLIENT_MAINTENANCE_PPO_SETUP_TABLE,
    isBeingCopied: isBeingCopied,
    payload: data,
  };
};

const updateSiteDetails = data => {
  return {
    type: UPDATE_SITE_DETAILS,
    payload: data,
  };
};

const updateMasterSiteDetails = data => {
  return {
    type: UPDATE_MASTER_SITE_DETAILS,
    payload: data,
  };
};

const updateClientComboBoxes = data => {
  return {
    type: UPDATE_CLIENT_COMBO_BOXES,
    payload: data,
  };
};

const updateStateCityByZipCode = data => {
  return {
    type: UPDATE_STATE_CITY_BY_ZIP_CODE,
    payload: data,
  };
};

const updatePayerStateCityByZipCode = data => {
  return {
    type: UPDATE_PAYER_STATE_CITY_BY_ZIP_CODE,
    payload: data,
  };
};

const updateSiteSettingsCopyModalClients = data => {
  return {
    type: UPDATE_SITE_COPY_SETTINGS_MODAL_CLIENTS,
    payload: data,
  };
};

const selectSiteCopySettingsModal = data => {
  return {
    type: SELECT_SITE_COPY_SETTINGS_MODAL,
    payload: data,
  };
};

const resetSiteCopySettingsModal = () => {
  return {
    type: RESET_SITE_COPY_SETTINGS_MODAL,
  };
};

const updateSaveSiteDetails = () => {
  return {
    type: UPDATE_SAVE_SITE_DETAILS
  };
};
const updateSiteTree = data => {
  return {
    type: UPDATE_SITE_TREE,
    payload: data,
  };
};

const resetSiteTree = () => {
  return {
    type: RESET_SITE_TREE,
  };
};

const resetGroupClients = () => {
  return {
    type: RESET_GROUP_CLIENTS,
  };
};


const resetGroupDetails = () => {
  return {
    type: RESET_GROUP_DETAILS,
  };
};

export default {
  loadInitialData,
  updateCurrentView,
  initSLPProfilesData,
  findGroupByGroupName,
  addBlankGroup,
  saveGroupAction,
  loadGroupDetailsAction,
  updateClientRecordsTable,
  updateGroupClient,
  updateClientRatesFilter,
  updateClientRatesTable,
  addClientRates,
  updateUAndCSetupTable,
  updateUAndCSetupFilter,
  addUAndCSetup,
  updatePPOSetupFilter,
  updatePPOSetupTable,
  updateSettingsListTable,
  addPPOSetup,
  updateMasterClientDataProvider,
  updateSiteDetails,
  updateMasterSiteDetails,
  updateClientComboBoxes,
  updateStateCityByZipCode,
  updateSiteSettingsCopyModalClients,
  selectSiteCopySettingsModal,
  resetSiteCopySettingsModal,
  updatePayerStateCityByZipCode,
  updateSaveSiteDetails,
  updateSiteTree,
  resetSiteTree,
  resetGroupClients,
  resetGroupDetails
};
