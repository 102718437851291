import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label, CustomInput } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';

class TopNavActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [`${this.props.name}_state`]: '',
      [`${this.props.name}_effective`]: false,
      [`${this.props.name}_lineOfBusiness`]: '',
      [`${this.props.name}_stateDisplay`]: '',
      [`${this.props.name}_lineOfBusinessDisplay`]: '',
    };
  }

  onCheckboxChangeHandler = event => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
  };

  onChangeHandler = (targetId, value) => {
    this.setState({
      [`${targetId}Display`]: value,
    });
  };

  onSelectHandler = (targetId, displayValue, value) => {
    this.setState({
      [`${targetId}Display`]: displayValue,
      [targetId]: value,
    });
  };

  onResetHandler = () => {
    const resetState = {
      [`${this.props.name}_state`]: '',
      [`${this.props.name}_effective`]: false,
      [`${this.props.name}_lineOfBusiness`]: '',
      [`${this.props.name}_stateDisplay`]: '',
      [`${this.props.name}_lineOfBusinessDisplay`]: '',
    };

    this.setState(resetState, () => {
      this.props.updateFilter({
        state: '',
        effective: false,
        lineOfBusiness: '',
      });
    });
  };

  onFindHandler = () => {
    const data = {
      state: this.state[`${this.props.name}_state`],
      effective: this.state[`${this.props.name}_effective`],
      lineOfBusiness: this.state[`${this.props.name}_lineOfBusiness`],
    };
    this.props.updateFilter(data);
  };

  render() {
    let addNewButton = null;
    if (this.props.addNewButton && this.props.haveAddNewButton) {
      addNewButton = this.props.addNewButton();
    }

    let terminateAllButton = null;
    if (this.props.terminateAllButton && this.props.haveTerminateButton) {
      terminateAllButton = this.props.terminateAllButton();
    }

    return (
      <>
        <Row>
          <Col lg="1">
            <FormGroup>
              <Label>State:</Label>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <AppAutocomplete
                id={`${this.props.name}_state`}
                data={this.props.stateList}
                value={this.state[`${this.props.name}_stateDisplay`]}
                displayOption="key"
                returnValue="key"
                onChange={this.onChangeHandler}
                onSelect={this.onSelectHandler}
                showToolTip={true}
                placeholder="State"
                isSuggested={true}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <CustomInput
                id={`${this.props.name}_effective`}
                type="checkbox"
                checked={this.state[`${this.props.name}_effective`]}
                onChange={this.onCheckboxChangeHandler}
                label="Effective"
                inline
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="1">
            <FormGroup>
              <Label>LOB:</Label>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <AppAutocomplete
                id={`${this.props.name}_lineOfBusiness`}
                data={this.props.lobList}
                value={this.state[`${this.props.name}_lineOfBusinessDisplay`]}
                displayOption="description"
                returnValue="key"
                onChange={this.onChangeHandler}
                onSelect={this.onSelectHandler}
                showToolTip={true}
                placeholder="LOB"
                isSuggested={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <Row className="float-right">
              <Col>
                {addNewButton}
                {terminateAllButton}
                {this.props.haveResetButton && (
                  <Button
                    type="button"
                    className="ml-2"
                    onClick={this.onResetHandler}
                  >
                    Reset
                  </Button>
                )}
                <Button
                  type="button"
                  className="ml-2"
                  onClick={this.onFindHandler}
                >
                  FIND
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

TopNavActions.defaultProps = {
  haveResetButton: true,
};

export default TopNavActions;
