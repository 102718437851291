import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { expertsSelectors, expertsOperations } from '../store';

import ExpertTaskListTop from './ExpertTaskListTop';
import ExpertTaskListTable from './ExpertTaskListTable';

const MY_TASKS = 'My Tasks';
class ExpertTaskListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTasks: [],
      transferredUser: '',
      transferredUserName: '',
      transferMode: false,
      selectedUser: this.props.currentUserId,
      selectedUserName: MY_TASKS,
    };
  }

  switchMode = () => {
    this.setState(prevState => ({
      transferMode: !prevState.transferMode,
      transferredUser: '',
      transferredUserName: '',
    }));
  };

  handleUserChange = (_fieldName, value) => {
    this.setState({
      selectedUserName: value,
    });
  };

  handleUserSelect = (_fieldName, displayValue, value) => {
    this.setState({
      selectedUser: value,
      selectedUserName: displayValue,
    });
    this.props.getUserTasks(value);
  };

  handleTransferredUserChange = (_fieldName, value) => {
    this.setState({
      transferredUserName: value,
    });
  };

  handleTransferredUserSelect = (_fieldName, displayValue, value) => {
    this.setState({
      transferredUser: value,
      transferredUserName: displayValue,
    });
  };

  refresh = () => {
    this.setState({
      selectedTasks: [],
      transferredUser: '',
      transferMode: false,
    });
    this.props.getUserTasks(this.state.selectedUser);
  };

  selectAllClickHandler = () => {
    this.setState({
      selectedTasks: this.props.tasks.map(item => item.id),
    });
  };

  selectAll = event => {
    event.target.checked
      ? this.setState({
          selectedTasks: this.props.tasks.map(item => item.id),
        })
      : this.setState({
          selectedTasks: [],
        });
  };

  transferTasks = () => {
    this.props.transferTasks({
      selectedTasks: this.state.selectedTasks,
      transferredUser: this.state.transferredUser,
      selectedUser: this.state.selectedUser,
    });
    this.setState({
      selectedTasks: [],
    });
  };

  selectTask = (id, event) => {
    event.target.checked
      ? this.setState(prevState => ({
          selectedTasks: [...prevState.selectedTasks, id],
        }))
      : this.setState(prevState => ({
          selectedTasks: prevState.selectedTasks.filter(value => value !== id),
        }));
  };

  render() {
    const expertUsers = [
      {
        id: this.props.currentUserId,
        fullname: MY_TASKS,
      },
      ...this.props.expertUsers,
    ];
    return (
      <>
        <ExpertTaskListTop
          taskLength={this.props.tasks.length}
          selectedUser={this.state.selectedUser}
          selectedUserName={this.state.selectedUserName}
          selectedTasks={this.state.selectedTasks}
          expertUsers={expertUsers}
          transferredOptions={this.props.expertUsers}
          handleUserChange={this.handleUserChange}
          handleUserSelect={this.handleUserSelect}
          refresh={this.refresh}
          transferMode={this.state.transferMode}
          switchMode={this.switchMode}
          selectAllClickHandler={this.selectAllClickHandler}
          handleTransferredUserChange={this.handleTransferredUserChange}
          handleTransferredUserSelect={this.handleTransferredUserSelect}
          transferTasks={this.transferTasks}
          transferredUser={this.state.transferredUser}
          transferredUserName={this.state.transferredUserName}
        />
        <ExpertTaskListTable
          data={this.props.tasks}
          transferMode={this.state.transferMode}
          selectedTasks={this.state.selectedTasks}
          selectAll={this.selectAll}
          selectTask={this.selectTask}
          currentUserId={this.props.currentUserId}
          users={this.props.users}
        />
      </>
    );
  }
}

ExpertTaskListContainer.propTypes = {
  tasks: PropTypes.array.isRequired,
  expertUsers: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
  getInitialData: PropTypes.func.isRequired,
  getUserTasks: PropTypes.func.isRequired,
  transferTasks: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const taskState = expertsSelectors.getTasks(state);
  const initialDataState = expertsSelectors.getInitialData(state);
  return {
    tasks: taskState.tasks,
    expertUsers: initialDataState.expertUsers,
    users: initialDataState.users,
  };
};

const mapDispatchToProps = dispatch => ({
  getInitialData: userId => dispatch(expertsOperations.getInitialData(userId)),
  getUserTasks: userId => dispatch(expertsOperations.getUserTasks(userId)),
  transferTasks: data => dispatch(expertsOperations.transferTasks(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertTaskListContainer);
