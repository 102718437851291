import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, FormGroup, Label, Input, CustomInput } from 'reactstrap';

const GroupDeliveryNotificationTab = ({
  data,
  onInputChange,
  notificationTypes,
}) => (
  <Row>
    <Col lg="8">
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label for="notificationMethod">
              Preferred Method of Notification
            </Label>
            <Input
              type="select"
              name="notificationMethod"
              id="notificationMethod"
              value={data.notificationMethod}
              onChange={onInputChange}
            >
              {notificationTypes.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.element}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="callForAssignment"
              id="callForAssignment"
              label="Call before assigning?"
              checked={data.callForAssignment}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <CustomInput
              type="checkbox"
              name="onlineTaskList"
              id="onlineTaskList"
              label="Manage Referrals online via Tasklist?"
              checked={data.onlineTaskList}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  </Row>
);

GroupDeliveryNotificationTab.propTypes = {
  data: PropTypes.object.isRequired,
  notificationTypes: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default GroupDeliveryNotificationTab;
