import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { billOperationsOperations, billOperationsSelectors } from '../store';

import BillClaimNotesModal from './BillClaimNotesModal';
import propTypes from 'modules/administration/store/propTypes';



class BillClaimNotesAction extends Component {
    render() {
        return (
            <BillClaimNotesModal
                iconOnly={this.props.iconOnly}
                recordId={this.props.recordId}
                notes={this.props.notes}
                eorNotes={this.props.eorNotes}
                accountingNotes={this.props.accountingNotes}
                isFromClaim={this.props.isFromClaim}
                submitSaveNotes={body =>
                    this.props.saveNote(body, this.props.searchFields)
                }
                claimId={this.props.claimId}
                isClaim={true}
                isFindBillClaimSearch={this.props.isFindBillClaimSearch}
            />
        );
    }
}

BillClaimNotesAction.propTypes = {
    iconOnly: PropTypes.bool,
    notes: PropTypes.string,
    isFromClaim: PropTypes.string,
    claimId: PropTypes.any
};

const mapStateToProps = state => {
    const findBills = billOperationsSelectors.getFindBills(state);
    return {
        searchFields: findBills.searchFields,
    };
};

const mapDispatchToProps = dispatch => ({
    saveNote: (body, searchFields) =>
        dispatch(billOperationsOperations.saveNote(body, searchFields))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillClaimNotesAction);
