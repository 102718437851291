import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';

import { formatPhoneNumber } from '../utils/expertUtils';

const IndividualInformation = ({ people }) => {
  const cell1 = people.cell1 || '';
  const pager1 = people.pager1 || '';
  const pagerPin = people.pagerpinnumber || '';
  const pagerPinRenderer = pagerPin !== '' ? ` pin ${pagerPin}` : '';
  const email1 = people.email1 || '';
  const email2 = people.email2 || '';
  return (
    <>
      <Row>
        <Col lg="6">
          <div className="underline-text">Cell Phone</div>
          <div>{cell1 !== '' ? formatPhoneNumber(cell1) : 'not on file'}</div>
        </Col>
        <Col lg="6">
          <div className="underline-text">Email Address</div>
          <div>
            {email1 !== '' ? (
              <a href={`mailto:${email1}`}>{email1}</a>
            ) : (
              'not on file'
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col lg="6">
          <div className="underline-text">Pager</div>
          <div>
            {pager1 !== ''
              ? `${formatPhoneNumber(pager1)}${pagerPinRenderer}`
              : 'not on file'}
          </div>
        </Col>
        <Col lg="6">
          <div className="underline-text">Email Address 2</div>
          <div>
            {email2 !== '' ? (
              <a href={`mailto:${email2}`}>{email2}</a>
            ) : (
              'not on file'
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const GroupInformation = ({ expert, groupExpert }) => {
  // TODO must link to edit expert
  const website = expert.website || '';
  return (
    <>
      <Row>
        <Col lg="6">
          <div className="underline-text">Linked Experts</div>
          <Card className="small-fix-height-card">
            <CardBody>
              {groupExpert
                .filter(group => group.expert_id && group.expert_id !== '')
                .map((group, index) => (
                  <div key={index}>
                    {group.fullname || ''}
                    <hr></hr>
                  </div>
                ))}
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <div className="underline-text">Website</div>
          <div>
            {website !== '' ? (
              <a href={website} rel="noopener noreferrer" target="_blank">
                {website}
              </a>
            ) : (
              'not on file'
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const ExpertGeneralCard = props => {
  return (
    <Card className="fix-height-card">
      <CardHeader>General</CardHeader>
      <CardBody>
        {props.groupMode ? (
          <GroupInformation
            expert={props.expert.expert}
            groupExpert={props.expert.groupExpert}
          />
        ) : (
          <IndividualInformation people={props.expert.people} />
        )}
        <Row style={{ marginTop: 5 }}>
          <Col lg="12">
            <div className="underline-text">Services</div>
            {props.expert.expertServices.map((service, index) => (
              <span key={index}>
                {service.servicename || ''}
                {props.expert.expertServices.length - 1 === index ? '' : ', '}
              </span>
            ))}
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col lg="12">
            <div className="underline-text">Networks</div>
            {props.expert.expertNetworks.map((network, index) => (
              <span
                className={!network.active ? 'red-text italic-text' : ''}
                key={index}
              >
                {network.networkname || ''}
                {props.expert.expertNetworks.length - 1 === index ? '' : ', '}
              </span>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ExpertGeneralCard.propTypes = {
  expert: PropTypes.shape({
    expertNetworks: PropTypes.array.isRequired,
    expertServices: PropTypes.array.isRequired,
    people: PropTypes.object.isRequired,
    expert: PropTypes.object.isRequired,
    groupExpert: PropTypes.array.isRequired,
  }).isRequired,
  groupMode: PropTypes.bool.isRequired,
};

export default ExpertGeneralCard;
