import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { billOperationsSelectors } from '../store';
import { authSelectors } from 'modules/auth/store';

import BillClaimNotesTab from './BillClaimNotesTab';

let initialState = {
    activeTab: '1',
    internalNote: '',
    eorNote: '',
}
class BillClaimNotesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            tabInfo: {
                activeTab: '1',
                internalNote: '',
                eorNote: '',
            },
            valueChanged: false,
            tab: 1
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal, tabInfo: initialState })
    }

    componentWillUnmount() {
        this.setState({ tabInfo: {}, valueChanged: false })
    }

    saveNote = () => {
        const currentUserId = this.props.user.id.toString();
        let body = {
            action: 'savenote',
            recid: this.props.recordId,
            userId: currentUserId,
            profile: this.props.profile
        };

        if (this.props.isFromClaim == "true") {
            body = {
                ...body,
                isclaimnote: "true",
                profile: this.props.profile,
                recid: this.props.claimId
            };
        }
        if (this.state.tabInfo.internalNote !== '') {
            body = {
                ...body,
                internalnote: this.state.tabInfo.internalNote,
            };
        }
        if (this.state.tabInfo.eorNote !== '' && this.props.isFromClaim != "true") {
            body = {
                ...body,
                note: this.state.tabInfo.eorNote,
            };
        }

        if (this.state.tabInfo.eorNote !== '' && this.props.isFromClaim == "true") {
            body = {
                ...body,
                internalnote: this.state.tabInfo.eorNote,
            };
        }

        if (this.state.tabInfo.internalNote !== '' && this.props.isFromClaim == "true") {
            body = {
                ...body,
                internalnote: this.state.tabInfo.internalNote,
            };
        }

        if (!this.props.recordId) {
            let internalnote = '';
            internalnote = this.state.tab == 1 ? this.state.tabInfo.internalNote : this.state.tabInfo.eorNote
            body = {
                ...body,
                isclaimnote: "true",
                profile: this.props.profile,
                recid: this.props.claimId,
                internalnote: internalnote
            };
            delete body.note;
            internalnote == "" && delete body.internalnote
        }

        if (this.props.isFromClaim) {
            let internalnote = '';
            // if (this.state.valueChanged) {
            internalnote = this.state.tab == 1 ? this.state.tabInfo.internalNote : this.state.tabInfo.eorNote
            // }
            body = {
                ...body,
                internalnote: internalnote
            };
            delete body.note;
            internalnote == "" && delete body.internalnote
        }

        if (this.props.isFindBillClaimSearch) {
            body.recid = this.props.recordId
        }
        if (this.state.tabInfo.internalNote != "" || this.state.tabInfo.eorNote != "") {
            this.props.submitSaveNotes(body);
            this.toggle();
        }


    };

    onChangeNoteText = (event, tab) => {
        // this.setState({ valueChanged: true, tab: tab })
        this.setState({
            tabInfo: {
                ...this.state.tabInfo,
                [event.target.id]: event.target.value,
            },
        });
    };
    setActiveTab = tab => {
        this.setState({ tab: tab })
    }

    render() {
        return (
            <li>
                <Button
                    color="link"
                    onClick={this.toggle}
                    style={{ cursor: 'pointer' }}
                >
                    <span aria-hidden>
                        <FontAwesomeIcon icon="edit" style={{ display: 'block' }} />
                        {this.props.iconOnly ? '' : <span>Notes</span>}
                    </span>
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle}>Notes</ModalHeader>
                    <ModalBody>
                        <BillClaimNotesTab
                            onChangeNoteText={this.onChangeNoteText}
                            notes={this.props.notes}
                            eorNotes={this.props.eorNotes}
                            internalNote={this.state.tabInfo.internalNote}
                            eorNote={this.state.eorNote}
                            accountingNotes={this.props.accountingNotes}
                            setActiveTab={this.setActiveTab}
                            recordId={this.props.recordId}
                            claimId={this.props.claimId}
                            isFindBillClaimSearch={this.props.isFindBillClaimSearch}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.saveNote}>
                            SAVE
            </Button>
                    </ModalFooter>
                </Modal>
            </li>
        );
    }
}

BillClaimNotesModal.propTypes = {
    claimObject: PropTypes.object,
    claimId: PropTypes.any
}

const mapStateToProps = state => {
    const billReviewInitialData = billOperationsSelectors.getInitialData(state);
    const findBills = billOperationsSelectors.getFindBills(state);

    return {
        user: authSelectors.getUser(state),
        profile: billReviewInitialData.currentProfile,
        claimCode: findBills.claim.int_claimcode || ''
    };
};

export default connect(mapStateToProps)(BillClaimNotesModal);
