import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import moment from '../../../../node_modules/moment/moment';
import { connect } from 'react-redux';
import { commonOperations } from 'common/store';
import { fast360Operations, fast360Actions } from '../store';
import { authSelectors } from 'modules/auth/store';


const Fast360PayableLinesModal = ({
  isOpen,
  onClose,
  data,
  onSave,
  loadLineItemsList,
  currentUserId,
  userId,
  currentWorkList,
  lineItemsList,
  loadLineItemDetails,
  lineItemDetails,
  updateLineItemDetails,
  savePayableLines,
  lmsUserId,
  requiredServiceData,
  approvalDenialReasons,
  loadApprovalDenialReasons,
  payableLines,
  productCatalog,
  alert
}) => {

  const [isNewItem] = useState(data ? false : true);
  const [selectedItem, setSelectedItem] = useState(data ? data : {
    dos: Date.now(),
    qty: '',
    modifier: '',
    itemName: '',
    vendorName: '',
    taxId: '',
    vendorCntrctPerUnit: '',
    totalVendorCntrctAmt: '',
    vendorInvRcvdDate: Date.now(),
    vendorInvApprvDate: Date.now(),
    vendorInvDate: Date.now(),
    vendorBilledAmt: '',
    apprvdAmtPaid: '',
    vendorInvDecision: '',
    notes: '',
    reconsiderationDecision: '',
    reconsiderationAmt: '',
    hcpcDtlSubsrvcId: '',
    apId: '0',
    pkServiceId: '',
    reasonId: ''

  });
  const [selectedItemNameIndex, setSelectedItemNameIndex] = useState(null);

  const [items, setItems] = useState([]);
  const [approvalReasons, setApprovalReasons] = useState([]);
  const [denialReasons, setDenialReasons] = useState([]);
  const [reasonType, setReasonType]  = useState(data ? data.vendorInvDecision : '');
  const [serviceData, setServiceData]  = useState(requiredServiceData.selectedService ? requiredServiceData.selectedService: requiredServiceData);


  useEffect(() => {
    if(Object.keys(requiredServiceData).length !== 0) {
      const srvData = requiredServiceData.selectedService ? requiredServiceData.selectedService: requiredServiceData;
      setServiceData(srvData);
    }
  }, [requiredServiceData]);
  
  useEffect(() => {
    // React request Data
    
    const req = {
      functionName: "loadItemsList",
      zebraUserId: currentUserId,
      referralId: serviceData.referralId,
      serviceId: serviceData.serviceId,
      referenceTable: serviceData.module
    }
    const req1 = {  
      functionName: "loadApprovalDenialReasons"
    }
     
    loadApprovalDenialReasons(req1);
    loadLineItemsList(req);

  }, []);

  useEffect(() => {
    if(approvalDenialReasons["Deny Invoice"]) {
      setDenialReasons(approvalDenialReasons["Deny Invoice"]);
    }

    if(approvalDenialReasons["Approve Invoice"]) {
      setApprovalReasons(approvalDenialReasons["Approve Invoice"]);
    }
  }, [approvalDenialReasons])

  useEffect(() => {
    const updatedLineItemsList = [];
    if(lineItemsList.length > 0 && serviceData.module !== 'TRN' && serviceData.module !== 'SPT' && serviceData.module !== 'INT') {

      lineItemsList.forEach(i => {
  
        const index = payableLines.findIndex(item => item.hcpcDtlSubsrvcId == i.item_id);
        if(index === -1) {
          updatedLineItemsList.push(i);
        }
      })
    } else if(lineItemsList.length > 0  && (serviceData.module === 'TRN' || serviceData.module === 'SPT' || serviceData.module === 'INT')) {

      lineItemsList.forEach(i => {
  
        const index = payableLines.findIndex(item => item.itemName == i.item_name);
        if(index === -1) {
          updatedLineItemsList.push(i);
        }
      })
    } 
    
    if(updatedLineItemsList.length > 0 && selectedItem.itemName != '') {


      const index = updatedLineItemsList.findIndex((i) => i.item_id == selectedItem.hcpcDtlSubsrvcId);
      if(index >= 0) {
        setSelectedItemNameIndex(index);
      }
    }
    setItems(updatedLineItemsList);
  }, [lineItemsList])

  useEffect(() => {
    if (lineItemDetails.length > 0) {

      const selectedItemDetails = lineItemDetails.map(item => {
        return {
          ...selectedItem,
          qty: item.quantity,
          modifier: item.modifier,
          itemName: item.item_name,
          vendorName: item.vendor_name,
          taxId: item.tax_id ? item.tax_id : selectedItem.taxId,
          hcpcDtlSubsrvcId: item.FK_hcpc_dtl_subsrvc_id,
          pkServiceId:  item.pkServiceId,
          dos: item.servicedate ? new Date(item.servicedate) : selectedItem.dos,
          vendorCntrctPerUnit: item.vendor_cntrct_per_unit,
          totalVendorCntrctAmt: item.total_vendor_cntrct_amt,
          apprvdAmtPaid: item.total_vendor_cntrct_amt,
          vendInvNo: item.chr_vendor_invoice_num,
        }
      });
      setSelectedItem(selectedItemDetails[0]);
    }
  }, [lineItemDetails])

  const itemNameChangeHandler = (key, val) => {
    const index = items.findIndex((i) => i.item_name === val);

    if (index >= 0) {
      const item = items[index];
      setSelectedItemNameIndex(index);
       const req = {
        functionName: "loadItemsDetails",
        zebraUserId: currentUserId,
        referralId: serviceData.referralId,
        serviceId: serviceData.serviceId,
        referenceTable: serviceData.module,
        itemId: item.item_id,
        FK_hcpc_dtl_subsrvc_id: item.FK_hcpc_dtl_subsrvc_id ? item.FK_hcpc_dtl_subsrvc_id : '' 
      }
    
      loadLineItemDetails(req)

    }
  }

  const getFormattedDate = (date) => {
    if(!date || date === '') {
      return '';
    }
    return moment(date).format('YYYY-MM-DD h:mm:ss')
  }

  const addPayableLinesItem = () => {
    //Real data
    const req = {
      functionName: "savePayableLines",
      zebraUserId: currentUserId,
      apId: selectedItem.apId,
      userId: userId,
      dos: getFormattedDate(selectedItem.dos),
      qty: selectedItem.qty,
      modifier: selectedItem.modifier,
      itemName: selectedItem.itemName,
      vendorName: selectedItem.vendorName,
      taxId: selectedItem.taxId,
      vendorCntrctPerUnit: selectedItem.vendorCntrctPerUnit,
      totalVendorCntrctAmt: selectedItem.totalVendorCntrctAmt,
      vendorInvRcvdDate: getFormattedDate(selectedItem.vendorInvRcvdDate),
      vendorInvApprvDate: getFormattedDate(selectedItem.vendorInvApprvDate),
      vendorInvDate: getFormattedDate(selectedItem.vendorInvDate),
      vendorBilledAmt: selectedItem.vendorBilledAmt,
      apprvdAmtPaid: selectedItem.apprvdAmtPaid,
      vendorInvDecision: selectedItem.vendorInvDecision,
      notes: selectedItem.notes,
      reconsiderationDecision: selectedItem.reconsiderationDecision,
      reconsiderationAmt: selectedItem.reconsiderationAmt,
      hcpcDtlSubsrvcId: selectedItem.hcpcDtlSubsrvcId,
      tableName: serviceData.module,
      pkServiceId: selectedItem.pkServiceId,
      reasonId: selectedItem.reasonId,
      vendInvNo: selectedItem.vendInvNo,
    };
    if (selectedItem.vendInvNo === '' || selectedItem.vendInvNo === undefined) {
      alert('You must provide a Vendor Invoice Number in order to save.');
      return;
    }
    savePayableLines(req);
    onSave();
    updateLineItemDetails([]);
    onClose();
  }

  const getReasons = () => {
    if(reasonType == 'Approved') {
      return approvalReasons;
    //   return [
    //     {
    //         reason: "Paid per Participating Provider Agreement",
    //         reasonId: 1690
    //     }
    // ]
    
    } else if(reasonType == 'Denied') {
      return denialReasons;
    //   return [
    //     {
    //         reason: "DENIED.  PROCEDURE CODE DOES NOT MATCH SERVICE RENDERED",
    //         reasonId: 1691
    //     },
    //     {
    //         reason: "DATE OF SERVICE IS PRIOR TO THE DATE OF INJURY",
    //         reasonId: 1692
    //     }
    // ]
    } else {
      return [];
    }
  }

  
  

  return <>
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader>Add Payable Line</ModalHeader>
      <ModalBody>
        <Form>
          <Row style={{ padding: '3px' }}>
          <Label for="confno" md="3">
              Confirmation No:
            </Label>
            <Col md="9">
              <Input
                disabled={true}
                value={serviceData.referralId + '-' + serviceData.module + '-' + serviceData.serviceId}
              />

            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="dateOfService" md="3">
              Date of Service:
            </Label>
            <Col md="9">
              <AppDateInput
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.dos}
                onChange={value => {
                  setSelectedItem({ ...selectedItem, dos: moment(value).valueOf() })
                }}
                selected={selectedItem && selectedItem.dos ? selectedItem.dos : ''}
              />

            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="quantity" md="3">
              Quantity:
            </Label>
            <Col md="9">
              <Input
                disabled={selectedItem && selectedItem.qty !== ''}
                value={selectedItem && selectedItem.qty}
                type="number"
                id="quantity"
                name="quantity"
                onChange={e => { }}
              />
            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="modifier" md="3">
              Modifier:
            </Label>
            <Col md="9">
              <Input
                name="modifier"
                disabled={selectedItem && selectedItem.modifier}
                min="0"
                max="12"
                value={selectedItem && selectedItem.modifier ? selectedItem.modifier : ''}
                type="text"
                id="modifier"
                onChange={e => { }}
              />
            </Col>
          </Row>
          {/* <Row style={{ padding: '3px' }}>
            <Label for="itemName" md="3">
              Item Type:
            </Label>
            <Col md="3" >
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" checked={isNewItem}
                    onChange={() => setIsNewItem(true)} />  New </Label>
              </FormGroup>

            </Col>
            <Col md="3" >
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" checked={!isNewItem}
                    onChange={() => setIsNewItem(false)} />  Existing </Label>
              </FormGroup>
            </Col>
          </Row> */}

          <Row style={{ padding: '3px' }}>
            <Label for="itemName" md="3">
              Item Name:
            </Label>
            <Col md="9">
              {/* {isNewItem && <Input
                name="itemName"
                disabled={selectedItem && selectedItem.itemName && isEditMode}
                min="0"
                max="12"
                value={selectedItem && selectedItem.itemName ? selectedItem.itemName : ''}
                type="text"
                id="itemName"
                name="itemName"
                onChange={e => { }}
              />} */}
              <AppInput
                id="itemName"
                type="select"
                placeholder="Item Name"
                changeHandler={itemNameChangeHandler}
                option={{
                  value: 'item_name',
                  displayValue: 'item_name',
                }}
                value={ items[selectedItemNameIndex] && items[selectedItemNameIndex].item_name}
                data={items}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.itemName}
              />
            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="vendorName" md="3">
              Vendor Name:
            </Label>
            <Col md="9">
              <Input
                name="vendorName"
                min="0"
                max="12"
                value={selectedItem && selectedItem.vendorName ? selectedItem.vendorName : ''}
                type="text"
                id="vendorName"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, vendorName: e.target.value })
                }}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendorName}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="taxId" md="3">
              Tax ID:
            </Label>
            <Col md="9">
              <Input
                name="taxId"
                min="0"
                max="12"
                value={selectedItem && selectedItem.taxId ? selectedItem.taxId : ''}
                type="text"
                id="taxId"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, taxId: e.target.value })
                }}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.taxId}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="vendorCntrctPerUnit" md="3">
              Vendor Contract Per Unit:
            </Label>
            <Col md="9">
              <Input
                name="vendorCntrctPerUnit"
                min="0"
                max="12"
                value={selectedItem && selectedItem.vendorCntrctPerUnit ? selectedItem.vendorCntrctPerUnit : ''}
                type="number"
                id="vendorCntrctPerUnit"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, vendorCntrctPerUnit: e.target.value })
                }}
                disabled={ !isNewItem && productCatalog && selectedItem && selectedItem.vendorCntrctPerUnit}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="totalVendorCntrctAmt" md="3">
              Total Vendor Contract Amount:
            </Label>
            <Col md="9">
              <Input
                name="totalVendorCntrctAmt"
                min="0"
                max="12"
                value={selectedItem && selectedItem.totalVendorCntrctAmt ? selectedItem.totalVendorCntrctAmt : ''}
                type="number"
                id="totalVendorCntrctAmt"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, totalVendorCntrctAmt: e.target.value })
                }}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.totalVendorCntrctAmt}
              />
            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="vendInvNo" md="3">
              Vendor Invoice number:
            </Label>
            <Col md="9">
              <Input
                name="vendInvNo"
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendInvNo}
                className={selectedItem && selectedItem.vendInvNo ? '' : "error"}
                value={selectedItem && selectedItem.vendInvNo ? selectedItem.vendInvNo : ''}
                id="vendInvNo"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, vendInvNo: e.target.value })
                }}
              />
            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="vendorInvRcvdDate" md="3">
              Vendor Invoice Received Date:
            </Label>
            <Col md="9">
              <AppDateInput

                onChange={value => {
                  setSelectedItem({ ...selectedItem, vendorInvRcvdDate: new Date(value) })
                }}
                selected={selectedItem && selectedItem.vendorInvRcvdDate ?  selectedItem.vendorInvRcvdDate  : ''}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendorInvRcvdDate}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="vendorInvApprvDate" md="3">
              Vendor Invoice Approve Date:
            </Label>
            <Col md="9">
              <AppDateInput

                onChange={value => {
                  setSelectedItem({ ...selectedItem, vendorInvApprvDate: new Date(value) })
                }}
                selected={selectedItem && selectedItem.vendorInvApprvDate ? selectedItem.vendorInvApprvDate : ''}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendorInvApprvDate}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="vendorInvDate" md="3">
              Vendor Invoice Date:
            </Label>
            <Col md="9">
              <AppDateInput

                onChange={value => {
                  setSelectedItem({ ...selectedItem, vendorInvDate: new Date(value) })
                }}
                selected={selectedItem && selectedItem.vendorInvDate ? selectedItem.vendorInvDate : ''}
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendorInvDate}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="vendorBilledAmt" md="3">
              Vendor Billed Amount:
            </Label>
            <Col md="9">
              <Input
                name="vendorBilledAmt"
                min="0"
                max="12"
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.vendorBilledAmt}
                value={selectedItem && selectedItem.vendorBilledAmt ? selectedItem.vendorBilledAmt : ''}
                type="number"
                id="vendorBilledAmt"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, vendorBilledAmt: e.target.value })
                }}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="apprvdAmtPaid" md="3">
              Approved Amount Paid:
            </Label>
            <Col md="9">
              <Input
                name="apprvdAmtPaid"
                min="0"
                max="12"
                disabled={!isNewItem && productCatalog && selectedItem && selectedItem.apprvdAmtPaid}
                value={selectedItem && selectedItem.apprvdAmtPaid ? selectedItem.apprvdAmtPaid : ''}
                type="number"
                id="apprvdAmtPaid"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, apprvdAmtPaid: e.target.value })
                }}
              />
            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="vendorInvDecision" md="3">
              Decision:
            </Label>
            <Col md="4">
              <AppInput
                id="vendorInvDecision"
                type="select"
                placeholder="Decision..."
                disabled={!isNewItem && productCatalog && data && data.vendorInvDecision === "Approved"}
                changeHandler={(e, val) => {
                  setReasonType(val);
                  let reasonId = '';
                  let notes = productCatalog && data && data.vendorInvDecision === "Denied" ? "Auto-Denied by Catalog Program": "";
                  if(val === 'Approved') {
                    const index = approvalReasons.findIndex(i => i.reason === 'Discount applied according to the Contract Terms');
                    notes = productCatalog ? "Auto-Approved by Catalog Program" : '';
                    if(index !== -1) {
                      reasonId = approvalReasons[index].reasonId;
                    }
                  }
                  setSelectedItem({ ...selectedItem, notes, vendorInvDecision: val, reasonId: reasonId })
                }}
                option={{
                  value: 'value',
                  displayValue: 'label',
                }}
                value={selectedItem && selectedItem.vendorInvDecision}
                data={[
                  {
                    label: 'Approved',
                    value: 'Approved',

                  },
                  {
                    label: 'Denied',
                    value: 'Denied',
                  },
                  {
                    label: 'Reconsideration Reprocess',
                    value: 'Reconsideration Reprocess',
                  },
                ]}
              />

            </Col>
            <Col md="5">
              <AppInput
                id="reason"
                type="select"
                placeholder="Reason..."
                changeHandler={(e, val) => {
                  setSelectedItem({ ...selectedItem, reasonId: val })
                }}
                option={{
                  value: 'reasonId',
                  displayValue: 'reason',
                }}
                value={selectedItem && selectedItem.reasonId}
                data={getReasons()}
                disabled={!isNewItem && productCatalog && data && data.vendorInvDecision === "Approved"}
              />

            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="notes" md="3">
              Notes:
            </Label>
            <Col md="9">
              <Input
                name="notes"
                min="0"
                max="12"
                value={selectedItem && selectedItem.notes ? selectedItem.notes : ''}
                type="text"
                id="notes"
                onChange={(e) => {
                  setSelectedItem({ ...selectedItem, notes: e.target.value})
                }}
              />
            </Col>
          </Row>
          <Row style={{ padding: '3px' }}>
            <Label for="reconsiderationDecision" md="3">
              Reconsideration Decision:
            </Label>
            <Col md="9">

              <AppInput
                id="reconsiderationDecision"
                type="select"
                placeholder="Reconsideration Decision..."
                changeHandler={(e, val) => {
                  setSelectedItem({ ...selectedItem, reconsiderationDecision: val })
                }}
                option={{
                  value: 'value',
                  displayValue: 'label',
                }}
                value={selectedItem && selectedItem.reconsiderationDecision}
                data={[
                  {
                    label: 'Approved',
                    value: 'Approved',

                  },
                  {
                    label: 'Denied',
                    value: 'Denied',
                  },
                  {
                    label: 'Reconsideration Reprocess',
                    value: 'Reconsideration Reprocess',
                  },
                ]}
                disabled={!isNewItem && productCatalog && data && data.vendorInvDecision === "Approved"}
              />



            </Col>
          </Row>

          <Row style={{ padding: '3px' }}>
            <Label for="reconsiderationAmt" md="3">
              Reconsideration Amount:
            </Label>
            <Col md="9">
              <Input
                name="reconsiderationAmt"
                min="0"
                max="12"
                value={selectedItem && selectedItem.reconsiderationAmt ? selectedItem.reconsiderationAmt : ''}
                type="number"
                id="reconsiderationAmt"
                onChange={e => {
                  setSelectedItem({ ...selectedItem, reconsiderationAmt: e.target.value })
                }}
                disabled={!isNewItem && productCatalog && data && data.vendorInvDecision === "Approved"}
              />
            </Col>
          </Row>

          <div style={{ float: 'right' }}>
            <Button type="button"
            disabled={!isNewItem && productCatalog && data && data.vendorInvDecision === "Approved"} 
            onClick={addPayableLinesItem}>
              Save
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              type="button"
              onClick={() => {
                updateLineItemDetails([]);
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>

  </>
}

const mapStateToProps = state => {
  // const userId = authSelectors.getUserId(state);

  return {
    currentUserId: authSelectors.getUserId(state),
    userId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    currentWorkList: state.FAST360.fast360Reducer.currentWorkListItem,
    lineItemsList: state.FAST360.fast360Reducer.lineItemsList,
    lineItemDetails: state.FAST360.fast360Reducer.lineItemDetails,
    lmsUserId: state.FAST360.initialData.roleUsers
    ? state.FAST360.initialData.roleUsers.LmsUserId
    : '',
    requiredServiceData: state.FAST360.fast360Reducer.requiredServiceData,
    approvalDenialReasons: state.FAST360.fast360Reducer.approvalDenialReasons,
    productCatalog: state.FAST360.fast360Reducer.productCatalog,
  };
};

const mapDispatchToProps = dispatch => ({
  loadLineItemsList: data =>
    dispatch(fast360Operations.loadLineItemsList(data)),
  loadLineItemDetails: data =>
    dispatch(fast360Operations.loadLineItemDetails(data)),
  updateLineItemDetails: data => dispatch(fast360Actions.updateLineItemDetails(data)),
  savePayableLines: data =>
  dispatch(fast360Operations.savePayableLines(data)),
  loadApprovalDenialReasons: data =>
  dispatch(fast360Operations.loadApprovalDenialReasons(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),

});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360PayableLinesModal);