export const BASIC_INFORMATION = {
  key: 'BASIC_INFORMATION',
  value: 'Basic Information',
};
export const RECRUITMENT_INFORMATION = {
  key: 'RECRUITMENT_INFORMATION',
  value: 'Recruitment Information',
};
export const DELIVERY_AND_NOTIFICATION = {
  key: 'DELIVERY_AND_NOTIFICATION',
  value: 'Delivery and Notification',
};
export const COMMENT = {
  key: 'COMMENT',
  value: 'Comments',
};

export const BIOGRAPHY = {
  key: 'BIOGRAPHY',
  value: 'Biography',
};
export const PROSPECT = 'Prospect';
export const APPLICANT = 'Applicant';
export const RECOMMENDED = 'Recommended';
export const DEACTIVATED = 'Deactivated';
export const GENDER = ['Female', 'Male'];
