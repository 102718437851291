import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { expertsOperations, expertsSelectors, expertsActions } from '../store';

import NewGroupSimpleModal from './NewGroupSimpleModal';
import NewGroupAdvanceModal from './NewGroupAdvanceModal';

class NewGroupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleModalOpen: false,
      advanceModalOpen: false,
      simpleModalGroupName: '',
      advanceModalGroupName: '',
    };
  }

  onInputChangeHandler = event => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  stateSimpleModalToggle = () => {
    this.setState(prevState => ({
      simpleModalOpen: !prevState.simpleModalOpen,
    }));
  };

  stateAdvanceModalToggle = () => {
    this.setState(prevState => ({
      advanceModalOpen: !prevState.advanceModalOpen,
    }));
  };

  onAdvanceModalGroupNameInputChangeHandler = event => {
    this.setState({
      advanceModalGroupName: event.target.value,
    });
  };

  resetSimpleModal = () => {
    this.setState({
      simpleModalGroupName: '',
    });
  };

  resetAdvanceModal = () => {
    this.setState({
      advanceModalGroupName: '',
    });
    this.props.resetView();
  };

  onSimpleModalNextClickHandler = () => {
    this.props.checkForDuplicateExpertGroup({
      groupName: this.state.simpleModalGroupName,
    });
    this.setState(prevState => ({
      advanceModalGroupName: prevState.simpleModalGroupName,
    }));
    this.stateSimpleModalToggle();
    this.stateAdvanceModalToggle();
  };

  onSimpleModalInputKeyPressHandler = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.onSimpleModalNextClickHandler();
  };

  onGroupClickHandler = item => {
    this.props.loadExpertGroupAddresses({
      expertId: item.id,
    });
  };

  onCreateNewGroupClickHandler = () => {
    this.props.saveNewGroup({
      groupName: this.state.advanceModalGroupName,
      currentUserId: this.props.currentUserId,
    });
    this.stateAdvanceModalToggle();
  };

  render() {
    return (
      <>
        <NewGroupSimpleModal
          modalOpen={this.state.simpleModalOpen}
          groupName={this.state.simpleModalGroupName}
          modalToggle={this.stateSimpleModalToggle}
          resetModal={this.resetSimpleModal}
          onNextClickHandler={this.onSimpleModalNextClickHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          onInputKeyPressHandler={this.onSimpleModalInputKeyPressHandler}
        />
        <NewGroupAdvanceModal
          modalOpen={this.state.advanceModalOpen}
          groupName={this.state.advanceModalGroupName}
          modalToggle={this.stateAdvanceModalToggle}
          resetModal={this.resetAdvanceModal}
          onGroupClickHandler={this.onGroupClickHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          duplicateExpertGroups={this.props.duplicateExpertGroups}
          selectedExpertGroupAddresses={this.props.selectedExpertGroupAddresses}
          onCreateNewGroupClickHandler={this.onCreateNewGroupClickHandler}
        />
        <Button
          style={{ width: '100%' }}
          type="button"
          onClick={this.stateSimpleModalToggle}
        >
          New Group
        </Button>
      </>
    );
  }
}

NewGroupContainer.propTypes = {
  duplicateExpertGroups: PropTypes.array.isRequired,
  selectedExpertGroupAddresses: PropTypes.array.isRequired,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  checkForDuplicateExpertGroup: PropTypes.func.isRequired,
  resetView: PropTypes.func.isRequired,
  loadExpertGroupAddresses: PropTypes.func.isRequired,
  saveNewGroup: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const newExpertGroupState = expertsSelectors.getNewExpertGroup(state);
  return {
    duplicateExpertGroups: newExpertGroupState.duplicateExpertGroups,
    selectedExpertGroupAddresses:
      newExpertGroupState.selectedExpertGroupAddresses,
  };
};

const mapDispatchToProps = dispatch => ({
  checkForDuplicateExpertGroup: data =>
    dispatch(expertsOperations.checkForDuplicateExpertGroup(data)),
  resetView: () => dispatch(expertsActions.resetNewExpertGroupView()),
  loadExpertGroupAddresses: data =>
    dispatch(expertsOperations.loadExpertGroupAddresses(data)),
  saveNewGroup: data => dispatch(expertsOperations.saveNewGroup(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupContainer);
