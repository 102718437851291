export const approveBills = 'approveBills';
export const recentActivity = 'recentActivity';
export const qaBills = 'qaBills';

export const adjustersType = 'adjusters';
export const mcnsUsersType = 'mcnsUsers';
export const nurseAuditsType = 'nurseAudits';
export const codeReviewsType = 'codeReviews';
export const paycodeList51 = 'paycode51';
export const paycodeList50 = 'paycode50';
export const paycodeList57 = 'paycode57';

export const adjustersTypeSelection = {
  id: adjustersType,
  name: 'Bill Review',
};
export const paycodeListSelection = {
  id: paycodeList50,
  name: 'Paycode List',
};
export const mcnsUsersTypeSelection = {
  id: mcnsUsersType,
  name: 'MCNS',
};
export const nurseAuditsTypeSelection = {
  id: nurseAuditsType,
  name: 'Nurse Audit',
};
export const codeReviewsTypeSelection = {
  id: codeReviewsType,
  name: 'Code Review',
};

export const groupByBill = 'groupByBill';
export const groupByClaim = 'groupByClaim';
export const groupByAdjuster = 'groupByAdjuster';

export const groupBySelection = [
  {
    id: groupByBill,
    name: 'Group by Bill',
  },
  {
    id: groupByClaim,
    name: 'Group by Claim',
  },
  {
    id: groupByAdjuster,
    name: 'Group by Adjuster',
  },
];

export const modifyBillLines = [
  {
    id: 1,
    name: 'Undecided',
  },
  {
    id: 2,
    name: 'Approve',
  },
  {
    id: 3,
    name: 'Deny',
  },
];

export const billReviewBills = [
  {
    id: 1,
    name: 'Undecided',
  },
  {
    id: 2,
    name: 'Approve',
  },
  {
    id: 3,
    name: 'Deny',
  },
];

export const columnName = {
  billId: 'Bill ID',
  flag: 'Flag',
  claimant: 'Claimant',
  claim: 'Claim #',
  client: 'Client',
  billType: 'Bill Type',
  state: 'State',
  dateOfService: 'Dates of Service',
  chargeAllow: 'Charge/Allow',
  paycode: 'Paycode',
  carrierReceived: 'Carrier Received',
  eor: 'EOR',
  odg: 'ODG',
  meds: 'Meds',
  moreInfo: 'M.I.',
  notes: 'Notes',
  auHist: 'AuHist',
  treatingProvider: 'Treating Provider',
  billingProvider: 'Billing Provider',
  processor: 'Processor',
  processingPrinted: 'Processing/Printed',
  treatmentType: 'Treatment Type',
  documents: 'Documents',
  status: 'Status',
  lob: 'LOB',
  network: 'Network'
};

export const taskListGridSettingName = 'adjusterWorklistGrid';
export const taskListGridSettingModuleId = 4;
export const taskListGridSettingColumns = [
  {
    columnOrder: 1,
    columnName: columnName.state,
    state: true,
  },
  {
    columnOrder: 2,
    columnName: columnName.treatingProvider,
    state: true,
  },
  {
    columnOrder: 3,
    columnName: columnName.billingProvider,
    state: true,
  },
  {
    columnOrder: 4,
    columnName: columnName.notes,
    state: true,
  },
  {
    columnOrder: 5,
    columnName: columnName.billType,
    state: true,
  },
  {
    columnOrder: 6,
    columnName: columnName.auHist,
    state: true,
  },
  {
    columnOrder: 7,
    columnName: columnName.lob,
    state: true,
  },
  //Zebra-520
  {
    columnOrder: 8,
    columnName: columnName.network,
    State: true,

  },
  {
    columnOrder: 9,
    columnName: columnName.client,
    state: true,
  },
  {
    columnOrder: 10,
    columnName: columnName.paycode,
    state: true,
  },
  {
    columnOrder:11,
    columnName: columnName.moreInfo,
    state: true,
  }
];

export const findBillsGridSettingName = 'billDataGrid';
export const findBillsGridSettingModuleId = 4;
export const findBillsGridSettingColumns = [
  {
    columnOrder: 1,
    columnName: columnName.processor,
    state: true,
  },
  {
    columnOrder: 2,
    columnName: columnName.treatingProvider,
    state: true,
  },
  {
    columnOrder: 3,
    columnName: columnName.processingPrinted,
    state: true,
  },
  {
    columnOrder: 4,
    columnName: columnName.billingProvider,
    state: true,
  },
  {
    columnOrder: 5,
    columnName: columnName.carrierReceived,
    state: true,
  },
  {
    columnOrder: 6,
    columnName: columnName.treatmentType,
    state: true,
  },
  {
    columnOrder: 7,
    columnName: columnName.dateOfService,
    state: true,
  },
  {
    columnOrder: 8,
    columnName: columnName.state,
    state: true,
  },
  {
    columnOrder: 9,
    columnName: columnName.client,
    state: true,
  },
  {
    columnOrder: 10,
    columnName: columnName.lob,
    state: true,
  },
  //Zebra-520
  {
    columnOrder:11,
    columnName: columnName.network,
    state: true,
  },
  {
  columnOrder:12,
  columnName: columnName.moreInfo,
  state: false,
  }
];
