import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import BillSearchResults from './BillSearchResults';
import { authSelectors } from 'modules/auth/store';
import URDecision from './URDecision';

const ClaimResultModal = ({
  isOpen,
  onClose,
  findResults,
  userId,
  profile,
  findBills,
  billsFromClaim,
  stateList,
  stateDocuments,
  sites,
  onURDecision,
  clearURDecisionData,
  clearClaimFromTaskList,
  clearBillsFromTaskListClaim,
}) => {
  
  const toggle = () => {
    setClaimItem({});
    clearBillsFromTaskListClaim();
    onClose();
  };
  const hide = {
    display: 'none',
  };
  const [showURDecision, setShowURDecision] = useState(false);
  const [claimItem, setClaimItem] = useState({});
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    if (isOpen && findResults.length) {
      const requestBody = {
        claimNumber: findResults[0].int_injurycode,
        billScan: '',
        end: '',
        client: '',
        state: '',
        billTypes: '',
        billStatus: '',
        processor: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        siteId: '',
        userId: userId,
        profile: profile,
      };
      findBills(requestBody);
      setClaimItem(findResults[0]);
    }
  }, [isOpen, findResults]);

  const toggleSearch = () => {
    setShowSearchBar(!showSearchBar);
  };

  const urDecision = claim => {
    onURDecision({ ...claim, userId });
    setClaimItem(claim);
    toggleURDecision();
  };

  const toggleURDecision = () => {
    setShowURDecision(!showURDecision);
    toggleSearch();
  };

  const exitURDecision = () => {
    setClaimItem({});
    clearURDecisionData();
    toggleURDecision();
  };

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
     <BillSearchResults 
          searchResults={billsFromClaim}
          stateList={stateList}
          currentUserId={userId}
          stateDocuments={stateDocuments}
          claimControlHandler={toggle}
          claimType="billsFromTaskList"
          sites={sites}
          claimData={claimItem}
          exitHandler={toggle}
        />
        <div style={showURDecision ? {} : hide}>
        <URDecision exitHandler={exitURDecision} claimData={claimItem} />
      </div>
      </ModalBody>
    </Modal>
  );
};

ClaimResultModal.propTypes = {
  data: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  date: PropTypes.string,
  claimNumber: PropTypes.string.isRequired,
  billId: PropTypes.number.isRequired,
  claimant: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  alert: PropTypes.func.isRequired,
  findResults: PropTypes.array.isRequired,
  claimData: PropTypes.object,
  sites: PropTypes.array.isRequired,
  claimControlHandler: PropTypes.func,
  findBills: PropTypes.func.isRequired,
  stateList: PropTypes.array.isRequired,
  billsFromClaim: PropTypes.array.isRequired,
  stateDocuments: PropTypes.array.isRequired,
  onURDecision: PropTypes.func.isRequired,
  clearURDecisionData: PropTypes.func.isRequired,
  clearBillsFromTaskListClaim: PropTypes.func,
  clearClaimFromTaskList: PropTypes.func
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const findBills = billOperationsSelectors.getFindBills(state);
  const initialData = billOperationsSelectors.getInitialData(state);

  return {
    userId: user.user_id,
    profile: billReviewInitialData.currentProfile,
    claim: findBills.claim,
    sites: initialData.slpsites,
    billsFromClaim: findBills.billsFromTaskListClaim,
    stateList: initialData.stateDocuments.stateList,
    stateDocuments: initialData.stateDocuments.documents,
  };
};

const mapDispatchToProps = dispatch => ({
  findBills: data => dispatch(billOperationsOperations.findBills(data, "billsFromTaskList")),
  onURDecision: claim =>
    dispatch(billOperationsOperations.getURDecision(claim)),
    clearURDecisionData: () =>
    dispatch(billOperationsActions.updateURDecisionData([])),
  clearBillsFromTaskListClaim: () =>
    dispatch(billOperationsActions.updateBillsFromTaskListClaim([])),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimResultModal);
