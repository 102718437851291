import React, { Component } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import * as NumberUtils from 'shared/utils/numberUtils';
class Fast360FileAppTableBodyRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            docType: ''
        };
        this.handleDescription = this.handleDescription.bind(this);
        this.handleDocType = this.handleDocType.bind(this);
    }
    handleDescription = (e) => {
        e.preventDefault();
        let propData = this.props.data;
        this.setState({ description: e.target.value });

        // const updatedData = this?.props?.data.map(i => {
        //     if (i?.file?.name == this?.props?.dataObject?.file?.name) {
        //         return {
        //             ...i, description: e.target.value
        //         }
        //     }
        //     else return i;
        // })
        propData[this.props.rowIndex].description = e.target.value;
        this.props.setRowData(propData);
    }


    handleDocType = (e) => {
        let propData = this.props.data;
        this.setState({ docType: e.target.value });
        propData[this.props.rowIndex].docType = e.target.value;
        this.props.setRowData(propData);
    }

    render() {
        const { file, docType, description } = this?.props?.dataObject
        const { docTypes } = this?.props?.docTypes;
        const rowIndex = this.props.rowIndex;
        return (
            <>
                <tbody>
                    <td>
                        <div
                            style={{
                                marginLeft: '12px',
                                marginTop: '10px'
                            }}
                        >
                            {file?.name}
                        </div>
                    </td>
                    <td>
                        <Input
                            type="select"
                            defaultValue=""
                            onChange={this.handleDocType}
                            value={docType}
                        >
                            <option value="" disabled={true}>
                                Document Type</option>
                            {docTypes.map(item => (
                                <option value={item.id}>{item.element}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input
                            type="text"
                            value={description}
                            onChange={e => this.handleDescription(e)}
                            // onPaste={(e) => {
                            //     e.preventDefault();
                            //     return false;
                            // }}
                        ></Input>
                    </td>
                    <td>
                        <div
                            style={{
                                marginLeft: '12px',
                            }}
                        >
                            {NumberUtils.formatFileSize(file?.size)}
                        </div>
                    </td>
                    {this.props.columns.map((column, columnIndex) => {
                        if (column.render) {
                            return (
                                <td key={columnIndex}>
                                    {column.render(this?.props?.dataObject, rowIndex)}
                                </td>)
                        }
                    }
                    )}

                    {/* {this.props.columns.map((column) => {
                        

                        if (column.header == 'File Name') {
                            return (
                                <td>
                                    <div
                                        style={{
                                            marginLeft: '12px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        {file?.name}
                                    </div>
                                </td>
                            );
                        }
                        if (column.header == 'Document Type') {
                            return (
                                <td>
                                    <Input
                                        type="select"
                                        defaultValue=""
                                        onChange={this.handleDocType}
                                        value={this.state.docType}
                                    >
                                        <option value="" disabled={true}>
                                            Document Type</option>
                                        {docTypes.map(item => (
                                            <option value={item.id}>{item.element}</option>
                                        ))}
                                    </Input>
                                </td>
                            );
                        }
                        if (column.header == 'Description') {
                            return (
                                <td>
                                    <Input
                                        type="text"
                                        value={this.state.description}
                                        onChange={e => this.handleDescription(e)}
                                    ></Input>
                                </td>
                            );
                        }
                        if (column.header == 'File Size') {
                            return (
                                <td>
                                    <div
                                        style={{
                                            marginLeft: '12px',
                                        }}
                                    >
                                        {NumberUtils.formatFileSize(file?.size)}
                                    </div>
                                </td>
                            );
                        }
                    })} */}
                </tbody>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        docTypes: state.FAST360.fast360Reducer.updateGetDocTypes ? state.FAST360.fast360Reducer.updateGetDocTypes : {}
    }

};

const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

Fast360FileAppTableBodyRow.propTypes = AppTableBodyPropType;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fast360FileAppTableBodyRow);
