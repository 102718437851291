export const MODULE_NAME = 'FAST360';
export const LOAD_INITIAL_DATA = 'LOAD_INITIAL_DATA';
export const LOAD_CLIENT_DATA = 'LOAD_CLIENT_DATA';
export const RESET_CLIENT_DATA = 'RESET_CLIENT_DATA';
export const UPDATE_PATIENT_ZIP_LIST = 'UPDATE_PATIENT_ZIP_LIST';
export const RESET_PATIENT_ZIP_LIST = 'RESET_PATIENT_ZIP_LIST';
export const UPDATE_DEFAULT_PERCENT = 'UPDATE_DEFAULT_PERCENT';
export const RESET_DEFAULT_PERCENT = 'RESET_DEFAULT_PERCENT';
export const UPDATE_SAVE_FEES_DATA = 'UPDATE_SAVE_FEES_DATA';
export const RESET_SAVE_FEES_DATA = 'RESET_SAVE_FEES_DATA';
export const UPDATE_SAVE_COSTS_DATA = 'UPDATE_SAVE_COSTS_DATA';
export const RESET_SAVE_COSTS_DATA = 'RESET_SAVE_COSTS_DATA';
export const UPDATE_SERVICE_ID_FROM_REF = 'UPDATE_SERVICE_ID_FROM_REF';
export const RESET_SERVICE_ID_FROM_REF = 'RESET_SERVICE_ID_FROM_REF';
export const UPDATE_LOAD_PRODUCT_SEARCH_LIST = 'UPDATE_LOAD_PRODUCT_SEARCH_LIST';
export const RESET_LOAD_PRODUCT_SEARCH_LIST = 'RESET_LOAD_PRODUCT_SEARCH_LIST';
export const UPDATE_LOAD_HCPC_LIST = 'UPDATE_LOAD_HCPC_LIST';
export const RESET_LOAD_HCPC_LIST = 'RESET_LOAD_HCPC_LIST';
export const UPDATE_LOAD_HCPC_LIST_WITH_LIMIT = 'UPDATE_LOAD_HCPC_LIST_WITH_LIMIT';
export const RESET_LOAD_HCPC_LIST_WITH_LIMIT = 'RESET_LOAD_HCPC_LIST_WITH_LIMIT';
export const UPDATE_HCPC_TERM_CHECK = 'UPDATE_HCPC_TERM_CHECK';
export const RESET_HCPC_TERM_CHECK = 'RESET_HCPC_TERM_CHECK';
export const UPDATE_SAVE_HHC_HCPC = 'UPDATE_SAVE_HHC_HCPC';
export const RESET_SAVE_HHC_HCPC = 'RESET_SAVE_HHC_HCPC';
export const UPDATE_SAVE_MSE_HCPC = 'UPDATE_SAVE_MSE_HCPC';
export const RESET_SAVE_MSE_HCPC = 'RESET_SAVE_MSE_HCPC';
export const UPDATE_SAVE_DTL_HCPC = 'UPDATE_SAVE_DTL_HCPC';
export const RESET_SAVE_DTL_HCPC = 'RESET_SAVE_DTL_HCPC';
export const UPDATE_SAVE_MOD_HCPC = 'UPDATE_SAVE_MOD_HCPC';
export const RESET_SAVE_MOD_HCPC = 'RESET_SAVE_MOD_HCPC';
export const UPDATE_DETAIL_LINE_FEE_LIST = 'UPDATE_DETAIL_LINE_FEE_LIST';
export const RESET_DETAIL_LINE_FEE_LIST = 'RESET_DETAIL_LINE_FEE_LIST';
export const UPDATE_CLIENT_FEE_LIST = 'UPDATE_CLIENT_FEE_LIST';
export const RESET_CLIENT_FEE_LIST = 'RESET_CLIENT_FEE_LIST';
export const UPDATE_VENDOR_LOOKUP_LIST = 'UPDATE_VENDOR_LOOKUP_LIST';
export const RESET_VENDOR_LOOKUP_LIST = 'RESET_VENDOR_LOOKUP_LIST';
export const UPDATE_AUD_INFO_LIST = 'UPDATE_AUD_INFO_LIST';
export const RESET_AUD_INFO_LIST = 'RESET_AUD_INFO_LIST';
export const UPDATE_TRN_INFO_LIST = 'UPDATE_TRN_INFO_LIST';
export const UPDATE_TRN_DETAILS_LIST = 'UPDATE_TRN_DETAILS_LIST';
export const UPDATE_SPT_DETAILS_LIST = 'UPDATE_SPT_DETAILS_LIST';
export const UPDATE_DELETED_TRN_LIST = 'UPDATE_DELETED_TRN_LIST';
export const UPDATE_DELETED_SPT_LIST = 'UPDATE_DELETED_SPT_LIST';
export const RESET_TRN_INFO_LIST = 'RESET_TRN_INFO_LIST';
export const UPDATE_SPT_INFO_LIST = 'UPDATE_SPT_INFO_LIST';
export const RESET_SPT_INFO_LIST = 'RESET_SPT_INFO_LIST';
export const UPDATE_SEARCH_REFERRER = 'UPDATE_SEARCH_REFERRER';
export const UPDATE_SEARCH_CLIENT = 'UPDATE_SEARCH_CLIENT';
export const UPDATE_SEARCH_CLAIMANT = 'UPDATE_SEARCH_CLAIMANT';
export const RESET_SEARCH_CLAIMANT = 'RESET_SEARCH_CLAIMANT';
export const UPDATE_LOAD_CLAIMANT_BY_ID = 'UPDATE_LOAD_CLAIMANT_BY_ID';
export const RESET_LOAD_CLAIMANT_BY_ID = 'RESET_LOAD_CLAIMANT_BY_ID';
export const UPDATE_LOAD_CLAIM = 'UPDATE_LOAD_CLAIM';
export const RESET_LOAD_CLAIM = 'RESET_LOAD_CLAIM';
export const UPDATE_LOAD_DX = 'UPDATE_LOAD_DX';
export const RESET_LOAD_DX = 'RESET_LOAD_DX';
export const UPDATE_DELETE_ICD = 'UPDATE_DELETE_ICD';
export const RESET_DELETE_ICD = 'RESET_DELETE_ICD';
export const UPDATE_DELETE_INJURY = 'UPDATE_DELETE_INJURY';
export const RESET_DELETE_INJURY = 'RESET_DELETE_INJURY';
export const UPDATE_SAVE_CLAIM = 'UPDATE_SAVE_CLAIM';
export const RESET_SAVE_CLAIM = 'RESET_SAVE_CLAIM';
export const UPDATE_SAVE_REFERRAL = 'UPDATE_SAVE_REFERRAL';
export const RESET_SAVE_REFERRAL = 'RESET_SAVE_REFERRAL';
export const RESET_SEARCH_REFERRER = 'RESET_SEARCH_REFERRER';
export const UPDATE_SELECT_ITEM_REFERRER = 'UPDATE_SELECT_ITEM_REFERRER';
export const UPDATE_DATA_FROM_REFERRER = 'UPDATE_DATA_FROM_REFERRER';
export const UPDATE_ADJUSTER_INFO = 'UPDATE_ADJUSTER_INFO';
export const RESET_ADJUSTER_INFO = 'RESET_ADJUSTER_INFO';
export const UPDATE_SEARCH_ADJUSTER = 'UPDATE_SEARCH_ADJUSTER';
export const RESET_SEARCH_ADJUSTER = 'RESET_SEARCH_ADJUSTER';
export const UPDATE_LOAD_ADJUSTER = 'UPDATE_LOAD_ADJUSTER';
export const RESET_LOAD_ADJUSTER = 'RESET_LOAD_ADJUSTER';
export const UPDATE_EMPLOYER_INFO = 'UPDATE_EMPLOYER_INFO';
export const RESET_EMPLOYER_INFO = 'RESET_EMPLOYER_INFO';
export const UPDATE_SEARCH_EMPLOYER = 'UPDATE_SEARCH_EMPLOYER';
export const RESET_SEARCH_EMPLOYER = 'RESET_SEARCH_EMPLOYER';
export const UPDATE_LOAD_EMPLOYER = 'UPDATE_LOAD_EMPLOYER';
export const RESET_LOAD_EMPLOYER = 'RESET_LOAD_EMPLOYER';
export const UPDATE_PHYSICIAN_INFO = 'UPDATE_PHYSICIAN_INFO';
export const RESET_PHYSICIAN_INFO = 'RESET_PHYSICIAN_INFO';
export const UPDATE_SEARCH_PHYSICIAN = 'UPDATE_SEARCH_PHYSICIAN';
export const RESET_SEARCH_PHYSICIAN = 'RESET_SEARCH_PHYSICIAN';
export const UPDATE_LOAD_PHYSICIAN = 'UPDATE_LOAD_PHYSICIAN';
export const RESET_LOAD_PHYSICIAN = 'RESET_LOAD_PHYSICIAN';
export const UPDATE_ATTORNEY_INFO = 'UPDATE_ATTORNEY_INFO';
export const RESET_ATTORNEY_INFO = 'RESET_ATTORNEY_INFO';
export const UPDATE_SEARCH_ATTORNEY = 'UPDATE_SEARCH_ATTORNEY';
export const RESET_SEARCH_ATTORNEY = 'RESET_SEARCH_ATTORNEY';
export const UPDATE_LOAD_ATTORNEY = 'UPDATE_LOAD_ATTORNEY';
export const RESET_LOAD_ATTORNEY = 'RESET_LOAD_ATTORNEY';
export const UPDATE_CASE_MANAGER_INFO = 'UPDATE_CASE_MANAGER_INFO';
export const RESET_CASE_MANAGER_INFO = 'RESET_CASE_MANAGER_INFO';
export const UPDATE_SEARCH_CASE_MANAGER = 'UPDATE_SEARCH_CASE_MANAGER';
export const RESET_SEARCH_CASE_MANAGER = 'RESET_SEARCH_CASE_MANAGER';
export const UPDATE_LOAD_CASE_MANAGER = 'UPDATE_LOAD_CASE_MANAGER';
export const RESET_LOAD_CASE_MANAGER = 'RESET_LOAD_CASE_MANAGER';
export const RESET_SEARCH_CLIENT = 'RESET_SEARCH_CLIENT';
export const UPDATE_LOAD_REFERRER = 'UPDATE_LOAD_REFERRER';
export const UPDATE_LOAD_CLIENT = 'UPDATE_LOAD_CLIENT';
export const RESET_LOAD_REFERRER = 'RESET_LOAD_REFERRER';
export const RESET_LOAD_CLIENT = 'RESET_LOAD_CLIENT';
export const UPDATE_LOCATION_LIST = 'UPDATE_LOCATION_LIST';
export const UPDATE_ZIP_LIST = 'UPDATE_ZIP_LIST';
export const RESET_ZIP_LIST = 'RESET_ZIP_LIST';
export const UPDATE_REF_INFO = 'UPDATE_REF_INFO';
export const UPDATE_CLIENT_INFO = 'UPDATE_CLIENT_INFO';
export const RESET_CLIENT_INFO = 'RESET_CLIENT_INFO';
export const RESET_REF_INFO = 'RESET_REF_INFO';
export const UPDATE_USER_WORK_LIST = 'UPDATE_USER_WORK_LIST';
export const UPDATE_NEW_USER_WORK_LIST = 'UPDATE_NEW_USER_WORK_LIST';
export const UPDATE_CLICK_RECORD = 'UPDATE_CLICK_RECORD';
export const UPDATE_GET_NOTES_DATA = 'UPDATE_GET_NOTES_DATA';
export const UPDATE_LOAD_ELIGIBILITY = 'UPDATE_LOAD_ELIGIBILITY';
export const UPDATE_GET_FOLLOWUP_DATA = 'UPDATE_GET_FOLLOWUP_DATA';
export const UPDATE_ADV_SEARCH = 'UPDATE_ADV_SEARCH';
export const UPDATE_LEG_STATUS = 'UPDATE_LEG_STATUS';
export const UPDATE_LOAD_HCPC_QUICK = 'UPDATE_LOAD_HCPC_QUICK';
export const UPDATE_CLIENT_LOOKUP = 'UPDATE_CLIENT_LOOKUP';
export const UPDATE_COST_SAVINGS = 'UPDATE_COST_SAVINGS';
export const RESET_COST_SAVINGS = 'RESET_COST_SAVINGS';
export const UPDATE_AUD_HCPC_LIST = 'UPDATE_AUD_HCPC_LIST';
export const RESET_AUD_HCPC_LIST = 'RESET_AUD_HCPC_LIST';
export const UPDATE_LOOK_UP_SPLOCATION = 'UPDATE_LOOK_UP_SPLOCATION';
export const UPDATE_GET_LOAD_NOTES = 'UPDATE_GET_LOAD_NOTES';
export const UPDATE_STATUS_CLICKED = 'UPDATE_STATUS_CLICKED';
export const UPDATE_STATUS_DATA = 'UPDATE_STATUS_DATA';
export const UPDATE_TASK_CLICKED = 'UPDATE_TASK_CLICKED';
export const UPDATE_TASK_DATA = 'UPDATE_TASK_DATA';
export const UPDATE_MILESTONE_CLICKED = 'UPDATE_MILESTONE_CLICKED';
export const UPDATE_MILESTONE_DATA = 'UPDATE_MILESTONE_DATA';
export const UPDATE_PREVIOUS_USER = 'UPDATE_PREVIOUS_USER';
export const TASK_WORKLIST_BACKUP = 'TASK_WORKLIST_BACKUP';
export const MILESTONE_WORKLIST_BACKUP = 'MILESTONE_WORKLIST_BACKUP';
export const STATUS_WORKLIST_BACKUP = 'STATUS_WORKLIST_BACKUP';
export const MILESTONE_AGAIN_CLICKED = 'MILESTONE_AGAIN_CLICKED';
export const UPDATE_GET_CANCEL_REASONS = 'UPDATE_GET_CANCEL_REASONS';
export const UPDATE_POST_CANCEL_SERVICES = 'UPDATE_POST_CANCEL_SERVICES';
export const UPDATE_POST_OUTCOMES = 'UPDATE_POST_OUTCOMES';
export const LOAD_ADVSEARCH_REPORT = 'LOAD_ADVSEARCH_REPORT';
export const RESET_ADVSEARCH_REPORT = 'RESET_ADVSEARCH_REPORT';
export const UPDATE_GET_EMAIL_ADDRESS = 'UPDATE_GET_EMAIL_ADDRESS';
export const UPDATE_GET_EXTERNAL_REFERRAL = 'UPDATE_GET_EXTERNAL_REFERRAL';
export const UPDATE_GET_AP_EXTRACT = 'UPDATE_GET_AP_EXTRACT';
export const UPDATE_POST_EXTERNAL = 'UPDATE_POST_EXTERNAL';
export const UPDATE_POST_EXTERNAL_CLIENT = 'UPDATE_POST_EXTERNAL_CLIENT';
export const UPDATE_GP_DATA = 'UPDATE_GP_DATA';
export const UPDATE_LOAD_FROM_WORKLIST = 'UPDATE_LOAD_FROM_WORKLIST';
export const UPDATE_LOAD_EXISTING_TASKS = 'UPDATE_LOAD_EXISTING_TASKS';
export const UPDATE_LOAD_CURRENT_TASK_ID = 'UPDATE_LOAD_CURRENT_TASK_ID';
export const UPDATE_PRODUCT_CATALOG = 'UPDATE_PRODUCT_CATALOG';
export const UPDATE_LOAD_REFERRAL_ALERTS = 'UPDATE_LOAD_REFERRAL_ALERTS';
export const UPDATE_GROUP_PROGRESSION = 'UPDATE_GROUP_PROGRESSION';
export const SELECTED_EXTERNAL_DATA = 'SELECTED_EXTERNAL_DATA';
export const UPDATE_VENDOR_LOOKUP = 'UPDATE_VENDOR_LOOKUP';
export const RESET_VENDOR_LOOKUP = 'RESET_VENDOR_LOOKUP';
export const SELECT_FROMWORKLIST_SERVICES = 'SELECT_FROMWORKLIST_SERVICES';
export const UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES = 'UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES';
export const GET_AP_INFO = 'GET_AP_INFO';
export const SAVE_AP_INFO = 'SAVE_AP_INFO';
export const SAVE_OBJ = 'SAVE_OBJ';
export const QUICK_SEARCH_CHECK = 'QUICK_SEARCH_CHECK';
export const MERGE_FILTER_DATA = ' MERGE_FILTER_DATA';
export const SET_CURRENT_WORK_LIST = 'SET_CURRENT_WORK_LIST';
export const SET_VENDOR_LOOKUP_SUGGESTIONS = 'SET_VENDOR_LOOKUP_SUGGESTIONS';
export const SET_VENDOR_LOOKUP_TOP5BYLOCATION = 'SET_VENDOR_LOOKUP_TOP5BYLOCATION';
export const SET_VENDOR_LOOKUP_TOP5BYCOUNT = 'SET_VENDOR_LOOKUP_TOP5BYCOUNT';
export const SET_EVALUATE_VENDOR_DATA = 'SET_EVALUATE_VENDOR_DATA';
export const SET_VENDOR_HISTORY_DATA = 'SET_VENDOR_HISTORY_DATA';
export const RESET_VENDOR_LOOKUP_DATA = 'RESET_VENDOR_LOOKUP_DATA';
export const UPDATE_REQUIRED_FIELDS = ' UPDATE_REQUIRED_FIELDS';
export const UPDATE_GET_DOCTYPES = ' UPDATE_GET_DOCTYPES';
export const UPDATE_GET_CC_DOCUMENTS = ' UPDATE_GET_CC_DOCUMENTS';
export const UPDATE_SAVE_FILEUPLOAD = ' UPDATE_SAVE_FILEUPLOAD';
export const UPDATED_FILES_DATA = ' UPDATED_FILES_DATA';
export const UPDATE_DELETE_DOCUMENT = ' UPDATE_DELETE_DOCUMENT';
export const UPDATE_RETRIVE_DOCUMENT = ' UPDATE_RETRIVE_DOCUMENT';
export const RESET_UPLOADED_DOCUMENT = ' RESET_UPLOADED_DOCUMENT';
export const UPDATE_UPLOADED_DOCUMENT = 'UPDATE_UPLOADED_DOCUMENT';
export const REQUIRED_SERVICE_DATA = ' REQUIRED_SERVICE_DATA';
export const UPDATE_GET_CLAIM_AUTH = ' UPDATE_GET_CLAIM_AUTH';
export const UPDATE_SAVE_AUTH_HISTORY = ' UPDATE_SAVE_AUTH_HISTORY';
export const UPDATE_USERS_TOT_ORDERS = 'UPDATE_USERS_TOT_ORDERS';
export const RESET_USERS_TOT_ORDERS = 'RESET_USERS_TOT_ORDERS';
export const UPDATE_USERS_OOC = 'UPDATE_USERS_OOC';
export const RESET_USERS_OOC = 'RESET_USERS_OOC';
export const UPDATE_USERS_RUSH = 'UPDATE_USERS_RUSH';
export const RESET_USERS_RUSH = 'RESET_USERS_RUSH';
export const UPDATE_NEW_ORDERS = 'UPDATE_NEW_ORDERS';
export const RESET_NEW_ORDERS = 'RESET_NEW_ORDERS';
export const UPDATE_FOLLOWUPS = 'UPDATE_FOLLOWUPS';
export const RESET_FOLLOWUPS = 'RESET_FOLLOWUPS';
export const UPDATE_SAVE_AUTH_SERVICE = ' UPDATE_SAVE_AUTH_SERVICE';
export const UPDATE_CLEAR_AUTH_SERVICE = ' UPDATE_CLEAR_AUTH_SERVICE';
export const UPDATE_GET_AUTH_SERVICE = ' UPDATE_GET_AUTH_SERVICE';
export const SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA = 'SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA';
export const SELECTED_AUTH_RECORD = 'SELECTED_AUTH_RECORD';
export const SET_SELECTED_VENDOR_DATA = "SET_SELECTED_VENDOR_DATA";
export const SAVE_CONFIRM_CONTACT_LIST = "SAVE_CONFIRM_CONTACT_LIST";
export const SAVE_LMS_DOCUMENT_EMAIL = "SAVE_LMS_DOCUMENT_EMAIL";
export const SAVE_REPORT_TEMPLATES_BODY = "SAVE_REPORT_TEMPLATES_BODY";
export const SAVE_ATTACHMENT_VALIDATION_RESULT = "SAVE_ATTACHMENT_VALIDATION_RESULT";
export const REFERRAL_TRANSFERED = 'REFERRAL_TRANSFERED';
export const CLEAR_REFERRAL_TRANSFERED = 'CLEAR_REFERRAL_TRANSFERED';
export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_SUB_PRODUCT_DATA = "SET_SUB_PRODUCT_DATA";
export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
export const DETAIL_EMAIL_SENT = "DETAIL_EMAIL_SENT";
export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";
export const UPDATE_GP_INFO = "UPDATE_GP_INFO";
export const SET_INPUT_FIELDS = "SET_INPUT_FIELDS";
export const SET_ELIGIBILITY = "SET_ELIGIBILITY";
export const UPDATE_QUICK_SEARCH_LIST = 'UPDATE_QUICK_SEARCH_LIST';
export const RESET_QUICK_SEARCH_LIST = 'RESET_QUICK_SEARCH_LIST';
export const CLEAR_ELIGIBILITY = 'CLEAR_ELIGIBILITY';
export const STATUS_BACKUP = 'STATUS_BACKUP';
export const MILESTONE_BACKUP = 'MILESTONE_BACKUP';
export const TASK_BACKUP = 'TASK_BACKUP';
export const PRODUCT_BACKUP = 'PRODUCT_BACKUP';
export const SUBPRODUCT_BACKUP = 'SUBPRODUCT_BACKUP';
export const REQ_FIELDS = 'REQ_FIELDS';
export const SAVE_GREAT_PLAINS = 'SAVE_GREAT_PLAINS';
export const CLEAR_GREAT_PLAINS = 'CLEAR_GREAT_PLAINS';
export const TRN_DETAIL_MODAL_DATA = 'TRN_DETAIL_MODAL_DATA';
export const TRN_TABLE_DETAILS = 'TRN_TABLE_DETAILS';
export const SPT_TABLE_DETAILS = 'SPT_TABLE_DETAILS';
export const SAVE_RENTAL_SERVICE_REFERRAL = 'SAVE_RENTAL_SERVICE_REFERRAL';
export const SET_LAST_SERVICE_MODULE = 'SET_LAST_SERVICE_MODULE';
export const CLEAR_ATTACHMENT_VALIDATION_RESULT = 'CLEAR_ATTACHMENT_VALIDATION_RESULT';
export const SET_VENDOR_DATA = 'SET_VENDOR_DATA';
export const SET_LAST_SUB_SERVICE_MODULE = 'SET_LAST_SUB_SERVICE_MODULE';
export const RESET_VENDOR_LOOKUP_SUGGESTIONS = 'RESET_VENDOR_LOOKUP_SUGGESTIONS';
export const SET_SPT_DETAILS = 'SET_SPT_DETAILS';
export const SET_TRN_DETAILS = 'SET_TRN_DETAILS';
export const SPT_SAVE_DATA = 'SPT_SAVE_DATA';
export const TRN_SAVE_DATA = 'TRN_SAVE_DATA';
export const UPDATE_DETAIL_PROVIDER = 'UPDATE_DETAIL_PROVIDER';
export const UPDTAE_FOLLOWUP_DATE = 'UPDTAE_FOLLOWUP_DATE';
export const FOLLOW_UP_CHANGE_EVENT = 'FOLLOW_UP_CHANGE_EVENT';
export const FOLLOW_UP_DATE_EVENT = 'FOLLOW_UP_DATE_EVENT';
export const UPDATE_LINE_ITEMS_LIST = 'UPDATE_LINE_ITEMS_LIST';
export const UPDATE_LINE_ITEM_DETAILS = 'UPDATE_LINE_ITEM_DETAILS';
export const UPDATE_PAYABLE_LINES = 'UPDATE_PAYABLE_LINES';
export const SET_SELECTED_FILTER_TYPE = 'SET_SELECTED_FILTER_TYPE';
export const SET_ADV_VALUES = 'SET_ADV_VALUES';
export const UPDATE_TOGGLE_VALUES = 'UPDATE_TOGGLE_VALUES';
export const UPDATE_INVOICE_TASK_DATA = 'UPDATE_INVOICE_TASK_DATA';
export const IS_LOGOUT = 'IS_LOGOUT';
export const APPROVAL_DENIAL_REASONS = 'APPROVAL_DENIAL_REASONS';
export const SKIP_PAYABLE_LINES = 'SKIP_PAYABLE_LINES';
export const SET_SELECTED_INVOICE_COORDINATOR_TABLE = 'SET_SELECTED_INVOICE_COORDINATOR_TABLE';
export const SET_SELECTED_INVOICE_COORDINATOR_USER = 'SET_SELECTED_INVOICE_COORDINATOR_USER';
export const SET_NPPES_PHYSICIANS_LIST = "SET_NPPES_PHYSICIANS_LIST";
export const RESET_NPPES_PHYSICIANS_LIST = "RESET_NPPES_PHYSICIANS_LIST";
export const VM_PROVIDER_TYPES = 'VM_PROVIDER_TYPES';
export const VM_GROUPS = 'VM_GROUPS';
export const UPDATE_VM_RESULTS = 'UPDATE_VM_RESULTS';
export const RESET_PROVIDER_RESULTS = 'RESET_PROVIDER_RESULTS';



export default {
  MODULE_NAME,
  LOAD_INITIAL_DATA,
};
