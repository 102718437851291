import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import { formatMMDDYYYY } from 'shared/utils/shareUtils';

import { renderClientName } from '../utils/billMaintenanceUtil';

class SiteMaintenanceFormMasterClient extends Component {
  onMasterClientEnterPress = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.props.loadMasterClientDataProvider(event.target.value);
  };

  render() {
    const id = this.props.siteDetails.id || 0;
    const taxId = this.props.masterSiteDetails.taxId || '';
    const carrierType = this.props.masterSiteDetails.carrierType || '';
    const dateRange = this.props.masterSiteDetails.dateRange || {};
    const effDate = dateRange.effDate || '';
    const termDate = dateRange.termDate || '';
    const contactFields =
      (this.props.masterSiteDetails.contact || {}).fields || {};
    const contact = contactFields.contact || '';
    const phone1 = contactFields.phone1 || '';
    const fax = contactFields.fax || '';
    const eorProfile = this.props.masterSiteDetails.eorProfile || '';
    const naic = this.props.masterSiteDetails.naic || '';
    const serviceCompanyCode =
      this.props.masterSiteDetails.serviceCompanyCode || 0;
    return (
      <>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="masterClient">
                <span className="bold-text">Master Client</span>
                {this.props.parentId > 0 && (
                  <span className="bold-text" style={{ marginLeft: 10 }}>
                    Master: {this.props.parentId}
                  </span>
                )}
                {id > 0 && (
                  <span className="bold-text" style={{ marginLeft: 10 }}>
                    Site: {id}
                  </span>
                )}
              </Label>
              <AppAutocomplete
                id="masterClient"
                data={this.props.masterClientDataProvider}
                value={this.props.masterClient}
                displayOption="chr_sitename"
                placeholder=""
                onChange={this.props.onInputChangeHandler}
                onSelect={this.props.onMasterClientSelect}
                renderItem={renderClientName}
                inputProps={{
                  onKeyPress: this.onMasterClientEnterPress,
                }}
                showToolTip={false}
                updateOnLeave={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Row>
              <Col lg="3">TIN:</Col>
              <Col lg="9">{taxId}</Col>
            </Row>
            <Row>
              <Col lg="3">Type:</Col>
              <Col lg="9">{carrierType}</Col>
            </Row>
            <Row>
              <Col lg="3">Eff:</Col>
              <Col lg="9">{formatMMDDYYYY(effDate)}</Col>
            </Row>
            <Row>
              <Col lg="3">Term:</Col>
              <Col lg="9">{formatMMDDYYYY(termDate)}</Col>
            </Row>
          </Col>
          <Col lg="4">
            <Row>
              <Col lg="3">Contact:</Col>
              <Col lg="9">{contact}</Col>
            </Row>
            <Row>
              <Col lg="3">Phone:</Col>
              <Col lg="9">{phone1}</Col>
            </Row>
            <Row>
              <Col lg="3">Fax:</Col>
              <Col lg="9">{fax}</Col>
            </Row>
          </Col>
          <Col lg="4">
            <Row>
              <Col lg="3">Logo:</Col>
              <Col lg="9">{eorProfile}</Col>
            </Row>
            <Row>
              <Col lg="3">NAIC#:</Col>
              <Col lg="9">{naic}</Col>
            </Row>
            <Row>
              <Col lg="3">SVC CO:</Col>
              <Col lg="9">{serviceCompanyCode}</Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

SiteMaintenanceFormMasterClient.propTypes = {
  masterClient: PropTypes.string.isRequired,
  parentId: PropTypes.number.isRequired,
  masterClientDataProvider: PropTypes.array.isRequired,
  siteDetails: PropTypes.object.isRequired,
  masterSiteDetails: PropTypes.object.isRequired,
  loadMasterClientDataProvider: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onMasterClientSelect: PropTypes.func.isRequired,
};

export default SiteMaintenanceFormMasterClient;
