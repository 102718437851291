import axios from 'axios';
import * as R from 'ramda';

import helper from 'helper/helper';
import objectUtils from 'core/utils/objectUtils';
import adminObjectUtils from '../utils/adminObjectUtils';


import {
  findUserTransform,
 // loadUserDataTransform,
  resetPasswordTransform,
  loadLocationsTransform,
  resendPasswordTransform,
  loadUserWorklistLinksTransform,
  canTransferBillDataTransform,
  updateAdjusterMapDataTransform,
  getCarrierListTransform,
  saveUserInformationTransform,
  saveAncilUserTransform,
  deleteWorklistLinksTransform,
} from './transform';

const findUser = (name, location) => {
  return helper.request.post(
    '/findUser',
    findUserTransform.toRequestBody(name, location)
  );
};

const getZebraUserData = id => {
  return helper.request.post(
    '/getZebraUserData',
    loadUserDataTransform.toRequestBody(id)
  );
};

const loadUserDataTransform = {
  toRequestBody: id => {
    return {
      'query-vw_user_edit.vw_user_edit': {
        user_id: id,
      },
      'picklist.salutation': {
        name: 'salutation',
      },
      'picklist.gender': {
        name: 'gender',
      },
      'picklist.language': {
        name: 'language',
      },
      'picklist.honorific': {
        name: 'honorific',
      },
      'picklist.locations': {
        name: 'userlocations',
      },
      'query-vw_module_search.modules': {
        id: '%',
      },
      'query-vw_users_modules_groups.users_modules': {
        user_id: id,
      },
      'query-userroles.userroles': {
        id: '%',
      },
      'queryattr-vw_user_search.users': {
        user_id: id,
      },
      'queryattr-vw_client_search.clients': {
        id: '%',
        asc: 'name',
      },
      'queryattr-client_user.user_clients': {
        user_id: id,
      },
      'queryattr-workflows.teams': {
        id: '%',
      },
    };
  },
  fromResponseBody: (
    zebraUserData,
    loadView,
    loadAncilUserRoles,
    loadUsersAncilRoles,
    carrierList
  ) => {
    const zebraUserDataResult = zebraUserData.data.result;
    const loadViewData = loadView.data.result;
    const loadAncilUserRolesData = loadAncilUserRoles.data.result;
    const loadUsersAncilRolesData = loadUsersAncilRoles.data.result;
    const carrierListData = carrierList.data.result;

    let userId = -1;
    if (zebraUserDataResult.vwUserEdit.id !== undefined) {
      userId = zebraUserDataResult.vwUserEdit.id;
    }

    const payload = {
      userId: userId,
      userBasics: adminObjectUtils.parseBasicsData(
        zebraUserDataResult,
        carrierListData
      ),
      userProfileList: loadViewData.userProfileList,
      userAccess: adminObjectUtils.parseAccessData(zebraUserDataResult),
      userAncilAccess: adminObjectUtils.parseAncilAccessData(
        loadAncilUserRolesData,
        loadUsersAncilRolesData
      ),
      userBillReview: adminObjectUtils.parseBillReviewData(loadViewData),
      //userBillApproval: adminObjectUtils.parseBillApprovalData(zebraUserDataResult),
      userBillApproval: adminObjectUtils.parseBillApprovalData(loadViewData),
      userAdjusterMapping: adminObjectUtils.parseAdjusterMappingData(
        loadViewData
      ),
      userComments: {
        comments: zebraUserDataResult.vwUserEdit.person.comments,
      },
      userClients: adminObjectUtils.parseClients(zebraUserDataResult),
    };

 
    return objectUtils.setNullValues(payload, '');
  },
};

const loadAncilUserRoles = () => {
  return helper.request.get('/loadAncilUserRoles');
};

const loadUsersAncilRoles = id => {
  return helper.request.post('/loadUsersAncilRoles', { userId: id });
};

const loadView = id => {
  return helper.request.get(`/loadView/${id}`);
};

const getCarrierList = () => {
  return helper.request.post(
    '/loadListEntriesByCriteria',
    getCarrierListTransform.toRequestBody()
  );
};

const getUserInformation = id => {
  return axios
    .all([
      getZebraUserData(id),
      loadView(id),
      loadAncilUserRoles(),
      loadUsersAncilRoles(id),
      getCarrierList(),
    ])
    .then(
      axios.spread(
        (
          zebraUserData,
          loadView,
          loadAncilUserRoles,
          loadUsersAncilRoles,
          carrierList
        ) => {
          return loadUserDataTransform.fromResponseBody(
            zebraUserData,
            loadView,
            loadAncilUserRoles,
            loadUsersAncilRoles,
            carrierList
          );
        }
      )
    );
};

const getAdjusterMappingLists = (userId, profile) => {
  return helper.request
    .post(
      '/loadUserWorklistLinks',
      loadUserWorklistLinksTransform.toRequestBody(userId, profile)
    )
    .then(response => {
      return loadUserWorklistLinksTransform.fromResponseBody(response, profile);
    });
};

const saveUserInformation = user => {
  return helper.request.post(
    '/saveZebraUserData',
    saveUserInformationTransform.toRequestBody(user)
  );
};

const saveAncilUser = (user, userId) => {
  return helper.request.post(
    '/saveAncilUser',
    saveAncilUserTransform.toRequestBody(user, userId)
  );
}

const deleteWorklistLinks = (user, userId) => {
  return helper.request.post(
    '/deleteWorklistLinks',
    deleteWorklistLinksTransform.toRequestBody(user, userId)
  )
}

const resetPassword = userId => {
  return helper.request.post(
    '/resetPassword',
    resetPasswordTransform.toRequestBody(userId)
  );
};

const loadLocations = async() => {
  const response = await helper.request.post('/loadLocations', {});

  const responseData = response.data ? response.data: {};

  return responseData;

};

const resendPassword = userId => {
  return helper.request.post(
    '/resendPassword',
    resendPasswordTransform.toRequestBody(userId)
  );
};

const clearSecurityQuestion = userId => {
  return helper.request.get(`/clearSecurityQuestion/${userId}`);
};

const getAdjusterList = siteId => {
  return helper.request.post('/loadAdjusterList', { siteId: siteId });
};

const canUserTransferBill = (billId, userId, profile) => {
  return helper.request.post(
    '/canTransferBillData',
    canTransferBillDataTransform.toRequestBody(billId, userId, profile)
  );
};

const updateAdjusterMapData = (
  userId,
  linkedUserId,
  profile,
  firstName,
  lastName
) => {
  return helper.request.post(
    '/updateAdjusterMapData',
    updateAdjusterMapDataTransform.toRequestBody(
      userId,
      linkedUserId,
      profile,
      firstName,
      lastName
    )
  );
};

export default {
  findUser,
//  getUserInfoNew,
  getUserInformation,
  getAdjusterMappingLists,
  getAdjusterList,
  canUserTransferBill,
  updateAdjusterMapData,
  saveUserInformation,
  saveAncilUser,
  deleteWorklistLinks,
  resetPassword,
  resendPassword,
  loadLocations,
  clearSecurityQuestion,
};
