import React, { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  fast360Actions,
  fast360Selectors,
  fast360Operations,
} from '../store/index';
import AppDateInput from 'shared/components/input/AppDateInput';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Row, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import ServicesSelection from './ServicesSelection';
import Fast360EditButtons from './Fast360EditButtons';
import Fast360Acc from './Fast360Acc';
import Fast360AUD from './Fast360AUD';
import Fast360MSE from './Fast360MSE';
import Fast360DTL from './Fast360DTL';
import Fast360DGN from './Fast360DGN';
import Fast360HHC from './Fast360HHC';
import Fast360INT from './Fast360INT';
import Fast360TRN from './Fast360TRN';
import Fast360SPT from './Fast360SPT';
import Fast360MOD from './Fast360MOD';
import Fast360Vendor from './Fast360Vendor';
import Fast360Notes from './Fast360Notes';
import Fast360PlaceOfService from './Fast360PlaceOfService';
import DashboardButton from './DashboardButton';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { Modal, ModalHeader, ModalBody, CustomInput, Form } from 'reactstrap';
import { props } from 'ramda';
import Fast360DetailEmail from './Fast360DetailEmail';
import Fast360Eligilibity from './Fast360Eligilibity';
import Fast360WorklistFiles from './Fast360WorklistFiles';
import TrasnserFast360 from './TrasnserFast360';
import SwitchServices from './SwitchServices';
import ClaimClientVenNot from './ClaimClientVenNot';
import { shortToDatabaseTable } from '../utils/dataUtils';
import * as FetchUtils from 'shared/utils/fetchUtils';
import { commonSelectors } from 'common/store';

import PopupModal from 'shared/components/PopupModal';

let combinedServicesCount;
const fromEmails = [
  {
    name: 'Interpretation',
    email: 'Interpretation@careworks.com',
  },
  {
    name: 'Transportation',
    email: 'Transportation@careworks.com',
  },
];
//Test Commit to force a build.
const Fast360Services = ({
  disableSave,
  setDisableSave,
  isFromLanding,
  module,
  onSaveComp,
  serviceVal,
  instValue,
  setRefTransportation,
  setDetails,
  refTransportation,
  lmsUserId,
  selectedExtData,
  updatePostExternal, //selectedWorklistFromLanding,
  updateLoadFromWorkList,
  hhcCategory,
  mseCategory,
  appointmentType,
  accommodationType,
  translationCategory,
  alertData,
  translationCertification,
  diagnosticType,
  modificationCategory,
  transportCategory,
  specialTransportCategory,
  getCurrentTaskId,
  currentTaskId,
  loadReferralAlerts,
  relationShipType,
  userId,
  setVendorData,
  postupdateLoadFromWorkList,
  serviceType,
  data,
  loadReferralWorkList,
  dataObject,
  loadExistingTasks,
  worklistData,
  groupTasksProgression,
  callDynamicLoad,
  fromExternal,
  cmbBillReason,
  cmbAltBillReason,
  cmbMissedBillReason,
  requiredServiceData,
  fromWorklist,
  sendFinalizeAssignmentEmail,
  getFinalizeAssignmentEmailBody,
  finalizeEmailAssignmentData,
  logBreadCrumbs,
  // updateLoadFromWorkList,
  selectedVendorData,
  currentWorkList,
  gpIntegrationList,
  serviceData,
  readyForBilling,
  setReadyForBilling,
  setVendorDataInfo,
  loadReferralandVender,
  externalReferralData,
  loadCostSavings,
  loadAudiologyHcpcsList,
  resetAudiologyHcpcsList,
  resetCostSavings,
  sptsaveData,
  trnSaveData,
  trnsaveInfo,
  sptSaveinfo,
  updateTRNInfoTable,
  trnDetailsList,
  updateSPTInfoTable,
  updateloadReferralAlerts,
  sptDetailsList,
  trnDeletedList,
  sptDeletedList,
  appLoading,
  setFollowupDate360,
  setAdvValues,
  onWorklistExit,
  loadPayableLines
}) => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [tempselectedItem, setTempSelectedItem] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState('');
  const [servicesData, setServicesData] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [index, setIndex] = useState(0);
  const [emailClicked, setEmailClicked] = useState(false);
  const [fileClicked, setFileClicked] = useState(false);
  const [eligibilityClicked, setEligibilityClicked] = useState(false);
  const [transferClicked, setTransferClicked] = useState(false);
  const [rushText, setRushText] = useState('');
  const [itemChanged, setItemChanged] = useState(false);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [currentOutcome, setCurrentOutcome] = useState('');
  const resettingRef = useRef(false);
  const [reasonList, setReasonList] = useState([]);
  const { vendor = {} } = selectedItem || {};
  const [showAssignmentButton, setShowAssignmentButton] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [finalizedBodyPreview, setFinalizedBodyPreview] = useState(false);
  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] = useState(
    false
  );
  const [isUppdateEmail, setIsUpdateEmail] = useState(false);
  const [show, setShow] = useState(false);
  const [referralAlertData, setReferralAlertData] = useState([]);
  
  useEffect(() => {
    if (alertData.length > 0) {
      setReferralAlertData(alertData);
      setIsNoteOpen(true);
    }
  }, [alertData])

  useEffect(() => {
    if (!isEqual(serviceVal)) {
      onSaveComp(servicesData, instValue, combinedServicesCount);
    }
  }, [serviceVal, servicesData]);


  useEffect(() => {
    setSelectedModule(selectedExtData.svc ? selectedExtData.svc : '');
    selectedExtData.svc && addService(selectedExtData.svc);
  }, []);

  const handleTransfer = () => {
    setTransferClicked(!transferClicked);
  };
  const handleTransferFromModal = () => {
    handleTransfer();
  };

  const openNotesModal = () => {
    setOpenNotes(!openNotes);
  };

  const getDataFromLanding = () => {
    let combinedArr = [];
    let audArr = [],
      hhcArr = [],
      accmArr = [],
      denArr = [],
      tranArr = [],
      diagArr = [],
      trnArr = [],
      mseArr = [],
      modArr = [],
      stpArr = [];

    servicesData.splice(0, servicesData.length);
    setServicesData(servicesData);
    //const moduleSelected = selectedWorklistFromLanding.chr_reference_table;
    if (updateLoadFromWorkList) {
      const {
        audiologyList,
        hhcList,
        accommodationList,
        dentalList,
        translationList,
        diagnosticList,
        mseList,
        transportationList,
        modificationList,
        specialTransportationList,
      } = updateLoadFromWorkList;
      if (
        audiologyList &&
        hhcList &&
        accommodationList &&
        dentalList &&
        translationList &&
        diagnosticList &&
        mseList &&
        transportationList &&
        modificationList &&
        specialTransportationList
      ) {
        audiologyList.length > 0 &&
          audiologyList.map(i => audArr.push({ ...i, modSelected: 'AUD' }));
        hhcList.length > 0 &&
          hhcList.map(i => hhcArr.push({ ...i, modSelected: 'HHC' }));
        accommodationList.length > 0 &&
          accommodationList.map(i =>
            accmArr.push({ ...i, modSelected: 'ACC' })
          );
        dentalList.length > 0 &&
          dentalList.map(i => denArr.push({ ...i, modSelected: 'DTL' }));
        translationList.length > 0 &&
          translationList.map(i => tranArr.push({ ...i, modSelected: 'INT' }));
        diagnosticList.length > 0 &&
          diagnosticList.map(i => diagArr.push({ ...i, modSelected: 'DGN' }));
        mseList.length > 0 &&
          mseList.map(i => mseArr.push({ ...i, modSelected: 'MSE' }));
        transportationList.length > 0 &&
          transportationList.map(i =>
            trnArr.push({ ...i, modSelected: 'TRN' })
          );
        modificationList.length > 0 &&
          modificationList.map(i => modArr.push({ ...i, modSelected: 'MOD' }));
        specialTransportationList.length > 0 &&
          specialTransportationList.map(i =>
            stpArr.push({ ...i, modSelected: 'SPT' })
          );

        combinedArr = [
          ...audArr,
          ...hhcArr,
          ...accmArr,
          ...denArr,
          ...tranArr,
          ...mseArr,
          ...trnArr,
          ...modArr,
          ...stpArr,
          ...diagArr,
        ];
      }
    }
    if (
      fromExternal &&
      fromExternal.fromExternal &&
      externalReferralData.length
    ) {
      addServiceFromLanding(
        externalReferralData[0].svc,
        externalReferralData[0],
        externalReferralData[0],
        true
      );
    }
    combinedServicesCount = combinedArr?.length;

    /* combined mod*/
    combinedArr.map(item => {
      addServiceFromLanding(item.modSelected, item, updateLoadFromWorkList);
    });
    //setServicesData(serData);
    const serID = serviceData?.selectedService ? serviceData?.selectedService?.id : data?.serviceId;

    let foundIndex = servicesData.findIndex(i => i.serviceId == serID);
    if (foundIndex > -1) {
      if (setVendorDataInfo.flag) {
        setSelectedItem(setVendorDataInfo.selectedSubService);
      } else {
        setSelectedItem(servicesData[foundIndex]);
      }
  
      setDisableSave(servicesData[foundIndex].sentToGP)
      setDetails(servicesData[foundIndex]);
      onModuleChange(servicesData[foundIndex].module);
      setRefTransportation(servicesData[foundIndex].module);
      setSelectedModule(servicesData[foundIndex].module);
      setIndex(foundIndex);
      setRushText('');
      if (servicesData[foundIndex] == 52) {
        setReasonList(cmbAltBillReason);
      } else if (servicesData[foundIndex] == 1050) {
        setReasonList(cmbMissedBillReason);
      } else {
        setReasonList(cmbBillReason);
      }
      if (servicesData[foundIndex].rush) {
        setRushText('RUSH');
      }

      let taskPayload = {
       // functionName: 'loadExistingTasks',
        loadCurrentTaskId: true,
        loadGroupTaskProgression: true,
        loadExistingTasks: true,
        loadReferralAlerts: false,
        loadFollowUpdInfo : false,
        zebraUserId: userId,
        table: '',
        referralId: servicesData[foundIndex].referralId,
        tableAbbrv: servicesData[foundIndex].module,
        serviceId: servicesData[foundIndex].serviceId,
        currentTask: servicesData[foundIndex].currentTaskId,
        referenceTable: servicesData[foundIndex].module,
        claimNo: data.claimNo,
        userid: userId,
        breadcrumbkey: 'VIEWSERVICE',
        breadcrumbrefTableId: servicesData[foundIndex].serviceId,
        breadcrumbrefId: servicesData[foundIndex].referralId,
        breadcrumbrefTable: servicesData[foundIndex].module,
      };
      callDynamicLoad(taskPayload);
     /* let callPaylad = {
        functionName: 'loadExistingTasks',
        loadCurrentTaskId: true,
        loadGroupTaskProgression: true,
        loadExistingTasks: true,
        loadReferralAlerts: false,
        loadFollowUpdInfo : false,
        zebraUserId: userId,
        table: '',
        referralId: servicesData[foundIndex].referralId,
        tableAbbrv: servicesData[foundIndex].module,
        serviceId: servicesData[foundIndex].serviceId,
        currentTask: servicesData[foundIndex].currentTaskId,
        referenceTable: servicesData[foundIndex].module,
        claimNo: data.claimNo,
        userid: userId,
        breadcrumbkey: 'VIEWSERVICE',
        breadcrumbrefTableId: servicesData[foundIndex].serviceId,
        breadcrumbrefId: servicesData[foundIndex].referralId,
        breadcrumbrefTable: servicesData[foundIndex].module,
      };
      callDynamicLoad(taskPayload);
     /* let callPaylad = {
        functionName: 'loadExistingTasks',
        zebraUserId: userId,
        referralId: servicesData[foundIndex].referralId,
        tableAbbrv: servicesData[foundIndex].module,
        serviceId: servicesData[foundIndex].serviceId,
        from: 'fromdataLanding',
      };
      let groupProgressPayload = {
        functionName: 'groupTasksProgression',
        zebraUserId: userId,
        currentTask: servicesData[foundIndex].currentTaskId,
        referenceTable: servicesData[foundIndex].module,
        claimNo: data.claimNo,
        serviceId: servicesData[foundIndex].serviceId,
      };
      let getTaskIdPayload = {
        functionName: 'getCurrentTaskId',
        zebraUserId: userId,
        tableAbbrv: servicesData[foundIndex].module,
        serviceId: servicesData[foundIndex].serviceId,
      };*/
      // let alertPayload = {
      //   functionName: 'loadReferralAlerts',
      //   zebraUserId: userId,
      //   referralId: servicesData[foundIndex].referralId,
      //   tableAbbrv: servicesData[foundIndex].module,
      //   serviceId: servicesData[foundIndex].serviceId,
      // };
      if (servicesData[foundIndex].outcomeId > 41) {
        setShow(true);
      } else {
        setShow(false);
      }
      setReadyForBilling(servicesData[foundIndex].readyForGp);
      //setIsNoteOpen(true);
      // setTimeout(() => {
      //   loadReferralAlerts(alertPayload);
      // }, 1000);
      /*setTimeout(() => {
        loadExistingTasks(taskPayload);
      }, 2000);
      setTimeout(() => {
        groupTasksProgression(groupProgressPayload);
      }, 1250);
      setTimeout(() => {
        getCurrentTaskId(getTaskIdPayload);
      }, 1750);
      */
     
     /* const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'VIEWSERVICE',
        refTableId: servicesData[foundIndex].serviceId,
        refId: servicesData[foundIndex].referralId,
        refTable: servicesData[foundIndex].module,
      };
      logBreadCrumbs(breadCrumb);*/
    }

    setVendorData({ selectedSubService: {}, flag: false });
  };

  // useEffect(() => {
  //   console.log(currentWorkList);
  //   if (Object.keys(currentWorkList).length > 0 && currentWorkList.FK_referral_id && currentWorkList.chr_reference_table && currentWorkList.int_reference_table_id) {
  //     let alertPayload = {
  //       functionName: 'loadReferralAlerts',
  //       zebraUserId: userId,
  //       referralId: currentWorkList.FK_referral_id,
  //       tableAbbrv: currentWorkList.chr_reference_table,
  //       serviceId: currentWorkList.int_reference_table_id,
  //     };
  //     if (!isNoteOpen) {
  //       setIsNoteOpen(true);
  //     }
  //     setTimeout(() => {
  //       loadReferralAlerts(alertPayload);
  //     }, 1000);
  //   }

  // }, [currentWorkList]);

  useEffect(() => {
    getDataFromLanding();
  }, [updateLoadFromWorkList, externalReferralData]);

  const setAttributes = data => {
    let obj = {
      nonworkcomp: false,
      specialneeds: false,
      vic: false,
      converted: false,
      recurring: false,
      retrospective: false,
      prospective: false,
      quote: false,
    };
    data.map(item => {
      if (item.attributeId === 1487) obj.nonworkcomp = true;
      if (item.attributeId === 1486) obj.specialneeds = true;
      if (item.attributeId === 1485) obj.vic = true;
      if (item.attributeId === 1484) obj.converted = true;
      if (item.attributeId === 1483) obj.recurring = true;
      if (item.attributeId === 1482) obj.retrospective = true;
      if (item.attributeId === 1481) obj.prospective = true;
      if (item.attributeId === 1480) obj.quote = true;
    });
    return obj;
  };

  const addServiceFromLanding = (mod, item, obj, fromExtRef) => {
    var newId = item.id;
    var pos = 0;

    const dte = new Date();
    const month = dte.getMonth() + 1;
    const day = dte.getDate();
    const year = dte.getFullYear();
    let module = '';
    if (mod === 'TEN' || mod === 'OPP') {
      module = 'MSE';
    } else module = mod;
    // for (var i = 0; i < serData.length; i++) {
    //   if (serData[i].module === mod) {
    //     newId = newId + 1;

    //   }
    // }
    if (module === 'MSE' || mod === 'MOD' || mod === 'HHC' || mod === 'AUD') {
      pos = 1642;
    } else {
      pos = 1641;
    }
    const service = {
      module: module,
      serviceId: fromExtRef ? '1' : newId,
      referralId: 0,
      createDate: moment(dte).format('MM/DD/YYYY hh:mm a'),
      dos: month + '/' + day + '/' + year,
      product: 0,
      locationSame: '0',
      outcomeId: item.outcomeId,
      sentToGP:item.sentToGP,
      rush: false,
      highPriority: false,
      placeOfServiceId: pos,
      serviceAddress: {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
        phone: '',
      },
      attributes: [],
    };
    if (service.outcomeId == undefined) {
      service.outcomeId = 39;
    }

    if (mod === 'ACC') {
      let msDiff =
        new Date(item?.checkOutDate).getTime() -
        new Date(item?.checkInDate).getTime(); //Future date - current date
      service.wheelchairAccess = item?.wheelchairAccess;
      service.poNumber = item?.poNumber;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      service.id = item?.id;
      let accSubSer = accommodationType.filter(
        elem => elem.value === item?.subService
      )[0];
      service.categoryId = accSubSer && accSubSer.id;
      let accAppType = appointmentType.filter(
        elem => elem.value === item?.apptType
      )[0];
      service.subService = `${accSubSer?.value}`;
      service.apptTypeId = accAppType && accAppType.id;
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.amenities = item?.amenities;
      service.rfees = item?.rfees;
      service.rfeeIds = item?.rfeeIds;
      service.fees = item?.fees;
      service.feeIds = item?.feeIds;
      service.readyForGp = item?.readyForGp;
      service.serviceCosts = item?.serviceCosts;
      service.serviceFees = item?.serviceFees;
      service.checkInDate = moment(item?.checkInDate).format('MM/DD/YYYY');
      service.checkOutDate = moment(item?.checkOutDate).format('MM/DD/YYYY');
      service.placeOfServiceId = item?.placeOfServiceId;
      // service.daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
      let start = moment(item?.checkOutDate, 'YYYY-MM-DD');
      let end = moment(item?.checkInDate, 'YYYY-MM-DD');
      service.daysDiff = Number(moment.duration(start.diff(end)).asDays());
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
    }
    if (mod === 'DTL') {
      service.locationSame = item?.serviceAddSameAsClmnt ? '1' : '0';
      service.subService = item?.subService;
      service.serviceAddSameAsClmnt = false;
      service.poNumber = item?.poNumber;
      service.id = item?.id;
      service.readyForGp = item?.readyForGp;
      service.subService = 'Dental';
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      if (!item.serviceAddSameAsClmnt) {
        service.serviceAddress.street1 = item?.serviceAddress?.street1;
        service.serviceAddress.zipCode.city =
          item?.serviceAddress?.zipCode?.city;
        service.serviceAddress.zipCode.id = item?.serviceAddress?.zipCode?.id;
        service.serviceAddress.zipCode.state =
          item?.serviceAddress?.zipCode?.state;
        service.serviceAddress.zipCode.zip = item?.serviceAddress?.zipCode?.zip;
        service.locationPhone = item?.serviceAddress?.phone
          ? formatPhoneNumber(item.serviceAddress.phone)
          : '';
        service.serviceAddress.phone = item?.serviceAddress?.phone
          ? formatPhoneNumber(item.serviceAddress.phone)
          : '';
        service.serviceAddressId = 0;
        service.serviceAddress.zipCodeId = item?.serviceAddress?.zipCodeId;
      } else {
        service.serviceAddSameAsClmnt = item.serviceAddSameAsClmnt;
        service.serviceAddress.street1 = obj.claim?.claimant?.address?.street1;
        service.serviceAddress.zipCode.city =
          obj.claim?.claimant?.address?.zipCode?.city;
        service.serviceAddress.zipCode.state =
          obj.claim?.claimant?.address?.zipCode?.state;
        service.serviceAddress.zipCode.id =
          obj.claim?.claimant?.address?.zipCode?.id;
        service.serviceAddress.zipCode.zip =
          obj.claim?.claimant?.address?.zipCode?.zip;
        service.locationPhone = obj?.claim?.claimant?.people?.phone
          ? formatPhoneNumber(obj.claim.claimant.people.phone)
          : ' ';
        service.serviceAddress.phone = obj?.claim?.claimant?.people?.phone
          ? formatPhoneNumber(obj.claim.claimant.people.phone)
          : ' ';

        service.serviceAddressId = 0;
        service.serviceAddress.zipCodeId =
          obj.claim?.claimant?.address?.zipCodeId;
      }
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.placeOfServiceId = item?.placeOfServiceId;
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.hcpcsList = item?.hcpcsList;
    }
    if (mod === 'HHC') {
      service.locationSame = item?.serviceAddSameAsClmnt ? '1' : '0';
      service.poNumber = item?.poNumber;
      service.id = item?.id;
      service.subService = item?.subService;
      service.readyForGp = item?.readyForGp;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      if (!item.serviceAddSameAsClmnt) {
        service.serviceAddress.id = item?.serviceAddress?.id;
        service.serviceAddress.street1 = item?.serviceAddress?.street1;
        service.serviceAddress.street2 = item?.serviceAddress?.street2;
        service.serviceAddress.zipCode.city =
          item?.serviceAddress?.zipCode?.city;
        service.serviceAddress.zipCode.state =
          item?.serviceAddress?.zipCode?.state;
        service.serviceAddress.zipCode.zip = item?.serviceAddress?.zipCode?.zip;
        service.locationPhone = item?.serviceAddress?.phone
          ? formatPhoneNumber(item?.serviceAddress?.phone)
          : '';
        service.serviceAddress.phone = item?.serviceAddress?.phone
          ? formatPhoneNumber(item?.serviceAddress?.phone)
          : '';
        service.serviceAddressId = item?.serviceAddress?.id;
        service.serviceAddress.zipCodeId = item?.serviceAddress?.zipCodeId;
      } else {
        service.serviceAddSameAsClmnt = item.serviceAddSameAsClmnt;
        service.serviceAddress.id = item?.serviceAddress?.id;
        service.serviceAddress.street1 = obj.claim?.claimant?.address?.street1;
        service.serviceAddress.street2 = obj.claim?.claimant?.address?.street2;
        service.serviceAddress.zipCode.city =
          obj.claim?.claimant?.address?.zipCode?.city;
        service.serviceAddress.zipCode.state =
          obj.claim?.claimant?.address?.zipCode?.state;
        service.serviceAddress.zipCode.zip =
          obj.claim?.claimant?.address?.zipCode?.zip;
        service.serviceAddress.zipCodeId =
          obj.claim?.claimant?.address?.zipCodeId;
        service.serviceAddressId = obj.claim?.claimant?.address?.id;
        service.locationPhone = obj?.claim?.claimant?.people?.phone
          ? formatPhoneNumber(obj.claim.claimant.people.phone)
          : ' ';
      }
      let hhcsubSer = hhcCategory.filter(
        elem => elem.value === item?.subService
      )[0];
      service.categoryId = hhcsubSer && hhcsubSer.id;
      if(item?.placeOfServiceId !== undefined && item?.placeOfServiceId !== null){
        service.placeOfServiceId = item?.placeOfServiceId;
      }
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.recurringOrder = item?.recurringOrder;
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.hcpcsList = item.hcpcsList;
    }
    if (mod === 'INT') {
      service.poNumber = item?.poNumber;
      service.id = item?.id;
      service.readyForGp = item?.readyForGp;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      if(item?.placeOfServiceId !== undefined && item?.placeOfServiceId !== null){
        service.placeOfServiceId = item?.placeOfServiceId;
      }
      service.subService = '';
      let intSubServ = translationCategory.filter(
        elem => elem.value === item?.subService
      )[0];
      service.rfees = item?.rfees;
      service.rfeeIds = item?.rfeeIds;
      service.fees = item?.fees;
      service.feeIds = item?.feeIds;
      service.serviceCosts = item?.serviceCosts;
      service.serviceFees = item?.serviceFees;
      service.translationCategoryId = `${intSubServ?.id}`;
      service.subService = `${intSubServ?.value}`;
      service.languageId = item?.languageId;
      service.fromLanguageId = item?.fromLanguageId;
      service.appointmentTypeId = item?.appointmentTypeId;
      service.certificationId = item?.certificationId;
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.recurring = item?.recurring;
      service.facilityId = item?.facilityId;
      service.facility = {
        AddressId: item?.facility?.AddressId,
        address: {
          zipCodeId: item?.facility?.address?.zipCodeId,
          zipCode: {
            id: item?.facility?.address?.zipCode?.id,
            zip: item?.facility?.address?.zipCode?.zip,
            city: item?.facility?.address?.zipCode?.city,
            state: item?.facility?.address?.zipCode?.state,
            county: item?.facility?.address?.zipCode?.county,
          },
          street1: item?.facility?.address?.street1,
          street2: item?.facility?.address?.street2,
          id: item?.facility?.address?.id,
        },
        name: item?.facility?.name,
        phone: item?.facility?.phone
          ? formatPhoneNumber(item.facility.phone)
          : '',
      };
      service.authTravel = item?.intTravel === 'Yes' ? '1' : '0';
      service.linesPerPage = item?.linesPerPage;
      service.endTZ = item?.timeZoneId;
      service.startTZ = item?.timeZoneId;
      service.arriveTZ = item?.timeZoneId;
      service.timeZoneId = item?.timeZoneId;
      let aptTime = getTimeData(item?.startTime);
      if (aptTime) {
        service.aptHour = aptTime.Hr;
        service.aptMinutes = aptTime.Min;
        service.aptAMPM = aptTime.ampm;
        service.startHours = aptTime.Hr;
        service.startMinutes = aptTime.Min;
        service.startAMPM = aptTime.ampm;
        service.startTime = aptTime.Hr + ':' + aptTime.Min + ':' + '00';
      }
      let endTimeZone = getTimeData(item?.endTime);
      if (endTimeZone) {
        service.endHour = endTimeZone.Hr;
        service.endMinute = endTimeZone.Min;
        service.endAMPM = endTimeZone.ampm;
        service.endTime = endTimeZone.Hr + ':' + endTimeZone.Min + ':' + '00';
      }
      if (item.arrivalDate) {
        let arrTime = getTimeData(item?.arrivalDate.split(' ')[1]); //: "2020-12-28 06:00:00.0";
        service.arrivalHour = arrTime.Hr;
        service.arrivalMin = arrTime.Min;
        service.arriveAMPM = arrTime.ampm;
        service.arrivalTime = arrTime.Hr + ':' + arrTime.Min + ':' + '00';
      }
      let duration = moment
        .utc(
          moment(item?.endTime, 'HH:mm:ss').diff(
            moment(item?.startTime, 'HH:mm:ss')
          )
        )
        .format('HH:mm:ss');
      service.durationHour = duration.split(':')[0];
      service.durationMinutes = duration.split(':')[1];
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.vendorAssignmentEmailSent = item?.vendorAssignmentEmailSent;
    }
    if (mod === 'MOD') {
      service.locSameDif = item?.modlocClaimant ? '1' : '0';
      service.vhYearbuilt = item?.vhYearbuilt;
      service.id = item?.id;
      service.vhMake = item?.vhMake;
      service.vhModel = item?.vhModel;
      service.vhNnoofmiles = item?.vhNnoofmiles;
      service.vhTypeId = item?.vhTypeId;
      service.vhNoofdoors = item?.vhNoofdoors;
      service.hitchYesNo = item?.vhHitch ? '1' : '0';
      service.placeOfServiceId = item?.placeOfServiceId;
      service.readyForGp = item?.readyForGp;
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.recurringOrder = item?.recurringOrder;
      service.poNumber = item?.poNumber;
      service.subService = item?.subService;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      let modSubServ = modificationCategory.filter(
        elem => elem.value === item?.subService
      )[0];
      service.modCategoryId = `${modSubServ?.id}`;
      service.subService = `${modSubServ?.value}`;
      if (!item.modlocClaimant) {
        service.locationAddress = {};
        service.locationAddress.street1 = item?.locationAddress?.street1;
        service.locationAddress.street2 = item?.locationAddress?.street2;
        service.locationAddress.zipCode = {};
        service.locationAddress.zipCode.city =
          item?.locationAddress?.zipCode?.city;
        service.locationAddress.zipCode.state =
          item?.locationAddress?.zipCode?.state;
        service.locationAddress.zipCode.zip =
          item?.locationAddress?.zipCode?.zip;
        service.locationAddress.zipCode.id = item?.locationAddress?.zipCode?.id;
        service.locationAddress.zipCodeId = item?.locationAddress?.zipCodeId;
        service.locationAddress.id = item?.locationAddress?.id;
        service.modlocAddressId = item?.locationAddress?.id;
      } else {
        service.locationAddress = {};
        service.locationAddress.street1 = obj.claim?.claimant?.address?.street1;
        service.locationAddress.street2 = obj.claim?.claimant?.address?.street2;
        service.locationAddress.zipCode = {};
        service.locationAddress.zipCode.city =
          obj.claim?.claimant?.address?.zipCode?.city;
        service.locationAddress.zipCode.state =
          obj.claim?.claimant?.address?.zipCode?.state;
        service.locationAddress.zipCode.zip =
          obj.claim?.claimant?.address?.zipCode?.zip;
        service.locationAddress.zipCode.id =
          obj.claim?.claimant?.address?.zipCode?.id;
        service.locationAddress.zipCodeId =
          obj.claim?.claimant?.address?.zipCodeId;
        service.locationAddress.id = obj.claim?.claimant?.address?.id;
        service.modlocAddressId = obj.claim?.claimant?.address?.id;
      }
      service.lanlordName = item?.lanlordName;
      service.landlordPhone = item?.landlordPhone
        ? formatPhoneNumber(item?.landlordPhone)
        : '';
      service.landlordFax = item?.landlordFax
        ? formatPhoneNumber(item?.landlordFax)
        : '';
      service.landlordEmail = item?.landlordEmail;
      service.landlordAddressId = item?.landlordAddressId;
      service.landlordAddress = {
        zipCodeId: item?.landlordAddress?.zipCodeId,
        zipCode: {
          id: item?.landlordAddress?.zipCode?.id,
          zip: item?.landlordAddress?.zipCode?.zip,
          city: item?.landlordAddress?.zipCode?.city,
          state: item?.landlordAddress?.zipCode?.state,
          county: item?.landlordAddress?.zipCode?.county,
        },
        street1: item?.landlordAddress?.street1,
        street2: item?.landlordAddress?.street2,
        id: item?.landlordAddress?.id,
      };
      service.hoaAddressId = item?.hoaAddressId;
      service.hoaName = item?.hoaName;
      service.hoaPhone = item?.hoaPhone
        ? formatPhoneNumber(item?.hoaPhone)
        : '';
      service.hoaFax = item?.hoaFax ? formatPhoneNumber(item?.hoaFax) : '';
      service.hoaEmail = item?.hoaEmail;
      service.hoaAddress = {
        zipCodeId: item?.hoaAddress?.zipCodeId,
        zipCode: {
          id: item?.hoaAddress?.zipCode?.id,
          zip: item?.hoaAddress?.zipCode?.zip,
          city: item?.hoaAddress?.zipCode?.city,
          state: item?.hoaAddress?.zipCode?.state,
          county: item?.hoaAddress?.zipCode?.county,
        },
        street1: item?.hoaAddress?.street1,
        street2: item?.hoaAddress?.street2,
        id: item?.hoaAddress?.id,
      };
      service.modYN = item?.modifyOk ? '1' : '0';
      service.ownVRent = item?.ownOrRent === 'Rent' ? '0' : '1';
      service.yearBuilt = item?.yearBuilt;
      service.listelementHomestructId = item?.listelementHomestructId;
      service.listelementHometypeId = item?.listelementHometypeId;
      service.sqFoot = item?.sqFoot;
      service.numStories = item?.numStories;
      service.numBedrooms = item?.numBedrooms;
      service.numBaths = item?.numBaths;
      service.numPeople = item?.numPeople;
      service.basement = item?.basement;
      service.historicDistrict = item?.historicDistrict;
      service.hoaYN = item?.hoaApproval ? '1' : '0';

      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.detailList = item?.detailList;
    }
    if (module === 'MSE') {
      service.locationSame = item?.deliverAddSameAsClmnt ? '1' : '0';
      service.subService = item?.subService;
      let mscSubServ = undefined;
      if(item?.placeOfServiceId !== undefined && item?.placeOfServiceId !== null){
        service.placeOfServiceId = item?.placeOfServiceId;
      }
      service.id = item?.id;
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.recurringOrder = item?.recurringOrder;
      service.poNumber = item?.poNumber;
      service.readyForGp = item?.readyForGp;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      mscSubServ =
        mseCategory &&
        mseCategory.filter(elem => elem.value === item?.subService)[0];
      service.categoryId = mscSubServ?.PK_listelement_id;
      service.subService = `${mscSubServ?.value}`;
      service.deliverAddressId = 0;
      service.deliverAddress = {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
      };
      if (!item.deliverAddSameAsClmnt) {
        service.deliverAddress.street1 = item?.deliverAddress?.street1;
        service.deliverAddress.zipCode.city =
          item?.deliverAddress?.zipCode?.city;
        service.deliverAddress.zipCode.id = item?.deliverAddress?.zipCode?.id;
        service.deliverAddress.zipCodeId = item?.deliverAddress?.zipCodeId;
        service.deliverAddress.zipCode.state =
          item?.deliverAddress?.zipCode?.state;
        service.deliverAddress.zipCode.zip = item?.deliverAddress?.zipCode.zip;
        service.deliverAddressId = 0;
      } else {
        service.deliverAddress.street1 = obj.claim?.claimant?.address?.street1;
        service.deliverAddress.zipCodeId =
          obj.claim?.claimant?.address?.zipCode?.id;
        service.deliverAddress.zipCode.id =
          obj.claim?.claimant?.address?.zipCode?.id;
        service.deliverAddress.zipCode.city =
          obj.claim?.claimant?.address?.zipCode?.city;
        service.deliverAddress.zipCode.state =
          obj.claim?.claimant?.address?.zipCode?.state;
        service.deliverAddress.zipCode.zip =
          obj.claim?.claimant?.address?.zipCode?.zip;
        service.deliverAddressId = 0;
      }
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.detailList = item.detailList;
    }
    if (mod === 'TRN') {
      service.poNumber = item?.poNumber;
      service.id = item?.id;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      if(item?.placeOfServiceId !== undefined && item?.placeOfServiceId !== null){
        service.placeOfServiceId = item?.placeOfServiceId;
      }
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.readyForGp = item?.readyForGp;
      service.recurring = item?.recurring;
      let trnSubServ = transportCategory.filter(
        elem => elem.value === item?.subService
      )[0];
      service.transportationCategoryId = trnSubServ && trnSubServ.id;
      service.subService = trnSubServ && `${trnSubServ.value}`;
      let trnAccType = appointmentType.filter(
        elem => elem.value === item?.apptType
      )[0];
      service.rfees = item?.rfees;
      service.rfeeIds = item?.rfeeIds;
      service.fees = item?.fees;
      service.feeIds = item?.feeIds;
      service.serviceCosts = item?.serviceCosts;
      service.serviceFees = item?.serviceFees;
      service.apptTypeId = trnAccType && trnAccType.id;
      service.detailList = item?.detailList;
      service.vendorAssignmentEmailSent = item?.vendorAssignmentEmailSent;
      if (item && item?.attributes && item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
    }
    if (mod === 'AUD') {
      service.id = item?.id;
      service.poNumber = item?.poNumber;
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      service.subService = 'Audiology';
      service.highPriority = item?.highPriority;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.rush = item?.rush;
      service.readyForGp = item?.readyForGp;
      service.placeOfServiceId = item?.placeOfServiceId;
      if (item?.attributes?.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.hcpcsList = item?.hcpcsList;
    }
    if (mod === 'DGN') {
      let dgnSubServ = undefined;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.poNumber = item?.poNumber;
      service.readyForGp = item?.readyForGp;
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      dgnSubServ =
        diagnosticType &&
        diagnosticType.filter(elem => elem.value === item?.subService)[0];
      service.examType = dgnSubServ?.id;
      service.subService = `${dgnSubServ.value}`;
      service.placeOfServiceId = item?.placeOfServiceId;
      service.id = item?.id || 0;
      service.medicalQuestions = item?.medicalQuestions;  

      if (item?.attributes.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.hcpcsList = item?.hcpcsList;
    }
    if (mod === 'SPT') {
      service.poNumber = item?.poNumber;
      service.createDate = moment(item?.createDate).format(
        'MM/DD/YYYY hh:mm a'
      );
      service.dos = moment(item?.dos).format('MM/DD/YYYY');
      service.highPriority = item?.highPriority;
      service.rush = item?.rush;
      service.readyForGp = item?.readyForGp;
      service.recurring = item?.recurring;
      let sptSubserv = specialTransportCategory.filter(
        elem => elem.value === item?.subService
      )[0];
      service.specialTransportationCategoryId = sptSubserv && sptSubserv.id;
      let sptAppType = appointmentType.filter(
        elem => elem.value === item?.apptType
      )[0];
      service.rfees = item?.rfees;
      service.rfeeIds = item?.rfeeIds;
      service.fees = item?.fees;
      service.feeIds = item?.feeIds;
      service.serviceCosts = item?.serviceCosts;
      service.serviceFees = item?.serviceFees;
      service.apptTypeId = sptAppType && sptAppType.id;
      service.subService = `${sptSubserv.value}`;
      service.addPassName = item?.addPassName;
      service.addPassWeight = item?.addPassWeight;
      service.addPassHeightFeet = item?.addPassHeightFeet;
      service.addPassHeightInch = item?.addPassHeightInch;
      service.id = item?.id;
      service.addPassPhone = item?.addPassPhone
        ? formatPhoneNumber(item?.addPassPhone)
        : '';
      service.placeOfServiceId = item?.placeOfServiceId;
      service.addPassRelation = item?.addPassRelation;
      if (item?.attributes.length > 0) {
        const attr = setAttributes(item?.attributes);
        service.nonworkcomp = attr.nonworkcomp;
        service.specialneeds = attr.specialneeds;
        service.vic = attr.vic;
        service.converted = attr.converted;
        service.recurring = attr.recurring;
        service.retrospective = attr.retrospective;
        service.prospective = attr.prospective;
        service.quote = attr.quote;
      } else {
        service.nonworkcomp = false;
        service.specialneeds = false;
        service.vic = false;
        service.converted = false;
        service.recurring = false;
        service.retrospective = false;
        service.prospective = false;
        service.quote = false;
      }
      service.detailList = item?.detailList;
    }
    service.reasonId = item?.cancelStatus;
    service.referralId = fromExtRef ? '' : item?.referralId;
    service.costSavings = item?.costSavings;
    service.vendor = item?.vendor;
    service.currentTaskId = item?.currentTaskId;
    service.assignedUser = item?.assignedUser;
    service.serviceTaskId = item?.serviceTaskId;
    if (service.currentTaskId === undefined) {
      service.currentTaskId = 1;
    }

    servicesData.push(service);

    setIndex(servicesData?.length);
    setServicesData(servicesData);
    setSelectedItem(service);
    setDisableSave(false);
    setDetails(service);
    onModuleChange(mod);
    setRefTransportation(mod);
    if (service.id < 500) {
      let taskPayload = {
        functionName: 'loadExistingTasks',
        loadCurrentTaskI: true,
        loadGroupTaskProgression: true,
        loadExistingTasks:true,
        loadReferralAlerts: true,
        loadFollowUpdInfo : false,
        zebraUserId: userId,
        table: '',
        referralId: 0,
        tableAbbrv: '',
        serviceId: 0,
        currentTask: 0,
        referenceTable: 0,
        claimNo: data.claimNo,
        userid: userId,
        breadcrumbkey: '',
        breadcrumbrefTableId: 0,
        breadcrumbrefId: 0,
        breadcrumbrefTable: '',
      };
      callDynamicLoad(taskPayload);
/*
      let taskPayload = {
        functionName: 'loadExistingTasks',
        zebraUserId: userId,
        referralId: 0,
        tableAbbrv: '',
        serviceId: 0,
        from: 'fromzeroservice',
      };
      let groupProgressPayload = {
        functionName: 'groupTasksProgression',
        zebraUserId: userId,
        currentTask: 0,
        referenceTable: 0,
        claimNo: data.claimNo,
        serviceId: 0,
      };
      let getTaskIdPayload = {
        functionName: 'getCurrentTaskId',
        zebraUserId: userId,
        tableAbbrv: '',
        serviceId: 1,
      };
      let alertPayload = {
        functionName: 'loadReferralAlerts',
        zebraUserId: userId,
        referralId: 0,
        tableAbbrv: '',
        serviceId: 0,
      };
      setTimeout(() => {
        loadReferralAlerts(alertPayload);
      }, 500);
      setTimeout(() => {
        loadExistingTasks(taskPayload);
      }, 1500);
      setTimeout(() => {
        groupTasksProgression(groupProgressPayload);
      }, 750);
      setTimeout(() => {
        getCurrentTaskId(getTaskIdPayload);
      }, 1250);*/
      
    }
    // serData.length > 0 && setSelectedItem(serData[0]);
    // onModuleChange(mod);
    // setRefTransportation(obj.mainMod);
    // setSelectedModule(serData[0].module);
  };

  const getTimeData = data => {
    let Hr = 0,
      Min = 0,
      ampm = undefined;
    if (data) {
      if (data.includes(':')) {
        const time = data;
        if (Number(time.split(':')[0]) > 12) {
          Hr = Number(time.split(':')[0]) - 12;
          ampm = '0';
        } else if (Number(time.split(':')[0]) == 0) {
          Hr = 12;
          ampm = '1';
        } else {
          Hr = Number(time.split(':')[0]);
          ampm = '1';
        }
        Min = Number(time.split(':')[1].split(' ')[0]);
        return { Hr, Min, ampm };
      } else {
        const time = data;
        const hrs = Number(time.substring(0, 2));
        const min = Number(time.substring(2, 4));
        if (hrs <= 11) {
          ampm = '1';
          Hr = hrs === 0 ? 12 : hrs;
        } else {
          ampm = '0';
          Hr = hrs;
        }
        Min = min;
        return { Hr, Min, ampm };
      }
    }
  };

  const addService = mod => {
    var newId = 1;
    var pos = 0;

    const dte = new Date();
    const month = dte.getMonth() + 1;
    const day = dte.getDate();
    const year = dte.getFullYear();
    let module = '';
    if (mod === 'TEN' || mod === 'OPP') {
      module = 'MSE';
    } else module = mod;
    for (var i = 0; i < servicesData.length; i++) {
      if (servicesData[i].module === mod) {
        newId = newId + 1;
      }
    }
    if (module === 'MSE' || mod === 'MOD' || mod === 'HHC' || mod === 'AUD') {
      pos = 1642;
    } else {
      pos = 1641;
    }
    const service = {
      module: module,
      serviceId: newId,
      newServiceId: 0,
      referralId: isFromLanding ? worklistData?.data?.referralId : 0,
      createDate: moment(dte).format('MM/DD/YYYY hh:mm a'),
      dos: '', //month + '/' + day + '/' + year,
      product: 0,
      locationSame: '0',
      outcomeId: 39,
      sentToGP: false,
      rush: false,
      highPriority: false,
      placeOfServiceId: pos,
      serviceAddress: {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
        phone: '',
      },
      attributes: [],
    };
    setShow(false);

    if (mod === 'ACC') {
      service.subService = '';
      service.categoryId = '';
      service.apptType = '';
      service.apptTypeId = '';
      service.wheelchairAccess = false;
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'DTL') {
      service.serviceAddSameAsClmnt = false;
      service.serviceAddressId = 0;
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'HHC') {
      service.serviceAddSameAsClmnt = false;
      service.subService = '';
      service.categoryId = '';
      service.serviceAddressId = 0;
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'INT') {
      service.subService = '';
      service.translationCategoryId = '';
      service.facilityId = 0;
      service.facility = {
        AddressId: 0,
        address: {
          zipCodeId: 0,
          zipCode: { id: 0, zip: '' },
          street1: '',
          street2: '',
          id: 0,
        },
        name: '',
        phone: '',
      };
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'MOD') {
      service.landlordAddressId = 0;
      service.landlordAddress = {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
        id: 0,
      };
      service.modlocAddressId = 0;
      service.locationAddress = {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
        id: 0,
      };
      service.hoaAddressId = 0;
      service.hoaAddress = {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
        id: 0,
      };
      // service.detailList = [
      //   {
      //     id: 0,
      //   },
      // ];
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (module === 'MSE') {
      service.deliverAddressId = 0;
      service.deliverAddress = {
        zipCodeId: 0,
        zipCode: { id: 0, zip: '' },
        street1: '',
      };
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'TRN') {
      service.detailList = [];
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
      trnSaveData(false);
    }
    if (mod === 'AUD') {
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'DGN') {
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
    }
    if (mod === 'SPT') {
      service.detailList = [];
      service.nonworkcomp = false;
      service.specialneeds = false;
      service.vic = false;
      service.converted = false;
      service.recurring = false;
      service.retrospective = false;
      service.prospective = false;
      service.quote = false;
      sptsaveData(false);
    }
    service.currentTaskId = 1;
    setIndex(servicesData?.length);
    setServicesData([...servicesData, service]);
    setSelectedItem(service);
    setDisableSave(false);
    setDetails(service);
    onModuleChange(mod);
    setRefTransportation(mod);
    setAdvValues(`${service?.referralId}-${service?.module}-${service?.serviceId}`)

    let taskPayload = {
      loadCurrentTaskId: true,
      loadGroupTaskProgression: true,
      loadExistingTasks:true,
      loadReferralAlerts: true,
      loadFollowUpdInfo : false,
      zebraUserId: userId,
      table: '',
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
      currentTask: 0,
      referenceTable: 0,
      claimNo: data.claimNo,
      userid: userId,
      breadcrumbkey: '',
      breadcrumbrefTableId: 0,
      breadcrumbrefId: 0,
      breadcrumbrefTable: '',
    };
    callDynamicLoad(taskPayload);
   /*let taskPayload = {
      functionName: 'loadExistingTasks',
      zebraUserId: userId,
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
      from: 'fromzeroservice',
    };
    let groupProgressPayload = {
      functionName: 'groupTasksProgression',
      zebraUserId: userId,
      currentTask: 0,
      referenceTable: 0,
      claimNo: data.claimNo,
      serviceId: 0,
    };
    let getTaskIdPayload = {
      functionName: 'getCurrentTaskId',
      zebraUserId: userId,
      tableAbbrv: '',
      serviceId: 1,
    };
    let alertPayload = {
      functionName: 'loadReferralAlerts',
      zebraUserId: userId,
      referralId: 0,
      tableAbbrv: '',
      serviceId: 0,
    };
    setTimeout(() => {
      loadReferralAlerts(alertPayload);
    }, 500);
    setTimeout(() => {
      loadExistingTasks(taskPayload);
    }, 1500);
    setTimeout(() => {
      groupTasksProgression(groupProgressPayload);
    }, 750);
    setTimeout(() => {
      getCurrentTaskId(getTaskIdPayload);
    }, 1250);*/
    resetAudiologyHcpcsList();
    resetCostSavings();
  };

  const CheckGridChange = (item, itemIndex) => {
    if(!selectedItem || (selectedItem && (selectedItem.serviceId !== item.serviceId || selectedItem.module !== item.module) )){
      //requiredServiceData(item);
      setTempSelectedItem(item);
      setItemChanged(true);
      setIndex(itemIndex);
    }
  };


  const serviceGridchange = (opt, copiedService) => {
   
    if (opt === 'YES') {
      if (copiedService) {
        setSelectedModule(selectedItem.module);
        const breadCrumb2 = {
          functionName: 'logBreadCrumbs',
          zebraUserId: userId,
          userid: userId,
          breadcrumbkey: 'CLOSESERVICE',
          refTableId: selectedItem.serviceId,
          refId: selectedItem.referralId,
          refTable: selectedItem.module,
        };
        logBreadCrumbs(breadCrumb2);
        setRushText('');
        if (selectedItem.outcomeId > 41) {
          setShow(true);
        } else {
          setShow(false);
        }
        setReadyForBilling(selectedItem.readyForGp);
        if (selectedItem.outcomeId == 52) {
          setReasonList(cmbAltBillReason);
        } else if (selectedItem.outcomeId == 1050) {
          setReasonList(cmbMissedBillReason);
        } else {
          setReasonList(cmbBillReason);
        }
        if (selectedItem.rush) {
          setRushText('RUSH');
        }

        let taskPayload = {
          loadCurrentTaskId :true,
          loadGroupTaskProgression: true,
          loadExistingTasks : true,
          loadReferralAlerts :false,
          loadFollowUpdInfo : true,
          zebraUserId: userId,
          table: '',
          referralId: selectedItem.referralId,
          tableAbbrv: selectedItem.module,
          serviceId: selectedItem.serviceId,
          currentTask: selectedItem.currentTaskId,
          referenceTable: selectedItem.module,
          claimNo: data.claimNo,
          userid: userId,
          breadcrumbkey: 'VIEWSERVICE',
          breadcrumbrefTableId: selectedItem.serviceId,
          breadcrumbrefId: selectedItem.referralId,
          breadcrumbrefTable: selectedItem.module,
        };
        callDynamicLoad(taskPayload);
        /*let taskPayload = {
          functionName: 'loadExistingTasks',
          zebraUserId: userId,
          referralId: selectedItem.referralId,
          tableAbbrv: selectedItem.module,
          serviceId: selectedItem.serviceId,
          from: 'serviceGridChange',
        };
        let groupProgressPayload = {
          functionName: 'groupTasksProgression',
          zebraUserId: userId,
          currentTask: selectedItem.currentTaskId,
          referenceTable: selectedItem.module,
          claimNo: data.claimNo,
          serviceId: selectedItem.serviceId,
        };
        let getTaskIdPayload = {
          functionName: 'getCurrentTaskId',
          zebraUserId: userId,
          tableAbbrv: selectedItem.module,
          serviceId: selectedItem.serviceId,
        };
        let alertPayload = {
          functionName: 'loadReferralAlerts',
          zebraUserId: userId,
          referralId: selectedItem.referralId,
          tableAbbrv: selectedItem.module,
          serviceId: selectedItem.serviceId,
        };
        // setTimeout(() => {
        // loadReferralAlerts(alertPayload);
        // }, 500);
        setTimeout(() => {
          loadExistingTasks(taskPayload);
        }, 1500);
        setTimeout(() => {
          groupTasksProgression(groupProgressPayload);
        }, 750);
        setTimeout(() => {
          getCurrentTaskId(getTaskIdPayload);
        }, 1250);*/
        //setIsNoteOpen(true);
      } else {
        setSelectedModule(tempselectedItem.module);
        /*const breadCrumb = {
          functionName: 'logBreadCrumbs',
          zebraUserId: userId,
          userid: userId,
          breadcrumbkey: 'VIEWSERVICE',
          refTableId: tempselectedItem.serviceId,
          refId: tempselectedItem.referralId,
          refTable: tempselectedItem.module,
        };
        logBreadCrumbs(breadCrumb);*/
        const breadCrumb2 = {
          functionName: 'logBreadCrumbs',
          zebraUserId: userId,
          userid: userId,
          breadcrumbkey: 'CLOSESERVICE',
          refTableId: selectedItem.serviceId,
          refId: selectedItem.referralId,
          refTable: selectedItem.module,
        };
        logBreadCrumbs(breadCrumb2);
        setSelectedItem(tempselectedItem);
        setReadyForBilling(tempselectedItem.readyForGp);
        if (!tempselectedItem.sentToGP) {
          setDisableSave(false)
        }else{
          setDisableSave(true)
        }
        setRefTransportation(tempselectedItem.module);
        setDetails(tempselectedItem);
        setRushText('');
        if (tempselectedItem.outcomeId > 41) {
          setShow(true);
        } else {
          setShow(false);
        }
        setReadyForBilling(tempselectedItem.readyForGp);
        if (tempselectedItem.outcomeId == 52) {
          setReasonList(cmbAltBillReason);
        } else if (tempselectedItem.outcomeId == 1050) {
          setReasonList(cmbMissedBillReason);
        } else {
          setReasonList(cmbBillReason);
        }
        if (tempselectedItem.rush) {
          setRushText('RUSH');
        }
       
        /*let alertPayload = {
          functionName: 'loadReferralAlerts',
          zebraUserId: userId,
          referralId: tempselectedItem.referralId,
          tableAbbrv: tempselectedItem.module,
          serviceId: tempselectedItem.serviceId,
        };
        setTimeout(() => {
        loadReferralAlerts(alertPayload);
        }, 500);*/
        if ( tempselectedItem?.copiedService || tempselectedItem?.serviceId < 500  ){
          let taskPayload = {
            loadCurrentTaskId:false,
            loadGroupTaskProgression: false,
            loadExistingTasks: true,
            loadReferralAlerts: tempselectedItem.referralId === 0 ? false : true,
            loadFollowUpdInfo : false,
            zebraUserId: userId,
            table: '',
            referralId: tempselectedItem.referralId,
            tableAbbrv: tempselectedItem.module,
            serviceId: tempselectedItem.serviceId,
            currentTask: 0,
            referenceTable: '',
            claimNo: data.claimNo,
            userid: userId,
            breadcrumbkey: 'VIEWSERVICE',
            breadcrumbrefTableId: tempselectedItem.serviceId,
            breadcrumbrefId: tempselectedItem.referralId,
            breadcrumbrefTable: tempselectedItem.module,
          };
          callDynamicLoad(taskPayload);
          /*
          let getTaskIdPayload = {
            functionName: 'getCurrentTaskId',
            zebraUserId: userId,
            tableAbbrv: '',
            serviceId: 1,
          };
          let taskPayload = {
            functionName: 'loadExistingTasks',
            zebraUserId: userId,
            referralId: 0,
            tableAbbrv: '',
            serviceId: 0,
            from: 'fromzeroservice',
          };
          getCurrentTaskId(getTaskIdPayload);
          loadExistingTasks(taskPayload);*/
          
        }else{
          let tablename = '';
          if (tempselectedItem.module === "ACC"){
            tablename = 'accommodation';
          }else if(tempselectedItem.module === "AUD"){
            tablename = 'audiology';
          }else if(tempselectedItem.module === "DTL"){
            tablename = 'dental';
          }else if(tempselectedItem.module === "DGN"){
            tablename = 'diagnostics';
          }else if(tempselectedItem.module=== "MOD"){
            tablename = 'modifications';
          }else if(tempselectedItem.module === "INT"){
            tablename = 'interpretation';
          }else if(tempselectedItem.module === "TRN"){
            tablename = 'transportation';
          }else if(tempselectedItem.module === "SPT"){
            tablename = 'special_transp';
          }else if(tempselectedItem.module === "MSE"){
            tablename = 'mse';
          }else if(tempselectedItem.module === "HHC"){
            tablename = 'hhc';
          }
          let taskPayload = {
            loadCurrentTaskId : true,
            loadGroupTaskProgression : true,
            loadExistingTasks : true,
            loadReferralAlerts : true,
            loadFollowUpdInfo : true,
            zebraUserId: userId,
            table: tempselectedItem?.module
            ? shortToDatabaseTable(tempselectedItem.module)
            : '',
            referralId: tempselectedItem.referralId,
            tableAbbrv: tempselectedItem.module,
            serviceId: tempselectedItem.serviceId,
            currentTask: tempselectedItem.currentTaskId,
            referenceTable: tempselectedItem.module,
            claimNo: data.claimNo,
            userid: userId,
            breadcrumbkey: 'VIEWSERVICE',
            breadcrumbrefTableId: tempselectedItem.serviceId,
            breadcrumbrefId: tempselectedItem.referralId,
            breadcrumbrefTable: tempselectedItem.module,
          };
          callDynamicLoad(taskPayload);
         /* let taskPayload = {
            functionName: 'loadExistingTasks',
            zebraUserId: userId,
            referralId: tempselectedItem.referralId,
            tableAbbrv: tempselectedItem.module,
            serviceId: tempselectedItem.serviceId,
            from: 'serviceGridChange',
          };
          let groupProgressPayload = {
            functionName: 'groupTasksProgression',
            zebraUserId: userId,
            currentTask: tempselectedItem.currentTaskId,
            referenceTable: tempselectedItem.module,
            claimNo: data.claimNo,
            serviceId: tempselectedItem.serviceId,
          };
          let getTaskIdPayload = {
            functionName: 'getCurrentTaskId',
            zebraUserId: userId,
            tableAbbrv: tempselectedItem.module,
            serviceId: tempselectedItem.serviceId,
          };
          setTimeout(() => {
            loadExistingTasks(taskPayload);
          }, 1500);
          setTimeout(() => {
            groupTasksProgression(groupProgressPayload);
          }, 750);
          setTimeout(() => {
            getCurrentTaskId(getTaskIdPayload);
          }, 1250);*/
        }
        
        if (
          tempselectedItem?.copiedService === undefined &&
          tempselectedItem?.newServiceId === undefined
        ) {
          let hcpcParams = {
            zebraUserId: userId,
            audiology_id: tempselectedItem.serviceId,
            tableAbbrv: tempselectedItem?.module,
            transport_type:
              tempselectedItem?.module === 'SPT'
                ? tempselectedItem?.specialTransportationCategoryId
                : 0,
          };
          let costParams = {
            zebraUserId: userId,
            audiology_id: tempselectedItem.serviceId,
            tableAbbrv: tempselectedItem.module,
          };
          const req = {
            functionName:"getPayableLines",
            zebraUserId: userId,
            referralId:  tempselectedItem.referralId,
            serviceId: tempselectedItem.serviceId,
            referenceTable: tempselectedItem.module
        }
          setTimeout(() => {
            loadAudiologyHcpcsList(hcpcParams);
          }, 1750);
          setTimeout(() => {
            loadCostSavings(costParams);
          }, 2250);
          setTimeout(() => {
            loadPayableLines(req);
          }, 2500);
        }
        //setIsNoteOpen(true);
      }
      requiredServiceData(tempselectedItem);

      setAdvValues(`${tempselectedItem?.referralId}-${tempselectedItem?.module}-${tempselectedItem?.serviceId}`)
    } else {
     requiredServiceData(selectedItem);
      // setIsNoteOpen(false);
    }
    setItemChanged(false);
  };

  const BIGLOOP = () => {
    var nextServ
    var nextReferral
    var nextService
    nextServ = selectedItem.module;
    nextReferral = selectedItem.referralId;
    nextService = selectedItem.serviceId;
    for (var i = 0; i < 10000; i++) {
      setTimeout(function() {
        nextReferral = nextReferral + 1;
        if (nextServ === 'ACC' ){
          nextServ = 'AUD';
        }else if (nextServ === 'AUD' ){
          nextServ = 'DTL';
        }else if (nextServ === 'DTL' ){
            nextServ = 'INT';
        }else if (nextServ === 'INT' ){
          nextServ = 'HHC';
        }else if (nextServ === 'HHC' ){
          nextServ = 'MOD';
        }else if (nextServ === 'MOD' ){
          nextServ = 'MSE';
        }else if (nextServ === 'MSE' ){
          nextServ = 'TRN';
        }else if (nextServ === 'TRN' ){
          nextServ = 'SPT';
        }else if (nextServ === 'SPT' ){
          nextServ = 'ACC';
        }

        
      
          const breadCrumb = {
            functionName: 'logBreadCrumbs',
            zebraUserId: userId,
            userid: userId,
            breadcrumbkey: 'VIEWSERVICE',
            refTableId: nextService,
            refId: nextReferral,
            refTable: nextServ,
          };
          logBreadCrumbs(breadCrumb);
          const breadCrumb2 = {
            functionName: 'logBreadCrumbs',
            zebraUserId: userId,
            userid: userId,
            breadcrumbkey: 'CLOSESERVICE',
            refTableId: nextService,
            refId: nextReferral,
            refTable: nextServ,
          };
          logBreadCrumbs(breadCrumb2);
          
          let taskPayload = {
            functionName: 'loadExistingTasks',
            zebraUserId: userId,
            referralId: nextReferral,
            tableAbbrv: nextServ,
            serviceId: nextService,
            from: 'serviceGridChange',
          };
          let groupProgressPayload = {
            functionName: 'groupTasksProgression',
            zebraUserId: userId,
            currentTask: selectedItem.currentTaskId,
            referenceTable: nextServ,
            claimNo: data.claimNo,
            serviceId: nextService,
          };
          let getTaskIdPayload = {
            functionName: 'getCurrentTaskId',
            zebraUserId: userId,
            tableAbbrv: nextServ,
            serviceId: nextService,
          };
          let alertPayload = {
            functionName: 'loadReferralAlerts',
            zebraUserId: userId,
            referralId: nextReferral,
            tableAbbrv: nextServ,
            serviceId: nextService,
          };
          
          loadReferralAlerts(alertPayload);
          
       
            loadExistingTasks(taskPayload);
        
            groupTasksProgression(groupProgressPayload);
         
            getCurrentTaskId(getTaskIdPayload);
       
        }, 10000);
        //setIsNoteOpen(true);
      } 
  };

  const onModuleChange = mod => {
    setSelectedModule(mod);
  };

  const loadModule = () => {
    if (selectedModule === 'ACC') {
      return (
        <Fast360Acc
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      );
    } else if (selectedModule === 'AUD') {
      return (
        <Fast360AUD
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          audPo={selectedExtData}
          selectedMod={selectedExtData.svc}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (
      selectedModule === 'MSE' ||
      selectedModule === 'TEN' ||
      selectedModule === 'OPP'
    ) {
      return (
        <Fast360MSE
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          msePO={selectedExtData}
          updatePostExternal={updatePostExternal}
          selectedMod={selectedExtData.svc}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (selectedModule === 'MOD') {
      return (
        <Fast360MOD
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          modPO={selectedExtData}
          selectedMod={selectedExtData.svc}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (selectedModule === 'DTL') {
      return (
        <Fast360DTL
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          dtlPo={selectedExtData}
          selectedMod={selectedExtData.svc}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (selectedModule === 'DGN') {
      return (
        <Fast360DGN
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (selectedModule === 'HHC') {
      return (
        <Fast360HHC
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          hhcPO={selectedExtData}
          selectedMod={selectedExtData.svc}
          isFromLanding={isFromLanding}
          dataFromWorklist={updateLoadFromWorkList}
        />
      );
    } else if (selectedModule === 'INT') {
      return (
        <Fast360INT
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          intPo={selectedExtData}
          selectedMod={selectedExtData.svc}
          updatePostExternal={updatePostExternal}
        />
      );
    } else if (selectedModule === 'TRN') {
      return (
        <Fast360TRN
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          fromExt={selectedExtData.svc ? true : false}
          trnPo={selectedExtData}
          selectedMod={selectedExtData.svc}
          updatePostExternal={updatePostExternal}
        />
      );
    } else if (selectedModule === 'SPT') {
      return (
        <Fast360SPT
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      );
    }
    return '';
  };
  const loadPlaceOfService = () => {
    if (servicesData.length === 0) {
      return '';
    } else {
      if (
        !isFromLanding &&
        selectedModule != '' &&
        selectedModule != 'OPP' &&
        selectedModule != 'TEN' &&
        selectedModule != 'ACC' &&
        selectedModule != 'SPT' &&
        selectedModule != 'TRN' &&
        selectedModule != 'INT' &&
        selectedModule != 'AUD' &&
        selectedModule != 'HHC' &&
        selectedModule != 'DGN' &&
        selectedModule != 'MSE' &&
        selectedModule != 'DTL' &&
        selectedModule != 'MOD'
      ) {
        return;
      }

      return (
        <Fast360PlaceOfService
          selectedService={selectedItem}
          setSelectedItem={setSelectedItem}
          isFromLanding={isFromLanding}
          refTransportation={refTransportation}
        />
      );
    }
  };

  const handleCopy = () => {
    let sData = [...servicesData];
    let count = 0;
    let copiedService = true;
    sData.map(i => i.module === selectedItem.module && count++);
    setIndex(sData?.length);
    const selectedServiceObj = JSON.parse(JSON.stringify(selectedItem));

    if (selectedServiceObj.detailList && selectedServiceObj.detailList.length > 0) {
      let detailsArr = JSON.parse(JSON.stringify(selectedServiceObj.detailList));
      if (detailsArr.length > 0 && (selectedServiceObj.module === "TRN" || selectedServiceObj.module === "SPT")) {
        if (selectedServiceObj.module === "TRN") {
          if (trnDeletedList.length > 0) {
            trnDeletedList.map(delItem => {
              detailsArr = detailsArr.filter(detailObj => detailObj.id !== delItem);
            });
          }
          if (trnDetailsList.length > 0) {
            trnDetailsList.map(detailItem => {
              if (detailItem.id) {
                detailsArr = detailsArr.filter(detailObj => detailObj.id !== detailItem.id);
              } else {
                detailsArr = detailsArr.filter(detailObj => detailObj.tempId !== detailItem.tempId);
              }
            });
          }
        }
        if (selectedServiceObj.module === "SPT" && sptDeletedList.length > 0) {
          sptDeletedList.map(delItem => {
            detailsArr = detailsArr.filter(detailObj => detailObj.id !== delItem);
          });
        }
        detailsArr = detailsArr.map(itemObj => { return { ...itemObj, id: 0, serviceId: count + 1, tempId: `${selectedServiceObj.module}-${Math.floor(Math.random() * 1000)}` } });
        selectedServiceObj.module === "TRN" ? updateTRNInfoTable(detailsArr) : updateSPTInfoTable(detailsArr);
        selectedServiceObj.detailList = [];
      }
    }
    if (selectedServiceObj.module === "TRN") {
      let filteredList = trnDetailsList.filter(detailObj => detailObj.serviceId == selectedServiceObj.serviceId);
      if (filteredList.length > 0) {
        filteredList = filteredList.map(itemObj => {
          let curItemObj = JSON.parse(JSON.stringify(itemObj));
          return { ...curItemObj, id: 0, serviceId: count + 1, tempId: `${selectedServiceObj.module}-${Math.floor(Math.random() * 1000)}` }
        });
        updateTRNInfoTable(filteredList);
      }
    }    
    if (selectedServiceObj.module === "SPT") {
      let filteredSPTList = sptDetailsList.filter(detailObj => detailObj.serviceId == selectedServiceObj.serviceId);
      if (filteredSPTList.length > 0) {
        filteredSPTList = filteredSPTList.map(itemObj => {
          let curItemObj = JSON.parse(JSON.stringify(itemObj));
          return { ...curItemObj, id: 0, serviceId: count + 1, tempId: `${selectedServiceObj.module}-${Math.floor(Math.random() * 1000)}` }
        });
        updateSPTInfoTable(filteredSPTList);
      }
    }
    

    const newCopyService = {
      ...selectedServiceObj,
      serviceId: count + 1,
      copiedService: copiedService,
      outcomeId: 39,
    };
    delete newCopyService.reasonId;
    sData.push(newCopyService);
    setServicesData(sData);
    setSelectedItem(newCopyService);
    setDisableSave(false);
    setTempSelectedItem(newCopyService);
    setDetails(newCopyService);
    setRerender(!rerender);
    serviceGridchange('YES', true);
  };

  const handleDelete = () => {
    toggle();
    if (selectedItem.module == 'TRN' && trnsaveInfo == false) trnSaveData(true);
    if (selectedItem.module == 'SPT' && sptSaveinfo == false) sptsaveData(true);
  };

  const setDisable = () => {
    if (servicesData.length > 0 && selectedItem !== undefined) return false;
    if (servicesData.length === 0 || selectedModule === '') return true;
  };

  const handleOptions = opt => {
    if (opt === 'YES') {
      let sData = servicesData;
      const index = sData.findIndex(
        i =>
          i.module == selectedItem.module &&
          i.serviceId == selectedItem.serviceId
      );
      if (index > -1) {
        sData.splice(index, 1);
      }
      setServicesData(sData);
      setSelectedModule('');
      setDetails({});
      setRerender(!rerender);
      toggle();
    }
    if (opt === 'NO') {
      toggle();
    }
  };

  const toggle = () => {
    setCancelClicked(!cancelClicked);
  };

  const handleEmailClick = () => {
    if (emailClicked) {
      let breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'CLOSEEMAIL',
        refTableId: selectedItem.serviceId,
        refId: selectedItem.referralId,
        refTable: selectedItem.module,
      };

      logBreadCrumbs(breadCrumb);
    } else {
      let breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENEMAIL',
        refTableId: selectedItem.serviceId,
        refId: selectedItem.referralId,
        refTable: selectedItem.module,
      };

      logBreadCrumbs(breadCrumb);
    }
    setEmailClicked(!emailClicked);
  };
  const handleFileClick = () => {
    if (selectedItem?.serviceId < 50) {
      alert('Please save the service before you attach files.');
    } else {
      setFileClicked(!fileClicked);
    }
  };

  const handleEligibilityClick = () => {
    setEligibilityClicked(!eligibilityClicked);
  };

  useEffect(() => {
    if (
      (serviceData.module === 'INT' || serviceData.module === 'TRN') &&
      !showAssignmentButton && serviceData.vendor && serviceData.vendor.name
    ) {
        setShowAssignmentButton(true);
    }
  }, [
    currentWorkList.ServiceType,
    setShowAssignmentButton,
    showAssignmentButton,
    serviceData
  ]);

  useEffect(() => {
    if (finalizeEmailAssignmentData && finalizeEmailAssignmentData.length > 0) {
      if (isUppdateEmail) {
        setEmailSubject(finalizeEmailAssignmentData[1].chr_subject);
        setEmailContent(finalizeEmailAssignmentData[1].chr_body);
      } else {
        setEmailSubject(finalizeEmailAssignmentData[0].chr_subject);
        setEmailContent(finalizeEmailAssignmentData[0].chr_body);
      }
    }
  }, [finalizeEmailAssignmentData]);

  const showFinalizeAssignemtModal = () => {
    const categories = gpIntegrationList.productList
      .map(list => list.name)
      .filter(
        category =>
          category === 'Interpretation' || category === 'Transportation'
      );
      let servicesMap = {'INT': 'Interpretation', 'TRN': 'Transportation'}
    const currentCategory = servicesMap[serviceData.module];
    const result = currentCategory && categories.includes(currentCategory);
    let subject = '';
    if (result) {
      let email = '';

      const primaryMail =
        vendor.primaryContact && vendor.primaryContact.people
          ? vendor.primaryContact.people.email1
          : '';
      setVendorEmail(primaryMail);

      if (currentCategory === 'Interpretation') {
        email = fromEmails.find(email => email.name === 'Interpretation').email;
        setFromEmail(email);
      } else if (currentCategory === 'Transportation') {
        email = fromEmails.find(email => email.name === 'Transportation').email;
        setFromEmail(email);
      }
    }
    const cnfNo = `${serviceData.referralId}-${serviceData.module}-${serviceData.serviceId}`;
    const serviceDate = new Date(serviceData.dos);
    const day = serviceDate.getDate();
    const month = serviceDate.getMonth() + 1;
    const fullYear = serviceDate.getFullYear();
    let peopleObj = updateLoadFromWorkList?.claim?.claimant?.people || {};
    subject = `New ${currentCategory} Service Request for ${peopleObj.last || ''} ${peopleObj.first || ''} (${cnfNo}) ${month}/${day}/${fullYear}`;
    setEmailContent('');
    setEmailSubject(subject);
    setFinalizeModalOpen(true);
  };

  const sendAssignmentEmail = () => {
    const req = {
      functionName: 'sendEmail',
      userId: userId,
      emailFrom: fromEmail,
      emailTo: [vendorEmail],
      subject: emailSubject,
      body: emailContent,
    };
    createLetter();
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'FINALIZEASSIGNMENT',
      refTableId: serviceData.serviceId,
      refId: serviceData.referralId,
      refTable: serviceData.module,
    };
    //helper.request.post('/SaveObject', breadCrumb);

    logBreadCrumbs(breadCrumb);
    // sendFinalizeAssignmentEmail(req);
    setFinalizeModalOpen(false);

    setEmailConfirmationModalOpen(true);
  };

  const createLetter = () => {
    const actionUrl = new URL(
      'fast360/letters/createLetter.php',
      process.env.REACT_APP_SLP_BASE_URL
    );
    const params = new URLSearchParams();
    params.append('setFlag', 1);
    params.append('from', fromEmail);
    params.append('tableType', serviceData.module ? serviceData.module : '');
    params.append('tablename', serviceData.module === 'TRN' ? 'transportation' : 'interpretation' );
    params.append('subject', emailSubject);
    params.append('sendEmail', 1);
    params.append('id', 1);
    params.append('bulk', 1);
    params.append(
      'reportName',
      serviceData.module && serviceData.module === 'INT'
        ? 'lmsVendorInterp.pdf'
        : 'lmsVendorTransport.pdf'
    );
    params.append('printType', 'SendEmail');
    params.append('sendTo', vendorEmail);
    params.append('vendorEmail', vendorEmail);
    params.append('msgBody', emailContent);
    params.append(
      'referrals[]',
      serviceData.referralId ? serviceData.referralId : ''
    );
    params.append(
      'services[]',
      serviceData.serviceId ? serviceData.serviceId : ''
    );
    params.append('action', 'createLMSReport');
    params.append('setFlag', 1);
    actionUrl.search = params.toString();
    FetchUtils.openWindowWithPost(
      actionUrl.href,
      '',
      'Vendor Assignment Email Sent'
    );
  };

  const getEmailConfirmationBody = () => {
    return (
      <div className="my-2 d-flex justify-content-center">
        <span>
          Vendor Assignment email has been sent. Orders have been moved to
          Vendor Confirmation.
        </span>
      </div>
    );
  };

  const getEmailConfirmationFooter = () => {
    return (
      <div className="d-flex flex-row justify-content-center w-100 m-0">
        <Button
          color="primary"
          className="px-5"
          onClick={() => setEmailConfirmationModalOpen(false)}
        >
          OK
        </Button>
      </div>
    );
  };

  const getEmailBody = () => {
    return (
      <div style={{ minHeight: '300px' }}>
        <div class="d-flex flex-row align-items-center">
          <div className="form-group w-100 d-flex">
            <label
              style={{ minWidth: '75px' }}
              className="col-form-label"
              for="verndor-email"
            >
              To:
            </label>
            <input
              type="text"
              name="verndor-email"
              id="verndor-email"
              onChange={e => setVendorEmail(e.target.value)}
              className="form-control"
              value={vendorEmail}
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div className="form-group w-100 d-flex">
            <label
              style={{ minWidth: '75px' }}
              className="col-form-label"
              for="from-email"
            >
              From:
            </label>
            <input
              type="text"
              name="from-email"
              id="from-email"
              onChange={e => setFromEmail(e.target.value)}
              className="form-control"
              value={fromEmail}
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div className="form-group w-100 d-flex">
            <label
              style={{ minWidth: '75px' }}
              className="col-form-label"
              for="email-subject"
            >
              Subject:
            </label>
            <input
              type="text"
              name="email-subject"
              id="email-subject"
              onChange={e => setEmailSubject(e.target.value)}
              className="form-control"
              value={emailSubject}
            />
          </div>
        </div>
        <div class="d=flex flex-column">
          {finalizedBodyPreview ? (
            <div
              className="form-group w-100 d-flex"
              dangerouslySetInnerHTML={{ __html: emailContent }}
            ></div>
          ) : (
            <div className="form-group w-100 d-flex">
              <label
                style={{ minWidth: '75px' }}
                className="col-form-label"
                for="email-body"
              >
                Email Body:
              </label>
              <textarea
                rows={10}
                className="px-3 d-block w-100 form-control"
                onChange={e => setEmailContent(e.target.value)}
                value={emailContent}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  const emailOptionSelectHandler = event => {
    if (
      !(
        event.target.value == 'vendor-assignment' ||
        event.target.value == 'updated-service-referral'
      )
    ) {
      setEmailSubject('');
      setEmailContent('');
      return;
    }

    // const phaseId = event.target.value == 'vendor-assignment' ? 1 : 2;
    const isUpdate = event.target.value == 'vendor-assignment' ? false : true;
    // const serviceIds = updateLoadFromWorkList.transportationList.map(service => service.id);
    // const additialServices = updateLoadFromWorkList.translationList.map(service => service.id);
    setIsUpdateEmail(isUpdate);

    const req = {
      functionName: 'getVendorEmailTemplates',
      userId: userId,
      referralId: serviceData.referralId,
      // serviceIds: [...serviceIds, ...additialServices].join(', '), // comma seperated multile serivce ids
      serviceIds: serviceData.serviceId, // comma seperated multile serivce ids
      refTable:
        serviceData.module,
      typeId: serviceData.module === 'INT' ? 1 : 2,
      phaseId: 1,
    };

    getFinalizeAssignmentEmailBody(req);
  };
  const getMailDialogFooter = () => {
    return (
      <div className="d-flex flex-row justify-content-between w-100">
        <div>
          <select className="form-control" onChange={emailOptionSelectHandler}>
            <option selected>Select Template</option>
            <option value="vendor-assignment">Vendor Assignment</option>
            <option value="updated-service-referral">
              Updated Service Referral
            </option>
          </select>
        </div>
        <div>
          <Button
            className="mr-2"
            color="info"
            onClick={() => setFinalizedBodyPreview(!finalizedBodyPreview)}
          >
            Preview
          </Button>
          <Button
            className="mr-2"
            color="secondary"
            onClick={() => setFinalizeModalOpen(false)}
          >
            Close
          </Button>
          <Button color="primary" onClick={sendAssignmentEmail}>
            Send
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {emailClicked && (
        <Fast360DetailEmail isOpen={emailClicked} clicked={handleEmailClick} />
      )}
      <Fast360WorklistFiles
        isOpen={fileClicked}
        clicked={handleFileClick}
        dataObject={selectedItem}
        currentWorkList={selectedItem}
      />
      <Fast360Eligilibity
        isOpen={eligibilityClicked}
        clicked={handleEligibilityClick}
        claimNo={data.claimNo}
      />
      {cancelClicked && (
        <Modal
          size="md"
          isOpen={true}
          style={{ height: '190px' }}
          scrollable={'true'}
        >
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: '10px' }}>
              <Col sm={12} style={{ marginTop: '6px' }}>
                Are you sure you wish to delete Service Record?{' '}
              </Col>
            </Row>
            <Form>
              <div style={{ float: 'right', marginTop: '30px' }}>
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => handleOptions('NO')}
                >
                  NO
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => handleOptions('YES')}
                >
                  YES
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      )}
      <FormGroup>
        <Row>
       
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('ACC')}
            >
              Accommodations
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('AUD')}
            >
              Audiology
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('DTL')}
            >
              Dental
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('DGN')}
              disabled
            >
              Diagnostics
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('HHC')}
            >
              HHC
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('INT')}
            >
              Interp
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('MOD')}
            >
              Modification
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('MSE')}
            >
              MSE
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('TRN')}
            >
              Transport
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => addService('SPT')}
            >
              Special Transport
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={handleEligibilityClick}
            >
              Eligibility
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={openNotesModal}
            >
              Notes
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={handleEmailClick}
            >
              Email
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={handleFileClick}
              disabled={!worklistData.fromWorklist}
            >
              Files
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={handleTransfer}
            >
              Transfer
            </Button>
          </Col>
          {showAssignmentButton && (
            <Col>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={showFinalizeAssignemtModal}
              >
                Finalize Assignment
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Fast360EditButtons
            currentTaskData={this?.taskData ? this.taskData : []}
            currentTaskId={currentTaskId}
            groupProgressData={
              this?.groupProgressData ? this.groupProgressData : []
            }
            worklistData={worklistData.workListData}
            currentUserId={worklistData.userId}
            fromWorklist={worklistData.fromWorklist}
            setFollowupData={worklistData.setFollowupData}
            setFollowupTime={worklistData.setFollowupTime}
            setFollowupDate360={worklistData.setFollowupDate360}
            referralId={selectedItem?.referralId ? selectedItem.referralId : 0}
            chrReferenceTable={selectedItem?.module ? selectedItem.module : 0}
            intReferenceTableId={
              selectedItem?.serviceId ? selectedItem.serviceId : 0
            }
            rushText={rushText}
            setFhrs360={worklistData.setFhrs360}
            setFmin360={worklistData.setFmin360}
            setTimeZ360={worklistData.setTimeZ360}
            data={worklistData.data}
            onWorklistExit={worklistData.onWorklistExit}
            refreshCall={worklistData.refreshCall}
            selectedItem={selectedItem ? selectedItem : {}}
            setOutcomeParent={setCurrentOutcome}
            setSelectedItem={setSelectedItem}
            setReasonList={setReasonList}
            reasonList={reasonList}
            setReadyForBilling={setReadyForBilling}
            readyforBilling={readyForBilling}
            show={show}
            setShow={setShow}
          // setFollowupDate360={setFollowupDate360}
          />
        </Row>
        <br></br>
        <Row
          style={{
            marginLeft: '217px',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        >
          <Button
            type="button"
            style={{ marginRight: '20px' }}
            onClick={() => handleCopy()}
            disabled={setDisable()}
          >
            COPY <br />
          </Button>
          <Button
            type="button"
            style={{ backgroundColor: '#FF0000' }}
            onClick={() => handleDelete()}
            disabled={setDisable()}
          >
            - <br />
          </Button>
        </Row>
        <Row>
          <Col lg="2">
            <ServicesSelection
              servicesData={servicesData}
              setSelectedModule={CheckGridChange}
              rerender={rerender}
              index={index}
            />
          </Col>

          <Col lg="4">{loadModule()}</Col>
          <Col lg="3">
            <Fast360Vendor
              disableSave={disableSave}
              lmsUserId={lmsUserId}
              userId={userId}
              serviceType={serviceType}
              selectedSubService={selectedItem}
              loadReferralWorkList={loadReferralWorkList}
              fromWorklist={worklistData.fromWorklist}
              loadReferralandVender={loadReferralandVender}
            />
          </Col>
          <Col lg="3">{loadPlaceOfService()}</Col>
        </Row>
        {referralAlertData.length > 0 && appLoading.loading === false && (
          <ClaimClientVenNot
            isOpen={isNoteOpen}
            alertData={referralAlertData}
            setReferralAlertData={setReferralAlertData}
            updateloadReferralAlerts={updateloadReferralAlerts}
            setIsNoteOpen={setIsNoteOpen}
          />
        )}
        {transferClicked && (
          <TrasnserFast360
            handleTransferFromModal={handleTransferFromModal}
            clicked={handleTransfer}
            selectedService={selectedItem}
            selectedUser={0}
            onWorklistExit={onWorklistExit}
          />
        )}
        {itemChanged && (
          <SwitchServices serviceGridchange={serviceGridchange} />
        )}
        {openNotes && (
          <Fast360Notes
            clicked={openNotesModal}
            refId={selectedItem?.referralId ? selectedItem.referralId : 0}
            tableName={selectedItem?.module ? selectedItem.module : ''}
            serviceId={selectedItem?.serviceId ? selectedItem.serviceId : 0}
            table={
              selectedItem?.module
                ? shortToDatabaseTable(selectedItem.module)
                : ''
            }
            dataObject={selectedItem ? selectedItem : {}}
            zebraId={worklistData.userId}
            currentOutcomeDesc={currentOutcome}
            worklistData={worklistData}
          />
        )}
      </FormGroup>
      <PopupModal
        content={getEmailBody()}
        title="Email/Reporting"
        externalToggle={() => setFinalizeModalOpen(false)}
        size="xl"
        isOpen={finalizeModalOpen}
        footerContent={getMailDialogFooter()}
      />
      <PopupModal
        title=""
        content={getEmailConfirmationBody()}
        isOpen={emailConfirmationModalOpen}
        externalToggle={() => setEmailConfirmationModalOpen(false)}
        footerContent={getEmailConfirmationFooter()}
        size="lg"
      />
    </>
  );
};

const mapStateToProps = state => {
  const initialData = fast360Selectors.getFast360InitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  const appLoading = commonSelectors.appLoading(state);
  return {
    selectedExtData: state.FAST360.fast360Reducer.selectedExternalData
      ? state.FAST360.fast360Reducer.selectedExternalData
      : {},
    updatePostExternal: state.FAST360.fast360Reducer.updatePostExternal
      ? state.FAST360.fast360Reducer.updatePostExternal
      : [],
    //selectedWorklistFromLanding: state.FAST360.fast360Reducer.selectedWorklistFromLanding ? state.FAST360.fast360Reducer.selectedWorklistFromLanding : {},
    updateLoadFromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    taskData: state.FAST360.fast360Reducer.updateloadExistingTasks
      ? state.FAST360.fast360Reducer.updateloadExistingTasks
      : [],
    currentTaskId: state.FAST360.fast360Reducer.updateloadCurrentTaskId
      ? state.FAST360.fast360Reducer.updateloadCurrentTaskId
      : 0,
    groupProgressData: state.FAST360.fast360Reducer.updateloadGroupProgression
      ? state.FAST360.fast360Reducer.updateloadGroupProgression
      : [],
    alertData: state.FAST360.fast360Reducer.updateloadReferralAlerts
      ? state.FAST360.fast360Reducer.updateloadReferralAlerts
      : [],
    hhcCategory: initialData.hhcCategory,
    accommodationType: initialData.accommodationType,
    appointmentType: initialData.appointmentType,
    translationCategory: initialData.translationCategory,
    translationCertification: initialData.translationCertification,
    diagnosticType: initialData.diagnosticType,
    mseCategory: initialData.mseCategory,
    modificationCategory: initialData.modificationCategory,
    transportCategory: initialData.transportCategory,
    specialTransportCategory: initialData.specialTransportCategory,
    relationShipType: initialData.relationShipType,

    gpIntegrationList: initialData.gpIntegrationList,
    currentWorkList: fast360Info.currentWorkListItem,
    finalizeEmailAssignmentData: fast360Info.finalizeEmailAssignmentData,
    updateLoadFromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList
      ? state.FAST360.fast360Reducer.updateLoadFromWorkList
      : {},
    cmbBillReason: state.FAST360.initialData.cmbBillReason
      ? state.FAST360.initialData.cmbBillReason
      : [],
    cmbAltBillReason: state.FAST360.initialData.cmbAltBillReason
      ? state.FAST360.initialData.cmbAltBillReason
      : [],
    cmbMissedBillReason: state.FAST360.initialData.cmbMissedBillReason
      ? state.FAST360.initialData.cmbMissedBillReason
      : [],
    selectedVendorData: state.FAST360.fast360Reducer.selectedVendorData
      ? state.FAST360.fast360Reducer.selectedVendorData
      : [],
    serviceData: state.FAST360.fast360Reducer.requiredServiceData
      ? state.FAST360.fast360Reducer.requiredServiceData
      : {},
    trnDetailsList: state.FAST360.fast360Reducer.trnDetailsList
      ? state.FAST360.fast360Reducer.trnDetailsList
      : [],
    sptDetailsList: state.FAST360.fast360Reducer.sptDetailsList
      ? state.FAST360.fast360Reducer.sptDetailsList
      : [],
    trnDeletedList: state.FAST360.fast360Reducer.trnDeletedList
      ? state.FAST360.fast360Reducer.trnDeletedList
      : [],
    sptDeletedList: state.FAST360.fast360Reducer.sptDeletedList
      ? state.FAST360.fast360Reducer.sptDeletedList
      : [],
    setVendorDataInfo: state.FAST360.fast360Reducer.setVendorData,
    externalReferralData: state.FAST360.fast360Reducer.updatePostExternal,
    trnsaveInfo: state.FAST360.fast360Reducer.trnsaveInfo,
    sptSaveinfo: state.FAST360.fast360Reducer.sptSaveinfo,
    appLoading: appLoading
  };
};

const mapDispatchToProps = dispatch => ({
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  postupdateLoadFromWorkList: data =>
    dispatch(fast360Actions.updateLoadFromWorkList(data)),
  loadReferralAlerts: data =>
    dispatch(fast360Operations.loadReferralAlerts(data)),
  loadAudiologyHcpcsList: data =>
    dispatch(fast360Operations.loadAudiologyHcpcsList(data)),
  loadCostSavings: data => dispatch(fast360Operations.loadCostSavings(data)),
  resetAudiologyHcpcsList: () =>
    dispatch(fast360Actions.resetAudiologyHcpcsList()),
  resetCostSavings: () => dispatch(fast360Actions.resetCostSavings()),
  groupTasksProgression: data =>
    dispatch(fast360Operations.groupTasksProgression(data)),
  callDynamicLoad: data =>
    dispatch(fast360Operations.callDynamicLoad(data)),
    
  loadExistingTasks: data =>
    dispatch(fast360Operations.loadExistingTasks(data)),
  getCurrentTaskId: data => dispatch(fast360Operations.getCurrentTaskId(data)),
  requiredServiceData: data =>
    dispatch(fast360Actions.requiredServiceData(data)),
  sendFinalizeAssignmentEmail: data =>
    dispatch(fast360Operations.sendFinalizeAssignmentEmail(data)),
  getFinalizeAssignmentEmailBody: data =>
    dispatch(fast360Operations.getFinalizeAssignmentEmailBody(data)),
  logBreadCrumbs: data => dispatch(fast360Operations.logBreadCrumbs(data)),
  setVendorData: data => dispatch(fast360Actions.setVendorData(data)),
  sptsaveData: data => dispatch(fast360Actions.sptsaveData(data)),
  trnSaveData: data => dispatch(fast360Actions.trnSaveData(data)),
  updateTRNInfoTable: data => dispatch(fast360Actions.updateTRNInfoTable(data)),
  updateSPTInfoTable: data => dispatch(fast360Actions.updateSPTInfoTable(data)),
  updateloadReferralAlerts: data => dispatch(fast360Actions.updateloadReferralAlerts(data)),
  setAdvValues: (data) => dispatch(fast360Actions.setAdvValues(data)),
  loadPayableLines: data => dispatch(fast360Operations.loadPayableLines(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360Services);
