
import React from 'react';
import Fast360AppTable from 'shared/components/table/Fast360AppTable';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations, fast360Actions } from '../store';
import { Button, FormGroup } from 'reactstrap';
import CancelFast360Modal from './CancelFast360Modal';
import moment from 'moment';


class Fast360LandingTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isCancelOpen: false,
      canAssignVendorButton: false,
      toggle: false

    }
    this.filterSelectedStatus = this.filterSelectedStatus.bind(this)
    this.filterSelectedTask = this.filterSelectedTask.bind(this)
    this.filterSelectedMileStone = this.filterSelectedMileStone.bind(this)

  }
  handleTransfer = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  handleCancel = () => {
    this.setState({ isCancelOpen: !this.state.isCancelOpen })
  }
  handleAssign = () => {
    //TODO Handle Vendor Assignment for UpdatedRec
    const updatedRec = this.props.selectedVendorData[this.props.selectedVendorData.length - 1];
    if (Object.keys(updatedRec).length === 0) {
      return;
    }
    const { FK_referral_id, int_reference_table_id, chr_claim_no, chr_reference_table, currentTaskID, ServiceType } = updatedRec;
    let assignRefs = this.props.initialWorkList.filter(rec => (rec.chr_reference_table == updatedRec.chr_reference_table && rec.chr_claim_no == updatedRec.chr_claim_no && rec.Vendor == updatedRec.Vendor));
    this.props.setAssignedGrid(assignRefs);
    let serviceIds = assignRefs.map(rec => { return rec.int_reference_table_id });
    let reqData = { ...updatedRec, int_reference_table_id: serviceIds.join(",") }
    this.props.setCurrentWorkList(reqData);
    this.props.openReferral(FK_referral_id, chr_reference_table, int_reference_table_id, currentTaskID, chr_claim_no, ServiceType, true);
  }
  handleConfirmVendor = () => {
    //TODO Handle Vendor Assignment for UpdatedRec
    const updatedRec = this.props.selectedVendorData[this.props.selectedVendorData.length - 1];
    if (Object.keys(updatedRec).length === 0) {
      return;
    }
    const { FK_referral_id, int_reference_table_id, chr_claim_no, Vendor, chr_reference_table, currentTaskID, ServiceType, VendorAssignmentEmailSent } = updatedRec;
    let assignRefs = this.props.initialWorkList.filter(rec => (rec.chr_claim_no === chr_claim_no && rec.chr_reference_table === chr_reference_table && rec.Vendor === Vendor && rec.VendorAssignmentEmailSent === VendorAssignmentEmailSent));
    this.props.setAssignedGrid(assignRefs);
    let serviceIds = assignRefs.map(rec => { return rec.int_reference_table_id });
    let reqData = { ...updatedRec, int_reference_table_id: serviceIds.join(",") }
    this.props.setCurrentWorkList(reqData);
    this.props.openReferral(FK_referral_id, chr_reference_table, int_reference_table_id, currentTaskID, chr_claim_no, ServiceType, true);

  }



  handleTransferFromModal = () => {
    let user = {};
    let userSelected = this.state.userSelected;
    if (this.state.userSelected == "1111") {
      user = {
        zebraId: 1111,
        username: 'Unbilled/Unassigned',
        id: -1,
      };
      userSelected = this.props.currentUserId;
    }
    else if (this.state.userSelected == "2222") {
      user = {
        zebraId: 2222,
        id: 0,
        username: 'Unassigned',
      };
      userSelected = this.props.currentUserId;
    }
    else {
      user = this.state.users && this.state.users.find(u => u.zebraId == Number(this.state.userSelected));
    }
    this.setState({ isOpen: !this.state.isOpen })
    this.props.updateClickRecord();
    setTimeout(() => {
      this.props.getUsersWorkList(user, userSelected)
    }, 3000);
  }
  filterSelectedStatus = (nextProps) => {
    let selected = [], selectedWorkList = [];
    if (nextProps.statusData.length) {
      let selectedItems = nextProps.statusData.filter(i => i.isChecked == true);
      selectedItems.map(i => i.name && selected.push(i.name.toLowerCase().replace(/ /g, '')));
      nextProps.statusBackup.map(i => i.servStatus
        ? selected.includes(i.servStatus.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i)
        : selectedWorkList.push(i));
      //this.props.updateNewUsersWorkList(selectedWorkList);
    }
  }

  filterSelectedTask = (nextProps) => {
    let selected = [], selectedWorkList = [];
    if (nextProps.taskData.length) {
      let selectedItems = nextProps.taskData.filter(i => i.isChecked == true);
      selectedItems.map(i => i.chr_name && selected.push(i.chr_name.toLowerCase().replace(/ /g, '')));
      // if (this.props.milestoneChanged)
      //   this.props.milestoneWorkList.map(i => i.currentTaskName && selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
      // else
      this.props.taskBackUp.map(i => i.currentTaskName && selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
      this.props.updateNewUsersWorkList(selectedWorkList);
    }
  }
  getClaimNo = (item) => {
    return item.chr_claim_no?.split(' ').join('\u00A0')
  }
  filterSelectedMileStone = (nextProps) => {
    let selected = [], selectedWorkList = [];
    if (nextProps.mileStoneData.length) {
      let selectedItems = nextProps.mileStoneData.filter(i => i.isChecked == true);
      selectedItems.map(i => i.name && selected.push(i.name.toLowerCase().replace(/ /g, '')));
      // if (this.props.statusChanged)
      //   this.props.statusWorkList.map(i => i.currentMilestone && selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
      // else
      this.props.mileStoneBackUp.map(i => i.currentMilestone && selected.includes(i.currentMilestone.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
      this.props.updateNewUsersWorkList(selectedWorkList);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (((nextProps.statusData?.length > 0 && this.props.statusData !== nextProps.statusData) ||
      (nextProps.dataFromReducer?.initialWorkList?.length !== this.props.dataFromReducer?.initialWorkList?.length)) && nextProps.selectedType === 'status') {

      this.filterSelectedStatus(nextProps)
    }
    if (((nextProps.mileStoneData?.length > 0 && this.props.mileStoneData !== nextProps.mileStoneData) ||
      (nextProps.dataFromReducer?.initialWorkList?.length !== this.props.dataFromReducer?.initialWorkList?.length)) && nextProps.selectedType === 'mileStone') {
      this.filterSelectedMileStone(nextProps)
    }
    if (((nextProps.taskData?.length > 0 && this.props.taskData !== nextProps.taskData) ||
      (nextProps.dataFromReducer?.initialWorkList?.length !== this.props.dataFromReducer?.initialWorkList?.length)) && nextProps.selectedType === 'task') {

      this.filterSelectedTask(nextProps)
    }

    if (nextProps.dataFromReducer?.initialWorkList?.length > 0 && this?.props?.dataFromReducer?.initialWorkList?.length > 0 &&
      nextProps.dataFromReducer?.initialWorkList?.length !== this?.props?.dataFromReducer?.initialWorkList?.length) {
      // if (nextProps.selectedProduct != "") {
      //   if (nextProps.selectedSubProduct !== "") {

      //     let filterData = nextProps.productBackUp.filter(
      //       i =>
      //         i.ServiceType.toLowerCase() ==
      //         nextProps.selectedProduct.toLowerCase() &&
      //         i.product.toLowerCase().replace(/ /g, '') ==
      //         nextProps.selectedSubProduct.toLowerCase().replace(/ /g, '')
      //     );
      //     this.props.updateNewUsersWorkList(filterData);
      //   }
      //   else {
      //     let filterData = nextProps?.productBackUp?.filter(
      //       i => i.ServiceType.toLowerCase() == nextProps?.selectedProduct?.toLowerCase()
      //     );
      //     this.props.updateNewUsersWorkList(filterData);
      //   }
      // }


      // We are already doing filtering in Fast360TopNav.js
      // if (nextProps.inputField != '') {
      //   const lowercasedFilter = nextProps.inputField;
      //   let filteredData = [];
      //   this.props.dataFromReducer.backupWorkList.map(item => {
      //     if (
      //       (item.confNo && item.confNo.toLowerCase().includes(lowercasedFilter)) ||
      //       (item.chr_claim_no &&
      //         item.chr_claim_no.toLowerCase().includes(lowercasedFilter)) ||
      //       (item.claimantName &&
      //         item.claimantName.toLowerCase().includes(lowercasedFilter)) ||
      //       (item.product &&
      //         item.product.toLowerCase().includes(lowercasedFilter)) ||
      //       (item.Vendor && item.Vendor.toLowerCase().includes(lowercasedFilter)) ||
      //       (item.serviceDate &&
      //         moment(item.serviceDate)
      //           .format('MM/DD/YYYY')
      //           .includes(lowercasedFilter)) ||
      //       (item.lang && item.lang.toLowerCase().includes(lowercasedFilter))
      //     ) {
      //       filteredData.push(item);
      //     }
      //   });
      //   this.props.updateNewUsersWorkList(filteredData);
      // }
    }
    if (nextProps.togValuesChange !== this.props.togValuesChange) {
      this.setState({ toggle: this.state.toggle })
    }
  }

  render() {
    let isNotNormalUser = this.props.selectedUser == '1111' || this.props.selectedUser == '2222',
      updatedRec = this.props.updatedRec,
      assignableRecords = updatedRec.filter(rec => rec.chr_reference_table === 'INT' || rec.chr_reference_table === 'TRN'),
      canAssignVendor = assignableRecords && assignableRecords.length > 0 && assignableRecords.length === updatedRec.length,
      isVendorConfirm = assignableRecords && assignableRecords.length > 0 && assignableRecords.length === updatedRec.length && this.props.selectedVendorData.length > 0 && this.props.selectedVendorData[this.props.selectedVendorData.length - 1].VendorAssignmentEmailSent === '1';
    return (
      <>
        <Fast360AppTable
          columns={[
            {
              header: 'Conf #',
              field: 'confNo',
              percent: '13',

            },
            {
              header: 'Renewal',
              percent: '3',
            },
            {
              header: 'Priority',
              field: 'bol_rush',
              percent: '3',
            },
            {
              header: 'Task',
              field: 'currentTaskName',
              percent: '4.5',
            },
            {
              header: 'OOC',
              field: 'ooc',
              percent: '3',
            },
            {
              header: 'Claimant Name',
              field: 'claimantName',
              percent: '6',
            },
            {
              header: 'Claim #',
              field: 'chr_claim_no',
              render: item => this.getClaimNo(item),
              percent: '4.5',
            },
            {
              header: 'Product',
              field: 'ServiceType',
              percent: '4.5',
            },
            {
              header: 'Date of Service',
              field: 'serviceDate',
              percent: '4.5',
            },
            {
              header: 'Vendor',
              field: 'Vendor',
              percent: '8.5',
            },
            isNotNormalUser ?
              {
                header: 'Claimant Phone #',
                field: 'chr_phone',
                percent: '4.5',
                hide: false
              } : {
                header: 'Claimant Phone #',
                field: 'chr_phone',
                percent: '12',
                hide: true
              },
            !isNotNormalUser ?
              {
                header: 'Claimant State',
                field: 'claimantState',
                percent: '3',
                hide: false
              } : {
                header: 'Claimant State',
                field: 'claimantState',
                percent: '3',
                hide: true
              },
            {
              header: 'Language',
              field: 'lang',
              percent: '4.5',
            },
            {
              header: 'Outcome',
              field: 'outcome',
              percent: '4.5',
            },
            {
              header: 'Billed Date',
              field: 'billedDate',
              percent: '4.5',
            },
            !isNotNormalUser ?
              {
                header: 'Follow Up Date',
                field: 'FollowupDate',
                percent: '4.5',
                hide: false
              } :
              {
                header: 'Follow Up Date',
                field: 'FollowupDate',
                percent: '4.5',
                hide: true
              },
            !isNotNormalUser ?
              {
                header: 'Follow Up Reason',
                field: 'FollowupType',
                percent: '6.5',
                hide: false
              } : {
                header: 'Follow Up Reason',
                field: 'FollowupType',
                percent: '6.5',
                hide: true
              },
            !isNotNormalUser ?
              {
                header: 'Last Note Updated',
                field: 'lastNoteModifiedDate',
                percent: '4.5',
                hide: false
              } : {
                header: 'Last Note Updated',
                field: 'lastNoteModifiedDate',
                percent: '4.5',
                hide: true
              },
            {
              header: 'Notes',
              field: 'notes',
              percent: '2.5',
              sortAble: false,
            },
            {
              header: 'Email/Print',
              field: 'emailPrint',
              percent: '2.5',
              sortAble: false,
            },
            {
              header: 'Files',
              field: 'files',
              percent: '2.5',
              sortAble: false,
            },
            !isNotNormalUser ? {
              header: 'V.A',
              field: 'vendorCount',
              percent: '2.5',
              hide: false
            } : {
              header: 'V.A',
              field: 'vendorCount',
              percent: '2.5',
              hide: true
            },
            {
              header: '',
              percent: '2.5',
            },
          ]}
          data={this.props.initialWorkList}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          zebraId={this.props.zebraId}
          openReferral={this.props.openReferral}
          refreshCall={this.props.refreshCall}
        />
        <div className="float-right bill-review-summary">
          <span>{this.props.initialWorkList.length} Records Found</span>
          <FormGroup className="float-right bill-review-summary">
            {canAssignVendor && !isVendorConfirm && this.props.selectedVendorData.length > 0 && this.props.selectedUser !== '1111' && this.props.selectedUser !== '2222' && <Button onClick={this.handleAssign} type="button">Assign Vendor</Button>}
            {canAssignVendor && isVendorConfirm && this.props.selectedVendorData.length > 0 && <Button onClick={this.handleConfirmVendor} type="button">Vendor Confirmation</Button>}

            <Button
              onClick={this.handleCancel}
              type="button"
              disabled={updatedRec.length ? false : true}>CANCEL</Button>
            {this.state.isCancelOpen && <CancelFast360Modal clicked={this.handleCancel} selectedUser={this.props.selectedUser} />}

          </FormGroup>

        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    initialWorkList: state.FAST360.initialData.initialWorkList ? state.FAST360.initialData.initialWorkList : [],
    updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
    selectedVendorData: state.FAST360.fast360Reducer.selectedVendorData ? state.FAST360.fast360Reducer.selectedVendorData : [],
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    mileStoneData: state.FAST360.fast360Reducer.updateMilestoneData,
    statusData: state.FAST360.fast360Reducer.updatedStatusData,
    taskData: state.FAST360.fast360Reducer.updateTaskData,
    selectedProduct: state.FAST360.fast360Reducer.selectedProduct,
    selectedSubProduct: state.FAST360.fast360Reducer.selectedSubProduct,
    milestoneWorkList: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.milestoneWorkListBackup : [],
    statusWorkList: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.statusWorkListBackup : [],
    selectedType: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.selectedType : [],
    inputField: state.FAST360.fast360Reducer.inputField,
    mileStoneBackUp: state.FAST360.fast360Reducer.mileStoneBackUp,
    taskBackUp: state.FAST360.fast360Reducer.taskBackUp,
    statusBackup: state.FAST360.fast360Reducer.statusBackUp,
    mileStoneBackUp: state.FAST360.fast360Reducer.mileStoneBackUp,
    productBackUp: state.FAST360.fast360Reducer.productBackUp,
    subProductBackUp: state.FAST360.fast360Reducer.subProductBackUp,
    togValuesChange: state.FAST360.fast360Reducer.updateToggleValues


  };
};
const mapDispatchToProps = dispatch => {
  return {
    setCurrentWorkList: data => dispatch(fast360Actions.setCurrentWorkList(data)),
    updateNewUsersWorkList: (data) => dispatch(fast360Actions.updateNewUsersWorkList(data)),
    setMilestoneBackUp: (data) => dispatch(fast360Actions.mileStoneBackUp(data)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fast360LandingTable);