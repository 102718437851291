import { ServiceExecutor } from 'core/services/serviceExecutor';
import { commonActions } from 'common/store';
import service from '../services/service';
import actions from './actions';

const getModFromTable = (serviceAbrv) => {
  if (serviceAbrv.toLowerCase() === "hhc") {
    return "HHC";
  } else if (serviceAbrv.toLowerCase() === "audiology") {
    return "AUD";
  } else if (serviceAbrv.toLowerCase() === "dental") {
    return "DTL";
  } else if (serviceAbrv.toLowerCase() === "diagnostics") {
    return "DGN";
  } else if (serviceAbrv.toLowerCase() === "modifications") {
    return "MOD";
  } else if (serviceAbrv.toLowerCase() === "mse") {
    return "MSE";
  } else if (serviceAbrv.toLowerCase() === "transportation") {
    return "TRN";
  } else if (serviceAbrv.toLowerCase() === "interpretation") {
    return "INT";
  } else if (serviceAbrv.toLowerCase() === "special_transp") {
    return "SPT";
  } else if (serviceAbrv.toLowerCase() === "accommodation") {
    return "ACC";
  }
  return serviceAbrv;
};

const loadInitialData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadInitialData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadInitialData(responseData));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const loadLookupReport = (data, userId, mergedCheck, queryStr) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadLookupReport(data, userId))
      .withResponseHandler(responseData => {
        let resArr = [...responseData];
        if (queryStr && resArr.length > 0) {
          resArr = resArr.filter(resObj => resObj.hcpc && resObj.hcpc.indexOf(queryStr) > -1);
        }
        dispatch(actions.loadLookupReport(resArr));
        if (mergedCheck) {
          const merged = resArr
            .map(e => e['ConfirmNumber'])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => resArr[e])
            .map(e => resArr[e]);

          dispatch(actions.mergeFilterData(merged));
        }
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const vendorLookUpStoreProcedure = (data, setVendorSearchCall) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        service.vendorLookUpStoreProcedure(data, setVendorSearchCall)
      )
      .withResponseHandler(responseData => {
        // dispatch(actions.vendorLookUpStoreProcedure(responseData));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const getEvaluateVendorData = (data, setOpenVendorEvaluateModal) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        service.getEvaluateVendorData(data, setOpenVendorEvaluateModal)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.evaluateVendorData(responseData));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const getVendorHistoryData = (data, setOpenVendorEvaluateModal) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        service.getVendorHistoryData(data, setOpenVendorEvaluateModal)
      )
      .withResponseHandler(responseData => {
        dispatch(actions.getVendorHistoryData(responseData));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const vendorLookUpAssign = (data, updatedWorkList, selectedSubService) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() =>
        service.vendorLookUpAssign(data)
      )
      .withResponseHandler(responseData => {
        if (selectedSubService) {
          let postPayload = {
            zebraUserId: data.zebraUserId,
            functionName: "loadVendor",
            vendor_id: data.vendorId
          };
          dispatch(loadUpdatedVendor(postPayload, selectedSubService, updatedWorkList));
        }
      })
      .withFailureAlert('An error occurred while assigning vendor.')
      .build()
      .execute();
  };
};

const vendorLookupSuggestions = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.vendorLookupSuggestions(data))
      .withResponseHandler(responseData => {
        dispatch(actions.vendorLookupSuggestions({ [data.serviceId]: responseData }));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const vendorLookupTop5ByLocation = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.vendorLookupTop5ByLocation(data))
      .withResponseHandler(responseData => {
        dispatch(actions.vendorLookupTop5ByLocation({ [data.serviceId]: responseData }));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const vendorLookupTop5ByCount = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.vendorLookupTop5ByCount(data))
      .withResponseHandler(responseData => {
        dispatch(actions.vendorLookupTop5ByCount({ [data.serviceId]: responseData }));
      })
      .withFailureAlert('An error occurred while loading the initial data.')
      .build()
      .execute();
  };
};

const getDefaultCostSavingsPercent = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getDefaultCostSavingsPercent(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDefaultPercent(responseData));
      })
      .withFailureAlert('An error occurred while finding Default Percent data.')
      .build()
      .execute();
  };
};

const saveFees = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveFees(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveFeesData(responseData));
      })
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveFeesData(responseData));
        if (req) {
          dispatch(loadFromWorkList(req));
        }
      })
      .withSuccessAlert('Successfully saved fees data.')
      .withFailureAlert('An error occurred while saving fees data.')
      .build()
      .execute();
  };
};

const saveCosts = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveCosts(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveCostsData(responseData));
      })
      .withFailureAlert('An error occurred while saving Costs data.')
      .build()
      .execute();
  };
};

const getServiceIdFromReference = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getServiceIdFromReference(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateServiceIdFromReference(responseData));
      })
      .withFailureAlert('An error occurred while getting id from reference.')
      .build()
      .execute();
  };
};

const loadProductSearchList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadProductSearchList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadProductSearchTable(responseData));
      })
      .withFailureAlert('An error occurred while finding HCPC data.')
      .build()
      .execute();
  };
};

const productSubmit = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.productSubmit(data))
      .withResponseHandler(responseData => {
        //dispatch(actions.updateLoadProductSearchTable(responseData));
        dispatch(actions.updateProductCatalog(1));
      })
      .withFailureAlert('An error occurred while finding HCPC data.')
      .build()
      .execute();
  };
};
const loadHCPC = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadHCPC(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadHCPCTable(responseData));
      })
      .withFailureAlert('An error occurred while finding HCPC data.')
      .build()
      .execute();
  };
};

const lookupHcpcWithLimit = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.lookupHcpcWithLimit(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadHCPCWithLimit(responseData));
      })
      .withFailureAlert('An error occurred while finding HCPC Search data.')
      .build()
      .execute();
  };
};

const saveHHCHCPC = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveHHCHCPC(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveHHCHCPC(responseData));
      })
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveHHCHCPC(responseData));
        if (req) {
          dispatch(loadAudiologyHcpcsList(req));
          setTimeout(() => {
            dispatch(loadCostSavings(req));
          }, 3000);
        }
      })
      .withSuccessAlert('Successfully saved HHC hcpc data.')
      .withFailureAlert('An error occurred while saving HCPC HHC data.')
      .build()
      .execute();
  };
};

const saveMSEHCPC = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveMSEHCPC(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveMSEHCPC(responseData));
      })
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveMSEHCPC(responseData));
        if (req) {
          dispatch(loadAudiologyHcpcsList(req));
          setTimeout(() => {
            dispatch(loadCostSavings(req));
          }, 3000);
        }
      })
      .withSuccessAlert('Successfully saved HCPC data.')
      .withFailureAlert('An error occurred while saving HCPC data.')
      .build()
      .execute();
  };
};

const saveDTLHCPC = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveDTLHCPC(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveDTLHCPC(responseData));
      })
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveDTLHCPC(responseData));
        if (req) {
          dispatch(loadAudiologyHcpcsList(req));
          setTimeout(() => {
            dispatch(loadCostSavings(req));
          }, 3000);
        }
      })
      .withSuccessAlert('Successfully saved DTL hcpc data.')
      .withFailureAlert('An error occurred while saving HCPC DTL data.')
      .build()
      .execute();
  };
};

const saveMODHCPC = (data, req) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveMODHCPC(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveMODHCPC(responseData));
      })
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveMODHCPC(responseData));
        if (req) {
          dispatch(loadAudiologyHcpcsList(req));
          setTimeout(() => {
            dispatch(loadCostSavings(req));
          }, 3000);
        }
      })
      .withSuccessAlert('Successfully saved MOD hcpc data.')
      .withFailureAlert('An error occurred while saving HCPC MOD data.')
      .build()
      .execute();
  };
};

const hcpcTermCheck = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.hcpcTermCheck(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateHcpcTermCheck(responseData));
      })
      .withFailureAlert(
        'An error occurred while checking HCPC code correct/not.'
      )
      .build()
      .execute();
  };
};

const getDetailLineFee = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getDetailLineFee(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetDetailLineFeeTable(responseData));
      })
      // .withFailureAlert('An error occurred while finding Detail Line Fee data.')
      .build()
      .execute();
  };
};

const clientFeeLookup = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.clientFeeLookup(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClientFeeLookupTable(responseData));
      })
      // .withFailureAlert('An error occurred while finding client Fee lookup data.')
      .build()
      .execute();
  };
};

const getVendorsList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorsList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateVendorLookupTable(responseData));
      })
      // .withFailureAlert('An error occurred while finding client Fee lookup data.')
      .build()
      .execute();
  };
};

const getReferrerInfo = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getReferrerInfo(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchReferrerTable(responseData));
      })
      .withFailureAlert('An error occurred while finding referrer data.')
      .build()
      .execute();
  };
};

const getClaimantList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClaimantList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchClaimant(responseData));
      })
      .withFailureAlert('An error occurred while finding claimant data.')
      .build()
      .execute();
  };
};

const getLoadClaimantById = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadClaimantById(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadClaimantById(responseData));
      })
      .withFailureAlert('An error occurred while finding claimant data.')
      .build()
      .execute();
  };
};

const getLoadClaim = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadClaim(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadClaim(responseData));
      })
      .withFailureAlert('An error occurred while finding claim data.')
      .build()
      .execute();
  };
};

const getDxCodeDesc = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getDxCodeDesc(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadDX(responseData));
      })
      .withFailureAlert('An error occurred while finding DX data.')
      .build()
      .execute();
  };
};

const deleteICD = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteICD(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDeleteIcd(responseData));
      })
      .withFailureAlert('An error occurred while deleting icd data.')
      .build()
      .execute();
  };
};

const deleteInjury = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteInjury(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDeleteInjury(responseData));
      })
      .withFailureAlert('An error occurred while deleting injury data.')
      .build()
      .execute();
  };
};

const deleteHCPCService = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteHCPCService(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveMSEHCPC(responseData));
        let req = {
          zebraUserId: data.userId,
          referral_id: data.referralId,
          mainMod: data.mainMod
        };
        if (req) {
          dispatch(loadFromWorkList(req));
        }
      })
      .withSuccessAlert('Successfully deleted service detail.')
      .withFailureAlert('An error occurred while deleting service detail.')
      .build()
      .execute();
  };
};

const saveClaim = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveClaim(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveClaim(responseData));
      })
      .withSuccessAlert('Successfully saved a Claim')
      .withFailureAlert('An error occurred while saving Claim info.')
      .build()
      .execute();
  };
};

/*
const loadReferral = (data) => {
  const { zebraUserId, referral_id } = data;
  let postPayload = {
    functionName: 'loadReferral',
    zebraUserId,
    referral_id,
  };
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadFromWorkList(postPayload))
      .build()
      .execute();
  };
};*/

const saveReferral = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveReferral(data))
      .withResponseHandler(responseData => {
        let loadWorkData = {
          functionName: 'loadReferral',
          zebraUserId: data.currentUserId,
          referral_id: responseData.id
        };
        dispatch(actions.updateSaveReferral(responseData));
        dispatch(actions.resetTRNInfoTable({}));
        dispatch(actions.resetSPTInfoTable({}));
        if (data.referralId > 0) {
          dispatch(loadFromWorkList(loadWorkData));
        }
      })
      .withSuccessAlert('Successfully saved a Referral')
      .withFailureAlert('An error occurred while saving Referral info.')
      .build()
      .execute();
  };
};

const getAdjusterList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getAdjusterList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchAdjusterTable(responseData));
      })
      .withFailureAlert('An error occurred while finding adjuster data.')
      .build()
      .execute();
  };
};

const getLoadAdjuster = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadAdjuster(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadAdjuster(responseData));
      })
      .withFailureAlert('An error occurred while finding adjuster data.')
      .build()
      .execute();
  };
};

const saveAdjuster = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAdjuster(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateAdjusterInfo(responseData));
      })
      .withSuccessAlert('Successfully saved a Adjuster')
      .withFailureAlert('An error occurred while saving Adjuster info.')
      .build()
      .execute();
  };
};

const getEmployerList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getEmployerList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchEmployerTable(responseData));
      })
      .withFailureAlert('An error occurred while finding Employer data.')
      .build()
      .execute();
  };
};

const getLoadEmployer = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadEmployer(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadEmployer(responseData));
      })
      .withFailureAlert('An error occurred while finding Employer data.')
      .build()
      .execute();
  };
};

const saveEmployer = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveEmployer(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateEmployerInfo(responseData));
      })
      .withSuccessAlert('Successfully saved a Employer')
      .withFailureAlert('An error occurred while saving Employer info.')
      .build()
      .execute();
  };
};

const getPhysicianList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPhysicianList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchPhysicianTable(responseData));
      })
      .withFailureAlert('An error occurred while finding Physician data.')
      .build()
      .execute();
  };
};

const getLoadPhysician = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadPhysician(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadPhysician(responseData));
      })
      .withFailureAlert('An error occurred while finding Physician data.')
      .build()
      .execute();
  };
};

const savePhysician = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.savePhysician(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePhysicianInfo(responseData));
      })
      .withSuccessAlert('Successfully saved a Physician')
      .withFailureAlert('An error occurred while saving Physician info.')
      .build()
      .execute();
  };
};

const getAttorneyList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getAttorneyList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchAttorneyTable(responseData));
      })
      .withFailureAlert('An error occurred while finding Attorney data.')
      .build()
      .execute();
  };
};

const getLoadAttorney = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadAttorney(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadAttorney(responseData));
      })
      .withFailureAlert('An error occurred while finding Attorney data.')
      .build()
      .execute();
  };
};

const saveAttorney = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAttorney(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateAttorneyInfo(responseData));
      })
      .withSuccessAlert('Successfully saved a Attorney')
      .withFailureAlert('An error occurred while saving Attorney info.')
      .build()
      .execute();
  };
};

const getCaseManagerList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getCaseManagerList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchCaseManagerTable(responseData));
      })
      .withFailureAlert('An error occurred while finding Case Manager data.')
      .build()
      .execute();
  };
};

const getLoadCaseManager = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadCaseManager(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadCaseManager(responseData));
      })
      .withFailureAlert('An error occurred while finding Case Manager data.')
      .build()
      .execute();
  };
};

const getAdvSearchData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getAdvSearchData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateAdvSearch(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const getLoadHCPCQuick = (query, userId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadHCPCQuick(query, userId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadHCPCQuick(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const getClientLookup = (query, userId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClientLookup(query, userId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateClientLookup(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const loadAudiologyHcpcsList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadAudiologyHcpcsList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateAudiologyHcpcsList(responseData));
        if (data.loadCostSavings != null && data.loadCostSavings) {
          dispatch(loadCostSavings(data));
        }
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const loadCostSavings = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadCostSavings(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateCostSavings(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const getLookUpSpLocation = (query, userId, fieldName) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLookUpSpLocation(query, userId, fieldName))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLookUpSpLocation(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const getLegStatus = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLegStatus(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLegStatus(responseData));
      })
      .withFailureAlert('An error occurred while finding Advance Search data.')
      .build()
      .execute();
  };
};

const saveCaseManager = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveCaseManager(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateCaseManagerInfo(responseData));
      })
      .withSuccessAlert('Successfully saved a CaseManager')
      .withFailureAlert('An error occurred while saving Case Manager info.')
      .build()
      .execute();
  };
};

const getUsersWorkList = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateUsersWorkList(responseData));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const getMyTotOrders = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {

        dispatch(actions.updateUsersTotOrders(responseData));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const getUsersOOC = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateUsersOOC(responseData));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const getMyRush = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateUsersRush(responseData, currentUserId));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const getMyNewOrders = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateNewOrders(responseData));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const getMyFollowups = (data, currentUserId) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getUsersWorkList(data, currentUserId))
      .withResponseHandler(responseData => {
        dispatch(actions.updateFollowups(responseData));
      })
      .withFailureAlert('An error occurred while finding users data.')
      .build()
      .execute();
  };
};
const postTransfer = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postTransfer(data))
      .withResponseHandler(responseData => {
        dispatch(actions.referralTransfered())
        dispatch(actions.updateUsersWorkList(responseData));

      })
      .withSuccessAlert('Referrals Transferred')
      .withFailureAlert('An error occurred while Transferred .')
      .build()
      .execute();
  };
};

const getClientInfo = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClientInfo(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSearchClientTable(responseData));
      })
      .withFailureAlert('An error occurred while finding referrer data.')
      .build()
      .execute();
  };
};

const loadClientWithFees = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadClientWithFees(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadClientData(responseData));
      })
      .withFailureAlert('An error occurred while finding payor data.')
      .build()
      .execute();
  };
};

const getLoadReferrer = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadReferrer(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLoadReferrer(responseData));
      })
      .withFailureAlert('An error occurred while loading referrer info.')
      .build()
      .execute();
  };
};

const getZipList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getZipList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateZipList(responseData));
      })
      .withFailureAlert('An error occurred while loading zip list info.')
      .build()
      .execute();
  };
};


const getLocationList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLocationList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateZipList(responseData));
      })
      .withFailureAlert('An error occurred while loading zip list info.')
      .build()
      .execute();
  };
};

const saveReferrer = (data, savedId, saveType) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveReferrer(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateReferrerInfo(responseData));
        dispatch(actions.updateSelectItemRefTable(saveType));
        dispatch(actions.updateDataFromReferrer(savedId));
      })
      .withSuccessAlert('Successfully saved a Referrer')
      .withFailureAlert('An error occurred while saving Referrer info.')
      .build()
      .execute();
  };
};
const getNotesData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getNotesData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetNotesData(responseData));
      })
      .withFailureAlert('An error occurred while loading notes')
      .build()
      .execute();
  };
};

const loadEligibility = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadEligibility(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateloadEligibility(responseData));
      })
      .withFailureAlert('An error occurred while loading eligibility')
      .build()
      .execute();
  };
};

const getLoadNotes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLoadNotes(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetLoadNotes(responseData));
      })
      .withFailureAlert('An error occurred while loading notes')
      .build()
      .execute();
  };
};

const getFollowupData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getFollowupData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetFollowupData(responseData));
      })
      .withFailureAlert('An error occurred while loading followupData')
      .build()
      .execute();
  };
};
const saveNotesData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveNotesData(data))
      .withResponseHandler(responseData => { })
      .withFailureAlert('An error occurred while saving Notes')
      .build()
      .execute();
  };
};


const progressTasks = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.progressTasks(data))
      .withResponseHandler(responseData => { })
      .withFailureAlert('An error occurred while saving Notes')
      .build()
      .execute();
  };
};
const saveAPApproval = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAPApproval(data))
      .withResponseHandler(responseData => { })
      .withSuccessAlert('All items have been approved for submission')
      .withFailureAlert('An error occurred while saving approval')
      .build()
      .execute();
  };
};
//Save Object
const saveObj = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveObj(data))
      .withResponseHandler(responseData => { })
      .withSuccessAlert('Data Saved Successfully')
      .withFailureAlert('An error occurred while saving')
      .build()
      .execute();
  };
};
const saveFollowUp = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveFollowUp(data))
      .withResponseHandler(responseData => { })
      .withFailureAlert('An error occurred while saving Notes')
      .build()
      .execute();
  };
};
const getCancelReasons = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getCancelReasons(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetCancelReasons(responseData));
      })
      .withFailureAlert('An error occurred while loading Cancel Reasons')
      .build()
      .execute();
  };
};
const postCancelService = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postCancelService(data))
      .withResponseHandler(responseData => {
        // dispatch(actions.updatePostCancelService(responseData));
      })
      .withFailureAlert('An error occurred while canceling the order')
      .build()
      .execute();
  };
};
const postOutcomes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postOutcomes(data))
      .withResponseHandler(responseData => {
        // dispatch(actions.updatePostOutcomes(responseData));
      })
      .withFailureAlert('An error occurred while saving the outcome')
      .build()
      .execute();
  };
};

const getEmailAddress = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getEmailAddress(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetEmailAddress(responseData));
      })
      .withFailureAlert('An error occurred while loading Email')
      .build()
      .execute();
  };
};
const getAPExtract = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getAPExtract(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetAPExtract(responseData));
      })
      .withFailureAlert('An error occurred while loading AP Extract')
      .build()
      .execute();
  };
};

const getExternalReferral = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getExternalReferral(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetExternalReferral(responseData));
      })
      .withFailureAlert('An error occurred while loading External Referral')
      .build()
      .execute();
  };
};

const postExternal = (data, mod) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postExternal(data))
      .withResponseHandler(responseData => {
        let res = responseData;
        res[0].svc = mod;
        dispatch(actions.updatePostExternal(res));
      })
      .withFailureAlert('An error occurred while loading External Referral')
      .build()
      .execute();
  };
};
const postExternalClient = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postExternalClient(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePostExternalClient(responseData));
      })
      .withFailureAlert('An error occurred while loading External Rederral')
      .build()
      .execute();
  };
};

const getGpData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getGpData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGpData(responseData));
      })
      .withFailureAlert('An error occurred while loading Great Plains data')
      .build()
      .execute();
  };
};

const getGpInfo = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getGpInfo(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGpInfo(responseData));
      })
      .withFailureAlert('An error occurred while loading Integration File Archive')
      .build()
      .execute();
  };
};

const clearGpData = () => {
  return dispatch => dispatch(actions.updateGpData({}));
};

const loadFromWorkList = (data, serviceData) => {
  const { mainMod, zebraUserId, referral_id } = data;
  let postPayload = {
    functionName: 'loadReferral',
    zebraUserId,
    referral_id,
  };
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadFromWorkList(postPayload))
      .withResponseHandler(responseData => {
        if (serviceData) {
          const {
            audiologyList,
            hhcList,
            accommodationList,
            dentalList,
            translationList,
            diagnosticList,
            mseList,
            transportationList,
            modificationList,
            specialTransportationList,
          } = responseData;
          let finalArr = [
            ...audiologyList,
            ...hhcList,
            ...accommodationList,
            ...dentalList,
            ...translationList,
            ...diagnosticList,
            ...mseList,
            ...transportationList,
            ...modificationList,
            ...specialTransportationList];
          const selected = finalArr.filter(i => i.id == serviceData.id);
          dispatch(actions.requiredServiceData(selected[0]));
        }
        dispatch(actions.updateLoadFromWorkList({ ...responseData, mainMod }));

      })
      .withFailureAlert('An error occurred while loading worklist')
      .build()
      .execute();
  };
};

const loadUpdatedVendor = (postPayload, serviceData, workListData) => {

  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadUpdatedVendor(postPayload))
      .withResponseHandler(responseData => {
        let selectedWorkListItem = {};
        if (responseData) {
          const listType = {
            "AUD": "audiologyList",
            "HHC": "hhcList",
            "ACC": "accommodationList",
            "DTL": "dentalList",
            "INT": "translationList",
            "DGN": "diagnosticList",
            "MSE": "mseList",
            "TRN": "transportationList",
            "MOD": "modificationList",
            "SPT": "specialTransportationList",
          };
          let keyName = listType[serviceData.module];
          let workListArr = workListData[keyName];
          workListArr = workListArr.map(workListItem => {
            if (workListItem.id === serviceData.serviceId) {
              workListItem.vendor = responseData;
              selectedWorkListItem = workListItem;
            }
            return workListItem;
          })
          workListData[keyName] = workListArr;
          serviceData.vendor = { ...responseData };
          dispatch(actions.setVendorData({ selectedSubService: serviceData, flag: false }));
          dispatch(actions.updateLoadFromWorkList(workListData));
          dispatch(actions.requiredServiceData({ ...selectedWorkListItem, module: serviceData.module, ...serviceData }));
          // onClose();
        }

      })
      .withFailureAlert('An error occurred while loading updated vendor data')
      .build()
      .execute();
  };
};

const saveFileUpload = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveFileUpload(data))
      .withResponseHandler(responseData => {
        if (responseData == 'The file has been uploaded successfully') {
          dispatch(actions.updateSaveFileUpload(responseData));
        } else {
          dispatch(commonActions.addAlert(new Date().getTime(),'Unable to upload file' , 'danger'));
        }
      })
      .withFailureAlert('An error occurred while saving files')
      .build()
      .execute();
  };
};

const getDocTypes = zebraUserId => {
  let postPayload = {
    functionName: 'loadDocTypes',
    zebraUserId,
  };
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getDocTypes(postPayload))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetDocTypes(responseData));
      })
      .withFailureAlert('An error occurred while loading Doctypes')
      .build()
      .execute();
  };
};
const fetchImageData = (data, fromExternal, currentUserId, handleModalChange, dispatch) => {
  let filesData = [];
  // let dataUpdated = false;
  data.map(async (dataObj, index) => {
    let reqData = {
      "functionName": "loadCCDocumentData",
      "zebraUserId": currentUserId,
      "fileName": dataObj.path.split("/")[1],
      "description": dataObj.description,
      "path": dataObj.path,
      "isExtFile": fromExternal,
    };
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.retriveDocument(reqData))
      .withResponseHandler(responseData => {
        let bstr = atob(responseData.fileData);
        let bstrlen = bstr.length;
        let u8arr = new Uint8Array(bstrlen);
        let fileType = reqData.fileName.split(".").pop();
        while(bstrlen--) {
          u8arr[bstrlen] = bstr.charCodeAt(bstrlen);
        }
        filesData.push({...reqData, fileData: new File([u8arr],reqData.fileName, {type: fileType})})
        if(filesData.length === data.length) {
          dispatch(actions.updateUploadedFiles(filesData));
          handleModalChange(data);
        }
      })
      .withFailureAlert('An error occurred while retrieving the Document'+reqData.fileName)
      .build()
      .execute();
  })
}

const getCcDocument = (data, handleModalChange, fromExternal) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getCcDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetCcDocument(responseData));
        if(responseData && responseData.length > 0 && handleModalChange) {
          
          dispatch(getDocTypes(data.zebraUserId));
          fetchImageData(responseData, fromExternal, data.zebraUserId, handleModalChange, dispatch);
        }
      })
      .withFailureAlert('An error occurred while loading list of Documents')
      .build()
      .execute();
  };
};

const deleteDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDeleteDocument(responseData));
      })
      .withFailureAlert('An error occurred while deleting the Documents')
      .build()
      .execute();
  };
};

const retriveDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.retriveDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateRetriveDocument(responseData));
      })
      .withFailureAlert('An error occurred while retrieving the Documents')
      .build()
      .execute();
  };
};

const getClaimAuth = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getClaimAuth(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetClaimAuth(responseData));
      })
      .withFailureAlert('An error occurred while retrieving Auth History')
      .build()
      .execute();
  };
};

const saveAuthHistory = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAuthHistory(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveAuthHistory(responseData));
      })
      .withFailureAlert('An error occurred while saving Auth History')
      .build()
      .execute();
  };
}

const saveAuthService = (data, obj) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAuthService(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveAuthService(responseData));
        dispatch(actions.updateClearAuthService(obj));
      })
      .withFailureAlert('An error occurred while saving Auth History')
      .build()
      .execute();
  };
}

const getAuthService = (data, obj) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getAuthService(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateGetAuthService(responseData));
      })
      .withFailureAlert('An error occurred while loading Auth History')
      .build()
      .execute();
  };
}

const clearAuthService = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.clearAuthService(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateSaveAuthService({}));
        dispatch(actions.updateClearAuthService({}));
      })
      .withFailureAlert('An error occurred while saving Auth History')
      .build()
      .execute();
  };
}


const loadExistingTasks = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadExistingTasks(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateloadExistingTasks(responseData));
      })
      .withFailureAlert('An error occurred while loading existing task data')
      .build()
      .execute();
  };
};

const getCurrentTaskId = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getCurrentTaskId(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateloadCurrentTaskId(responseData));
      })
      .withFailureAlert('An error occurred while loading existing task data')
      .build()
      .execute();
  };
};
const logBreadCrumbs = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.logBreadCrumbs(data))
      .build()
      .execute();
  };
};

const refreshClientFees = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.refreshClientFees(data))
      .build()
      .execute();
  };
};

const refreshVendorFees = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.refreshVendorFees(data))
      .build()
      .execute();
  };
};

const loadReferralAlerts = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadReferralAlerts(data))
      .withResponseHandler(responseData => {
        let sortedData = responseData;
        if(responseData.length > 0){
          sortedData = sortedData.sort((a,b) => (a.alertName > b.alertName ? 1 : -1));
        }
        dispatch(actions.updateloadReferralAlerts(sortedData));
      })
      .withFailureAlert('An error occurred while loading referral alerts')
      .build()
      .execute();
  };
};
const postrequiredFileds = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.postrequiredFileds(data))
      .withResponseHandler(responseData => {
        if (responseData.length > 0) {
          dispatch(actions.updateRequiredFields(responseData));
        } else if (responseData.length === 0) {
          let fakeResponse = ['close'];
          dispatch(actions.updateRequiredFields(fakeResponse));
          let unassignPayload = {
            functionName: 'unassignService',
            zebraUserId: data.zebraUserId,
            referralId: data.referralId,
            tableAbbrv: getModFromTable(data.tablename),
            serviceId: data.serviceId
          };
          dispatch(saveObj(unassignPayload));
          let progressTaskPayload = {
            functionName: 'progressTask2',
            zebraUserId: data.zebraUserId,
            referralId: data.referralId,
            tableAbbrv: getModFromTable(data.tablename),
            serviceId: data.serviceId
          };

          dispatch(saveObj(progressTaskPayload));
          let taskPayload = {
            functionName: 'loadExistingTasks',
            zebraUserId: data.zebraUserId,
            referralId: data.referralId,
            tableAbbrv: getModFromTable(data.tablename),
            serviceId: data.serviceId
          };
          setTimeout(() => {
            dispatch(loadExistingTasks(taskPayload));
          }, 1000);


          let currentTaskPayload = {
            functionName: 'getCurrentTaskId',
            zebraUserId: data.zebraUserId,
            referralId: data.referralId,
            tableAbbrv: getModFromTable(data.tablename),
            serviceId: data.serviceId
          };
          setTimeout(() => {
            dispatch(getCurrentTaskId(currentTaskPayload));
          }, 1500);


        }
      })
      .withFailureAlert('An error occurred while saving data')
      .build()
      .execute();
  };
};

const callDynamicLoad = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.callDynamicLoad(data))
      .withResponseHandler(responseData => {
        if (data.loadCurrentTaskId){
          if (responseData.currentTaskId != undefined){
             dispatch(actions.updateloadCurrentTaskId(responseData.currentTaskId));
          }else{
            dispatch(actions.updateloadCurrentTaskId(1));
          }
        }else{
          if (data.currentTaskId === 0){
            dispatch(actions.updateloadCurrentTaskId(1));
          }
        }
        if (data.loadGroupTaskProgression){
          if (responseData.groupProgressionData != undefined){
             dispatch(actions.updateloadGroupProgression(responseData.groupProgressionData));
          }
        }
        if (data.loadExistingTasks){
          if (responseData.existingTaskData != undefined){
            dispatch(actions.updateloadExistingTasks(responseData.existingTaskData));
         }
        }
        if (data.loadReferralAlerts){
          let sortedData = responseData.updateloadReferralAlerts || [];
          if(sortedData.length > 0){
            sortedData = sortedData.sort((a,b) => (a.alertName > b.alertName ? 1 : -1));
          }
          dispatch(actions.updateloadReferralAlerts(sortedData));
        }
        if (data.loadFollowUpdInfo){
          if (responseData.followUpInfo != undefined){
             dispatch(actions.updateGetFollowupData(responseData.followUpInfo));
          }
        }
      })
      .build()
      .execute();
  };
};
const groupTasksProgression = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.groupTasksProgression(data))
      .withResponseHandler(responseData => {
       dispatch(actions.updateloadGroupProgression(responseData));
      })
      .build()
      .execute();
  };
};

const groupTasksProgression2 = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.groupTasksProgression2(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateloadGroupProgression(responseData));
      })
      .build()
      .execute();
  };
};

const getWorklistEmailReportTemplates = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getWorklistEmailReportTemplates(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateWorklistEmailReportTemplates(responseData));
      })
      .build()
      .execute();
  };
};

const loadVendorLookupInitialData = () => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorLookupInitialData())
      .withResponseHandler(responseData => {
        dispatch(actions.updateVendorLookup(responseData));
      })
      .withFailureAlert('An error occurred while loading services')
      .build()
      .execute();
  };
};

const resetFilterData = data => {
  return dispatch => {
    dispatch(actions.selectedSubProduct(''));
    dispatch(actions.selectedProduct(''));
    dispatch(actions.updateClickRecord());
    dispatch(actions.setSelectedType(''));
    dispatch(actions.setSelectedFilterType(''));
    dispatch(actions.updateStatusClicked(false));
    dispatch(actions.updateMilestoneClicked(false));
    dispatch(actions.updateTaskClicked(false));
    dispatch(actions.productBackUp([]));
    dispatch(actions.taskBackUp([]));
    dispatch(actions.mileStoneBackUp([]));
    dispatch(actions.statusBackUp([]));
    dispatch(actions.updateIsLogout(true));
  }
}

const saveTransportLeg = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveTransportLeg(data.inputData))
      .withResponseHandler(responseData => {
        const lastSavedLeg = responseData[responseData.length - 1];
        if (!data.inputData.transportLeg[0]?.id) {
          dispatch(actions.updateTRNInfoTable([{ ...lastSavedLeg, transportationId: data.inputData.serviceId, serviceId: data.inputData.serviceId, tempId: data.tempId }]));
        }
      })
      .withFailureAlert('An error occurred while saving transport legacy')
      .build()
      .execute();
  };
};

const saveSPTLeg = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveSPTLeg(data.inputData))
      .withResponseHandler(responseData => {
        const lastSavedLeg = responseData[responseData.length - 1];
        if (!data.inputData.specialtransportLeg?.id) {
          let newDetailObj = { ...lastSavedLeg, specTransportationId: data.inputData.serviceId, serviceId: data.inputData.serviceId };
          if (!lastSavedLeg.pickupAddress.zipCode) {
            newDetailObj.pickupAddress = { ...data.inputData.specialtransportLeg.pickupAddress };
          }
          if (!lastSavedLeg.dropOffAddress.zipCode) {
            newDetailObj.dropOffAddress = { ...data.inputData.specialtransportLeg.dropOffAddress };
          }
          dispatch(actions.updateSPTInfoTable([newDetailObj]));
        }
      })
      .withFailureAlert('An error occurred while saving special transport legacy')
      .build()
      .execute();
  };
};
const resetVendorLookup = () => {
  return dispatch => dispatch(actions.resetVendorLookup());
};

const loadVendorLookupSubServices = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorLookupSubServices(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateVendorLookup(responseData));
      })
      .withFailureAlert('An error occurred while loading subservices')
      .build()
      .execute();
  };
};

const clearVendorHistorySuggestedVendors = activeTab => {
  return dispatch => {
    switch (activeTab) {
      case '1':
        dispatch(actions.vendorLookupSuggestions([]));
        break;
      case '2':
        dispatch(actions.vendorLookupTop5ByLocation([]));
        break;
      case '3':
        dispatch(actions.vendorLookupTop5ByCount([]));
        break;
    }
  };
};

const getQuickSearchList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getQuickSearchList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateQuickSearchList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading Quick search data')
      .build()
      .execute();
  };
};

const quickSearchCheck = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoad(data))
      .withResponseHandler(responseData => {
        dispatch(actions.quickSearchCheck(responseData));
      })
      .build()
      .execute();
  };
};

const getAPInfo = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoad(data))
      .withResponseHandler(responseData => {
        dispatch(actions.getAPInfo(responseData));
      })
      .build()
      .execute();
  };
};

const saveAPinfo = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveAPinfo(data))
      .withResponseHandler(responseData => { })
      .withSuccessAlert('AP data saved succssfully')
      .withFailureAlert('An error occurred while saving AP Info')
      .build()
      .execute();
  };
};

const sendFinalizeAssignmentEmail = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.sendFinalizeAssignmentEmail(data))
      .withResponseHandler(responseData => { })
      // .withSuccessAlert('Finalize Assingment E-Mail Sent')
      .withFailureAlert('An error occurred while sending E-Mail')
      .build()
      .execute();
  };
}

const getFinalizeAssignmentEmailBody = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getFinalizeAssignmentEmailBody(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveFinalizeAssignmentEmailData(responseData));

      })
      .build()
      .execute();
  };
}

const fetchConfirmWithContactList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.fetchConfirmWithContactList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveConfirmWithContactList(responseData));

      })
      .build()
      .execute();
  };
}

const getLMSDocumentsEmail = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getLMSDocumentsEmail(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveLMSDocumentsEmail(responseData));

      })
      .build()
      .execute();
  };
}

const clearLSMDOcumentsEmail = () => {
  return dispatch => {
    dispatch(actions.saveLMSDocumentsEmail([]));
  }
}

const clearAttachmentValidationResult = () => {
  return dispatch => {
    dispatch(actions.clearAttachmentValidationResult())
  }
}

const sendDetailEmail = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.sendDetailEmail(data))
      .withResponseHandler(responseData => {

        if (responseData && Object.keys(responseData).length === 0 && responseData.constructor === Object) {
          dispatch(actions.detailEmailSent(false))
        } else {
          dispatch(actions.detailEmailSent(true))
        }


      })
      .build()
      .execute();
  };
}

const getReportTemplatesBody = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getReportTemplatesBody(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveReportTemplatesBody(responseData));

      })
      .build()
      .execute();
  };
}

const clearReportTemplatesBody = () => {
  return dispatch => {
    dispatch(actions.saveReportTemplatesBody({}));
  }
}

const getValidAttachment = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getValidAttachment(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveAttachmentValidationResult(responseData));

      })
      .build()
      .execute();
  };
}

const resetDetailEmailSent = () => {
  return dispatch => {
    dispatch(actions.resetDetailEmailSent());
  }
}

const saveOverrideEligibility = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveOverrideEligibility(data))
      .withResponseHandler(responseData => {
        //dispatch(actions.saveAttachmentValidationResult({ path: data.path, result: responseData }));

      })
      .build()
      .execute();
  };
}

const checkEligibility = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.checkEligibility(data))
      .withResponseHandler(responseData => {
        dispatch(actions.setEligibility(responseData));

      })
      .build()
      .execute();
  };
}

const clearEligibility = () => {
  return dispatch => {
    dispatch(actions.clearEligibility())
  }
}

const loadByServiceReferral = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadByServiceReferral(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadByServiceReferral(responseData));

      })
      .build()
      .execute();
  };
}

const clearReferralTransfered = () => {
  return dispatch => {
    dispatch(actions.clearReferralTransfered())
  }
}
const saveGreatPlains = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveGreatPlains(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveGreatPlains(responseData));
      })
      .build()
      .execute();
  };
}
const clearGreatPlains = () => {
  return dispatch => {
    dispatch(actions.clearGreatPlains())
  }
}

const setLastServiceModule = (data) => {
  return dispatch => {
    dispatch(actions.setLastServiceModule(data))
  }
}

const setLastSubServiceModule = (data) => {
  return dispatch => {
    dispatch(actions.setLastSubServiceModule(data));
  }
}

const getDetailProvider = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericLoad(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDetailProvider(responseData));
      })
      .build()
      .execute();
  };
}

const saveDetailProvider = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.genericSave(data))
      .withResponseHandler(responseData => {
        const hcpcParams = {
          zebraUserId: data.zebraUserId,
          audiology_id: data.serviceId,
          tableAbbrv: data.serviceType,
          transport_type: 0,
          loadCostSavings: true
        }
        dispatch(loadAudiologyHcpcsList(hcpcParams));
      })
      .withSuccessAlert('Successfully saved Provider Details.')
      .withFailureAlert('An error occurred while saving provider details.')
      .build()
      .execute();
  };
};

const loadLineItemsList = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadLineItemsList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLineItemsList(responseData));
      })
      .build()
      .execute();
  };
}

const loadLineItemDetails = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadLineItemDetails(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateLineItemDetails(responseData));
      })
      .build()
      .execute();
  };
}

const loadPayableLines = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadPayableLines(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updatePayableLines(responseData));
      })
      .build()
      .execute();
  };
}

const savePayableLines = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.savePayableLines(data))
      .withResponseHandler(responseData => {

      })
      .withSuccessAlert('Successfully saved Payable Lines.')
      .withFailureAlert('An error occurred while saving Payable Lines.')
      .build()
      .execute();
  };
};

const loadInvoiceTaskData = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadInvoiceTaskData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateInvoiceTaskData(responseData));
      })
      .build()
      .execute();
  };
}
/*
const deletePayableLines =  (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deletePayableLines(data))
      .withResponseHandler(responseData => {
        const req = {
          functionName:"getPayableLines",
          zebraUserId: data.zebraUserId,
          referralId:  data.referralId,
          serviceId: data.serviceId,
          referenceTable: data.referenceTable
        }
        dispatch(loadPayableLines(req));
      })
      .withSuccessAlert('Successfully deleted Payable Lines.')
      .withFailureAlert('An error occurred while deleting Payable Lines.')
      .build()
      .execute();
  };
}

const loadApprovalDenialReasons = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadApprovalDenialReasons(data))
      .withResponseHandler(responseData => {
        dispatch(actions.setApprovalDenialReasons(responseData));
      })
      .build()
      .execute();
  };
}*/

const deletePayableLines = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deletePayableLines(data))
      .withResponseHandler(responseData => {
        const req = {
          functionName: "getPayableLines",
          zebraUserId: data.zebraUserId,
          referralId: data.referralId,
          serviceId: data.serviceId,
          referenceTable: data.referenceTable
        }
        dispatch(loadPayableLines(req));
      })
      .withSuccessAlert('Successfully deleted Payable Lines.')
      .withFailureAlert('An error occurred while deleting Payable Lines.')
      .build()
      .execute();
  };
}

const loadApprovalDenialReasons = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadApprovalDenialReasons(data))
      .withResponseHandler(responseData => {
        dispatch(actions.setApprovalDenialReasons(responseData));
      })
      .build()
      .execute();
  };
}

const loadVMProviderTypes = () => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVMProviderTypes())
      .withResponseHandler(responseData => {
        dispatch(actions.setVMProviderTypes(responseData));
      })
      .build()
      .execute();
  };
}

const loadVMGroups = () => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVMGroups())
      .withResponseHandler(responseData => {
        dispatch(actions.setVMGroups(responseData));
      })
      .build()
      .execute();
  };
}

const saveProvider = (data, resetFormData, isEdit) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveProvider(data))
      .withResponseHandler((responseData) => {
        if(responseData && responseData.id && !isEdit) {
          return resetFormData()
        }
      })
      .withSuccessAlert('Successfully saved Provider.')
      //.withFailureAlert(failureMessage)
      .withFailureHandler(responseData => {
        if (responseData !== 'true') {
          var failureMessage =  responseData?.response?.data?.Description || 'An error occurred while saving Provider.';
          dispatch(commonActions.addAlert(new Date().getTime(), failureMessage, 'danger'));
        }
      })
      .build()
      .execute();
  };
};

const searchProvider = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.searchProvider(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateVMResults(responseData));
      })
      .build()
      .execute();
  };
};

const loadSkipPayableLines = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadSkipPayableLines(data))
      .withResponseHandler(responseData => {
        let skipPayableLines = true;
        if(responseData ) {
          skipPayableLines = !!responseData['skipPayableLines']; 
        }
        dispatch(actions.setSkipPayableLines(skipPayableLines));
      })
      .build()
      .execute();
  };
}

const updateSedgwickAttachments = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.updateSedgwickAttachments(data))
      .withFailureAlert('An error occurred while saving Auth History')
      .build()
      .execute();
  };
}
const loadNppesPhysicianList = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadNppesPhysicianList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.setNppesPhysicianList(responseData));
      })
      .build()
      .execute();
  };
}

const saveNppesPhysician = (data) => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveNppesPhysician(data))
      .withResponseHandler(responseData => {

      })
      .withSuccessAlert('Successfully saved Physician.')
      .withFailureAlert('An error occurred while saving Physician.')
      .build()
      .execute();
  };
};

export default {
  loadInitialData,
  getDefaultCostSavingsPercent,
  saveFees,
  saveCosts,
  getServiceIdFromReference,
  loadProductSearchList,
  productSubmit,
  loadHCPC,
  lookupHcpcWithLimit,
  hcpcTermCheck,
  saveHHCHCPC,
  saveMSEHCPC,
  saveDTLHCPC,
  saveMODHCPC,
  getDetailLineFee,
  clientFeeLookup,
  getVendorsList,
  getReferrerInfo,
  getClaimantList,
  getLoadClaimantById,
  getLoadClaim,
  getDxCodeDesc,
  deleteICD,
  deleteInjury,
  deleteHCPCService,
  saveClaim,
  saveReferral,
  getAdjusterList,
  getLoadAdjuster,
  saveAdjuster,
  getEmployerList,
  getLoadEmployer,
  saveEmployer,
  getPhysicianList,
  getLoadPhysician,
  savePhysician,
  getAttorneyList,
  getLoadAttorney,
  saveAttorney,
  getCaseManagerList,
  getLoadCaseManager,
  saveCaseManager,
  getLoadReferrer,
  loadClientWithFees,
  getZipList,
  getLocationList,
  saveReferrer,
  getUsersWorkList,
  getClientInfo,
  postTransfer,
  getNotesData,
  loadEligibility,
  getLoadNotes,
  getFollowupData,
  saveNotesData,
  saveFollowUp,
  getAdvSearchData,
  getLoadHCPCQuick,
  getClientLookup,
  getLookUpSpLocation,
  getLegStatus,
  getCancelReasons,
  postCancelService,
  postOutcomes,
  progressTasks,
  saveAPApproval,
  getEmailAddress,
  getExternalReferral,
  getAPExtract,
  loadLookupReport,
  postExternal,
  postExternalClient,
  getGpData,
  clearGpData,
  loadFromWorkList,
  loadUpdatedVendor,
  loadExistingTasks,
  getCurrentTaskId,
  logBreadCrumbs,
  loadReferralAlerts,
  groupTasksProgression,
  callDynamicLoad,
  getWorklistEmailReportTemplates,
  loadVendorLookupInitialData,
  resetVendorLookup,
  loadVendorLookupSubServices,
  clearVendorHistorySuggestedVendors,
  getQuickSearchList,
  refreshClientFees,
  refreshVendorFees,
  quickSearchCheck,
  saveTransportLeg,
  saveSPTLeg,
  getAPInfo,
  saveAPinfo,
  vendorLookupTop5ByLocation,
  vendorLookupTop5ByCount,
  saveObj,
  postrequiredFileds,
  vendorLookUpStoreProcedure,
  getEvaluateVendorData,
  getVendorHistoryData,
  vendorLookupSuggestions,
  getDocTypes,
  getCcDocument,
  saveFileUpload,
  vendorLookUpAssign,
  deleteDocument,
  retriveDocument,
  getClaimAuth,
  saveAuthHistory,
  getMyTotOrders,
  getUsersOOC,
  getMyRush,
  getMyNewOrders,
  getMyFollowups,
  saveAuthService,
  clearAuthService,
  getAuthService,
  loadAudiologyHcpcsList,
  loadCostSavings,
  sendFinalizeAssignmentEmail,
  getFinalizeAssignmentEmailBody,
  fetchConfirmWithContactList,
  getLMSDocumentsEmail,
  sendDetailEmail,
  getReportTemplatesBody,
  clearLSMDOcumentsEmail,
  clearReportTemplatesBody,
  getValidAttachment,
  clearReferralTransfered,
  resetDetailEmailSent,
  getGpInfo,
  saveOverrideEligibility,
  checkEligibility,
  clearEligibility,
  saveGreatPlains,
  clearGreatPlains,
  loadByServiceReferral,
  setLastServiceModule,
  clearAttachmentValidationResult,
  setLastSubServiceModule,
  getDetailProvider,
  saveDetailProvider,
  loadLineItemsList,
  loadLineItemDetails,
  loadPayableLines,
  savePayableLines,
  loadInvoiceTaskData,
  deletePayableLines,
  loadApprovalDenialReasons,
  resetFilterData,
  loadSkipPayableLines,
  loadNppesPhysicianList,
  saveNppesPhysician,
  loadVMProviderTypes,
  loadVMGroups,
  saveProvider,
  searchProvider,
  loadSkipPayableLines,
  updateSedgwickAttachments,
};
