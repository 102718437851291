import React, { useEffect } from 'react';

export const ThemeContext = React.createContext();

const Provider = ({ children }) => {
  const domain = window.location.hostname;

  let themeName = '';
  let themePath = '';

  if (domain === 'z3.sedgwick.com') {
    themeName = 'sedgwick';
    themePath = `${process.env.PUBLIC_URL}/themes/sedgwick`;
  } else if (domain === 'z3.careworks.com') {
    themeName = 'careworks';
    themePath = `${process.env.PUBLIC_URL}/themes/careworks`;
  } else if (domain === 's1z3.careworks.com' || domain === 'z3qa.careworks.com') {
    themeName = 's1';
    themePath = `${process.env.PUBLIC_URL}/themes/s1`;
  }

  if (themePath === '') {
    themePath = `${process.env.PUBLIC_URL}/themes/careworks`;
  }

  useEffect(() => {
    const favicon = document.getElementById('favicon');

    favicon.href = `${themePath}/favicon.png`;
  }, [themePath]);

  return (
    <ThemeContext.Provider value={{ themeName, themePath }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default Provider;
