import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Button, Row, Col, FormGroup, Input, Form } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';

import {
  EXPERT_REPORT_TYPE_ITEMS,
  getPreviousFiveYearsFromCurrentYear,
  EXPERT_SPECIALTY_BY_STATE_ID,
  NETWORK_DIRECTORY_REPORT_ID,
  RE_CREDENTIALING_REPORT_ID,
  SPECIALTIES_COUNT_ID,
  SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID,
  STATE_LICENSE_REPORT_ID,
  YTD_CREDENTIALING_BY_STATE_REPORT_ID,
  YTD_CREDENTIALING_REPORT_ID,
  allYearsValue,
  allYearsDisplay,
  extractIdFromElementId,
} from '../utils/expertUtils';

const Select = ({ value, handleChange, name, data, idKey, nameKey }) => {
  return (
    <Input type="select" name={name} value={value} onChange={handleChange}>
      {data.map((item, index) => (
        <option key={index} value={item[idKey]}>
          {item[nameKey]}
        </option>
      ))}
    </Input>
  );
};

class ExpertReportTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkIds: '',
      specialtyIds: '',
      stateIds: '',
      primaryStateIds: '',
      years: allYearsValue,
      yearsDisplay: allYearsDisplay,
      network: '',
      networkDisplay: '',
      specialty: '',
      specialtyDisplay: '',
      state: '',
      stateDisplay: '',
      primaryState: '',
      primaryStateDisplay: '',
    };
  }

  selectedText = id => {
    const selectedValues = this.state[`${id}Ids`]
      .toString()
      .split(',')
      .filter(item => item !== '');
    if (selectedValues.length < 1) {
      return 'Nothing selected';
    } else if (selectedValues.length === 1) {
      return 'Single Item Selected';
    } else {
      return 'Multiple Items Selected';
    }
  };

  getControlChooseANetwork = key => {
    return (
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="report-network"
            data={this.props.networks}
            value={this.state.networkDisplay}
            displayOption="element"
            returnValue="id"
            onChange={this.onDisplayValueChangeHandler}
            onSelect={this.onSelectHandler}
            clearInput={this.clearInput}
            showToolTip={true}
            placeholder="Choose A Network"
            tooltipText={
              <span>
                {this.selectedText('network')}
                <br />
                Choose A Network
              </span>
            }
            isSuggested={true}
            key={key}
          />
        </FormGroup>
      </Col>
    );
  };

  getControlStateLicensedIn = key => {
    return (
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="report-state"
            data={this.props.states}
            value={this.state.stateDisplay}
            displayOption="element"
            returnValue="id"
            onChange={this.onDisplayValueChangeHandler}
            onSelect={this.onSelectHandler}
            clearInput={this.clearInput}
            showToolTip={true}
            placeholder="State Licensed In"
            tooltipText={
              <span>
                {this.selectedText('state')}
                <br />
                State Licensed In
              </span>
            }
            isSuggested={true}
            key={key}
          />
        </FormGroup>
      </Col>
    );
  };

  getControlSpecialty = key => {
    const specialties = this.props.specialties
      .reduce((result, specialty) => {
        return R.find(item => item.name === specialty.name, result)
          ? result
          : [...result, specialty];
      }, [])
      .sort((item1, item2) => item1.name.localeCompare(item2.name));
    return (
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="report-specialty"
            data={specialties}
            value={this.state.specialtyDisplay}
            displayOption="name"
            returnValue="id"
            onChange={this.onDisplayValueChangeHandler}
            onSelect={this.onSelectHandler}
            clearInput={this.clearInput}
            showToolTip={true}
            placeholder="Specialty"
            tooltipText={
              <span>
                {this.selectedText('specialty')}
                <br />
                Specialty
              </span>
            }
            isSuggested={true}
            key={key}
          />
        </FormGroup>
      </Col>
    );
  };

  getControlStateOfPrimaryAddress = key => {
    return (
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="report-primaryState"
            data={this.props.states}
            value={this.state.primaryStateDisplay}
            displayOption="element"
            returnValue="id"
            onChange={this.onDisplayValueChangeHandler}
            onSelect={this.onSelectHandler}
            clearInput={this.clearInput}
            showToolTip={true}
            placeholder="State Of Primary Address"
            tooltipText={
              <span>
                {this.selectedText('primaryState')}
                <br />
                State Of Primary Address
              </span>
            }
            isSuggested={true}
            key={key}
          />
        </FormGroup>
      </Col>
    );
  };

  getControlYear = key => {
    return (
      <Col lg="2">
        <FormGroup>
          <AppAutocomplete
            id="report-years"
            data={getPreviousFiveYearsFromCurrentYear()}
            value={this.state.yearsDisplay}
            displayOption="name"
            returnValue="id"
            onChange={this.onDisplayValueChangeHandler}
            onSelect={this.onSelectHandler}
            clearInput={this.clearYearsInput}
            showToolTip={true}
            placeholder="Year"
            isSuggested={true}
            key={key}
          />
        </FormGroup>
      </Col>
    );
  };

  getControlsExpertSpecialtyByState = key => {
    return (
      <>
        {this.getControlChooseANetwork(key)}
        {this.getControlStateLicensedIn(key)}
      </>
    );
  };

  getControlsNetworkDirectoryReport = key => {
    return <>{this.getControlChooseANetwork(key)}</>;
  };

  getControlsReCredentialingReport = key => {
    return (
      <>
        {this.getControlChooseANetwork(key)}
        {this.getControlYear(key)}
      </>
    );
  };

  getControlsSpecialtiesCount = key => {
    return (
      <>
        {this.getControlChooseANetwork(key)}
        {this.getControlSpecialty(key)}
      </>
    );
  };

  getControlsStateLicenseReport = key => {
    return <>{this.getControlChooseANetwork(key)}</>;
  };

  getControlsYTDCredentialByStateReport = key => {
    return (
      <>
        {this.getControlChooseANetwork(key)}
        {this.getControlSpecialty(key)}
        {this.getControlStateLicensedIn(key)}
        {this.getControlYear(key)}
      </>
    );
  };

  getControlsSpecialtyCountByStateLicenseAndPrimary = key => {
    return (
      <>
        {this.getControlChooseANetwork(key)}
        {this.getControlSpecialty(key)}
        {this.getControlStateLicensedIn(key)}
        {this.getControlStateOfPrimaryAddress(key)}
      </>
    );
  };

  getControlsYTDCredentialingReport = key => {
    return <>{this.getControlsYTDCredentialByStateReport(key)}</>;
  };

  joinFilter(targetId, value) {
    const filterValue = this.state[targetId];
    if (value === '') {
      return '';
    }
    const splitFilterIds = filterValue
      .toString()
      .split(',')
      .filter(item => item !== '' && item !== value.toString());
    if (targetId === 'specialtyIds') {
      const specialty = R.find(
        item => item.id === value,
        this.props.specialties
      );
      if (specialty) {
        const allSpecialties = this.props.specialties.filter(
          item =>
            item.name === specialty.name &&
            !splitFilterIds.includes(item.id.toString())
        );
        const allSpecialtyIds = allSpecialties.reduce((result, item) => {
          return [...result, item.id.toString()];
        }, splitFilterIds);
        return allSpecialtyIds.join(',');
      }
    }
    return [...splitFilterIds, value].join(',');
  }

  onDisplayValueChangeHandler = (targetId, value) => {
    const id = targetId.replace('report-', '');
    this.setState({
      [`${id}Display`]: value,
    });
  };

  onSelectHandler = (targetId, displayValue, value) => {
    const joinFilterSelect = [
      'networkIds',
      'specialtyIds',
      'stateIds',
      'primaryStateIds',
    ];
    const id = extractIdFromElementId(targetId, 'report-');
    this.setState({
      [`${id}Display`]: displayValue,
      [id]: value,
      [`${id}Ids`]: joinFilterSelect.includes(`${id}Ids`)
        ? this.joinFilter(`${id}Ids`, value)
        : value,
    });
  };

  clearInput = targetId => {
    const id = targetId.replace('report-', '');
    const joinFilterSelect = [
      'networkIds',
      'specialtyIds',
      'stateIds',
      'primaryStateIds',
    ];
    this.setState({
      [`${id}Ids`]: joinFilterSelect.includes(`${id}Ids`)
        ? this.joinFilter(`${id}Ids`, '')
        : '',
    });
  };

  clearYearsInput = _id => {
    this.setState({
      years: allYearsValue,
      yearsDisplay: allYearsDisplay,
    });
  };

  runReport = () => {
    const {
      networkIds,
      stateIds,
      primaryStateIds,
      years,
      specialtyIds,
    } = this.state;
    this.props.runExpertReport({
      reportId: this.props.reportId,
      networkIds: networkIds !== '' ? networkIds : '-1',
      specialtyId: specialtyIds !== '' ? specialtyIds : '-1',
      stateIds: stateIds !== '' ? stateIds : '-1',
      primarystateIds: primaryStateIds !== '' ? primaryStateIds : '-1',
      years: years !== '' ? years : '-1',
    });
  };

  exportToExcel = () => {
    // TODO
  };

  exportToPdf = () => {
    // TODO
  };

  onReportTypeChange = event => {
    this.setState({
      networkIds: '',
      specialtyIds: '',
      stateIds: '',
      primaryStateIds: '',
      years: allYearsValue,
      yearsDisplay: allYearsDisplay,
      network: '',
      networkDisplay: '',
      specialty: '',
      specialtyDisplay: '',
      state: '',
      stateDisplay: '',
      primaryState: '',
      primaryStateDisplay: '',
    });
    this.props.onReportTypeChange(event.target.value);
  };

  getControlsView() {
    switch (this.props.reportId) {
      case EXPERT_SPECIALTY_BY_STATE_ID:
        return this.getControlsExpertSpecialtyByState(
          EXPERT_SPECIALTY_BY_STATE_ID
        );
      case NETWORK_DIRECTORY_REPORT_ID:
        return this.getControlsNetworkDirectoryReport(
          NETWORK_DIRECTORY_REPORT_ID
        );
      case RE_CREDENTIALING_REPORT_ID:
        return this.getControlsReCredentialingReport(
          RE_CREDENTIALING_REPORT_ID
        );
      case SPECIALTIES_COUNT_ID:
        return this.getControlsSpecialtiesCount(SPECIALTIES_COUNT_ID);
      case SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID:
        return this.getControlsSpecialtyCountByStateLicenseAndPrimary(
          SPECIALLY_COUNT_BY_STATE_LICENSE_AND_PRIMARY_ID
        );
      case STATE_LICENSE_REPORT_ID:
        return this.getControlsStateLicenseReport(STATE_LICENSE_REPORT_ID);
      case YTD_CREDENTIALING_BY_STATE_REPORT_ID:
        return this.getControlsYTDCredentialByStateReport(
          YTD_CREDENTIALING_BY_STATE_REPORT_ID
        );
      case YTD_CREDENTIALING_REPORT_ID:
      default:
        return this.getControlsYTDCredentialingReport(
          YTD_CREDENTIALING_REPORT_ID
        );
    }
  }

  render() {
    return (
      <>
        <Form>
          <Row>
            <Col lg="2">
              <FormGroup>
                <Select
                  value={this.props.reportId}
                  name="reportType"
                  idKey="id"
                  nameKey="name"
                  handleChange={this.onReportTypeChange}
                  data={EXPERT_REPORT_TYPE_ITEMS}
                />
              </FormGroup>
            </Col>
            <Col lg="10">
              <Row>
                {this.getControlsView()}
                <Col lg="2">
                  <FormGroup>
                    <Button
                      color={'danger'}
                      style={{ width: '100%' }}
                      type="button"
                      onClick={this.runReport}
                    >
                      Run Report
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Button
                      style={{ width: '100%' }}
                      type="button"
                      onClick={this.exportToExcel}
                    >
                      Export To Excel File
                    </Button>
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Button
                      style={{ width: '100%' }}
                      type="button"
                      onClick={this.exportToPdf}
                    >
                      Export To Pdf
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

ExpertReportTop.propTypes = {
  states: PropTypes.array.isRequired,
  networks: PropTypes.array.isRequired,
  specialties: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
  onReportTypeChange: PropTypes.func.isRequired,
  runExpertReport: PropTypes.func.isRequired,
};

export default ExpertReportTop;
