import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { Col, Row, Form, Button, CustomInput, Label } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';

import PropTypes from 'prop-types';
import AppTable from 'shared/components/table/AppTable';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { fast360Operations } from '../store';
import { authSelectors } from 'modules/auth/store';

import "react-datepicker/dist/react-datepicker.css";
let currPageBillsSelected = [];
class Fast360WCEDI extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,            
            selectedRows: [],
            extractDate: "",
            formattedExtract:"",

        }
        this.toggle = this.toggle.bind(this);
        this.saveAPApproval = this.saveAPApproval.bind(this);

    }
    toggle = () => {
        this.props.openFast360WCEDI()
    };
  
    saveAPApproval = () =>{
      const { currentUserId } = this.props;
      let saveAPApprovalArgs = {
        "functionName": "ExtractWCEDI",
        "zebraUserId": currentUserId,
        "extractDate": this.state.formattedExtract,
        "module":  this.state.module ?  this.state.module : 'ACC'
      }
      
      this.props.saveObj(saveAPApprovalArgs);
      this.toggle();
    };

   onChangeHandler = object => {
     let extractDate; 
     let formatExtract; 
      
        let monDOS = object.value.getMonth() + 1;
        extractDate =
          monDOS +
          '/' + object.value.getDate() +
          '/' +
          object.value.getFullYear();
        
          formatExtract = object.value.getFullYear() + '-' +
          monDOS + '-' + object.value.getDate() ;
          
          
  
      this.setState({
        extractDate: extractDate,
        formattedExtract: formatExtract
      });
    };
    onChangeHandlerMod = event => {
      this.setState({
         module: event.target.value,
       });

     };
    componentDidMount() {
        const { currentUserId } = this.props;
        //const user = this.props.users && this.props.users.find(u => u.zebraId == currentUserId);

        
    }
    selectItem = (item, event) => {
      const result = event.target.checked
          ? this.state.selectedRows.concat(item)
          : this.state.selectedRows.filter(
            row => row.payableId !== item.payableId
          );
      this.setState({
        selectedRows: result,
      });
     
    };

    isChecked = () => {

      if (this.state.selectedRows.length == this.props.apData.length) {
        return true;
      }
      
      return false;
    };
   selectAll = event => {
      if (this.state.selectedRows.length === this.props.apData.length) {
        this.setState({
          selectedRows: [],
        });
        
      }else{
        this.setState({
          selectedRows: this.props.apData,
        });
      }
      
         
    };
    renderSelectActionHeader() {
      return (
        <div className="select-action">
          <CustomInput
            id={`billSearchResult-selectAll`}
            name={`billSearchResult-selectAll`}
            type="checkbox"
            checked={this.isChecked() == true ? true : false}
            onChange={this.selectAll}
          />
        </div>
      );
    }
   getSelectActionColumn = (item) => {
      if (!!!item) {
        return;
      }
      return (
        <div className="select-action">
          <CustomInput
            id={`billSearchResult-select-${item.payableId}`}
            name={`billSearchResult-select-${item.payableId}`}
            type="checkbox"
            checked={this.state.selectedRows.includes(item)}
            onChange={event => {
              this.selectItem(item, event);
            }}
          />
        </div>
      );
    }
  
    
render() {
    return (
        <>
            <Modal size="sm" isOpen={true} modalClassName="fee-lookup-modal-body">
                <ModalHeader onClick={this.toggle} >WCEDI</ModalHeader>
                <ModalBody>
                  <Form>
                    <Row>
                   
                    <Col xl="6">
                      <Label>Extract Date: </Label>
                      <AppDateInput
                        id="dos"
                        onChange={value => this.onChangeHandler({ key: 'dos', value })}
                        value={this.state.extractDate || ''}
                      />
                    </Col >
                    <Col xl="5">
                      <Label>Module: </Label>
                      <Input
                        id="appt"
                        type="select"
                        onChange={this.onChangeHandlerMod}
                        value={this.state.module}
                        placeholder="Choose..."
                       
                        //data={appointmentType}
                      >
                        <option value="ACC">ACC</option>
                        <option value="AUD">AUD</option>
                        <option value="DGN">DGN</option>
                        <option value="DTL">DTL</option>
                        <option value="HHC">HHC</option>
                        <option value="INT">INT</option>
                        <option value="MSE">MSE</option>
                        <option value="MOD">MOD</option>
                        <option value="TRN">TRN</option>
                        <option value="SPT">SPT</option>
                      </Input>
                    </Col >

                    </Row>
                     <Row>
                     <Col xl="3" className="text-right" style={{ paddingTop: '8px' }}>  </Col>
                      <Col sm="5"  style={{ paddingTop: '8px' }}>
                        <Button  style={{ paddingTop: '8px' }}  onClick={this.saveAPApproval}>Run Extract</Button>
                      </Col>
                      <Col sm="4"  style={{ paddingTop: '8px' }}>
                        <Button  style={{ paddingTop: '8px' }}  onClick={this.toggle}>Cancel</Button>
                      </Col>
                     </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
  }
};

Fast360WCEDI.propTypes = {
    saveObj: PropTypes.func,
    alert: PropTypes.func,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    userId: PropTypes.string,
    zebraUserId: PropTypes.string
};

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  
    return {
      currentUserId: userId,
      apData: state.FAST360.fast360Reducer.updateGetAPExtract ? state.FAST360.fast360Reducer.updateGetAPExtract : [],
    };

};


const mapDispatchToProps = dispatch => ({
    saveObj: (data) => dispatch(fast360Operations.saveObj(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Fast360WCEDI);

