import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { map, isEmpty } from 'lodash';
import NewClaimantModal from './NewClaimantModal';
import NewReferrerModal from './NewReferrerModal';
import NewAdjusterModal from './NewAdjusterModal';
import NewEmployerModal from './NewEmployerModal';
import NewPhysicianModal from './NewPhysicianModal';
import NewAttorneyModal from './NewAttorneyModal';
import NewCaseManagerModal from './NewCaseManagerModal';
import PayorLookup from './PayorLookup';
import Fast360SideNav from './Fast360SideNav';
import { connect } from 'react-redux';
import { fast360Selectors, fast360Operations } from '../store/index';
import {
  formatPhoneNumber,
  formatSSN,
} from 'modules/experts/utils/expertUtils';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { ClickAwayListener } from '@material-ui/core';

import Fast360FileModal from 'modules/fast-360/components/Fast360FileModal';


const Fast360EditHeader = ({
  updatedRefInfo,
  updatedEmployerInfo,
  updatedPhysicianInfo,
  updatedClaimInfo,
  updatedPayorInfo,
  updatedAdjusterInfo,
  updatedCaseManagerInfo,
  updatedAttorneyInfo,
  fromExternal,
  updatePostExternalClient,
  fromWorkList,
  loadClaimantByIdInfo,
  isFromLanding,
  setAdvValues,
  selectedExternalData,
}) => {
  const [isOpenNewClaimantModal, setIsOpenNewClaimantModal] = useState(false);
  const [isOpenRefModal, setIsOpenRefModal] = useState(false);
  const [isOpenAdjusterModal, setIsOpenAdjusterModal] = useState(false);
  const [isOpenCaseManagerModal, setIsOpenCaseManagerModal] = useState(false);
  const [isOpenEmployerModal, setIsOpenEmployerModal] = useState(false);
  const [isOpenPhysicianModal, setIsOpenPhysicianModal] = useState(false);
  const [isOpenAttorneyModal, setIsOpenAttorneyModal] = useState(false);
  const [isOpenNewPayorModal, setIsOpenNewPayorModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [patientData, setPatientData] = useState(undefined);
  const [refData, setRefData] = useState(undefined);
  const [adjData, setAdjData] = useState(undefined);
  const [caseManData, setCaseManData] = useState(undefined);
  const [phyData, setPhyData] = useState(undefined);
  const [attData, setAttData] = useState(undefined);
  const [empData, setEmpData] = useState(undefined);
  const [populatedRef, setPopulatedRef] = useState(false);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);


  const openNewClaimantModal = () => {

    setIsOpenNewClaimantModal(!isOpenNewClaimantModal);
  };
  const openNewReferrerModal = () => {
    setIsOpenRefModal(!isOpenRefModal);
  };
  const openNewAdjusterModal = () => {

    setIsOpenAdjusterModal(!isOpenAdjusterModal);
  };
  const openNewCaseManagerModal = () => {

    setIsOpenCaseManagerModal(!isOpenCaseManagerModal);
  };
  const openNewPayorModal = () => {

    setIsOpenNewPayorModal(!isOpenNewPayorModal);
  };
  const onViewHandler = () => {
    setIsViewMode(!isViewMode);
  };
  const openNewEmployerModal = () => {

    setIsOpenEmployerModal(!isOpenEmployerModal);
  };
  const openNewPhysicianModal = () => {

    setIsOpenPhysicianModal(!isOpenPhysicianModal);
  };
  const openNewAttorneyModal = () => {

    setIsOpenAttorneyModal(!isOpenAttorneyModal);
  };
  const getDiagnosisList = () => {
    if (!fromExternal.fromWorklist) {
      let diagnosis = [];
      if (updatedClaimInfo && updatedClaimInfo.injuryList) {
        updatedClaimInfo.injuryList.forEach(i => {
          diagnosis = diagnosis.concat(map(i.diagnosisList, 'chr_icdfull'));
        });
      }
      return diagnosis.join(',');
    } else if(fromExternal.fromWorklist && fromWorkList.claim && 
      updatedClaimInfo && updatedClaimInfo.injuryList && updatedClaimInfo.injuryList.length &&
       updatedClaimInfo.injuryList.length > 0) {
      let value = '', icdArr = [];
      updatedClaimInfo.injuryList.forEach(i => {
        icdArr = icdArr.concat(map(i.diagnosisList, 'chr_icdfull'));
      });
      value = icdArr.join(', ')
      return value;
    } else {
      let value = '', icdArr = [];
      if (fromWorkList.claim && fromWorkList.claim.injuryList &&
        fromWorkList.claim.injuryList.length &&
        fromWorkList.claim.injuryList.length > 0) {
        // fromWorkList.claim.injuryList.map(item=>{
        // item.diagnosisList.length>0 && item.diagnosisList[0].chr_icdfull && icdArr.push(item.diagnosisList[0].chr_icdfull)
        // })
        fromWorkList.claim.injuryList.forEach(i => {
          icdArr = icdArr.concat(map(i.diagnosisList, 'chr_icdfull'));
        });
      } else if(fromWorkList.claim && updatedClaimInfo && updatedClaimInfo.injuryList &&
        updatedClaimInfo.injuryList.length && updatedClaimInfo.injuryList.length > 0) {
        updatedClaimInfo.injuryList.forEach(i => {
          icdArr = icdArr.concat(map(i.diagnosisList, 'chr_icdfull'));
        });
      }
      value = icdArr.join(', ')
      return value;
    }
  };

  const renderPayorName = (name) => {
    let payorName = ''
    if (name.length > 0 && name[0].chr_companyname) {
      if (name[0].chr_companyname == '') {
        payorName = 'York Risk Services Group'
      }
      else
        payorName = name[0].chr_companyname
    }
    else {
      if (name.length != 0 || fromExternal.fromExternal.fromExternal)
        payorName = 'York Risk Services Group'
    }
    if (!fromExternal.fromExternal.fromExternal) {
      payorName = ''
    }
    return payorName;
  }
  const renderPayorDisplayName = (name) => {
    let payorName = '';
    if (name.length > 0 && name[0].chr_companyname) {
      if (name[0].chr_companyname == '') {
        payorName = 'York Risk Services Group'
      }
      else
        payorName = name[0].chr_companyname
    }
    else {
      if (name.length != 0 || fromExternal.fromExternal.fromExternal)
        payorName = 'York Risk Services Group'
    }
    if (fromExternal.fromExternal.isPayorEdited) {
      payorName = ''
    }
    return payorName;
  }

  const renderPayorFromExternal = (data, param) => {
    let value = ''
    if (!data.fromExternal.isPayorEdited && data.externalReferralData.length > 0) {
      if (!updatedClaimInfo) {
        value = data.externalReferralData[0][param];
      }
    }
    return value;
  }
  const renderClientIdFromExternal = (data, fromExternal, param) => {
    let value = ''
    if (fromExternal?.fromExternal?.fromExternal && !fromExternal?.fromExternal?.isPayorEdited && data?.length > 0) {
        value = data[0][param] ? `${data[0][param]}` : '';
    }
    return value;
  }
  const renderPayorAddress = (data, fromExternal, param) => {
    let value = ''
    if (fromExternal?.fromExternal?.fromExternal && !fromExternal?.fromExternal?.isPayorEdited && data?.length > 0) {
      value = data[0][param] ? `${data[0][param]}, ` : '';
    }
    return value;
  }
  const renderPatientFromExternal = (data, param) => {
    let value = ''
    if (data.fromExternal.fromExternal && !data.fromExternal.isPatientEdited && data.externalReferralData.length > 0) {
      value = data.externalReferralData[0][param];
    }
    return value;
  }

  const renderPatientFromWorklist = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList?.claim?.claimant) {
        value = fromWorkList?.claim?.claimant?.people[param];
      }
    }
    return value;

  }

  const renderPatientPhoneFromWorklist = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList?.claim?.claimant) {
        value = fromWorkList.claim.claimant &&
          fromWorkList.claim.claimant.people &&
          fromWorkList.claim.claimant.people[param] &&
          formatPhoneNumber(fromWorkList.claim.claimant.people[param])
      }
    }
    return value;
  }

  const renderPhysicianFromWorklist = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician && fromWorkList.physician.people) {
        value = fromWorkList.physician.people[param];
      }
    }
    return value;

  }
  const renderPatientAddress = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList?.claim?.claimant) {
        value = fromWorkList?.claim?.claimant?.address?.street1 + (fromWorkList?.claim?.claimant?.address?.street2 ? " " + fromWorkList.claim.claimant.address.street2 : "") ;
      }
    }
    return value;

  }
  const renderPatientFullAddress = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim.claimant) {
        value = fromWorkList.claim.claimant.address.zipCode[param];
      }
    }
    return value;

  }
  const renderPatientDoi = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim) {
        value = fromWorkList.claim.doi;
      }
    }
    return value;

  }
  const renderPatientHeight = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim) {
        value = fromWorkList.claim.claimant[param];
      }
    }
    return value;

  }

  const renderGender = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim) {
        if (fromWorkList.claim.claimant.people.genderId === 1) {
          value = 'Male';
        } else if (fromWorkList.claim.claimant.people.genderId === 2) {
          value = 'Female';
        } else {
          value = '';
        }

      }
    }
    return value;

  }
  const renderReferrerDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isReferrerEdited && fromWorkList && fromWorkList.referrer) {
      if (fromWorkList.referrer) {
        value = fromWorkList.referrer.people[param];
      }
    }
    return value;

  }

  const renderReferrerPhoneDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isReferrerEdited && fromWorkList && fromWorkList.referrer) {
      if (fromWorkList.referrer) {
        value = fromWorkList.referrer && fromWorkList.referrer.people && fromWorkList.referrer.people[param] &&
          formatPhoneNumber(fromWorkList.referrer.people[param]);
      }
    }
    return value;

  }

  const renderAdjusterDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isAdjusterEdited && fromWorkList && fromWorkList.adjuster) {
      if (fromWorkList.adjuster) {
        value = fromWorkList.adjuster.people[param];
      }
    }
    return value;

  }

  const renderAdjusterPhoneDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isAdjusterEdited && fromWorkList && fromWorkList.adjuster) {
      if (fromWorkList.adjuster) {
        value = fromWorkList.adjuster && fromWorkList.adjuster.people && fromWorkList.adjuster.people[param] &&
          formatPhoneNumber(fromWorkList.adjuster.people[param]);
      }
    }
    return value;

  }

  const renderCaseManagerDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isCaseManagerEdited && fromWorkList && fromWorkList.caseManager) {
      if (fromWorkList.caseManager) {
        value = fromWorkList.caseManager.people[param];
      }
    }
    return value;

  }

  const renderCaseManagerPhoneDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isCaseManagerEdited && fromWorkList && fromWorkList.caseManager) {
      if (fromWorkList.caseManager) {
        value = fromWorkList.caseManager && fromWorkList.caseManager.people && fromWorkList.caseManager.people[param] &&
          formatPhoneNumber(fromWorkList.caseManager.people[param]);
      }
    }
    return value;

  }

  const renderPayorNameDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList.client) {
      if (fromWorkList?.client) {
        value = fromWorkList?.client[param];
      }
    }
    return value;

  }
  const renderPayorAddressDetails = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList?.client) {
      if (fromWorkList?.client) {
        value = fromWorkList?.client?.address?.street1;
      }
    }
    return value;

  }
  const renderPayorAddressZip = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList?.client) {
      if (fromWorkList?.client) {
        value = fromWorkList?.client?.address?.zipCode[param];
      }
    }
    return value;

  }

  const renderPayorPhoneDetails = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList?.client) {
      if (fromWorkList?.client) {
        value = fromWorkList.client && fromWorkList.client[param] &&
          formatPhoneNumber(fromWorkList.client[param]);
      }
    }
    return value;

  }

  const renderPhysicianName = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician && fromWorkList.physician.people) {
        value = fromWorkList.physician.people[param];
      }
    }
    return value;

  }
  const renderPhysicianId = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician) {
        value = fromWorkList.physician[param];
      }
    }
    return value;
  }

  const renderPhysicianAddress = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician && fromWorkList?.physician?.address) {
        value = fromWorkList.physician.address[param];
      }
    }
    return value;
  }

  const renderPhysicianAddressZip = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician && fromWorkList?.physician?.address) {
        value = fromWorkList?.physician?.address?.zipCode[param];
      }
    }
    return value;
  }

  const renderPhysicianPhoneFromWorklist = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPhyEdited && fromWorkList && fromWorkList.physician) {
      if (fromWorkList.physician && fromWorkList?.physician?.people) {
        value = fromWorkList.physician && fromWorkList.physician.people && fromWorkList.physician.people[param] &&
          formatPhoneNumber(fromWorkList.physician.people[param]);
      }
    }
    return value;
  }

  const renderAttorneyName = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isEttorneyEdited && fromWorkList && fromWorkList.claim?.attorney) {
      if (fromWorkList.claim?.attorney && fromWorkList.claim?.attorney?.people) {
        value = fromWorkList.claim?.attorney?.people[param];
      }
    }
    return value;
  }

  const renderAttorneyPhone = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isEttorneyEdited && fromWorkList && fromWorkList.claim?.attorney) {
      if (fromWorkList.claim?.attorney && fromWorkList.claim?.attorney?.people) {
        value = fromWorkList.claim.attorney && fromWorkList.claim.attorney.people && fromWorkList.claim.attorney.people[param] &&
          formatPhoneNumber(fromWorkList.claim.attorney.people[param]);
      }
    }
    return value;
  }

  const renderEmployerName = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isEmployerEdited && fromWorkList && fromWorkList.claim?.employer) {
      if (fromWorkList.claim?.employer && fromWorkList.claim?.employer?.people) {
        if (param === 'name') {
          value = fromWorkList.claim?.employer[param];
        } else {
          value = fromWorkList.claim?.employer?.people[param];
        }
      }
    }
    return value;
  }

  const renderEmployerPhone = (param) => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isEmployerEdited && fromWorkList && fromWorkList.claim?.employer) {
      if (fromWorkList.claim?.employer && fromWorkList.claim?.employer?.people) {
        value = fromWorkList.claim.employer && fromWorkList.claim.employer.people && fromWorkList.claim.employer.people[param] &&
          formatPhoneNumber(fromWorkList.claim.employer.people[param]);
      }
    }
    return value;
  }

  const renderClaimNumber = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList.claim) {
      if (updatedClaimInfo) {
        value = updatedClaimInfo.claimNo;
      } else if (fromWorkList.claim) {
        value = fromWorkList.claim.claimNo;
      }
    } else if (fromExternal.isNewReferral) {
      if (updatedClaimInfo) {
        value = updatedClaimInfo.claimNo;
      }

    } else if (fromExternal.fromExternal.fromExternal) {
      if (updatedClaimInfo) {
        value = updatedClaimInfo.claimNo;
      }

    }
    return value;
  }

  const renderProcessingOffice = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim) {
        value = fromWorkList.claim.procOffice;
      }
    }
    return value;
  }

  const renderClientAccount = () => {
    let value = '';
    if (fromExternal.fromWorklist && !fromExternal.externalData.isPayorEdited && fromWorkList && fromWorkList.claim) {
      if (fromWorkList.claim) {
        value = fromWorkList.claim.clientAccount;
      }
    }
    return value;
  }


  const renderRefFromExternal = (data, param) => {
    let value = ''
    if (data.fromExternal.fromExternal && !data.fromExternal.isReferrerEdited && data.externalReferralData.length > 0) {
      value = data.externalReferralData[0][param];
    }
    return value;
  }
  const renderAdjFromExternal = (data, param) => {
    let value = ''
    if (data.fromExternal.fromExternal && !data.fromExternal.isAdjusterEdited && data.externalReferralData.length > 0) {
      value = data.externalReferralData[0][param];
    }
    return value;
  }
  const renderCaseManagerFromExternal = (data, param) => {
    let value = ''
    if (data.fromExternal.fromExternal && !data.fromExternal.isCaseManagerEdited && data.externalReferralData.length > 0) {
      value = data.externalReferralData[0][param];
    }
    return value;
  }
  const renderEmployer = (data, param) => {
    let value = ''
    if (data.fromExternal.fromExternal && !data.fromExternal.isEmployerEdited && data.externalReferralData.length > 0) {
      value = data.externalReferralData[0][param];
    }
    return value;
  }

  const getSplitName = (name) => {
    let fname = '', lname = '';
    if (name.includes(',')) {
      let managerNameArr = name.split(',');
      fname = managerNameArr[managerNameArr.length - 1];
      lname = managerNameArr[0];
    }
    else {
      let managerNameArr = name.split(' ');
      fname = managerNameArr[0];
      lname = managerNameArr[managerNameArr.length - 1];
    }
    return { fname, lname };
  }

  const isSSNAvailable = () =>
  (fromExternal.fromWorklist && !fromExternal.externalData.isPatientEdited &&
    fromWorkList && fromWorkList.claim && fromWorkList.claim.claimant &&
    fromWorkList.claim.claimant.people && fromWorkList.claim.claimant.people.ssn)

  const renderSSN = () => {
    let value = ''
    if (isSSNAvailable()) {
      value = fromWorkList.claim.claimant.people.ssn;
    }
    return value;
  }

  const getReffDetails = () => {
    if (!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people?.first && loadClaimantByIdInfo?.referrer?.people?.last) {
      const ref = loadClaimantByIdInfo?.referrer?.people;
      return {
        refFirstName: ref.first,
        refLastName: ref.last,
        refEmail: ref.email1,
        insComp: loadClaimantByIdInfo?.referrer?.insurer
      }
    }

    return {};

  }

  const getAdjDetails = () => {
    if (!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.people.first && loadClaimantByIdInfo?.adjuster?.people.last) {
      const adj = loadClaimantByIdInfo?.referrer?.people;
      return {
        adjFirstName: adj.first,
        adjLastName: adj.last,
        adjEmail: adj.email1,
        adjPhone: adj.phone
      }
    }
    return {};

  }

  const getClaimDetails = () => {
    if (!isFromLanding && isEmpty(updatedClaimInfo) && loadClaimantByIdInfo?.claim?.claimant?.people && (loadClaimantByIdInfo?.claim?.claimant?.people?.first || loadClaimantByIdInfo?.claim?.claimant?.people?.last)) {
      const claimant = loadClaimantByIdInfo?.claim?.claimant?.people;
      const address = loadClaimantByIdInfo?.claim?.claimant?.address;
      return {
        claimantFirstName: claimant.first,
        claimantLastName: claimant.last,
        claimantAddress: `${address.street1} ${address.zipCode.city} ${address.zipCode.state} ${address.zipCode.zip}`,
        claimantZip: address.zipCode.zip,
        claimNumber: loadClaimantByIdInfo?.claim?.claimNo,
        claimDOI: loadClaimantByIdInfo?.claim?.doi
      }
    }
    return {};
  }



  const setClaimData = () => {
    if (loadClaimantByIdInfo) {


      setPatientData((currentPatient) => {
        const patient = getClaimDetails();
        return {
          ...currentPatient,
          ...patient
        }
      })

      setRefData((currentRef) => {
        const ref = getReffDetails();
        return {
          ...currentRef,
          ...ref
        }

      });

      setAdjData((currentAdj) => {
        const adj = getAdjDetails();
        return {
          ...currentAdj,
          ...adj
        };
      })


    }

  }
  const serviceTypeMap = {
    acc: 'ACC',
    aud: 'AUD',
    dtl: 'DTL',
    dental: 'DTL',
    dgn: 'DGN',
    diagnostics: 'DGN',
    int: 'INT',
    interpretation: 'INT',
    trn: 'TRN',
    transportation: 'TRN',
    spt: 'SPT',
    special_transp: 'SPT',
    mse: 'MSE',
    hhc: 'HHC',
    mod: 'MOD',
    modification: 'MOD'
  }


  return (
    <>
      <Fast360SideNav open={openSlider} patientData={patientData} refData={refData} adjData={adjData} caseManData={caseManData}
        phyData={phyData} attData={attData} empData={empData} populatedRef={populatedRef} isFromLanding={isFromLanding} />
      {isFromLanding && <div style={{ marginTop: '10px', marginBottom: '20px' }}>
        <b> Confirmation Number:</b> {setAdvValues?.split('(')[0] || fromExternal?.currentWorkList?.confNo?.split('(')[0] || fromExternal?.currentWorkList?.ConfirmNumber?.split('(')[0] || (`${fromExternal?.data?.referralId}-${fromExternal?.data?.tableName?.toUpperCase()}-${fromExternal?.data?.serviceId}`)}
      </div>}
      <FormGroup background-color="#e6ebed">
        {(fromExternal.isNewReferral || (fromExternal.fromExternal.fromExternal && fromExternal.externalReferralData.length > 0)) && <Row>
          <ClickAwayListener onClickAway={() => { setOpenSlider(false) }}>
            <Button type="button" onClick={() => { setClaimData(); setOpenSlider(true) }} style={{ marginBottom: '20px' }}> Required Fields </Button>
          </ClickAwayListener>
          <Button type="button" onClick={() => {
            console.log(fromExternal);
            setIsOpenFileModal(true)
          }} style={{ marginBottom: '20px', float: "right" }}>Attachments</Button>
        </Row>}
        <Row>
          <Col>
            <Label>
              <b>Patient</b>{' '}
              <FontAwesomeIcon
                className="blue-text cursor-hover"
                icon={faEdit}
                onClick={openNewClaimantModal}
              />
              {fromExternal.fromExternal.fromExternal && !fromExternal.fromExternal.isPatientEdited &&
                <> <FontAwesomeIcon className="red-text" icon={faExclamationTriangle} data-for="tooltip"
                  data-tip={'Attention-Please Confirm'} /><ReactTooltip id="tooltip" /></>}
            </Label>
            <br></br>
            <Label>Name:  {renderPatientFromExternal(fromExternal, 'PatientFirstName')} {renderPatientFromExternal(fromExternal, 'PatientLastName')}</Label>
            &nbsp;
            {renderPatientFromWorklist('first')} {renderPatientFromWorklist('last')}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.first}{' '}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.last}
            <br></br>
            <Label>Address:
              {renderPatientFromExternal(fromExternal, 'PatientAddress')} {renderPatientFromExternal(fromExternal, 'PatientCity')}
              {renderPatientFromExternal(fromExternal, 'PatientState')} {renderPatientFromExternal(fromExternal, 'PatientZip')}
            </Label>
            {renderPatientAddress()} {renderPatientFullAddress('city')}
            <br />
            {renderPatientFullAddress('state')} {renderPatientFullAddress('zip')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.address.street1}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.address.street2}
            <br />
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.address.zipCode &&
              updatedClaimInfo.claimant.address.zipCode.city}{' '}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.address.zipCode &&
              updatedClaimInfo.claimant.address.zipCode.state}
            {''}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.address.zipCode &&
              updatedClaimInfo.claimant.address.zipCode.zip}
            <br></br>
            <Label>Phone:
              {renderPatientFromExternal(fromExternal, 'PatientPhone')}</Label>
            {renderPatientPhoneFromWorklist('phone')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.phone &&
              formatPhoneNumber(updatedClaimInfo.claimant.people.phone)}
            &nbsp;
            <Label>
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
                updatedClaimInfo.claimant &&
                updatedClaimInfo.claimant.okToContact ? (
                <div>
                  <FontAwesomeIcon className="green-text" icon={faCircle} />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon className="red-text" icon={faCircle} />
                </div>
              )}
            </Label>
            <br></br>
            <Label>Cell:</Label>
            {renderPatientPhoneFromWorklist('cellPhone')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.cellPhone &&
              formatPhoneNumber(updatedClaimInfo.claimant.people.cellPhone)}
            &nbsp;
            <Label>
              {updatedClaimInfo &&
                updatedClaimInfo.claimant &&
                updatedClaimInfo.claimant.okToText ? (
                <div>
                  <FontAwesomeIcon className="green-text" icon={faCircle} />
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon className="red-text" icon={faCircle} />
                </div>
              )}
            </Label>
            <br></br>
            <Label>
              <b>DOB:
                {renderPatientFromExternal(fromExternal, 'PatientDateOfBirth')}
              </b>
            </Label>
            {renderPatientFromWorklist('dob') && moment(renderPatientFromWorklist('dob')).format('MM/DD/YYYY')}


            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.dob}
            <br></br>
            <Label>
              <b>DOI:
                {renderPatientFromExternal(fromExternal, 'PatientDateOfInjury')}
              </b>
            </Label>
            {renderPatientDoi() && moment(renderPatientDoi()).format('MM/DD/YYYY')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo && updatedClaimInfo.doi}
            <br></br>
            <Label>
              <b>FT:
                {renderPatientFromExternal(fromExternal, 'PatientHeightFeet')}</b>
            </Label>
            {renderPatientHeight('heightFeet')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.heightFeet}
            <Label>
              &nbsp;<b>In:
                {renderPatientFromExternal(fromExternal, 'PatientHeightInches')}</b>
            </Label>
            {renderPatientHeight('heightInch')}

            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.heightInch}
            <Label>
              &nbsp;<b>Lbs:
                {renderPatientFromExternal(fromExternal, 'PatientWeight')}</b>
            </Label>
            {renderPatientHeight('weight')}
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.weight}
            &nbsp;
            {renderGender('')}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPatientEdited) && updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people.genderId === 1
              ? 'Male'
              : updatedClaimInfo &&
                updatedClaimInfo.claimant &&
                updatedClaimInfo.claimant.people.genderId === 2
                ? 'Female'
                : ''}
            <br></br>
            <Label>
              <b>SSN:</b>
            </Label>
            {isSSNAvailable() ? isViewMode ? formatSSN(renderSSN()) : '###-##-####' : ''}
            {isSSNAvailable() ? (
              <Button
                type="button"
                size="sm"
                style={{ marginLeft: '5px' }}
                onClick={onViewHandler}
              >
                {isViewMode ? 'HIDE' : 'VIEW'}
              </Button>
            ) : (
              ''
            )}
            &nbsp;
            {updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people &&
              updatedClaimInfo.claimant.people.ssn
              ? isViewMode ? formatSSN(updatedClaimInfo.claimant.people.ssn) : '###-##-####'
              : ''}
            {updatedClaimInfo &&
              updatedClaimInfo.claimant &&
              updatedClaimInfo.claimant.people &&
              updatedClaimInfo.claimant.people.ssn ? (
              <Button
                type="button"
                size="sm"
                style={{ marginLeft: '5px' }}
                onClick={onViewHandler}
              >
                {isViewMode ? 'HIDE' : 'VIEW'}
              </Button>
            ) : (
              ''
            )}
            <br></br>
            <Label>
              <b>Diagnosis:</b>
            </Label>
            {getDiagnosisList()}
            <br></br>
          </Col>
          <Col>
            <Label>
              <b>
                Referrer{' '}
                <FontAwesomeIcon
                  className="blue-text cursor-hover"
                  icon={faEdit}
                  onClick={openNewReferrerModal}
                />
                {fromExternal.fromExternal.fromExternal && !fromExternal.fromExternal.isReferrerEdited &&
                  <> <FontAwesomeIcon className="red-text" icon={faExclamationTriangle} data-for="tooltip"
                    data-tip={'Attention-Please Confirm'} /><ReactTooltip id="tooltip" /></>}
              </b>
            </Label>
            <br></br>
            <Label>Name: {renderRefFromExternal(fromExternal, 'RequesterName')}</Label>
            {renderReferrerDetails('first')} {renderReferrerDetails('last')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people.first}{' '}
              {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people.last}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isReferrerEdited) && updatedRefInfo.referrer &&
                updatedRefInfo.referrer.people.first}{' '}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isReferrerEdited) && updatedRefInfo.referrer && updatedRefInfo.referrer.people.last}
            </Label>
            <br></br>
            <Label>Phone: {renderRefFromExternal(fromExternal, 'RequesterPhone')}</Label>
            {renderReferrerPhoneDetails('phone')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people && loadClaimantByIdInfo.referrer?.people?.phone &&
                formatPhoneNumber(loadClaimantByIdInfo.referrer.people.phone)}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isReferrerEdited) && updatedRefInfo.referrer &&
                updatedRefInfo.referrer.people.phone &&
                formatPhoneNumber(updatedRefInfo.referrer.people.phone)}
            </Label>
            &nbsp;<Label>Ext: </Label>
            {renderReferrerDetails('phoneExt')}
            <Label>
              {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people?.phoneExt}
              {updatedRefInfo.referrer &&
                updatedRefInfo.referrer.people.phoneExt}
            </Label>
            <br></br>
            <Label>Email: {renderRefFromExternal(fromExternal, 'RequesterEmail')}</Label>
            {renderReferrerDetails('email1')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people?.email1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isReferrerEdited) && updatedRefInfo.referrer && updatedRefInfo.referrer.people.email1}
            </Label>
            <br></br>
            <Label>
              <b>Fax: </b>
              {renderReferrerPhoneDetails('fax')}
              <Label>
                &nbsp;
                {!isFromLanding && isEmpty(updatedRefInfo) && loadClaimantByIdInfo?.referrer?.people?.fax && formatPhoneNumber(loadClaimantByIdInfo.referrer.people.fax)}
                {updatedRefInfo.referrer &&
                  updatedRefInfo.referrer.people.fax &&
                  formatPhoneNumber(updatedRefInfo.referrer.people.fax)}
              </Label>
            </Label>
          </Col>
          <Col>
            <Label>
              <b>
                Adjuster{' '}
                <FontAwesomeIcon
                  className="blue-text cursor-hover"
                  icon={faEdit}
                  onClick={openNewAdjusterModal}
                />
                {fromExternal.fromExternal.fromExternal && !fromExternal.fromExternal.isAdjusterEdited &&
                  <> <FontAwesomeIcon className="red-text" icon={faExclamationTriangle} data-for="tooltip"
                    data-tip={'Attention-Please Confirm'} /><ReactTooltip id="tooltip" /></>}
              </b>
              <br></br>
              <Label>Name: {getSplitName(renderAdjFromExternal(fromExternal, 'AdjusterName')).fname}{' '}
                {getSplitName(renderAdjFromExternal(fromExternal, 'AdjusterName')).lname}</Label>
              {renderAdjusterDetails('first')} {renderAdjusterDetails('last')}
              &nbsp;
              {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.people.first}{' '}
              {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.people.last}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isAdjusterEdited) && updatedAdjusterInfo.adjuster &&
                updatedAdjusterInfo.adjuster.people.first}{' '}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isAdjusterEdited) && updatedAdjusterInfo.adjuster &&
                updatedAdjusterInfo.adjuster.people.last}
              <br></br>
              <Label>Phone: {renderAdjFromExternal(fromExternal, 'AdjusterPhone')}</Label>
              {renderAdjusterPhoneDetails('phone')}
              <Label>
                &nbsp;
                {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo && loadClaimantByIdInfo.adjuster &&
                  loadClaimantByIdInfo.adjuster.people.phone &&
                  formatPhoneNumber(loadClaimantByIdInfo.adjuster.people.phone)}
                {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isAdjusterEdited) && updatedAdjusterInfo.adjuster &&
                  updatedAdjusterInfo.adjuster.people.phone &&
                  formatPhoneNumber(updatedAdjusterInfo.adjuster.people.phone)}
              </Label>
              &nbsp;<Label>Ext: </Label>
              {renderAdjusterDetails('phoneExt')}
              <Label>
                {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.people?.phoneExt}
                {updatedAdjusterInfo.adjuster &&
                  updatedAdjusterInfo.adjuster.people.phoneExt}
              </Label>
              <br></br>
              <Label>Email: {renderAdjFromExternal(fromExternal, 'AdjusterEmail')}</Label>
              {renderAdjusterDetails('email1')}
              &nbsp;
              {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo?.adjuster?.people?.email1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isAdjusterEdited) && updatedAdjusterInfo.adjuster &&
                updatedAdjusterInfo.adjuster.people.email1}
              <br></br>
              <Label>
                <b>FAX </b>
              </Label>
              {renderAdjusterPhoneDetails('fax')}
              &nbsp;
              {!isFromLanding && isEmpty(updatedAdjusterInfo) && loadClaimantByIdInfo && loadClaimantByIdInfo.adjuster &&
                loadClaimantByIdInfo.adjuster.people.fax &&
                formatPhoneNumber(loadClaimantByIdInfo.adjuster.people.fax)}
              {updatedAdjusterInfo.adjuster &&
                updatedAdjusterInfo.adjuster.people.fax &&
                formatPhoneNumber(updatedAdjusterInfo.adjuster.people.fax)}
              <br></br>
              <Label>
                <b>Examiner Office #:</b>
              </Label>
              <br></br>
              <Label>
                <b>Sedgwick Client Acct #</b>
                {renderClientAccount()}
                &nbsp;
                {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) &&
                  updatedClaimInfo && updatedClaimInfo.clientAccount}
              </Label>
            </Label>
          </Col>
          <Col>
            <Label>
              <b>
                Nurse Case Manager{' '}
                <FontAwesomeIcon
                  className="blue-text cursor-hover"
                  icon={faEdit}
                  onClick={openNewCaseManagerModal}
                />
                 {fromExternal.fromExternal.fromExternal && !fromExternal.fromExternal.isCaseManagerEdited && (loadClaimantByIdInfo?.caseManager?.people?.last || (fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 &&
          fromExternal.externalReferralData[0].CaseManagerName))&&
                  <> <FontAwesomeIcon className="red-text" icon={faExclamationTriangle} data-for="tooltip"
                    data-tip={'Attention-Please Confirm'} /><ReactTooltip id="tooltip" /></>}
              </b>
            </Label>
            <br></br>
            <Label>Name: {renderCaseManagerFromExternal(fromExternal, 'CaseManagerName')} </Label>
            {renderCaseManagerDetails('first')} {renderCaseManagerDetails('last')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.first}{' '}
            {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.last}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isCaseManagerEdited) && updatedCaseManagerInfo.caseManager &&
              updatedCaseManagerInfo.caseManager.people.first}{' '}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isCaseManagerEdited) && updatedCaseManagerInfo.caseManager &&
              updatedCaseManagerInfo.caseManager.people.last}
            <br></br>
            <Label>Phone: {renderCaseManagerFromExternal(fromExternal, 'CaseManagerPhone')}</Label>
            {renderCaseManagerPhoneDetails('phone')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.phone &&
                formatPhoneNumber(
                  loadClaimantByIdInfo.caseManager.people.phone
                )}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isCaseManagerEdited) && updatedCaseManagerInfo.caseManager &&
                updatedCaseManagerInfo.caseManager.people.phone &&
                formatPhoneNumber(
                  updatedCaseManagerInfo.caseManager.people.phone
                )}
            </Label>
            &nbsp;<Label>Ext: </Label>
            {renderCaseManagerDetails('phoneExt')}
            <Label>
              {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.phoneExt}
              {updatedCaseManagerInfo.caseManager &&
                updatedCaseManagerInfo.caseManager.people.phoneExt}
            </Label>
            <br></br>
            <Label>Email: {renderCaseManagerFromExternal(fromExternal, 'CaseManagerEmail')}</Label>
            {renderCaseManagerDetails('email1')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.email1}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isCaseManagerEdited) && updatedCaseManagerInfo.caseManager &&
              updatedCaseManagerInfo.caseManager.people.email1}
            <br></br>
            <Label>
              <b>FAX</b>
              {renderCaseManagerPhoneDetails('fax')}
            </Label>
            &nbsp;
            {!isFromLanding && isEmpty(updatedCaseManagerInfo) && loadClaimantByIdInfo?.caseManager?.people?.fax &&
              formatPhoneNumber(
                loadClaimantByIdInfo.caseManager.people.fax
              )}
            {updatedCaseManagerInfo.caseManager &&
              updatedCaseManagerInfo.caseManager.people.fax &&
              formatPhoneNumber(updatedCaseManagerInfo.caseManager.people.fax)}
            <br></br>
          </Col>
          <Col>
            <Label>
              <b>
                Payor{' '}
                <FontAwesomeIcon
                  className="blue-text cursor-hover"
                  icon={faEdit}
                  onClick={openNewPayorModal}
                />
                {fromExternal.fromExternal.fromExternal && !fromExternal.fromExternal.isPayorEdited &&
                  <> <FontAwesomeIcon className="red-text" icon={faExclamationTriangle} data-for="tooltip"
                    data-tip={'Attention-Please Confirm'} /><ReactTooltip id="tooltip" /></>}
              </b>
            </Label>
            <br></br>

            <Label>Name: {fromExternal.fromExternal.fromExternal && renderPayorDisplayName(updatePostExternalClient)}</Label>
            {renderPayorNameDetails('name')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.name}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && (updatedPayorInfo ? updatedPayorInfo.name : '')}
            <br></br>
            <Label>Claim Number: {fromExternal.fromExternal.fromExternal && renderPayorFromExternal(fromExternal, 'ClaimNumber')}</Label>
            <span>{renderClaimNumber()?.split(' ').join('\u00A0')}</span>
            <br></br>
            <Label>Client ID: {renderClientIdFromExternal(updatePostExternalClient, fromExternal, 'PK_client_id')}</Label>
            {renderPayorNameDetails('id')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.id}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.id}
            <br></br>
            <Label>Processing Office:{renderProcessingOffice()}</Label>
            &nbsp;
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedClaimInfo && updatedClaimInfo.procOffice}
            <br></br>
            {renderPayorAddress(updatePostExternalClient, fromExternal, 'chr_street1')}
            {renderPayorAddress(updatePostExternalClient, fromExternal, 'chr_street2')}
            {renderPayorAddress(updatePostExternalClient, fromExternal, 'chr_city')}
            {renderPayorAddress(updatePostExternalClient, fromExternal, 'chr_state')}
            {renderPayorAddress(updatePostExternalClient, fromExternal, 'chr_zipcode')}
            {renderPayorAddressDetails()} {renderPayorAddressZip('city')} {renderPayorAddressZip('state')}
            <br />
            {renderPayorAddressZip('zip')}

            <br></br>
            <Label>
              {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.address?.street1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.address && updatedPayorInfo.address.street1}{' '}
            </Label>
            <br></br>
            <Label>
              {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.address?.zipCode?.city}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.address &&
                updatedPayorInfo.address.zipCode &&
                updatedPayorInfo.address.zipCode.city}{' '}
            </Label>
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.address?.zipCode?.state}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.address &&
                updatedPayorInfo.address.zipCode &&
                updatedPayorInfo.address.zipCode.state}{' '}
            </Label>
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.address?.zipCode?.zip}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.address &&
                updatedPayorInfo.address.zipCode &&
                updatedPayorInfo.address.zipCode.zip}{' '}
            </Label>
            <br></br>
            <Label>Phone:</Label>
            {renderPayorPhoneDetails('phone')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.phone && formatPhoneNumber(loadClaimantByIdInfo.payor.phone)}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.phone &&
              formatPhoneNumber(updatedPayorInfo.phone)}{' '}
            <br></br>
            <Label>Fax:</Label>
            {renderPayorPhoneDetails('fax')}

            &nbsp;
            {!isFromLanding && isEmpty(updatedPayorInfo) && loadClaimantByIdInfo?.payor?.fax && formatPhoneNumber(loadClaimantByIdInfo.payor.fax)}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPayorEdited) && updatedPayorInfo && updatedPayorInfo.fax && formatPhoneNumber(updatedPayorInfo.fax)}{' '}
          </Col>
          <Col>
            <Label>
              <b>Physician</b>{' '}
              <FontAwesomeIcon
                className="blue-text cursor-hover"
                icon={faEdit}
                onClick={openNewPhysicianModal}
              />
            </Label>
            <br></br>
            <Label>Name: </Label>
            {renderPhysicianName('first')} {renderPhysicianName('last')}
            {/* {fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 &&
              fromExternal.externalReferralData[0].PhysicianName &&
              `${fromExternal.externalReferralData[0].PhysicianName}`} */}
            &nbsp;
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.first}{' '}
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.last}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.people && updatedPhysicianInfo.people.first}{' '}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.people && updatedPhysicianInfo.people.last}
            <br></br>
            <Label>
              <b>License:</b>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.licenseNumber}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.licenseNumber}
            </Label>
            {renderPhysicianId('licenseNumber')}
            <Label>
              &nbsp;<b>NPI:</b>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.npi}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.npi}
            </Label>
            {renderPhysicianId('npi')}
            <br></br>
            <Label>Address:
              {renderPhysicianAddress('street1')}{' '}
              {renderPhysicianAddress('street2')}{' '}
              <br></br>
              {renderPhysicianAddressZip('city')}{' '}
              {renderPhysicianAddressZip('state')}{', '}
              {renderPhysicianAddressZip('zip')} </Label>
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.address?.street1}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.address && updatedPhysicianInfo.address.street1}
            <br />
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.address?.street2}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.address && updatedPhysicianInfo.address.street2}
            <br />
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.address?.zipCode?.city}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo &&
              updatedPhysicianInfo?.address?.zipCode &&
              updatedPhysicianInfo?.address?.zipCode?.city}{' '}
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.address?.zipCode?.state}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo &&
              updatedPhysicianInfo?.address?.zipCode?.state}
            {','}
            {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.address?.zipCode?.zip}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo &&
              updatedPhysicianInfo?.address?.zipCode?.zip}
            <br></br>
            <Label>Phone: </Label>
            {renderPhysicianPhoneFromWorklist('phone')}
            {/* {fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 &&
              `${fromExternal.externalReferralData[0].PhysicianPhone}`} */}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.phone && formatPhoneNumber(loadClaimantByIdInfo.physician.people.phone)}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo &&
                updatedPhysicianInfo.people && updatedPhysicianInfo.people.phone &&
                formatPhoneNumber(updatedPhysicianInfo.people.phone)}
            </Label>
            &nbsp;<Label>Ext: </Label>
            {renderPhysicianFromWorklist('phoneExt')}
            <Label>
              {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.phoneExt}
              {updatedPhysicianInfo && updatedPhysicianInfo?.people?.phoneExt}
            </Label>
            <br></br>
            <Label>Email:</Label>
            {renderPhysicianFromWorklist('email1')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.email1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isPhyEdited) && updatedPhysicianInfo && updatedPhysicianInfo.people.email1}
            </Label>
            <br></br>
            <Label>
              <b>Fax: </b>
              <Label>
                {renderPhysicianPhoneFromWorklist('fax')}
                &nbsp;
                {!isFromLanding && isEmpty(updatedPhysicianInfo) && loadClaimantByIdInfo?.physician?.people?.fax &&
                  formatPhoneNumber(loadClaimantByIdInfo.physician.people.fax)}
                {updatedPhysicianInfo &&
                  updatedPhysicianInfo.people.fax &&
                  formatPhoneNumber(updatedPhysicianInfo.people.fax)}
              </Label>
            </Label>
          </Col>
          <Col>
            <Label>
              <b>Attorney</b>{' '}
              <FontAwesomeIcon
                className="blue-text cursor-hover"
                icon={faEdit}
                onClick={openNewAttorneyModal}
              />
            </Label>
            <br></br>
            <Label>Name:</Label>
            {renderAttorneyName('first')} {renderAttorneyName('last')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.first}{' '}
              {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.last}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
                updatedAttorneyInfo.people &&
                updatedAttorneyInfo.people.first}{' '}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
                updatedAttorneyInfo.people &&
                updatedAttorneyInfo.people.last}
            </Label>
            <br></br>
            <Label>Phone:</Label>
            {renderAttorneyPhone('phone')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.phone && formatPhoneNumber(loadClaimantByIdInfo.claim.attorney.people.phone)}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
                updatedAttorneyInfo.people &&
                updatedAttorneyInfo.people.phone &&
                formatPhoneNumber(updatedAttorneyInfo.people.phone)}
            </Label>
            &nbsp;<Label>Ext: </Label>
            {renderAttorneyName('phoneExt')}
            <Label>
              {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.phoneExt}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
                updatedAttorneyInfo.people &&
                updatedAttorneyInfo.people.phoneExt}
            </Label>
            <br></br>
            <Label>Email:</Label>
            {renderAttorneyName('email1')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.email1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
                updatedAttorneyInfo.people &&
                updatedAttorneyInfo.people.email1}
            </Label>
            <br></br>
            <Label>Fax:</Label>
            {renderAttorneyPhone('fax')}
            &nbsp;
            {!isFromLanding && isEmpty(updatedAttorneyInfo) && loadClaimantByIdInfo?.claim?.attorney?.people?.fax &&
              formatPhoneNumber(loadClaimantByIdInfo.claim.attorney.people.fax)}
            {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEttorneyEdited) && updatedAttorneyInfo &&
              updatedAttorneyInfo.people &&
              updatedAttorneyInfo.people.fax &&
              formatPhoneNumber(updatedAttorneyInfo.people.fax)}
            <br></br>
            <Label>
              <b>Employer</b>{' '}
              <FontAwesomeIcon
                className="blue-text cursor-hover"
                icon={faEdit}
                onClick={openNewEmployerModal}
              />
            </Label>
            <br></br>
            <Label>Name: {renderEmployer(fromExternal, 'EmployerName')}</Label>
            {renderEmployerName('name')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer?.name}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEmployerEdited) && updatedEmployerInfo.employer &&
                updatedEmployerInfo.employer.name}
            </Label>
            <br></br>
            <Label>Phone:</Label>
            {renderEmployerPhone('phone')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer?.people?.phone && formatPhoneNumber(loadClaimantByIdInfo.claim.employer.people.phone)}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEmployerEdited) && updatedEmployerInfo.employer &&
                updatedEmployerInfo.employer.people.phone &&
                formatPhoneNumber(updatedEmployerInfo.employer.people.phone)}
            </Label>
            &nbsp;<Label>Ext: </Label>
            {renderEmployerName('phoneExt')}
            <Label>
              {!isFromLanding && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer?.people?.phoneExt}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEmployerEdited) && updatedEmployerInfo.employer &&
                updatedEmployerInfo.employer.people.phoneExt}
            </Label>
            <br></br>
            <Label>Email:</Label>
            {renderEmployerName('email1')}
            <Label>
              &nbsp;
              {!isFromLanding && isEmpty(updatedEmployerInfo) && loadClaimantByIdInfo?.claim?.employer?.people?.email1}
              {(!fromExternal.fromExternal.fromExternal || fromExternal.fromExternal.isEmployerEdited) && updatedEmployerInfo.employer &&
                updatedEmployerInfo.employer.people.email1}
            </Label>
            <br></br>
            <Label></Label>
          </Col>
        </Row>
      </FormGroup>
      <NewClaimantModal
        isOpen={isOpenNewClaimantModal}
        onClose={openNewClaimantModal}
        isNew={!(updatedClaimInfo && updatedClaimInfo.claimant)}
        updatedClaimInfo={updatedClaimInfo}
        fromExternal={fromExternal.fromExternal}
        externalRefData={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ? fromExternal.externalReferralData[0] : {}}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        setPatientEdited={fromExternal.fromWorklist ? fromExternal.externalData.setPatientEdited : fromExternal.fromExternal.setPatientEdited}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        setPatientData={setPatientData}
        referralId={fromExternal?.data?.referralId}
        setPopulatedRef={setPopulatedRef}
      />
      {isOpenNewPayorModal && <PayorLookup
        isOpen={isOpenNewPayorModal}
        onClose={openNewPayorModal}
        isNew={!(updatedPayorInfo)}
        updatedPayorInfo={updatedPayorInfo}
        updatedClaimInfo={updatedClaimInfo}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        claimNumber={updatedClaimInfo && updatedClaimInfo.claimNo}
        fromExternal={fromExternal.fromExternal}
        setPayorEdited={fromExternal.fromWorklist ? fromExternal.externalData.setPayorEdited : fromExternal.fromExternal.setPayorEdited}
        externalRefData={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ? fromExternal.externalReferralData[0] : {}}
        NewPayorName={renderPayorName(updatePostExternalClient)}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.payor}
        isFromLanding={isFromLanding}
        referralId={fromExternal?.data?.referralId}
      />}
      <NewReferrerModal
        isOpen={isOpenRefModal}
        onClose={openNewReferrerModal}
        isNew={!updatedRefInfo.referrer}
        setReferrerEdited={fromExternal.fromWorklist ? fromExternal.externalData.setReferrerEdited : fromExternal.fromExternal.setReferrerEdited}
        updatedRefInfo={updatedRefInfo}
        fromExternal={fromExternal.fromExternal}
        NewReferrerFirstName={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ?
          fromExternal.externalReferralData[0].RequesterName : ''}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        externalRefData={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ? fromExternal.externalReferralData[0] : {}}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        setRefData={setRefData}
        referralId={fromExternal?.data?.referralId}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.referrer?.people}
        isFromLanding={isFromLanding}
      />
      <NewAdjusterModal
        isOpen={isOpenAdjusterModal}
        onClose={openNewAdjusterModal}
        isNew={!updatedAdjusterInfo.adjuster}
        updatedAdjusterInfo={updatedAdjusterInfo}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        fromExternal={fromExternal.fromExternal}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        setAdjusterEdited={fromExternal.fromWorklist ? fromExternal.externalData.setAdjusterEdited : fromExternal.fromExternal.setAdjusterEdited}
        externalRefData={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ? fromExternal.externalReferralData[0] : {}}
        NewAdjusterName={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ?
          fromExternal.externalReferralData[0].AdjusterName : ''}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.adjuster?.people}
        setAdjData={setAdjData}
        referralId={fromExternal?.data?.referralId}
        isFromLanding={isFromLanding}
      />
      <NewCaseManagerModal
        isOpen={isOpenCaseManagerModal}
        onClose={openNewCaseManagerModal}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        isNew={!updatedCaseManagerInfo.caseManager}
        updatedCaseManagerInfo={updatedCaseManagerInfo}
        fromExternal={fromExternal.fromExternal}
        externalRefData={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ? fromExternal.externalReferralData[0] : {}}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        setCaseManagerEdited={fromExternal.fromWorklist ? fromExternal.externalData.setCaseManagerEdited : fromExternal.fromExternal.setCaseManagerEdited}
        NewCaseManagerName={fromExternal.fromExternal && fromExternal.externalReferralData.length > 0 ?
          fromExternal.externalReferralData[0].CaseManagerName : ''}
        setCaseManData={setCaseManData}
        referralId={fromExternal?.data?.referralId}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.caseManager?.people}
        isFromLanding={isFromLanding}
      />
      <NewEmployerModal
        isOpen={isOpenEmployerModal}
        onClose={openNewEmployerModal}
        isNew={!updatedEmployerInfo.employer}
        updatedEmployerInfo={updatedEmployerInfo}
        isEmployerEdited={fromExternal.isEmployerEdited}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        setEmployerEdited={fromExternal.fromWorklist ? fromExternal.externalData.setEmployerEdited : fromExternal.fromExternal.setEmployerEdited}
        fromExternal={fromExternal.fromExternal}
        NewEmployerName={fromExternal.isFromLanding ?
          fromExternal.workListData?.claim?.employer?.name : ''}
        setEmpData={setEmpData}
        referralId={fromExternal?.data?.referralId}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.claim?.employer}
        isFromLanding={isFromLanding}
      />
      <NewPhysicianModal
        isOpen={isOpenPhysicianModal}
        onClose={openNewPhysicianModal}
        isNew={!updatedPhysicianInfo}
        updatedPhysicianInfo={updatedPhysicianInfo}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        setPhyEdited={fromExternal.fromWorklist ? fromExternal.externalData.setPhyEdited : fromExternal.fromExternal.setPhyEdited}
        fromExternal={fromExternal.fromExternal}
        setPhyData={setPhyData}
        referralId={fromExternal?.data}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.physician}
        isFromLanding={isFromLanding}
      />
      <NewAttorneyModal
        isOpen={isOpenAttorneyModal}
        onClose={openNewAttorneyModal}
        isNew={!updatedAttorneyInfo}
        updatedAttorneyInfo={updatedAttorneyInfo}
        setIsExternal={fromExternal.fromExternal.setIsExternal}
        setEttorneyEdited={fromExternal.fromWorklist ? fromExternal.externalData.setEttorneyEdited : fromExternal.fromExternal.setEttorneyEdited}
        fromExternal={fromExternal.fromExternal}
        setAttData={setAttData}
        referralId={fromExternal?.data?.referralId}
        fromWorklist={fromExternal.fromWorklist}
        worklistData={fromWorkList}
        fromExistingWorkList={fromWorkList.claim?.attorney}
        loadInfoFromExistingPatient={!isFromLanding && loadClaimantByIdInfo?.claim?.attorney}
        isFromLanding={isFromLanding}
      />
      {isOpenFileModal && <Fast360FileModal
        clicked={() => setIsOpenFileModal(false)}
        isOpen={isOpenFileModal}
        dataObject={selectedExternalData}
        zebraId={selectedExternalData.zebraId}
        refId={selectedExternalData.sedgwickReferralId}
        fromExternal={fromExternal.fromExternal.fromExternal}
        tableName={"sedgwick_referral"}
        serviceId={selectedExternalData.sedgwickReferralId} // is serviceid and refid same?not need of serviceid
      />}
    </>
  );
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    loadClaimantByIdInfo: fast360Info.loadClaimantByIdInfo,
    updatedRefInfo: fast360Info.updatedRefInfo,
    updatedPayorInfo: fast360Info.updatedPayorInfo,
    updatedAdjusterInfo: fast360Info.updatedAdjusterInfo,
    updatedClaimInfo:
      fast360Info.updatedClaimInfo && fast360Info.updatedClaimInfo.claim,
    updatedCaseManagerInfo: fast360Info.updatedCaseManagerInfo,
    updatedEmployerInfo: fast360Info.updatedEmployerInfo,
    updatedPhysicianInfo: fast360Info.updatedPhysicianInfo,
    updatedAttorneyInfo: fast360Info.updatedAttorneyInfo,
    updatedClaimInjuryListInfo:
      fast360Info.updatedClaimInjuryListInfo &&
      fast360Info.updatedClaimInjuryListInfo.claimInjury,
    updatePostExternalClient: state.FAST360.fast360Reducer.updatePostExternalClient ? state.FAST360.fast360Reducer.updatePostExternalClient : [],
    fromWorkList: state.FAST360.fast360Reducer.updateLoadFromWorkList ? state.FAST360.fast360Reducer.updateLoadFromWorkList : {},
    setAdvValues: state.FAST360.fast360Reducer.setAdvValues,
    selectedExternalData: state.FAST360.fast360Reducer.selectedExternalData ? state.FAST360.fast360Reducer.selectedExternalData : {},
  };
};



export default connect(mapStateToProps, null)(Fast360EditHeader);
