import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';

import { expertInfoSelectors, expertInfoActions } from '../store';

import {
  BASIC_INFORMATION,
  RECRUITMENT_INFORMATION,
  DELIVERY_AND_NOTIFICATION,
  COMMENT,
} from '../utils/constantUtils';

import GroupRecordModalBasicInfoTab from './GroupRecordModalBasicInfoTab';
import RecordModalRecruitmentInfoTab from './RecordModalRecruitmentInfoTab';
import GroupRecordDeliveryNotificationTab from './GroupRecordDeliveryNotificationTab';
import RecordCommentTab from './RecordCommentTab';

const TabHeader = ({ activeTab, toggle }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === BASIC_INFORMATION.key,
        })}
        onClick={() => toggle(BASIC_INFORMATION.key)}
      >
        {BASIC_INFORMATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === RECRUITMENT_INFORMATION.key,
        })}
        onClick={() => toggle(RECRUITMENT_INFORMATION.key)}
      >
        {RECRUITMENT_INFORMATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === DELIVERY_AND_NOTIFICATION.key,
        })}
        onClick={() => toggle(DELIVERY_AND_NOTIFICATION.key)}
      >
        {DELIVERY_AND_NOTIFICATION.value}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === COMMENT.key })}
        onClick={() => toggle(COMMENT.key)}
      >
        {COMMENT.value}
      </NavLink>
    </NavItem>
  </Nav>
);

const GroupRecordModalBody = ({
  updateInput,
  updateError,
  data,
  errors,
  expertGroupTypes,
  expertStatuses,
  expertStatusProspect,
  expertStatusApplicant,
  recruitmentMethods,
  referralCodes,
  termReason,
  expertDocumentTypes,
  notificationTypes,
}) => {
  const [activeTab, setActiveTab] = useState(BASIC_INFORMATION.key);

  const handleOnInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const targetName = target.name;
    updateInput({ [targetName]: value });
    validate(targetName, value);
  };

  const handleOnCustomInputChange = (id, value) => {
    updateInput({ [id]: value });
    validate(id, value);
  };

  const handleOnInputMaskChange = event => {
    const target = event.target;
    const value = target.value.replace(/[^0-9]/g, '');
    const targetName = target.name;
    updateInput({ [targetName]: value });
    validate(targetName, value);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const validate = (id, value) => {
    if (id === 'groupName') {
      validateGroupName(value);
      return;
    }
    if (id === 'tinNumber') {
      validateTinNumber(value);
    }
  };

  const validateGroupName = value => {
    if (value === '') {
      updateError({
        groupName: 'The Group name field is required.',
      });
      return;
    }
    updateError({
      groupName: '',
    });
  };

  const validateTinNumber = value => {
    if (value !== '' && value.length !== 9) {
      updateError({
        tinNumber: 'The Tin number field must be 9 characters long.',
      });
      return;
    }
    updateError({
      tinNumber: '',
    });
  };

  return (
    <>
      <TabHeader activeTab={activeTab} toggle={toggle} />
      <TabContent activeTab={activeTab} style={{ paddingTop: '10px' }}>
        <TabPane tabId={BASIC_INFORMATION.key}>
          <GroupRecordModalBasicInfoTab
            data={data}
            errors={errors}
            onInputChange={handleOnInputChange}
            onInputMaskChange={handleOnInputMaskChange}
            expertGroupTypes={expertGroupTypes}
          />
        </TabPane>
        <TabPane tabId={RECRUITMENT_INFORMATION.key}>
          <RecordModalRecruitmentInfoTab
            data={data}
            onInputChange={handleOnInputChange}
            onCustomInputChange={handleOnCustomInputChange}
            expertStatuses={expertStatuses}
            expertStatusProspect={expertStatusProspect}
            expertStatusApplicant={expertStatusApplicant}
            recruitmentMethods={recruitmentMethods}
            referralCodes={referralCodes}
            termReason={termReason}
            expertDocumentTypes={expertDocumentTypes}
          />
        </TabPane>
        <TabPane tabId={DELIVERY_AND_NOTIFICATION.key}>
          <GroupRecordDeliveryNotificationTab
            data={data}
            onInputChange={handleOnInputChange}
            notificationTypes={notificationTypes}
          />
        </TabPane>
        <TabPane tabId={COMMENT.key}>
          <RecordCommentTab data={data} onInputChange={handleOnInputChange} />
        </TabPane>
      </TabContent>
    </>
  );
};

GroupRecordModalBody.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  expertGroupTypes: PropTypes.array.isRequired,
  expertStatusProspect: PropTypes.array.isRequired,
  expertStatusApplicant: PropTypes.array.isRequired,
  recruitmentMethods: PropTypes.array.isRequired,
  referralCodes: PropTypes.array.isRequired,
  termReason: PropTypes.array.isRequired,
  expertDocumentTypes: PropTypes.array.isRequired,
  notificationTypes: PropTypes.array.isRequired,
  updateInput: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialDataState = expertInfoSelectors.getInitialData(state);
  const groupState = expertInfoSelectors.getGroupInfo(state);
  return {
    data: groupState.data,
    errors: groupState.errors,
    expertStatuses: groupState.expertStatuses,
    expertGroupTypes: initialDataState.expertGroupTypes,
    expertStatusProspect: groupState.expertStatusProspect,
    expertStatusApplicant: groupState.expertStatusApplicant,
    recruitmentMethods: groupState.recruitmentMethods,
    referralCodes: groupState.referralCodes,
    termReason: groupState.termReason,
    expertDocumentTypes: initialDataState.expertDocumentTypes,
    notificationTypes: groupState.notificationTypes,
  };
};

const mapDispatchToProps = dispatch => ({
  updateInput: data => dispatch(expertInfoActions.updateGroupInput(data)),
  updateError: data => dispatch(expertInfoActions.updateGroupInputError(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupRecordModalBody);
