import { combineReducers } from 'redux';
import constants from './constants';
import appModules from 'common/appModules';

const sideNavMenuDefaultState = {
  close: true,
  activeModule: appModules.getDefaultModule(),
};

const sideNavMenuReducer = (state = sideNavMenuDefaultState, action) => {
  switch (action.type) {
    case constants.SWITCH_MENU:
      return { ...state, close: !state.close };
    case constants.SWITCH_MODULE:
      return { ...state, activeModule: action.payload.module };
    default:
      return state;
  }
};

const appErrorDefaultState = {
  hasError: false,
};

const appErrorReducer = (state = appErrorDefaultState, action) => {
  switch (action.type) {
    case constants.ERROR:
      return { ...state, hasError: true };
    case constants.CLEAR_APP_ERROR:
      return { ...state, hasError: false };
    default:
      return state;
  }
};

const appAlertsDefaultState = [];

const appAlertsReducer = (state = appAlertsDefaultState, action) => {
  switch (action.type) {
    case constants.ADD_APP_ALERT:
      return [...state, action.payload];
    case constants.CLEAR_APP_ALERT:
      return state.filter(alert => {
        return alert.id !== action.payload.id;
      });
    default:
      return state;
  }
};

const appLoadingDefaultState = {
  loading: false,
  processCount: 0,
};

const appLoadingReducer = (state = appLoadingDefaultState, action) => {
  switch (action.type) {
    case constants.SET_APP_LOADING:
      return {
        ...state,
        loading: true,
        processCount: state.processCount + 1,
      };
    case constants.CLEAR_APP_LOADING:
      return {
        ...state,
        processCount: state.processCount - 1,
        loading: state.processCount !== 1,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  sideNavMenu: sideNavMenuReducer,
  appError: appErrorReducer,
  appAlerts: appAlertsReducer,
  appLoading: appLoadingReducer,
});

export default reducer;
