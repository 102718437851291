import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import AppDateInput from 'shared/components/input/AppDateInput';
import { Fast360SPTDetailModalContext } from '../components/AdvanceSearch/contexts/Fast360SPTDetailModalContext';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const Fast360SPTDetailModal = ({
  disableSave,
  isOpen,
  onClose,
  onSave,
  timeZone,
  loadLegStatus,
  getZipList,
  userId,
  getLookUpSpLocation,
  loadlookUpLocation,
  zipList,
  alert,
  updatedClaimInfo,
  transportDetailObj,
  referralId,
  serviceId,
  saveSPTLeg,
  updateSPTInfoTable,
  updateSptDetailList,
  sptDetailsList,
  pickupAddr,
  legLength,
  sptsaveData,
  detailsForOrder,
  setSPTData,
  sptDetails,
}) => {
  const { SPTData, dispatch } = useContext(Fast360SPTDetailModalContext);
  function updateSptModalForm(value, key) {
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { [key]: value },
    });
  }

  function preselectPickupAddress(addrObj) {
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { pickupAddress: addrObj },
    });
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  function updatePickupModalForm(value, key) {
    let pickUpAddress = SPTData.pickupAddress;
    if (key == "phone") {
      value = formatPhoneNum(value);
    }
    pickUpAddress[key] = value;
    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { pickupAddress: pickUpAddress },
    });
  }

  function updateDropModalForm(value, key) {
    let dropAddress = SPTData.dropOffAddress;
    if (key == "phone") {
      value = formatPhoneNum(value);
    }
    dropAddress[key] = value;

    dispatch({
      type: 'UPDATE_MODAL_DATA',
      payload: { dropOffAddress: dropAddress },
    });
  }

  const tripTypeDropDown = [
    { label: 'Choose', data: '' },
    { label: 'Direct', data: 'Direct' },
    { label: 'Connecting', data: 'Connecting' },
  ];

  const transportClassDropDown = [
    { label: 'Coach', data: 'Coach' },
    { label: 'Business Class', data: 'Business Class' },
    { label: 'Economy', data: 'Economy' },
    { label: 'First Class', data: 'First Class' },
    { label: 'N/A', data: 'N/A' },
  ];

  const updateBulkData = inputObj => {
    if (inputObj && inputObj.leg) {
      let pickupdateStr = moment(inputObj.pickup).format('YYYY-MM-DD hh:mm a'); //: "2020-12-28 06:00:00.0";
      let pickupdateArr = pickupdateStr.split(' ');
      inputObj.departureDate = new Date(inputObj.pickup);
      inputObj.departureHours = pickupdateArr[1].split(':')[0];
      inputObj.departureMin = pickupdateArr[1].split(':')[1];
      inputObj.departureAm = pickupdateArr[2];
      let dropOffdateStr = moment(inputObj.dropOff).format(
        'YYYY-MM-DD hh:mm a'
      ); //: "2020-12-28 06:00:00.0";
      let dropOffDateArr = dropOffdateStr.split(' ');
      inputObj.ArrivalDate = new Date(inputObj.dropOff);
      inputObj.ArrivalHours = dropOffDateArr[1].split(':')[0];
      inputObj.ArrivalMin = dropOffDateArr[1].split(':')[1];
      inputObj.ArrivalAm = dropOffDateArr[2];
      dispatch({
        type: 'UPDATE_MODAL_DATA',
        payload: inputObj,
      });
    }
  };

  useEffect(() => {
    if (transportDetailObj && transportDetailObj.leg) {
      updateBulkData({
        ...transportDetailObj,
        dropOffAddress: { ...transportDetailObj.dropOffAddress },
        pickupAddress: { ...transportDetailObj.pickupAddress },
      });
    } else {
      updateSptModalForm(legLength + 1, 'leg');
      updateSptModalForm(new Date(), 'departureDate');
      updateSptModalForm(new Date(), 'ArrivalDate');
      if (pickupAddr && (pickupAddr.city || pickupAddr.zipcode)) {
        preselectPickupAddress({ ...pickupAddr });
      }
    }
    return () => {
      dispatch({
        type: 'RESET_MODAL_DATA',
        payload: {},
      });
    };
  }, []);

  const validateFields = () => {
    let inputObj = { ...SPTData };
    if (inputObj.leg === '' || inputObj.leg <= 0) {
      alert('Quantity must be greater than 0.');
    } else if (inputObj.legStatusId === '' || inputObj.legStatusId <= 0) {
      alert('status is required to add/change a leg');
    } else if (inputObj.tripType === '' || inputObj.tripType <= 0) {
      alert('Trip Type is required to add/change a leg');
    } else if (inputObj.departureDate === '' || inputObj.departureDate <= 0) {
      alert(
        'Departure Date is required to add/change Scheduled Return trip leg'
      );
    } else if (
      !inputObj.departureHours ||
      (inputObj.departureHours && inputObj.departureHours === '0')
    ) {
      alert('Pickup Time is required to add/change a leg');
    } else if (
      inputObj.pickupAddress &&
      (inputObj.pickupAddress.facilityName === '' ||
        !inputObj.pickupAddress.facilityName)
    ) {
      alert(
        'Pickup Location is Required to add/change Scheduled Return trip leg'
      );
    } else if (inputObj.ArrivalDate === '' || inputObj.ArrivalDate <= 0) {
      alert('Arrival Date is required to add/change Scheduled Return trip leg');
    } else if (
      !inputObj.ArrivalHours ||
      (inputObj.ArrivalHours && inputObj.ArrivalHours === '0')
    ) {
      alert('Arrival Time is required to add/change Scheduled Return trip leg');
    } else if (
      inputObj.dropOffAddress &&
      (inputObj.dropOffAddress.facilityName === '' ||
        !inputObj.dropOffAddress.facilityName)
    ) {
      alert(
        'Arrival Location is Required to add/change Scheduled Return trip leg'
      );
    } else {
      let pickupHour = `${('0' + inputObj.departureHours).slice(-2)}:${(
        '0' + inputObj.departureMin
      ).slice(-2)} ${inputObj.departureAm}`;
      let pickupDate = `${moment(inputObj.departureDate).format(
        'MM/DD/YYYY'
      )} ${pickupHour}`;
      inputObj.pickup = moment(pickupDate).format('YYYY-MM-DD HH:mm:ss.S');

      let dropOffHour = `${('0' + inputObj.ArrivalHours).slice(-2)}:${(
        '0' + inputObj.ArrivalMin
      ).slice(-2)} ${inputObj.ArrivalAm}`;
      let dropOffDate = `${moment(inputObj.ArrivalDate).format(
        'MM/DD/YYYY'
      )} ${dropOffHour}`;
      inputObj.dropOff = moment(dropOffDate).format('YYYY-MM-DD HH:mm:ss.S');

      delete inputObj.departureAm;
      delete inputObj.departureDate;
      delete inputObj.departureHours;
      delete inputObj.departureMin;
      delete inputObj.ArrivalAm;
      delete inputObj.ArrivalDate;
      delete inputObj.ArrivalHours;
      delete inputObj.ArrivalMin;
      delete inputObj.ArrivalTz;
      delete inputObj.departureTz;
      delete inputObj.carrier;
      delete inputObj.pickupAddress.phone;
      delete inputObj.dropOffAddress.phone;
      inputObj.pickupAddress.requiredFields = [];
      inputObj.dropOffAddress.requiredFields = [];
      inputObj.dropOffAddressId = inputObj?.dropOffAddress?.fields?.id;
      inputObj.pickupAddressId = inputObj?.pickupAddress?.fields?.id;
      inputObj.arriveAddressId = inputObj?.dropOffAddress?.fields?.id;
      inputObj.departAddressId = inputObj?.pickupAddress?.fields?.id;
      inputObj.vendor = {
        address: {
          zipCode: { longitude: 0.0, latitude: 0.0, dts: false },
          active: false,
          permAddress: false,
        },
        remitAddress: {
          zipCode: { longitude: 0.0, latitude: 0.0, dts: false },
          active: false,
          permAddress: false,
        },
        primaryContact: {
          people: {},
          active: false,
          primary: false,
          referralReceived: false,
          qaAssignedConfirmed: false,
          psAssignedConfirmed: false,
          vosAssignedConfirmed: false,
          orderCancelledBilled: false,
        },
        active: false,
        preferred: false,
        contactList: [],
        serviceList: [],
      };
      let selectedReturnType = loadLegStatus?.ReturnType.filter(
        returnObj => returnObj.value === inputObj.returnTypeDesc
      );
      if (selectedReturnType.length > 0) {
        inputObj.returnType = selectedReturnType[0].id;
      }

      let reqData = {
        inputData: {
          specialtransportLeg: inputObj,
          serviceId: serviceId,
          zebraUserId: userId,
        },
        loadWorkData: {
          zebraUserId: userId,
          service_id: serviceId,
          transport_type: detailsForOrder.specialTransportCategory,
          module: detailsForOrder.module,
        },
      };
      if (referralId && serviceId > 100) {
        saveSPTLeg(reqData);
        // setSPTData([...sptDetails, inputObj]);
        sptsaveData(true);
        if (inputObj.id) {
          onSave(inputObj);
        }
        closeModal(true);
      } else {
        if (transportDetailObj.tempId) {
          let sptList = sptDetailsList.map(sptItem => {
            if (sptItem.tempId === transportDetailObj.tempId) {
              sptItem = { ...inputObj, serviceId, tempId: transportDetailObj.tempId };
            }
            return sptItem;
          });
          updateSptDetailList(sptList);
          onSave({ ...inputObj, tempId: transportDetailObj.tempId });
        } else {
          let tempId = `spt-${Math.floor(Math.random() * 1000)}`;
          updateSPTInfoTable([{ ...inputObj, serviceId, tempId: tempId }]);
          onSave({ ...inputObj, tempId: tempId });
        }
        sptsaveData(true);
      }
    }
  };

  const closeModal = (onSave) => {
    dispatch({
      type: 'RESET_MODAL_DATA',
      payload: {},
    });
    onClose(onSave);
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Form>
            <Row style={{ padding: '3px' }}>
              <Col md="1" />
              <Label for="leg" md="1">
                Leg:
              </Label>
              <Col md="1">
                <Input
                  type="number"
                  min="1"
                  value={SPTData.leg}
                  onChange={e => updateSptModalForm(e.target.value, 'leg')}
                />
              </Col>
              <Col md="1"></Col>
              <Label for="Carrier" md="1.5">
                Carrier:
              </Label>
              <Col md="4">
                <Input
                  type="text"
                  value={SPTData.carrier}
                  onChange={e => updateSptModalForm(e.target.value, 'carrier')}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Col md="1" />
              <Label for="Status" md="1">
                Status:
              </Label>
              <Col md="2">
                <AppInput
                  id="status"
                  type="select"
                  changeHandler={(id, value) =>
                    updateSptModalForm(value, 'legStatusId')
                  }
                  option={{
                    value: 'id',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={SPTData.legStatusId}
                  data={loadLegStatus.LegStatus || []}
                />
                {/* <Input type="select">
                  <option>Direct</option>
                </Input> */}
              </Col>
              <Label for="TripType" md="1.5">
                Trip Type:
              </Label>
              <Col md="2">
                <Input
                  type="select"
                  name="tripType"
                  id="tripType"
                  value={SPTData.tripType}
                  onChange={e => {
                    updateSptModalForm(e.target.value, 'tripType');
                  }}
                >
                  {tripTypeDropDown.map(value => {
                    return <option value={value.data}>{value.label}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row style={{ padding: '8px' }}>
              <Col sm="10" md={{ size: 3, offset: 4 }}>
                <Label>
                  <b>Departure Information</b>
                </Label>
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Departure Date/Time:</Label>
              <Col md="3">
                <AppDateInput
                  onChange={value => updateSptModalForm(value, 'departureDate')}
                  selected={SPTData.departureDate ? SPTData.departureDate : ''}
                  value={SPTData?.departureDate || ''}
                />
              </Col>
              <Col md="1">
                <Input
                  name="departureHours"
                  type="text"
                  min="0"
                  value={SPTData.departureHours}
                  onChange={e =>
                    updateSptModalForm(
                      Number(e.target.value) > 12
                        ? Number(e.target.value) % 12
                        : e.target.value,
                      'departureHours'
                    )
                  }
                  onBlur={() => {
                    if (SPTData.departureHours === '') {
                      updateSptModalForm('0', 'departureHours');
                    }
                  }}
                />
              </Col>
              <Col md="1">
                <Input
                  name="departureMin"
                  type="text"
                  min="0"
                  value={SPTData.departureMin}
                  onChange={e =>
                    updateSptModalForm(
                      Number(e.target.value) > 60
                        ? Number(e.target.value) % 60
                        : e.target.value,
                      'departureMin'
                    )
                  }
                  onBlur={() => {
                    if (SPTData.departureMin === '') {
                      updateSptModalForm('00', 'departureMin');
                    }
                  }}
                />
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateSptModalForm('am', 'departureAm')}
                >
                  AM
                </Button>
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateSptModalForm('pm', 'departureAm')}
                >
                  PM
                </Button>
              </Col>
              <Col md="2">
                <AppInput
                  id="departureTz"
                  type="select"
                  changeHandler={(id, value) =>
                    updateSptModalForm(value, 'departureTz')
                  }
                  option={{
                    value: 'id',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={SPTData.departureTz}
                  data={timeZone}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Departure Lookup:</Label>
              <Col md="6">
                <AsyncTypeahead
                  filterBy={['facilityName', 'locationCode']}
                  placeholder="Search"
                  id="async-example"
                  isLoading={false}
                  clearButton={true}
                  value={
                    SPTData.facilityName
                      ? [
                        {
                          value: SPTData.facilityName,
                          label: `${SPTData.facilityName}-${SPTData.locationCode}`,
                        },
                      ]
                      : []
                  }
                  labelKey={option =>
                    `${option.facilityName}-${option.locationCode}`
                  }
                  minLength={3}
                  onChange={e => {
                    if (e.length > 0) {
                      updatePickupModalForm(e[0].facilityName, 'facilityName');
                      updatePickupModalForm(e[0].locationCode, 'locationCode');
                      updatePickupModalForm(e[0].street, 'street');
                      updatePickupModalForm(e[0].city, 'city');
                      updatePickupModalForm(e[0].state, 'state');
                      updatePickupModalForm(e[0].zipcode, 'zipcode');
                      updatePickupModalForm(e[0].fields, 'fields');
                    } else {
                      updatePickupModalForm(null, 'facilityName');
                    }
                  }}
                  onSearch={query =>
                    getLookUpSpLocation(query, userId, 'locationName')
                  }
                  options={loadlookUpLocation}
                  renderMenuItemChildren={option => (
                    <span>
                      {option.facilityName}-{option.locationCode}
                      <br />
                      {option.street}
                      <br /> {option.city} {option.state},{option.zipcode}
                    </span>
                  )}
                />
              </Col>
              <Col md="3">
                <AsyncTypeahead
                  filterBy={['facilityName', 'locationCode']}
                  placeholder="Code Lookup"
                  id="async-example"
                  isLoading={false}
                  clearButton={true}
                  value={
                    SPTData.facilityName
                      ? [
                        {
                          value: SPTData.facilityName,
                          label: `${SPTData.facilityName}-${SPTData.locationCode}`,
                        },
                      ]
                      : []
                  }
                  labelKey={option =>
                    `${option.facilityName}-${option.locationCode}`
                  }
                  minLength={3}
                  onChange={e => {
                    if (e.length > 0) {
                      updatePickupModalForm(e[0].facilityName, 'facilityName');
                      updatePickupModalForm(e[0].locationCode, 'locationCode');
                      updatePickupModalForm(e[0].street, 'street');
                      updatePickupModalForm(e[0].city, 'city');
                      updatePickupModalForm(e[0].state, 'state');
                      updatePickupModalForm(e[0].zipcode, 'zipcode');
                      updatePickupModalForm(e[0].fields, 'fields');
                    } else {
                      updatePickupModalForm(null, 'locationCode');
                    }
                  }}
                  onSearch={query => getLookUpSpLocation(query, userId, 'code')}
                  options={loadlookUpLocation}
                  renderMenuItemChildren={option => (
                    <span>
                      {option.facilityName}-{option.locationCode}
                      <br />
                      {option.street}
                      <br /> {option.city} {option.state},{option.zipcode}
                    </span>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Departure Location:</Label>
              <Col md="6">
                <Input
                  type="text"
                  value={SPTData.pickupAddress.facilityName}
                  onChange={e => {
                    updatePickupModalForm(e.target.value, 'facilityName');
                  }}
                />
              </Col>
              <Label md="1">Code:</Label>
              <Col md="2">
                <Input
                  type="text"
                  value={SPTData.pickupAddress.locationCode}
                  onChange={e => {
                    updatePickupModalForm(e.target.value, 'locationCode');
                  }}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Col md="3" />
              <Col md="6">
                <Input
                  type="text"
                  value={SPTData.pickupAddress.street}
                  onChange={e => {
                    updatePickupModalForm(e.target.value, 'street');
                  }}
                />
              </Col>
              <Label md="1">Phone:</Label>
              <Col md="2">
                <Input
                  type="tel"
                  value={SPTData.pickupAddress.phone}
                  id="departurePhone"
                  name="departurePhone"
                  minLength="0"
                  maxLength="14"
                  onChange={e => updatePickupModalForm(e.target.value, 'phone')}
                  placeholder="(xxx)xxx-xxxx"
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Col md="3" />
              <Col md="3">
                <Input type="text" value={SPTData.pickupAddress.city} />
              </Col>
              <Col md="1">
                <Input type="text" value={SPTData.pickupAddress.state} />
              </Col>
              <Col md="2">
                <Input
                  type="text"
                  value={SPTData.pickupAddress.zipcode}
                  onChange={e => {
                    updatePickupModalForm(e.target.value, 'zipcode');
                  }}
                />
              </Col>
              <Label md="1">Gate:</Label>
              <Col md="2">
                <Input
                  type="text"
                  name="departureGate"
                  id="departureGate"
                  value={SPTData.departureGate}
                  onChange={e =>
                    updateSptModalForm(e.target.value, 'departureGate')
                  }
                />
              </Col>
            </Row>
            <Row style={{ padding: '8px' }}>
              <Col sm="10" md={{ size: 3, offset: 4 }}>
                <Label>
                  <b>Arrival Information</b>
                </Label>
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Arrival Date/Time:</Label>
              <Col md="3">
                <AppDateInput
                  onChange={value => updateSptModalForm(value, 'ArrivalDate')}
                  selected={SPTData.ArrivalDate ? SPTData.ArrivalDate : ''}
                  value={SPTData?.ArrivalDate || ''}
                />
              </Col>
              <Col md="1">
                <Input
                  name="ArrivalHours"
                  type="text"
                  min="0"
                  value={SPTData.ArrivalHours}
                  onChange={e =>
                    updateSptModalForm(
                      Number(e.target.value) > 12
                        ? Number(e.target.value) % 12
                        : e.target.value,
                      'ArrivalHours'
                    )
                  }
                  onBlur={() => {
                    if (SPTData.ArrivalHours === '') {
                      updateSptModalForm('0', 'ArrivalHours');
                    }
                  }}
                />
              </Col>
              <Col md="1">
                <Input
                  name="ArrivalMin"
                  type="text"
                  min="0"
                  value={SPTData.ArrivalMin}
                  onChange={e =>
                    updateSptModalForm(
                      Number(e.target.value) > 60
                        ? Number(e.target.value) % 60
                        : e.target.value,
                      'ArrivalMin'
                    )
                  }
                  onBlur={() => {
                    if (SPTData.ArrivalMin === '') {
                      updateSptModalForm('00', 'ArrivalMin');
                    }
                  }}
                />
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateSptModalForm('am', 'ArrivalAm')}
                >
                  AM
                </Button>
              </Col>
              <Col md="1">
                <Button
                  color={'primary'}
                  onClick={() => updateSptModalForm('pm', 'ArrivalAm')}
                >
                  PM
                </Button>
              </Col>
              <Col md="2">
                <AppInput
                  id="ArrivalTz"
                  type="select"
                  changeHandler={(id, value) =>
                    updateSptModalForm(value, 'ArrivalTz')
                  }
                  option={{
                    value: 'value',
                    displayValue: 'value',
                  }}
                  placeholder="Choose"
                  value={SPTData.ArrivalTz}
                  data={timeZone}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Arrival Lookup:</Label>
              <Col md="6">
                <AsyncTypeahead
                  filterBy={['facilityName', 'locationCode']}
                  placeholder="Search"
                  id="async-example"
                  isLoading={false}
                  clearButton={true}
                  value={SPTData.ArrivalLocation}
                  labelKey={option =>
                    `${option.facilityName}-${option.locationCode}`
                  }
                  minLength={3}
                  onChange={e => {
                    if (e.length > 0) {
                      updateDropModalForm(e[0].facilityName, 'facilityName');
                      updateDropModalForm(e[0].locationCode, 'locationCode');
                      updateDropModalForm(e[0].street, 'street');
                      updateDropModalForm(e[0].city, 'city');
                      updateDropModalForm(e[0].state, 'state');
                      updateDropModalForm(e[0].zipcode, 'zipcode');
                      updateDropModalForm(e[0].fields, 'fields');
                    } else {
                      updateDropModalForm(null, 'facilityName');
                    }
                  }}
                  onSearch={query =>
                    getLookUpSpLocation(query, userId, 'locationName')
                  }
                  options={loadlookUpLocation}
                  renderMenuItemChildren={option => (
                    <span>
                      {option.facilityName}-{option.locationCode}
                      <br />
                      {option.street}
                      <br /> {option.city} {option.state},{option.zipcode}
                    </span>
                  )}
                />
              </Col>
              <Col md="3">
                <AsyncTypeahead
                  filterBy={['locationCode']}
                  placeholder="Code Lookup"
                  id="async-example"
                  isLoading={false}
                  clearButton={true}
                  value={SPTData.ArrivalLocation}
                  labelKey={option =>
                    `${option.facilityName}-${option.locationCode}`
                  }
                  minLength={3}
                  onChange={e => {
                    if (e.length > 0) {
                      updateDropModalForm(e[0].facilityName, 'facilityName');
                      updateDropModalForm(e[0].locationCode, 'locationCode');
                      updateDropModalForm(e[0].street, 'street');
                      updateDropModalForm(e[0].city, 'city');
                      updateDropModalForm(e[0].state, 'state');
                      updateDropModalForm(e[0].zipcode, 'zipcode');
                      updateDropModalForm(e[0].fields, 'fields');
                    } else {
                      updateDropModalForm(null, 'facilityName');
                    }
                  }}
                  onSearch={query => getLookUpSpLocation(query, userId, 'code')}
                  options={loadlookUpLocation}
                  renderMenuItemChildren={option => (
                    <span>
                      {option.facilityName}-{option.locationCode}
                      <br />
                      {option.street}
                      <br /> {option.city} {option.state},{option.zipcode}
                    </span>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="3">Arrival Location:</Label>
              <Col md="6">
                <Input
                  type="text"
                  value={SPTData.dropOffAddress.facilityName}
                  onChange={e => {
                    updateDropModalForm(e.target.value, 'facilityName');
                  }}
                />
              </Col>
              <Label md="1">Code:</Label>
              <Col md="2">
                <Input
                  type="text"
                  value={SPTData.dropOffAddress.locationCode}
                  onChange={e => {
                    updateDropModalForm(e.target.value, 'locationCode');
                  }}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Col md="3" />
              <Col md="6">
                <Input
                  type="text"
                  value={SPTData.dropOffAddress.street}
                  onChange={e => {
                    updateDropModalForm(e.target.value, 'street');
                  }}
                />
              </Col>
              <Label md="1">Phone:</Label>
              <Col md="2">
                <Input
                  type="tel"
                  value={SPTData.dropOffAddress.phone}
                  id="ArrivalPhone"
                  name="ArrivalPhone"
                  minLength="0"
                  maxLength="14"
                  onChange={e => updateDropModalForm(e.target.value, 'phone')}
                  placeholder="(xxx)xxx-xxxx"
                />
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Col md="3" />
              <Col md="3">
                <Input type="text" value={SPTData.dropOffAddress.city} />
              </Col>
              <Col md="1">
                <Input type="text" value={SPTData.dropOffAddress.state} />
              </Col>
              <Col md="2">
                <Input
                  type="text"
                  value={SPTData.dropOffAddress.zipcode}
                  onChange={e => {
                    updateDropModalForm(e.target.value, 'zipcode');
                  }}
                />
              </Col>
              <Col md="1">
                <Input
                  type="checkbox"
                  id="wheelchair"
                  name="wheelchair"
                  style={{ margin: '9px' }}
                />
              </Col>
              <Label for="wheelchair" style={{ margin: '4px' }}>
                Wheelchair
              </Label>
            </Row>
            <Row>
              <Col md="4">
                <Row style={{ padding: '3px' }}>
                  <Label md="6">Transport Class:</Label>
                  <Col md="6">
                    <Input
                      type="select"
                      name="transportClass"
                      id="transportClass"
                      value={SPTData.tripClass}
                      onChange={e =>
                        updateSptModalForm(e.target.value, 'tripClass')
                      }
                    >
                      {transportClassDropDown.map(value => {
                        return (
                          <option value={value.data}>{value.label}</option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row style={{ padding: '3px' }}>
                  <Label md="6">Trip Duration:</Label>
                  <Col md="4">
                    <Input
                      name="tripDurationHrs"
                      id="tripDurationHrs"
                      type="text"
                      min="1"
                      value={SPTData.tripDurationHrs}
                      onChange={e =>
                        updateSptModalForm(e.target.value, 'tripDurationHrs')
                      }
                    />
                  </Col>
                  <Label md="1.5">Hrs</Label>
                </Row>
                <Row style={{ padding: '3px' }}>
                  <Label md="6">Layover:</Label>
                  <Col md="4">
                    <Input
                      name="layOverHrs"
                      id="layOverHrs"
                      type="text"
                      min="1"
                      value={SPTData.tripLayoverHrs}
                      onChange={e =>
                        updateSptModalForm(e.target.value, 'tripLayoverHrs')
                      }
                    />
                  </Col>
                  <Label md="1.5">Hrs</Label>
                </Row>
              </Col>
              <Col md="4">
                <Row style={{ padding: '3px' }}>
                  <Col md="3">
                    <Input
                      name="tripDurationMin"
                      id="tripDurationMin"
                      type="text"
                      min="1"
                      value={SPTData.tripDurationMins}
                      onChange={e =>
                        updateSptModalForm(e.target.value, 'tripDurationMins')
                      }
                    />
                  </Col>
                  <Label md="1.5">Min</Label>
                </Row>
                <Row style={{ padding: '3px' }}>
                  <Col md="3">
                    <Input
                      name="layOverMin"
                      id="layOverMin"
                      type="text"
                      min="1"
                      value={SPTData.tripLayoverMins}
                      onChange={e =>
                        updateSptModalForm(e.target.value, 'tripLayoverMins')
                      }
                    />
                  </Col>
                  <Label md="1.5">Min</Label>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: '3px' }}>
              <Label md="2">Transport Cost:</Label>
              <Col md="2">
                <Input
                  name="transpCostAmt"
                  id="transportCost"
                  type="text"
                  min="1"
                  value={SPTData.transpCostAmt}
                  onChange={e =>
                    updateSptModalForm(e.target.value, 'transpCostAmt')
                  }
                />
              </Col>
              <Label md="2">Rebook Fee:</Label>
              <Col md="2">
                <Input
                  name="transpRebookFee"
                  id="rebookFee"
                  type="text"
                  min="1"
                  value={SPTData.transpRebookFee}
                  onChange={e =>
                    updateSptModalForm(e.target.value, 'transpRebookFee')
                  }
                />
              </Col>
              <Label md="2">Service Credit:</Label>
              <Col md="2">
                <Input
                  name="transpServiceCredit"
                  id="serviceCredit"
                  type="text"
                  min="1"
                  value={SPTData.transpServiceCredit}
                  onChange={e =>
                    updateSptModalForm(e.target.value, 'transpServiceCredit')
                  }
                />
              </Col>
            </Row>
            <div style={{ float: 'right', padding: '5px' }}>
              <Button type="button" onClick={validateFields}>
                Save
              </Button>
              <Button
                style={{ marginLeft: 5 }}
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const initialData = fast360Selectors.getFast360InitialData(state);

  return {
    loadlookUpLocation: initialData.loadlookUpLocation,
    sptDetailsList: state.FAST360.fast360Reducer.sptDetailsList
      ? state.FAST360.fast360Reducer.sptDetailsList
      : [],
  };
};

const mapDispatchToProps = dispatch => ({
  getLookUpSpLocation: (query, userId, fieldName) =>
    dispatch(fast360Operations.getLookUpSpLocation(query, userId, fieldName)),
  saveSPTLeg: data => dispatch(fast360Operations.saveSPTLeg(data)),
  updateSPTInfoTable: data => dispatch(fast360Actions.updateSPTInfoTable(data)),
  updateSptDetailList: data => dispatch(fast360Actions.updateSptDetailList(data)),
  sptsaveData: data => dispatch(fast360Actions.sptsaveData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fast360SPTDetailModal);
