import actionCreatorUtils from 'core/utils/actionCreatorUtils';

import constants, { BASICS_MODULE } from './constants';

const changeProfile = profileId => ({
  type: constants.CHANGE_PROFILE,
  payload: { profileId: profileId },
});

const openAdjusters = () => {
  return {
    type: constants.OPEN_MODAL,
  };
};

const addAdjuster = adjuster => {
  return {
    type: constants.ADD_ADJUSTER,
    payload: {
      id: adjuster.int_userid,
      value: adjuster.adjusterName,
      checked: false,
    },
  };
};

const cancelAdminUserInput = () => {
  return {
    type: constants.CANCEL_NEW_USER,
  };
};

const updateInput = (event, module) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const targetName = target.name;

  return {
    type: constants.UPDATE_USER_INPUT,
    payload: {
      objectName: module,
      targetName: targetName,
      value: value,
    },
  };
};

const updateUserClients = (availableClients, linkedClients) => {
  return {
    type: constants.UPDATE_USER_CLIENTS,
    payload: {
      availableClients: availableClients,
      linkedClients: linkedClients,
    },
  };
};

const updatePhoneInput = event => {
  const target = event.target;
  const targetName = target.name;
  const formattedNumber = formatPhoneNumber(target.value);

  return {
    type: constants.UPDATE_USER_INPUT,
    payload: {
      objectName: BASICS_MODULE,
      targetName: targetName,
      value: formattedNumber,
    },
  };
};

const formatPhoneNumber = value => {
  let phoneNumber = value;
  let formattedNumber;
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
  const areaCode = phoneNumber.substr(0, 3);
  const firstPart = phoneNumber.substr(3, 3);
  const secondPart = phoneNumber.substr(6, 4);
  if (areaCode.length === 0) {
    formattedNumber = '';
  } else if (areaCode.length < 3) {
    formattedNumber = '(' + areaCode;
  } else if (areaCode.length === 3 && firstPart.length < 3) {
    formattedNumber = '(' + areaCode + ') ' + firstPart;
  } else if (areaCode.length === 3 && firstPart.length === 3) {
    formattedNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
  }

  return formattedNumber;
};

const updateEntireObjectArray = (event, module, arrayName) => {
  const target = event.target;
  const value = target.checked;
  const payload = { checked: value };

  return {
    type: constants.UPDATE_ALL_CHECKBOXES,
    module: module,
    arrayName: arrayName,
    payload: payload,
  };
};

const updateObjectArray = (event, module, arrayName) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const array = target.name.split('_');
  const attribute = array.length !== 1 ? array.pop() : undefined;
  const id = array.join('_');
  const payload =
    attribute !== undefined ? { [attribute]: value } : { checked: value };
  const type =
    attribute === 'default'
      ? constants.UPDATE_DEFAULT_CHECKBOX
      : constants.UPDATE_DATA_TABLE;

  return {
    type: type,
    id: id,
    arrayName: arrayName,
    module: module,
    payload: payload,
  };
};

const updateAvailableFromLinked = (
  linkClients,
  availableClients,
  draggedItem
) => {
  const linkPayload = linkClients.filter(client => client !== draggedItem);
  const availablePayload = [...availableClients, draggedItem];

  return {
    type: constants.UPDATE_CLIENT_LISTS,
    payload: { linkClients: linkPayload, availableClients: availablePayload },
  };
};

const updateLinkedFromAvailable = (
  linkClients,
  availableClients,
  draggedItem
) => {
  const linkPayload = [...linkClients, draggedItem];
  const availablePayload = availableClients.filter(
    client => client !== draggedItem
  );

  return {
    type: constants.UPDATE_CLIENT_LISTS,
    payload: { linkClients: linkPayload, availableClients: availablePayload },
  };
};

const saveAdminUserForm = () => {
  return {
    type: constants.SAVE_ADMIN_USER_FORM,
  };
};

const billApprovalApplyToSelected = (thresholdAmt, linkClients) => {
  const linkPayload = linkClients.map(obj => {
    if (obj.checked) {
      obj.thresholdAmt = thresholdAmt;
    }

    return obj;
  });

  return {
    type: constants.APPLY_THRESHOLD_TO_SELECTED,
    payload: linkPayload,
  };
};

const updateUserTable = data => {
  const payload = data.map(obj => ({
    ...obj,
    name: obj.lastName + ', ' + obj.firstName,
  }));

  return {
    type: constants.UPDATE_USER_TABLE,
    payload: payload,
  };
};

const loadUserInformation = userInformation => {
  return actionCreatorUtils.create(
    constants.LOAD_USER_INFORMATION,
    userInformation
  );
};

const loadAdjusterMappingLists = adjusterMappingData => {
  return actionCreatorUtils.create(
    constants.LOAD_ADJUSTER_MAPING_INFORMATION,
    adjusterMappingData
  );
};

const updateLocationList  = data => {
  return actionCreatorUtils.create(
    constants.UPDATE_LOCATION_LIST,
    data,
  );
};

const loadAdjusterList = adjusterListData => {
  return actionCreatorUtils.create(
    constants.LOAD_ADJUSTER_LIST,
    adjusterListData
  );
};

const setAncilAccessPanel = value => {
  return {
    type: constants.SET_ACTIVE_ANCIL_ACCESS_PANEL,
    payload: {
      activeAccessPanel: value,
    },
  };
};

const updateAncilAccess = (event, dataTableId) => {
  const target = event.target;
  const value = target.checked;

  return {
    type: constants.UPDATE_ANCIL_ACCESS,
    dataTableId: dataTableId,
    id: target.name,
    payload: value,
  };
};

const removeItemsFromAdjusterLists = (
  adjusterList,
  billList,
  claimnumberList,
  adjusterDelete,
  billDelete,
  claimDelete,
) => {
  const selectedAdjustersRemoved = adjusterList.filter(
    adjuster => !adjuster.checked
  );
  const selectedBillsRemoved = billList.filter(bill => !bill.checked);
  const selectedClaimnumbersRemoved = claimnumberList.filter(
    claim => !claim.checked
  );
  const adjusterDeleted = adjusterList.filter(
    adjuster => adjuster.checked
  )
  const billsDeleted = billList.filter(bill => bill.checked);
  const claimDeleted = claimnumberList.filter(
    claim => claim.checked
  );

  const payload = {
    adjusterList: selectedAdjustersRemoved,
    billList: selectedBillsRemoved,
    claimnumberList: selectedClaimnumbersRemoved,
    adjusterDelete: adjusterDelete != null ? adjusterDelete.concat(adjusterDeleted) : adjusterDeleted,
    claimDelete: claimDelete != null ? claimDelete.concat(claimDeleted) : claimDeleted,
    billDelete: billDelete != null ? billDelete.concat(billsDeleted) : billsDeleted,
  };

  return {
    type: constants.LOAD_ADJUSTER_MAPING_INFORMATION,
    payload: payload,
  };
};

export default {
  addAdjuster,
  billApprovalApplyToSelected,
  cancelAdminUserInput,
  changeProfile,
  openAdjusters,
  saveAdminUserForm,
  updateInput,
  updateAvailableFromLinked,
  updateUserClients,
  updateLinkedFromAvailable,
  updateObjectArray,
  updateEntireObjectArray,
  updateUserTable,
  loadUserInformation,
  loadAdjusterMappingLists,
  loadAdjusterList,
  setAncilAccessPanel,
  updateAncilAccess,
  updatePhoneInput,
  formatPhoneNumber,
  removeItemsFromAdjusterLists,  
  updateLocationList,
};
