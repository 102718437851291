import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { assign } from 'lodash';
import { commonSelectors, commonOperations } from 'common/store';
import { authSelectors } from 'modules/auth/store';
import AppTable from 'shared/components/table/AppTable';

import { billOperationsSelectors } from '../../../bill-operations/store';
import {
  billMaintenanceActions,
} from '../../store';

import {
  billMaintenanceOperations,
  billMaintenanceSelectors,
} from '../../store';

class GroupMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      findGroupText: '',
      groupName: '',
      db: 'Attenta',
      client: '',
      siteTop: 0,
      id: '',
      linkedUsers: [],
      body: {
        profile: this.props.profile,
        username: this.props.userName,
        zebraUserId: this.props.userId,
      },
      sortUserBy: 'Linked Users',
      userUpArrow: true,
      selectedGroupItem: {}
    };
  }

  componentDidMount() {
    this.getInitialSLPProfilesData(this.state.body);
  }

  getInitialSLPProfilesData = body => {
    this.props.getInitialSLPProfilesData(Object.assign({}, body));
  };

  addNewBlankGroup = () => {
    this.props.addNewBlankGroup();
  };

  openGroup = item => {
    const group = item.fields || '';
    item.fields && this.setState({selectedGroupItem: item.fields});
    this.setState({
      groupName: group.description || '',
      db: group.profile || '',
      client: group.clientname || '',
      siteId: group.sitetop || '',
      siteTop: group.sitetop || '',
      id: group.id || 0,
    });
    if (group.id === 0) {
      this.props.resetGroupDetails();
      this.setState({selectedGroupItem: {}});
      return;
    }
    const body = {
      ...this.state.body,
      groupId: group.id,
    };
    this.props.openGroupDetails(body);
  };

  findGroup = () => {
    const body = {
      ...this.state.body,
      groupName: this.state.findGroupText,
    };
    this.props.lookUpGroupRecords(body);
  };

  buildSLPProfiles = () => {
    return this.props.sLPProfilesArray.map(item => (
      <option key={item.chr_profile} value={item.chr_profile}>
        {item.chr_databasename}
      </option>
    ));
  };

  renderGroupColumn = item => {
    const fields = item.fields || {};
    const groupId = fields.id || 0;
    const description = fields.description || '';
    return `${groupId} - ${description}`;
  };

  saveGroup = async () => {
    const {selectedGroupItem} = this.state;
if (selectedGroupItem.description === this.state.groupName && selectedGroupItem.clientname === this.state.client &&
  selectedGroupItem.profile === this.state.db && selectedGroupItem.sitetop === this.state.siteTop ) {
    this.props.alert('There are no changes to save');
  } else if(this.state.groupName === '' || this.state.client === '' || 
  this.state.siteTop === ''){
   this.props.alert('Please enter all the required values before saving')
  } else {
    let body = {
      ...this.state.body,
      id: this.state.id,
      siteId: this.state.siteTop,
      description: this.state.groupName,
      profileId: this.state.db ?  this.state.db : 'Attenta',
      siteTop: this.state.siteTop,
      groupDB: this.state.db ?  this.state.db : 'Attenta',
      zebraUserId: this.props.userId,
      username: this.props.userName,
      clientname: this.state.client
    };

    await this.props.saveGroup(body);
    let lookUpBody = {
      ...this.state.body,
      groupName: this.state.findGroupText,
    };
    await this.props.lookUpGroupRecords(lookUpBody); 
  } 
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value === "Forte" ? "Attenta" : e.target.value,
    });
  };

  disableSave = () => {
    if (this.state.groupName == '' && this.state.db == '' && this.state.client == '') {
      return true; 
    }
  }

  disableSaveWhenSelect = () => {
    var c=0;
    Object.keys(this.props.groups).forEach(key => {
      var fields = [];
      fields.push(this.props.groups[key].fields);
        fields.forEach(x => {
          if(x.description === this.state.groupName || x.profile === this.state.db || x.clientname === this.state.client) {
            c++;
          }
        });
    });
    if(c>0) {
      return true;
    }
    else 
    {
      return false;
    }
  };

  onSiteNameSearchKeyPressHandler = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      this.state.client = event.target.value;
    this.findGroupClients();
  };
};

findGroupClients() {
   // const { profile } = this.props;
    const { client } = this.state;
    
    this.props.findGroupClients({
      profile: this.props.profile,
      db: this.state.db ?  this.state.db : 'Attenta',
      siteName: client,
      siteLookUpByDB: true,
      zebraUserId: this.props.userId,
    });
  }

  handleFormFields = (event) => {
    const selectedClient = this.props.groupClients && this.props.groupClients.find(obj => obj.chr_sitename === event.chr_sitename);
    if (selectedClient === null || selectedClient === undefined) {
      this.setState({client:  '',siteId: '',siteTop: '' })
    } else {
      this.setState({client:  selectedClient.chr_sitename, siteId: selectedClient.int_sitecode, siteTop: selectedClient.int_sitecode })
    } 
    this.props.resetGroupClients();
  };


  render() {
    const options = this.props.groupClients.length ? this.props.groupClients.map((obj) =>
      assign(obj, {
        label:obj.chr_sitename,
        value: obj.int_sitecode
      })
    ): [];
    return (
      <div id="group-maintenance-wrapper">
        <Row>
          <Col md={4}>
            <Row className="mb-1">
              <Col md={7}>
                <Input
                  placeholder="Find Client Records"
                  type="text"
                  onChange={this.onInputChange}
                  name="findGroupText"
                  title="Find Client Records"
                />
              </Col>
              <Col>
                <Button color="secondary" onClick={this.addNewBlankGroup}>
                  +
                </Button>
                <Button
                  className="ml-1"
                  color="secondary"
                  onClick={this.findGroup}
                  disabled={this.props.isLoading}
                >
                  FIND
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="client-records-table">
                  <AppTable
                    resizable
                    columns={[
                      {
                        header: 'Group',
                        field: 'chr_sitename',
                        render: item => this.renderGroupColumn(item),
                      },
                    ]}
                    data={this.props.groups}
                    autoPagination={true}
                    rowClickHandler={this.openGroup}
                    sortAble={true}
                    sortImmediately={false}
                  ></AppTable>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <FormGroup row>
              <Col md={1}>
                <Label className="col-form-label" htmlFor="groupName">
                  Name:
                </Label>
              </Col>
              <Col>
                <Input
                  name="groupName"
                  type="text"
                  value={this.state.groupName}
                  onChange={this.onInputChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={1}>
                <Label className="col-form-label" htmlFor="db">
                  DB:
                </Label>
              </Col>
              <Col>
                <Input
                  type="select"
                  name="db"
                  className="custom-select"
                  disabled={this.props.isLoading || this.state.id === ''}
                  value={this.state.db}
                  onChange={this.onInputChange}
                >
                  {this.buildSLPProfiles()}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={1}>
                <Label className="col-form-label" htmlFor="client">
                  Client:
                </Label>
              </Col>
              <Col>
                {/* <Input
                  name="client"
                  type="text"
                  value={this.state.client}
                  onChange={this.onInputChange}
                /> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  onChange={(e) =>
                    this.handleFormFields(e)
                  }
                  //onChange={this.onInputChange}
                  placeholder="Find Client Records"
                  menuPlacement="bottom"
                  onKeyDown={e => this.onSiteNameSearchKeyPressHandler(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  //menuIsOpen={fromExternal.fromExternal ? false : zipList.length}
                  menuIsOpen={this.props.groupClients.length > 0}
                  // value={this.state.client}
                  value={
                    this.state.client
                      ? [
                        {
                          value: this.state.client,
                          label: this.state.client,
                        },
                      ]
                      : []
                  }

                  closeMenuOnSelect={true}
                  options={options}
                  name="client"
                  id="client"
                />
              </Col>
            </FormGroup>
            <Row>
              <Col>
                <AppTable
                  columns={[
                    {
                      header: 'Linked Users',
                      rowKey: true,
                      field: 'linkeduser',
                    },
                    {
                      header: 'Threshold Amt',
                      field: 'thresholdamt',
                    },
                  ]}
                  data={this.props.linkedUsers}
                  autoPagination={true}
                  striped={true}
                  sortAble={true}
                  sortImmediately={false}
                  resizable={true}
                ></AppTable>
              </Col>
            </Row>
          </Col>
          <Col md={1}>
            <Button
             // disabled={this.disabledValue()}
              color="primary"
              onClick={this.saveGroup}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

GroupMaintenance.propTypes = {
  sLPProfilesArray: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  linkedUsers: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  saveGroupResult: PropTypes.array.isRequired,
  findGroupClients: PropTypes.func.isRequired,
  resetGroipClients: PropTypes.func,
  resetGroupDetails: PropTypes.func,
  groupClients: PropTypes.array.isRequired,
};
const mapStateToProps = state => {
  const groupMaintenance = billMaintenanceSelectors.getGroupMaintenance(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const appLoading = commonSelectors.appLoading(state);
  const clientMaintenanceInitialData = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const user = authSelectors.getUser(state);
  return {
    sLPProfilesArray: groupMaintenance.sLPProfilesArray,
    isLoading: appLoading.loading,
    groups: groupMaintenance.groups,
    linkedUsers: groupMaintenance.linkedUsers,
    profile: billReviewInitialData.currentProfile,
    userName: initialData.userName,
    saveGroupResult: groupMaintenance.saveGroupResult,
    groupClients: clientMaintenanceInitialData.groupClients,
    userId: user && user.user_id,
  };
};
const mapDispatchToProps = dispatch => ({
  getInitialSLPProfilesData: body =>
    dispatch(billMaintenanceOperations.getSLPProfilesForBillMaintenance(body)),
  lookUpGroupRecords: body =>
    dispatch(billMaintenanceOperations.lookUpGroups(body)),
  addNewBlankGroup: () => dispatch(billMaintenanceOperations.addBlankGroup()),
  findGroupClients: data => dispatch(billMaintenanceOperations.findGroupClients(data)),
  resetGroupClients: () => dispatch(billMaintenanceActions.resetGroupClients()),
  resetGroupDetails: () => dispatch(billMaintenanceActions.resetGroupDetails()),
  saveGroup: body => dispatch(billMaintenanceOperations.saveGroup(body)),
  openGroupDetails: body => dispatch(billMaintenanceOperations.openGroup(body)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupMaintenance);
