import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import * as constants from '../constants';

import reducer from './reducers';

reducerRegistry.register(constants.moduleName, reducer);

export { default as authSelectors } from './selectors';
export { default as authActions } from './actions';

export default reducer;
