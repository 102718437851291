import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const NewAttorneyModal = ({
  isOpen,
  onClose,
  userId,
  userRole,
  getAttorneyList,
  attorneyInfo,
  updatedAttorneyInfo,
  updatedClaimInfo,
  resetSearchAttorneyTable,
  getLoadAttorney,
  getLoadClaim,
  loadAttorneyInfo,
  zipList,
  getZipList,
  resetZipList,
  resetLoadAttorney,
  saveAttorney,
  attorneyType,
  alert,
  isNew,
  setIsExternal,
  setEttorneyEdited,
  setAttData,
  isFromLanding,
  loadInfoFromExistingPatient,
  fromWorklist,
  worklistData,
  fromExistingWorkList,
  logBreadCrumbs
}) => {
  const [isOpenLoadAttorneyModal, setIsOpenLoadAttorneyModal] = useState(
    false
  );
  const [isOpenSeachAttorney, setIsOpenSeachAttorney] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [attValidLastName, setAttValidLastName] = useState(false);
  const [atttvalidFirmName, setAttValidFirmName] = useState(false);
  const [searchFields, setSearchFields] = useState({
    attorneyLastName: '',
    attorneyFirstName: '',
    attorneyFirmName: '',
  });
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [index, setIndex] = useState(undefined);
  const [formAttorneyFields, setFormAttorneyFields] = useState({
    attFirstName: (!isFromLanding && loadInfoFromExistingPatient?.people?.first) || '',
    attLastName: (!isFromLanding && loadInfoFromExistingPatient?.people?.last) || '',
    attFirmName: (!isFromLanding && loadInfoFromExistingPatient?.firmName) || '',
    attEmail: '',
    attPhone: '',
    attPhoneExt: '',
    attFax: '',
    attAddress: '',
    attAddress2: '',
    attAttorneyType: null,
    attCity: '',
    attCounty: '',
    attState: '',
    attZip: '',
    attNotes: '',
    attZipCodeId: '',
    attZipId: '',
    attTimeZone: '',
  });

  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        setIsOpenSeachAttorney(true);
      } else {
        setIsOpenLoadAttorneyModal(true);
      }
    }
  }, [isOpen]);
  useEffect(() => {
    if (!formAttorneyFields.attLastName)
      setAttValidLastName(false);
    else setAttValidLastName(true);
    if (!formAttorneyFields.attFirmName)
      setAttValidFirmName(false);
    else setAttValidFirmName(true);
    setAttData(formAttorneyFields);

  }, [formAttorneyFields])

  useEffect(() => {
    if (((updatedClaimInfo?.claim?.id > 0) || (fromWorklist && worklistData?.claim?.id > 0)) && !isEmpty(updatedAttorneyInfo)) {
      const claimParams = {
        userId,
        functionName: 'loadClaim',
        claimsId: updatedClaimInfo?.claim?.id || worklistData?.claim?.id,
      };
      getLoadClaim(claimParams);
    }
  }, [updatedAttorneyInfo])

  useEffect(() => {
    if (isOpenLoadAttorneyModal) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENATTORNEY',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb)
      if (selectedItem) {
        const params = {
          userId,
          functionName: 'loadAttorney',
          id: selectedItem.PK_attorney_id,
        };
        getLoadAttorney(params);
      } else if (!isNew && !attorneyInfo) {
        if (!isEmpty(updatedAttorneyInfo) && updatedAttorneyInfo.id !== 0) {
          const params = {
            userId,
            functionName: 'loadAttorney',
            id: updatedAttorneyInfo.id,
          };
          getLoadAttorney(params);
          const pplCheck =
            (updatedAttorneyInfo &&
              updatedAttorneyInfo.people) ||
            {};
          const adrCheck =
            (updatedAttorneyInfo &&
              updatedAttorneyInfo.address) ||
            {};
          setFormAttorneyFields({
            attfirstName:
              updatedAttorneyInfo &&
              updatedAttorneyInfo.first,
            attLastName:
              updatedAttorneyInfo &&
              updatedAttorneyInfo.last,
            attFirmName:
              updatedAttorneyInfo &&
              updatedAttorneyInfo.firmName,
            attAttorneyType: updatedAttorneyInfo && updatedAttorneyInfo.attorneyTypeId,
            attEmail: pplCheck.email1,
            attNotes: updatedAttorneyInfo.comments,
            attPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            attPhoneExt: pplCheck && pplCheck.phoneExt,
            attFax: pplCheck && pplCheck.fax && formatPhoneNumber(pplCheck.fax),
            attAddress: adrCheck.street1,
            attZipCodeId: adrCheck.zipCodeId,
            attZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            attTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            attAddress2: adrCheck.street2,
            attCity: adrCheck.zipCode && adrCheck.zipCode.city,
            attCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            attState: adrCheck.zipCode && adrCheck.zipCode.state,
            attZip: adrCheck.zipCode && adrCheck.zipCode.zip,
          });
        }
      }
    }
  }, [isOpenLoadAttorneyModal]);

  useEffect(() => {
    if (!isEmpty(loadAttorneyInfo)) {
      const pplCheck = loadAttorneyInfo.people || {};
      const adrCheck = loadAttorneyInfo.address || {};
      setFormAttorneyFields({
        attFirstName: pplCheck.first,
        attLastName: pplCheck.last,
        attFirmName: loadAttorneyInfo.firmName,
        attAttorneyType: loadAttorneyInfo.attorneyTypeId,
        attNotes: loadAttorneyInfo.comments,
        attFax: pplCheck && pplCheck.fax && formatPhoneNumber(pplCheck.fax),
        attEmail: pplCheck.email1,
        attPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        attPhoneExt: pplCheck && pplCheck.phoneExt,
        attAddress: adrCheck.street1,
        attZipCodeId: adrCheck.zipCodeId,
        attZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        attTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        attAddress2: adrCheck.street2,
        attCity: adrCheck.zipCode && adrCheck.zipCode.city,
        attCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        attState: adrCheck.zipCode && adrCheck.zipCode.state,
        attZip: adrCheck.zipCode && adrCheck.zipCode.zip,
      });
    }
  }, [loadAttorneyInfo]);

  const loadAttorneyInfoToggle = () => {
    setIsOpenLoadAttorneyModal(!isOpenLoadAttorneyModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormAttorneyFields({ ...formAttorneyFields, attLastName: '', attFirmName: '', attAttorneyType: '' })
    }
  };

  useEffect(() => {
    let fname = '', lname = '', firmName = '';
    if (!isFromLanding && isEmpty(updatedAttorneyInfo) && !isEmpty(loadInfoFromExistingPatient)) {
      fname = loadInfoFromExistingPatient?.people?.first || ''
      lname = loadInfoFromExistingPatient?.people?.last || ''
      firmName = loadInfoFromExistingPatient?.firmName || ''
    } else {
      if (fromWorklist && worklistData) {
        if (fromExistingWorkList?.people?.first)
          fname = fromExistingWorkList?.people?.first
        if (fromExistingWorkList?.people?.last)
          lname = fromExistingWorkList?.people?.last
        if (fromExistingWorkList?.firmName)
          firmName = fromExistingWorkList?.firmName
      }
    }
    if (isOpen) {
      setSearchFields({
        attorneyFirstName: fname,
        attorneyLastName: lname,
        attorneyFirmName: firmName,
      });
      const payload = {
        attorneyFirstName: fname,
        attorneyLastName: lname,
        attorneyFirmName: firmName,
        userId,
      };
      (fname || lname) && getAttorneyList(payload);
      // (!isFromLanding && loadInfoFromExistingPatient) && getAdjusterList(payload);
    }

  }, [isOpen])

  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.claim?.attorney?.id) {
        const item = attorneyInfo && attorneyInfo.filter(o => o.PK_attorney_id === worklistData?.claim?.attorney?.id);
        let index = attorneyInfo && attorneyInfo.findIndex(i => i.PK_attorney_id === worklistData?.claim?.attorney?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [attorneyInfo])

  const searchAttorneyToggle = () => {
    setIsOpenSeachAttorney(!isOpenSeachAttorney);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      attorneyLastName: '',
      attorneyFirstName: '',
      attorneyFirmName: '',
    });
    resetSearchAttorneyTable();
    resetLoadAttorney();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };
  const setMap = {
    attLastName: setAttValidLastName,
    attFirmName: setAttValidFirmName,
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "attPhone") {
      value = formatPhoneNum(value);
    }
    setFormAttorneyFields({ ...formAttorneyFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };


  const isDisabledInput = () => {
    return userRole === 'Administrator' ? false : true;
  };

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchAttorneyTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'Firm Name',
              field: 'chr_firmname',
            },
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            }
          ]}
          data={attorneyInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-attorney-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadAttorneyModal = () => {
    setIsOpenSeachAttorney(false);
    setIsOpenLoadAttorneyModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getAttorneyList(params);
  };

  const onChangeCurrentAttorney = () => {
    setSearchFields({
      attorneyLastName: '',
      attorneyFirstName: '',
      attorneyFirmName: '',
    });
    setIsOpenLoadAttorneyModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachAttorney(true);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormAttorneyFields({
        ...formAttorneyFields,
        attCity: selectedZip.chr_city,
        attState: selectedZip.chr_state,
        attCounty: selectedZip.chr_county,
        attZip: selectedZip.chr_zipcode,
        attZipCodeId: selectedZip.code_id,
        attZipId: selectedZip.code_id,
        attTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVEATTORNEY',
      refTableId: loadAttorneyInfo.id,
      refId: 0,
      refTable: 'ATT',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem) {
      const response = loadAttorneyInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formAttorneyFields.attFirstName;
      response.people.last = formAttorneyFields.attLastName;
      response.people.email1 = formAttorneyFields.attEmail;
      response.people.fax = formAttorneyFields.attFax &&
        formAttorneyFields.attFax.replace(/[\s()-]/g, '');
      response.people.phone =
        formAttorneyFields.attPhone &&
        formAttorneyFields.attPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formAttorneyFields.attPhoneExt;
      response.address.street1 = formAttorneyFields.attAddress;
      response.address.street2 = formAttorneyFields.attAddress2;
      response.address.zipCode.city = formAttorneyFields.attCity;
      response.address.zipCode.county = formAttorneyFields.attCounty;
      response.address.zipCode.state = formAttorneyFields.attState;
      response.address.zipCode.zip = formAttorneyFields.attZip;
      response.address.zipCode.id = formAttorneyFields.attZipId;
      response.address.zipCodeId = formAttorneyFields.attZipCodeId;
      response.firmName = formAttorneyFields.attFirmName;
      response.attorneyTypeId = Number(formAttorneyFields.attAttorneyType);
      response.comments = formAttorneyFields.attNotes;
      const attorneyId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        attorneyId,
        claimId,
      };
      if ((response.firmName === '' || response.firmName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Firm name or Attorney name in order to save.');
        return;
      }
      if (response.attorneyTypeId === null || response.attorneyTypeId === undefined || response.attorneyTypeId === 0) {
        alert('You must provide an Attorney Type in order to save.');
        return;
      }
      saveAttorney(params);
      loadAttorneyInfoToggle();
    } else if (!isNew && !attorneyInfo) {
      const response = updatedAttorneyInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formAttorneyFields.attFirstName;
      response.people.last = formAttorneyFields.attLastName;
      response.people.fax = formAttorneyFields.attFax &&
        formAttorneyFields.attFax.replace(/[\s()-]/g, '');
      response.people.email1 = formAttorneyFields.attEmail;
      response.people.phone =
        formAttorneyFields.attPhone &&
        formAttorneyFields.attPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formAttorneyFields.attPhoneExt;
      response.address.street1 = formAttorneyFields.attAddress;
      response.address.street2 = formAttorneyFields.attAddress2;
      response.address.zipCode.city = formAttorneyFields.attCity;
      response.address.zipCode.county = formAttorneyFields.attCounty;
      response.address.zipCode.state = formAttorneyFields.attState;
      response.address.zipCode.zip = formAttorneyFields.attZip;
      response.address.zipCode.id = formAttorneyFields.attZipId;
      response.address.zipCodeId = formAttorneyFields.attZipCodeId;
      response.firmName = formAttorneyFields.attFirmName;
      response.attorneyTypeId = Number(formAttorneyFields.attAttorneyType);
      response.comments = formAttorneyFields.attNotes;
      const attorneyId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        attorneyId,
        claimId,
      };
      if ((response.firmName === '' || response.firmName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Firm name or Attorney name in order to save.');
        return;
      }
      if (response.attorneyTypeId === null || response.attorneyTypeId === undefined || response.attorneyTypeId === 0) {
        alert('You must provide an Attorney Type in order to save.');
        return;
      }
      saveAttorney(params);
      loadAttorneyInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.comments = formAttorneyFields.attNotes;
      response.firmName = formAttorneyFields.attFirmName;
      response.attorneyTypeId = Number(formAttorneyFields.attAttorneyType);
      response.people = {
        first: formAttorneyFields.attFirstName,
        last: formAttorneyFields.attLastName,
        fax: formAttorneyFields.attFax &&
          formAttorneyFields.attFax.replace(/[\s()-]/g, ''),
        email1: formAttorneyFields.attEmail,
        phone:
          formAttorneyFields.attPhone &&
          formAttorneyFields.attPhone.replace(/[\s()-]/g, ''),
        phoneExt: formAttorneyFields.attPhoneExt,
      };
      response.address = {
        street1: formAttorneyFields.attAddress,
        street2: formAttorneyFields.attAddress2,
        zipCodeId: formAttorneyFields.attZipCodeId,
        zipCode: {
          city: formAttorneyFields.attCity,
          county: formAttorneyFields.attCounty,
          state: formAttorneyFields.attState,
          zip: formAttorneyFields.attZip,
          id: formAttorneyFields.attZipId,
          timeZone: formAttorneyFields.attTimeZone,
        },
      };
      if ((response.firmName === '' || response.firmName === undefined)
        && ((response?.people?.last === '' || response?.people?.last === undefined) ||
          (response?.people?.first === '' || response?.people?.first === undefined))) {
        alert('You must provide a Firm name or Attorney name in order to save.');
        return;
      }
      if (response.attorneyTypeId === null || response.attorneyTypeId === undefined || response.attorneyTypeId === 0) {
        alert('You must provide an Attorney Type in order to save.');
        return;
      }
      const attorneyId = response.id;
      let claimId;
      claimId = response.claimId;
      if (claimId === undefined) {
        claimId = updatedClaimInfo?.claim?.id || worklistData?.claim?.id || 0;
      }
      const params = {
        response,
        userId,
        attorneyId,
        claimId,
      };
      saveAttorney(params);
      loadAttorneyInfoToggle();
    }
    // setIsExternal();
    setEttorneyEdited(true)
  };

  const getBody = () => {
    if (attorneyInfo && attorneyInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new Attorney as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new Attorney?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setFormAttorneyFields({
      attFirstName: (!isFromLanding && loadInfoFromExistingPatient?.people?.first) || '',
      attLastName: (!isFromLanding && loadInfoFromExistingPatient?.people?.last) || '',
      attFirmName: (!isFromLanding && loadInfoFromExistingPatient?.firmName) || '',
      attEmail: '',
      attPhone: '',
      attPhoneExt: '',
      attFax: '',
      attAddress: '',
      attAddress2: '',
      attAttorneyType: null,
      attCity: '',
      attCounty: '',
      attState: '',
      attZip: '',
      attNotes: '',
      attZipCodeId: '',
      attZipId: '',
      attTimeZone: '',
    });
  };

  const onChangeHandlerAttorneyType = (id, value) => {
    setFormAttorneyFields({ ...formAttorneyFields, [id]: value });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadAttorneyModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadAttorneyModal}>
        <ModalHeader toggle={loadAttorneyInfoToggle}>
          ADD A NEW ATTORNEY
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={4}>
              <Label for="attFirstName">First Name</Label>
              <Input
                type="text"
                id="attFirstName"
                value={formAttorneyFields.attFirstName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !attorneyInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={4}>
              <Label for="attLastName">Last Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="attLastName"
                value={formAttorneyFields.attLastName || ''}
                className={attValidLastName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !attorneyInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={4}>
              <Label for="attFirmName">Firm Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="attFirmName"
                value={formAttorneyFields.attFirmName || ''}
                className={atttvalidFirmName ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !attorneyInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
          </FormGroup>
          <Col style={{ top: '-8px', color: 'red', paddingLeft: 0 }}>
            {' '}
            {(selectedItem || (!isNew && !attorneyInfo)) && (
              <span>
                {isDisabledInput()
                  ? 'Please contact Administrator if you want to change a Attorney Name'
                  : 'Warning: Changing the name on this record will also change it on any referral that this record is linked to!'}
              </span>
            )}
          </Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="attEmail">Attorney Email</Label>
              <Input
                type="text"
                id="attEmail"
                value={formAttorneyFields.attEmail || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="attPhone">Attorney Phone#</Label>
              <Input
                type="tel"
                id="attPhone"
                value={formAttorneyFields.attPhone || ''}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="attPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="attPhoneExt"
                value={formAttorneyFields.attPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="attFax">Fax#</Label>
              <Input
                type="text"
                id="attFax"
                value={formAttorneyFields.attFax || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="attAddress">Attorney Address</Label>
              <Input
                type="text"
                id="attAddress"
                value={formAttorneyFields.attAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="attAddress2">Attorney Address 2</Label>
              <Input
                type="text"
                id="attAddress2"
                value={formAttorneyFields.attAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="attAttorneyType">Attorney Type</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <AppInput
                changeHandler={onChangeHandlerAttorneyType}
                option={{
                  value: 'id',
                  displayValue: 'value',
                }}
                placeholder="Choose..."
                value={formAttorneyFields.attAttorneyType || ''}
                className={formAttorneyFields.attAttorneyType ? '' : "error"}
                data={attorneyType}
                id="attAttorneyType"
                error={formAttorneyFields.attAttorneyType ? false : true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="attCity">Attorney City</Label>
              <Input
                type="text"
                id="attCity"
                value={formAttorneyFields.attCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="attState">State</Label>
              <Input
                type="text"
                id="attState"
                value={formAttorneyFields.attState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="attCounty">County</Label>
              <Input
                type="text"
                id="attCounty"
                value={formAttorneyFields.attCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="attZip">Attorney Zip</Label>
              <Input
                type="text"
                id="attZip"
                value={formAttorneyFields.attZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="attZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'attZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="attZip"
                id="attZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label for="attNotes">Comments</Label>
              <Input
                type="textarea"
                id="attNotes"
                value={formAttorneyFields.attNotes || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentAttorney}>
              CHANGE CURRENT ATTORNEY
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={isOpenSeachAttorney}>
        <ModalHeader toggle={searchAttorneyToggle}>
          SEARCH ATTORNEY
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="attorneyFirstName">First Name</Label>
                  <Input
                    type="text"
                    id="attorneyFirstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.attorneyFirstName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="attorneyLastName">Last Name</Label>
                  <Input
                    type="text"
                    id="attorneyLastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.attorneyLastName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="attorneyFirmName">Firm Name</Label>
                  <Input
                    type="text"
                    id="attorneyFirmName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.attorneyFirmName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchAttorneyTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!attorneyInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadAttorneyModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
    </>
  );
};

NewAttorneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  attorneyInfo: PropTypes.array,
  getAttorneyList: PropTypes.func,
  resetSearchAttorneyTable: PropTypes.func,
  getLoadAttorney: PropTypes.func,
  loadAttorneyInfo: PropTypes.object,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadAttorney: PropTypes.func,
  saveAttorney: PropTypes.func,
  alert: PropTypes.func
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);

  return {
    attorneyInfo: fast360Info.attorneyInfo,
    loadAttorneyInfo: fast360Info.loadAttorneyInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    updatedAttorneyInfo: fast360Info.updatedAttorneyInfo,
    attorneyType: initialData.attorneyType,
    updatedClaimInfo: fast360Info.updatedClaimInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  getAttorneyList: data => dispatch(fast360Operations.getAttorneyList(data)),
  getLoadAttorney: data => dispatch(fast360Operations.getLoadAttorney(data)),
  getLoadClaim: data => dispatch(fast360Operations.getLoadClaim(data)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchAttorneyTable: () =>
    dispatch(fast360Actions.resetSearchAttorneyTable()),
  resetLoadAttorney: () => dispatch(fast360Actions.resetLoadAttorney()),
  saveAttorney: data => dispatch(fast360Operations.saveAttorney(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAttorneyModal);
