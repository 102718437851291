import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Form, FormGroup, Input } from 'reactstrap';

import { commonOperations } from 'common/store';
import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import SearchableSelect from 'shared/components/input/SearchableSelect';
import AppInput from 'shared/components/input/AppInput';
import DropdownMultiSelect from 'shared/components/dropdown-multi-select/dropdown-multi-select';
import ManageBillsModal from './ManageBillsModal';
import AddManageBillsModal from './AddManageBillsModal';
import * as billOperationsConstants from '../store/constants';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

class FindBillsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientSelected: false,
      searchType: 'BILL',
      searchMode: '',
      searchFields: {
        claimNumber: '',
        billScan: '',
        end: '',
        client: '',
        state: '',
        stateName: '',
        billTypes: '',
        externalId: '',
        billStatus: '',
        processor: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        siteId: '',
      },
      isManageBillsModal: false,
      plusClicked: false,
      minusClicked: false,
      editClicked: false,
      isOpenManageBillModal: false,
    };
  }

  onClearAll = () => {
    let searchFieldsObj = {
      claimNumber: '',
      billScan: '',
      end: '',
      client: '',
      state: "",
      stateName: "",
      billTypes: '',
      externalId: '',
      billStatus: '',
      processor: '',
      entryDate: '',
      tin: '',
      lastNameOrSsn: '',
      firstName: '',
      siteId: '',
    }
    document.getElementById("client").value = '';
    this.setState({searchFields: searchFieldsObj});
  }


  onFormSubmit = e => {
    e.preventDefault();
    this.props.changeSearchType(this.state.searchType);
    if (
      this.state.searchFields.claimNumber === '' &&
      this.state.searchFields.billScan === ''
    ) {
      this.props.alert('Enter a Claim Number or Bill / Scan.');
    } else if( parseInt(this.state.searchFields.end)- parseInt(this.state.searchFields.billScan) > 1000)
    {
      this.props.alert('The bill ID range must not exceed 1000 difference.');
    }
    else {
      const searchFields = {
        ...this.state.searchFields,
        userId: this.props.currentUserId,
        profile: this.props.profile,
      };

      this.props.updateSearchFields(searchFields);

      switch (this.state.searchType) {
        case billOperationsConstants.CLAIM:
          this.props.findClaims(searchFields);
          break;
        case billOperationsConstants.BILL:
          this.props.findBills(searchFields);
          break;
        default:
      }
    }
  };

  updateState = (field, value) => {

    /*
    const match = this.props.clients.filter(object => {
      return object["int_sitecode"] === value;
    });
    */

    this.setState({
      searchFields: {
        ...this.state.searchFields,
        siteId: value,
        //client: match[0]["chr_sitename"],
      },
    });
  };

  onAutocompleteChange = (id, value) => {
    this.setState({
      searchFields: {
        ...this.state.searchFields,
        [`${id}Name`]: value,
      }
    });
  };

  onAutocompleteSelect = (id, displayValue, value) => {
    this.setState({
      searchFields: {
        ...this.state.searchFields,
        [`${id}Name`]: displayValue,
        [id]: value,
      }
    });
  };

  updateStateHandler = data => {
    if (data !== null) {
      this.setState({
        searchFields: {
          ...this.state.searchFields,
          state: data.abbreviation,
        },
      });
    } else {
      this.setState({
        searchFields: {
          ...this.state.searchFields,
          state: '',
        },
      });
    }
  };

  onChangeHandler = (targetId, value) => {
    value = value === 'default' ? '' : value;

    if (targetId === billOperationsConstants.CLAIM_NUMBER) {
      this.setState({
        searchType: billOperationsConstants.CLAIM,
        searchFields: {
          ...this.state.searchFields,
          claimNumber: value,
          billScan: '',
          end: '',
        },
      });
    } else if (
      targetId === billOperationsConstants.BILL_SCAN ||
      targetId === billOperationsConstants.END
    ) {
      this.setState({
        searchType: billOperationsConstants.BILL,
        searchFields: {
          ...this.state.searchFields,
          claimNumber: '',
          [targetId]: value,
        },
      });
    } else if (targetId === "billTypes") {
      this.setState({
      searchFields: {
        ...this.state.searchFields,
        [targetId]: value,
      }
    });
  }
};

  //TODO: Perform a searched based off of saved data
  executeSavedSearch = () => { };

  renderModal = () => (
    <ManageBillsModal
      isOpen={this.state.isManageBillsModal}
      onClose={this.openManageBillModal}
      handleModalClicked={this.handleModalClicked}
      userId={this.props.currentUserId}
    />
  );

  //TODO: Add modal for managing previous searches
  mySearchesHandler = event => {
    const value = event.target.value;
    if (value === 'manage') {
      this.setState({ isManageBillsModal: !this.state.isManageBillsModal });
      // this.renderModal();
      // return <ManageBillsModal>
    } else {
      this.setState({
        searchMode: billOperationsConstants.SHOW_ADVANCED_BILL_SEARCH,
        searchFields: {
          ...this.state.searchFields,
          claimNumber: '',

        },
      });
      // this.props.changeSearchType(this.state.searchType);
      this.props.changeSearchMode(billOperationsConstants.SHOW_ADVANCED_BILL_SEARCH);
      this.props.changeSearchHandler(event);
      this.handleClick(value);


    }
  };

  openManageBillModal = () => {
    this.setState(prevState => ({
      isManageBillsModal: !prevState.isManageBillsModal,
    }));
  };


  handleModalClicked = name => {
    if (name === 'Add') {
      this.setState({ plusClicked: true });
    } else if (name === 'Delete') {
      this.setState({ minusClicked: true });
    } else if (name === 'Edit') {
      this.setState({ editClicked: true });
    }
  };

  handleClick = (value) => {
    let selectedObj = this.props.updatedBillListData.filter(item =>
      item.search_name.trim() == value
    )
    selectedObj.length && this.props.sendAdvanceData(selectedObj[0]);
    // return modules.filter(module => {
    // return module.name === name;
    // this.props.changeSearchHandler(event);
    // this.props.sendAdvanceData(item);
  }

  renderBillSearchData = () =>

    this.props.updatedBillListData && this.props.updatedBillListData.map(item => (
      <option
        style={{ pointer: 'cursor' }}
        onClick={() => this.handleClick(item)}
      >
        {item.search_name}
      </option>

    ));

    onClearStateInput = () => {
      this.setState({
        searchFields: {
          ...this.state.searchFields,
          state: "",
          stateName: "",
        },
     });
    };


  render() {
    return (
      <>
        {this.state.isManageBillsModal && this.renderModal()}
        {/* {this.state.plusClicked && <AddManageBillsModal />} */}
        <div id="findBillsSearchContainer">
          <Form onSubmit={this.onFormSubmit}>
            <Row form>
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={this.onChangeHandler}
                    placeholder="Claim Number"
                    id="claimNumber"
                    type="text"
                    value={this.state.searchFields.claimNumber}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={this.onChangeHandler}
                    placeholder="Bill / Scan"
                    id="billScan"
                    type="text"
                    value={this.state.searchFields.billScan}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <AppInput
                    changeHandler={this.onChangeHandler}
                    placeholder="End"
                    id="end"
                    type="text"
                    value={this.state.searchFields.end}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <SearchableSelect
                    data={this.props.clients}
                    id="client"
                    returnValue={'int_sitecode'}
                    displayOption="chr_sitename"
                    placeholder="Client"
                    stateHandler={this.updateState}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <AppAutocomplete
                    id="state"
                    data={this.props.states}
                    value={this.state.searchFields.stateName}
                    displayOption="name"
                    returnValue="abbreviation"
                    onChange={this.onAutocompleteChange}
                    onSelect={this.onAutocompleteSelect}
                    showToolTip={true}
                    placeholder="State..."
                    isSuggested={true}
                    clearInput={this.onClearStateInput}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <DropdownMultiSelect
                    id="billTypes"
                    accessor={{
                      key: 'key',
                      value: 'description',
                    }}
                    options={this.props.billTypes}
                    placeHolder="Bill Type"
                    optionColumnHeader="Bill Type"
                    changeHandler={this.onChangeHandler}
                    selected={this.state.searchFields.billTypes === "" ? [] : this.state.searchFields.billTypes}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="select"
                    name="mySearches"
                    id="showAdvancedSearch"
                    value="default"
                    onChange={this.mySearchesHandler}
                  >
                    <option value="default">My Searches...</option>
                    <option
                      onClick={this.props.changeSearchHandler}
                      value="manage"
                    >
                      Manage...{' '}
                    </option>
                    {this.renderBillSearchData()}

                    {
                      //TODO: Loop through my search list here
                    }
                  </Input>
                </FormGroup>
              </Col>
              <div>
              <Col>
                <Button type="submit">FIND</Button>{' '}
                <Button
                  id="showAdvancedSearch"
                  onClick={this.props.changeSearchHandler}
                >
                  ADVANCED
                </Button>{' '}
              <Button
                id="clearAllbtn"
                onClick={this.onClearAll}
              >
                CLEAR ALL
              </Button>
              </Col>
              </div>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

FindBillsSearch.propTypes = {
  states: PropTypes.array.isRequired,
  billTypes: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  changeSearchHandler: PropTypes.func.isRequired,
  changeSearchType: PropTypes.func.isRequired,
  findBills: PropTypes.func.isRequired,
  findClaims: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  changeSearchMode: PropTypes.func.isRequired,
  sendAdvanceData: PropTypes.func
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const billReviewState = billOperationsSelectors.getBillReview(state);
  return {
    states: initialData.states,
    billTypes: initialData.billTypes,
    clients: initialData.slpsites,
    billsearches: initialData.billsearches ? initialData.billsearches : [],
    updatedBillListData: billReviewState.updatedBillSearches ? billReviewState.updatedBillSearches : initialData.billsearches
  };
};

const mapDispatchToProps = dispatch => ({
  findBills: data => dispatch(billOperationsOperations.findBills(data)),
  findClaims: data => dispatch(billOperationsOperations.findClaims(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updateSearchFields: data =>
    dispatch(billOperationsActions.updateSearchFields(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindBillsSearch);
