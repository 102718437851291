export const moduleName = 'AUTH';

export const loginResultCodes = {
  INVALID_INPUT: 0,
  SUCCESS: 1,
  INVALID_PASSWORD: 2,
  EXPIRED: 3,
  INVALID_USERNAME: 7,
  ACCOUNT_LOCKED: 8,
};
