import React,  { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  fast360Selectors,
  fast360Actions,
  fast360Operations
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import { Modal, ModalHeader, ModalBody, Label, Button, Input, Row, Col} from 'reactstrap';

const Fast360DetailProvider = ({
    isOpen,
    userId,
    service_id,
    service_type,
    service_detail_id,
    detailProvider,
    getDetailProvider,
    saveDetailProvider,
    onClose,
}) => {
    const [openDetailProvider, setOpenDetailProvider] = useState(false);
    const [detail_provider_id, setDetailProviderId] = useState(0);
    const [formDetailFields, setFormDetailFields] = useState({
        licenseNumber: '',
        npi: '',
    });

    const dispatch = useDispatch();
    const alert = msg => dispatch(commonOperations.addAlert(msg, 'danger'));

    const toggle = () => {
        onClose();
        resetForm();
        setOpenDetailProvider(false);
    }

    const closeDetailProvider = () => {
        toggle();
    }

    const resetForm = () => {
        setFormDetailFields({
            licenseNumber: '',
            npi: '',
        });
        setDetailProviderId(0);
    }
    
    useEffect(() => {
        if (isOpen) {            
            setOpenDetailProvider(true);
        } else {
            setOpenDetailProvider(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (service_detail_id !== 0 && (formDetailFields.npi === undefined || formDetailFields.npi === '')) {
            const params = {
                functionName: 'loadDetailProvider',
                serviceType: service_type,
                serviceDetailId: service_detail_id,
                userId: userId,
                zebraUserId: userId,
            };
            getDetailProvider(params);
        } else {
            resetForm();
        }
    }, [isOpen, service_detail_id])

    useEffect(() => {
        if (!isEmpty(detailProvider)) {
            setFormDetailFields({
                licenseNumber: detailProvider.licenseNumber,
                npi: detailProvider.npi,
            });
            setDetailProviderId(detailProvider.id);
        }
    }, [detailProvider]);

    const handleInputChange = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;        
        setFormDetailFields({ ...formDetailFields, [name]: value });
    };

    const saveDetailProviderFunc = () => {
        const regex = /[0-9]/g;
        if (formDetailFields.npi > '' && (formDetailFields.npi.replace(regex, "") != "" || formDetailFields.npi.length != 10)) {
            alert('You must obtain the correct NPI for this provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed');
            return;
        }
        const params = {
            functionName: 'saveDetailProvider',
            zebraUserId: userId,
            serviceId: service_id,
            serviceType: service_type,
            serviceDetailId: service_detail_id,
            detailProviderId: detail_provider_id,
            licenseNumber: formDetailFields.licenseNumber,
            npi: formDetailFields.npi
        };
        saveDetailProvider(params);
        toggle();
    }

    return (
        <>
            <Modal isOpen={openDetailProvider}>
                <ModalHeader toggle={toggle}>Provider Details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <Label>License No</Label>
                            <Input
                                name="licenseNumber"
                                onChange={handleInputChange}
                                defaultValue={formDetailFields.licenseNumber}
                                value={formDetailFields.licenseNumber}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Label>NPI</Label>
                            <Input
                                name="npi"
                                onChange={handleInputChange}
                                defaultValue={formDetailFields.npi}
                                value={formDetailFields.npi}
                                maxLength="10"
                                />
                        </Col>
                    </Row>
                    <div style={{ float: 'right' }}>
                        <Button type="button" onClick={() => saveDetailProviderFunc()}>
                        Save
                        </Button>
                        <Button
                        style={{ marginLeft: 5 }}
                        type="button"
                        onClick={() => closeDetailProvider()}>
                        Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    const fast360Info = fast360Selectors.getFast360Info(state);
    const user = authSelectors.getUser(state);
    return {
      audInfo: fast360Info.audInfo,
      userId: user.id,
      serviceIdFromRef: fast360Info.serviceIdFromRef,
      detailProvider: state.FAST360.fast360Reducer.updateDetailProvider ? state.FAST360.fast360Reducer.updateDetailProvider : {},
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    getDetailProvider: data => dispatch(fast360Operations.getDetailProvider(data)),
    saveDetailProvider: data => dispatch(fast360Operations.saveDetailProvider(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    /*updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
    getServiceIdFromReference: data => dispatch(fast360Operations.getServiceIdFromReference(data)),
    loadFromWorkList: data => dispatch(fast360Operations.loadFromWorkList(data)),
    loadAudiologyHcpcsList: data => dispatch(fast360Operations.loadAudiologyHcpcsList(data)),
    loadCostSavings: data => dispatch(fast360Operations.loadCostSavings(data)),
    updateAudInfoTable: data => dispatch(fast360Actions.updateAudInfoTable(data)),
    deleteHCPCService: data => dispatch(fast360Operations.deleteHCPCService(data)),
    logBreadCrumbs : data =>   dispatch(fast360Operations.logBreadCrumbs(data)),*/
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Fast360DetailProvider);
  