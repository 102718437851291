import PropTypes from 'prop-types';
import React from 'react';
import { Button, Row, Col, Label, FormGroup } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import PopupModal from 'shared/components/PopupModal';

class TransferBillsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      assignedUserDisplay: '',
      assignedUser: '',
    };
  }

  handleUserDisplayChange = (_fieldName, value) => {
    this.setState({
      assignedUserDisplay: value,
    });
  };

  handleUserSelect = (_fieldName, displayValue, value) => {
    this.setState({
      assignedUserDisplay: displayValue,
      assignedUser: value,
    });
  };

  handleTransferClick = () => {
    this.props.handleTransferClick(this.state.assignedUser);
    this.toggle();
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  getBody() {
    return (
      <Row form>
        <Col lg="6">
          <FormGroup>
            <Label for="assignedUser">User</Label>
            <AppAutocomplete
              id="assignedUser"
              data={this.props.users}
              value={this.state.assignedUserDisplay}
              displayOption="name"
              returnValue="id"
              onChange={this.handleUserDisplayChange}
              onSelect={this.handleUserSelect}
              showToolTip={true}
              placeholder="Choose..."
              isSuggested={true}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  getFooter() {
    return (
      <Button color="primary" onClick={this.handleTransferClick}>
        TRANSFER
      </Button>
    );
  }

  resetModal = () => {
    this.setState({
      assignedUserDisplay: '',
      assignedUser: '',
    });
  };

  render() {
    return (
      <>
        <PopupModal
          content={this.getBody()}
          title="TRANSFER BILLS TO.."
          externalToggle={this.toggle}
          isOpen={this.state.modal}
          footerContent={this.getFooter()}
          onClosed={this.resetModal}
          size="lg"
        />
        <Button
          disabled={this.props.selectedBills.length <= 0}
          onClick={this.toggle}
          style={{ width: '100%' }}
          type="button"
          size="sm"
        >
          TRANSFER
        </Button>
      </>
    );
  }
}

TransferBillsModal.propTypes = {
  users: PropTypes.array.isRequired,
  handleTransferClick: PropTypes.func.isRequired,
  selectedBills: PropTypes.array.isRequired,
};

export default TransferBillsModal;
