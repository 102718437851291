import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AppDateInput from 'shared/components/input/AppDateInput';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';

import AppTooltip from 'shared/components/tooltip/AppTooltip';

import { fast360Selectors, fast360Actions } from '../store/index';

const Fast360Acc = ({
  accommodationType,
  appointmentType,
  selectedService,
  requiredServiceData
}) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  const [validDos, setValidDos] = useState(true);
  const [valiCheckOut, setValiCheckOut] = useState(true);
  const [validCheckIn, setValidCheckIn] = useState(true);
  useEffect(() => {
    if (!selectedService?.dos)
      setValidDos(false);
    else setValidDos(true);
  }, [selectedService]);

  useEffect(() => {
    if (!selectedService?.checkInDate)
      setValiCheckOut(false);
    else setValiCheckOut(true);
  }, [selectedService]);
  useEffect(() => {
    if (!selectedService?.checkOutDate)
      setValidCheckIn(false);
    else setValidCheckIn(true);
  }, [selectedService]);

  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const onChangeHandlerAccType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.categoryId = target.value;
    setSelectedItem({ selectedService });
  };
  const onChangeHandlerApptType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.apptType = name;
    selectedService.apptTypeId = target.value;
    setSelectedItem({ selectedService });
    if (
      target.value === '22' ||
      target.value === '23' ||
      target.value === '24' ||
      target.value === '25' ||
      target.value === '26'
    ) {
      selectedService.highPriority = true;
    }
    setSelectedItem({ selectedService });
  };

  const setMap = {
    dos: setValidDos,
    checkInDate: setValidCheckIn,
    checkOutDate: setValiCheckOut,
  }

  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();

      setValidDos(true);
    }
    if (object.key === 'checkInDate') {
      let monCheckIn = object.value.getMonth() + 1;
      selectedService.checkInDate =
        monCheckIn +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
      setValidCheckIn(true);
    }
    if (object.key === 'checkOutDate') {
      let monCheckOut = object.value.getMonth() + 1;
      selectedService.checkOutDate =
        monCheckOut +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
      setValiCheckOut(true);
    }

    var msDiff =
      new Date(selectedService.checkOutDate).getTime() -
      new Date(selectedService.checkInDate).getTime(); //Future date - current date
    selectedService.daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    setSelectedItem({ selectedService });
    // setMap[name] && setMap[name](value !== "" ? true : false);
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'amenities') {
      selectedService.amenities =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'poNumber') {
      selectedService.poNumber = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    if (name === 'wheelchairAccess') {
      selectedService.wheelchairAccess = target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="4">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subServ'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerAccType(e)}
                className={selectedService?.categoryId ? '' : "error"}
                placeholder="Choose..."
                value={selectedService?.categoryId || ''}
                id="subService"
              >
                <option value="default">Choose...</option>
                {accommodationType.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="4">
            <Label>Appt. Type: </Label>
            <AppTooltip text={'Appt Type is a required field'} id={'apptType'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerApptType(e)}
                className={selectedService?.apptTypeId ? '' : "error"}
                placeholder="Choose..."
                value={selectedService?.apptTypeId || ''}
                id="apptType"
              >
                <option value="default">Choose...</option>
                {appointmentType.map((id, index) => (
                  <option key={index} value={id.id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip
              text={'Check In Date is a required field'}
              id={'apptType'}
            >
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
                error={!validDos}
              />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
          <Col sm="4">
            <Label>PO Number: </Label>
            <Input
              name="poNumber"
              onChange={handleInputChange}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col xs="1"></Col>
          <Label check>
            <Input
              name="rush"
              type="checkbox"
              onChange={handleInputChange}
              checked={selectedService?.rush}
            />{' '}
            Rush Order
          </Label>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Label check>
            <Input
              name="wheelchairAccess"
              type="checkbox"
              onChange={handleInputChange}
              checked={selectedService?.wheelchairAccess}
            />{' '}
            Wheelchair Access Requested
          </Label>
        </Row>
        <Row>
          <Col xs="1"></Col>

          <Label check>
            <Input
              name="highPriority"
              type="checkbox"
              onChange={handleInputChange}
              checked={selectedService?.highPriority}
            />{' '}
            High Priority
          </Label>
        </Row>
        <Row>
          <br></br>
          <Col sm="4">
            <Label>Amenities: </Label>
            <Input
              type="textarea"
              name="amenities"
              onChange={handleInputChange}
              value={selectedService?.amenities || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>DOS From: </Label>
            <AppDateInput
              name="checkInDate"
              onChange={value => onChangeHandler({ key: 'checkInDate', value })}
              value={selectedService?.checkInDate || ''}
              error={!validCheckIn}
            />{' '}
          </Col>
          <Col xs="1"></Col>
          <Col sm="3">
            <Label>DOS To: </Label>
            <AppDateInput
              name="checkOutDate"
              onChange={value =>
                onChangeHandler({ key: 'checkOutDate', value })
              }
              value={selectedService?.checkOutDate || ''}
              error={!valiCheckOut}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Total Days Stay: </Label>
            <Input id="totDaysStay" value={selectedService?.daysDiff ? Math.floor(selectedService?.daysDiff) : ''} />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};

Fast360Acc.propTypes = {
  accommodationType: PropTypes.array,
  appointmentType: PropTypes.array,
};
const mapStateToProps = state => {
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    accommodationType: initialData.accommodationType,
    appointmentType: initialData.appointmentType,
  };
};
const mapDispatchToProps = dispatch => ({
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360Acc);