export const sortByType = (item1, item2, order) => {
    if (isAllEmpty(item1, item2)) {
        return 0;
    }

    // prioritize type: boolean => number => Date => string
    if (isBooleanType(item1, item2)) {
        return sortByBooleanType(item1, item2, order);
    }
    if (isNumberType(item1, item2)) {
        return sortByNumberType(item1, item2, order);
    }
    if (isDateType(item1, item2)) {
        return sortByDateType(item1, item2, order);
    }
    if (isStringType(item1, item2)) {
        return sortByStringType(item1, item2, order);
    }
    return 0;
}

export const isAllEmpty = (item1, item2) => {
    return (item1 === undefined || item1 === null) &&
    (item2 === undefined || item2 === null);
}

export const isBooleanType = (item1, item2) => {
    return typeof item1 === 'boolean' || typeof item2 === 'boolean';
}

export const sortByBooleanType = (item1, item2, order) => {
    if (typeof item1 !== 'boolean') {
        return order ? 1 : -1;
    }
    if (typeof item2 !== 'boolean') {
        return order ? -1 : 1;
    }
    return order ? Number(item1) - Number(item2) : Number(item2) - Number(item1);
}

export const isNumberType = (item1, item2) => {
    return !isNaN(item1) || !isNaN(item2);
}

export const sortByNumberType = (item1, item2, order) => {
    const number1 = Number(item1);
    const number2 = Number(item2);
    if (isNaN(number1)) {
        return order ? 1 : -1;
    }
    if (isNaN(number2)) {
        return order ? -1 : 1;
    }
    return order ? number1 - number2 : item2 - number1;
}

export const isDateType = (item1, item2) => {
    const date1 = new Date(item1);
    if (isValidDate(date1)) {
        return true;
    }
    const date2 = new Date(item2);
    if (isValidDate(date2)) {
        return true;
    }
    return false;
}

export const isValidDate = date => {
    return date instanceof Date && !isNaN(date)
}

export const sortByDateType = (item1, item2, order) => {
    const date1 = new Date(item1);
    const date2 = new Date(item2);
    if (!date1 instanceof Date || isNaN(date1)) {
        return order ? 1 : -1;
    }
    if (!date2 instanceof Date || isNaN(date2)) {
        return order ? -1 : 1;
    }
    return order ? date1 - date2 : date2 - date1;
}

export const isStringType = (item1, item2) => {
    return typeof item1 === 'string' || typeof item2 === 'string';
}

export const sortByStringType = (item1, item2, order) => {
    if (typeof item1 !== 'string') {
        return order ? 1 : -1;
    }
    if (typeof item2 !== 'string') {
        return order ? -1 : 1;
    }
    return order ? item1.localeCompare(item2) : item2.localeCompare(item1);
}

export const formatMMDDYYYY = (value, separator = '/') => {
    const date = new Date(value);
    if (!isValidDate(date)) {
        return '';
    }
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + separator + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + separator + date.getFullYear();
}

export const formatYYYYMMDD = (value, separator = '/') => {
    const date = new Date(value);
    if (!isValidDate(date)) {
        return '';
    }
    return date.getFullYear() + separator + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + separator + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
}

const dayInMs = 1000 * 60 * 60 * 24;
export const countDaysDiff = (date1, date2) => {
    if (!date1 instanceof Date || isNaN(date1)) {
        return -1;
    }
    if (!date2 instanceof Date || isNaN(date2)) {
        return -1;
    }
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);
    date2.setHours(0);
    date2.setMinutes(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    return Math.abs((date1.getTime() - date2.getTime()) / dayInMs);
}

export const parseValidDate = value => {
    return isValidDate(new Date(value)) ? new Date(value) : '';
};

export const isValidEmail = email => {
    return /^[\w.-]+@\w[\w.-]+\.[\w.-]*[a-z][a-z]$/i.test(email);
};