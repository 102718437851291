import { combineReducers } from 'redux';

// FIXME: Directly accessing reducers because going via the parent path causes a dependency cycle
import billOperationReducer from '../../bill-operations/store/reducers';
import billMaintenanceReducer from '../../bill-maintenance/store/reducers';
import {RESET_STORE_STATE} from '../../bill-operations/store/constants';
import { BILL_OPERATION, CHANGE_BILL_MODULE } from './constants';

const defaultState = {
  currentView: BILL_OPERATION,
};

const billReviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_BILL_MODULE:
      return { ...state, currentView: action.payload.module };
    default:
      return { ...state };
  }
};

const topReducer = combineReducers({
  billOperation: billOperationReducer,
  billMaintenance: billMaintenanceReducer,
  billReview: billReviewReducer,
});

const reducer=(state,action)=>{

  if (action.type===RESET_STORE_STATE){
   return topReducer(undefined, {})
  }

  return topReducer(state,action)
}

export default reducer;
