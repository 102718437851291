import classnames from 'classnames';
import React from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

import UserBasics from './UserBasics';
import UserAccess from './UserAccess';
import UserAncilAccess from './UserAncilAccess';
import UserBillReview from './UserBillReview';
import UserBillApproval from './UserBillApproval';
import UserAdjusterMapping from './UserAdjusterMapping';
import UserComment from './UserComment';
import UserClients from './UserClients';

const UserTab = props => (
  <div>
    <Nav tabs>
      {props.tabs.map(tab => (
        <NavItem key={tab.index}>
          <NavLink
            className={classnames({ active: props.activeTab === tab.index })}
            onClick={() => props.toggleTab(tab.index)}
          >
            {tab.name}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
    <TabContent activeTab={props.activeTab}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <UserBasics />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            <UserAccess />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="3">
        <Row>
          <Col sm="12">
            <UserAncilAccess />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="4">
        <Row>
          <Col sm="12">
            <UserBillReview />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="5">
        <Row>
          <Col sm="12">
            <UserBillApproval />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="6">
        <Row>
          <Col sm="12">
            <UserAdjusterMapping />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="7">
        <Row>
          <Col sm="12">
            <UserClients />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="8">
        <Row>
          <Col sm="12">
            <UserComment />
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  </div>
);

export default UserTab;
