export const topByCost = 'topByCost';
export const topByLocation = 'topByLocation';
export const topByCount = 'topByCount';

export const genderData = [
    {
      id: 1,
      name: 'Male',
    },
    {
      id: 2,
      name: 'Female',
    },
  ];

export const sideOfBody = [
  {
    id: 1,
    name: "Left",
  },
  {
    id: 2,
    name: "Right",
  },
  {
    id: 3,
    name: "Both",
  },
  {
    id: 4,
    name: "Upper",
  },
  {
    id: 5,
    name: "Lower",
  },
  {
    id: 6,
    name: "Unspecified",
  },
]

export const primaryData = [
  {
    value: 'New',
  },
  {
    value: 'Rental',
  },
  {
    value: 'Used',
  },
  {
    value: 'KF',
  },
];

export const secondaryData = [
  {
    value: 'None',
  },
  {
    value: 'KF',
  },
  {
    value: 'LT',
  },
  {
    value: 'RT',
  },
  {
    value: 'RX',
  },
  {
    value: 'RP',
  },
  {
    value: 'SI',
  },
  {
    value: 'VG/PGO',
  },
];

export const discountData = [
  {
    value: 'FS',
  },
  {
    value: 'UC',
  },
  {
    value: 'MCA',
  },
  {
    value: 'OWCP',
  },
  {
    value: 'MSRP',
  },
  {
    value: 'FLAT',
  }
];

export const calcData = [
  {
    value: 'FS',
  },
  {
    value: 'UC',
  },
  {
    value: 'MCA',
  },
  {
    value: 'V.Billing Charges',
  }
];

export const calculator = [
  {
    value: '+',
  },
  {
    value: '-',
  }
];

export const rentalUnit = [
  {
    value: 'Day',
  },
  {
    value: 'Week',
  },
  {
    value: 'Month',
  }
];

export const toothNumber = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
  {
    value: 12,
  },
  {
    value: 13,
  },
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
  {
    value: 17,
  },
  {
    value: 18,
  },
  {
    value: 19,
  },
  {
    value: 20,
  },
  {
    value: 21,
  },
  {
    value: 22,
  },
  {
    value: 23,
  },
  {
    value: 24,
  },
  {
    value: 25,
  },
  {
    value: 26,
  },
  {
    value: 27,
  },
  {
    value: 28,
  },
  {
    value: 29,
  },
  {
    value: 30,
  },
  {
    value: 31,
  },
  {
    value: 32,
  }
];