
import React from 'react';
import ExternalReferralTable from 'shared/components/table/ExternalReferralTable';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';
import { fast360Operations } from '../store';
import { Button, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';



class ExternalReferral extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,

        }
        this.toggle = this.toggle.bind(this);

    }
    toggle = () => {
        const breadCrumb = {
            functionName: 'logBreadCrumbs',
            zebraUserId: this.props.currentUserId,
            breadcrumbkey: 'CLOSEQUEUE',
            refTableId: 0,
            refId: 0,
            refTable: '',
          };
        this.props.logBreadCrumbs(breadCrumb);
     
        
        this.props.openExternal()
    };

    componentDidMount() {
        const { currentUserId } = this.props;
        //const user = this.props.users && this.props.users.find(u => u.zebraId == currentUserId);

        let payload = {
            "functionName": "loadYorkQueueList",
            "zebraUserId": currentUserId,
            //"user_id": user.id
        }
        this.props.getExternalReferral(payload);

        const breadCrumb = {
            functionName: 'logBreadCrumbs',
            zebraUserId: currentUserId,
            breadcrumbkey: 'OPENQUEUE',
            refTableId: 0,
            refId: 0,
            refTable: '',
          };
        this.props.logBreadCrumbs(breadCrumb); 
      
    }


    render() {
        return (
            <Modal size="xl" isOpen={true}>
                <ModalHeader style={{ border: 'none' }}><Button onClick={this.toggle}
                    color="primary" style={{ marginLeft: '-16px', marginTop: '-27px', width: '150px' }}>CLOSE QUEUE
                    </Button>

                </ModalHeader>
                <ModalHeader style={{ marginLeft: '325px', fontWeight: 'bolder', border: 'none' }}> External Referral Queue:{this.props.referral.length} Items</ModalHeader>
                <ModalBody>
                    <>
                        <ExternalReferralTable
                            columns={[
                                {
                                    header: 'Service',
                                    field: 'TempField',
                                    percent: '6',

                                },
                                {
                                    header: 'Client',
                                    field: 'sedgwickReferralId',
                                    percent: '6',
                                },
                                {
                                    header: 'Date Received',
                                    field: 'DateReceived',
                                    percent: '11',
                                },
                                {
                                    header: 'Date of Service',
                                    field: 'DateOfService',
                                    percent: '11',
                                },
                                {
                                    header: 'Service Type',
                                    field: 'ServiceType',
                                    percent: '10',
                                },
                                {
                                    header: 'Patient Name',
                                    //field:'svc',
                                    percent: '12',
                                    sortAble: false
                                },
                                {
                                    header: 'Patient City/State',
                                    //field:'svc',
                                    sortAble: false,
                                    percent: '12',
                                },
                                {
                                    header: 'Adjuster Name',
                                    field: 'AdjusterName',
                                    percent: '11',

                                },
                                {
                                    header: 'Auth Code',
                                    field: 'UniquePortalAuthCode',
                                    percent: '10',
                                },
                                {
                                    header: 'File Name',
                                    field: 'FileName',
                                    percent: '10',
                                },

                            ]}
                            data={this.props.referral}
                            //autoPagination={true}
                            sortAble={true}
                            resizable={true}
                            sortImmediately={false}
                            toggle={this.toggle}
                            openExternal={this.props.openExternal}
                            onNewReferral={this.props.onNewReferral}
                            openExt={this.props.openExt}
                        />
                    </>
                </ModalBody>
            </Modal>
        );
    }
};




const mapStateToProps = state => {
    const userId = authSelectors.getUserId(state);
    return {
        currentUserId: userId,
        referral: state.FAST360.fast360Reducer.updateGetExternalReferral ? state.FAST360.fast360Reducer.updateGetExternalReferral : [],
        users: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.users : [],
    };
};
const mapDispatchToProps = dispatch => ({
    getExternalReferral: (data) => dispatch(fast360Operations.getExternalReferral(data)),
    postExternal: (data) => dispatch(fast360Operations.postExternal(data)),
    logBreadCrumbs : data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalReferral);
