import * as R from 'ramda';

import helper from 'helper/helper';

import { parseValidDate, formatYYYYMMDD } from 'shared/utils/shareUtils';

const getInitialData = async userId => {
  const response = await helper.request.post('/LoadExpertCoreData', { userId });
  return {
    expertGroupTypes:
      (response.data.expertgrouptype || {}).expertgrouptype || [],
    expertDocumentTypes:
      (response.data.expertDocumentTypes || {}).expertDocumentTypes || [],
  };
};

const loadExpertSummaryData = data => {
  return helper.request.post(`/findSummaryData`, data);
};

const loadExpertPopupData = data => {
  return helper.request.post(`/LoadPopupData`, data);
};

const findExpert = data => {
  return helper.request.post(`/FindExpertSimple`, data);
};

const loadExpertGroup = async data => {
  const [expertSummaryResponse, expertPopupResponse] = await Promise.all([
    loadExpertSummaryData({
      expertId: data.expertId,
    }),
    loadExpertPopupData({
      expertId: data.expertId,
      isGroup: true,
    }),
  ]);
  const expert =
    (expertSummaryResponse.data.expert || []).length > 0
      ? expertSummaryResponse.data.expert[0]
      : {};
  return {
    data: {
      id: expert.id || 0,
      groupName: expert.groupname || '',
      groupType: expert.expertgrouptype || -1,
      tinNumber: expert.tinnumber || '',
      payableTo: expert.payable || '',
      npi: expert.npi || '',
      previousJericho: expert.jericho_id || '',
      website: expert.website || '',
      underInvestigation: expert.underinvestigation || false,
      reason: expert.txt_uireason || '',
      expertStatus: expert.statusid || '',
      referredBy: expert.referredby || '',
      expertStatusStep: expert.expertstatusstepid || -1,
      recommendedOn: parseValidDate(expert.recommendedon || ''),
      startDate: parseValidDate(expert.startdate || ''),
      lastCredentialedOn: parseValidDate(expert.lastcredentialledon || ''),
      recruitmentMethod: expert.recruitmentmethod || '',
      referralCode: expert.referralcode || -1,
      terminationReason: expert.terminationreason || '',
      terminationDate: parseValidDate(expert.terminationdate || ''),
      notificationMethod: expert.notificationmethod || '',
      callForAssignment: expert.callforassignment || false,
      onlineTaskList: expert.onlinetasklist || false,
      comments: expert.comments || '',
    },
    expertStatuses: expertPopupResponse.data.expertstatus || [],
    expertStatusProspect: expertPopupResponse.data.expertstatusprospect || [],
    expertStatusApplicant: expertPopupResponse.data.expertstatusapplicant || [],
    recruitmentMethods: expertPopupResponse.data.recruitmentmethods || [],
    referralCodes: expertPopupResponse.data.referralcodes || [],
    termReason: expertPopupResponse.data.termreason || [],
    notificationTypes: expertPopupResponse.data.notificationtypes || [],
  };
};

const saveExpertGroup = async data => {
  const body = {
    expert_id: data.id,
    startdate: formatYYYYMMDD(data.startDate, '-'),
    isgroup: true,
    groupname: data.groupName,
    tinnumber: data.tinNumber,
    payable: data.payableTo,
    comments: data.comments,
    npi: data.npi,
    recommendedon: formatYYYYMMDD(data.recommendedOn, '-'),
    terminationdate: formatYYYYMMDD(data.terminationDate, '-'),
    lastcredentialledon: formatYYYYMMDD(data.lastCredentialedOn, '-'),
    terminationreason: data.terminationReason,
    statusid: data.expertStatus,
    notificationmethod: data.notificationMethod,
    onlinetasklist: data.onlineTaskList,
    callforassignment: data.callForAssignment,
    underinvestigation: data.underInvestigation,
    txt_uireason: data.reason,
    recruitmentmethod: data.recruitmentMethod,
    referralcode: data.referralCode,
    referredby: data.referredBy,
    website: data.website,
    expertgrouptype: data.groupType,
    expertstatusstepid: data.expertStatusStep,
    jericho_id: data.previousJericho,
    zebUserId: data.currentUserId,
  };

  const response = await helper.request.post(`/SaveGroup`, body);

  if (response.data !== 1) {
    throw new Error(response.data);
  }

  return response.data;
};

const loadExpert = async data => {
  const expertSearchResponse = await findExpert({
    expertId: data.expertId,
  });

  const expertSearchResult = R.find(
    item => item.expert_id === parseInt(data.expertId),
    expertSearchResponse.data.expertSearch || []
  );

  const peopleId = expertSearchResult ? expertSearchResult.people_id : 0;

  const [expertSummaryResponse, expertPopupResponse] = await Promise.all([
    loadExpertSummaryData({
      expertId: data.expertId,
      peopleId,
    }),
    loadExpertPopupData({
      expertId: data.expertId,
    }),
  ]);
  const expert =
    (expertSummaryResponse.data.expert || []).length > 0
      ? expertSummaryResponse.data.expert[0]
      : {};
  const people =
    (expertSummaryResponse.data.people || []).length > 0
      ? expertSummaryResponse.data.people[0]
      : {};

  const honor = people.honor || '';
  const language = people.languages || '';

  return {
    data: {
      id: expert.id || 0,
      peopleId: expert.people_id || 0,
      firstName: people.firstname || '',
      middleInitial: people.middleinitial || '',
      lastName: people.lastname || '',
      gender: people.gender === true ? 1 : -1,
      honor: honor.length > 0 ? honor.split(', ') : [],
      tinNumber: expert.tinnumber || '',
      ssn: people.ssn || '',
      dob: parseValidDate(people.dob || ''),
      language: language.length > 0 ? language.split(', ') : [],
      payableTo: expert.payable || '',
      npi: expert.npi || '',
      cellphone: people.cell1 || '',
      pager: people.pager1 || '',
      pagerPin: people.pagerpinnumber || '',
      email1: people.email1 || '',
      email2: people.email2 || '',
      previousJericho: expert.jericho_id || '',
      vendor: expert.vendor_id || '',
      underInvestigation: expert.underinvestigation || false,
      reason: expert.txt_uireason || '',
      expertStatus: expert.statusid || '',
      referredBy: expert.referredby || '',
      expertStatusStep: expert.expertstatusstepid || -1,
      recommendedOn: parseValidDate(expert.recommendedon || ''),
      startDate: parseValidDate(expert.startdate || ''),
      lastCredentialedOn: parseValidDate(expert.lastcredentialledon || ''),
      recruitmentMethod: expert.recruitmentmethod || '',
      referralCode: expert.referralcode || -1,
      terminationReason: expert.terminationreason || '',
      terminationDate: parseValidDate(expert.terminationdate || ''),
      notificationMethod: expert.notificationmethod || '',
      callForAssignment: expert.callforassignment || false,
      onlineTaskList: expert.onlinetasklist || false,
      comments: expert.comments || '',
      biography: expert.biography || '',
      salutation: people.salutation || '',
      expertUserId: expert.expertuserid || -1,
      maxFileSizeMB: expert.maxfilesize || '',
      maxPageCount: expert.maxpagecount || '',
      maxFaxedPages: expert.maxfaxedpages || '',
    },
    expertStatuses: expertPopupResponse.data.expertstatus || [],
    expertStatusProspect: expertPopupResponse.data.expertstatusprospect || [],
    expertStatusApplicant: expertPopupResponse.data.expertstatusapplicant || [],
    recruitmentMethods: expertPopupResponse.data.recruitmentmethods || [],
    referralCodes: expertPopupResponse.data.referralcodes || [],
    termReason: expertPopupResponse.data.termreason || [],
    notificationTypes: expertPopupResponse.data.notificationtypes || [],
    salutations: expertPopupResponse.data.salutation || [],
    languages: expertPopupResponse.data.language || [],
    honorifics: expertPopupResponse.data.honorific || [],
  };
};

const saveExpert = async data => {
  const body = {
    expert_id: data.id,
    people_id: data.peopleId,
    firstname: data.firstName,
    middleinitial: data.middleInitial,
    lastname: data.lastName,
    gender: data.gender,
    honor: data.honor.join(', '),
    tinnumber: data.tinNumber,
    ssn: data.ssn,
    dob: formatYYYYMMDD(data.dob, '-'),
    languages: data.language.join(', '),
    payable: data.payableTo,
    comments: data.comments,
    npi: data.npi,
    cell1: data.cellphone,
    pager1: data.pager,
    pagerpinnumber: data.pagerPin,
    email1: data.email1,
    email2: data.email2,
    recommendedon: formatYYYYMMDD(data.recommendedOn, '-'),
    startdate: formatYYYYMMDD(data.startDate, '-'),
    terminationdate: formatYYYYMMDD(data.terminationDate, '-'),
    lastcredentialledon: formatYYYYMMDD(data.lastCredentialedOn, '-'),
    terminationreason: data.terminationReason,
    statusid: data.expertStatus,
    notificationmethod: data.notificationMethod,
    onlinetasklist: data.onlineTaskList,
    callforassignment: data.callForAssignment,
    underinvestigation: data.underInvestigation,
    txt_uireason: data.reason,
    recruitmentmethod: data.recruitmentMethod,
    referralcode: data.referralCode,
    referredby: data.referredBy,
    expertstatusstepid: data.expertStatusStep,
    jericho_id: data.previousJericho,
    vendor_id: data.vendor,
    biography: data.biography,
    salutation: data.salutation,
    maxfilesize: data.maxFileSizeMB,
    maxpagecount: data.maxPageCount,
    maxfaxedpages: data.maxFaxedPages,
    expertuserid: data.expertUserId,
    zebraUserId: data.currentUserId,
  };

  const response = await helper.request.post(`/SaveExpertData`, body);

  if (response.data !== 1) {
    throw new Error(response.data);
  }

  return response.data;
};

export default {
  saveExpertGroup,
  saveExpert,
  loadExpertGroup,
  loadExpert,
  getInitialData,
};
