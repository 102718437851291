import React, { createContext, useReducer } from 'react';
import { isNil } from 'lodash';

export const CheckBoxContext = createContext();

// const useCheckBoxContext = () => useContext(checkBoxContext);


const initialState = {
    displayAdjuster: false,
    displayAttorneyName: false,
    displayBilledStatus: false,
    displayBilledDate: false,
    displayCaseManager: false,
    displayCity: false,
    displayClaimant: false,
    displayCompletedDate: false,
    displayDayBeforePTConf: false,
    displayDayBeforeFacilityConf: false,
    displayDayBeforeProviderConf: false,
    displayDOS: false,
    displayEnteredBy: false,
    displayEventId: false,
    displayFaxConfirmation: false,
    displayLastModifiedBy: false,
    displayLastModifiedDate: false,
    displayOrderDate: false,
    displayPayer: false,
    displayPhysicianName: false,
    displayPricing: false,
    displayProductType: false,
    displayReferrerName: false,
    displayUSState: false,
    displayType: false,
    displayVendorId: false,
    displayVendorName: false,
    displayVendorCityState: false,
    displayVendorExtra: false,
    displayPONumber: false,
    displayClientId: false,
    displayOutcome: false,
    displayReadyForBilling: false,
    displayCurrentlyAssignedTo: false,
    displayYorkClientName: false,
    displayYorkEmployerName: false,
    displayProgramName: false,
    displayServiceHCPC: false,
    displayServiceLocation: false,
    displayServiceRentals: false,
    displayServiceRentalStartDate: false,
    displayServiceRentalEndDate: false,
    displayServiceRentalFreq: false,
    displayServiceRenewal: false,
    displayAccommodationCheckInDateTime: false,
    displayAccommodationCheckOutDateTime: false,
    displayAccommodationNumberOfNights: false,
    displayAccommodationHotelAddress: false,
    displayAccommodationNumberOfRooms: false,
    displayAccommodationDateTimeCreated: false,
    displayAccommodationDateTimeBooked: false,
    displayAccommodationBookingSite: false,
    displayAccommodationHotelContact: false,
    displayTransportPickUpAddress: false,
    displayTransportDropOffAddress: false,
    displayTransportPickUpTime: false,
    displayTransportDropOffTime: false,
    displayTransportAppointmentType: false,
    displayTransportMileage: false,
    displayTransportStaffedBy: false,
    displayTransportTimeStaffed: false,
    displayTransportNumberOfLegs: false,
    displayTransportReturnType: false,
    displayTransportWaitingOnVendorConf: false,
    displayTransportDateTimeBooked: false,
    displayTransportationPickUpAddress: false,
    displayTransportationDropOffAddress: false,
    displayTransportationPickUpTime: false,
    displayTransportationDropOffTime: false,
    displayTransportationMileage: false,
    displayTransportationStaffedBy: false,
    displayTransportationTimeStaffed: false,
    displayTransportationNumberOfLegs: false,
    displayTransportationReturnType: false,
    displayTransportationWaitingOnVendorConf: false,
    displayTransportationDateTimeBooked: false,
    displaySplTransportationDropOffAddress: false,
    displaySplTransportationAppointmentType: false,
    displaySplTransportationDepartingDateTime: false,
    displaySplTransportationArrivingDateTime: false,
    displaySplTransportationDateTimeCreated: false,
    displaySplTransportationDateTimeBooked: false,
    displaySplTransportationAirline: false,
    displaySplTransportationDepartingAirport: false,
    displaySplTransportationArrivingAirport: false,
    displaySplTransportationFlightClass: false,
    displaySplTransportationBookingSite: false,
    displaySplTransportationLayOverTime: false,
   
    displayInterpretationAddress: false,
    displayInterpretationTime: false,
    displayInterpretationStartTime: false,
    displayInterpretationLanguage: false,
    displayInterpretationMileage: false,
    displayInterpretationTimeStaffed: false,
    displayInterpretationStaffedBy: false,
    displayInterpretationArrivalTime: false,
    displayInterpretationAppointmentType: false,
    displayHHCHCPC: false,
    displayHHCServiceLocation: false,
};

const reducer = (state, action) => {
    const detailsValue = action.payload;
    switch (action.type) {
        case 'UPDATE_CheckBox':
            return {
                ...state,
                displayAdjuster: isNil(detailsValue.displayAdjuster) ? state.displayAdjuster : detailsValue.displayAdjuster,
                displayAttorneyName: isNil(detailsValue.displayAttorneyName) ? state.displayAttorneyName : detailsValue.displayAttorneyName,
                displayBilledStatus: isNil(detailsValue.displayBilledStatus) ? state.displayBilledStatus : detailsValue.displayBilledStatus,
                displayBilledDate: isNil(detailsValue.displayBilledDate) ? state.displayBilledDate : detailsValue.displayBilledDate,
                displayCaseManager: isNil(detailsValue.displayCaseManager) ? state.displayCaseManager : detailsValue.displayCaseManager,
                displayCity: isNil(detailsValue.displayCity) ? state.displayCity : detailsValue.displayCity,
                displayClaimant: isNil(detailsValue.displayClaimant) ? state.displayClaimant : detailsValue.displayClaimant,
                displayCompletedDate: isNil(detailsValue.displayCompletedDate) ? state.displayCompletedDate : detailsValue.displayCompletedDate,
                displayDayBeforePTConf: isNil(detailsValue.displayDayBeforePTConf) ? state.displayDayBeforePTConf : detailsValue.displayDayBeforePTConf,
                displayDayBeforeFacilityConf: isNil(detailsValue.displayDayBeforeFacilityConf) ? state.displayDayBeforeFacilityConf : detailsValue.displayDayBeforeFacilityConf,
                displayDayBeforeProviderConf: isNil(detailsValue.displayDayBeforeProviderConf) ? state.displayDayBeforeProviderConf : detailsValue.displayDayBeforeProviderConf,
                displayDOS: isNil(detailsValue.displayDOS) ? state.displayDOS : detailsValue.displayDOS,
                displayEnteredBy: isNil(detailsValue.displayEnteredBy) ? state.displayEnteredBy : detailsValue.displayEnteredBy,
                displayEventId: isNil(detailsValue.displayEventId) ? state.displayEventId : detailsValue.displayEventId,
                displayFaxConfirmation: isNil(detailsValue.displayFaxConfirmation) ? state.displayFaxConfirmation : detailsValue.displayFaxConfirmation,
                displayLastModifiedBy: isNil(detailsValue.displayLastModifiedBy) ? state.displayLastModifiedBy : detailsValue.displayLastModifiedBy,
                displayLastModifiedDate: isNil(detailsValue.displayLastModifiedDate) ? state.displayLastModifiedDate : detailsValue.displayLastModifiedDate,
                displayOrderDate: isNil(detailsValue.displayOrderDate) ? state.displayOrderDate : detailsValue.displayOrderDate,
                displayPayer: isNil(detailsValue.displayPayer) ? state.displayPayer : detailsValue.displayPayer,
                displayPhysicianName: isNil(detailsValue.displayPhysicianName) ? state.displayPhysicianName : detailsValue.displayPhysicianName,
                displayPricing: isNil(detailsValue.displayPricing) ? state.displayPricing : detailsValue.displayPricing,
                displayProductType: isNil(detailsValue.displayProductType) ? state.displayProductType : detailsValue.displayProductType,
                displayReferrerName: isNil(detailsValue.displayReferrerName) ? state.displayReferrerName : detailsValue.displayReferrerName,
                displayUSState: isNil(detailsValue.displayUSState) ? state.displayUSState : detailsValue.displayUSState,
                displayType: isNil(detailsValue.displayType) ? state.displayType : detailsValue.displayType,
                displayVendorId: isNil(detailsValue.displayVendorId) ? state.displayVendorId : detailsValue.displayVendorId,
                displayVendorName: isNil(detailsValue.displayVendorName) ? state.displayVendorName : detailsValue.displayVendorName,
                displayVendorCityState: isNil(detailsValue.displayVendorCityState) ? state.displayVendorCityState : detailsValue.displayVendorCityState,
                displayVendorExtra: isNil(detailsValue.displayVendorExtra) ? state.displayVendorExtra : detailsValue.displayVendorExtra,
                displayPONumber: isNil(detailsValue.displayPONumber) ? state.displayPONumber : detailsValue.displayPONumber,
                displayClientId: isNil(detailsValue.displayClientId) ? state.displayClientId : detailsValue.displayClientId,
                displayClientName: isNil(detailsValue.displayClientName) ? state.displayClientName : detailsValue.displayClientName,
                displayOutcome: isNil(detailsValue.displayOutcome) ? state.displayOutcome : detailsValue.displayOutcome,
                displayReadyForBilling: isNil(detailsValue.displayReadyForBilling) ? state.displayReadyForBilling : detailsValue.displayReadyForBilling,
                displayCurrentlyAssignedTo: isNil(detailsValue.displayCurrentlyAssignedTo) ? state.displayCurrentlyAssignedTo : detailsValue.displayCurrentlyAssignedTo,
                displayYorkClientName: isNil(detailsValue.displayYorkClientName) ? state.displayYorkClientName : detailsValue.displayYorkClientName,
                displayYorkEmployerName: isNil(detailsValue.displayYorkEmployerName) ? state.displayYorkEmployerName : detailsValue.displayYorkEmployerName,
                displayProgramName: isNil(detailsValue.displayProgramName) ? state.displayProgramName : detailsValue.displayProgramName,
                displayServiceHCPC: isNil(detailsValue.displayServiceHCPC) ? state.displayServiceHCPC : detailsValue.displayServiceHCPC,
                displayServiceLocation: isNil(detailsValue.displayServiceLocation) ? state.displayServiceLocation : detailsValue.displayServiceLocation,
                displayServiceRentals: isNil(detailsValue.displayServiceRentals) ? state.displayServiceRentals : detailsValue.displayServiceRentals,
                displayServiceRentalStartDate: isNil(detailsValue.displayServiceRentalStartDate) ? state.displayServiceRentalStartDate : detailsValue.displayServiceRentalStartDate,
                displayServiceRentalEndDate: isNil(detailsValue.displayServiceRentalEndDate) ? state.displayServiceRentalEndDate : detailsValue.displayServiceRentalEndDate,
                displayServiceRentalFreq: isNil(detailsValue.displayServiceRentalFreq) ? state.displayServiceRentalFreq : detailsValue.displayServiceRentalFreq,
                displayServiceRenewal: isNil(detailsValue.displayServiceRenewal) ? state.displayServiceRenewal : detailsValue.displayServiceRenewal,
                displayAccommodationCheckInDateTime: isNil(detailsValue.displayAccommodationCheckInDateTime) ? state.displayAccommodationCheckInDateTime : detailsValue.displayAccommodationCheckInDateTime,
                displayAccommodationCheckOutDateTime: isNil(detailsValue.displayAccommodationCheckOutDateTime) ? state.displayAccommodationCheckOutDateTime : detailsValue.displayAccommodationCheckOutDateTime,
                displayAccommodationNumberOfNights: isNil(detailsValue.displayAccommodationNumberOfNights) ? state.displayAccommodationNumberOfNights : detailsValue.displayAccommodationNumberOfNights,
                displayAccommodationNumberOfRooms: isNil(detailsValue.displayAccommodationNumberOfRooms) ? state.displayAccommodationNumberOfRooms : detailsValue.displayAccommodationNumberOfRooms,
                displayAccommodationHotelAddress: isNil(detailsValue.displayAccommodationHotelAddress) ? state.displayAccommodationHotelAddress : detailsValue.displayAccommodationHotelAddress,
                displayAccommodationDateTimeCreated: isNil(detailsValue.displayAccommodationDateTimeCreated) ? state.displayAccommodationDateTimeCreated : detailsValue.displayAccommodationDateTimeCreated,
                displayAccommodationDateTimeBooked: isNil(detailsValue.displayAccommodationDateTimeBooked) ? state.displayAccommodationDateTimeBooked : detailsValue.displayAccommodationDateTimeBooked,
                displayAccommodationBookingSite: isNil(detailsValue.displayAccommodationBookingSite) ? state.displayAccommodationBookingSite : detailsValue.displayAccommodationBookingSite,
                displayAccommodationHotelContact: isNil(detailsValue.displayAccommodationHotelContact) ? state.displayAccommodationHotelContact : detailsValue.displayAccommodationHotelContact,
                displayTransportPickUpAddress: isNil(detailsValue.displayTransportPickUpAddress) ? state.displayTransportPickUpAddress : detailsValue.displayTransportPickUpAddress,
                displayTransportDropOffAddress: isNil(detailsValue.displayTransportDropOffAddress) ? state.displayTransportDropOffAddress : detailsValue.displayTransportDropOffAddress,
                displayTransportPickUpTime: isNil(detailsValue.displayTransportPickUpTime) ? state.displayTransportPickUpTime : detailsValue.displayTransportPickUpTime,
                displayTransportDropOffTime: isNil(detailsValue.displayTransportDropOffTime) ? state.displayTransportDropOffTime : detailsValue.displayTransportDropOffTime,
                displayTransportAppointmentType: isNil(detailsValue.displayTransportAppointmentType) ? state.displayTransportAppointmentType : detailsValue.displayTransportAppointmentType,
                displayTransportMileage: isNil(detailsValue.displayTransportMileage) ? state.displayTransportMileage : detailsValue.displayTransportMileage,
                displayTransportStaffedBy: isNil(detailsValue.displayTransportStaffedBy) ? state.displayTransportStaffedBy : detailsValue.displayTransportStaffedBy,
                displayTransportTimeStaffed: isNil(detailsValue.displayTransportTimeStaffed) ? state.displayTransportTimeStaffed : detailsValue.displayTransportTimeStaffed,
                displayTransportNumberOfLegs: isNil(detailsValue.displayTransportNumberOfLegs) ? state.displayTransportNumberOfLegs : detailsValue.displayTransportNumberOfLegs,
                displayTransportReturnType: isNil(detailsValue.displayTransportReturnType) ? state.displayTransportReturnType : detailsValue.displayTransportReturnType,
                displayTransportWaitingOnVendorConf: isNil(detailsValue.displayTransportWaitingOnVendorConf) ? state.displayTransportWaitingOnVendorConf : detailsValue.displayTransportWaitingOnVendorConf,
                displayTransportDateTimeBooked: isNil(detailsValue.displayTransportDateTimeBooked) ? state.displayTransportDateTimeBooked : detailsValue.displayTransportDateTimeBooked,
                displayTransportationPickUpAddress: isNil(detailsValue.displayTransportationPickUpAddress) ? state.displayTransportationPickUpAddress : detailsValue.displayTransportationPickUpAddress,
                displayTransportationDropOffAddress: isNil(detailsValue.displayTransportationDropOffAddress) ? state.displayTransportationDropOffAddress : detailsValue.displayTransportationDropOffAddress,
                displayTransportationPickUpTime: isNil(detailsValue.displayTransportationPickUpTime) ? state.displayTransportationPickUpTime : detailsValue.displayTransportationPickUpTime,
                displayTransportationDropOffTime: isNil(detailsValue.displayTransportationDropOffTime) ? state.displayTransportationDropOffTime : detailsValue.displayTransportationDropOffTime,
                displayTransportationMileage: isNil(detailsValue.displayTransportationMileage) ? state.displayTransportationMileage : detailsValue.displayTransportationMileage,
                displayTransportationStaffedBy: isNil(detailsValue.displayTransportationStaffedBy) ? state.displayTransportationStaffedBy : detailsValue.displayTransportationStaffedBy,
                displayTransportationTimeStaffed: isNil(detailsValue.displayTransportationTimeStaffed) ? state.displayTransportationTimeStaffed : detailsValue.displayTransportationTimeStaffed,
                displayTransportationNumberOfLegs: isNil(detailsValue.displayTransportationNumberOfLegs) ? state.displayTransportationNumberOfLegs : detailsValue.displayTransportationNumberOfLegs,
                displayTransportationReturnType: isNil(detailsValue.displayTransportationReturnType) ? state.displayTransportationReturnType : detailsValue.displayTransportationReturnType,
                displayTransportationWaitingOnVendorConf: isNil(detailsValue.displayTransportationWaitingOnVendorConf) ? state.displayTransportationWaitingOnVendorConf : detailsValue.displayTransportationWaitingOnVendorConf,
                displayTransportationDateTimeBooked: isNil(detailsValue.displayTransportationDateTimeBooked) ? state.displayTransportationDateTimeBooked : detailsValue.displayTransportationDateTimeBooked,
                displaySplTransportationDropOffAddress: isNil(detailsValue.displaySplTransportationDropOffAddress) ? state.displaySplTransportationDropOffAddress : detailsValue.displaySplTransportationDropOffAddress,
                displaySplTransportationAppointmentType: isNil(detailsValue.displaySplTransportationAppointmentType) ? state.displaySplTransportationAppointmentType : detailsValue.displaySplTransportationAppointmentType,
                displaySplTransportationDepartingDateTime: isNil(detailsValue.displaySplTransportationDepartingDateTime) ? state.displaySplTransportationDepartingDateTime : detailsValue.displaySplTransportationDepartingDateTime,
                displaySplTransportationArrivingDateTime: isNil(detailsValue.displaySplTransportationArrivingDateTime) ? state.displaySplTransportationArrivingDateTime : detailsValue.displaySplTransportationArrivingDateTime,
                displaySplTransportationDateTimeCreated: isNil(detailsValue.displaySplTransportationDateTimeCreated) ? state.displaySplTransportationDateTimeCreated : detailsValue.displaySplTransportationDateTimeCreated,
                displaySplTransportationDateTimeBooked: isNil(detailsValue.displaySplTransportationDateTimeBooked) ? state.displaySplTransportationDateTimeBooked : detailsValue.displaySplTransportationDateTimeBooked,
                displaySplTransportationAirline: isNil(detailsValue.displaySplTransportationAirline) ? state.displaySplTransportationAirline : detailsValue.displaySplTransportationAirline,
                displaySplTransportationDepartingAirport: isNil(detailsValue.displaySplTransportationDepartingAirport) ? state.displaySplTransportationDepartingAirport : detailsValue.displaySplTransportationDepartingAirport,
                displaySplTransportationArrivingAirport: isNil(detailsValue.displaySplTransportationArrivingAirport) ? state.displaySplTransportationArrivingAirport : detailsValue.displaySplTransportationArrivingAirport,
                displaySplTransportationFlightClass: isNil(detailsValue.displaySplTransportationFlightClass) ? state.displaySplTransportationFlightClass : detailsValue.displaySplTransportationFlightClass,
                displaySplTransportationBookingSite: isNil(detailsValue.displaySplTransportationBookingSite) ? state.displaySplTransportationBookingSite : detailsValue.displaySplTransportationBookingSite,
                displaySplTransportationLayOverTime: isNil(detailsValue.displaySplTransportationLayOverTime) ? state.displaySplTransportationLayOverTime : detailsValue.displaySplTransportationLayOverTime,
                displayInterpretationAddress: isNil(detailsValue.displayInterpretationAddress) ? state.displayInterpretationAddress : detailsValue.displayInterpretationAddress,
                displayInterpretationTime: isNil(detailsValue.displayInterpretationTime) ? state.displayInterpretationTime : detailsValue.displayInterpretationTime,
                displayInterpretationStartTime: isNil(detailsValue.displayInterpretationStartTime) ? state.displayInterpretationStartTime : detailsValue.displayInterpretationStartTime,
                displayInterpretationLanguage: isNil(detailsValue.displayInterpretationLanguage) ? state.displayInterpretationLanguage : detailsValue.displayInterpretationLanguage,
                displayInterpretationMileage: isNil(detailsValue.displayInterpretationMileage) ? state.displayInterpretationMileage : detailsValue.displayInterpretationMileage,
                displayInterpretationTimeStaffed: isNil(detailsValue.displayInterpretationTimeStaffed) ? state.displayInterpretationTimeStaffed : detailsValue.displayInterpretationTimeStaffed,
                displayInterpretationStaffedBy: isNil(detailsValue.displayInterpretationStaffedBy) ? state.displayInterpretationStaffedBy : detailsValue.displayInterpretationStaffedBy,
                displayInterpretationArrivalTime: isNil(detailsValue.displayInterpretationArrivalTime) ? state.displayInterpretationArrivalTime : detailsValue.displayInterpretationArrivalTime,
                displayInterpretationAppointmentType: isNil(detailsValue.displayInterpretationAppointmentType) ? state.displayInterpretationAppointmentType : detailsValue.displayInterpretationAppointmentType,
                displayHHCHCPC: isNil(detailsValue.displayHHCHCPC) ? state.displayHHCHCPC : detailsValue.displayHHCHCPC,
                displayHHCServiceLocation: isNil(detailsValue.displayHHCServiceLocation) ? state.displayHHCServiceLocation : detailsValue.displayHHCServiceLocation,
                setAudiolgy:detailsValue.setAudiolgy,
                setDental:detailsValue.setDental,
            }

    case 'RESET':
        return{
            ...state,
            displayServiceHCPC: '',
            displayServiceLocation: '',
            displayServiceRentals: '',
            displayServiceRentalStartDate: '',
            displayServiceRentalEndDate: '',
            displayServiceRentalFreq: '',
            displayServiceRenewal: '',
            displayTransportPickUpAddress: '',
            displayTransportDropOffAddress: '',
            displayTransportPickUpTime: '',
            displayTransportDropOffTime: '',
            displayTransportAppointmentType: '',
            displayTransportMileage: '',
            displayTransportStaffedBy: '',
            displayTransportTimeStaffed: '',
            displayTransportNumberOfLegs: '',
            displayTransportDateTimeBooked: '',
            displayTransportWaitingOnVendorConf: '',
            displayTransportDepartingDateTime: '',
            displayTransportArrivingDateTime: '',
            displayTransportDateTimeCreated: '',
            displayTransportDateTimeBooked: '',
            displayTransportAirline: '',
            displayTransportDepartingAirport: '',
            displayTransportArrivingAirport: '',
            displayTransportFlightClass: '',
            displayTransportBookingSite: '',
            displayTransportLayOverTime: '',
            displayTransportReturnType: '',
        }
        case 'CHECKBOX_CONTEXT_RESET':
            return initialState
    }
}

export const CheckBoxContextProvider = ({ children }) => {
    const currentState = children.props.children.props?.storedState1 ? children.props.children.props.storedState1 : initialState;

    const [state1, dispatch1] = useReducer(reducer, currentState);

    return (
        <CheckBoxContext.Provider value={{ state1, dispatch1 }}>
            {children}
        </CheckBoxContext.Provider>
    );
};
