import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { formatMMDDYYYY } from 'shared/utils/shareUtils';

import {
  billMaintenanceSelectors,
  billMaintenanceActions,
} from '../../../store';

import TopNavActions from '../TopNavActions';

import PPOSetupTable from './PPOSetupTable';
import AddPPOSetupButton from './AddPPOSetupButton';

class PPOSetupContainer extends Component {
  addNewPPOSetup = () => {
    return <AddPPOSetupButton data={this.props.data} />;
  };

  terminateAllButton = () => {
    return (
      <AppTooltip
        text="Mass Terminate of PPO"
        id="terminateAllTooltip"
        placement="bottom"
      >
        <Button type="button" className="ml-2" onClick={this.terminateAll}>
          TERMINATE ALL
        </Button>
      </AppTooltip>
    );
  };

  terminateAll = () => {
    const terminatedData = this.props.data.map(item => {
      return {
        ...item,
        fields: {
          ...item.fields,
          dateRange: {
            ...item.fields.dateRange,
            termDate: formatMMDDYYYY(new Date()),
          },
        },
      };
    });
    this.props.updateTableData(terminatedData);
  };

  render() {
    return (
      <>
        <TopNavActions
          name={`${this.props.name}_topPPOSetup`}
          stateList={this.props.stateList}
          lobList={this.props.lobList}
          updateFilter={this.props.updateFilter}
          addNewButton={this.addNewPPOSetup}
          terminateAllButton={this.terminateAllButton}
          haveAddNewButton={this.props.haveAddNewButton}
          haveResetButton={this.props.haveResetButton}
          haveTerminateButton={this.props.haveTerminateButton}
        />
        <PPOSetupTable
          name={`${this.props.name}_tablePPOSetup`}
          isSelectable={this.props.isSelectable}
          onSelectRows={this.props.onSelectRows}
          data={this.props.data}
          updateTableData={this.props.updateTableData}
          lobList={this.props.lobList}
          stateList={this.props.stateList}
          clientNetworkList={this.props.clientNetworkList}
          ppoList={this.props.ppoList}
          editColumns={this.props.editColumns}
          filters={this.props.filters}
        />
      </>
    );
  }
}

PPOSetupContainer.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  stateList: PropTypes.array.isRequired,
  lobList: PropTypes.array.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateTableData: PropTypes.func.isRequired,
  clientNetworkList: PropTypes.array.isRequired,
  haveAddNewButton: PropTypes.bool,
  haveResetButton: PropTypes.bool,
  haveTerminateButton: PropTypes.bool,
};

PPOSetupContainer.defaultProps = {
  haveAddNewButton: true,
  haveTerminateButton: true,
  haveResetButton: true,
};

const mapStateToProps = state => {
  const initialDataState = billMaintenanceSelectors.getClientMaintenanceInitialData(
    state
  );
  const ppoSetupFilters = billMaintenanceSelectors.getClientMaintenancePPOSetupFilter(
    state
  );

  return {
    filters: ppoSetupFilters,
    stateList: initialDataState.clientComboBoxes.stateList,
    lobList: initialDataState.clientComboBoxes.lobList,
    clientNetworkList: initialDataState.clientComboBoxes.clientNetworkList,
    ppoList: initialDataState.clientComboBoxes.ppoList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFilter: data =>
      dispatch(billMaintenanceActions.updatePPOSetupFilter(data)),
    updateTableData: data =>
      dispatch(billMaintenanceActions.updatePPOSetupTable(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PPOSetupContainer);
