import React, { Component, useState } from 'react';
import { AppTableBodyPropType } from './AppTablePropTypes';
import { SelectableCell } from './SelectableCell';
import { ClickableCell } from './ClickableCell';
import BillDetailDeny from '../../../modules/bill-operations/components/BillDetailDeny';
import FeelLookUpBillReviewModal from '../../../modules/bill-operations/components/FeelLookUpBillReviewModal';
import { Form, FormGroup, Label } from 'reactstrap';
import { Input } from 'reactstrap';
import { billReviewBills } from '../../../modules/bill-operations/utils/constantUtils';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsSelectors,
} from '../../../modules/bill-operations/store';
import { connect } from 'react-redux';

class BillReviewAppTableBodyRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      highlightedRow: -1,
      isOpen: false,
      selectedReason: -1,
      dropdownState: 'Undecided',
      selectedDec: '',
      rowIndex: null,
      flag: 0,
      netSavInput:
        this.props.dataObject.negReduct !== ''
          ? this.props.dataObject.negReduct
          : '$0.00',
      extNonEmg:
        this.props.dataObject.extendedNonEmergency !== ''
          ? this.props.dataObject.extendedNonEmergency
          : '$0.00',
      extEmg:
        this.props.dataObject.extendedEmergency !== ''
          ? this.props.dataObject.extendedEmergency
          : '$0.00',
      medPay:
        this.props.dataObject.medPayAllocation !== ''
          ? this.props.dataObject.medPayAllocation
          : '$0.00',
      basicEmg:
        this.props.dataObject.basicEmergency !== ''
          ? this.props.dataObject.basicEmergency
          : '$0.00',
      basicNonEmg:
        this.props.dataObject.basicNonEmergency !== ''
          ? this.props.dataObject.basicNonEmergency
          : '$0.00',
      cptClicked: false,
      fromBillReview: true,
      selectedDenyReason: '',
      mcnsRowIndex: 0,
      reasonOthertext:'',
      miscCode1: '',
      miscCode2: '',
      reasonMTGNote: ''
    };
  }
  toggleSelectedRow = (id, checked) => {
    this.setState(prevState => {
      return checked
        ? this.addRowFromState(prevState, id)
        : this.removeRowFromState(prevState, id);
    }, this.executeSelectHandler);
  };

  removeRowFromState = (state, id) => {
    return this.updateSelectedRows(
      state,
      state.selectedRows.filter(row => row !== id)
    );
  };

  addRowFromState = (state, id) => {
    return this.updateSelectedRows(state, state.selectedRows.concat(id));
  };

  updateSelectedRows = (state, selectedRows) => {
    return {
      ...state,
      selectedRows,
    };
  };

  executeSelectHandler = () => {
    const selectedRows = this.state.selectedRows.map(id => {
      return this.props.data[id];
    });
    this.props.selectHandler(selectedRows);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedRows: [] });
  }

  onRowClicked = (dataObject, rowIndex, event) => {
    if (event.target.tagName === 'TD') {
      this.props.rowClickHandler(dataObject);
      this.setState({
        highlightedRow: rowIndex,
      });
    }
  };
  reasonOtherNote =(data)=>
  {
      let localdata = data
      this.setState({reasonOthertext:localdata})
      const payload = {        
        ...this.state,
        reasonOthertext: localdata        
      };
      this.props.postBillReviewData(payload, this.props.rowIndex);
  }
  reasonMTGNoteSet = (data) =>
  {
    let localdata = data;
    this.setState({reasonMTGNote:localdata});
    const payload = {        
      ...this.state,
      reasonMTGNote: localdata      
    };
    this.props.postBillReviewData(payload, this.props.rowIndex);
  }
  setCode1 = (data) => 
  {
    let localdata = data;
    this.setState({miscCode1:localdata});
    const payload = {        
      ...this.state,
      miscCode1: localdata      
    };
    this.props.postBillReviewData(payload, this.props.rowIndex);
  }
  setCode2 = (data) => 
  {
    let localdata = data;
    this.setState({miscCode2:localdata});
    const payload = {        
      ...this.state,
      miscCode2: localdata      
    };
    this.props.postBillReviewData(payload, this.props.rowIndex);
  }
  dropdownData = rowIndex => {
    return (
      <>
        <Input
          type="select"
          name="selectedDecision"
          onChange={event => this.handleInputChange(event, rowIndex)}
        >
          {billReviewBills.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </Input>
        <BillDetailDeny
          isOpen={this.state.isOpen}
          index={rowIndex}
          selected={this.state.selectedReason}
          claimant={this.props.claimant}
          billId={this.props.billId}
          siteId={this.props.siteId}
          getDenyReason={this.getDenyReason}
          reasonOtherNote ={this.reasonOtherNote}
          fromBillReview={true}
          dataObject = {this.props.dataObject}
          taskListData= {this.props.taskListData}
          returnData ={this.props.returnData}
          updateMap={this.props.updateMap}
          getUpdatedMap={this.props.getUpdatedMap}
          setCode1={this.setCode1}
          setCode2={this.setCode2}
          reasonMTGNote = {this.reasonMTGNoteSet}
        />
      </>
    );
  };

  billReviewPost = () => {
    this.props.postBillReviewData(this.state, this.props.rowIndex);
  };

  getDenyReason = data => {
    let index = this.props.rowIndex + 1;
    let finalData = data.filter(item => item.detailId === index);
    if (this.state.selectedDec === 'Deny')
      this.setState({ selectedDenyReason: finalData[finalData.length - 1].note }, () => {
        this.billReviewPost();
      });
  };

  handleMedPay = (event, rowIndex) => {
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      newValue = '$' + changedVal;
    }

    this.setState({ medPay: newValue });
  };
  handleBasicEmg = (event, rowIndex) => {
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      newValue = '$' + changedVal;
    }

    this.setState({ basicEmg: newValue });
  };
  handleBasicNonEmg = (event, rowIndex) => {
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      newValue = '$' + changedVal;
    }

    this.setState({ basicNonEmg: newValue });
  };

  handleEmg = (event, rowIndex) => {
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      newValue = '$' + changedVal;
    }
    this.setState({ extEmg: newValue });
  };

  handleNonEmg = (event, rowIndex) => {
    let changedVal = 0.0;
    let newValue = event.target.value;
    if (newValue.indexOf('$') !== -1) {
      changedVal = parseFloat(Number(event.target.value.split('$')[1])).toFixed(
        2
      );
    } else {
      changedVal = parseFloat(Number(event.target.value)).toFixed(2);
      newValue = '$' + changedVal;
    }
    this.setState({ extNonEmg: newValue });
  };

  handleBlur = (event, rowIndex, element) => {
    let val = parseFloat(Number(event.target.value.split('$')[1])).toFixed(2);
    if (element === 'medPay')
      this.setState({ medPay: '$' + val }, () => {
        this.billReviewPost();
      });
    else if (element === 'extNonEmg')
      this.setState({ extNonEmg: '$' + val }, () => {
        this.billReviewPost();
      });
    else if (element === 'extEmg')
      this.setState({ extEmg: '$' + val }, () => {
        this.billReviewPost();
      });
    else if (element === 'basicEmg')
      this.setState({ basicEmg: '$' + val }, () => {
        this.billReviewPost();
      });
    else if (element === 'basicNonEmg')
      this.setState({ basicNonEmg: '$' + val }, () => {
        this.billReviewPost();
      });
  };

  handleCptClick = rowIndex => {
    this.setState(prevState => ({
      cptClicked: !prevState.cptClicked,
      mcnsRowIndex: rowIndex,
    }));
  };

  handleCloseCptClick = () => {
    this.setState({ cptClicked: false });
  };

  handleInputChange = (event, rowIndex) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({ rowIndex: rowIndex });

    if (name === 'selectedDecision') {
      this.setState(
        {
          selectedDec: value,
        },
        () => {
          this.billReviewPost();
        }
      );
    }

    if (value == 'Deny' && this.state.selectedReason == -1) {
      this.setState(
        prevState => ({
          selectedReason: rowIndex,
        }),
        () => {
          this.billReviewPost();
        }
      );
    }

    if (
      this.state.selectedReason == rowIndex &&
      (value == 'Approve' || value == 'Undecided')
    ) {
      this.setState(
        {
          selectedReason: -1,
          isOpen: false,
        },
        () => {
          this.billReviewPost();
        }
      );
    }

    if (value == 'Approve') {
      var billed = this.props.dataObject.billed;
      billed = billed.substring(1);
      billed = billed.replace(/,/g, '');
      var intBilled = billed * 1;
      this.total = this.total + intBilled;

      this.setState(
        {
          total: 1,
        },
        () => {
          this.billReviewPost();
        }
      );

      this.props.setRembTotal(intBilled);
      this.setState(
        {
          flag: 1,
        },
        () => {
          this.billReviewPost();
        }
      );
    }

    if (value == 'Undecided' || value == 'Deny') {
      var billed = this.props.dataObject.billed;
      billed = billed.substring(1);
      billed = billed.replace(/,/g, '');
      var intBilled = billed * 1;
      this.total = this.total + intBilled;

      this.setState(
        {
          total: 1,
        },
        () => {
          this.billReviewPost();
        }
      );

      if (this.state.flag == 1) {
        this.props.removeRembTotal(intBilled);
        this.setState(
          {
            flag: 0,
          },
          () => {
            this.billReviewPost();
          }
        );
      }
    }
  };

  render() {
    const rowIndex = this.props.rowIndex;
    const dataObject = this.props.dataObject;

    return (
      <>
        {this.state.cptClicked && (
          <FeelLookUpBillReviewModal
            isFromBillReview={this.state.fromBillReview}
            BillReviewData={this.props.data}
            isOpen={this.state.cptClicked}
            mcnsRowIndex={this.state.mcnsRowIndex}
            taskListData={
              this.props.taskListData ? this.props.taskListData : []
            }
            onClose={this.handleCloseCptClick}
            start_date={dataObject.dos}
          />
        )}

        <tbody>
          <tr
            key={rowIndex}
            style={
              this.props.highlightedRow === rowIndex
                ? { backgroundColor: '#cdf0f9' }
                : null
            }
            onDoubleClick={
              this.props.isRowDoubleClickable
                ? () => this.props.rowDoubleClickHandler(this.props.returnData)
                : null
            }
            onClick={
              this.props.isRowClickable
                ? event => this.onRowClicked(dataObject, rowIndex, event)
                : null
            }
          >
            {this.props.isSelectable ? (
              <SelectableCell
                rowIndex={rowIndex}
                selectHandler={this.toggleSelectedRow}
                selected={this.state.selectedRows.includes(rowIndex)}
              />
            ) : null}
            {this.props.columns.map((column, columnIndex) => {
              if (column.render) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {column.render(dataObject, rowIndex)}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>
                    {column.render(dataObject, rowIndex)}
                  </td>
                );
              }

              if (column.header == 'Decision') {
                return (
                  <>
                    <th
                      key={columnIndex}
                      scope="row"
                      style={{ width: '180px' }}
                    >
                      {this.dropdownData(rowIndex)}
                    </th>
                  </>
                );
              }

              if (this.props.returnData === dataObject) {
                if (column.header == 'Extended Non-Emergency') {
                  return (
                    !column.hide && (
                      <td key={columnIndex} style={{ width: '60px' }}>
                        <FormGroup>
                          <Input
                            type="text"
                            id="ExtendedNonEmergency"
                            value={this.state.extNonEmg}
                            onChange={event =>
                              this.handleNonEmg(event, rowIndex)
                            }
                            onBlur={event =>
                              this.handleBlur(event, rowIndex, 'extNonEmg')
                            }
                          />
                        </FormGroup>
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'Extended Emergency') {
                  return (
                    !column.hide && (
                      <td key={columnIndex} style={{ width: '60px' }}>
                        <FormGroup>
                          <Input
                            type="text"
                            id="ExtendedEmergency"
                            value={this.state.extEmg}
                            onChange={event => this.handleEmg(event, rowIndex)}
                            onBlur={event =>
                              this.handleBlur(event, rowIndex, 'extEmg')
                            }
                          />
                        </FormGroup>
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'Total Paid') {
                  return (
                    !column.hide && (
                      <td key={columnIndex}>
                        {dataObject[column.field] || ''}
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'MedPay Allocations') {
                  return (
                    !column.hide && (
                      <td key={columnIndex} style={{ width: '60px' }}>
                        <FormGroup>
                          <Input
                            type="text"
                            id="MedPayAllocation"
                            value={this.state.medPay}
                            onChange={event =>
                              this.handleMedPay(event, rowIndex)
                            }
                            onBlur={event =>
                              this.handleBlur(event, rowIndex, 'medPay')
                            }
                          />
                        </FormGroup>
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'Basic Emergency') {
                  return (
                    !column.hide && (
                      <td key={columnIndex} style={{ width: '60px' }}>
                        <FormGroup>
                          <Input
                            type="text"
                            id="BasicEmgValue"
                            value={this.state.basicEmg}
                            onChange={event =>
                              this.handleBasicEmg(event, rowIndex)
                            }
                            onBlur={event =>
                              this.handleBlur(event, rowIndex, 'basicEmg')
                            }
                          />
                        </FormGroup>
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'Basic Non-Emergency') {
                  return (
                    !column.hide && (
                      <td key={columnIndex} style={{ width: '60px' }}>
                        <FormGroup>
                          <Input
                            type="text"
                            id="BasicNonEmgValue"
                            value={this.state.basicNonEmg}
                            onChange={event =>
                              this.handleBasicNonEmg(event, rowIndex)
                            }
                            onBlur={event =>
                              this.handleBlur(event, rowIndex, 'basicNonEmg')
                            }
                          />
                        </FormGroup>
                      </td>
                    )
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'Network Savings') {
                  return (
                    <td key={columnIndex} style={{ width: '100px' }}>
                      <FormGroup>
                        <Input
                          type="text"
                          id="NetSavings"
                          value={this.state.netSavInput}
                          disabled={true}
                        />
                      </FormGroup>
                    </td>
                  );
                }
              }
              if (this.props.returnData === dataObject) {
                if (column.header == 'CPT') {
                  return (
                    <td
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => this.handleCptClick(rowIndex)}
                    >
                      {dataObject.cpt}
                    </td>
                  );
                }
              }

              if (this.props.returnData === dataObject) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {dataObject[column.field] || ''}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>{dataObject[column.field] || ''}</td>
                );
              }

              if (this.props.returnData === dataObject.fields) {
                if (column.rowKey) {
                  return (
                    <th key={columnIndex} scope="row">
                      {dataObject[column.field] || ''}
                    </th>
                  );
                }

                return (
                  <td key={columnIndex}>
                    {dataObject.fields[column.field] ||
                      dataObject.fields.dateRange[column.field] ||
                      ''}
                  </td>
                );
              }
            })}
            {this.props.isClickable ? (
              <ClickableCell
                clickHandler={() => this.props.clickHandler(dataObject)}
                location="/administration/user"
              />
            ) : null}
          </tr>
        </tbody>
      </>
    );
  }
}

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);

  return {
    totalFromState: billReviewState.rembTotal,
    //denyReasonFromState: billReviewState.denyReasons ? billReviewState.denyReasons : [],
    mcnsFromState: billReviewState.mcnsEditvalues
      ? billReviewState.mcnsEditvalues
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  setRembTotal: total => dispatch(billOperationsActions.setRembTotal(total)),
  removeRembTotal: total =>
    dispatch(billOperationsActions.removeRembTotal(total)),
  setMcnsEditVal: value =>
    dispatch(billOperationsActions.setMcnsEditVal(value)),
  setProvReimb: value => dispatch(billOperationsActions.setProvReimb(value)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

BillReviewAppTableBodyRow.propTypes = AppTableBodyPropType;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillReviewAppTableBodyRow);
