import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { authSelectors } from 'modules/auth/store';

import * as billOperationsConstants from '../store/constants';
import { billOperationsOperations, billOperationsSelectors } from '../store';

import {
  findBillsGridSettingName,
  findBillsGridSettingModuleId,
} from '../utils/constantUtils';

import BillReviewTopNav from './TopNav';
import FindBillsSearch from './FindBillsSearch';
import FindBillsAdvancedSearch from './FindBillsAdvancedSearch';
import BillSearchResults from './BillSearchResults';
import ClaimSearchResults from './ClaimSearchResults';

const FindBills = ({
  searchResults,
  sites,
  currentUserId,
  profile,
  getGridSettings,
  stateList,
  stateDocuments,
  billsInClaim,
  initialStateData
}) => {
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [showSimpleSearch, setShowSimpleSearch] = useState(true);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [searchMode, setSearchMode] = useState('');
  const [advanceData, setAdvanceData] = useState(null);
  const [isFromManageBill, setIsFromManageBill] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(null);





  useEffect(() => {
    if (currentUserId && currentUserId !== 0 && profile && profile !== '') {
      getGridSettings({
        gridName: findBillsGridSettingName,
        userId: currentUserId,
        moduleId: findBillsGridSettingModuleId,
        profile,
        zebraUserId: currentUserId,
      });
    }
  }, [currentUserId, profile, getGridSettings]);

  const onChangeSearch = event => {
    const targetId = event.target.id;

    switch (targetId) {
      case billOperationsConstants.SHOW_ADVANCED_SEARCH:
        setShowSimpleSearch(false);
        setShowAdvancedSearch(true);

        break;
      case billOperationsConstants.CLOSE_ADVANCED_SEARCH:
        setShowSimpleSearch(true);
        setShowAdvancedSearch(false);
        setSearchMode('false');
        //setIsFromManageBill(false);
        setAdvanceData(null);


        break;
      default:
    }
  };

  const onSendAdvanceData = data => {
    let dataClone = { ...data }
    let stateAbb = initialStateData.filter(t => t.abbreviation == data.state_id);
    dataClone.state_id = stateAbb.length && stateAbb[0].abbreviation;
    setAdvanceData(dataClone);
    setIsFromManageBill(true);
  }

  const toggleSearch = () => {
    setShowSearchBar(!showSearchBar);
  };

  const changeSearchType = searchType => {
    setSearchType(searchType);
  };

  const changeSearchMode = searchMode => {
    setSearchMode(searchMode);
  };


  return (
    <div id="findBillsContainer">
      <BillReviewTopNav />
      {showSearchBar && showSimpleSearch ? (
        <FindBillsSearch
          changeSearchHandler={onChangeSearch}
          changeSearchType={changeSearchType}
          currentUserId={currentUserId}
          profile={profile}
          changeSearchMode={changeSearchMode}
          sendAdvanceData={onSendAdvanceData}
        />
      ) : null}

      {showSearchBar && showAdvancedSearch ? (
        <FindBillsAdvancedSearch
          changeSearchHandler={onChangeSearch}
          changeSearchType={changeSearchType}
          currentUserId={currentUserId}
          profile={profile}
          changeSearchMode={searchMode}
          sendAdvanceData={advanceData}
          fromManageBill={isFromManageBill}
          setIsFromManageBill={setIsFromManageBill}
          setSearchCriteria={setSearchCriteria}

        />
      ) : null}

      {searchType === 'BILL' || isFromManageBill ? (
        <BillSearchResults
          searchResults={searchResults}
          sites={sites}
          stateDocuments={stateDocuments}
          stateList={stateList}
          currentUserId={currentUserId}
          profile={profile}
          toggleSearch={toggleSearch}
          isFromManageBill={isFromManageBill}
          envRef={true}
          
        />
      )
        : (
          <ClaimSearchResults
            searchResults={searchResults}
            sites={sites}
            toggleSearch={toggleSearch}
            stateList={stateList}
            stateDocuments={stateDocuments}
            billsInClaim={billsInClaim}
            currentUserId={currentUserId}
            profile={profile}
            isFromManageBill={isFromManageBill}
            envRef={true}
            searchCriteria={searchCriteria}
          />
        )}

    </div>
  );
};

FindBills.propTypes = {
  searchResults: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  billsInClaim: PropTypes.array.isRequired,
  profile: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  getGridSettings: PropTypes.func.isRequired,
  stateList: PropTypes.array.isRequired,
  stateDocuments: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const findBills = billOperationsSelectors.getFindBills(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);

  return {
    searchResults: findBills.findResults,
    billsInClaim: findBills.billsInClaim,
    sites: initialData.slpsites,
    stateList: initialData.stateDocuments.stateList,
    stateDocuments: initialData.stateDocuments.documents,
    profile: initialData.currentProfile || '',
    currentUserId: user.id || 0,
    initialStateData: initialData.states
  };
};

const mapDispatchToProps = dispatch => ({
  getGridSettings: data =>
    dispatch(billOperationsOperations.getGridSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindBills);
