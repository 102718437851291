import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { billOperationsSelectors, billOperationsOperations } from '../store';
import { commonOperations } from 'common/store';
import { selectedByGroup } from '../utils/billUtils';

const GroupBySelection = ({
  groupBy,
  groupBySelection,
  selectedBills,
  id,
  reviewType,
  name,
  currentProfile,
  selectedAdjusterId,
  selectedAdjusterName,
  findBills,
  initialUsers,
  alert,
  updateSelectedBills,
  bills,
  updateGroupBySelection,
}) => {
  const handleGroupBySelectionChange = event => {
    const groupBySelection = event.target.value;
    if(selectedAdjusterName === '' && reviewType === "adjusters") {
      alert('You must Select a Adjuster to view data.');
      return;
    } else if(reviewType === "adjusters"){
    const selectedAdjusterId = initialUsers['adjusters'].length ? initialUsers.adjusters.filter(t => t.name == selectedAdjusterName)[0].id: 0;
    findBills(Number(id), selectedAdjusterName, currentProfile, Number(selectedAdjusterId), "adjusters")
    }
    updateGroupBySelection(groupBySelection);
    if (selectedBills.length < 1) {
      return;
    }
    // const selectedBillItem = R.find(
    //   item => item.id === selectedBills[selectedBills.length - 1],
    //   bills
    // );
    // console.log("selectedBillItem", selectedBillItem);
    // const groupSelectedBillIds = bills
    //   .filter(item => selectedByGroup(item, selectedBillItem, groupBySelection))
    //   .map(item => item.id);
    // updateSelectedBills([...groupSelectedBillIds, ...selectedBills]);
  };

  return (
    <Input
      type="select"
      name="groupBySelection"
      value={groupBy}
      onChange={handleGroupBySelectionChange}
    >
      {groupBySelection.map((item, index) => (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      ))}
    </Input>
  );
};

GroupBySelection.propTypes = {
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  bills: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const approveBillState = billOperationsSelectors.getApproveBillState(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  return {
    initialUsers: approveBillState.users,
    currentProfile: initialData.currentProfile,
    id: state.AUTH.user.user_id,
    reviewType: approveBillState.reviewType,
    selectedAdjusterName: approveBillState.selectedAdjusterName,
  };
};


const mapDispatchToProps = dispatch => ({
  findBills: (userId, name, profile, adjuster, reviewType) =>
    dispatch(
      billOperationsOperations.findApproveBills(
        userId,
        name,
        profile,
        adjuster,
        reviewType
      )
    ),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupBySelection);