import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { CustomInput, Input } from 'reactstrap';

import AppTable from 'shared/components/table/AppTable';

import './SiteSettingsTab.scss';

class SiteSettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upArrow: true,
      checkedSiteList: [],
    };
  }

  onSort = () => {
    this.setState(prevState => ({
      upArrow: !prevState.upArrow,
    }));
  };

  buildDescriptionHeader = name => {
    return (
      <span className="cursor-hover" onClick={this.onSort}>
        {name}
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.upArrow ? faArrowUp : faArrowDown}
        />
      </span>
    );
  };
  buildCheckBoxHeader = () => (
    <CustomInput
      id="checkbox-select-all"
      type="checkbox"
      onChange={this.onCheckAllSites}
    />
  );

  buildSitesTableData = () => {
    return this.props.settingsList
      .filter(item => this.props.choices.includes(item.description))
      .sort((prev, next) =>
        this.state.upArrow
          ? prev.description.localeCompare(next.description)
          : next.description.localeCompare(prev.description)
      )
      .map(site => ({
        description: (
          <Input
            type="text"
            value={site.description}
            onChange={this.onSiteSettingChange}
          />
        ),
        checkBox: (
          <CustomInput
            type="checkbox"
            id={`${site.key}-checkbox`}
            checked={this.getSiteCheckedValue(site)}
            onChange={e => this.onCheckSite(e, site)}
          />
        ),
      }));
  };

  getSiteCheckedValue = site => this.state.checkedSiteList.includes(site);

  onCheckSite = (e, site) => {
    const result = e.target.checked
      ? this.state.checkedSiteList.concat(site)
      : this.state.checkedSiteList.filter(item => site.key !== item.key);
    this.setState({
      checkedSiteList: result,
    });
    this.props.updateSettingsList(result);
  };
  onCheckAllSites = e => {
    const result = e.target.checked ? this.props.settingsList.slice() : [];
    this.setState({
      checkedSiteList: result,
    });
    this.props.updateSettingsList(result);
  };

  onSiteSettingChange = e => {};

  render() {
    return (
      <div className="site-setting-tab-wrapper">
        <AppTable
          resizable
          columns={[
            {
              header: this.buildDescriptionHeader('Description'),
              rowKey: true,
              field: 'description',
            },
            {
              header: this.buildCheckBoxHeader(),
              field: 'checkBox',
            },
          ]}
          data={this.buildSitesTableData()}
          striped
        ></AppTable>
      </div>
    );
  }
}

SiteSettingsTab.propTypes = {
  settingsList: PropTypes.array.isRequired,
  updateSettingsList: PropTypes.func,
};
export default SiteSettingsTab;
