import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { fast360Operations, fast360Selectors, fast360Actions } from '../store';


class TaskHover360 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChecked: true,
            list: []
        };
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount() {

        let mile = [], data = [], checkVal = [];
        this.props.milestoneData.map(i => i.isChecked && mile.push(i.name))

        let mLength = this.props.milestoneData.length;

        if (!this.props.taskChanged && mLength === 0)
            this.props.dataFromReducer.tasks.map(item => data.push({ ...item, 'isChecked': true }))
        if (!this.props.taskChanged || (this.props.prevUser != '' && this.props.userSelected != this.props.prevUser))
            this.props.dataFromReducer.tasks.map(item => mile.includes(item.milestoneName) == true && data.push({ ...item, 'isChecked': true }))
        if (this.props.taskChanged) {
            if (this.props.milestoneClickedAgain)
                this.props.dataFromReducer.tasks.map(item => data.push({ ...item, 'isChecked': true }))
            else
                data = this.props.taskData;
        }

        data.map(i => checkVal.push(i.isChecked));
        if (checkVal.includes(false))
            this.setState({ allChecked: false });
        this.setState({ list: data })

        if(this.props.taskBackUp && this.props.taskBackUp.length === 0) {

            this.props.setTaskBackUp(this.props.dataFromReducer.initialWorkList);
        }

    }
    toggle = () => {
        this.props.clicked()
    };

    handleChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { list, allChecked } = prevState;
            if (itemName === "checkAll") {
                allChecked = checked;
                list = list.map(item => ({ ...item, isChecked: checked }));
            } else {
                list = list.map(item =>
                    item.chr_name === itemName ? { ...item, isChecked: checked } : item
                );
                allChecked = list.every(item => item.isChecked);
            }
            return { list, allChecked };
        }, () => {
            this.filterSelectedItems();
            this.props.updateTaskClicked(true);
            this.props.milestoneAgainClicked(false);
            this.props.updateTaskData(this.state.list);
            this.props.updatePreviousUser(this.props.userSelected);
            this.props.setSelectedType('task');
            this.props.setSelectedFilterType('task');
        });
    };

    filterSelectedItems = () => {
        let selected = [], selectedWorkList = [];
        let selectedItems = this.state.list.filter(i => i.isChecked == true);
        selectedItems.map(i => selected.push(i.chr_name.toLowerCase().replace(/ /g, '')));
        // if (this.props.milestoneChanged)
        //     this.props.milestoneWorkList.map(i => i.currentTaskName && selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) == true && selectedWorkList.push(i));
        // else

        this.props.taskBackUp.map(i => selected.includes(i.currentTaskName.toLowerCase().replace(/ /g, '')) === true && selectedWorkList.push(i));
        this.props.updateNewUsersWorkList(selectedWorkList);
        this.props.TaskWorkListBackup(selectedWorkList);
    }
    componentWillUnmount() {
        // this.props.milestoneAgainClicked(true);
    }


    render() {

        return (
            <Modal size="md" isOpen={true} style={{ height: '300px' }} scrollable={true}>
                <ModalHeader toggle={this.toggle}>
                    Task:
                </ModalHeader>
                <FormGroup check style={{ margin: '16px' }}>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChange}
                        />{' '}
                        {'Select All'}
                    </Label>
                </FormGroup>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            {this.state.list.map((item, idx) => (
                                <FormGroup key={idx} check>
                                    <Label check>
                                        <Input
                                            type="checkbox" key={idx}
                                            name={item.chr_name} value={item.chr_name} checked={item.isChecked} onChange={this.handleChange}
                                        />
                                        {item.chr_name}
                                    </Label>
                                </FormGroup>

                            ))}
                        </FormGroup>
                    </Form>

                </ModalBody>
            </Modal>
        )
    }
}


const mapStateToProps = state => {
    return {
        milestoneChanged: state.FAST360.fast360Reducer.milestoneClicked,
        dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
        taskChanged: state.FAST360.fast360Reducer.taskClicked,
        taskData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateTaskData : [],
        prevUser: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatePreviousUser : '',
        milestoneData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateMilestoneData : [],
        milestoneWorkList: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.milestoneWorkListBackup : [],
        milestoneClickedAgain: state.FAST360.fast360Reducer.milestoneAgainClicked,
        taskBackUp: state.FAST360.fast360Reducer.taskBackUp

    };
};
const mapDispatchToProps = dispatch => ({
    updateNewUsersWorkList: (data) => dispatch(fast360Actions.updateNewUsersWorkList(data)),
    updateTaskClicked: (data) => dispatch(fast360Actions.updateTaskClicked(data)),
    updateTaskData: (data) => dispatch(fast360Actions.updateTaskData(data)),
    updatePreviousUser: (data) => dispatch(fast360Actions.updatePreviousUser(data)),
    milestoneAgainClicked: (data) => dispatch(fast360Actions.milestoneAgainClicked(data)),
    setSelectedType: (data) => dispatch(fast360Actions.setSelectedType(data)),
    setSelectedFilterType:(data) => dispatch(fast360Actions.setSelectedFilterType(data)), 
    setTaskBackUp: (data) => dispatch(fast360Actions.taskBackUp(data)),
    TaskWorkListBackup: data => dispatch(fast360Actions.TaskWorkListBackup(data))



});


export default connect(mapStateToProps, mapDispatchToProps)(TaskHover360);