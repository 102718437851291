export const UPDATE_APPROVE_BILL_TABLE = 'UPDATE_APPROVE_BILL_TABLE';
export const UPDATE_APPROVE_BILL_INITIAL_DATA =
  'UPDATE_APPROVE_BILL_INITIAL_DATA';
export const UPDATE_SELECTED_APPROVE_BILLS = 'UPDATE_SELECTED_APPROVE_BILLS';
export const UPDATE_APPROVE_BILL_REVIEW_TYPE =
  'UPDATE_APPROVE_BILL_REVIEW_TYPE';
export const UPDATE_APPROVE_BILL_SELECTED_ADJUSTER =
  'UPDATE_APPROVE_BILL_SELECTED_ADJUSTER';
export const UPDATE_APPROVE_BILL_GROUP_BY = 'UPDATE_APPROVE_BILL_GROUP_BY';
export const UPDATE_QA_BILL_TABLE = 'UPDATE_QA_BILL_TABLE';
export const UPDATE_SELECTED_QA_BILLS = 'UPDATE_SELECTED_QA_BILLS';
export const UPDATE_QA_BILL_GROUP_BY = 'UPDATE_QA_BILL_GROUP_BY';
export const UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER =
  'UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER';
export const BILL = 'BILL';
export const CLAIM = 'CLAIM';
export const UPDATE_FIND_BILLS_TABLE = 'UPDATE_FIND_BILLS_TABLE';
export const UPDATE_DELETE_BILL_LINES_DATA = 'UPDATE_DELETE_BILL_LINES_DATA';
export const UPDATE_UR_DECISION_TABLE = 'UPDATE_UR_DECISION_TABLE';
export const UPDATE_BILLS_FROM_CLAIM = 'UPDATE_BILLS_FROM_CLAIM';
export const UPDATE_CLAIM_FROM_TASK_LIST = 'UPDATE_CLAIM_FROM_TASK_LIST';
export const BILLS_FROM_TASK_LIST_CLAIM = 'BILLS_FROM_TASK_LIST_CLAIM';
export const UPDATE_SELECTED_BILLS_FROM_CLAIM =
  'UPDATE_SELECTED_BILLS_FROM_CLAIM';
export const UPDATE_CLAIM_DATA = 'UPDATE_CLAIM_DATA';
export const SWITCH_VIEW = 'SWITCH_VIEW';
export const LOAD_BILL_REVIEW_DATA = 'LOAD_BILL_REVIEW_DATA';

export const SHOW_ADVANCED_SEARCH = 'showAdvancedSearch';
export const CLOSE_ADVANCED_SEARCH = 'closeAdvancedSearch';
export const SHOW_ADVANCED_CLAIM_SEARCH = 'showAdvancedClaimSearch';
export const SHOW_ADVANCED_BILL_SEARCH = 'showAdvancedBillSearch';
export const CLAIM_NUMBER = 'claimNumber';
export const BILL_SCAN = 'billScan';
export const END = 'end';
export const FIND_BILLS = 'findBills';
export const TASK_LIST = 'taskList';
export const FIND_PPO = 'findPPO';
export const UPDATE_SEND_EDI_SETUP_DATA = 'UPDATE_SEND_EDI_SETUP_DATA';
export const RESET_SEND_EDI_SETUP_DATA = 'RESET_SEND_EDI_SETUP_DATA';
export const UPDATE_ODG_LOOKUP_DATA = 'UPDATE_ODG_LOOKUP_DATA';
export const RESET_ODG_LOOKUP_DATA = 'RESET_ODG_LOOKUP_DATA';
export const UPDATE_BILL_LINES_DATA = 'UPDATE_BILL_LINES_DATA';
export const UPDATE_POST_BILL_LINES_DATA = 'UPDATE_POST_BILL_LINES_DATA';
export const UPDATE_BILL_TOTAL_DATA = 'UPDATE_BILL_TOTAL_DATA';
export const RESET_BILL_TOTAL_DATA = 'RESET_BILL_TOTAL_DATA';
export const RESET_BILL_LINES_DATA = 'RESET_BILL_LINES_DATA';
export const UPDATE_BILL_LINES_DROPDOWN_DATA =
  'UPDATE_BILL_LINES_DROPDOWN_DATA';
export const UPDATE_BILL_DETAIL_DROPDOWN_DATA =
  'UPDATE_BILL_DETAIL_DROPDOWN_DATA';
export const RESET_BILL_DETAIL_DROPDOWN_DATA =
  'RESET_BILL_DETAIL_DROPDOWN_DATA';
export const UPDATE_PRINT_EOR_DATA = 'UPDATE_PRINT_EOR_DATA';
export const RESET_PRINT_EOR_DATA = 'RESET_PRINT_EOR_DATA';
export const UPDATE_PRINT_REPORTS_DATA = 'UPDATE_PRINT_REPORTS_DATA';
export const RESET_PRINT_REPORTS_DATA = 'RESET_PRINT_REPORTS_DATA';
export const UPDATE_CLAIM_LIST_DATA = 'UPDATE_CLAIM_LIST_DATA';
export const RESET_CLAIM_LIST_DATA = 'RESET_CLAIM_LIST_DATA';
export const UPDATE_EMPLOYER_LIST_DATA = 'UPDATE_EMPLOYER_LIST_DATA';
export const RESET_EMPLOYER_LIST_DATA = 'RESET_EMPLOYER_LIST_DATA';
export const UPDATE_PROVIDER_LIST_DATA = 'UPDATE_PROVIDER_LIST_DATA';
export const RESET_PROVIDER_LIST_DATA = 'RESET_PROVIDER_LIST_DATA';
export const UPDATE_CLAIMANT_LIST_DATA = 'UPDATE_CLAIMANT_LIST_DATA';
export const RESET_CLAIMANT_LIST_DATA = 'RESET_CLAIMANT_LIST_DATA';
export const UPDATE_SEARCH_PPO_DATA = 'UPDATE_SEARCH_PPO_DATA';
export const RESET_SEARCH_PPO_DATA = 'RESET_SEARCH_PPO_DATA';
export const UPDATE_CLIENTS_PPO_DATA = 'UPDATE_CLIENTS_PPO_DATA';
export const RESET_CLIENTS_PPO_DATA = 'RESET_CLIENTS_PPO_DATA';
export const UPDATE_START_DATE_DATA = 'UPDATE_START_DATE_DATA';
export const RESET_START_DATE_DATA = 'RESET_START_DATE_DATA';
export const UPDATE_SITE_OPTIONS_DATA = 'UPDATE_SITE_OPTIONS_DATA';
export const RESET_SITE_OPTIONS_DATA = 'RESET_SITE_OPTIONS_DATA';
export const UPDATE_CASH_APP_DATA = 'UPDATE_CASH_APP_DATA';
export const RESET_CASH_APP_DATA = 'RESET_CASH_APP_DATA';
export const UPDATE_MANAGE_BILL_DATA = 'UPDATE_MANAGE_BILL_DATA';
export const RESET_MANAGE_BILL_DATA = 'RESET_MANAGE_BILL_DATA';
export const RESET_FEE_LOOKUP_NPI_DATA = 'RESET_FEE_LOOKUP_NPI_DATA';
export const UPDATE_FEE_LOOKUP_NPI_DATA = 'UPDATE_FEE_LOOKUP_NPI_DATA';
export const UPDATE_FEE_LOOKUP_DATA = 'UPDATE_FEE_LOOKUP_DATA';
export const RESET_FEE_LOOKUP_DATA = 'RESET_FEE_LOOKUP_DATA';
export const UPDATE_FEE_LOOKUP_DATA_UC = 'UPDATE_FEE_LOOKUP_DATA_UC';
export const UPDATE_FEE_LOOKUP_DATA_SSD = 'UPDATE_FEE_LOOKUP_DATA_SSD';
export const RESET_FEE_LOOKUP_DATA_SSD = 'RESET_FEE_LOOKUP_DATA_SSD';
export const RESET_FEE_LOOKUP_DATA_UC = 'RESET_FEE_LOOKUP_DATA_UC';
export const UPDATE_FEE_LOOKUP_DATA_UHUC = 'UPDATE_FEE_LOOKUP_DATA_UHUC';
export const RESET_FEE_LOOKUP_DATA_UHUC = 'RESET_FEE_LOOKUP_DATA_UHUC';
export const UPDATE_FEE_LOOKUP_DATA_BY_ZIP = 'UPDATE_FEE_LOOKUP_DATA_BY_ZIP';
export const UPDATE_PRINT_EOR_DROPDOWN_USER_DATA =
  'UPDATE_PRINT_EOR_DROPDOWN_USER_DATA';
export const RESET_PRINT_EOR_DROPDOWN_USER_DATA =
  'RESET_PRINT_EOR_DROPDOWN_USER_DATA';
export const RESET_FEE_LOOKUP_DATA_BY_ZIP = 'RESET_FEE_LOOKUP_DATA_BY_ZIP';
export const UPDATE_FEE_LOOKUP_DATA_MED = 'UPDATE_FEE_LOOKUP_DATA_MED';
export const RESET_FEE_LOOKUP_DATA_MED = 'RESET_FEE_LOOKUP_DATA_MED';
export const LOAD_TASK_LIST_GRID_SETTINGS = 'LOAD_TASK_LIST_GRID_SETTINGS';
export const UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX =
  'UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX';
export const LOAD_FIND_BILLS_GRID_SETTINGS = 'LOAD_FIND_BILLS_GRID_SETTINGS';
export const UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX =
  'UPDATE_FIND_BILLS_GRID_SETTINGS_CHECKBOX';

export const UPDATE_RECENT_ACTIVITY_INITIAL_DATA =
  'UPDATE_RECENT_ACTIVITY_INITIAL_DATA';
export const UPDATE_RECENT_ACTIVITY_TABLE = 'UPDATE_RECENT_ACTIVITY_TABLE';
export const UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER =
  'UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER';
export const UPDATE_SEARCH_FIELDS = 'UPDATE_SEARCH_FIELDS';

export const UPDATE_REFERRAL_DOCUMENTS = 'UPDATE_REFERRAL_DOCUMENTS';
export const RESET_REFERRAL_DOCUMENTS = 'RESET_REFERRAL_DOCUMENTS';
export const UPDATE_RETRIEVE_REFERRAL_DOCUMENT =
  'UPDATE_RETRIEVE_REFERRAL_DOCUMENT';
export const RESET_RETRIEVE_REFERRAL_DOCUMENT =
  'RESET_RETRIEVE_REFERRAL_DOCUMENT';
export const UPDATE_SELECTED_DEMAND_BILLS = 'UPDATE_SELECTED_DEMAND_BILLS';
export const UPDATE_DEMAND_DOCUMENTS = 'UPDATE_DEMAND_DOCUMENTS';
export const UPDATE_RETRIVE_DEMAND_DOCUMENT = 'UPDATE_RETRIVE_DEMAND_DOCUMENT';
export const RESET_RETRIVE_DEMAND_DOCUMENT = 'RESET_RETRIVE_DEMAND_DOCUMENT';
export const UPDATE_REFERRAL_EMAIL_TEMPLATES =
  'UPDATE_REFERRAL_EMAIL_TEMPLATES';
export const UPDATE_REFERRAL_SPECIALTIES = 'UPDATE_REFERRAL_SPECIALTIES';
export const RESET_REFERRAL = 'RESET_REFERRAL';
export const SET_DENY_REASON = 'SET_DENY_REASON';
// export const SET_DENY_REASON2 = 'SET_DENY_REASON2';
export const RESET_DENY_REASON = 'RESET_DENY_REASON';
export const SET_MCNS_EDIT_VAL = 'SET_MCNS_EDIT_VAL';
export const RESET_MCNS_EDIT_VAL = 'RESET_MCNS_EDIT_VAL';
export const SET_REMB_TOTAL = 'SET_REMB_TOTAL';
export const RESET_REMB_TOTAL = 'RESET_REMB_TOTAL';
export const REMOVE_REMB_TOTAL = 'REMOVE_REMB_TOTAL';
export const SET_BILL_LINES_DATA = 'SET_BILL_LINES_DATA';
export const UPDATE_STATUS_HISTORY_DATA = 'UPDATE_STATUS_HISTORY_DATA';
export const RESET_STATUS_HISTORY_DATA = 'RESET_STATUS_HISTORY_DATA';
export const UPDATE_STATUS_LIST_DATA = 'UPDATE_STATUS_LIST_DATA';
export const RESET_STATUS_LIST_DATA = 'RESET_STATUS_LIST_DATA';
export const UPDATE_UNPEND_DATA = 'UPDATE_UNPEND_DATA';
export const RESET_UNPEND_DATA = 'RESET_UNPEND_DATA';
export const RESET_STORE_STATE ='RESET_STORE_STATE';
export const UPDATE_CURRENT_PROFILE = 'UPDATE_CURRENT_PROFILE';
export const UPDATE_PROFILE_DRODOWN_DATA = 'UPDATE_PROFILE_DRODOWN_DATA';
export const RESET_PROFILE_DRODOWN_DATA = 'RESET_PROFILE_DRODOWN_DATA';
export const UPDATE_POST_MCNS_BILLS_DATA = 'UPDATE_POST_MCNS_BILLS_DATA';
export const RESET_POST_MCNS_BILLS_DATA = 'RESET_POST_MCNS_BILLS_DATA';
export const SET_BILL_REVIEW_REV_EDITVAL = 'SET_BILL_REVIEW_REV_EDITVAL';
export const RESET_BILL_REVIEW_REV_EDITVAL = 'RESET_BILL_REVIEW_REV_EDITVAL';
export const UPDATE_POST_BILL_REVIEW_DATA = 'UPDATE_POST_BILL_REVIEW_DATA';
export const RESET_POST_BILL_REVIEW_DATA = 'RESET_POST_BILL_REVIEW_DATA';
export const UPDATE_POST_BILL_REVIEW_AO_DATA =
  'UPDATE_POST_BILL_REVIEW_AO_DATA';
export const RESET_POST_BILL_REVIEW_AO_DATA = 'RESET_POST_BILL_REVIEW_AO_DATA';
export const ADD_APP_ALERT = 'ADD_APP_ALERT';
export const DEFAULT_ALERT_LEVEL = 'primary';
export const DEFAULT_ALERT_TIMEOUT = 10000;
export const UPDATE_FINDBILLS_FROM_TABS = 'UPDATE_FINDBILLS_FROM_TABS';
export const UPDATE_FIND_BILLS_FROM_RECENT = 'UPDATE_FIND_BILLS_FROM_RECENT';
export const UPDATE_SAVE_PAY_CODE_DATA = 'UPDATE_SAVE_PAY_CODE_DATA';
export const UPDATE_MORE_INFO_DATA = 'UPDATE_MORE_INFO_DATA';
export const UPDATE_AOREMAINING_INFO_DATA = 'UPDATE_AOREMAINING_INFO_DATA';
export const LOAD_MORE_INFO = 'LOAD_MORE_INFO';
export const UNPEND_BILL = 'UNPEND_BILL';
export const UPDATE_BILLCLAIM_NOTES = 'UPDATE_BILLCLAIM_NOTES';
export const UPDATE_NEW_MED = 'UPDATE_NEW_MED';
export const UPDATE_CORRECTION_REASONS = 'UPDATE_CORRECTION_REASONS';
export const RESET_TASK_LIST = 'RESET_TASK_LIST';


export default {
  UPDATE_APPROVE_BILL_TABLE,
  UPDATE_APPROVE_BILL_INITIAL_DATA,
  UPDATE_SELECTED_APPROVE_BILLS,
  UPDATE_APPROVE_BILL_SELECTED_ADJUSTER,
  UPDATE_APPROVE_BILL_GROUP_BY,
  UPDATE_QA_BILL_TABLE,
  UPDATE_SELECTED_QA_BILLS,
  UPDATE_QA_BILL_GROUP_BY,
  UPDATE_QA_BILL_MASTER_CLIENT_DATA_PROVIDER,
  SHOW_ADVANCED_SEARCH,
  CLOSE_ADVANCED_SEARCH,
  BILL,
  CLAIM,
  CLAIM_NUMBER,
  BILL_SCAN,
  END,
  SHOW_ADVANCED_BILL_SEARCH,
  SHOW_ADVANCED_CLAIM_SEARCH,
  UPDATE_FIND_BILLS_TABLE,
  UPDATE_DELETE_BILL_LINES_DATA,
  LOAD_BILL_REVIEW_DATA,
  UPDATE_SEND_EDI_SETUP_DATA,
  RESET_SEND_EDI_SETUP_DATA,
  UPDATE_ODG_LOOKUP_DATA,
  RESET_ODG_LOOKUP_DATA,
  UPDATE_BILL_LINES_DATA,
  UPDATE_POST_BILL_LINES_DATA,
  RESET_BILL_LINES_DATA,
  UPDATE_BILL_DETAIL_DROPDOWN_DATA,
  RESET_BILL_DETAIL_DROPDOWN_DATA,
  UPDATE_PRINT_EOR_DATA,
  RESET_PRINT_EOR_DATA,
  RESET_SEARCH_PPO_DATA,
  UPDATE_SEARCH_PPO_DATA,
  UPDATE_CLIENTS_PPO_DATA,
  RESET_CLIENTS_PPO_DATA,
  UPDATE_START_DATE_DATA,
  RESET_START_DATE_DATA,
  UPDATE_SITE_OPTIONS_DATA,
  RESET_SITE_OPTIONS_DATA,
  UPDATE_CASH_APP_DATA,
  RESET_CASH_APP_DATA,
  UPDATE_MANAGE_BILL_DATA,
  RESET_MANAGE_BILL_DATA,
  UPDATE_FEE_LOOKUP_DATA,
  RESET_FEE_LOOKUP_DATA,
  UPDATE_FEE_LOOKUP_DATA_UC,
  RESET_FEE_LOOKUP_DATA_SSD,
  UPDATE_FEE_LOOKUP_DATA_SSD,
  RESET_FEE_LOOKUP_DATA_UC,
  UPDATE_FEE_LOOKUP_DATA_UHUC,
  RESET_FEE_LOOKUP_DATA_UHUC,
  UPDATE_FEE_LOOKUP_DATA_BY_ZIP,
  RESET_FEE_LOOKUP_DATA_BY_ZIP,
  UPDATE_PRINT_EOR_DROPDOWN_USER_DATA,
  RESET_PRINT_EOR_DROPDOWN_USER_DATA,
  UPDATE_FEE_LOOKUP_DATA_MED,
  RESET_FEE_LOOKUP_DATA_MED,
  LOAD_TASK_LIST_GRID_SETTINGS,
  UPDATE_BILL_TOTAL_DATA,
  RESET_BILL_TOTAL_DATA,
  UPDATE_TASK_LIST_GRID_SETTINGS_CHECKBOX,
  UPDATE_RECENT_ACTIVITY_INITIAL_DATA,
  UPDATE_RECENT_ACTIVITY_TABLE,
  UPDATE_RECENT_ACTIVITY_SELECTED_ADJUSTER,
  UPDATE_REFERRAL_DOCUMENTS,
  UPDATE_RETRIEVE_REFERRAL_DOCUMENT,
  RESET_RETRIEVE_REFERRAL_DOCUMENT,
  UPDATE_SELECTED_DEMAND_BILLS,
  UPDATE_DEMAND_DOCUMENTS,
  UPDATE_RETRIVE_DEMAND_DOCUMENT,
  RESET_RETRIVE_DEMAND_DOCUMENT,
  UPDATE_REFERRAL_EMAIL_TEMPLATES,
  RESET_REFERRAL,
  UPDATE_REFERRAL_SPECIALTIES,
  SET_DENY_REASON,
  RESET_DENY_REASON,
  SET_REMB_TOTAL,
  RESET_REMB_TOTAL,
  REMOVE_REMB_TOTAL,
  SET_BILL_LINES_DATA,
  UPDATE_BILL_LINES_DROPDOWN_DATA,
  UPDATE_STATUS_HISTORY_DATA,
  RESET_STATUS_HISTORY_DATA,
  UPDATE_STATUS_LIST_DATA,
  RESET_STATUS_LIST_DATA,
  UPDATE_UNPEND_DATA,
  RESET_UNPEND_DATA,
  RESET_STORE_STATE,
  UPDATE_CURRENT_PROFILE,
  UPDATE_PROFILE_DRODOWN_DATA,
  RESET_PROFILE_DRODOWN_DATA,
  UPDATE_POST_MCNS_BILLS_DATA,
  RESET_POST_MCNS_BILLS_DATA,
  SET_MCNS_EDIT_VAL,
  RESET_MCNS_EDIT_VAL,
  SET_BILL_REVIEW_REV_EDITVAL,
  RESET_BILL_REVIEW_REV_EDITVAL,
  UPDATE_POST_BILL_REVIEW_DATA,
  RESET_POST_BILL_REVIEW_DATA,
  DEFAULT_ALERT_LEVEL,
  DEFAULT_ALERT_TIMEOUT,
  ADD_APP_ALERT,
  UPDATE_FINDBILLS_FROM_TABS,
  UPDATE_FIND_BILLS_FROM_RECENT,
  UPDATE_SAVE_PAY_CODE_DATA,
  UPDATE_MORE_INFO_DATA,
  UPDATE_AOREMAINING_INFO_DATA,
  UPDATE_POST_BILL_REVIEW_AO_DATA,
  RESET_POST_BILL_REVIEW_AO_DATA,
  LOAD_MORE_INFO,
  UNPEND_BILL,
  UPDATE_BILLCLAIM_NOTES,
  UPDATE_NEW_MED,
  UPDATE_CORRECTION_REASONS,
  RESET_TASK_LIST,
};
