export const CHANGE_PROFILE = 'CHANGE_PROFILE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const ADD_ADJUSTER = 'ADD_ADJUSTER';
export const CANCEL_NEW_USER = 'CANCEL_NEW_USER';
export const UPDATE_USER_INPUT = 'UPDATE_USER_INPUT';
export const UPDATE_USER_CLIENTS = 'UPDATE_USER_CLIENTS';
export const SAVE_ADMIN_USER_FORM = 'SAVE_ADMIN_USER_FORM';
export const UPDATE_DATA_TABLE = 'UPDATE_DATA_TABLE';
export const APPLY_THRESHOLD_TO_SELECTED = 'APPLY_THRESHOLD_TO_SELECTED';
export const UPDATE_USER_TABLE = 'UPDATE_USER_TABLE';
export const LOAD_USER_INFORMATION = 'LOAD_USER_INFORMATION';
export const LOAD_ADJUSTER_MAPING_INFORMATION =
  'LOAD_ADJUSTER_MAPING_INFORMATION';
export const LOAD_ADJUSTER_LIST = 'LOAD_ADJUSTER_LIST';
export const SET_ACTIVE_ANCIL_ACCESS_PANEL = 'SET_ACTIVE_ANCIL_ACCESS_PANEL';
export const UPDATE_ANCIL_ACCESS = 'UPDATE_ANCIL_ACCESS';
export const UPDATE_DEFAULT_CHECKBOX = 'UPDATE_DEFAULT_CHECKBOX';
export const UPDATE_CLIENT_LISTS = 'UPDATE_CLIENT_LISTS';
export const UPDATE_ALL_CHECKBOXES = 'UPDATE_ALL_CHECKBOXES';
export const UPDATE_LOCATION_LIST = 'UPDATE_LOCATION_LIST';

export const ACCESS_MODULE = 'userAccess';
export const BASICS_MODULE = 'userBasics';
export const ANCIL_ACCESS_MODULE = 'userAncilAccess';
export const BILL_REVIEW_MODULE = 'userBillReview';
export const BILL_APPROVAL_MODULE = 'userBillApproval';
export const ADJUSTER_MAPPING_MODULE = 'userAdjusterMapping';
export const COMMENTS_MODULE = 'userComments';
export const ADJUSTER_LIST = 'adjusterList';
export const CLAIM_NUMBER_LIST = 'claimnumberList';
export const BILL_LIST = 'billList';
export const DATA_TABLE = 'dataTable';
export const DATA_ACCESS_LEVEL = 'dataAccessLevel';

const moduleName = 'ADMIN';

export default {
  CHANGE_PROFILE,
  OPEN_MODAL,
  ADD_ADJUSTER,
  CANCEL_NEW_USER,
  UPDATE_USER_INPUT,
  UPDATE_USER_CLIENTS,
  SAVE_ADMIN_USER_FORM,
  UPDATE_DATA_TABLE,
  UPDATE_DEFAULT_CHECKBOX,
  UPDATE_CLIENT_LISTS,
  ACCESS_MODULE,
  ANCIL_ACCESS_MODULE,
  APPLY_THRESHOLD_TO_SELECTED,
  BILL_REVIEW_MODULE,
  BASICS_MODULE,
  ADJUSTER_MAPPING_MODULE,
  UPDATE_USER_TABLE,
  LOAD_USER_INFORMATION,
  LOAD_ADJUSTER_MAPING_INFORMATION,
  LOAD_ADJUSTER_LIST,
  SET_ACTIVE_ANCIL_ACCESS_PANEL,
  UPDATE_ANCIL_ACCESS,
  UPDATE_ALL_CHECKBOXES,
  ADJUSTER_LIST,
  CLAIM_NUMBER_LIST,
  BILL_LIST,
  DATA_TABLE,
  DATA_ACCESS_LEVEL,
  moduleName,
  UPDATE_LOCATION_LIST
};
