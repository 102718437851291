import React from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const RereviewModal = props => {
  return (
    <>
      <Modal isOpen={props.modalOpen} toggle={props.modalToggle} size="md">
        <ModalHeader toggle={props.modalToggle}>Add Note</ModalHeader>
        <ModalBody>
          <Form>
            <Input
              id="note"
              name="text"
              type="textarea"
              value={props.noteValue}
              onChange={props.changeNoteValue}
              style={{ height: '100px' }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={props.submitNoteRereview}
            disabled={props.noteValue === '' ? true : false}
          >
            SEND
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RereviewModal;
