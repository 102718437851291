import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Col, Row, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect, useRef } from 'react';
import PopupModal from 'shared/components/PopupModal';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { connect } from 'react-redux';
import MoreInfoModal from './MoreInfoModal';
import BillReviewAppTable from 'shared/components/table/BillReviewAppTable';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

const BillReviewBillsModal = ({
  isOpen,
  onClose,
  modifyBillLinespData,
  billLinesFullData,
  postBillLinesDataRes,
  modifyBillTotalAmount,
  resetBillTotalData,
  resetBillLinesData,
  getBillLinesData,
  savePaycode,
  postBillReviewData,
  postBillReviewAoData,
  resetBillReviewData,
  getBillTotalData,
  userId,
  slpUserId,
  profile,
  data,
  date,
  resetDenyReason,
  claimNumber,
  format,
  siteId,
  billId,
  claimant,
  denyReasons,
  rembTotal,
  formattedDOIDate,
  alert,
  reviewType,
  refresh,
  billRevEditValues,
  resetBillRevEditVal,
  postBillReviewDataRes,
  postBillReviewAoDataRes,
  paycodeList,
  enablePaycode,
  paycodeId,
  setPaycodeID,
  setChrCode,
  loadMoreInfo,
  moreInfoData,
  chrCode,
  allowMultiDenialReason,
  isVanliner,
  isNationalInterstateAuto,
  juris,
}) => {
  const [notes, setNotes] = useState('');
  const [showMoreInfoModal, setShowMoreInfoModal] = useState('');
  const [initialData, setInititalData] = useState([]);
  const [paycodeParms, setRequestParams] = useState({});

  const [isOpenFeeLookup, setIsOpenFeeLookup] = useState(true);
  const [isUndecided, setIsUndecided] = useState('true');
  const [isPostBillCall, setIsPostBillCall] = useState(false);
  const isPostBillCallRef = useRef(isPostBillCall);
  const [payCodeChanged, setPayCOdeChanged] = useState(false);
  const [paycodeChangeOpen, setPaycodeChangeOpen] = useState(false);

  const toggle = () => {
    resetBillLinesData();
    resetBillTotalData();
    resetBillRevEditVal();
    setNotes('');
    // setIsUndecided(0);
    setIsPostBillCall(false);
    setIsOpenFeeLookup(true);
    if (payCodeChanged) refresh();
    onClose();
  };

  const paycodeChangeOpenToggle = () => {
    setPaycodeChangeOpen(!paycodeChangeOpen);
  };
  const onChangePaycode = (value, chr_code, tg) => {
    const requestParams = {
      pvalue: value,
      pchr_code: chr_code,
      ptg: tg,
    };
    let openWindow = false;
    setRequestParams(requestParams);
    data.forEach(item => {
      if (
        item.id === billId &&
        item.juris !== 'WC' &&
        item.billState === 'MI'
      ) {
        openWindow = true;
      }
    });
    if ((chrCode === '5' || chrCode === '15') && openWindow) {
      setPaycodeChangeOpen(true);
    } else {
      submitPaycodeChange(value, chr_code, tg);
    }
  };

  const changePaycodeFromModal = () => {
    submitPaycodeChange(
      paycodeParms.pvalue,
      paycodeParms.pchr_code,
      paycodeParms.ptg
    );
  };

  const submitPaycodeChange = (value, chr_code, tg) => {
    //const target = tg.target;
    //const name = target.name;

    const requestBody = {
      billId: billId,
      listId: value,
      oldPaycode: paycodeId,
      newPaycode: chr_code,
      userId: userId,
      profile: profile,
    };
    //tg.target.value = value;
    savePaycode(requestBody);
    setPaycodeID(value);
    setChrCode(chr_code);
    setPaycodeChangeOpen(false);
    if (requestBody.listId != requestBody.oldPaycode) setPayCOdeChanged(true);
  };
  const onChangeHandler = (id, value) => {
    setNotes(value);
  };
  const onMoreInfoClickHandler = () => {
    setShowMoreInfoModal(true);
  };
  useEffect(() => {
    const requestParams = {
      zebraUserId: userId,
      userId: userId,
      claimId: claimNumber,
      billId: billId,
      siteId: siteId,
      profile: profile,
    };
    if (isOpen && enablePaycode) {
      loadMoreInfo(requestParams);
    }
  }, [isOpen, enablePaycode]);
  useEffect(() => {
    const params = {
      userId,
      profile,
      billId,
      siteId,
      claimant,
      denyReasons,
    };
    if (isOpen && isOpenFeeLookup) {
      resetBillLinesData();
      resetBillTotalData();
      // resetDenyReason();
      getBillLinesData(params);
      getBillTotalData(params);
    }
    isPostBillCallRef.current = isPostBillCall;
  }, [isOpen, isPostBillCall]);
  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      notes,
      userId,
      profile,
      billId,
      billRevEditValues,
      slpUserId,
      // billRevEditValues: billRevEditValues.length ? billRevEditValues : initialData,
    };
    resetBillReviewData();
    if (billRevEditValues.length === 0) {
      alert('You must approve/deny all bill lines in order to submit.');
    } else {
      if (!billRevEditValues.every(t => t.decision !== 'Undecided'))
        alert('You must approve/deny all bill lines in order to submit.');
      else {
        if(juris !== 'WC' && allowMultiDenialReason && enablePaycode && (paycodeId === 0 || paycodeId === null)){
          alert('You must select a paycode in order to submit.');
        }else{
          postBillReviewData(params);
          billLinesFullData &&
            billLinesFullData.fromAO &&
            postBillReviewAoData(params);
          if (postBillReviewDataRes != -1) {
            setTimeout(() => {
              refresh();
            }, 2000);
            setNotes('');
            resetBillRevEditVal();
            onClose();
          }
        }
      }
    }
  };
  const onMoreInfoModalClose = () => {
    setShowMoreInfoModal(!showMoreInfoModal);
  };
  const sortColumn = (item1, item2, order, field) => {
    const field1 = item1[field] || {};
    const field2 = item2[field] || {};
    return sortByType(field1[field], field2[field], order);
  };
  const billReviewSubmissionData = data => {
    setInititalData(data);
  };
  const getBillLinesAppTable = () => {
    let isMed =
      billLinesFullData &&
      (billLinesFullData.PDC == '29' ||
        billLinesFullData.PDC == '31' ||
        billLinesFullData.PDC == '33' ||
        billLinesFullData.PDC == '34');
    let isExtended =
      billLinesFullData &&
      (billLinesFullData.PDC == '32' || billLinesFullData.PDC == '34');
    let isBasic =
      billLinesFullData &&
      (billLinesFullData.PDC == '29' || billLinesFullData.PDC == '30');
    let isTotalpaid =
      billLinesFullData &&
      (billLinesFullData.PDC == '29' ||
        billLinesFullData.PDC == '30' ||
        billLinesFullData.PDC == '31' ||
        billLinesFullData.PDC == '32' ||
        billLinesFullData.PDC == '33' ||
        billLinesFullData.PDC == '34');
    // console.log('pdc valll', isMed, isExtended, isBasic);
    let columnData = [
      {
        header: 'Line',
        field: 'lineNumber',
        percent: '4',
      },
      {
        header: 'CPT',
        field: 'cpt',
        percent: '5',

        sortFunction: (item1, item2, order) =>
          sortColumn(item1, item2, order, 'icdDesc'),
      },
      {
        header: 'CPT Description',
        field: 'cptDescription',
        percent: '10',
      },
      {
        header: 'Units',
        field: 'units',
        percent: '5',
      },
      {
        header: 'Modifiers',
        field: 'modifier',
        percent: '5',
      },
      {
        header: 'DOS',
        field: 'dos',
        percent: '5',
      },
      {
        header: 'Billed',
        field: 'billed',
        percent: '5',
      },
      {
        header: 'Reductions',
        field: 'reductions',
        percent: '5',
      },
      !isTotalpaid
        ? {
            header: 'Total Paid',
            field: 'reimb',
            percent: '5',
            hide: false,
          }
        : {
            header: 'Total Paid',
            field: 'reimb',
            percent: '5',
            hide: true,
          },

      isExtended
        ? {
            header: 'Extended Non-Emergency',
            field: 'extendedNonEmergency',
            percent: '8',
            hide: false,
          }
        : {
            header: 'Extended Non-Emergency',
            field: 'extendedNonEmergency',
            percent: '8',
            hide: true,
          },

      isExtended
        ? {
            header: 'Extended Emergency',
            field: 'extendedEmergency',
            percent: '8',
            hide: false,
          }
        : {
            header: 'Extended Emergency',
            field: 'extendedEmergency',
            percent: '8',
            hide: true,
          },

      isMed
        ? {
            header: 'MedPay Allocations',
            field: 'medPayAllocation',
            percent: '8',
            hide: false,
          }
        : {
            header: 'MedPay Allocations',
            field: 'medPayAllocation',
            percent: '8',
            hide: true,
          },
      isBasic
        ? {
            header: 'Basic Emergency',
            field: 'basicEmergency',
            percent: '5',
            hide: false,
          }
        : {
            header: 'Basic Emergency',
            field: 'basicEmergency',
            percent: '5',
            hide: true,
          },
      isBasic
        ? {
            header: 'Basic Non-Emergency',
            field: 'basicNonEmergency',
            percent: '6',
            hide: false,
          }
        : {
            header: 'Basic Non-Emergency',
            field: 'basicNonEmergency',
            percent: '6',
            hide: true,
          },
      {
        header: 'Network Savings',
        field: 'reimb',
        percent: '7',
      },
      {
        header: 'Decision',
        field: 'decision',
        percent: '12',
      },
    ];

    return (
      <div className="bill-lines">
        <Row>
          <div style={{ marginRight: '290px' }} className="CliamAdjust">
            BILL LINES
          </div>
          <Col>
            <Row>
              <div className="col-3">Claim #: {claimNumber}</div>
              <div className="col-2">DOI: {date}</div>
              <div className="col-3">Bill Total : {modifyBillTotalAmount}</div>
              <div className="col-4">Total Amount Approved $: 0.00 </div>
            </Row>
          </Col>
        </Row>

        <BillReviewAppTable
          columns={columnData}
          data={modifyBillLinespData}
          fullData={billLinesFullData}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          key="bill-lines-table"
          siteId={siteId}
          claimant={claimant}
          billId={billId}
          billReviewSubmissionData={billReviewSubmissionData}
          taskListData={data}
          fromAO={billLinesFullData ? billLinesFullData.fromAO : false}
          allowMultiDenialReason={allowMultiDenialReason}
        />

        {showMoreInfoModal && (
          <MoreInfoModal
            moreInfoData={moreInfoData}
            showModal={showMoreInfoModal}
            onClose={() => {
              setShowMoreInfoModal(false);
            }}
            billId={billId}
            claimNumber={claimNumber}
          />
        )}
      </div>
    );
  };

  const filterList57 = (list57, isVL, isNIS) => {
    if(isNIS){
      return list57.filter(paycode => {
        // paycode for National Interstate Insurance and Auto only filter (nisFilter)
        if(paycode.nisFilter === true || paycode.clientFilter === 0 || paycode.clientFilter === 1){
          return true;
        }
        return false;
      });
    }
    if(isVL){
      return list57.filter(paycode => {
        if(paycode.clientFilter === 1 || paycode.clientFilter === 2){
          return true;
        }
        return false;
      });
    }else{
      return list57.filter(paycode => {
        if(paycode.clientFilter === 0 || paycode.clientFilter === 1){
          return true;
        }
        return false;
      });
    }
  };

  return (
    <>
      <PopupModal
        content={
          <span>
            Are you sure you want to change the original value of 5/15?
            Attendant Care policy money cannot be used for this bill unless the
            bill has a paycode of 5 or 15.
          </span>
        }
        title="Confirmation"
        externalToggle={paycodeChangeOpenToggle}
        isOpen={paycodeChangeOpen}
        size="lg"
        footerContent={
          <>
            <Button color="secondary" onClick={paycodeChangeOpenToggle}>
              NO
            </Button>
            <Button color="primary" onClick={changePaycodeFromModal}>
              YES
            </Button>
          </>
        }
      />
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Approve Bill Lines</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row>
              <Col lg="12">{getBillLinesAppTable()}</Col>
            </Row>

            <Row>
              <Col>
                <FormGroup row>
                  <Label for="firstName" className="CliamAdjustNote">
                    Notes
                  </Label>

                  <Col>
                    <AppInput
                      changeHandler={onChangeHandler}
                      placeholder="Notes"
                      id="notes"
                      type="textarea"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                {
                  <FormGroup row>
                    <Label for="paycde">Paycode:</Label>
                    <Col>
                      <Input
                        disabled={!enablePaycode}
                        type="select"
                        value={paycodeId || ''}
                        onChange={event =>
                          onChangePaycode(
                            event.target.value,
                            event.target.childNodes[
                              event.target.selectedIndex
                            ].getAttribute('chrcode'),
                            event
                          )
                        }
                        data={paycodeList}
                      >
                        <option defaultValue key={-1} value="">
                          {' '}
                        </option>

                        {paycodeList != null &&
                          filterList57(paycodeList, isVanliner, isNationalInterstateAuto).map((item, index) => (
                            <option
                              key={item.paycodeId}
                              value={item.paycodeId}
                              chrcode={item.chr_code}
                            >
                              {item.description}
                            </option>
                          ))}
                      </Input>
                    </Col>
                  </FormGroup>
                }
              </Col>
              {enablePaycode ? (
                <Col>
                  <FormGroup row>
                    <Col>
                      <Button
                        outline
                        color="primary"
                        //style={{ marginRight: '20px' }}
                        onClick={onMoreInfoClickHandler}
                      >
                        More Info
                      </Button>
                    </Col>
                  </FormGroup>
                </Col>
              ) : (
                ''
              )}
              <Col>
                <Button type="submit" id="odgSubmit">
                  SUBMIT{' '}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

BillReviewBillsModal.propTypes = {
  data: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modifyBillLinespData: PropTypes.array,
  billLinesFullData: PropTypes.any,
  postBillLinesDataRes: PropTypes.number,
  modifyBillTotalAmount: PropTypes.string,
  rembTotal: PropTypes.number,
  postBillReviewData: PropTypes.func,
  postBillReviewAoData: PropTypes.any,
  resetBillReviewData: PropTypes.func,
  getBillLinesData: PropTypes.func,
  savePaycode: PropTypes.func,
  resetBillLinesData: PropTypes.func,
  resetBillTotalData: PropTypes.func,
  date: PropTypes.string,
  claimNumber: PropTypes.string.isRequired,
  billId: PropTypes.number.isRequired,
  claimant: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  slpUserId: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  alert: PropTypes.func.isRequired,
  reviewType: PropTypes.string,
  resetBillRevEditVal: PropTypes.func,
  refresh: PropTypes.func,
  postBillReviewDataRes: PropTypes.number,
  postBillReviewAoDataRes: PropTypes.number,
  paycodeList: PropTypes.array,
  paycodeId: PropTypes.number,
  setPaycodeID: PropTypes.func,
  enablePaycode: PropTypes.bool,
  moreInfoData: PropTypes.array.isRequired,
  loadMoreInfo: PropTypes.func.isRequired,
  allowMultiDenialReason: PropTypes.bool,
  isNationalInterstateAuto: PropTypes.bool,
  juris: PropTypes.string,
};

const mapStateToProps = state => {
  const billReviewState = billOperationsSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  return {
    modifyBillLinespData: billReviewState.modifyBillLinespData,
    billLinesFullData: billReviewState.modifyBillLinesFullData,
    postBillReviewDataRes: billReviewState.postBillReviewDataRes,
    postBillReviewAoDataRes: billReviewState.postBillReviewAoDataRes,
    modifyBillTotalAmount: billReviewState.modifyBillTotalAmount,
    userId: user.user_id,
    slpUserId: billReviewInitialData.slpUserId,
    profile: billReviewInitialData.currentProfile,
    moreInfoData: billReviewState.moreInfoData,
    billRevEditValues: billReviewState.billRevEditValues
      ? billReviewState.billRevEditValues
      : [],
    //rembTotal: billReviewState.rembTotal,
  };
};

const mapDispatchToProps = dispatch => ({
  getBillLinesData: data =>
    dispatch(billOperationsOperations.getBillLinesData(data)),
  resetBillLinesData: () =>
    dispatch(billOperationsActions.resetBillLinesData()),
  getBillTotalData: data =>
    dispatch(billOperationsOperations.getBillTotalData(data)),
  resetBillTotalData: () =>
    dispatch(billOperationsActions.resetBillTotalData()),
  resetBillRevEditVal: () =>
    dispatch(billOperationsActions.resetBillRevEditVal()),
  savePaycode: data =>
    dispatch(billOperationsOperations.savePaycode(data, true)),
  postBillReviewData: data =>
    dispatch(billOperationsOperations.postBillReviewData(data)),
  postBillReviewAoData: data =>
    dispatch(billOperationsOperations.postBillReviewAoData(data)),
  resetBillReviewData: () =>
    dispatch(billOperationsActions.resetBillReviewData()),
  loadMoreInfo: data => dispatch(billOperationsOperations.loadMoreInfo(data)),
  // resetDenyReason: reason =>
  //     dispatch(billOperationsActions.resetDenyReason(reason)),

  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillReviewBillsModal);
