import React, { useState, useEffect } from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { assign } from 'lodash';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';
import { formatPhoneNumber } from 'modules/experts/utils/expertUtils';
import { authSelectors } from 'modules/auth/store';
import { commonOperations } from 'common/store';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const refType = {
  referrer: 'loadReferrer',
  adjuster: 'loadAdjuster',
  provider: 'loadPhysician',
  'case manager': 'loadCaseManager',
};
const NewReferrerModal = ({
  isOpen,
  onClose,
  userId,
  getReferrerInfo,
  referrerInfo,
  updatedRefInfo,
  resetSearchReferrerTable,
  getLoadReferrer,
  loadRefInfo,
  referrerType,
  zipList,
  userRole,
  getZipList,
  resetZipList,
  resetLoadReferrer,
  ReferralSourceList,
  saveReferrer,
  alert,
  isNew,
  NewReferrerFirstName,
  setIsExternal,
  setReferrerEdited,
  fromExternal,
  externalRefData,
  fromWorklist,
  worklistData,
  setRefData,
  isFromLanding,
  loadInfoFromExistingPatient,
  referralId,
  logBreadCrumbs,
  populatedRef
}) => {
  // const getFirstName = (data) => {
  //   let value = '';
  //   if (fromWorklist && data && data.referrer && data.referrer.people && data.referrer.people.first)
  //     value = data.referrer.people.first
  //   else if (!fromWorklist && externalRefData && externalRefData.PatientFirstName)
  //     value = getSplitName(NewReferrerFirstName)
  //   return value;
  // }
  const [isOpenLoadRefModal, setIsOpenLoadRefModal] = useState(false);
  const [isOpenSeachRef, setIsOpenSeachRef] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [referrerValidFirstName, setReferrerValidFirstName] = useState(false);
  const [referrerValidLastName, setReferrerValidLastName] = useState(false);
  const [referrerValidEmail, setReferrerValidEmail] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [referrerValidInsComp, setReferrerValidInsComp] = useState(false);

  const [searchFields, setSearchFields] = useState({
    firstName: (NewReferrerFirstName ? NewReferrerFirstName : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    lastName: '',
    ReferName: (NewReferrerFirstName ? NewReferrerFirstName : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    refEmail: (externalRefData?.RequesterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),

  });
  const [selectedItem, setSelectedItem] = useState(undefined);

  const getSplitName = (name) => {
    let fname = '', lname = '';
    if (name.includes(',')) {
      let managerNameArr = name.split(',');
      fname = managerNameArr[managerNameArr.length - 1];
      lname = managerNameArr[0];
    }
    else {
      let managerNameArr = name.split(' ');
      fname = managerNameArr[0];
      lname = managerNameArr[managerNameArr.length - 1];
    }
    return { fname, lname };
  }
  const [formRefFields, setFormRefFields] = useState({

    refFirstName: (fromExternal ? getSplitName(NewReferrerFirstName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
    refLastName: (fromExternal ? getSplitName(NewReferrerFirstName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
    companyName: '',
    refEmail: (externalRefData?.RequesterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
    refPhone: (externalRefData?.RequesterPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
    refPhoneExt: '',
    refFax: '',
    refAddress: '',
    refAddress2: '',
    refeType: '',
    refCity: '',
    refCounty: '',
    refState: '',
    refZip: '',
    refZipCodeId: '',
    refZipId: '',
    refTimeZone: '',
    insComp: '',
    refNotes: '',
    refSource: '',
  });

  // const getSplitName = (name) => {
  //   let fname = '', lname = '';
  //   if (name.includes(',')) {
  //     let managerNameArr = name.split(',');
  //     fname = managerNameArr[managerNameArr.length - 1];
  //     lname = managerNameArr[0];
  //   }
  //   else {
  //     let managerNameArr = name.split(' ');
  //     fname = managerNameArr[0];
  //     lname = managerNameArr[managerNameArr.length - 1];
  //   }
  //   return { fname, lname };
  // }

  useEffect(() => {
    if (isOpen) {
      const breadCrumb = {
        functionName: 'logBreadCrumbs',
        zebraUserId: userId,
        userid: userId,
        breadcrumbkey: 'OPENREF',
        refTableId: 0,
        refId: 0,
        refTable: '',
      };
      logBreadCrumbs(breadCrumb);
      if (isNew) {
        setIsOpenSeachRef(true);
      } else {
        setIsOpenLoadRefModal(true);
      }
      let fname = '', lname = '';
      if (NewReferrerFirstName) {
        let name = getSplitName(NewReferrerFirstName)
        fname = name.fname;
        lname = name.lname;
      } else if (!isFromLanding && isEmpty(updatedRefInfo) && !isEmpty(loadInfoFromExistingPatient)) {
        fname = loadInfoFromExistingPatient?.first || ''
        lname = loadInfoFromExistingPatient?.last || ''
      } else {
        if (fromWorklist && worklistData) {
          if (worklistData?.referrer?.people?.first)
            fname = worklistData?.referrer?.people?.first
          if (worklistData?.referrer?.people?.last)
            lname = worklistData?.referrer?.people?.last
        }
      }

      setSearchFields({
        firstName: fname,
        lastName: lname,
        claimNumber: '',
      });
      const payload = {
        firstName: fname,
        lastName: lname,
        userId,
      };
      (NewReferrerFirstName || (fname || lname)) && getReferrerInfo(payload);
    }
  }, [isOpen]);


  useEffect(() => {
    if (fromWorklist && worklistData) {
      if (worklistData?.referrer?.id) {
        const item = referrerInfo && referrerInfo.filter(o => o.PK_adjuster_id === worklistData?.referrer?.id);
        let index = referrerInfo && referrerInfo.findIndex(i => i.PK_adjuster_id === worklistData?.referrer?.id);
        index === -1 ? setSelectedItem(undefined) : setSelectedItem(item && item[0]);
        setIndex(index)
      }
    }
  }, [referrerInfo])

  useEffect(() => {
    if (!formRefFields.refFirstName)
      setReferrerValidFirstName(false);
    else setReferrerValidFirstName(true);
    if (!formRefFields.refLastName)
      setReferrerValidLastName(false);
    else setReferrerValidLastName(true);
    if (!formRefFields.refEmail)
      setReferrerValidEmail(false);
    else setReferrerValidEmail(true);
    if (!formRefFields.insComp)
      setReferrerValidInsComp(false);
    else setReferrerValidInsComp(true);
    setRefData(formRefFields);
  }, [formRefFields]);

  useEffect(() => {
    if (isOpenLoadRefModal) {
      if (selectedItem) {
        const params = {
          userId,
          functionName: refType[selectedItem.refType.toLowerCase()],
          id: selectedItem.PK_adjuster_id,
        };
        getLoadReferrer(params, selectedItem.refType);
      } else if (!isNew && !referrerInfo) {
        if (!isEmpty(updatedRefInfo)) {
          const pplCheck = updatedRefInfo.referrer.people || {};
          const adrCheck = updatedRefInfo.referrer.address || {};
          setFormRefFields({
            refFirstName: pplCheck.first,
            refLastName: pplCheck.last,
            companyName: '',
            refEmail: pplCheck.email1,
            refPhone:
              pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
            refPhoneExt: pplCheck && pplCheck.phoneExt,
            refFax: pplCheck && pplCheck.fax,
            refAddress: adrCheck.street1,
            refZipCodeId: adrCheck.zipCodeId,
            refZipId: adrCheck.zipCode && adrCheck.zipCode.id,
            refTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
            refAddress2: adrCheck.street2,
            refeType: 'Referrer',
            refCity: adrCheck.zipCode && adrCheck.zipCode.city,
            refCounty: adrCheck.zipCode && adrCheck.zipCode.county,
            refState: adrCheck.zipCode && adrCheck.zipCode.state,
            refZip: adrCheck.zipCode && adrCheck.zipCode.zip,
            refSource: 'E-Mail',
            insComp: updatedRefInfo.referrer.insurer,
            refNotes: updatedRefInfo.referrer.notes,
          });
        }
      }
    }
  }, [isOpenLoadRefModal]);

  useEffect(() => {
    if (!isEmpty(loadRefInfo)) {
      const pplCheck = loadRefInfo.people || {};
      const adrCheck = loadRefInfo.address || {};
      setFormRefFields({
        refFirstName: pplCheck.first,
        refLastName: pplCheck.last,
        companyName: '',
        refEmail: pplCheck.email1,
        refPhone:
          pplCheck && pplCheck.phone && formatPhoneNumber(pplCheck.phone),
        refPhoneExt: pplCheck && pplCheck.phoneExt,
        refFax: pplCheck && pplCheck.fax,
        refAddress: adrCheck.street1,
        refZipCodeId: adrCheck.zipCodeId,
        refZipId: adrCheck.zipCode && adrCheck.zipCode.id,
        refTimeZone: adrCheck.zipCode && adrCheck.zipCode.timeZone,
        refAddress2: adrCheck.street2,
        refeType: selectedItem.refType,
        refCity: adrCheck.zipCode && adrCheck.zipCode.city,
        refCounty: adrCheck.zipCode && adrCheck.zipCode.county,
        refState: adrCheck.zipCode && adrCheck.zipCode.state,
        refZip: adrCheck.zipCode && adrCheck.zipCode.zip,
        refSource: 'E-Mail',
        insComp: loadRefInfo.insurer,
        refNotes: loadRefInfo.notes,
      });
    }
  }, [loadRefInfo]);

  const loadRefInfoToggle = () => {
    setIsOpenLoadRefModal(!isOpenLoadRefModal);
    setSelectedItem(undefined);
    setIndex(undefined);
    onClose();
    resetFormData();
    resetZipList();
    if (isNew) {
      setFormRefFields({ ...formRefFields, refFirstName: '', refLastName: '', refEmail: '', insComp: '' })
    }
  };

  const searchRefToggle = () => {
    setIsOpenSeachRef(!isOpenSeachRef);
    onClose();
    resetFormData();
  };

  const resetFormData = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    resetSearchReferrerTable();
    resetLoadReferrer();
    setSelectedItem(undefined);
    setIndex(undefined);
  };

  const isDisabledInput = () => {
    return userRole === 'Administrator' ? false : true;
  };

  const onChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    setSearchFields({ ...searchFields, [name]: value });
  };

  const onChangeHandlerRefType = (id, value) => {
    setFormRefFields({ ...formRefFields, [id]: value });
  };

  const setMap = {
    refFirstName: setReferrerValidFirstName,
    refLastName: setReferrerValidLastName,
    refEmail: setReferrerValidEmail,
    insComp: setReferrerValidInsComp
  }

  const formatPhoneNum = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const onLoadChangeHandler = event => {
    const target = event.target;
    let value = target.value;
    const name = target.id;
    if (name == "refPhone") {
      value = formatPhoneNum(value);
    }
    setFormRefFields({ ...formRefFields, [name]: value });
    setMap[name] && setMap[name](value !== "" ? true : false);
  };

  const renderAddressColumn = item => {
    let print = '';
    if (item.chr_city) {
      print = (
        <>
          <br />
          <span>
            {`${item.chr_city}, ${item.chr_state}  ${item.chr_zipcode}`}
          </span>
        </>
      );
    }
    return (
      <>
        {item.chr_street1}
        {print}
      </>
    );
  };

  const renderPhoneColumn = item => {
    const phone = item.chr_phone || '';
    if (phone) {
      return formatPhoneNumber(phone);
    }
  };

  const onRowClickHandler = item => {
    setSelectedItem(item);
  };

  const getSearchReferrerTable = () => {
    return (
      <div>
        <AppTable
          modalHeight={200}
          columns={[
            {
              header: 'First Name',
              field: 'chr_firstname',
            },
            {
              header: 'Last Name',
              field: 'chr_lastname',
            },
            {
              header: 'Address',
              field: 'chr_street1',
              render: item => renderAddressColumn(item),
            },
            {
              header: 'Phone No.',
              field: 'chr_phone',
              render: item => renderPhoneColumn(item),
            },
            {
              header: 'Type',
              field: 'refType',
            },
          ]}
          data={referrerInfo || []}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          rowClickHandler={onRowClickHandler}
          sortImmediately={false}
          key="search-referrer-table"
          highlightedRow={index}
        />
      </div>
    );
  };

  const openLoadRefModal = () => {
    setIsOpenSeachRef(false);
    setIsOpenLoadRefModal(true);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const params = {
      ...searchFields,
      userId,
    };
    getReferrerInfo(params);
  };

  const onChangeCurrentRef = () => {
    setSearchFields({
      firstName: '',
      lastName: '',
    });
    setIsOpenLoadRefModal(false);
    setSelectedItem(undefined);
    setIndex(undefined);
    setIsOpenSeachRef(true);
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip) {
      setFormRefFields({
        ...formRefFields,
        refCity: selectedZip.chr_city,
        refState: selectedZip.chr_state,
        refCounty: selectedZip.chr_county,
        refZip: selectedZip.chr_zipcode,
        refZipCodeId: selectedZip.code_id,
        refZipId: selectedZip.code_id,
        refTimeZone: selectedZip.chr_timezone,
      });
    }
    resetZipList();
  };

  const onSaveHandler = () => {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: userId,
      userid: userId,
      breadcrumbkey: 'SAVEREF',
      refTableId: loadRefInfo.id,
      refId: 0,
      refTable: 'REF',
    };
    logBreadCrumbs(breadCrumb)
    if (selectedItem) {
      const savedId = loadRefInfo.id;
      const saveType = selectedItem.refType;
      const response = loadRefInfo;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      if (selectedItem.refType !== 'Referrer') {
        response.id = 0;
        response.peopleId = 0;
        response.people.id = 0;
        response.addressId = 0;
        response.address.id = 0;
        response.clientId = '5727';
        response.client = {
          id: '5727',
        };
      }

      response.people.first = formRefFields.refFirstName;
      response.people.last = formRefFields.refLastName;
      response.people.email1 = formRefFields.refEmail;
      response.people.phone =
        formRefFields.refPhone &&
        formRefFields.refPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formRefFields.refPhoneExt;
      response.people.fax = formRefFields.refFax;
      response.address.street1 = formRefFields.refAddress;
      response.address.street2 = formRefFields.refAddress2;
      response.address.zipCode.city = formRefFields.refCity;
      response.address.zipCode.county = formRefFields.refCounty;
      response.address.zipCode.state = formRefFields.refState;
      response.address.zipCode.zip = formRefFields.refZip;
      response.address.zipCode.id = formRefFields.refZipId;
      response.address.zipCodeId = formRefFields.refZipCodeId;
      //response.address.zipCode.county = refSource;
      response.insurer = formRefFields.insComp;
      response.notes = formRefFields.refNotes;
      delete response.comments;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.adjusterOffice;
      delete response.title;
      const refId = referralId || 0;
      const referrerId = response.id;
      const params = {
        response,
        userId,
        refId,
        referrerId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.insurer === '' || response.insurer === undefined) {
        alert('You must provide an insurer in order to save.');
        return;
      }
      saveReferrer(params, savedId, saveType);
      loadRefInfoToggle();
    } else if (!isNew && !referrerInfo) {
      const response = updatedRefInfo && updatedRefInfo.referrer;
      let addId;
      addId = response.addressId;
      if (addId === undefined) {
        response['addressId'] = 0;
        response.address['id'] = 0;
      }
      response.people.first = formRefFields.refFirstName;
      response.people.last = formRefFields.refLastName;
      response.people.email1 = formRefFields.refEmail;
      response.people.phone =
        formRefFields.refPhone &&
        formRefFields.refPhone.replace(/[\s()-]/g, '');
      response.people.phoneExt = formRefFields.refPhoneExt;
      response.people.fax = formRefFields.refFax;
      response.address.street1 = formRefFields.refAddress;
      response.address.street2 = formRefFields.refAddress2;
      response.address.zipCode.city = formRefFields.refCity;
      response.address.zipCode.county = formRefFields.refCounty;
      response.address.zipCode.state = formRefFields.refState;
      response.address.zipCode.zip = formRefFields.refZip;
      response.address.zipCode.id = formRefFields.refZipId;
      response.address.zipCodeId = formRefFields.refZipCodeId;
      //response.address.zipCode.county = refSource;
      response.insurer = formRefFields.insComp;
      response.notes = formRefFields.refNotes;
      delete response.comments;
      delete response.licenseNumber;
      delete response.npi;
      delete response.medicalProvider;
      delete response.jurisProperClaimId;
      delete response.groupName;
      delete response.specialtyId;
      delete response.adjusterOffice;
      delete response.title;
      const refId = referralId || 0;
      const referrerId = response.id;
      const params = {
        response,
        userId,
        refId,
        referrerId
      };
      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.insurer === '' || response.insurer === undefined) {
        alert('You must provide an insurer in order to save.');
        return;
      }
      saveReferrer(params);
      loadRefInfoToggle();
    } else {
      const response = {};
      response.id = 0;
      response.peopleId = 0;
      response.addressId = 0;
      response.clientId = '5727';
      response.client = {
        id: '5727',
      };
      response.insurer = formRefFields.insComp;
      response.notes = formRefFields.refNotes;
      response.people = {
        id: 0,
        first: formRefFields.refFirstName,
        last: formRefFields.refLastName,
        email1: formRefFields.refEmail,
        phone:
          formRefFields.refPhone &&
          formRefFields.refPhone.replace(/[\s()-]/g, ''),
        phoneExt: formRefFields.refPhoneExt,
        fax: formRefFields.refFax,
      };
      response.address = {
        id: 0,
        street1: formRefFields.refAddress,
        street2: formRefFields.refAddress2,
        zipCodeId: formRefFields.refZipCodeId,
        zipCode: {
          city: formRefFields.refCity,
          county: formRefFields.refCounty,
          state: formRefFields.refState,
          zip: formRefFields.refZip,
          id: formRefFields.refZipId,
          timeZone: formRefFields.refTimeZone,
        },
      };

      if (response.people.first === '' || response.people.first === undefined) {
        alert('You must provide a first name in order to save.');
        return;
      }
      if (response.people.last === '' || response.people.last === undefined) {
        alert('You must provide a last name in order to save.');
        return;
      }
      if (
        response.people.email1 === '' ||
        response.people.email1 === undefined
      ) {
        alert('You must provide an email in order to save.');
        return;
      }
      if (response.insurer === '' || response.insurer === undefined) {
        alert('You must provide an insurer in order to save.');
        return;
      }
      const refId = referralId || 0;
      const referrerId = response.id;
      const params = {
        response,
        userId,
        refId,
        referrerId
      };
      saveReferrer(params);
      loadRefInfoToggle();
    }
    // setIsExternal();
    setReferrerEdited(true);

  };

  const getBody = () => {
    if (referrerInfo && referrerInfo.length > 0) {
      return (
        <div className="text-center">
          You appear to have potential matches.
          <br />
          Are you sure you wish to add a new referrer as this could create
          duplicates?
        </div>
      );
    } else {
      return (
        <div className="text-center">
          Are you sure you want to add a new referrer?
        </div>
      );
    }
  };

  const confirmationToggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(undefined);
    setIndex(undefined);
    setFormRefFields({
      refFirstName: (fromExternal ? getSplitName(NewReferrerFirstName).fname : '') || (!isFromLanding && loadInfoFromExistingPatient?.first),
      refLastName: (fromExternal ? getSplitName(NewReferrerFirstName).lname : '') || (!isFromLanding && loadInfoFromExistingPatient?.last),
      companyName: '',
      refEmail: (externalRefData?.RequesterEmail || '') || (!isFromLanding && loadInfoFromExistingPatient?.email1),
      refPhone: (externalRefData?.RequesterPhone || '') || (!isFromLanding && loadInfoFromExistingPatient?.phone),
      refPhoneExt: '',
      refFax: '',
      refAddress: '',
      refAddress2: '',
      refeType: '',
      refCity: '',
      refCounty: '',
      refState: '',
      refZip: '',
      refZipCodeId: '',
      refZipId: '',
      refTimeZone: '',
      insComp: '',
      refNotes: '',
      refSource: '',
    });
  };

  const getFooter = () => {
    return (
      <>
        <Button color="secondary" onClick={confirmationToggle}>
          NO
        </Button>
        <Button
          color="primary"
          onClick={() => {
            openLoadRefModal();
            confirmationToggle();
          }}
        >
          YES
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpenLoadRefModal}>
        <ModalHeader toggle={loadRefInfoToggle}>ADD A NEW REFERRER</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col sm={3}>
              <Label for="refFirstName">Referrer First Name</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="refFirstName"
                className={referrerValidFirstName ? '' : "error"}
                value={formRefFields.refFirstName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !referrerInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={3}>
              <Label for="refLastName">Referrer Last Name</Label>{' '}
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="refLastName"
                className={referrerValidLastName ? '' : "error"}
                value={formRefFields.refLastName || ''}
                onChange={e => onLoadChangeHandler(e)}
                readOnly={
                  (selectedItem || (!isNew && !referrerInfo)) &&
                  isDisabledInput()
                }
              />
            </Col>
            <Col sm={3}>
              <Label for="companyName">Company Name</Label>
              <Input
                type="text"
                id="companyName"
                value={formRefFields.companyName || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <Col style={{ top: '-8px', color: 'red', paddingLeft: 0 }}>
            {' '}
            {(selectedItem || (!isNew && !referrerInfo)) && (
              <span>
                {isDisabledInput()
                  ? 'Please contact Administrator if you want to change a Referrer Name'
                  : 'Warning: Changing the name on this record will also change it on any referral that this record is linked to!'}
              </span>
            )}
          </Col>
          <FormGroup row>
            <Col sm={4}>
              <Label for="refEmail">Referrer Email</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="refEmail"
                value={formRefFields.refEmail || ''}
                className={referrerValidEmail ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="refPhone">Referrer Phone#</Label>
              <Input
                type="tel"
                id="refPhone"
                value={formRefFields.refPhone || ''}
                onChange={e => onLoadChangeHandler(e)}
                placeholder="(xxx)xxx-xxxx"
              />
            </Col>
            <Col sm={2}>
              <Label for="refPhoneExt">Phone Ext:</Label>
              <Input
                type="tel"
                id="refPhoneExt"
                value={formRefFields.refPhoneExt || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="refFax">Referrer Fax#</Label>
              <Input
                type="text"
                id="refFax"
                value={formRefFields.refFax || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label for="refAddress">Referrer Address</Label>
              <Input
                type="text"
                id="refAddress"
                value={formRefFields.refAddress || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="refAddress2">Referrer Address 2</Label>
              <Input
                type="text"
                id="refAddress2"
                value={formRefFields.refAddress2 || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={4}>
              <Label for="refeType">Referrer Type </Label>
              <AppInput
                changeHandler={onChangeHandlerRefType}
                option={{
                  value: 'refeType',
                  displayValue: 'chr_element',
                }}
                placeholder="Choose..."
                value={formRefFields.refeType || ''}
                data={referrerType}
                id="refeType"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="refCity">Referrer City</Label>
              <Input
                type="text"
                id="refCity"
                value={formRefFields.refCity || ''}
                readOnly
              />
            </Col>
            <Col sm={1}>
              <Label for="refState">State</Label>
              <Input
                type="text"
                id="refState"
                value={formRefFields.refState || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="refCounty">County</Label>
              <Input
                type="text"
                id="refCounty"
                value={formRefFields.refCounty || ''}
                readOnly
              />
            </Col>
            <Col sm={2}>
              <Label for="refZip">Referrer Zip</Label>
              <Input
                type="text"
                id="refZip"
                value={formRefFields.refZip || ''}
                readOnly
              />
            </Col>
            <Col sm={4}>
              <Label for="refZip">Lookup Zip</Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={(e, actionType) =>
                  handleFormFields(e, 'refZip', actionType)
                }
                placeholder=""
                menuPlacement="bottom"
                onKeyDown={e => handleTypeAheadSearch(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                menuIsOpen={zipList.length}
                value=""
                closeMenuOnSelect={true}
                options={
                  zipList.length
                    ? zipList.map(obj =>
                      assign(obj, {
                        label: obj.zip_type_with_county,
                        value: obj.code_id,
                      })
                    )
                    : []
                }
                name="refZip"
                id="refZip"
              />
              <span>type in 3+ digits and hit tab to search</span>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3}>
              <Label for="insComp">Insurance Company</Label>
              <FontAwesomeIcon
                className="red-text"
                icon={faAsterisk}
                style={{ fontSize: '8px' }}
              />
              <Input
                type="text"
                id="insComp"
                value={formRefFields.insComp || ''}
                className={referrerValidInsComp ? '' : "error"}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
            <Col sm={3}>
              <Label for="refSource">Referrer Source</Label>
              <AppInput
                changeHandler={onChangeHandlerRefType}
                option={{ value: 'refSource', displayValue: 'value' }}
                placeholder="Choose..."
                value={formRefFields.refSource || ''}
                data={ReferralSourceList}
                id="refSource"
              />
            </Col>
            <Col className="pgo-check">
              <Label check>
                <Input id="pgo" type="checkbox" onChange={() => { }} /> PGO
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={12}>
              <Label for="refNotes">Comments</Label>
              <Input
                type="textarea"
                id="refNotes"
                value={formRefFields.refNotes || ''}
                onChange={e => onLoadChangeHandler(e)}
              />
            </Col>
          </FormGroup>
          <div style={{ float: 'right' }}>
            <Button type="button" onClick={onChangeCurrentRef}>
              CHANGE CURRENT REFERRER
            </Button>
            {'  '}
            <Button type="button" onClick={onSaveHandler}>
              SAVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpenSeachRef}>
        <ModalHeader toggle={searchRefToggle}>SEARCH REFERRER</ModalHeader>
        <ModalBody>
          <Form onSubmit={onFormSubmit}>
            <Row form>
              <Col sm={3}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    id="firstName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.firstName}

                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>{' '}
                  <Input
                    type="text"
                    id="lastName"
                    onChange={e => onChangeHandler(e)}
                    value={searchFields.lastName}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <br />
                <Button style={{ marginTop: '7px' }} type="submit">
                  FIND
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg="12">{getSearchReferrerTable()}</Col>
            </Row>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                disabled={!referrerInfo}
                onClick={confirmationToggle}
              >
                NEW
              </Button>
              {'  '}
              <Button
                type="button"
                disabled={!selectedItem}
                onClick={openLoadRefModal}
              >
                SELECT
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <PopupModal
        content={getBody()}
        title="Confirmation"
        externalToggle={confirmationToggle}
        isOpen={isConfirmModalOpen}
        footerContent={getFooter()}
        size="lg"
      />
    </>
  );
};

NewReferrerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  referrerInfo: PropTypes.array,
  getReferrerInfo: PropTypes.func,
  resetSearchReferrerTable: PropTypes.func,
  getLoadReferrer: PropTypes.func,
  loadRefInfo: PropTypes.object,
  referrerType: PropTypes.array,
  getZipList: PropTypes.func,
  zipList: PropTypes.array,
  resetZipList: PropTypes.func,
  resetLoadReferrer: PropTypes.func,
  saveReferrer: PropTypes.func,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  return {
    referrerInfo: fast360Info.referrerInfo,
    loadRefInfo: fast360Info.loadRefInfo,
    loadClaimantByIdInfo: fast360Info.loadClaimantByIdInfo,
    zipList: fast360Info.zipList,
    userId: user.id,
    userRole: user.role_name,
    referrerType: initialData.referrerType,
    ReferralSourceList: initialData.ReferralSourceList,
    updatedRefInfo: fast360Info.updatedRefInfo
  };
};

const mapDispatchToProps = dispatch => ({
  getReferrerInfo: data => dispatch(fast360Operations.getReferrerInfo(data)),
  getLoadReferrer: (data, type) => dispatch(fast360Operations.getLoadReferrer(data, type)),
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  resetSearchReferrerTable: () =>
    dispatch(fast360Actions.resetSearchReferrerTable()),
  resetLoadReferrer: () => dispatch(fast360Actions.resetLoadReferrer()),
  saveReferrer: (data, savedId, saveType) => dispatch(fast360Operations.saveReferrer(data, savedId, saveType)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewReferrerModal);
