import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, CustomInput, Input } from 'reactstrap';

import { commonOperations } from 'common/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';
import AppTable from 'shared/components/table/AppTable';
import Fast360FileAppTable from 'shared/components/table/Fast360FileAppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import * as FileUtils from 'shared/utils/fileUtils';
import * as NumberUtils from 'shared/utils/numberUtils';
import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone';


import { check } from 'prettier';
import { fast360Operations, fast360Actions } from '../store';
import moment from 'moment';


let checkedItems = [];
let uploadCheckedItems = [];
let viewDocFileName = undefined;
const Fast360FileModal = ({
  loadDemandDocuments,
  demandFileUpload,
  profile,
  user,
  isOpen,
  selectedBills,
  claimObject,
  documents,
  loadDeleteDemandDocuments,
  confirmDemandBills,
  isShowListOfDoc,
  clicked,
  closeListOfDoc,
  retriveDemandDocument,
  retrieveDoc,
  uploadedDocuments,
  getDocTypes,
  resetRetriveDemandDocument,
  fileDesc,
  dataObject,
  getCcDocument,
  currentUserId,
  getCcDocumentData,
  saveFileUpload,
  updatedFilesData,
  updatedFilesDataCall,
  alert,
  deleteDocument,
  logBreadCrumbs,
  retrieveReferralDocument,
  updateRetriveDocument,
  newRef,
  newData,
  refId,
  updateSaveReferral,
  onNewRefExit,
  onExit,
  tableName,
  serviceId,
  fromExternal,
  isExternalReferral,
  refSaved
}) => {
  const [isDemandModalOpen, setIsDemandModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isOpenAttachFiles, setIsOpenAttachFiles] = useState(false);
  const [isRefreshDocuments, setIsRefreshDocuments] = useState(true);
  const [isSedgewickReferral, setIsSedgewickReferral] = useState(false);
  const [checkedVal, setCheckedVal] = useState(false);
  const [removeVal, setRemoveVal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const attachFileRef = useRef();
  const [demandNoteValue, setDemandNoteValue] = useState('');
  const [filesPayload, setFilesPayload] = useState([]);
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (isOpen) {
      setIsOpenAttachFiles(true);
    }
  }, [isOpen])


  const getCCDocCall = () => {
    if (dataObject) {
      const { FK_referral_id, ServiceType, int_reference_table_id, referralId, module, id, sedgwickReferralId } = dataObject;
      let serviceTypeLower = ServiceType ? ServiceType?.toLowerCase() : module?.toLowerCase();
      const objMap = {
        'modification': 'modifications',
        'diagnostic': 'diagnostics',
        'special transport' : 'special_transp',
        'int': 'interpretation',
        'acc': 'accommodation',
        'aud': 'audiology',
        'dtl': 'dental',
        'dgn': 'diagnostics',
        'hhc': 'hhc',
        'mse': 'mse',
        'mod': 'modifications',
        'spt': 'special_transp',
        'trn': 'transportation',
        "transportation": 'transportation',
        'accommodation': 'accommodation',
        'audiology': 'audiology',
        'dental': 'dental',
        'interpretation': 'interpretation'
      }
      let postPayload = {
        "functionName": 'loadCCDocuments',
        "zebraUserId": currentUserId,
        "referral_id": FK_referral_id ? FK_referral_id : referralId,
        "referral_service_id": int_reference_table_id ? int_reference_table_id : id,
        "tablename": (serviceTypeLower == 'modification' || serviceTypeLower == 'diagnostic' || serviceTypeLower == 'special transport') ? objMap[serviceTypeLower] : objMap[serviceTypeLower],
      };
      if(sedgwickReferralId && fromExternal){
        postPayload.referral_id = sedgwickReferralId;
        postPayload.referral_service_id = serviceId;
        postPayload.tablename = "sedgwick_referral";
      } 
      getCcDocument(postPayload)
     
      let breadCrumb = {
        "functionName": 'logBreadCrumbs',
        "zebraUserId": currentUserId,
        "userid": currentUserId,
        "breadcrumbkey": 'LOADDOCS',
        "refTableId":  int_reference_table_id ? int_reference_table_id : id,
        "refId": FK_referral_id ? FK_referral_id : referralId,
        "refTable": dataObject.chr_reference_table ? dataObject.chr_reference_table : module,
      };
      logBreadCrumbs(breadCrumb)
    }
  }
  const handleModalChange = (data) => {
    if(data.length > 0) {
      setIsOpenAttachFiles(false);
      setTimeout(() => {
        setIsOpenUploadFiles(true);
      }, 500);
    }
  }

  const getCCDocCallNew = () => {
    let postPayload = {
      "functionName": 'loadCCDocuments',
      "zebraUserId": currentUserId,
      "referral_id": refId,
    };
    if(isExternalReferral && dataObject.sedgwickReferralId){
      postPayload.referral_service_id = dataObject.sedgwickReferralId;
      postPayload.tablename = "sedgwick_referral";
      postPayload.referral_id = dataObject.sedgwickReferralId;
      refId && getCcDocument(postPayload, handleModalChange, fromExternal || isExternalReferral)
      setIsSedgewickReferral(true);
    } else {
      refId && getCcDocument(postPayload);
    }
  }

  const getCCDocCallNew1 = (updatedFilesData) => {
    if (updatedFilesData.length) {
      let postPayload = {
        "functionName": 'loadCCDocuments',
        "zebraUserId": currentUserId,
        "referral_id": refId,
        "referral_service_id": updatedFilesData[0].record_id,
        "tablename": updatedFilesData[0].table_name
      };
      refId && getCcDocument(postPayload)
    }
  }

  useEffect(() => {
    if (isOpen && newRef) getCCDocCallNew()
    else getCCDocCall();
  }, [newRef, isOpen])


  //   useEffect(() => {
  //     if (isRefreshDocuments && (selectedBills.length > 0 || isShowListOfDoc)) {
  //       loadDemandDocuments({
  //         profile,
  //         billId: claimObject.int_claimcode,
  //       });

  //       setIsRefreshDocuments(false);
  //     }
  //   }, [
  //     loadDemandDocuments,
  //     profile,
  //     filesToUpload,
  //     isOpenUploadFiles,
  //     isRefreshDocuments,
  //     isDemandModalOpen,
  //     selectedBills,
  //   ]);

  //   useEffect(() => {
  //     viewDocFileName =
  //       viewDocFileName === fileDesc ? viewDocFileName : undefined;
  //     if (retrieveDoc && !viewDocFileName) {
  //       debugBase64(retrieveDoc, fileDesc);
  //       viewDocFileName = fileDesc;
  //       resetRetriveDemandDocument();
  //     }
  //   }, [retrieveDoc]);

  const getDemandModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={stateDemandModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={toggleUploadFiles}>
          Yes
        </Button>
      </>
    );
  };

  const onDrop = acceptedFiles => {
    handleDrag(acceptedFiles);
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


  const getDeleteModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={stateDeleteModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={handleDleteFiles}>
          Yes
        </Button>
      </>
    );
  };

  const getDemandModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>
            The demand status has been added to the selected bills. Would you
            like to upload any documentation?
          </span>
        </Col>
      </Row>
    );
  };

  const getDeleteModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to delete these file(s)?</span>
        </Col>
      </Row>
    );
  };

  const stateDemandModalToggle = () => {
    setIsDemandModalOpen(!isDemandModalOpen);
    setDemandNoteValue('');
  };

  const stateDeleteModalToggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const demandHanlder = async () => {
    let billList = [];
    selectedBills && selectedBills.forEach(item => {
      return billList.push(parseInt(item));
    });
    const params = {
      functionName: 'addDemandStatus',
      billList: billList,
      zebraUserId: user.user_id,
      profile: profile,
    };
    await confirmDemandBills(params);
    stateDemandModalToggle();
  };

  const changeDemandNoteValue = event => {
    setDemandNoteValue(event.target.value)
  };

  const toggleUploadFiles = () => {
    setIsOpenUploadFiles(!isOpenUploadFiles);
  };

  const toggleAttachFiles = () => {
    //clicked();
    setIsOpenAttachFiles(!isOpenAttachFiles);
    // isFromModal && isShowListOfDoc && closeListOfDoc();
    //setIsDemandModalOpen(false);
  };

  const handleSelectUploadFile = e => {
    e.preventDefault();
    let arr = [];

    const keyOffset = filesToUpload.length;
    const filesToAdd = Object.values(
      R.mapObjIndexed(
        (value, key) => ({
          id: parseInt(key, 10) + keyOffset,
          file: value,
          selected: false,
          description: '',
          docType: ''
        }),
        attachFileRef.current.files
      )
    );
    attachFileRef.current.value = null;

    const newFilesToUpload = R.concat(filesToUpload, filesToAdd);
    const result = [];
    const map = new Map();
    for (const [index, value] of newFilesToUpload.entries()) {
      if (!map.has(value.file.name)) {
        map.set(value.file.name, true);
        result.push({ ...value, id: index + 1 });
      }
    }
    setFilesToUpload(result);
  };

  const handleDrag = arr => {
    const keyOffset = filesToUpload.length;
    arr.map((item, index) => {
      filesToUpload.push({ file: item, id: keyOffset + index + 1, selected: false,  description: '', docType: '' });
    });
    const result = [];
    const map = new Map();
    for (const [index, value] of filesToUpload.entries()) {
      if (!map.has(value.file.name)) {
        map.set(value.file.name, true);
        result.push({ ...value, id: index + 1 });
      }
    }
    setFilesToUpload(result);

  };


  const clearFilesToUpload = () => {
    setFilesToUpload([]);
    uploadCheckedItems = [];
    setRemoveVal(false);
  };

  const removeFilesToUpload = () => {
    const removeId = uploadCheckedItems.map(file => file.id);
    const selectedFiles = filesToUpload.filter(
      file => !removeId.includes(file.id)
    );
    const updatedFiles = selectedFiles.map((i, idx) => {
      return { ...i, id: idx + 1 }
    })
    setFilesToUpload(selectedFiles);
    if (updatedFilesData.length > updatedFiles.length) {
      let finalFiles = [];
      finalFiles = updatedFilesData.filter(a => selectedFiles.map(b => b.id).includes(a.id));
      updatedFilesDataCall(finalFiles);
    }
    uploadCheckedItems = [];
    setRemoveVal(false);
  };

  const getTotalUploadSize = () => {
    let totalBytes = 0;
    for (let i = 0; i < filesToUpload.length; i++) {
      totalBytes += filesToUpload[i].file.size;
    }

    return NumberUtils.formatFileSize(totalBytes);
  };

  const uploadCall = () => {
    let fileData = '';
    Promise.all([
      R.map(async item => {
        let breadCrumb = {
          "functionName": 'logBreadCrumbs',
          "zebraUserId": item.zebraUserId,
          "userid": item.zebraUserId,
          "breadcrumbkey": 'UPLOADATT',
          "refTableId": item.record_id,
          "refId": refId,
          "refTable": dataObject?.chr_reference_table,
        };
        logBreadCrumbs(breadCrumb)

        fileData = await FileUtils.fileToBase64(item.file);
        return saveFileUpload({
          "functionName": item.functionName,
          "zebraUserId": item.zebraUserId,
          "filedescription": item.filedescription,
          "Filename": item.Filename,
          "filedata": fileData,
          "fileprivate": item.fileprivate,
          "filetype": item.filetype,
          "record_id": item.record_id,
          "table_name": item.table_name,
          "upload": item.upload,
          "logical_name": item.Filename
        });
      }, updatedFilesData),
    ]).then(data => {
      setTimeout(() => {
        setIsRefreshDocuments(true);
        updatedFilesDataCall([]);
        getCCDocCall();
      }, 3000);
      toggleUploadFiles();
      toggleAttachFiles();
    });
  }

  const uploadNewCall = () => {
    let fileData = '';
    Promise.all([
      R.map(async item => {
        let breadCrumb = {
          "functionName": 'logBreadCrumbs',
          "zebraUserId": item.zebraUserId,
          "userid": item.zebraUserId,
          "breadcrumbkey": 'UPLOADATT',
          "refTableId": item.record_id,
          "refId": refId,
          "refTable": dataObject?.chr_reference_table,
        };
        logBreadCrumbs(breadCrumb)
        fileData = await FileUtils.fileToBase64(item.file);
        return saveFileUpload({
          "functionName": item.functionName,
          "zebraUserId": item.zebraUserId,
          "filedescription": item.filedescription,
          "Filename": item.Filename,
          "filedata": fileData,
          "fileprivate": item.fileprivate,
          "filetype": item.filetype,
          "record_id": item.record_id,
          "table_name": item.table_name,
          "upload": item.upload,
          "logical_name": item.Filename
        });
      }, updatedFilesData),
    ]).then(data => {
      setTimeout(() => {
        setIsRefreshDocuments(true);
        updatedFilesDataCall([]);
        getCCDocCallNew1(updatedFilesData);
      }, 3000);
      toggleUploadFiles();
      toggleAttachFiles();
    });
  }

  const handleUploadFiles = e => {
    e.preventDefault();
    if (updatedFilesData.length == 0 && filesToUpload.length == 0) {
      alert('One or more files have no Description.');
    }
    if(filesToUpload?.some(file => file.docType == "")){
      alert("Error: you must assign a document type to all uploads")
    } else if (updatedFilesData.length > 0 && updatedFilesData.some(e => e.filetype == "")) {
      alert('One or more files have no Type.');
    } else if(filesToUpload?.some(file => file.description == "")){
      alert("Error: you must assign a document description to all uploads")
    } else if (updatedFilesData.length > 0 && updatedFilesData.some(e => e.filedescription == "")) {
      alert('One or more files have no Description.');
    }
    else {
      if (!newRef)
        uploadCall();
      else
        uploadNewCall()
    }

  };

  const handleAttachFiles = e => {
    e.preventDefault();

    toggleAttachFiles();
    toggleUploadFiles();
  };
  const setPayload = (data) => {
    setFilesPayload(data)
  }

  const getUploadFilesBody = () => {
    const filesToUploadCount = filesToUpload.length;
    const filesToUploadLabel =
      filesToUploadCount < 1
        ? ''
        : `Total Files: ${filesToUploadCount}, Total Size: ${getTotalUploadSize()}`;

    return (
      <Form>
        <Row form>
          <Col sm={12}>
            <Fast360FileAppTable
              columns={[
                {
                  header: 'File Name',
                },
                {
                  header: 'Document Type',
                },
                {
                  header: 'Description',
                },
                {
                  header: 'File Size',
                },
                {
                  header: '',
                  render: item => renderColumn(item)
                },
              ]}
              data={filesToUpload}
              dataObject={dataObject}
              setPayload={setPayload}
              newRef={newRef}
              newData={newData}
            />
          </Col>
        </Row>
        {/* <Row form>
          <Col>
            Document Notes:
            <Input
              id="note"
              name="text"
              type="textarea"
              value={demandNoteValue}
              onChange={changeDemandNoteValue}
              style={{ height: '100px' }}
            />
            <br />
          </Col>
        </Row> */}
        <Row>
          <div {...getRootProps()} style={{ border: '1px solid black', height: 100, width: '100%', marginBottom: 10 }}>
            <p style={{ marginLeft: '35%', marginRight: '35%', marginTop: '4%' }}>Drag 'n' drop your files here</p>
            <input {...getInputProps()} />
          </div>
        </Row>
        <Row form>
          <Col sm={6}>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="attachFile"
                multiple
                accept=".pdf,.jpg,.png,.tif,.gif,.doc,.docx,.xls,.xlsx"
                ref={attachFileRef}
                onChange={handleSelectUploadFile}
              />
              <label className="custom-file-label" htmlFor="attachFile">
                {filesToUploadLabel}
              </label>
            </div>
          </Col>
          <Col sm={6} align="right">
            <Button
              className="file-attachment-remove-button"
              disabled={!removeVal}
              onClick={() => removeFilesToUpload()}
            >
              REMOVE
            </Button>
            <Button
              className="file-attachment-button"
              disabled={filesToUpload.length < 1}
              onClick={() => clearFilesToUpload()}
            >
              CLEAR ALL
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const getUploadFilesFooter = () => {
    return (
      <Button
        className="file-attachment-button"
        disabled={filesToUpload.length < 1}
        onClick={handleUploadFiles}
      >
        UPLOAD
      </Button>
    );
  };

  const renderActiveColumn = item => {
    if (item.bol_active === true) {
      return (
        <FontAwesomeIcon className="active-green-circle" icon={faCircle} />
      );
    } else {
      return <FontAwesomeIcon className="red-text" icon={faCircle} />;
    }
  };

  const selectRemoveHandler = (item, event) => {
    uploadCheckedItems = event.target.checked
      ? uploadCheckedItems.concat(item)
      : uploadCheckedItems.filter(row => row.id !== item.id);
    if (uploadCheckedItems.length > 0) {
      setRemoveVal(true);
    } else {
      setRemoveVal(false);
    }
  };

  const renderColumn = item => {
    return (
      <div className="select-action">
        <CustomInput
          id={item?.file?.name}
          name={item?.id}
          key={item?.id}
          type="checkbox"
          onChange={event => {
            selectRemoveHandler(item, event);
          }}
        />
      </div>
    );
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked
      ? checkedItems.concat(item)
      : checkedItems.filter(row => row.id !== item.id);
    if (checkedItems.length > 0) {
      setCheckedVal(true);
    } else {
      setCheckedVal(false);
    }
  };

  const renderCheckBoxColumn = item => {
    return (
      <div className="select-action">
        {item.bol_active && <CustomInput
          id={item.id}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />}
      </div>
    );
  };

  const getDocFromTable = item => {
    const fileName = item.path.split('/')[1];
    const desc = item.description;
    retrieveReferralDocument({
      "functionName": "loadCCDocumentData",
      "zebraUserId": currentUserId,
      fileName,
      "description": desc,
      "path": item.path,
      "isExtFile": fromExternal,

    });
    setFileName(fileName);
  };

  const debugBase64 = (fileDoc, fileDesc) => {
    FileUtils.saveBase64File(fileDesc, fileDoc);
  };


  const getAttachFilesBody = () => {
    return (
      <div>
        <AppTable
          modalHeight={400}
          columns={[
            {
              header: 'Date Added',
              // field: 'upload_date',
              render: item => `${moment(item.upload_date).format('L')} ${moment(item.upload_date).format('LT')}`
            },
            {
              header: 'Document Type',
              field: 'type',
            },
            {
              header: 'Description',
              field: 'description',
            },
            {
              header: 'Delete',
              field: '',
              render: item => renderCheckBoxColumn(item),
            },
            {
              header: 'Active',
              field: 'bol_active',
              render: item => renderActiveColumn(item),
            },
          ]}
          data={getCcDocumentData}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          rowDoubleClickHandler={getDocFromTable}
          key="demand-document-table"
        />
      </div>
    );
  };

  useEffect(() => {
    viewDocFileName =
      viewDocFileName === fileDesc ? viewDocFileName : undefined;
    if (retrieveDoc && retrieveDoc.fileData) {
      if (retrieveDoc.fileData !== 'Unable to open file!')
        debugBase64(retrieveDoc.fileData, fileName);
      viewDocFileName = fileDesc;
      updateRetriveDocument({})
    }
  }, [retrieveDoc?.description]);

  useEffect(() => {
    if(uploadedDocuments.length > 0 && isSedgewickReferral){
      let filesArr = uploadedDocuments.map(docItem => docItem.fileData);
      handleDrag(filesArr);
    }
    // viewDocFileName =
    //   viewDocFileName === fileDesc ? viewDocFileName : undefined;
    // if (retrieveDoc && retrieveDoc.fileData) {
    //   if (retrieveDoc.fileData !== 'Unable to open file!')
    //     debugBase64(retrieveDoc.fileData, fileName);
    //   viewDocFileName = fileDesc;
    //   updateRetriveDocument({})
    // }
  }, [uploadedDocuments]);

  const getAttachFilesFooter = () => {
    if (checkedVal === false) {
      if(tableName !== 'sedgwick_referral'){
        return <Button onClick={handleAttachFiles}>UPLOAD FILE(S)</Button>;
      }
    } else {
      return <Button onClick={stateDeleteModalToggle}>DELETE FILE(S)</Button>;
    }
  };

  const handleDleteFiles = async () => {
    await Promise.all([
      checkedItems.map(item =>
        deleteDocument({
          documentId: item.id,
        })
      ),
    ]).then(data => {
      if (checkedItems.length > 0) {
        let breadCrumb = {
          "functionName": 'logBreadCrumbs',
          "zebraUserId": currentUserId,
          "userid": currentUserId,
          "breadcrumbkey": 'DELETEATT',
          "refTableId": dataObject.int_reference_table_id,
          "refId": refId,
          "refTable": dataObject.chr_reference_table,
        };
        logBreadCrumbs(breadCrumb)
      }
      checkedItems = [];
      setTimeout(() => {
        setIsRefreshDocuments(true);
        setCheckedVal(false);
        stateDeleteModalToggle();
        getCCDocCall();
      }, 2000);
    });
  };

  const resetUploadFilesModal = () => {
    clearFilesToUpload();
  };

  const resetAttachFilesModal = () => { 
    if(tableName === 'sedgwick_referral'){
      clicked();
    }
  };

  return (
    <>
      <PopupModal
        content={getDemandModalBodyContent()}
        title="Status Added"
        isOpen={isDemandModalOpen}
        externalToggle={stateDemandModalToggle}
        footerContent={getDemandModalFooterContent()}
        size="lg"
      />
      <PopupModal
        content={getDeleteModalBodyContent()}
        title="Confirm Delete"
        isOpen={isDeleteModalOpen}
        externalToggle={stateDeleteModalToggle}
        footerContent={getDeleteModalFooterContent()}
        size="md"
      />
      <PopupModal
        content={getUploadFilesBody()}
        title="ADD A upload FILE"
        externalToggle={() => {
          toggleUploadFiles();
          toggleAttachFiles();
          checkedItems = [];
          setCheckedVal(false);
          uploadCheckedItems = [];
          setRemoveVal(false);
          // if (newRef) {
          //   onNewRefExit();
          //   updateSaveReferral({})
          // }
        }}
        isOpen={isOpenUploadFiles}
        footerContent={getUploadFilesFooter()}
        onClosed={resetUploadFilesModal}
        size="lg"
      />
      <PopupModal
        content={getAttachFilesBody()}
        title="ADD A FILE"
        externalToggle={() => {
          if(isSedgewickReferral){
            onNewRefExit(isSedgewickReferral);
            updateSaveReferral({});
            clicked(false);
          }
          if (newRef) {
            onNewRefExit();
            updateSaveReferral({})
            onExit();
          }
          toggleAttachFiles();
        }}
        isOpen={isOpenAttachFiles}
        footerContent={getAttachFilesFooter()}
        onClosed={resetAttachFilesModal}
        size="lg"
      />
    </>
  );
};



const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  return {
    currentUserId: userId,
    getCcDocumentData: state.FAST360.fast360Reducer.updateGetCcDocument ? state.FAST360.fast360Reducer.updateGetCcDocument : {},
    updatedFilesData: state.FAST360.fast360Reducer.updatedFilesData ? state.FAST360.fast360Reducer.updatedFilesData : [],
    retrieveDoc: state.FAST360.fast360Reducer.retrieveDocument,
    uploadedDocuments: state.FAST360.fast360Reducer.uploadedDocuments,

  };
};

const mapDispatchToProps = dispatch => ({
  getCcDocument: (data, handleModalChange, fromExternal) => dispatch(fast360Operations.getCcDocument(data, handleModalChange, fromExternal)),
  saveFileUpload: data => dispatch(fast360Operations.saveFileUpload(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  updatedFilesDataCall: data => dispatch(fast360Actions.updatedFilesData(data)),
  deleteDocument: data => dispatch(fast360Operations.deleteDocument(data)),
  retrieveReferralDocument: data => dispatch(fast360Operations.retriveDocument(data)),
  updateRetriveDocument: data => dispatch(fast360Actions.updateRetriveDocument(data)),
  updateSaveReferral: data => dispatch(fast360Actions.updateSaveReferral(data)),
  logBreadCrumbs: data =>
    dispatch(fast360Operations.logBreadCrumbs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360FileModal);
