import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label, Col, Row, Button } from 'reactstrap';
import Fast360FileModal from './Fast360FileModal';
import { fast360Selectors, fast360Operations, fast360Actions } from '../store/index';
import { authSelectors } from 'modules/auth/store';
import Select from 'react-select';
import Fast360TreeStructure from './Fast360TreeStructure';
import * as FetchUtils from 'shared/utils/fetchUtils';
import { shortToDatabaseTable } from '../utils/dataUtils'


class Fast360WorklistFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadClick: false,
            emailTemplates: [],
            sendTo: '',
            body: ''
        };
        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const { currentUserId, currentWorkList, user } = this.props;
        const refId = currentWorkList.FK_referral_id;
        const serviceId = currentWorkList.int_reference_table_id;
        const tableName = currentWorkList.chr_reference_table;
        
        if (tableName != "TRN" && tableName != "SPT" && tableName != "MOD" && tableName != "INT") {
            if (refId != null && refId != 0) {
                let templateLoad = {
                    "functionName" : "getWorklistEmailReportTemplates",
                    "zebraUserId": currentUserId,
                    "user_id": user.id,
                    "referralId" : refId,
                    "tableType" : tableName,
                    "serviceId" : serviceId
                }
    
                this.props.getEmailReportTemplates(templateLoad);
            }
        } else {
            // Build templates
            this.buildTemplates();
        }
    }

    buildTemplates() {
        const tableName = this.props.currentWorkList.chr_reference_table;;
        let tempReports = [];
        let counter = 0;
        if (tableName != "TRN" && tableName != "SPT" && tableName != "INT") {
            tempReports.push({"label" : "Completed (for internal use)", "value" : counter, "filename" : "lmsCompleted.pdf", "body" : ""});
            counter++;
            if (tableName === "HHC") {
                tempReports.push({"label" : "HHC Authorized (for vendor use)", "value" : counter, "filename" : "lmsHHCAuthorized.pdf", "body" : ""});
                counter++;
                tempReports.push({"label" : "HHC Cancelled (for vendor use)", "value" : counter, "filename" : "lmsHHCCancelled.pdf", "body" : ""});
                counter++;
            } else {
                tempReports.push({"label" : "Authorized (for vendor use)", "value" : counter, "filename" : "lmsAuthorized.pdf", "body" : ""});
                counter++;
                tempReports.push({"label" : "Cancelled (for vendor use)", "value" : counter, "filename" : "lmsCancelled.pdf", "body" : ""});
                counter++;
            }
            tempReports.push({"label" : "Quote (for customer use - MSRP)", "value" : counter, "filename" : "lmsQuoteMSRP.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteMSRP)});
            counter++;
            if (tableName != "MOD") {
                tempReports.push({"label" : "Quote (for customer use - FS/UC)", "value" : counter, "filename" : "lmsQuoteFS.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteFS)});
                counter++;
                tempReports.push({"label" : "Quote (for customer use - OWCP)", "value" : counter, "filename" : "lmsQuoteOWCP.pdf", "body" : (tableName === "ACC" ? "" : this.props.emailReportTemplates.QuoteOWCP)});
                counter++;
            }
        } else if (tableName === "INT") {
            tempReports.push({"label" : "Vendor Interpretation Report", "value" : counter, "filename" : "lmsVendorInterp.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Interpretation Report", "value" : counter, "filename" : "lmsInternalInterp.pdf", "body" : ""});
            counter++;
        } else if (tableName === "TRN") {
            tempReports.push({"label" : "Vendor Transport Report", "value" : counter, "filename" : "lmsVendorTransport.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Transport Report", "value" : counter, "filename" : "lmsInternalTransport.pdf", "body" : ""});
            counter++;
        } else if (tableName === "SPT") {
            tempReports.push({"label" : "Vendor Special Transport Report", "value" : counter, "filename" : "lmsVendorTransport.pdf", "body" : ""});
            counter++;
            tempReports.push({"label" : "Internal Special Transport Report", "value" : counter, "filename" : "lmsInternalTransport.pdf", "body" : ""});
            counter++;
        }
        this.setState( { emailTemplates: tempReports });
    }

    componentDidUpdate(prevProps) {
        if (this.props.emailReportTemplates !== prevProps.emailReportTemplates) {
            // Now that we got the templates build the dropdown
            this.buildTemplates();
        }
    }

    reportSelectOnChangeHandler = (event) => {
        let body = "";
        let selectedReportId = "";
        if(event.value !== null) {
            body =  this.state.emailTemplates[event.value].body;
            selectedReportId = event.value
        }
        this.setState({body: body, selectedReportId, selectedReportId}, () => {
            const params = new URLSearchParams();
            params.append('printType', "Preview");
    
            this.buildRequest(params); 
        })
    
    }

    
    buildRequest = (params) => {
        
        const refId = this.props.dataObject?.referralId;
        const serviceId = this.props.dataObject?.serviceId;
        const tableName = this.props.dataObject?.module;
        const selectedTemplate = this.state.emailTemplates[this.state.selectedReportId];

        params.append('reportName', selectedTemplate.filename);
        params.append('referralId', refId);
        params.append('serviceId', serviceId);
        params.append('tablename', shortToDatabaseTable(tableName));
        params.append('tableType', tableName);        
        //params.append('sendTo', this.state.sendTo);
        params.append('msgBody', this.state.body);
        params.append('authStart', "");
        params.append('authEnd', "");

        if (tableName === "HHC" && 
            (params.get("reportName") === "lmsHHCAuthorized.pdf" || 
             params.get("reportName") === "lmsHHCCancelled.pdf")) {
            this.setState({ showAuth: true });
            this.setState({ params: params });
        } else {
            if(serviceId == undefined || refId == undefined){
               alert("Report could not be generated. Please try running again from the Worklist")
            } else {
               this.sendRequest(params);
            }
        }
    }

    sendRequest = (params) => {        
        const actionUrl = new URL('/fast360/letters/createLetter.php', process.env.REACT_APP_SLP_BASE_URL);
        actionUrl.search = params.toString();

        FetchUtils.openWindowWithPost(
            actionUrl.toString(),
            {
                username: process.env.REACT_APP_SLP_USERNAME,
                password: process.env.REACT_APP_SLP_PASSWORD,
            },
            `CreateLetter${new Date().getTime()}`
        );
    }


    toggle = () => {
        this.props.clicked();
        // this.props.getCcDocument({});
        // this.props.setCurrentWorkList({});
    };

    handleClick = () => {
        this.setState({ uploadClick: !this.state.uploadClick });
    }

    render() {
        return (
            <>
                {this.state.uploadClick && <Fast360FileModal
                    clicked={this.handleClick}
                    isOpen={this.state.uploadClick}
                    dataObject={this.props.dataObject}
                    zebraId={this.props.currentUserId}
                    refId={this.props.dataObject.FK_referral_id}
                    tableName={this.props.dataObject.chr_reference_table}
                    serviceId={this.props.dataObject.int_reference_table_id}
                />}
                <Modal size="lg" isOpen={this.props.isOpen} style={{ height: '620px' }} scrollable={true}>
                    <ModalHeader toggle={this.toggle}>
                        FILES
                </ModalHeader>
                    <ModalBody>
                        <Row style={{ marginTop: '50px' }}>

                            <FormGroup style={{ width: '50%' }}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder="Add file to record"
                                    onChange={event => this.reportSelectOnChangeHandler(event)}
                                    name="templates"
                                    id="templates"
                                    options={this.state.emailTemplates}
                                />
                            </FormGroup>
                        </Row>
                        <Row style={{ marginTop: '40px', marginBottom: 40 }}>
                            <Button onClick={this.handleClick} style={{ width: '30%' }}>Upload Files</Button>
                        </Row>
                        <Fast360TreeStructure dataObject={this.props.dataObject} />
                    </ModalBody>
                </Modal >
            </>
        )
    }
}


const mapStateToProps = state => {
    const fast360Info = fast360Selectors.getFast360Info(state);
    const userId = authSelectors.getUserId(state);
    const user = authSelectors.getUser(state);
    return {
        currentWorkList: fast360Info.currentWorkListItem,
        currentUserId: userId,
        user: user,
        emailReportTemplates: state.FAST360.fast360Reducer.updateWorklistEmailReportTemplates ? state.FAST360.fast360Reducer.updateWorklistEmailReportTemplates : [],
    };
};

const mapDispatchToProps = dispatch => ({
    getCcDocument: (data) => dispatch(fast360Actions.updateGetCcDocument(data)),
    getEmailReportTemplates: (data) => dispatch(fast360Operations.getWorklistEmailReportTemplates(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360WorklistFiles);
