import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Form } from 'reactstrap';

import { commonOperations } from 'common/store';

import * as billOperationsConstants from '../store/constants';
import { billOperationsActions, billOperationsOperations, billOperationsSelectors } from '../store';

import AdvancedClaimSearch from './AdvancedClaimSearch';
import AdvancedBillSearch from './AdvancedBillSearch';

class FindBillsAdvancedSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdvancedClaimSearch: !this.props.fromManageBill ? true : false,
      showAdvancedBillSearch: false,
      hidePrintedBills: false,
      stateFromFindBill: this.props.changeSearchMode,
      isClear: false,
      searchClaimFields: {
        claimNumber: '',
        billScan: '',
        end: '',
        client: '',
        state: '',
        billTypes: '',
        externalId: '',
        billStatus: '',
        processor: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        siteId: '',
      },
      searchBillFields: {
        claimNumber: '',
        billScan: '',
        end: '',
        client: '',
        state: '',
        billTypes: '',
        externalId: '',
        billStatus: '',
        processor: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        siteId: '',
      }
    };
  }

  componentDidMount() {
    if (this.props.sendAdvanceData) {
      let searchFields = {
        userId: this.props.currentUserId,
        profile: this.props.profile,
      };
      let searchObj = {
        claimNumber: '',
        billScan: '',
        end: '',
        client: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        billtypes: '',
        externalId: '',
        state: this.props.sendAdvanceData.state_id,
        billStatus: this.props.sendAdvanceData.status_id,
        processor: this.props.sendAdvanceData.user_id,
        siteId: this.props.sendAdvanceData.site_id,
      }
      searchFields = {
        ...searchFields,
        claimNumber: '',
        billScan: '',
        end: '',
        client: this.props.sendAdvanceData.site_id,
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        billtypes: '',
        externalId: '',
        state: this.props.sendAdvanceData.state_id,
        billStatus: this.props.sendAdvanceData.status_id,
        processor: this.props.sendAdvanceData.user_id,
        siteId: this.props.sendAdvanceData.site_id,

      };
      this.setState({ searchBillFields: searchObj });
      if (this.props.sendAdvanceData.status_id === "")
        this.props.alert('You must enter a starting bill ID.')
      else
        this.props.findBills(searchFields);
    }
  }



  onChangeSearch = event => {
    const target = event.target;
    const targetId = target.id;

    switch (targetId) {
      case billOperationsConstants.SHOW_ADVANCED_CLAIM_SEARCH:
        this.setState({
          stateFromFindBill: 'false',
          showAdvancedClaimSearch: true,
          showAdvancedBillSearch: false

        });
        this.props.setIsFromManageBill(false);
        break;
      case billOperationsConstants.SHOW_ADVANCED_BILL_SEARCH:
        this.setState({
          stateFromFindBill: 'true',
          showAdvancedClaimSearch: false,
          showAdvancedBillSearch: true,
        });
        break;
      default:
    }
  };

  onChangeBillHandler = (field, value) => {
    value = value === 'default' ? '' : value;
    if(field === "hidePrintedBills") {
      this.setState({hidePrintedBills: !this.state.hidePrintedBills});
    }
    this.setState({
      searchBillFields: {
        ...this.state.searchBillFields,
        [field]: value,
      },
      isClear: false
    }, () => { console.log('onChangeBillHandler', this.state) });
  };

  onChangeClaimHandler = (field, value) => {
    value = value === 'default' ? '' : value;
    if (field === "client") {
      this.setState({
        searchClaimFields: {
          ...this.state.searchClaimFields,
          siteId: value,
        },
        isClear: false
      });
    } else {
      this.setState({
        searchClaimFields: {
          ...this.state.searchClaimFields,
          [field]: value,
        },
        isClear: false
      });
    }    
  };

  // componentWillReceiveProps(nextProps) {
  //   if (this.props != nextProps) {

  //   }
  // }

  onFormSubmit = e => {
    e.preventDefault();
    const searchType = this.state.showAdvancedClaimSearch ? 'CLAIM' : 'BILL';
    this.props.changeSearchType(searchType);
    let stateAbb = this.props.initialStateData.filter(t => t.abbreviation == this.state.searchBillFields.state);
    this.state.searchBillFields.state = stateAbb.length && stateAbb[0].abbreviation;
    let searchFields = {
      userId: this.props.currentUserId,
      profile: this.props.profile,
    };
    //let client = this.state.searchBillFields.siteId;
    switch (searchType) {
      case 'CLAIM':
        searchFields = {
          ...searchFields,
          ...this.state.searchClaimFields,
        };
        let objClaim = this.state.searchClaimFields;
        let result = Object.values(objClaim).every(value => value === "");
        if (Object.keys(objClaim).length === 0 || result) {
          this.props.alert('You cannot search without entering at least one search parameter.');
          return;
      }
        this.props.findClaims(searchFields);
        break;
      case 'BILL':
        searchFields = {
          ...searchFields,
          ...this.state.searchBillFields
        };
        //let billSearchFields = { ...searchFields, client }
        this.props.findBills(searchFields);
        break;
      default:
    }

    this.props.updateSearchFields(searchFields);
    this.props.setSearchCriteria(searchFields);
  };

  onClearAll = () => {
    let cliamSObj = {
    claimNumber: '',
    billScan: '',
    end: '',
    client: '',
    state: '',
    billTypes: '',
    externalId: '',
    billStatus: '',
    processor: '',
    entryDate: '',
    tin: '',
    lastNameOrSsn: '',
    firstName: '',
    siteId: '',
  };
  let billsObj = {
    claimNumber: '',
        billScan: '',
        end: '',
        client: '',
        state: '',
        billTypes: '',
        externalId: '',
        billStatus: '',
        processor: '',
        entryDate: '',
        tin: '',
        lastNameOrSsn: '',
        firstName: '',
        siteId: '',
  }
  this.setState({searchClaimFields: cliamSObj, searchBillFields: billsObj, isClear: true, hidePrintedBills: false});
  this.props.setSearchCriteria(null);
 }

  render() {
    return (
      <div id="findBillsAdvancedSearchContainer">
        <Form onSubmit={this.onFormSubmit}>
          <Row form>
            <Col lg="1">
              <Button
                id="showAdvancedClaimSearch"
                style={{
                  fontWeight: this.state.showAdvancedClaimSearch && this.state.stateFromFindBill != 'showAdvancedBillSearch'
                    ? 'bold'
                    : 'normal',
                }}
                color="link"
                onClick={this.onChangeSearch}
              >
                Claim
              </Button>{' '}
              |{' '}
              <Button
                id="showAdvancedBillSearch"
                style={{
                  fontWeight: this.state.showAdvancedBillSearch || this.state.stateFromFindBill == 'showAdvancedBillSearch'
                    ? 'bold'
                    : 'normal',
                }}
                color="link"
                onClick={this.onChangeSearch}
              >
                Bill
              </Button>
            </Col>
            <Col>
              {this.state.showAdvancedClaimSearch && this.state.stateFromFindBill != 'showAdvancedBillSearch' ? (
                <AdvancedClaimSearch
                  onChangeHandler={this.onChangeClaimHandler}
                  isClear={this.state.isClear}
                  searchFields = {this.state.searchClaimFields}
                />
              ) : null}
              {this.state.showAdvancedBillSearch ? (
                <AdvancedBillSearch
                  onChangeHandler={this.onChangeBillHandler}
                  hidePrintedBills={this.state.hidePrintedBills}
                  searchFields={this.state.searchBillFields}
                  isClear={this.state.isClear}
                />
              ) : null}
              {this.state.stateFromFindBill == 'showAdvancedBillSearch' ? (
                <AdvancedBillSearch
                  onChangeHandler={this.onChangeBillHandler}
                  hidePrintedBills={this.state.hidePrintedBills}
                  stateAdv={this.props.sendAdvanceData.state_id}
                  siteAdv={this.props.sendAdvanceData.site_id}
                  externalIdAdv={this.props.sendAdvanceData.external_id}
                  statusAdv={this.props.sendAdvanceData.status_id}
                  userAdv={this.props.sendAdvanceData.user_id}
                  searchFields={this.state.searchBillFields}
                  isClear={this.state.isClear}
                />
              ) : null}
              {/* {this.state.showAdvancedBillSearch && this.props.fromManageBill == false ? (
                <AdvancedBillSearch
                  onChangeHandler={this.onChangeBillHandler}
                  hidePrintedBills={this.state.hidePrintedBills}


                />
              ) : null} */}
            </Col>
            <Col lg="3">
              <Button type="submit">FIND</Button>{' '}
              <Button
                id="clearAllbtn"
                onClick={this.onClearAll}
              >
                CLEAR ALL
              </Button>{' '}
              <Button
                id="closeAdvancedSearch"
                onClick={this.props.changeSearchHandler}
              >
                CLOSE ADVANCED
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

FindBillsAdvancedSearch.propTypes = {
  changeSearchHandler: PropTypes.func.isRequired,
  changeSearchType: PropTypes.func.isRequired,
  findBills: PropTypes.func.isRequired,
  findClaims: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  changeSearchMode: PropTypes.string,
  sendAdvanceData: PropTypes.array,
  fromManageBill: PropTypes.bool,
  setSearchCriteria: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialStateData = billOperationsSelectors.getInitialData(state).states;
  return {
    initialStateData: initialStateData,
  };
};

const mapDispatchToProps = dispatch => ({
  updateSearchFields: data =>
    dispatch(billOperationsActions.updateSearchFields(data)),
  findBills: data => dispatch(billOperationsOperations.findBills(data)),
  findClaims: data => dispatch(billOperationsOperations.findClaims(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindBillsAdvancedSearch);
