import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import { expertsOperations, expertsSelectors, expertsActions } from '../store';

import NewExpertSimpleModal from './NewExpertSimpleModal';
import NewExpertAdvanceModal from './NewExpertAdvanceModal';

class NewExpertContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleModalOpen: false,
      advanceModalOpen: false,
      simpleModalFirstName: '',
      simpleModalLastName: '',
      advanceModalFirstName: '',
      advanceModalLastName: '',
    };
  }

  stateSimpleModalToggle = () => {
    this.setState(prevState => ({
      simpleModalOpen: !prevState.simpleModalOpen,
    }));
  };

  stateAdvanceModalToggle = () => {
    this.setState(prevState => ({
      advanceModalOpen: !prevState.advanceModalOpen,
    }));
  };

  onInputChangeHandler = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  resetSimpleModal = () => {
    this.setState({
      simpleModalFirstName: '',
      simpleModalLastName: '',
    });
  };

  resetAdvanceModal = () => {
    this.setState({
      advanceModalFirstName: '',
      advanceModalLastName: '',
    });
    this.props.resetView();
  };

  onSimpleModalNextClickHandler = () => {
    this.props.checkForDuplicateExpert({
      firstName: this.state.simpleModalFirstName,
      lastName: this.state.simpleModalLastName,
    });
    this.setState(prevState => ({
      advanceModalFirstName: prevState.simpleModalFirstName,
      advanceModalLastName: prevState.simpleModalLastName,
    }));
    this.stateSimpleModalToggle();
    this.stateAdvanceModalToggle();
  };

  onSimpleModalInputKeyPressHandler = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.onSimpleModalNextClickHandler();
  };

  onExpertClickHandler = item => {
    this.props.loadExpertAddresses({
      expertId: item.id,
    });
  };

  onCreateNewExpertClickHandler = () => {
    this.props.saveNewExpert({
      firstName: this.state.advanceModalFirstName,
      lastName: this.state.advanceModalLastName,
      currentUserId: this.props.currentUserId,
    });
    this.stateAdvanceModalToggle();
  };

  render() {
    return (
      <>
        <NewExpertSimpleModal
          modalOpen={this.state.simpleModalOpen}
          firstName={this.state.simpleModalFirstName}
          lastName={this.state.simpleModalLastName}
          modalToggle={this.stateSimpleModalToggle}
          resetModal={this.resetSimpleModal}
          onNextClickHandler={this.onSimpleModalNextClickHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          onInputKeyPressHandler={this.onSimpleModalInputKeyPressHandler}
        />
        <NewExpertAdvanceModal
          modalOpen={this.state.advanceModalOpen}
          firstName={this.state.advanceModalFirstName}
          lastName={this.state.advanceModalLastName}
          modalToggle={this.stateAdvanceModalToggle}
          resetModal={this.resetAdvanceModal}
          onExpertClickHandler={this.onExpertClickHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          duplicateExperts={this.props.duplicateExperts}
          selectedExpertAddresses={this.props.selectedExpertAddresses}
          onCreateNewExpertClickHandler={this.onCreateNewExpertClickHandler}
        />
        <Button
          style={{ width: '100%' }}
          type="button"
          onClick={this.stateSimpleModalToggle}
        >
          New Expert
        </Button>
      </>
    );
  }
}

NewExpertContainer.propTypes = {
  duplicateExperts: PropTypes.array.isRequired,
  selectedExpertAddresses: PropTypes.array.isRequired,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  checkForDuplicateExpert: PropTypes.func.isRequired,
  resetView: PropTypes.func.isRequired,
  loadExpertAddresses: PropTypes.func.isRequired,
  saveNewExpert: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const newExpertGroupState = expertsSelectors.getNewExpert(state);
  return {
    duplicateExperts: newExpertGroupState.duplicateExperts,
    selectedExpertAddresses: newExpertGroupState.selectedExpertAddresses,
  };
};

const mapDispatchToProps = dispatch => ({
  checkForDuplicateExpert: data =>
    dispatch(expertsOperations.checkForDuplicateExpert(data)),
  resetView: () => dispatch(expertsActions.resetNewExpertView()),
  loadExpertAddresses: data =>
    dispatch(expertsOperations.loadExpertAddresses(data)),
  saveNewExpert: data => dispatch(expertsOperations.saveNewExpert(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewExpertContainer);
