import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  Col,
  Row,
  FormGroup,
  CustomInput,
  Button,
  Input,
  Label,
} from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppTable from 'shared/components/table/AppTable';
import PopupModal from 'shared/components/PopupModal';
import { connect } from 'react-redux';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { authSelectors } from 'modules/auth/store';
import * as DateTimeUtils from 'shared/utils/dateTimeUtils';
import Select, { createFilter } from 'react-select';
import { assign } from 'lodash';
const StatusDocumentsModal = ({
  isOpen,
  onClose,
  userId,
  profile,
  statusHistoryData,
  sendStatusListDropdownData,
  selectedRowObj,
  sendUnPendData,
  claim,
}) => {
  const [searchFields, setSearchFields] = useState({});
  let checkedItems = [];
  const claimObject = claim || {};
  const [isShowCurrentStatus, setIsShowCurrentStatus] = useState(false);

  const toggle = () => {
    setSearchFields({});
    onClose();
  };

  const onChangeHandler = (targetId, value) => {
    if (targetId !== null) {
      let name = 'statusId';
      setSearchFields({ ...searchFields, [name]: targetId.id });
    } else {
      let name = 'statusId';
      setSearchFields({ ...searchFields, [name]: '' });
    }
  };

  const filterConfig = {
    matchFrom: 'start'
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked
      ? checkedItems.concat(item)
      : checkedItems.filter(row => row.status !== item.status);
  };

  const showCurrentStatusHandler = event => {
    setIsShowCurrentStatus(event.target.checked);
  };

  const onStatusSubmit = () => {
    const params = {
      ...searchFields,
      userId,
      billId: selectedRowObj.int_billid || selectedRowObj.id,
      profile,
    };
    sendStatusListDropdownData(params);
    onClose();
  };

  const onUnPendedHandler = () => {
    checkedItems = checkedItems.map(item => item.status);

    const params = {
      checkedItems,
      userId,
      billId: selectedRowObj.int_billid || selectedRowObj.id,
      profile,
    };
    sendUnPendData(params);
    onClose();
  };

  const getSelectActionColumn = item => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.status}
          name={item.status}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />
      </div>
    );
  };

  const getUploadModalContent = () => {
    return (
      <>
        <Row style={{ paddingBottom: '10px' }}>
          <Col lg="3">
            <span>
              Bill ID: {selectedRowObj.int_billid || selectedRowObj.id}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppTable
              modalHeight={500}
              resizable
              columns={[
                {
                  header: 'Flag Name',
                  field: 'status',
                },
                {
                  header: 'Unpend',
                  field: '',
                  render: item => getSelectActionColumn(item),
                },
              ]}
              data={
                statusHistoryData.length ? statusHistoryData[2].userFlags : []
              }
              autoPagination={true}
            />
          </Col>
        </Row>
        <Row className="float-right">
          <Col>
            <Button type="submit" onClick={onUnPendedHandler}>
              APPLY
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const getFilteredData = () => {
    if (isShowCurrentStatus) {
      const filteredData = statusHistoryData[0].statushistory.filter(
        item => item.isCurrent.toLowerCase() === 'true'
      );
      return filteredData;
    } else {
      return statusHistoryData[0].statushistory;
    }
  };

  const getDaysFromStatusDate = item => {
    const statusDays = new Date(item.statusdate2);
    let printed = new Date();
    const turnAroundTime = DateTimeUtils.elapsedDays(printed, statusDays);
    let daysdiff = '';

    if (!isNaN(turnAroundTime)) {
      if (turnAroundTime === 0) {
        daysdiff = 'Today';
      } else {
        if (turnAroundTime === 1) {
          daysdiff = 'yesterday';
        } else {
          daysdiff = turnAroundTime + ' days ago';
        }
      }
    }
    return (
      <>
        {item.statusdate}
        <br />
        <span className="grey-text italic-text">{daysdiff}</span>
      </>
    );
  };
  const printStatusHistory = () =>
  {
    var pr = document.getElementsByTagName("table");
    var billinfo = document.getElementsByClassName("Billdetails");
    var out = "Bill History Status for "+ billinfo[0].innerHTML+"  "+billinfo[1].innerHTML;
    var style = "<style>";
                style = style + "table {width: 100%;}";
                style = style + "table,th,td {border: solid 1px #DDD; border-collapse: collapse;";
                style = style + "padding: 2px 3px;text-align: center;}";
                style = style + " thead {transform: none !important}";
                style = style + "</style>";
    pr = pr[pr.length-1].outerHTML
    const  newWin = window.open("");
    newWin.document.write(style);
    newWin.document.write(out)
    newWin.document.write("<P></P>")
    newWin.document.write(pr);
    newWin.print();
    newWin.close(); 
  }
  const getModalContent = () => {
    return (
      <>
        <Row style={{ paddingBottom: '10px' }} >
          <Col lg="3">
            <span className ="Billdetails">
              Bill ID: {selectedRowObj.int_billid || selectedRowObj.id}
            </span>
          </Col>
          <Col lg="3" className ="Billdetails" >
            <span>
              Claim# {claimObject.chr_claimnumber || selectedRowObj.claimNumber}
            </span>
          </Col>
          <Col lg={{ size: '3', offset: '3' }}>
            <span className="float-right">
              <PopupModal
                content={getUploadModalContent()}
                title="USER FLAG MAINTENANCE..."
                text="UNPEND USER FLAGS"
                size="lg"
                isButton={true}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col lg="11">
            <FormGroup>
              {/* <AppInput
                changeHandler={onChangeHandler}
                option={{
                  value: 'id',
                  displayValue: 'desc',
                }}
                placeholder="Choose a status to add"
                data={
                  statusHistoryData.length
                    ? statusHistoryData[1].statuslist
                    : []
                }
                id="statusHistoryId"
              /> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                onChange={onChangeHandler}
                //defaultValue={addManageBillsBillStatus.filter(option => option.id == searchFields.billStatus)}
                name="statusHistoryId"
                id="statusHistoryId"
                filterOption={createFilter(filterConfig)}
                options={
                  statusHistoryData.length
                    ? statusHistoryData[1].statuslist.map(obj =>
                        assign(obj, { label: obj.desc, value: obj.id })
                      )
                    : []
                }
              />
            </FormGroup>
          </Col>
          <Col lg="1">
            <Button
              className="show-dropdown-button"
              color="primary"
              onClick={onStatusSubmit}
            >
              {'+'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppTable 
              modalHeight={350}
              resizable
              columns={[
                {
                  header: 'Status',
                  field: 'status',
                },
                {
                  header: 'Status Date',
                  field: 'statusdate',
                  render: item => getDaysFromStatusDate(item),
                },
                {
                  header: 'State',
                  field: 'state',
                },
                {
                  header: 'User',
                  field: 'username',
                },
              ]}
              data={statusHistoryData.length ? getFilteredData() : []}
              autoPagination={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup check className="float-right">
              <Label check>
                <Input
                  id="showCurrentStatus"
                  type="checkbox"
                  onChange={showCurrentStatusHandler}
                />{' '}
                Show Current Status
              </Label>
            </FormGroup>
          </Col>
          <Col>
          <Button onClick = {printStatusHistory}>Print</Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>BILL STATUS HISTORY</ModalHeader>
        <ModalBody>{getModalContent()}</ModalBody>
      </Modal>
    </>
  );
};

StatusDocumentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statusHistoryData: PropTypes.array,
  sendStatusListDropdownData: PropTypes.func,
  resetStatusListData: PropTypes.func,
  statusListData: PropTypes.array,
  unpendedData: PropTypes.array,
  sendUnPendData: PropTypes.func,
  resetUnPendData: PropTypes.func,
  alert: PropTypes.func,
  userId: PropTypes.string,
  profile: PropTypes.string,
  selectedRowObj: PropTypes.object,
  claim: PropTypes.object,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  return {
    userId: user && user.user_id,
    profile: initialData.currentProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  sendStatusListDropdownData: data =>
    dispatch(billOperationsOperations.sendStatusListDropdownData(data)),
  resetStatusListData: () =>
    dispatch(billOperationsActions.resetStatusListData()),
  sendUnPendData: data =>
    dispatch(billOperationsOperations.sendUnPendData(data)),
  resetUnPendData: () => dispatch(billOperationsActions.resetUnPendData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusDocumentsModal);
