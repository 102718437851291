export const EXPERT_INFO_LOAD_INITIAL_DATA = 'EXPERT_INFO_LOAD_INITIAL_DATA';
export const EXPERT_INFO_UPDATE_GROUP_DATA = 'EXPERT_INFO_UPDATE_GROUP_DATA';
export const EXPERT_INFO_UPDATE_EXPERT_DATA = 'EXPERT_INFO_UPDATE_EXPERT_DATA';
export const EXPERT_INFO_UPDATE_GROUP_INPUT = 'EXPERT_INFO_UPDATE_GROUP_INPUT';
export const EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR =
  'EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR';
export const EXPERT_INFO_UPDATE_EXPERT_INPUT =
  'EXPERT_INFO_UPDATE_EXPERT_INPUT';
export const EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR =
  'EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR';

export default {
  EXPERT_INFO_UPDATE_GROUP_INPUT,
  EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR,
  EXPERT_INFO_UPDATE_EXPERT_INPUT,
  EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR,
  EXPERT_INFO_UPDATE_GROUP_DATA,
  EXPERT_INFO_UPDATE_EXPERT_DATA,
  EXPERT_INFO_LOAD_INITIAL_DATA,
};
