import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import HeaderMenu from '../components/menus/HeaderMenu';

import User from './User';

class Header extends Component {
  render() {
    return (
      <Row>
        <Col className="header-section">
          <Row>
            {this.props.header !== "Fast 360" && <><Col lg="auto">
              <span className="header-name">{this.props.header}</span>
            </Col>
              <Col lg="auto" className="header-menu">
                <HeaderMenu module={this.props.header} />
              </Col>
            </>
            }
            <Col className="header-user">
              <User floating="float-right" />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

Header.propTypes = {
  header: PropTypes.string.isRequired,
};

export default Header;
