import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { Col, Row, Form, Button, CustomInput } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import AppTable from 'shared/components/table/AppTable';
import { formatCurrency } from 'modules/bill-operations/utils/billUtils';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { fast360Operations } from '../store';
import { authSelectors } from 'modules/auth/store';

import "react-datepicker/dist/react-datepicker.css";
let currPageBillsSelected = [];
class Fast360APApproval extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,            
            selectedRows: [],
            selectedDollars: 0.00,
            processedRows: 0,
            processedAmount: 0.00
            

        }
        this.toggle = this.toggle.bind(this);

    }
    toggle = () => {
        this.props.openFast360APApproval()
    };
  
    saveAPApproval = () =>{
      const { currentUserId } = this.props;
      let saveAPApprovalArgs = {
        "functionName": "updateAccountspayableapproval",
        "zebraUserId": currentUserId,
        "sendFlag": "1",
        "selectedBills": this.state.selectedRows,
        "lmsUserId": currentUserId
      }
      
      this.props.saveAPApproval(saveAPApprovalArgs);
      alert('You have successfully processed ' + this.state.selectedRows.length  + ' AP records for ' +  formatCurrency( this.state.selectedDollars ? this.state.selectedDollars : 0.00) )
      this.toggle();
      
    };

    componentDidMount() {
        const { currentUserId } = this.props;
        //const user = this.props.users && this.props.users.find(u => u.zebraId == currentUserId);

        let payload = {
            "functionName": "apExtract",
            "zebraUserId": currentUserId,
            "sendFlag": false
            //"user_id": user.id
        }
        this.props.getAPExtract(payload);
    }
    selectItem = (item, event) => {
      const result = event.target.checked
          ? this.state.selectedRows.concat(item)
          : this.state.selectedRows.filter(
            row => row.payableId !== item.payableId
          );
      const dollarsout = result.reduce((a,v) =>  a = a + Number(v.serviceAmt ? v.serviceAmt : 0) , 0 );
      this.setState({
        selectedRows: result,
        selectedDollars: dollarsout,
      });
     
    };

    isChecked = () => {

      if (this.state.selectedRows.length == this.props.apData.length) {
        return true;
      }
      
      return false;
    };
   selectAll = event => {
      if (this.state.selectedRows.length === this.props.apData.length) {
        this.setState({
          selectedRows: [],
        });
        
        this.setState({
          selectedDollars: 0.00,
        });
      }else{
        this.setState({
          selectedRows: this.props.apData,
        });
        const dollarsout = this.props.apData.reduce((a,v) =>  a = a + Number(v.serviceAmt ? v.serviceAmt : 0) , 0 );
        this.setState({
          selectedDollars: dollarsout,
        });
      }
      
    };
    
    renderSelectActionHeader() {
      return (
        <div className="select-action">
          <CustomInput
            id={`billSearchResult-selectAll`}
            name={`billSearchResult-selectAll`}
            type="checkbox"
            checked={this.isChecked() == true ? true : false}
            onChange={this.selectAll}
          />
        </div>
      );
    }
   getSelectActionColumn = (item) => {
      if (!!!item) {
        return;
      }
      return (
        <div className="select-action">
          <CustomInput
            id={`billSearchResult-select-${item.payableId}`}
            name={`billSearchResult-select-${item.payableId}`}
            type="checkbox"
            checked={this.state.selectedRows.includes(item)}
            onChange={event => {
              this.selectItem(item, event);
            }}
          />
        </div>
      );
    }
  
  // renderReferenceNumberColumn = (item) => {
  //   if (item.invoiceNum) {
  //     const removeSpl = item.invoiceNum.replaceAll("-", "")
  //     return removeSpl;
  //   }
  // }

  renderServiceAmtColumn = (item) => {
    if (item.serviceAmt) {
      const amtRounding = Number(item.serviceAmt).toFixed(2);
      return amtRounding;
    }
  }
  
    
render() {
    return (
        <>
            <Modal size="xl" isOpen={true} modalClassName="fee-lookup-modal-body">
                <ModalHeader onClick={this.toggle} >AP Approval</ModalHeader>
                <ModalBody>
                  <Form>
                    <Row>
                    <Col xl="12">
                      <AppTable modalHeight={400}  style={{ width: '2000px' }}
                      size="xl"
                        columns={[
                          {
                            header: 'Cost Center',
                            field: 'costCenter',
                            percent: '8',
                          
                          },
                          {
                            header: 'Company Code',
                            field: 'companyCode',
                            percent: '7',
                          },
                          {
                            header: 'Physical Location',
                            field: 'physicalLocation',
                            percent: '15'
                          },
                          {
                            header: 'Profit Center',
                            field: 'profitCenter',
                            percent: '10'
                          },
                          {
                            header: 'Expense Type',
                            field: 'expenseType',
                            percent: '10'
                          },
                          {
                            header: 'Material ID',
                            field: 'materialId',
                            percent: '8'
                          },
                         
                          {
                            header: 'Payment Reference',
                            field: 'paymentReference',
                            percent: '35'
                          },
                          
                          {
                            header: 'SAP Vendor',
                            field: 'sapVendorId',
                            percent: '15'
                          },
                          {
                            header: 'Vendor Inv #',
                            field: 'invoiceNum',
                            percent: '20'
                          },
                          {
                            header: 'Document Date',
                            field: 'invoiceDate',
                            percent: '10'
                          },
                          {
                            header: 'Service Amt',
                            field: 'serviceAmt',
                            render: item => this.renderServiceAmtColumn(item),
                            percent: '10'
                          },
                 
                          {
                            header: 'Payment Method',
                            field: 'paymentMethod',
                            percent: '5'
                          },
                          {
                            renderHeader: () => this.renderSelectActionHeader(),
                            field: 'checkBox',
                            render: item => this.getSelectActionColumn(item),
                            percent: '3',
                            sortAble: false,
                          },
                        
                        ]}
                        data={this.props.apData}
                        resizable={true}
                        />
                    </Col >
                   

                    </Row>
                     <Row>
                        <Col lg="3" style={{ paddingTop: '2px' }}>
                          <span>{this.props.apData.length} Payable Items</span>
                        </Col>
                     </Row>
                     <Row>
                        <Col lg="3" style={{ paddingTop: '2px' }}>
                           <span> Total Selected Bills: {this.state.selectedRows.length}</span>
                        </Col>
                     </Row>
                     <Row>
                        <Col lg="3" style={{ paddingTop: '2px' }}>
                          <span>
                            Total Selected Charges:{' '}
                            {formatCurrency( this.state.selectedDollars ? this.state.selectedDollars : 0.00 )
                            }
                          </span>
                          </Col>
                     </Row>
                     
                    <Row> 
                     <Col lg="10" className="text-right" style={{ paddingTop: '8px' }}>  </Col>
                      <Col sm="1"  style={{ paddingTop: '8px' }}>
                        <Button  style={{ paddingTop: '8px' }}  onClick={this.toggle}>Close</Button>
                      </Col>
                      <Col sm="1"  style={{ paddingTop: '8px' }}>
                        <Button  style={{ paddingTop: '8px' }}  onClick={this.saveAPApproval}>Approve</Button>
                      </Col>
                     </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
  }
};

Fast360APApproval.propTypes = {
    saveAPApproval: PropTypes.func,
    alert: PropTypes.func,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    userId: PropTypes.string,
    zebraUserId: PropTypes.string
};

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  
    return {
      currentUserId: userId,
      apData: state.FAST360.fast360Reducer.updateGetAPExtract ? state.FAST360.fast360Reducer.updateGetAPExtract : [],
    };

};


const mapDispatchToProps = dispatch => ({
    saveAPApproval: (data) => dispatch(fast360Operations.saveAPApproval(data)),
    getAPExtract: (data) => dispatch(fast360Operations.getAPExtract(data)),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Fast360APApproval);

