import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';

import * as billOperationsConstants from '../store/constants';
import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

const TopNav = ({
  currentView,
  onClickTaskList,
  onClickFindBills,
  openPpoLink,
}) => {
  return (
    <div style={{ paddingBottom: '10px' }}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: currentView === billOperationsConstants.TASK_LIST,
            })}
            onClick={() => {
              onClickTaskList();
            }}
          >
            TASK LIST
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: currentView === billOperationsConstants.FIND_PPO,
            })}
            onClick={() => {
              openPpoLink();
            }}
          >
            FIND PPOs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: currentView === billOperationsConstants.FIND_BILLS,
            })}
            onClick={() => {
              onClickFindBills();
            }}
          >
            FIND BILLS
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

TopNav.propTypes = {
  currentView: PropTypes.string.isRequired,
  onClickTaskList: PropTypes.func.isRequired,
  onClickFindBills: PropTypes.func.isRequired,
  openPpoLink: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const billReview = billOperationsSelectors.getBillReview(state);
  return {
    currentView: billReview.currentView,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickTaskList: () =>
    dispatch(
      billOperationsActions.switchView(billOperationsConstants.TASK_LIST)
    ),
  onClickFindBills: () =>
    dispatch(
      billOperationsActions.switchView(billOperationsConstants.FIND_BILLS)
    ),
  openPpoLink: () =>
    dispatch(
      billOperationsOperations.openPpoLink({ profile: 'S4', userId: '2591' })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
