import React, { Component } from 'react';
import { Table } from 'reactstrap';
import UserAccessTableHeader from './UserAccessTableHeader';
import UserAccessTableBody from './UserAccessTableBody';
import { AppTablePropType } from './AppTablePropTypes';

class UserAccessTable extends Component {
  render() {
    return (
      <Table size={this.props.size} striped={this.props.striped}>
        {this.props.caption && <caption>{this.props.caption}</caption>}
        <UserAccessTableHeader columns={this.props.columns} />
        <UserAccessTableBody
          columns={this.props.columns}
          data={this.props.data}
          updateDataTable={this.props.updateDataTable}
          showAccessCheckboxes={this.props.showAccessCheckboxes}
          activeAccessPanel={this.props.activeAccessPanel}
        />
      </Table>
    );
  }
}

UserAccessTable.propTypes = AppTablePropType;

UserAccessTable.defaultProps = {
  size: 'sm',
  dark: true,
  striped: true,
};

export default UserAccessTable;
