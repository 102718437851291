import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import { billMaintenanceSelectors, billMaintenanceActions } from '../store';
import {
  CLIENT_MAINTENANCE,
  GROUP_MAINTENANCE,
  USER_FLAGS,
  CASH_RECEIPTS,
  EDI_MANAGER,
} from '../utils/constantUtils';

const BillingMaintenanceSelect = props => {
  const updateCurrentView = event => {
    props.updateCurrentView(event.target.value);
  };

  return (
    <div>
      <Input
        type="select"
        name="maintenanceSelect"
        id="maintenanceSelect"
        disabled={props.isLoading}
        value={props.currentView}
        onChange={updateCurrentView}
      >
        <option value={CLIENT_MAINTENANCE}>Client Maintenance</option>
        <option value={GROUP_MAINTENANCE}>Group Maintenance</option>
        {/* <option value={USER_FLAGS}>User Flags</option>
        <option value={CASH_RECEIPTS}>Cash Receipts</option>
        <option value={EDI_MANAGER}>EDI Manager</option> */}
      </Input>
    </div>
  );
};

BillingMaintenanceSelect.propTypes = {
  currentView: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateCurrentView: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const initialData = billMaintenanceSelectors.getInitialData(state);
  return {
    currentView: initialData.currentView,
  };
};

const mapDispatchToProps = dispatch => ({
  updateCurrentView: currentView =>
    dispatch(billMaintenanceActions.updateCurrentView(currentView)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingMaintenanceSelect);
