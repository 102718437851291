import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, FormGroup, Input } from 'reactstrap';

const RecordCommentTab = ({ data, onInputChange }) => (
  <Row>
    <Col lg="12">
      <FormGroup>
        <Input
          type="textarea"
          name="comments"
          id="comments"
          value={data.comments}
          onChange={onInputChange}
          spellCheck={true}
          rows={20}
        />
      </FormGroup>
    </Col>
  </Row>
);

RecordCommentTab.propTypes = {
  data: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default RecordCommentTab;
