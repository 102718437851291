import { combineReducers } from 'redux';
import {
  EXPERT_INFO_UPDATE_GROUP_INPUT,
  EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR,
  EXPERT_INFO_UPDATE_EXPERT_INPUT,
  EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR,
  EXPERT_INFO_UPDATE_GROUP_DATA,
  EXPERT_INFO_UPDATE_EXPERT_DATA,
  EXPERT_INFO_LOAD_INITIAL_DATA,
} from './constants';

const initialDefaultState = {
  expertGroupTypes: [],
  expertDocumentTypes: [],
};

const initialReducer = (state = initialDefaultState, action) => {
  switch (action.type) {
    case EXPERT_INFO_LOAD_INITIAL_DATA: {
      return {
        ...state,
        expertGroupTypes: action.payload.expertGroupTypes,
        expertDocumentTypes: action.payload.expertDocumentTypes,
      };
    }
    default:
      return state;
  }
};

const groupDefaultState = {
  data: {
    id: 0,
    groupName: '',
    groupType: -1,
    tinNumber: '',
    payableTo: '',
    npi: '',
    previousJericho: '',
    website: '',
    underInvestigation: false,
    reason: '',
    expertStatus: '',
    referredBy: '',
    expertStatusStep: -1,
    recommendedOn: '',
    startDate: '',
    lastCredentialedOn: '',
    recruitmentMethod: '',
    referralCode: -1,
    terminationReason: '',
    terminationDate: '',
    notificationMethod: '',
    callForAssignment: false,
    onlineTaskList: false,
    comments: '',
  },
  errors: {
    groupName: '',
    tinNumber: '',
  },
  expertStatuses: [],
  expertStatusProspect: [],
  expertStatusApplicant: [],
  recruitmentMethods: [],
  referralCodes: [],
  termReason: [],
  notificationTypes: [],
};

const groupReducer = (state = groupDefaultState, action) => {
  switch (action.type) {
    case EXPERT_INFO_UPDATE_GROUP_INPUT: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case EXPERT_INFO_UPDATE_GROUP_INPUT_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    }
    case EXPERT_INFO_UPDATE_GROUP_DATA: {
      return {
        ...state,
        data: action.payload.data,
        expertStatuses: action.payload.expertStatuses,
        expertStatusProspect: action.payload.expertStatusProspect,
        expertStatusApplicant: action.payload.expertStatusApplicant,
        recruitmentMethods: action.payload.recruitmentMethods,
        referralCodes: action.payload.referralCodes,
        termReason: action.payload.termReason,
        notificationTypes: action.payload.notificationTypes,
      };
    }
    default:
      return state;
  }
};

const expertDefaultState = {
  data: {
    id: 0,
    peopleId: 0,
    firstName: '',
    middleInitial: '',
    lastName: '',
    gender: -1,
    honor: [],
    tinNumber: '',
    ssn: '',
    dob: '',
    language: [],
    payableTo: '',
    npi: '',
    cellphone: '',
    pager: '',
    pagerPin: '',
    email1: '',
    email2: '',
    previousJericho: '',
    vendor: '',
    underInvestigation: false,
    reason: '',
    expertStatus: '',
    referredBy: '',
    expertStatusStep: -1,
    recommendedOn: '',
    startDate: '',
    lastCredentialedOn: '',
    recruitmentMethod: '',
    referralCode: -1,
    terminationReason: '',
    terminationDate: '',
    notificationMethod: '',
    callForAssignment: false,
    onlineTaskList: false,
    comments: '',
    biography: '',
    salutation: '',
    expertUserId: -1,
    maxFileSizeMB: '',
    maxPageCount: '',
    maxFaxedPages: '',
  },
  errors: {
    firstName: '',
    lastName: '',
    ssn: '',
    tinNumber: '',
    vendor: '',
    cellphone: '',
    pager: '',
  },
  expertStatuses: [],
  expertStatusProspect: [],
  expertStatusApplicant: [],
  recruitmentMethods: [],
  referralCodes: [],
  termReason: [],
  notificationTypes: [],
  salutations: [],
  languages: [],
  honorifics: [],
};

const expertReducer = (state = expertDefaultState, action) => {
  switch (action.type) {
    case EXPERT_INFO_UPDATE_EXPERT_INPUT: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case EXPERT_INFO_UPDATE_EXPERT_INPUT_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    }
    case EXPERT_INFO_UPDATE_EXPERT_DATA: {
      return {
        ...state,
        data: action.payload.data,
        expertStatuses: action.payload.expertStatuses,
        expertStatusProspect: action.payload.expertStatusProspect,
        expertStatusApplicant: action.payload.expertStatusApplicant,
        recruitmentMethods: action.payload.recruitmentMethods,
        referralCodes: action.payload.referralCodes,
        termReason: action.payload.termReason,
        notificationTypes: action.payload.notificationTypes,
        salutations: action.payload.salutations,
        languages: action.payload.languages,
        honorifics: action.payload.honorifics,
      };
    }
    default:
      return state;
  }
};

const expertInfoReducer = combineReducers({
  group: groupReducer,
  expert: expertReducer,
  initialData: initialReducer,
});

export default expertInfoReducer;
