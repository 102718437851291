import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AppTable from 'shared/components/table/AppTable';

import { formatPhoneNumber } from '../utils/expertUtils';

const STREET = 'Street';
const CITY = 'City';
const STATE = 'State';
const PHONE = 'Phone #';
export default class NewExpertAddressTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: STREET,
      sortOrder: true,
    };
  }

  getRow(item) {
    return {
      street: item.street1 || '',
      city: item.city || '',
      state: item.state || '',
      phone: item.phone ? formatPhoneNumber(item.phone) : '',
    };
  }

  getHeader(name) {
    const arrow =
      this.state.sortBy === name ? (
        <FontAwesomeIcon
          className="grey-text"
          style={{ marginLeft: 5 }}
          icon={this.state.sortOrder ? faArrowUp : faArrowDown}
        />
      ) : (
        ''
      );
    return (
      <span
        className="cursor-hover"
        onClick={() => this.onSortHandler(name, !this.state.sortOrder)}
      >
        {name}
        {arrow}
      </span>
    );
  }

  onSortHandler = (sortBy, sortOrder) => {
    this.setState({
      sortBy,
      sortOrder,
    });
  };

  sortData(item1, item2) {
    const { sortBy, sortOrder } = this.state;
    switch (sortBy) {
      case STREET: {
        const street1 = item1.street1 || '';
        const street2 = item2.street1 || '';
        return sortOrder
          ? street1.localeCompare(street2)
          : street2.localeCompare(street1);
      }
      case CITY:
        const city1 = item1.city || '';
        const city2 = item2.city || '';
        return sortOrder
          ? city1.localeCompare(city2)
          : city2.localeCompare(city1);
      case STATE:
        const state1 = item1.state || '';
        const state2 = item2.state || '';
        return sortOrder
          ? state1.localeCompare(state2)
          : state2.localeCompare(state1);
      case PHONE:
      default:
        const phone1 = item1.phone || '';
        const phone2 = item2.phone || '';
        return sortOrder
          ? phone1.localeCompare(phone2)
          : phone2.localeCompare(phone1);
    }
  }

  render() {
    const data = this.props.data
      .sort((item1, item2) => this.sortData(item1, item2))
      .map((item, index) => this.getRow(item, index));
    return (
      <AppTable
        resizable
        columns={[
          {
            header: this.getHeader(STREET),
            field: 'street',
          },
          {
            header: this.getHeader(CITY),
            field: 'city',
          },
          {
            header: this.getHeader(STATE),
            field: 'state',
          },
          {
            header: this.getHeader(PHONE),
            field: 'phone',
          },
        ]}
        data={data}
        autoPagination={true}
      />
    );
  }
}

NewExpertAddressTable.propTypes = {
  data: PropTypes.array.isRequired,
};
