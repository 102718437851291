import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import AppTooltip from 'shared/components/tooltip/AppTooltip';

import {
  getItemAddressServices,
  buildGoogleMapLink,
} from '../utils/expertUtils';

import PhoneFaxRenderer from './PhoneFaxRenderer';

const AddressRenderer = ({ item, index, renderPhoneFax }) => {
  const street1 = item.street1 || '';
  const street2 = item.street2 || '';
  const addressName = item.addressname || '';
  const city = item.city || '';
  const state = item.state || '';
  const zipCode = item.zipcode || '';
  const latitude = item.latitude || '';
  const longitude = item.longitude || '';
  const groupName = item.groupname || '';
  const googleMapUrl = buildGoogleMapLink(
    street1,
    city,
    state,
    zipCode,
    latitude,
    longitude
  );
  const streetHtml = (
    <span>
      {street1} {street2 !== '' && `- ${street2}`}
      <br></br>
    </span>
  );
  const addressHtml =
    groupName !== '' ? (
      <>
        <span className="bold-text">{groupName}</span>
        <br></br>
        {streetHtml}
      </>
    ) : addressName !== '' ? (
      <>
        <span>{addressName}</span>
        <br></br>
        {streetHtml}
      </>
    ) : (
      <>{streetHtml}</>
    );
  return (
    <div>
      <div style={{ float: 'left' }}>
        <AppTooltip
          text="Show in Google Maps"
          id={`preview-address-google-map-${index}`}
        >
          <a
            style={{ marginRight: 5 }}
            href={googleMapUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon className="red-text" icon={faMapMarker} />
          </a>
        </AppTooltip>
      </div>
      <div style={{ float: 'left' }}>
        {addressHtml}
        {renderPhoneFax && <PhoneFaxRenderer item={item} lineBreak={false} />}
        <span className="grey-text italic-text">
          {getItemAddressServices(item).join(', ')}
        </span>
      </div>
    </div>
  );
};

AddressRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  renderPhoneFax: PropTypes.bool.isRequired,
};

export default AddressRenderer;
