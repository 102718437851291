import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input, Row, Col, Form, FormGroup, Button } from 'reactstrap';

import AppAutocomplete from 'shared/components/input/AppAutocomplete';
import AppDateInput from 'shared/components/input/AppDateInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import { isEqual } from 'lodash';

import {
  taskListGridSettingName,
  taskListGridSettingModuleId,
} from '../utils/constantUtils';

import GridSettingsModal from './GridSettingsModal';
import GroupBySelection from './GroupBySelection';
import ReferralButton from './ReferralButton';

const LeftTopNav = props => {
  const handleKeyDown = (e) => {
    e.preventDefault();
    props.findQABills();  
  }
  return (
    <Form onSubmit={handleKeyDown}>
    <Row form>
      <Col>
          <FormGroup>
            <AppTooltip
              idWrapTag="div"
              text="Bill/Scan"
              id="startId"
              placement="bottom"
            >
              <Input
                onChange={event =>
                  props.onInputChange(event.target.id, event.target.value)
                }
                placeholder="Bill/Scan"
                id="startId"
                type="text"
                value={props.startId}
              />
            </AppTooltip>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <AppTooltip
              idWrapTag="div"
              text="End"
              id="endId"
              placement="bottom"
            >
              <Input
                onChange={event =>
                  props.onInputChange(event.target.id, event.target.value)
                }
                placeholder="End"
                id="endId"
                type="text"
                value={props.endId}
              />
            </AppTooltip>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <AppAutocomplete
              id="site"
              data={props.clients}
              value={props.siteName}
              displayOption="chr_sitename"
              returnValue='int_sitecode'
              onChange={props.onAutocompleteChange}
              onSelect={props.onAutocompleteSelect}
              showToolTip={true}
              placeholder="Client"
              isSuggested={true}
              width="200%"
              clearInput={props.onClearSiteInput}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <AppAutocomplete
              id="state"
              data={props.states}
              value={props.stateName}
              displayOption="name"
              returnValue="abbreviation"
              onChange={props.onAutocompleteChange}
              onSelect={props.onAutocompleteSelect}
              showToolTip={true}
              placeholder="State..."
              isSuggested={true}
              clearInput={props.onClearStateInput}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <AppTooltip
              idWrapTag="div"
              text="Entry Date"
              id="entryDate"
              placement="bottom"
            >
              <AppDateInput
                id="entryDate"
                onChange={value => props.onInputChange('entryDate', value)}
                selected={props.entryDate}
              />
            </AppTooltip>
          </FormGroup>
        </Col>
        <Col style={{marginRight: "-70px"}}>
          <Button type="submit">FIND</Button>
        </Col>
      </Row>
    </Form>
  );
};

const RightTopNav = props => {
  return (
    <Row>
      <Col lg="4">
      <FormGroup>
        <ReferralButton
          name="qaRefSetting"
          selectedBills={props.selectedBills}
          bills={props.bills}
        />
       </FormGroup>
      </Col>
      <Col lg="4">
      <FormGroup>
        <GridSettingsModal
          name="qaBillSetting"
          settingColumns={props.settingColumns}
          updateCheckbox={props.updateGridSettingsCheckbox}
          gridSettingName={taskListGridSettingName}
          gridSettingModuleId={taskListGridSettingModuleId}
        />
        </FormGroup>
      </Col>
      <Col lg="4">
        <FormGroup>
          <GroupBySelection
            groupBy={props.groupBy}
            groupBySelection={props.groupBySelection}
            selectedBills={props.selectedBills}
            updateSelectedBills={props.updateSelectedBills}
            bills={props.bills}
            updateGroupBySelection={props.updateGroupBySelection}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

class QABillTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entryDate: '',
      startId: '',
      endId: '',
      state: '',
      stateName: '',
      site: '',
      siteName: '',
      selectedData: {}
    };
  }

 componentDidUpdate(prevProps) {
   const {isApproveQABills} = this.props;
   if (!isEqual(prevProps.isApproveQABills, isApproveQABills) && isApproveQABills) {
    this.findQABills();
   }
  }

  onInputChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  onAutocompleteChange = (id, value) => {
    this.setState({
      [`${id}Name`]: value,
    });
  };

  onAutocompleteSelect = (id, displayValue, value) => {
    this.setState({
      [`${id}Name`]: displayValue,
      [id]: value,
    });
  };

  onMasterClientEnterPress = event => {
    if (event.charCode !== 13) {
      return;
    }
    this.props.loadMasterClientDataProvider(event.target.value);
  };

  findQABills = () => {
    this.props.findQABills({ ...this.state });
  };

  onClearStateInput = () => {
    this.setState({
    state: "",
    stateName: ""
    });
  };

  onClearSiteInput = () => {
    this.setState({
      site: "",
      siteName: ""
    });
 }

  render() {
    return (
      <Row>
        <Col lg="7">
          <LeftTopNav
            entryDate={this.state.entryDate}
            startId={this.state.startId}
            endId={this.state.endId}
            stateName={this.state.stateName}
            siteName={this.state.siteName}
            onInputChange={this.onInputChange}
            onAutocompleteChange={this.onAutocompleteChange}
            onAutocompleteSelect={this.onAutocompleteSelect}
            findQABills={this.findQABills}
            states={this.props.states}
            masterClientDataProvider={this.props.masterClientDataProvider}
            onMasterClientEnterPress={this.onMasterClientEnterPress}
            updateState={this.updateState}
            clients={this.props.clients}
            onClearStateInput={this.onClearStateInput}
            onClearSiteInput={this.onClearSiteInput}
          />
        </Col>
        <Col lg="5">
          <RightTopNav
            selectedGroupBy={this.props.groupBy}
            data={this.props.groupBySelection}
            groupBy={this.props.groupBy}
            groupBySelection={this.props.groupBySelection}
            selectedBills={this.props.selectedBills}
            updateSelectedBills={this.props.updateSelectedBills}
            bills={this.props.bills}
            updateGroupBySelection={this.props.updateGroupBySelection}
            settingColumns={this.props.settingColumns}
            updateGridSettingsCheckbox={this.props.updateGridSettingsCheckbox}
          />
        </Col>
      </Row>
    );
  }
}

QABillTopNav.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  masterClientDataProvider: PropTypes.array.isRequired,
  findQABills: PropTypes.func.isRequired,
  confirmQABills: PropTypes.func.isRequired,
  loadMasterClientDataProvider: PropTypes.func.isRequired,
  bills: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  updateSelectedBills: PropTypes.func.isRequired,
  updateGroupBySelection: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  groupBySelection: PropTypes.array.isRequired,
  isApproveQABills: PropTypes.bool
};

export default QABillTopNav;
