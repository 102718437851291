import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, CustomInput, Input } from 'reactstrap';

import { commonOperations } from 'common/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';
import AppTable from 'shared/components/table/AppTable';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import * as FileUtils from 'shared/utils/fileUtils';
import * as NumberUtils from 'shared/utils/numberUtils';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';
import { check } from 'prettier';

let checkedItems = [];
let uploadCheckedItems = [];
let viewDocFileName = undefined;
const DemandButton = ({
  loadDemandDocuments,
  demandFileUpload,
  profile,
  user,
  selectedBills,
  claimObject,
  documents,
  loadDeleteDemandDocuments,
  confirmDemandBills,
  isShowListOfDoc,
  isShowDemandButton,
  closeListOfDoc,
  retriveDemandDocument,
  retrieveDoc,
  resetRetriveDemandDocument,
  fileDesc,
}) => {
  const [isDemandModalOpen, setIsDemandModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isOpenAttachFiles, setIsOpenAttachFiles] = useState(isShowListOfDoc);
  const [isRefreshDocuments, setIsRefreshDocuments] = useState(true);
  const [checkedVal, setCheckedVal] = useState(false);
  const [removeVal, setRemoveVal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const attachFileRef = useRef();
  const [demandNoteValue, setDemandNoteValue] = useState('');

  useEffect(() => {
    if (isRefreshDocuments && (selectedBills.length > 0 || isShowListOfDoc)) {
      loadDemandDocuments({
        profile,
        billId: claimObject.int_claimcode,
      });

      setIsRefreshDocuments(false);
    }
  }, [
    loadDemandDocuments,
    profile,
    filesToUpload,
    isOpenUploadFiles,
    isRefreshDocuments,
    isDemandModalOpen,
    selectedBills,
  ]);

  useEffect(() => {
    viewDocFileName =
      viewDocFileName === fileDesc ? viewDocFileName : undefined;
    if (retrieveDoc && !viewDocFileName) {
      debugBase64(retrieveDoc, fileDesc);
      viewDocFileName = fileDesc;
      resetRetriveDemandDocument();
    }
  }, [retrieveDoc]);

  const getDemandModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={stateDemandModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={toggleUploadFiles}>
          Yes
        </Button>
      </>
    );
  };

  const getDeleteModalFooterContent = () => {
    return (
      <>
        <Button color="secondary" onClick={stateDeleteModalToggle}>
          No
        </Button>
        <Button color="primary" onClick={handleDleteFiles}>
          Yes
        </Button>
      </>
    );
  };

  const getDemandModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>
            The demand status has been added to the selected bills. Would you
            like to upload any documentation?
          </span>
        </Col>
      </Row>
    );
  };

  const getDeleteModalBodyContent = () => {
    return (
      <Row form>
        <Col>
          <span>Are you sure you want to delete these file(s)?</span>
        </Col>
      </Row>
    );
  };

  const stateDemandModalToggle = () => {
    setIsDemandModalOpen(!isDemandModalOpen);
    setDemandNoteValue('');
  };

  const stateDeleteModalToggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const demandHanlder = async () => {
    let billList = [];
    selectedBills.forEach(item => {
      return billList.push(parseInt(item));
    });
    const params = {
      functionName: 'addDemandStatus',
      billList: billList,
      zebraUserId: user.user_id,
      profile: profile,
    };
    await confirmDemandBills(params);
    stateDemandModalToggle();
  };

  const changeDemandNoteValue = event => {
    setDemandNoteValue(event.target.value)
  };

  const toggleUploadFiles = () => {
    setIsOpenUploadFiles(!isOpenUploadFiles);
  };

  const toggleAttachFiles = (isFromModal = false) => {
    setIsOpenAttachFiles(!isOpenAttachFiles);
    isFromModal && isShowListOfDoc && closeListOfDoc();
    setIsDemandModalOpen(false);
  };

  const handleSelectUploadFile = e => {
    e.preventDefault();

    const keyOffset = filesToUpload.length;
    const filesToAdd = Object.values(
      R.mapObjIndexed(
        (value, key) => ({
          id: parseInt(key, 10) + keyOffset,
          file: value,
          selected: false,
        }),
        attachFileRef.current.files
      )
    );
    attachFileRef.current.value = null;

    const newFilesToUpload = R.concat(filesToUpload, filesToAdd);
    setFilesToUpload(newFilesToUpload);
  };

  const clearFilesToUpload = () => {
    setFilesToUpload([]);
    uploadCheckedItems = [];
    setRemoveVal(false);
  };

  const removeFilesToUpload = () => {
    const removeId = uploadCheckedItems.map(file => file.id);
    const selectedFiles = filesToUpload.filter(
      file => !removeId.includes(file.id)
    );
    setFilesToUpload(selectedFiles);
    uploadCheckedItems = [];
    setRemoveVal(false);
  };

  const getTotalUploadSize = () => {
    let totalBytes = 0;
    for (let i = 0; i < filesToUpload.length; i++) {
      totalBytes += filesToUpload[i].file.size;
    }

    return NumberUtils.formatFileSize(totalBytes);
  };

  const handleUploadFiles = e => {
    e.preventDefault();

    Promise.all([
      R.map(async fileObj => {
        const fileExt = FileUtils.getFilenameExtension(fileObj.file.name);
        const fileData = await FileUtils.fileToBase64(fileObj.file);
        return demandFileUpload({
          fileExt,
          fileName: fileObj.file.name,
          fileData,
          billId: claimObject.int_claimcode,
          profile,
          sessionId: user.sessionId,
          docNote: demandNoteValue,
        });
      }, filesToUpload),
    ]).then(data => {
      setTimeout(() => {
        setIsRefreshDocuments(true);
      }, 2000);
      toggleUploadFiles();
      toggleAttachFiles();
    });
  };

  const handleAttachFiles = e => {
    e.preventDefault();

    toggleAttachFiles();
    toggleUploadFiles();
  };

  const getUploadFilesBody = () => {
    const filesToUploadCount = filesToUpload.length;
    const filesToUploadLabel =
      filesToUploadCount < 1
        ? ''
        : `Total Files: ${filesToUploadCount}, Total Size: ${getTotalUploadSize()}`;

    return (
      <Form>
        <Row form>
          <Col sm={12}>
            <AppTable
              columns={[
                {
                  header: 'File Name',
                  render: item => item.file.name,
                },
                {
                  header: 'File Size',
                  render: item => NumberUtils.formatFileSize(item.file.size),
                },
                {
                  header: 'Remove',
                  field: '',
                  render: item => renderColumn(item),
                },
              ]}
              data={filesToUpload}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
          Document Notes:
          <Input
                id="note"
                name="text"
                type="textarea"
                value={demandNoteValue}
                onChange={changeDemandNoteValue}
                style={{ height: '100px' }}
            />
            <br />
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="attachFile"
                multiple
                accept=".pdf,.jpg,.png,.tif,.gif,.doc,.docx,.xls,.xlsx"
                ref={attachFileRef}
                onChange={handleSelectUploadFile}
              />
              <label className="custom-file-label" htmlFor="attachFile">
                {filesToUploadLabel}
              </label>
            </div>
          </Col>
          <Col sm={6} align="right">
            <Button
              className="file-attachment-remove-button"
              disabled={!removeVal}
              onClick={() => removeFilesToUpload()}
            >
              REMOVE
            </Button>
            <Button
              className="file-attachment-button"
              disabled={filesToUpload.length < 1}
              onClick={() => clearFilesToUpload()}
            >
              CLEAR ALL
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const getUploadFilesFooter = () => {
    return (
      <Button
        className="file-attachment-button"
        disabled={filesToUpload.length < 1}
        onClick={handleUploadFiles}
      >
        UPLOAD
      </Button>
    );
  };

  const renderActiveColumn = item => {
    if (item.bol_active === true) {
      return (
        <FontAwesomeIcon className="active-green-circle" icon={faCircle} />
      );
    } else {
      return <FontAwesomeIcon className="red-text" icon={faCircle} />;
    }
  };

  const selectRemoveHandler = (item, event) => {
    uploadCheckedItems = event.target.checked
      ? uploadCheckedItems.concat(item)
      : uploadCheckedItems.filter(row => row.id !== item.id);
    if (uploadCheckedItems.length > 0) {
      setRemoveVal(true);
    } else {
      setRemoveVal(false);
    }
  };

  const renderColumn = item => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.file.name}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectRemoveHandler(item, event);
          }}
        />
      </div>
    );
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked
      ? checkedItems.concat(item)
      : checkedItems.filter(row => row.id !== item.id);
    if (checkedItems.length > 0) {
      setCheckedVal(true);
    } else {
      setCheckedVal(false);
    }
  };

  const renderCheckBoxColumn = item => {
    return (
      <div className="select-action">
        <CustomInput
          id={item.id}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />
      </div>
    );
  };

  const getDocFromTable = item => {
    const getPath = item.path.split('/')[1];
    const desc = item.description;
    retriveDemandDocument({ fileName: getPath, description: desc });
  };

  const debugBase64 = (fileDoc, fileDesc) => {
    FileUtils.saveBase64File(fileDesc, fileDoc);
  };

  const getAttachFilesBody = () => {
    return (
      <div>
        <AppTable
          modalHeight={400}
          columns={[
            {
              header: 'Date Added',
              field: 'upload_date',
            },
            {
              header: 'Notes',
              field: 'notes',
            },
            {
              header: 'Description',
              field: 'description',
            },
            {
              header: 'Delete',
              field: '',
              render: item => renderCheckBoxColumn(item),
            },
            {
              header: 'Active',
              field: 'bol_active',
              render: item => renderActiveColumn(item),
            },
          ]}
          data={documents}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          rowDoubleClickHandler={getDocFromTable}
          key="demand-document-table"
        />
      </div>
    );
  };

  const getAttachFilesFooter = () => {
    if (checkedVal === false) {
      return <Button onClick={handleAttachFiles}>UPLOAD FILE(S)</Button>;
    } else {
      return <Button onClick={stateDeleteModalToggle}>DELETE FILE(S)</Button>;
    }
  };

  const handleDleteFiles = async () => {
    await Promise.all([
      checkedItems.map(item =>
        loadDeleteDemandDocuments({
          docId: item.id,
        })
      ),
    ]).then(data => {
      setTimeout(() => {
        setIsRefreshDocuments(true);
      }, 2000);
      checkedItems = [];
      setCheckedVal(false);
      stateDeleteModalToggle();
    });
  };

  const resetUploadFilesModal = () => {
    clearFilesToUpload();
  };

  const resetAttachFilesModal = () => { };

  return (
    <>
      {isShowDemandButton && (
        <div style={{ marginRight: '12px' }}>
          <Button
            style={{ width: '100%', marginRight: '12px' }}
            type="button"
            color="primary"
            size="md"
            onClick={() => demandHanlder()}
            disabled={selectedBills.length < 1}
          >
            DEMAND
          </Button>
        </div>
      )}
      <PopupModal
        content={getDemandModalBodyContent()}
        title="Status Added"
        isOpen={isDemandModalOpen}
        externalToggle={stateDemandModalToggle}
        footerContent={getDemandModalFooterContent()}
        size="lg"
      />
      <PopupModal
        content={getDeleteModalBodyContent()}
        title="Confirm Delete"
        isOpen={isDeleteModalOpen}
        externalToggle={stateDeleteModalToggle}
        footerContent={getDeleteModalFooterContent()}
        size="md"
      />
      <PopupModal
        content={getUploadFilesBody()}
        title="ADD A FILE"
        externalToggle={() => {
          toggleUploadFiles();
          toggleAttachFiles();
          checkedItems = [];
          setCheckedVal(false);
          uploadCheckedItems = [];
          setRemoveVal(false);
        }}
        isOpen={isOpenUploadFiles}
        footerContent={getUploadFilesFooter()}
        onClosed={resetUploadFilesModal}
        size="lg"
      />
      <PopupModal
        content={getAttachFilesBody()}
        title="ADD A FILE"
        externalToggle={() => toggleAttachFiles(true)}
        isOpen={isOpenAttachFiles}
        footerContent={getAttachFilesFooter()}
        onClosed={resetAttachFilesModal}
        size="xl"
      />
    </>
  );
};

DemandButton.propTypes = {
  loadDemandDocuments: PropTypes.func.isRequired,
  resetReferral: PropTypes.func.isRequired,
  demandFileUpload: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  bills: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  claimObject: PropTypes.object,
  loadDeleteDemandDocuments: PropTypes.func,
  confirmDemandBills: PropTypes.func,
  searchFields: PropTypes.object,
  claimType: PropTypes.string,
  findBills: PropTypes.func,
  retriveDemandDocument: PropTypes.func,
  retrieveDoc: PropTypes.string,
  fileDesc: PropTypes.string,
  resetRetriveDemandDocument: PropTypes.func,
  demandNoteValue: PropTypes.string,

};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  const demandState = billOperationsSelectors.getDemandState(state);
  return {
    profile: initialData.currentProfile,
    user,
    documents: demandState.demandDoc,
    retrieveDoc:
      demandState.retireveDemandDoc && demandState.retireveDemandDoc.fileData,
    fileDesc:
      demandState.retireveDemandDoc &&
      demandState.retireveDemandDoc.description,
  };
};

const mapDispatchToProps = dispatch => ({
  confirmDemandBills: data =>
    dispatch(billOperationsOperations.confirmDemandBills(data)),
  loadDemandDocuments: data =>
    dispatch(billOperationsOperations.loadDemandDocuments(data)),
  retriveDemandDocument: data =>
    dispatch(billOperationsOperations.retriveDemandDocument(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
  resetReferral: () => dispatch(billOperationsActions.resetReferral()),
  demandFileUpload: data =>
    dispatch(billOperationsOperations.demandFileUpload(data)),
  loadDeleteDemandDocuments: data =>
    dispatch(billOperationsOperations.loadDeleteDemandDocuments(data)),
  findBills: data =>
    dispatch(billOperationsOperations.findBills(data, data.claimType)),
  resetRetriveDemandDocument: () =>
    dispatch(billOperationsActions.resetRetriveDemandDocument()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemandButton);
