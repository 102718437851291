import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col } from 'reactstrap';

import Day from './Day';
import { makeRange } from './YearlyCalendar';

class Month extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMonthDays() {
    const { year, month } = this.props;
    const firstDayOfWeek = 0;
    const monthStart = moment([year, month, 1]);
    let prevMonthDaysCount = monthStart.weekday();
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    const numberOfDays = monthStart.daysInMonth();
    const totalDays = 37;
    const days = [];
    makeRange(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1).forEach(i => {
      const day = moment([year, month, i - prevMonthDaysCount]);
      let classes = ['day'];
      if (i <= prevMonthDaysCount || i > numberOfDays + prevMonthDaysCount) {
        return;
      }
      if ((i - 2) % 7 === firstDayOfWeek && days.length) {
        days.push(
          <hr className="week-separator  p-0 m-0" key={`separator-${i}`} />
        );
      }

      const eventsInDay = this.props.eventsInMonth.filter(
        event => event.start.toDateString() === day.toDate().toDateString()
      );
      if (eventsInDay.length > 0) {
        classes.push('yearly-event');
      }
      days.push(
        <Day
          eventsInDay={eventsInDay}
          key={`day-${i}`}
          day={day.isValid() ? day : null}
          classes={classes.join(' ')}
          onClickEvent={this.props.onClickEvent}
        />
      );
    });
    return days;
  }

  render() {
    const { month, year } = this.props;
    return (
      <Col
        className={
          'month-wrapper pl-0 pr-0 month-border-left ' +
          classNames({ 'month-border-right': month === 11 })
        }
      >
        <div className="month-name">
          {moment([year, month, 1]).format('MMMM')}
          {month === 0 ? ' ' + year : ''}
        </div>
        {this.renderMonthDays()}
      </Col>
    );
  }
}

Month.propTypes = {
  year: PropTypes.number.isRequired,
  eventsInMonth: PropTypes.array,
  month: PropTypes.number.isRequired,
  onClickEvent: PropTypes.func,
};

Month.defaultProps = {
  eventsInMonth: [],
};

export default Month;
