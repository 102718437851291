import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import moment from 'moment';
import { Col, Row, Form, FormGroup, Button, Card, CardHeader, CardBody } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import {  fast360Operations} from '../store';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppDateInput from 'shared/components/input/AppDateInput';
import { commonOperations } from 'common/store';
import { connect } from 'react-redux';
import { authSelectors } from 'modules/auth/store';

import "react-datepicker/dist/react-datepicker.css";

const Fast360APMaint = ({
    isOpen,
    onClose,
    lmsUserId,
    zebraUserId,
    apDataLoad,
    alert,
    getAPInfo,
    saveAPinfo,
    saveObj,
   
}) => {


  const [referralId, setReferralId] = useState('');
  const [tableAbbrv, setTableAbbrv] = useState('');
  const [apDataSave, setApDataSave] = useState({});
  const [serviceId, setServiceId] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTool = () => setTooltipOpen(!tooltipOpen);
    useEffect(() => {
      if (apDataLoad ) {
        setApDataSave(apDataLoad)
      }
      }, [apDataLoad]);
  
      
    const toggle = () => {
      setApDataSave({});
      onClose();
    };

    const changeRef = (value) => {
      setReferralId(value);
      changeConf(value, tableAbbrv, serviceId) ;
    };

    const changeTableAbbrv = (value) => {
      setTableAbbrv(value);
      changeConf(referralId, value, serviceId) ;
    };
    const changeService = (value) => {
      setServiceId(value);
      changeConf(referralId, tableAbbrv, value) ;
    };

    const changeAPData = (value, name) => {
      let aptemp;
      aptemp = apDataSave
      
      if (name === 'vendorInvoiceNum'){
        aptemp.invoiceNo = value;
      }

      if (name === 'vendorInvoiceAmount'){
        aptemp.invoiceAmt = value;
      }

      setApDataSave({...aptemp});
      
    };


    const onChangeHandler = object => {
      let aptemp;
      aptemp = apDataSave
      if (object.key === 'invoiceDate') {
        aptemp.invoiceDate = moment(object.value).format('MM/DD/YYYY');
      }
  
      setApDataSave({...aptemp});
    };

    const changeConf = (ref, table, service) => {
      if ((referralId.length >= 5) && (tableAbbrv.length === 3)){
        let getAPInfoData = {
          "functionName": "getAPInfo",
          "zebraUserId": 2591,
          "userId": 2591,        
          "referralId": ref,
          "serviceId": service,
          "referenceTable": table,
      
        }
        
        getAPInfo(getAPInfoData);
        //setApDataSave(apDataLoad);
      }
    };
    const saveAP = () => {
    
      let APInfoData = {
        "functionName": "saveAPInfo",
        "zebraUserId": 2591,
        "userId": lmsUserId,
        "accountId": apDataSave.accountId, 
        "invoiceNo": apDataSave.invoiceNo,
        "invoiceDate":  moment(apDataSave.invoiceDate).format('YYYY-MM-DD'),
        "invoiceAmt": apDataSave.invoiceAmt,
        "serviceId": apDataSave.serviceId,  
    
      }
        
      saveAPinfo(APInfoData);
      setTimeout(() => {
        changeConf(referralId, tableAbbrv, serviceId) ;
      }, 1000);
      
    };
    //do monday
    const resubmit = () => {
      let aptemp;
      let clearAPApprovalData = {
        "functionName": "clearAPApproval",
        "zebraUserId": 2591,
        "userId": lmsUserId,
        "accountId": apDataSave.accountId, 
    
      }
      saveObj(clearAPApprovalData);
     
      aptemp = apDataSave
      aptemp.apSentDate = '';
      
      setApDataSave({...aptemp});
      
    };
    useEffect(() => {
       
    });

    

    return (
        <>
            <Modal size="md" isOpen={isOpen} modalClassName="fee-lookup-modal-body">
                <ModalHeader  toggle={toggle} >AP Maintenance</ModalHeader>
                <ModalBody>
                  <Form>
                  <Row>
                    <Col sm="4" className="text-right" style={{ paddingTop: '8px' }}>  Conf #: </Col>
                    <Col lg="7" style={{ paddingTop: '8px' }}>
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input
                          
                            style={{ width: '45%' }}
                            id="referralId"
                            name="referralId"
                            onChange={e => changeRef(e.target.value)}
                          />-
                          <Input
                            style={{ width: '45%' }}
                            id="serviceType"
                            name="serviceType"
                            onChange={e => changeTableAbbrv(e.target.value)}
                          />-
                          <Input
                            style={{ width: '45%' }}
                            id="serviceId"
                            name="serviceId"
                            onChange={e => changeService(e.target.value)}
                          />
                        </span>
                      </Col>

                    </Row>
                    <Row>
                      <Col sm="4" className="text-right" style={{ paddingTop: '8px' }}> Vendor Invoice Number: </Col>
                      <Col lg="7" style={{ paddingTop: '8px' }}>
                         <Input
                              style={{ width: '100%' }}
                              id="vendorInvoiceNum"
                              name="vendorInvoiceNum"
                              value={apDataSave.invoiceNo ||  ''}
                              onChange={e => changeAPData(e.target.value, "vendorInvoiceNum")}
                            />
                       
                        </Col>

                    </Row>
                    <Row>
                      <Col sm="4" className="text-right" style={{ paddingTop: '8px' }}> Vendor Invoice Date: </Col>
                      <Col lg="7" style={{ paddingTop: '8px' }}>
                          <AppDateInput
                              style={{ width: '100%' }}
                              id="vendorInvoicedate"
                              name="vendorInvoicedate"
                              value={apDataSave.invoiceDate ||  ''}
                              onChange={value => onChangeHandler({ key: 'invoiceDate', value })}
                            />
                       
                        </Col>

                    </Row>
                    <Row>
                      <Col sm="4" className="text-right" style={{ paddingTop: '8px' }}> Vendor Invoice Amount: </Col>
                      <Col lg="7" style={{ paddingTop: '8px' }}>
                         <Input
                              style={{ width: '100%' }}
                              id="vendorInvoiceAmount"
                              name="vendorInvoiceAmount"
                              value={apDataSave.invoiceAmt ||  ''}
                              onChange={e => changeAPData(e.target.value, "vendorInvoiceAmount")}
                            />
                       
                        </Col>

                    </Row>
                    <Row>
                      <Col sm="4" className="text-right" style={{ paddingTop: '8px' }}> AP Report Sent: </Col>
                      <Col lg="7" style={{ paddingTop: '8px' }}>
                        <AppDateInput
                              style={{ width: '100%' }}
                              id="vendorInvoiceAmount"
                              name="vendorInvoiceAmount"
                              value={apDataSave.apSentDate || ''}
                            />
                       
                        </Col>

                    </Row>
                     <Row>
                     <Col lg="5" className="text-right" style={{ paddingTop: '8px' }}>  </Col>
                      <Col xs="2"  style={{ paddingTop: '8px' }}>
                        <Button style={{ paddingTop: '8px' }}  onClick={toggle}>Close</Button>
                      </Col>
                      <Col xs="2"  style={{ paddingTop: '8px' }}>
                        <Button style={{ paddingTop: '8px' }}  onClick={saveAP}>Save</Button>
                      </Col>
                      <Col xs="3"  style={{ paddingTop: '8px' }}>
                        <Button style={{ paddingTop: '8px' }}  onClick={resubmit}>Resubmit</Button>
                      </Col>
                     
                     </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

Fast360APMaint.propTypes = {
    getAPInfo: PropTypes.func,
    saveAPInfo: PropTypes.func,
    alert: PropTypes.func,
    onClose: PropTypes.func,
    zebraUserId: PropTypes.string
};

const mapStateToProps = state => {
    return {
      lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
      apDataLoad: state.FAST360.fast360Reducer.getAPInfo ? state.FAST360.fast360Reducer.getAPInfo : [],
      saveAPResponse: state.FAST360.fast360Reducer.saveAPinfo ? state.FAST360.fast360Reducer.saveAPinfo : [],
      clearAPApprovalResponse: state.FAST360.fast360Reducer.saveObj ? state.FAST360.fast360Reducer.saveObj : [],
    };

};



const mapDispatchToProps = dispatch => ({
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
    getAPInfo: (data) => dispatch(fast360Operations.getAPInfo(data)),
    saveAPinfo: (data) => dispatch(fast360Operations.saveAPinfo(data)),
    saveObj: (data) => dispatch(fast360Operations.saveObj(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Fast360APMaint);




