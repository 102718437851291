import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label, Col, Row, Button } from 'reactstrap';

class CarrierTrackingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: "",
            trackingId: ""
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = () => {
        this.props.clicked()
    };

    handleDropdown = (e) => {

        this.setState({ selectedVal: e })
    }

    handleTrackingChange = (e) => {
        this.setState({ trackingId: e })
    }
    handleClick = () => {
        let url = {
            DHL: `https://www.dhl.com/en/express/tracking.html?AWB=${this.state.trackingId}&brand=DHL`,
            FEDEX: `https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers=${this.state.trackingId}`,
            OnTrac: `https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=${this.state.trackingId}`,
            UPS: `https://www.ups.com/track?loc=null&tracknum=${this.state.trackingId}&requester=WT/`,
            USPS: `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${this.state.trackingId}`

        }
        let defaultURL = `https://www.dhl.com/en/express/tracking.html?AWB=${this.state.trackingId}&brand=DHL`;
        let finalURL = url[this.state.selectedVal] ? url[this.state.selectedVal] : defaultURL;
        window.open(finalURL, '_blank')
    }


    render() {
        return (
            <Modal size="lg" isOpen={true} style={{ height: '275px' }} scrollable={'true'}>
                <ModalHeader toggle={this.toggle}>
                    Carrier Tracking
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: '50px' }}>
                        <Col xl={1} lg={1} style={{ marginTop: '6px' }}>Carrier: </Col>
                        <Col xl={3} lg={3} style={{ marginLeft: '-18px' }}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    value={this.state.selectedVal}
                                    onChange={e => this.handleDropdown(e.target.value)}
                                >
                                    <option value="DHL" style={{ fontWeight: '600' }}>DHL</option>
                                    <option value="FEDEX" style={{ fontWeight: '600' }}>FEDEX</option>
                                    <option value="OnTrac" style={{ fontWeight: '600' }}>OnTrac</option>
                                    <option value="UPS" style={{ fontWeight: '600' }}>UPS</option>
                                    <option value="USPS" style={{ fontWeight: '600' }}>USPS</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xl={2} lg={2} style={{ marginTop: '6px' }}> Tracking....</Col>
                        <Col xl={4} lg={4} style={{ marginLeft: '-55px' }}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="Enter Tracking Number"
                                    value={this.state.trackingId}
                                    onChange={e => this.handleTrackingChange(e.target.value)}
                                ></Input>
                            </FormGroup>
                        </Col>
                        <Col xl={2} lg={2} style={{ marginLeft: '50px' }}>
                            <Button onClick={this.handleClick} disabled={this.state.trackingId == ""} style={{ width: '125px' }}>Track
                            </Button>
                        </Col>
                    </Row>

                </ModalBody>
            </Modal >
        )
    }
}

export default CarrierTrackingModal;
