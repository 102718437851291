import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import PopupModal from 'shared/components/PopupModal';
import React, { useState } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap';

import { useAuth } from 'modules/auth/hooks/useAuth';

import defaultUser from '../../assets/static/images/user.png';

import { fast360Operations, fast360Actions } from '../../modules/fast-360/store';
import { connect } from 'react-redux';

library.add(faSignOutAlt);

const User = ({ floating, dataFromReducer, setSelectedSubProduct, setSelectedProduct, updateNewUsersWorkList,
  updateClickRecord, setSelectedType, setSelectedFilterType, updateStatusClicked, updateMilestoneClicked, updateTaskClicked, updateMilestoneData,
  updateTaskData, updatedStatusData, setProductBackUp, setTaskBackUp, setMilestoneBackUp, setStatusBackup, updateIsLogout }) => {
  const { logout, user, userId } = useAuth();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // TODO: use decode jwt to get email
  // We not sure the return result is jwt
  const userName = user ? `${user.firstname} ${user.lastname}` : 'Unknown User';

  const showConfirmationModalState = () => {
    setShowConfirmationModal(!showConfirmationModal);
  }
  
  const confirmationModalContent = () => {
    return (
      <span>
        Are you sure you want to logout of Bill Review?
      </span>
    )
  }
 
  //Uncommenting this lines of code for ZEb3-1353
  
  const confirmLogoutState = () => {
    //logout(userName, userId);
    if (user != null) {
      logout(user.username, userId);
    }
    setShowConfirmationModal(!showConfirmationModal);
    let mdata = [], tdata = [], sData = [];
    let rec = [];
    if (dataFromReducer != null && dataFromReducer.backupWorkList != null) {
      rec = dataFromReducer.backupWorkList.map(item => {
        item.selected = false;
        return item;
      });
    }    
    dataFromReducer?.milestone && dataFromReducer.milestone.map(item => mdata.push({ 'name': item, 'isChecked': true }));
    dataFromReducer?.tasks && dataFromReducer.tasks.map(item => tdata.push({ ...item, 'isChecked': true }));
    dataFromReducer?.status && dataFromReducer.status.map(item => sData.push({ ...item, 'isChecked': true }))
    setSelectedSubProduct('');
    setSelectedProduct('');
    updateNewUsersWorkList(rec);
    updateClickRecord();
    setSelectedType('');
    setSelectedFilterType('');
    updateStatusClicked(false);
    updateMilestoneClicked(false);
    updateTaskClicked(false);
    updateMilestoneData(mdata);
    updateTaskData(tdata);
    updatedStatusData(sData);
    setProductBackUp([]);
    setTaskBackUp([]);
    setMilestoneBackUp([]);
    setStatusBackup([]);
    updateIsLogout(true);
    localStorage.setItem('auth_token', "");
    window.location.href = '/login';
    window.location.reload();
  }

  return (
    <div className={floating}>
      {/* <Button size="sm" onClick={() => console.log('ME Button clicked')}>
        ME
      </Button>{' '} */}
      <UncontrolledButtonDropdown>
        <DropdownToggle caret className="user-wrapper">
          {userName}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={showConfirmationModalState}>
            <span style={{ marginRight: '10px' }}>Logout </span>{' '}
            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <span
        className="user-icon"
        style={{ backgroundImage: `url(${defaultUser})` }}
      />
      {showConfirmationModal &&
        <PopupModal
          content={confirmationModalContent()}
          title="Confirmation"
          isOpen={showConfirmationModal}
          externalToggle={showConfirmationModalState}
          footerContent={
            <>
              <Button color="secondary" onClick={showConfirmationModalState}>
                NO
              </Button>
              <Button color="primary" onClick={confirmLogoutState}>
                YES
              </Button>
            </>
          }
          size="md"
        />
      }
    </div>
  );
};


const mapStateToProps = state => {
  return {
    dataFromReducer: state.FAST360.initialData ? state.FAST360.initialData : [],
    usersOOCList: state.FAST360.initialData.usersOOCList ? state.FAST360.initialData.usersOOCList : undefined,
    usersRushList: state.FAST360.initialData.usersRushList ? state.FAST360.initialData.usersRushList : undefined,
    usersNewList: state.FAST360.initialData.usersNewList ? state.FAST360.initialData.usersNewList : undefined,
    usersFollowUpList: state.FAST360.initialData.usersFollowUpList ? state.FAST360.initialData.usersFollowUpList : undefined,
    updatedRec: state.FAST360.fast360Reducer.updateClickRecord ? state.FAST360.fast360Reducer.updateClickRecord : [],
    userId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    quickSearchCheck: state.FAST360.fast360Reducer.quickSearchCheck ? state.FAST360.fast360Reducer.quickSearchCheck : {},
    referralTransfered: state.FAST360.fast360Reducer.referralTransfered ? state.FAST360.fast360Reducer.referralTransfered : false,
    worklistLoaded: state.FAST360.initialData.worklistLoaded ? state.FAST360.initialData.worklistLoaded : false,
    selectedProduct: state.FAST360.fast360Reducer.selectedProduct,
    selectedSubProduct: state.FAST360.fast360Reducer.selectedSubProduct,
    inputField: state.FAST360.fast360Reducer.inputField,
    productBackUp: state.FAST360.fast360Reducer.productBackUp,
    subProductBackUp: state.FAST360.fast360Reducer.subProductBackUp,
    lastServiceModule: state.FAST360.fast360Reducer.lastServiceModule ? state.FAST360.fast360Reducer.lastServiceModule : '',
    lastSubServiceModule: state.FAST360.fast360Reducer.lastSubServiceModule ? state.FAST360.fast360Reducer.lastSubServiceModule : '',
    setVendorData: state.FAST360.fast360Reducer.setVendorData,
    statusData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updatedStatusData : [],
    statusBackup: state.FAST360.fast360Reducer.statusBackUp ? state.FAST360.fast360Reducer.statusBackUp : [],
    milestoneData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateMilestoneData : [],
    mileStoneBackUp: state.FAST360.fast360Reducer.mileStoneBackUp ? state.FAST360.fast360Reducer.mileStoneBackUp : [],
    taskData: state.FAST360.fast360Reducer ? state.FAST360.fast360Reducer.updateTaskData : [],
    taskBackUp: state.FAST360.fast360Reducer.taskBackUp ? state.FAST360.fast360Reducer.taskBackUp : [],
    selectedFilterType: state.FAST360.fast360Reducer.selectedFilterType,

  };
};


const mapDispatchToProps = dispatch => ({
  updateNewUsersWorkList: data =>
    dispatch(fast360Actions.updateNewUsersWorkList(data)),
  updateClickRecord: () => dispatch(fast360Actions.updateClickRecord([])),
  updatePreviousUser: data => dispatch(fast360Actions.updatePreviousUser(data)),
  updateStatusClicked: data =>
    dispatch(fast360Actions.updateStatusClicked(data)),
  updateMilestoneClicked: data =>
    dispatch(fast360Actions.updateMilestoneClicked(data)),
  updateTaskClicked: data => dispatch(fast360Actions.updateTaskClicked(data)),
  updateMilestoneData: data =>
    dispatch(fast360Actions.updateMilestoneData(data)),
  runQuickSearchCheck: (data) => dispatch(fast360Operations.quickSearchCheck(data)),
  setSelectedVendor: data => dispatch(fast360Actions.setSelectedVendor(data)),
  clearReferralTransfered: () => dispatch(fast360Actions.clearReferralTransfered()),
  resetUsersTotOrders: () => dispatch(fast360Actions.resetUsersTotOrders()),
  resetUsersRush: () => dispatch(fast360Actions.resetUsersRush()),
  resetUsersOOC: () => dispatch(fast360Actions.resetUsersOOC()),
  resetNewOrders: () => dispatch(fast360Actions.resetNewOrders()),
  resetFollowups: () => dispatch(fast360Actions.resetFollowups()),
  setSelectedVendor: data => dispatch(fast360Actions.setSelectedVendor(data)),
  setSelectedProduct: data => dispatch(fast360Actions.selectedProduct(data)),
  setSelectedSubProduct: data => dispatch(fast360Actions.selectedSubProduct(data)),
  updateTaskData: (data) => dispatch(fast360Actions.updateTaskData(data)),
  updatedStatusData: (data) => dispatch(fast360Actions.updatedStatusData(data)),
  setSelectedType: (data) => dispatch(fast360Actions.setSelectedType(data)),
  setInputFields: data => dispatch(fast360Actions.setInputFields(data)),
  setProductBackUp: (data) => dispatch(fast360Actions.productBackUp(data)),
  setSubProductBackUp: (data) => dispatch(fast360Actions.subProductBackUp(data)),
  setLastServiceModule: data => dispatch(fast360Actions.setLastServiceModule(data)),
  setLastSubServiceModule: data => dispatch(fast360Actions.setLastSubServiceModule(data)),
  setStatusBackup: (data) => dispatch(fast360Actions.statusBackUp(data)),
  setMilestoneBackUp: (data) => dispatch(fast360Actions.mileStoneBackUp(data)),
  setTaskBackUp: (data) => dispatch(fast360Actions.taskBackUp(data)),
  setSelectedFilterType: (data) => dispatch(fast360Actions.setSelectedFilterType(data)),
  updateIsLogout: (data) => dispatch(fast360Actions.updateIsLogout(data)),
});

User.propTypes = {
  floating: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
