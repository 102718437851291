import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import {
  mcnsUsersType,
  adjustersType,
  nurseAuditsType,
  codeReviewsType,
} from '../utils/constantUtils';

import CorrectionBill from './CorrectionBill';
import AgreeBill from './AgreeBill';
import AgreeBillMoreInfo from './AgreeBill';
import ModifyBill from './ModifyBill';
import SummaryCountContainer from './SummaryCountContainer';

const AllButtons = ({
  disabled,
  unpendDisabled,
  handleAgreeButtonClick,
  handleDisagreeButtonClick,
  handlePendButtonClick,
  handleUnpendButtonClick,
  handleModifyButtonClick,
  reviewType,
}) => {
  switch (reviewType) {
    case adjustersType:
      return (
        <AdjusterTypeButtons
          disabled={disabled}
          unpendDisabled={unpendDisabled}
          handleAgreeButtonClick={handleAgreeButtonClick}
          handleDisagreeButtonClick={handleDisagreeButtonClick}
          handlePendButtonClick={handlePendButtonClick}
          handleUnpendButtonClick={handleUnpendButtonClick}
        />
      );
    case mcnsUsersType:
      return (
        <McnsTypeButtons
          disabled={disabled}
          unpendDisabled={unpendDisabled}
          handleAgreeButtonClick={handleAgreeButtonClick}
          handleDisagreeButtonClick={handleDisagreeButtonClick}
          handlePendButtonClick={handlePendButtonClick}
          handleUnpendButtonClick={handleUnpendButtonClick}
        />
      );
    case nurseAuditsType:
      return (
        <NurseAuditsTypeButtons
          disabled={disabled}
          handleAgreeButtonClick={handleAgreeButtonClick}
          handleModifyButtonClick={handleModifyButtonClick}
        />
      );
    case codeReviewsType:
    default:
      return (
        <CodeReviewTypeButtons
          disabled={disabled}
          handleAgreeButtonClick={handleAgreeButtonClick}
          handleModifyButtonClick={handleModifyButtonClick}
        />
      );
  }
};

const AdjusterTypeButtons = ({
  disabled,
  unpendDisabled,
  handleDisagreeButtonClick,
  handlePendButtonClick,
  handleUnpendButtonClick,
}) => (
  <Row>
    <Col lg="2">
      <Button
        type="button"
        onClick={handleUnpendButtonClick}
        disabled={unpendDisabled}
        style={{ width: '100%' }}
        color="primary"
      >
        UNPEND
      </Button>
    </Col>
    <Col>
      <Button
        type="button"
        onClick={handlePendButtonClick}
        disabled={disabled}
        style={{ width: '100%' }}
        color="primary"
      >
        PEND
      </Button>
    </Col>
    <Col>
      <Button
        type="button"
        onClick={handleDisagreeButtonClick}
        disabled={disabled}
        style={{ width: '100%' }}
        color="danger"
      >
        DISAGREE
      </Button>
    </Col>
    <Col>
      <CorrectionBill disabled={disabled} />
    </Col>
    <Col>
      <AgreeBill disabled={disabled} />
    </Col>
  </Row>
);

const McnsTypeButtons = ({
  disabled,
  unpendDisabled,
  handleDisagreeButtonClick,
  handlePendButtonClick,
  handleUnpendButtonClick,
}) => (
  //Correction button show in GUI but always disable
  <Row>
    <Col lg="2">
      <Button
        type="button"
        onClick={handleUnpendButtonClick}
        disabled={unpendDisabled}
        style={{ width: '100%' }}
        color="primary"
      >
        UNPEND
      </Button>
    </Col>
    <Col>
      <Button
        type="button"
        onClick={handlePendButtonClick}
        disabled={disabled}
        style={{ width: '100%' }}
        color="primary"
      >
        PEND
      </Button>
    </Col>
    <Col>
      <Button
        type="button"
        onClick={handleDisagreeButtonClick}
        disabled={disabled}
        style={{ width: '100%' }}
        color="danger"
      >
        DISAGREE
      </Button>
    </Col>
    <Col>
      <CorrectionBill disabled={true} />
    </Col>
    <Col>
      <AgreeBill disabled={disabled} />
    </Col>
  </Row>
);

const NurseAuditsTypeButtons = ({ disabled }) => (
  //Correction button show in GUI but always disable
  <Row>
    <Col lg="2"></Col>
    <Col></Col>
    <Col>
      <ModifyBill disabled={disabled} />
    </Col>
    <Col>
      <CorrectionBill disabled={true} />
    </Col>
    <Col>
      <AgreeBill disabled={disabled} />
    </Col>
  </Row>
);

const CodeReviewTypeButtons = ({ disabled }) => (
  //Correction button show in GUI but always disable
  <Row>
    <Col lg="2"></Col>
    <Col></Col>
    <Col>
      <ModifyBill disabled={disabled} />
    </Col>
    <Col>
      <CorrectionBill disabled={true} />
    </Col>
    <Col>
      <AgreeBill disabled={disabled} />
    </Col>
  </Row>
);

const ApproveBillsBottomNav = ({
  selectedBills,
  bills,
  reviewType,
  unpendDisabled,
  handleDisagreeButtonClick,
  handlePendButtonClick,
  handleUnpendButtonClick,
}) => {
  return (
    <Row>
      <Col lg="6">
        <SummaryCountContainer
          bills={bills}
          selectedBills={selectedBills}
          reviewType={reviewType}
        />
      </Col>
      <Col lg="6">
        <AllButtons
          disabled={selectedBills.length < 1}
          unpendDisabled={unpendDisabled}
          handleDisagreeButtonClick={handleDisagreeButtonClick}
          handlePendButtonClick={handlePendButtonClick}
          handleUnpendButtonClick={handleUnpendButtonClick}
          reviewType={reviewType}
        />
      </Col>
    </Row>
  );
};

ApproveBillsBottomNav.propTypes = {
  handleDisagreeButtonClick: PropTypes.func.isRequired,
  handlePendButtonClick: PropTypes.func.isRequired,
  handleUnpendButtonClick: PropTypes.func.isRequired,
  bills: PropTypes.array.isRequired,
  selectedBills: PropTypes.array.isRequired,
  reviewType: PropTypes.string.isRequired,
  unpendDisabled: PropTypes.bool.isRequired,
};

export default ApproveBillsBottomNav;
