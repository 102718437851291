import 'core-js/stable';
import reducerRegistry from 'shared/store/reducerRegistry';

import { MODULE_NAME } from './constants';
import reducer from './reducers';

reducerRegistry.register(MODULE_NAME, reducer);

export { default as fast360Constants } from './constants';
export { default as fast360Operations } from './operations';
export { default as fast360Selectors } from './selectors';
export { default as fast360Actions } from './actions';

export default reducer;
