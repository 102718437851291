import React, { Component } from 'react';
import { Row, Card, Col, FormGroup, Input, Label, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { BILL_REVIEW_MODULE } from '../store/constants';
import actions from '../store/actions';
import { authSelectors } from 'modules/auth/store';

const columns = ['Profile', 'Selected', 'Default', 'Site Tops'];

class UserBillReview extends Component {
  render() {
    return (
      <Card body>
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label for="initials">Initials</Label>
              <Input
                type="text"
                name="initials"
                id="initials"
                value={this.props.userBillReview.initials}
                onChange={this.props.onUpdateInput}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="accessLevel">Access Level</Label>
              <Input
                type="select"
                name="accessLevel"
                id="accessLevel"
                value={this.props.userBillReview.accessLevel}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Choose access level</option>
                {this.props.userBillReview.accessList.map((access, index) => (
                  <option key={index} value={access.id}>
                    {access.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="processingCenter">Processing Center</Label>
              <Input
                type="select"
                name="processingCenter"
                id="processingCenter"
                value={this.props.userBillReview.processingCenter}
                onChange={this.props.onUpdateInput}
                className="dropdown"
              >
                <option value="default">Choose a center</option>
                {this.props.userBillReview.centerList.map((center, index) => (
                  <option key={index} value={center.id}>
                    {center.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Table size="sm" striped={true}>
              <thead className="thead-dark">
                <tr>
                  {columns.map((column, index) => {
                    return <th key={index}>{column}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.userBillReview.profiles.map((profile, index) => (
                  <tr key={index}>
                    <td>{profile.id}</td>
                    <td>
                      <Input
                        type="checkbox"
                        name={profile.id + '_selected'}
                        checked={profile.selected}
                        onChange={this.props.onUpdateProfile}
                        disabled={checkProfileDisabled(profile.id, this.props.userBillReview.userId)}
                        
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name={profile.id + '_default'}
                        checked={profile.default}
                        onChange={this.props.onUpdateProfile}
                        disabled={checkProfileDisabled(profile.id, this.props.userBillReview.userId)}
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        name={profile.id + '_siteTops'}
                        value={profile.siteTops}
                        onChange={this.props.onUpdateProfile}
                        disabled={checkProfileDisabled(profile.id, this.props.userBillReview.userId)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    );
  }
}

const checkProfileDisabled = (profileId, userId) => {
  if(profileId === "slp_cov" && userId !== 2886){//only this user has control of who has access to slp_cov
    return true;
  }
  return false;
}

const mapStateToProps = state => {
  const currentUser = authSelectors.getUser(state);
  return {
    userBillReview: {
      userId: currentUser.id,
      initials: state.ADMIN.user.userBillReview.initials,
      accessLevel: state.ADMIN.user.userBillReview.accessLevel,
      processingCenter: state.ADMIN.user.userBillReview.processingCenter,
      profiles: state.ADMIN.user.userBillReview.profiles,
      accessList: state.ADMIN.user.userBillReview.accessList,
      centerList: state.ADMIN.user.userBillReview.centerList,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: event =>
    dispatch(actions.updateObjectArray(event, BILL_REVIEW_MODULE, 'profiles')),
  onUpdateInput: event =>
    dispatch(actions.updateInput(event, BILL_REVIEW_MODULE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBillReview);
