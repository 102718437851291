import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Input, Label, Form, Button } from 'reactstrap';
import { useAuth } from '../hooks/useAuth';
import UsernameEmptyModal from './UsernameEmptyModal'
import PasswordResetModal from './PasswordResetModal';

const ForgotPasswordForm = ({ setNewUsername, newUsername, setLoginState, setPassword}) => {
  const { forgotPassword, resetPasswordResult } = useAuth();
  const [showUserEmptyModal, setShowUserEmptyModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const userId = 0;

  const isUsernameEmpty = () => {
    if (newUsername.length === 0) {
      setShowUserEmptyModal(true)
      return true;
    }
    else {
      return false;
    }
  }

  useEffect(()=>{
    if(resetPasswordResult == 1 || resetPasswordResult == 2 || resetPasswordResult == 3){
      setShowPasswordResetModal(true)
    }

  },[resetPasswordResult])

  return (
    <>
      <Form
        onSubmit={async e => {
          e.preventDefault();
          {
          isUsernameEmpty() === false &&
            await forgotPassword(newUsername, userId);
          }
        }
        }
        className="york-form reset-password-form"
      >
        <Col lg="12" className="marginTop25">
          <FormGroup className="group full">
            <Label for="newUsername">Username</Label>
            <Input
              type="text"
              name="newUsername"
              id="username"
              onChange={e => setNewUsername(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg="12">
          <Row className="action">
            <Col lg="6" xs="6">
              <button className="link-underline orange" type="submit">
                Reset
            </button>
            </Col>
          </Row>
        </Col>
      </Form>
      <UsernameEmptyModal isOpen={showUserEmptyModal} onToggle={() => { setShowUserEmptyModal(false) }} />
      {showPasswordResetModal && 
        <PasswordResetModal
          isOpen={showPasswordResetModal}
          onToggle={()=>setShowPasswordResetModal(!showPasswordResetModal)}
          newUsername={newUsername}
          setLoginState={setLoginState}
          setPassword={setPassword}
        />
      }
    </>
  );
};

export default ForgotPasswordForm;
