import React from 'react';

import FindUsersToolbar from '../components/FindUsersToolbar';
import UserResultsTable from '../components/UserResultsTable';

const UserSearch = () => {
  return (
    <div>
      <FindUsersToolbar />
      <UserResultsTable />
    </div>
  );
};

export default UserSearch;
