import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import { authSelectors } from 'modules/auth/store';

import '../assets/static/task-list.scss';

import {
  billOperationsActions,
  billOperationsOperations,
  billOperationsSelectors,
} from '../store';

import { approveBills, recentActivity, qaBills } from '../utils/constantUtils';

import ApproveBillContainer from './ApproveBillContainer';
import RecentActivityContainer from './RecentActivityContainer';
import QABillContainer from './QABillContainer';

class TaskListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: approveBills,
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.props.updateFindBillsFromRecentActivity(tab)
      if (
        tab === recentActivity &&
        this.props.recentActivityAdjusters.length < 1
      ) {
        return;
      }

      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const isSlpUser = this.props.user.slp || false;

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === approveBills,
              })}
              onClick={() => {
                this.toggle(approveBills);
              }}
            >
              APPROVE BILLS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === recentActivity,
              })}
              onClick={() => {
                this.toggle(recentActivity);
              }}
            >
              RECENT ACTIVITY
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === qaBills,
              })}
              onClick={() => {
                this.toggle(qaBills);
              }}
            >
              QA BILLS
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={this.state.activeTab}
          style={{ paddingTop: '10px' }}
        >
          <TabPane tabId={approveBills}>
            <ApproveBillContainer
              currentUserId={this.props.currentUserId}
              isSlpUser={isSlpUser}
              slpUserId={this.props.slpUserId}
              profile={this.props.profile}
            />
          </TabPane>
          <TabPane tabId={recentActivity}>
            <RecentActivityContainer
              currentUserId={this.props.currentUserId}
              isSlpUser={isSlpUser}
              profile={this.props.profile}
              manager={this.props.manager}
              currentSelection={this.props.currentSelection}
            />
          </TabPane>
          <TabPane tabId={qaBills}>
            <QABillContainer
              currentUserId={this.props.currentUserId}
              isSlpUser={isSlpUser}
              profile={this.props.profile}
              manager={this.props.manager}
            />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

TaskListContainer.propTypes = {
  getInitialData: PropTypes.func.isRequired,
  getGridSettings: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  manager: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  slpUserId: PropTypes.number.isRequired,
  recentActivityAdjusters: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const initialData = billOperationsSelectors.getInitialData(state);
  const billReviewOperation = billOperationsSelectors.getBillReview(state).updateFindBillsFromRecentActivity;
  const user = authSelectors.getUser(state);
  const recentActivityState = billOperationsSelectors.getRecentActivityState(
    state
  );
  return {
    profile: initialData.currentProfile,
    manager: initialData.manager,
    user: authSelectors.getUser(state),
    slpUserId: initialData.slpUserId,
    currentUserId: user.id || -1,
    recentActivityAdjusters: recentActivityState.adjusters,
    currentSelection: billReviewOperation
  };
};

const mapDispatchToProps = dispatch => ({
  getInitialData: data =>
    dispatch(billOperationsOperations.getTaskListInitialData(data)),
  getGridSettings: data =>
    dispatch(billOperationsOperations.getGridSettings(data)),
  getQABills: data => dispatch(billOperationsOperations.getQABills(data)),
  updateGroupBySelection: groupBy =>
    dispatch(billOperationsActions.updateQABillGroupBy(groupBy)),
  updateFindBillsFromRecentActivity: data =>
    dispatch(billOperationsActions.updateFindBillsFromRecentActivity(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(TaskListContainer);
