import PropTypes from 'prop-types';
import React from 'react';
import { Button, Row, FormGroup, Col, Input } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';

import NewGroupTable from './NewGroupTable';
import NewGroupAddressTable from './NewGroupAddressTable';

const NewGroupAdvanceModal = props => {
  const getBody = () => (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Input
              name="advanceModalGroupName"
              value={props.groupName}
              onChange={props.onInputChangeHandler}
              placeholder="Group Name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Button
              style={{ width: '100%' }}
              onClick={props.onCreateNewGroupClickHandler}
              type="button"
            >
              Create New Record
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {props.duplicateExpertGroups.length > 0 && (
        <Row>
          <Col lg="12">
            <div className="red-text">Potential duplicate record(s) found!</div>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 20 }}>
        <Col lg="12">
          <span>OR enter existing record below….</span>
        </Col>
        <Col lg="12">
          <NewGroupTable
            data={props.duplicateExpertGroups}
            onGroupClickHandler={props.onGroupClickHandler}
          />
        </Col>
        <Col lg="12">
          <NewGroupAddressTable data={props.selectedExpertGroupAddresses} />
        </Col>
      </Row>
    </>
  );

  const getFooter = () => (
    <>
      <Button type="button" color="danger" onClick={props.modalToggle}>
        Cancel
      </Button>
    </>
  );

  return (
    <>
      <PopupModal
        content={getBody()}
        title="Add a new Expert Group"
        externalToggle={props.modalToggle}
        isOpen={props.modalOpen}
        footerContent={getFooter()}
        onClosed={props.resetModal}
        size="lg"
      />
    </>
  );
};

NewGroupAdvanceModal.propTypes = {
  duplicateExpertGroups: PropTypes.array.isRequired,
  selectedExpertGroupAddresses: PropTypes.array.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  modalToggle: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onGroupClickHandler: PropTypes.func.isRequired,
  onCreateNewGroupClickHandler: PropTypes.func.isRequired,
};

export default NewGroupAdvanceModal;
