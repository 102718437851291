import { useAuth } from 'modules/auth/hooks/useAuth';
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Progress, Row, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { fast360Operations } from '../store/index';
import services from '../services/service';


const item = (heading, callback, value, max = null) => (
  <ListGroupItem onClick={callback}>
    <ListGroupItemHeading >
      <Row className="justify-content-md-center">{heading}</Row>  
    </ListGroupItemHeading>
      <Row className="justify-content-md-center bold-text">{value}</Row>
      {max && <Progress value={value} max={max} color="danger" className="mb-4 mt-2" />}
  </ListGroupItem>
);

const DashboardUser = ({userData: {total, ooc, rush, newServices, followUps, userId, zebraId}}) => {  const dispatch = useDispatch();
  const username = useAuth().user.fullname;
  const user = { zebraId, id:userId, username};
  const [newServiceCount, setNewServiceCount] = useState(null);

  useEffect(() => {
    services.getUsersWorkList(user, zebraId).then(responseData => {
      const newOrderTime = new Date().getTime() - (1000 * 60 * 60 * 24);
      const newOrders = responseData.filter(o => new Date(o.currentTaskCreateDate).getTime() >= newOrderTime);
      setNewServiceCount(newOrders.length);
    });
  
  }, []);

  
  const getMyTotOrders = () => {
    dispatch(fast360Operations.getMyTotOrders(user, zebraId));
  }
  
  const getMyOOC = () => {
    dispatch(fast360Operations.getUsersOOC(user, zebraId));
  }
  
  const getMyRush = () => {
    dispatch(fast360Operations.getMyRush(user));
  }
  
  const getMyNewServices = () => {
    dispatch(fast360Operations.getMyNewOrders(user, zebraId));
  }
  
  const getMyFollowups = () => {
    dispatch(fast360Operations.getMyFollowups(user, zebraId));
  }

  return (
  <ListGroup>
    {item("Total Orders", getMyTotOrders, total)}
    {item("My OOC", getMyOOC, ooc, total)}
    {item("My Rush/High-Priority", getMyRush, rush, total)}
    {item("My New Orders", getMyNewServices, newServiceCount)}
    {item("My Follow Ups", getMyFollowups, followUps)}
  </ListGroup>
  );
}


export default DashboardUser;
