import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { assign, isEmpty } from 'lodash';
import { authSelectors } from 'modules/auth/store';
import AppInput from 'shared/components/input/AppInput';
import AppTooltip from 'shared/components/tooltip/AppTooltip';
import AppDateInput from 'shared/components/input/AppDateInput';
import DashboardButton from './DashboardButton';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  fast360Operations,
  fast360Selectors,
  fast360Actions,
} from '../store/index';

const locationSame = '1';
const locationDiff = '0';
const Fast360MSE = ({ mseCategory, selectedService, userId, zipList,
  claimantAddress,
  getZipList,
  fromExt,
  msePO,
  updatePostExternal,
  resetZipList,
  selectedMod,
  updatePatientZip,
  patientInfo,
  dataFromWorklist,
  isFromLanding,
  requiredServiceData }) => {
  const [selectedItem, setSelectedItem] = useState(selectedService);
  useEffect(() => {
    requiredServiceData(selectedItem);
  }, [selectedItem]);
  const [poNum, setPoNum] = useState('');
  useEffect(() => {
    if (fromExt && (selectedMod === 'MSE' || selectedMod === 'TEN' || selectedMod === 'OPP')) {
      setPoNum(msePO.UniquePortalAuthCode)
      selectedService.poNumber = msePO.UniquePortalAuthCode;
      if (updatePostExternal.length > 0) {
        const itemReq = updatePostExternal[0].ItemRequested;
        if (itemReq == 'MSE') selectedService.categoryId = 893;
        if (itemReq == 'DME') selectedService.categoryId = 894;
        if (itemReq == 'OPP') selectedService.categoryId = 896;
      }
    }
  }, [msePO.UniquePortalAuthCode]);

  useEffect(() => {
    if (selectedService || isFromLanding || (!isEmpty(dataFromWorklist))) {
      if (isFromLanding || (!isEmpty(dataFromWorklist))) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.categoryId,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          hhcService: selectedService?.subService,
          serviceTypeId: selectedService?.categoryId,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService, isFromLanding, dataFromWorklist]);

  useEffect(() => {
    if (
      selectedService?.dos ||
      selectedService?.subService ||
      isFromLanding ||
      !isEmpty(dataFromWorklist)
    ) {
      if (isFromLanding || !isEmpty(dataFromWorklist)) {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.categoryId,
          module: selectedService?.module,
        });
      } else {
        updatePatientZip({
          ...patientInfo,
          serviceId: selectedService?.serviceId,
          audZip: selectedService?.dos,
          serviceTypeId: selectedService?.categoryId,
          module: selectedService?.module,
        });
      }
    }
  }, [selectedService?.dos, isFromLanding, dataFromWorklist]);

  const onChangeHandlerMseType = e => {
    const target = e.target;
    const name = target.options[target.selectedIndex].text;
    selectedService.subService = name;
    selectedService.categoryId = target.value;
    setSelectedItem({ selectedService });
  };

  const onChangeHandler = object => {
    if (object.key === 'dos') {
      let monDOS = object.value.getMonth() + 1;
      selectedService.dos =
        monDOS +
        '/' + object.value.getDate() +
        '/' +
        object.value.getFullYear();
    }

    setSelectedItem({ selectedService });
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'poNumber') {
      selectedService.poNumber = value;
      // setPoNum(value)
    }

    if (name === 'recurring') {
      selectedService.recurringOrder =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'locationAddress') {
      selectedService.deliverAddress.street1 = value;
    }
    if (name === 'rush') {
      selectedService.rush =
        target.type === 'checkbox' ? target.checked : target.value;
    }
    if (name === 'highPriority') {
      selectedService.highPriority =
        target.type === 'checkbox' ? target.checked : target.value;
    }

    setSelectedItem({ selectedService });
  };

  const onTabRadioChangeHandler = event => {
    selectedService.locationSame = event.target.value;
    setSelectedItem({ selectedService });
    if(!selectedService.deliverAddress){
      selectedService.deliverAddress = {zipCode: {}};
    }
    if (selectedService.locationSame === '1') {
      if (claimantAddress != undefined) {
        selectedService.deliverAddress.street1 = claimantAddress.street1;
        selectedService.deliverAddressId = claimantAddress.id;
        selectedService.deliverAddress.zipCode.id = claimantAddress.zipCode.id;
        selectedService.deliverAddress.zipCode.city = claimantAddress.zipCode.city;
        selectedService.deliverAddress.zipCode.state = claimantAddress.zipCode.state;
        selectedService.deliverAddress.zipCode.county = claimantAddress.zipCode.county;
        selectedService.deliverAddress.zipCode.zip = claimantAddress.zipCode.zip;
        selectedService.deliverAddress.zipCodeId = claimantAddress.zipCodeId;
        // selectedService.deliverAddress.zipId = claimantAddress.zipCodeId;
        selectedService.deliverAddress.zipCode.timeZone = claimantAddress.timezone;
        selectedService.deliverAddSameAsClmnt = selectedService?.locationSame === '1' ? true : false;
      }
      else {
        if (isFromLanding) {
          selectedService.deliverAddress.street1 = dataFromWorklist.claim?.claimant?.address?.street1;
          selectedService.deliverAddress.zipCode.city = dataFromWorklist.claim?.claimant?.address?.zipCode?.city;
          selectedService.deliverAddress.zipCode.state = dataFromWorklist.claim?.claimant?.address?.zipCode?.state;
          selectedService.deliverAddress.zipCode.zip = dataFromWorklist.claim?.claimant?.address?.zipCode?.zip;
          selectedService.deliverAddress.zipCodeId = dataFromWorklist.claim?.claimant?.address?.zipCodeId;
        }
      }
    } else {
      selectedService.deliverAddressId = 0;
      selectedService.deliverAddress.street1 = '';
      selectedService.deliverAddress.zipCode.id = '';
      selectedService.deliverAddress.zipCode.city = '';
      selectedService.deliverAddress.zipCode.state = '';
      selectedService.deliverAddress.zipCode.county = '';
      selectedService.deliverAddress.zipCode.zip = '';
      selectedService.deliverAddress.zipCodeId = '';
      //selectedService.deliverAddress.zipId = '';
      selectedService.deliverAddress.zipCode.timeZone = '';
      selectedService.deliverAddSameAsClmnt = selectedService?.locationSame === '1' ? true : false;
    }
    setSelectedItem({ selectedService });
  };

  const handleFormFields = (event, id, actionType) => {
    const selectedZip = zipList.find(obj => obj.code_id === event.value);
    if (selectedZip != null) {
      selectedService.deliverAddress.zipCode.id = selectedZip.code_id;
      selectedService.deliverAddress.zipCode.city = selectedZip.chr_city;
      selectedService.deliverAddress.zipCode.state = selectedZip.chr_state;
      selectedService.deliverAddress.zipCode.county = selectedZip.chr_county;
      selectedService.deliverAddress.zipCode.zip = selectedZip.chr_zipcode;
      selectedService.deliverAddress.zipCodeId = selectedZip.code_id;
      //selectedService.deliverAddress.zipId = selectedZip.code_id;
      selectedService.deliverAddress.zipCode.timeZone = selectedZip.chr_timezone;
      setSelectedItem({ selectedService });
    }
    resetZipList();
  };

  const handleTypeAheadSearch = event => {
    event.persist();
    if (
      event.target.value.length > 2 &&
      (event.keyCode === 13 || event.keyCode === 9)
    ) {
      const params = {
        userId: userId,
        zipCode: event.target.value,
      };
      getZipList(params);
    } else if (event.target.value.length > 0 && zipList.length) {
      resetZipList();
    }
  };

  const locationField = () => {
    if (selectedService?.categoryId !== undefined) {
      if (
        (selectedService?.categoryId === '1199')
      ) {
        return '';
      } else {
        return (
          <>
            <Row>
              <Col sm="8">
                <FormGroup className="form-check-inline">
                  <CustomInput
                    type="radio"
                    label="yes"
                    id="locationSameRadio"
                    name="tabRadio"
                    value={locationSame}
                    checked={selectedService?.locationSame === locationSame}
                    onChange={onTabRadioChangeHandler}
                  />
                </FormGroup>
                <FormGroup className="form-check-inline">
                  <CustomInput
                    type="radio"
                    id="locationDiffRadio"
                    name="tabRadio"
                    label="no"
                    value={locationDiff}
                    checked={selectedService?.locationSame === locationDiff}
                    onChange={onTabRadioChangeHandler}
                  />
                </FormGroup>
                <Label>Delivery Address Same as Patient Address</Label>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <Label>Location: </Label>
                <Input
                  name="locationAddress"
                  onChange={handleInputChange}
                  value={selectedService?.deliverAddress?.street1 || ''}
                  readOnly={
                    (selectedService?.locationSame === '1' && claimantAddress != undefined)
                  }
                />
              </Col>
              <Col sm="5">
                <Row>
                  <Label>City/State/Zip: </Label>
                </Row>
                <Row>
                  <Label>
                    {selectedService?.deliverAddress?.zipCode?.city}{' '}
                    {selectedService?.deliverAddress?.zipCode?.state},{' '}
                    {selectedService?.deliverAddress?.zipCode?.zip}
                  </Label>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <Label for="locZip">Lookup Zip</Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  onChange={(e, actionType) =>
                    handleFormFields(e, 'locZip', actionType)
                  }
                  placeholder=""
                  menuPlacement="bottom"
                  onKeyDown={e => handleTypeAheadSearch(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  menuIsOpen={zipList.length}
                  value={selectedService?.deliverAddress?.zipCode?.zip}
                  closeMenuOnSelect={true}
                  options={
                    zipList.length
                      ? zipList.map(obj =>
                        assign(obj, {
                          label: obj.zip_type_with_county,
                          value: obj.code_id,
                        })
                      )
                      : []
                  }
                  name="locZip"
                  id="locZip"
                  isDisabled={
                    (selectedService?.locationSame === '1' && claimantAddress != undefined)
                  }
                />
              </Col>
            </Row>
          </>
        )
      }
    }
  }

  return (
    <>
      <FormGroup>
        <br></br>
        <Row>
          <Col sm="3">
            <Label>Sub Service: </Label>
            <AppTooltip text={'Sub Service is a required field'} id={'subService'}>
              <Input
                type="select"
                className="dropdown"
                onChange={e => onChangeHandlerMseType(e)}
                placeholder="Choose..."
                value={selectedService?.categoryId || ''}
               
                id="subService"

              >
                <option value="default">Choose...</option>
                {mseCategory.map((id, index) => (
                  <option key={index} value={id.PK_listelement_id}>
                    {id.value}
                  </option>
                ))}
              </Input>
            </AppTooltip>
          </Col>
          <Col sm="3">
            <Label>PO Number: </Label>
            <Input
              id="poNumber"
              name="poNumber"
              onChange={handleInputChange}
              // value={poNum || ''}
              value={selectedService?.poNumber || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="1"></Col>
          <Col sm="2">
            <br></br>
            <Label check>
              <Input
                name="rush"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.rush}
              />{' '}
              Rush Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                type="checkbox"
                name="recurring"
                onChange={handleInputChange}
                checked={selectedService?.recurringOrder}
              />{' '}
              Recurring Order
            </Label>
            <Col xs="1"></Col>
            <Label check>
              <Input
                name="highPriority"
                type="checkbox"
                onChange={handleInputChange}
                checked={selectedService?.highPriority}
              />{' '}
              High Priority
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Label>Date Of Service: </Label>
            <AppTooltip text={'Date of Service is a required field'} id={'dos'}>
              <AppDateInput
                id="dos"
                onChange={value => onChangeHandler({ key: 'dos', value })}
                value={selectedService?.dos || ''}
              />
            </AppTooltip>
          </Col>
          <Col xs="1"></Col>
        </Row>
        {locationField()}
      </FormGroup>
    </>
  );
};

Fast360MSE.propTypes = {
  modificationCategory: PropTypes.array,
  alert: PropTypes.func,
};

const mapStateToProps = state => {
  const user = authSelectors.getUser(state);
  const initialData = fast360Selectors.getFast360InitialData(state);
  const fast360Info = fast360Selectors.getFast360Info(state);
  var claimantAddress;

  if (fast360Info.updatedClaimInfo.claim != undefined) {
    if (fast360Info.updatedClaimInfo.claim.claimant != undefined) {
      if (fast360Info.updatedClaimInfo.claim.claimant.address != undefined) {
        claimantAddress = fast360Info.updatedClaimInfo.claim.claimant.address
      } else {
        claimantAddress = undefined;
      }
    } else {
      claimantAddress = undefined;
    }
  } else {
    claimantAddress = undefined;
  }
  return {
    mseCategory: initialData.mseCategory,
    claimantAddress: claimantAddress,
    zipList: fast360Info.zipList,
    userId: user.id,
    patientInfo: fast360Info.patientZip,
  };
};

const mapDispatchToProps = dispatch => ({
  getZipList: data => dispatch(fast360Operations.getZipList(data)),
  resetZipList: () => dispatch(fast360Actions.resetZipList()),
  updatePatientZip: data => dispatch(fast360Actions.updatePatientZip(data)),
  requiredServiceData: data => dispatch(fast360Actions.requiredServiceData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fast360MSE);
