import {
    Modal,
    ModalHeader, ModalBody
} from 'reactstrap';
import { Col, Row, Form, FormGroup, Button } from 'reactstrap';
import AppInput from 'shared/components/input/AppInput';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { commonOperations } from 'common/store';
import {
    billOperationsActions,
    billOperationsOperations,
    billOperationsSelectors,
} from "../store";
import { connect } from 'react-redux';
import AppTable from 'shared/components/table/AppTable';
import { sortByType } from 'shared/utils/shareUtils';
import { authSelectors } from 'modules/auth/store';

const SearchPPOModal = ({
    isOpen,
    onClose,
    searchPPOData,
    clientsPPOData,
    resetSearchPPOData,
    getSearchPPOData,
    getClientsPPOData,
    resetClientsPPOData,
    userId,
    profile,
    alert
}) => {
    const [searchFields, setSearchFields] = useState({})
    const toggle = () => {
        resetFormData();
        onClose();
    };
    const onChangeHandler = (id, value) => {
        setSearchFields({ ...searchFields, [id]: value })
    }
    const resetFormData = () => {
        setSearchFields({});
        resetSearchPPOData();
        resetClientsPPOData();
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        const params = {
            ...searchFields,
            userId,
            profile
        }
        const result = Object.values(searchFields).every(value => value === "")
        if (Object.keys(searchFields).length === 0 || result) {
            alert('You must provide a TIN and Bill ID in order to search.');
            return;
        }
        resetSearchPPOData();
        resetClientsPPOData();
        if (searchFields.providerTIN && searchFields.providerTIN !== "") {
            getSearchPPOData(params);
        }

        if (searchFields.billID && searchFields.billID !== "") {
            getClientsPPOData(params);
            getSearchPPOData(params);
        }


    }

    const sortColumn = (item1, item2, order, field) => {
        const field1 = item1[field] || {};
        const field2 = item2[field] || {};
        return sortByType(field1[field], field2[field], order);
    }

    const getSerachPPOAppTable = () => {
        return (
            <div className="client-ppo-table">
                <AppTable
                    modalHeight={150}
                    columns={[
                        {
                            header: 'Network',
                            field: 'network'
                        },
                        {
                            header: 'Bill Type',
                            field: 'billType'
                        },
                        {
                            header: 'Bills',
                            field: 'billCount'
                        },
                        {
                            header: 'Charges',
                            field: 'charges'
                        },
                        {
                            header: 'FS %',
                            field: 'feeSchedulePercent'
                        },
                        {
                            header: 'Audit %',
                            field: 'auditPercent'
                        },
                        {
                            header: 'Avg Save %',
                            field: 'averageSavings'
                        },
                        {
                            header: 'Last Hit',
                            field: 'lastHit'
                        },
                    ]}
                    data={searchPPOData}
                    autoPagination={true}
                    sortAble={true}
                    resizable={true}
                    sortImmediately={false}
                    key="search-ppo-table"
                />
            </div>
        )
    }

    const getClientsPPOAppTable = () => {
        return (
            <>
                <span>Clients PPO setup for Bill ID:</span>
                <div className="client-ppo-table">
                    <AppTable
                        columns={[
                            {
                                header: 'PPO Name',
                                field: 'name'
                            },
                            {
                                header: 'LOB',
                                field: 'lob',
                                render: item => (item.lob || {}).lob || '',
                                sortFunction: (item1, item2, order) =>
                                    sortColumn(item1, item2, order, 'lob'),
                            },
                            {
                                header: 'Rank',
                                field: 'rank'
                            },
                            {
                                header: 'Effective',
                                field: 'dateRange',
                                render: item => (item.dateRange || {}).effDate || '',
                                sortAble: false
                            },
                            {
                                header: 'Term Date',
                                field: 'dateRange',
                                render: item => (item.dateRange || {}).termDate || '',
                                sortAble: false
                            }
                        ]}
                        data={clientsPPOData}
                        autoPagination={true}
                        sortAble={true}
                        resizable={true}
                        sortImmediately={false}
                        key="clients-ppo-table"
                    />
                </div>
            </>
        )
    }

    return (
        <>
            <Modal size="xl" isOpen={isOpen}>
                <ModalHeader toggle={toggle}>PPO HISTORY SEARCH</ModalHeader>
                <ModalBody>
                    <Form onSubmit={onFormSubmit}>
                        <Row form>
                            <Col>
                                <FormGroup>
                                    <AppInput
                                        changeHandler={onChangeHandler}
                                        placeholder="Provider TIN"
                                        id="providerTIN"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <AppInput
                                        changeHandler={onChangeHandler}
                                        placeholder="Bill Id"
                                        id="billID"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button type="submit">FIND</Button>
                            </Col>
                            <span>
                                Results for last 180 days
               </span>
                        </Row>
                        <Row>
                            <Col lg="12">
                                {getSerachPPOAppTable()}
                            </Col>
                            <Col lg="12">
                                {(clientsPPOData.length > 0) && getClientsPPOAppTable()}
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

SearchPPOModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    searchPPOData: PropTypes.array,
    clientsPPOData: PropTypes.array,
    getSearchPPOData: PropTypes.func,
    getClientsPPOData: PropTypes.func,
    resetClientsPPOData: PropTypes.func,
    resetSearchPPOData: PropTypes.func,
    alert: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    const billReviewState = billOperationsSelectors.getBillReview(state);
    const user = authSelectors.getUser(state);
    const billReviewInitialData = billOperationsSelectors.getInitialData(state);

    return {
        searchPPOData: billReviewState.searchPPOData,
        clientsPPOData: billReviewState.clientsPPOData,
        userId: user && user.user_id,
        profile: billReviewInitialData.currentProfile
    };
};

const mapDispatchToProps = dispatch => ({
    getSearchPPOData: data =>
        dispatch(billOperationsOperations.getSearchPPOData(data)),
    resetSearchPPOData: () =>
        dispatch(billOperationsActions.resetSearchPPOData()),
    getClientsPPOData: data =>
        dispatch(billOperationsOperations.getClientsPPOData(data)),
    resetClientsPPOData: () =>
        dispatch(billOperationsActions.resetClientsPPOData()),
    alert: msg => dispatch(commonOperations.addAlert(msg, 'danger'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPPOModal);