import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Input } from 'reactstrap';

import { commonActions } from 'common/store';
import appModules, { BILL_REVIEW_MODULE } from 'common/appModules';

import { authSelectors } from 'modules/auth/store';

import BillMaintenance from '../bill-maintenance/BillMaintenance';
import BillMaintenanceSelect from '../bill-maintenance/components/BillMaintenanceSelect';

import BillOperations from '../bill-operations/BillOperations';
import { billOperationsOperations, billOperationsSelectors } from '../bill-operations/store';

import { billReviewActions, billReviewSelectors } from './store';
import { BILL_OPERATION, BILL_MAINTENANCE } from './store/constants';

const BillModuleSelect = props => (
  <FormGroup>
    <Input
      type="select"
      name="billReviewModuleSelect"
      onChange={props.changeModule}
      value={props.currentView}
    >
      <option value={BILL_OPERATION}>Billing Operations</option>
      <option value={BILL_MAINTENANCE}>Billing Maintenance</option>
    </Input>
  </FormGroup>
);

const BillOperationNav = props => (
  <>
    <Row form>
      <BillModuleSelect
        changeModule={props.changeModule}
        currentView={props.currentView}
      />
    </Row>
  </>
);

const BillMaintenanceNav = props => (
  <Row>
    <Col lg="2">
      <BillModuleSelect
        changeModule={props.changeModule}
        currentView={props.currentView}
        isLoading={props.isLoading}
      />
    </Col>
    <Col lg="2">
      <BillMaintenanceSelect isLoading={props.isLoading} />
    </Col>
  </Row>
);

const BillReview = ({
  changeModule,
  loadBillReviewData,
  currentView,
  currentUserId,
  switchModule,
  activeCurrentProfile
}) => {
  useState(() => {
    switchModule(appModules.getModule(BILL_REVIEW_MODULE));
  });
  useEffect(() => {
    if (currentUserId) {
      setTimeout(() => {
        loadBillReviewData(currentUserId.toString());
      },2000)
   }
  }, [loadBillReviewData, activeCurrentProfile, currentUserId]);

  switch (currentView) {
    case BILL_MAINTENANCE:
      return (
        <>
          <BillMaintenanceNav
            changeModule={event => changeModule(event.target.value)}
            currentView={currentView}
          />
          <BillMaintenance />
        </>
      );
    case BILL_OPERATION:
    default:
      return (
        <>
          <BillOperationNav
            changeModule={event => changeModule(event.target.value)}
            currentView={currentView}
          />
          <BillOperations />
        </>
      );
  }
};

const mapStateToProps = state => {
  const billReview = billReviewSelectors.getBillReview(state);
  const user = authSelectors.getUser(state);
  const initialData = billOperationsSelectors.getInitialData(state);
  return {
    currentView: billReview.currentView,
    currentUserId: user && user.id,
    activeCurrentProfile: initialData.activeCurrentProfile || '',

  };
};

const mapDispatchToProps = dispatch => ({
  loadBillReviewData: userId =>
    dispatch(billOperationsOperations.loadBillReviewData(userId)),
  changeModule: module => dispatch(billReviewActions.changeModule(module)),
  switchModule: module => dispatch(commonActions.switchModule(module)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillReview);
