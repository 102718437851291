import {createSelector} from 'reselect';

import constants from './constants';

const getModule = state => state[constants.moduleName];

const sideNavMenu = createSelector(getModule, state => state.sideNavMenu);
const appError = createSelector(getModule, state => state.appError);
const appAlerts = createSelector(getModule, state => state.appAlerts);
const appLoading = createSelector(getModule, state => state.appLoading);

export default {sideNavMenu, appError, appAlerts, appLoading}
