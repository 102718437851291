import * as R from 'ramda';

import { commonActions, commonOperations } from 'common/store';

export class ServiceExecutor {
  constructor(build) {
    this.dispatch = build.dispatch;
    this.serviceCall = build.serviceCall;
    this.loading = build.loading;
    this.responseHandlers = build.responseHandlers;
    this.failureHandlers = build.failureHandlers;
  }

  execute() {
    if (this.loading) {
      this.dispatch(commonActions.setLoading());
    }
    this.serviceCall()
      .then(response =>
        R.forEach(
          responseHandler => responseHandler(response),
          this.responseHandlers
        )
      )
      .catch(error =>
        R.forEach(failureHandler => failureHandler(error), this.failureHandlers)
      )
      .finally(() => {
        if (this.loading) {
          this.dispatch(commonActions.clearLoading());
        }
      });
  }

  static Builder(dispatch) {
    return new Builder(dispatch);
  }
}

class Builder {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.loading = true;
    this.responseHandlers = [];
    this.failureHandlers = [console.error];
  }

  withServiceCall(serviceCall) {
    this.serviceCall = serviceCall;
    return this;
  }

  withLoading(loading) {
    this.loading = loading;
    return this;
  }

  withResponseHandler(responseHandler) {
    this.responseHandlers = R.append(responseHandler, this.responseHandlers);
    return this;
  }

  withFailureHandler(failureHandler) {
    this.failureHandlers = R.append(failureHandler, this.failureHandlers);
    return this;
  }

  withFailureAlert(message, level = 'danger') {
    return this.withFailureHandler(() => {
      this.dispatch(commonOperations.addAlert(message, level));
    });
  }

  withSuccessAlert(message, level = 'success') {
    return this.withResponseHandler(() => {
      this.dispatch(commonOperations.addAlert(message, level));
    });
  }

  build() {
    return new ServiceExecutor(this);
  }
}
