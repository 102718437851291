import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

// import { isUndefined } from 'lodash';


export const AdvSearchContext = createContext();

const initialState = {
    claimantFirst: '',
    claimantLast: '',
    serviceType: '',
    referralId: '',
    serviceId: '',
    referralCompany: '',
    yorkClientName: '',
    vendorName: '',
    claimNo: '',
    ncmName: '',
    ncmLastName: '',
    yorkEmployerName: '',
    clientName: '',
    clientId:'',
    poNumber: '',
    vendorPaidBy: '',
    yorkProgramName: '',
    adjusterName: '',
    adjusterLastName: '',
    eventId: '',
    claimId: '',
    dosStartDate: '',
    dosEndDate: '',
    orderStartDate: '',
    orderEndDate: '',
    completionStartDate: '',
    completionEndDate: '',
    pgo: "null",
    conv: "null",
    york: "null",
    billed: false,
    readyForGP: false,
    hcpcId: '',
    rental: '',
    serviceRentalStartDate: '',
    serviceRentalEndDate: '',
    accommodationCheckInDate: '',
    accommodationHotelContact: '',
    accommodationTotalCostFrom: '',
    accommodationTotalBilledChargesFrom: '',
    accommodationOrderMarginFrom: '',
    accommodationCheckOutDate: '',
    accommodationNumberOfRooms: '',
    accommodationTotalCostTo: '',
    accommodationTotalBilledChargesTo: '',
    accommodationOrderMarginTo: '',
    accommodationDateTimeCreated: '',
    accommodationBookingSite: '',
    accommodationDateTimeBooked: '',
    transportationTotalCostFrom: '',
    transportationTotalBilledChargesFrom: '',
    transportationOrderMarginFrom: '',
    transportationFirstLegOnly: '',
    transportationTotalCostTo: '',
    transportationTotalBilledChargesTo: '',
    transportationOrderMarginTo: '',
    interpretationTotalCostFrom: '',
    interpretationTotalBilledChargesFrom: '',
    interpretationOrderMarginFrom: '',
    interpretationTotalCostTo: '',
    interpretationTotalBilledChargesTo: '',
    interpretationOrderMarginTo: '',
    states:[],
    orderStatusIds:[],
    serviceTypes:{
        audiology:[],
        dental:[],
        diagnostics:[],
        modification:[],
        mse:[]
    },
    accommodationTypes:[],
    transportTypes: {
        transport:[],
        specialTransport:[],
    },
    interpretationTypes:[],
    service: "ALL",
    hhcTypes: [],


};

const reducer = (state, action) => {
    const detailsValue = action.payload;
    switch (action.type) {
        case 'UPDATE_AdvSearch_Form':
            return {
                ...state,
                claimantFirst: isNil(detailsValue.claimantFirst) ? state.claimantFirst : detailsValue.claimantFirst,
                claimantLast: isNil(detailsValue.claimantLast) ? state.claimantLast : detailsValue.claimantLast,
                serviceType: isNil(detailsValue.serviceType) ? state.serviceType : detailsValue.serviceType,
                referralId: isNil(detailsValue.referralId) ? state.referralId : detailsValue.referralId,
                serviceId: isNil(detailsValue.serviceId) ? state.serviceId : detailsValue.serviceId,
                referralCompany: isNil(detailsValue.referralCompany) ? state.referralCompany : detailsValue.referralCompany,
                yorkClientName: isNil(detailsValue.yorkClientName) ? state.yorkClientName : detailsValue.yorkClientName,
                vendorName: isNil(detailsValue.vendorName) ? state.vendorName : detailsValue.vendorName,
                claimNo: isNil(detailsValue.claimNo) ? state.claimNo : detailsValue.claimNo,
                ncmName: isNil(detailsValue.ncmName) ? state.ncmName : detailsValue.ncmName,
                yorkEmployerName: isNil(detailsValue.yorkEmployerName) ? state.yorkEmployerName : detailsValue.yorkEmployerName,
                ncmLastName: isNil(detailsValue.ncmLastName) ? state.ncmLastName : detailsValue.ncmLastName,
                clientName: isNil(detailsValue.clientName) ? state.clientName : detailsValue.clientName,
                clientId: isNil(detailsValue.clientId) ? state.clientId : detailsValue.clientId,
                poNumber: isNil(detailsValue.poNumber) ? state.poNumber : detailsValue.poNumber,
                vendorPaidBy: isNil(detailsValue.vendorPaidBy) ? state.vendorPaidBy : detailsValue.vendorPaidBy,
                yorkProgramName: isNil(detailsValue.yorkProgramName) ? state.yorkProgramName : detailsValue.yorkProgramName,
                adjusterName: isNil(detailsValue.adjusterName) ? state.adjusterName : detailsValue.adjusterName,
                adjusterLastName: isNil(detailsValue.adjusterLastName) ? state.adjusterLastName : detailsValue.adjusterLastName,
                eventId: isNil(detailsValue.eventId) ? state.eventId : detailsValue.eventId,
                claimId: isNil(detailsValue.claimId) ? state.claimId : detailsValue.claimId,
                dosStartDate: isNil(detailsValue.dosStartDate) ? state.dosStartDate : detailsValue.dosStartDate,
                dosEndDate: isNil(detailsValue.dosEndDate) ? state.dosEndDate : detailsValue.dosEndDate,
                orderStartDate: isNil(detailsValue.orderStartDate) ? state.orderStartDate : detailsValue.orderStartDate,
                orderEndDate: isNil(detailsValue.orderEndDate) ? state.orderEndDate : detailsValue.orderEndDate,
                completionStartDate: isNil(detailsValue.completionStartDate) ? state.completionStartDate : detailsValue.completionStartDate,
                completionEndDate: isNil(detailsValue.completionEndDate) ? state.completionEndDate : detailsValue.completionEndDate,
                pgo: isNil(detailsValue.pgo) ? state.pgo : detailsValue.pgo,
                conv: isNil(detailsValue.conv) ? state.conv : detailsValue.conv,
                york: isNil(detailsValue.york) ? state.york : detailsValue.york,
                billed: isNil(detailsValue.billed) ? state.billed : detailsValue.billed,
                readyForGP: isNil(detailsValue.readyForGP) ? state.readyForGP : detailsValue.readyForGP,
                hcpcId: isNil(detailsValue.hcpcId) ? state.hcpcId : detailsValue.hcpcId,
                rental: isNil(detailsValue.rental) ? state.rental : detailsValue.rental,
                serviceRentalStartDate: isNil(detailsValue.serviceRentalStartDate) ? state.serviceRentalStartDate : detailsValue.serviceRentalStartDate,
                serviceRentalEndDate: isNil(detailsValue.serviceRentalEndDate) ? state.serviceRentalEndDate : detailsValue.serviceRentalEndDate,
                accommodationCheckInDate: isNil(detailsValue.accommodationCheckInDate) ? state.accommodationCheckInDate : detailsValue.accommodationCheckInDate,
                accommodationTotalCostFrom: isNil(detailsValue.accommodationTotalCostFrom) ? state.accommodationTotalCostFrom : detailsValue.accommodationTotalCostFrom,
                accommodationTotalBilledChargesFrom: isNil(detailsValue.accommodationTotalBilledChargesFrom) ? state.accommodationTotalBilledChargesFrom : detailsValue.accommodationTotalBilledChargesFrom,
                accommodationOrderMarginFrom: isNil(detailsValue.accommodationOrderMarginFrom) ? state.accommodationOrderMarginFrom : detailsValue.accommodationOrderMarginFrom,
                accommodationCheckOutDate: isNil(detailsValue.accommodationCheckOutDate) ? state.accommodationCheckOutDate : detailsValue.accommodationCheckOutDate,
                accommodationNumberOfRooms: isNil(detailsValue.accommodationNumberOfRooms) ? state.accommodationNumberOfRooms : detailsValue.accommodationNumberOfRooms,
                accommodationTotalCostTo: isNil(detailsValue.accommodationTotalCostTo) ? state.accommodationTotalCostTo : detailsValue.accommodationTotalCostTo,
                accommodationTotalBilledChargesTo: isNil(detailsValue.accommodationTotalBilledChargesTo) ? state.accommodationTotalBilledChargesTo : detailsValue.accommodationTotalBilledChargesTo,
                accommodationOrderMarginTo: isNil(detailsValue.accommodationOrderMarginTo) ? state.accommodationOrderMarginTo : detailsValue.accommodationOrderMarginTo,
                accommodationDateTimeCreated: isNil(detailsValue.accommodationDateTimeCreated) ? state.accommodationDateTimeCreated : detailsValue.accommodationDateTimeCreated,
                accommodationBookingSite: isNil(detailsValue.accommodationBookingSite) ? state.accommodationBookingSite : detailsValue.accommodationBookingSite,
                accommodationDateTimeBooked: isNil(detailsValue.accommodationDateTimeBooked) ? state.accommodationDateTimeBooked : detailsValue.accommodationDateTimeBooked,
                transportationTotalCostFrom: isNil(detailsValue.transportationTotalCostFrom) ? state.transportationTotalCostFrom : detailsValue.transportationTotalCostFrom,
                transportationTotalBilledChargesFrom: isNil(detailsValue.transportationTotalBilledChargesFrom) ? state.transportationTotalBilledChargesFrom : detailsValue.transportationTotalBilledChargesFrom,
                transportationOrderMarginFrom: isNil(detailsValue.transportationOrderMarginFrom) ? state.transportationOrderMarginFrom : detailsValue.transportationOrderMarginFrom,
                transportationFirstLegOnly: isNil(detailsValue.transportationFirstLegOnly) ? state.transportationFirstLegOnly : detailsValue.transportationFirstLegOnly,
                transportationTotalCostTo: isNil(detailsValue.transportationTotalCostTo) ? state.transportationTotalCostTo : detailsValue.transportationTotalCostTo,
                transportationTotalBilledChargesTo: isNil(detailsValue.transportationTotalBilledChargesTo) ? state.transportationTotalBilledChargesTo : detailsValue.transportationTotalBilledChargesTo,
                transportationOrderMarginTo: isNil(detailsValue.transportationOrderMarginTo) ? state.transportationOrderMarginTo : detailsValue.transportationOrderMarginTo,
                interpretationTotalCostFrom: isNil(detailsValue.interpretationTotalCostFrom) ? state.interpretationTotalCostFrom : detailsValue.interpretationTotalCostFrom,
                interpretationTotalBilledChargesFrom: isNil(detailsValue.interpretationTotalBilledChargesFrom) ? state.interpretationTotalBilledChargesFrom : detailsValue.interpretationTotalBilledChargesFrom,
                interpretationOrderMarginFrom: isNil(detailsValue.interpretationOrderMarginFrom) ? state.interpretationOrderMarginFrom : detailsValue.interpretationOrderMarginFrom,
                interpretationTotalCostTo: isNil(detailsValue.interpretationTotalCostTo) ? state.interpretationTotalCostTo : detailsValue.interpretationTotalCostTo,
                interpretationTotalBilledChargesTo: isNil(detailsValue.interpretationTotalBilledChargesTo) ? state.interpretationTotalBilledChargesTo : detailsValue.interpretationTotalBilledChargesTo,
                interpretationOrderMarginTo: isNil(detailsValue.interpretationOrderMarginTo) ? state.interpretationOrderMarginTo : detailsValue.interpretationOrderMarginTo,
                accommodationApptTypeUI: isNil(detailsValue.accommodationApptTypeUI) ? state.accommodationApptTypeUI : detailsValue.accommodationApptTypeUI,
                interpretationApptType: isNil(detailsValue.interpretationApptType) ? state.interpretationApptType : detailsValue.interpretationApptType,
                interpretationLanguageId: isNil(detailsValue.interpretationLanguageId) ? state.interpretationLanguageId : detailsValue.interpretationLanguageId,
                transportApptType: isNil(detailsValue.transportApptType) ? state.transportApptType : detailsValue.transportApptType,

            }
        case 'UPDATE_Types':
            return {
                ...state,
                states:  isNil(detailsValue.states)? [...state.states] :[...detailsValue.states], 
                orderStatusIds: isNil(detailsValue.orderStatusIds)? [...state.orderStatusIds] :[...detailsValue.orderStatusIds],
                accommodationTypes: isNil(detailsValue.accommodationTypes)? [...state.accommodationTypes] :[...detailsValue.accommodationTypes],
                interpretationTypes: isNil(detailsValue.interpretationTypes)? [...state.interpretationTypes] :[...detailsValue.interpretationTypes],
                hhcTypes: isNil(detailsValue.hhcTypes)? [...state.hhcTypes] :[...detailsValue.hhcTypes],
                
            }
        case 'UPDATE_SERVICE':
            return {
                ...state,
                service: isNil(detailsValue) ? state.service : detailsValue,
            }
            case  'SERVICE_TYPES_TABS': 
            return {
                    ...state,
                    serviceTypes: {
                    ...state.serviceTypes,
                    audiology: isNil(detailsValue.serviceTypes.audiology)?[...state.serviceTypes.audiology] :[...detailsValue.serviceTypes.audiology],
                    dental: isNil(detailsValue.serviceTypes.dental)?[...state.serviceTypes.dental] :[...detailsValue.serviceTypes.dental],
                    diagnostics: isNil(detailsValue.serviceTypes.diagnostics)?[...state.serviceTypes.diagnostics] :[...detailsValue.serviceTypes.diagnostics],
                    mse: isNil(detailsValue.serviceTypes.mse)?[...state.serviceTypes.mse] :[...detailsValue.serviceTypes.mse],
                    modification: isNil(detailsValue.serviceTypes.modification)?[...state.serviceTypes.modification] :[...detailsValue.serviceTypes.modification],
                    },
                }
            case 'TRANSPORT_TYPES_TABS':
            return {
                ...state,
                transportTypes: {
                    ...state.transportTypes,
                    transport: isNil(detailsValue.transportTypes.transport)?[...state.transportTypes.transport] :[...detailsValue.transportTypes.transport],
                    specialTransport: isNil(detailsValue.transportTypes.specialTransport)?[...state.transportTypes.specialTransport] :[...detailsValue.transportTypes.specialTransport],
                    },
            }
            case 'SEARCH_CONTEXT_RESET':
            return initialState
        default:
            return state

    }
}

export const AdvSearchContextProvider = ({ children }) => {
    const currentState = children.props?.storedState ? children.props.storedState : initialState;

    const [state, dispatch] = useReducer(reducer, currentState);

    return (
        <AdvSearchContext.Provider value={{ state, dispatch }}>
            {children}
        </AdvSearchContext.Provider>
    );
};

