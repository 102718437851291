import PropTypes from 'prop-types';
import React from 'react';
import { Button, Row, FormGroup, Col, Input } from 'reactstrap';

import PopupModal from 'shared/components/PopupModal';

import NewExpertTable from './NewExpertTable';
import NewExpertAddressTable from './NewExpertAddressTable';

const NewGroupAdvanceModal = props => {
  const getBody = () => (
    <>
      <Row>
        <Col lg="4">
          <FormGroup>
            <Input
              name="advanceModalFirstName"
              value={props.firstName}
              onChange={props.onInputChangeHandler}
              placeholder="First Name"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Input
              name="advanceModalLastName"
              value={props.lastName}
              onChange={props.onInputChangeHandler}
              placeholder="Last Name *"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Button
              style={{ width: '100%' }}
              type="button"
              onClick={props.onCreateNewExpertClickHandler}
              disabled={props.lastName === ''}
            >
              Create New Record
            </Button>
          </FormGroup>
        </Col>
      </Row>
      {props.duplicateExperts.length > 0 && (
        <Row>
          <Col lg="12">
            <div className="red-text">Potential duplicate record(s) found!</div>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 20 }}>
        <Col lg="12">
          <span>OR enter existing record below….</span>
        </Col>
        <Col lg="12">
          <NewExpertTable
            data={props.duplicateExperts}
            onExpertClickHandler={props.onExpertClickHandler}
          />
        </Col>
        <Col lg="12">
          <NewExpertAddressTable data={props.selectedExpertAddresses} />
        </Col>
      </Row>
    </>
  );

  const getFooter = () => (
    <>
      <Button type="button" color="danger" onClick={props.modalToggle}>
        Cancel
      </Button>
    </>
  );

  return (
    <>
      <PopupModal
        content={getBody()}
        title="Add a new Expert"
        externalToggle={props.modalToggle}
        isOpen={props.modalOpen}
        footerContent={getFooter()}
        onClosed={props.resetModal}
        size="lg"
      />
    </>
  );
};

NewGroupAdvanceModal.propTypes = {
  duplicateExperts: PropTypes.array.isRequired,
  selectedExpertAddresses: PropTypes.array.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  modalToggle: PropTypes.func.isRequired,
  resetModal: PropTypes.func.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onExpertClickHandler: PropTypes.func.isRequired,
  onCreateNewExpertClickHandler: PropTypes.func.isRequired,
};

export default NewGroupAdvanceModal;
