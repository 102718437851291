import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'reactstrap';

import ExpertGeneralCard from './ExpertGeneralCard';
import ExpertCredentialsCard from './ExpertCredentialsCard';
import ExpertAddressesCard from './ExpertAddressesCard';

const ExpertGeneralInformation = props => {
  const groupMode = props.selectedExpert.isGroup === 1 ? true : false;
  return (
    <>
      <Row>
        <Col lg="4">
          <ExpertGeneralCard expert={props.expert} groupMode={groupMode} />
        </Col>
        <Col lg="8">
          <ExpertCredentialsCard expert={props.expert} groupMode={groupMode} />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col lg="12">
          <ExpertAddressesCard data={props.expert.addresses} />
        </Col>
      </Row>
    </>
  );
};

ExpertGeneralInformation.propTypes = {
  expert: PropTypes.shape({
    expertNetworks: PropTypes.array.isRequired,
    expertServices: PropTypes.array.isRequired,
    addresses: PropTypes.array.isRequired,
    credentials: PropTypes.array.isRequired,
    credentialTypes: PropTypes.array.isRequired,
    people: PropTypes.object.isRequired,
    expert: PropTypes.object.isRequired,
    groupExpert: PropTypes.array.isRequired,
  }).isRequired,
  selectedExpert: PropTypes.shape({
    isGroup: PropTypes.number.isRequired,
  }).isRequired,
};

export default ExpertGeneralInformation;
