import React from 'react';
import { connect } from 'react-redux';
import { commonSelectors } from 'common/store';
import { Spinner } from 'reactstrap';

import './AppLoading.scss';

const AppLoading = ({ loading }) => {
  return (
    <>
      {loading && <div id="loading-overlay"></div>}
      <div id="loading-container">
        {loading && <Spinner style={{ width: '5rem', height: '5rem' }} />}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { ...commonSelectors.appLoading(state) };
};

export default connect(mapStateToProps)(AppLoading);
