export const thousandsSeparatorFormat = (number, separator = ',') => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const precisionFormat = (number, precisionNumber = 2) => {
  if (isNaN(number)) {
    return '';
  }
  return parseFloat(
    Math.round(number * Math.pow(10, precisionNumber)) /
      Math.pow(10, precisionNumber)
  ).toFixed(precisionNumber);
};

export const formatFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const formatSsn = number => {
  if (!number) {
    return number;
  }

  const numStr = `${number}`;

  return `${numStr.substr(0, 3)}-${numStr.substr(3, 2)}-${numStr.slice(5)}`;
};

export const formatMaskedSsn = number => {
  if (!number) {
    return number;
  }

  const numStr = `${number}`;

  if (numStr.length < 4) {
    return numStr;
  }

  return `XXX-XX-${numStr.slice(-4)}`;
};

export const formatPhoneNumber = phoneNumber => {
  if (
    phoneNumber === null ||
    phoneNumber === '' ||
    phoneNumber.length < 7 ||
    typeof phoneNumber !== 'string'
  ) {
    return '';
  }
  return `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
    3,
    3
  )}-${phoneNumber.substr(6, phoneNumber.length - 1)}`;
};
