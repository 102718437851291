import classnames from 'classnames';
import React, { useState } from 'react';
import AppTable from 'shared/components/table/AppTable';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import Fast360VendorTable from 'shared/components/table/Fast360VendorTable';
import Fast360VendorEvaluateModal from '../components/Fast360VendorEvaluateModal.js';
import Fast360VendorHistoryModal from '../components/Fast360VendorHistoryModal.js';
import { fast360Operations, fast360Selectors } from '../store/index.js';
import { connect } from 'react-redux';

const VendorLookupResults = props => {
  const {
    vendorLookupSuggestions,
    vendorLookupByTop5ByLocation = [],
    vendorLookupByTop5ByCount = [],
    evaluateVendorData = [],
    vendorHistoryData = [],
  } = props;
  // const [activeTab, setActiveTab] = useState('1');
  const [openVendorEvaluateModal, setOpenVendorEvaluateModal] = useState(false);
  const [openVendorHistoryModal, setOpenVendorHistoryModal] = useState(false);

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };
  const evaluateVendor = vendorObj => {
    let { subService, ...payloadObj } = props.payloadObj;
    payloadObj.vendorid = vendorObj.PK_vendor_id;
    props.getEvaluateVendorData(payloadObj, setOpenVendorEvaluateModal);
  };
  const getVendorHistory = vendorObj => {
    let { serviceType, ...payloadObj } = props.payloadObj;
    payloadObj.vendorId = vendorObj.PK_vendor_id;
    payloadObj.tableAbbrv = serviceType;
    props.getVendorHistoryData(payloadObj, setOpenVendorHistoryModal);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: props.activeTab === '1' })}
            onClick={() => props.onToggle('1')}
          >
            Top by Cost
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: props.activeTab === '2' })}
            onClick={() => props.onToggle('2')}
          >
            Top by Location
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: props.activeTab === '3' })}
            onClick={() => props.onToggle('3')}
          >
            Top by Count
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={props.activeTab} style={{ width: '1125px' }}>
        <TabPane tabId="1">
          <AppTable
            columns={[
              {
                header: 'Name',
                field: 'chr_firmname',
                percent: '16',
              },
              {
                header: 'Contracted',
                field: 'contracted',
                percent: '16',
              },
              {
                header: 'Address',
                field: 'Address',
                percent: '16',
              },
              {
                header: 'Est. Price',
                field: 'pricing',
                percent: '16',
              },
              {
                header: 'Distance',
                field: 'distance',
                percent: '16',
              },
              {
                header: '',
                //field: 'ssPos',
                percent: '20',
                buttons: [
                  { name: 'Evaluate', click: evaluateVendor },
                  { name: 'Vendor History', click: getVendorHistory },
                ],
              },
            ]}
            data={vendorLookupSuggestions}
            autoPagination
            sortAble
            resizable
            sortImmediately={false}
            key="top-by-cost"
            rowClickHandler={props.rowClickHandler}
          />
        </TabPane>
        <TabPane tabId="2">
          <AppTable
            columns={[
              {
                header: 'Name',
                field: 'chr_firmname',
                percent: '20',
              },
              {
                header: 'Contracted',
                field: 'contracted',
                percent: '20',
              },
              {
                header: 'Address',
                field: 'Address',
                percent: '20',
              },
              {
                header: 'Distance',
                field: 'distance',
                percent: '20',
              },
              {
                header: '',
                //field: 'ssTos',
                percent: '20',
                buttons: [
                  { name: 'Evaluate', click: evaluateVendor },
                  { name: 'Vendor History', click: getVendorHistory },
                ],
              },
            ]}
            data={vendorLookupByTop5ByLocation}
            autoPagination
            sortAble
            resizable
            sortImmediately={false}
            key="top-by-location"
            rowClickHandler={props.rowClickHandler}
          />
        </TabPane>
        <TabPane tabId="3">
          <AppTable
            columns={[
              {
                header: 'Name',
                field: 'chr_firmname',
                percent: '16',
              },
              {
                header: 'Contracted',
                field: 'contracted',
                percent: '16',
              },
              {
                header: 'Address',
                field: 'Address',
                percent: '16',
              },
              {
                header: 'Time Used',
                field: 'used',
                percent: '16',
              },
              {
                header: 'Distance',
                field: 'distance',
                percent: '16',
              },
              {
                header: '',
                //field: 'ssPos',
                percent: '20',
                buttons: [
                  { name: 'Evaluate', click: evaluateVendor },
                  { name: 'Vendor History', click: getVendorHistory },
                ],
              },
            ]}
            data={vendorLookupByTop5ByCount}
            autoPagination
            sortAble
            resizable
            sortImmediately={false}
            key="top-by-count"
            rowClickHandler={props.rowClickHandler}
          />
        </TabPane>
        <Fast360VendorEvaluateModal
          isOpen={openVendorEvaluateModal}
          evaluateVendorData={evaluateVendorData}
          onHide={() => setOpenVendorEvaluateModal(false)}
          onClose={() => props.closeLookupModal(false)}
        />
        <Fast360VendorHistoryModal
          isOpen={openVendorHistoryModal}
          vendorHistoryData={vendorHistoryData}
          onHide={() => setOpenVendorHistoryModal(false)}
          onClose={() => props.closeLookupModal(false)}
        />
      </TabContent>
    </div>
  );
};
const mapStateToProps = state => {
  const fast360Info = fast360Selectors.getFast360Info(state);
  return {
    evaluateVendorData: fast360Info.evaluateVendorData,
    vendorHistoryData: fast360Info.vendorHistoryData,
  };
};

const mapDispatchToProps = dispatch => ({
  getEvaluateVendorData: (data, setOpenVendorEvaluateModal) =>
    dispatch(
      fast360Operations.getEvaluateVendorData(data, setOpenVendorEvaluateModal)
    ),
  getVendorHistoryData: (data, setOpenVendorHistoryModal) =>
    dispatch(
      fast360Operations.getVendorHistoryData(data, setOpenVendorHistoryModal)
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorLookupResults);
