import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { billOperationsOperations, billOperationsSelectors } from '../store';

import NotesModal from './NotesModal';

class NoteLinkAction extends Component {
  render() {
    return (
      <NotesModal
        iconOnly={this.props.iconOnly}
        recordId={this.props.recordId}
        notes={this.props.notes}
        eorNotes={this.props.eorNotes}
        accountingNotes={this.props.accountingNotes}
        isFromClaim={this.props.isFromClaim}
        submitSaveNotes={body =>
          this.props.saveNote(body, this.props.searchFields)
        }
      />
    );
  }
}

NoteLinkAction.propTypes = {
  iconOnly: PropTypes.bool,
  notes: PropTypes.string,
  isFromClaim: PropTypes.string
};

const mapStateToProps = state => {
  const findBills = billOperationsSelectors.getFindBills(state);
  return {
    searchFields: findBills.searchFields,
  };
};

const mapDispatchToProps = dispatch => ({
  saveNote: (body, searchFields) =>
    dispatch(billOperationsOperations.saveNote(body, searchFields, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteLinkAction);
