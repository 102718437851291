import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { commonOperations } from 'common/store';

import { authSelectors } from 'modules/auth/store';

import PopupModal from 'shared/components/PopupModal';

import ExpertInfo from '../ExpertInfo';

import { expertInfoSelectors, expertInfoOperations } from '../store';

import { getElementFromId } from '../utils/expertInfoUtils';
import { PROSPECT, APPLICANT } from '../utils/constantUtils';

import GroupRecordModalBody from './GroupRecordModalBody';
import RecordModalFooter from './RecordModalFooter';

const GroupContainer = ({
  match,
  currentUserId,
  loadInitialData,
  loadExpertGroup,
  saveExpertGroup,
  alert,
  data,
  errors,
  expertStatuses,
}) => {
  const [modal, setModal] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      const id = match.params.id;
      loadExpertGroup({
        expertId: id,
      });
    }

    if (currentUserId && currentUserId !== 0) {
      loadInitialData(currentUserId);
    }
  }, [match, currentUserId, loadExpertGroup, loadInitialData]);

  const toggle = () => {
    setModal(!modal);
  };

  const saveGroup = () => {
    if (data.groupName === '') {
      alert('Please enter a Group Name.');
      return;
    }

    if (Number(data.groupType) === Number('-1')) {
      alert('Please select a Group Type.');
    }

    const expertStatus = getElementFromId(expertStatuses, data.expertStatus);

    if (
      Number(data.expertStatusStep) === Number(-1) &&
      (expertStatus === PROSPECT || expertStatus === APPLICANT)
    ) {
      alert('Please select a Status Step Progress.');
      return;
    }

    const inputErrors = Object.keys(errors).filter(
      key => errors[key].length > 0
    );

    if (inputErrors.length > 0) {
      alert(
        <span>
          The following problem(s) occurred:
          {inputErrors.map((key, index) => (
            <span key={index}>
              <br />
              {errors[key]}
            </span>
          ))}
        </span>
      );
      return;
    }

    saveExpertGroup({
      ...data,
      currentUserId,
    });
  };

  return (
    <>
      <ExpertInfo id={data.id} />
      <PopupModal
        className="expert-info-modal"
        content={<GroupRecordModalBody />}
        title="Basic Group Information"
        externalToggle={toggle}
        isOpen={modal}
        footerContent={
          <RecordModalFooter
            data={data}
            toggle={toggle}
            save={saveGroup}
            expertStatuses={expertStatuses}
          />
        }
        size="lg"
      />
    </>
  );
};

GroupContainer.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  expertStatuses: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  loadInitialData: PropTypes.func.isRequired,
  loadExpertGroup: PropTypes.func.isRequired,
  saveExpertGroup: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const groupState = expertInfoSelectors.getGroupInfo(state);
  const user = authSelectors.getUser(state);
  return {
    data: groupState.data,
    errors: groupState.errors,
    expertStatuses: groupState.expertStatuses,
    currentUserId: user.id || 0,
  };
};

const mapDispatchToProps = dispatch => ({
  loadInitialData: userId => dispatch(expertInfoOperations.initData(userId)),
  loadExpertGroup: groupId =>
    dispatch(expertInfoOperations.loadExpertGroup(groupId)),
  saveExpertGroup: data => dispatch(expertInfoOperations.saveExpertGroup(data)),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupContainer);
